module.exports = {
    STATUS_CODE: {
        OK: 200,
        CREATED: 201,
        MULTI_STATUS: 207,
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
        INTERNAL_SERVER_ERROR: 500
    },
    MAX_ATTEMPTS: 3,
    RETRY_DELAY_IN_MS: 200,
};