import React, { useEffect } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link";
import Icon from "@amzn/meridian/icon";
import Tag from "@amzn/meridian/tag";
import Button from "@amzn/meridian/button";
import RadioButton from "@amzn/meridian/radio-button";

import chevronUpSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-up-small";
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";

import MeetingSuggestionAttendeeGroup from "./meeting-suggestion-attendee-group.js";

import {
    getAttendeeCount,
    getStationFreeStatus,
    getTotalAttendees,
    getAttendeeAvailabilityType,
    getAttendeeTagText,
} from "../livestream-utils";
import { convertEpoch, sortRoomsByName } from "../../meeting-scheduler/meeting-scheduler-utils";
import { FLOW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";
import { shortRoomName, renderLocationFeatureIcon } from "../../shared/locations/locations-utils";
import { LOCATION_FEATURE } from "../../shared/locations/locations-constants";
import { ATTENDEE_ROLE } from "../../people/people-constants";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { TIME_FORMAT } from "../../shared/settings/settings-constants.js";

const MeetingSuggestions = (props) => {
    const flowType = props.flowType;
    const identity = props.identity;
    const attendees = props.attendees;
    const eventRequirements = props.eventRequirements;
    const prevRooms = props.prevRooms;
    const prevLocations = props.prevLocations;
    const currentYear = props.currentYear;
    const meetingSuggestions = props.meetingSuggestions;
    const onChangeSelectSuggestion = props.onChangeSelectSuggestion;
    const openRoomSwapModal = props.openRoomSwapModal;
    const isExistingMeetingSelected = props.isExistingMeetingSelected;
    const timeFormat = props.timeFormat;

    const [isInitialLoad, setIsInitialLoad] = [props.isInitialLoad, props.setIsInitialLoad];
    const meetingSuggestionSelected = props.meetingSuggestionSelected;
    const [meetingSuggestionsDetail, setMeetingSuggestionsDetail] = [props.meetingSuggestionsDetail, props.setMeetingSuggestionsDetail];

    // Current room selection info
    const [selectedRooms, setSelectedRooms] = [props.selectedRooms, props.setSelectedRooms];

    // Timezones
    const timezones = props.timezones;
    const suggestionsTimezoneId = props.suggestionsTimezoneId;
    const suggestionsTimezoneName = timezoneIdToDisplayName(timezones, suggestionsTimezoneId);

    // Toggle meeting suggestion details
    const toggleSuggestionDetail = (id, type = "") => {
        setMeetingSuggestionsDetail((prevMeetingSuggestionsDetail) => ({
            ...prevMeetingSuggestionsDetail,
            [id + type]: !prevMeetingSuggestionsDetail[id + type],
        }));
    };

    // Return a list of attendees for a specific group (free, tentative, unavailable)
    const getAttendeeGroup = (suggestion, suggestionGroup, groupType) => {
        // only display the attendee if they are the organizer, a presenter, or a point of contact
        let filteredSuggestionGroup = [];
        attendees.forEach((attendee) => {
            if (suggestionGroup.includes(attendee.email) &&
                (attendee.isOrganizer || attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER || attendee.isPointOfContact)) {
                filteredSuggestionGroup.push(attendee.email);
            }
        });

        let displayLivestreamEngineers = (groupType === "Available" && getStationFreeStatus(suggestion, eventRequirements)) ||
            (groupType === "Unavailable" && !getStationFreeStatus(suggestion, eventRequirements));

        return ((getAttendeeCount(filteredSuggestionGroup, attendees) > 0 || displayLivestreamEngineers) &&
            <MeetingSuggestionAttendeeGroup
                suggestion={suggestion}
                attendees={attendees}
                attendeeGroup={filteredSuggestionGroup}
                attendeeGroupType={groupType}
                displayLivestreamEngineers={displayLivestreamEngineers}
                totalAttendees={getTotalAttendees(suggestion, attendees)}
                meetingSuggestionsDetail={meetingSuggestionsDetail}
                timezones={timezones}
                suggestionsTimezoneId={suggestionsTimezoneId}
                toggleSuggestionDetail={toggleSuggestionDetail}
            />
        );
    };

    // Initialize selected rooms for each suggestion to the first room
    useEffect(() => {
        if (identity && identity.username && isInitialLoad && meetingSuggestions.length && prevLocations) {
            let rooms = {};

            meetingSuggestions.forEach((suggestion) => {
                suggestion.suggestionRoomList.forEach((roomList, roomListIndex) => {
                    rooms[suggestion.startTime + "-" + roomListIndex] = 0;
                });
            });

            if (flowType === FLOW_TYPE.CREATE) {
                onChangeSelectSuggestion(meetingSuggestions[0], rooms);
            }

            setSelectedRooms(rooms);
            setIsInitialLoad(false);
        }
    }, [identity, isInitialLoad, meetingSuggestions, flowType, prevLocations, setSelectedRooms, setIsInitialLoad, onChangeSelectSuggestion]);

    return (
        <Column width={props.width} maxWidth={props.maxWidth || "900px"}>
            {meetingSuggestions.map((suggestion, suggestionIndex) => (
                <Row alignmentHorizontal="justify" alignmentVertical="top" spacingInset="small" backgroundColor="white" type="outline" key={suggestion.startTime}>
                    <Row alignmentHorizontal="justify" alignmentVertical="top" wrap="down" spacing="none">
                        <Row minWidth="270px" spacing="none" spacingInset="small">
                            <RadioButton
                                checked={meetingSuggestionSelected === suggestion.peopleRankIndex && !isExistingMeetingSelected}
                                value={suggestion.peopleRankIndex}
                                onChange={() => onChangeSelectSuggestion(suggestion)}
                                size="large"
                                name="suggestionOption"
                            >
                                <Column spacing="none">
                                    <Row spacingInset="none" spacing="small">
                                        <Text type="h100">{convertEpoch(suggestion.startTime, "time", suggestionsTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR)} - {convertEpoch(suggestion.endTime, "time", suggestionsTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR)}</Text>
                                        <Text type="b100" color="secondary">{suggestionsTimezoneName}</Text>
                                    </Row>
                                    {(convertEpoch(suggestion.startTime, "date", suggestionsTimezoneId) !== convertEpoch(suggestion.endTime, "date", suggestionsTimezoneId)) ?
                                        <Column spacing="none">
                                            <Text>{convertEpoch(
                                                suggestion.startTime,
                                                currentYear !== convertEpoch(suggestion.endTime, "year", suggestionsTimezoneId) ? "shortWeekdayDateYear" : "shortWeekdayDate",
                                                suggestionsTimezoneId)} to
                                            </Text>
                                            <Text>{convertEpoch(
                                                suggestion.endTime,
                                                currentYear !== convertEpoch(suggestion.endTime, "year", suggestionsTimezoneId) ? "shortWeekdayDateYear" : "shortWeekdayDate",
                                                suggestionsTimezoneId)}
                                            </Text>
                                        </Column>
                                        :
                                        <Text>{convertEpoch(
                                            suggestion.startTime,
                                            currentYear !== convertEpoch(suggestion.startTime, "year", suggestionsTimezoneId) ? "shortWeekdayDateYear" : "shortWeekdayDate",
                                            suggestionsTimezoneId)}
                                        </Text>
                                    }
                                </Column>
                            </RadioButton>
                        </Row>
                        <Row alignmentVertical="stretch" wrap="down" spacing="medium" spacingInset="small">
                            {(prevRooms.length !== 0 || prevLocations.length !== 0) ?
                                <Column width="250px" alignmentHorizontal="left" spacingInset="none small">
                                    {sortRoomsByName(prevRooms).map((room) => {
                                        return (room.email &&
                                            <Column key={suggestion.startTime + "-" + room.email} alignmentHorizontal="left" spacing="xsmall">
                                                <Tag type={suggestion.freePeople.includes(room.email) ? "success" : "error"}>
                                                    <Column maxWidth="215px">
                                                        <Text type="b200" truncate={true}>{shortRoomName(room.name)}</Text>
                                                    </Column>
                                                </Tag>
                                                <Row alignmentHorizontal="justify">
                                                    <Text type="b100">{suggestion.freePeople.includes(room.email) ? "Available" : "Unavailable"}</Text>
                                                    <Text type="b100">{room.capacity + (room.capacity === 1 ? " seat" : " seats")}</Text>
                                                    <Row alignmentHorizontal="right" spacing="xsmall" wrap="down">
                                                        {room.resourceList.includes(LOCATION_FEATURE.CAMERA) && renderLocationFeatureIcon(LOCATION_FEATURE.CAMERA, 16)}
                                                        {room.resourceList.includes(LOCATION_FEATURE.DISPLAY) && renderLocationFeatureIcon(LOCATION_FEATURE.DISPLAY, 16)}
                                                        {room.restricted && renderLocationFeatureIcon(LOCATION_FEATURE.RESTRICTED, 16)}
                                                        {room.managed && renderLocationFeatureIcon(LOCATION_FEATURE.HAND_MANAGED, 16)}
                                                    </Row>
                                                </Row>
                                            </Column>
                                        )
                                    })}
                                    {suggestion.suggestionRoomList.map((roomList, roomListIndex) => {
                                        return (roomList.length !== 0 &&
                                            <Column key={suggestion.startTime + "-" + roomListIndex} alignmentHorizontal="left" spacing="xsmall">
                                                <Tag type="success">
                                                    <Column maxWidth="215px">
                                                        <Text type="b200" truncate={true}>{shortRoomName(roomList[(selectedRooms[suggestion.startTime + "-" + roomListIndex]) || 0].room.name)}</Text>
                                                    </Column>
                                                </Tag>
                                                <Row alignmentHorizontal="justify">
                                                    <Text type="b100">
                                                        <Link type="secondary" onClick={() => openRoomSwapModal(suggestion.startTime, suggestionIndex, roomListIndex)}>Change ({roomList.length} available)</Link>
                                                    </Text>
                                                    <Text type="b100">{roomList[(selectedRooms[suggestion.startTime + "-" + roomListIndex]) || 0].room.capacity + (roomList[(selectedRooms[suggestion.startTime + "-" + roomListIndex]) || 0].room.capacity === 1 ? " seat" : " seats")}</Text>
                                                    <Column alignmentHorizontal="right" spacing="xsmall">
                                                        <Row spacing="xsmall">
                                                            {roomList[(selectedRooms[suggestion.startTime + "-" + roomListIndex]) || 0].room.resourceList.includes(LOCATION_FEATURE.CAMERA) && renderLocationFeatureIcon(LOCATION_FEATURE.CAMERA, 16)}
                                                            {roomList[(selectedRooms[suggestion.startTime + "-" + roomListIndex]) || 0].room.resourceList.includes(LOCATION_FEATURE.DISPLAY) && renderLocationFeatureIcon(LOCATION_FEATURE.DISPLAY, 16)}
                                                        </Row>
                                                        <Row spacing="xsmall">
                                                            {roomList[(selectedRooms[suggestion.startTime + "-" + roomListIndex]) || 0].room.restricted && renderLocationFeatureIcon(LOCATION_FEATURE.RESTRICTED, 16)}
                                                            {roomList[(selectedRooms[suggestion.startTime + "-" + roomListIndex]) || 0].room.managed && renderLocationFeatureIcon(LOCATION_FEATURE.HAND_MANAGED, 16)}
                                                        </Row>
                                                    </Column>
                                                </Row>
                                            </Column>
                                        )
                                    })}
                                </Column>
                                :
                                <Column width="250px" alignmentHorizontal="left" spacingInset="none small">
                                    <Tag type="success">Virtual only</Tag>
                                </Column>
                            }
                            <Column width="400px" spacing="small" spacingInset="none small">
                                <Row alignmentVertical="center" spacing="xsmall" height="20px">
                                    <Tag type={getAttendeeAvailabilityType(suggestion, attendees, eventRequirements)}>{getAttendeeTagText(suggestion, attendees, eventRequirements)}</Tag>
                                    <Button type="icon" onClick={() => toggleSuggestionDetail(suggestion.startTime)} size="small">
                                        {meetingSuggestionsDetail[suggestion.startTime] ?
                                            <Icon tokens={chevronUpSmallTokens}>{`Hide attendee details for meeting suggestion from ${convertEpoch(suggestion.startTime, "time", suggestionsTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR)} to ${convertEpoch(suggestion.endTime, "time", suggestionsTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR)} ${suggestionsTimezoneName}`}</Icon>
                                            :
                                            <Icon tokens={chevronDownSmallTokens}>{`View attendee details for meeting suggestion from ${convertEpoch(suggestion.startTime, "time", suggestionsTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR)} to ${convertEpoch(suggestion.endTime, "time", suggestionsTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR)} ${suggestionsTimezoneName}`}</Icon>
                                        }
                                    </Button>
                                </Row>
                                {meetingSuggestionsDetail[suggestion.startTime] &&
                                    <Column spacing="small">
                                        {getAttendeeGroup(suggestion, suggestion.unavailablePeople, "Unavailable")}
                                        {getAttendeeGroup(suggestion, suggestion.tentativePeople, "Tentative")}
                                        {getAttendeeGroup(suggestion, suggestion.freePeople, "Available")}
                                        {getAttendeeGroup(suggestion, suggestion.unknownPeople, "Unknown")}
                                    </Column>
                                }
                            </Column>
                        </Row>
                    </Row>
                </Row>
            ))}
        </Column>
    );
};

export default MeetingSuggestions;
