import React from "react"
import PropTypes from "prop-types"
import MiniCalendar from "../_mini-calendar"
import { isWithinRange, isBefore } from "../../_utils/date-string"
import dateLocales from "../../_utils/date-locales"

/**
 * This component provides a nice way for a user to input a date range. The UI
 * is a month calendar that allows the user to either select a day in the
 * current month or to browse other months. If the "name" prop is provided two
 * normal html inputs are rendered under the hood (one for the start date and
 * one for the end date) so this component can be used like any other form
 * control.
 */
class DateRangeInputCalendar extends React.Component {
  static propTypes = {
    /**
     * Identify whether the user will input the start or end of the date range
     * when they interact with the calendar.
     */
    input: PropTypes.oneOf(["start", "end"]).isRequired,
    /**
     * This date is used to determine which month the calendar should display
     * by default. The date must be provided in the format YYYY-MM-DD.
     */
    viewDate: PropTypes.string.isRequired,
    /**
     * A function that will be called when the value of the date input is
     * changed by the user. The new date range will be passed to the function as
     * an array of two date strings in the format YYYY-MM-DD.
     *
     * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
     * so you must track the values provided to this function somewhere in state
     * and pass them back via the `value` prop in order to make this component
     * interactive.
     */
    onChange: PropTypes.func.isRequired,
    /**
     * A function that will be called whenever the calendar wants to update the
     * view date itself.
     */
    onChangeViewDate: PropTypes.func.isRequired,
    /**
     * If set to true the user will be able to choose the same date for the start
     * and the end of the date range.
     *
     * This can be helpful if a single date or a date range is acceptable, or if
     * the date range picker is used in conjunction with time pickers (not yet
     * part of Meridian, [+1 the backlog SIM](https://sim.amazon.com/issues/MRDN-438))
     * to allow the selection of a time range that may fall within a single day or
     * span multiple days.
     */
    allowSameStartAndEnd: PropTypes.bool,
    /**
     * Determines whether changing months is animated with a sliding motion.
     */
    animateMonthChange: PropTypes.bool,
    /**
     * A function that can be used to disable specific dates. The function will
     * be passed date strings in the format YYYY-MM-DD and is expected to return
     * true for disabled dates and false otherwise.
     */
    disabledDates: PropTypes.func,
    /**
     * The locale to use when rendering the input (e.g. month names, names of
     * days of the week, etc.). See the [Intl API](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl).
     */
    locale: PropTypes.oneOf(Object.keys(dateLocales)),
    /**
     * Set the width of a single month.
     */
    monthWidth: PropTypes.number,
    /**
     * The number of calendar months to display at a time.
     */
    monthsInView: PropTypes.number,
    /**
     * The name of the component's form control. If the component is placed in a
     * form, this will be the name associated with the component's data when the
     * form is submitted.
     */
    name: PropTypes.string,
    /**
     * A localized label for the next month button.
     */
    nextMonthLabel: PropTypes.string,
    /**
     * Define today's date (in the format YYYY-MM-DD). This date will be
     * marked in the calendar with a special indicator.
     */
    now: PropTypes.string,
    /**
     * A localized label for the previous month button.
     */
    previousMonthLabel: PropTypes.string,
    /**
     * A function that can be used to customize the rendering of a single day. The
     * function is passed an object which contains the following props.
     * ```
     * date:      `string` of date in the format YYYY-MM-DD
     * label:     `string` of the localized day of the month (e.g. "21")
     * current:   `boolean` set to `true` if the date is todays date
     * selected:  `boolean` set to `true` if date is selected
     * disabled:  `boolean` set to `true` if date is disabled
     * range:     `boolean` set to `true` if date is in the range of selected dates
     * ```
     */
    renderDay: PropTypes.func,
    /**
     * The value of the input as an array of two date strings in the format
     * YYYY-MM-DD.
     *
     * Update this when `onChange` is called to ensure the component is interactive
     * (see the documentation for the `onChange` prop).
     */
    value: PropTypes.arrayOf(PropTypes.string),
    /**
     * Use this attribute to associate a text label with the calendar popup when selecting the start date
     *
     * @added by meetex team member (camei@)
     */
    ariaLabelStart: PropTypes.string,
    /**
     * Use this attribute to associate a text label with the calendar popup when selecting the end date
     *
     * @added by meetex team member (camei@)
     */
    ariaLabelEnd: PropTypes.string,
  }

  static defaultProps = {
    value: [],
  }

  /**
   * Get the next value based on the current value, the input that's being
   * changed, the new value of the input, and whether it's acceptable to have
   * the range start and end with the same day.
   */
  static getNextValue = ({
    value,
    input,
    inputValue,
    allowSameStartAndEnd,
  }) => {
    // If we're changing the start date then update that and either return the
    // same end date if it's still valid or clear the end date if it's no longer
    // valid
    if (input === "start") {
      return [
        inputValue,
        !inputValue ||
        !value[1] ||
        isBefore(inputValue, value[1]) ||
        (allowSameStartAndEnd && inputValue === value[1])
          ? value[1]
          : undefined,
      ]
    }
    // If we're changing the end date then update that and either return the
    // same start date if it's still valid or clear the start date if it's no
    // longer valid
    else if (input === "end") {
      return [
        !inputValue ||
        !value[0] ||
        isBefore(value[0], inputValue) ||
        (allowSameStartAndEnd && inputValue === value[0])
          ? value[0]
          : undefined,
        inputValue,
      ]
    }
  }

  state = {
    hoverDate: undefined,
  }

  onClickDate = inputValue =>
    this.props.onChange(
      DateRangeInputCalendar.getNextValue({
        value: this.props.value,
        input: this.props.input,
        inputValue,
        allowSameStartAndEnd: this.props.allowSameStartAndEnd,
      })
    )

  // Track the current hovered date so we can actively highlight the hovered
  // range for the user.
  onMouseEnterDate = hoverDate => this.setState({ hoverDate })

  rangeDates = date => {
    const { value, input } = this.props
    const { hoverDate } = this.state
    // If both values are selected, highlight the range between them
    return value[0] && value[1]
      ? isWithinRange(date, value[0], value[1])
      : // If only the start value is selected, highlight the range between it
      // and the hover date
      hoverDate && input === "end" && value[0] && isBefore(value[0], hoverDate)
      ? isWithinRange(date, value[0], hoverDate)
      : // If only the end value is selected, highlight the range between it
      // and then hover date
      hoverDate &&
        input === "start" &&
        value[1] &&
        isBefore(hoverDate, value[1])
      ? isWithinRange(date, hoverDate, value[1])
      : false
  }

  render() {
    const props = this.props
    return (
      <MiniCalendar
        selectedDates={date =>
          date === props.value[0] || date === props.value[1]
        }
        rangeDates={this.rangeDates}
        onClickDate={this.onClickDate}
        onMouseEnterDate={this.onMouseEnterDate}
        viewDate={props.viewDate}
        onChangeViewDate={props.onChangeViewDate}
        now={props.now}
        disabledDates={props.disabledDates}
        locale={props.locale}
        monthsInView={props.monthsInView}
        monthWidth={props.monthWidth}
        previousMonthLabel={props.previousMonthLabel}
        nextMonthLabel={props.nextMonthLabel}
        renderDay={props.renderDay}
        animateMonthChange={props.animateMonthChange}
        aria-label={props.input === "start" ? props.ariaLabelStart : props.ariaLabelEnd}
      >
        {props.name ? (
          <span>
            <input
              type="hidden"
              name={`${props.name}[]`}
              value={props.value[0] || ""}
            />
            <input
              type="hidden"
              name={`${props.name}[]`}
              value={props.value[1] || ""}
            />
          </span>
        ) : null}
      </MiniCalendar>
    )
  }
}

export default DateRangeInputCalendar
