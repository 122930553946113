import { call, put, takeEvery } from "redux-saga/effects";
import * as Actions from "../actions/action-types";

import Cookies from 'universal-cookie';
import CognitoClient from "../../api/cognito";

import { getPerson } from "../../api/apig/ras";
import { showToast } from "../../shared/toasts/actions";

import { getErrorToast } from "../../shared/toasts/toast-utils";
import { TOAST_ID } from "../../shared/toasts/toast-constants";

export function* watchGetSigningCredentials() {
    yield takeEvery(Actions.GET_SIGNING_CREDENTIALS_ASYNC, getSigningCredentials);
}

export function* getSigningCredentials(idToken) {
    console.log('Getting Signing Creds');

    // TODO error handling; show error UI here
    const { IdentityId: identityId } = yield call(CognitoClient.getId, idToken);
    const credentialsResponse = yield call(CognitoClient.getCredentialsForIdentity, idToken, identityId);
    const credentials = credentialsResponse.Credentials;

    yield put({
        type: Actions.UPDATE_SIGNING_CREDENTIALS,
        ...credentials
    });
}

export function* watchRefreshTokens() {
    yield takeEvery(Actions.REFRESH_TOKENS, refreshTokens);
}

export function* refreshTokens(refreshToken, appClientId, userAlias) {
    try {
        // refresh identity/access token
        const tokenExpiration = new Date();
        const refreshTokensResponse = yield call(CognitoClient.refreshTokens, refreshToken, appClientId);
        const authenticationResult = refreshTokensResponse.AuthenticationResult;

        if (!authenticationResult.RefreshToken) {
            authenticationResult.RefreshToken = refreshToken;
        }
        tokenExpiration.setSeconds(tokenExpiration.getSeconds() + authenticationResult.ExpiresIn);

        // Update the browser cookies with the new tokens
        const cookieOptions = {
            path: "/",
            maxAge: 60 * 60, // 1h expiration time to match cookie expiration
            domain: `.${window.location.hostname}`, // prepend a dot to support subdomains
            secure: true,
        };
        const cookies = new Cookies();
        cookies.set(`CognitoIdentityServiceProvider.${appClientId}.federateoidc_${userAlias}.idToken`,
            authenticationResult.IdToken, cookieOptions);
        cookies.set(`CognitoIdentityServiceProvider.${appClientId}.federateoidc_${userAlias}.accessToken`,
            authenticationResult.AccessToken, cookieOptions);
        cookies.set(`CognitoIdentityServiceProvider.${appClientId}.federateoidc_${userAlias}.refreshToken`,
            authenticationResult.RefreshToken, cookieOptions);

        // Update the state with the new tokens
        yield put({
            type: Actions.UPDATE_IDENTITY_CREDENTIALS,
            idToken: authenticationResult.IdToken,
            accessToken: authenticationResult.AccessToken,
            refreshToken: authenticationResult.RefreshToken,
            idTokenExpiration: tokenExpiration.getTime(),
        });

        // refresh APIG access credentials
        yield call(getSigningCredentials, authenticationResult.IdToken);
    } catch(err) {
        const toast = getErrorToast("Your session has expired, please refresh the page to continue");
        toast.id = TOAST_ID.SESSION_EXPIRED;
        yield put(showToast(toast));
    }
}

export function* watchGetPerson() {
    yield takeEvery(Actions.GET_PERSON, getPersonApi);
}

export function* getPersonApi(action) {
    console.log("Calling get person in sagas for: " + action.username);
    const response = yield call(getPerson, action.username);
    console.log(`Response is: ${JSON.stringify(response)}`);
    return JSON.parse(response);
}

const sagas = [
    watchGetSigningCredentials(),
    watchGetPerson(),
    watchRefreshTokens(),
];

export default sagas;
