import textTokens from "@amzn/meridian-tokens/component/text"

const text = (t, keys = {}) => {
  const tokens = textTokens(t.tokens)
  return {
    color:
      t("foregroundColor", keys.color) ||
      t.tokens.themeForegroundPrimaryDefault,
    fontFamily:
      t("fontFamily", keys.fontFamily) || tokens.textFontFamilyAmazonEmber,
    fontSize: t("fontSize", keys.fontSize) || tokens.textFontSizeB300,
    fontWeight: t("fontWeight", keys.fontWeight) || tokens.textFontWeightB300,
    lineHeight: t("lineHeight", keys.lineHeight) || tokens.textLineHeightB300,
  }
}

export default text
