import React from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import { KEYCODE } from "../../shared-constants";
import { renderAriaLabel, renderRatingSvg } from "../feedback-utils";

const MeetingsRating = (props) => {
    const userRating = props.userRating;
    const onUpdateUserRating = props.onUpdateUserRating;
    const closeButtonRef = props.closeButtonRef;
    const isModal = props.isModal;

    const ratings = [1, 2, 3, 4, 5];

    const onKeyDown = (event, rating) => {
        const keyCode = event.keyCode || event.which;
        switch (keyCode) {
            case KEYCODE.ENTER:
            case KEYCODE.SPACE:
                onUpdateUserRating(rating);
                break;
            case KEYCODE.TAB:
                if (rating === 5 && !userRating && !event.shiftKey) {
                    event.preventDefault();
                    closeButtonRef.current.focus();
                }
                break;
            default:
                break;
        }
    };

    return (
        <Row spacingInset="small" spacing="small" alignmentHorizontal="center">
            {ratings.map((rating) =>
                <Column alignmentHorizontal="center" spacingInset="small" spacing="small" key={rating}>
                    {userRating &&
                        <Row height="15px" alignmentHorizontal="center">
                            <Text type="h100">{userRating === rating ? rating : ""}</Text>
                        </Row>
                    }
                    <div
                        className="ratingIcon"
                        aria-label={renderAriaLabel(rating, userRating)}
                        aria-live="assertive"
                        role="button"
                        onClick={() => onUpdateUserRating(rating)}
                        tabIndex="0"
                        onKeyDown={(event) => onKeyDown(event, rating)}
                    >
                        {renderRatingSvg(rating, userRating, isModal)}
                    </div>
                </Column>
            )}
        </Row>
    );
};

export default MeetingsRating;