import React, { useCallback, useEffect, useRef, useState } from 'react';

const ExternalAuthLanding = () => {

    useEffect(() => {

        // If the window is a popup
        if (window.opener !== null) {
            window.opener.OnSuccessfullExtAuth();
            window.close()
        }
    })

    return (
        <div>
            External Auth Landing
        </div>
    );
};

export default  ExternalAuthLanding;