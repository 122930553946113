import jwt from "jsonwebtoken";
import { all, call, delay, fork, put, select } from "redux-saga/effects";
import { merge } from "lodash";
// import { push } from "connected-react-router";

import * as IdentityActions from "../features/identity/actions/action-types";
import { getSigningCredentials, refreshTokens } from "../features/identity/sagas";
import { getJwtIdTokens } from "../features/identity/jwt-id-tokens";
import {
    getPerson,
    getBuildingWithQueryString,
    getConfRoomWithQueryString,
    getUserByEmail,
} from "../features/api/apig/ras";
import { getRegion } from "../features/api/apig/ras/region-mapping";
import { isErrorResponse } from "../features/api/apig/apig-utils";
import { showToast } from "../features/shared/toasts/actions";
import { getErrorToast } from "../features/shared/toasts/toast-utils";
import { getUserPreferences, getUserFavorites } from "../features/api/apig/ams";
import { getIdentityTokens } from "./selector"
import { setCurrentUser } from "../features/identity/actions";
import { saveBuildings, saveFloors } from "../features/shared/locations/actions";
import { saveFavorites } from "../features/shared/favorites/actions";
import { sortBuildingsByBuildingCode } from "../features/shared/locations/locations-utils";
import { getStageConfig } from "../features/api/stageConfig/stage-config-utils";
// import { setUnauthorizedUser } from "../features/shared/actions";
import { panoramaInit } from "../features/shared/metrics/panorama";
import isBefore from "date-fns/isBefore";
import subMinutes from "date-fns/subMinutes";
import { ENDPOINTS } from "../features/api/apig/ams/endpointConfig";
import { getUserDefaultLocation } from "../features/shared/locations/locations-utils";
// import { checkGroupMembership } from "../features/people/sagas";
import { getTimezones } from "../features/shared/timezones/actions"
import { LANGUAGES } from "../features/shared/timezones/timezones-constants";
import { clientSideErrorTelemetryInit } from "../features/shared/metrics/client-side-error-telemetry-utils";
import { getExchangePreferences } from "../features/shared/exchange-preferences/actions";
import { savePreferences, savePreferencesAysnc, saveSettingsChimeEmail, setPreferencesLoaded } from "../features/shared/settings/actions";
import { CALENDAR_VIEW_DESKTOP, CALENDAR_VIEW_MOBILE, DATE_FORMAT, SCHEDULING_MODE, TIME_FORMAT, WORKING_HOURS } from "../features/shared/settings/settings-constants";
import { saveGetUserByEmail } from "../features/shared/chime/actions";
import { postNoValidChimeEmailFoundMetric } from "../features/shared/metrics/actions";

let userAlias;
// const betaAccessGroups = [
//     "amazon-meetings-testers",
//     "amazon-meetings-interest",
// ];

const TOKEN_EXPIRY_CHECK_INTERVAL_MILLIS = 5 * 60 * 1000;
const TOKEN_REFRESH_BEFORE_MINUTES = 10;

export function* startup() {
    const stageConfig = yield getStageConfig();
    // read cookies set by upstream AuthN lambda
    const { idToken, accessToken, refreshToken } = getJwtIdTokens(stageConfig.distributionConfig.UserPoolAppId);
    // decode JWT to read identity information
    // read alias from userId property of 0th identity in array, and rename sub to identifier
    // a user should only have 1 identity here
    // 'userId' should always equate to alias
    const { identities: [{ userId: alias }], exp } = jwt.decode(idToken, {complete: true}).payload;
    userAlias = alias;
    yield put({
        type: IdentityActions.SET_CURRENT_USER,
        user: {loginUser: userAlias}
    })

    console.log(`User Alias from jwt ${alias}, expiration time is ${new Date(exp * 1000)}`);
    yield put({
        type: IdentityActions.UPDATE_IDENTITY_CREDENTIALS,
        idToken,
        accessToken,
        refreshToken,
        idTokenExpiration: (exp * 1000)
    });

    yield call(getSigningCredentials, idToken);

    const stage = stageConfig.stage;
    const region = getRegion(stageConfig.edgeLocation).region;
    yield put({
        type: IdentityActions.UPDATE_STAGE_CONFIG,
        stage,
        region,
    });

    // Initialize the metric tracker
    const metricsEndpoint = ENDPOINTS[stage][region] || ENDPOINTS[stage]["PDX"];
    panoramaInit(metricsEndpoint + "api");

    // Client side error telemetry
    clientSideErrorTelemetryInit(JSON.stringify({ alias: userAlias }), idToken);
}

// TODO: cleanup unauthorized code
// export function* isAuthorizedUser() {
//     // Verify user is part of the Beta test groups
//     if (yield checkGroupMembership(userAlias, betaAccessGroups, true)) {
//         return;
//     }
//
//     // Set a value in the state and check it in the main router to always redirect to unauthorized
//     yield put(setUnauthorizedUser());
//     yield put(push("/unauthorized"));
// }

export function* refreshIdentityTokens() {
    yield delay(TOKEN_EXPIRY_CHECK_INTERVAL_MILLIS);

    const identityTokens = yield select(getIdentityTokens);
    if (isBefore(subMinutes(identityTokens.idTokenExpiration, TOKEN_REFRESH_BEFORE_MINUTES), new Date())) {
        const stageConfig = yield getStageConfig();
        yield call(refreshTokens, identityTokens.refreshToken, stageConfig.distributionConfig.UserPoolAppId, userAlias);
    }
    yield call(refreshIdentityTokens);
}

export function* loadMetadata() {
    yield put(getTimezones(LANGUAGES.ENGLISH));
    yield fork(loadFavorites);
    const { buildings, currentUser, preferences } = yield all({
        buildings: call(loadBuildings),
        currentUser: call(loadUser),
        preferences: call(loadPreferences),
    });

    yield fork(validateChimeUser, currentUser, preferences?.chimeEmail);
    yield fork(loadConfRooms, buildings, currentUser, preferences);
}

function* validateChimeUser(currentUser, chimeEmail) {
    if (chimeEmail?.length > 0) {
        return;
    }
    console.log("validateChimeUserByEmailApi");

    // set the primary email as the first order as the primary email will be the valid chime email for most of the users.
    const emails = [currentUser.email, ...currentUser.alternateEmails];
    const requestList = emails.map(
        (email) => ({ userEmail: email, username: currentUser.username })
    );
    for (const request of requestList) {
        const response = yield call(getUserByEmail, request);
        const userDetails = JSON.parse(response);
        if (!isErrorResponse(userDetails)) {
            yield put(saveSettingsChimeEmail(request.userEmail));
            yield put(saveGetUserByEmail(userDetails));

            // Save preference API will read the user's existing data first from ddb then compare the existing data with the request payload.
            // And only update the fields that has been modified instead of overriding the user's existing data.
            yield put(savePreferencesAysnc(userAlias, {chimeEmail: request.userEmail}, false));
            yield put(postNoValidChimeEmailFoundMetric(currentUser.username, emails, request.userEmail));
            return;
        }
    }

    const toast = getErrorToast("No valid chime email found on your account!");
    yield put(showToast(toast));

    yield put(postNoValidChimeEmailFoundMetric(currentUser.username, emails));
}

function* loadUser() {
    // Get current user and their exchange preferences
    const getPersonResponse = yield call(getPerson, userAlias);
    const currentUser = JSON.parse(getPersonResponse);
    if (isErrorResponse(currentUser)) {
        const toast = getErrorToast(`An error occurred when retrieving data for user ${userAlias}`);
        yield put(showToast(toast));
        return;
    }

    currentUser.email = currentUser.email?.toLowerCase();
    currentUser.alternateEmails = currentUser.alternateEmails?.map((email) => email.toLowerCase()) || [];
    if (currentUser.alternateEmails && currentUser.email?.split("@")[0] !== userAlias) {
        const emailWithAlias = currentUser.alternateEmails.find((email) => email.split("@")[0] === userAlias);
        if (emailWithAlias) {
            const indexToRemove = currentUser.alternateEmails.indexOf(emailWithAlias);
            currentUser.alternateEmails.splice(indexToRemove, 1);
            currentUser.alternateEmails.unshift(currentUser.email);
            currentUser.primaryEmail = currentUser.email;
            currentUser.email = emailWithAlias;
        }
    }

    // Only treat Cameron as a Gamma tenant user on beta website
    if (window?.location?.origin === "https://meetings-beta.amazon.com" && currentUser.email === "camei@amazon.com") {
        // Update Cameron's email to point to the Gamma Tenant
        currentUser.email = "camei@formidev.com";
    }

    yield put(setCurrentUser(currentUser));
    yield put(getExchangePreferences(currentUser.email));
    return currentUser;
}

function* loadBuildings() {
    // Get buildings
    const response = yield call(getBuildingWithQueryString, {});
    const buildings = JSON.parse(response);
    if (isErrorResponse(buildings)) {
        const toast = getErrorToast("An error occurred when retrieving buildings.");
        yield put(showToast(toast));
        return;
    }
    const buildingList = sortBuildingsByBuildingCode(buildings);
    yield put(saveBuildings(buildingList));
    return buildings;
}

function* loadConfRooms(buildings, currentUser, preferences) {
    const buildingOptions = buildings && buildings.map((building) => {
        return { value: building.buildingCode }
    });

    const { userDefaultBuilding, userDefaultFloor } = getUserDefaultLocation(currentUser && currentUser.site, buildingOptions);
    const queryList = [{ buildingCode: userDefaultBuilding }];

    let preferredBuilding = preferences?.roomBookingPreferences?.building;
    let preferredFloor = preferences?.roomBookingPreferences?.floor;

    let isPreferredBuildingValid = preferredBuilding && buildingOptions.find((building) => building.value === preferredBuilding);

    if (preferredBuilding?.length > 0 && typeof preferredFloor === "number" && isPreferredBuildingValid) {
        queryList.push({ buildingCode: preferences?.roomBookingPreferences?.building });
    } else {
        preferences.roomBookingPreferences.building = userDefaultBuilding;
        preferences.roomBookingPreferences.floor = userDefaultFloor;

        // initialize the default building & floor.
        yield put(savePreferences(preferences));
    }

    // we will need to loop through all the buildings and query them one by one rather than use yield all()
    // because if the building doesn't have the floor we won't get the request buildingCode from the response
    // thus failed to update the floorList for that building
    for (const query of queryList) {
        const response = yield call(getConfRoomWithQueryString, query);
        const roomList = JSON.parse(response);
        if (isErrorResponse(roomList)) {
            const toast = getErrorToast(`An error occurred when retrieving the rooms in your default building.`);
            yield put(showToast(toast));
        } else {
            const floorSet = new Set(roomList.map((room) => room.floor));
            const floorList = [...floorSet].sort((a, b) => a - b);
            yield put(saveFloors(floorList, roomList, query.buildingCode));
        }
    }

    // Only when finish loading the confRoom to set preferenceLoaded
    yield put(setPreferencesLoaded(true));
}

function* loadFavorites() {
    // Load user favorites if present
    let response = yield call(getUserFavorites, userAlias);
    const userPreferencesAndFavorites = (response && JSON.parse(response)) || {};
    let favorites = [];
    if (isErrorResponse(userPreferencesAndFavorites)) {
        const toast = getErrorToast("An error occurred when retrieving your favorites.");
        yield put(showToast(toast));
    } else if (userPreferencesAndFavorites.favorites) {
        try {
            // We put this parse in a try catch block in case there is a bad string stored in our preferences ddb
            favorites = JSON.parse(userPreferencesAndFavorites.favorites);
        } catch (e) {
            console.error("Error parsing favorites: ", e);
        }
    }
    yield put(saveFavorites(favorites, userAlias));
}

function* loadPreferences() {
   // Load user preferences if present
   let response = yield call(getUserPreferences, userAlias);
   const userPreferences = (response && JSON.parse(response)) || {};
   let preferences = {
        emailAndChime: {
            usePersonalChimePin: false,
            enableAutoCallParticipants: true,
            enableAutoCallRoomBooking: true,
        },
        timePreferences: {
            primaryTimezone: "",
            workingHours: {
                days: WORKING_HOURS.DAYS.slice(1, -1),
                startTime: WORKING_HOURS.START_TIME,
                endTime: WORKING_HOURS.END_TIME,
            },
            timeFormat: TIME_FORMAT.TWELVE_HOUR,
            dateFormat: DATE_FORMAT.MONTH_DAY_YEAR,
        },
        roomBookingPreferences: {
            building: undefined,
            floor: undefined,
            camera: false,
            display: false,
            noRestricted: false,
            noManaged: false,
            minimumSeats: 1,
            availableOnly: false,
        },
        layoutPreferences: {
            schedulingMode: SCHEDULING_MODE.LIST_MODE,
            calendarViewDesktop: CALENDAR_VIEW_DESKTOP.DAY,
            calendarViewMobile: CALENDAR_VIEW_MOBILE.DAY,
            // Will add this in the future
            // theme: THEME.LIGHT | THEME.DARK | THEME.CUSTOM
        },
        chimeEmail: undefined,
    };

   if (isErrorResponse(userPreferences)) {
       const toast = getErrorToast("An error occurred when retrieving your preferences.");
       yield put(showToast(toast));
   } else {
       if (userPreferences.preferences) {
           try {
                // We put this parse in a try catch block in case there is a bad string stored in our preferences ddb
                const preferencesResponse = JSON.parse(userPreferences.preferences);

                /**
                 * Hot fix section start
                 * Hot fix for the incompatible issue from roomBooking feature
                 */
                preferences.roomBookingPreferences.building = preferencesResponse?.timePreferences?.building;
                preferences.roomBookingPreferences.floor = preferencesResponse?.timePreferences?.floor;
                /**
                 * Hot fix section end
                 */

                preferences = merge(preferences, preferencesResponse);

                // Merge will append the data in array instead of override,
                // So need to explicity assing the workingHours days value to the object
                if (preferencesResponse?.timePreferences?.workingHours?.days) {
                    preferences.timePreferences.workingHours.days = preferencesResponse.timePreferences.workingHours.days.slice();
                }
           } catch (e) {
               console.error("Error parsing preferences: ", e);
           }
       }
   }

   yield put(savePreferences(preferences));

   return preferences;
}