// Executes code after waiting at least one whole animation frame. This is
// helpful when you want to allow DOM updates to settle before triggering
// something like a css transition. This returns a function that can be used to
// cancel the callback.
const waitAnimationFrame = callback => {
  let frame = requestAnimationFrame(() => {
    frame = requestAnimationFrame(callback)
  })
  return () => cancelAnimationFrame(frame)
}

export default waitAnimationFrame
