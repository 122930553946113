import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { getCurrentMeeting } from "../../../sagas/selector";

import addMonths from "date-fns/addMonths"
import addDays from "date-fns/addDays";
import differenceInMinutes from "date-fns/differenceInMinutes";
import format from "date-fns/format";
import { parse, isValid, isAfter } from "date-fns";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";

import MeetingNavigationBanner from "./meeting-navigation-banner";
import StepAttendeesAndRooms from "./step-attendees-and-rooms";
import StepSmartSuggestions from "./step-smart-suggestions";
import StepMeetingAgendaAndReview from "./step-meeting-agenda-and-review";
import PermalinkButton from "../../shared/permalink/components/permalink-button";

import HeaderBackground from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";

import { formatWorkHoursTime } from "../../shared/exchange-preferences/exchange-preferences-utils";
import { TIME_FORMAT, RECURRENCE_TYPE, TIME_CONSTANT } from "../../shared/shared-constants";
import { renderDurationLabel } from "../../shared/time-utils";
import { FLOW_TYPE, DURATION, STEP_LABEL, SUGGESTION_VIEW_TYPE, STEP } from "../meeting-scheduler-constants";
import { NAVIGATION_TYPE, STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { getMeetingDaysLabel, getDuration, getNextDayOfWeek } from "../meeting-scheduler-utils";
import { setSuggestionViewType } from "../actions";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { FAILED_METRIC_PARAMETERS, PERMALINK_SOURCE } from "../../shared/permalink/permalink-constants";
import { getUrlParameters } from "../../shared/permalink/permalink-utils";
import { postCopyToNewCreateMeetingMetrics, postQueryParamsLinkMetric } from "../../shared/metrics/actions";

const MeetingForm = (props) => {
    const flowType = props.flowType;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const mastheadSize = props.mastheadSize;
    const identity = props.identity;
    const meeting = props.meeting;
    const meetingList = props.meetingList;
    const meetingDetails = props.meetingDetails;
    const loadExistingMeeting = props.loadExistingMeeting;
    const addChimePin = props.addChimePin;
    const currentStep = props.currentStep;
    const masterState = props.masterState;
    const attendees = props.attendees;
    const findRooms = props.findRooms;
    const buildings = props.buildings;
    const rooms = props.rooms;
    const locations = props.locations;
    const favorites = props.favorites;
    const timeFormat = props.timeFormat;
    const dateFormat = props.dateFormat;
    const userSettingsBuilding = props.userSettingsBuilding;
    const userSettingsFloor = props.userSettingsFloor;
    const peopleSuggestions = props.peopleSuggestions &&
        props.peopleSuggestions.filter((person) => person.basicInfo && person.basicInfo.login &&
            !attendees.some((attendee) => attendee.alias === person.basicInfo.login.toLowerCase()));
    const groupSuggestions = props.groupSuggestions &&
        props.groupSuggestions.filter((group) => !attendees.some((attendee) => attendee.alias === group));
    const meetingSuggestions = props.meetingSuggestions;
    const timezones = props.timezones;
    const primaryTimezone = props.primaryTimezone;
    const [timezoneValue, setTimezoneValue] = [props.timezoneValue, props.setTimezoneValue];
    const workHours = props.workHours;
    // Redux state variable to track if we are loading a meeting stored in the meetingDetails redux state
    // Will be true when moving to the standard workflow from a meeting details modal to edit or copy to new
    const loadMeeting = props.loadMeeting;
    const meetingRecurrencePattern = props.meetingRecurrencePattern;
    const queryParams = props.queryParams;
    const invalidUserEmail = props.invalidUserEmail;
    const onUpdateCurrentMeeting = props.onUpdateCurrentMeeting;
    const onGetMeetingRecurrencePatternAndSave = props.onGetMeetingRecurrencePatternAndSave;
    const [gridAttendeeAvailability, setGridAttendeeAvailability] = useState({});
    const dispatch = useDispatch();
    const [freeAttendeesCount, setFreeAttendeesCount] = useState(0);

    // Presets for the date range picker
    const formatIso = (date) => format(date, "yyyy-MM-dd");
    const today = formatIso(new Date());
    const tomorrow = formatIso(addDays(new Date(), +1));
    const nextWeekSunday = formatIso(getNextDayOfWeek(new Date(), 0));
    const nextWeekSaturday = formatIso(getNextDayOfWeek(addDays(new Date(), +7), 6));
    const weekFromNow = formatIso(addDays(new Date(), +7));
    const twoWeeksFromNow = formatIso(addDays(new Date(), +14));
    const monthFromNow = formatIso(addMonths(new Date(), +1));
    const sixMonthsFromNow = formatIso(addMonths(new Date(), +6));

    /* Step smart suggestions states */
    const [isFirstSearch, setIsFirstSearch] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isAttendeesChanged, setIsAttendeesChanged] = useState(false);
    const [isLocationsChanged, setIsLocationsChanged] = useState(false);
    const [isRepeatChanged, setIsRepeatChanged] = useState(false);
    const [prevAttendees, setPrevAttendees] = useState(attendees);
    const [prevRooms, setPrevRooms] = useState(rooms);
    const [prevLocations, setPrevLocations] = useState(locations);
    const [meetingSuggestionSelected, setMeetingSuggestionSelected] = useState();
    const [meetingSuggestionsDetail, setMeetingSuggestionsDetail] = useState({});
    const [sortOption, setSortOption] = useState("Availability");
    const [alerts, setAlerts] = useState([]);
    const [isExistingMeetingSelected, setIsExistingMeetingSelected] = useState();
    const [existingMeetingDetail, setExistingMeetingDetail] = useState({});
    const [changedTimeWindow, setChangedTimeWindow] = useState(); // current grid window position
    // Whether we are using an existing meeting to load the defaults for our suggestion search inputs
    const [loadSearchCriteriaFromMeeting, setLoadSearchCriteriaFromMeeting] = useState(flowType === FLOW_TYPE.UPDATE || loadMeeting);
    const [copiedToNew, setCopiedToNew] = useState(false);

    // Meeting duration
    const [durationSelected, setDurationSelected] = useState(DURATION.THIRTY_MINUTES);
    const [customDurationValue, setCustomDurationValue] = useState(DURATION.THIRTY_MINUTES);
    const [customDurationSelected, setCustomDurationSelected] = useState("min");
    const [customDurationLabel, setCustomDurationLabel] = useState("30 mins");
    const [customDurationError, setCustomDurationError] = useState(false);
    const [isAllDayEvent, setIsAllDayEvent] = useState(false);
    const [prevDuration, setPrevDuration] = useState(getDuration(durationSelected, customDurationValue, customDurationSelected));

    // Meeting recurrence
    const [repeatSelected, setRepeatSelected] = useState("none");
    const [repeatDays, setRepeatDays] = useState(["Mon", "Tue", "Wed", "Thu", "Fri"]);
    const [customRepeatLabel, setCustomRepeatLabel] = useState("");
    const [customRepeatInterval, setCustomRepeatInterval] = useState(1);
    const [customRepeatPeriod, setCustomRepeatPeriod] = useState("week");
    const [customRepeatDays, setCustomRepeatDays] = useState([new Date().toDateString().split(" ")[0]]); // current day of week
    const [customRepeatMonthSelected, setCustomRepeatMonthSelected] = useState("day");
    const [customRepeatMonthDay, setCustomRepeatMonthDay] = useState(new Date().getDate()); // current date
    const [customRepeatMonthDayOfWeek, setCustomRepeatMonthDayOfWeek] = useState(new Date().toDateString().split(" ")[0]); // current day of week
    const [customRepeatEndSelected, setCustomRepeatEndSelected] = useState("by");
    const [customRepeatEndDate, setCustomRepeatEndDate] = useState(sixMonthsFromNow);
    const [customRepeatOccurrences, setCustomRepeatOccurrences] = useState(10);
    const [customRepeatError, setCustomRepeatError] = useState(false);
    const [prevCustomRepeatDays, setPrevCustomRepeatDays] = useState(customRepeatDays);
    const [repeatToastId, setRepeatToastId] = useState();
    const [checkRecurrenceToast, setCheckRecurrenceToast] = useState(true);
    const [repeatOpen, setRepeatOpen] = useState(false);
    const [recurrenceCopied, setRecurrenceCopied] = useState(false);
    // Whether we need to load the master meeting's recurrence pattern into the redux state
    const [loadRecurrenceFromMaster, setLoadRecurrenceFromMaster] = useState(false);

    // Meeting navigation buttons loader
    const [isLastStepLoading, setIsLastStepLoading] = useState(false); // disable send invites button

    const closeRecurrenceToast = () => {
        props.onHideToast(repeatToastId); // hide previously opened toast
        setRepeatToastId(); // clear toast id
    };

    const presets = [
        { label: "Today", value: [today, today] },
        { label: "Tomorrow", value: [tomorrow, tomorrow] },
        { label: "Next week", value: [nextWeekSunday, nextWeekSaturday] },
        { label: "Within a week", value: [today, weekFromNow] },
        { label: "Within two weeks", value: [today, twoWeeksFromNow] },
        { label: "Within a month", value: [today, monthFromNow] },
    ];

    const currentYear = new Date().getFullYear().toString();

    // Meeting date, time, and timezone
    const [dateRangeValue, setDateRangeValue] = useState([today, weekFromNow]);
    const [dateRangeLabel, setDateRangeLabel] = useState("8 days");

    const [startTime, setStartTime] = useState(undefined); // Initialized in useEffect
    const [endTime, setEndTime] = useState(undefined); // Initialized in useEffect
    const [timeLabel, setTimeLabel] = useState(""); // Initialized in useEffect

    const [isQueryParamsCheck, setIsQueryParamsCheck] = useState(true);

    // Meeting days of week
    const [daysOfWeek, setDaysOfWeek] = useState(["Mon", "Tue", "Wed", "Thu", "Fri"]);
    const [daysOfWeekLabel, setDaysOfWeekLabel] = useState("Only on Mon - Fri");

    // Current room selection info
    const [currentSuggestion, setCurrentSuggestion] = useState();
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
    const [currentRoomListIndex, setCurrentRoomListIndex] = useState(0);
    const [selectedRooms, setSelectedRooms] = useState({});
    const [selectedRoom, setSelectedRoom] = useState();

    /* Step meeting agenda states */
    const [isSubjectEmpty, setIsSubjectEmpty] = useState(false);
    const [copyToNewChimeHandled, setCopyToNewChimeHandled] = useState(false);
    const [customLocation, setCustomLocation] = useState();

    // Previous getSchedule query to avoid sending duplicate requests
    const [prevGetScheduleQuery, setPrevGetScheduleQuery] = useState();

    // Alerts shown on the review step
    const [reviewAlerts, setReviewAlerts] = useState([]);

    // queryParameters for generation of PermaLink
    const [queryParameters, setQueryParameters] = useState({});

    const getPageTitle = () => {
        if (flowType === FLOW_TYPE.UPDATE) {
            return "Standard meeting (Edit)";
        }

        return "Standard meeting";
    };

    // Load a recurrence pattern from a meeting
    const loadRecurrence = useCallback((recurrence) => {
        if (!recurrence || !recurrence.hasOwnProperty("__type")) {
            return;
        }

        // load recurrence metadata for series or new meeting
        setCustomRepeatInterval(recurrence.interval);

        if (recurrence.hasOwnProperty("endByDate")) {
            setCustomRepeatEndSelected("by");
            setCustomRepeatEndDate(formatIso(new Date(recurrence.endByDate * 1000)));
        } else if (recurrence.hasOwnProperty("endAfterNOccurrences")) {
            setCustomRepeatEndSelected("after");
            setCustomRepeatOccurrences(recurrence.endAfterNOccurrences);
        } else if (recurrence.neverEnding) {
            setCustomRepeatEndSelected("none");
        }

        let repeatType = recurrence.__type;

        if (repeatType === RECURRENCE_TYPE.DAILY) {
            setRepeatSelected("day");
            setCustomRepeatPeriod("day");
            setRepeatDays(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
            setDaysOfWeekLabel("First meeting on Sun - Sat");
        } else if (repeatType === RECURRENCE_TYPE.WEEKLY) {
            setRepeatSelected("week");
            setCustomRepeatPeriod("week");

            let daysOfWeek = [];
            // get an array for the days of week the meeting repeats on
            for (const [key] of Object.entries(recurrence.daysOfWeek)) {
                daysOfWeek.push(key.charAt(0).toUpperCase() + key.slice(1, 3));
            };

            setRepeatDays(daysOfWeek);
            setDaysOfWeek(daysOfWeek);
            setCustomRepeatDays(daysOfWeek);
            getMeetingDaysLabel(daysOfWeek, repeatSelected, setDaysOfWeekLabel);
        } else if (repeatType === RECURRENCE_TYPE.ABSOLUTE_MONTHLY) {
            setRepeatSelected("month");
            setCustomRepeatPeriod("month");
            setCustomRepeatMonthDay(recurrence.dayOfMonth);
            setRepeatDays(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
            setDaysOfWeekLabel("First meeting on Sun - Sat");
        } else if (repeatType === RECURRENCE_TYPE.RELATIVE_MONTHLY) {
            setRepeatSelected("month");

            let dayOfWeek = recurrence.dayOfWeek;

            setRepeatDays(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);

            if (dayOfWeek === "day") {
                getMeetingDaysLabel(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], repeatSelected, setDaysOfWeekLabel);
            } else if (dayOfWeek === "weekday") {
                getMeetingDaysLabel(["Mon", "Tue", "Wed", "Thu", "Fri"], repeatSelected, setDaysOfWeekLabel);
            } else if (dayOfWeek === "weekend") {
                getMeetingDaysLabel(["Sun", "Sat"], repeatSelected, setDaysOfWeekLabel);
            } else {
                dayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1, 3);
                setRepeatDays([dayOfWeek]);
                getMeetingDaysLabel([dayOfWeek], repeatSelected, setDaysOfWeekLabel);
            }

            setCustomRepeatPeriod("month");
            setCustomRepeatMonthSelected(recurrence.weekOfMonth);
            setCustomRepeatMonthDayOfWeek(dayOfWeek);
        }

        setRecurrenceCopied(true);
    }, [repeatSelected]);

    const sendMetricsAndCreateMeeting = (meeting) => {
        // publish metric
        dispatch(postCopyToNewCreateMeetingMetrics(copiedToNew, meetingDetails.organizer === identity.email, recurrenceCopied, repeatSelected !== "none"));
        props.onCreateMeeting(meeting);
    };

    useEffect(() => {
        if (workHours) {
            setDaysOfWeek(workHours.days);
            getMeetingDaysLabel(workHours.days, repeatSelected, setDaysOfWeekLabel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workHours])

    // useEffect for On query params load for all the Query Parameters of Smart Meeting Page
    useEffect(() => {
        if (isQueryParamsCheck && invalidUserEmail !== undefined && identity && identity.username &&
            (queryParams.timezone === undefined || (timezones.length > 0 && timezoneValue !== undefined))) {
            let invalidParameters = [];
            let queryParamsSource = PERMALINK_SOURCE.THIRD_PARTY;
            if (queryParams.permalinkButton) {
                queryParamsSource = PERMALINK_SOURCE.PERMALINK_BUTTON;
            } else if (queryParams.outlookPlugin) {
                queryParamsSource = PERMALINK_SOURCE.OUTLOOK_PLUGIN;
            }
            if (invalidUserEmail) {
                invalidParameters.push(FAILED_METRIC_PARAMETERS.PARTICIPANTS_BY_PRIORITY);
            }
            // Load meeting Start and End times
            if (queryParams.startTime !== undefined && queryParams.endTime !== undefined) {
                if (isValid(parse(queryParams.startTime, "HH:mm:ss", new Date())) &&
                    isValid(parse(queryParams.endTime, "HH:mm:ss", new Date()))) {
                    let queryStartTimeInMinutes = parseInt(queryParams.startTime) * 60 + parseInt(queryParams.startTime.substr(queryParams.startTime.indexOf(":") + 1, 2));
                    let queryEndTimeInMinutes = parseInt(queryParams.endTime) * 60 + parseInt(queryParams.endTime.substr(queryParams.endTime.indexOf(":") + 1, 2));
                    setStartTime(queryParams.startTime);
                    setEndTime(queryParams.endTime);
                    setTimeLabel(renderDurationLabel(queryStartTimeInMinutes, queryEndTimeInMinutes, TIME_CONSTANT.MIN_NAME));
                } else {
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.START_END_TIME);
                }
            }

            // Load meeting duration
            const durationValues = [15, 30, 45, 60, 90, 120, 180];
            if (queryParams.durationMinutes) {
                if (!isNaN(queryParams.durationMinutes)) {
                    if (durationValues.includes(parseInt(queryParams.durationMinutes))) {
                        setDurationSelected(parseInt(queryParams.durationMinutes));
                    } else {
                        setDurationSelected("custom");
                        setCustomDurationValue(parseInt(queryParams.durationMinutes));
                        setCustomDurationSelected("min");
                        setCustomDurationError(parseInt(queryParams.durationMinutes) > DURATION.MAX_MINUTES);
                    }
                } else {
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.DURATION_MINUTES);
                }
            }

            // Load meeting Start and End Dates and Date Range Label
            if (queryParams.startDate !== undefined && queryParams.endDate !== undefined) {
                if (!isAfter(new Date(queryParams.startDate), new Date(queryParams.endDate)) &&
                    isValid(parse(queryParams.startDate, "yyyy-MM-dd", new Date())) &&
                    isValid(parse(queryParams.startDate, "yyyy-MM-dd", new Date()))) {
                    setDateRangeValue([formatIso(addDays(new Date(queryParams.startDate), +1)),
                        formatIso(addDays(new Date(queryParams.endDate), +1))]);
                } else {
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.START_END_DATE);
                }
            }

            // Load meeting suggestion view type
            if (queryParams.suggestionViewType !== undefined) {
                if (queryParams.suggestionViewType === SUGGESTION_VIEW_TYPE.GRID || queryParams.suggestionViewType === SUGGESTION_VIEW_TYPE.LIST) {
                    dispatch(setSuggestionViewType(queryParams.suggestionViewType));
                } else {
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.SUGGESTION_VIEW);
                }
            }

            // Load meeting subject from Query Params
            if (queryParams.subject !== undefined) {
                onUpdateCurrentMeeting({subject: queryParams.subject});
            }

            // Load timezone from permalink
            if (queryParams.timezone !== undefined) {
                const timezone = timezones.find((timezone) => timezone.id === decodeURIComponent(queryParams.timezone));
                if (timezone === undefined) {
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.TIMEZONE);
                } else {
                    setTimezoneValue(timezone.id);
                }
            }

            // get an array for the days of week the meeting is on
            let daysOfWeek = [];
            const daysOfWeekValidation = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            if (queryParams.filterWeekDays !== undefined) {
                let filterWeekDays = queryParams.filterWeekDays.split(",");
                if (filterWeekDays.length !== 0) {
                    daysOfWeek = filterWeekDays.map((dayOfWeek) => dayOfWeek.slice(0, 3));
                    daysOfWeek = daysOfWeek.filter((dayOfWeek) => daysOfWeekValidation.includes(dayOfWeek));
                    if (daysOfWeek.length < filterWeekDays.length) {
                        invalidParameters.push(FAILED_METRIC_PARAMETERS.FILTER_WEEK_DAYS);
                    }
                    if (daysOfWeek.length > 0) {
                        setDaysOfWeek(daysOfWeek);
                        getMeetingDaysLabel(daysOfWeek, "none", setDaysOfWeekLabel);
                    }
                }
            }

            if (window.location.href.includes("?") && Object.keys(getUrlParameters(WORKFLOWS.STANDARD_MEETING.HREF)).length) {
                dispatch(postQueryParamsLinkMetric(invalidParameters, queryParamsSource));
            }

            setIsQueryParamsCheck(false);
        }
    }, [identity, onUpdateCurrentMeeting, dispatch, isQueryParamsCheck, queryParams, dateRangeValue, invalidUserEmail, timezones, timezoneValue, setTimezoneValue]);

    // Load in work hours to start and end time filters
    useEffect(() => {
        if (workHours.startTime !== undefined && workHours.endTime !== undefined) {
            setStartTime(formatWorkHoursTime(workHours.startTime, TIME_FORMAT.HH_MM_SS));
            setEndTime(formatWorkHoursTime(workHours.endTime, TIME_FORMAT.HH_MM_SS));
            setTimeLabel(renderDurationLabel(workHours.startTime, workHours.endTime, TIME_CONSTANT.MIN_NAME));
        }
    }, [workHours]);

    // Load in search criteria when editing a meeting or copying an old meeting to a new one
    useEffect(() => {
        if (identity && identity.username && meetingDetails.hasOwnProperty("time") && loadSearchCriteriaFromMeeting) {
            if (flowType === FLOW_TYPE.UPDATE) {
                setIsExistingMeetingSelected(true);
            } else if (flowType === FLOW_TYPE.CREATE) {
                setCopiedToNew(true);
            }

            const startTime = new Date(meetingDetails.time.startTime * TIME_CONSTANT.ONE_SEC_IN_MS);
            const endTime = new Date(meetingDetails.time.endTime * TIME_CONSTANT.ONE_SEC_IN_MS);

            const durationValues = [15, 30, 45, 60, 90, 120, 180];
            const meetingDuration = differenceInMinutes(endTime, startTime);

            // Load meeting duration
            if (meetingDetails.isAllDayEvent) {
                setDurationSelected("custom");
                let numDays = Math.ceil(meetingDuration / TIME_CONSTANT.ONE_DAY_IN_MIN)
                setCustomDurationValue(numDays);
                setCustomDurationSelected("day");
                setCustomDurationLabel(numDays + (numDays > 1 ? " days" : " day"));
                setCustomDurationError(meetingDuration > DURATION.MAX_MINUTES);
                setIsAllDayEvent(true);
            } else if (durationValues.includes(meetingDuration)) {
                setDurationSelected(meetingDuration);
            } else {
                setDurationSelected("custom");
                setCustomDurationValue(meetingDuration);
                setCustomDurationSelected("min");
                setCustomDurationLabel(meetingDuration + (meetingDuration > 1 ? " mins" : " min"));
                setCustomDurationError(meetingDuration > DURATION.MAX_MINUTES);
            }

            // Load recurrence details if present
            if (meetingDetails.recurrence && meetingDetails.recurrence.hasOwnProperty("__type")) {
                if (flowType === FLOW_TYPE.UPDATE && !masterState) {
                    // If updating an occurrence, set time window based on occurrence time
                    let startDate = formatIso(startTime);
                    setDateRangeValue([startDate, formatIso(addDays(new Date(startDate), +7))]);
                } else {
                    loadRecurrence(meetingDetails.recurrence);
                    // If we are creating a new meeting with a copied recurrence, open the recurrence modal
                    if (flowType === FLOW_TYPE.CREATE) {
                        setRepeatOpen(true);
                    }
                }
            // If we are creating a new meeting by copying an occurrence,
            // get the recurrence pattern from the master meeting
            } else if (flowType === FLOW_TYPE.CREATE && meetingDetails.isRecurring) {
                dispatch(onGetMeetingRecurrencePatternAndSave(meetingDetails.entryID, identity.email));
                setLoadRecurrenceFromMaster(true);
            }

            // Load timezone
            setTimezoneValue(meetingDetails.startTimezone.id);

            // Load meeting only once
            setLoadSearchCriteriaFromMeeting(false);
        }
    }, [identity, flowType, meetingDetails, loadMeeting, masterState, repeatSelected, isExistingMeetingSelected, setIsExistingMeetingSelected, dispatch, setTimezoneValue, loadSearchCriteriaFromMeeting, loadRecurrenceFromMaster, onGetMeetingRecurrencePatternAndSave, loadRecurrence]);

    useEffect(() => {
        if (loadRecurrenceFromMaster && meetingRecurrencePattern) {
            loadRecurrence(meetingRecurrencePattern);
            setLoadRecurrenceFromMaster(false);
            setRepeatOpen(true);
        }
    }, [loadRecurrenceFromMaster, meetingRecurrencePattern, loadRecurrence]);

    const header = useRef();

    // Begin new workflow content with focus on the <h1> heading
    useEffect(() => {
        if (header.current) {
            header.current.focus();
        }
    }, []);

    return (
        <Column height="100%" spacing="none">
            <div style={{"paddingBottom": STEP_NAVIGATION_FOOTER.HEIGHT}}>
                <Column
                    height="100%"
                    minHeight={`calc(100vh - ${mastheadSize} - ${STEP_NAVIGATION_FOOTER.HEIGHT})`} // 100vh - mastheadSize - footer
                    heights={["fit", "fit", "fill"]}
                    spacing="none"
                >
                    <div style={{
                        "backgroundImage": `url(${HeaderBackground})`,
                        "backgroundRepeat": "no-repeat",
                        "backgroundColor": "rgba(26, 69, 78)",
                        "backgroundPosition": "150px -90px",
                        "height": "50px",
                        "width": "100%",
                    }}>
                        <Row height={"100%"} alignmentVertical={"center"} spacingInset={"medium"} widths={["fill", "fit"]}>
                            <Heading color="inverted" level={1} type={"h300"} ref={header} tabIndex="-1">{getPageTitle()}</Heading>
                            <PermalinkButton
                                path={WORKFLOWS.STANDARD_MEETING.HREF}
                                queryParameters={queryParameters}
                            />
                        </Row>
                    </div>
                    <MeetingNavigationBanner
                        navigationType={NAVIGATION_TYPE.HEADER}
                        flowType={flowType}
                        identity={identity}
                        meeting={meeting}
                        meetingList={meetingList}
                        meetingDetails={meetingDetails}
                        currentStep={currentStep}
                        masterState={masterState}
                        onChangeStep={props.onChangeStep}
                        onCreateMeeting={sendMetricsAndCreateMeeting}
                        steps={[
                            STEP.ATTENDEES_AND_ROOMS,
                            STEP.SMART_SUGGESTIONS,
                            STEP.MEETING_AGENDA_AND_REVIEW,
                        ]}
                        labels={[
                            STEP_LABEL.ATTENDEES_AND_ROOMS,
                            STEP_LABEL.SMART_SUGGESTIONS,
                            STEP_LABEL.MEETING_AGENDA_AND_REVIEW,
                        ]}
                        setIsSubjectEmpty={setIsSubjectEmpty}
                        reviewAlerts={reviewAlerts}
                        setReviewAlerts={setReviewAlerts}
                        isSuggestionSelected={meetingSuggestions?.length && meetingSuggestionSelected !== undefined}
                        isSearchingMeetingSuggestions={props.isSearchingMeetingSuggestions}
                        isAttendeeLoading={attendees.filter((attendee) => !attendee.email).length > 0}
                        isLastStepLoading={isLastStepLoading}
                        setIsLastStepLoading={setIsLastStepLoading}
                    />
                    {currentStep === STEP.ATTENDEES_AND_ROOMS &&
                        <StepAttendeesAndRooms
                            flowType={flowType}
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            identity={identity}
                            attendees={attendees}
                            findRooms={findRooms}
                            buildings={buildings}
                            rooms={rooms}
                            locations={locations}
                            peopleSuggestions={peopleSuggestions}
                            groupSuggestions={groupSuggestions}
                            userSettingsBuilding={userSettingsBuilding}
                            userSettingsFloor={userSettingsFloor}
                            userSettingsMinimumSeats={props.userSettingsMinimumSeats}
                            userSettingsCamera={props.userSettingsCamera}
                            userSettingsDisplay={props.userSettingsDisplay}
                            userSettingsNoRestricted={props.userSettingsNoRestricted}
                            userSettingsNoManaged={props.userSettingsNoManaged}
                            favorites={favorites}
                            queryParameters={queryParameters}
                            setQueryParameters={setQueryParameters}
                            onAddAttendee={props.onAddAttendee}
                            onUpdateAttendee={props.onUpdateAttendee}
                            onRemoveAttendee={props.onRemoveAttendee}
                            onLoadFloors={props.onLoadFloors}
                            onUpdateFindRooms={props.onUpdateFindRooms}
                            onAddRoom={props.onAddRoom}
                            onRemoveRoom={props.onRemoveRoom}
                            onAddLocation={props.onAddLocation}
                            onRemoveLocation={props.onRemoveLocation}
                            onAddRoomByEmail={props.onAddRoomByEmail}
                            onGetRASDataForPerson={props.onGetRASDataForPerson}
                            onGetRASDataForGroup={props.onGetRASDataForGroup}
                            onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                            onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                            onGetGroupSuggestions={props.onGetGroupSuggestions}
                            onClearGroupSuggestions={props.onClearGroupSuggestions}
                            onAddFavorite={props.onAddFavorite}
                            onRemoveFavorite={props.onRemoveFavorite}
                        />
                    }
                    {currentStep === STEP.SMART_SUGGESTIONS &&
                        <StepSmartSuggestions
                            flowType={flowType}
                            currentStep={currentStep}
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            mastheadSize={mastheadSize}
                            identity={identity}
                            meeting={meeting}
                            meetingDetails={meetingDetails}
                            masterState={masterState}
                            loadExistingMeeting={loadExistingMeeting}
                            attendees={attendees}
                            isAttendeesChanged={isAttendeesChanged}
                            setIsAttendeesChanged={setIsAttendeesChanged}
                            prevAttendees={prevAttendees}
                            setPrevAttendees={setPrevAttendees}
                            setGridAttendeeAvailability={setGridAttendeeAvailability}
                            freeAttendeesCount={freeAttendeesCount}
                            setFreeAttendeesCount={setFreeAttendeesCount}
                            findRooms={findRooms}
                            rooms={rooms}
                            locations={locations}
                            meetingSuggestions={meetingSuggestions}
                            queryParameters={queryParameters}
                            setQueryParameters={setQueryParameters}
                            onUpdateCurrentMeeting={onUpdateCurrentMeeting}
                            isSearchingMeetingSuggestions={props.isSearchingMeetingSuggestions}
                            onGetMeetingSuggestions={props.onGetMeetingSuggestions}
                            onSearchSuggestion={props.onSearchSuggestion}
                            onSelectSuggestion={props.onSelectSuggestion}
                            suggestionViewType={props.suggestionViewType}
                            onSetSuggestionViewType={props.onSetSuggestionViewType}
                            onUpdateRecurrence={props.onUpdateRecurrence}
                            primaryTimezone={primaryTimezone}
                            timezones={timezones}
                            isFirstSearch={isFirstSearch}
                            setIsFirstSearch={setIsFirstSearch}
                            isInitialLoad={isInitialLoad}
                            setIsInitialLoad={setIsInitialLoad}
                            isLocationsChanged={isLocationsChanged}
                            setIsLocationsChanged={setIsLocationsChanged}
                            isRepeatChanged={isRepeatChanged}
                            setIsRepeatChanged={setIsRepeatChanged}
                            prevRooms={prevRooms}
                            setPrevRooms={setPrevRooms}
                            prevLocations={prevLocations}
                            setPrevLocations={setPrevLocations}
                            meetingSuggestionSelected={meetingSuggestionSelected}
                            setMeetingSuggestionSelected={setMeetingSuggestionSelected}
                            meetingSuggestionsDetail={meetingSuggestionsDetail}
                            setMeetingSuggestionsDetail={setMeetingSuggestionsDetail}
                            sortOption={sortOption}
                            setSortOption={setSortOption}
                            alerts={alerts}
                            setAlerts={setAlerts}
                            isExistingMeetingSelected={isExistingMeetingSelected}
                            setIsExistingMeetingSelected={setIsExistingMeetingSelected}
                            existingMeetingDetail={existingMeetingDetail}
                            setExistingMeetingDetail={setExistingMeetingDetail}
                            changedTimeWindow={changedTimeWindow}
                            setChangedTimeWindow={setChangedTimeWindow}
                            durationSelected={durationSelected}
                            setDurationSelected={setDurationSelected}
                            customDurationValue={customDurationValue}
                            setCustomDurationValue={setCustomDurationValue}
                            customDurationSelected={customDurationSelected}
                            setCustomDurationSelected={setCustomDurationSelected}
                            customDurationLabel={customDurationLabel}
                            setCustomDurationLabel={setCustomDurationLabel}
                            customDurationError={customDurationError}
                            setCustomDurationError={setCustomDurationError}
                            isAllDayEvent={isAllDayEvent}
                            setIsAllDayEvent={setIsAllDayEvent}
                            prevDuration={prevDuration}
                            setPrevDuration={setPrevDuration}
                            repeatSelected={repeatSelected}
                            setRepeatSelected={setRepeatSelected}
                            repeatDays={repeatDays}
                            setRepeatDays={setRepeatDays}
                            customRepeatLabel={customRepeatLabel}
                            setCustomRepeatLabel={setCustomRepeatLabel}
                            customRepeatInterval={customRepeatInterval}
                            setCustomRepeatInterval={setCustomRepeatInterval}
                            customRepeatPeriod={customRepeatPeriod}
                            setCustomRepeatPeriod={setCustomRepeatPeriod}
                            customRepeatDays={customRepeatDays}
                            setCustomRepeatDays={setCustomRepeatDays}
                            customRepeatMonthSelected={customRepeatMonthSelected}
                            setCustomRepeatMonthSelected={setCustomRepeatMonthSelected}
                            customRepeatMonthDay={customRepeatMonthDay}
                            setCustomRepeatMonthDay={setCustomRepeatMonthDay}
                            customRepeatMonthDayOfWeek={customRepeatMonthDayOfWeek}
                            setCustomRepeatMonthDayOfWeek={setCustomRepeatMonthDayOfWeek}
                            customRepeatEndSelected={customRepeatEndSelected}
                            setCustomRepeatEndSelected={setCustomRepeatEndSelected}
                            customRepeatEndDate={customRepeatEndDate}
                            setCustomRepeatEndDate={setCustomRepeatEndDate}
                            customRepeatOccurrences={customRepeatOccurrences}
                            setCustomRepeatOccurrences={setCustomRepeatOccurrences}
                            customRepeatError={customRepeatError}
                            setCustomRepeatError={setCustomRepeatError}
                            prevCustomRepeatDays={prevCustomRepeatDays}
                            setPrevCustomRepeatDays={setPrevCustomRepeatDays}
                            repeatToastId={repeatToastId}
                            setRepeatToastId={setRepeatToastId}
                            checkRecurrenceToast={checkRecurrenceToast}
                            setCheckRecurrenceToast={setCheckRecurrenceToast}
                            presets={presets}
                            currentYear={currentYear}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}
                            dateRangeLabel={dateRangeLabel}
                            setDateRangeLabel={setDateRangeLabel}
                            startTime={startTime}
                            setStartTime={setStartTime}
                            endTime={endTime}
                            setEndTime={setEndTime}
                            timeLabel={timeLabel}
                            setTimeLabel={setTimeLabel}
                            timezoneValue={timezoneValue}
                            setTimezoneValue={setTimezoneValue}
                            daysOfWeek={daysOfWeek}
                            setDaysOfWeek={setDaysOfWeek}
                            daysOfWeekLabel={daysOfWeekLabel}
                            setDaysOfWeekLabel={setDaysOfWeekLabel}
                            currentSuggestion={currentSuggestion}
                            setCurrentSuggestion={setCurrentSuggestion}
                            currentSuggestionIndex={currentSuggestionIndex}
                            setCurrentSuggestionIndex={setCurrentSuggestionIndex}
                            currentRoomListIndex={currentRoomListIndex}
                            setCurrentRoomListIndex={setCurrentRoomListIndex}
                            selectedRooms={selectedRooms}
                            setSelectedRooms={setSelectedRooms}
                            selectedRoom={selectedRoom}
                            setSelectedRoom={setSelectedRoom}
                            closeRecurrenceToast={closeRecurrenceToast}
                            onShowToast={props.onShowToast}
                            onChangeStep={props.onChangeStep}
                            prevGetScheduleQuery={prevGetScheduleQuery}
                            setPrevGetScheduleQuery={setPrevGetScheduleQuery}
                            repeatOpen={repeatOpen}
                            setRepeatOpen={setRepeatOpen}
                            timeFormat={timeFormat}
                            dateFormat={dateFormat}
                            schedulingMode={props.schedulingMode}
                        />
                    }
                    {currentStep === STEP.MEETING_AGENDA_AND_REVIEW &&
                        <StepMeetingAgendaAndReview
                            suggestionViewType={props.suggestionViewType}
                            flowType={flowType}
                            currentStep={currentStep}
                            onChangeStep={props.onChangeStep}
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            identity={identity}
                            meeting={meeting}
                            timezones={timezones}
                            timezoneValue={timezoneValue}
                            setTimezoneValue={setTimezoneValue}
                            getMeeting={getCurrentMeeting}
                            meetingSuggestions={meetingSuggestions}
                            meetingSuggestionSelected={meetingSuggestionSelected}
                            meetingSuggestionsDetail={meetingSuggestionsDetail}
                            setMeetingSuggestionsDetail={setMeetingSuggestionsDetail}
                            loadExistingMeeting={loadExistingMeeting}
                            isExistingMeetingSelected={isExistingMeetingSelected}
                            setIsExistingMeetingSelected={setIsExistingMeetingSelected}
                            existingMeetingDetail={existingMeetingDetail}
                            setExistingMeetingDetail={setExistingMeetingDetail}
                            onSelectSuggestion={props.onSelectSuggestion}
                            currentYear={currentYear}
                            addChimePin={addChimePin}
                            attendees={attendees}
                            gridAttendeeAvailability={gridAttendeeAvailability}
                            freeAttendeesCount={freeAttendeesCount}
                            rooms={rooms}
                            selectedRooms={selectedRooms}
                            setSelectedRooms={setSelectedRooms}
                            prevRooms={prevRooms}
                            setPrevRooms={setPrevRooms}
                            prevLocations={prevLocations}
                            setPrevLocations={setPrevLocations}
                            queryParameters={queryParameters}
                            setQueryParameters={setQueryParameters}
                            onUpdateCurrentMeeting={props.onUpdateCurrentMeeting}
                            onAddAttendee={props.onAddAttendee}
                            onRemoveAttendee={props.onRemoveAttendee}
                            chimePin={props.chimePin}
                            setChimePin={props.setChimePin}
                            chimeUniqueIds={props.chimeUniqueIds}
                            chimeUser={props.chimeUser}
                            autoCall={props.autoCall}
                            setAutoCall={props.setAutoCall}
                            loadChimeInLocation={prevLocations.length === 0 && prevRooms.length === 0}
                            isSubjectEmpty={isSubjectEmpty}
                            meetingDetails={meetingDetails}
                            onShowToast={props.onShowToast}
                            copiedToNew={copiedToNew}
                            copyToNewChimeHandled={copyToNewChimeHandled}
                            setCopyToNewChimeHandled={setCopyToNewChimeHandled}
                            usePersonalChimePin={props.usePersonalChimePin}
                            durationSelected={durationSelected}
                            setDurationSelected={setDurationSelected}
                            customDurationValue={customDurationValue}
                            setCustomDurationValue={setCustomDurationValue}
                            customDurationSelected={customDurationSelected}
                            setCustomDurationSelected={setCustomDurationSelected}
                            changedTimeWindow={changedTimeWindow}
                            setChangedTimeWindow={setChangedTimeWindow}
                            reviewAlerts={reviewAlerts}
                            timeFormat={timeFormat}
                            customLocation={customLocation}
                            setCustomLocation={setCustomLocation}
                        />
                    }
                </Column>
            </div>
            <MeetingNavigationBanner
                navigationType={NAVIGATION_TYPE.FOOTER}
                flowType={flowType}
                identity={identity}
                meeting={meeting}
                meetingList={meetingList}
                meetingDetails={meetingDetails}
                currentStep={currentStep}
                masterState={masterState}
                onChangeStep={props.onChangeStep}
                onCreateMeeting={sendMetricsAndCreateMeeting}
                steps={[
                    STEP.ATTENDEES_AND_ROOMS,
                    STEP.SMART_SUGGESTIONS,
                    STEP.MEETING_AGENDA_AND_REVIEW,
                ]}
                labels={[
                    STEP_LABEL.ATTENDEES_AND_ROOMS,
                    STEP_LABEL.SMART_SUGGESTIONS,
                    STEP_LABEL.MEETING_AGENDA_AND_REVIEW,
                ]}
                setIsSubjectEmpty={setIsSubjectEmpty}
                reviewAlerts={reviewAlerts}
                setReviewAlerts={setReviewAlerts}
                isSuggestionSelected={meetingSuggestions?.length && meetingSuggestionSelected !== undefined}
                isSearchingMeetingSuggestions={props.isSearchingMeetingSuggestions}
                isAttendeeLoading={attendees.filter((attendee) => !attendee.email).length > 0}
                isLastStepLoading={isLastStepLoading}
                setIsLastStepLoading={setIsLastStepLoading}
            />
        </Column>
    );
};

export default MeetingForm;
