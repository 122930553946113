import React from "react";

import Link from "@amzn/meridian/link";

const MeetingUpdatedToast = (props) => {
    return (
        <React.Fragment>
            <b>Meeting {props.master && "series"} successfully updated.</b> <Link onClick={props.openToastModal}>View Details</Link>
        </React.Fragment>
    );
};

export default MeetingUpdatedToast;