// Shamelessly lifted from https://github.com/tajo/react-portal
import React from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"

const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
)

class Portal extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    node: PropTypes.any,
  }

  componentWillUnmount() {
    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode)
    }
    this.defaultNode = null
  }

  render() {
    if (!canUseDOM) {
      return null
    }
    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement("div")
      document.body.appendChild(this.defaultNode)
    }
    return ReactDOM.createPortal(
      this.props.children,
      this.props.node || this.defaultNode
    )
  }
}

export default Portal
