import React from "react";

import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Tooltip from "@amzn/meridian/tooltip";

import createPollSvg from "../../../assets/icons/shared/create_poll.svg";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";

const PollDashboardHeader = (props) => {
    const userFullName = props.userFullName;
    const userFirstName = userFullName !== undefined ? userFullName.split(" ")[0] : "";
    const organizerPreferredHour = props.organizerPreferredHour;
    const showResultsPanel = props.showResultsPanel;
    const handleChangePage = props.handleChangePage;

    const getGreetingMessage = () => {
        if (userFirstName) {
            if (organizerPreferredHour < 12) {
                return `Good morning ${userFirstName}`;
            } else if (organizerPreferredHour < 18) {
                return `Good afternoon ${userFirstName}`;
            } else {
                return `Good evening ${userFirstName}`;
            }
        } else return "";
    };

    const renderNavigationButtons = () => {
        return (
            <a
                type="icon"
                backdropColor="white"
                size="small"
                label="Navigate to create meeting poll"
                href={WORKFLOWS.POLL_CREATE.HREF}
                onClick={(event) => {handleChangePage(event, WORKFLOWS.POLL_CREATE)}}
            >
                <img
                    src={createPollSvg}
                    alt="Create meeting poll"
                />
            </a>
        );
    };

    return (
        <Row alignmentHorizontal="justify" alignmentVertical="top" spacingInset="small small large small">
            <Heading level={2} type="h400">
                <div style={{color: "#1A454E"}}>
                    {getGreetingMessage()}
                </div>
            </Heading>
            {!showResultsPanel &&
                <Tooltip
                    position="bottom"
                    title={<div>Navigate to <b>Create meeting poll</b></div>}
                >
                    {renderNavigationButtons()}
                </Tooltip>
            }
        </Row>
    );
};

export default PollDashboardHeader;