const shim = () => {}
shim.isRequired = shim
const getShim = () => shim

// Important!
// Keep this list in sync with production version in `./_prop-types.js`.
const size = shim
const flexAlignment = getShim
const spacing = getShim
const alignment = shim

// eslint-disable-next-line import/no-anonymous-default-export
export default { size, flexAlignment, spacing, alignment }
