import React from "react";

import Link from "@amzn/meridian/link";

const OOTOCreatedToast = (props) => {
    return (
        <React.Fragment>
            <b>Out Of The Office created.</b>&nbsp;
            <Link onClick={props.openToastModal}>
                View Details
            </Link>
        </React.Fragment>
    );
};

export default OOTOCreatedToast;