import { call, put, takeEvery } from "redux-saga/effects";
import * as Actions from "../actions/action-types";
import { getSchedule } from "../../../api/apig/ras";
import { setScheduleAvailability } from "../actions";
import { isErrorResponse } from "../../../api/apig/apig-utils";

export function* watchGetSchedule() {
    yield takeEvery(Actions.GET_SCHEDULE, getScheduleApi);
}

export function* getScheduleApi(action) {
    const response = yield call(
        getSchedule,
        action.interval,
        action.listOfMailboxes,
        action.startTime.getTime(),
        action.endTime.getTime()
    );
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        //TODO: add better handling
        console.error(`ERROR: ${parsedResponse}`);
    }
    let schedules = {};
    parsedResponse.schedules.forEach((schedule) => {
        schedules[schedule.mailbox] = schedule;
    });
    yield put(setScheduleAvailability(schedules, action.startTime.getTime()));
}

const sagas = [
    watchGetSchedule()
];

export default sagas;