import React from "react";

import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";

import OOTONavigationButtons from "./ooto-navigation-buttons";

import { NAVIGATION_TYPE, STEP_NAVIGATION_HEADER, STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";

const OOTONavigationBanner = (props) => {
    const navigationType = props.navigationType; // Header or footer
    const currentStep = props.currentStep;
    const steps = props.steps || [];
    const labels = props.labels;
    const stepIndex = steps.indexOf(currentStep);

    const renderNavigationButtons = () => {
        return (
            <OOTONavigationButtons
                currentStep={props.currentStep}
                meeting={props.meeting}
                identity={props.identity}
                timezones={props.timezones}
                onUpdateMeeting={props.onUpdateMeeting}
                onChangeStep={props.onChangeStep}
                onCreateMeeting={props.onCreateMeeting}
                steps={props.steps}
                labels={props.labels}
                setIsSubjectEmpty={props.setIsSubjectEmpty}
                reviewAlerts={props.reviewAlerts}
                setReviewAlerts={props.setReviewAlerts}
                isAttendeeLoading={props.isAttendeeLoading}
                isDatePickerError={props.isDatePickerError}
                timezoneValue={props.timezoneValue}
                oldMessageBodyTimeInfo={props.oldMessageBodyTimeInfo}
                setOldMessageBodyTimeInfo={props.setOldMessageBodyTimeInfo}
                oldMessageSubjectTimeInfo={props.oldMessageSubjectTimeInfo}
                setOldMessageSubjectTimeInfo={props.setOldMessageSubjectTimeInfo}
                setMessageBodyTimeFormatChanged={props.setMessageBodyTimeFormatChanged}
                setMessageSubjectTimeFormatChanged={props.setMessageSubjectTimeFormatChanged}
                pointOfContact={props.pointOfContact}
                timeInfoAlert={props.timeInfoAlert}
                queryParams={props.queryParams}
                isLastStepLoading={props.isLastStepLoading}
                setIsLastStepLoading={props.setIsLastStepLoading}
            />
        );
    };

    return (
        navigationType === NAVIGATION_TYPE.HEADER ?
            <div style={{
                overflow: "hidden",
                backgroundColor: "white",
                width: "100%",
                borderBottom: "1px solid #bbc0c1", // Meridian gray-300
            }}>
                <Row height={STEP_NAVIGATION_HEADER.HEIGHT} spacingInset="medium" alignmentHorizontal="justify">
                    <Row>
                        <div style={{
                            color: "#1A454E",
                            fontSize: "28px",
                            fontWeight: "bold",
                            paddingBottom: "4px",
                        }}>
                            {stepIndex + 1}
                        </div>
                        <Heading level={2} type="h300">
                            <div style={{color: "#1A454E"}}>
                                {labels[stepIndex]}
                            </div>
                        </Heading>
                    </Row>
                    {renderNavigationButtons()}
                </Row>
            </div>
            :
            <div style={{
                "position": "fixed",
                "left": 0,
                "bottom": 0,
                "width": "100%",
                "zIndex": "30",
                "backgroundColor": "white",
                "borderTop": "1px solid #bbc0c1", // Meridian gray-300
            }}>
                <Row height={STEP_NAVIGATION_FOOTER.HEIGHT} width="100%" spacingInset="medium" alignmentHorizontal="right">
                    {renderNavigationButtons()}
                </Row>
            </div>
    );
};

export default OOTONavigationBanner;