import { call, put, takeEvery } from "redux-saga/effects";
import * as Actions from "../actions/action-types";
import { getFeedbackEligibility } from "../../../api/apig/ams";

export function* watchGetFeedbackEligibility() {
    yield takeEvery(Actions.GET_FEEDBACK_ELIGIBILITY, getFeedbackEligibilityApi);
}

export function* getFeedbackEligibilityApi(action) {
    const response = yield call(
        getFeedbackEligibility,
        action.alias
    );
    const parsedResponse = JSON.parse(response);
    const isEligible = parsedResponse.isEligible;

    yield put({
        type: Actions.SET_IS_ELIGIBLE,
        isEligible
    });
}

const sagas = [
    watchGetFeedbackEligibility()
];

export default sagas;