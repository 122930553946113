import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
    getLivestreamCreatingMeetingState,
    getLivestreamIntakeLoadedState,
} from "../../../sagas/selector";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Theme from "@amzn/meridian/theme";
import Tooltip from "@amzn/meridian/tooltip";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";

import tealLightTokens from "@amzn/meridian-tokens/theme/teal-light";
import arrowLeftLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-left-large";
import arrowRightLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-right-large";

import "../../shared/styles/navigation-buttons.css";
import { ReactComponent as SendLivestreamIcon } from "../../../assets/icons/livestream/send_livestream.svg";

import { STEP, LIVESTREAM_ALERTS, LIVESTREAM_PREPARATION_MINUTES, LIVESTREAM_PERMALINK_DETAILS } from "../livestream-constants";
import { FLOW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";
import { checkEventRequirementErrors } from "../livestream-utils";
import { generateLivestreamTicketPermalink, generateEventIntakeTicket } from "../livestream-ticket-utils";
import { ATTENDEE_PRIORITY, ATTENDEE_ROLE } from "../../people/people-constants";
import { ALERTS, MAX_PAYLOAD_SIZE } from "../../shared/shared-constants";
import { generateComponentId, getObjectInBytes } from "../../shared/shared-utils";
import { generateNewMeetingId } from "../../shared/meeting-utils";
import {
    addLivestreamPrepInfo,
    addLivestreamLinkInfo,
    addMeetingsInfo,
    addPresenterInfo,
    addViewerInfo,
    addPrepMeetingInfo
} from "../../shared/body/body-utils";
import { TIME_FORMAT as SETTINGS_TIME_FORMAT } from "../../shared/settings/settings-constants";

const LivestreamNavigationButtons = (props) => {
    const isCreatingMeeting = useSelector(getLivestreamCreatingMeetingState);
    const isLivestreamIntakeLoaded = useSelector(getLivestreamIntakeLoadedState);
    const flowType = props.flowType;
    const identity = props.identity;
    const timezones = props.timezones;
    const meeting = props.meeting;
    const eventRequirements = props.eventRequirements;
    const setEventRequirements = props.setEventRequirements;
    const eventRequirementErrors = props.eventRequirementErrors;
    const setEventRequirementErrors = props.setEventRequirementErrors;
    const attendees = props.attendees;
    const selectedStations = props.selectedStations;
    const agendaViewerText = props.agendaViewerText; // meeting body sent to viewers
    const timeFormat = props.timeFormat;

    const currentStep = props.currentStep;
    const [prevStep, setPrevStep] = useState(undefined);
    const steps = props.steps;
    const labels = props.labels;
    const stepIndex = steps.indexOf(currentStep);
    const onChangeStep = props.onChangeStep;

    const prevStepLabel = stepIndex > 0 && labels[stepIndex - 1]; // name of prev step for tooltip
    const nextStepLabel = stepIndex < steps.length - 1 && labels[stepIndex + 1]; // name of next step for tooltip
    const nextButtonId = generateComponentId("button", currentStep);

    const setIsSubjectEmpty = props.setIsSubjectEmpty;
    const reviewAlerts = props.reviewAlerts;
    const setReviewAlerts = props.setReviewAlerts;

    const isSuggestionSelected = props.isSuggestionSelected; // disable next button if no meeting suggestion is selected
    const isSearchingMeetingSuggestions = props.isSearchingMeetingSuggestions; // disable back/next button while searching for suggestions
    const isAttendeeLoading = props.isAttendeeLoading; // disable next button if attendee data is still being retrieved (email is undefined)
    const noLivestreamStations = props.noLivestreamStations // disable next button if no livestream stations are available

    const disableNextButton = isSearchingMeetingSuggestions || isAttendeeLoading ||
        (noLivestreamStations && currentStep === STEP.EVENT_SCHEDULE) ||
        (!isSuggestionSelected && currentStep === STEP.EVENT_SCHEDULE && flowType === FLOW_TYPE.CREATE);

    const [showNextTooltip, setShowNextTooltip] = useState(undefined); // handle tooltip hovering
    const [isLastStepLoading, setIsLastStepLoading] = [props.isLastStepLoading, props.setIsLastStepLoading]; // disable send invite button

    const isMeetingAgendaValid = () => {
        // If subject is empty, display error and do not proceed to next step
        if (!meeting.subject || !meeting.subject.length || meeting.subject === "") {
            setIsSubjectEmpty(true);
            return false;
        } else {
            setIsSubjectEmpty(false);
            return true;
        }
    };

    const onReviewButton = () => {
        setIsLastStepLoading(true); // During final step, set it to loading to prevent double click

        if (flowType === FLOW_TYPE.CREATE) {
            const isRecordingEvent = eventRequirements["eventRequestType"] === "RECORDING";

            // Create two separate meetings for livestream: prep hold and viewer meeting.
            // Update preparation meeting body with self-service info, creation info, livestream prep info, livestream link info
            const prepMeetingUniqueId = meeting.uniqueID;
            const prepBody = {
                value: addPrepMeetingInfo(addLivestreamPrepInfo(addLivestreamLinkInfo(addPresenterInfo(meeting.body.value), isRecordingEvent)), identity.username, prepMeetingUniqueId, meeting.recurrence),
                type: "html"
            };

            // Update viewer meeting body with self service info, creation info, livestream link info
            const viewerMeetingUniqueId = generateNewMeetingId(identity.username); // generate a new unique ID for viewer meeting
            const viewerBody = {
                value: addMeetingsInfo(addViewerInfo(agendaViewerText, isRecordingEvent), identity.username, viewerMeetingUniqueId, meeting.recurrence),
                type: "html"
            };

            // Filter out attendees based on the meeting invite type
            // Prep meeting includes organizer, livestream engineers, Presenters, Chime, rooms (rooms are in resources)
            // Viewer meeting includes organizer, viewers
            let prepMeetingRequiredAttendees = [];
            let prepMeetingOptionalAttendees = [];
            let viewerMeetingRequiredAttendees = [];
            let viewerMeetingOptionalAttendees = [];

            // Copy over Chime emails to the prep and presenter meetings
            meeting.requiredAttendees.forEach((attendee) => {
                if (attendee.email && attendee.email.endsWith("@chime.aws")) {
                    prepMeetingRequiredAttendees.push(attendee);
                }
            });

            // Sort attendees into each meeting
            attendees.forEach((attendee) => {
                if (attendee.email) {
                    const attendeeObject = {email: attendee.email};

                    if (attendee.isOrganizer) {
                        // Organizer is added to all meetings
                        prepMeetingRequiredAttendees.push(attendeeObject);
                        viewerMeetingRequiredAttendees.push(attendeeObject);
                    } else if (attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER) {
                        attendee.priority === ATTENDEE_PRIORITY.OPTIONAL ?
                            prepMeetingOptionalAttendees.push(attendeeObject)
                            :
                            prepMeetingRequiredAttendees.push(attendeeObject);
                    } else {
                        attendee.priority === ATTENDEE_PRIORITY.OPTIONAL ?
                            viewerMeetingOptionalAttendees.push(attendeeObject)
                            :
                            viewerMeetingRequiredAttendees.push(attendeeObject);
                    }
                }
            });

            // Add livestream engineers to prep hold meeting invite
            prepMeetingRequiredAttendees.push({email: "its-me-lso@amazon.com"});
            selectedStations.forEach((stationEmail) => {
                prepMeetingRequiredAttendees.push({email: stationEmail});
            });

            const prepMeeting = JSON.parse(JSON.stringify(meeting));
            prepMeeting.subject = `PREP Hold for Livestream (${meeting.subject}) - Do not modify`;
            prepMeeting.body = prepBody;
            prepMeeting.requiredAttendees = prepMeetingRequiredAttendees;
            prepMeeting.optionalAttendees = prepMeetingOptionalAttendees;
            prepMeeting.isResponseRequested = true; // always accept prep meeting responses to receive livestream engineer auto-confirmations

            const viewerMeeting = JSON.parse(JSON.stringify(meeting));
            viewerMeeting.uniqueID = viewerMeetingUniqueId;
            viewerMeeting.body = viewerBody;
            viewerMeeting.requiredAttendees = viewerMeetingRequiredAttendees;
            viewerMeeting.optionalAttendees = viewerMeetingOptionalAttendees;
            viewerMeeting.resources = []; // remove rooms from viewer meeting
            viewerMeeting.time.startTime += LIVESTREAM_PREPARATION_MINUTES * 60;
            viewerMeeting.isResponseRequested = false; // do not request responses from viewers

            const prepMeetingSize = getObjectInBytes(prepMeeting);
            const viewerMeetingSize = getObjectInBytes(viewerMeeting);

            // do not send the request if the payload exceeds the limit
            if (prepMeetingSize > MAX_PAYLOAD_SIZE || viewerMeetingSize > MAX_PAYLOAD_SIZE) {
                // show error alert on the review page
                const payloadAlert = JSON.parse(JSON.stringify(ALERTS.MAX_PAYLOAD_SIZE_EXCEEDED));
                if (prepMeetingSize > viewerMeetingSize) {
                    payloadAlert.value = payloadAlert.value.replace("request", `request for Prephold And Presenters (${(prepMeetingSize / 1024 / 1024).toFixed(2)}MB)`); // show request size in MB
                } else {
                    payloadAlert.value = payloadAlert.value.replace("request", `request for viewers (${(viewerMeetingSize / 1024 / 1024).toFixed(2)}MB)`); // show request size in MB
                }

                setReviewAlerts(reviewAlerts
                    .filter((alert) => alert.id !== ALERTS.MAX_PAYLOAD_SIZE_EXCEEDED.id) // remove existing payload size alerts
                    .concat([payloadAlert]));

                return;
            }

            const livestreamTicketPermalink = generateLivestreamTicketPermalink(eventRequirements, meeting, attendees, timezones, selectedStations, timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR, LIVESTREAM_PERMALINK_DETAILS.CREATE_TICKET_ERROR);
            const livestreamEventIntakeTicket = generateEventIntakeTicket(eventRequirements, meeting, timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR, timezones, attendees, selectedStations);
            props.onCreateLivestream(prepMeeting, viewerMeeting, livestreamTicketPermalink, livestreamEventIntakeTicket );
        }
    };

    const onBackClick = () => {
        if (stepIndex !== 0) {
            onChangeStep(steps[stepIndex - 1]);
        }
    };

    const onNextClick = () => {
        if (currentStep === STEP.EVENT_REQUIREMENTS) {
            const errorCheck = checkEventRequirementErrors(eventRequirements, eventRequirementErrors, setEventRequirements, setEventRequirementErrors);
            if (!errorCheck) {
                onChangeStep(steps[stepIndex + 1]);
            }
        } else if (currentStep === STEP.EVENT_AGENDA_AND_REVIEW) { // Final step
            if (isMeetingAgendaValid()) { // Only send meeting if meeting summary is valid
                onReviewButton();
            }
        } else {
            onChangeStep(steps[stepIndex + 1]);
        }
    };

    // Add a timer to the 'next' button on the review step to prevent double clicking
    useEffect(() => {
        if (prevStep !== currentStep && !isLastStepLoading) {
            setPrevStep(currentStep);
            setIsLastStepLoading(currentStep === STEP.EVENT_AGENDA_AND_REVIEW);
            if (isSearchingMeetingSuggestions || currentStep === STEP.EVENT_AGENDA_AND_REVIEW) {
                // disable tooltip when next button is disabled/loading
                setShowNextTooltip(false);
            }

            window.setTimeout(() => {
                setIsLastStepLoading(false);
            }, 1000); // 1 sec timer
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevStep, setPrevStep, currentStep, isSearchingMeetingSuggestions, isLastStepLoading, disableNextButton]);

    // When the loader is being displayed waiting for a response from the API and the user navigates back & forth - continue to display the loader
    // Once the API response is an error, remove the loader and display the send mail icon to retry
    useEffect(() => {
        if (isLivestreamIntakeLoaded) {
            if (!isCreatingMeeting && isLastStepLoading &&  currentStep === STEP.EVENT_AGENDA_AND_REVIEW) {
                window.setTimeout(() => {
                    setIsLastStepLoading(false);
                }, 1000); // 1 sec timer
            }
        } else {
            setIsLastStepLoading(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, isLastStepLoading, isCreatingMeeting, isLivestreamIntakeLoaded]);

    return (
        <section aria-label="Step navigation">
            <Theme tokens={tealLightTokens}>
                <Column spacing={"xsmall"}>
                <Row>
                    {(isSearchingMeetingSuggestions || isAttendeeLoading) &&
                        <Loader size="small" />
                    }
                    {prevStepLabel &&
                        <Tooltip
                            position="bottom"
                            title={<div>Back to <b>{prevStepLabel}</b></div>}
                        >
                            <Button
                                label={`Back to ${prevStepLabel}`}
                                type="secondary"
                                circular="true"
                                onClick={onBackClick}
                                disabled={isSearchingMeetingSuggestions}
                            >
                                <Icon tokens={arrowLeftLargeTokens}>Back to {prevStepLabel}</Icon>
                            </Button>
                        </Tooltip>
                    }
                    {nextStepLabel ?
                        <Tooltip
                            position="bottom"
                            title={<div>Proceed to <b>{nextStepLabel}</b></div>}
                            open={disableNextButton ? false : showNextTooltip}
                        >
                            <Button
                                id={nextButtonId}
                                label={`Proceed to ${nextStepLabel}`}
                                type="primary"
                                circular="true"
                                onClick={onNextClick}
                                disabled={disableNextButton}
                                onFocus={() => setShowNextTooltip(true)}
                                onBlur={() => setShowNextTooltip(false)}
                                onMouseEnter={() => setShowNextTooltip(true)}
                                onMouseLeave={() => setShowNextTooltip(false)}
                            >
                                <Icon tokens={arrowRightLargeTokens} />
                            </Button>
                        </Tooltip>
                        :
                        <Tooltip
                            position="bottom"
                            title={<b>Send livestream request</b>}
                            open={isLastStepLoading ? false : showNextTooltip}
                        >
                            <button
                                id={nextButtonId}
                                className="sendInviteButton"
                                onClick={onNextClick}
                                aria-label="Send livestream request"
                                disabled={isLastStepLoading}
                                onFocus={() => setShowNextTooltip(true)}
                                onBlur={() => setShowNextTooltip(false)}
                                onMouseEnter={() => setShowNextTooltip(true)}
                                onMouseLeave={() => setShowNextTooltip(false)}
                            >
                                {isLastStepLoading ?
                                    <div style={{paddingLeft: "12px"}}>
                                        <Loader size="small" />
                                    </div>
                                    :
                                    <SendLivestreamIcon />
                                }
                            </button>
                        </Tooltip>
                    }
                </Row>
                {isLastStepLoading && currentStep === STEP.EVENT_AGENDA_AND_REVIEW && isCreatingMeeting &&
                    <Row spacingInset={"200 none none none"}>
                        <Alert type={LIVESTREAM_ALERTS.LIVESTREAM_PROCESSING_CREATE_MEETING.type} size="small">
                            <Text id={LIVESTREAM_ALERTS.LIVESTREAM_PROCESSING_CREATE_MEETING.id}>
                                {LIVESTREAM_ALERTS.LIVESTREAM_PROCESSING_CREATE_MEETING.value}
                            </Text>
                        </Alert>
                    </Row>
                }
                </Column>
            </Theme>
        </section>
    );
};

export default LivestreamNavigationButtons;