import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Responsive from "@amzn/meridian/responsive";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle";
import tealDarkTokens from "@amzn/meridian-tokens/theme/teal-dark";

import PatternLarge from "../../../assets/backgrounds/pattern_large_dark.svg";
import PatternSmall from "../../../assets/backgrounds/pattern_small_vertical.svg";

import "../../shared/accessibility-styles.css";

import MeetingQuick from "../../meeting-quick/containers/meeting-quick-container";
import MeetingsToaster from "../../shared/toasts/containers/toast-container";
import MeetingsFeedbackContainer from "../../shared/feedback/containers/meetings-feedback-container";
import CreateMeetingMenu from "../components/create-meeting-menu";
import UpcomingMeetingsDetails from "./upcoming-meetings";

import { getWorkflow } from "../../../sagas/selector";
import { HOMEPAGE_FOOTER } from "../../shared/shared-constants";
import { generateComponentId } from "../../shared/shared-utils";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { changePage } from "../../shared/workflow/workflow-utils";

const LandingPage = () => {
    const dispatch = useDispatch();
    const currentWorkflow = useSelector(getWorkflow);

    const createButtonRef = useRef();
    const [createMenuOpen, setCreateMenuOpen] = useState(false);
    const [quickModalOpen, setQuickModalOpen] = useState(false);
    const onClickCreateMenu = useCallback(() => setCreateMenuOpen(true), []);

    const header = useRef();

    // Begin new workflow content with focus on the <h1> heading
    useEffect(() => {
        document.title = "Amazon Meetings";
        if (header.current) {
            header.current.focus();
        }
    }, []);

    // Identify external auth redirection with fragments and do a redirect in react to the appropriate page
    useEffect(() => {
        //window.location.href.substr(window.location.origin.length + 1 ,window.location.href.indexOf("#"))
        const pathname = window.location.pathname;
        console.log("Pathname: " + pathname);
        if (pathname !== "/ext-auth") return;
//** Re-enable L@E and when redirect trigger a refresh to reuse the midway token and bypass auth
        const currentUrl = window.location.href;
        console.log("Hash Index: " + currentUrl.indexOf("#") + " - pathname Index: " + currentUrl.indexOf(pathname));

        // If the path comes from the redirect it will appear before the hash in the React app
        if (currentUrl.indexOf("#") === -1 || (currentUrl.indexOf(pathname) < currentUrl.indexOf("#"))) {
            console.log("Redirect!");
            dispatch(push("ext-auth"));
        }
    });

    const onAuth = () => {
        let authWindow = window.open(
            `https://meetings-website-auth-daverod.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=3g9mtjh1ajv3st25om2hg1b9su&redirect_uri=https://maps-beta.daverod.people.a2z.com/ext-auth`,
             // `https://meetings-website-auth-daverod.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=3g9mtjh1ajv3st25om2hg1b9su&redirect_uri=http://localhost:3000/ext-auth`,
            "amw-auth-popup",
            "popup"); //popup

        window.OnSuccessfullExtAuth = () => {
           dispatch(push("room-booking"));
           window.OnSuccessfullExtAuth = null;
           window.location.reload();
        };

    };

//  https://meetings-website-auth-daverod.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=3g9mtjh1ajv3st25om2hg1b9su&redirect_uri=https://meetings-website-auth-daverod.auth.us-east-1.amazoncognito.com/oauth2/idpresponse

//  https://meetings-website-auth-daverod.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=3g9mtjh1ajv3st25om2hg1b9su&redirect_uri=https://maps-beta.daverod.people.a2z.com
    return (
        <Responsive query="min-width" props={{
            spacingSize: {
                default: "small",
                "550px": "xlarge"
            },
            isMobileView: {
                default: true,
                "550px": false
            },
            isWrapOptions: {
                default: true,
                "700px": false
            }
        }}>
            {(props) => (
                <div aria-labelledby="Main Content" style={{
                    "backgroundImage": `url(${!props.isWrapOptions ? PatternLarge : PatternSmall})`,
                    "backgroundRepeat": "no-repeat",
                    "backgroundPosition": "115% 50%",
                    "backgroundColor": "rgba(26, 69, 78)",
                    "maxHeight": `${props.isMobileView ? "300px" : "400px"}`,
                }}>
                    <Row alignmentHorizontal="center" alignmentVertical="center">
                        <Column width="100%" maxWidth="1200px" spacing={props.spacingSize} spacingInset={props.spacingSize}>
                            <section aria-label="Quick Create">
                                <div style={{"paddingLeft": "16px"}}>
                                    <Heading color="inverted" level={1} type={props.isMobileView ? "h500" : "h600"} ref={header} tabIndex="-1">Schedule smarter</Heading>
                                    <Row maxWidth="400px" wrap="down">
                                        <Text color="inverted" type="b400">
                                            Amazon Meetings' powerful suggestion tools make meeting creation fast and easy.
                                        </Text>
                                    </Row>
                                    <Row spacingInset="medium none" spacing="medium" wrap="down">
                                        <Theme tokens={tealDarkTokens}>
                                            <Button
                                                ref={createButtonRef}
                                                type="primary"
                                                onClick={onClickCreateMenu}
                                                id={generateComponentId("button", "create-a-meeting")}
                                            >
                                                <Row spacing="small" spacingInset="none small none none" alignmentHorizontal="left">
                                                    <Icon tokens={plusCircleTokens} />
                                                    <Text type="h100" color="inverted">Create</Text>
                                                </Row>
                                            </Button>
                                        </Theme>
                                        <CreateMeetingMenu
                                            createButtonRef={createButtonRef}
                                            createMenuOpen={createMenuOpen}
                                            setCreateMenuOpen={setCreateMenuOpen}
                                            quickModalOpen={quickModalOpen}
                                            setQuickModalOpen={setQuickModalOpen}
                                            currentWorkflow={currentWorkflow}
                                        />
                                        <Theme tokens={tealDarkTokens}>
                                            <Button
                                                type="tertiary"
                                                href={WORKFLOWS.CALENDAR.HREF}
                                                onClick={() => changePage(dispatch, currentWorkflow, WORKFLOWS.CALENDAR)}
                                                id={generateComponentId("button", "view-calendar")}
                                            >
                                                View calendar
                                            </Button>
                                            <Button
                                                type="tertiary"
                                                onClick={() => onAuth()}
                                                id={generateComponentId("button", "cognito-auth")}
                                            >
                                                Click to Auth Local
                                            </Button>
                                        </Theme>
                                    </Row>
                                    <MeetingQuick quickModalOpen={quickModalOpen} setQuickModalOpen={setQuickModalOpen} createButtonRef={createButtonRef.current} />
                                </div>
                            </section>
                            <section aria-label="Upcoming Meetings" style={{paddingBottom: HOMEPAGE_FOOTER.HEIGHT}}>
                                <UpcomingMeetingsDetails isWrapOptions={props.isWrapOptions} />
                            </section>
                            <MeetingsToaster />
                            <MeetingsFeedbackContainer />
                        </Column>
                    </Row>
                </div>
            )}
        </Responsive>
    );
};

export default LandingPage;