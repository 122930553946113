module.exports = {
    SIDE_MENU_WIDTH: 332,
    HELP_MENU_WIDTH: 300,
    NAVIGATION_TYPE: {
        HEADER: "Header",
        FOOTER: "Footer",
    },
    STEP_NAVIGATION_HEADER: {
        HEIGHT: "85px",
    },
    STEP_NAVIGATION_FOOTER: {
        HEIGHT: "85px",
    },
};