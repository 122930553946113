import _ from "lodash";
import { randomBytes } from 'crypto';

import { isLocationAFavoriteRoom, isLocationASuggestion } from "../meeting-quick/meeting-quick-utils";
import { shortRoomName } from "./locations/locations-utils";
import { convertEpoch, getDaysOfWeekLabel, getOrdinalDay } from "../meeting-scheduler/meeting-scheduler-utils";
import { RECURRENCE_TYPE } from "./shared-constants";
import { LOCATION } from "../meeting-quick/meeting-quick-constants";

export const getMeetingSuggestions = (suggestions) => {
    return suggestions.suggestionList && suggestions.suggestionList.map((suggestion, suggestionIndex) => {
        return {
            startTime: suggestion.time[0].startTime,
            endTime: suggestion.time[0].endTime,
            location: !suggestion.suggestionRoomList[0].length ? 'No room included' : suggestion.suggestionRoomList[0][0].room.name,
            freePeople: suggestion.freePeople[0],
            tentativePeople: suggestion.tentativePeople[0],
            unavailablePeople: suggestion.unavailablePeople[0].concat(suggestion.unavailableOptionalPeople[0]),
            unknownPeople: suggestion.unknownPeople[0],
            email: !suggestion.suggestionRoomList[0].length ? "" : suggestion.suggestionRoomList[0][0].room.email,
            peopleRank: suggestion.peopleRank[0],
            peopleRankIndex: suggestionIndex,
            suggestionRoomList: suggestion.suggestionRoomList,
        }
    });
};

export const getQuickMtgSuggestions = (suggestions, location, favorites) => {
    if (suggestions.suggestionList.length === 1 && suggestions.suggestionList[0].suggestionRoomList[0].length > 1) {
        return prepareRoomSuggestions(suggestions.suggestionList[0]);
    }
    return suggestions.suggestionList.map((suggestion) => {
        return prepareTimeSuggestions(suggestion, location, favorites);
    });
};

export const prepareRoomSuggestions = (suggestion) => {
    return suggestion.suggestionRoomList[0] && suggestion.suggestionRoomList[0].map((suggestionRoom) => {
        return {
            startTime: suggestion.time[0].startTime,
            endTime: suggestion.time[0].endTime,
            location: suggestionRoom.room.name,
            freePeople: suggestion.freePeople[0] || [],
            tentativePeople: suggestion.tentativePeople[0] || [],
            unavailablePeople: suggestion.unavailablePeople[0] || [],
            unknownPeople: suggestion.unknownPeople[0] || [],
            email: suggestionRoom.room.email
        }
    });
};

export const mapCachedAvailabilityList = (cachedAvailabilityList) => cachedAvailabilityList.map((availability) => {
    return {
        capacity: availability.capacity,
        email: availability.email,
        features: availability.resourceList,
        floor: availability.floor,
        name: shortRoomName(availability.name),
        roomNumber: availability.roomNumber,
        restricted: availability.restricted,
        status: "Available",
        availabilities: availability.availabilities
    };
});

export const prepareTimeSuggestions = (suggestion, location, favorites) => {
    let freeList = suggestion.freePeople[0];
    let locationName;
    let email;

    if (isLocationAFavoriteRoom(location)) {
        freeList = suggestion.freePeople.filter((free) => location === free);
        if (freeList) {
            locationName = favorites && favorites.filter((favorite) => favorite.value === location)[0].name;
            email = location;
        }
    } else if (isLocationASuggestion(location)) {
        locationName = !suggestion.suggestionRoomList[0].length ? LOCATION.NO_SUGGESTIONS_FOUND : suggestion.suggestionRoomList[0][0].room.name;
        email = !suggestion.suggestionRoomList[0].length ? "" : suggestion.suggestionRoomList[0][0].room.email;
    } else {
        locationName = LOCATION.VIRTUAL_ONLY;
        email = "";
    }

    return {
        startTime: suggestion.time[0].startTime,
        endTime: suggestion.time[0].endTime,
        location: locationName,
        freePeople: freeList,
        tentativePeople: suggestion.tentativePeople[0] || [],
        unavailablePeople: suggestion.unavailablePeople[0] || [],
        unknownPeople: suggestion.unknownPeople[0] || [],
        email: email
    }
};

export const generateNewMeetingId = (user, now) => {
    now = (now && new Date(now)) || new Date();
    let id = 'Meetings-' + Number(now) + '-';
    if (user) {
        id += user + '-';
    }
    id += _randomHexBytes(16);
    return id;

    /**
     * Generates a random string of length bytes hex encoded. [a-z0-9]{length*2}
     */
    function _randomHexBytes(length) {
        if (!_.isFinite(length) || length <= 0) {
            throw new Error('length must be a finite number and greater than 0. You gave ' + length);
        }
        return randomBytes(length).toString('hex');
    }
};

// Return a string that is singular or plural based on the number inputted
const parseEnding = (count, string, includeSingleCount = false) => {
    if (count === 1) {
        return includeSingleCount ? `${count} ${string}` : string;
    } else {
        return `${count} ${string}s`;
    }
};

// Return a readable string given a recurrence object
export const parseRecurrence = (recurrence) => {
    if (recurrence) {
        let repeatType = recurrence.__type;
        let repeatInterval = recurrence.interval;
        let repeatPeriod;

        let startDate = (recurrence.hasOwnProperty("startDate") && convertEpoch(recurrence.startDate, "date")) || "";
        let endByDate = recurrence.hasOwnProperty("endByDate") && convertEpoch(recurrence.endByDate, "date");
        let endAfterNOccurrences = recurrence.hasOwnProperty("endAfterNOccurrences") && parseEnding(recurrence.endAfterNOccurrences, "occurrence", true);

        let repeatString = "";
        let startingString = "";
        let endingString = "";

        if (startDate) {
            startingString = `effective ${startDate}`;
        }

        if (endByDate) {
            endingString = `to ${endByDate}`;
        } else if (endAfterNOccurrences) {
            endingString = `for ${endAfterNOccurrences}`;
        } else if (recurrence.neverEnding) {
            endingString = "forever";
        }

        if (repeatType === RECURRENCE_TYPE.DAILY) {
            repeatPeriod = "day";
        } else if (repeatType === RECURRENCE_TYPE.WEEKLY) {
            repeatPeriod = "week";

            let daysOfWeek = [];

            // get an array for the days of week the meeting repeats on
            for (const [key] of Object.entries(recurrence.daysOfWeek)) {
                if (recurrence.daysOfWeek[key]) {
                    daysOfWeek.push(key.charAt(0).toUpperCase() + key.slice(1));
                }
            };

            repeatString = `on ${getDaysOfWeekLabel(daysOfWeek, false)}`;
        } else if (repeatType === RECURRENCE_TYPE.ABSOLUTE_MONTHLY) {
            repeatPeriod = "month";

            repeatString = `on the ${getOrdinalDay(recurrence.dayOfMonth)}`;
        } else if (repeatType === RECURRENCE_TYPE.RELATIVE_MONTHLY) {
            repeatPeriod = "month";

            let dayOfWeek = recurrence.dayOfWeek.charAt(0).toUpperCase() + recurrence.dayOfWeek.slice(1);
            repeatString = `on the ${recurrence.weekOfMonth} ${dayOfWeek}`;
        }

        return `Occurs every ${parseEnding(repeatInterval, repeatPeriod)} ${repeatString} ${startingString} ${endingString}`;
    }
};

// Sort a list of attendees by priority, then alphabetically by name
export const sortAttendees = (attendees, organizerEmail = undefined) => {
    if (attendees) {
        const priorities = {"Organizer": 1, "Essential": 2, "Required": 3, "Optional": 4};

        attendees.sort((attendee1, attendee2) => {
            if (organizerEmail) {
                if (attendee1.email === organizerEmail) {
                    return -1;
                }
                if (attendee2.email === organizerEmail) {
                    return 1;
                }
            }
            if (attendee1.priority !== attendee2.priority) {
                return priorities[attendee1.priority] - priorities[attendee2.priority];
            }
            return attendee1.name > attendee2.name ? 1 : -1;
        });

        return attendees;
    }
};

export const getFormattedChimePin = (chimePin) => {
    return chimePin.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3")
};

// Checks if input fits the chime pin attendee form. ex) pin+5286558892@chime.aws
export const isOneTimeChimePinAttendee = (chimeAttendee) => {
    return /pin\+\d*@chime\.aws/.test(chimeAttendee);
};

// extracts chime pin number from chime attendee form. ex) pin+5286558892@chime.aws => 5286558892
export const getChimePinFromChimeAttendee = (chimeAttendee) => {
    return chimeAttendee.replace(/pin\+/,"").replace(/@chime\.aws/,"");
};