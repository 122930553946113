import React, { useRef, useState } from "react";

import AttendeeSearchBar from "./attendee-search-bar";
import AttendeePopover from "./attendee-popover";
import FavoritesModal from "../../shared/favorites/components/favorites-modal";
import { createFavorite, renderSetFavoriteIcon, renderFavoriteTypeIcon } from "../../shared/favorites/favorites-utils";
import { SCREEN_SIZE } from "../../shared/shared-constants";

import {
    ATTENDEE_RESPONSE,
    ATTENDEE_TYPE,
    ATTENDEE_SORT_BY,
    ATTENDEE_SEARCH_BAR_MIN_WIDTH,
    ATTENDEE_SEARCH_BAR_SMALL_POPOVER_HEIGHT
} from "../people-constants";
import { FAVORITE_TYPE } from "../../shared/favorites/favorites-constants";

import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import Button from "@amzn/meridian/button";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Loader from "@amzn/meridian/loader";

import trashTokens from "@amzn/meridian-tokens/base/icon/trash";

const MailingList = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    let identity = props.identity;
    let attendees = props.attendees;
    const peopleSuggestions = props.peopleSuggestions;
    const groupSuggestions = props.groupSuggestions;
    const pointOfContact = props.pointOfContact;
    const setPointOfContact = props.setPointOfContact;
    const favorites = props.favorites;

    const attendeeList = attendees.map((attendee) => attendee.alias);
    const [sortColumn, setSortColumn] = useState(ATTENDEE_SORT_BY.ATTENDEE);
    const [sortDirection, setSortDirection] = useState("ascending");

    const sortAttendees = (attendees, sortColumn, sortDirection) => {
        let sortResult = sortDirection === "ascending" ? -1 : 1;
        let attributeToCompare;
        if (sortColumn === ATTENDEE_SORT_BY.POINT_OF_CONTACT) {
            attributeToCompare = "isPointOfContact";
        } else {
            // sort by attendees
            attributeToCompare = "identifier";
        }

        return attendees.sort((attendee1, attendee2) => {
            if (attendee1[attributeToCompare] < attendee2[attributeToCompare]) {
                return sortResult;
            }
            if (attendee1[attributeToCompare] > attendee2[attributeToCompare]) {
                return -sortResult;
            }
            return 0;
        });
    };

    const onSort = (sortState) => {
        if (sortState.sortColumn !== sortColumn) {
            setSortColumn(sortState.sortColumn);
        }
        if (sortState.sortDirection !== sortDirection) {
            setSortDirection(sortState.sortDirection);
        }
    };

    const onClickRemoveAttendee = (attendee) => {
        if (attendee.status !== ATTENDEE_RESPONSE.ORGANIZER) {
            setPointOfContact(pointOfContact.filter((poc) => poc.email !== attendee.email))
            props.onRemoveAttendee(attendee);
        }
    };

    const onRemoveAttendeeByAlias = (alias) => {
        let attendee = attendees.find((attendee) => attendee.alias === alias);
        if (attendee) {
            onClickRemoveAttendee(attendee)
        }
    };

    const onClickPointOfContact = (attendee) => {
        attendee.isPointOfContact = !attendee.isPointOfContact;
        props.onUpdateAttendee(attendee);
        if (attendee.isPointOfContact) {
            setPointOfContact([...pointOfContact, attendee])
        } else {
            setPointOfContact(pointOfContact.filter((poc) => poc.email !== attendee.email))
        }
    };

    // Popover
    const popoverRefs = useRef([]);
    const [openAttendeePopover, setOpenAttendeePopover] = useState("");
    const onClickAttendee = (attendee) => {
        setOpenAttendeePopover(attendee.identifier)

        // If rasData is not present, call APIs once to retrieve card data
        if (attendee.rasData === undefined) {
            if (attendee.type === ATTENDEE_TYPE.PERSON) {
                props.onGetRASDataForPerson(attendee.alias, false);
            } else if (attendee.type === ATTENDEE_TYPE.GROUP) {
                props.onGetRASDataForGroup(attendee.alias);
            }
        }
    };
    const onClosePopover = () => setOpenAttendeePopover("");

    // Favorites
    const favorited = (attendee) => {
        if (favorites && favorites.length > 0) {
            switch (attendee.type) {
                case ATTENDEE_TYPE.PERSON:
                case ATTENDEE_TYPE.GROUP:
                    return !!favorites.find((favorite) => favorite.value === attendee.alias);
                case ATTENDEE_TYPE.EXTERNAL_EMAIL:
                    return !!favorites.find((favorite) => favorite.value === attendee.email);
                default:
                    return false;
            }
        }
        return false;
    };

    const onFavoriteClick = (attendee) => {
        if (favorites === undefined) {
            return;
        }

        let favorite;
        switch (attendee.type) {
            case ATTENDEE_TYPE.PERSON:
                favorite = createFavorite(FAVORITE_TYPE.USER, attendee);
                break;
            case ATTENDEE_TYPE.GROUP:
                favorite = createFavorite(FAVORITE_TYPE.GROUP, attendee);
                break;
            case ATTENDEE_TYPE.EXTERNAL_EMAIL:
                favorite = createFavorite(FAVORITE_TYPE.EXTERNAL_EMAIL, {email: attendee.email});
                break;
            default:
                return;
        }

        if (favorited(attendee)) {
            props.onRemoveFavorite(favorite, identity.username);
        } else {
            props.onAddFavorite(favorite, identity.username);
        }
    };

    return(
        <Table
            headerRows={1}
            showDividers={false}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            spacing="small"
        >
            <Column spacingInset="small medium" backgroundColor="white">
                <Column spacing="xxsmall" minWidth={ATTENDEE_SEARCH_BAR_MIN_WIDTH}>
                    <Text type="b200">Notify people</Text>
                    <Row widths={["fill", "fit"]}>
                        <AttendeeSearchBar
                            peopleSuggestions={peopleSuggestions}
                            groupSuggestions={groupSuggestions}
                            width="100%"
                            popoverHeight={ATTENDEE_SEARCH_BAR_SMALL_POPOVER_HEIGHT}
                            onAddAttendee={props.onAddAttendee}
                            onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                            onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                            onGetGroupSuggestions={props.onGetGroupSuggestions}
                            onClearGroupSuggestions={props.onClearGroupSuggestions}
                        />
                        {screenSizeBreakpoint <= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR &&
                            <FavoritesModal
                                identity={identity}
                                attendees={attendees}
                                favorites={favorites}
                                hideLocations={true}
                                onAddAttendee={props.onAddAttendee}
                                onGetRASDataForPerson={props.onGetRASDataForPerson}
                                onRemoveFavorite={props.onRemoveFavorite}
                            />
                        }
                    </Row>
                </Column>
            </Column>
            <TableRow>
                <TableCell sortColumn={ATTENDEE_SORT_BY.ATTENDEE}>
                    Name ({attendees.length})
                </TableCell>
                <TableCell width={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "20%" : "10%"} />
                <TableCell
                    sortColumn={ATTENDEE_SORT_BY.POINT_OF_CONTACT}
                    alignmentHorizontal="left"
                >
                    {screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "Point of Contact" : "POC"}
                </TableCell>
                <TableCell />
            </TableRow>
            {sortAttendees(attendees, sortColumn, sortDirection).map((attendee) => (
                <TableRow
                    highlight={true}
                    key={attendee.identifier}
                >
                    <TableCell>
                        <Row spacing="xsmall">
                            {attendee.email === undefined &&
                                <Loader size="small" />
                            }
                            <Link
                                onClick={() => onClickAttendee(attendee)}
                                ref={(ref) => popoverRefs.current[attendee.identifier] = ref}
                                type="secondary"
                            >
                                <Row>
                                    {attendee.type === ATTENDEE_TYPE.PERSON && renderFavoriteTypeIcon(FAVORITE_TYPE.USER)}
                                    {attendee.type === ATTENDEE_TYPE.GROUP && renderFavoriteTypeIcon(FAVORITE_TYPE.GROUP)}
                                    {attendee.type === ATTENDEE_TYPE.EXTERNAL_EMAIL && renderFavoriteTypeIcon(FAVORITE_TYPE.EXTERNAL_EMAIL)}
                                    {" " + attendee.identifier + " "}
                                </Row>
                            </Link>
                            <AttendeePopover
                                attendee={attendee}
                                attendeeList={attendeeList}
                                anchorNode={popoverRefs.current[attendee.identifier]}
                                open={openAttendeePopover === attendee.identifier}
                                position={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "right" : "bottom"}
                                onClose={onClosePopover}
                                onAddAttendee={props.onAddAttendee}
                                onRemoveAttendee={props.onRemoveAttendee}
                                onRemoveAttendeeByAlias={onRemoveAttendeeByAlias}
                                onGetRASDataForPerson={props.onGetRASDataForPerson}
                                isFavorited={favorited}
                                onFavoriteClick={onFavoriteClick}
                            />
                        </Row>
                    </TableCell>
                    <TableCell>
                        <Row>
                            {renderSetFavoriteIcon(favorited(attendee), onFavoriteClick, attendee, identity.username === attendee.alias)}
                        </Row>
                    </TableCell>
                    <TableCell alignmentHorizontal="right">
                        <label
                            htmlFor={`${attendee.identifier}-PointOfContact-checkbox`}
                            aria-label={attendee.name}
                        />
                        <Checkbox
                            checked={attendee.isPointOfContact}
                            onChange={() => onClickPointOfContact(attendee)}
                            disabled={attendee.status === ATTENDEE_RESPONSE.ORGANIZER || attendee.email === undefined}
                            id={`${attendee.identifier}-PointOfContact-checkbox`}
                        />
                    </TableCell>
                        <TableCell alignmentHorizontal="left">
                            <Row height="16px">
                                <Button
                                    onClick={() => onClickRemoveAttendee(attendee)}
                                    type="link"
                                    disabled={attendee.status === ATTENDEE_RESPONSE.ORGANIZER}
                                    label={`Remove attendee ${attendee.name}`}
                                    size="small"
                                >
                                    <Icon tokens={trashTokens}/>
                                </Button>
                            </Row>
                        </TableCell>
                </TableRow>
            ))}
        </Table>
    )
}
export default MailingList