import { ATTENDEE_AVAILABILITY } from "./meeting-scheduler-constants";

// Get the number of attendees of a given response type
export const getAttendeeResponseTypeCount = (responseType, attendees) => {
    let total = 0;

    attendees.forEach((attendee) => {
        if (attendee.response === responseType && !attendee.isOrganizer && !attendee.email.endsWith("@chime.aws")) {
            total += 1;
        }
    });

    return total;
};

// Get the total number of attendees in the meeting
export const getNonChimeAttendeesCount = (attendees) => {
    let total = 0;

    attendees.forEach((attendee) => {
        if (!attendee.isOrganizer && !attendee.email.endsWith("@chime.aws")) {
            total += 1;
        }
    });

    return total;
};

// Get attendee tag styling based on percentage of attendees who have accepted the meeting
export const getAttendeeAvailabilityType = (attendees) => {
    let accepted = getAttendeeResponseTypeCount("accept", attendees);
    let total = getNonChimeAttendeesCount(attendees);
    let availability = parseInt((accepted / total) * 100);

    if (total === 0 || availability > ATTENDEE_AVAILABILITY.SUCCESS) {
        return "success";
    } else if (availability > ATTENDEE_AVAILABILITY.WARNING) {
        return "warning";
    } else {
        return "error";
    }
};