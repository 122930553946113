import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { addAttendeeByEmail } from "../actions";
import { showToast } from "../../shared/toasts/actions";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import HighlightSubstring from "@amzn/meridian/highlight-substring";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import SearchField, { SearchSuggestion, SearchSuggestionFooter } from "../../shared/meridian-custom-components/src/components/search-field";
import Text from "@amzn/meridian/text";

import groupSvg from "../../../assets/icons/people/group.svg";

import {
    papiUserToAttendee,
    groupToAttendee,
    isValidEmail,
    externalEmailToAttendee,
} from "../people-utils";
import {
    ATTENDEE_PRIORITY,
    ATTENDEE_RESPONSE,
    ATTENDEE_ROLE,
    ATTENDEE_SEARCH_BAR_DEFAULT_WIDTH,
    ATTENDEE_SEARCH_BAR_SUGGESTION_HEIGHT,
    ATTENDEE_SEARCH_BAR_DEFAULT_POPOVER_HEIGHT,
    ATTENDEE_SEARCH_BAR_MINIMUM_QUERY_LENGTH,
    EMAIL_SUGGESTIONS_TIP,
    PRESENTER_SUGGESTIONS_TIP,
    NO_ATTENDEE_SUGGESTIONS,
    TRUNCATION_DEFAULT,
    UNKNOWN_JOB_TITLE,
} from "../people-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { getErrorToastWithComponent } from "../../shared/toasts/toast-utils";
import { TIMEOUT, TOAST_COMPONENT } from "../../shared/toasts/toast-constants";
import { addFavoriteByEmail } from "../../shared/favorites/actions";

const AttendeeSearchBar = (props) => {
    // Inputs
    const width = props.width || ATTENDEE_SEARCH_BAR_DEFAULT_WIDTH;
    const popoverHeight = props.popoverHeight || ATTENDEE_SEARCH_BAR_DEFAULT_POPOVER_HEIGHT;

    const onAddAttendee = props.onAddAttendee;
    const onGetPeopleSuggestions = props.onGetPeopleSuggestions;
    const onClearPeopleSuggestions = props.onClearPeopleSuggestions;
    const onGetGroupSuggestions = props.onGetGroupSuggestions;
    const onClearGroupSuggestions = props.onClearGroupSuggestions;
    const placeholderText = props.placeholderText;
    const isAddingFavorite = props.isAddingFavorite;
    const identity = props.identity;
    const disableExternalAttendees = props.disableExternalAttendees || false;

    const dispatch = useDispatch();

    const [searchAttendeeQuery, setSearchAttendeeQuery] = useState("");
    const [seeMoreClicked, setSeeMoreClicked] = useState(false);
    const [addedAttendee, setAddedAttendee] = useState(undefined);
    const [attendeeSuggestionsTips, setAttendeeSuggestionsTips] = useState("");

    // Check if attendee suggestion contains all parts of the search query
    const filterSuggestionByQuery = (identifier, query) => {
        if (identifier === undefined || query === undefined) {
            return false;
        }

        let suggestionIdentifier = identifier.toLowerCase();
        let searchAttendeeQuery = query.replace(",", "").split(" ");

        return searchAttendeeQuery.every((queryItem) => suggestionIdentifier.includes(queryItem.toLowerCase()));
    };

    const peopleSuggestions = (props.peopleSuggestions && props.peopleSuggestions.map((person) => papiUserToAttendee(person))
        .filter((suggestion) => filterSuggestionByQuery(suggestion.identifier, searchAttendeeQuery)));
    const groupSuggestions = (props.groupSuggestions && props.groupSuggestions.map((group) => groupToAttendee(group))
        .filter((suggestion) => filterSuggestionByQuery(suggestion.identifier, searchAttendeeQuery)));
    const fullSuggestions = peopleSuggestions && groupSuggestions ?
        peopleSuggestions.concat(groupSuggestions)
        :
        peopleSuggestions || groupSuggestions;
    const suggestions = peopleSuggestions && groupSuggestions ?
        peopleSuggestions.slice(0, TRUNCATION_DEFAULT.PEOPLE_SUGGESTIONS).concat(groupSuggestions.slice(0, TRUNCATION_DEFAULT.GROUP_SUGGESTIONS))
        :
        (peopleSuggestions && peopleSuggestions.slice(0, TRUNCATION_DEFAULT.PEOPLE_SUGGESTIONS)) || (groupSuggestions && groupSuggestions.slice(0, TRUNCATION_DEFAULT.GROUP_SUGGESTIONS));
    const showSeeMore = fullSuggestions && fullSuggestions.length > suggestions.length;

    const emailSeparators = [";", ","];
    const emailSeparatorRegex = /[;,]/;

    const onShowToast = (toast) => dispatch(showToast(toast));

    // Add a valid email as an attendee and return true, else return false
    // Currently we are not allowing chime to be added through the search bar
    const addAttendeeIfValidEmail = (email) => {
        // Emails pasted from Outlook will have the email between <>
        let parsedEmail = (email || "").split("<").pop().split(">")[0].trim();

        // Ignore chime emails for now
        if (isValidEmail(parsedEmail) && !parsedEmail.endsWith("@chime.aws")) {
            let attendee = {
                email: parsedEmail,
                response: ATTENDEE_RESPONSE.NO_RESPONSE
            };

            if (isAddingFavorite) {
                dispatch(addFavoriteByEmail(parsedEmail, identity.username));
            } else {
                if (!disableExternalAttendees) {
                    setAddedAttendee(parsedEmail);
                    dispatch(addAttendeeByEmail(attendee, ATTENDEE_PRIORITY.REQUIRED, props.addExternalEmail));
                }
            }
            
            return true;
        }

        return false;
    };

    // Handle when suggestions are cleared
    const onClearSuggestions = useCallback(() => {
        onClearPeopleSuggestions();
        onClearGroupSuggestions();
        setSearchAttendeeQuery("");
    }, [onClearPeopleSuggestions, onClearGroupSuggestions, setSearchAttendeeQuery]);

    // Handle adding a suggestion
    const onSuggestionSubmit = (suggestionToAdd, currentSuggestions) => {
        // If suggestion is a string, user did not select a suggestion from the drop down list
        if (typeof suggestionToAdd === 'string') {
            let emails = suggestionToAdd.split(emailSeparatorRegex);

            // We received a list of emails
            if (emails.length > 1) {
                // Try to add each email
                emails.forEach((email) => addAttendeeIfValidEmail(email));
            // We received a single query
            } else {
                // Try to add the query if it is a valid email
                if (!addAttendeeIfValidEmail(suggestionToAdd) && currentSuggestions && currentSuggestions.length) {
                    // else add the first matching suggestion if present
                    let suggestion = currentSuggestions.find((suggestion) =>
                        suggestion.identifier.toLowerCase().includes(suggestionToAdd.toLowerCase()));
                    if (suggestion) {
                        onAddAttendee(suggestion);
                        setAddedAttendee(suggestion.identifier);
                    }
                }
                // TODO: update this to work with internal emails and multiple emails
                if (props.onSuggestionSubmit) {
                    props.onSuggestionSubmit(externalEmailToAttendee(suggestionToAdd));
                }
            }
        } else {
            // if suggestion is not a string, user selected a suggestion from the drop down list
            onAddAttendee(suggestionToAdd);
            setAddedAttendee(suggestionToAdd.identifier);
        }

        // Reset Search Box
        onClearSuggestions();
    };

    // Handle when search is selected
    const onSearchQueryChange = (query) => {
        // Update search query
        setSearchAttendeeQuery(query);
        let invalidEmails = "";
        let currentSeparator = emailSeparators.find((separator) => query.includes(separator));

        // This will handle list of emails or a single email with valid separator(s).
        // Handle emails when it fits these conditions:
        // - Contains '@' character to make sure they're emails
        // - Contains valid separators, semicolon or comma
        if (query.includes("@") && currentSeparator) {
            let emails = query.split(emailSeparatorRegex);

            emails.forEach((email) => {
                // This statement is to make sure to only check for emails, it handles the case where the outlook email is like:
                // LastName, FirstName <alias@amazon.com> and the split happens on the separator. This makes sure that the toast only contain invalid email formats.
                // If the emails are valid then it will be added by the addAttendeeIfValidEmail function when it's called
                if (email.includes("@") && !addAttendeeIfValidEmail(email)) {
                    invalidEmails += email + currentSeparator;
                }
            });
            // Reset Search Box
            onClearSuggestions();
        } else { // This else statement should handle a single email without separator, user alias or name
            // Handle pasting in a single email from Outlook without semicolon or comma separator
            if (query.includes("<") && query.includes(">")) {
                addAttendeeIfValidEmail(query);
                // Reset Search Box
                onClearSuggestions();
                return;
            }

            let emails = query.split(emailSeparatorRegex);
            emails = emails.map((email) => (email || "").trim());

            // Ignore chime emails for now
            if (emails && emails.find((email) => isValidEmail(email) && !email.endsWith("@chime.aws"))) {
                setAttendeeSuggestionsTips(" " + EMAIL_SUGGESTIONS_TIP);
            } else if (props.workflow === WORKFLOWS.LIVESTREAM.NAME && props.selectedAttendeeRole === ATTENDEE_ROLE.LIVESTREAM.PRESENTER) {
                setAttendeeSuggestionsTips(" " + PRESENTER_SUGGESTIONS_TIP);
            } else {
                setAttendeeSuggestionsTips("");
            }
            // Update Suggestions if query criteria is met
            if (query && query.length >= ATTENDEE_SEARCH_BAR_MINIMUM_QUERY_LENGTH && !emailSeparators.find((separator) => query.includes(separator)) && !query.includes("@")) {
                onGetPeopleSuggestions(query);
                onGetGroupSuggestions(query);
                setAddedAttendee(undefined);
            }

            // reset seeMoreClicked
            setSeeMoreClicked(false);
        }

        // if there are invalid email formats, then show the toast message
        if (invalidEmails) {
            const toast = getErrorToastWithComponent();
            toast.toastActionProps.text = `Invalid email format for "${invalidEmails}". Read more on`;
            toast.toastActionProps.linkText = "supported email formats.";
            toast.toastActionProps.href = "https://w.amazon.com/bin/view/Meetex/AM2/Resources/EmailFormats/";
            toast.toastActionProps.componentName = TOAST_COMPONENT.LINK;
            toast.toastTimeout = TIMEOUT.LONG;
            onShowToast(toast);
        }
    };

    // Handle when Suggestion is selected
    const onSearchSuggestionClick = (suggestion) => {
        if (suggestion) {
            onSuggestionSubmit(suggestion, suggestions);
        }
    };

    // Handle when "See more..." is clicked
    const onSeeMoreClick = useCallback(() => {
        setSeeMoreClicked(true);
    }, [setSeeMoreClicked]);

    const getSearchDescription = () => {
        if (searchAttendeeQuery && searchAttendeeQuery.length >= ATTENDEE_SEARCH_BAR_MINIMUM_QUERY_LENGTH && fullSuggestions) {
            if (((showSeeMore && !seeMoreClicked) ? suggestions : fullSuggestions).length !== 0) {
                return `${((showSeeMore && !seeMoreClicked) ? suggestions : fullSuggestions).length} attendee suggestion(s) are available, press the down key to enter the list box with the suggestions.`;
            } else {
                return NO_ATTENDEE_SUGGESTIONS + attendeeSuggestionsTips;
            }
        } else if (addedAttendee !== undefined) {
            return `${addedAttendee} added to meeting`;
        }

        return "";
    };

    return (
        <div id="amw-attendee-search-id">
            <SearchField
                aria-labelledby={props["aria-labelledby"]}
                value={searchAttendeeQuery}
                closeOnClickSuggestion={false}
                onChange={onSearchQueryChange}
                onSubmit={(suggestion) => onSuggestionSubmit(suggestion, suggestions)}
                placeholder={placeholderText ?? "Search for and add attendees to this meeting"}
                type="search"
                searchButton={false}
                width={width}
                popoverMaxHeight={popoverHeight + (seeMoreClicked ? ATTENDEE_SEARCH_BAR_SUGGESTION_HEIGHT : 0)}
                onClear={onClearSuggestions}
                description={getSearchDescription()}
            >
                {/* SearchSuggestion must be direct child of SearchField. Undefined value needed to not show any pop up */}
                {searchAttendeeQuery && searchAttendeeQuery.length >= ATTENDEE_SEARCH_BAR_MINIMUM_QUERY_LENGTH && fullSuggestions && fullSuggestions.length ?
                    ((showSeeMore && !seeMoreClicked) ? suggestions : fullSuggestions).map((suggestion) => (
                        <SearchSuggestion
                            onClick={() => onSearchSuggestionClick(suggestion)}
                            key={`${suggestion.identifier} ${suggestion.jobTitle ? suggestion.jobTitle : UNKNOWN_JOB_TITLE}`}
                        >
                            {({highlighted, query = ""}) => (
                                <Row
                                    suggestion={`${suggestion.identifier} ${suggestion.jobTitle ? suggestion.jobTitle : UNKNOWN_JOB_TITLE}`}
                                    width="100%"
                                    widths={["fit", "fill", "fit"]}
                                    spacing="small"
                                    wrap="down"
                                >
                                    <img
                                        src={suggestion.imageLink || groupSvg}
                                        height={suggestion.imageLink ? "35px" : "25px"}
                                        aria-hidden="true"
                                        alt=""
                                        style={suggestion.imageLink || !highlighted ?
                                            {filter: "none"} : {filter: "grayscale(100%) brightness(5)"}}
                                    />
                                    <HighlightSubstring
                                        match={query}
                                        color={highlighted ? "inverted" : "primary"}
                                    >
                                        {suggestion.identifier}
                                    </HighlightSubstring>
                                    <HighlightSubstring
                                        match={query}
                                        color={highlighted ? "inverted" : "primary"}
                                    >
                                        {suggestion.jobTitle ? suggestion.jobTitle : UNKNOWN_JOB_TITLE}
                                    </HighlightSubstring>
                                </Row>
                            )}
                        </SearchSuggestion>
                    ))
                    :
                    undefined
                }
                {(searchAttendeeQuery && searchAttendeeQuery.length >= ATTENDEE_SEARCH_BAR_MINIMUM_QUERY_LENGTH) && !fullSuggestions ?
                    <Column alignmentHorizontal="center" spacing="none" spacingInset="small">
                        <Text>Searching...</Text>
                        <Loader size="medium"></Loader>
                    </Column>
                    :
                    undefined
                }
                {(searchAttendeeQuery && searchAttendeeQuery.length >= ATTENDEE_SEARCH_BAR_MINIMUM_QUERY_LENGTH) && fullSuggestions && !fullSuggestions.length ?
                    <Column alignmentHorizontal="center" spacing="none" spacingInset="small">
                        <Text alignment="center">{NO_ATTENDEE_SUGGESTIONS + attendeeSuggestionsTips}</Text>
                    </Column>
                    :
                    undefined
                }
                {showSeeMore && !seeMoreClicked && (
                    <SearchSuggestionFooter>
                        <Row widths={["fill"]}>
                            <Button type={"link"} onClick={onSeeMoreClick}>
                                See more...
                            </Button>
                        </Row>
                    </SearchSuggestionFooter>
                )}
            </SearchField>
        </div>
    );
};

export default AttendeeSearchBar;
