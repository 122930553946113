import React, { useRef, useState } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Icon from "../../meridian-custom-components/src/components/icon";

import infoKnockoutTokens from "@amzn/meridian-tokens/base/icon/info-knockout"

import "../settings.css";
import Popover from "@amzn/meridian/popover";
import { Noop } from "../settings-utils";

const FormInputItem = (props) => {
    const iconRef = useRef();
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const item = props.item;

    return (
        <Row id={props.largeMargin ? "settings-form-input-item-row-large-margin" : "settings-form-input-item-row-small-margin"}>
            <Column spacing="small" width="fill">
                {item.header &&
                    <Row>
                        <Heading id={item.header} level={5}>{item.header}</Heading>
                        {item.tooltipComponent &&
                            <React.Fragment>
                                <span ref={iconRef} onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)}>
                                    <Icon tokens={infoKnockoutTokens}/>
                                </span>
                                <Popover
                                    onClose={Noop}
                                    anchorNode={iconRef.current}
                                    open={tooltipOpen}
                                    spacingInset={item.noTooltipPadding ? "none" : "medium"}
                                    position="right"
                                    id="settings-tooltip"
                                >
                                    {item.tooltipComponent}
                                </Popover>
                            </React.Fragment> 
                            
                        }
                    </Row>
                }
                {item.inputComponent &&
                    <Row>
                        {item.inputComponent}
                    </Row>
                }
            </Column>
        </Row>
    );
}

const FormInputList = (props) => {
    return (
        <Column width="100%" spacing="large">
            {props.inputItems.map((item, index) => (
                <FormInputItem key={"FormInputList" + index} largeMargin={props.largeMargin} item={item}/>
            ))}
        </Column>
    );
};

export default FormInputList;