import * as Actions from "./action-types";

export const createMeeting = (meeting, username) => {
    return {type: Actions.CREATE_MEETING, meeting, username}
};

export const clearDraft = () => {
    return {type: Actions.CLEAR_DRAFT};
};

export const turnMeetingRemindersOff = (turnMeetingRemindersOffRequest) => {
    return {type: Actions.TURN_MEETING_REMINDERS_OFF, turnMeetingRemindersOffRequest};
};

export const updateMeeting = (meeting) => {
    return {type: Actions.UPDATE_MEETING_ASYNC, meeting};
};

export const changeStep = (step) => {
    return {type: Actions.CHANGE_STEP, step}
};

export const setLoadOOTO = (loadOOTO) => {
    return {type: Actions.SET_LOAD_OOTO, loadOOTO};
};

export const setAwarenessMeeting = (awarenessMeeting) => {
    return {type: Actions.SET_AWARENESS_MEETING, awarenessMeeting};
};

export const getAwarenessMeetingAndSave = (requestedOnBehalfOf, uniqueOrEntryID, master, propertyNames) => {
    return {type: Actions.GET_AWARENESS_MEETING_AND_SAVE, requestedOnBehalfOf, uniqueOrEntryID, master, propertyNames};
};

export const getMeeting = (requestedOnBehalfOf, uniqueOrEntryID, master, propertyNames) => {
    return {type: Actions.GET_MEETING, requestedOnBehalfOf, uniqueOrEntryID, master, propertyNames};
};

export const setMaster = (master) => {
    return {type: Actions.SET_MASTER, master};
};

export const setCreatingMeetingState = (isCreatingMeeting) => {
    return {type: Actions.SET_CREATING_MEETING_STATE, isCreatingMeeting};
};