import {
    EVENT_PLATFORM,
    LIVESTREAM_PERMALINK_DETAILS,
    LIVESTREAM_PREPARATION_MINUTES,
    LIVESTREAM_URL_PATH_PARAMETER,
    SIGN_LANGUAGE_INTERPRETER_OPTION,
} from "./livestream-constants";
import { convertEpoch } from "../meeting-scheduler/meeting-scheduler-utils";
import { timezoneIdToDisplayName } from "../shared/timezones/timezones-utils";
import { ATTENDEE_ROLE, ATTENDEE_TYPE } from "../people/people-constants";

// Create the title and description for the livestream ticket based on the selected requirements and meeting details
const generateTicketDetails = (eventRequirements, meeting, attendees, timezones, selectedStations, is12HourTimeFormat, permalinkMessage = "") => {

    let description = [];
    const startEpoch = meeting.time.startTime + (LIVESTREAM_PREPARATION_MINUTES * 60);
    const endEpoch = meeting.time.endTime;
    const timezone = meeting.startTimezone.id;
    const duration = `${(endEpoch - startEpoch) / 60} minutes`;
    let startDate = convertEpoch(startEpoch, "date", timezone);
    let endDate = convertEpoch(endEpoch, "date", timezone);
    const startTime = convertEpoch(startEpoch, "time", timezone, is12HourTimeFormat);
    const endTime = convertEpoch(endEpoch, "time", timezone, is12HourTimeFormat);
    const eventDate = startDate === endDate ? startDate : `${startDate} - ${endDate}`;
    const eventPreflightInSeconds = LIVESTREAM_PREPARATION_MINUTES * 60;
    const timeZoneName = timezoneIdToDisplayName(timezones, timezone, "short");
    const time = `${startTime} - ${endTime} ${timeZoneName}`;
    const includePreflight = convertEpoch(startEpoch - eventPreflightInSeconds, "time", timezone); // include preflight
    const preflight = `${includePreflight} - ${startTime} ${timeZoneName}`;
    const livestreamTitle = eventRequirements["livestreamTitle"].trim();
    const eventTitle = eventRequirements["eventName"].trim()
    const livestreamEventTitle = livestreamTitle + ((eventTitle && (livestreamTitle !== eventTitle)) ? (`: ${eventTitle}`) : "");
    const eventDescription = eventRequirements["eventDescription"].replace(/<[^>]+>/g, '');
    const eventRequestType = eventRequirements["eventRequestType"];
    const recordingEventRequestType = "RECORDING";
    const isEventNotRecordingType = eventRequestType !== recordingEventRequestType;

    const getEventRequestType = () => {
        switch (eventRequestType) {
            case "LIVE": {
                return "Live event"
            }
            case "EVENT_PLAYBACK": {
                return "Event playback"
            }
            case "RECORDING": {
                return "Recording only"
            }
            default: {
                return console.error("There should be at least one event request type selected, as the series will have specifically 3 types of events.");
            }
        }
    };

    // If ticket was created with permalink, add description for where it is from
    if (permalinkMessage) {
        description.push("**" + LIVESTREAM_PERMALINK_DETAILS.NO_STATIONS_TITLE + "** " + permalinkMessage);
    }

    description.push(`
        EVENT STORY
        * **Event type:** ${getEventRequestType()}
        * **Event category:** Manual confirmation
        * **Operations mode:** Manual spin-up
        ${(eventRequestType === recordingEventRequestType ? "* **Recording title:** " : "* **Livestream event title:** ") + livestreamEventTitle}
        * **Event description:** ${eventDescription}
        * **Event date:** ${eventDate}
        * **Event pre-flight:** ${preflight}
        * **Event time:** ${time} (${duration})
        ${isEventNotRecordingType ? "* **Remote viewers expected:** " + eventRequirements["viewerCount"] : "  "}
    `);

    let eventChat = "";
    const slackChannelEnabled = isEventNotRecordingType && (eventRequirements["allowChat"] && eventRequirements["slackEnabled"]);
    const chatEnabled = isEventNotRecordingType && (eventRequirements["allowChat"] && !eventRequirements["slackEnabled"]);
    const slackChannelUrl = isEventNotRecordingType && ((eventRequirements["allowChat"] && eventRequirements["slackEnabled"] && eventRequirements["slackChannel"]) || "");
    if (!chatEnabled && !slackChannelEnabled) {
        eventChat = "None";
    } else if (chatEnabled) { //chatEnabled refers to nativeChat
        eventChat = "Yes, Native chat"
    } else if (slackChannelEnabled && slackChannelUrl) {
        const pathArray = slackChannelUrl.split('/');
        const slackChannelName = pathArray[3].split('=')[1] || "";
        const slackWorkspace = pathArray[2] || "";
        eventChat = "Yes, Slack chat - Workspace name: " + slackWorkspace + ", Channel name: " + slackChannelName + ", URL: " + slackChannelUrl;
    }

    const streamingPlatforms = eventRequirements["platforms"]?.map((platform) => {
        return (EVENT_PLATFORM[platform].LABEL === "livestream.amazon.dev" ? "Internal: " : "Public: ") + EVENT_PLATFORM[platform].LABEL
    }).join(", ");

    description.push(`
        EVENT FEATURES
        ${eventRequestType !== "EVENT_PLAYBACK" ? "* **Video/audio playback:** " + (eventRequirements["videoPlayback"] ? "Yes" : "No") : "  "}
        * **Multiple livestream sessions running simultaneously:** ${eventRequirements["multipleLivestreams"] ? "Yes" : "No"}
        ${isEventNotRecordingType ? "* **Streaming platform(s):** " + streamingPlatforms : "  "}
        ${isEventNotRecordingType ? "* **Event chat:** " + eventChat : "  "}
    `);

    const presenters = [];
    attendees.forEach((attendee) => {
        if (attendee.email && attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER) {
            if (attendee.type === ATTENDEE_TYPE.EXTERNAL_EMAIL) {
                presenters.push(`${attendee.email} (External)`);
            } else {
                presenters.push(attendee.identifier);
            }
        }
    });
    const presenterDescription = presenters.length ? `${presenters.length} - ${presenters.join(", ")}` : "0 - No presenters added";
    const ccOptions = eventRequirements["isAnyPresenterL10"] && eventRequirements["ccOptions"];

    const getCcOptions = () => {
        switch (ccOptions) {
            case "DISCLAIMER": {
                return "Display a disclaimer on event description"
            }
            case "NEED_STENOGRAPHER": {
                return "Hire a stenographer"
            }
            default: {
                return console.error("There has to be at least one cc option enabled when L10 presenter is opted.");
            }
        }
    };

    const eventLocation = eventRequirements["eventLocation"];
    const conferenceRooms = meeting.location.split(",");

    const getEventLocation = (isConfRoomsAdded) => {
        switch(eventLocation) {
            case "REMOTE_OR_VIRTUAL": {
                return "Remote/virtual"
            }
            case "MEETING_ROOM": {
                if (isConfRoomsAdded) {
                    return conferenceRooms?.length ? conferenceRooms.join(", ") : "Remote/virtual"
                } else {
                    return "Amazon AV/VC meeting room"
                }
            }
            case "OFF_CAMPUS": {
                return "Off campus"
            }
            default: {
                return console.error("The should be at least one event Location for an event in a series.");
            }
        }
    };

    const viewershipRestrictions = (eventRequirements["restricted"] && eventRequirements["restrictedGroups"]) || [];

    const restrictedGroups = viewershipRestrictions?.map((permission) => {
        return permission.name + " (" + permission.type + ")";
    }).join(", ");

    const needsASLInterpreter = SIGN_LANGUAGE_INTERPRETER_OPTION[eventRequirements["signLanguageInterpreter"]];
    const presentationLanguage = eventRequirements["nonEnglish"] ? [""] : ["English"];

    description.push(`
        ATTENDEES' NEEDS
        * **L10/L10+ and/or VIP presenters:** ${eventRequirements["isAnyPresenterL10"] ? "Yes" : "No"}
        * **Closed-captioning option:** ${eventRequirements["isAnyPresenterL10"] ? getCcOptions() : "None"}
        * **Presenter(s):** ${presenterDescription}
        * **Restrict to specific group(s):** ${viewershipRestrictions.length > 0 ? ("Yes, " + restrictedGroups) : "No"}
        ${eventRequestType !== "EVENT_PLAYBACK" ? "* **Event location:** " + getEventLocation() : "  "}
        * **Audience in Fulfillment centers:** ${eventRequirements["isAudienceInFCs"] ? "Yes" : "No"}
        * **Sign language services:** ${needsASLInterpreter}
        * **English only:** ${presentationLanguage.length === 1 && presentationLanguage[0] === "English" ? "Yes" : "No"}
    `);

    const recordingType = eventRequirements["recordingType"];

    description.push(`
        POST EVENT REQUIREMENTS
        * **Recording after the stream:** ${recordingType !== "PRIVATE_DRIVE" && recordingType !== "BROADCAST" ? "No" : "Yes"}
        * **Footage delivery:** ${recordingType === "PRIVATE_DRIVE" ? "Private Drive" : recordingType === "BROADCAST" ? "Broadcast upload" : "No"}
        * **Preferred Broadcast URL:** ${recordingType === "BROADCAST" ? ("https://broadcast.amazon.com/channels/" + eventRequirements["previousBroadcastUrl"]) : "None"}
        * **Permission to upload to Broadcast:** ${eventRequirements["broadcastPermission"] ? "Yes" : "No"}
    `);

    const pointsOfContact = eventRequirements["pointsOfContact"].map((pointOfContact) => {
        return pointOfContact.alias;
    }).join(", ");

    const contactChannels = eventRequirements["contactChannels"];
    const phoneNumber = eventRequirements["phoneNumber"];

    const getContactChannels = {
        "contactChannelsType": contactChannels,
        "phoneNumber": phoneNumber
    };

    description.push(`
        ADDITIONAL DETAILS
        * **Main contact(s):** ${pointsOfContact}
        * **Best contact channel(s):** ${getContactChannels.contactChannelsType.join(", ")}
        ${getContactChannels.phoneNumber !== "" ? (` * **Phone number(s):** ` + getContactChannels.phoneNumber) : "  "}
        ${eventRequirements["notes"] ? `* **Notes for Livestream Operations:** ${eventRequirements["notes"]}` : "  "}
    `);

    const chimePinEmail = meeting.requiredAttendees.filter((attendee) => {
        return attendee.email && attendee.email.endsWith("@chime.aws") && attendee.email.includes("pin+");
    })[0]?.email;
    const chimeId = chimePinEmail && chimePinEmail.slice(chimePinEmail.indexOf("pin+") + 4, chimePinEmail.indexOf("@"));

    description.push(`
        AMAZON MEETINGS
        * **Livestream unit(s) booked:** ${selectedStations?.length ? selectedStations.join(", ") : "None"}
        * **Dial in location:** ${getEventLocation(true)}
        * **Chime ID:** ${chimeId}
    `);

    // Remove indentation and trim whitespace from strings
    description = description.map((string) => {
        return string.replaceAll("    ", "").trim();
    });

    const descriptionString = description.join("\n\n");
    const title = `${getEventRequestType()}: ${livestreamEventTitle} | ${eventDate} | ${time} | ${getEventLocation(true)}`;
    return {
        title: title,
        description: descriptionString,
    };
};

// Generate a permalink to create a livestream ticket manually
export const generateLivestreamTicketPermalink = (eventRequirements, meeting, attendees, timezones, selectedStations, is12HourTimeFormat, permalinkMessage) => {
    // Original LiveOps ticket template: https://t.corp.amazon.com/create/templates/6e5ae1ef-d225-4bba-a821-0b3bf07b98f3
    let ticketUrl = "https://t.corp.amazon.com/create/options?";

    const ticketDetails = generateTicketDetails(eventRequirements, meeting, attendees, timezones, selectedStations, is12HourTimeFormat, permalinkMessage);

    const watchers = [];
    eventRequirements["pointsOfContact"].forEach((pointOfContact) => {
        if (pointOfContact.email && pointOfContact.type === ATTENDEE_TYPE.PERSON) {
            watchers.push(pointOfContact.alias); // only add internal users as watchers
        }
    });

    // Query parameters to fill out livestream ticket details
    let ticketQueryParameters = {
        category: "Livestreaming",
        type: "Coordination",
        item: "Initial Request",
        severity: "FIVE",
        group: "Livestreaming Team",
        watchers: watchers.join(","),
        title: ticketDetails.title,
        description: ticketDetails.description.replaceAll("&", "and"), // replace ampersands with and to avoid permalink breaking
    };

    Object.keys(ticketQueryParameters).forEach((parameter) => {
        ticketUrl += (`&${parameter}=${ticketQueryParameters[parameter]}`.trim());
    });

    //encodeUri is not encoding the # character so SIM-T is not able to parse it, so # used in live url is explicitly replaced with UTF-8 encoding
    return encodeURI(ticketUrl.trim()).replace(LIVESTREAM_URL_PATH_PARAMETER, '/%23/live/');
};

export const generateEventIntakeTicket = (eventRequirements, meeting, is12HourTimeFormat, timezones, attendees, selectedStations) => {
    const meetingStartTime = meeting.time.startTime;
    const eventStartTimeEpoch = meetingStartTime + (LIVESTREAM_PREPARATION_MINUTES * 60);
    const eventEndTimeEpoch = meeting.time.endTime;

    // Assign the organizer of the event as the ticket's requester
    const requesterAlias = attendees.find((attendee) => attendee.alias && attendee.isOrganizer)?.alias || "";

    const locations = meeting.location.split(",");

    const getPresenters = () => {
        const presenters = [];
        attendees.forEach((attendee) => {
            if (attendee.email && attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER) {
                if (attendee.type === ATTENDEE_TYPE.EXTERNAL_EMAIL) {
                    presenters.push(`${attendee.email} (External)`);
                } else {
                    presenters.push(attendee.identifier);
                }
            }
        });
        return presenters;
    };

    const getContactChannels = () => {
        const contactChannels = eventRequirements["contactChannels"];
        const phoneNumber = eventRequirements["phoneNumber"];
        return {
            "contactChannelsType": contactChannels,
            "phoneNumber": phoneNumber
        }
    };

    const language = () => {
        return eventRequirements["nonEnglish"] ? [""] : ["English"];
    };

    const chimePinEmail = meeting.requiredAttendees.filter((attendee) => {
        return attendee.email && attendee.email.endsWith("@chime.aws") && attendee.email.includes("pin+");
    })[0]?.email;
    const chimeId = chimePinEmail && chimePinEmail.slice(chimePinEmail.indexOf("pin+") + 4, chimePinEmail.indexOf("@"));

    const getLivestreamUrl = () => {
        const reuseUrl = eventRequirements["reuseUrl"];
        return reuseUrl ? eventRequirements["existingLivestreamUrl"].trim()
            : eventRequirements["desiredLivestreamUrl"].trim();
    };

    const getStreamingPlatforms = () => {
        const platforms = eventRequirements["platforms"];
        return platforms.map((streamingPlatform) => EVENT_PLATFORM[streamingPlatform].LABEL);
    };

    const getPointsOfContact = () => {
        const pocAttendees = eventRequirements["pointsOfContact"];
        return pocAttendees.map((poc) => poc.alias);
    };

    const eventPreflightInSeconds = LIVESTREAM_PREPARATION_MINUTES * 60;

    const eventRequestType = eventRequirements["eventRequestType"];
    const isEventNotRecordingType = eventRequestType !== "RECORDING";

    const eventIntakeRequest = {
        "url": isEventNotRecordingType ? getLivestreamUrl() : undefined,
        "startTime": eventStartTimeEpoch,
        "endTime": eventEndTimeEpoch,
        "timeZone": meeting.startTimezone.id,
        "isExistingUrl": eventRequirements["reuseUrl"],
        "requester": requesterAlias,
        "seriesTitle": eventRequirements["livestreamTitle"].trim(),
        "eventTitle": isEventNotRecordingType ? eventRequirements["eventName"].trim() : undefined,
        "description": eventRequirements["eventDescription"],
        "expectedNumberOfViewers": isEventNotRecordingType ? eventRequirements["viewerCount"] : undefined,
        "expectedNumberOfViewersJustification":  isEventNotRecordingType ? eventRequirements["viewerJustification"] : undefined,
        "expectedNumberOfViewersAdditional":  isEventNotRecordingType ? eventRequirements["expectedViewerCount"] : undefined,
        "eventRequestType": eventRequirements["eventRequestType"],
        "broadcastUrl": eventRequirements["previousBroadcastUrl"],
        "uploadBroadcastPermission": eventRequirements["broadcastPermission"],
        "hasVideoOrAudioPlayback": (eventRequestType !== "EVENT_PLAYBACK") ? eventRequirements["videoPlayback"] : undefined,
        "isMultiTrackEvent": eventRequirements["multipleLivestreams"],
        "chatEnabled": isEventNotRecordingType ? (eventRequirements["allowChat"] && !eventRequirements["slackEnabled"]) : undefined, //chatEnabled refers to native chat
        "slackChannelEnabled": isEventNotRecordingType ? (eventRequirements["allowChat"] && eventRequirements["slackEnabled"]) : undefined,
        "slackChannelName": isEventNotRecordingType ? ((eventRequirements["allowChat"] && eventRequirements["slackEnabled"] && eventRequirements["slackChannel"]) || "") : undefined,
        "streamingPlatforms": isEventNotRecordingType ? getStreamingPlatforms() : undefined,
        "needsASLInterpreter": SIGN_LANGUAGE_INTERPRETER_OPTION[eventRequirements["signLanguageInterpreter"]],
        "conferenceRooms": locations,
        "needsStenographer": eventRequirements["isAnyPresenterL10"] && eventRequirements["ccOptions"] === "NEED_STENOGRAPHER",
        "isAnyPresenterL10": eventRequirements["isAnyPresenterL10"],
        "ccOptions": eventRequirements["isAnyPresenterL10"] ? eventRequirements["ccOptions"] : undefined,
        "eventLocation": eventRequirements["eventLocation"],
        "isAudienceInFCs": eventRequirements["isAudienceInFCs"],
        "viewershipRestrictions": (eventRequirements["restricted"] && eventRequirements["restrictedGroups"]) || [],
        "presentationLanguage": language(),
        "pointsOfContact": getPointsOfContact(),
        "contactChannels": getContactChannels(),
        "presenters": getPresenters(),
        "additionalNotes": eventRequirements["notes"],
        "chimeId": parseInt(chimeId),
        "lsStations": selectedStations,
        "recordingType": eventRequirements["recordingType"],
        "oid": eventRequirements["seriesOid"],
        "eventPreflightInSeconds": eventPreflightInSeconds
        // "reactionsEnabled": eventRequirements["reactions"] // TODO: will uncomment once reactions feature is enabled in prod.
    };
    return eventIntakeRequest;
};