import { call, put, delay, select, takeEvery } from "redux-saga/effects";

import { getAlias, getIdentity, getIdentityTokens } from "../../../../sagas/selector";
import { TIME_CONSTANT } from "../../shared-constants";
import * as Actions from "../actions/action-types";
import { postCustomMetric } from "../panorama";
import { getFeedbackEligibility, setFeedbackAlertMessage } from "../../feedback/actions";

function* getContext() {
    let identity = yield select(getIdentity);

    // For our initial metrics on page load, the identity is not yet set.
    // Wait for identity to load so we can set the context correctly.
    while ((identity || {}).username === undefined) {
        yield delay(TIME_CONSTANT.ONE_SEC_IN_MS); // check if identity loaded once a second
        identity = yield select(getIdentity);
    }

    return JSON.stringify({
        alias: identity.username,
        jobTitle: identity.title,
        exchangeOnlineMailbox: identity.exchangeOnlineMailbox ? 1 : 0
    });
}

function* postMetric(eventType, eventName, eventDetail, eventValue) {
    const eventContext = yield call(getContext);
    const { idToken } = yield select(getIdentityTokens);
    postCustomMetric(eventType, eventName, eventContext, eventDetail, eventValue, idToken);
}

export function* postRatingMetric(action) {
    let feedback = action.feedback;
    let isModal = action.isModal;
    let submitted = action.onSubmitClicked;

    // Closed feedback modal without submitting, regardless if they inputted a rating.
    if (!submitted) {
        feedback = isModal ? "User closed feedback modal." : "User did not click submit on the widget." + feedback;
    }
    else if (!feedback || feedback === "") {
        feedback = isModal ? "User provided no feedback for the modal." : "User provided no feedback for the widget.";
    }
    yield call(postMetric, "Feedback", "User rating", feedback, action.rating && action.rating.toString());
}

export function* watchPostRatingMetric() {
    yield takeEvery(Actions.POST_RATING_METRIC, postRatingMetric);
}

function* postRASAPICallMetric(action) {
    yield call(postMetric, "ResourceAdapterService call", action.operationName, action.errorDetails, action.success ? "1" : "0");
}

export function* watchPostRASAPICallMetric() {
    yield takeEvery(Actions.POST_RAS_API_CALL_METRIC, postRASAPICallMetric);
}

function* postAMSAPICallMetric(action) {
    yield call(postMetric, "AmazonMeetingsService call", action.operationName, action.errorDetails, action.success ? "1" : "0");
}

export function* watchPostAMSAPICallMetric() {
    yield takeEvery(Actions.POST_AMS_API_CALL_METRIC, postAMSAPICallMetric);
}

function* postLivestreamAPICallMetric(action) {
    yield call(postMetric, "Livestream API call", action.operationName, action.errorDetails, action.success ? "1" : "0");
}

export function* watchPostLivestreamAPICallMetric() {
    yield takeEvery(Actions.POST_LIVESTREAM_API_CALL_METRIC, postLivestreamAPICallMetric);
}

function* postCreateMeetingRoomsMetrics(action) {
    const allRoomEmails = JSON.stringify(action.allRooms.map((room) => room.email));
    yield call(postMetric, "CreateMeeting Rooms", "Total rooms", allRoomEmails, action.allRooms.length.toString());

    if (action.allRooms.length > 0) {
        const noResponseRoomEmails = JSON.stringify(action.noResponseRooms.map((room) => room.email));
        yield call(postMetric, "CreateMeeting Rooms", "No response rooms", noResponseRoomEmails, action.noResponseRooms.length.toString());

        const acceptedRoomEmails = JSON.stringify(action.acceptedRooms.map((room) => room.email));
        yield call(postMetric, "CreateMeeting Rooms", "Accepted rooms", acceptedRoomEmails, action.acceptedRooms.length.toString());

        const declinedRoomEmails = JSON.stringify(action.declinedRooms.map((room) => room.email));
        yield call(postMetric, "CreateMeeting Rooms", "Declined rooms", declinedRoomEmails, action.declinedRooms.length.toString());
    }
}

export function* watchPostCreateMeetingRoomsMetrics() {
    yield takeEvery(Actions.POST_CREATE_MEETING_ROOMS_METRICS, postCreateMeetingRoomsMetrics);
}

function* postCopyToNewCreateMeetingMetrics(action) {
    let details = `copiedToNew (${action.copiedToNew}), sameOrganizer (${action.sameOrganizer}), hadRecurrence (${action.hadRecurrence}), hasRecurrence (${action.hasRecurrence})`;
    yield call(postMetric, "Copy to new", "createMeeting", details, action.copiedToNew ? "1" : "0");
}

function* watchPostCopyToNewCreateMeetingMetrics() {
    yield takeEvery(Actions.POST_COPY_TO_NEW_CREATE_MEETING_METRICS, postCopyToNewCreateMeetingMetrics)
}

function* postUnknownEdgeLocationMetric(action) {
    yield call(postMetric, "ResourceAdapterService endpoint selection", "Unmapped edge location", `Edge location (${action.edgeLocation}) not in map, defaulting to PDX region`, "1");
}

export function* watchPostUnknownEdgeLocationMetric() {
    yield takeEvery(Actions.POST_UNKNOWN_EDGE_LOCATION_METRIC, postUnknownEdgeLocationMetric);
}

function* postRequesterEmailRetryMetric(action) {
    yield call(postMetric, "ResourceAdapterService retry", "Retry requester email", `Operation (${action.operation}) failed with email (${action.failedEmail}), retried with email (${action.retryEmail})`, action.success ? "1" : "0");
}

export function* watchPostRequesterEmailRetryMetric() {
    yield takeEvery(Actions.POST_REQUESTER_EMAIL_RETRY_METRIC, postRequesterEmailRetryMetric)
}

function* postAvailabilitySharingCopyToClipboardMetric(action) {
    let alias = yield select(getAlias);
    yield put(setFeedbackAlertMessage("Availability copied"));
    yield put(getFeedbackEligibility(alias));
    yield call(postMetric, "Availability sharing", "Availability copied to clipboard", `User (${action.userEmail}) shared availability with a duration of (${action.timeRangeDurationInDays}) days`, "1");
}

export function* watchPostAvailabilitySharingCopyToClipboardMetric() {
    yield takeEvery(Actions.POST_AVAILABILITY_SHARING_COPY_TO_CLIPBOARD_METRIC, postAvailabilitySharingCopyToClipboardMetric)
}

function* postQueryParamsLinkMetric(action) {
    let failedQueryParametersMessage = "Failed query parameters - ";
    const invalidParameters = action.invalidParameters;
    if (invalidParameters.length > 0) {
        failedQueryParametersMessage += invalidParameters.join(",");
    } else {
        failedQueryParametersMessage = "";
    }
    yield call(postMetric, "Permalink Used", action.queryParamsSource, failedQueryParametersMessage, invalidParameters.length > 0 ? "0" : "1");
}

export function* watchPostQueryParamsLinkMetric() {
    yield takeEvery(Actions.POST_QUERY_PARAMS_LINK_METRIC, postQueryParamsLinkMetric);
}

function* postCreateLivestreamEventMetric(action) {
    const meetingUniqueIds = action.meetingUniqueIds.join(", ");
    const meetingEntryIds = action.meetingEntryIds.join(", ");
    const failedMeetings = action.failedMeetings.join(", ");
    const details = `ticketSuccess (${action.ticketSuccess}), ticketId (${action.ticketId}), ticketErrorResponse (${action.ticketErrorResponse}), meetingUniqueIds (${meetingUniqueIds}), meetingEntryIds (${meetingEntryIds}, failedMeetings (${failedMeetings})`;
    const success = action.ticketSuccess && !failedMeetings.length ? "1" : "0"; // event creation succeeds if ticket and all meetings succeed

    yield call(postMetric, "Livestream event", "createLivestreamEvent", details, success);
}

export function* watchPostCreateLivestreamEventMetric() {
    yield takeEvery(Actions.POST_CREATE_LIVESTREAM_EVENT_METRIC, postCreateLivestreamEventMetric);
}

function* postNoValidChimeEmailFoundMetric(action) {
    const userEmails = action.userEmails.join(", ");
    const notFound = action.validEmail === undefined ? "1" : "0"
    const details = `${action.validEmail === undefined ? "No valid" : action.validEmail} chime email found for user (${action.userAlias}), emails (${userEmails})`;

    yield call(postMetric, "Validate Chime Email", "noValidChimeEmailFound", details, notFound);
}

export function* watchPostNoValidChimeEmailFoundMetric() {
    yield takeEvery(Actions.POST_NO_VALID_CHIME_EMAIL_FOUND_METRIC, postNoValidChimeEmailFoundMetric);
}

function* postSaveUserPreferencesMetric(action) {
    const details = `Save user preferences for user (${action.userAlias}), saveUserPreferenceSuccess (${action.savePreferencesSuccess}), errorResponse (${action.preferencesErrorResponse})`;
    const success = action.savePreferencesSuccess ? "1" : "0"; // event creation succeeds if ticket and all meetings succeed

    // update eventName to distinguish wether the user went to preference page
    const eventName = `saveUserPreferences${action.fromPreferencePage ? "FromPreferencePage" : "FromOtherPages"}`;

    yield call(postMetric, "Save User Preferences", eventName, details, success);
}

export function* watchPostSaveUserPreferencesMetric() {
    yield takeEvery(Actions.POST_SAVE_USER_PREFERENCES_METRIC, postSaveUserPreferencesMetric);
}

function* postCreateMeetingPollEventMetric(action) {
    const eventType = "Create meeting poll";
    const eventName = `CreateMeetingPoll${action.fromPage}`;
    // To reduce the request we sent to the cloudwatch, we can create one formatted detail messages with all the value we need
    // When create the dashboard we can use *parse* function on cloudwatch to generate the table we needed
    const eventDetails = 
        `Create meeting poll for user: (${action.userAlias}), ` 
        + `pollId: (${action.pollId}), ` 
        + `from page: (${action.fromPage}), ` 
        + `createPollSuccess: (${action.createPollSuccess ? 1 : 0}), ` 
        + `errorResponse: (${action.meetingPollErrorResponse}), `
        + `totalExternalAttendees: (${action.totalExternalAttendees}), ` 
        + `totalInternalAttendees: (${action.totalInternalAttendees}), `
        + `totalAttendees: (${action.totalExternalAttendees + action.totalInternalAttendees}), `
        + `totalTimeSlotsSelected: (${action.totalTimeSlotsSelected}), `
        + `timeSlotsSelectedFromGridMode: (${action.timeSlotsSelectedFromGridMode ? 1 : 0}), `
        + `timeSlotsSelectedFromCalendarMode: (${action.timeSlotsSelectedFromCalendarMode ? 1 : 0}).`;
    
    yield call(postMetric, eventType, eventName, eventDetails, action.createPollSuccess ? 1 : 0);
}

export function* watchPostCreateMeetingPollEventMetric() {
    yield takeEvery(Actions.POST_CREATE_MEETING_POLL_EVENT_METRIC, postCreateMeetingPollEventMetric);
}

function* postUpdateMeetingPollEventMetric(action) {
    const eventType = "Update meeting poll";
    const eventName = `UpdateMeetingPollFrom${action.previousPollStatus}`;
    // To reduce the request we sent to the cloudwatch, we can create one formatted detail messages with all the value we need
    // When create the dashboard we can use *parse* function on cloudwatch to generate the table we needed
    const eventDetails = 
        `Update meeting poll for user: (${action.userAlias}), ` 
        + `from poll status: (${action.previousPollStatus}), ` 
        + `updatePollSuccess: (${action.updatePollSuccess ? 1 : 0}), ` 
        + `errorResponse: (${action.updatePollErrorResponse}), `
    
    yield call(postMetric, eventType, eventName, eventDetails, action.updatePollSuccess ? 1 : 0);
}

export function* watchPostUpdateMeetingPollEventMetric() {
    yield takeEvery(Actions.POST_UPDATE_MEETING_POLL_EVENT_METRIC, postUpdateMeetingPollEventMetric);
}

function* postRoomBookingMetric(action) {
    let success = action.success ? "1" : "0";
    yield call(postMetric, "Room booking", action.event, action.details, success);
}

export function* watchPostRoomBookingMetric() {
    yield takeEvery(Actions.POST_ROOM_BOOKING_METRIC, postRoomBookingMetric);
}

const sagas = [
    watchPostRatingMetric(),
    watchPostRASAPICallMetric(),
    watchPostAMSAPICallMetric(),
    watchPostCreateMeetingRoomsMetrics(),
    watchPostCopyToNewCreateMeetingMetrics(),
    watchPostUnknownEdgeLocationMetric(),
    watchPostRequesterEmailRetryMetric(),
    watchPostQueryParamsLinkMetric(),
    watchPostAvailabilitySharingCopyToClipboardMetric(),
    watchPostCreateLivestreamEventMetric(),
    watchPostNoValidChimeEmailFoundMetric(),
    watchPostSaveUserPreferencesMetric(),
    watchPostCreateMeetingPollEventMetric(),
    watchPostUpdateMeetingPollEventMetric(),
    watchPostRoomBookingMetric(),
    watchPostLivestreamAPICallMetric(),
];

export default sagas;
