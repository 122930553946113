import React, { useCallback, useEffect, useState } from "react";

import { zonedTimeToUtc } from "date-fns-tz";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import parseISO from "date-fns/parseISO";
import subDays from "date-fns/subDays";

import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";

import DatePicker from "../../shared/meridian-custom-components/src/components/date-picker";
import TimeSelect, { timeRange } from "../../shared/meridian-custom-components/src/components/time-select";
import RichTextEditor from "../../shared/components/rich-text-editor";
import ErrorMessageWrapper from "../../shared/components/error-message-wrapper";

import { generateComponentId } from "../../shared/shared-utils";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { convertDateTimezone } from "../../shared/time-utils";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { convertEpochMilliseconds } from "../poll-utils";
import { getDefaultTimeFormat, getLocalesFromSettingsDateFormat } from "../../shared/settings/settings-utils";
import { STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";
import { CREATE_POLL_TEXT_EDITOR } from "../poll-constants";
import { FLOW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";

const CreatePollAddDetails = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const [expiration, setExpiration] = [props.expiration, props.setExpiration];
    const timezones = props.timezones;
    const timezone = props.timezone;
    const timeFormat = props.timeFormat;
    const dateFormat = props.dateFormat;
    const flowType = props.flowType;
    const timeSlotsSelected = props.timeSlotsSelected;
    const showAddDetailsErrorMessage = props.showAddDetailsErrorMessage;
    const [voteDueDate, setVoteDueDate] = [props.voteDueDate, props.setVoteDueDate];
    const [voteDueTime, setVoteDueTime] = [props.voteDueTime, props.setVoteDueTime];
    const [isDueChanged, setIsDueChanged] = [props.isDueChanged, props.setIsDueChanged];
    const [latestDueDate, setLatestDueDate] = useState();

    const formatDate = (date) => format(date, "yyyy-MM-dd");
    const formatTime = (date) => format(date, "HH:mm:ss");

    // Date is invalid if it is in the past or is after the latest time slot's date
    const isDateInvalid = (date, lastDate = latestDueDate) => {
        return isBefore(parseISO(date), subDays(convertDateTimezone(new Date(), timezone, false), 1)) || (lastDate && isAfter(parseISO(date), lastDate));
    };

    // Time option is invalid if the selected date is the current date and the time is in the past
    const isTimeInvalid = (time, dueDate = voteDueDate) => {
        // Convert the current timezone to the selected timezone to determine disabled times
        const now = convertDateTimezone(new Date(), timezone, false);
        return dueDate === formatDate(now) && time < formatTime(now);
    };

    const isDisabledDate = useCallback((date) => (
        isDateInvalid(date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [latestDueDate]);

    const onChangeVoteDueDate = (dueDate) => {
        setVoteDueDate(dueDate);
        if (dueDate !== voteDueDate) {
            setIsDueChanged(true);
        }
    };
    const onChangeVoteDueTime = (dueTime) => {
        setVoteDueTime(dueTime);
        if (dueTime !== voteDueTime) {
            setIsDueChanged(true);
        }
    };

    useEffect(() => {
        if (voteDueDate !== undefined && voteDueTime !== undefined && timezone !== undefined) {
            const utcDateTime = zonedTimeToUtc(new Date(voteDueDate + " " + voteDueTime), timezone);
            setExpiration(utcDateTime.getTime());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voteDueDate, voteDueTime, timezone]);

    useEffect(() => {
        // For when there is an existing expiration date and time during edit poll workflow
       if (expiration !== undefined && voteDueDate === undefined && voteDueTime === undefined && flowType === FLOW_TYPE.UPDATE) {
           const customDate = new Date(convertEpochMilliseconds(expiration, "date")).toISOString().split("T")[0]; // e.g: 2021-07-15
           setVoteDueDate(customDate);
           setVoteDueTime(convertEpochMilliseconds(expiration, "hour", timezone, false));
       }
    }, [expiration, flowType, timezone, voteDueDate, voteDueTime, setVoteDueDate, setVoteDueTime]);

    // Determine latest poll due date and set default due date/time
    useEffect(() => {
        if (timeSlotsSelected?.length) {
            let earliestStartTime = timeSlotsSelected[0]?.startTime;
            let latestStartTime = timeSlotsSelected[0]?.startTime;

            // Find the time slot with the earliest/latest start time
            timeSlotsSelected.forEach((timeSlot) => {
                if (timeSlot.startTime < earliestStartTime) {
                    earliestStartTime = timeSlot.startTime;
                }
                if (timeSlot.startTime > latestStartTime) {
                    latestStartTime = timeSlot.startTime;
                }
            });

            // Determine the last due date possible for selection
            if (latestStartTime) {
                const lastDate = convertDateTimezone(new Date(latestStartTime * 1000), timezone, false);
                setLatestDueDate(lastDate);

                // Clear the vote due date if it is invalid based on the new latest date
                if (isDateInvalid(voteDueDate, lastDate)) {
                    setVoteDueDate(undefined);
                }
            }

            // Set a default due date/time based on the earliest time slot if the user has not manually changed it
            // or if the user's selected due date is invalid
            if ((!isDueChanged && earliestStartTime) || (voteDueDate && isDateInvalid(voteDueDate, new Date(latestStartTime * 1000)))) {
                // TODO: Implement default date and time logic

                // const earliestStartDate = new Date(earliestStartTime * 1000);
                // let defaultDate;

                // const defaultDueDate = formatDate(defaultDate);
                // const defaultDueTime = format(defaultDate, "HH:00:00"); // Setting minutes to 00 since due times are in 30 min increments

                // setVoteDueDate(defaultDueDate);
                // setVoteDueTime(defaultDueTime);
                // setIsDueChanged(false);
            }
        } else {
            setLatestDueDate(undefined);

            // If the default date/time were automatically set and all time slots are removed, reset the selection
            if (!isDueChanged) {
                setVoteDueDate(undefined);
                setVoteDueTime(undefined);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDueChanged, timeSlotsSelected, voteDueDate, setVoteDueDate, voteDueTime, setVoteDueTime, timezone]);

    // Clear invalid vote due date/time
    useEffect(() => {
        if (isDateInvalid(voteDueDate)) {
            setVoteDueDate(undefined);
        }
        if (voteDueDate === formatDate(convertDateTimezone(new Date(), timezone, false)) &&
            isTimeInvalid(voteDueTime, voteDueDate)) {
            setVoteDueTime(undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSlotsSelected, voteDueDate, voteDueTime, timezone]);

    return (
        <Column
            spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.MD ? "large" : "medium none"}
            width="100%"
            alignmentVertical="stretch"
            spacing="medium"
        >
            <ErrorMessageWrapper id="votes-due-error" errorMessage="This field is required. Add a vote due date" showErrorMessage={showAddDetailsErrorMessage}>
                <Row id="create-poll-votes-due-input-label">
                    <Text type="b300">Votes due</Text>
                </Row>
                <Row wrap="down">
                    <DatePicker
                        value={voteDueDate}
                        onChange={onChangeVoteDueDate}
                        placeholder="Select date"
                        width="220px"
                        aria-label="Select vote due date"
                        aria-required="true"
                        aria-invalid={showAddDetailsErrorMessage === true && voteDueDate === undefined ? "true" : "false"}
                        aria-describedby={showAddDetailsErrorMessage === true && voteDueDate === undefined ? "votes-due-error" : ""}
                        locale={getLocalesFromSettingsDateFormat(dateFormat)}
                        disabledDates={isDisabledDate}
                    />
                    <Row>
                        <TimeSelect
                            value={voteDueTime}
                            onChange={onChangeVoteDueTime}
                            options={timeRange({
                                start: "00:00:00",
                                end: "23:59:59",
                                step: 60 * 30, // 30 minutes
                            })}
                            disabledOptions={isTimeInvalid}
                            placeholder="Select time"
                            width="220px"
                            aria-label="Select vote due time"
                            aria-required="true"
                            aria-invalid={showAddDetailsErrorMessage === true && voteDueTime === undefined ? "true" : "false"}
                            aria-describedby={showAddDetailsErrorMessage === true && voteDueTime === undefined ? "votes-due-error" : ""}
                            format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                        />
                        {timezone &&
                            <Text color="secondary">
                                {timezoneIdToDisplayName(timezones, timezone)}
                            </Text>
                        }
                    </Row>
                </Row>
            </ErrorMessageWrapper>
            <Row>
                <div
                    style={{
                        paddingBottom: STEP_NAVIGATION_FOOTER.HEIGHT,
                        width: "100%"
                    }}
                >
                    <RichTextEditor
                        textValue={props.body}
                        updateTextValue={props.setBody}
                        flowType={flowType}
                        height={CREATE_POLL_TEXT_EDITOR.HEIGHT}
                        id={generateComponentId("rich-text-editor", "body")}
                        aria-required="true"
                    />
                </div>
            </Row>
        </Column>
    );
};

export default CreatePollAddDetails;
