import * as Actions from "../actions/action-types";
import { pathToWorkflow } from "../workflow-utils";

const initialState = {
    workflow: pathToWorkflow(),
    time: new Date()
};

const workflowReducer = (state = initialState, action) => {
    switch(action.type) {
        case Actions.SWITCH_WORKFLOW:
            return {
                ...state,
                workflow: action.workflow,
                time: new Date()
            };
        default:
            return state;
    }
}

export default workflowReducer;