import React from "react"
import PropTypes from "prop-types"

/**
 * Pass this as a child to the `SearchField` component to render a footer below
 * the suggestions in the search suggestion popover.
 *
 * Use this to add any contextual information or actions to the suggestions
 * (e.g. an "X results" label or a "More suggestions" link).
 */
class SearchSuggestionFooter extends React.Component {
  static propTypes = {
    /**
     * The elements to show in the footer. Plain text and links recommended.
     */
    children: PropTypes.node.isRequired,
  }

  render() {
    // Actual rendering is done in ./search-suggestion-popover.js
    return this.props.children
  }
}

export default SearchSuggestionFooter
