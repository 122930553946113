import { STATUS } from "../poll-constants";
import * as Actions from "./action-types";

export const createPoll = (createPollRequest, fromPage = "", timeSlotsSelectedFromGridMode = false, timeSlotsSelectedFromCalendarMode = false) => {
    return {type: Actions.CREATE_POLL, createPollRequest, fromPage, timeSlotsSelectedFromGridMode, timeSlotsSelectedFromCalendarMode};
};

export const findPolls = (requestedOnBehalfOf, identity, startTime, endTime, pollStatus) => {
    return {type: Actions.FIND_POLLS, requestedOnBehalfOf, identity, startTime, endTime, pollStatus};
};

export const setPollList = (pollList, meetingPollsLoaded) => {
    return {type: Actions.SET_POLL_LIST, pollList, meetingPollsLoaded};
};

export const getPoll = (requestedOnBehalfOf, pollID) => {
    return {type: Actions.GET_POLL, requestedOnBehalfOf, pollID};
};

export const getAttendeePoll = (requestedOnBehalfOf, pollID) => {
    return {type: Actions.GET_ATTENDEE_POLL, requestedOnBehalfOf, pollID};
};

export const respondPoll = (requestedOnBehalfOf, pollID, availability) => {
    return {type: Actions.RESPOND_POLL, requestedOnBehalfOf, pollID, availability};
};

export const setMeetingPollsLoaded = (isLoaded) => {
    return {type: Actions.SET_MEETING_POLLS_LOADED, isLoaded};
};

export const setPollDetailsLoaded = (isLoaded) => {
    return {type: Actions.SET_POLL_DETAILS_LOADED, isLoaded};
};

export const clearPollDetails = () => {
    return {type: Actions.CLEAR_POLL_DETAILS};
};

export const deletePoll = (deletePollRequest) => {
    return {type: Actions.DELETE_POLL, deletePollRequest};
};

export const updatePoll = (updatePollRequest, createMeetingRequests, deleteMeetingRequests, setMeetingListAction, previousPollStatus = STATUS.ONGOING) => {
    return {type: Actions.UPDATE_POLL, updatePollRequest, createMeetingRequests, deleteMeetingRequests, setMeetingListAction, previousPollStatus};
};

export const sendNotification = (notificationType, requestedOnBehalfOf, pollID, toAddresses, subject, body, email) => {
    return {type: Actions.SEND_NOTIFICATION, notificationType, requestedOnBehalfOf, pollID, toAddresses, subject, body, email};
};

export const setReminderResponse = (email, reminderResponse) => {
    return {type: Actions.SET_REMINDER_RESPONSE, email, reminderResponse};
};

export const setMeetingListForAvailability = (meetingList) => {
    return {type: Actions.SET_MEETING_LIST_FOR_AVAILABILITY, meetingList};
};

export const findMeetingsForAvailability = (requestedOnBehalfOf, startTime, endTime, maxResults) => {
    return {type: Actions.FIND_MEETINGS_FOR_AVAILABILITY, requestedOnBehalfOf, startTime, endTime, maxResults};
};

export const createMeetingForPoll = (createMeetingRequest, redirectTo, pollDetails, meetingList) => {
    return {type: Actions.CREATE_MEETING_FOR_POLL, createMeetingRequest, redirectTo, pollDetails, meetingList};
};

export const setMeetingPollFromPage = (fromPage) => {
    return {type: Actions.SET_MEETING_POLL_FROM_PAGE, fromPage}
};

export const setMeetingPollTimeSlotsSelectedFromGridMode = (timeSlotsSelectedFromGridMode) => {
    return {type: Actions.SET_MEETING_POLL_TIME_SLOTS_SELECTED_FROM_GRID_MODE, timeSlotsSelectedFromGridMode}
};

export const setMeetingPollTimeSlotsSelectedFromCalendarMode = (timeSlotsSelectedFromCalendarMode) => {
    return {type: Actions.SET_MEETING_POLL_TIME_SLOTS_SELECTED_FROM_CALENDAR_MODE, timeSlotsSelectedFromCalendarMode}
};

export const clearMeetingPollTimeSlotsSelectedMarker = () => {
    return {type: Actions.CLEAR_MEETING_POLL_TIME_SLOTS_SELECTED_MARKER}
};

export const processingPollCompleted = () => {
    return {type: Actions.PROCESSING_POLL_COMPLETED};
};