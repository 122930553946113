import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { getMeetingStep, getWorkflow } from "../../../../sagas/selector";

import Link from "@amzn/meridian/link";

const FunctionToast = (props) => {
    const currentStep = useSelector(getMeetingStep);
    const currentWorkflow = useSelector(getWorkflow);
    const allowedSteps = props.allowedSteps;
    const allowedWorkflows = props.allowedWorkflows;

    const onClose = props.onClose;
    const onClick = () => {
        props.function();
        onClose();
    };

    // Close toast if it shouldn't be open in the current step or workflow
    useEffect(() => {
        if ((allowedSteps && !allowedSteps.includes(currentStep)) || (allowedWorkflows && !allowedWorkflows.includes(currentWorkflow.NAME))) {
            onClose();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, allowedSteps, currentWorkflow, allowedWorkflows]);

    return (
        <React.Fragment>
            {props.text}&nbsp;
            <Link onClick={onClick}>
                {props.functionText}
            </Link>
        </React.Fragment>
    );
};

export default FunctionToast;