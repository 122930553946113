import * as Actions from "./action-types";

export const saveFavorites = (favorites, alias) => {
    return {type: Actions.SAVE_FAVORITES, favorites, alias};
};

export const addFavorite = (favorite, alias) => {
    return {type: Actions.ADD_FAVORITE, favorite, alias};
};

export const addFavoriteByEmail = (email, alias) => {
    return {type: Actions.ADD_FAVORITE_BY_EMAIL, email, alias};
};

export const removeFavorite = (favorite, alias) => {
    return {type: Actions.REMOVE_FAVORITE, favorite, alias};
};

export const removeFavoriteByAlias = (favoriteAlias, alias) => {
    return {type: Actions.REMOVE_FAVORITE_BY_ALIAS, favoriteAlias, alias};
};

export const updateFavorites = (favorites, alias) => {
    return {type: Actions.UPDATE_FAVORITES, favorites, alias};
};