import {chimeBridgeInfoTemplate} from './chime/chime-bridge-info';
import {htmlToDomNodes, indicesOfMarkers, replaceContentBetween} from './chime/html-template-utilities'

// ng-quill editor does not support h1~6 tags now, try to replace them
const chimeBridgeInfoTemplateHtml =
    chimeBridgeInfoTemplate.replace(/<h4>(.*?)<\/h4>/g, '<span style="font-size:18px"><b>$1</b></span><br>');

const newline = '\n\n';

// comply with ./chime-bridge-info
const bridgeInfoBeginPattern = '={10,}Conference Bridge Information={10,}';
const bridgeInfoEndPattern = '={40,}';
const bridgeInfoBeginRegex = new RegExp(bridgeInfoBeginPattern, 'i');
const bridgeInfoEndRegex = new RegExp(bridgeInfoEndPattern, 'i');

export const updateBridgeInfoInBody = (bodyHTML, bridge) => {
    bodyHTML = bodyHTML || '';

    let nodes = htmlToDomNodes(bodyHTML);
    let bridgeInfoIndices = indicesOfMarkers(nodes, bridgeInfoBeginRegex, bridgeInfoEndRegex);

    // no bridge selected, so delete bridge info
    if (!bridge || !bridge.bridgeID) {
        return replaceContentBetween(nodes, bridgeInfoIndices, '');
    } else {
        let newBridgeInfo = '';
        let meetingID = bridge.bridgeID;

        newBridgeInfo = chimeBridgeInfoTemplateHtml.replace(/\{\{MEETING_ID\}\}/g, meetingID);

        // add newlines so people will be less likely to type in the info area
        newBridgeInfo = newline + newBridgeInfo + newline;

        // try to update existing bridge info
        if (bridgeInfoIndices) {
            return replaceContentBetween(nodes, bridgeInfoIndices, newBridgeInfo);
        }

        // no existing bridge info or creation info, just append bridge info to the end
        else {
            return bodyHTML + newBridgeInfo;
        }
    }
}

export default updateBridgeInfoInBody;