import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import noop from "lodash/noop"
import popoverTokens from "@amzn/meridian-tokens/component/popover"
import { getBestOrigin } from "./utils"
import Portal from "../portal"
import TransitionController from "../_transition-controller"
import CustomPropTypes from "../../_prop-types"
import { parseAlignment } from "../../_prop-types/parsers"
import addDebouncedEventListener from "../../_utils/add-debounced-event-listener"
import waitAnimationFrame from "../../_utils/wait-animation-frame"
import {
  emptyNodeBounds,
  compareNodeBounds,
  getNodeBounds,
} from "../../_utils/node-bounds"
import { getUnthemedTokens } from "../../_utils/token"

// The closest a popover can be shown to the edge of the viewport
const viewportPadding = 16

// Grab the popover motion duration and function from our tokens.
const {
  popoverMotionDuration,
  popoverMotionFunction,
  popoverClosedScale,
} = getUnthemedTokens(popoverTokens)

/**
 * Styles
 */

// NOTE: This component does not use emotion because its styles are *highly*
// dynamic (updated on scroll). The emotion docs say that emotion is performant
// enough for highly dynamic styles, but using inline styles still seems to
// perform marginally better in this situation. Plus, using inline styles here
// isn't any worse from a dev perspective since we don't need any of the fancy
// css features enabled by emotion.

const styles = ({
  // From props
  shrinkToFit,
  offsetHorizontal,
  offsetVertical,
  maxHeight,
  maxWidth,
  // From state
  anchorRect,
  viewportRect,
  // From transition controller
  state,
  // From "best origin" calculations
  anchorOrigin,
  popoverOrigin,
  availableSpace,
}) => {
  // The distance to offset the popover from the anchor's left edge (changes
  // based on the anchor origin).
  const offsetFromAnchorLeft = {
    left: -offsetHorizontal,
    center: anchorRect.width / 2 + offsetHorizontal,
    right: anchorRect.width + offsetHorizontal,
  }[anchorOrigin.x]
  // The distance to offset the popover from the anchor's top edge (changes
  // based on the anchor origin).
  const offsetFromAnchorTop = {
    top: -offsetVertical,
    center: anchorRect.height / 2 + offsetVertical,
    bottom: anchorRect.height + offsetVertical,
  }[anchorOrigin.y]
  return {
    position: "fixed",
    boxSizing: "border-box",
    // Anchor the popover's position from its bottom edge if it's anchored at
    // the bottom. This ensures long content forces the popover to expand
    // downwards.
    top:
      state !== "exited" && popoverOrigin.y !== "bottom"
        ? anchorRect.top + offsetFromAnchorTop - viewportPadding
        : undefined,
    // Anchor the popover's position from its bottom edge if it's anchored at
    // the bottom. This ensures long content forces the popover to expand
    // upwards.
    bottom:
      state !== "exited" && popoverOrigin.y === "bottom"
        ? viewportRect.height -
          anchorRect.top -
          offsetFromAnchorTop -
          viewportPadding
        : undefined,
    // Anchor the popover's position from its left edge if it's anchored at the
    // the left or center. This ensures long content forces the popover to
    // expand to the right.
    left:
      state !== "exited" && popoverOrigin.x !== "right"
        ? anchorRect.left + offsetFromAnchorLeft - viewportPadding
        : undefined,
    // Anchor the popover's position from its right edge if it's anchored at the
    // right. This ensures long content forces the popover to expand to the left.
    right:
      state !== "exited" && popoverOrigin.x === "right"
        ? viewportRect.width -
          anchorRect.left -
          offsetFromAnchorLeft -
          viewportPadding
        : undefined,
    transformOrigin: `${popoverOrigin.y} ${popoverOrigin.x}`,
    opacity: state === "exited" || state === "exiting" ? 0 : 1,
    // For popovers that are anchored at their center, apply a transform to
    // ensure that the popover is positioned correctly and that long content
    // forces it to expand from the center.
    transform: `translateY(${
      popoverOrigin.y === "center" ? "-50%" : "0%"
    }) translateX(${popoverOrigin.x === "center" ? "-50%" : "0%"}) scale(${
      state === "exited" || state === "exiting" ? popoverClosedScale / 100 : 1
    })`,
    transition:
      state === "entering" || state === "entered" || state === "exiting"
        ? `transform ${popoverMotionDuration} ${popoverMotionFunction}, opacity ${popoverMotionDuration} ${popoverMotionFunction}`
        : undefined,
    visibility: state === "exited" ? "hidden" : "visible",
    // Add margin on every side to make sure that the viewport padding is
    // maintained
    margin: viewportPadding,
    // If the user has specified a maxHeight then use that. If the popover is
    // open and the `shrinkToFit` prop is set then also constrain by the
    // available vertical space.
    maxHeight:
      state !== "exited" && shrinkToFit
        ? Math.min(maxHeight || availableSpace.y, availableSpace.y)
        : maxHeight,
    // If the user has specified a maxWidth then use that. If the popover is
    // open and the `shrinkToFit` prop is set then also constrain by the
    // available horizontal space.
    maxWidth:
      state !== "exited" && shrinkToFit
        ? Math.min(maxWidth || availableSpace.x, availableSpace.x)
        : maxWidth,
  }
}

/**
 * This component handles the positioning of popover content in relation to
 * another element (the "anchor" element). It allows you to "stick" the popover
 * to the anchor element.
 *
 * This component has two real benefits over a simple absolute-positing strategy
 * for popover content. First, it renders popover content at the root of the DOM
 * using portals. This allows popovers to break out of the anchor element's
 * parent containers regardless of any overflow/scroll styles on those
 * containers. Second, this component will automatically re-position and re-size
 * the popover content based on the available space in the viewport. This
 * ensures that the content in the popover will be as accessible to the user as
 * possible.
 *
 * This component does not actually render anything to the DOM. It simply
 * generates the styles necessary to correctly position the popover. The styles
 * are passed via render props to the component's children so that the user can
 * render the popover exactly how they want to. This requires that the user set
 * the style prop on their popover element to the `popoverStyle` render prop
 * returned by this component.
 */
class PopoverController extends React.Component {
  static propTypes = {
    anchorOrigin: CustomPropTypes.alignment.isRequired,
    children: PropTypes.func.isRequired,
    popoverOrigin: CustomPropTypes.alignment.isRequired,
    /**
     * Any dom node or React element is valid here, but it's recommended that
     * you add a ref to the React element you want to anchor the popover to and
     * then pass the result in here.
     *
     * This anchor node is required in order to open the popover, but is not
     * required to render a closed popover. This allows you to render an
     * instance of `<PopoverController />` while you're still waiting for a ref
     * to your anchor element.
     *
     * NOTE: When you add a callback ref to an element avoid using inline
     * functions, as they can result in the ref flipping to `null` which can
     * break popover positioning:
     * https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
     */
    anchorNode: PropTypes.object,
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    offsetHorizontal: PropTypes.number,
    offsetVertical: PropTypes.number,
    open: PropTypes.bool,
    /**
     * If set to true the controller will attempt to resize the popover if there
     * is not enough space to display it at its natural size.
     */
    shrinkToFit: PropTypes.bool,
    onBeforeOpen: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
  }

  static defaultProps = {
    offsetHorizontal: 0,
    offsetVertical: 0,
    onBeforeOpen: noop,
    onOpen: noop,
    onClose: noop,
  }

  state = {
    // Should we render the popover's children? We don't when the popover is
    // closed to avoid unnecessary work.
    render: true,
    // The bounding rectangle for the anchor node.
    anchorRect: emptyNodeBounds,
    // The bounding rectangle for the popover node.
    popoverRect: emptyNodeBounds,
    // The bounding rectangle for the viewport.
    viewportRect: emptyNodeBounds,
  }

  eventListeners = []

  // Update the rectangle that represents the size/position of the element that
  // the popover is anchored to
  updateAnchorRect = () => {
    const { open, anchorNode } = this.props
    if (open) {
      /* eslint-disable-next-line react/no-find-dom-node */
      const anchorDomNode = ReactDOM.findDOMNode(anchorNode)
      const anchorRect = getNodeBounds(anchorDomNode)
      if (!compareNodeBounds(anchorRect, this.state.anchorRect)) {
        this.setState({ anchorRect })
      }
    }
  }

  // Update the rectangle that represents the viewport
  updateViewportRect = () => {
    const viewportRect = getNodeBounds(window)
    if (!compareNodeBounds(viewportRect, this.state.viewportRect)) {
      this.setState({ viewportRect })
    }
  }

  // Update the rectangle that represents the popover element (the children of
  // this component). Only do this when the popover element is first loaded to
  // ensure that the max width and height that we apply later do not affect how
  // we decide to position the popover (otherwise the max dimensions would
  // ensure that the popover rectangle would always fit in its current position,
  // and we'd no longer be able to identify better positioning).
  updatePopoverRect = () => {
    const popoverRect = getNodeBounds(this.popoverNode)
    if (!compareNodeBounds(popoverRect, this.state.popoverRect)) {
      this.setState({ popoverRect })
    }
  }

  onResizeWindow = () => {
    this.updateViewportRect()
    this.updateAnchorRect()
  }

  onScroll = event => {
    const { open } = this.props
    const { popoverNode } = this
    // If the popover is open and the scroll event came from *outside* the
    // popover then assume the anchor element moved (and an update is needed for
    // the anchor rect).
    if (open && (!popoverNode || !popoverNode.contains(event.target))) {
      this.updateAnchorRect()
    }
  }

  onBeforeOpen = done => {
    this.props.onBeforeOpen()
    this.setState({ render: true }, () => {
      // Give the children a chance to settle before poking through the DOM
      this.cancelAnimationFrame = waitAnimationFrame(() => {
        /* eslint-disable-next-line react/no-find-dom-node */
        this.popoverNode = ReactDOM.findDOMNode(this)
        this.updateViewportRect()
        this.updateAnchorRect()
        this.updatePopoverRect()
        this.addEventListeners()
        done()
      })
    })
  }

  onOpen = done => {
    this.props.onOpen(this.popoverNode)
    done()
  }

  onAfterClose = () => {
    this.props.onClose(this.popoverNode)
    this.removeEventListeners()
    this.setState({ render: true })
  }

  addEventListeners() {
    // Remove any existing event listeners just to be safe
    this.removeEventListeners()
    // Listen for *any* scroll event in the document
    this.eventListeners.push(
      addDebouncedEventListener(document, "scroll", this.onScroll, true)
    )
    // Listen for resize events from the window
    this.eventListeners.push(
      addDebouncedEventListener(window, "resize", this.onResizeWindow)
    )
  }

  removeEventListeners() {
    this.eventListeners.forEach(removeListener => removeListener())
    this.eventListeners = []
  }

  componentDidMount() {
    if (this.props.open && Boolean(this.props.anchorNode)) {
      /* eslint-disable-next-line react/no-find-dom-node */
      this.onBeforeOpen(() => this.onOpen(noop))
    }
  }

  componentWillUnmount() {
    this.removeEventListeners()
    if (this.cancelAnimationFrame) {
      this.cancelAnimationFrame()
    }
    clearTimeout(this.blurTimeout)
    clearTimeout(this.animationTimeout)
  }

  componentDidUpdate(prevProps) {
    const { anchorNode } = this.props
    if (anchorNode !== prevProps.anchorNode) {
      this.updateAnchorRect()
    }
  }

  render() {
    const { children, ...props } = this.props
    const { anchorRect, popoverRect, viewportRect, render } = this.state
    const { anchorOrigin, popoverOrigin, availableSpace } = getBestOrigin({
      anchorOrigin: parseAlignment(this.props.anchorOrigin),
      popoverOrigin: parseAlignment(this.props.popoverOrigin),
      extraSpace: {
        x: props.offsetHorizontal + viewportPadding,
        y: props.offsetVertical + viewportPadding,
      },
      anchorRect,
      popoverRect,
      viewportRect,
    })
    return (
      <TransitionController
        in={props.open && Boolean(props.anchorNode)}
        duration={parseInt(popoverMotionDuration)}
        onBeforeEnter={this.onBeforeOpen}
        onEnter={this.onOpen}
        onAfterExit={this.onAfterClose}
      >
        {state =>
          render ? (
            <Portal>
              {children({
                popoverState: state,
                popoverStyle: styles({
                  shrinkToFit: props.shrinkToFit,
                  offsetHorizontal: props.offsetHorizontal,
                  offsetVertical: props.offsetVertical,
                  maxHeight: props.maxHeight,
                  maxWidth: props.maxWidth,
                  state,
                  anchorOrigin,
                  popoverOrigin,
                  anchorRect,
                  popoverRect,
                  viewportRect,
                  availableSpace,
                }),
                popoverOrigin,
                anchorOrigin,
                anchorRect,
              })}
            </Portal>
          ) : null
        }
      </TransitionController>
    )
  }
}

export default PopoverController
