import React from "react";

const MeetingDeletedToast = (props) => {
    return (
        <React.Fragment>
            <b>Meeting {props.master && "series"} successfully canceled.</b>
        </React.Fragment>
    );
};

export default MeetingDeletedToast;