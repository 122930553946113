module.exports = {
    BUILDING_COLUMNS: {
        BUILDING_CODE: "Building Code",
        BUILDING_NAME: "Building Name",
        CITY: "City",
        COUNTRY: "Country",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        GOOGLE_MAP_LINK: "Google Map Link",
        ROOMS: "Rooms",
        UPDATE_TT_LINK: "Update TT Link",
    },
    ROOM_COLUMNS: {
        NAME: "Name",
        BUILDING_CODE: "Building Code",
        FLOOR: "Floor",
        CAPACITY: "Capacity",
        RESTRICTED: "Restricted",
        HAND_MANAGED: "Hand Managed",
        PHONE: "Phone",
        RESOURCE_LIST: "Resource List",
        CITY: "City",
        HIDDEN: "Hidden",
        EMAIL: "Email",
        UPDATE_TT_LINK: "Update TT Link",
    },
}