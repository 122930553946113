import {SAVE_CHIME_PIN, SAVE_GET_USER_BY_EMAIL, CLEAR_CHIME_PIN} from "../actions/action-types";

const initialState = {
    chimeUniqueIds: [],
    chimeUser: {}
};

const chimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CHIME_PIN:
            return {
                ...state,
                chimeUniqueIds: action.uniqueIds
            };
        case SAVE_GET_USER_BY_EMAIL:
            return {
                ...state,
                chimeUser: action.userDetails.User
            };
        case CLEAR_CHIME_PIN:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
};

export default chimeReducer;