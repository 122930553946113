import * as Actions from "../actions/action-types";

import { favoritesEqual } from "../favorites-utils";

const initialState = {
    favorites: undefined,
};

function favoritesReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SAVE_FAVORITES:
            return {
                ...state,
                favorites: action.favorites instanceof Array ? action.favorites : []
            };
        case Actions.ADD_FAVORITE:
            return state.favorites.includes(action.favorite) ? state : {
                ...state,
                favorites: [
                    ...state.favorites,
                    action.favorite
                ]
            };
        case Actions.REMOVE_FAVORITE:
            return {
                ...state,
                favorites: state.favorites.filter((favorite) => !favoritesEqual(favorite, action.favorite))
            };
        case Actions.REMOVE_FAVORITE_BY_ALIAS:
            return {
                ...state,
                favorites: state.favorites.filter((favorite) => favorite.value !== action.favoriteAlias)
            };
        default:
            return state;
    }
}

export default favoritesReducer;