import React from "react";

import Toggle from "@amzn/meridian/toggle";
import Column from "@amzn/meridian/column";
import Image from "@amzn/meridian/_image";

import FormSection from "./form-section";
import FormInputList from "./form-input-list";
import RadioGroup from "./radio-group";
import { generateComponentId } from "../../shared-utils";
import { CHIME_MEETING_ID } from "../settings-constants";

import ChimeMeetingIcon from "../../../../assets/icons/shared/tooltips_chime.svg";

import "../settings.css";
import Text from "@amzn/meridian/text";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";

const EmailAndChime = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const personalChimeMeetingId = props.personalChimeMeetingId;
    const usePersonalChimePin = props.usePersonalChimePin;
    const chimeEmail = props.chimeEmail;

    const onChangeChimeMeetingIdSelected = (selectedChimeMeetingId) => {
        if (selectedChimeMeetingId.indexOf(CHIME_MEETING_ID.GENERATE_NEW_ID) === 0) {
            props.onChangeUsePersonalChimePin(false);
        } else {
            props.onChangeUsePersonalChimePin(true);
        }
    }

    return (
        <FormSection 
            children={
                <FormInputList
                    largeMargin={true} 
                    screenSizeBreakpoint={screenSizeBreakpoint}
                    inputItems={[
                        {
                            header: "Auto-call",
                            inputComponent:
                                <Column>
                                    <Toggle 
                                        id={generateComponentId("toggle", "auto-call participants", "settings")} 
                                        checked={props.enableAutoCallParticipants} 
                                        onChange={props.onChangeEnableAutoCallParticipants}>
                                        Auto-call meeting participants
                                    </Toggle>
                                    <Toggle 
                                        id={generateComponentId("toggle", "auto-call room booking", "settings")} 
                                        checked={props.enableAutoCallRoomBooking} 
                                        onChange={props.onChangeEnableAutoCallRoomBooking}>
                                        Auto-call for room booking
                                    </Toggle>
                                </Column>
                        },
                        {
                            header: "Meeting ID",
                            tooltipComponent:
                                <div style={{width: "300px"}}>
                                    <Text>
                                        A new meeting ID will create a new, unique and resusable meeting ID for your meeting.
                                    </Text>
                                    <br/>
                                    <Text>
                                        My personal meeting ID will use your personal meeting ID and personalized meeting link, if set, for the meeting.
                                    </Text>
                                </div>, 
                            inputComponent: 
                                <RadioGroup isVertical
                                    radios={[
                                        {
                                            value: CHIME_MEETING_ID.GENERATE_NEW_ID,
                                        },
                                        {
                                            value:
                                                <Row>
                                                    {CHIME_MEETING_ID.PERSONAL_ID}
                                                    {personalChimeMeetingId ?? <Loader size="small"/>}
                                                </Row>,
                                            data: CHIME_MEETING_ID.PERSONAL_ID + personalChimeMeetingId,
                                        },
                                    ]}   
                                    checkedValue={usePersonalChimePin ? CHIME_MEETING_ID.PERSONAL_ID + personalChimeMeetingId : CHIME_MEETING_ID.GENERATE_NEW_ID} 
                                    setCheckedValue={onChangeChimeMeetingIdSelected} 
                                />
                        },
                        {
                            header: "Email address you use to sign in to Amazon Chime",
                            tooltipComponent:
                                <div style={{width:"350px", margin: "0", padding: "0"}}>
                                    <Image src={ChimeMeetingIcon} size="350px" viewportAspectRatio={1}/>
                                </div>,
                            noTooltipPadding: true, 
                            inputComponent:
                                chimeEmail?.length > 0 ? 
                                <Text type="b200">
                                    {chimeEmail}
                                </Text> :
                                <Loader size="small"/>
                        },
                    ]}
                />
            }
        />
    );
};

export default EmailAndChime;