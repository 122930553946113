import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Responsive from "@amzn/meridian/responsive";
import {
    getAttendees,
    getBuildings,
    getChimeUniqueIds,
    getChimeUser,
    getFavoriteRooms,
    getFavorites,
    getGroupSuggestions as getGroupSuggestionsSelector,
    getIdentity,
    getPeopleSuggestions as getPeopleSuggestionsSelector,
    getQuickMeetingModalState,
    getQuickMeetingSuggestions,
    getTimezonesList,
    getWorkflow,
    getWorkHours,
    getSettingsPrimaryTimezone,
    getSettingsTimeFormat,
    getSettingsDateFormat,
    getSettingsEnableAutoCallParticipants,
    getSettingsUsePersonalChimePin,
    getSettingsChimeEmail,
    getSettingsWorkingHours,
} from "../../../sagas/selector";
import {
    addAttendee,
    clearDraft as clearDraftPeople,
    clearGroupSuggestions,
    clearPeopleSuggestions,
    getGroupSuggestions,
    getPeopleSuggestions,
    getRASDataForGroup,
    getRASDataForPerson,
    removeAttendee,
} from "../../people/actions";
import {rasUserToAttendee} from "../../people/people-utils";
import {clearSuggestions, createMeeting, getSuggestions} from "../../shared/actions";
import {clearDraft as clearDraftMeeting, saveDraft} from "../../meeting-scheduler/actions";
import {clearDraft as clearDraftOOTO} from "../../meeting-ooto/actions";
import updateBridgeInfoInBody from "../../shared/chime/manage-bridge-info";
import {addMeetingsInfo} from "../../shared/body/body-utils";
import {generateNewMeetingId} from "../../shared/meeting-utils";
import {setQuickMeetingModalState} from "../actions";
import MeetingQuickModal from "../components/meeting-quick-modal";
import {ATTENDEE_PRIORITY, ATTENDEE_RESPONSE, ATTENDEE_TYPE} from "../../people/people-constants";
import {DURATION, LOCATION, MODAL_STATE, SCREEN_SIZE} from "../meeting-quick-constants";
import {getUserDefaultLocation} from "../../shared/locations/locations-utils";
import {
    isLocationAFavoriteRoom,
    isLocationASuggestion,
    isLocationAVSuggestion,
} from "../meeting-quick-utils";
import {createChimePin, getUserByEmail} from '../../shared/chime/actions';
import {addFavorite, removeFavorite} from "../../shared/favorites/actions";
import {createFavorite} from "../../shared/favorites/favorites-utils";
import {FAVORITE_TYPE} from "../../shared/favorites/favorites-constants";
import {changePage} from "../../shared/workflow/workflow-utils";
import {WORKFLOWS} from "../../shared/workflow/workflow-constants";
import {roundTimeToNearestQuarterHour} from "../../shared/time-utils";
import {ALERTS, TIME_FORMAT, TIME_CONSTANT, MAX_PAYLOAD_SIZE} from "../../shared/shared-constants";
import {getObjectInBytes} from "../../shared/shared-utils";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInDays";
import {SEARCH_LIMIT} from "../../meeting-scheduler/meeting-scheduler-constants";
import {getNextDayOfWeek} from "../../meeting-scheduler/meeting-scheduler-utils";
import {formatWorkHoursTime} from "../../shared/exchange-preferences/exchange-preferences-utils";
import {formattedTimeToTimeUnit, renderDurationLabel} from "../../shared/time-utils";
import { getTimeMinutes } from "../../shared/settings/settings-utils";

const formatIso = (date) => format(date, "yyyy-MM-dd");

const MeetingQuick = (props) => {
    const dispatch = useDispatch();
    const [quickModalOpen, setQuickModalOpen] = [props.quickModalOpen, props.setQuickModalOpen];
    const createButtonRef = props.createButtonRef;
    const [locationTemp, setLocationTemp] = useState("none");

    const quickMeetingModalState = useSelector(getQuickMeetingModalState);
    const identity = useSelector(getIdentity);
    const attendees = useSelector(getAttendees);
    const peopleSuggestionsSelector = useSelector(getPeopleSuggestionsSelector);
    const groupSuggestionsSelector = useSelector(getGroupSuggestionsSelector);
    const chimeUniqueIds = useSelector(getChimeUniqueIds);
    const chimeUser = useSelector(getChimeUser);
    const suggestions = useSelector((state) => {
        return getQuickMeetingSuggestions(state, locationTemp)
    });
    const favoriteRooms = useSelector(getFavoriteRooms);
    const favorites = useSelector(getFavorites);
    const buildings = useSelector(getBuildings);
    const timezones = useSelector(getTimezonesList);
    const currentWorkflow = useSelector(getWorkflow);
    const exchangeWorkHours = useSelector(getWorkHours);
    const settingsWorkHours = useSelector(getSettingsWorkingHours);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const enableAutoCallParticipants = useSelector(getSettingsEnableAutoCallParticipants);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const dateFormat = useSelector(getSettingsDateFormat);
    const usePersonalChimePin = useSelector(getSettingsUsePersonalChimePin);
    const chimeEmail = useSelector(getSettingsChimeEmail);

    const [duration, setDuration] = useState(DURATION.THIRTY_MINUTES.toString());
    const [customDuration, setCustomDuration] = useState(DURATION.THIRTY_MINUTES.toString());
    const [customDurationUnits, setCustomDurationUnits] = useState("min");
    const [showCustomDuration, setShowCustomDuration] = useState(duration === "custom");
    const [location, setLocation] = useState("none");
    const [chimePin, setChimePin] = useState();
    const [showQuickSuggestions, setShowQuickSuggestions] = useState(false);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const [meetingSubject, setMeetingSubject] = useState("");
    const [meetingBody, setMeetingBody] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);
    const [isResponseRequested, setIsResponseRequested] = useState(true);
    const [showReview, setShowReview] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);
    const [discardMeetingModalOpen, setDiscardMeetingModalOpen] = useState(false);
    const [isFirstSearch, setIsFirstSearch] = useState(true);
    const [timezoneValue, setTimezoneValue] = useState(undefined); // useEffect will set initial timezoneValue
    const [reviewAlerts, setReviewAlerts] = useState([]);
    const [autoCall, setAutoCall] = useState(false);
    
    const today = formatIso(new Date());
    const tomorrow = formatIso(addDays(new Date(), +1));
    const nextWeekMonday = formatIso(getNextDayOfWeek(new Date(), 1));
    const nextWeekFriday = formatIso(getNextDayOfWeek(addDays(new Date(), +7), 5));
    const weekFromNow = formatIso(addDays(new Date(), +7));
    const twoWeeksFromNow = formatIso(addDays(new Date(), +14));
    const monthFromNow = formatIso(addMonths(new Date(), +1));

    const presets = [
        { label: "Today", value: [today, today] },
        { label: "Tomorrow", value: [tomorrow, tomorrow] },
        { label: "Next week", value: [nextWeekMonday, nextWeekFriday] },
        { label: "Within a week", value: [today, weekFromNow] },
        { label: "Within two weeks", value: [today, twoWeeksFromNow] },
        { label: "Within a month", value: [today, monthFromNow] },
    ];

    const [dateRangeValue, setDateRangeValue] = useState([today, twoWeeksFromNow]);
    const [dateRangeLabel, setDateRangeLabel] = useState("8 days");
    const [startTime, setStartTime] = useState(); // Initialized in useEffect
    const [endTime, setEndTime] = useState(); // Initialized in useEffect
    const [timeLabel, setTimeLabel] = useState(); // Initialized in useEffect

    const [alerts, setAlerts] = useState([]);
    const [durationExceedsRoomLimit, setDurationExceedsRoomLimit] = useState(false);

    const subjectRef = useRef();
    const getSuggestionsButtonRef = useRef();
    const closeButtonRef = useRef();
    const favoritesRef = useRef();
    const reviewButtonRef = useRef();

    const getStartTimeAndEndTime = (exchangeWorkHours, settingsWorkHours) => {
        let startTime, endTime;

        if (exchangeWorkHours.startTime && exchangeWorkHours.endTime) {
            startTime = exchangeWorkHours.startTime;
            endTime = exchangeWorkHours.endTime;
        }

        if (settingsWorkHours.startTime && settingsWorkHours.endTime) {
            startTime = getTimeMinutes(settingsWorkHours.startTime);
            endTime = getTimeMinutes(settingsWorkHours.endTime);
        }

        return [startTime, endTime];
    }

    const getRoomOptions = useCallback(() => {
        const roomOptions = [
            {label: "None", value: "none"},
            {label: "Suggest a room", value: "suggest-room"},
            {label: "Suggest a room with AV/VC", value: "suggest-room-av-vc"}
        ];
        if (favoriteRooms) {
            roomOptions.push(
                ...(favoriteRooms.map((room) => {
                    return {
                        label: room.name,
                        value: room.value
                    }
                }))
            );
        }
        return roomOptions;
    }, [favoriteRooms]);

    // Load in work hours to start and end time filters
    useEffect(() => {
        const [startTime, endTime] = getStartTimeAndEndTime(exchangeWorkHours, settingsWorkHours);

        if (startTime && endTime) {
            setStartTime(formatWorkHoursTime(startTime, TIME_FORMAT.HH_MM_SS));
            setEndTime(formatWorkHoursTime(endTime, TIME_FORMAT.HH_MM_SS));
            setTimeLabel(renderDurationLabel(startTime, endTime, TIME_CONSTANT.MIN_NAME));
        }
    }, [exchangeWorkHours, settingsWorkHours]);

    useEffect(() => {
        if (identity && identity.username &&
            attendees && !attendees.find((attendee) => attendee.isOrganizer)) {
            let organizer = rasUserToAttendee(identity);
            organizer.status = ATTENDEE_RESPONSE.ORGANIZER;
            organizer.priority = ATTENDEE_PRIORITY.REQUIRED;
            organizer.isOrganizer = true;
            dispatch(addAttendee(organizer));
        }
    }, [attendees, identity, dispatch]);

    // generates a new chime pin if there isn't one
    useEffect(() => {
        if (identity && identity.username && identity.email && chimeUniqueIds && !chimeUniqueIds.AllocatedPins) {
            dispatch(createChimePin({entities: [{email: chimeEmail || identity.email}]}));
            setChimePin();
        }
    }, [identity, chimeEmail, chimeUniqueIds, dispatch]);

    // get personal chime pin if chimeUser is empty.
    useEffect(() => {
        if (identity && identity.username && identity.email && Object.keys(chimeUser).length === 0) {
            dispatch(getUserByEmail({userEmail: chimeEmail ?? identity.email, username: identity.username}));
        }
    }, [identity, chimeEmail, chimeUser, dispatch]);

    useEffect(() => {
        if (!timezoneValue) {
            setTimezoneValue(primaryTimezone);
        }
    }, [timezoneValue, primaryTimezone]);

    useEffect(() => {
        if (!autoCall) {
            setAutoCall(enableAutoCallParticipants);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableAutoCallParticipants]);

    // modal state handlers
    const resetQuickMeetingModal = () => {
        const [startTime, endTime] = getStartTimeAndEndTime(exchangeWorkHours, settingsWorkHours);

        dispatch(clearDraftMeeting());
        dispatch(clearDraftOOTO());
        dispatch(clearDraftPeople());
        setDuration(DURATION.THIRTY_MINUTES.toString());
        setDateRangeValue([today, twoWeeksFromNow]);
        setStartTime(formatWorkHoursTime(startTime, TIME_FORMAT.HH_MM_SS));
        setEndTime(formatWorkHoursTime(endTime, TIME_FORMAT.HH_MM_SS));
        setLocation("none");
        clearMeetingSuggestions();
        setShowQuickSuggestions(false);
        setChimePin(undefined);
        setMeetingSubject("");
        setMeetingBody("");
        setIsPrivate(false);
        setIsResponseRequested(true);
        setAlerts([]);

        setShowFavorites(false);
        setShowReview(false);
        setIsFirstSearch(true);
        setAutoCall(enableAutoCallParticipants);
    };

    const clearMeetingSuggestions = () => {
        setSelectedSuggestionIndex(-1);
        dispatch(clearSuggestions());
    };

    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onOpenQuickMeetingModal = () => {
        // TODO: Need to fix states so we only have to clear states on close
        resetQuickMeetingModal();
        setQuickModalOpen(false);
        dispatch(setQuickMeetingModalState(MODAL_STATE.OPEN));
        changePage(dispatch, currentWorkflow, WORKFLOWS.SIMPLE_MEETING);
    };

    const onCloseQuickMeetingModal = () => {
        setQuickModalOpen(false);
        resetQuickMeetingModal();
        dispatch(setQuickMeetingModalState(MODAL_STATE.CLOSE));
        createButtonRef.focus();
    };

    const onCloseQuickSuggestions  = () => {
        setShowQuickSuggestions(false);
    };

    // people/group search handlers
    const peopleSuggestions = peopleSuggestionsSelector &&
        peopleSuggestionsSelector.filter((person) => person.basicInfo && person.basicInfo.login
            && !attendees.some((attendee) => attendee.alias === person.basicInfo.login.toLowerCase()));
    const groupSuggestions = groupSuggestionsSelector &&
        groupSuggestionsSelector.filter((group) => !attendees.some((attendee) => attendee.alias === group));

    const onGetRASDataForPerson = (alias, addNewAttendee, name) => dispatch(getRASDataForPerson(alias, addNewAttendee, name));
    const onGetRASDataForGroup = (group) => dispatch(getRASDataForGroup(group));
    const onGetPeopleSuggestions = (query) => dispatch(getPeopleSuggestions(query));
    const onClearPeopleSuggestions = () => dispatch(clearPeopleSuggestions());
    const onGetGroupSuggestions = (query) => dispatch(getGroupSuggestions(query));
    const onClearGroupSuggestions = () => dispatch(clearGroupSuggestions());
    const onAddAttendee = (attendee) => dispatch(addAttendee(attendee));
    const onRemoveAttendee = (attendee) => dispatch(removeAttendee(attendee));
    const onRemoveAttendeeByAlias = (alias) => {
        let attendee = attendees.find((attendee) => attendee.alias === alias);
        if (attendee) {
            onRemoveAttendee(attendee);
        }
    };

    // favorites handlers
    const onAddFavorite = (favorite, alias) => dispatch(addFavorite(favorite, alias));
    const onRemoveFavorite = (favorite, alias) => dispatch(removeFavorite(favorite, alias));

    // other event handlers
    const onBrowseFavorites = () => {
        setShowQuickSuggestions(false);
        setShowFavorites(true);
        if (favoritesRef.current) {
            favoritesRef && favoritesRef.current && favoritesRef.current.focus();
        }
    };

    useEffect(() => {
        if (quickModalOpen && (quickMeetingModalState === undefined || quickMeetingModalState === MODAL_STATE.CLOSE)) {
            onOpenQuickMeetingModal();
        }
    }, [quickModalOpen, quickMeetingModalState, onOpenQuickMeetingModal]);

    const onFavoriteClick = (attendee) => {
        if (favorites === undefined) {
            return;
        }

        let favorite;
        switch (attendee.type) {
            case ATTENDEE_TYPE.PERSON:
                favorite = createFavorite(FAVORITE_TYPE.USER, attendee);
                break;
            case ATTENDEE_TYPE.GROUP:
                favorite = createFavorite(FAVORITE_TYPE.GROUP, attendee);
                break;
            case ATTENDEE_TYPE.EXTERNAL_EMAIL:
                favorite = createFavorite(FAVORITE_TYPE.EXTERNAL_EMAIL, {email: attendee.email});
                break;
            default:
                return;
        }

        if (isFavorited(attendee)) {
            onRemoveFavorite(favorite, identity.username);
        } else {
            onAddFavorite(favorite, identity.username);
        }
    };

    const isFavorited = (attendee) => {
        if (favorites && favorites.length > 0) {
            switch (attendee.type) {
                case ATTENDEE_TYPE.PERSON:
                case ATTENDEE_TYPE.GROUP:
                    return !!favorites.find((favorite) => favorite.value === attendee.alias);
                case ATTENDEE_TYPE.EXTERNAL_EMAIL:
                    return !!favorites.find((favorite) => favorite.value === attendee.email);
                default:
                    return false;
            }
        }
        return false;
    };

    const onSetChimePin = (pin, resetBody = false) => {
        setChimePin(pin);
        if (pin === "none") {
            setAutoCall(false);
        }
    };

    const validateInputsForSuggestions = () => {
        const alerts = [];
        if (!attendees || !attendees.length) {
            alerts.push(ALERTS.INVALID_ATTENDEES.value);
        }
        const duration = getDurationInMinutes();
        if (!duration) {
            alerts.push(ALERTS.MEETING_DURATION_NOT_SPECIFIED.value);
        }
        if (duration > DURATION.MAX_DURATION_IN_MINUTES) {
            alerts.push(ALERTS.INVALID_MEETING_DURATION.value);
        }
        if (!dateRangeValue[0]) { // start date
            alerts.push(ALERTS.INVALID_START_DATE.value);
        }
        if (!dateRangeValue[1]) { // end date
            alerts.push(ALERTS.INVALID_END_DATE.value);
        }
        if (dateRangeValue[0] && dateRangeValue[1]) {
            let dateDifference = differenceInDays(
                new Date(dateRangeValue[1] + "T" + startTime),
                new Date(dateRangeValue[0] + "T" + startTime)
            );
            if (dateDifference >= SEARCH_LIMIT.DAYS) {
                alerts.push(ALERTS.MAX_DAYS_EXCEEDED.value);
            }
            if (parseInt(duration / TIME_CONSTANT.ONE_DAY_IN_MIN) > dateDifference + 1) {
                alerts.push(ALERTS.MEETING_DURATION_LONGER_THAN_DATE_RANGE.value);
            }
        }

        if (startTime === endTime && endTime !== "00:00:00") {
            alerts.push(ALERTS.INVALID_TIME_WINDOW.value);
        } else {
            const startTimeMin = formattedTimeToTimeUnit(startTime, TIME_FORMAT.HH_MM_SS, TIME_CONSTANT.MIN_NAME);
            const endTimeMin = formattedTimeToTimeUnit(endTime === "00:00:00" ? "24:00:00" : endTime, TIME_FORMAT.HH_MM_SS, TIME_CONSTANT.MIN_NAME);

            let differenceInMin = endTimeMin - startTimeMin;
            if (startTimeMin > endTimeMin) {
                // treat the end time selected as the end time of the next day
                differenceInMin += TIME_CONSTANT.ONE_DAY_IN_MIN;
            }

            if (duration < TIME_CONSTANT.ONE_DAY_IN_MIN && duration > differenceInMin) {
                alerts.push(ALERTS.MEETING_DURATION_LONGER_THAN_TIME_WINDOW.value);
            }
        }

        // Show a warning alert if the meeting includes a room and the duration is longer than a day
        setDurationExceedsRoomLimit(duration > TIME_CONSTANT.ONE_DAY_IN_MIN && location !== "none"); // using custom flag so alert can be styled

        setAlerts(alerts);
        return alerts;
    };

    const onGetSuggestions = () => {
        if (validateInputsForSuggestions().length) {
            return;
        }
        clearMeetingSuggestions();
        setLocationTemp(location.valueOf());
        dispatch(getSuggestions(prepareSuggestionRequest()));
        setShowQuickSuggestions(true);
        setIsFirstSearch(false);
        setSelectedSuggestionIndex(-1);
    };

    const validateInputsForSend = () => {
        const alerts = validateInputsForSuggestions();
        if (!meetingSubject || !meetingSubject.length) {
            alerts.push(ALERTS.INVALID_MEETING_SUBJECT.value);
        }
        if (selectedSuggestionIndex === -1) {
            alerts.push(ALERTS.NO_SUGGESTION_SELECTED.value);
        }
        setAlerts(alerts);
        return alerts;
    };

    const onSendMeetingRequest = () => {
        if (validateInputsForSend().length) {
            return;
        }

        const createMeetingRequest = prepareCreateMeetingRequest();

        // Update meeting body with self service info and creation info
        createMeetingRequest.meeting.body = {
            value: addMeetingsInfo(getMeetingBody(), identity.username, createMeetingRequest.meeting.uniqueID, createMeetingRequest.meeting.recurrence),
            type: "html"
        };

        const meetingSize = getObjectInBytes(createMeetingRequest);

        // do not send the request if the payload exceeds the limit
        if (meetingSize > MAX_PAYLOAD_SIZE) {
            // show error alert on the review page
            const payloadAlert = JSON.parse(JSON.stringify(ALERTS.MAX_PAYLOAD_SIZE_EXCEEDED));
            payloadAlert.value = payloadAlert.value.replace("request", `request (${(meetingSize / 1024 / 1024).toFixed(2)}MB)`); // show request size in MB

            setReviewAlerts(reviewAlerts
                .filter((alert) => alert.id !== ALERTS.MAX_PAYLOAD_SIZE_EXCEEDED.id) // remove existing payload size alerts
                .concat([payloadAlert]));

            return;
        }

        dispatch(createMeeting(createMeetingRequest, "/"));
        setChimePin();
    };

    const onShowReview = (showReview) => {
        if (showReview) {
            if(validateInputsForSend().length) {
                return;
            }
            const createMeetingRequest = prepareCreateMeetingRequest();
            createMeetingRequest.uniqueID = "";
            dispatch(saveDraft(createMeetingRequest));
        }
        setShowReview(showReview);
    }

    /*const navigateToPath = (path) => {
        dispatch(clearDraft());
        const createMeetingRequest = prepareCreateMeetingRequest();
        createMeetingRequest.uniqueID = "";
        dispatch(saveDraft(createMeetingRequest));
        changePage(dispatch, currentWorkflow, pathToWorkflow(path));
        onCloseQuickMeetingModal();
    };*/

    // create suggestion request
    const getDurationInMinutes = () => {
        if (duration === "custom") {
            let minutes;
            if (customDurationUnits === "day") {
                minutes = parseFloat(customDuration) * DURATION.TWENTY_FOUR_HOURS_IN_MINUTES;
            } else if (customDurationUnits === "hour") {
                minutes = parseFloat(customDuration) * DURATION.ONE_HOUR_IN_MINUTES;
            } else {
                minutes = parseFloat(customDuration);
            }
            return minutes;
        }
        return parseInt(duration);
    };

    const getOrganizerInfo = () => attendees.find((attendee) => attendee.isOrganizer);

    const getWishlistRoomList = () => {
        if (isLocationASuggestion(location)) {
            const organizer = getOrganizerInfo();
            const organizerSite = organizer && organizer.rasData && organizer.rasData.site;
            const { userDefaultBuilding, userDefaultFloor } = getUserDefaultLocation(organizerSite, buildings);
            const wishList = {};
            wishList.minCapacity = attendees.length;
            wishList.buildingCode =  userDefaultBuilding;
            wishList.floor = userDefaultFloor;
            wishList.thisBuildingOnly = true;
            wishList.restrictedAllowed = false;
            wishList.hiddenAllowed = false;

            if (isLocationAVSuggestion(location)) {
                wishList.resourceList = ["vtc", "video"];
            }
            return wishList;
        }
        return undefined;
    };

    const removeInvalidAttendees = () => {
        return attendees.filter((attendee) => attendee && attendee.email);
    };

    const getWishlistParticipantList = () => {
        let participants = removeInvalidAttendees().map((attendee) => {
            return {"exchangeMailbox": attendee.email};
        });

        // RAS GetSuggestions supports getting suggestions only by buildings
        // to get availability of a specific room (favourited) we are adding it as a participant
        if (isLocationAFavoriteRoom(location)) {
            participants.push({"exchangeMailbox": location});
        }

        return participants;
    };

    const prepareSuggestionRequest = () => {
        const startTimeDate = new Date(dateRangeValue[0] + "T" + startTime);
        const endTimeDate = new Date(dateRangeValue[1] + "T" + endTime);

        let startHour = startTimeDate.getHours();
        let startMinute = startTimeDate.getMinutes();
        let endHour = endTimeDate.getHours();
        let endMinute = endTimeDate.getMinutes();

        const meetingDuration = getDurationInMinutes();
        if (duration === "custom" && meetingDuration >= 24 * TIME_CONSTANT.ONE_HOUR_IN_MIN) {
            startHour = 0;
            startMinute = 0;
            endHour = 0;
            endMinute = 0;
        }

        let startTimeString = "00:00:00";
        let endTimeString = "23:59:59";

        let startDate = new Date(dateRangeValue[0] + "T" + startTimeString);
        let endDate = new Date(dateRangeValue[1] + "T" + endTimeString);

        // If the start date is today, then the start time is set to the nearest 15 min to avoid suggestions in the past
        let currentDate = new Date();
        if (currentDate.toLocaleDateString() === startTimeDate.toLocaleDateString()) {
            startDate = roundTimeToNearestQuarterHour(currentDate);
        }

        let startEpoch = startDate.getTime() / TIME_CONSTANT.ONE_SEC_IN_MS;
        let endEpoch = endDate.getTime() / TIME_CONSTANT.ONE_SEC_IN_MS;

        let request = {};
        request.duration = meetingDuration;
        request.requestedOnBehalfOf = identity.email;
        request.groupedBy = "timeslot";
        request.timezone = {
            id: timezoneValue
        };
        request.searchDateRange = {
            startTime: startEpoch,
            endTime: endEpoch,
        };
        request.wishlistRoomList = [
            {
                wishlistRoomAttributes: getWishlistRoomList(),
                wishlistParticipantList: getWishlistParticipantList()
            }
        ];
        request.simpleFilters = {};
        request.simpleFilters.timeZone = timezoneValue;
        request.simpleFilters.dayOfWeek = {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
            sunday: false
        };
        request.simpleFilters.timeOfDay = [
            {
                startHour: startHour,
                startMinute: startMinute,
                endHour: endHour,
                endMinute: endMinute
            }
        ];

        return request;
    };

    // create meeting request
    const prepareRequiredAttendees = () => {
        let requiredAttendees = [];

        if (chimePin !== "none") {
            if (autoCall) {
                requiredAttendees.push({"email": "meet@chime.aws"});
            }
            if (chimeUser && chimeUser.PersonalPIN && chimePin !== chimeUser.PersonalPIN) {
                requiredAttendees.push({"email": "pin+" + chimePin + "@chime.aws"});
            }
        }

        let filteredRequiredAttendees = removeInvalidAttendees();
        requiredAttendees.push(...filteredRequiredAttendees.map((attendee) => {
            return {"email": attendee.email};
        }));

        return requiredAttendees;
    };

    const isNoRoomSuggestionSelected =
        () => (!suggestions || !suggestions[selectedSuggestionIndex] || suggestions[selectedSuggestionIndex].location === LOCATION.VIRTUAL_ONLY);

    const getResources = () => {
        let resources = [];
        if (!isNoRoomSuggestionSelected()) {
            resources.push({
                email: suggestions[selectedSuggestionIndex].email,
                name: suggestions[selectedSuggestionIndex].location
            })
        }
        return resources;
    };

    const getMeetingBody = () => {
        let body = meetingBody.replace(/\n/g, '<br/>');
        if (chimePin !== "none") {
            body = updateBridgeInfoInBody(body, {bridgeID: chimePin})
        }
        return body;
    };

    const prepareCreateMeetingRequest = () => {
        const selectedSuggestion = suggestions && suggestions[selectedSuggestionIndex];
        const organizer = getOrganizerInfo();
        const request = {};
        request.meeting = {};
        request.meeting.subject = meetingSubject;
        request.meeting.body = {
            value: getMeetingBody(),
            type: "html"
        };
        request.meeting.uniqueID = generateNewMeetingId(identity.username);
        request.meeting.organizer = (organizer && organizer.email) || "";
        request.meeting.requiredAttendees = prepareRequiredAttendees();
        request.meeting.time = {
            startTime: selectedSuggestion && selectedSuggestion.startTime,
            endTime: selectedSuggestion && selectedSuggestion.endTime
        };
        request.meeting.location = (selectedSuggestion && selectedSuggestion.location) || "";
        request.meeting.startTimezone = {
            id: timezoneValue,
        };
        request.meeting.endTimezone = {
            id: timezoneValue,
        };
        request.meeting.status = "busy";
        request.meeting.isPrivate = isPrivate;
        request.meeting.isResponseRequested = isResponseRequested;
        request.meeting.extendedProperties = {};

        const wishList = getWishlistRoomList() || {};
        wishList.participantsByPriority = getWishlistParticipantList() || {};
        request.meeting.extendedProperties.amazonMeetingsWishlist = {
            __type: "com.amazon.resourceadapter#StringValue",
            value: JSON.stringify({
                wishlist: [wishList],
                bridges: (chimePin !== "none") ? { selected: chimePin } : {}
            })
        };
        request.meeting.extendedProperties.managed = {
            __type: "com.amazon.resourceadapter#StringValue",
            value: "false"
        };
        request.meeting.extendedProperties.resources = {
            __type: "com.amazon.resourceadapter#StringValue",
            value: JSON.stringify([{
                resourceId: selectedSuggestion && selectedSuggestion.email,
                manageLevel: 0
            }])
        };
        request.meeting.resources = getResources();
        request.sendInvitations = true;

        return request;
    };

    return (
        <Responsive
            query="min-width"
            props={{
                screenSizeBreakpoint: {
                    default: SCREEN_SIZE.QUICK_MEETING_SMALL_MOBILE_VIEW,
                    "380px": SCREEN_SIZE.QUICK_MEETING_MOBILE_VIEW,
                    "721px": SCREEN_SIZE.QUICK_MEETING_FULL_VIEW,
                    "840px": SCREEN_SIZE.QUICK_MEETING_FULL_VIEW_LOCATION,
                }
            }}>
            {
                (responsiveProps) =>
                    <MeetingQuickModal
                        screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                        onOpenQuickMeetingModal={onOpenQuickMeetingModal}
                        onCloseQuickMeetingModal={onCloseQuickMeetingModal}
                        quickMeetingModalState={quickMeetingModalState}
                        favorites={favorites}
                        onAddFavorite={onAddFavorite}
                        onRemoveFavorite={onRemoveFavorite}
                        onBrowseFavorites={onBrowseFavorites}
                        onFavoriteClick={onFavoriteClick}
                        isFavorited={isFavorited}
                        duration={duration}
                        setDuration={setDuration}
                        customDuration={customDuration}
                        setCustomDuration={setCustomDuration}
                        customDurationUnits={customDurationUnits}
                        setCustomDurationUnits={setCustomDurationUnits}
                        showCustomDuration={showCustomDuration}
                        setShowCustomDuration={setShowCustomDuration}
                        getDurationInMinutes={getDurationInMinutes}
                        location={location}
                        setLocation={setLocation}
                        getRoomOptions={getRoomOptions}
                        chimePin={chimePin}
                        onSetChimePin={onSetChimePin}
                        chimeUniqueIds={chimeUniqueIds}
                        chimeUser={chimeUser}
                        timezones={timezones}
                        timezoneValue={timezoneValue}
                        showQuickSuggestions={showQuickSuggestions}
                        setShowQuickSuggestions={setShowQuickSuggestions}
                        onCloseQuickSuggestions={onCloseQuickSuggestions}
                        suggestions={suggestions}
                        onGetSuggestions={onGetSuggestions}
                        attendees={attendees}
                        selectedSuggestionIndex={selectedSuggestionIndex}
                        setSelectedSuggestionIndex={setSelectedSuggestionIndex}
                        meetingSubject={meetingSubject}
                        setMeetingSubject={setMeetingSubject}
                        meetingBody={meetingBody}
                        setMeetingBody={setMeetingBody}
                        isPrivate={isPrivate}
                        setIsPrivate={setIsPrivate}
                        isResponseRequested={isResponseRequested}
                        setIsResponseRequested={setIsResponseRequested}
                        onSendMeetingRequest={onSendMeetingRequest}
                        peopleSuggestions={peopleSuggestions}
                        onGetRASDataForPerson={onGetRASDataForPerson}
                        onGetPeopleSuggestions={onGetPeopleSuggestions}
                        onClearPeopleSuggestions={onClearPeopleSuggestions}
                        groupSuggestions={groupSuggestions}
                        onGetRASDataForGroup={onGetRASDataForGroup}
                        onGetGroupSuggestions={onGetGroupSuggestions}
                        onClearGroupSuggestions={onClearGroupSuggestions}
                        onAddAttendee={onAddAttendee}
                        onRemoveAttendee={onRemoveAttendee}
                        onRemoveAttendeeByAlias={onRemoveAttendeeByAlias}
                        alerts={alerts}
                        reviewAlerts={reviewAlerts}
                        durationExceedsRoomLimit={durationExceedsRoomLimit}
                        showReview={showReview}
                        setShowReview={setShowReview}
                        onShowReview={onShowReview}
                        setShowFavorites={setShowFavorites}
                        showFavorites={showFavorites}
                        discardMeetingModalOpen={discardMeetingModalOpen}
                        setDiscardMeetingModalOpen={setDiscardMeetingModalOpen}
                        setIsFirstSearch={setIsFirstSearch}
                        isFirstSearch={isFirstSearch}
                        subjectRef={subjectRef}
                        getSuggestionsButtonRef={getSuggestionsButtonRef}
                        closeButtonRef={closeButtonRef}
                        favoritesRef={favoritesRef}
                        reviewButtonRef={reviewButtonRef}
                        dateRangeValue={dateRangeValue}
                        setDateRangeValue={setDateRangeValue}
                        dateRangeLabel={dateRangeLabel}
                        setDateRangeLabel={setDateRangeLabel}
                        startTime={startTime}
                        setStartTime={setStartTime}
                        endTime={endTime}
                        setEndTime={setEndTime}
                        timeLabel={timeLabel}
                        setTimeLabel={setTimeLabel}
                        autoCall={autoCall}
                        setAutoCall={setAutoCall}
                        presets={presets}
                        timeFormat={timeFormat}
                        dateFormat={dateFormat}
                        usePersonalChimePin={usePersonalChimePin}
                    />
            }
        </Responsive>
    )
};

export default MeetingQuick;