// These styles are helpful for visually hiding an element while still allowing
// the user to interact with it (e.g. hide an input but allow the user to focus
// on it by clicking on an associated label). Simply applying "display: none" to
// an input will tell the browser to ignore it completely and the user will not
// be able to interact with it at all. Having an invisible yet interactive
// element is useful for creating custom styled inputs that are backed by their
// native html counterparts under the hood or for presenting information to
// screen-readers only.
const visuallyHidden = {
  clip: "rect(0 0 0 0)",
  overflow: "hidden",
  position: "fixed",
  pointerEvents: "none",
  border: 0,
  width: 1,
  height: 1,
  opacity: 0,
}

export default visuallyHidden
