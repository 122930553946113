import React, { useState, useRef, useEffect } from "react";

import format from "date-fns/format";
import parse from "date-fns/parse";
import isBefore from "date-fns/isBefore";
import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import addTime from "date-fns/add";
import addMinutes from "date-fns/addMinutes";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import "../../styles/calendar-time-grid.css";

import GRID_CONSTANTS, { FIFTEEN_MINUTES, MIN_BLOCK_UNIT } from "../custom-calendar-time-grid-constants";
import { KEYCODE, TIME_CONSTANT } from "../../shared-constants";
import { getDuration } from "../../../meeting-scheduler/meeting-scheduler-utils";
import { getEndTime } from "../custom-calendar-time-grid-utils";
import { convertDateTimezone, parseDatePickerDateString } from "../../time-utils";
import { SCREEN_SIZE } from "../../shared-constants";
import { getTimeSlotString } from "../../../meeting-polls/poll-utils";
import { CREATE_POLL_TIME_CELL } from "../../../meeting-polls/poll-constants";

const formatIso = (date) => format(date, "yyyy-MM-dd");
const formatIsoTime = (date) => format(date, "h:mm a");
const formatTimeOfDay = (date) => format(date, "HH:mm");
const formatShortDate = (date) => format(date, "MMM d");
const format12HourTimeOfDay = (date) => format(date, "h:mm aaaaa'm'");

const CalendarDayGrid = (props) => {
    const MINUTES_INDEX_RANGE = [0, 1, 2, 3];

    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const isMultiSelection = props.isMultiSelection;
    const groupColumnWidth = props.groupColumnWidth || GRID_CONSTANTS.DEFAULT_GROUP_COLUMN_WIDTH;
    const columnWidth = props.headerWidth || GRID_CONSTANTS.DEFAULT_COLUMN_WIDTH;
    const groupIds = props.groupIds;
    const date = props.date;
    const gridModeDate = props.gridModeDate;
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());
    const dateStartTime = parsedDate.setHours(props.startHour);
    const gridModeTimeZone = props.gridModeTimeZone;

    const selectedStartTime = props.timeSelectorOptions.selectedStartTime;
    const setSelectedStartTime = props.timeSelectorOptions.setSelectedStartTime;
    const durationSelected = props.timeSelectorOptions.duration;
    const customDurationValue = props.timeSelectorOptions.customDurationValue;
    const customDurationSelected = props.timeSelectorOptions.customDurationSelected;
    const duration = customDurationValue && getDuration(durationSelected, customDurationValue, customDurationSelected);
    const setChangedTimeWindow = props.setChangedTimeWindow;
    const spansMultipleDates = props.spansMultipleDates;

    const currentMeetingSuggestion = props.timeSelectorOptions.currentMeetingSuggestion;
    const onSelectSuggestion = props.timeSelectorOptions.onSelectSuggestion;

    const timeSlotsSelected = props.timeSlotsSelected;
    const onChangeTimeSlotsSelected = props.onChangeTimeSlotsSelected;
    const highLightTimeSlot = props.highLightTimeSlot;
    const setRefocusSlider = props.setRefocusSlider;
    let [containerHeight, setContainerHeight] = useState(0);
    let [showTimeSelectorBlocks, setShowTimeSelectorBlocks] = useState([]);
    // Use the array to represent the selectedTimeBlock {blockPosition, blockKey};
    let [selectedTimeSlotBlocks, setSelectedTimeSlotBlocks] = useState([]);
    let [insideSelectedTimeBlock, setInsideSelectedTimeBlock] = useState(false);
    let [draggingTimeBlock, setDraggingTimeBlock] = useState(undefined);
    const [currentScrollIntoHour, setCurrentScrollIntoHour] = useState((new Date()).getHours());

    const container = useRef(null);
    const timeSlider = props.timeSelectorOptions.sliderRef;

    // Disabling warning, to allow the height of the time slider to be adjusted. I don't think there is risk of an infinite
    // loop here since nothing else depends on this value
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setContainerHeight(container.current.clientHeight);
    });

    useEffect(() => {
        if (isMultiSelection) {
            if (highLightTimeSlot !== undefined) {
                const startDateTime = utcToZonedTime(fromUnixTime(highLightTimeSlot.startTime), gridModeTimeZone);
                const startHour = startDateTime.getHours();
                const startMinuteIdx = parseInt(startDateTime.getMinutes() / FIFTEEN_MINUTES);
                highlightSelectedTimeBlock(getHighLightTimeBlock(startHour, startMinuteIdx));
                setCurrentScrollIntoHour(startHour);
                setRefocusSlider(true);
            } else {
                onMouseLeaveTimeBlock();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [highLightTimeSlot]);

    useEffect(() => {
        const selectedTimeSlotBlocks = [];
        const parsedGridModeDate = parseDatePickerDateString(gridModeDate);
        if (timeSlotsSelected?.length > 0) {
            timeSlotsSelected.forEach((timeSlot) => {
                const startDateTime = utcToZonedTime(fromUnixTime(timeSlot.startTime), gridModeTimeZone);
                const endDateTime = utcToZonedTime(fromUnixTime(timeSlot.endTime), gridModeTimeZone);

                if (parsedGridModeDate && startDateTime &&
                    parsedGridModeDate.getMonth() === startDateTime.getMonth() &&
                    parsedGridModeDate.getDate() === startDateTime.getDate()
                ) {
                    const startHour = startDateTime.getHours();
                    const startMinuteIdx = parseInt(startDateTime.getMinutes() / FIFTEEN_MINUTES);
                    let endHour = endDateTime.getHours();
                    let endMinuteIdx = parseInt(endDateTime.getMinutes() / FIFTEEN_MINUTES);
                    let j = startMinuteIdx;

                    if (endMinuteIdx === 0) {
                        endMinuteIdx = MINUTES_INDEX_RANGE.length - 1;
                        endHour -= 1;
                    } else {
                        endMinuteIdx -= 1;
                    }

                    const currentTimeBlock = [];
                    for (let i = startHour; i <= endHour; i++) {
                        if (i === endHour) {
                            while (j <= endMinuteIdx) {
                                currentTimeBlock.push(getTimeBlockKey(i, j));
                                j += 1;
                            }
                        } else {
                            while (j < MINUTES_INDEX_RANGE.length) {
                                currentTimeBlock.push(getTimeBlockKey(i, j));
                                j += 1;
                            }
                            j = 0;
                        }
                    }

                    selectedTimeSlotBlocks.push(currentTimeBlock);
                }
            });
        }
        setSelectedTimeSlotBlocks(selectedTimeSlotBlocks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSlotsSelected, gridModeDate]);

    const getTimeRangeHeaders = () => {
        let columnHeaders = [];
        let hoursInTimeRange = [];
        for (let i = props.startHour; i <= props.endHour; i++) {
            columnHeaders.push(`${i % 12 === 0 ? 12 : i % 12}:00 ${i >= 12 ? "PM" : "AM"}`);
            hoursInTimeRange.push(i);
        }
        return {columnHeaders, hoursInTimeRange};
    };

    const {columnHeaders, hoursInTimeRange} = getTimeRangeHeaders();

    const renderFirstCell = () => {
        return (
            <div
                className="calendar-column-header calendar-column-section-header calendar-row-group-header"
                role="rowheader"
                style={{zIndex: "26"}}
            >
                {props.renderFirstCell()}
            </div>
        );
    };

    const renderAdditionalTimezones = () => {
        if (!props.additionalTimezones || !props.additionalTimezones.length) {
            return;
        }
        return (
            props.additionalTimezones.map((timezone, index) =>
                <React.Fragment key={`wrapper-timezone-${index}`}>
                    <div
                        className="calendar-row-header calendar-timezone-header"
                        role="rowheader"
                        style={{
                            top: `${GRID_CONSTANTS.TIME_ROW_HEIGHT + (GRID_CONSTANTS.ADDITIONAL_TIMEZONE_ROW_HEIGHT * index)}px`,
                        }}
                    >
                        <Row alignmentHorizontal={screenSizeBreakpoint <= SCREEN_SIZE.BREAKPOINT.SM ? "left" : "right"} key={`timezone-${index}`} height="21px" spacingInset="small">
                            <Text type="b200">{timezone.displayName}</Text>
                        </Row>
                    </div>
                    {_renderTimezoneRow(timezone, index)}
                </React.Fragment>
            )
        );

        function _renderTimezoneRow(timezone, timezoneIndex) {
            let newDateStartTime = dateStartTime;
            let gridDate = date;

            // When specified grid mode timezone we will need to parse the time based on the specified timezone
            if (gridModeDate !== undefined && gridModeTimeZone !== undefined) {
                const gridModeParsedDate = parseDatePickerDateString(gridModeDate);
                gridDate = gridModeParsedDate;
                gridModeParsedDate.setHours(props.startHour);
                newDateStartTime = zonedTimeToUtc(gridModeParsedDate, gridModeTimeZone);
            }

            const rangeStartInTimezone = utcToZonedTime(newDateStartTime, timezone.timezoneId);
            return (
                columnHeaders.map((header, index) => {
                    const newTimeInTimezone = addTime(rangeStartInTimezone, {hours: index});
                    let dateDelta = "";
                    if (formatIso(newTimeInTimezone) < gridDate) {
                        dateDelta = "-1D";
                    } else if (formatIso(newTimeInTimezone) > gridDate) {
                        dateDelta = "+1D";
                    }
                    return (
                        <div
                            className="calendar-timezone-section-header"
                            role="cell"
                            key={`timezone-${header}-${index}`}
                            style={{
                                top: `${GRID_CONSTANTS.TIME_ROW_HEIGHT + (GRID_CONSTANTS.ADDITIONAL_TIMEZONE_ROW_HEIGHT * timezoneIndex)}px`,
                            }}
                        >
                            <Row spacingInset="none small">
                                <Text type="b200" color="secondary" alignment="left">{formatIsoTime(newTimeInTimezone)}{dateDelta}</Text>
                            </Row>
                        </div>
                    );
                })
            );
        }
    };

    // Add a 0 in front of a number if it is single digit
    const padNum = (number) => {
        return ("0" + number).slice(-2);
    };

    // Return the ID for a grid cell given the time it starts on
    // e.g. gridCell_08_00
    const getGridCellId = (hour, minute) => {
        return `gridCell_${padNum(hour)}_${padNum(minute)}`;
    };

    // Return the date object for a given grid cell ID
    const convertGridCellIdToDate = (gridCellId) => {
        const hour = padNum(gridCellId.split("_")[1]);
        const minute = padNum(gridCellId.split("_")[2]);

        return new Date(`${date}T${hour}:${minute}:00`);
    };

    // Return the grid cell ID for a given date object
    const convertDateToGridCellId = (date) => {
        if (Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date)) {
            const timezoneOffset = date.getTimezoneOffset() * (60 * 1000); // timezone offset in milliseconds
            const dateTimeString = new Date(date.getTime() - timezoneOffset).toISOString();

            const timeString = dateTimeString.split("T")[1];
            const hour = timeString.split(":")[0];
            const minute = timeString.split(":")[1];

            return getGridCellId(hour, minute);
        }
    };

    // Return the ID for a grid cell offset from a given grid cell ID
    const getOffsetGridCellId = (gridCellId, offset = CREATE_POLL_TIME_CELL.MINUTES) => {
        const currentTime = convertGridCellIdToDate(gridCellId);
        const newTime = addMinutes(currentTime, offset);

        return convertDateToGridCellId(newTime);
    };

    const onKeyDown = (event) => {
        const key = event.which || event.keyCode;

        let gridCellId = event.target?.id;
        let focusedGridCellId;

        let timeInfo = gridCellId.split("_");
        let currentHour = Number(timeInfo[1]);
        let currentMinute = Number(timeInfo[2]);

        let newTimeInfo;
        let newHour;
        let newMinute;

        switch (key) {
            case KEYCODE.LEFT:
                focusedGridCellId = getOffsetGridCellId(gridCellId, -CREATE_POLL_TIME_CELL.MINUTES);
                event.preventDefault(currentHour, currentMinute % 15);
                break;
            case KEYCODE.RIGHT:
                focusedGridCellId = getOffsetGridCellId(gridCellId, CREATE_POLL_TIME_CELL.MINUTES);
                event.preventDefault();
                break;
            case KEYCODE.SPACE:
                onKeyboardPressTimeBlock(currentHour);
                event.preventDefault();
                break;
            default:
                break;
        }

        let focusedGridCell = document.getElementById(focusedGridCellId);

        if (focusedGridCell) {
            // Unfocus currently focused cell and set focus to the new one
            document.activeElement.setAttribute("tabindex", "-1");
            focusedGridCell.setAttribute("tabindex", "0");
            focusedGridCell.focus();

            newTimeInfo = focusedGridCellId.split("_");
            newHour = Number(newTimeInfo[1]);
            newMinute = Number(newTimeInfo[2]);

            onMouseEnterTimeBlock(newHour, newMinute / 15);
            setFocusableGridCellId(focusedGridCell.id);
        }
    };

    const [focusableGridCellId, setFocusableGridCellId] = useState();

    // Set initial focusable grid cell to first cell in the day
    useEffect(() => {
        let focusableGridCell = document.querySelectorAll("*[id^='gridCell']")[0];

        if (focusableGridCell) {
            setFocusableGridCellId(focusableGridCell.id);
        }
    }, []);

    // Add focus listener to grid cells so mouse over styling is applied upon tab focus
    useEffect(() => {
        let gridCells = document.querySelectorAll("*[id^='gridCell']");

        gridCells.forEach((gridCell) => {
            let focusEventListener = () => {
                let hour = Number(gridCell.id?.split("_")[1]);
                let minute = Number(gridCell.id?.split("_")[2]);
                onMouseEnterTimeBlock(hour, minute / 15);
            };

            gridCell.addEventListener("focus", (event) => {
                focusEventListener();
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration])

    // Return the aria-label for the currently focused cell
    const calculateGridCellAriaLabel = (hour, minute) => {
        const minuteIdx = minute / 15;
        // Create a date object for the starting time of the grid cell
        let time = `${padNum(hour)}:${padNum(minute)}`
        let startTime = getDateFromHourAndMinuteIndex(hour, minuteIdx);
        let ariaLabel = "";

        let timeLabel = getTimeSlotString(startTime.getTime() / 1000, (startTime.getTime() / 1000) + (duration * TIME_CONSTANT.ONE_HOUR_IN_SEC));

        let availabilitySummary = props.calculateAttendeeAvailabilitySummary(startTime, duration);

        if (isSameBlock()) {
            startTime = findStartTimeOfSelectedBlock(getTimeBlockKey(hour, minuteIdx));
            if (startTime !== -1) {
                timeLabel = getTimeSlotString(startTime.getTime() / 1000, (startTime.getTime() / 1000) + (duration * TIME_CONSTANT.ONE_HOUR_IN_SEC));
            }
            ariaLabel = `Unmark ${timeLabel} as available, current selected block is ${time}`;
        } else if (hasOverlap()) {
            ariaLabel = `Time slot conflicts with selected time slot at ${timeLabel}`;
        } else if (!isSameBlock()) {
            ariaLabel = `Mark ${timeLabel} as available (${availabilitySummary})`;
        }

        return ariaLabel;
    };

    const renderKeyboardNavigationRow = () => {
        return (
            <React.Fragment key={`row-wrapper-keyboard-navigation`}>
                <div className="calendar-row-group-header">
                    <Row></Row>
                </div>
                {hoursInTimeRange.map((hour) =>
                    <div key={`row-item-keyboard-navigation-${hour}`}>
                        <Row width="100%" height="100%" spacing="none">
                            {[0, 15, 30, 45].map((minute) =>
                                <div
                                    id={getGridCellId(hour, minute)}
                                    key={getGridCellId(hour, minute)}
                                    spacing="none"
                                    width="25%"
                                    onKeyDown={onKeyDown}
                                    tabIndex={getGridCellId(hour, minute) === focusableGridCellId ? "0" : "-1"}
                                    aria-label={calculateGridCellAriaLabel(hour, minute)}
                                    style={{
                                        width: "25%",
                                        height: "100%",
                                    }}
                                >
                                </div>
                            )}
                        </Row>
                    </div>
                )}
            </React.Fragment>
        );
    };

    const renderColumnHeaders = () => {
        return (
            columnHeaders.map((columnTitle) =>
                <div
                    className="calendar-column-header calendar-column-section-header"
                    role="columnheader"
                    key={`column-header-${columnTitle}`}
                >
                    {props.renderHeader(columnTitle)}
                </div>
            )
        );
    };

    const renderRows = (rowGroups, renderGroupHeader = props.renderGroupHeader, renderHour = props.renderHour) => {
        return (
            <React.Fragment>
                {rowGroups
                    .filter(groupId => groupId !== undefined)
                    .map((groupId) => {
                        return (
                            <React.Fragment key={`row-wrapper-${groupId}`}>
                                <div className="calendar-row-group-header" role="rowheader">
                                    {renderGroupHeader(groupId)}
                                </div>
                                {hoursInTimeRange.map((hour) =>
                                    <div
                                        style={props.showWorkingHour &&
                                            {background: props.renderAttendeeWorkingHourBackground(groupId, hour)}}
                                        className="calendar-grid-item"
                                        role="cell"
                                        key={`row-item-${groupId}-${hour}`}
                                    >
                                        {renderHour(groupId, hour)}
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })
                }
            </React.Fragment>
        );
    };

    const renderSections = (sections) => {
        return (
            <React.Fragment>
                {sections.map((section) =>
                    <React.Fragment key={section.label}>
                        <div className="calendar-row-section-header calendar-column-section-header" role="rowheader">
                            {props.renderSectionHeader(section.label, section.action)}
                        </div>
                        {hoursInTimeRange.map((hour) =>
                            <div
                                className="calendar-grid-item calendar-column-section-header"
                                role="cell"
                                key={`row-item-${section.label}-${hour}`}
                            />
                        )}
                        {renderRows(section.members, section.renderGroupHeader)}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    const updateDuration = props.updateDuration;

    const updateSelectedSuggestion = () => {
        onSelectSuggestion({
            ...currentMeetingSuggestion,
            startTime: selectedStartTime.getTime() / 1000,
            endTime: getEndTime(selectedStartTime.getTime(), duration).getTime() / 1000,
        });
    };

    let [dragging, setDragging] = [props.dragging, props.setDragging];
    let [startTime, setStartTime] = useState(undefined);
    let [startDuration, setStartDuration] = useState(undefined);
    let [startX, setStartX] = useState(undefined);

    // If the block already selected then this function will remove it
    // or it will add it to the list.
    const onChangeTimeBlockFromSelectedTimeSlots = (timeBlockArray) => {
        const startTime = getDateTimeFromBlockKey(timeBlockArray[0]);
        const endTime = getDateTimeFromBlockKey(timeBlockArray[timeBlockArray.length - 1], true);

        const occursInPast = isBefore(startTime, convertDateTimezone(new Date(), gridModeTimeZone, false));

        // TODO: Currently we don't allow the customer to select the block span two days
        if (endTime.getDate() !== startTime.getDate() || occursInPast) {
            return;
        }

        onChangeTimeSlotsSelected({
            startTime: getUnixTime(zonedTimeToUtc(startTime, gridModeTimeZone)),
            endTime: getUnixTime(zonedTimeToUtc(endTime, gridModeTimeZone))
        });
    }

    const onStartDrag = (e, dragComponent) => {
        // only left mouse button and if dragging is not yet defined
        if (e.button !== 0 || dragging !== undefined) {
            return;
        }

        if (isMultiSelection) {
            // remove the block from the selectedTimeBlockList
            onChangeTimeBlockFromSelectedTimeSlots(showTimeSelectorBlocks);
            setDraggingTimeBlock(showTimeSelectorBlocks);
        } else {
            setStartTime(selectedStartTime);
            setStartDuration(duration);
        }

        // save current state
        setDragging(dragComponent);
        setStartX(e.clientX);

        e.stopPropagation();
        e.preventDefault();
    };

    const onDrag = (e) => {
        if (dragging === undefined) {
            return;
        }

        const minuteIncrement = isMultiSelection ? FIFTEEN_MINUTES : 5; // how many minutes the window will change when dragging
        const hourIncrement = (minuteIncrement / 60); // how many hours the duration will change

        let deltaX = e.clientX - startX;
        let intervalChanges = Math.floor(deltaX / (columnWidth * hourIncrement));

        if (isMultiSelection) {
            if (draggingTimeBlock !== undefined && intervalChanges !== 0) {
                const nextTimeBlock = draggingTimeBlock.map((timeBlockKey) => incrementTimeBlock(timeBlockKey, intervalChanges));
                if (nextTimeBlock[0] !== showTimeSelectorBlocks[0]) {
                    setShowTimeSelectorBlocks(nextTimeBlock);
                }
            }
        } else {
            let newStartTimeEpoch, newStartTime, newEndTime, newDuration;

            switch (dragging) {
                case GRID_CONSTANTS.DRAG_COMPONENT.TIME:
                    newStartTimeEpoch = startTime.getTime() + (intervalChanges * (1000 * 60 * minuteIncrement));
                    newStartTime = new Date(newStartTimeEpoch);
                    // subtract 1 ms so that getHours will return (XX - 1) for a newEndTime of XX:00
                    newEndTime = getEndTime(newStartTimeEpoch - 1, duration);
                    if (startTime.getDay() === newStartTime.getDay() &&
                        startTime.getDay() === newEndTime.getDay() &&
                        newStartTime.getHours() >= props.startHour &&
                        newEndTime.getHours() <= props.endHour
                    ) {
                        setSelectedStartTime(newStartTime);
                        setChangedTimeWindow(newStartTime);
                    }
                    break;
                case GRID_CONSTANTS.DRAG_COMPONENT.START_TIME:
                    newDuration = startDuration - (intervalChanges * hourIncrement);
                    if (newDuration > 0) {
                        let newStartTime = new Date(startTime.getTime() + (intervalChanges * (1000 * 60 * minuteIncrement)));
                        if (startTime.getDay() === newStartTime.getDay() && newStartTime.getHours() >= props.startHour) {
                            setSelectedStartTime(newStartTime);
                            setChangedTimeWindow(newStartTime);
                            updateDuration(newDuration);
                        }
                    } else {
                        updateDuration(hourIncrement);
                    }
                    break;
                case GRID_CONSTANTS.DRAG_COMPONENT.END_TIME:
                    newDuration = startDuration + (intervalChanges * hourIncrement);
                    // subtract 1 ms so that getHours will return (XX - 1) for a newEndTime of XX:00
                    newEndTime = getEndTime(startTime.getTime() - 1, newDuration);
                    if (startTime.getDay() === newEndTime.getDay() && newEndTime.getHours() <= props.endHour && newDuration > 0) {
                        updateDuration(newDuration);
                    }
                    break;
                default:
                    break;
            }

            if (selectedStartTime.getTime() !== startTime.getTime()) {
                props.updateAttendeeAvailabilityTag(newStartTime, undefined);
            } else if (duration !== newDuration) {
                props.updateAttendeeAvailabilityTag(undefined, newDuration);
            }
        }

        e.stopPropagation();
        e.preventDefault();
    };

    const onEndDrag = (e) => {
        setDragging(undefined);

        if (isMultiSelection) {
            const timeBlock = hasOverlap() ? draggingTimeBlock : showTimeSelectorBlocks;
            setDraggingTimeBlock(undefined);
            onChangeTimeBlockFromSelectedTimeSlots(timeBlock);
        } else {
            // Update the selected suggestion if the position of the time window has changed
            // (Duration changes are covered in the useEffect of calendar-time-grid.js)
            if (selectedStartTime.getTime() !== startTime.getTime()) {
                updateSelectedSuggestion();
            }
        }

        e.stopPropagation();
        e.preventDefault();
    };

    const onMouseEnterTimeBlock = (hour, minuteIdx) => {
        if (dragging === undefined) {
            const showTimeBlocks = getHighLightTimeBlock(hour, minuteIdx);

            const timeBlockIndex = findTimeBlock(showTimeBlocks[0]);
            if (timeBlockIndex > -1) {
                setInsideSelectedTimeBlock(true);
                setShowTimeSelectorBlocks([...selectedTimeSlotBlocks[timeBlockIndex]]);
            } else {
                highlightSelectedTimeBlock(showTimeBlocks);
            }
        }
    };

    const getHighLightTimeBlock = (hour, minuteIdx) => {
        const showTimeBlocks = [];
        const numberOfBlocks = duration / MIN_BLOCK_UNIT;
        for (let i = 0; i < numberOfBlocks; i++) {
            let hourIdx = (hour + parseInt((minuteIdx + i) / MINUTES_INDEX_RANGE.length));
            let idx = (minuteIdx + i) % MINUTES_INDEX_RANGE.length;
            showTimeBlocks.push(getTimeBlockKey(hourIdx, idx));
        }

        return showTimeBlocks;
    };

    const highlightSelectedTimeBlock = (showTimeBlocks) => {
        setInsideSelectedTimeBlock(false);
        setShowTimeSelectorBlocks([...showTimeBlocks]);
    };

    // check if the current hoverred block is in the selected time blocks
    const containsTimeBlock = (timeBlock) => {
        return selectedTimeSlotBlocks?.length > 0 && selectedTimeSlotBlocks.some((t) => t.indexOf(timeBlock) > -1);
    };

    const incrementTimeBlock = (key, length) => {
        const timeBlockIndexArray = key.split(":");
        const hourIdx = parseInt(timeBlockIndexArray[0]);
        const minuteIdx = parseInt(timeBlockIndexArray[1]) + length;

        let nextMinuteIdx = minuteIdx % MINUTES_INDEX_RANGE.length;
        if (nextMinuteIdx < 0) {
            nextMinuteIdx += MINUTES_INDEX_RANGE.length;
        }

        let nextHourIdx = hourIdx + Math.floor(minuteIdx / MINUTES_INDEX_RANGE.length);
        return getTimeBlockKey(nextHourIdx, nextMinuteIdx);
    };

    // find the index of the current hoverred block
    const findTimeBlock = (timeBlock) => {
        if (selectedTimeSlotBlocks?.length > 0) {
            for (let i = 0; i < selectedTimeSlotBlocks.length; i++) {
                if (selectedTimeSlotBlocks[i].indexOf(timeBlock) > -1) {
                    return i;
                }
            }
        }

        return -1;
    };

    const onMouseLeaveTimeBlock = () => {
        if (dragging === undefined) {
            // Not showing any block on the graph
            setShowTimeSelectorBlocks([]);
        }
    };

    const isFirstBlock = (key) => {
        return showTimeSelectorBlocks?.length > 0 && showTimeSelectorBlocks[0] === key;
    };

    const isFirstOfAnySelectedBlock = (key) => {
        return selectedTimeSlotBlocks?.length > 0 && selectedTimeSlotBlocks.some((t) => t[0] === key);
    };

    const isLastBlock = (key) => {
        return showTimeSelectorBlocks?.length > 0 && showTimeSelectorBlocks[showTimeSelectorBlocks.length - 1] === key;
    };

    const isLastOfAnySelectedBlock = (key) => {
        return selectedTimeSlotBlocks?.length > 0 && selectedTimeSlotBlocks.some((t) => t[t.length - 1] === key);
    };

    const containsBlock = (hour) => {
        return MINUTES_INDEX_RANGE.some((minuteIdx) =>
            showTimeSelectorBlocks.indexOf(getTimeBlockKey(hour, minuteIdx)) > -1
        );
    };

    const hasOverlap = () => {
        return selectedTimeSlotBlocks?.length > 0 &&
            showTimeSelectorBlocks?.length > 0 &&
            showTimeSelectorBlocks.some((key) => containsTimeBlock(key));
    };

    const isSameBlock = () => {
        return selectedTimeSlotBlocks?.length > 0 &&
            showTimeSelectorBlocks?.length > 0 &&
            showTimeSelectorBlocks.every((key) => containsTimeBlock(key));
    };

    const findStartTimeOfSelectedBlock = (key) => {
        if (selectedTimeSlotBlocks?.length > 0) {
            for (const timeSlot of selectedTimeSlotBlocks) {
                if (timeSlot.indexOf(key) > -1) {
                    return getDateTimeFromBlockKey(timeSlot[0]);
                }
            }
        }

        return -1;
    }

    const onClickTimeBlock = (hour) => {
        if (showTimeSelectorBlocks?.length > 0 && (!hasOverlap() || isSameBlock()) && containsBlock(hour)) {
            onChangeTimeBlockFromSelectedTimeSlots(showTimeSelectorBlocks);
        }
    };

    // Keyboard function to select/unselect time block when key is pressed
    const onKeyboardPressTimeBlock = (hour) => {
        if (isSameBlock() || (!hasOverlap() && containsBlock(hour))) {
            // Clear the time block slider from visual for better accessibility
            if (isSameBlock()) {
                setShowTimeSelectorBlocks([]);
            }
            onChangeTimeBlockFromSelectedTimeSlots(showTimeSelectorBlocks);
        }
    };

    const getTimeBlockKey = (hour, minuteIdx) => {
        return `${hour}:${minuteIdx}`;
    };

    const getDateTimeFromBlockKey = (key, isLastBlock) => {
        const hour = key.split(":")[0];
        const minuteIdx = parseInt(key.split(":")[1]) + (isLastBlock ? 1 : 0);
        return getDateFromHourAndMinuteIndex(hour, minuteIdx);
    };

    const getDateFromHourAndMinuteIndex = (hour, idx) => {
        const date = parseDatePickerDateString(gridModeDate) || new Date();
        date.setHours(hour);
        date.setMinutes(idx * 15);
        date.setSeconds(0);
        return date;
    };

    const renderMultiTimeSelector = (timeSelectorOptions) => {
        const currentHourBlockRef = timeSelectorOptions.currentHourBlockRef;

        // Padding in front of time window based off start time minutes. Ex: half a column when it starts at HH:30
        const additionalTimezonesLength = props.additionalTimezones ? props.additionalTimezones.length : 0;
        const additionalTimezoneRowsHeight = GRID_CONSTANTS.ADDITIONAL_TIMEZONE_ROW_HEIGHT * additionalTimezonesLength;
        const timeSelectorTop = -1 * (GRID_CONSTANTS.TIME_ROW_HEIGHT + additionalTimezoneRowsHeight + 1);
        const timeSelectorHeight = containerHeight;

        // Time label padding
        const timeLabelTop = spansMultipleDates ? -7 : 1;

        const blockDurationWidth = (MIN_BLOCK_UNIT * columnWidth) - GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH // 15 / 60 = MIN_BLOCK_UNIT means 15mins per block
        const startLinePadding = 0 - GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH;
        const durationPadding = startLinePadding + GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH;
        const endLinePadding = durationPadding + blockDurationWidth;
        const blockWidth = blockDurationWidth + GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH;

        return (
            <React.Fragment>
                <div className="calendar-row-header" style={{
                    top: `${GRID_CONSTANTS.TIME_ROW_HEIGHT + additionalTimezoneRowsHeight}px`,
                }} />
                {hoursInTimeRange.map((hour) =>
                    <div
                        style={{
                            position: "sticky",
                            top: `${GRID_CONSTANTS.TIME_ROW_HEIGHT + additionalTimezoneRowsHeight}px`,
                            zIndex: containsBlock(hour) ? GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1 : GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX,
                        }}
                        key={`row-item-suggestions-${hour}`}
                        onClick={() => onClickTimeBlock(hour)}
                        ref={hour === currentScrollIntoHour ? currentHourBlockRef : null}
                    >
                        {MINUTES_INDEX_RANGE.map((idx) => {
                            const key = getTimeBlockKey(hour, idx);
                            const firstBlockDate = getDateFromHourAndMinuteIndex(hour, idx);
                            const lastBlockDate = getDateFromHourAndMinuteIndex(hour, idx + 1);

                            return (
                                <div style={{
                                    position: "relative",
                                    opacity: showTimeSelectorBlocks.indexOf(key) > -1 ? "1" : containsTimeBlock(key) ? "0.7" : "0"
                                }}
                                    onMouseEnter={() => onMouseEnterTimeBlock(hour, idx)}
                                    onMouseLeave={onMouseLeaveTimeBlock}
                                >
                                {/* Show the left text tooltilp if it's the first block */}
                                {isFirstBlock(key) &&
                                    <div
                                        style={{
                                            width: `${GRID_CONSTANTS.TIME_SELECTOR_TIME_WIDTH}px`,
                                            position: "absolute",
                                            left: `${startLinePadding + idx * blockWidth - (GRID_CONSTANTS.TIME_SELECTOR_TIME_WIDTH + GRID_CONSTANTS.TIME_SELECTOR_TIME_OFFSET)}px`,
                                            top: `${timeLabelTop}px`,
                                            zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1}`,
                                            backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.TIME,
                                            borderRadius: "4px",
                                            userSelect: "none",
                                        }}
                                    >
                                        <Column spacing="none">
                                            {spansMultipleDates &&
                                                <Text alignment="center" type="b100">{formatShortDate(firstBlockDate)}</Text>
                                            }
                                            <Text alignment="center" type="b100">{format12HourTimeOfDay(firstBlockDate)}</Text>
                                        </Column>
                                    </div>
                                }
                                {/* Show the left vertical line if it's the first block or if it's in the first block of any selected time slots */}
                                {(isFirstBlock(key) || isFirstOfAnySelectedBlock(key)) &&
                                    <div
                                        style={{
                                            width: `${GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH}px`,
                                            height: `${timeSelectorHeight}px`,
                                            position: "absolute",
                                            left: `${startLinePadding + idx * blockWidth}px`,
                                            top: `${timeSelectorTop}px`,
                                            zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1}`,
                                            backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.EDGE,
                                            userSelect: "none",
                                        }}
                                    />
                                }
                                <div
                                    style={{
                                        width: `${isLastBlock(key) ? blockDurationWidth : blockDurationWidth + GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH}px`,
                                        height: `${timeSelectorHeight}px`,
                                        position: "absolute",
                                        left: `${durationPadding + idx * blockWidth}px`,
                                        top: `${timeSelectorTop}px`,
                                        zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX}`,
                                        cursor: insideSelectedTimeBlock ? "move" : hasOverlap() ? "not-allowed" : "pointer",
                                        backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.BACKGROUND,
                                        opacity: "0.25",
                                        userSelect: "none",
                                    }}
                                    onMouseDown={(e) => onStartDrag(e, GRID_CONSTANTS.DRAG_COMPONENT.TIME, key)}
                                    draggable="true"
                                />
                                {/* Show the right vertical line if it's the last block or if it's in the last block of any selected time slots */}
                                {(isLastBlock(key) || isLastOfAnySelectedBlock(key)) &&
                                    <div
                                        style={{
                                            width: `${GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH}px`,
                                            height: `${timeSelectorHeight}px`,
                                            position: "absolute",
                                            left: `${endLinePadding + idx * blockWidth}px`,
                                            top: `${timeSelectorTop}px`,
                                            zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1}`,
                                            backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.EDGE,
                                            userSelect: "none",
                                        }}
                                    />
                                }
                                {/* Show the right text tooltilp if it's the last block */}
                                {isLastBlock(key) &&
                                    <div
                                        style={{
                                            width: `${GRID_CONSTANTS.TIME_SELECTOR_TIME_WIDTH}px`,
                                            position: "absolute",
                                            left: `${endLinePadding + idx * blockWidth + (GRID_CONSTANTS.TIME_SELECTOR_TIME_OFFSET)}px`,
                                            top: `${timeLabelTop}px`,
                                            zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 20}`,
                                            backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.TIME,
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <Column spacing="none">
                                            {spansMultipleDates &&
                                                <Text alignment="center" type="b100">{formatShortDate(lastBlockDate)}</Text>
                                            }
                                            <Text alignment="center" type="b100">{format12HourTimeOfDay(lastBlockDate)}</Text>
                                        </Column>
                                    </div>
                                }
                            </div>)})}
                    </div>
                )}
            </React.Fragment>
        );
    };

    const renderTimeSelector = (timeSelectorOptions) => {
        const currentHour = selectedStartTime.getHours();
        const selectedEndTime = getEndTime(selectedStartTime.getTime(), duration);

        // Time window padding calculations
        // Padding in front of time window based off start time minutes. Ex: half a column when it starts at HH:30
        let paddingByMinutes = (columnWidth / 60) * parseInt(formatTimeOfDay(selectedStartTime).split(":")[1]);
        let additionalTimezonesLength = props.additionalTimezones ? props.additionalTimezones.length : 0;
        let additionalTimezoneRowsHeight = GRID_CONSTANTS.ADDITIONAL_TIMEZONE_ROW_HEIGHT * additionalTimezonesLength;
        let timeSelectorTop = -1 * (GRID_CONSTANTS.TIME_ROW_HEIGHT + additionalTimezoneRowsHeight + 1);
        let timeSelectorHeight = containerHeight;
        let startLinePadding = paddingByMinutes - GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH;
        let durationPadding = startLinePadding + GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH;
        let durationWidth = duration ? ((duration) * columnWidth) - GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH : 0;
        let endLinePadding = durationPadding + durationWidth;

        // Time selector circle padding calculations
        let circleTop = (GRID_CONSTANTS.TIME_SELECTOR_ROW_HEIGHT / 2) - GRID_CONSTANTS.TIME_SELECTOR_CIRCLE_RADIUS + 2;
        let startCirclePadding = startLinePadding + (GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH / 2) - GRID_CONSTANTS.TIME_SELECTOR_CIRCLE_RADIUS;
        let endCirclePadding = endLinePadding + (GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH / 2) - GRID_CONSTANTS.TIME_SELECTOR_CIRCLE_RADIUS;

        // Time label padding
        let timeLabelTop = spansMultipleDates ? -7 : 1;

        return (
            <React.Fragment>
                <div className="calendar-row-header" style={{
                    top: `${GRID_CONSTANTS.TIME_ROW_HEIGHT + additionalTimezoneRowsHeight}px`,
                }}>
                    {timeSelectorOptions.renderHeader("Suggestions")}
                </div>
                {hoursInTimeRange.map((hour) =>
                    <div
                        style={{
                            position: "sticky",
                            top: `${GRID_CONSTANTS.TIME_ROW_HEIGHT + additionalTimezoneRowsHeight}px`,
                            zIndex: `${currentHour === hour ? 21 : 20}`
                        }}
                        key={`row-item-suggestions-${hour}`}
                    >
                        {currentHour === hour &&
                            <div style={{position: "relative"}}>
                                <div
                                    style={{
                                        width: `${GRID_CONSTANTS.TIME_SELECTOR_CIRCLE_RADIUS * 2}px`,
                                        height: `${GRID_CONSTANTS.TIME_SELECTOR_CIRCLE_RADIUS * 2}px`,
                                        position: "absolute",
                                        left: `${startCirclePadding}px`,
                                        top: `${circleTop}px`,
                                        zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1}`,
                                        cursor: "col-resize",
                                        backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.EDGE,
                                        borderRadius: "50%",
                                    }}
                                    onMouseDown={(e) => onStartDrag(e, GRID_CONSTANTS.DRAG_COMPONENT.START_TIME)}
                                    draggable="true"
                                >
                                    <div
                                        style={{
                                            width: `${GRID_CONSTANTS.TIME_SELECTOR_TIME_WIDTH}px`,
                                            position: "absolute",
                                            left: `${-1 * (GRID_CONSTANTS.TIME_SELECTOR_TIME_WIDTH + GRID_CONSTANTS.TIME_SELECTOR_TIME_OFFSET)}px`,
                                            top: `${timeLabelTop}px`,
                                            zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1}`,
                                            backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.TIME,
                                            borderRadius: "4px",
                                            userSelect: "none",
                                        }}
                                    >
                                        <Column spacing="none">
                                            {spansMultipleDates &&
                                                <Text alignment="center" type="b100">{formatShortDate(selectedStartTime)}</Text>
                                            }
                                            <Text alignment="center" type="b100">{format12HourTimeOfDay(selectedStartTime)}</Text>
                                        </Column>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: `${GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH}px`,
                                        height: `${timeSelectorHeight}px`,
                                        position: "absolute",
                                        left: `${startLinePadding}px`,
                                        top: `${timeSelectorTop}px`,
                                        zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1}`,
                                        cursor: "col-resize",
                                        backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.EDGE,
                                        userSelect: "none",
                                    }}
                                    onMouseDown={(e) => onStartDrag(e, GRID_CONSTANTS.DRAG_COMPONENT.START_TIME)}
                                    draggable="true"
                                    ref={timeSlider}
                                />
                                <div
                                    style={{
                                        width: `${durationWidth}px`,
                                        height: `${timeSelectorHeight}px`,
                                        position: "absolute",
                                        left: `${durationPadding}px`,
                                        top: `${timeSelectorTop}px`,
                                        zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX}`,
                                        cursor: "move",
                                        backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.BACKGROUND,
                                        opacity: "0.25",
                                        userSelect: "none",
                                    }}
                                    onMouseDown={(e) => onStartDrag(e, GRID_CONSTANTS.DRAG_COMPONENT.TIME)}
                                    draggable="true"
                                />
                                <div
                                    style={{
                                        width: `${GRID_CONSTANTS.TIME_SELECTOR_BORDER_WIDTH}px`,
                                        height: `${timeSelectorHeight}px`,
                                        position: "absolute",
                                        left: `${endLinePadding}px`,
                                        top: `${timeSelectorTop}px`,
                                        zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1}`,
                                        cursor: "col-resize",
                                        backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.EDGE,
                                        userSelect: "none",
                                    }}
                                    onMouseDown={(e) => onStartDrag(e, GRID_CONSTANTS.DRAG_COMPONENT.END_TIME)}
                                    draggable="true"
                                />
                                <div
                                    style={{
                                        width: `${GRID_CONSTANTS.TIME_SELECTOR_CIRCLE_RADIUS * 2}px`,
                                        height: `${GRID_CONSTANTS.TIME_SELECTOR_CIRCLE_RADIUS * 2}px`,
                                        position: "absolute",
                                        left: `${endCirclePadding}px`,
                                        top: `${circleTop}px`,
                                        zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1}`,
                                        cursor: "col-resize",
                                        backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.EDGE,
                                        borderRadius: "50%",
                                        userSelect: "none",
                                    }}
                                    onMouseDown={(e) => onStartDrag(e, GRID_CONSTANTS.DRAG_COMPONENT.END_TIME)}
                                    draggable="true"
                                >
                                    <div
                                        style={{
                                            width: `${GRID_CONSTANTS.TIME_SELECTOR_TIME_WIDTH}px`,
                                            position: "absolute",
                                            left: `${(2 * GRID_CONSTANTS.TIME_SELECTOR_CIRCLE_RADIUS) + GRID_CONSTANTS.TIME_SELECTOR_TIME_OFFSET}px`,
                                            top: `${timeLabelTop}px`,
                                            zIndex: `${GRID_CONSTANTS.TIME_SELECTOR_Z_INDEX + 1}`,
                                            backgroundColor: GRID_CONSTANTS.TIME_SELECTOR_COLOR.TIME,
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <Column spacing="none">
                                            {spansMultipleDates &&
                                                <Text alignment="center" type="b100">{formatShortDate(selectedEndTime)}</Text>
                                            }
                                            <Text alignment="center" type="b100">{format12HourTimeOfDay(selectedEndTime)}</Text>
                                        </Column>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )}
            </React.Fragment>
        );
    };

    return (
        <div
            className="calendar-day-grid"
            id="calendar-day-grid"
            role="table"
            ref={container}
            style={{
                display: "grid",
                gridTemplateColumns: `${groupColumnWidth}px repeat(${columnHeaders.length}, ${columnWidth}px)`,
                gridTemplateRows: isMultiSelection ? "3px 33px repeat(1, 0fr)" : "36px repeat(1, 0fr)",
                overflow: "auto",
            }}
            onMouseMove={dragging && onDrag}
            onMouseUp={dragging && onEndDrag}
            onMouseLeave={dragging && onEndDrag}
        >
            {isMultiSelection && renderKeyboardNavigationRow()}

            {renderFirstCell()}

            {renderColumnHeaders()}

            {renderAdditionalTimezones()}

            {props.timeSelectorOptions &&
                isMultiSelection ?
                    renderMultiTimeSelector(props.timeSelectorOptions)
                    :
                    renderTimeSelector(props.timeSelectorOptions)}

            {props.sections ?
                renderSections(props.sections) :
                renderRows(groupIds)
            }
        </div>
    );
};

export default CalendarDayGrid;
