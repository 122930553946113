import * as Actions from "./action-types";

export const updateUserFeedback = (userFeedback) => {
    return {type: Actions.UPDATE_USER_FEEDBACK, userFeedback};
};

export const updateUserRating = (userRating) => {
    return {type: Actions.UPDATE_USER_RATING, userRating};
};

export const clearFeedback = () => {
    return {type:Actions.CLEAR_FEEDBACK};
};

export const getFeedbackEligibility = (alias) => {
    return {type:Actions.GET_FEEDBACK_ELIGIBILITY, alias};
};

export const setIsEligible = (isEligible) => {
    return {type:Actions.SET_IS_ELIGIBLE, isEligible};
};

export const setFeedbackAlertMessage = (feedbackAlertMessage) => {
    return {type:Actions.SET_FEEDBACK_ALERT_MESSAGE, feedbackAlertMessage};
};