import { TOAST_ACTION_TYPE, TOAST_TYPE, TIMEOUT } from "./toast-constants";

export const getSuccessToast = (toastMessage) => {
    return getToast(toastMessage, TOAST_TYPE.SUCCESS);
};

export const getWarningToast = (toastMessage) => {
    return getToast(toastMessage, TOAST_TYPE.WARNING);
};

export const getErrorToast = (toastMessage) => {
    return getToast(toastMessage, TOAST_TYPE.ERROR);
};

export const getInformationalToast = (toastMessage) => {
    return getToast(toastMessage, TOAST_TYPE.INFORMATIONAL);
};

const getToast = (toastMessage, toastType) => {
    return {
        id: generateToastId(),
        toastType: toastType,
        toastActionType: TOAST_ACTION_TYPE.NONE,
        toastMessage: toastMessage,
        toastTimeout: TIMEOUT.DEFAULT
    };
};

export const getSuccessToastWithComponent = () => {
    return getToastWithComponent(TOAST_TYPE.SUCCESS);
};

export const getWarningToastWithComponent = () => {
    return getToastWithComponent(TOAST_TYPE.WARNING);
};

export const getErrorToastWithComponent = () => {
    return getToastWithComponent(TOAST_TYPE.ERROR);
};

export const getInformationalToastWithComponent = () => {
    return getToastWithComponent(TOAST_TYPE.INFORMATIONAL);
};

const getToastWithComponent = (toastType) => {
    return {
        id: generateToastId(),
        toastType: toastType,
        toastActionType: TOAST_ACTION_TYPE.DISPLAY_COMPONENT,
        toastMessage: "",
        toastActionProps: {},
        toastTimeout: TIMEOUT.DEFAULT
    };
};

const generateToastId = () => {
    return String(Number(new Date()));
};