import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { switchWorkflow } from "../../shared/workflow/actions";
import { changeStep, updateCurrentMeeting } from "../../meeting-scheduler/actions";
import { addAttendeeByEmail, clearAttendees } from "../../people/actions";
import { getAttendees } from "../../../sagas/selector";

import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Expander from "@amzn/meridian/expander";
// import Link from "@amzn/meridian/link";

import PollAttendeePopover from "./poll-attendee-popover";

import "../meeting-polls.css";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { formatDateTime, showAttendee } from "../poll-utils";
import { renderPollPriorityIcon } from "../../people/people-utils";
import { POLL_PRIORITY } from "../../people/people-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { RESULT_PAGE_SIZE } from "../poll-constants";
import { STEP } from "../../meeting-scheduler/meeting-scheduler-constants";

const PollResultCard = (props) => {
    const identity = props.identity;
    const pollDetails = props.pollDetails;
    const attendeeList = props.attendeeList;
    const respondedAttendeeList = props.respondedAttendeeList;
    const availability = props.availability;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const attendeeInformationList = useSelector(getAttendees);

    // TODO attendee popover
    const [openAttendeePopover, setOpenAttendeePopover] = useState("");
    const onCloseAttendeePopover = () => setOpenAttendeePopover("");
    const attendeePopoverRefs = useRef([]);

    // Time and timezone
    const startTime = new Date(props.availability.startTime);
    const endTime = new Date(props.availability.endTime);
    const timezone = pollDetails.timezone;
    const timezones = props.timezones;
    const timeFormat = props.timeFormat;
    const browserTimezoneName = timezoneIdToDisplayName(timezones, timezone) || "";

    const [expanderOpen, setExpanderOpen] = useState();

    const dispatch = useDispatch();

    // Switch to standard workflow
    const onChangeStep = (step) => dispatch(changeStep(step));
    const onUpdateCurrentMeeting = (meeting) => dispatch(updateCurrentMeeting(meeting));

    const availableAttendeeList = (respondedAttendeeList && availability && respondedAttendeeList.filter(
        (attendee) => attendee.availability && attendee.availability.find(
            (attendeeAvailability) => attendeeAvailability.startTime <= availability.startTime && attendeeAvailability.endTime >= availability.endTime) !== undefined
    )) || [];
    const unavailableAttendeeList = (respondedAttendeeList && respondedAttendeeList.filter((attendee) => availableAttendeeList && !availableAttendeeList.includes(attendee))) || [];

    const onRedirectCreate = (step = undefined) => {
        dispatch(clearAttendees());
        dispatch(addAttendeeByEmail({email: identity.email}, "organizer"));
        attendeeList.forEach((attendee) => {
           dispatch(addAttendeeByEmail(attendee, attendee.priority));
        });
        onUpdateCurrentMeeting({
            subject: pollDetails.subject,
            body: {
                value: pollDetails.body,
            },
            time: {
                startTime: startTime.getTime(),
                endTime: endTime.getTime(),
            },
            startTimezone: {id: timezone},
            endTimezone: {id: timezone},
        });
        dispatch(push("/create-meeting-from-poll"));
        dispatch(switchWorkflow(WORKFLOWS.STANDARD_MEETING));
        onChangeStep(STEP.MEETING_AGENDA_AND_REVIEW);
    };

    // TODO: Temporarily disable the attendee popover, will update it later.
    /*const showAttendeeWithLink = (attendee) => {
        return (
            <Row key={attendee.email} spacing="small">
                {renderPollPriorityIcon(attendee.priority, false, 16)}
                <Link
                    type="secondary"
                    onClick={() => viewAttendeeDetails(attendee)}
                    ref={(ref) => attendeePopoverRefs.current[attendee.displayName] = ref}
                >
                    <Text type="b100">
                        {attendee.displayName}
                    </Text>
                </Link>
            </Row>
        )
    };

    const viewAttendeeDetails = (attendee) => {
        setOpenAttendeePopover(attendee.displayName);
    };*/

    const renderTimeAndScheduleButton = () => {
        return (
            <Column
                width="180px"
                alignmentHorizontal="left"
                spacing="large"
                spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ? "medium none none small" : "small none none none"}
            >
                <Row spacing="small">
                    <Column alignmentHorizontal="left" spacing="xxsmall">
                        <Text type="h300">
                            {formatDateTime(startTime, "dayOfDate", timezone)}
                        </Text>
                        <Text type="b200" color="secondary">
                            {formatDateTime(startTime, "shortMonth", timezone).toUpperCase()}
                        </Text>
                    </Column>
                    <Column alignmentHorizontal="left" spacing="small">
                        <Text type="b100" color="secondary">
                            {formatDateTime(startTime, "shortWeekday", timezone).toUpperCase()}
                        </Text>
                        <Row spacing="xxsmall">
                            <div className="tag-text">
                                {formatDateTime(startTime, "time", timezone, timeFormat)}-{formatDateTime(endTime, "time", timezone, timeFormat)}
                            </div>
                            <Text type="b100" color="secondary">
                                {browserTimezoneName}
                            </Text>
                        </Row>
                    </Column>
                </Row>
                <Button
                    minWidth="150px"
                    className="schedule-button"
                    type="tertiary"
                    size="medium"
                    onClick={onRedirectCreate}
                    label="Schedule meeting"
                >
                    Schedule
                </Button>
            </Column>
        )
    };

    const renderAttendeesExpander = () => {
        return (
            <Expander
                open={expanderOpen}
                onChange={setExpanderOpen}
                title="Attendees"
                type="inline"
            >
                <div style={{
                    position: "relative",
                    right: "35px"
                }}>
                    <Column spacing="none" spacingInset="none">
                        <Row spacing="medium" spacingInset="medium none large none" aria-hidden="true">
                            <Row spacing="small">
                                {renderPollPriorityIcon(POLL_PRIORITY.REQUIRED)}
                                <Text type="b100">
                                    Required
                                </Text>
                            </Row>
                            <Row spacing="small">
                                {renderPollPriorityIcon(POLL_PRIORITY.OPTIONAL)}
                                <Text type="b100">
                                    Optional
                                </Text>
                            </Row>
                        </Row>
                        <Row
                            alignmentVertical="top"
                            alignmentHorizontal="left"
                            spacing={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ? "xlarge" : "small"}
                        >
                            <Column
                                spacing="small"
                                spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ? "none large none none" : "none"}
                            >
                                <h3 className="tag-text">
                                    Available ({availableAttendeeList.length}/{respondedAttendeeList.length})
                                </h3>
                                <div role="list">
                                    {availableAttendeeList && availableAttendeeList.map((attendee) =>
                                        <div role="listitem" style={{marginBottom: "8px"}}>
                                            {showAttendee(attendee)}
                                            <PollAttendeePopover
                                                position="bottom"
                                                attendee={attendeeInformationList.find((attendeeInformation) => attendeeInformation.email === attendee.email)}
                                                anchorNode={attendeePopoverRefs.current[attendee.displayName]}
                                                open={openAttendeePopover === attendee.displayName}
                                                onClose={onCloseAttendeePopover}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Column>
                            <Column spacing="small">
                                <h3 className="tag-text">
                                    Unavailable ({unavailableAttendeeList.length}/{respondedAttendeeList.length})
                                </h3>
                                <div role="list">
                                    {unavailableAttendeeList && unavailableAttendeeList.map((attendee) =>
                                        <div role="listitem" style={{marginBottom: "8px"}}>
                                            {showAttendee(attendee)}
                                            <PollAttendeePopover
                                                position="bottom"
                                                attendee={attendeeInformationList.find((attendeeInformation) => attendeeInformation.email === attendee.email)}
                                                anchorNode={attendeePopoverRefs.current[attendee.displayName]}
                                                open={openAttendeePopover === attendee.displayName}
                                                onClose={onCloseAttendeePopover}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Column>
                        </Row>
                    </Column>
                </div>
            </Expander>
        )
    };

    const renderExpanderWithMobileView = () => {
        return (
            <div style={{
                position:"relative",
            }}>
                {renderAttendeesExpander()}
            </div>
        )
    };

    const renderExpanderWithoutMobileView = () => {
        return (
            <div style={{
                position: expanderOpen ? "relative" : "absolute",
                top: expanderOpen ? "" : "100px",
                bottom: expanderOpen ? "42px" : ""
            }}>
                {renderAttendeesExpander()}
            </div>
        )
    };

    return (
        <section aria-label={"Proposed time " + props.index}>
            <Card
                width={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ? RESULT_PAGE_SIZE.CARD_WIDTH : RESULT_PAGE_SIZE.CARD_MOBILE_VIEW_WIDTH}
                spacingInset="small"
                onClick={() => {}}
            >
                <Column
                    width={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ? RESULT_PAGE_SIZE.CARD_WIDTH : RESULT_PAGE_SIZE.CARD_MOBILE_VIEW_WIDTH}
                    spacing="none"
                    spacingInset="none xxsmall small medium"
                >
                    <Row alignmentVertical="justify" spacing="medium" spacingInset="none">
                        <Column spacing="xsmall" spacingInset="medium none none none">
                            <Text type="h100" color="secondary">
                                Available
                            </Text>
                            <Text type="h500">
                                {availableAttendeeList.length}
                            </Text>
                        </Column>
                        <Column spacing="xsmall" spacingInset="medium none none none">
                            <Text type="h100" color="secondary">
                                Unavailable
                            </Text>
                            <Text type="h500">
                                {unavailableAttendeeList.length}
                            </Text>
                        </Column>
                        {screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL &&
                            <Row spacing="medium" spacingInset="none">
                                <div style={{
                                    height: "130px",
                                    borderLeft: "1px solid #E7E9E9"
                                }} />
                                {renderTimeAndScheduleButton()}
                            </Row>
                        }
                    </Row>
                    {screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ?
                        renderExpanderWithoutMobileView()
                        :
                        <Column spacingInset="none">
                            {renderExpanderWithMobileView()}
                            <div className="poll-horizontal-divider" />
                            {renderTimeAndScheduleButton()}
                        </Column>
                    }
                </Column>
            </Card>
        </section>
    )
};

export default PollResultCard;