import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { css, cx } from "emotion"
import { getIconFromTokens } from "../../_utils/token"
import { memoize } from "../../_utils/functional"

const spanStyles = memoize(size =>
  css({
    position: "relative",
    flexShrink: 0,
    display: "inline-block",
    width: size,
    height: size,
    verticalAlign: "middle",
  })
)

const imgSpanStyles = css({
  // Don't show the icon's label
  fontSize: 0,
  // Center the icon image
  "& svg": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
  },
})

// These RTL styles are applied to a parent span only when an icon contains an LTR and RTL version.
const rtlStyles = css({
  [`span:nth-child(1)`]: {
    [`[dir="rtl"] &`]: {
      display: "none",
    },
  },
  [`span:nth-child(2)`]: {
    display: "none",
    [`[dir="rtl"] &`]: {
      display: "inline-block",
    },
  },
})

const shouldNotUpdate = (prevProps, nextProps) =>
  nextProps.tokens === prevProps.tokens &&
  nextProps.className === prevProps.className &&
  nextProps.children === prevProps.children

const decodeSvgString = svgData =>
  decodeURIComponent(
    svgData.replace(/^data:image\/svg\+xml,/, "")
    // Add aria-hidden="true" so that screen readers ignore the underlying svg
    // (we label the parent span) and focusable="false" because IE11 defaults
    // to adding SVGs to the tab order
  ).replace(/<svg/, `<svg aria-hidden="true" focusable="false"`)

const Icon = React.memo(
  React.forwardRef((props, ref) => {
    const { data, dataRtl, width, height } = getIconFromTokens(props.tokens)
    const svg = decodeSvgString(data)
    // Create an RTL version of the SVG to be used for the RTL icon if it exists, else null
    const svgRtl = dataRtl ? decodeSvgString(dataRtl) : null
    const html = useMemo(() => ({ __html: svg }), [svg])
    // Generate RTL html for the RTL version of an SVG
    const htmlRtl = useMemo(() => ({ __html: svgRtl }), [svgRtl])
    const size =
      width > Icon.sizes.small || height > Icon.sizes.small
        ? Icon.sizes.large
        : Icon.sizes.small
    const simpleChildren =
      props.children === undefined || typeof props.children === "string"
    return (
      <span
        className={
          dataRtl
            ? cx(rtlStyles, spanStyles(size), props.className)
            : cx(spanStyles(size), props.className)
        }
      >
        <span
          role="img"
          aria-hidden={!props.children ? true : undefined}
          aria-label={simpleChildren ? props.children : undefined}
          className={imgSpanStyles}
          tabIndex={props.tabIndex}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          ref={ref}
          dangerouslySetInnerHTML={simpleChildren ? html : undefined}
        >
          {simpleChildren ? null : (
            <React.Fragment>
              <span dangerouslySetInnerHTML={html} />
              {props.children}
            </React.Fragment>
          )}
        </span>
        {dataRtl && (
          <span
            role="img"
            aria-hidden={!props.children ? true : undefined}
            aria-label={simpleChildren ? props.children : undefined}
            className={imgSpanStyles}
            tabIndex={props.tabIndex}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            ref={ref}
            dangerouslySetInnerHTML={simpleChildren ? htmlRtl : undefined}
          >
            {simpleChildren ? null : (
              <React.Fragment>
                <span dangerouslySetInnerHTML={htmlRtl} />
                {props.children}
              </React.Fragment>
            )}
          </span>
        )}
      </span>
    )
  }),
  shouldNotUpdate
)

Icon.sizes = { small: 16, large: 24 }

Icon.displayName = "Icon"

Icon.propTypes = {
  /**
   * An object of icon tokens imported from the Meridian token library.
   */
  tokens: PropTypes.object.isRequired,
  /**
   * An accessible label for the icon (not visible in the UI). Since version 4.x
   * a Badge component can be passed in along with the label in order to render
   * a badge on the icon (visible in the UI).
   *
   * @since 4.x
   */
  children: PropTypes.node,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   * This can be used for positioning the icon or setting the icon's color
   *
   * This should *not* be used to override other styles on the component.
   * Meridian's internal CSS and HTML are private APIs and may change at any
   * time, potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  /**
   * Manually specify the order of the component in sequential keyboard navigation.
   *
   * If this is not specified the component will not be focuseable.
   *
   * Use this with caution. See docs for the [HTML tabindex attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex)
   * for details on appropriate usage.
   */
  tabIndex: PropTypes.number,
  /**
   * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   *
   * Note that `tabIndex` must be specified in order for the component to be
   * focuseable.
   */
  onBlur: PropTypes.func,
  /**
   * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   *
   * Note that `tabIndex` must be specified in order for the component to be
   * focuseable.
   */
  onFocus: PropTypes.func,
}

Icon.sizes = { small: 16, large: 24 }

export default Icon
