// Configs for AmazonMeetingsService
export const ENDPOINTS = {
    Alpha: {
        PDX: "https://96y26z8571.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    Beta: {
        PDX: "https://v9o25o8ae2.execute-api.us-west-2.amazonaws.com/beta/"
    },
    BetaPrime: {
        PDX: "https://v9o25o8ae2.execute-api.us-west-2.amazonaws.com/beta/" // BetaPrime will use AMS Beta for now
    },
    Prod: {
        PDX: "https://yyhzdcb904.execute-api.us-west-2.amazonaws.com/prod/"
    },

    // Dev Config
    alvinyu: {
        PDX: "https://qtl2ofje7e.execute-api.us-west-2.amazonaws.com/alvinyu/"
    },
    amiyyapu: {
        PDX: "https://1xx0525tfl.execute-api.us-west-2.amazonaws.com/amiyyapu/"
    },
    amberyi: {
        PDX: "https://cl6amqzbo6.execute-api.us-west-2.amazonaws.com/amberyi/"
    },
    anirudps: {
    	PDX: "https://8b3j4i6l1l.execute-api.us-west-2.amazonaws.com/anirudps/"
    },
    camei: {
        PDX: "https://fdpr05yd88.execute-api.us-west-2.amazonaws.com/camei/"
    },
    daverod: {
        PDX: "https://zwloikpop6.execute-api.us-west-2.amazonaws.com/daverod/" // https://api.pdx.daverod.ams.daverod.people.a2z.com/daverod/
    },
    kjoshuaz: {
        PDX: "https://9c3b7zx2h7.execute-api.us-west-2.amazonaws.com/kjoshuaz/"
    },
    mahtabr: {
        PDX: "https://1xx0525tfl.execute-api.us-west-2.amazonaws.com/amiyyapu/"
    },
    migueldz: {
        PDX: "https://lfqo2wdp40.execute-api.us-west-2.amazonaws.com/migueldz/"
    },
    niskar: {
        PDX: "https://1xx0525tfl.execute-api.us-west-2.amazonaws.com/amiyyapu/"
    },
    tejavk: {
        PDX: "https://1xx0525tfl.execute-api.us-west-2.amazonaws.com/amiyyapu/"
    },
    uccdev: {
        PDX: "https://1xx0525tfl.execute-api.us-west-2.amazonaws.com/amiyyapu/"
    },
    yangzhuo: {
        PDX: "https://h2ffw520y0.execute-api.us-west-2.amazonaws.com/yangzhuo/"
    },
    ziyuyang: {
        PDX: "https://zwloikpop6.execute-api.us-west-2.amazonaws.com/daverod/"
    }
};

export const OPERATIONS = {
    GET_USER_FAVORITES: "getUserFavorites",
    GET_USER_PREFERENCES: "getUserPreferences",
    SAVE_USER_FAVORITES: "saveUserFavorites",
    SAVE_USER_PREFERENCES: "saveUserPreferences",
    CREATE_SIM_TICKET: "createSimTicket",
    CREATE_POLL: "createPoll",
    FIND_POLLS: "findPolls",
    GET_ATTENDEE_POLL: "getAttendeePoll",
    GET_POLL: "getPoll",
    DELETE_POLL: "deletePoll",
    RESPOND_POLL: "respondPoll",
    UPDATE_POLL: "updatePoll",
    SEND_NOTIFICATION: "sendNotification",
    GET_FEEDBACK_ELIGIBILITY: "getFeedbackEligibility",
};

// Paths can be obtained from:
// https://code.amazon.com/packages/AmazonMeetingsServiceCDK/blobs/mainline/--/src/amazon-meetings-lambda/RequestHandlers/operations.js
export const OPERATION_PATHS = {
    getUserFavorites: "api/preferences/get",
    saveUserFavorites: "api/favorites/save",
    saveUserPreferences: "api/preferences/save",
    createSimTicket: "api/tickets/create",
    createPoll: "api/poll/create",
    findPolls: "api/poll/find",
    getAttendeePoll: "api/poll/attendee/get",
    getPoll: "api/poll/get",
    deletePoll: "api/poll/delete",
    respondPoll: "api/poll/respond",
    updatePoll: "api/poll/update",
    sendNotification: "api/poll/notification/send",
    getFeedbackEligibility: "api/feedback/get",
};
