import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getFeedbackAlertMessage, getIsEligible, getUserFeedback, getUserRating } from "../../../../sagas/selector";
import { clearFeedback, updateUserFeedback, updateUserRating } from "../actions";

import MeetingsFeedbackModal from "../components/meetings-feedback-modal";

const MeetingsFeedbackContainer = () => {
    const dispatch = useDispatch();

    const isEligible = useSelector(getIsEligible);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState();
    const userFeedback = useSelector(getUserFeedback);
    const userRating = useSelector(getUserRating);
    const feedbackAlertMessage = useSelector(getFeedbackAlertMessage);
    const onUpdateUserFeedback = (feedback) => dispatch(updateUserFeedback(feedback));
    const onUpdateUserRating = (rating) => dispatch(updateUserRating(rating));
    const onClearFeedback = () => dispatch(clearFeedback());

    useEffect(() => {
        if (isEligible) {
            setFeedbackModalOpen(true);
        }
    }, [isEligible]);

    return (
        <MeetingsFeedbackModal
            open={feedbackModalOpen}
            userFeedback={userFeedback}
            userRating={userRating}
            onClose={() => setFeedbackModalOpen(false)}
            onUpdateUserFeedback={onUpdateUserFeedback}
            onUpdateUserRating={onUpdateUserRating}
            onClearFeedback={onClearFeedback}
            feedbackAlertMessage={feedbackAlertMessage}
        />
    );
};

export default MeetingsFeedbackContainer;