import React from "react"
import PropTypes from "prop-types"
import { cx, css } from "emotion"

const mountStyled = (opacity, scale, duration, easing) =>
  css({
    opacity: opacity,
    transform: `scale(${scale})`,
    transition: `opacity ${duration} ${easing}, transform ${duration} ${easing}`,
  })

class AnimateFadeScale extends React.Component {
  static propTypes = {
    /**
     * A function that returns the elements to animate.
     */
    children: PropTypes.func.isRequired,
    /**
     * If true the children will be mounted, otherwise they will be unmounted.
     */
    open: PropTypes.bool.isRequired,
    /**
     * Allows the parent AnimateMount component to worry about unmounting the
     * component so we can focus on animation
     */
    transitionEnd: PropTypes.func.isRequired,
    /**
     * Allows the parent AnimateMount component to worry about mounting the
     * component so we can focus on animation
     */
    updateStateOnOpen: PropTypes.func.isRequired,
    /**
     * Accepts the shared css class provided by the AnimateMount parent
     */
    className: PropTypes.string,
    /**
     * The duration of the animation. This should be a valid CSS animation
     * duration (e.g. `"100ms"` or `"1s"`).
     */
    duration: PropTypes.string,
    /**
     * The easing function of the animation. This should be a valid CSS easing
     * function (e.g. `"linear"` or `"ease"`).
     */
    easing: PropTypes.string,
  }

  static defaultProps = {
    duration: "150ms",
    easing: "ease",
  }

  state = {
    opacity: 0,
    scale: 0.75,
  }

  timer = null

  componentDidMount() {
    if (this.props.open) {
      this.updateStateOnOpen()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      this.updateStateOnUnmount()
    } else if (!prevProps.open && this.props.open) {
      this.updateStateOnMount()
    }
  }

  componentWillUnmount() {
    // Clear our timer to prevent leaks
    clearTimeout(this.timer)
  }

  updateStateOnOpen = () => {
    // Tell the wrapper component to remount the node when the open prop is true
    this.props.updateStateOnOpen()
    // Set state to trigger intro animation
    this.timer = setTimeout(this.updateStateOnMount, 0)
  }

  updateStateOnUnmount = () => {
    // Update state for unmount animation
    this.setState({
      opacity: 0,
      scale: 0.75,
    })
  }
  updateStateOnMount = () => {
    // Update state for mount animation
    this.setState({
      opacity: 1,
      scale: 1,
    })
  }

  transitionEnd = () => {
    if (!this.props.open) {
      // Tell the wrapper component to remove the node on transition end when
      // the open prop is false
      this.props.transitionEnd()
    }
  }

  render() {
    const { opacity, scale } = this.state
    const { children, duration, easing, className } = this.props
    const styles = mountStyled(opacity, scale, duration, easing)
    return children({
      open: opacity !== 0,
      className: cx(className, styles),
      onTransitionEnd: this.transitionEnd,
    })
  }
}

export default AnimateFadeScale
