import React from "react"
import PropTypes from "prop-types"
import checkIconTokens from "@amzn/meridian-tokens/base/icon/check-large"
import Clickable from "../_clickable"
import Icon from "../icon"
import filterPropsByPrefix from "../../_utils/filter-props-by-prefix"

class OptionListItem extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    children: PropTypes.func,
    /**
     * All props prefaced with `data-` are accepted. This is useful for
     * integrating with other libraries such as Amplify Analytics or Cypress.
     *
     * @since 5.x
     */
    "data-*": PropTypes.string,
    /**
     * This disables interaction with the component and applies special visual
     * styles to indicate that it's not interactive.
     */
    disabled: PropTypes.bool,
    /**
     * If a URL is provided here, the component will behave like a link.
     *
     * Note that you must either set this prop or the `onClick` prop (or both).
     */
    href: PropTypes.string,
    /**
     * IMPORTANT: This is provided by OptionList, do not set manually.
     */
    id: PropTypes.string,
    /**
     * IMPORTANT: This is provided by OptionList, do not set manually.
     */
    preselected: PropTypes.bool,
    /**
     * The relationship of the linked URL to the current page. Only applicable if
     * `href` is set.
     *
     * See docs for the [a tag's rel attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-rel) for details.
     */
    rel: PropTypes.string,
    selected: PropTypes.bool,
    /**
     * IMPORTANT: This is provided by OptionList, do not set manually.
     */
    showIndicator: PropTypes.bool,
    /**
     * Determine where the linked URL is displayed. This is only applicable if
     * `href` is provided but `onClick` is not.
     *
     * IMPORTANT: If you set this prop to `"_blank"`, make sure to set `rel` to
     * `"noopener noreferrer"` to avoid performance and security issues.
     *
     * See docs for the [a tag's target attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target) for details.
     */
    target: PropTypes.string,
    /**
     * IMPORTANT: This is provided by OptionList, do not set manually.
     */
    onChangePreselected: PropTypes.func,
    /**
     * This will be triggered when the component is clicked. If the `href` prop is
     * set, that value will be passed as the first argument to this function.
     *
     * If this prop is used along with the `href` prop, the default link behavior
     * of the component will be disabled and the click handler will be responsible
     * for implementing the behavior of the component.
     *
     * These features make it easy to implement accessible client-side navigation.
     * If you have a client-side navigation function of the form `navigate(<url>)`, you
     * can integrate it into this component by setting a URL via `href` and then
     * passing `navigate` directly to this prop (i.e. `href={url} onClick={navigate}`).
     * See the [Routing and navigation guide](https://meridian.a2z.com/for-developers/guides/routing-and-navigation/?platform=reactjs)
     * for details.
     *
     * Note that you must set either this prop or the `href` prop (or both).
     */
    onClick: PropTypes.func,
  }

  static defaultProps = {
    selected: false,
    preselected: false,
  }

  static getDerivedStateFromProps(props, state) {
    const { preselected, selected } = state
    if (props.selected !== selected) {
      // If the option was just selected or deselected then don't show the
      // preselected state
      return { selected: props.selected, preselected: false }
    } else if (props.preselected !== preselected) {
      // If the preselected prop was changed from on high then update the
      // internal preselected state to stay in sync
      return { preselected: props.preselected }
    } else {
      return null
    }
  }

  state = { preselected: this.props.preselected, selected: this.props.selected }

  // These help us determine if the user actually moved their mouse off of an
  // option or if an option was just scrolled out from under the mouse.
  mouseX = 0
  mouseY = 0

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.label !== this.props.label ||
      nextProps.children !== this.props.children ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.href !== this.props.href ||
      nextProps.id !== this.props.id ||
      nextState.selected !== this.state.selected ||
      nextState.preselected !== this.state.preselected
    )
  }

  onMouseMove = event => {
    if (!this.props.preselected) {
      this.props.onChangePreselected(true)
    }

    // Track the mouse position so we can detect whether `onMouseLeaveOption`
    // was triggered by a mouse move or by something else (e.g. scrolling).
    this.mouseX = event.clientX
    this.mouseY = event.clientY
  }

  onMouseEnter = event => {
    // Track the mouse position so we can detect whether `onMouseLeaveOption`
    // was triggered by a mouse move or by something else (e.g. scrolling).
    this.mouseX = event.clientX
    this.mouseY = event.clientY
  }

  onMouseLeave = event => {
    // If the user has moused out of an option by moving their mouse (and not by
    // scrolling the option out from under their mouse) then blur it. Don't blur
    // if they haven't moved their mouse. This allows the user to use keyboard
    // navigation while their mouse cursor is resting over the list (otherwise
    // the mouse and keyboard end up fighting over control).
    if (
      this.props.preselected &&
      (event.clientX !== this.mouseX || event.clientY !== this.mouseY)
    ) {
      this.props.onChangePreselected(false)
    }
  }

  render() {
    const props = this.props
    const { selected, preselected } = this.state
    const children = props.children
      ? props.children({ selected, preselected })
      : props.label
    const passThroughProps = filterPropsByPrefix(props, ["data-"])
    return (
      <Clickable
        {...passThroughProps}
        disabled={props.disabled}
        id={props.id}
        href={props.href}
        rel={props.rel}
        target={props.target}
        onClick={props.onClick}
        onMouseMove={props.disabled ? undefined : this.onMouseMove}
        onMouseEnter={props.disabled ? undefined : this.onMouseEnter}
        onMouseLeave={props.disabled ? undefined : this.onMouseLeave}
        role="option"
        aria-selected={selected || preselected}
        tabIndex="-1"
      >
        <div>{children}</div>
        {props.showIndicator ? <Icon tokens={checkIconTokens} /> : null}
      </Clickable>
    )
  }
}

export default OptionListItem
