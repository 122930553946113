import {
    CLEAR_CHIME_PIN,
    CREATE_CHIME_PIN,
    GET_USER_BY_EMAIL,
    SAVE_CHIME_PIN,
    SAVE_GET_USER_BY_EMAIL,
} from "./action-types";

export const getUserByEmail = (getUserByEmailRequest) => {
    return {type: GET_USER_BY_EMAIL, getUserByEmailRequest}
};

export const saveGetUserByEmail = (userDetails) => {
    return {type: SAVE_GET_USER_BY_EMAIL, userDetails}
};

export const createChimePin = (createChimePinRequest) => {
    return {type: CREATE_CHIME_PIN, createChimePinRequest}
};

export const saveChimePin = (uniqueIds) => {
    return {type: SAVE_CHIME_PIN, uniqueIds}
};

export const clearChimePin = () => {
    return {type: CLEAR_CHIME_PIN}
};