import React from "react"
import PropTypes from "prop-types"
import MiniCalendar from "../_mini-calendar"
import dateLocales from "../../_utils/date-locales"

/**
 * This component provides a nice way for a user to input a single date. The UI
 * is a month calendar that allows the user to either select a day in the
 * current month or to browse other months. If the "name" prop is provided a
 * normal html input is rendered under the hood so this component can be used
 * like any other form control.
 */
class DateInputCalendar extends React.Component {
  static propTypes = {
    /**
     * This date is used to determine which month the calendar should display
     * by default. The date must be provided in the format YYYY-MM-DD.
     */
    viewDate: PropTypes.string.isRequired,
    /**
     * A function that will be called when the value of the date input is
     * changed by the user. The new date will be passed to the function as a
     * string with the format YYYY-MM-DD.
     *
     * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
     * so you must track the values provided to this function somewhere in state
     * and pass them back via the `value` prop in order to make this component
     * interactive.
     */
    onChange: PropTypes.func.isRequired,
    /**
     * A function that will be called whenever the calendar wants to update the
     * view date itself.
     */
    onChangeViewDate: PropTypes.func.isRequired,
    /**
     * Determines whether changing months is animated with a sliding motion.
     */
    animateMonthChange: PropTypes.bool,
    /**
     * A function that can be used to disable specific dates. The function will
     * be passed date strings in the format YYYY-MM-DD and is expected to return
     * true for disabled dates and false otherwise.
     */
    disabledDates: PropTypes.func,
    /**
     * The locale to use when rendering the input (e.g. month names, names of
     * days of the week, etc.). See the [Intl API](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl).
     */
    locale: PropTypes.oneOf(Object.keys(dateLocales)),
    /**
     * Set the width of a single month.
     */
    monthWidth: PropTypes.number,
    /**
     * The number of calendar months to display at a time.
     */
    monthsInView: PropTypes.number,
    /**
     * The name of the component's form control. If the component is placed in a
     * form, this will be the name associated with the component's data when the
     * form is submitted.
     */
    name: PropTypes.string,
    /**
     * A localized label for the next month button.
     */
    nextMonthLabel: PropTypes.string,
    /**
     * Define today's date (in the format YYYY-MM-DD). This date will be
     * marked in the calendar with a special indicator.
     */
    now: PropTypes.string,
    /**
     * A localized label for the previous month button.
     */
    previousMonthLabel: PropTypes.string,
    /**
     * A function that can be used to customize the rendering of a single day. The
     * function is passed an object which contains the following props.
     * ```
     * date:      `string` of date in the format YYYY-MM-DD
     * label:     `string` of the localized day of the month (e.g. "21")
     * current:   `boolean` set to `true` if the date is todays date
     * selected:  `boolean` set to `true` if date is selected
     * disabled:  `boolean` set to `true` if date is disabled
     * range:     `boolean` set to `true` if date is in the range of selected dates
     * ```
     */
    renderDay: PropTypes.func,
    /**
     * The value of the input in the format YYYY-MM-DD.
     *
     * Update this when `onChange` is called to ensure the component is interactive
     * (see the documentation for the `onChange` prop).
     */
    value: PropTypes.string,
    /**
     * Use this attribute to associate a text label with the calendar popup
     *
     * @added by meetex team member (camei@)
     */
    "aria-label": PropTypes.string,
  }

  render() {
    const props = this.props
    return (
      <MiniCalendar
        selectedDates={date => date === props.value}
        onClickDate={props.onChange}
        viewDate={props.viewDate}
        onChangeViewDate={props.onChangeViewDate}
        now={props.now}
        disabledDates={props.disabledDates}
        locale={props.locale}
        monthsInView={props.monthsInView}
        monthWidth={props.monthWidth}
        previousMonthLabel={props.previousMonthLabel}
        nextMonthLabel={props.nextMonthLabel}
        renderDay={props.renderDay}
        animateMonthChange={props.animateMonthChange}
        aria-label={props["aria-label"]}
      >
        {props.name ? (
          <input type="hidden" name={props.name} value={props.value} />
        ) : null}
      </MiniCalendar>
    )
  }
}

export default DateInputCalendar
