import React, { useRef, useState } from "react";

import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Popover from "@amzn/meridian/popover";
import Text from "@amzn/meridian/text";
import starTokens from "@amzn/meridian-tokens/base/icon/star"
import mailTokens from "@amzn/meridian-tokens/base/icon/mail";

import AttendeeTag from "../../people/components/attendee-tag";
import AttendeePopover from "../../people/components/attendee-popover";
import Favorites from "../../shared/favorites/components/favorites";
import AttendeeSearchBar from "../../people/components/attendee-search-bar";

import { ATTENDEE_PRIORITY, ATTENDEE_RESPONSE, ATTENDEE_TYPE } from "../../people/people-constants";
import { KEYCODE, SCREEN_SIZE } from "../../shared/shared-constants";
import { FAVORITE_TYPE } from "../../shared/favorites/favorites-constants";
import { createFavorite } from "../../shared/favorites/favorites-utils";
import { externalEmailToAttendee, isValidEmail } from "../../people/people-utils";
import { DISABLE_EXTERNAL_ATTENDEES } from "../../meetings-config";

const CreatePollInviteAttendees = (props) => {
    const emailSeparatorRegex = /[;,]/;

    const [externalEmail, setExternalEmail] = useState("");
    const [openAttendeePopover, setOpenAttendeePopover] = useState("");
    const [openFavoritePopover, setOpenFavoritePopover] = useState(false);
    const attendeePopoverRefs = useRef([]);
    const favoritesPopoverRef = useRef();
    const disableExternalAttendees = DISABLE_EXTERNAL_ATTENDEES;

    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const identity = props.identity;
    const attendees = props.attendees;
    const externalAttendees = props.externalAttendees;
    const attendeesAliasList = attendees.map((attendee) => attendee.alias);
    const favorites = props.favorites;
    const peopleSuggestions = props.peopleSuggestions;
    const groupSuggestions = props.groupSuggestions;
    const onGetPeopleSuggestions = props.onGetPeopleSuggestions;
    const onGetGroupSuggestions = props.onGetGroupSuggestions;
    const onClearGroupSuggestions = props.onClearGroupSuggestions;
    const onClearPeopleSuggestions = props.onClearPeopleSuggestions;
    const onGetRASDataForPerson = props.onGetRASDataForPerson;
    const onGetRASDataForGroup = props.onGetRASDataForGroup;
    const onAddFavorite = props.onAddFavorite;
    const onRemoveFavorite = props.onRemoveFavorite;
    const onRemoveAttendee = props.onRemoveAttendee;
    const onUpdateAttendee = props.onUpdateAttendee;
    const onUpdateExternalAttendee = props.onUpdateExternalAttendee;
    const onRemoveExternalAttendee = props.onRemoveExternalAttendee;
    const onAddExternalAttendee = props.onAddExternalAttendee;

    const onCloseAttendeePopover = () => setOpenAttendeePopover("");

    const isFavorited = (attendee) => {
        if (favorites && favorites.length > 0) {
            switch (attendee.type) {
                case ATTENDEE_TYPE.PERSON:
                case ATTENDEE_TYPE.GROUP:
                    return !!favorites.find((favorite) => favorite.value === attendee.alias);
                case ATTENDEE_TYPE.EXTERNAL_EMAIL:
                    return !!favorites.find((favorite) => favorite.value === attendee.email);
                default:
                    return false;
            }
        }
        return false;
    };

    const onFavoriteClick = (attendee) => {
        if (favorites === undefined) {
            return;
        }

        let favorite;
        switch (attendee.type) {
            case ATTENDEE_TYPE.PERSON:
                favorite = createFavorite(FAVORITE_TYPE.USER, attendee);
                break;
            case ATTENDEE_TYPE.GROUP:
                favorite = createFavorite(FAVORITE_TYPE.GROUP, attendee);
                break;
            case ATTENDEE_TYPE.EXTERNAL_EMAIL:
                favorite = createFavorite(FAVORITE_TYPE.EXTERNAL_EMAIL, {email: attendee.email});
                break;
            default:
                return;
        }

        if (isFavorited(attendee)) {
            onRemoveFavorite(favorite, identity.username);
        } else {
            onAddFavorite(favorite, identity.username);
        }
    };

    const onAddAttendee = (newAttendee) => {
        newAttendee.priority = ATTENDEE_PRIORITY.REQUIRED;
        props.onAddAttendee(identity.username, newAttendee);
    };

    const viewAttendeeDetails = (attendee) => {
        setOpenAttendeePopover(attendee.identifier);

        // If rasData is not present, call APIs once to retrieve card data
        if (attendee.rasData === undefined) {
            if (attendee.type === ATTENDEE_TYPE.PERSON) {
                onGetRASDataForPerson(attendee.alias, false);
            } else if (attendee.type === ATTENDEE_TYPE.GROUP) {
                onGetRASDataForGroup(attendee.alias);
            }
        }
    };

    const createAttendeeTag = (attendee, allowViewAttendeeDetails, isExternal = false) => {
        return (
            <AttendeeTag
                allowChangeAttendeePriority={true}
                attendee={attendee}
                attendeePopoverRefs={attendeePopoverRefs}
                allowViewAttendeeDetails={allowViewAttendeeDetails}
                isFavorited={isFavorited(attendee)}
                onUpdateAttendee={isExternal ? onUpdateExternalAttendee : onUpdateAttendee}
                removeAttendee={isExternal ? onRemoveExternalAttendee : onRemoveAttendee}
                viewAttendeeDetails={viewAttendeeDetails}
                key={attendee.name || attendee.email}
            />
        );
    };

    // Add a valid email as an attendee and return true, else return false
    // Currently we are not allowing chime to be added through the search bar
    const addExternalIfValidEmail = (email) => {
        // Emails pasted from Outlook will have the email between <>
        let parsedEmail = (email || "").split("<").pop().split(">")[0].trim();

        // Ignore chime & amazon emails for now
        if (isValidEmail(parsedEmail) && !parsedEmail.endsWith("@chime.aws")) {
            const emailTail = parsedEmail.split("@")[1];
            // not allow amazon email to be added as external
            if (!emailTail.includes("amazon")) {
                onAddExternalAttendee(externalEmailToAttendee(parsedEmail, ATTENDEE_RESPONSE.NO_RESPONSE, ATTENDEE_PRIORITY.REQUIRED));
            }
        }
    };

    const onAddExternalEmail = () => {
        let emails = externalEmail.split(emailSeparatorRegex);

        // We received a list of emails
        if (emails.length > 1) {
            // Try to add each email
            emails.forEach((email) => addExternalIfValidEmail(email));
        // We received a single query
        } else {
            // Try to add the query if it is a valid email
            addExternalIfValidEmail(externalEmail);
        }

        // Reset Search Box
        setExternalEmail("");
    };

    const onEnterExternalEmail = (event) => {
        const keyCode = event.keyCode || event.which;
        switch (keyCode) {
            case KEYCODE.ENTER:
                onAddExternalEmail();
                closeExternalEmailPopover();
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    const showAttendeeTags = (attendees, isExternal = false) => {
        return (
            attendees.map((attendee) => (
                <Row key={attendee.name || attendee.email}>
                    <div style={{
                        height: attendee.isOrganizer ? "27px" : "",
                        paddingTop: "4px",
                    }}>
                        {createAttendeeTag(attendee, true, isExternal)}
                    </div>
                    <AttendeePopover
                        attendee={attendee}
                        position="bottom"
                        attendeeList={attendeesAliasList}
                        anchorNode={attendeePopoverRefs.current[attendee.identifier]}
                        open={openAttendeePopover === attendee.identifier}
                        onClose={onCloseAttendeePopover}
                        onAddAttendee={onAddAttendee}
                        onRemoveAttendeeByAlias={props.onRemoveAttendeeByAlias}
                        onGetRASDataForPerson={onGetRASDataForPerson}
                        isFavorited={isFavorited}
                        onFavoriteClick={onFavoriteClick}
                        disableAddFromPopover={true}
                    />
                </Row>
            ))
        );
    };

    const closeExternalEmailPopover = () => {
        props.setOpenExternalEmailPopover(false);
    };

    const openExternalEmailPopover = () => {
        props.setOpenExternalEmailPopover(true);
    };

    const onChangeExternalEmail = (email) => {
        if (!props.openExternalEmailPopover) {
            openExternalEmailPopover();
        }

        setExternalEmail(email);
    };

    const showFavoritesPopover = () => (
        <Popover
            anchorNode={favoritesPopoverRef.current}
            open={openFavoritePopover}
            onClose={() => setOpenFavoritePopover(false)}
            position="bottom"
            alignment="center"
        >
            <Favorites
                addAttendeeFromMeetingPolls={true}
                hideHeaderAndActions={true}
                identity={identity}
                attendees={attendees}
                favorites={favorites}
                hideLocations={true}
                onAddAttendee={onAddAttendee}
                onAddExternalAttendee={onAddExternalAttendee}
                onGetRASDataForPerson={onGetRASDataForPerson}
                onRemoveFavorite={onRemoveFavorite}
            />
        </Popover>
    );

    return (
        <Column
            spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.MD ? "large" : "medium none"}
            width="100%"
            alignmentVertical="stretch"
            spacing="medium"
        >
            <Row className="create-poll-invite-attendee-search-bar" alignmentVertical="end">
                <Column width="100%" maxWidth="440px">
                    <Text tag="label" id="create-poll-invite-internal-attendee-input-label" type="b300" htmlFor="amw-attendee-search-id">
                        Add Amazonians
                    </Text>
                    <AttendeeSearchBar
                        disableExternalAttendees={disableExternalAttendees}
                        addExternalEmail={true}
                        width="100%"
                        placeholderText="Search for name, alias..."
                        onAddAttendee={onAddAttendee}
                        onGetPeopleSuggestions={onGetPeopleSuggestions}
                        onClearPeopleSuggestions={onClearPeopleSuggestions}
                        onGetGroupSuggestions={onGetGroupSuggestions}
                        onClearGroupSuggestions={onClearGroupSuggestions}
                        peopleSuggestions={peopleSuggestions}
                        groupSuggestions={groupSuggestions}
                    />
                </Column>
                <Column>
                    <Button ref={favoritesPopoverRef} type="tertiary" onClick={() => setOpenFavoritePopover(true)}>
                        {screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.MD ?
                            <Row>
                                <Icon tokens={starTokens} />
                                &nbsp;Favorites
                            </Row>
                            :
                            <Icon tokens={starTokens} />
                        }
                    </Button>
                </Column>
                {showFavoritesPopover()}
            </Row>
            {attendees?.length > 0 ?
                <Row spacingInset="xsmall none" spacing="xsmall" width="100%" maxWidth="580px" wrap="down">
                    {showAttendeeTags(attendees)}
                </Row>
                :
                <div style={{"height": "15px"}} />
            }
            <Row className="create-poll-invite-attendee-search-bar">
                <Column ref={props.externalEmailPopoverRef} width="100%" maxWidth="440px">
                    <Text tag="label" id="create-poll-invite-external-attendee-input-label" type="b300" htmlFor="external-attendees-input">
                        Add external attendees
                    </Text>
                    <Input
                        id="external-attendees-input"
                        disabled={false}
                        placeholder="mail@example.com"
                        value={externalEmail}
                        onChange={onChangeExternalEmail}
                        prefixIconTokens={mailTokens}
                        onKeyDown={onEnterExternalEmail}
                        onFocus={openExternalEmailPopover}
                        onBlur={closeExternalEmailPopover}
                        aria-required="true"
                    />
                </Column>
            </Row>
            {
                externalAttendees?.length > 0 &&
                    <Row spacingInset="xsmall none" spacing="xsmall" width="100%" wrap="down">
                        {showAttendeeTags(externalAttendees, true)}
                    </Row>
            }
        </Column>

    );
};

export default CreatePollInviteAttendees;