import React from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";

import sighEmojiSvg from "../../../assets/icons/shared/sigh_emoji.svg";

// Get tips to increase the number of meeting suggestions
const getTips = (isSimpleMeeting) => {
    let tips = ["Extend the time window to include more hours or even days."];

    if (isSimpleMeeting) {
        tips.push("Set a later start date.");
        tips.push("Decrease the meeting duration.");
    } else {
        tips.push("Set a later start date and manually book the first few occurrences.");
        tips.push("Remove the essential tags from attendees.");
    }

    return tips;
};

const SuggestionsTips = (props) => {
    const isSimpleMeeting = props.isSimpleMeeting;
    const alignmentHorizontal = props.alignmentHorizontal;
    const maxWidth = props.maxWidth;
    const spacing = props.spacing;
    const spacingInset = props.spacingInset;
    const tips = getTips(isSimpleMeeting);

    return (
        <Row
            alignmentHorizontal={alignmentHorizontal}
            alignmentVertical="top"
            backgroundColor="white"
            maxWidth={maxWidth}
            spacing={spacing}
            spacingInset={spacingInset}
            type="outline"
            width="100%"
            wrap="down"
        >
            <Column>
                <img src={sighEmojiSvg} style={{"height": "105px", "width": "97px"}} alt="Sigh calendar emoji" />
            </Column>
            <Column spacing="none">
                <div style={{color: "#2E4971"}}>
                    <h3 style={{display: "inline"}}>Not seeing what you want?</h3>
                    <Column spacing="none" spacingInset="small none none none">
                        <span>Try these adjustments for more suggestions:</span><br />
                        {tips.map((tip, index) => (
                            <div key={index}><span>{(index + 1) + ". " + tip}</span><br /></div>
                        ))}
                    </Column>
                </div>
            </Column>
        </Row>
    );
};

export default SuggestionsTips;