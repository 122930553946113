import * as Actions from "../actions/action-types";

const initialState = {
    schedules: {},
    isGettingSchedules: false,
};

function timeGridReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.GET_SCHEDULE:
            return {
                ...state,
                isGettingSchedules: true,
            };
        case Actions.SET_SCHEDULE:
            return {
                ...state,
                isGettingSchedules: false,
                schedules: {
                    ...state.schedules,
                    [action.startTime]: {
                        ...action.schedules
                    }
                },
            };
        default:
            return state;
    }
}

export default timeGridReducer;