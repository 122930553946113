import React from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group"
import TimeSelect, { timeRange } from "../../meridian-custom-components/src/components/time-select";

import FormSection from "./form-section";
import TimezonePicker from "../../components/timezone-search-bar";
import RadioGroup from "./radio-group";
import FormInputList from "./form-input-list";
import { DATE_FORMAT, TIME_FORMAT, WORKING_HOURS } from "../settings-constants";

import { getDefaultTimeFormat } from "../settings-utils";
import "../settings.css";

const TimePreferences = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const timeFormat = props.timeFormat;
    const dateFormat = props.dateFormat;
    const timezones = props.timezones;
    const workingHourDays = props.workingHourDays;
    const startTime = props.workingHourStartTime;
    const endTime = props.workingHourEndTime;

    return (
        <FormSection 
            children={
                <FormInputList 
                    screenSizeBreakpoint={screenSizeBreakpoint}
                    inputItems={[
                        {
                            header: "Meeting Hours",
                            inputComponent: 
                                <Column>
                                    <Text id="settings-working-hours-description" type="b200" color="secondary">
                                        Set your preferred time to schedule meetings
                                    </Text>
                                    <Text type="b200" color="secondary">
                                        We'll provide meeting suggestions based on your meeting hours
                                    </Text>
                                    <Row spacing="none">
                                        <div id="settings-time-preference-working-hours-button-group">
                                            <ButtonGroup type="outline" value={workingHourDays} onChange={props.onChangeWorkingHourDays}>
                                                <ButtonOption value={WORKING_HOURS.DAYS[0]}>S</ButtonOption>
                                                <ButtonOption value={WORKING_HOURS.DAYS[1]}>M</ButtonOption>
                                                <ButtonOption value={WORKING_HOURS.DAYS[2]}>T</ButtonOption>
                                                <ButtonOption value={WORKING_HOURS.DAYS[3]}>W</ButtonOption>
                                                <ButtonOption value={WORKING_HOURS.DAYS[4]}>T</ButtonOption>
                                                <ButtonOption value={WORKING_HOURS.DAYS[5]}>F</ButtonOption>
                                                <ButtonOption value={WORKING_HOURS.DAYS[6]}>S</ButtonOption>
                                            </ButtonGroup>
                                        </div>
                                        <TimeSelect
                                            value={startTime}
                                            onChange={props.onChangeWorkingHourStartTime}
                                            error={startTime >= endTime}
                                            options={timeRange({
                                                start: "00:00:00",
                                                end: "23:30:00",
                                                step: 60 * 30, // 30 minutes,
                                            })}
                                            format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                                            width={150}
                                            placeholder="Select start time"
                                            size="medium"
                                            aria-labelledby={"starttime"}
                                        />
                                        <TimeSelect
                                            value={endTime}
                                            onChange={props.onChangeWorkingHourEndTime}
                                            error={startTime >= endTime}
                                            options={timeRange({
                                                start: "00:30:00",
                                                end: "23:59:59",
                                                step: 60 * 30, // 30 minutes
                                            })}
                                            format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                                            width={150}
                                            placeholder="Select end time"
                                            size="medium"
                                            aria-labelledby={"endtime"}
                                        />
                                    </Row>
                                </Column>
                        },
                        {
                            inputComponent: <hr className="email-and-chime-divider" />
                        },
                        {
                            header: "Timezone",
                            inputComponent: 
                                <TimezonePicker
                                    screenSizeBreakpoint={screenSizeBreakpoint}
                                    width="150px"
                                    mobileWidth="330px"
                                    size="small"
                                    timezones={timezones}
                                    value={props.primaryTimezone}
                                    onChange={props.onChangePrimaryTimezone} />
                        },
                        {
                            header: "Time format",
                            inputComponent: 
                                <RadioGroup
                                    radios={[
                                        {
                                            value: TIME_FORMAT.TWELVE_HOUR,
                                        },
                                        {
                                            value: TIME_FORMAT.TWENTY_FOUR_HOUR,
                                        },
                                    ]}   
                                    checkedValue={timeFormat} 
                                    setCheckedValue={props.onChangeTimeFormat} 
                                />
                        },
                        {
                            header: "Date format",
                            inputComponent: 
                                <RadioGroup
                                    radios={[
                                        {
                                            value: DATE_FORMAT.MONTH_DAY_YEAR,
                                            descriptions: ["Month/Day/Year", "The US date format"]
                                        },
                                        {
                                            value: DATE_FORMAT.DAY_MONTH_YEAR,
                                            descriptions: ["Day/Month/Year"]
                                        },
                                        {
                                            value: DATE_FORMAT.YEAR_MONTH_DAY,
                                            descriptions: ["Year-Month-Day", "ISO 8601"]
                                        },
                                    ]}
                                    checkedValue={dateFormat} 
                                    setCheckedValue={props.onChangeDateFormat} 
                                />
                        }
                    ]}
                />
            }
        />
    );
};

export default TimePreferences;