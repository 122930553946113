const metricsNamespace = "metrics";

export const POST_RATING_METRIC = `${metricsNamespace}.post_rating_metric`;
export const POST_RAS_API_CALL_METRIC = `${metricsNamespace}.post_ras_api_call_metric`;
export const POST_AMS_API_CALL_METRIC = `${metricsNamespace}.post_ams_api_call_metric`;
export const POST_LIVESTREAM_API_CALL_METRIC = `${metricsNamespace}.post_livestream_api_call_metric`;
export const POST_MCIS_API_CALL_METRIC = `${metricsNamespace}.post_mcis_api_call_metric`;
export const POST_CREATE_MEETING_ROOMS_METRICS = `${metricsNamespace}.post_create_meeting_rooms_metrics`;
export const POST_COPY_TO_NEW_CREATE_MEETING_METRICS = `${metricsNamespace}.post_copy_to_new_create_meeting_metrics`;
export const POST_UNKNOWN_EDGE_LOCATION_METRIC = `${metricsNamespace}.post_unknown_edge_location_metric`;
export const POST_REQUESTER_EMAIL_RETRY_METRIC = `${metricsNamespace}.post_requester_email_retry_metric`;
export const POST_QUERY_PARAMS_LINK_METRIC = `${metricsNamespace}.post_query_params_link_metric`;
export const POST_AVAILABILITY_SHARING_COPY_TO_CLIPBOARD_METRIC = `${metricsNamespace}.post_availability_sharing_copy_to_clipboard_metric`;
export const POST_CREATE_LIVESTREAM_EVENT_METRIC = `${metricsNamespace}.post_create_livestream_event_metric`;
export const POST_NO_VALID_CHIME_EMAIL_FOUND_METRIC = `${metricsNamespace}.post_no_valid_chime_email_metric`;
export const POST_SAVE_USER_PREFERENCES_METRIC = `${metricsNamespace}.post_save_user_preferences_metric`;
export const POST_CREATE_MEETING_POLL_EVENT_METRIC = `${metricsNamespace}.post_create_meeting_poll_event_metric`;
export const POST_UPDATE_MEETING_POLL_EVENT_METRIC = `${metricsNamespace}.post_update_meeting_poll_event_metric`;
export const POST_ROOM_BOOKING_METRIC = `${metricsNamespace}.post_room_booking_metric`;
