import React from "react";

import Row from "@amzn/meridian/row";

import "../settings.css";

const FormSection = (props) => {
    return (
        <Row id="settings-form-section-row">
            <div style={{
                padding: "60px 0 0 60px",
                width: "90%"
            }}>
                {props.children}
            </div>
        </Row>
    );
};

export default FormSection;