import React, { useState } from "react";

import { externalEmailToAttendee, groupToAttendee } from "../../../people/people-utils";
import { fillFavoriteDefaults, getFavoriteLabel, isValidFavorite, renderFavoriteTypeIcon, sortFavorites } from "../favorites-utils";
import { FAVORITE_TYPE, FAVORITES_DEFAULT_WIDTH } from "../favorites-constants";
import { buildingsEqual, locationAndFavoriteEqual, renderLocationFeatureIcon } from "../../locations/locations-utils";
import { LOCATION_FEATURE, LOCATION_FLOOR_ANY } from "../../locations/locations-constants";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import trashTokens from "@amzn/meridian-tokens/base/icon/trash";
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil";
import saveSvg from "../../../../assets/icons/general/save.svg";

const Favorites = (props) => {
    // Inputs
    const identity = props.identity || {};
    const addAttendeeFromMeetingPolls = props.addAttendeeFromMeetingPolls;
    const attendeeList = props.attendees.map((attendee) => attendee.alias);
    const rooms = (props.rooms || []).map((room) => room.email);
    const locations = props.locations || [];
    const favorites = props.favorites;
    const hideLocations = props.hideLocations;
    const hideHeaderAndActions = props.hideHeaderAndActions;
    const width = props.width || FAVORITES_DEFAULT_WIDTH;

    const coworkers = identity.coworkers && identity.coworkers.filter((coworkers) => !!coworkers.name);
    const directs = identity.directs && identity.directs.filter((direct) => !!direct.name);

    const onAddAttendee = props.onAddAttendee;
    const onAddExternalAttendee = props.onAddExternalAttendee;
    const onGetRASDataForPerson = props.onGetRASDataForPerson;
    const onAddRoomByEmail = props.onAddRoomByEmail || (() => {});
    const onAddLocation = props.onAddLocation || (() => {});

    const [editing, setEditing] = useState(false);

    const allAttendeesAdded = (attendees) => {
        return attendees && !attendees.some((attendee) => !attendeeList.includes(attendee.username));
    };

    const favoriteAdded = (favorite) => {
        switch (favorite.type) {
            case FAVORITE_TYPE.USER:
            case FAVORITE_TYPE.GROUP:
            case FAVORITE_TYPE.EXTERNAL_EMAIL:
                return attendeeList.includes(favorite.value);
            case FAVORITE_TYPE.ROOM:
                return rooms.includes(favorite.value);
            case FAVORITE_TYPE.LOCATION:
                return locations.some((location) => locationAndFavoriteEqual(location, favorite));
            default:
                // disable favorite if type is undefined
                return true;
        }
    };

    const addUser = (userFavorite) => {
        if (!attendeeList.includes(userFavorite.value)) {
            if (addAttendeeFromMeetingPolls) {
                onAddAttendee({alias: userFavorite.value, name: userFavorite.name});
            } else {
                onGetRASDataForPerson(userFavorite.value, true, userFavorite.name);
            }
        }
    };

    const onAddUsers = (users) => {
        users.forEach((user) => addUser({
            value: user.username,
            name: user.name
        }));
    };

    const onAddFavoriteToMeeting = (favorite) => {
        switch (favorite.type) {
            case FAVORITE_TYPE.USER:
                addUser(favorite);
                return;
            case FAVORITE_TYPE.GROUP:
                if (!attendeeList.includes(favorite.value)) {
                    onAddAttendee(groupToAttendee(favorite.value));
                }
                return;
            case FAVORITE_TYPE.EXTERNAL_EMAIL:
                if (!attendeeList.includes(favorite.value)) {
                    if (onAddExternalAttendee !== undefined) {
                        onAddExternalAttendee(externalEmailToAttendee(favorite.value));
                    } else {
                        onAddAttendee(externalEmailToAttendee(favorite.value));
                    }
                }
                return;
            case FAVORITE_TYPE.ROOM:
                if (!rooms.includes(favorite.value)) {
                    onAddRoomByEmail({email: favorite.value});
                }
                return;
            case FAVORITE_TYPE.LOCATION:
                if (!locations.find((location) => locationAndFavoriteEqual(location, favorite))) {
                    favorite = fillFavoriteDefaults(favorite);
                    onAddLocation({
                        minCapacity: favorite.minCapacity,
                        buildingCode: favorite.value,
                        floor: favorite.floor,
                        resourceList: favorite.resourceList,
                        restrictedAllowed: favorite.restrictedAllowed,
                        hiddenAllowed: favorite.hiddenAllowed,
                        thisBuildingOnly: favorite.thisBuildingOnly,
                    });
                }
                return;
            default:
                return;
        }
    };

    const onRemoveFavorite = (favorite) => {
        if (favorites.length === 1) {
            setEditing(false);
        }

        props.onRemoveFavorite(favorite, identity.username);
    };

    const filterFavorites = (favorites) => {
        // remove invalid favorites
        let filteredFavorites = favorites.filter((favorite) => isValidFavorite(favorite));

        if (hideLocations) {
            filteredFavorites = filteredFavorites.filter((favorite) => favorite.type !== FAVORITE_TYPE.ROOM && favorite.type !== FAVORITE_TYPE.LOCATION);
        }

        // Filling out the default values for each favorite if they are undefined
        // resourceList can be undefined for favorites before/on 7/29/21 https://mcm.amazon.com/cms/MCM-45580244
        favorites.forEach((favorite) => {
            fillFavoriteDefaults(favorite);
        });

        return filteredFavorites;
    };

    const renderFavoriteLink = (favorite) => {
        if (favorite.type === FAVORITE_TYPE.LOCATION) {
            const floor = favorite.floor === LOCATION_FLOOR_ANY ? "Any floor" : `Floor ${favorite.floor}`;
            const seats = `${favorite.minCapacity}+ seats`;
            const label = `${floor}, ${seats}`;

            let features = [];
            favorite.resourceList?.includes(LOCATION_FEATURE.CAMERA) && features.push("Camera");
            favorite.resourceList?.includes(LOCATION_FEATURE.DISPLAY) && features.push("Display");
            favorite.restrictedAllowed && features.push("Restricted");
            favorite.hiddenAllowed && features.push("Hidden");

            const ariaLabel = `${favorite.thisBuildingOnly === false ? "in or nearby " : ""}${favorite.name}${features.length !== 0 ? ", " + features.join(", ") : ""}`;

            return (
                <div style={{"paddingLeft": "28px"}} aria-label={ariaLabel}>
                    <Row spacing="none" alignmentVertical="stretch" wrap="down">
                        <Text type="b200">
                            <Link
                                type="secondary"
                                disabled={favoriteAdded(favorite)}
                                onClick={() => onAddFavoriteToMeeting(favorite)}
                            >
                                {label}&nbsp;
                            </Link>
                        </Text>
                        <div style={{"paddingTop": "2px"}}>
                            <Row spacing="xsmall" alignmentVertical="stretch">
                                {favorite.resourceList && favorite.resourceList.includes(LOCATION_FEATURE.CAMERA) && renderLocationFeatureIcon(LOCATION_FEATURE.CAMERA, 16)}
                                {favorite.resourceList && favorite.resourceList.includes(LOCATION_FEATURE.DISPLAY) && renderLocationFeatureIcon(LOCATION_FEATURE.DISPLAY, 16)}
                                {favorite.restrictedAllowed && renderLocationFeatureIcon(LOCATION_FEATURE.RESTRICTED, 16)}
                                {favorite.hiddenAllowed && renderLocationFeatureIcon(LOCATION_FEATURE.HAND_MANAGED, 16)}
                            </Row>
                        </div>
                    </Row>
                </div>
            );
        } else {
            return (
                <Text type="b200">
                    <Link
                        type="secondary"
                        disabled={favoriteAdded(favorite)}
                        onClick={() => onAddFavoriteToMeeting(favorite)}
                    >
                        {renderFavoriteTypeIcon(favorite.type, favoriteAdded(favorite))}
                        &nbsp;{getFavoriteLabel(favorite)}
                    </Link>
                </Text>
            );
        }
    };

    const renderFavorite = (favorite) => {
        return (
            <Row
                alignmentHorizontal="justify"
                spacing="small"
                spacingInset="none"
                key={getFavoriteLabel(favorite)}
                widths={["fill", "fit"]}
            >
                {renderFavoriteLink(favorite)}
                {favoriteAdded(favorite) &&
                    <Text type="b200" color="secondary">Added</Text>
                }
                {editing &&
                    <Row height="16px">
                        <Button
                            type="link"
                            alignment="right"
                            size="small"
                            onClick={() => onRemoveFavorite(favorite)}
                            label={`Remove favorite ${getFavoriteLabel(favorite)}`}
                        >
                            <Icon tokens={trashTokens} />
                        </Button>
                    </Row>
                }
            </Row>
        );
    };

    const renderFavorites = (favorites) => {
        const sortedFavorites = sortFavorites(filterFavorites(favorites));

        let uniqueLocations = [];

        sortedFavorites.forEach((favorite) => {
            if (favorite.type === FAVORITE_TYPE.LOCATION) {
                if (!uniqueLocations.find((location) => buildingsEqual(location, favorite))) {
                    // store the unique buildings to render as labels for groups of favorites
                    uniqueLocations.push({
                        "value": favorite.value,
                        "name": favorite.name,
                        "thisBuildingOnly": favorite.thisBuildingOnly,
                    });
                }
            }
        });

        return (
            <Column spacing="small">
                {sortedFavorites.map((favorite) => {
                    let uniqueLocationIndex = favorite.type === FAVORITE_TYPE.LOCATION ?
                                              uniqueLocations.findIndex((location) => buildingsEqual(location, favorite)) : -1;

                    if (uniqueLocationIndex !== -1) {
                        uniqueLocations.splice(uniqueLocationIndex, 1);

                        let label = favorite.name + (favorite.thisBuildingOnly ? "" : " and nearby");

                        return (
                            <Column spacing="xsmall">
                                <Row spacing="none">
                                    {renderFavoriteTypeIcon(favorite.type, false, 10)}
                                    <div style={{"paddingLeft": "4px"}}>
                                        <Text type="b200">{label}</Text>
                                    </div>
                                </Row>
                                {renderFavorite(favorite)}
                            </Column>
                        );
                    } else {
                        return (
                            <Column spacing="none">
                                {renderFavorite(favorite)}
                            </Column>
                        );
                    }
                })}
            </Column>
        );
    };

    return (
        <Column spacingInset="medium" spacing="small" width={width}>
            {!hideHeaderAndActions &&
                <Row widths={["fill", "fit"]} spacing="small" height="25px">
                    <Text type="h100" alignment="left">Favorites</Text>
                    <Button
                        type="link"
                        alignment="right"
                        size="small"
                        disabled={favorites === undefined || favorites.length === 0}
                        onClick={() => setEditing(!editing)}
                        label={editing ? "Save favorites" : "Edit favorites"}
                    >
                        {editing ?
                            <img src={saveSvg} alt="save" aria-hidden="true" />
                            :
                            <Icon tokens={pencilTokens} />
                        }
                    </Button>
                </Row>
            }
            {!identity.username &&
                <Row alignmentHorizontal="center">
                    <Loader />
                </Row>
            }
            {identity.manager &&
                <Row alignmentHorizontal="justify" spacing="small" spacingInset="none">
                    <Text type="b200">
                        <Link
                            ref={props.favoritesRef}
                            type="secondary"
                            alignment="left"
                            disabled={attendeeList.includes(identity.manager.username)}
                            onClick={() => onGetRASDataForPerson(identity.manager.username, true, identity.manager.name)}
                        >
                            {renderFavoriteTypeIcon(FAVORITE_TYPE.USER, attendeeList.includes(identity.manager.username))}
                            &nbsp;{identity.manager.name}
                        </Link>
                    </Text>
                    {attendeeList.includes(identity.manager.username) &&
                        <Text type="b200" color="secondary">Added</Text>
                    }
                </Row>
            }
            {directs && directs.length > 0 &&
                <Row alignmentHorizontal="justify" spacing="small" spacingInset="none">
                    <Text type="b200">
                        <Link
                            type="secondary"
                            alignment="left"
                            disabled={allAttendeesAdded(directs)}
                            onClick={() => onAddUsers(directs)}
                        >
                            {renderFavoriteTypeIcon(FAVORITE_TYPE.GROUP, allAttendeesAdded(directs))}
                            &nbsp;My reports ({directs.length})
                        </Link>
                    </Text>
                    {allAttendeesAdded(directs) &&
                        <Text type="b200" color="secondary">Added</Text>
                    }
                </Row>
            }
            {coworkers && coworkers.length > 0 &&
                <Row alignmentHorizontal="justify" spacing="small" spacingInset="none">
                    <Text type="b200">
                        <Link
                            type="secondary"
                            alignment="left"
                            disabled={allAttendeesAdded(coworkers)}
                            onClick={() => onAddUsers(coworkers)}
                        >
                            {renderFavoriteTypeIcon(FAVORITE_TYPE.GROUP, allAttendeesAdded(coworkers))}
                            &nbsp;My coworkers ({coworkers.length})
                        </Link>
                    </Text>
                    {allAttendeesAdded(coworkers) &&
                        <Text type="b200" color="secondary">Added</Text>
                    }
                </Row>
            }
            <Divider />
            {favorites ?
                favorites.length > 0 ?
                    renderFavorites(favorites)
                    :
                    <Row alignmentHorizontal="center">
                        <Text type="b200">No favorites</Text>
                    </Row>
                :
                <Row alignmentHorizontal="center">
                    <Loader />
                </Row>
            }
        </Column>
    );
};

export default Favorites;