import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import keycode from "keycode"
import { css } from "emotion"
import uniqueId from "lodash/uniqueId"
import findIndex from "lodash/findIndex"
import noop from "lodash/noop"
import optionListItemTokens from "@amzn/meridian-tokens/component/option-list-item"
import { Style } from "../theme"
import { getTokenHelper } from "../../_utils/token"
import { memoize } from "../../_utils/functional"
import textStyles from "../../_styles/text"

const styles = memoize(t =>
  css(textStyles(t), {
    outline: "none",
    // Item styles
    "& [role=option]": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      boxSizing: "border-box",
      padding: `${t("spacingInsetVertical")}px ${t(
        "spacingInsetHorizontal"
      )}px`,
      transition: t.transitionCss("motion", ["color", "background-color"]),
      // Foreground and background color
      backgroundColor: t("backgroundColorDefault"),
      color: t("foregroundColorDefault"),
      "&[aria-selected=true]": {
        backgroundColor: t("backgroundColorSelected"),
        color: t("foregroundColorSelected"),
      },
      "&[aria-current=true]": {
        backgroundColor: t("backgroundColorPreselected"),
        color: t("foregroundColorPreselected"),
      },
      "&:active": {
        backgroundColor: t("backgroundColorPressed"),
        color: t("foregroundColorPressed"),
      },
      "&[disabled], &[aria-disabled]": {
        backgroundColor: t("backgroundColorDisabled"),
        color: t("foregroundColorDisabled"),
      },
    },
    // Children styles
    "& [role=option] > *:first-child": {
      flex: "auto",
    },
    // Selected indicator styles
    "&[aria-multiselectable=true] [role=option]": {
      "& > *:last-child": {
        flexShrink: 0,
        // HACK: IE11 does not support margin-inline-* or margin-block-* so we have
        // to use directional margin-left/right. If/when we drop IE11 we can simplify
        // these styles to be:
        // {
        //   marginInlineStart: t("spacingHorizontal"),
        // },
        marginLeft: t("spacingInsetHorizontal"),
        [`[dir="rtl"] &`]: {
          marginRight: t("spacingInsetHorizontal"),
          marginLeft: 0,
        },
        color: t("indicatorColorDefault"),
        transition: t.transitionCss("motion", "color"),
      },
      "&[aria-current=true] > *:last-child": {
        color: t("indicatorColorPreselected"),
      },
      "&[aria-selected=true] > *:last-child": {
        color: t("indicatorColorSelected"),
      },
      "&:active > *:last-child": {
        color: t("indicatorColorPressed"),
      },
      "&[disabled][aria-selected=true] > *:last-child, &[aria-disabled][aria-selected=true] > *:last-child": {
        color: t("indicatorColorDisabled"),
      },
    },
  })
)

/**
 * This component helps render a keyboard-friendly list of selectable options.
 */
class OptionList extends React.Component {
  static propTypes = {
    /**
     * A set of OptionListItem nodes.
     */
    children: PropTypes.node.isRequired,
    /**
     * If set to true then the option list will grab focus as soon as it's mounted.
     *
     * This uses HTML's native `autofocus` attribute. Use this sparingly as
     * there are [common accessibility pitfalls](https://www.brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/)
     * associated with this behavior.
     */
    autoFocus: PropTypes.bool,
    /**
     * The index of the default "preselected" option. The "preselected" option
     * is the option that is highlighted and will be selected if the user
     * presses enter or space on their keyboard.
     *
     * NOTE: This is an uncontrolled component by default. It tracks the
     * preselected index using internal state. If the value of this prop changes
     * the internal state will be updated to reflect the new value, but
     * subsequent user interaction with the component may change the internal
     * state. To use make this component behave like a controlled component, use
     * the "preselectedIndex" and "onChangePreselectedIndex" props below.
     */
    defaultPreselectedIndex: PropTypes.number,
    /**
     * If set to true if more than one option may be selected at once.
     */
    multiSelectable: PropTypes.bool,
    /**
     * Use this to fix the index of the "preselected" option. This will disable
     * the internal state used to track the preselected index.
     */
    preselectedIndex: PropTypes.number,
    /**
     * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
     * is passed as the first argument to the function.
     */
    onBlur: PropTypes.func,
    /**
     * This is called any time the OptionList wants to update the preselected
     * index
     */
    onChangePreselectedIndex: PropTypes.func,
    /**
     * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
     * is passed as the first argument to the function.
     */
    onFocus: PropTypes.func,
    /**
     * This is called when the component has focus and a keyboard key is
     * pressed down. The [keydown event](https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event)
     * is passed as the first argument to the function.
     *
     * If `event.preventDefault()` is called from this handler then the option
     * list's default keyboard behavior will be prevented.
     */
    onKeyDown: PropTypes.func,
    /**
     * Use this optional attribute to manually set the id of the popup element that is being created.
     * If one is not specified, one will be uniquely generated.
     *
     * Note: this id will also be used to set the id for the options themselves.
     * The option will have the following id: `${popupId}-option-${index}`
     */
    popupId: PropTypes.string,
  }

  static defaultProps = {
    onKeyDown: noop,
    onChangePreselectedIndex: noop,
  }

  state = {
    preselectedIndex:
      this.props.preselectedIndex !== undefined
        ? this.props.preselectedIndex
        : this.props.defaultPreselectedIndex !== undefined
        ? this.props.defaultPreselectedIndex
        : -1,
  }

  // Generate a unique id that can be used to reference this element in the DOM
  id = this.props.popupId || uniqueId("option-list-")

  // Track the DOM node for each option so we can scroll to it using
  // `Element.scrollIntoView`
  optionDomNodes = []

  // Track the list DOM node so we can manage focus (see `focusOnMount`)
  listDomNode = undefined

  updateListDomNode = node => (this.listDomNode = node)

  getSelectedIndex = options =>
    findIndex(options, option => option && option.props.selected)

  getPreselectedIndex = () =>
    this.props.preselectedIndex !== undefined
      ? this.props.preselectedIndex
      : this.state.preselectedIndex

  getNextPreselectedIndex = offset => {
    const options = React.Children.toArray(this.props.children)
    const preselectedIndex = this.getPreselectedIndex()
    const selectedIndex = this.getSelectedIndex(options)
    const enabledIndices = options.reduce((result, option, index) => {
      if (option && !option.props.disabled) {
        result.push(index)
      }
      return result
    }, [])
    const firstIndex = enabledIndices[0]
    const lastIndex = enabledIndices[enabledIndices.length - 1]
    // If we've got a preselected or a selected option then move to the prev or
    // next option in the list (depending on the value of offset).
    const currentIndex =
      preselectedIndex !== -1 ? preselectedIndex : selectedIndex
    if (currentIndex !== -1) {
      const nextIndex =
        enabledIndices[enabledIndices.indexOf(currentIndex) + offset]
      return nextIndex !== undefined
        ? nextIndex
        : offset < 0
        ? firstIndex
        : lastIndex
      // If we don't have a preselected or selected option and we were trying to
      // move up, preselect the option at the bottom of the list.
    } else if (offset < 0) {
      return lastIndex
      // If we don't have a preselected or selected option and we were trying to
      // move down, preselect the option at the top of the list.
    } else {
      return firstIndex
    }
  }

  onFocusOptionIndex = index => {
    if (index === undefined || index < 0) {
      return
    }
    const idToFocus = `${this.id}-option-${index}`
    const elementToFocus = document.getElementById(idToFocus)
    elementToFocus && elementToFocus.focus()
  }

  onChangePreselectedIndex = preselectedIndex => {
    if (this.props.preselectedIndex === undefined) {
      this.setState({ preselectedIndex })
    }
    this.props.onChangePreselectedIndex(preselectedIndex)
  }

  onKeyDownContainer = event => {
    // Call the user's key down handler first. If they prevent the default event
    // behavior, don't run any of our code.
    this.props.onKeyDown(event)
    if (!event.defaultPrevented) {
      const key = keycode(event)
      let keyUsed = false

      // Move the preselection up or down in the list when the up arrow is pressed
      if (key === "up" || key === "down") {
        const preselectedIndex = this.getNextPreselectedIndex(
          key === "up" ? -1 : 1
        )
        this.onChangePreselectedIndex(preselectedIndex)
        this.scrollOptionIntoView(preselectedIndex)
        keyUsed = true
      }

      // Simulate a click on the current preselected option when enter or space is
      // pressed
      if (key === "enter" || key === "space") {
        const preselectedIndex = this.getPreselectedIndex()
        if (preselectedIndex !== -1) {
          React.Children.toArray(this.props.children)[
            preselectedIndex
          ].props.onClick()
        }
        keyUsed = true
      }

      // Don't perform the normal action associated with the key (i.e. scroll for
      // arrows) if the key was used for an option list action
      if (keyUsed) {
        event.preventDefault()
      }
    }
  }

  optionRef = index => domNode =>
    /* eslint-disable-next-line react/no-find-dom-node */
    (this.optionDomNodes[index] = ReactDOM.findDOMNode(domNode))

  scrollOptionIntoView = index => {
    const optionDomNode = this.optionDomNodes[index]
    if (optionDomNode) {
      optionDomNode.scrollIntoView(optionDomNode)
    }
  }

  focus() {
    return this.listDomNode.focus()
  }

  componentDidMount() {
    // Scroll the preselected or selected option into view on mount
    const preselectedIndex = this.getPreselectedIndex()
    if (preselectedIndex !== -1) {
      this.scrollOptionIntoView(preselectedIndex)
    } else {
      const options = React.Children.toArray(this.props.children)
      this.scrollOptionIntoView(this.getSelectedIndex(options))
    }

    // Focus on mount
    if (this.props.autoFocus) {
      // NOTE: React guarantees that callback refs will be resolved before
      // componentDidMount is called, so this is safe.
      this.focus()
    }
  }

  componentDidUpdate(prevProps) {
    const { preselectedIndex, defaultPreselectedIndex } = this.props
    // If the preselected index was changed via props, update the internal state
    if (
      preselectedIndex !== undefined &&
      preselectedIndex !== prevProps.preselectedIndex
    ) {
      this.setState({ preselectedIndex })
    } else if (
      preselectedIndex === undefined &&
      defaultPreselectedIndex !== undefined &&
      defaultPreselectedIndex !== prevProps.defaultPreselectedIndex
    ) {
      this.setState({ preselectedIndex: defaultPreselectedIndex })
      // If the default was changed then scroll it into view. We don't do this
      // if the `preselectedIndex` prop is changed because that means the parent
      // component is fully managing state and will need to be responsible for
      // managing scroll position as well.
      this.scrollOptionIntoView(defaultPreselectedIndex)
    }
  }

  render() {
    const props = this.props
    const { preselectedIndex } = this.state
    return (
      <Style
        tokens={optionListItemTokens}
        map={getTokenHelper("optionListItem")}
      >
        {t => (
          <div
            ref={this.updateListDomNode}
            onKeyDown={this.onKeyDownContainer}
            tabIndex="0"
            role="listbox"
            aria-multiselectable={props.multiSelectable}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            className={styles(t)}
            id={this.id}
          >
            {React.Children.map(props.children, (option, index) =>
              option
                ? React.cloneElement(option, {
                    ref: this.optionRef(index),
                    id: `${this.id}-option-${index}`,
                    preselected: index === preselectedIndex,
                    showIndicator: props.multiSelectable,
                    onChangePreselected: preselected =>
                      this.onChangePreselectedIndex(preselected ? index : -1),
                  })
                : null
            )}
          </div>
        )}
      </Style>
    )
  }
}

export default OptionList
