import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column";
import HighlightSubstring from "@amzn/meridian/highlight-substring";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import SearchField, { SearchSuggestion, SearchSuggestionFooter, SearchSuggestionHeader } from "@amzn/meridian/search-field";
import Tag from "@amzn/meridian/tag";
import Text from "@amzn/meridian/text";
import Thumbnail from "@amzn/meridian/thumbnail";
import {getPermissionPeopleSuggestionsSelector} from "../../../sagas/selector";
import {clearPermissionPeopleSuggestions, getPermissionPeopleSuggestions} from "../actions";
import {NO_SUGGESTIONS_FOUND} from "../livestream-constants";

const PersonSearchField = (props) => {
    const error = props.error ?? false;
    const errorMessage = props.errorMessage ?? "";

    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const dispatch = useDispatch();
    const onClearPeopleSuggestions = () => dispatch(clearPermissionPeopleSuggestions());
    const onGetPeopleSuggestions = (query) => dispatch(getPermissionPeopleSuggestions(query));
    const peopleSuggestions = useSelector(getPermissionPeopleSuggestionsSelector);

    // suggestions data clean & prep
    useEffect(() => {
        if (peopleSuggestions instanceof Array && peopleSuggestions.length > 0) {
            setSuggestions(peopleSuggestions
                .filter(person => person?.basicInfo?.firstName && person?.basicInfo?.lastName && person?.basicInfo?.login)
                .map(person => {
                    return {
                        firstName: person.basicInfo.firstName,
                        lastName: person.basicInfo.lastName,
                        login: person.basicInfo.login.toLowerCase(),
                    }
                })
            );
        }
    }, [peopleSuggestions]);

    const onBlur = () => {
        if (props.value) {
            setQuery("");
            setSuggestions([]);
        }
        onClearPeopleSuggestions();
    };

    const onChange = (query) => {
        setSuggestions([]);
        setQuery(query);
        if (!props.value) {
            onClearPeopleSuggestions();
            if (query.trim().length > 2) {
                onGetPeopleSuggestions(query);
            }
        }
    };

    const onClear = () => {
        setSuggestions([]);
        setQuery(query);
        onClearPeopleSuggestions();
    };

    const onClick = (login) => {
        setSuggestions([]);
        setQuery("");
        props.updateValue(login);
        onClearPeopleSuggestions();
    };

    const searchSuggestion = (suggestion, index) => (
        <SearchSuggestion onClick={() => onClick(suggestion.login)} key={index+suggestion.login}>
            {({query = ""}) => (
                <Row>
                    <Thumbnail
                        size="small"
                        source={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?login=" + suggestion.login}
                    />
                    <HighlightSubstring match={query}>
                        {`${suggestion.firstName} ${suggestion.lastName} (@${suggestion.login})`}
                    </HighlightSubstring>
                </Row>
            )}
        </SearchSuggestion>
    );

    return (
        <React.Fragment>
            <SearchField
                id={props.id ?? "person-search-field"}
                name="select-accountName"
                onBlur={onBlur}
                onChange={onChange}
                onClear={onClear}
                onSubmit={() => {}} // feature disabled
                placeholder="Search account"
                value={query}
            >
                <SearchSuggestionHeader>Name suggestions</SearchSuggestionHeader>
                {!props.value && query.trim().length > 2 && suggestions.length > 0 && suggestions.map(searchSuggestion)}
                {!props.value && (query.trim().length > 2) && !peopleSuggestions &&
                    <Row alignmentHorizontal="center" spacingInset="400 none 400">
                        <Loader size="medium"/>
                    </Row>
                }
                {!props.value && (query.trim().length > 2) && peopleSuggestions && !peopleSuggestions.length &&
                    <Column alignmentHorizontal="center" spacing="none" spacingInset="small">
                        <Text alignment="center">{NO_SUGGESTIONS_FOUND}</Text>
                    </Column>
                }
                {props.value && <Column/>}
                <SearchSuggestionFooter>
                    <Row>
                        {!props.value && query.trim().length > 2 &&
                            <Text>{`${suggestions.length} Result${suggestions?.length === 1 ? "" : "s"}`}</Text>}
                        {!props.value && query.trim().length < 3 &&
                            <Alert size="small">Requires at least 3 letters.</Alert>}
                    </Row>
                </SearchSuggestionFooter>
            </SearchField>
            {error && (errorMessage.length > 0) &&
                <Row spacingInset="200 none none">
                    <Alert type="error" size="small">{errorMessage}</Alert>
                </Row>
            }
            {props.value &&
                <Row spacingInset="200 none none">
                    <Tag onClose={() => {props.updateValue("")}} type="theme">{props.value}</Tag>
                </Row>
            }
        </React.Fragment>
    );
};

export default PersonSearchField;
