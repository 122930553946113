import { put, call, takeEvery, takeLatest, delay, select } from "redux-saga/effects";
import * as Actions from "../actions/action-types";
import {
    addAttendee,
    updateAttendee,
    savePeopleSuggestions,
    saveGroupSuggestions,
    updatePersonAttendeeWithRasData,
    updateGroupAttendeeWithRasData,
    cacheAttendeeWorkingHours,
    addExternalAttendee,
} from "../actions";
import { peopleAPISearchAll, findGroups, getPerson, getGroup } from "../../api/apig/ras";
import { rasUserToAttendee, groupToAttendee, externalEmailToAttendee, groupToAttendeeWithRasData } from "../people-utils";
import { isErrorResponse } from "../../api/apig/apig-utils";
import { getErrorToast } from "../../shared/toasts/toast-utils";
import { showToast } from "../../shared/toasts/actions";
import { DEBOUNCE_DELAY } from "../people-constants";

import _ from "lodash";
import { setFavoritesPopoverAttendee } from "../../shared/settings/actions";
import { addFavorite } from "../../shared/favorites/actions";
import { FAVORITE_TYPE } from "../../shared/favorites/favorites-constants";
import { getUserPreferencesByUserAlias } from "../../api/apig/ams";
import { DEFAULT_WORKING_HOURS } from "../../shared/settings/settings-constants";
import { getAttendees, getAttendeesWorkingHoursCache } from "../../../sagas/selector";

export function* watchPeopleAPISearchAll() {
    yield takeLatest(Actions.GET_PEOPLE_SUGGESTIONS, peopleAPISearchAllApi);
}

export function* peopleAPISearchAllApi(action) {
    // Debounce, wait 500ms before sending a request
    yield delay(DEBOUNCE_DELAY);

    console.log("Calling peopleAPISearchAll in sagas for: " + action.query);
    const response = yield call(peopleAPISearchAll, action.query);
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast(`An error occurred when finding users for query "${action.query}".`);
        yield put(showToast(toast));
        return;
    }
    const people = (parsedResponse && parsedResponse.records) || [];

    yield put(savePeopleSuggestions(people));
}

export function* watchFindGroups() {
    yield takeLatest(Actions.GET_GROUP_SUGGESTIONS, findGroupsApi);
}

export function* findGroupsApi(action) {
    // Debounce, wait 500ms before sending a request
    yield delay(DEBOUNCE_DELAY);

    console.log("Calling findGroups in sagas for: " + action.query);
    const response = yield call(findGroups, action.query, action.start, action.size);
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast(`An error occurred when finding groups for query "${action.query}".`);
        yield put(showToast(toast));
        return;
    }
    const groups = (parsedResponse && parsedResponse.groups) || [];

    yield put(saveGroupSuggestions(groups));
}

export function* watchGetRasDataForUser() {
    yield takeEvery(Actions.GET_RAS_DATA_FOR_PERSON, getPersonApi);
}

export function* getPersonApi(action) {
    if (action.addNewAttendee) {
        let rasUser = {
            name: action.name && _.startCase(action.name.toLowerCase()),
            username: action.alias,
        };
        yield put(addAttendee(rasUserToAttendee(rasUser)));
    }

    console.log("Calling getPerson in sagas for: " + action.alias);
    const response = yield call(getPerson, action.alias);
    const person = JSON.parse(response);
    if (isErrorResponse(person)) {
        const toast = getErrorToast(`An error occurred when retrieving user ${action.alias}.`);
        yield put(showToast(toast));
        return;
    }

    if (action.addNewAttendee) {
        const rasUserAttendee = rasUserToAttendee(person);
        action.addNewAttendee && (rasUserAttendee.isPointOfContact = true);
        yield put(updateAttendee(rasUserAttendee));
    } else {
        yield put(updatePersonAttendeeWithRasData(person));
    }
    if (action.setSettingsPopoverAttendee) {
        yield put(setFavoritesPopoverAttendee(rasUserToAttendee(person)));
    }
}

export function* watchGetRasDataForUserAndAddToFavorite() {
    yield takeEvery(Actions.GET_RAS_DATA_FOR_PERSON_AND_ADD_TO_FAVORITE, getPersonAndAddToFavoriteApi);
}

export function* getPersonAndAddToFavoriteApi(action) {
    console.log("Calling getPersonAndAddToFavoriteApi in sagas for: " + action.alias);
    const response = yield call(getPerson, action.alias);
    const person = JSON.parse(response);
    if (isErrorResponse(person)) {
        const toast = getErrorToast(`An error occurred when retrieving user ${action.alias}.`);
        yield put(showToast(toast));
        return;
    }

    yield put(addFavorite(
        {
            type: FAVORITE_TYPE.USER,
            value: person.username,
            name: person.name,
        }, action.name)
    );
}

export function* watchGetRasDataForGroup() {
    yield takeEvery(Actions.GET_RAS_DATA_FOR_GROUP, getGroupApi);
}

export function* getGroupApi(action) {
    console.log("Calling getGroup in sagas for: " + action.name);
    const response = yield call(getGroup, action.name);
    const group = JSON.parse(response);
    if (isErrorResponse(group)) {
        const toast = getErrorToast(`An error occurred when retrieving group ${action.name}.`);
        yield put(showToast(toast));
        return;
    }

    yield put(updateGroupAttendeeWithRasData(group));

    if (action.setSettingsPopoverAttendee) {
        yield put(setFavoritesPopoverAttendee(groupToAttendeeWithRasData(group)));
    }
}

export function* addAttendeeByEmail(action) {
    console.log("Calling addAttendeeByEmail in sagas for: " + action.attendee?.email);
    const emailTail = action.attendee.email?.split("@")[1] || ""; // obtain the attendee's alias through their email
    if (emailTail.includes("amazon")) {
        const name = action.attendee.email.split("@")[0];
        let response;

        response = yield call(getPerson, name);
        const person = JSON.parse(response);
        if (!isErrorResponse(person)) {
            yield put(addAttendee(rasUserToAttendee(person, action.attendee.response, action.priority)));
            return;
        }

        response = yield call(getGroup, name);
        const group = JSON.parse(response);
        if (!isErrorResponse(group) && group.name !== undefined) {
            yield put(addAttendee(groupToAttendee(group.name, action.attendee.response, action.priority)));
            return;
        }
    }

    const externalAttendee = externalEmailToAttendee(action.attendee.email, action.attendee.response, action.priority);
    if (!action.addExternalEmail) {
        yield put(addAttendee(externalAttendee));
    } else {
        yield put(addExternalAttendee(externalAttendee));
    }
}

export function* watchAddAttendeeByEmail() {
    yield takeEvery(Actions.ADD_ATTENDEE_BY_EMAIL, addAttendeeByEmail);
}

export function* checkGroupMembership(username, groups, checkSubgroups) {
    const groupsQueue = [...groups];
    const exploredGroups = [];

    while (groupsQueue && groupsQueue.length > 0) {
        const group = groupsQueue.shift();
        const getGroupResponse = yield call(getGroup, group);
        if (isErrorResponse(getGroupResponse)) {
            const toast = getErrorToast(`An error occurred when verifying user ${username} membership with ${group}.`);
            yield put(showToast(toast));
            break;
        }

        exploredGroups.push(group);
        const groupMembers = (getGroupResponse && JSON.parse(getGroupResponse).members) || [];
        for (let member of groupMembers) {
            if (member.username === username) {
                return true;
            }
            if (checkSubgroups && member.type === 'group' && !exploredGroups.includes(member.username)) {
                groupsQueue.push(member.username);
            }
        }
    }

    return false;
}

export function* watchAddAttendeeAndFetchWorkingHour() {
    yield takeEvery(Actions.ADD_ATTENDEE_AND_FETCH_WORKING_HOURS, addAttendeeAndFetchWorkingHours);
}

export function* addAttendeeAndFetchWorkingHours(action) {
    // will return early if the input is invalid
    if (action.attendee === undefined || action.attendee.alias === undefined) {
        return;
    }

    const requestedOnBehalfOf = action.requestedOnBehalfOf;
    let attendee = action.attendee;
    attendee.workingHours = DEFAULT_WORKING_HOURS;

    const currentAttendees = yield select(getAttendees);
    // if we already fetched the attendee's workingHours we will return.
    const existingAttendee = currentAttendees?.find((currentAttendee) => currentAttendee?.alias === attendee?.alias);
    if (existingAttendee?.workingHours !== undefined) {
        return;
    }

    // fetch the rasData first if not fetched
    if (attendee?.rasData === undefined) {
        console.log("Calling getPerson in sagas for: " + attendee.alias);
        const response = yield call(getPerson, attendee.alias);
        const person = JSON.parse(response);
        if (isErrorResponse(person)) {
            const toast = getErrorToast(`An error occurred when retrieving user ${attendee.alias}.`);
            yield put(showToast(toast));
            return;
        }

        attendee = rasUserToAttendee(person);
    }

    // Check if attendee's workingHours has been cached
    const attendeesWorkingHoursCache = yield select(getAttendeesWorkingHoursCache);
    let shouldCacheWorkingHour = true;
    if (attendeesWorkingHoursCache[attendee.alias] !== undefined) {
        attendee.workingHours = attendeesWorkingHoursCache[attendee.alias];
    } else {
        let response = yield call(getUserPreferencesByUserAlias, requestedOnBehalfOf, attendee.alias);
        const userPreferences = response && JSON.parse(response);
        if (isErrorResponse(userPreferences)) {
            const toast = getErrorToast(`An error occurred when retrieving ${attendee.alias} preferences.`);
            yield put(showToast(toast));
        } else {
            if (userPreferences.preferences) {
                try {
                     // We put this parse in a try catch block in case there is a bad string stored in our preferences ddb
                     const preferencesResponse = JSON.parse(userPreferences.preferences);

                     if (preferencesResponse?.timePreferences?.workingHours !== undefined) {
                        attendee.workingHours = preferencesResponse.timePreferences.workingHours;
                     }

                     if (preferencesResponse?.timePreferences?.primaryTimezone !== undefined) {
                        attendee.workingHours.preferenceTimezone = preferencesResponse.timePreferences.primaryTimezone;
                     }
                } catch (e) {
                    shouldCacheWorkingHour = false;
                    console.error("Error parsing preferences: ", e);
                }
            }
        }
    }

    if (shouldCacheWorkingHour) {
        console.log(attendee);
        console.log("cacheAttendeeWorkingHours", attendee);
        yield put(cacheAttendeeWorkingHours(attendee));
    }

    if (existingAttendee !== undefined) {
        console.log("updateAttendee", attendee);
        yield put(updateAttendee(attendee));
    } else {
        console.log("addAttendee", attendee);
        yield put(addAttendee(attendee));
    }
}

const sagas = [
    watchPeopleAPISearchAll(),
    watchFindGroups(),
    watchGetRasDataForUser(),
    watchGetRasDataForGroup(),
    watchAddAttendeeByEmail(),
    watchGetRasDataForUserAndAddToFavorite(),
    watchAddAttendeeAndFetchWorkingHour(),
];

export default sagas;