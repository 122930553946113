import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import AutoLinks from "../../../features/shared/quill-utils";

import { FLOW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";

Quill.register("modules/autoLinks", AutoLinks);

const RichTextEditor = (props) => {
    const [richText, setRichText] = useState(props.textValue || "");
    const displayToolbar = props.hasOwnProperty("displayToolbar") ? props.displayToolbar : true;
    const ariaLabelledby = props["aria-labelledby"];
    const modules = {
        toolbar: displayToolbar && [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike','link'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['clean']
        ],
        // TODO: decide whether to disable the Tab button in the rich text editor.
        // code below will disable the Tab button in the text editor.
        // Tab will move focus to another component instead of tab-space effect.
        // keyboard: {
        //     bindings: {
        //         tab: false,
        //     },
        // },
        clipboard: {
            matchVisual: false
        },
        autoLinks: true,
    };
    const error = props.error ?? false;

    // React Quill formats documentation: https://quilljs.com/docs/formats/
    const formats = [
        // Inline
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',

        // Block
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block',

        // Embeds
        'formula',
        'image',
        'video',
    ];
    useEffect(() => {
        if(props.flowType === FLOW_TYPE.LOAD) {
            setRichText(props.textValue);
        }
    }, [props.textValue, props.flowType]);

    useEffect(() => {
        if (richText === "" && props.flowType === FLOW_TYPE.UPDATE) {
            setRichText(props.textValue);
        }
    }, [richText, props.textValue, props.flowType]);

    // Add text editor styling and accessibility
    useEffect(() => {
        let quillToolbar = document.querySelector(".ql-toolbar");
        let quillContainer = document.querySelector(".ql-container");
        let quillEditor = document.querySelector(".ql-editor");

        if (quillToolbar) {
            quillToolbar.setAttribute("role", "region");
            quillToolbar.ariaLabel = "Formatting options";
            quillToolbar.style.borderRadius = "4px 4px 0px 0px";
            quillToolbar.style.borderColor = "#697576"; // Gray-500

            quillToolbar.children[0].ariaLabel = "Font size menu";
            quillToolbar.children[0].children[0].children[0].setAttribute("aria-description", "Font size menu");
            quillToolbar.children[0].children[0].children[1].ariaLabel = " ";
            let buttonMenu = ["Large heading", "Medium heading", "Normal"];
            Array.from(quillToolbar.children[0].children[0].children[1].children).forEach((button, buttonIndex) => {
                button.ariaLabel = buttonMenu[buttonIndex];
            });

            let buttonGroup1 = ["Bold", "Italic", "Underline", "Strikethrough", "Link"];
            Array.from(quillToolbar.children[1].children).forEach((button, buttonIndex) => {
                button.ariaLabel = buttonGroup1[buttonIndex];
                const observer = new MutationObserver(() => {
                    let ariaLabel = buttonGroup1[buttonIndex] + (button.classList.contains("ql-active") ? " selected" : "");
                    if (button.ariaLabel !== ariaLabel) {
                        button.ariaLabel = ariaLabel;
                    }
                });
                observer.observe(button, { childList: false, attributes: true, subtree: false });
            });

            let buttonGroup2 = ["Numbered list", "Bulleted list"];
            Array.from(quillToolbar.children[2].children).forEach((button, buttonIndex) => {
                button.ariaLabel = buttonGroup2[buttonIndex];
            });

            let buttonGroup3 = ["Clear formatting"];
            Array.from(quillToolbar.children[3].children).forEach((button, buttonIndex) => {
                button.ariaLabel = buttonGroup3[buttonIndex];
            });
        }

        if (quillContainer) {
            quillContainer.style.borderColor = "#697576"; // Gray-500
            quillContainer.style.backgroundColor = "white";

            if (quillToolbar) {
                quillContainer.style.borderRadius = "0px 0px 4px 4px";
            } else {
                quillContainer.style.borderRadius = "4px";
            }
        }

        if (quillEditor) {
            quillEditor.style.fontFamily = "Amazon Ember";
            quillEditor.setAttribute("role", "textbox");
            if (ariaLabelledby) {
                quillEditor.setAttribute("aria-labelledby", ariaLabelledby)
            } else {
                quillEditor.ariaLabel = "Message body";
            }
        }
    }, [ariaLabelledby]);

    useEffect(() => {
        let quillToolbar = document.querySelector(".ql-toolbar");
        let quillContainer = document.querySelector(".ql-container");
        if (quillToolbar && quillContainer) {
            if (error) {
                quillContainer.style.borderColor = "#AD0A30"; // Red-500
                quillContainer.style.borderWidth = "2px";
                quillToolbar.style.borderColor = "#AD0A30"; // Red-500
                quillToolbar.style.borderWidth = "2px";
            } else {
                quillContainer.style.borderColor = "#697576"; // Gray-500
                quillContainer.style.borderWidth = "thin";
                quillToolbar.style.borderColor = "#697576"; // Gray-500
                quillToolbar.style.borderWidth = "thin";
            }
        }
    }, [error]);

    return (
        <React.Fragment>
            {props.updateTextValue ?
                <div
                    onBlur={() => props.updateTextValue(richText)}
                    style={{height: props.height}}
                >
                    <ReactQuill
                        key={props.uniqueId || ""}
                        theme="snow"
                        style={{
                            height: "100%",
                            overflow: "visible",
                            wordBreak: "break-word",
                        }}
                        modules={modules}
                        value={richText}
                        onChange={setRichText}
                        formats={formats}
                    />
                </div>
                :
                <ReactQuill
                    key={props.uniqueId || ""}
                    theme="snow"
                    style={{
                        height:"100%",
                        overflow: "visible",
                        wordBreak: "break-word",
                    }}
                    modules={modules}
                    defaultValue={props.textValue || ""}
                    onChange={props.setTextValue}
                    formats={formats}
                />
            }
        </React.Fragment>
    );
};

export default RichTextEditor;