import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import keycode from "keycode"
import uniqueId from "lodash/uniqueId"
import find from "lodash/find"
import noop from "lodash/noop"
import escapeRegExp from "lodash/escapeRegExp"
import chevronSelectTokens from "@amzn/meridian-tokens/base/icon/chevron-select"
import selectTokens from "@amzn/meridian-tokens/component/select"
import SelectOptionList from "./select-option-list"
import SelectOptionListContainer from "./select-option-list-container"
import { getOptionsFromChildren, isOptionValueSelected } from "./utils"
import InputBox from "../_input-box"
import Focus from "../_focus"
import { Style } from "../theme"
import Responsive from "../responsive"
import Context from "./context"
import { getTokenHelper, memoizeTokenStyles } from "../../_utils/token"
import textStyles from "../../_styles/text"
import inputResetStyles from "../../_styles/input-reset"
import noTextWrapStyles from "../../_styles/no-text-wrap"
import visuallyHiddenStyles from "../../_styles/visually-hidden"

const styles = memoizeTokenStyles(
  (t, { width, minWidth }) =>
    css(textStyles(t), {
      outline: "none",
      position: "relative",
      width,
      // If an explicit width is set then don't allow the select to flex
      flex: width ? "none !important" : undefined,
      minWidth,
    }),
  ["width", "minWidth"]
)

const customSearchInputStyles = memoizeTokenStyles(
  (t, { disabled, open, empty }) =>
    css(
      inputResetStyles({
        empty,
        textColor: t("searchInputForegroundColorDefault"),
        placeholderColor: t(
          "searchInputPlaceholderColor",
          disabled ? "disabled" : "default"
        ),
      }),
      // If the select is open then hide the value label and show the input,
      // otherwise hide the input and show the label. Note that the input is
      // visually hidden but is still focuseable (so the user can tab to it).
      open
        ? { "& + div": { display: "none" } }
        : // Preserve the input's width so the Select doesn't change width on
          // focus/input reveal.
          { ...visuallyHiddenStyles, width: "auto", position: "initial" }
    ),
  ["disabled", "open", "empty"]
)

const valueLabelStyles = css(noTextWrapStyles)

/* eslint-disable react/prop-types */
class SelectClass extends React.Component {
  static nativeSearchTimeoutDuration = 1000

  /**
   * Properties
   */

  state = {
    // Are the select options open?
    open: false,
    // This enables us to preselect an option in the option list. This is the
    // "default" because the internal state of the option list may change if the
    // user uses up/down arrow keys to manage the preselected index.
    defaultPreselectedIndex: undefined,
    // For this component, there are two types of searching:
    //   1) Custom searching is what happens when `onSearch` and `searchQuery`
    //      are passed in as props. This renders a search input inside the
    //      Select and allows the developer to filter options down according to
    //      any custom search algorithm.
    //   2) Native searching is what happens when no custom search props are
    //      passed. This search mimicks the behavior of native html selects -
    //      the search query is stored internally and the first option that
    //      matches the query (prefix matching only) is selected if the select
    //      is closed or "preselected" if the select is open.
    nativeSearchQuery: "",
    preselectedIndex: (getOptionsFromChildren(this.props.children) || []).findIndex(option => isOptionValueSelected(this.props.value, option.value)), // currently focused element
  }

  // Generate a unique id that can be used to reference this element in the DOM
  id = this.props.id || uniqueId("select-")

  popupId = uniqueId("options-list-")

  valueId = uniqueId("options-list-value-label-")

  nativeSearchTimeout = undefined

  rootRef = undefined

  optionListRef = undefined

  customSearchInputRef = undefined

  /**
   * Helpers
   */

  updateRootRef = ref => {
    this.rootRef = ref
    // If we are forwarding a ref, assign forwarded ref to root ref
    if (this.props.forwardedRef) this.props.forwardedRef.current = ref
  }

  updateOptionListRef = ref => (this.optionListRef = ref)

  updateCustomSearchInputRef = ref => (this.customSearchInputRef = ref)

  isMultiSelectable = () => Array.isArray(this.props.value)

  isNativeSearching = () => /[\S]/.test(this.state.nativeSearchQuery)

  isCustomSearchable = () => Boolean(this.props.onSearch)

  /**
   * Close the select, optionally retaining focus on the handle.
   */
  close = ({ retainFocus }) => {
    const customSearchable = this.isCustomSearchable()
    this.setState({
      open: false,
      focus: retainFocus,
      defaultPreselectedIndex: undefined,
      preselectedIndex: (getOptionsFromChildren(this.props.children) || []).findIndex(option => isOptionValueSelected(this.props.value, option.value)),
    })
    if (customSearchable) {
      this.props.onSearch("")
    }
    // Move focus back to the main node before closing the option list
    if (retainFocus) {
      if (this.isCustomSearchable()) {
        this.customSearchInputRef.focus()
      } else {
        this.rootRef.focus()
      }
    }
  }

  /**
   * Take the current search query and attempt to find and focus the matching
   * option
   */
  nativeSearch = searchQuery => {
    const { value, onChange, children } = this.props
    const { open, defaultPreselectedIndex } = this.state
    const options = getOptionsFromChildren(children).filter(
      ({ disabled }) => !disabled
    )
    const selectedOption = find(options, option =>
      isOptionValueSelected(value, option.value)
    )
    // Look for the first option that starts with the full search query
    const searchQueryRegExp = new RegExp(`^${escapeRegExp(searchQuery)}`, "i")
    const fullMatch = find(options, option =>
      searchQueryRegExp.test(option.label)
    )

    // Look for an option that starts with the last character typed. Sort the
    // options so that if the same character is typed repeatedly the focus
    // cycles through all matching options.
    const lastChar = searchQuery[searchQuery.length - 1]
    const lastCharRegExp = new RegExp(`^${escapeRegExp(lastChar)}`, "i")
    const currentIndex = open
      ? defaultPreselectedIndex
      : selectedOption
      ? selectedOption.index
      : 0
    const lastCharMatch = find(
      [
        ...options.slice(currentIndex + 1),
        ...options.slice(0, currentIndex + 1),
      ],
      option => lastCharRegExp.test(option.label)
    )

    // Prefer a full match to a last character match
    const match = fullMatch || lastCharMatch

    // If the select is expanded and we have a match then focus it
    if (match && open) {
      this.setState({ defaultPreselectedIndex: match.index })
    }
    // If the select is closed and we have a match then select it
    else if (match) {
      onChange(this.isMultiSelectable() ? [match.value] : match.value)
    }
  }

  setFocusToOptionIndex = index => {
    const elementID = `${this.popupId}-option-${index}`
    const elementToFocus = document.getElementById(elementID)
    elementToFocus && elementToFocus.focus()
  }

  // find index of next focused element
  getNextPreselectedIndex = delta => {
    const options = getOptionsFromChildren(this.props.children)
    const nextIndex = (this.state.preselectedIndex || 0) + delta

    if (nextIndex >= options.length) {
      return options.length - 1
    } else if (nextIndex < 0) {
      return 0
    } else {
      return nextIndex
    }
  }

  /**
   * Event handlers
   */

  onKeyDown = event => {
    const { disabled } = this.props
    const { open } = this.state
    const key = keycode(event)
    // HACK: Assume the key is "printable" (can be added to a search query) if
    // it's either of length 1 or a space.
    const printableKey =
      key && key.length === 1 ? key : key === "space" ? " " : undefined
    const isNativeSearching = this.isNativeSearching()
    const isCustomSearchable = this.isCustomSearchable()
    let keyUsed = false

    // If any character is typed that doesn't trigger behavior in the option
    // list (as indicated by event.defaultPrevented set to true) then make sure
    // the custom search input is focused
    if (
      isCustomSearchable &&
      !event.defaultPrevented &&
      this.customSearchInputRef !== document.activeElement && printableKey
    ) {
      this.customSearchInputRef.focus()
    }

    // If no custom search behavior is defined and a printable key was typed,
    // trigger the "native" search functionality that mimicks the browser
    if (!isCustomSearchable && printableKey) {
      const nativeSearchQuery = this.state.nativeSearchQuery + printableKey
      this.setState({ nativeSearchQuery })
      this.nativeSearch(nativeSearchQuery)
      // Reset the timeout that will the search query
      clearTimeout(this.nativeSearchTimeout)
      this.nativeSearchTimeout = window.setTimeout(
        () =>
          this.setState({
            nativeSearchQuery: "",
            defaultPreselectedIndex: undefined,
          }),
        SelectClass.nativeSearchTimeoutDuration
      )
      keyUsed = true
    }

    // If no custom search behavior is defined and backspace or delete was
    // pressed, clear the "native" search query
    if (!isCustomSearchable && (key === "backspace" || key === "delete")) {
      // Reset the timeout that will clears searc query and instead clear the
      // query immediately
      clearTimeout(this.nativeSearchTimeout)
      this.setState({
        nativeSearchQuery: "",
        defaultPreselectedIndex: undefined,
      })
      keyUsed = true
    }

    // If the user hits escape on an open select then close it
    if (open && key === "esc") {
      // Stop propagating the event upwards. This ensures that a parent element
      // (e.g. a modal) won't also handle the escape key.
      event.stopPropagation()
      this.close({ retainFocus: true })
      keyUsed = true
    }

    // If the user hits up, down, or backspace/delete/space (and they're not in
    // the middle of a search) on a collapsed select then open it
    if (
      !open &&
      !disabled &&
      (key === "up" ||
        key === "down" ||
        ((key === "backspace" || key === "delete" || key === "space") &&
          !isNativeSearching))
    ) {
      this.setState({ open: true })
      keyUsed = true
    }

    // Disabled tabbing while the select is open
    if (open && key === "tab") {
      keyUsed = true
    }

    if (open && (key === "down" || key === "up")) {
      const nextPreselectedIndex = this.getNextPreselectedIndex(key === "down" ? 1 : -1)

      this.setState({
        defaultPreselectedIndex: nextPreselectedIndex,
        preselectedIndex: nextPreselectedIndex
      })

      this.setFocusToOptionIndex(nextPreselectedIndex)
      keyUsed = true
    }

    // Don't perform the normal action associated with the key (e.g. scroll for
    // arrows/space) if the key was used for a select-related action
    if (keyUsed) {
      event.preventDefault()
    }
  }

  onKeyDownOptionList = event => {
    // Prevent "space" from selecting an option when the user is searching
    if (keycode(event) === "space" && this.isNativeSearching()) {
      event.preventDefault()
    }
  }

  onKeyDownCustomSearchInput = event => {
    const key = keycode(event)
    const { open } = this.state
    // If the user presses an up/down arrow key when focused on an open
    // searchable select's search input, then transfer the focus to the option
    // list and immediately move the preselected index from the current option
    // to the next/previous option. This mimicks what would happen if the option
    // list was focused when the arrow key was pressed.
    if (open && (key === "down" || key === "up") && this.optionListRef) {
      const nextPreselectedIndex = this.optionListRef.getNextPreselectedIndex(
        key === "down" ? 1 : -1
      )
      this.setState({
        defaultPreselectedIndex: nextPreselectedIndex,
        preselectedIndex: nextPreselectedIndex,
      })
      this.setFocusToOptionIndex(nextPreselectedIndex)
      event.preventDefault()
    }
    // If the user presses enter when focused on an open searchable select's
    // search input, then select the  first item in the option list (which will
    // be the preselected option). This mimicks what would happen if the option
    // list was focused when the enter key was pressed.
    else if (open && key === "enter") {
      const firstOption = getOptionsFromChildren(this.props.children)[0]
      // Only select the first option if there's a search query
      if (firstOption && this.props.searchQuery) {
        this.onClickOption(firstOption.value)
      }
      // Don't submit a form if the user presses enter on an open searchable
      // select
      event.preventDefault()
    }
  }

  onChangeCustomSearchInput = event => {
    const { value } = event.target
    const { defaultPreselectedIndex } = this.state
    // Make sure the select is open if the user is typing in a search query
    if (!this.state.open) {
      this.setState({ open: true })
    }
    // If we have a query, preselect the first option if it isn't already
    if (value && defaultPreselectedIndex !== 0) {
      this.setState({
        defaultPreselectedIndex: 0,
        preselectedIndex: 0
      })
    }
    // If we don't have a query, clear the preselected option
    if (!value && defaultPreselectedIndex !== -1) {
      this.setState({
        defaultPreselectedIndex: -1,
        preselectedIndex: 0
      })
    }
    this.props.onSearch(value)
  }

  onMouseDownInputBox = event => {
    if (this.isCustomSearchable()) {
      if (!this.state.open) {
        // If the user mouses down on a closed searchable select, open it
        this.setState({ open: true })
      }

      if (event.target !== this.customSearchInputRef) {
        // If the user mouses down on the input box of a custom searchable
        // select, focus on the custom search input
        this.customSearchInputRef.focus()
        event.preventDefault()
      }
    } else {
      // If the user mouses down on a non-searchable select, toggle it
      this.setState({ open: !this.state.open })
    }
  }

  onClickOption = value => {
    if (this.isMultiSelectable()) {
      // If the value is selected then deselect it, otherwise select it
      if (isOptionValueSelected(this.props.value, value)) {
        this.props.onChange(this.props.value.filter(v => v !== value))
      } else {
        this.props.onChange([...this.props.value, value])
      }
    } else {
      this.close({ retainFocus: true })
      this.props.onChange(value)
    }
  }

  /**
   * Re-trigger the option list's "componentDidMount" when the option list is
   * actually visible. This is necessary because the things the option list does
   * on mount (like setting the scroll and transferring focus) only work when
   * it's visible, but the PopoverController mounts it in an invisible state (in
   * order to fade it in). This is only done for the popover because the
   * BottomSheet's onOpen even fires before its children are mounted, so this
   * won't work.
   */
  onOpenPopover = () => {
    if (this.optionListRef) {
      this.optionListRef.componentDidMount()
    }

    const options = getOptionsFromChildren(this.props.children) || []
    const preselectedIndex = options.findIndex(option => isOptionValueSelected(this.props.value, option.value))

    this.setState({ preselectedIndex: preselectedIndex })
    this.setFocusToOptionIndex(preselectedIndex)
  }

  /**
   * Close and retain focus if the user attempts to close the bottom sheet (e.g.
   * by tapping outside of it). This isn't necessary for the popover which
   * doesn't initiate its own close events.
   */
  onCloseBottomSheet = () => this.close({ retainFocus: true })

  /**
   * Close the select when it loses focus.
   */
  onBlur = event => {
    this.close({ retainFocus: false })
    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
  }

  /**
   * Clear out the default preselected index when the option list changes its
   * internal state. This allows us to update the default later on and stomp the
   * option list's internal state when we need too. If we didn't reset the
   * default here and just left it at 0 (for example), then setting it to 0
   * again later on would have no effect.
   */
  onChangePreselectedIndex = (index) => {
    if (this.state.defaultPreselectedIndex !== undefined) {
      this.setState({ defaultPreselectedIndex: undefined })
    }
    this.setState({ preselectedIndex: index });
  }

  /**
   * Lifecycle hooks
   */

  componentDidMount() {
    if (this.props.autoFocus) {
      // NOTE: React guarantees that callback refs will be resolved before
      // componentDidMount is called, so this is safe.
      if (this.isCustomSearchable()) {
        this.customSearchInputRef.focus()
      } else {
        this.rootRef.focus()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { open } = this.state
    const disabled = this.props.disabled && !prevProps.disabled
    // Collapse the select if it was disabled while open
    if (disabled && open) {
      this.close({ retainFocus: false })
    }
  }

  componentWillUnmount() {
    // Make sure we don't attempt to clear the searchQuery after the component
    // is gone
    clearTimeout(this.nativeSearchTimeout)
  }

  /**
   * Renderers
   */

  render() {
    const props = this.props
    const { open, defaultPreselectedIndex, preselectedIndex } = this.state
    const isCustomSearchable = this.isCustomSearchable()
    const options = getOptionsFromChildren(props.children)
    const selectedOptions = options.filter(option =>
      isOptionValueSelected(props.value, option.value)
    )
    const valueLabel = props.valueLabel(selectedOptions) || ""
    const valueIndex = options.findIndex(option =>
      isOptionValueSelected(props.value, option.value)
    )
    const tabIndex = props.disabled || isCustomSearchable ? undefined : "0"
    const optionIndex = preselectedIndex > -1 ? preselectedIndex : valueIndex > -1 ? valueIndex : undefined

    return (
      <Style tokens={selectTokens} map={getTokenHelper("select")}>
        {t => (
          <Focus
            onFocus={props.onFocus}
            onBlur={this.onBlur}
            multipleTargets={true}
          >
            {({ focus, focusProps }) => (
              <Responsive
                query="min-width"
                fallback={`${t("optionListPopoverBreakpoint")}px`}
                props={{
                  optionListContainerType: isCustomSearchable
                    ? { default: "popover" }
                    : {
                        default: "bottom-sheet",
                        [`${t("optionListPopoverBreakpoint")}px`]: "popover",
                      },
                }}
              >
                {({ optionListContainerType }) => (
                  <div
                    tabIndex={tabIndex}
                    id={this.id}
                    onKeyDown={props.disabled ? undefined : this.onKeyDown}
                    ref={this.updateRootRef}
                    className={styles(t, {
                      width: props.width,
                      minWidth: props.minWidth,
                    })}
                    {...focusProps}
                  >
                    <Context.Consumer>
                      {({ suffixIconTokens = chevronSelectTokens }) => (
                        <InputBox
                          label={props.label}
                          htmlFor={this.id}
                          empty={!valueLabel}
                          focus={focus}
                          suffixIconTokens={suffixIconTokens}
                          animated={optionListContainerType === "popover"}
                          labelFloated={
                            (isCustomSearchable && open) ||
                            Boolean(props.placeholder || valueLabel)
                          }
                          disabled={props.disabled}
                          size={props.size}
                          prefix={props.prefix}
                          suffix={props.suffix}
                          error={props.error && !focus}
                          cursor={
                            isCustomSearchable && open ? "text" : "pointer"
                          }
                          onMouseDown={
                            props.disabled
                              ? undefined
                              : this.onMouseDownInputBox
                          }
                        >
                          <input
                            type={isCustomSearchable ? "search" : undefined}
                            role="combobox"
                            aria-owns={this.popupId}
                            aria-controls={this.popupId}
                            aria-haspopup="listbox"
                            aria-labelledby={props["aria-labelledby"] ? `${props["aria-labelledby"]} ${this.valueId}` : undefined}
                            aria-describedby={props["aria-describedby"]}
                            aria-required={props["aria-required"]}
                            aria-invalid={props["aria-invalid"]}
                            aria-expanded={open}
                            aria-disabled={props.disabled}
                            aria-activedescendant={!this.isMultiSelectable() && optionIndex > -1 ? `${this.popupId}-option-${optionIndex}` : undefined}
                            onKeyDown={!props.disabled && isCustomSearchable ? this.onKeyDownCustomSearchInput : undefined}
                            className={customSearchInputStyles(t, {
                              empty:
                                this.props.searchQuery === undefined ||
                                this.props.searchQuery === "",
                              open,
                              disabled: props.disabled,
                            })}
                            placeholder={props.placeholder}
                            value={this.props.searchQuery || ""}
                            ref={this.updateCustomSearchInputRef}
                            onChange={this.onChangeCustomSearchInput}
                            disabled={props.disabled}
                            autoComplete="off"
                            {...focusProps}
                            tabIndex="0"
                          />
                          <div
                              id={this.valueId}
                              className={valueLabelStyles}
                          >
                            {valueLabel ? valueLabel : props.placeholder}
                          </div>
                        </InputBox>
                      )}
                    </Context.Consumer>
                    <SelectOptionListContainer
                      type={optionListContainerType}
                      open={open}
                      popoverAnchorNode={this.rootRef}
                      popoverMaxHeight={props.popoverMaxHeight}
                      onOpenPopover={this.onOpenPopover}
                      onCloseBottomSheet={this.onCloseBottomSheet}
                      t={t}
                    >
                      <SelectOptionList
                        open={open}
                        autoFocus={!isCustomSearchable}
                        value={props.value}
                        searchQuery={props.searchQuery}
                        defaultPreselectedIndex={defaultPreselectedIndex}
                        preselectedIndex={preselectedIndex}
                        onChangePreselectedIndex={this.onChangePreselectedIndex}
                        onClickOption={this.onClickOption}
                        onKeyDown={this.onKeyDownOptionList}
                        ref={this.updateOptionListRef}
                        popupId={this.popupId}
                        {...focusProps}
                      >
                        {props.children}
                      </SelectOptionList>
                    </SelectOptionListContainer>
                    {props.name ? (
                      this.isMultiSelectable() ? (
                        props.value.map(value => (
                          <input
                            type="hidden"
                            key={value}
                            name={`${props.name}[]`}
                            value={value}
                          />
                        ))
                      ) : (
                        <input
                          type="hidden"
                          name={props.name}
                          value={props.value || ""}
                        />
                      )
                    ) : null}
                  </div>
                )}
              </Responsive>
            )}
          </Focus>
        )}
      </Style>
    )
  }
}
/* eslint-enable react/prop-types */

// Forward ref to class component. TODO: update class component
// to functional component.
const Select = React.forwardRef((props, ref) => (
  <SelectClass {...props} forwardedRef={ref} />
))

Select.displayName = "Select"

Select.propTypes = {
  /**
   * A list of `SelectOption` elements that represent the choices to present to
   * the user.
   *
   * If no `SelectOption` elements are provided then any other elements passed here
   * will be rendered in place of them. This allows you to render things like a
   * loading indicator while waiting for asyncronously loaded options or a
   * message to the user when no options are available. Note that these elements
   * cannot contain anything interactive like links or buttons for accessibility
   * reasons.
   */
  children: PropTypes.node.isRequired,
  /**
   * Use this attribute to associate a text label with a Select component.
   * It accepts the id of a label element. This label only needs the context.
   * The selected value(s) will be appended to this label by this component automatically.
   * Note: It appears the placeholder prop must have a value for this to work.
   *
   * @since 5.x
   */
  "aria-labelledby": PropTypes.string,
  /**
   * Use this attribute to pass a space separated list of ids that you would like to use to
   * describe this Select component.
   * ex: This can be used to associate error labels to this component so that users will understand
   * when this component has a bad value
   *
   * @added by meetex team member (camei@)
   */
  "aria-describedby": PropTypes.string,
  /**
   * Use this attribute to indicate that user input is required on the element before a form may be submitted.
   * ex: set this to true when there is a required field
   *
   * @added by meetex team member (kjoshuaz@)
   */
  "aria-required": PropTypes.string,
  /**
   * Use this attribute to indicate the entered value does not conform to the format expected by the application.
   * ex: set this to true when there is a required field missing
   *
   * @added by meetex team member (kjoshuaz@)
   */
  "aria-invalid": PropTypes.string,
  /**
   * If set to true then the select will grab focus as soon as it's mounted.
   *
   * This uses HTML's native `autofocus` attribute. Use this sparingly as
   * there are [common accessibility pitfalls](https://www.brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/)
   * associated with this behavior.
   */
  autoFocus: PropTypes.bool,
  /**
   * This disables interaction with the component and applies special visual
   * styles to indicate that it's not interactive.
   */
  disabled: PropTypes.bool,
  /**
   * Show the component in an error state. Use this to indicate that the value
   * of the component is invalid.
   *
   * Consider pairing this state with a small error `Alert` component below
   * or to the right of the component explaining what the error is.
   */
  error: PropTypes.bool,
  /**
   * Sets an `id` attribute on the component's form element.
   *
   * Use this for testing or for accessibility. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   */
  id: PropTypes.string,
  /**
   * A label for the input.
   *
   * If this prop is provided the `size` prop will be ignored.
   */
  label: PropTypes.string,
  /**
   * Set the minimum width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   *
   * @since 5.x
   */
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The name of the component's form control. If the component is placed in a
   * form, this will be the name associated with the component's data when the
   * form is submitted.
   */
  name: PropTypes.string,
  /**
   * Text that is shown in place of the select's value when nothing is selected.
   *
   * This text is not visible when an option is selected, so it should
   * not contain essential information that should be persisted. For that,
   * consider using the `label` prop.
   */
  placeholder: PropTypes.string,
  /**
   * Sets the maximum height of the popover containing the select's options.
   * This is helpful if you have a very long list of options but don't want
   * to show a very tall popover. The option list is scrollable, so any
   * options that overflow the popover will still be accessible.
   */
  popoverMaxHeight: PropTypes.number,
  /**
   * Static text to show in an end-cap prepended to the select.
   */
  prefix: PropTypes.string,
  /**
   * Pass the query obtained via the `onSearch` handler back to this prop to
   * update the query rendered by the Select.
   */
  searchQuery: PropTypes.string,
  /**
   * Sets the size of the component using a preset.
   *
   * If the `label` prop is provided this prop will be ignored (the label
   * requires a particular amount of space which is not configurable).
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  /**
   * Static text to show in an end-cap appended to the input.
   */
  suffix: PropTypes.string,
  /**
   * This is the value of the currently selected option. Provide a number or
   * string value for a single-selectable Select, or an array of numbers or
   * strings for a multi-selectable Select. The matching option will
   * automatically be highlighted in the select.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  /**
   * This function generates the label that is shown in the Select's form
   * field to summarize the current value of the Select. This function
   * receives an array containing all of the selected options that are
   * provided as children to the Select component.
   *
   * The default function will return the selected option's label
   * if one selected option is found. If more than one selected option is
   * found it will return "X selected".
   *
   * If you are filtering the options in the Select (e.g. for a searchable
   * Select), you will have to provide your own function here that does not
   * rely on the array of options passed to this function. As stated above
   * that array will only include selected options *that are currently
   * rendered as children*, so it won't include any selected options that have
   * been filtered out of the current view.
   */
  valueLabel: PropTypes.func,
  /**
   * Set the width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   */
  onBlur: PropTypes.func,
  /**
   * A function that will be called when the user attempts to change the value
   * of the component (e.g. by selecting an option). The new value will be
   * passed as the first argument to this function.
   *
   * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
   * so you must track the values provided to this function somewhere in state
   * and pass them back via the `value` prop in order to make this component
   * interactive.
   */
  onChange: PropTypes.func,
  /**
   * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   */
  onFocus: PropTypes.func,
  /**
   * This function makes the select "searchable". A searchable select allows
   * the user to type a query into a text input to search through options.
   *
   * This function will be called with the user's latest query, which should
   * be passed back to the `searchQuery` prop. Note that this will not
   * automatically filter the options in the select. That responsibility is
   * still up to the developer.
   */
  onSearch: PropTypes.func,
}

Select.defaultProps = {
  onChange: noop,
  size: "medium",
  valueLabel: options =>
    options.length === 1
      ? options[0].label
      : options.length > 0
      ? `${options.length} selected`
      : "",
}

export default Select
