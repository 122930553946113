export const chimeBridgeInfoTemplate = `
<div> 
<div>==============Conference Bridge Information==============</div>
<p>You have been invited to an online meeting, powered by Amazon Chime.</p>
<h4>Chime meeting ID: {{MEETING_ID}}</h4>
<h4>Join via Chime clients (manually): Select "Meetings > Join a Meeting", and enter {{MEETING_ID}}</h4>
<h4>Join via Chime clients (auto-call): If you invite auto-call as attendee, Chime will call you when the meeting starts, select "Answer"</h4> <h4>Join via browser screen share: <a href="https://chime.aws/{{MEETING_ID}}">https://chime.aws/{{MEETING_ID}}</a></h4>
<h4>Join via phone (US): <a href="tel:+1-929-432-4463,,,{{MEETING_ID}}#"><strong>+1-929-432-4463,,,{{MEETING_ID}}#</strong></a></h4>
<h4>Join via phone (US toll-free): <a href="tel:+1-855-552-4463,,,{{MEETING_ID}}#"><strong>+1-855-552-4463,,,{{MEETING_ID}}#</strong></a></h4>
<h4>International dial-in: <a href="https://chime.aws/dialinnumbers/">https://chime.aws/dialinnumbers/</a></h4>
<h4>In-room video system: Ext: 62000, Meeting PIN: {{MEETING_ID}}#</h4>
</div>
<div>=================================================</div>
`;