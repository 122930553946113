import React from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import RadioButton from "@amzn/meridian/radio-button";
import Icon from "../../meridian-custom-components/src/components/icon";

import "../settings.css";

const RadioGroup = (props) => {
    const isVertical = props.isVertical;
    const hasDescription = props.radios?.length > 0 && props.radios[0]?.descriptions?.length > 0;
    const width = props.radios?.length > 0 ? (100 / props.radios.length + 10) + "%" : "20%";
    const widths = props.radios?.length > 0 ? props.radios.map(() => hasDescription ? width : "fit") : ["fit"];

    const [checkedValue, setCheckedValue] = [props.checkedValue, props.setCheckedValue];

    const radioGroup = props.radios?.length > 0 && props.radios.map((radio, index) =>
        <Column key={"radioGroup" + index}>
            <Row>
                <RadioButton
                    checked={radio?.data ? checkedValue === radio?.data : checkedValue === radio.value}
                    onChange={setCheckedValue}
                    value={radio.data ?? radio.value}
                >
                    {radio.value}
                </RadioButton>
                {radio.icon && <Icon tokens={radio.icon}/>}
            </Row>
        </Column> 
    );

    return (
        <Column>
            {isVertical ? radioGroup :
                    <Row widths={widths} spacing="large" className="settings-radio-group-radio">
                        {radioGroup}
                    </Row>
            }
            {hasDescription &&
                    <Row widths={widths} spacing="large" alignmentVertical="top">
                            {props.radios?.length > 0 && props.radios.map((radio, index) => 
                                <Column key={"RadioDescriptionGroup" + index} spacing={"none"}>
                                    {radio.descriptions.map((description, index) => 
                                        <Text key={"RadioDescription" + index} type="b200" color="secondary" className="settings-radio-group-description">
                                            {description}
                                        </Text>
                                    )}
                                </Column>
                            )}
                    </Row>
            }
        </Column>
    );
};

export default RadioGroup;