module.exports = {
    DEFAULT_TIME: {
        START_TIME: {
            HOURS: 9,
            MINUTES: 0,
            SECONDS: 0,
        },
        END_TIME: {
            HOURS: 17,
            MINUTES: 0,
            SECONDS: 0,
        },
    },
    STEP: {
        NOTIFY_PEOPLE: "NOTIFY_PEOPLE",
        OOTO_AGENDA_AND_REVIEW: "OOTO_AGENDA_AND_REVIEW"
    },
    STEP_LABEL: {
        NOTIFY_PEOPLE: "Notify people",
        OOTO_AGENDA_AND_REVIEW: "OOTO agenda and review"
    },
    FLOW_TYPE: {
        CREATE: "create",
        UPDATE: "update",
    },
    ALERTS: {
        // Error Alerts
        OOTO_MAX_PAYLOAD_SIZE_EXCEEDED: {
            "type": "error",
            "value": "Your OOTO request is too large (max 6MB). Please shorten the text body.",
            "id": "amw-ooto-max-payload-size-exceeded-id",
        },
        // Informational Alerts
        OOTO_INFORMATION: {
            "type": "informational",
            "value": "OOTO creates an appointment on your personal calendar, and sends a meeting invite to your notify list that doesn’t block their calendar.",
            "id": "amw-ooto-information-id",
        },
        OOTO_PROCESSING_CREATE_MEETING: {
            "type": "informational",
            "value": "Processing",
            "id": "amw-meeting-create-meeting-processing-id",
        },
        // warning Alerts
        OOTO_INCORRECT_TIME_INFO: {
            "type": "warning",
            "value": "Update your OOTO date/time in the <b>{0}</b> to match your current selection.<br/>Current selection: ",
            "id": "amw-ooto-incorrect-timeinfo",
        },
    },
    TABLE_ROW_COLOR: "#f0f1f2",
    BACKGROUND_OPACITY: 0.05,
    INPUT_WIDTH: {
        TABLE_WIDTH: 900,
        TIME_SELECT_WIDTH: 140,
        START_END_WIDTH: 165,
        DATE_PICKER_WIDTH: 170,
        STATUS_WIDTH: 200,
        DATE_RANGE_PICKER_MOBILE_WIDTH: 335,
        DATE_RANGE_PICKER_WIDTH: 350,
    },
    INPUT_HEIGHT: {
        TEXT_EDITOR_HEIGHT: 700,
        TIME_SELECT_HEIGHT: 78,
    }
};