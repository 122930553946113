import * as Actions from "./action-types";

export const savePreferences = (preferences) => {
    return {type: Actions.SAVE_PREFERENCES, preferences};
};

export const savePreferencesAysnc = (requestedOnBehalfOf, preferences, fromPreferencePage = true) => {
    return {type: Actions.SAVE_PREFERENCES_ASYNC, requestedOnBehalfOf, preferences, fromPreferencePage};
};

export const setFavoritesPopoverAttendee = (popoverAttendee) => {
    return {type: Actions.SET_FAVORITES_POPOVER_ATTENDEE, popoverAttendee};
};

export const saveSettingsChimeEmail = (email) => {
    return {type: Actions.SET_CHIME_EMAIL, email};
}

export const setPreferencesLoaded = (isLoaded) => {
    return {type: Actions.SET_PREFERENCE_LOADED, isLoaded};
}

export const getUserPreferencesAndFavorites = (requestedOnBehalfOf) => {
    return {type: Actions.GET_FAVORITES_AND_PREFERENCES_ASYNC, requestedOnBehalfOf};
};