import PropTypes from "prop-types"
import useFocus from "../_use-focus"

/**
 * NOTE: This component is deprecated. Use the useFocus hook instead.
 */
const Focus = ({ children, disabled, multipleTargets, onBlur, onFocus }) => {
  const focus = useFocus({
    disabled,
    multipleNodes: multipleTargets,
    onBlur,
    onFocus,
  })
  return children(focus)
}

Focus.displayName = "Focus"

Focus.propTypes = {
  children: PropTypes.func.isRequired,
  /**
   * A bug in React means that onBlur is not called when an element with focus
   * is disabled. This prop allows you to manually inform the Focus component
   * when the element(s) that it manages are disabled so that it can turn off
   * its internal focus state. See: https://github.com/facebook/react/issues/9142
   */
  disabled: PropTypes.bool,
  /**
   * Turn this on if you are using this component to track the focus of
   * multiple elements.
   */
  multipleTargets: PropTypes.bool,
  /**
   * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   */
  onBlur: PropTypes.func,
  /**
   * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   */
  onFocus: PropTypes.func,
}

export default Focus
