import React from "react";

import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Link from "@amzn/meridian/link";
import Responsive from "@amzn/meridian/responsive";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import { ERROR, IMAGE_CARD, SCREEN_SIZE } from "../shared-constants";

const ImageCard = (props) => {
    const imgHeight = props.imgHeight || IMAGE_CARD.IMAGE_HEIGHT;
    const imgWidth = props.imgWidth || IMAGE_CARD.IMAGE_WIDTH;
    const subtitleText = props.subtitleText;

    const renderSubtitle = () => {
        switch (props.errorType) {
            case ERROR.NOT_FOUND.VALUE:
                return (
                    <div>{subtitleText}, please visit <Link href="https://meetings.amazon.com">meetings.amazon.com</Link> and retry</div>
                );
            case ERROR.UNAUTHORIZED.VALUE:
            default:
                return (
                    <div>{subtitleText}</div>
                );
        }
    };

    return (
        <Responsive query="min-width" props={{
            screenSizeBreakpoint: {
                default: SCREEN_SIZE.MOBILE_VIEW,
                "550px": SCREEN_SIZE.PARTIAL_MOBILE_VIEW,
            },
        }}>
            {(responsiveProps) => (
                <Row spacingInset="medium" alignmentHorizontal="center">
                    <Box type="outline">
                        <Column maxWidth="650px" width="100%" spacingInset="xlarge large" spacing="large" alignmentHorizontal="center">
                            <img src={props.imgSrc} height={imgHeight} width={imgWidth} alt={props.altText} />
                            <Column spacing="medium" alignmentHorizontal="center">
                                <Text type={responsiveProps.screenSizeBreakpoint >= SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "h500" : "h300"} alignment="center">
                                    {props.titleText}
                                </Text>
                                <Text type={responsiveProps.screenSizeBreakpoint >= SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "b500" : "b300"} alignment="center">
                                    {renderSubtitle()}
                                </Text>
                            </Column>
                        </Column>
                    </Box>
                </Row>
            )}
        </Responsive>
    );
};

export default ImageCard;