import React from "react"
import PropTypes from "prop-types"
import boxTokens from "@amzn/meridian-tokens/component/box"
import { css } from "emotion"
import CustomPropTypes from "../../_prop-types"
import { parseSpacing } from "../../_prop-types/parsers"
import { useTheme } from "../theme"
import { getToken } from "../../_utils/token"
import { memoize, memoizeByKeys } from "../../_utils/functional"
import cxMemoized from "../../_utils/cx-memoized"
import filterPropsByPrefix from "../../_utils/filter-props-by-prefix"

const styles = memoizeByKeys(
  ({
    backgroundColor,
    borderColor,
    borderWidth,
    borderRadius,
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    overflowY,
    spacingInset,
  }) =>
    css({
      boxSizing: "border-box",
      // If we have a border radius defined then clip content so that it doesn't
      // bleed out the corners
      overflow: borderRadius ? "hidden" : undefined,
      overflowY: overflowY ? overflowY : undefined,
      padding: parseSpacing(spacingInset)
        .map(value => `${value}px`)
        .join(" "),
      borderStyle: borderWidth && borderColor ? "solid" : undefined,
      borderWidth,
      borderColor,
      borderRadius,
      backgroundColor,
      width,
      height,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
    }),
  [
    "backgroundColor",
    "borderColor",
    "borderWidth",
    "borderRadius",
    "width",
    "height",
    "minWidth",
    "minHeight",
    "maxWidth",
    "maxHeight",
    "overflowY",
    "spacingInset",
  ]
)

const themedStyles = memoize(t =>
  memoizeByKeys(
    ({
      type,
      backgroundColor,
      width,
      height,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      overflowY,
      spacingInset,
    }) =>
      styles({
        backgroundColor: backgroundColor
          ? getToken(t.tokens, "themeBackground", [
              backgroundColor,
              "default",
            ]) || backgroundColor
          : t("backgroundColor", type),
        borderColor: t("borderColor", type),
        borderWidth: t("borderWidth"),
        borderRadius: t("borderRadius", type),
        width,
        height,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        overflowY,
        spacingInset,
      }),
    [
      "type",
      "backgroundColor",
      "width",
      "height",
      "minWidth",
      "minHeight",
      "maxWidth",
      "maxHeight",
      "overflowY",
      "spacingInset",
    ]
  )
)

/**
 * The Box component can also be used to visually separate part of the layout by
 * applying an outline or fill.
 *
 * It can also be used as a layout component (similar to the Row and Column
 * components) to apply width and inset padding to any part of the layout (with
 * or without an outline or fill).
 */
const Box = React.forwardRef((props, ref) =>
  // Only pull in theme tokens if we're styling the box
  props.backgroundColor || props.type
    ? React.createElement(ThemedBox, { ...props, ref }, props.children)
    : React.createElement(
        props.tag,
        {
          ...filterPropsByPrefix(props, ["data-"]),
          className: cxMemoized(styles(props), props.className),
          id: props.id,
          tabIndex: props.tabIndex,
          ref,
        },
        props.children
      )
)

Box.displayName = "Box"

Box.propTypes = {
  /**
   * Set the box's background color using a preset, or using a string
   * containing any valid CSS color.
   */
  backgroundColor: PropTypes.oneOfType([
    PropTypes.oneOf([
      "primary",
      "secondary",
      "alternatePrimary",
      "alternateSecondary",
    ]),
    PropTypes.string,
  ]),
  /**
   * The elements to render inside the box.
   */
  children: PropTypes.node,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  /**
   * All props prefaced with `data-` are accepted. This is useful for
   * integrating with other libraries such as Amplify Analytics or Cypress.
   *
   * @since 5.x
   */
  "data-*": PropTypes.string,
  /**
   * Set the height of the box using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Adds an [id](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id) to the component.
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   *
   * @since 4.x
   */
  id: PropTypes.string,
  /**
   * Set the maximum height of the box using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the maximum width of the box using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the minimum height of the box using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the minimum width of the box using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Controls the vertical overflow behavior of the container. The container
   * can become scrollable by setting the prop to scroll, or the overflow
   * can be hidden by setting the prop to hidden.
   */
  overflowY: PropTypes.oneOf(["auto", "hidden", "scroll"]),
  /**
   * Apply padding to the component.
   *
   * CSS-style [1-to-4 value shorthand](https://developer.mozilla.org/en-US/docs/Web/CSS/Shorthand_properties)
   * is accepted (e.g. `"small medium"` for small padding on the top/bottom and
   * medium padding on the left/right).
   */
  spacingInset: CustomPropTypes.spacing(4),
  /**
   * Manually specify the order of the component in sequential keyboard navigation.
   *
   * Use this with caution. See docs for the [HTML tabindex attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex)
   * for details on appropriate usage.
   */
  tabIndex: PropTypes.string,
  /**
   * The HTML tag to use when rendering the component to the DOM.
   */
  tag: PropTypes.string,
  /**
   * The type of visuals to apply to the box. If this is omitted the box will
   * not have any decorative styles and can be used purely for layout purposes.
   */
  type: PropTypes.oneOf(["outline", "fill"]),
  /**
   * Set the width of the box using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Box.defaultProps = {
  tag: "div",
  spacingInset: "none",
}

/* eslint-disable react/prop-types */

/**
 * This component renders a themed box. This is only used if themed styles are
 * actually applied to the box. Otherwise we can skip all the extra work that
 * goes into theming.
 */
const ThemedBox = React.forwardRef((props, ref) => {
  const t = useTheme(boxTokens, "box")
  return React.createElement(
    props.tag,
    {
      ...filterPropsByPrefix(props, ["data-"]),
      className: cxMemoized(themedStyles(t)(props), props.className),
      id: props.id,
      tabIndex: props.tabIndex,
      ref,
    },
    props.children
  )
})

ThemedBox.displayName = "ThemedBox"

/* eslint-enable react/prop-types */

export default Box
