import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getCalendarViewType,
    getCalendarDate,
    getIdentity,
    getMeetingListCalendarLoaded,
    getMeetingListCalendar,
    getCalendarStatusFilter,
    getCalendarResponseFilter,
    getSettingsCalendarViewForDesktop,
    getSettingsCalendarViewForMobile,
    getSettingsDateFormat,
    getSettingsTimeFormat,
    getSettingsPrimaryTimezone,
} from "../../../sagas/selector";
import {
    setCalendarViewType,
    setCalendarDate,
    findMeetings,
    setCalendarStatusFilter,
    setCalendarResponseFilter,
} from "../actions";

import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import Row from "@amzn/meridian/row";
import Modal from "../../shared/meridian-custom-components/src/components/modal";
import Responsive from "@amzn/meridian/responsive";
import parse from "date-fns/parse";
import addTime from "date-fns/add";
import isSaturday from "date-fns/isSaturday";
import isSunday from "date-fns/isSunday";

import Calendar from "../components/calendar";
import CalendarOptions from "../components/calendar-options";
import { CALENDAR_MODE, SINGLE_DAY_VIEW_THRESHOLD } from "../calendar-constants";
import MeetingsToaster from "../../shared/toasts/containers/toast-container.js";
import useWindowDimensions from "../../shared/hooks/useWindowDimensionsHook";
import { CALENDAR_VIEW_DESKTOP, CALENDAR_VIEW_MOBILE } from "../../shared/settings/settings-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";


const formatIso = date => format(date, "yyyy-MM-dd");

const CalendarDetails = () => {
    const { width } = useWindowDimensions();
    const calendarViewType = useSelector(getCalendarViewType);
    const calendarDate = useSelector(getCalendarDate) || formatIso(calendarViewType === "workweek" ? newDateWithinWorkWeek(calendarViewType) : new Date());
    const calendarStatusFilter = useSelector(getCalendarStatusFilter) || ["busy", "tentative", "outOfOffice", "free"];
    const calendarResponseFilter = useSelector(getCalendarResponseFilter) || ["accept", "tentative", "notResponded"];
    const { email } = useSelector(getIdentity);
    const meetingListLoaded = useSelector(getMeetingListCalendarLoaded);
    const meetingList = useSelector(getMeetingListCalendar);
    const calendarViewDesktop = useSelector(getSettingsCalendarViewForDesktop);
    const calendarViewMobile = useSelector(getSettingsCalendarViewForMobile);
    const dateFormat = useSelector(getSettingsDateFormat);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);

    const [calendarOptionsModalOpen, setCalendarOptionsModalOpen] = useState(false);
    const onCalendarOptionsModalOpen = useCallback(() => setCalendarOptionsModalOpen(true), []);
    const onCalendarOptionsModalClose = useCallback(() => setCalendarOptionsModalOpen(false), []);

    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [showRefreshAlert, setShowRefreshAlert] = useState(false);

    const dispatch = useDispatch();

    const lastTimeRangeQueried = useRef("");

    useEffect(() => {
        document.title = "Calendar - Amazon Meetings";
    }, []);

    // Call findMeetings to populate the calendar
    useEffect(() => {
        const dateObj = parse(calendarDate, "yyyy-MM-dd", new Date());
        let startOfWeekTime = startOfWeek(dateObj).getTime();
        let endOfWeekTime = endOfWeek(dateObj).getTime();
        if (lastTimeRangeQueried.current !== `${email}-${startOfWeekTime}-${endOfWeekTime}` || triggerRefresh) {
            dispatch(findMeetings(email, startOfWeek(dateObj).getTime(), endOfWeek(dateObj).getTime(), -1));
            lastTimeRangeQueried.current = `${email}-${startOfWeekTime}-${endOfWeekTime}`;
            setTriggerRefresh(false);
        }
    }, [calendarDate, email, triggerRefresh, dispatch]);

    const onSetCalendarViewType = (viewType) => {
        dispatch(setCalendarViewType(viewType));
    };

    const onSetCalendarDate = (date) => {
        dispatch(setCalendarDate(date));
    };

    const onSetCalendarStatusFilter = (calendarStatusFilter) => {
        dispatch(setCalendarStatusFilter(calendarStatusFilter));
    };

    const onSetCalendarResponseFilter = (calendarResponseFilter) => {
        dispatch(setCalendarResponseFilter(calendarResponseFilter));
    };

    useEffect(() => {
        if (calendarViewDesktop && calendarViewMobile) {
            if (width > SCREEN_SIZE.PARTIAL_MOBILE_VIEW) {
                switch (calendarViewDesktop) {
                    case CALENDAR_VIEW_DESKTOP.AGENDA:
                        onSetCalendarViewType("agenda");
                        break;
                    case CALENDAR_VIEW_DESKTOP.DAY:
                        onSetCalendarViewType("day");
                        break;
                    case CALENDAR_VIEW_DESKTOP.WORK_WEEK:
                        onSetCalendarViewType("workweek");
                        break;
                    default:
                        onSetCalendarViewType("week");
                }
            } else {
                switch (calendarViewMobile) {
                    case CALENDAR_VIEW_MOBILE.AGENDA:
                        onSetCalendarViewType("agenda");
                        break;
                    case CALENDAR_VIEW_MOBILE.DAY:
                        onSetCalendarViewType("day");
                        break;
                    default:
                        onSetCalendarViewType("workweek");
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarViewDesktop, calendarViewMobile, width])

    return (
        <Responsive query="min-width" props={{
            screenSizeBreakpoint: {
                default: 360,
                "1200px": 1200,
                "900px": 900,
                "600px": 600
            }
        }}>
        {(responsiveProps) => (
            <Row width="100%" height="100%" spacingInset="medium" widths={["fill", "fit"]} alignmentVertical="top">
                <Calendar
                    meetingListLoaded={meetingListLoaded}
                    meetings={meetingList}
                    date={calendarDate}
                    calendarStatusFilter={calendarStatusFilter}
                    calendarResponseFilter={calendarResponseFilter}
                    onSetCalendarDate={onSetCalendarDate}
                    viewType={calendarViewType}
                    userEmail={email}
                    onCalendarOptionsModalOpen={onCalendarOptionsModalOpen}
                    screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                    setTriggerRefresh={setTriggerRefresh}
                    showRefreshAlert={showRefreshAlert}
                    setShowRefreshAlert={setShowRefreshAlert}
                    calendarMode={CALENDAR_MODE.DEFAULT}
                    timeFormat={timeFormat}
                    primaryTimezone={primaryTimezone}
                />
                {responsiveProps.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD ||
                    (calendarViewType === "week" && responsiveProps.screenSizeBreakpoint === 900) ?
                    <Modal
                        title="Calendar options"
                        open={calendarOptionsModalOpen}
                        onClose={onCalendarOptionsModalClose}
                        scrollContainer="viewport"
                        closeLabel="Close"
                    >
                        <CalendarOptions
                            viewType={calendarViewType}
                            date={calendarDate}
                            onSetCalendarViewType={onSetCalendarViewType}
                            onSetCalendarDate={onSetCalendarDate}
                            onSetCalendarStatusFilter={onSetCalendarStatusFilter}
                            onSetCalendarResponseFilter={onSetCalendarResponseFilter}
                            calendarStatusFilter={calendarStatusFilter}
                            calendarResponseFilter={calendarResponseFilter}
                            screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                            dateFormat={dateFormat}
                        />
                    </Modal>
                    :
                    <CalendarOptions
                        viewType={calendarViewType}
                        date={calendarDate}
                        onSetCalendarViewType={onSetCalendarViewType}
                        onSetCalendarDate={onSetCalendarDate}
                        onSetCalendarStatusFilter={onSetCalendarStatusFilter}
                        onSetCalendarResponseFilter={onSetCalendarResponseFilter}
                        calendarStatusFilter={calendarStatusFilter}
                        calendarResponseFilter={calendarResponseFilter}
                        screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                        dateFormat={dateFormat}
                    />
                }
                <MeetingsToaster />
            </Row>
        )}
        </Responsive>
    )
};

const newDateWithinWorkWeek = () => {
    const now = new Date();
    if (isSaturday(now)) {
        return addTime(now, {days: -1});
    }
    if (isSunday(now)) {
        return addTime(now, {days: 1});
    }
    return now;
};

export default CalendarDetails;
