import React from "react";

import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";

import "./meeting-polls.css";

import { convertEpoch } from "../meeting-scheduler/meeting-scheduler-utils";
import { renderPollPriorityIcon, renderPriorityIcon } from "../people/people-utils";
import { PRIORITY, STATUS, ONE_DAY_IN_MS, AVAILABILITY_STATUS } from "./poll-constants";
import { TIME_FORMAT } from "../shared/settings/settings-constants";

export const getPollStatusAccent = (status) => {
    switch (status) {
        case STATUS.OVERDUE:
            return "error";
        case STATUS.ONGOING:
            return "theme";
        case STATUS.CANCELED:
            return "warning";
        default: // Ready and complete status
            return "success";
    }
};

export const getAvailabilityStatusAccent = (status) => {
    switch (status) {
        case AVAILABILITY_STATUS.CONFLICT:
            return "warning";
        case AVAILABILITY_STATUS.OUT_OF_THE_OFFICE:
            return "theme";
        default:
            return "success";
    }
};

export const getPriorityNumber = (priority) => {
    switch (priority) {
        case "Required":
            return PRIORITY.REQUIRED.NUMBER;
        case "Optional":
            return PRIORITY.OPTIONAL.NUMBER;
        default:
            return "";
    }
};

export const showAttendee = (attendee) => {
    return (
        <Row key={attendee.email} spacing="xsmall">
            {renderPollPriorityIcon(attendee.priority, false, 16)}
            <span class="visually-hidden">{attendee.priority}</span>
            <Text type="b100">
                {attendee.displayName}
            </Text>
        </Row>
    );
};

export const showAttendeeInTooltip = (attendee) => {
    return (
        <Row key={attendee.email} spacing="small">
            {renderPriorityIcon(attendee.priority, true, 16)}
            <Text color="inverted">
                {attendee.displayName}
            </Text>
        </Row>
    );
};

export const formatDateTime = (time, format, timezone, timeFormat) => {
    return convertEpoch(time, format, timezone, timeFormat === TIME_FORMAT.TWELVE_HOUR);
};

// Convert epoch milliseconds to various time string formats
export const convertEpochMilliseconds = (epoch, conversion, timezone, is12HourTimeFormat, locale) => {
    return convertEpoch(epoch / 1000, conversion, timezone, is12HourTimeFormat, locale);
};

export const getCreationLabel = (createTime, organizerPreferredDate) => {
    let createdDate = new Date(convertEpochMilliseconds(createTime, "date"));
    let organizerLocalDate = new Date(organizerPreferredDate);

    if (createdDate - organizerLocalDate === 0) {
        return "Today";
    } else if (createdDate - organizerLocalDate === -ONE_DAY_IN_MS) {
        return "Yesterday";
    } else if (createdDate - organizerLocalDate === ONE_DAY_IN_MS) {
        return "Tomorrow";
    }

    return convertEpochMilliseconds(createTime, "shortDate");
};

export const getPollDisplayedTitle = (subject) => {
    return (
        <Column spacing="small">
            <Row wrap="down" width="250px">
                <Text className="line-clamp" type="h200" alignment="left">
                    {subject}
                </Text>
            </Row>
        </Column>
    );
};

export const compareAttendee = (attendee1, attendee2) => {
    if (attendee1.priority === attendee2.priority) {
        return ('' + attendee1.displayName).localeCompare(attendee2.displayName);
    } else {
        return getPriorityNumber(attendee1.priority) - getPriorityNumber(attendee2.priority);
    }
};

export const processAvailabilityList = (availabilityList, attendeeList) => {
    if (availabilityList && attendeeList) {
        availabilityList.forEach((availability) => {
            let numberOfAvailableAttendees = 0;
            attendeeList.forEach((attendee) => {
                if (attendee.availability && attendee.availability.some(
                    (attendeeAvailability) => JSON.stringify(attendeeAvailability) === JSON.stringify(availability))) {
                    numberOfAvailableAttendees++;
                }
            });
            availability.numberOfAvailableAttendees = numberOfAvailableAttendees;
        });
    }
    return availabilityList;
};

export const compareAvailabilityWithAvailableAttendees = (availability1, availability2) => {
    if (availability1.numberOfAvailableAttendees === availability2.numberOfAvailableAttendees) {
        return availability1.startTime - availability2.startTime;
    } else {
        return availability2.numberOfAvailableAttendees - availability1.numberOfAvailableAttendees;
    }
};

export const compareAvailability = (availability1, availability2) => {
    return availability1.startTime - availability2.startTime;
};

export const renderAttendeeNameList = (attendeeList) => {
    return (
        <React.Fragment>
            {attendeeList && attendeeList.map((attendee) =>
                <Column>
                    {showAttendeeInTooltip(attendee)}
                </Column>
            )}
        </React.Fragment>
    );
};

export const getCalendarHoldBody = (pollSubject, organizer) => {
    return `
        <style>
            .amazon-font {
                font-family: 'Amazon Ember';
                font-style: normal;
                font-weight: 400;
            }
            .button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 16px;
                width: 216px;
                background: #077398;
                border-radius: 4px;
                border: 0px;
                font-size: 16px;
                line-height: 24px;
                color: #FFFFFF;
            }
            button:hover {
                cursor: pointer;
            }
        </style>
        <svg width="500" height="4" style="margin-bottom: 48px;">
            <rect width="500" height="4" style="fill: #045C69;"/>
        </svg>
        <br>
        <svg width="85px" height="22px" viewBox="0 0 85 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin-bottom: 48px;">
            <title>Amazon Meetings</title>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Masthead-/-Light-/-Small-/-New-Logo" transform="translate(-56.000000, -7.000000)">
                    <g id="logo" transform="translate(56.000000, 7.000000)">
                        <path d="M21.4862058,4.8716186 C21.7831969,4.8716186 22.0847687,4.7597 22.3905397,4.53573488 L22.3905397,3.86383953 C22.1634064,3.80679302 21.9318196,3.77814186 21.6957792,3.77814186 C21.2023211,3.77814186 20.9554648,3.96923488 20.9554648,4.35129302 C20.9554648,4.51821163 21.0012732,4.64663023 21.0930172,4.73654884 C21.1847612,4.82672326 21.3158241,4.8716186 21.4862058,4.8716186 L21.4862058,4.8716186 Z M22.4691774,5.28002558 L22.4167522,4.9374907 C22.259604,5.07371163 22.0857867,5.17910698 21.8958091,5.25367674 C21.7057043,5.32824651 21.5167448,5.36572326 21.3289304,5.36572326 C21.0187058,5.36572326 20.7708316,5.2767 20.5851804,5.0989093 C20.3994019,4.9209907 20.30664,4.68282791 20.30664,4.38416512 C20.30664,4.06375814 20.4202702,3.80781628 20.6474034,3.61672326 C20.8745367,3.42575814 21.1803076,3.33021163 21.5648436,3.33021163 C21.8181894,3.33021163 22.0932942,3.36756047 22.3905397,3.44225814 L22.3905397,3.00750233 C22.3905397,2.77036279 22.3369693,2.60357209 22.2300831,2.50687442 C22.1228151,2.41030465 21.9405996,2.36195581 21.6828001,2.36195581 C21.3113705,2.36195581 20.9269618,2.43013023 20.5294469,2.56622326 L20.5294469,2.1049907 C20.6867223,2.02172326 20.8789903,1.95674651 21.1061235,1.9107 C21.3332567,1.86465349 21.5626804,1.84163023 21.79414,1.84163023 C22.2092148,1.84163023 22.5149858,1.92835116 22.7115801,2.10179302 C22.9081744,2.27523488 23.0065352,2.53987442 23.0065352,2.89558372 L23.0065352,5.28002558 L22.4691774,5.28002558 Z" id="Fill-3" fill="#1E4750"></path>
                        <path d="M28.216119,5.28007674 L28.216119,3.01407674 C28.216119,2.79458837 28.1745097,2.6320186 28.0915457,2.52662326 C28.0085816,2.42122791 27.8775187,2.36853023 27.6984843,2.36853023 C27.3794798,2.36853023 27.0583121,2.46740233 26.7351085,2.6650186 C26.7394349,2.69571628 26.7417253,2.72871628 26.7417253,2.76376279 L26.7417253,2.86915814 L26.7417253,5.28007674 L26.0929004,5.28007674 L26.0929004,3.01407674 C26.0929004,2.79458837 26.0514184,2.6320186 25.9683271,2.52662326 C25.8854903,2.42122791 25.7543001,2.36853023 25.5752657,2.36853023 C25.2431549,2.36853023 24.9242777,2.46522791 24.6185067,2.65836744 L24.6185067,5.28007674 L23.9696819,5.28007674 L23.9696819,1.94042558 L24.5136564,1.94042558 L24.5791879,2.2763093 C24.9985891,1.98647209 25.4070472,1.84155349 25.8045621,1.84155349 C26.210857,1.84155349 26.4860891,1.99747209 26.6302582,2.3091814 C27.0627657,1.99747209 27.4952732,1.84155349 27.9277807,1.84155349 C28.2292253,1.84155349 28.4606849,1.92725116 28.6224139,2.0983907 C28.7840157,2.26965814 28.8649439,2.51344884 28.8649439,2.82963488 L28.8649439,5.28007674 L28.216119,5.28007674 Z" id="Fill-4" fill="#1E4750"></path>
                        <path d="M30.7914154,4.8716186 C31.0884064,4.8716186 31.3899783,4.7597 31.695622,4.53573488 L31.695622,3.86383953 C31.468616,3.80679302 31.2370292,3.77814186 31.0009888,3.77814186 C30.5074034,3.77814186 30.2606744,3.96923488 30.2606744,4.35129302 C30.2606744,4.51821163 30.3064828,4.64663023 30.3982268,4.73654884 C30.4899708,4.82672326 30.6210337,4.8716186 30.7914154,4.8716186 L30.7914154,4.8716186 Z M31.774387,5.28002558 L31.7218346,4.9374907 C31.5646864,5.07371163 31.3909963,5.17910698 31.2008915,5.25367674 C31.0109139,5.32824651 30.8219543,5.36572326 30.63414,5.36572326 C30.3239154,5.36572326 30.0759139,5.2767 29.89039,5.0989093 C29.7046115,4.9209907 29.6118496,4.68282791 29.6118496,4.38416512 C29.6118496,4.06375814 29.7253525,3.80781628 29.952613,3.61672326 C30.1797463,3.42575814 30.4855172,3.33021163 30.8700531,3.33021163 C31.123399,3.33021163 31.3985037,3.36756047 31.695622,3.44225814 L31.695622,3.00750233 C31.695622,2.77036279 31.6421789,2.60357209 31.5351654,2.50687442 C31.4280247,2.41030465 31.2458091,2.36195581 30.9880097,2.36195581 C30.6165801,2.36195581 30.2321714,2.43013023 29.8346564,2.56622326 L29.8346564,2.1049907 C29.9919319,2.02172326 30.1841999,1.95674651 30.4113331,1.9107 C30.6383391,1.86465349 30.8677627,1.84163023 31.0993496,1.84163023 C31.5144244,1.84163023 31.8201954,1.92835116 32.0167897,2.10179302 C32.213384,2.27523488 32.3116175,2.53987442 32.3116175,2.89558372 L32.3116175,5.28002558 L31.774387,5.28002558 Z" id="Fill-5" fill="#1E4750"></path>
                        <polygon id="Fill-6" fill="#1E4750" points="33.0258466 5.28007674 33.0258466 4.77932093 34.932875 2.45422791 33.0915052 2.45422791 33.0915052 1.94042558 35.6537208 1.94042558 35.6537208 2.43440233 33.7204798 4.76614651 35.6733166 4.76614651 35.6733166 5.28007674"></polygon>
                        <path d="M37.7441228,4.85188256 C38.3601183,4.85188256 38.6680524,4.43682442 38.6680524,3.60696395 C38.6680524,2.78145233 38.3601183,2.3684407 37.7441228,2.3684407 C37.1281272,2.3684407 36.8200659,2.78145233 36.8200659,3.60696395 C36.8200659,4.43682442 37.1281272,4.85188256 37.7441228,4.85188256 M37.7441228,5.3788593 C37.2459566,5.3788593 36.8572216,5.2229407 36.5776632,4.91110349 C36.2981048,4.59939419 36.158262,4.16463837 36.158262,3.60696395 C36.158262,3.05363837 36.2981048,2.62118488 36.5776632,2.30921977 C36.8572216,1.99751047 37.2459566,1.84159186 37.7441228,1.84159186 C38.2421617,1.84159186 38.6308967,1.99751047 38.9105823,2.30921977 C39.1900135,2.62118488 39.3299835,3.05363837 39.3299835,3.60696395 C39.3299835,4.16463837 39.1900135,4.59939419 38.9105823,4.91110349 C38.6308967,5.2229407 38.2421617,5.3788593 37.7441228,5.3788593" id="Fill-7" fill="#1E4750"></path>
                        <path d="M42.3769663,5.28007674 L42.3769663,3.04707674 C42.3769663,2.81428605 42.3300127,2.6430186 42.2361055,2.53327442 C42.1421984,2.42353023 41.9947208,2.36853023 41.7938001,2.36853023 C41.4487103,2.36853023 41.1099828,2.48057674 40.7781265,2.70454186 L40.7781265,5.28007674 L40.1293016,5.28007674 L40.1293016,1.94042558 L40.673149,1.94042558 L40.7388076,2.3091814 C41.1537552,1.99747209 41.5905891,1.84155349 42.0493091,1.84155349 C42.3683136,1.84155349 42.6107163,1.92622791 42.7767717,2.09519302 C42.9426999,2.26428605 43.0257912,2.5091 43.0257912,2.82963488 L43.0257912,5.28007674 L42.3769663,5.28007674 Z" id="Fill-8" fill="#1E4750"></path>
                        <polygon id="Fill-9" fill="#1E4750" points="29.7025501 18.4306919 29.7025501 9.65844767 26.5801999 15.5818198 25.0910457 15.5818198 21.9525352 9.6424593 21.9525352 18.4306919 20.4473481 18.4306919 20.4473481 7.27630814 22.2728121 7.27630814 25.8594813 14.1170291 29.430372 7.27630814 31.2077373 7.27630814 31.2077373 18.4306919"></polygon>
                        <path d="M37.0683219,11.2359244 C36.3958293,11.2359244 35.8727231,11.4371221 35.4991302,11.8395174 C35.1251557,12.2419128 34.9118922,12.8401337 34.8585763,13.6341802 L38.8456871,13.6341802 C38.8562485,13.5484826 38.8617201,13.4088081 38.8617201,13.2157965 C38.8617201,12.5612965 38.7095344,12.0675756 38.4054177,11.7348895 C38.1010464,11.4024593 37.6551781,11.2359244 37.0683219,11.2359244 L37.0683219,11.2359244 Z M34.8585763,14.6965756 C34.8798263,15.6194244 35.1067051,16.3007849 35.5390853,16.740657 C35.9713383,17.1807849 36.6411587,17.4005291 37.5486737,17.4005291 C38.3918024,17.4005291 39.2512186,17.23425 40.1266677,16.901564 L40.1266677,18.0444128 C39.3581048,18.4629244 38.418524,18.6721802 37.3084341,18.6721802 C35.9953877,18.6721802 35.0026183,18.3075174 34.3301257,17.5776802 C33.6576332,16.8480988 33.3214506,15.7695872 33.3214506,14.3424012 C33.3214506,12.9798081 33.6576332,11.9199709 34.3301257,11.1635291 C35.0026183,10.4070872 35.9418174,10.0287384 37.148232,10.0287384 C38.1623787,10.0287384 38.9417575,10.3160174 39.4861138,10.8898081 C40.0305973,11.4639826 40.3027754,12.2768314 40.3027754,13.3283547 C40.3027754,13.8328198 40.260021,14.2888081 40.1746392,14.6965756 L34.8585763,14.6965756 Z" id="Fill-10" fill="#1E4750"></path>
                        <path d="M45.5387754,11.2359244 C44.8662829,11.2359244 44.3431766,11.4371221 43.9695838,11.8395174 C43.5956093,12.2419128 43.3823458,12.8401337 43.3290299,13.6341802 L47.3161407,13.6341802 C47.3267021,13.5484826 47.3321737,13.4088081 47.3321737,13.2157965 C47.3321737,12.5612965 47.179988,12.0675756 46.8758713,11.7348895 C46.5715,11.4024593 46.1256317,11.2359244 45.5387754,11.2359244 L45.5387754,11.2359244 Z M43.3290299,14.6965756 C43.3502799,15.6194244 43.5771587,16.3007849 44.0095389,16.740657 C44.4417919,17.1807849 45.1116123,17.4005291 46.0191272,17.4005291 C46.862256,17.4005291 47.7216722,17.23425 48.5971213,16.901564 L48.5971213,18.0444128 C47.8285584,18.4629244 46.8889775,18.6721802 45.7788877,18.6721802 C44.4658413,18.6721802 43.4730719,18.3075174 42.8005793,17.5776802 C42.1280868,16.8480988 41.7919042,15.7695872 41.7919042,14.3424012 C41.7919042,12.9798081 42.1280868,11.9199709 42.8005793,11.1635291 C43.4730719,10.4070872 44.412271,10.0287384 45.6188129,10.0287384 C46.6328323,10.0287384 47.4122111,10.3160174 47.9565674,10.8898081 C48.5010509,11.4639826 48.773229,12.2768314 48.773229,13.3283547 C48.773229,13.8328198 48.7304746,14.2888081 48.6450928,14.6965756 L43.3290299,14.6965756 Z" id="Fill-11" fill="#1E4750"></path>
                        <path d="M55.242238,18.2375779 C54.7191317,18.4412058 54.1265494,18.5434035 53.4647455,18.5434035 C51.9488698,18.5434035 51.1909955,17.7815895 51.1909955,16.2577058 L51.1909955,11.5256593 L49.7178743,11.5256593 L49.7178743,10.4954965 L51.2390943,10.302357 L51.4792066,7.98455465 L52.7762201,7.98455465 L52.7762201,10.2701244 L55.1781063,10.2701244 L55.1781063,11.5256593 L52.7762201,11.5256593 L52.7762201,16.1773802 C52.7762201,16.5744035 52.864274,16.8533686 53.040509,17.0142756 C53.2166168,17.1751826 53.5180614,17.255764 53.9452246,17.255764 C54.3828219,17.255764 54.8150749,17.2021709 55.242238,17.0947291 L55.242238,18.2375779 Z" id="Fill-12" fill="#1E4750"></path>
                        <path d="M56.8754723,10.27015 L58.4606969,10.27015 L58.4606969,18.4306151 L56.8754723,18.4306151 L56.8754723,10.27015 Z M57.676101,8.82160349 C57.3663855,8.82160349 57.1234738,8.73296395 56.9474933,8.5559407 C56.7713855,8.37891744 56.6832043,8.14548721 56.6832043,7.85577791 C56.6832043,7.5660686 56.7713855,7.33276628 56.9474933,7.15561512 C57.1234738,6.97859186 57.3663855,6.89008023 57.676101,6.89008023 C57.9854349,6.89008023 58.2284738,6.97859186 58.4045816,7.15561512 C58.5806894,7.33276628 58.6687433,7.5660686 58.6687433,7.85577791 C58.6687433,8.14548721 58.5806894,8.37891744 58.4045816,8.5559407 C58.2284738,8.73296395 57.9854349,8.82160349 57.676101,8.82160349 L57.676101,8.82160349 Z" id="Fill-13" fill="#1E4750"></path>
                        <path d="M66.3866033,18.4306919 L66.3866033,12.9743081 C66.3866033,12.4056337 66.2717006,11.9871221 66.0422769,11.7187733 C65.8125988,11.4506802 65.4523668,11.3163779 64.9614536,11.3163779 C64.1180704,11.3163779 63.2905928,11.5900988 62.4795299,12.1372849 L62.4795299,18.4306919 L60.8943054,18.4306919 L60.8943054,10.2702267 L62.2233847,10.2702267 L62.3834596,11.1715872 C63.397479,10.4097733 64.4650689,10.0287384 65.5859746,10.0287384 C66.3650988,10.0287384 66.9575539,10.235436 67.3634671,10.6484477 C67.7689985,11.0615872 67.9718278,11.659936 67.9718278,12.4431105 L67.9718278,18.4306919 L66.3866033,18.4306919 Z" id="Fill-14" fill="#1E4750"></path>
                        <path d="M73.6882433,16.998236 C74.3500472,16.998236 75.0065067,16.7515035 75.6577493,16.2577826 L75.6577493,12.0245733 C75.3375996,11.7779686 75.0117238,11.5981314 74.6810127,11.4854453 C74.3500472,11.3727593 73.9870157,11.3163523 73.5921729,11.3163523 C72.2044334,11.3163523 71.5105636,12.2660616 71.5105636,14.1653523 C71.5105636,15.0988174 71.6945606,15.8046081 72.0630636,16.281957 C72.4313121,16.7595616 72.972869,16.998236 73.6882433,16.998236 L73.6882433,16.998236 Z M75.6577493,17.3201779 C75.2947178,17.6315035 74.9050921,17.87005 74.488872,18.0363291 C74.0726519,18.202864 73.6507058,18.2858756 73.2239244,18.2858756 C72.5725546,18.2858756 71.9961325,18.1249686 71.4945307,17.8030267 C70.9925472,17.4810849 70.6002493,17.0117942 70.317637,16.394643 C70.034643,15.7777477 69.8934004,15.0560965 69.8934004,14.2296895 C69.8934004,12.94205 70.1975172,11.9199453 70.8060052,11.1635035 C71.4144933,10.4070616 72.2310277,10.0287128 73.2559903,10.0287128 C74.2166939,10.0287128 75.0544783,10.3615267 75.7698525,11.026643 L75.8978615,10.2702012 L77.2270681,10.2702012 L77.2270681,17.9317012 C77.2270681,19.2085965 76.8879588,20.1957826 76.210122,20.8933872 C75.5321579,21.590736 74.5689094,21.9395384 73.3198675,21.9395384 C72.3164094,21.9395384 71.3929888,21.7247826 70.54986,21.2957826 L70.54986,20.1529337 C71.5318136,20.4854919 72.412607,20.6518988 73.1918585,20.6518988 C74.0458031,20.6518988 74.6703241,20.4452012 75.0652942,20.0323174 C75.460137,19.61905 75.6577493,18.956364 75.6577493,18.0443872 L75.6577493,17.3201779 Z" id="Fill-15" fill="#1E4750"></path>
                        <path d="M83.4076115,16.2417174 C83.4076115,15.9841128 83.3249019,15.774857 83.1594828,15.61395 C82.9936819,15.4529151 82.6922373,15.2920081 82.25464,15.1311012 L80.893622,14.6160198 C79.7087118,14.1761477 79.1161295,13.42495 79.1161295,12.3625547 C79.1161295,11.6652058 79.3857627,11.1019035 79.9249019,10.6725198 C80.4637867,10.2435198 81.1711445,10.028764 82.0465936,10.028764 C82.8897223,10.028764 83.685134,10.200543 84.4324469,10.5438453 L84.4324469,11.6705779 C83.5997522,11.4024849 82.8311894,11.2681826 82.126631,11.2681826 C81.1017957,11.2681826 80.589378,11.6169849 80.589378,12.3143337 C80.589378,12.5935547 80.6746325,12.8132988 80.8455232,12.9743337 C81.0162867,13.1352407 81.3524693,13.312264 81.8543256,13.5054035 L83.1033675,13.9882523 C83.7331055,14.2352407 84.1866085,14.5275081 84.4643855,14.8655663 C84.7419079,15.2034965 84.8807328,15.6409384 84.8807328,16.1773802 C84.8807328,16.9393221 84.597866,17.5454733 84.0320052,17.9960895 C83.4661445,18.4468337 82.7134873,18.6720779 81.7742882,18.6720779 C80.7175142,18.6720779 79.7993106,18.4681942 79.0201864,18.0605547 L79.0201864,16.9338221 C80.02339,17.2665081 80.9521549,17.4327872 81.806354,17.4327872 C82.3292058,17.4327872 82.7271025,17.3309733 82.9992807,17.1269616 C83.2714588,16.9232058 83.4076115,16.6279965 83.4076115,16.2417174" id="Fill-16" fill="#1E4750"></path>
                        <path d="M5.01400749,0.38244186 L5.01400749,3.38569767 C5.01400749,3.59674419 4.8434985,3.76686047 4.63481587,3.76686047 L3.86752545,3.76686047 C3.65757036,3.76686047 3.48706138,3.59674419 3.48706138,3.38569767 L3.48706138,0.38244186 C3.48706138,0.171395349 3.65757036,-1.47685481e-14 3.86752545,-1.47685481e-14 L4.63481587,-1.47685481e-14 C4.8434985,-1.47685481e-14 5.01400749,0.171395349 5.01400749,0.38244186" id="Fill-17" fill="#1E4750"></path>
                        <path d="M13.2286085,0.38244186 L13.2286085,3.38569767 C13.2286085,3.59674419 13.0580996,3.76686047 12.8494169,3.76686047 L12.0821265,3.76686047 C11.8721714,3.76686047 11.7016624,3.59674419 11.7016624,3.38569767 L11.7016624,0.38244186 C11.7016624,0.171395349 11.8721714,-1.47685481e-14 12.0821265,-1.47685481e-14 L12.8494169,-1.47685481e-14 C13.0580996,-1.47685481e-14 13.2286085,0.171395349 13.2286085,0.38244186" id="Fill-18" fill="#1E4750"></path>
                        <path d="M16.7162425,2.49821512 L16.7162425,17.7703081 C16.7162425,18.1936802 16.3752246,18.5351919 15.9540419,18.5351919 L0.760928144,18.5351919 C0.341017964,18.5351919 0,18.1936802 0,17.7703081 L0,2.49821512 C0,2.07484302 0.341017964,1.7333314 0.760928144,1.7333314 L2.46856287,1.7333314 L2.46856287,3.38461047 C2.46856287,4.15972674 3.09588323,4.79030814 3.86699102,4.79030814 L4.63428144,4.79030814 C5.40538922,4.79030814 6.03143713,4.15972674 6.03143713,3.38461047 L6.03143713,1.7333314 L10.6835329,1.7333314 L10.6835329,3.38461047 C10.6835329,4.15972674 11.3108533,4.79030814 12.0819611,4.79030814 L12.8492515,4.79030814 C13.6190868,4.79030814 14.2464072,4.15972674 14.2464072,3.38461047 L14.2464072,1.7333314 L15.9540419,1.7333314 C16.3752246,1.7333314 16.7162425,2.07484302 16.7162425,2.49821512" id="Fill-19" fill="#1E4750"></path>
                        <path d="M4.05276946,11.902064 L5.20955838,11.902064 L4.62282934,9.91080814 L4.05276946,11.902064 Z M5.82097305,14.144657 L5.50705838,13.0316105 L3.75526946,13.0316105 L3.45789671,14.144657 L1.77227545,14.144657 L3.78835329,8.38871512 L5.54828593,8.38871512 L7.56449102,14.144657 L5.82097305,14.144657 Z" id="Fill-20" fill="#FFFFFF"></path>
                        <polygon id="Fill-21" fill="#FFFFFF" points="13.3176422 14.143493 13.3176422 10.5899814 12.3263997 12.8316791 10.9306437 12.8316791 9.9394012 10.5899814 9.9394012 14.143493 8.56833084 14.143493 8.56833084 8.38985349 10.2614596 8.38985349 11.6491991 11.5115512 13.0533533 8.38985349 14.6887126 8.38985349 14.6887126 14.143493"></polygon>
                    </g>
                </g>
            </g>
        </svg>
        <div class="amazon-font" style="width: 500px; font-size: 20px; line-height: 28px; color: #232F3E; margin-bottom: 24px;">
            This calendar hold is for Amazon Meetings Poll [${pollSubject}] created by ${organizer}.
        </div>
        <div class="amazon-font" style="width: 500px; font-size: 14px; line-height: 20px; color: #232F3E; margin-bottom: 24px;">
            This calendar hold will be canceled once a meeting time is finalized.
        </div>
        <svg width="500" height="1" style="margin-bottom: 24px;">
            <rect width="500" height="1" style="fill: #BBC0C1;"/>
        </svg>
        <div class="amazon-font" style="font-size: 12px; color: #232F3E; line-height: 18px; margin-bottom: 5px; display: flex; justify-content: center;">
            Amazon.com, Inc. &nbsp;|&nbsp; 410 Terry Avenue &nbsp;|&nbsp; Seattle, WA 98109-5210
            <br>
            &copy; 2023 Amazon.com, Inc. or its affiliates. All rights reserved.
        </div>
    `;
};

// Return a string for the time slot given a start and end time
export const getTimeSlotString = (startTime, endTime, timezone) => {
    const startDateString = convertEpoch(startTime, "longWeekdayDate", timezone);
    const startTimeString = convertEpoch(startTime, "time", timezone);

    const endDateString = convertEpoch(endTime, "longWeekdayDate", timezone);
    const endTimeString = convertEpoch(endTime, "time", timezone);

    const timeString = startDateString === endDateString ?
        `${startDateString} from ${startTimeString} to ${endTimeString}` :
        `${startDateString} at ${startTimeString} to ${endDateString} at ${endTimeString}`;

    return timeString;
};