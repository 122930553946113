import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import searchIconTokens from "@amzn/meridian-tokens/base/icon/search"
import searchFieldTokens from "@amzn/meridian-tokens/component/search-field"
import Button from "../button"
import Icon from "../icon"
import Theme, { Style } from "../theme"
import { memoizeTokenStyles, getTokenHelper } from "../../_utils/token"

const styles = memoizeTokenStyles(
  (t, { state, borderRadiusRight }) =>
    css({
      position: "relative",
      height: "100%",
      // When the button is *not* focused, draw a border to the left (when it is
      // focused a focus outline is shown and we don't want the border to render
      // on top of the outline).
      "&:not(:focus):before": {
        content: "''",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: t("borderLeftWidth"),
        backgroundColor: t("borderLeftColor", state),
      },
      borderRadius: `0 ${borderRadiusRight}px ${borderRadiusRight}px 0`,
      [`[dir="rtl"] &`]: {
        borderRadius: `${borderRadiusRight}px 0 0 ${borderRadiusRight}px`,
      },
    }),
  ["state", "borderRadiusRight"]
)

const getOverrideTokens = memoizeTokenStyles(
  (t, { active }) => {
    let activeColorTokens = {}
    if (active) {
      const fg = t("foregroundColorActive")
      const bgDefault = t("backgroundColorActiveDefault")
      const bgHover = t("backgroundColorActiveHover")
      const bgPressed = t("backgroundColorActivePressed")
      activeColorTokens = {
        buttonForegroundColorIconLightBackdropDefault: fg,
        buttonForegroundColorIconLightBackdropHover: fg,
        buttonForegroundColorIconLightBackdropPressed: fg,
        buttonBackgroundColorIconLightBackdropDefault: bgDefault,
        buttonBackgroundColorIconLightBackdropHover: bgHover,
        buttonBackgroundColorIconLightBackdropPressed: bgPressed,
        buttonForegroundColorIconDarkBackdropDefault: fg,
        buttonForegroundColorIconDarkBackdropHover: fg,
        buttonForegroundColorIconDarkBackdropPressed: fg,
        buttonBackgroundColorIconDarkBackdropDefault: bgDefault,
        buttonBackgroundColorIconDarkBackdropHover: bgHover,
        buttonBackgroundColorIconDarkBackdropPressed: bgPressed,
      }
    }
    return {
      buttonFocusOutlineOffset: 1,
      ...activeColorTokens,
    }
  },
  ["active"]
)

class SearchButton extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    borderRadiusRight: PropTypes.number,
    children: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }

  render() {
    const props = this.props
    const state = props.disabled
      ? "disabled"
      : props.active
      ? "active"
      : "empty"

    return (
      <Style
        tokens={searchFieldTokens}
        map={getTokenHelper("searchFieldSearchButton")}
      >
        {t => (
          <Theme tokens={getOverrideTokens(t, props)}>
            <Button
              type="icon"
              onClick={props.onClick}
              disabled={props.disabled}
              className={styles(t, {
                state,
                borderRadiusRight: props.borderRadiusRight,
              })}
              label={props.children}
            >
              <Icon tokens={searchIconTokens} />
            </Button>
          </Theme>
        )}
      </Style>
    )
  }
}

export default SearchButton
