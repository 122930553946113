import React, { useCallback, useRef, useState } from "react";

import Column from "@amzn/meridian/column";
import Link from "@amzn/meridian/link";
import Text from "@amzn/meridian/text";
import Heading from "@amzn/meridian/heading";
import Popover from "@amzn/meridian/popover";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";

import closeIconTokens from "@amzn/meridian-tokens/base/icon/close-small"

const PollKeyboardInstructions = (props) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const linkRef = useRef();

    const onClick = useCallback(() => setPopoverOpen(true), []);
    const onClose = useCallback(() => setPopoverOpen(false), []);

    return (
        <React.Fragment>
            <Link onClick={onClick} ref={linkRef}>
                Keyboard instructions
            </Link>
            <Popover
                anchorNode={linkRef.current}
                open={popoverOpen}
                onClose={onClose}
                position="right"
                alignment="center"
            >
                <Row widths={["fill", "fit"]} spacingInset="none none small none">
                    <Heading level={2} type="h100">
                        Navigate calendar with keyboard
                    </Heading>
                    <Column alignmentHorizontal="end">
                         <Button
                             type="icon"
                             size="small"
                             onClick={onClose}
                         >
                             <Icon tokens={closeIconTokens}>Close keyboard instructions</Icon>
                         </Button>
                    </Column>
                </Row>
                {props.showAmazonianCalendar ?
                    <Column spacing="small">
                        <Text type="b200">Use <b>Left/Right Arrow</b> keys to navigate between time slots on the grid.</Text>
                        <Text type="b200">Press <b>Space</b> key to select or unselect a time slot.</Text>
                    </Column>
                    :
                    <Column>
                        <Column spacing="small">
                            <Text type="b200">Use <b>Arrow</b> keys to navigate between time slots on the calendar.</Text>
                            <Text type="b200">Press <b>Space</b> key to select or unselect a time slot.</Text>
                        </Column>
                        <Column spacing="small">
                            <Text type="b200">Use <b>Tab</b> or <b>Shift+Tab</b> keys to navigate between meetings on the calendar.</Text>
                            <Text type="b200">Press <b>Enter</b> key to view meeting details.</Text>
                        </Column>
                    </Column>
                }
            </Popover>
        </React.Fragment>
    );
};

export default PollKeyboardInstructions;