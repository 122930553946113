import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { css } from "emotion"
import range from "lodash/range"
import addDebouncedEventListener from "../../_utils/add-debounced-event-listener"

const styles = ({
  slideMaxHeight,
  spacingInset,
  animate,
  motionDuration,
  motionFunction,
  width,
}) =>
  css({
    boxSizing: "border-box",
    height: slideMaxHeight ? slideMaxHeight + spacingInset * 2 : undefined,
    width,
    // Display block to make sure we fill all available width by default. This
    // will help avoid zero width carousels when using a container that's inline
    // by default (the children are absolutely positioned so they won't
    // contribute to the container's width).
    display: "block",
    position: "relative",
    overflow: "hidden",
    transition: animate
      ? `height ${motionDuration}ms ${motionFunction}`
      : undefined,
  })

const slideStyles = ({
  slidesInView,
  spacingHorizontal,
  slideWidthPercentage,
  spacingInset,
  index,
  slideIndex,
  animate,
  motionDuration,
  motionFunction,
}) => {
  const offset = index - slideIndex
  // Calculate the number of pixels we need to subtract from each slide in order
  // to fit them all in the carousel along with the requested horizontal
  // spacing. Round up to ensure that we don't clip subpixels off the last
  // slide.
  const spacingHorizontalWidthReduction = Math.ceil(
    (spacingHorizontal * (slidesInView - 1)) / slidesInView
  )
  // Positions the slide based on its offset
  const slidePosition = `${offset * 100}%`
  // Nudges based on the horizontal space between slides
  const spacingNudge = `${spacingHorizontal * offset}px`
  // Nudges based on the percentage width of each slide
  const percentageWidthNudge = slideWidthPercentage
    ? `${(((1 - slideWidthPercentage * slidesInView) * 100) / 2) *
        (1 / slideWidthPercentage)}%`
    : "0px"
  return css({
    boxSizing: "border-box",
    position: "absolute",
    top: spacingInset,
    left: spacingInset,
    width: slideWidthPercentage
      ? `calc((${slideWidthPercentage * 100}% - ${spacingInset *
          2}px) - ${spacingHorizontalWidthReduction}px)`
      : `calc(((100% - ${spacingInset *
          2}px) / ${slidesInView}) - ${spacingHorizontalWidthReduction}px)`,
    transform: `translateX(${slidePosition}) translateX(${spacingNudge}) translateX(${percentageWidthNudge})`,
    transition: animate
      ? `transform ${motionDuration}ms ${motionFunction}`
      : undefined,
  })
}

/**
 * This component handles the display and animation of content in carousel
 * slides. It uses a render prop for slides, which allows it to lazily render
 * them only when necessary for smoother animation and infinite lists.
 */
class CarouselController extends React.Component {
  static propTypes = {
    /**
     * The function used to render the carousel's container element. The only
     * argument passed to this function is an object with a "props" field. These
     * props should be passed through to the element.
     */
    renderContainer: PropTypes.func.isRequired,
    /**
     * The function used to render a slide element in the carousel. The only
     * argument passed to this function is an object with a "props" field and an
     * "index" field. The props should be passed through to the slide element.
     * The index can be used to determine what the contents of the slide should
     * be.
     */
    renderSlide: PropTypes.func.isRequired,
    /**
     * The index of the current slide.
     */
    slideIndex: PropTypes.number.isRequired,
    /**
     * The duration it should take to animate from one slide to the next in
     * milliseconds.
     */
    motionDuration: PropTypes.number,
    /**
     * The animation function to use when animating from one slide to the next.
     */
    motionFunction: PropTypes.string,
    /**
     * The percentage width each slide takes up relative to the carousel. Use this to
     * create a "peek" of prev/next slides. Use float between 0 ~ 1 e.g. 0.4 for 40%.
     */
    slideWidthPercentage: PropTypes.number,
    /**
     * The number of slides to show at a time.
     */
    slidesInView: PropTypes.number,
    /**
     * The horizontal "gutter" spacing between each slide.
     */
    spacingHorizontal: PropTypes.number,
    /**
     * The inset "padding" spacing for each slide.
     */
    spacingInset: PropTypes.number,
    /**
     * The width of the carousel. Defaults to "100%" so that it fills its
     * parent element.
     */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * A callback to run any time the carousel has finished transitioning to new
     * slide(s).
     */
    onTransitionEnd: PropTypes.func,
  }

  static defaultProps = {
    slidesInView: 1,
    spacingHorizontal: 0,
    spacingInset: 0,
    width: "100%",
  }

  // This timeout is used to delay certain actions (like pre-rendering the next
  // and previous carousel slides) until after the carousel has animated.
  postAnimationTimeout = null

  canAnimate = () =>
    Boolean(this.props.motionDuration && this.props.motionFunction)

  state = {
    // Track the slide index that we'll render separately from the user-provided
    // slide index. This allows us to intelligently pre-render slides when we're
    // not animating, and wait to add new slides or remove old slides until
    // animations have finished.
    renderIndex: this.props.slideIndex,
    canAnimate: this.canAnimate(),
    slideHeights: {},
  }

  componentDidMount() {
    // Clear all cached slide heights when the window is resized since the new
    // layout may cause the slide heights to change.
    this.removeWindowResizeListener = addDebouncedEventListener(
      window,
      "resize",
      () => this.setState({ slideHeights: {} })
    )
  }

  componentWillUnmount() {
    clearTimeout(this.postAnimationTimeout)
    this.removeWindowResizeListener()
  }

  // Set the height for a single slide
  updateSlideHeight = index => node => {
    /* eslint-disable-next-line react/no-find-dom-node */
    const domNode = ReactDOM.findDOMNode(node)
    if (
      domNode &&
      domNode.offsetHeight &&
      this.state.slideHeights[index] === undefined
    ) {
      // NOTE: offsetHeight height is used here (as opposed to
      // getBoundingClientRect) so that any css transforms (e.g. scale) are
      // ignored and the height used by the carousel is the actual height the
      // slide takes up in the layout.
      this.setState(state => ({
        slideHeights: { ...state.slideHeights, [index]: domNode.offsetHeight },
      }))
    }
  }

  componentDidUpdate(prevProps) {
    // Did the user change the index of the current slide?
    const indexChanged = this.props.slideIndex !== prevProps.slideIndex

    // Is the slide index that we're rendering falling too far behind the slide
    // index provided by the user? This can happen if the user is advancing
    // slides faster than we can animate
    const renderIndexBehind =
      indexChanged && this.state.renderIndex !== prevProps.slideIndex

    // Did a motion prop change?
    const motionChanged =
      this.props.motionDuration !== prevProps.motionDuration ||
      this.props.motionFunction !== prevProps.motionFunction

    // If the user is navigating too fast for us to keep up with animation then
    // turn animation off.
    if (renderIndexBehind && this.state.animate) {
      this.setState({ canAnimate: false })
    } else if (motionChanged) {
      // If the user changed a motion prop then re-evaluate our animatability
      this.setState({ canAnimate: this.canAnimate() })
    }

    // Once the current animation is complete then add/remove slides behind the
    // scenes and make sure animation is turned back on if it was turned off
    // (see above)
    if (indexChanged) {
      clearTimeout(this.postAnimationTimeout)
      this.postAnimationTimeout = setTimeout(
        () => {
          this.setState(
            {
              renderIndex: this.props.slideIndex,
              canAnimate: this.canAnimate(),
            },
            this.props.onTransitionEnd
          )
        },
        // Wait until 100ms after the animation has completed to ensure things
        // have settled down before rendering new slides behind-the-scenes.
        this.props.motionDuration + 100
      )
    }
  }

  render() {
    const props = this.props
    const state = this.state
    // Calculate the range of indices that will be in view. When the slides are
    // a percentage width of the carousel (i.e. the next/prev are "peeking"),
    // render yet another two slides so they'll be ready to enter the scene.
    const visibleIndices = range(
      props.slideIndex - (props.slideWidthPercentage ? 1 : 0),
      props.slideIndex +
        props.slidesInView +
        (props.slideWidthPercentage ? 1 : 0)
    )
    // Keep track of which slides are peeking so they are correctly set to aria-hidden
    const peekIndices = props.slideWidthPercentage
      ? [visibleIndices[0], visibleIndices[visibleIndices.length - 1]]
      : []
    // Animate if we can according to the current state *and* if the render
    // index is proximate to the slide index. If the render index is far away
    // from the slide index we want to skip animation to avoid animating across
    // unrendered slides.
    const animate =
      state.canAnimate && Math.abs(state.renderIndex - props.slideIndex) <= 1
    // If we've got animation turned on then intelligently render slides behind
    // the scenes for the smoothest animation, otherwise just render the visible
    // slides. In addition, when the slides are a percentage width of the carousel
    // (i.e. the next/prev are "peeking"), render yet another two slides so they'll
    // be ready to enter the scene.
    const renderIndices = animate
      ? range(
          state.renderIndex - (props.slideWidthPercentage ? 2 : 1),
          state.renderIndex +
            props.slidesInView +
            (props.slideWidthPercentage ? 2 : 1)
        )
      : visibleIndices
    // Render one slide before the first visible slide, all the visible slides,
    // and one slide after the last visible slide. This will allow slides just
    // out of view to animate smoothly into view when the slideIndex is changed.
    const slides = renderIndices.map(index =>
      props.renderSlide({
        index,
        props: {
          key: index,
          ref: this.updateSlideHeight(index),
          // Hide slides from screen readers if they are not visible
          // or only partially visible.
          "aria-hidden":
            visibleIndices.indexOf(index) === -1 ||
            peekIndices.indexOf(index) !== -1
              ? true
              : undefined,
          className: slideStyles({
            slidesInView: props.slidesInView,
            slideWidthPercentage: props.slideWidthPercentage,
            spacingHorizontal: props.spacingHorizontal,
            spacingInset: props.spacingInset,
            slideIndex: props.slideIndex,
            motionFunction: props.motionFunction,
            motionDuration: props.motionDuration,
            animate,
            index,
          }),
        },
      })
    )
    // Render at the max height of any of the visible slides
    const slideMaxHeight =
      visibleIndices.reduce(
        (result, index) =>
          state.slideHeights[index] > result
            ? state.slideHeights[index]
            : result,
        0
      ) || undefined
    return props.renderContainer({
      props: {
        className: styles({
          spacingInset: props.spacingInset,
          motionFunction: props.motionFunction,
          motionDuration: props.motionDuration,
          width: props.width,
          animate,
          slideMaxHeight,
        }),
        children: slides,
      },
    })
  }
}

export default CarouselController
