import { call, takeEvery, put } from "redux-saga/effects";
import * as Actions from "../actions/action-types";
import { addRoom, saveBuildings, saveFloors, setLoadFloorsStatus } from "../actions";
import {
    getBuildingWithQueryString,
    getConfRoomWithQueryString
} from "../../../api/apig/ras";
import { roomListToRoomOptions, sortBuildingsByBuildingCode } from "../locations-utils";
import { isErrorResponse } from "../../../api/apig/apig-utils";
import { getErrorToast } from "../../toasts/toast-utils";
import { showToast } from "../../toasts/actions";
import { ERRORS } from "../locations-constants";

export function* loadBuildingsApi(action) {
    const response = yield call(getBuildingWithQueryString, action.queryStringMap);
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast("An error occurred when retrieving the list of buildings.");
        yield put(showToast(toast));
        return;
    }
    const buildingList = sortBuildingsByBuildingCode(JSON.parse(response));
    yield put(saveBuildings(buildingList));
}

export function* watchLoadBuildings() {
    yield takeEvery(Actions.LOAD_BUILDINGS, loadBuildingsApi);
}

export function* loadFloorsApi(action) {
    yield put(setLoadFloorsStatus(false));

    const response = yield call(getConfRoomWithQueryString, action.queryStringMap);
    let roomList = JSON.parse(response);
    let floorList;
    if (isErrorResponse(roomList)) {
        const toast = getErrorToast(`An error occurred when retrieving floor data for building ${action.queryStringMap.buildingCode}.`);
        yield put(showToast(toast));
        roomList = ERRORS.INVALID_ROOM_LIST;
        floorList = ERRORS.INVALID_FLOOR_LIST;
    } else {
        const floorSet = new Set(roomList.map((room) => room.floor));
        floorList = [...floorSet].sort((a, b) => a - b);
    }
    yield put(saveFloors(floorList, roomList, action.queryStringMap.buildingCode));
}

export function* watchLoadFloors() {
    yield takeEvery(Actions.LOAD_FLOORS, loadFloorsApi);
}

export function* addRoomByEmailApi(action) {
    const response = yield call(getConfRoomWithQueryString, {email: action.room.email});
    const roomList = JSON.parse(response);
    if (isErrorResponse(roomList)) {
        const toast = getErrorToast(`An error occurred when retrieving room with email ${action.room.email}.`);
        yield put(showToast(toast));
        return;
    }
    const roomOptions = roomListToRoomOptions(roomList);
    if (roomOptions.length) {
        const room = roomOptions[0];
        room.status = action.room.response;
        yield put(addRoom(room));
    }
}

export function* watchAddRoomByEmail() {
    yield takeEvery(Actions.ADD_ROOM_BY_EMAIL, addRoomByEmailApi);
}

const sagas = [
    watchLoadBuildings(),
    watchLoadFloors(),
    watchAddRoomByEmail(),
];

export default sagas;