import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { getCreatingMeetingStateOOTO } from "../../../sagas/selector";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Theme from "@amzn/meridian/theme";
import Tooltip from "@amzn/meridian/tooltip";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";

import tealLightTokens from "@amzn/meridian-tokens/theme/teal-light";
import arrowLeftLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-left-large";
import arrowRightLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-right-large";

import "../../shared/styles/navigation-buttons.css";
import { ReactComponent as SendMailIcon } from "../../../assets/icons/shared/send_mail.svg";

import { ALERTS, STEP } from "../ooto-scheduler-constants";
import { MAX_PAYLOAD_SIZE } from "../../shared/shared-constants";
import { generateComponentId, getObjectInBytes } from "../../shared/shared-utils";
import {
    generateOOTOBody,
    generateOOTOSubject,
    getFormattedMeetingTimeInfo,
    removePointOfContactDataFromBody,
    addPointOfContactDataToBody,
    getFormattedSubjectTimeInfo,
    replaceTimeInfoAlertTitle,
} from "../ooto-scheduler-utils";

const OOTONavigationButtons = (props) => {
    const isCreatingMeeting = useSelector(getCreatingMeetingStateOOTO);
    const identity = props.identity;
    const meeting = props.meeting;
    const timezones = props.timezones;
    const onUpdateMeeting = props.onUpdateMeeting;

    const currentStep = props.currentStep;
    const [prevStep, setPrevStep] = useState(undefined);
    const steps = props.steps;
    const labels = props.labels;
    const stepIndex = steps.indexOf(currentStep);
    const onChangeStep = props.onChangeStep;

    const prevStepLabel = stepIndex > 0 && labels[stepIndex - 1]; // name of prev step for tooltip
    const nextStepLabel = stepIndex < steps.length - 1 && labels[stepIndex + 1]; // name of next step for tooltip
    const nextButtonId = generateComponentId("button", currentStep);

    const setIsSubjectEmpty = props.setIsSubjectEmpty;
    const reviewAlerts = props.reviewAlerts;
    const setReviewAlerts = props.setReviewAlerts;

    const isAttendeeLoading = props.isAttendeeLoading;
    const isDatePickerError = props.isDatePickerError;
    const timezoneValue = props.timezoneValue;

    const disableNextButton = isAttendeeLoading || isDatePickerError || !timezoneValue;

    const [showNextTooltip, setShowNextTooltip] = useState(undefined); // handle tooltip hovering
    const [isLastStepLoading, setIsLastStepLoading] = [props.isLastStepLoading, props.setIsLastStepLoading]; // disable send invite ooto button

    const getMessageBodyTimeFormatChanged = () => props.oldMessageBodyTimeInfo && meeting.body.value.indexOf(props.oldMessageBodyTimeInfo) === -1;
    const getMessageSubjectTimeFormatChanged = () => !props.queryParams.subject && props.oldMessageSubjectTimeInfo && meeting.subject.indexOf(props.oldMessageSubjectTimeInfo) === -1;

    const isMeetingAgendaValid = () => {
        // If subject is empty, display error and do not proceed to next step
        if (!meeting.subject || !meeting.subject.length || meeting.subject === "") {
            setIsSubjectEmpty(true);
            return false;
        } else {
            setIsSubjectEmpty(false);
            return true;
        }
    };

    const onReviewButton = () => {
        setIsLastStepLoading(true); // During final step, set it to loading to prevent double click
        const meetingSize = getObjectInBytes(meeting);

        // do not send the request if the payload exceeds the limit
        if (meetingSize > MAX_PAYLOAD_SIZE) {
            // show error alert on the review page
            const payloadAlert = JSON.parse(JSON.stringify(ALERTS.OOTO_MAX_PAYLOAD_SIZE_EXCEEDED));
            payloadAlert.value = payloadAlert.value.replace("request", `request (${(meetingSize / 1024 / 1024).toFixed(2)}MB)`); // show request size in MB

            setReviewAlerts(reviewAlerts
                .filter((alert) => alert.id !== ALERTS.OOTO_MAX_PAYLOAD_SIZE_EXCEEDED.id) // remove existing payload size alerts
                .concat([payloadAlert]));

            // re-enable the button after 1 sec so it's usable after alert is fixed
            window.setTimeout(() => {
                setIsLastStepLoading(false);
            }, 1000); // 1 sec timer

            return;
        }

        props.onCreateMeeting();
    };

    const onBackClick = () => {
        props.setMessageBodyTimeFormatChanged(getMessageBodyTimeFormatChanged());
        props.setMessageSubjectTimeFormatChanged(getMessageSubjectTimeFormatChanged());

        if (steps[stepIndex] === STEP.OOTO_AGENDA_AND_REVIEW) {
            // clean up the point of contact when go back to setup the new time.
            let meetingBody = removePointOfContactDataFromBody(meeting.body.value);
            onUpdateMeeting({
                body: {
                    ...meeting.body,
                    value: meetingBody
                }
            });
        }
        if (stepIndex !== 0) {
            onChangeStep(steps[stepIndex - 1]);
        }
    };

    const onNextClick = () => {
        if (currentStep === STEP.OOTO_AGENDA_AND_REVIEW) {
            if (isMeetingAgendaValid()) { // Only send meeting if meeting summary is valid
                onReviewButton();
            }
        } else {
            if (currentStep === STEP.NOTIFY_PEOPLE) {
                let meetingBody, meetingSubject;
                const newMessageBodyTimeInfo = getFormattedMeetingTimeInfo(meeting, timezones);
                const newMessageSubjectTimeInfo = getFormattedSubjectTimeInfo(meeting, timezones);
                if (!props.oldMessageBodyTimeInfo) {
                    // if no message body setup will create a new body with the time info.
                    meetingBody = generateOOTOBody(identity, meeting, timezones);
                } else {
                    meeting.body.value = removePointOfContactDataFromBody(meeting.body.value);
                    meetingBody = props.messageBodyTimeFormatChanged ? meeting.body.value : meeting.body.value.replace(props.oldMessageBodyTimeInfo, newMessageBodyTimeInfo);
                }

                if (!props.oldMessageSubjectTimeInfo) {
                    // if no message subject setup will create a new subject with the time info.
                    meetingSubject = props.queryParams?.subject ? props.queryParams?.subject : generateOOTOSubject(identity, meeting, timezones);
                } else {
                    meetingSubject = props.messageSubjectTimeFormatChanged ? meeting.subject : meeting.subject.replace(props.oldMessageSubjectTimeInfo, newMessageSubjectTimeInfo);
                }

                // add the point of contact
                meetingBody = addPointOfContactDataToBody(meetingBody, props.pointOfContact);

                onUpdateMeeting({
                    subject: meetingSubject,
                    body: {
                        ...meeting.body,
                        value: meetingBody
                    }
                });
                props.setOldMessageBodyTimeInfo(newMessageBodyTimeInfo);
                props.setOldMessageSubjectTimeInfo(newMessageSubjectTimeInfo);
            }
            if (currentStep === STEP.OOTO_AGENDA_AND_REVIEW) {
                // add the time format alert on the agenda and review page
                if (getMessageBodyTimeFormatChanged() || getMessageSubjectTimeFormatChanged()) {
                    const timeInfoAlert = replaceTimeInfoAlertTitle(props.timeInfoAlert, getMessageBodyTimeFormatChanged(), getMessageSubjectTimeFormatChanged());
                    setReviewAlerts(reviewAlerts.filter((alert) => alert.id !== ALERTS.OOTO_INCORRECT_TIME_INFO.id).concat([timeInfoAlert]));
                } else {
                    setReviewAlerts(reviewAlerts.filter((alert) => alert.id !== ALERTS.OOTO_INCORRECT_TIME_INFO.id)); // remove the existing alerts
                }
            }
            onChangeStep(steps[stepIndex + 1]);
        }
    };

    // Add a timer to the 'next' button on the agenda and review step to prevent double clicking
    useEffect(() => {
        if (prevStep !== currentStep && !isLastStepLoading) {
            setPrevStep(currentStep);
            setIsLastStepLoading(currentStep === STEP.OOTO_AGENDA_AND_REVIEW);
            if (currentStep === STEP.OOTO_AGENDA_AND_REVIEW) {
                // disable tooltip when next button is disabled/loading
                setShowNextTooltip(false);
            }

            window.setTimeout(() => {
                setIsLastStepLoading(false);
            }, 1000); // 1 sec timer
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevStep, setPrevStep, currentStep, isLastStepLoading]);

    // When the loader is being displayed waiting for a response from the API and the user navigates back & forth - continue to display the loader
    // Once the API response is an error, remove the loader and display the send mail icon to retry
    useEffect(() => {
        if (!isCreatingMeeting && isLastStepLoading && currentStep === STEP.OOTO_AGENDA_AND_REVIEW) {
            window.setTimeout(() => {
                setIsLastStepLoading(false);
            }, 1000); // 1 sec timer
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, isLastStepLoading, isCreatingMeeting]);

    return (
        <section aria-label="Step navigation">
            <Theme tokens={tealLightTokens}>
                <Column spacing={"xsmall"}>
                    <Row>
                        {(isAttendeeLoading || !timezoneValue) &&
                            <Loader size="small" />
                        }
                        {prevStepLabel &&
                            <Tooltip
                                position="bottom"
                                title={<div>Back to <b>{prevStepLabel}</b></div>}
                            >
                                <Button
                                    label={`Back to ${prevStepLabel}`}
                                    type="secondary"
                                    circular="true"
                                    onClick={onBackClick}
                                >
                                    <Icon tokens={arrowLeftLargeTokens}>Back to {prevStepLabel}</Icon>
                                </Button>
                            </Tooltip>
                        }
                        {nextStepLabel ?
                            <Tooltip
                                position="bottom"
                                title={<div>Proceed to <b>{nextStepLabel}</b></div>}
                                open={showNextTooltip}
                            >
                                <Button
                                    id={nextButtonId}
                                    label={`Proceed to ${nextStepLabel}`}
                                    type="primary"
                                    circular="true"
                                    onClick={onNextClick}
                                    disabled={disableNextButton}
                                    onFocus={() => setShowNextTooltip(true)}
                                    onBlur={() => setShowNextTooltip(false)}
                                    onMouseEnter={() => setShowNextTooltip(true)}
                                    onMouseLeave={() => setShowNextTooltip(false)}
                                >
                                    <Icon tokens={arrowRightLargeTokens} />
                                </Button>
                            </Tooltip>
                            :
                            <Tooltip
                                position="bottom"
                                title={<b>Send invite</b>}
                                open={isLastStepLoading ? false : showNextTooltip}
                            >
                                <button
                                    id={nextButtonId}
                                    className="sendInviteButton"
                                    onClick={onNextClick}
                                    aria-label="Send invite"
                                    disabled={isLastStepLoading}
                                    onFocus={() => setShowNextTooltip(true)}
                                    onBlur={() => setShowNextTooltip(false)}
                                    onMouseEnter={() => setShowNextTooltip(true)}
                                    onMouseLeave={() => setShowNextTooltip(false)}
                                >
                                    {isLastStepLoading ?
                                        <div style={{paddingLeft: "12px"}}>
                                            <Loader size="small" />
                                        </div>
                                        :
                                        <SendMailIcon />
                                    }
                                </button>
                            </Tooltip>
                        }
                    </Row>
                    {isLastStepLoading && currentStep === STEP.OOTO_AGENDA_AND_REVIEW && isCreatingMeeting &&
                        <Row spacingInset={"200 none none none"}>
                            <Alert type={ALERTS.OOTO_PROCESSING_CREATE_MEETING.type} size="small">
                                <Text id={ALERTS.OOTO_PROCESSING_CREATE_MEETING.id}>
                                    {ALERTS.OOTO_PROCESSING_CREATE_MEETING.value}
                                </Text>
                            </Alert>
                        </Row>
                    }
                </Column>
            </Theme>
        </section>
    );
};

export default OOTONavigationButtons;