import React from "react";

import "./styles/meetings-rating.css";

export const renderAriaLabel = (rating, userRating) => {
    let buttonState = `${rating === userRating ? `selected` : `unselected`}`;
    switch (rating) {
        case 1:
            return `Rage (Rating 1/5) ${buttonState}`;
        case 2:
            return `Frowning (Rating 2/5) ${buttonState}`;
        case 3:
            return `Expressionless (Rating 3/5) ${buttonState}`;
        case 4:
            return `Smiley (Rating 4/5) ${buttonState}`;
        case 5:
        default:
            return `Heart eyes (Rating 5/5) ${buttonState}`;
    }
};

export const renderRatingSvg = (rating, userRating, isModal) => {
    const height = isModal ? 50 : 36;
    const width = isModal ? 46 : 33;

    switch (rating) {
        case 1:
            return (
                <svg width={width} height={height} viewBox="0 0 33 36" style={{display: "block"}}>
                    <title>Rage</title>
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="rated_one_light" transform="translate(-1.000000, -1.000000)">
                            <g className={rating === userRating ? "selected" : "unselected"} id="rated_one" transform="translate(1.000000, 1.000000)">
                                <g id="face">
                                    <g>
                                        <path d="M10,0.844383822 L10,8.15668367 C10,8.6103665 9.62554427,9 9.16182874,9 L7.85994194,9 C7.39731495,9 7,8.6103665 7,8.15668367 L7,0.844383822 C7,0.389633495 7.39731495,0 7.85994194,0 L9.16182874,0 C9.62554427,0 10,0.389633495 10,0.844383822" id="Fill-1"></path>
                                        <path d="M26,0.844383822 L26,8.15668367 C26,8.6103665 25.6244557,9 25.1607402,9 L23.8599419,9 C23.3962264,9 23,8.6103665 23,8.15668367 L23,0.844383822 C23,0.389633495 23.3962264,0 23.8599419,0 L25.1607402,0 C25.6244557,0 26,0.389633495 26,0.844383822" id="Fill-3"></path>
                                        <path d="M33,9.9671505 L33,29.0328495 C33,32.8647823 29.8646734,36 26.0326076,36 L6.96624648,36 C3.13418064,36 0,32.8647823 0,29.0328495 L0,9.9671505 C0,6.71619557 2.25179706,3.97517189 5.27138244,3.23262032 L5.27138244,8.08670741 C5.27138244,9.64171123 6.54797375,10.9194041 8.10417752,10.9194041 L9.47473695,10.9194041 C11.0309407,10.9194041 12.307532,9.64171123 12.307532,8.08670741 L12.307532,3 L20.691322,3 L20.691322,8.08670741 C20.691322,9.64171123 21.9690593,10.9194041 23.5241171,10.9194041 L24.8946765,10.9194041 C26.4508803,10.9194041 27.7286176,9.64171123 27.7286176,8.08670741 L27.7286176,3.23262032 C30.747057,3.97517189 33,6.71619557 33,9.9671505" id="Fill-4"></path>
                                    </g>
                                </g>
                                <g id="emotion" strokeLinecap="round">
                                    <g transform="translate(6.000000, 15.000000)">
                                        <line x1="-1.02318154e-12" y1="0" x2="8.43509789" y2="4.91357266" id="Path" stroke="#232F3E" strokeWidth="2"></line>
                                        <path d="M3.43509789,11.5 C5.29476744,8.63504107 7.08981822,8.52686543 8.82025022,11.1754731 C9.93183283,12.8768669 10.6899572,9.59640666 12.8756086,9.46183653 C13.6868179,9.41189054 14.8733143,10.0912784 16.4350979,11.5" id="Path" stroke="#232F3E" strokeWidth="2"></path>
                                        <line x1="12" y1="0" x2="20.4350979" y2="4.91357266" id="Path" stroke="#232F3E" strokeWidth="2" transform="translate(16.217549, 2.456786) scale(-1, 1) translate(-16.217549, -2.456786) "></line>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            );
        case 2:
            return (
                <svg width={width} height={height} viewBox="0 0 33 36" style={{display: "block"}}>
                    <title>Frowning</title>
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="rated_two_light" transform="translate(-1.000000, -1.000000)">
                            <g className={rating === userRating ? "selected" : "unselected"} id="rated_two" transform="translate(1.000000, 1.000000)">
                                <g id="face">
                                    <g>
                                        <path d="M10,0.844383822 L10,8.15668367 C10,8.6103665 9.62554427,9 9.16182874,9 L7.85994194,9 C7.39731495,9 7,8.6103665 7,8.15668367 L7,0.844383822 C7,0.389633495 7.39731495,0 7.85994194,0 L9.16182874,0 C9.62554427,0 10,0.389633495 10,0.844383822" id="Fill-1"></path>
                                        <path d="M26,0.844383822 L26,8.15668367 C26,8.6103665 25.6244557,9 25.1607402,9 L23.8599419,9 C23.3962264,9 23,8.6103665 23,8.15668367 L23,0.844383822 C23,0.389633495 23.3962264,0 23.8599419,0 L25.1607402,0 C25.6244557,0 26,0.389633495 26,0.844383822" id="Fill-3"></path>
                                        <path d="M33,9.9671505 L33,29.0328495 C33,32.8647823 29.8646734,36 26.0326076,36 L6.96624648,36 C3.13418064,36 0,32.8647823 0,29.0328495 L0,9.9671505 C0,6.71619557 2.25179706,3.97517189 5.27138244,3.23262032 L5.27138244,8.08670741 C5.27138244,9.64171123 6.54797375,10.9194041 8.10417752,10.9194041 L9.47473695,10.9194041 C11.0309407,10.9194041 12.307532,9.64171123 12.307532,8.08670741 L12.307532,3 L20.691322,3 L20.691322,8.08670741 C20.691322,9.64171123 21.9690593,10.9194041 23.5241171,10.9194041 L24.8946765,10.9194041 C26.4508803,10.9194041 27.7286176,9.64171123 27.7286176,8.08670741 L27.7286176,3.23262032 C30.747057,3.97517189 33,6.71619557 33,9.9671505" id="Fill-4"></path>
                                    </g>
                                </g>
                                <g id="emotion">
                                    <g transform="translate(10.000000, 14.000000)">
                                        <circle id="Oval" fill="#232F3E" cx="2.5" cy="2.5" r="2.5"></circle>
                                        <circle id="Oval" fill="#232F3E" cx="10.5" cy="2.5" r="2.5"></circle>
                                        <path d="M0,13 C1.32296232,10.8795891 3.48962899,9.81938361 6.5,9.81938361 C10.883007,9.81938361 13,13 13,13" id="Path" stroke="#232F3E" strokeWidth="2" strokeLinecap="round"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            );
        case 3:
            return (
                <svg width={width} height={height} viewBox="0 0 33 36" style={{display: "block"}}>
                    <title>Expressionless</title>
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="rated_three_light" transform="translate(-1.000000, -1.000000)">
                            <g className={rating === userRating ? "selected" : "unselected"} id="rated_three" transform="translate(1.000000, 1.000000)">
                                <g id="face">
                                    <g>
                                        <path d="M10,0.844383822 L10,8.15668367 C10,8.6103665 9.62554427,9 9.16182874,9 L7.85994194,9 C7.39731495,9 7,8.6103665 7,8.15668367 L7,0.844383822 C7,0.389633495 7.39731495,0 7.85994194,0 L9.16182874,0 C9.62554427,0 10,0.389633495 10,0.844383822" id="Fill-1"></path>
                                        <path d="M26,0.844383822 L26,8.15668367 C26,8.6103665 25.6244557,9 25.1607402,9 L23.8599419,9 C23.3962264,9 23,8.6103665 23,8.15668367 L23,0.844383822 C23,0.389633495 23.3962264,0 23.8599419,0 L25.1607402,0 C25.6244557,0 26,0.389633495 26,0.844383822" id="Fill-3"></path>
                                        <path d="M33,9.9671505 L33,29.0328495 C33,32.8647823 29.8646734,36 26.0326076,36 L6.96624648,36 C3.13418064,36 0,32.8647823 0,29.0328495 L0,9.9671505 C0,6.71619557 2.25179706,3.97517189 5.27138244,3.23262032 L5.27138244,8.08670741 C5.27138244,9.64171123 6.54797375,10.9194041 8.10417752,10.9194041 L9.47473695,10.9194041 C11.0309407,10.9194041 12.307532,9.64171123 12.307532,8.08670741 L12.307532,3 L20.691322,3 L20.691322,8.08670741 C20.691322,9.64171123 21.9690593,10.9194041 23.5241171,10.9194041 L24.8946765,10.9194041 C26.4508803,10.9194041 27.7286176,9.64171123 27.7286176,8.08670741 L27.7286176,3.23262032 C30.747057,3.97517189 33,6.71619557 33,9.9671505" id="Fill-4"></path>
                                    </g>
                                </g>
                                <g id="emotion">
                                    <g transform="translate(10.000000, 14.000000)">
                                        <circle id="Oval" fill="#232F3E" cx="2.5" cy="2.5" r="2.5"></circle>
                                        <circle id="Oval" fill="#232F3E" cx="10.5" cy="2.5" r="2.5"></circle>
                                        <path d="M3,13 C7.66666667,13 10,13 10,13" id="Path" stroke="#232F3E" strokeWidth="2" strokeLinecap="round"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            );
        case 4:
            return (
                <svg width={width} height={height} viewBox="0 0 33 36" style={{display: "block"}}>
                    <title>Smiley</title>
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="rated_four_light" transform="translate(-1.000000, -1.000000)">
                            <g className={rating === userRating ? "selected" : "unselected"} id="rated_four" transform="translate(1.000000, 1.000000)">
                                <g id="face">
                                    <g>
                                        <path d="M10,0.844383822 L10,8.15668367 C10,8.6103665 9.62554427,9 9.16182874,9 L7.85994194,9 C7.39731495,9 7,8.6103665 7,8.15668367 L7,0.844383822 C7,0.389633495 7.39731495,0 7.85994194,0 L9.16182874,0 C9.62554427,0 10,0.389633495 10,0.844383822" id="Fill-1"></path>
                                        <path d="M26,0.844383822 L26,8.15668367 C26,8.6103665 25.6244557,9 25.1607402,9 L23.8599419,9 C23.3962264,9 23,8.6103665 23,8.15668367 L23,0.844383822 C23,0.389633495 23.3962264,0 23.8599419,0 L25.1607402,0 C25.6244557,0 26,0.389633495 26,0.844383822" id="Fill-3"></path>
                                        <path d="M33,9.9671505 L33,29.0328495 C33,32.8647823 29.8646734,36 26.0326076,36 L6.96624648,36 C3.13418064,36 0,32.8647823 0,29.0328495 L0,9.9671505 C0,6.71619557 2.25179706,3.97517189 5.27138244,3.23262032 L5.27138244,8.08670741 C5.27138244,9.64171123 6.54797375,10.9194041 8.10417752,10.9194041 L9.47473695,10.9194041 C11.0309407,10.9194041 12.307532,9.64171123 12.307532,8.08670741 L12.307532,3 L20.691322,3 L20.691322,8.08670741 C20.691322,9.64171123 21.9690593,10.9194041 23.5241171,10.9194041 L24.8946765,10.9194041 C26.4508803,10.9194041 27.7286176,9.64171123 27.7286176,8.08670741 L27.7286176,3.23262032 C30.747057,3.97517189 33,6.71619557 33,9.9671505" id="Fill-4"></path>
                                    </g>
                                </g>
                                <g id="emotion">
                                    <g transform="translate(10.000000, 14.000000)">
                                        <circle id="Oval" fill="#232F3E" cx="2.5" cy="2.5" r="2.5"></circle>
                                        <circle id="Oval" fill="#232F3E" cx="10.5" cy="2.5" r="2.5"></circle>
                                        <path d="M0.107324457,13.3761055 C1.43028678,11.2556946 3.59695345,10.1954892 6.60732446,10.1954892 C10.9903315,10.1954892 13.1073245,13.3761055 13.1073245,13.3761055" id="Path" stroke="#232F3E" strokeWidth="2" strokeLinecap="round" transform="translate(6.607324, 11.785797) scale(1, -1) translate(-6.607324, -11.785797) "></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            );
        case 5:
        default:
            return (
                <svg width={width} height={height} viewBox="0 0 33 36" style={{display: "block"}}>
                    <title>Heart eyes</title>
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="rated_five_light" transform="translate(-1.000000, -1.000000)">
                            <g className={rating === userRating ? "selected" : "unselected"} id="rated_five" transform="translate(1.000000, 1.000000)">
                                <g id="face">
                                    <g>
                                        <path d="M10,0.844383822 L10,8.15668367 C10,8.6103665 9.62554427,9 9.16182874,9 L7.85994194,9 C7.39731495,9 7,8.6103665 7,8.15668367 L7,0.844383822 C7,0.389633495 7.39731495,0 7.85994194,0 L9.16182874,0 C9.62554427,0 10,0.389633495 10,0.844383822" id="Fill-1"></path>
                                        <path d="M26,0.844383822 L26,8.15668367 C26,8.6103665 25.6244557,9 25.1607402,9 L23.8599419,9 C23.3962264,9 23,8.6103665 23,8.15668367 L23,0.844383822 C23,0.389633495 23.3962264,0 23.8599419,0 L25.1607402,0 C25.6244557,0 26,0.389633495 26,0.844383822" id="Fill-3"></path>
                                        <path d="M33,9.9671505 L33,29.0328495 C33,32.8647823 29.8646734,36 26.0326076,36 L6.96624648,36 C3.13418064,36 0,32.8647823 0,29.0328495 L0,9.9671505 C0,6.71619557 2.25179706,3.97517189 5.27138244,3.23262032 L5.27138244,8.08670741 C5.27138244,9.64171123 6.54797375,10.9194041 8.10417752,10.9194041 L9.47473695,10.9194041 C11.0309407,10.9194041 12.307532,9.64171123 12.307532,8.08670741 L12.307532,3 L20.691322,3 L20.691322,8.08670741 C20.691322,9.64171123 21.9690593,10.9194041 23.5241171,10.9194041 L24.8946765,10.9194041 C26.4508803,10.9194041 27.7286176,9.64171123 27.7286176,8.08670741 L27.7286176,3.23262032 C30.747057,3.97517189 33,6.71619557 33,9.9671505" id="Fill-4"></path>
                                    </g>
                                </g>
                                <g id="emotion">
                                    <g transform="translate(8.760594, 13.685114)">
                                        <path d="M3.739406,5.31488623 C6.07052494,3.36444218 6.91099314,2.04349784 6.26081059,1.3520532 C5.61062804,0.660608552 4.77015984,0.987679792 3.739406,2.33326691 C2.79507677,1.03303228 1.96485137,0.705961043 1.2487298,1.3520532 C0.532608234,1.99814535 1.36283363,3.31908969 3.739406,5.31488623 Z" id="Oval" fill="#232F3E" transform="translate(3.736752, 3.157443) rotate(-25.000000) translate(-3.736752, -3.157443) "></path>
                                        <path d="M11.739406,5.31488623 C14.0705249,3.36444218 14.9109931,2.04349784 14.2608106,1.3520532 C13.610628,0.660608552 12.7701598,0.987679792 11.739406,2.33326691 C10.7950768,1.03303228 9.96485137,0.705961043 9.2487298,1.3520532 C8.53260823,1.99814535 9.36283363,3.31908969 11.739406,5.31488623 Z" id="Oval" fill="#232F3E" transform="translate(11.736752, 3.157443) rotate(25.000000) translate(-11.736752, -3.157443) "></path>
                                        <path d="M3.36310945,14.3537059 C3.98588164,11.9369175 5.51223345,10.7285233 7.94216486,10.7285233 C11.2244011,10.7285233 12.0600986,14.3537059 12.0600986,14.3537059 C12.0600986,14.3537059 9.1611022,14.3537059 3.36310945,14.3537059 Z" id="Path" stroke="#232F3E" strokeWidth="2" fill="#232F3E" strokeLinecap="round" strokeLinejoin="round" transform="translate(7.711604, 12.541115) scale(1, -1) translate(-7.711604, -12.541115) "></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            );
    }
};