import * as Actions from "../actions/action-types";

const initialState = {
    emailAndChime: {
        usePersonalChimePin: undefined,
        enableAutoCallParticipants: undefined,
        enableAutoCallRoomBooking: undefined,
    },
    timePreferences: {
        primaryTimezone: undefined,
        workingHours: {
            days: undefined,
            startTime: undefined,
            endTime: undefined,
        },
        timeFormat: undefined,
        dateFormat: undefined,
    },
    roomBookingPreferences: {
        building: undefined,
        floor: undefined,
        camera: false,
        display: false,
        noRestricted: false,
        noManaged: false,
        minimumSeats: 1,
        availableOnly: false,
    },
    layoutPreferences: {
        schedulingMode: undefined,
        calendarViewDesktop: undefined,
        calendarViewMobile: undefined,
        // Will add this in future
        // theme: THEME.LIGHT | THEME.DARK | THEME.CUSTOM 
    },
    favoritesPopoverAttendee: undefined,
    chimeEmail: undefined,
    preferencesLoaded: false,
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SAVE_PREFERENCES:
            return {
                ...state,
                emailAndChime: {
                    ...state.emailAndChime,
                    ...action.preferences.emailAndChime,
                },
                timePreferences: {
                    ...state.timePreferences,
                    ...action.preferences.timePreferences,
                },
                roomBookingPreferences: {
                    ...state.roomBookingPreferences,
                    ...action.preferences.roomBookingPreferences,
                },
                layoutPreferences: {
                    ...state.layoutPreferences,
                    ...action.preferences.layoutPreferences,
                },
                chimeEmail: action.preferences.chimeEmail || state.chimeEmail,
                preferencesLoaded: action.preferences.preferencesLoaded || state.preferencesLoaded,
            }
        case Actions.SET_FAVORITES_POPOVER_ATTENDEE:
            return {
                ...state,
                favoritesPopoverAttendee: action.popoverAttendee,
            }
        case Actions.SET_CHIME_EMAIL:
            return {
                ...state,
                chimeEmail: action.email,
            }
        case Actions.SET_PREFERENCE_LOADED:
            return {
                ...state,
                preferencesLoaded: action.isLoaded,
            }
        default:
            return state;
    }
};

export default settingsReducer;