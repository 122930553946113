import { all, call, delay, put, retry, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import format from "date-fns/format";

import * as Actions from "../actions/action-types";
import * as ChimeActions from "../chime/actions/action-types";
import * as LocationActions from "../locations/actions/action-types";
import * as MeetingActions from "../../meeting-scheduler/actions/action-types";
import * as OOTOActions from "../../meeting-ooto/actions/action-types";
import * as LivestreamActions from "../../meeting-livestream/actions/action-types";
import * as PeopleActions from "../../people/actions/action-types";
import * as timezonesActions from "../timezones/actions/action-types";
import * as FeedbackActions from "../../shared/feedback/actions/action-types";

import { getAlias, getIdentity, getIsEligible } from "../../../sagas/selector";
import {
    batchSetGroupDetails,
    refreshAgenda,
    saveSuggestions,
    setGroupDetails,
    setMeetingAgendaLoaded,
    setMeetingRecurrencePattern,
    setMeetingsRoomBookingLoaded,
    setExistingMeetingsLoaded,
    setSuggestionsState,
} from "../actions";
import {
    createMeeting,
    findMeetings,
    deleteMeeting,
    updateMeeting,
    getGroup,
    getMeeting,
    getSuggestions,
    getCachedResourceAvailability,
    getTimezones,
} from "../../api/apig/ras";
import { 
    postCreateMeetingRoomsMetrics,
    postRoomBookingMetric
} from "../metrics/actions";
import { switchWorkflow } from "../workflow/actions/index";
import { showToast } from "../toasts/actions";
import { setQuickMeetingModalState } from "../../meeting-quick/actions";
import { setCreatingMeetingState, setMaster } from "../../meeting-scheduler/actions";
import { getFeedbackEligibility, setFeedbackAlertMessage } from "../feedback/actions";
import { TIME_CONSTANT, GET_MEETING_MAX_ATTEMPTS, GET_MEETING_RETRY_DELAY_IN_MS, ROOM_RESPONSE } from "../shared-constants";
import { MODAL_STATE } from "../../meeting-quick/meeting-quick-constants";
import { TIMEOUT, TOAST_COMPONENT, TOAST_TYPE } from "../toasts/toast-constants";
import {
    getSuccessToastWithComponent,
    getErrorToast,
    getErrorToastWithComponent,
    getWarningToast
} from "../toasts/toast-utils";
import { isErrorResponse } from "../../api/apig/apig-utils";
import { pathToWorkflow } from "../workflow/workflow-utils";
import { WORKFLOWS } from "../workflow/workflow-constants";
import { getCheckInStatusApi } from "../check-in/sagas";
import { CHECK_IN_FEATURE_ENABLED } from "../check-in/check-in-constants";
import { getChimePinFromMeetingBody } from "../chime/html-template-utilities";

const _ = require('lodash');

export function* watchDeleteMeeting() {
    yield takeEvery(Actions.DELETE_MEETING, deleteMeetingApi);
}

//This saga watches a FIND_MEETINGS action. On each action, it starts a task to fetch meetings from a server
export function* watchFindMeetings() {
    yield takeEvery(Actions.FIND_MEETINGS, findMeetingsApi);
}

export function* watchGetMeeting() {
    yield takeEvery(Actions.GET_MEETING, getMeetingApi);
}

export function* watchUpdateMeeting() {
    yield takeEvery(Actions.UPDATE_MEETING, updateMeetingApi);
}

export function* deleteMeetingApi(action) {
    const response = yield call(deleteMeeting, action.deleteMeetingRequest);
    const deleteMeetingResponse = JSON.parse(response);
    if (isErrorResponse(deleteMeetingResponse)) {
        const toast = getErrorToast("An error occurred when deleting your meeting, please try again.");
        yield put(showToast(toast));
        return;
    }

    if (action.event !== undefined) {
        yield put({
            type: Actions.DELETE_FROM_AVAILABILITY_LIST,
            email: action.event.email,
            location: action.event.name,
            time: action.event.time
        });
    }

    const toast = getSuccessToastWithComponent();
    toast.toastActionProps.entryId = action.deleteMeetingRequest.uniqueOrEntryID;
    toast.toastActionProps.master = action.deleteMeetingRequest.master;
    toast.toastActionProps.userEmail = action.deleteMeetingRequest.requestedOnBehalfOf;
    toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_DELETED;

    if (action.setMeetingListAction) {
        console.log(`Setting meeting list: ${action.setMeetingListAction}`);
        yield put(action.setMeetingListAction);
    }
    if (action.redirectTo) {
        yield put(push(action.redirectTo));
        yield put(switchWorkflow(pathToWorkflow()));
    }


    yield put(showToast(toast));
    return deleteMeetingResponse;
}

export function* findMeetingsApi(action) {
    const response = yield call(
        findMeetings,
        action.requestedOnBehalfOf,
        action.startTime,
        action.endTime,
        action.maxResults
    );
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const user = action.requestedOnBehalfOf.split("@")[0];
        const startDate = format(new Date(action.startTime), "yyyy-MM-dd");
        const endDate = format(new Date(action.startTime), "yyyy-MM-dd");
        const errorMsg = startDate === endDate ?
            `An error occurred when retrieving meetings on ${startDate} for ${user}'s calendar.` :
            `An error occurred when retrieving meetings from ${startDate} to ${endDate} on ${user}'s calendar.`;
        const toast = getErrorToast(errorMsg);
        yield put(showToast(toast));
        return;
    }
    const meetingList = parsedResponse.meetings;
    yield put({
        type: Actions.SET_MEETINGS_LIST,
        meetingList
    });

    return meetingList;
}

export function* getMeetingApi(action) {
    const response = yield call(
        getMeeting,
        action.requestedOnBehalfOf,
        action.uniqueOrEntryID,
        action.master,
        action.propertyNames
    );
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast("An error occurred when retrieving meeting.");
        yield put(showToast(toast));
        return;
    }
    return parsedResponse;
}

export function* getMeetingAgendaApi(action) {
    // show the loading indicator
    yield put(setMeetingAgendaLoaded(false));

    const {
        requestedOnBehalfOf,
        startTime,
        endTime,
        maxResults
    } = action;
    const meetingList = yield call(findMeetingsApi, {
        requestedOnBehalfOf,
        startTime,
        endTime,
        maxResults
    });

    if (CHECK_IN_FEATURE_ENABLED && meetingList) {
        // only fetch the meeting's detail that the user is the organizer && the meeting has the location && the startTime starts in 15 mins.
        const meetings = meetingList.filter(meeting =>
            meeting?.response === "organizer" &&
            meeting.location?.length > 0 &&
            meeting.time.startTime * 1000 - Date.now() <= TIME_CONSTANT.FIFTEEN_MINUTES_IN_MS).map(meeting => ({
            requestedOnBehalfOf,
            uniqueOrEntryID: meeting.entryID,
            master: false,
            propertyNames: "",
            targetMailbox: "",
        }));

        if (meetings && meetings.length > 0) {
            const meetingResourcesDictionary = yield call(getMeetingDetailsListAndSave, { meetings });

            if (meetingResourcesDictionary?.size > 0) {
                // fetch the rooms eligibility status & the checkIn status
                const rooms = [];
                const meetings = [];
                meetingResourcesDictionary.forEach((meeting, key) => {
                    meeting.resources?.length > 0 && meeting.resources.forEach(res => {
                        rooms.push(res.email);
                        if (res.response !== ROOM_RESPONSE.DECLINE) {
                            meetings.push({
                                roomEmail: res.email,
                                meetingStartTime: meeting.startTime
                            });
                        }
                    });
                });

                const requests = [];

                if (meetings.length > 0) {
                    requests.push(call(getCheckInStatusApi, { meetings }));
                }

                yield all(requests);
            }
        }
    }

    // Remove the loading indicator
    yield put(setMeetingAgendaLoaded(true));
}

export function* watchGetMeetingAgenda() {
    yield takeEvery(Actions.GET_MEETING_AGENDA, getMeetingAgendaApi);
}

export function* getMeetingDetailsListAndSave(action) {
    // Create a map to store the meetingId -> [resources, startTime, endTime, timeZone]
    const meetingResourcesDictionary = new Map();

    if (action.meetings && Array.isArray(action.meetings)) {
        const responses = yield all(action.meetings.map(meeting => call(
            getMeeting,
            meeting.requestedOnBehalfOf,
            meeting.uniqueOrEntryID,
            meeting.master,
            meeting.propertyNames
        )));

        for (const response of responses) {
            const parsedResponse = JSON.parse(response);
            if (isErrorResponse(parsedResponse)) {
                // TODO: Since we are using yield all, the response message doesn't contain any meeting detail info,
                // We may need to improve a bit from the API side for more info to improve below error message.
                const toast = getErrorToast("An error occurred when retrieving meeting.");
                yield put(showToast(toast));
                continue;
            }

            if (parsedResponse.resources && parsedResponse.resources.length > 0) {
                meetingResourcesDictionary.set(parsedResponse.entryID, {
                    resources: parsedResponse.resources,
                    startTime: parsedResponse.time.startTime,
                    endTime: parsedResponse.time.endTime,
                    timezone: parsedResponse.startTimezone.displayName,
                });
            }
        };
    }

    yield put({
        type: Actions.SET_MEETING_RESOURCES_DICTIONARY,
        meetingResourcesDictionary,
    });

    return meetingResourcesDictionary;
}

export function* watchGetMeetingDetailsListAndSave() {
    yield takeEvery(Actions.GET_MEETING_DETAILS_LIST_AND_SAVE, getMeetingDetailsListAndSave);
}

export function* getExistingMeetings(action) {
    yield put(setExistingMeetingsLoaded(false));

    const existingMeetings = [];

    if (action.meetings && Array.isArray(action.meetings)) {
        const groups = Math.ceil(action.meetings.length / 5);
        for (let i = 0; i < groups; i++) {
            const meetingGroup = action.meetings.slice(i * 5, (i+1) * 5);
            const responses = yield all(meetingGroup.map((meeting) => call(
                getMeeting,
                action.requestedOnBehalfOf,
                meeting.entryID,
                false,
                "",
                "",
            )));
            for (const response of responses) {
                const parsedResponse = JSON.parse(response);
                if (isErrorResponse(parsedResponse)) {
                    // TODO: Since we are using yield all, the response message doesn't contain any meeting detail info,
                    // We may need to improve a bit from the API side for more info to improve below error message.
                    const toast = getErrorToast("An error occurred when retrieving meeting.");
                    yield put(showToast(toast));
                    continue;
                }
    
                const pinFromBody = getChimePinFromMeetingBody(parsedResponse.body);
                if (pinFromBody && pinFromBody.length > 0) {
                    existingMeetings.push(parsedResponse);
                }
            };
        }
    }

    yield put({
        type: Actions.SET_EXISTING_MEETINGS,
        existingMeetings,
    });

    yield put(setExistingMeetingsLoaded(true));
    return existingMeetings;   
}

export function* watchGetExistingMeetings() {
    yield takeEvery(Actions.GET_EXISTING_MEETINGS, getExistingMeetings);
}

export function* updateMeetingApi(action) {
    let updateMeetingRequest = action.updateMeetingRequest;
    // If the user's email is empty or undefined, adding the current user's email
    // TODO: Find root cause, figure out why this is passed in empty (or if it is still an issue)
    if (!updateMeetingRequest.requestedOnBehalfOf) {
        const { email } = yield select(getIdentity);
        console.log("Updating meeting requestedOnBehalfOf to " + email);
        updateMeetingRequest.requestedOnBehalfOf = email;
    }

    const response = yield call(updateMeeting, updateMeetingRequest);
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast("An error occurred when updating your meeting, please try again.");
        yield put(showToast(toast));
        return;
    }

    const toast = getSuccessToastWithComponent();
    toast.toastActionProps.entryId = action.updateMeetingRequest.uniqueOrEntryID;
    toast.toastActionProps.master = action.updateMeetingRequest.master;
    toast.toastActionProps.userEmail = action.updateMeetingRequest.requestedOnBehalfOf;
    toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_UPDATED;


    if (action.setMeetingListAction) {
        console.log(`Setting meeting list: ${action.setMeetingListAction}`);
        yield put(action.setMeetingListAction);
    }

    // Meeting update clears draft and redirects user to the homepage.
    // TODO: Need to fix states so we only clear the flow that the user was working on
    yield put({type: MeetingActions.CLEAR_DRAFT});
    yield put({type: OOTOActions.CLEAR_DRAFT});
    yield put({type: LivestreamActions.CLEAR_DRAFT});
    yield put({type: PeopleActions.CLEAR_DRAFT});
    yield put({type: LocationActions.CLEAR_LOCATIONS_AND_ROOMS});
    yield put({type: ChimeActions.CLEAR_CHIME_PIN});

    if (action.redirectTo) {
        yield put(push(action.redirectTo));
        yield put(switchWorkflow(pathToWorkflow()));
    }

    yield put(showToast(toast));
    return parsedResponse;
}

export function* watchGetMeetingAndSave() {
    yield takeEvery(Actions.GET_MEETING_AND_SAVE, getMeetingAndSaveToState);
}

export function* getMeetingAndSaveToState(action) {
    console.log(`Getting meeting ${action.uniqueOrEntryID} on ${action.requestedOnBehalfOf}`);

    const response = yield call(getMeeting,
        action.requestedOnBehalfOf,
        action.uniqueOrEntryID,
        action.master,
        action.propertyNames,
        action.targetMailbox,
    );
    let parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast("An error occurred when retrieving meeting.");

        // if the user fails to retrieve a valid meeting when editing, redirect to the homepage
        if (pathToWorkflow() === WORKFLOWS.EDIT_MEETING) {
            yield put(push("/"));
            yield put(switchWorkflow(pathToWorkflow()));
        }

        yield put(showToast(toast));
        return;
    }

    // if the user is not the same as the organizer when editing, redirect to the homepage since they do not have permission to edit
    // TODO: remove once delegation is added, perform a permission check instead
    if (pathToWorkflow() === WORKFLOWS.EDIT_MEETING && action.requestedOnBehalfOf !== parsedResponse.organizer) {
        const toast = getErrorToast("You do not have permission to edit this meeting.");

        yield put(push("/"));
        yield put(switchWorkflow(pathToWorkflow()));

        yield put(showToast(toast));
        return;
    }

    if (parsedResponse.recurrence) {
        yield put(setMaster(true)); // meeting ID is for a recurring series, so master is true to edit the series
    }

    if (parsedResponse.isRecurring && !parsedResponse.recurrence) {
        // obtain the details for the recurring series to store master entry ID and recurrence info
        const masterResponse = yield call(
            getMeeting,
            action.requestedOnBehalfOf,
            action.uniqueOrEntryID,
            true,
            action.propertyNames,
            action.targetMailbox,
        );
        const parsedMasterResponse = JSON.parse(masterResponse);
        if (isErrorResponse(parsedMasterResponse)) {
            const toast = getErrorToast("An error occurred when retrieving master meeting.");
            yield put(showToast(toast));
            return;
        }

        parsedResponse = {
            ...parsedResponse,
            recurrence: parsedMasterResponse.recurrence,
            masterEntryID: parsedMasterResponse.entryID,
        };
    }

    let extendedProperties = parsedResponse["extendedProperties"];
    // Checking if extendedProperties is not an empty object
    if (JSON.stringify(extendedProperties) !== '{}') {
        let ootoObject = JSON.parse(extendedProperties["ooto"]?.["value"]);
        if (JSON.stringify(ootoObject) !== '{}' && ootoObject["ootoMeetingType"] === "selfMeeting") {
            yield put({ 
                type: Actions.SET_AWARENESS_MEETING_UNIQUE_ID, 
                awarenessMeetingUniqueId: ootoObject["awarenessMeetingUniqueId"]
            });
        }
    }

    yield put({
        type: action.saveAction,
        meeting: parsedResponse,
    });
}

export function* watchGetMeetingRecurrencePattern() {
    yield takeEvery(Actions.GET_MEETING_RECURRENCE_PATTERN_AND_SAVE, getMeetingRecurrencePattern);
}

export function* getMeetingRecurrencePattern(action) {
    const response = yield call(getMeeting,
        action.mailbox,
        action.entryID,
        true,
        "",
        action.mailbox,
    );
    let parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast("An error occurred when retrieving meeting recurrence pattern.");
        yield put(showToast(toast));
        return;
    }

    yield put(setMeetingRecurrencePattern(parsedResponse.recurrence));
}

export function* watchBatchGetGroup() {
    yield takeEvery(Actions.BATCH_GET_GROUP, batchGetGroup);
}

export function* batchGetGroup(action) {
    console.log(`Getting groups for ${action.groupList}`);
    if (!action.groupList || (action.groupList && action.groupList.length === 0)) {
        return;
    }

    const response = yield all(
        action.groupList.map((group) => callGetGroup({
            groupName: group.substring(0, group.indexOf("@")),
            expandLargeGroup: action.expandLargeGroup
        }))
    );

    const batchGroupDetails = {};
    action.groupList.forEach((groupName, index) => {
        if (!isErrorResponse(groupName)) {
            batchGroupDetails[groupName] = response[index];
        }
    });

    yield put(batchSetGroupDetails(batchGroupDetails));
}

export function* callGetGroup(action) {
    console.log(`Getting group ${action.groupName}`);

    const response = yield call(getGroup, action.groupName, action.expandLargeGroup);

    return JSON.parse(response);
}

export function* watchGetGroup() {
    yield takeEvery(Actions.GET_GROUP, callGetGroupAndSaveToState);
}

export function* callGetGroupAndSaveToState(action) {
    console.log(`Getting group ${action.groupName}`);

    const response = yield call(getGroup, action.groupName);
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast(`An error occurred when retrieving group ${action.groupName}.`);
        yield put(showToast(toast));
        return;
    }

    yield put(setGroupDetails(action.groupName, parsedResponse));
}

export function* watchGetSuggestions() {
    yield takeLatest(Actions.GET_SUGGESTIONS, getSuggestionsApi);
}

export function* getSuggestionsApi(action) {
    yield put(setSuggestionsState(true));

    const response = yield call(getSuggestions, action.getSuggestionsRequest);
    const suggestions = JSON.parse(response);
    if (isErrorResponse(suggestions)) {
        const toast = getErrorToast("An error has occurred when getting suggestions, please try again.");
        yield put(showToast(toast));
    } else {
        if (action.getSuggestionsRequest.addOffsetMinutes) {
            // move suggestions forward by offset minutes
            suggestions.suggestionList.forEach((suggestion) => {
                suggestion["time"][0].startTime += (action.getSuggestionsRequest.addOffsetMinutes * 60);
                suggestion["time"][0].endTime += (action.getSuggestionsRequest.addOffsetMinutes * 60);
            });
        }

        yield put(saveSuggestions(suggestions));
    }

    yield put(setSuggestionsState(false));
}

export function* watchGetTimezones() {
    yield takeEvery(timezonesActions.GET_TIMEZONES, getTimezonesApi);
}

export function* getTimezonesApi(action) {
    const response = yield call(
        getTimezones,
        action.language
    );
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast(`An error occurred when retrieving timezones.`);
        yield put(showToast(toast));
        return;
    }
    const timezones = parsedResponse.timezones;
    yield put({
        type: timezonesActions.SET_TIMEZONES,
        timezones
    })
}

export function* watchCreateMeeting() {
    yield takeLatest(Actions.CREATE_MEETING, createMeetingApi);
}

export function* createMeetingApi(action) {
    yield put(setCreatingMeetingState(true));

    // show the loading indicator
    yield put(setMeetingAgendaLoaded(false));

    let toast;
    const response = yield call(createMeeting, action.createMeetingRequest);
    const createMeetingResponse = JSON.parse(response);
    if (isErrorResponse(createMeetingResponse)) {
        if (action.page === "Room booking") {
            yield put(postRoomBookingMetric("create meeting", action.permalink ? "permalink" : "room booking page", false));
        }
        if (createMeetingResponse.error.message?.toLowerCase().includes("quota")) {
            // Custom error toast for QuotaExceededException
            toast = getErrorToastWithComponent();
            toast.toastActionProps.text = "Meeting creation unsuccessful due to full mailbox.";
            toast.toastActionProps.linkText = "Learn more";
            toast.toastActionProps.href = "https://w.amazon.com/bin/view/UnifiedCommunications/Amazon_Meetings/issues/QuotaExceededException";
            toast.toastActionProps.componentName = TOAST_COMPONENT.LINK;
        } else if (createMeetingResponse.error.message?.toLowerCase().includes("meeting creation fail because there are conflicting meetings in the rooms")) {
            const allRooms = action.createMeetingRequest.meeting.resources || [];
            let conflictingError = "The following room(s) have conflicting meetings in your invitation: ROOM_LIST. " +
                "Please choose another room and update the meeting.";
            const conflictingRoomNames = _.map(allRooms, 'name');

            toast = getErrorToastWithComponent();
            toast.toastMessage = conflictingError.replace(/ROOM_LIST/, conflictingRoomNames.join(','));
            toast.toastActionProps.text = conflictingError.replace(/ROOM_LIST/, conflictingRoomNames.join(','));
            toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CONFLICT;
            toast.toastTimeout = TIMEOUT.NEVER;
        } else {
            toast = getErrorToast("An error occurred when creating your meeting, please try again.");
        }

        yield put(showToast(toast));
        yield put(setCreatingMeetingState(false));
        return;
    }
    if (action.page === "Room booking") {
        yield put(postRoomBookingMetric("create meeting", action.permalink ? "permalink" : "room booking page", true));
    }

    // Meeting creation clears draft and redirects user to the homepage.
    // TODO: Need to fix states so we only clear the flow that the user was working on
    yield put({type: MeetingActions.CLEAR_DRAFT});
    yield put({type: OOTOActions.CLEAR_DRAFT});
    yield put({type: LivestreamActions.CLEAR_DRAFT});
    yield put({type: PeopleActions.CLEAR_DRAFT});
    yield put({type: LocationActions.CLEAR_LOCATIONS_AND_ROOMS});
    yield put({type: ChimeActions.CLEAR_CHIME_PIN});
    if (action.redirectTo) {
        yield put(push(action.redirectTo));
        yield put(switchWorkflow(pathToWorkflow()));
    }

    // verify if the meeting was created and accepted by all rooms
    let parsedMeeting;
    try {
        const meetingHasRooms = action.createMeetingRequest.meeting
            && action.createMeetingRequest.meeting.resources
            && action.createMeetingRequest.meeting.resources.length > 0;
        if (meetingHasRooms) {
            // adding delay before getMeeting to allow rooms to send response
            yield delay(TIME_CONSTANT.MEETING_CREATE_VERIFICATION_WAIT_TIME);

            // Success: returns the stringified meeting with all rooms responded,
            // Semi-success: throws if final attempt still has rooms that did not respond,
            // Error: returns a stringified error if there was an issue getting the meeting
            const meeting = yield retry(GET_MEETING_MAX_ATTEMPTS, GET_MEETING_RETRY_DELAY_IN_MS, getMeetingWithRoomResponses, [
                    action.createMeetingRequest.meeting.organizer,
                    createMeetingResponse.entryID,
                    false, // TODO: handle recurring meeting
                    ""
                ]);
            parsedMeeting = JSON.parse(meeting);
        } else {
            // If there were no rooms, do not call getMeeting since we do not need to verify if rooms accepted.
            parsedMeeting = {resources: []};
        }
    } catch (e) {
        // The meeting retrieved on the final attempt had some rooms that did not respond,
        // so this error was thrown with the retrieved meeting object stored in the error message.
        // This is a workaround for now since retry does not support this use case.
        // We can revisit this implementation if we find similar use cases in the future.
        parsedMeeting = JSON.parse(e.message);
    }

    if (isErrorResponse(parsedMeeting)) {
        toast = getErrorToast("An error occurred when verifying your meeting, please check your email for meeting confirmation(s).");
    } else {
        // room response status verification
        const allRooms = parsedMeeting.resources || [];
        const noResponseRooms = allRooms.filter((room) => room.response !== ROOM_RESPONSE.ACCEPT && room.response !== ROOM_RESPONSE.DECLINE);
        const acceptedRooms = allRooms.filter((room) => room.response === ROOM_RESPONSE.ACCEPT);
        const declinedRooms = allRooms.filter((room) => room.response === ROOM_RESPONSE.DECLINE);

        yield put(postCreateMeetingRoomsMetrics(allRooms, noResponseRooms, acceptedRooms, declinedRooms));

        if (acceptedRooms.length !== allRooms.length) {
            if (!_.isEmpty(declinedRooms)) {
                let declinedError = "The following room(s) declined your invitation: ROOM_LIST. " +
                    "Detailed reasons can be found in the response email. Please choose another room and update the meeting.";
                const declinedRoomNames = _.map(declinedRooms, 'displayName');

                toast = getErrorToastWithComponent();
                toast.toastMessage = declinedError.replace(/ROOM_LIST/, declinedRoomNames.join(','));
                toast.toastActionProps.text = declinedError.replace(/ROOM_LIST/, declinedRoomNames.join(','));
                toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_DECLINED;
                toast.toastTimeout = TIMEOUT.NEVER;
            } else {
                let noResponseError = "Booking request received. Check your email for final confirmation: ROOM_LIST.";
                const noResponseRoomNames = _.map(noResponseRooms, 'displayName');

                toast = getWarningToast(noResponseError.replace(/ROOM_LIST/, noResponseRoomNames.join(',')));
            }
        } else {
            if (action.createMeetingRequest.meeting.subject.includes("via Amazon Meetings")) {
                yield put({
                    type: Actions.UPDATE_AVAILABILITY_LIST,
                    suggestion: {
                        isException: false,
                        isPrivate: false,
                        isRecurring: false,
                        location: action.createMeetingRequest.meeting.resources[0].name,
                        status: "busy",
                        subject: action.createMeetingRequest.meeting.displayName || action.createMeetingRequest.meeting.subject,
                        time: action.createMeetingRequest.meeting.time
                    },
                    email: action.createMeetingRequest.meeting.resources[0].email
                });
                yield put({type: Actions.ADD_MEETING_ROOM_BOOKING, meeting: parsedMeeting});
            }
            toast = getSuccessToastWithComponent();
            toast.toastActionProps.subject = action.createMeetingRequest.meeting.subject;
            toast.toastActionProps.entryId = createMeetingResponse.entryID;
            toast.toastActionProps.userEmail = action.createMeetingRequest.meeting.organizer;
            toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CREATED;
        }
    }
    // need to refresh the meeting after the meeting is fully created.
    // otherwise won't be able to get the correct eligibility/checkIn status.
    yield put(refreshAgenda());
    yield put(setQuickMeetingModalState(MODAL_STATE.CLOSE));

    // Check if user is eligible for a feedback modal instead of a success toast.
    let isEligible;
    if (toast.toastType === TOAST_TYPE.SUCCESS) {
        const alias = yield select(getAlias);
        yield put(getFeedbackEligibility(alias));
        yield take([FeedbackActions.SET_IS_ELIGIBLE]);
        isEligible = yield select(getIsEligible);
        yield put(setFeedbackAlertMessage("Meeting Created"));
    }
    if (!isEligible) {
        yield put(showToast(toast));
    }
    yield put(setCreatingMeetingState(false));
}

function* getMeetingWithRoomResponses(params) {
    const response = yield call(getMeeting,
        params[0], // organizer
        params[1], // entryID
        params[2], // master
        params[3], // propertyNames
    );
    const parsedMeeting = JSON.parse(response);
    const resources = parsedMeeting.resources || [];
    const noResponseRooms = resources.filter((room) => room.response !== ROOM_RESPONSE.ACCEPT && room.response !== ROOM_RESPONSE.DECLINE);
    if (noResponseRooms.length > 0) {
        throw Error(response);
    }

    // Rooms responded or there was an error, do not retry
    return response;
}

export function* getCachedResourceAvailabilityApi(action) {
    yield put({
        type: Actions.SAVE_CACHED_AVAILABILITY,
        suggestions: {cachedAvailabilityList: []}
    });

    const rooms = action.cachedAvailabilityRequest.rooms;

    const response = yield call(getCachedResourceAvailability, {
            listOfMailboxes: action.cachedAvailabilityRequest.listOfMailboxes,
            startTime: action.cachedAvailabilityRequest.startTime,
            endTime: action.cachedAvailabilityRequest.endTime
        }
    );
    const parsedResponse = JSON.parse(response);

    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast(`An error occurred when retrieving availability for the conference rooms.`);
        yield put(showToast(toast));
        return;
    }

    const resourceAvailabilityMap = parsedResponse.resourceAvailabilityMap;
    rooms.forEach((room) => room.availabilities = resourceAvailabilityMap[(room.email).toLowerCase()]);
    yield put({
        type: Actions.SAVE_CACHED_AVAILABILITY,
        suggestions: {cachedAvailabilityList: rooms}
    });
}

export function* watchGetCachedResourceAvailability() {
    yield takeLatest(Actions.GET_CACHED_RESOURCE_AVAILABILITY, getCachedResourceAvailabilityApi);
}

export function* getMeetingsRoomBookingApi(action) {
    // show the loading indicator
    yield put(setMeetingsRoomBookingLoaded(false));

    const {
        requestedOnBehalfOf,
        startTime,
        endTime,
        maxResults
    } = action;
    const meetingList = yield call(findMeetingsApi, {
        requestedOnBehalfOf,
        startTime,
        endTime,
        maxResults
    });

    if (meetingList && meetingList.length > 0) {
        yield put({
            type: Actions.SET_MEETINGS_ROOM_BOOKING,
            meetingList
        });
    }
    // Remove the loading indicator
    yield put(setMeetingsRoomBookingLoaded(true));
}

export function* watchGetMeetingsRoomBooking() {
    yield takeEvery(Actions.GET_MEETINGS_ROOM_BOOKING, getMeetingsRoomBookingApi);
}

const sagas = [
    watchDeleteMeeting(),
    watchFindMeetings(),
    watchGetCachedResourceAvailability(),
    watchGetMeeting(),
    watchGetMeetingAndSave(),
    watchGetMeetingRecurrencePattern(),
    watchGetGroup(),
    watchBatchGetGroup(),
    watchGetSuggestions(),
    watchGetTimezones(),
    watchCreateMeeting(),
    watchUpdateMeeting(),
    watchGetMeetingDetailsListAndSave(),
    watchGetMeetingAgenda(),
    watchGetMeetingsRoomBooking(),
    watchGetExistingMeetings(),
];

export default sagas;
