/* Array containing launch announcements for Amazon Meetings, ordered newest to oldest
* time {int}: Epoch seconds for the announcement, equal to 8:00 AM PST on the date the feature is launched on the website
* title {string}: Title for the announcement post
* description {string}: Description for the announcement post
* href {string}: A link to the appropriate section on the announcement wiki
*/
export const launchAnnouncements = [
    {
        "time": 1709136000, // February 28, 2024, 8:00 AM PST
        "title": "Enhanced Livestream request",
        "description": `Organizers can now choose their desired Livestream URL, pre-populate the intake with their most recent event details, and choose the improved native chat or Slack chat for their events. Organizers will also receive immediate activation of their event page.`,
        "href": "https://w.amazon.com/bin/view/Live_Streaming_Platform/Products/Livestream/Organizers/",
    },
    {
        "time": 1682607600, // April 27, 2023, 8:00 AM PST
        "title": "Meeting room check in required",
        "description": `Starting 02-May, <b>meeting room check in will be required</b> in AV/VC capable meeting rooms with an occupancy of 24 or less. Visit the <a href=https://it.amazon.com/servicecatalog/meetings?#tab-body-609 target="_blank">Meeting Room Check In article</a> to learn more.`,
        "href": "https://it.amazon.com/servicecatalog/meetings?#tab-body-609",
    },
    {
        "time": 1680274800, // March 31, 2023, 8:00 AM PST
        "title": "Meeting Polls Launch",
        "description": `<a href=https://meetings.amazon.com/#/create-poll target="_blank">Meeting Polls</a> is now generally available! Meeting Polls lets meeting organizers and attendees collaborate to find the best time to meet. Organizers can propose up to 10 meeting date and time options and attendees vote on which options work for them. The <a href=https://meetings.amazon.com/#/polls target="_blank">Meeting Polls dashboard</a> tracks vote results automatically and allows organizers to schedule a meeting directly with 1-click. Meeting Polls was designed to help coordinate meeting times with other Amazonians and attendees outside the company.`,
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Roadmaps/MAPS/meeting_poll",
    },
    {
        "time": 1678377600, // March 9, 2023, 8:00 AM PST
        "title": "Meeting Polls Beta",
        "description": `Meeting Polls lets meeting organizers and attendees collaborate to find the best time to meet. Organizers can propose up to 10 meeting date and time options and attendees vote on which options work for them. Meeting Polls dashboard tracks vote results automatically and allows organizers to schedule a meeting directly with 1-click. Meeting Polls was designed to help coordinate meeting times with other Amazonians and attendees outside the company. Please share your Likes, Pain Points, Bugs, Feature Requests and Questions via the <a href="https://my.slack.com/archives/C0444NRQLG2" target="_blank">meeting-poll-beta Slack Channel</a>. Bonus, please take 5 minutes to give us your feedback by taking this <a href="https://survey.fieldsense.whs.amazon.dev/survey/9ac65e30-8710-476f-bbba-83b6f80714eb" target="_blank">survey</a>.`,
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Roadmaps/MAPS/meeting_poll",
    },
    {
        "time": 1675180800, // January 31, 2023, 8:00 AM PST
        "title": "Send a Meeting Poll to find the best time to meet",
        "description": `<a href="https://beta.meetings.enterprise-engineering.aws.dev/#/create-poll" target="_blank">Meeting Polls</a> beta lets meeting organizers and attendees collaborate to find the best time to meet. Organizers can propose up to 10 meeting date and time options and attendees vote on which options work for them. Meeting Polls <a href=https://beta.meetings.enterprise-engineering.aws.dev/#/polls target="_blank">dashboard</a> tracks vote results automatically and allows organizers to schedule a meeting directly with 1-click. Meeting Polls was designed to help coordinate meeting times with other Amazonians and attendees outside the company. Please share your Likes, Pain Points, Bugs, Feature Requests and Questions via the <a href=https://amzn-aws.slack.com/archives/C0444NRQLG2 target="_blank">meeting-poll-beta Slack Channel.</a>`,
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Roadmaps/MAPS/meeting_poll/",
    },
    {
        "time": 1660834800, // August 18, 2022, 8:00 AM PST
        "title": "Book an available meeting room faster with filters",
        "description": "Room booking now supports full-building searches for available rooms with filters for duration, minimum seats, AV/VC and restricted status.",
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Announcements/#HBookanavailablemeetingroomfasterwithfilters",
    },
    {
        "time": 1647270000, // March 14, 2022, 8:00 AM PST
        "title": "Customize your booking experience with Preferences",
        "description": "Organizers can now set Preferences for their Amazon Meetings experience. Choose your preferred time format, date format, timezone, building and floor, meeting hours, Amazon Chime options, calendar views and more.",
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Announcements/#HCustomizeyourbookingexperiencewithPreferences",
    },
    {
        "time": 1645200000, // February 18, 2022, 8:00 AM PST
        "title": "Livestream Event intake",
        "description": "Livestream organizers can now schedule events using Amazon Meetings! The new intake form provides smart suggestions for dates and times when both Presenters and Livestream Engineers are available. It also makes the task of coordinating an event simpler by automatically creating separate meeting invites for Presenters and Viewers.",
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Announcements/#HLivestreamEventintake",
    },
    {
        "time": 1644940800, // February 15, 2022, 8:00 AM PST
        "title": "Simplified steps for Standard Meeting and OOTO",
        "description": "We removed the Review step from Standard Meeting and OOTO. Now when scheduling you’ll see a preview of your meeting on the same page as the meeting agenda.",
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Announcements/#HSimplifiedstepsforStandardMeetingandOOTO"
    },
    {
        "time": 1641484800, // January 6, 2022, 8:00 AM PST
        "title": "Copy Existing Meetings – including OOTO",
        "description": "Amazonians can copy any existing meeting from their Amazon Meetings calendar or daily agenda, and can now choose between copying to a new OOTO or Standard Meeting. Now that OOTO is supported, you can copy your OOTO message and list of notified contacts to make OOTO scheduling even easier.",
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Announcements/#HCopyExistingMeetings2013includingOOTO"
    },
    {
        "time": 1637337600, // November 19, 2021, 8:00 AM PST
        "title": "Availability sharing (Beta)",
        "description": "The new Availability Sharing feature helps you respond to the question: \"When are you available to meet?\" without manually transcribing your calendar. Using this feature you can automatically identify available meeting times, manually fine-tune available times, and copy your availability to the clipboard for easy sharing via email, Slack or Chime.",
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Announcements/#HAvailabilitySharingBeta"
    },
    {
        "time": 1636038000, // November 4, 2021, 8:00 AM PST
        "title": "Copy existing meetings",
        "description": "Customers can now copy any existing meeting from their Amazon Meetings calendar into a new meeting invite. Attendees, meeting agenda, and recurrence pattern are all copied over to the new invite. This feature is useful when scheduling a series of related meetings.",
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Announcements/#HCopyExistingMeetings",
    },
    {
        "time": 1634137200, // October 13, 2021, 8:00 AM PST
        "title": "Mental break time",
        "description": "We implemented new 25 and 55 minute meeting duration options in the Standard Meeting workflow. Selecting one of these new durations offsets the start time by 5 minutes to allow for a mental break between meetings.",
        "href": "https://w.amazon.com/bin/view/Meetex/AM2/Announcements/#HMentalBreakTime",
    },
];
