import React, { useCallback, useEffect, useState } from "react";
import RoomFinderResultsTimeTable from "./room-finder-results-time-table";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Loader from "@amzn/meridian/loader";
import StatusLegend from "../../shared/components/status-legend";
import { renderWarehouseIcon } from "../../shared/shared-utils";
import { 
    LOCATION_FEATURE
 } from "../../shared/locations/locations-constants";

const RoomFinderResults = (props) => {
    const [availableOnlyChecked, setAvailableOnlyChecked] = [props.availableOnlyChecked, props.setAvailableOnlyChecked];
    const startTimeFilter = props.startTimeFilter;
    const endTimeFilter = props.endTimeFilter;
    const roomResults = props.roomResults;
    const minimalSeats = props.minimalSeats;
    const cameraChecked = props.cameraChecked;
    const displayChecked = props.displayChecked;
    const noRestrictChecked = props.noRestrictChecked;
    const noManagedChecked = props.noManagedChecked;
    const nearbyResults = props.nearbyResults;
    const getNearbySuggestions = props.getNearbySuggestions;
    const [searchNearby, setSearchNearby] = [props.searchNearby, props.setSearchNearby];
    const meetingList = props.meetingList;
    const refresh = props.onRefresh;
    
    const [filteredRoomResults, setFilteredRoomResults] = useState(roomResults);

    const onClickSearchNearby = () => {
        setSearchNearby(true);
        setAvailableOnlyChecked(true);
        getNearbySuggestions();
    }

    const availableOnlyFilter = useCallback((rooms) => {
        let newRooms = [];
        // Iterate all rooms
        for (const room of rooms) {
            let addRoom = true;
            const availabilities = room.availabilities;
            // Iterate all availabilities
            for (const availability of availabilities) {
                if (availability.status === "busy" && 
                    ((startTimeFilter < availability.time.startTime && availability.time.startTime < endTimeFilter) || 
                    (startTimeFilter < availability.time.endTime && availability.time.endTime < endTimeFilter) || 
                    (availability.time.startTime < startTimeFilter && startTimeFilter < availability.time.endTime)||
                    (availability.time.startTime < endTimeFilter && endTimeFilter < availability.time.endTime) ||
                    (availability.time.startTime === startTimeFilter) || 
                    (availability.time.endTime === endTimeFilter))) {
                    addRoom = false;
                    break;
                }
            }
            if (addRoom) {
                newRooms.push(room);
            }
        }
        return newRooms;
    },[endTimeFilter, startTimeFilter]);

    useEffect(() => {
        let rooms = roomResults;

        if (availableOnlyChecked) {
            rooms = availableOnlyFilter(roomResults);
        }
        rooms = rooms.filter((room) => {
            return (room.capacity >= minimalSeats)
                && (!cameraChecked || room.features.includes(LOCATION_FEATURE.CAMERA))
                && (!displayChecked || room.features.includes(LOCATION_FEATURE.DISPLAY))
                && (!noRestrictChecked || !room.restricted)
                && (!noManagedChecked || !room.managed);
        });
        setFilteredRoomResults(rooms);
    }, [minimalSeats, cameraChecked, displayChecked, noRestrictChecked, noManagedChecked, availableOnlyChecked, startTimeFilter, endTimeFilter, availableOnlyFilter, roomResults, meetingList, refresh]);

    return (
        // Uncomment if there is need to bring back list view
        // props.view === "list" ?
        //     <RoomFinderResultsList
        //         roomResults={props.roomResults}
        //         selectedFloor={props.selectedFloor}
        //         startTime={props.startTime}
        //         startTimeStamp={props.startTimeStamp}
        //         timezoneValue={props.timezoneValue}
        //     />
        // :
        filteredRoomResults?.length !== 0 ?
            <React.Fragment>
                <Column>
                    <RoomFinderResultsTimeTable
                        roomResults={filteredRoomResults}
                        startTime={props.startTime}
                        setStartTime={props.setStartTime}
                        startTimeStamp={props.startTimeStamp}
                        setStartTimeStamp={props.setStartTimeStamp}
                        setStartTimeInput={props.setStartTimeInput}
                        setStartTimeStampInput={props.setStartTimeStampInput}
                        timezoneValue={props.timezoneValue}
                        onRefresh={props.onRefresh}
                        onSelectRoomAndTime={props.onSelectRoomAndTime}
                        open={props.open}
                        onClose={props.onClose}
                        onClickReserveButton={props.onClickReserveButton}
                        toasts={props.toasts}
                        onCloseToast={props.onCloseToast}
                        timezones={props.timezones}
                        selectedEvent={props.selectedEvent}
                    />
                    <Column width="100%" alignmentHorizontal="center" spacingInset="small" backgroundColor="white">
                        <StatusLegend
                            orientation="row"
                            statuses={["busy", "restricted", "available"]}
                            showStripes={true}
                        />
                    </Column>
                </Column>
            </React.Fragment>
            :
            searchNearby === false?
                <Column alignmentHorizontal={"center"} spacingInset="medium small">
                    {renderWarehouseIcon()}
                    <Heading level={2} type="h200">
                        No room found with room filters
                    </Heading>                            
                    <Text>Search nearby floors and buildings instead.</Text>
                    <Button onClick={onClickSearchNearby} type="tertiary">Search nearby floors and buildings</Button>
                </Column>
                :
                nearbyResults?.length > 0 ?
                    <React.Fragment>
                        <Column>
                            <RoomFinderResultsTimeTable
                                roomResults={nearbyResults}
                                startTime={props.startTime}
                                setStartTime={props.setStartTime}
                                startTimeStamp={props.startTimeStamp}
                                setStartTimeStamp={props.setStartTimeStamp}
                                setStartTimeInput={props.setStartTimeInput}
                                setStartTimeStampInput={props.setStartTimeStampInput}
                                timezoneValue={props.timezoneValue}
                                onRefresh={props.onRefresh}
                                onSelectRoomAndTime={props.onSelectRoomAndTime}
                                open={props.open}
                                onClose={props.onClose}
                                onClickReserveButton={props.onClickReserveButton}
                                toasts={props.toasts}
                                onCloseToast={props.onCloseToast}
                                timezones={props.timezones}
                                selectedEvent={props.selectedEvent}
                            />
                            <Column width="100%" alignmentHorizontal="center" spacingInset="small"
                                    backgroundColor="white">
                                <StatusLegend
                                    orientation="row"
                                    statuses={["busy", "restricted", "available"]}
                                    showStripes={true}
                                />
                            </Column>
                        </Column>
                    </React.Fragment>
                    :
                    <Column alignmentHorizontal={"center"} spacingInset="medium small">
                        <Heading level={2} type="h200" alignment={"center"}>
                            Loading rooms...
                        </Heading>
                        <Loader type={"circular"} size={"large"}/>
                    </Column>
    );
};

export default RoomFinderResults;