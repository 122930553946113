import React from "react";

import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";

import { renderExternalLink } from "../shared-utils";

const AlertsContainer = (props) => {
    const alerts = props.alerts;

    return (
        <Column width={props.width || "stretch"} maxWidth={props.maxWidth} spacingInset={props.spacingInset}>
            {alerts.map((alert) => (
                <Alert type={alert.type} size="medium" key={alert.value}>
                    <Text id={alert.id}>
                        <div>
                            <span dangerouslySetInnerHTML={{__html: alert.value}} />
                            {alert.link &&
                                <React.Fragment>
                                    &nbsp;
                                    {renderExternalLink(alert.linkText, alert.link)}
                                </React.Fragment>
                            }
                        </div>
                    </Text>
                </Alert>
            ))}
        </Column>
    );
};

export default AlertsContainer;
