import roundToNearestMinutes from "date-fns/roundToNearestMinutes";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import parse from "date-fns/parse";
import add from "date-fns/add";

import { pad } from "../meeting-roomfinder/utils";
import { TIME_FORMAT, TIME_CONSTANT } from "./shared-constants";
import { TIME_FORMAT as TIME_HOUR_CLOCK } from "./settings/settings-constants";


export const compareCalendarDay = (timeA, timeB, timezone) => {
    let dateA = utcToZonedTime(timeA, timezone);
    let dateB = utcToZonedTime(timeB, timezone);
    if (dateA.getFullYear() - dateB.getFullYear() !== 0) { return dateA.getFullYear() - dateB.getFullYear() }
    if (dateA.getMonth() - dateB.getMonth() !== 0) { return dateA.getMonth() - dateB.getMonth() }
    if (dateA.getDate() - dateB.getDate() !== 0) { return dateA.getDate() - dateB.getDate() }
    return 0
};

export const getFormattedDateTime = (time, timezone, dateFormat) => {
    let date = utcToZonedTime(time, timezone);
    return format(date, dateFormat, {timeZone: timezone});
};

export const getEndTime = (hours, startTime, timezone) => {
    return zonedTimeToUtc(`${startTime} 23:59:59`, timezone);
};

export const parseAndAddTime = (dateString, startTimeString, durationHours) => {
    let date = parse(`${dateString} ${startTimeString}`, "yyyy-MM-dd HH:mm", new Date());
    return add(date, {minutes: durationHours * 60});
};

export const getTime = (time, timezone) => {
    let date = utcToZonedTime(time, timezone);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
};

export const createTimeOptions = (tileStartTime, startTime, startTimeStamp, endTime, timezone) => {
    let options = [getFormattedDateTime(tileStartTime, timezone, 'HH:mm') + ":00"];
    let minutesToAdd = 15 - tileStartTime.getMinutes() % 15;
    let start = new Date(tileStartTime.getTime());
    start.setMinutes(start.getMinutes() + minutesToAdd);
    start.setMilliseconds(0);
    start.setSeconds(0);
    start = start.getTime();
    let endOfWindow = getEndTime(parseInt(startTimeStamp.split(":")[0]), startTime, timezone);
    let end = endTime < endOfWindow ? endTime : endOfWindow;

    while (start <= end.getTime()) {
        options.push(getFormattedDateTime(start, timezone, 'HH:mm:ss'));
        start = start + 0.25 * 60 * 60 * 1000;
    }
    if (end.getMinutes() === 59) {
        options.push(getFormattedDateTime(end.getTime(), timezone, 'HH:mm:ss'));
    }
    return options;
};

/**
 * Returns the formatted time passed in as the time unit specified.
 * Will return undefined if correct format isn't specified.
 *
 * @param {String} time - time represented as a string
 * @param {String} format - the format of the time param
 * @param {String} timeUnit - the time unit of the number returned (defaults to seconds)
 * @return {Number} the number of timeUnits represented by the time
 */
export const formattedTimeToTimeUnit = (time, format, timeUnit) => {
    let duration = 0;
    switch (format) {
        case TIME_FORMAT.HH_MM_SS:
            const formatArr = time.split(":");
            duration = +formatArr[0] * TIME_CONSTANT.ONE_HOUR_IN_SEC + +formatArr[1] * TIME_CONSTANT.ONE_MIN_IN_SEC + +formatArr[2];
            break;
        default:
            return undefined;
    }

    switch (timeUnit) {
        case TIME_CONSTANT.DAY_NAME:
            return duration / TIME_CONSTANT.ONE_DAY_IN_SEC;
        case TIME_CONSTANT.HOUR_NAME:
            return duration / TIME_CONSTANT.ONE_HOUR_IN_SEC;
        case TIME_CONSTANT.MIN_NAME:
            return duration / TIME_CONSTANT.ONE_MIN_IN_SEC;
        case TIME_CONSTANT.SEC_NAME:
        default:
            return duration;
    }
};

export const renderDurationLabel = (startTime, endTime, timeUnit = TIME_CONSTANT.SEC_NAME) => {
    switch (timeUnit) {
        case TIME_CONSTANT.DAY_NAME:
            startTime *= TIME_CONSTANT.ONE_DAY_IN_SEC;
            endTime *= TIME_CONSTANT.ONE_DAY_IN_SEC;
            break;
        case TIME_CONSTANT.HOUR_NAME:
            startTime *= TIME_CONSTANT.ONE_HOUR_IN_SEC;
            endTime *= TIME_CONSTANT.ONE_HOUR_IN_SEC;
            break;
        case TIME_CONSTANT.MIN_NAME:
            startTime *= TIME_CONSTANT.ONE_MIN_IN_SEC;
            endTime *= TIME_CONSTANT.ONE_MIN_IN_SEC;
            break;
        default:
            break;
    }

    if (startTime === endTime) {
        return "invalid";
    }

    let secondsDuration = endTime - startTime;
    if (startTime > endTime) {
        // treat the end time selected as the end time of the next day
        secondsDuration += TIME_CONSTANT.ONE_DAY_IN_SEC;
    }

    let duration = "";
    const timeUnits = [
        {name: TIME_CONSTANT.DAY_NAME, duration: TIME_CONSTANT.ONE_DAY_IN_SEC},
        {name: TIME_CONSTANT.HOUR_NAME, duration: TIME_CONSTANT.ONE_HOUR_IN_SEC},
        {name: TIME_CONSTANT.MIN_NAME, duration: TIME_CONSTANT.ONE_MIN_IN_SEC}
    ];
    timeUnits.forEach((timeUnit) => {
        const numTimeUnits = Math.floor(secondsDuration / timeUnit.duration);
        if (numTimeUnits) {
            duration += numTimeUnits + " " + timeUnit.name + `${numTimeUnits === 1 ? " " : "s "}`;
            secondsDuration %= timeUnit.duration;
        }
    });
    duration = duration.slice(0, -1);
    return duration;
};

export const to12HrString = (time24HrString) => {
    let hour = (time24HrString.split(':'))[0];
    let min = (time24HrString.split(':'))[1];
    let part = hour > 12 ? 'PM' : 'AM';

    hour = hour > 12 ? hour - 12 : hour;

    return (`${pad(hour, 2)}:${pad(min, 2)} ${part}`);
};

export const getFormattedCurrentDate = () => {
    return format(new Date(), "yyyy-MM-dd");
};

export const getFormattedCurrentTime = () => {
    return format(roundTimeToNearestQuarterHour(new Date(), "HH:mm:ss"));
};

export const roundTimeToNearestQuarterHour = (time) => {
    return roundToNearestMinutes(time, { nearestTo: 15 });
};

export const parseDatePickerDateString = (date) => {
    return parse(date, "yyyy-MM-dd", new Date());
};

export const formatIso = (date) => {
    return format(date, "yyyy-MM-dd");
};

// Return a new date object after converting between timezones
export const convertDateTimezone = (date, timezone, local = true) => {
    if (timezone) {
        // Convert from local timezone to selected timezone
        const timezoneDate = new Date(date.toLocaleString("en-US", {
                                 timeZone: timezone,
                             }));

        // If local is true, date is in local timezone, when local is false date is in specified timezone
        const difference = local ? date - timezoneDate : timezoneDate - date;

        const newDate = new Date(date.getTime() + difference);

        return newDate;
    }

    return date;
};

export const getReadableTime = (time, timezone, timeFormat) => {
    let formattedTime = getFormattedDateTime(time * 1000, timezone, 'HH:mm');
    let twelveHourIndicator = "";
    let hours = Number(formattedTime.slice(0,2));
    const minutes = formattedTime.slice(3,5);
    if (timeFormat === TIME_HOUR_CLOCK.TWELVE_HOUR) {
        twelveHourIndicator = hours <= 11 ? "AM" : "PM";
        hours = hours % 12 === 0 ? 12 : hours % 12;
    }
    return `${hours}:${minutes}${twelveHourIndicator}`;
};
