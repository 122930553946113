import { call, put, takeEvery, takeLatest, select, take } from "redux-saga/effects";
import { push } from "connected-react-router";

import {
    createPoll,
    findPolls,
    getAttendeePoll,
    getPoll,
    deletePoll,
    respondPoll,
    updatePoll,
    sendNotification
} from "../../api/apig/ams";
import { createMeeting, deleteMeeting, findMeetings } from "../../api/apig/ras";

import * as Actions from "../../meeting-polls/actions/action-types";
import * as PeopleActions from "../../people/actions/action-types";
import * as MeetingActions from "../../meeting-scheduler/actions/action-types";
import * as LocationActions from "../../shared/locations/actions/action-types";
import * as ChimeActions from "../../shared/chime/actions/action-types";
import * as FeedbackActions from "../../shared/feedback/actions/action-types";
import { SET_MEETINGS_LIST } from "../../shared/actions/action-types";
import {
    clearPollDetails,
    processingPollCompleted,
    setMeetingPollsLoaded,
    setReminderResponse,
    updatePoll as updatePollAction
} from "../actions";
import { setCreatingMeetingState } from "../../meeting-scheduler/actions";
import { refreshAgenda, setMeetingAgendaLoaded } from "../../shared/actions";
import { switchWorkflow } from "../../shared/workflow/actions";
import { showToast } from "../../shared/toasts/actions";
import { getFeedbackEligibility, setFeedbackAlertMessage } from "../../shared/feedback/actions";

import { getCalendarHoldBody } from "../poll-utils";
import { isBadRequestError, isErrorResponse, isMultiStatus } from "../../api/apig/apig-utils";
import {
    getErrorToast,
    getErrorToastWithComponent,
    getSuccessToast,
    getSuccessToastWithComponent
} from "../../shared/toasts/toast-utils";
import { getAlias, getIsEligible } from "../../../sagas/selector";
import { pathToWorkflow } from "../../shared/workflow/workflow-utils";
import { TOAST_COMPONENT, TOAST_TYPE } from "../../shared/toasts/toast-constants";
import { postCreateMeetingPollEventMetric, postUpdateMeetingPollEventMetric } from "../../shared/metrics/actions";
import { STATUS } from "../poll-constants";
import format from "date-fns/format";

export function* watchCreatePoll() {
    yield takeEvery(Actions.CREATE_POLL, createPollApi);
}

export function* createPollApi(action) {
    let createMeetingError = "";
    const timeSlots = action?.createPollRequest?.availability;

    if (timeSlots && Array.isArray(timeSlots)) {
        const subject = action?.createPollRequest?.subject;
        const organizer = action?.createPollRequest?.organizer;
        const body = getCalendarHoldBody(subject, organizer);
        const timezone = action?.createPollRequest?.timezone;
        const calendarHolds = [];

        for (const timeSlot of timeSlots) {
            const response = yield call(createMeeting, {
                sendInvitations: false,
                meeting: {
                    subject: `Automatic calendar hold for meeting poll: [${subject}]`,
                    body: {
                        type: "html",
                        value: body,
                    },
                    status: "tentative",
                    endTimezone: {id: timezone},
                    startTimezone: {id: timezone},
                    time: {
                        startTime: timeSlot.startTime,
                        endTime: timeSlot.endTime
                    },
                    organizer: organizer,
                    requiredAttendees: [],
                    // Reminder set to false, reminderMinutesBeforeStart parameter required to be passed in so I include it with default value
                    reminder: {isReminderSet: false, reminderMinutesBeforeStart: 1}
            }});

            const createMeetingResponse = JSON.parse(response);
            if (isErrorResponse(createMeetingResponse)) {
                createMeetingError = "Error creating poll while creating meeting.";
                yield put(processingPollCompleted());
                return;
            }

            calendarHolds.push({
                entryID: createMeetingResponse.entryID,
                startTime: timeSlot.startTime,
                endTime: timeSlot.endTime
            });
        }

        // Convert calendarHolds to milliseconds
        const convertedCalendarHolds = calendarHolds?.map((timeSlot) => ({
            entryID: timeSlot.entryID,
            startTime: timeSlot.startTime * 1000,
            endTime: timeSlot.endTime * 1000
        }));

        action.createPollRequest.calendarHolds = convertedCalendarHolds?.slice();

        // Convert availability to milliseconds
        const convertedAvailability = action.createPollRequest.availability?.map((timeSlot) => ({
            startTime: timeSlot.startTime * 1000,
            endTime: timeSlot.endTime * 1000
        }));
        action.createPollRequest.availability = convertedAvailability?.slice();
    }

    if (createMeetingError?.length > 0) {
        const toast = getErrorToast(createMeetingError);
        yield put(showToast(toast));
        yield put(processingPollCompleted());
        return;
    }

    const response = yield call(
        createPoll,
        action.createPollRequest
    );
    const createResponse = JSON.parse(response);
    if (isErrorResponse(createResponse)) {
        const toast = getErrorToast("Error creating poll.");
        yield put(showToast(toast));

        const responseBody = createResponse.body && JSON.parse(createResponse.body);
        yield put(processingPollCompleted());
        yield put(postCreateMeetingPollEventMetric(action?.createPollRequest?.organizer, false, responseBody?.message, { fromPage: action.fromPage }));
        return;
    }

    const toast = getSuccessToast("Poll created successfully.");

    // Clear attendees
    yield put({type: PeopleActions.CLEAR_DRAFT});

    // Check if user is eligible for a feedback modal instead of a success toast.
    let isEligible = false;
    if (toast.toastType === TOAST_TYPE.SUCCESS) {
        const alias = yield select(getAlias);
        yield put(getFeedbackEligibility(alias));
        yield take([FeedbackActions.SET_IS_ELIGIBLE]);
        isEligible = yield select(getIsEligible);
        yield put(setFeedbackAlertMessage("Poll created successfully."));
    }
    if (!isEligible) {
        yield put(showToast(toast));
    }

    yield put(push("/polls"));
    yield put(switchWorkflow(pathToWorkflow()));
    yield put(processingPollCompleted());

    const attendees = action?.createPollRequest?.attendees;
    const metricPayload = {
        fromPage : action.fromPage,
        pollId: createResponse?.pollID, 
        totalExternalAttendees: attendees?.filter((attendee) => attendee.external === true)?.length ?? 0, 
        totalInternalAttendees: attendees?.filter((attendee) => attendee.external === false)?.length ?? 0, 
        totalTimeSlotsSelected: timeSlots?.length,
        timeSlotsSelectedFromGridMode: action.timeSlotsSelectedFromGridMode,
        timeSlotsSelectedFromCalendarMode: action.timeSlotsSelectedFromCalendarMode,
    };
    yield put(postCreateMeetingPollEventMetric(
        action?.createPollRequest?.organizer, 
        true, 
        "", 
        metricPayload));
}

export function* watchFindPolls() {
    yield takeEvery(Actions.FIND_POLLS, findPollsApi);
}

export function* findPollsApi(action) {
    // Set as false to show the loading indicator
    yield put(setMeetingPollsLoaded(false));

    const response = yield call(
        findPolls,
        action.requestedOnBehalfOf,
        action.identity,
        action.startTime,
        action.endTime,
        action.pollStatus
    );
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast(`Error finding polls for user ${action.requestedOnBehalfOf}.`);
        yield put(showToast(toast));
        return;
    }

    const pollList = parsedResponse.polls;
    yield put({
        type: Actions.SET_POLL_LIST,
        pollList
    });
}

export function* watchGetAttendeePoll() {
    yield takeEvery(Actions.GET_ATTENDEE_POLL, getAttendeePollApi);
}

export function* getAttendeePollApi(action) {
    // Clear the poll details before executing getPoll call
    yield put(clearPollDetails());

    const response = yield call(
        getAttendeePoll,
        action.requestedOnBehalfOf,
        action.pollID
    );
    const pollDetails = JSON.parse(response);
    if (isErrorResponse(pollDetails)) {
        const toast = getErrorToast(`Error retrieving poll object with ID [${action.pollID}]`);
        yield put(showToast(toast));
        return;
    }

    yield put({
        type: Actions.SET_POLL_DETAILS,
        pollDetails
    });
}

export function* watchGetPoll() {
    yield takeEvery(Actions.GET_POLL, getPollApi);
}

export function* getPollApi(action) {
    // Clear the poll details before executing getPoll call
    yield put(clearPollDetails());

    const response = yield call(
        getPoll,
        action.requestedOnBehalfOf,
        action.pollID
    );
    const pollDetails = JSON.parse(response);
    if (isErrorResponse(pollDetails)) {
        const toast = getErrorToast(`Error retrieving poll object with ID [${action.pollID}]`);
        yield put(showToast(toast));
        return;
    }

    // Convert availability back to seconds
    const convertedAvailability = pollDetails.availability?.map((timeSlot) => ({
        startTime: timeSlot.startTime / 1000,
        endTime: timeSlot.endTime / 1000
    }));

    // Convert calendarHolds back to seconds
    const convertedCalendarHolds = pollDetails.calendarHolds?.map((timeSlot) => ({
        entryID: timeSlot.entryID,
        startTime: timeSlot.startTime / 1000,
        endTime: timeSlot.endTime / 1000
    }));

    // Convert attendees' availability back to seconds
    if (pollDetails && pollDetails.attendees) {
        pollDetails.attendees.forEach((attendee) => {
            if (attendee.availability) {
                attendee.availability.forEach((availability) => {
                    availability.startTime = availability.startTime / 1000;
                    availability.endTime = availability.endTime / 1000;
                })
            }
        })
    };

    pollDetails.availability = convertedAvailability?.slice();
    pollDetails.calendarHolds = convertedCalendarHolds?.slice();

    yield put({
        type: Actions.SET_POLL_DETAILS,
        pollDetails
    });
}

export function* watchDeletePoll() {
    yield takeEvery(Actions.DELETE_POLL, deletePollApi);
}

export function* deletePollApi(action) {
    const response = yield call(
        deletePoll,
        action.deletePollRequest
    );
    const deletePollResponse = JSON.parse(response);
    if (isErrorResponse(deletePollResponse)) {
        const toast = getErrorToast("Error deleting meeting poll.");
        yield put(showToast(toast));
        return;
    }

    const toast = getSuccessToast("Meeting poll deleted successfully.");

    // Clear attendees
    yield put({type: PeopleActions.CLEAR_DRAFT});

    yield put(showToast(toast));
    yield put(push("/polls"));
    yield put(switchWorkflow(pathToWorkflow()));
}

export function* watchRespondPoll() {
    yield takeEvery(Actions.RESPOND_POLL, respondPollApi);
}

export function* respondPollApi(action) {
    const response = yield call(
        respondPoll,
        action.requestedOnBehalfOf,
        action.pollID,
        action.availability
    );
    const respondPollResponse = JSON.parse(response);
    if (isErrorResponse(respondPollResponse) || isBadRequestError(respondPollResponse)) {
        const toast = getErrorToast("Error calling respond poll.");
        yield put(showToast(toast));
        return;
    }
}

export function* watchUpdatePoll() {
    yield takeEvery(Actions.UPDATE_POLL, updatePollApi);
}

export function* updatePollApi(action) {
    const createMeetingRequests = action?.createMeetingRequests;
    const deleteMeetingRequests = action?.deleteMeetingRequests;
    const calendarHolds = action?.updatePollRequest.changes.calendarHolds;
    const previousPollStatus = action?.previousPollStatus;
    const userAlias = action?.updatePollRequest.organizer;

    let createMeetingError = "";
    let deleteMeetingError = "";

    // Create new meetings for calendar holds
    for (const createMeetingRequest of createMeetingRequests) {
        let meeting = createMeetingRequest;
        const response = yield call(createMeeting, {
            sendInvitations: false,
            meeting
        });

        const createMeetingResponse = JSON.parse(response);
        if (isErrorResponse(createMeetingResponse)) {
            createMeetingError = "Error updating poll, fail to create calendar holds.";
            continue;
        }

        calendarHolds.push({
            entryID: createMeetingResponse.entryID,
            startTime: createMeetingRequest.time.startTime,
            endTime: createMeetingRequest.time.endTime
        });
    }

    if (createMeetingError?.length > 0) {
        const toast = getErrorToast(createMeetingError);
        yield put(showToast(toast));
        yield put(processingPollCompleted());
        yield put(postUpdateMeetingPollEventMetric(userAlias, false, previousPollStatus, createMeetingError));
        return;
    }

    for (const deleteMeetingRequest of deleteMeetingRequests) {
        const entryIDsToDelete = deleteMeetingRequest.uniqueOrEntryID;
        const calendarHoldIndex = calendarHolds.findIndex((calendarHold) => calendarHold.entryID === entryIDsToDelete);
        // Delete the calendar hold from the list after deletion
        calendarHolds.splice(calendarHoldIndex, 1);
    }

    // Convert calendarHolds to milliseconds
    const convertedCalendarHolds = calendarHolds?.map((timeSlot) => ({
        entryID: timeSlot.entryID,
        startTime: timeSlot.startTime * 1000,
        endTime: timeSlot.endTime * 1000
    }));

    // Assign the calendar holds after the necessary changes to create and delete meetings
    action.updatePollRequest.changes.calendarHolds = convertedCalendarHolds?.slice();

    // Convert availability to milliseconds
    const convertedAvailability = action.updatePollRequest.changes.availability?.map((timeSlot) => ({
        startTime: timeSlot.startTime * 1000,
        endTime: timeSlot.endTime * 1000
    }));
    action.updatePollRequest.changes.availability = convertedAvailability?.slice();
    
    const response = yield call(
        updatePoll,
        action.updatePollRequest
    );

    const updatePollResponse = JSON.parse(response);
    if (isErrorResponse(updatePollResponse)) {
        const toast = getErrorToast("Error updating meeting poll.");
        yield put(showToast(toast));
        yield put(processingPollCompleted());
        yield put(postUpdateMeetingPollEventMetric(userAlias, false, previousPollStatus, updatePollResponse));
        return;
    }

    // Delete meetings when calendar holds are updated
    for (const deleteMeetingRequest of deleteMeetingRequests) {
        const response = yield call(deleteMeeting, deleteMeetingRequest);

        const deleteMeetingResponse = JSON.parse(response);
        if (isErrorResponse(deleteMeetingResponse)) {
            deleteMeetingError = "Error updating poll, fail to delete calendar holds.";
            continue;
        }

        if (action.setMeetingListAction) {
            console.log(`Setting meeting list: ${action.setMeetingListAction}`);
            yield put(action.setMeetingListAction);
        }
    }

    if (deleteMeetingError?.length > 0) {
        const toast = getErrorToast(deleteMeetingError);
        yield put(showToast(toast));
        yield put(postUpdateMeetingPollEventMetric(userAlias, false, previousPollStatus, deleteMeetingError));
        return;
    }

    const toast = getSuccessToast("Meeting poll updated successfully.");

    // Clear attendees
    yield put({type: PeopleActions.CLEAR_DRAFT});

    yield put(showToast(toast));
    yield put(push("/polls"));
    yield put(switchWorkflow(pathToWorkflow()));
    yield put(processingPollCompleted());
    yield put(postUpdateMeetingPollEventMetric(userAlias, true, previousPollStatus));
}

export function* watchSendNotification() {
    yield takeEvery(Actions.SEND_NOTIFICATION, sendNotificationApi);
}

export function* sendNotificationApi(action) {
    const response = yield call(
        sendNotification,
        action.notificationType,
        action.requestedOnBehalfOf,
        action.pollID,
        action.toAddresses,
        action.subject,
        action.body
    );
    let email;
    if (action.toAddresses.length === 0)  {
        email = "all";
    } else {
        email = action.toAddresses[0];
    }
    const sendNotificationResponse = JSON.parse(response);
    if (isErrorResponse(sendNotificationResponse) || isBadRequestError(sendNotificationResponse)) {
        yield put(setReminderResponse(email, "failure"));
    } else if (isMultiStatus(sendNotificationResponse)) {
        yield put(setReminderResponse(email, "duplicate"));
    } else {
        yield put(setReminderResponse(action?.email === "all" ? action.email : email, "success"));
    }
    return;
}

export function* watchFindMeetingsForAvailability() {
    yield takeEvery(Actions.FIND_MEETINGS_FOR_AVAILABILITY, findMeetingsForAvailabilityApi);
}

export function* findMeetingsForAvailabilityApi(action) {
    const response = yield call(
        findMeetings,
        action.requestedOnBehalfOf,
        action.startTime,
        action.endTime,
        action.maxResults
    );
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const user = action.requestedOnBehalfOf.split("@")[0];
        const startDate = format(new Date(action.startTime), "yyyy-MM-dd");
        const endDate = format(new Date(action.startTime), "yyyy-MM-dd");
        const errorMsg = startDate === endDate ?
            `An error occurred when retrieving meetings on ${startDate} for ${user}'s calendar.` :
            `An error occurred when retrieving meetings from ${startDate} to ${endDate} on ${user}'s calendar.`;
        const toast = getErrorToast(errorMsg);
        yield put(showToast(toast));
        return;
    }
    const meetingList = parsedResponse.meetings;
    yield put({
        type: Actions.SET_MEETING_LIST_FOR_AVAILABILITY,
        meetingList
    });
    return meetingList;
}

export function* watchCreateMeetingForPoll() {
    yield takeLatest(Actions.CREATE_MEETING_FOR_POLL, createMeetingForPollApi);
}

export function* createMeetingForPollApi(action) {
    yield put(setCreatingMeetingState(true));

    // show the loading indicator
    yield put(setMeetingAgendaLoaded(false));

    let toast;
    const response = yield call(createMeeting, action.createMeetingRequest);
    const createMeetingResponse = JSON.parse(response);
    if (isErrorResponse(createMeetingResponse)) {
        if (createMeetingResponse.error.message?.toLowerCase().includes("quota")) {
            // Custom error toast for QuotaExceededException
            toast = getErrorToastWithComponent();
            toast.toastActionProps.text = "Meeting creation unsuccessful due to full mailbox.";
            toast.toastActionProps.linkText = "Learn more";
            toast.toastActionProps.href = "https://w.amazon.com/bin/view/UnifiedCommunications/Amazon_Meetings/issues/QuotaExceededException";
            toast.toastActionProps.componentName = TOAST_COMPONENT.LINK;
        } else {
            toast = getErrorToast("An error occurred when creating your meeting, please try again.");
        }

        yield put(showToast(toast));
        yield put(setCreatingMeetingState(false));
        return;
    } else {
        toast = getSuccessToastWithComponent();
        toast.toastActionProps.subject = action.createMeetingRequest.meeting.subject;
        toast.toastActionProps.entryId = createMeetingResponse.entryID;
        toast.toastActionProps.userEmail = action.createMeetingRequest.meeting.organizer;
        toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CREATED;
    }

    // Meeting creation clears draft and redirects user to the homepage.
    yield put({type: MeetingActions.CLEAR_DRAFT});
    yield put({type: PeopleActions.CLEAR_DRAFT});
    yield put({type: LocationActions.CLEAR_LOCATIONS_AND_ROOMS});
    yield put({type: ChimeActions.CLEAR_CHIME_PIN});
    yield put(showToast(toast));

    // need to refresh the meeting after the meeting is fully created.
    // otherwise won't be able to get the correct eligibility/checkIn status.
    yield put(refreshAgenda());
    yield put(setCreatingMeetingState(false));

    const pollDetails = action.pollDetails;
    const meetingListAction = SET_MEETINGS_LIST;
    let updatePollRequest = {
        identity: pollDetails.identity,
        pollID: pollDetails.pollID,
        requestedOnBehalfOf: pollDetails.requestedOnBehalfOf,
        organizer: pollDetails.organizer,
        changes: {
            status: STATUS.COMPLETE,
            calendarHolds: [],
            createdMeeting: {
                entryID: createMeetingResponse.entryID,
                startTime: action.createMeetingRequest.meeting.time.startTime * 1000,
                endTime: action.createMeetingRequest.meeting.time.endTime * 1000
            }
        }

        // TODO: Handle delegation for deletePoll in the future
        // delegate: "test",
        // ccDelegate: false
    };

    const calendarHolds = pollDetails?.calendarHolds;
    const deleteMeetingRequests = [];
    const createMeetingRequests = [];
    let setMeetingListAction;

    for (const calendarHold of calendarHolds) {
        let deleteMeetingRequest = {
            requestedOnBehalfOf: pollDetails.organizer, // RAS is using email for this attribute instead of alias
            uniqueOrEntryID: calendarHold.entryID,
            master: false, // Set to false for occurrence
            sendCancellations: false,
            body: {
                value: pollDetails.body,
                type: "html"
            }
        };

        if (action.meetingList) {
            // Get new meeting list with deleted meeting omitted
            let changedMeetingList = action.meetingList.filter((meeting) => meeting.entryID !== calendarHold.entryID);
            if (meetingListAction && changedMeetingList) {
                setMeetingListAction = {
                    type: meetingListAction,
                    meetingList: changedMeetingList,
                };
            }
        }
        deleteMeetingRequests.push(deleteMeetingRequest);
    }

    yield put(updatePollAction(updatePollRequest, createMeetingRequests, deleteMeetingRequests, setMeetingListAction));
}



const sagas = [
    watchGetAttendeePoll(),
    watchGetPoll(),
    watchCreatePoll(),
    watchFindPolls(),
    watchDeletePoll(),
    watchRespondPoll(),
    watchUpdatePoll(),
    watchSendNotification(),
    watchFindMeetingsForAvailability(),
    watchCreateMeetingForPoll()
];

export default sagas;
