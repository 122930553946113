import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import Popover from "@amzn/meridian/popover";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";

import tealLightTokens from "@amzn/meridian-tokens/theme/teal-light";
import closeTokens from "@amzn/meridian-tokens/base/icon/close";

import { ReactComponent as MegaphoneIcon } from "../../../assets/icons/general/megaphone.svg";

import { launchAnnouncements } from "../launch-announcements";
import { ANNOUNCEMENTS_COUNT, ANNOUNCEMENTS_WIKI } from "../announcements-constants";
import { convertEpoch } from "../../meeting-scheduler/meeting-scheduler-utils";
import { COOKIE, SCREEN_SIZE, TIME_CONSTANT } from "../../shared/shared-constants";
import { getCookie, setCookie, renderExternalLink } from "../../shared/shared-utils";

const MeetingsAnnouncements = (props) => {
    const darkMode = props.darkMode;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;

    const [announcementsPopoverOpen, setAnnouncementsPopoverOpen] = useState(false);
    const announcementsButtonRef = useRef();
    const announcementsCloseButtonRef = useRef();

    const onClickAnnouncementsButton = useCallback(() => setAnnouncementsPopoverOpen(true), []);
    const onClose = () => {
        // Set cookie time to the most recent announcement's time to track which announcements have been viewed
        announcements.length && setCookie(COOKIE.LAST_VIEWED_ANNOUNCEMENT_TIME, announcements[0].time);
        setAnnouncementsPopoverOpen(false);
    };

    const lastViewedAnnouncementTime = getCookie(COOKIE.LAST_VIEWED_ANNOUNCEMENT_TIME); // Epoch seconds for the last viewed announcement
    const today = new Date();
    const oneWeekAgo = (today.getTime() / 1000) - TIME_CONSTANT.ONE_WEEK_IN_SEC;

    // Get an array grouping the announcements by time period
    const groupAnnouncements = (announcements) => {
        const groups = [
            {
                label: "This week",
                announcements: [],
            },
            {
                label: "This month",
                announcements: [],
            },
            {
                label: "Previous",
                announcements: [],
            },
        ];

        announcements.forEach((announcement) => {
            const announcementDate = new Date(announcement.time * 1000);

            // Group the announcements based on when they occurred
            if (announcement.time > oneWeekAgo) {
                groups[0].announcements.push(announcement);
            } else if (today.getMonth() === announcementDate.getMonth() && today.getFullYear() === announcementDate.getFullYear()) {
                groups[1].announcements.push(announcement);
            } else {
                groups[2].announcements.push(announcement);
            }
        });

        return groups;
    };

    const announcements = useMemo(() => launchAnnouncements?.slice(0, ANNOUNCEMENTS_COUNT) || [], []);
    const announcementGroups = groupAnnouncements(announcements);

    useEffect(() => {
        // If the user hasn't seen a new announcement that was made within the week, automatically open the announcement popover
        if (announcements.length && (!lastViewedAnnouncementTime || lastViewedAnnouncementTime < announcements[0].time) && announcements[0].time > oneWeekAgo) {
            setAnnouncementsPopoverOpen(true);
        }
    }, [announcements, lastViewedAnnouncementTime, oneWeekAgo]);

    return (
        <React.Fragment>
            <Button
                onClick={onClickAnnouncementsButton}
                ref={announcementsButtonRef}
                size="small"
                type="icon"
                label="Open announcements popover"
            >
                <MegaphoneIcon fill={darkMode ? "#FFFFFF" : "#232F3E"} />
            </Button>
            <Theme tokens={tealLightTokens}>
                <Popover
                    anchorNode={announcementsButtonRef.current}
                    open={announcementsPopoverOpen}
                    onClose={onClose}
                    spacingInset="none"
                    position="bottom"
                >
                    <div style={{
                        boxSizing: "content-box",
                        position: "absolute",
                        left: "0px",
                        backgroundColor: "#F9FAFA", // Meridian Gray-50
                        borderLeft: "inherit",
                        borderRight: "inherit",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        height: "48px",
                        width: screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "340px" : "300px",
                        zIndex: "1",
                    }} />
                    <Column
                        alignmentHorizontal="center"
                        spacing="small"
                        spacingInset="small"
                        width={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "340px" : "300px"}
                        maxHeight="400px"
                    >
                        <div style={{zIndex: "2", width: "100%"}}>
                            <Row spacing="small" spacingInset="none none none small" width="100%" alignmentHorizontal="justify" alignmentVertical="center">
                                <Text type="h200">
                                    What's new?
                                </Text>
                                <Button onClick={onClose} type="icon" size="small" ref={announcementsCloseButtonRef}>
                                    <Icon tokens={closeTokens}>
                                        Close announcements popover
                                    </Icon>
                                </Button>
                            </Row>
                        </div>
                        <Column spacing="small" spacingInset="small" width="100%" overflowY="auto">
                            {announcementGroups.map((group) => (
                                <React.Fragment key={group.label}>
                                    {group.announcements.length !== 0 &&
                                        <Row alignmenthorizontal="center" widths={["fill", "fit", "fill"]}>
                                            <div style={{
                                                height: "1px",
                                                borderBottom: "1px solid #E7E9E9", // Meridian Gray-200
                                            }} />
                                            <Text type="b200" color="secondary">{group.label}</Text>
                                            <div style={{
                                                height: "1px",
                                                borderBottom: "1px solid #E7E9E9", // Meridian Gray-200
                                            }} />
                                        </Row>
                                    }
                                    {group.announcements.map((announcement, announcementIndex) => (
                                        <Column key={announcementIndex} spacing="small" width="100%">
                                            <div style={{
                                                width: "100%",
                                                borderRadius: "4px",
                                                backgroundColor: "#F0F1F2", // Meridian Gray-100
                                            }}>
                                                <Column spacing="small" spacingInset="small">
                                                    <Row widths={["fill", "fit"]} alignmentHorizontal="justify" alignmentVertical="top">
                                                        <Text type="b200">
                                                            <b>{announcement.title}</b>
                                                        </Text>
                                                        {announcement.time &&
                                                            <Text type="b200" color="secondary">
                                                                {convertEpoch(announcement.time, "shortDateYear")}
                                                            </Text>
                                                        }
                                                    </Row>
                                                    <Text type="b200">
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{__html: announcement.description}}></div>
                                                        </div>
                                                        {renderExternalLink("Learn more", announcement.href)}
                                                    </Text>
                                                </Column>
                                            </div>
                                            {announcementIndex !== group.announcements.length - 1 &&
                                                <div style={{
                                                    height: "1px",
                                                    width: "100%",
                                                    borderBottom: "1px solid #E7E9E9", // Meridian Gray-200
                                                }} />
                                            }
                                        </Column>
                                    ))}
                                </React.Fragment>
                            ))}
                        </Column>
                        <div style={{zIndex: "2", width: "100%"}}>
                            <Column spacingInset="none none xxsmall none" height="24px" width="100%" alignmentHorizontal="center" alignmentVertical="center">
                                <Text type="b200">
                                    {renderExternalLink(ANNOUNCEMENTS_WIKI.LABEL, ANNOUNCEMENTS_WIKI.HREF)}
                                </Text>
                            </Column>
                        </div>
                    </Column>
                    <div style={{
                        boxSizing: "content-box",
                        position: "absolute",
                        left: "0px",
                        bottom: "0px",
                        backgroundColor: "#F9FAFA", // Meridian Gray-50
                        border: "inherit",
                        borderTop: "1px solid #BBC0C1", // Meridian Gray-300
                        borderBottomLeftRadius: "4px",
                        borderBottomRightRadius: "4px",
                        height: "40px",
                        width: screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "340px" : "300px",
                        zIndex: "1",
                    }} />
                </Popover>
            </Theme>
        </React.Fragment>
    );
};

export default MeetingsAnnouncements;
