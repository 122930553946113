import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useImperativeHandle,
} from "react"
import PropTypes from "prop-types"
import uniqueId from "lodash/uniqueId"
import noop from "lodash/noop"
import { cx, css } from "emotion"
import inputTokens from "@amzn/meridian-tokens/component/input"
import { useTheme } from "../theme"
import InputBox from "../_input-box"
import inputResetStyles from "../../_styles/input-reset"
import { memoizeTokenStyles } from "../../_utils/token"
import filterPropsByPrefix from "../../_utils/filter-props-by-prefix"

const inputStyles = memoizeTokenStyles(
  (t, { size, disabled, empty }) =>
    css(
      inputResetStyles({
        empty,
        textColor: t("foregroundColor", disabled ? "disabled" : "default"),
        placeholderColor: t(
          "placeholderColor",
          disabled ? "disabled" : "default"
        ),
      }),
      {
        height: t("lineHeight", size),
        lineHeight: t("lineHeight", size),
        textOverflow: "ellipsis",
      }
    ),
  ["size", "disabled", "empty"]
)

const Input = React.forwardRef((props, ref) => {
  const t = useTheme(inputTokens, "input")
  const [focusState, setFocusState] = useState(false)
  const inputRef = useRef()
  const { id: idProp } = props
  const id = useMemo(() => idProp || uniqueId("input-"), [idProp])
  const passThroughProps = filterPropsByPrefix(props, ["data-", "aria-"])

  // Make sure we never allow a value of `undefined` or `null`, which would put
  // the underlying input element in an uncontrolled state and allow the DOM and
  // the component to get out of sync.
  const value =
    props.value === undefined || props.value === null ? "" : props.value
  const focus = props.focus !== undefined ? props.focus : focusState
  const mask = props.type === "password" ? undefined : props.mask
  const empty = value === "" && (!mask || !focus)
  const {
    onChange: onChangeProp,
    onFocus: onFocusProp,
    onBlur: onBlurProp,
  } = props

  const onChange = useCallback(
    event => {
      const { value } = event.target
      const pattern = mask
        ? undefined
        : props.pattern
        ? props.pattern
        : props.type === "number"
        ? /^(-?)([0-9]*)(\.?)([0-9]*)$/
        : undefined
      if (
        !props.disabled &&
        (!pattern || value === "" || value.match(pattern))
      ) {
        onChangeProp(value)
      }
    },
    [props.pattern, props.type, props.disabled, mask, onChangeProp]
  )

  /**
   * Focus on the input anytime the user mouses down on the container. This
   * mimicks the default browser behavior when a user mouses down on an input.
   */
  const onMouseDownContainer = useCallback(
    event => {
      if (inputRef.current && event.target !== inputRef.current) {
        event.preventDefault()
        inputRef.current.focus()
      }
    },
    [inputRef]
  )

  const onFocus = useCallback(
    event => {
      setFocusState(true)
      onFocusProp(event)
    },
    [setFocusState, onFocusProp]
  )

  const onBlur = useCallback(
    event => {
      setFocusState(false)
      onBlurProp(event)
    },
    [setFocusState, onBlurProp]
  )

  const inputProps = {
    ...passThroughProps,
    onFocus,
    onBlur,
    className: cx(inputStyles(t, { ...props, empty }), props.className),
    id,
    // HACK: https://sim.amazon.com/issues/P17584529
    type: props.type === "number" ? "text" : props.type,
    placeholder: props.placeholder,
    value,
    onChange,
    disabled: props.disabled,
    name: props.name,
    autoFocus: props.autoFocus,
    autoComplete: props.autoFill ? undefined : "off",
    onKeyDown: props.onKeyDown,
    role: props.role,
  }

  // react-input-mask only supports callback refs, so we have to wrap our object
  // ref in a callback to make it happy.
  const maskInputRef = useCallback(node => (inputRef.current = node), [
    inputRef,
  ])

  // Pass our internal inputRef on up to the forwarded ref. This allows us to
  // use the input ref internally (e.g. see onMouseDownContainer) and still give
  // the parent access to it.
  useImperativeHandle(ref, () => inputRef.current, [inputRef])

  return (
    <InputBox
      size={props.label ? "xlarge" : props.size}
      label={props.label}
      prefix={props.prefix}
      suffix={props.suffix}
      error={props.error}
      disabled={props.disabled}
      focus={focus}
      empty={empty}
      width={props.width}
      onMouseDown={onMouseDownContainer}
      htmlFor={id}
      cursor="text"
      prefixIconTokens={props.prefixIconTokens}
      suffixIconTokens={props.suffixIconTokens}
    >
      <span role="status" aria-live="polite" style={{position:"absolute",clip:"rect(1px,1px,1px,1px)"}}>
        {props.description}
      </span>
      {mask ? (
        mask.getElement(
          { ...inputProps, inputRef: maskInputRef },
          // If we're forcing focus on an input with a mask that only
          // shows on focus (!alwaysShow), then force the mask too.
          props.focus !== undefined && !mask.alwaysShow
            ? { alwaysShow: props.focus }
            : {}
        )
      ) : (
        <input {...inputProps} ref={inputRef} />
      )}
      {props.children}
    </InputBox>
  )
})

Input.displayName = "Input"

Input.propTypes = {
  /**
   * All props prefixed with `aria-` are accepted. This is useful for adding [aria
   * attributes](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA) for improved accessibility.
   *
   * If adding a label with `aria-label`, first consider using the `label` prop
   * which will add a visible label and set the size to `xlarge`.
   *
   * @since 5.x
   */
  "aria-*": PropTypes.string,
  /**
   * Allow the browser to suggest auto-fill values for this input.
   *
   * Note that not all browsers respect this property. Some browsers (e.g.
   * Chrome 78) may choose to *always* allow auto-fill regardless of the value
   * of this property.
   */
  autoFill: PropTypes.bool,
  /**
   * If set to true then the input will grab focus as soon as it's mounted.
   *
   * This uses HTML's native `autofocus` attribute. Use this sparingly as
   * there are [common accessibility pitfalls](https://www.brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/)
   * associated with this behavior.
   */
  autoFocus: PropTypes.bool,
  /**
   * Specify custom children that will be rendered as adjacent siblings to the
   * text input element.
   *
   * This is an advanced feature designed to allow custom overlays on an input.
   * You should not need this prop unless you are building an advanced component
   * on top of the input.
   */
  children: PropTypes.node,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  /**
   * All props prefaced with `data-` are accepted. This is useful for adding selectors
   * for integrating with other libraries such as Amplify Analytics or Cypress
   *
   * @since 4.x
   */
  "data-*": PropTypes.string,
  /**
   * This disables interaction with the component and applies special visual
   * styles to indicate that it's not interactive.
   */
  disabled: PropTypes.bool,
  /**
   * Show the component in an error state. Use this to indicate that the value
   * of the component is invalid.
   *
   * Consider pairing this state with a small error `Alert` component below
   * or to the right of the component explaining what the error is.
   */
  error: PropTypes.bool,
  /**
   * This can be used to force the visible focus state of the input on or off.
   * This only affects the display of the focus ring, it does not change the
   * focus state of the input as far as the browser is concerned.
   *
   * Note that this should only be used when the input is still functionally
   * focused, despite not having the user's actual focus (e.g. typing would still
   * update the value of the input). This situation is only likely if you're
   * composing the Input with other components to build a more complex input
   * control.
   */
  focus: PropTypes.bool,
  /**
   * An id for the input that is unique among other inputs.
   *
   * This is only necessary if you need to use an input `size` other than xlarge
   * and want to add your own custom label for the specific input id,
   * eg: <Text tag="label" htmlFor="my-custom-id"/>
   *
   * It is recommended to use the `label` prop for almost all other cases,
   * which will set the `size` of the input to xlarge.
   *
   * @since 5.x
   */
  id: PropTypes.string,
  /**
   * A label for the input.
   *
   * If this prop is provided the `size` prop will be ignored and set to `xlarge`.
   */
  label: PropTypes.string,
  /**
   * Mask the input's value in order to force it to conform to a particular
   * format or template.
   *
   * This is helpful for things like formatting phone numbers:
   *
   * ```
   * import Input from "@amzn/meridian/input"
   * import InputMask from "@amzn/meridian/input/mask"
   *
   * const phoneMask = new InputMask("(###)###-####")
   * <Input type="tel" mask={phoneMask} />
   * ```
   *
   * The string passed to the mask accepts three special placeholder characters
   * by default:
   * - `"#"` - A placeholder for any digit [0-9]
   * - `"x"` - A placeholder for any latin character [A-Za-z]
   * - `"*"` - A placeholder for any latin character or digit [A-Za-z0-9]
   *
   * Custom placeholder characters can be set by passing an object of options as
   * the second argument to `InputMask`:
   *
   * ```
   * new InputMask("(^^^)^^^-^^^", { formatChars: { "^": "[1-8]" } })
   * ```
   *
   * Note that if the input type is "password" then this prop will be ignored.
   * Note that input masks should not be blank and should always include template
   * characters to indicate to the user that the input is masked.
   */
  mask: PropTypes.object,
  /**
   * The name of the component's form control. If the component is placed in a
   * form, this will be the name associated with the component's data when the
   * form is submitted.
   */
  name: PropTypes.string,
  /**
   * This can be used to ensure that input matches a specific pattern defined
   * by a regular expression. If the user tries to enter a value that does not
   * match the pattern then `onChange` will simply not be triggered.
   *
   * Note that if the input type is `"password"` or an input mask is provided via the
   * `mask` prop then this prop will be ignored.
   */
  pattern: PropTypes.instanceOf(RegExp),
  /**
   * Text that is shown in place of the input's value when the input is empty.
   *
   * This text is not visible when a value is entered in the input, so it should
   * not contain essential information that should be persisted. For that,
   * consider using the `label` prop.
   */
  placeholder: PropTypes.string,
  /**
   * Static text to show in an end-cap prepended to the component.
   *
   * To add a selectable prefix, see the `InputGroup` component.
   */
  prefix: PropTypes.string,
  /**
   * Pass an object of icon tokens here (imported from `@amzn/meridian-tokens/base/icon/<icon-name>`)
   * in order to show an icon prepended to the input's text-entry area.
   */
  prefixIconTokens: PropTypes.object,
  /**
   * Prop adds a role to the input for accessibility
   *
   * @added by MeetEx team (@camei @alvinyu)
   */
  role: PropTypes.string,
  /**
   * Sets the size of the component using a preset.
   *
   * If the `label` prop is provided this prop will be ignored (the label
   * requires a particular amount of space which is not configurable).
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  /**
   * Static text to show in an end-cap appended to the component.
   *
   * To add a selectable suffix, see the `InputGroup` component.
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Pass an object of icon tokens here (imported from `@amzn/meridian-tokens/base/icon/<icon-name>`)
   * in order to show an icon appended to the input's text-entry area.
   */
  suffixIconTokens: PropTypes.object,
  /**
   * The data type of the input's value.
   */
  type: PropTypes.oneOf([
    "date",
    "email",
    "number",
    "password",
    "search",
    "tel",
    "text",
    "url",
    "time",
  ]),
  /**
   * The value of the input.
   *
   * Update this when `onChange` is called to ensure the component is interactive
   * (see the documentation for the `onChange` prop).
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   */
  onBlur: PropTypes.func,
  /**
   * A function that will be called when the user attempts to change the value
   * of the component (e.g. by typing in the input). The new value will be
   * passed as the first argument to this function.
   *
   * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
   * so you must track the values provided to this function somewhere in state
   * and pass them back via the `value` prop in order to make this component
   * interactive.
   *
   * Note: For Inputs with `type="number"`, the new value passed to `onChange`
   * will be a `string` _not_ a `number`. The `string` type is necessary for
   * allowing users to enter floats like `1.5`. To arrive at `1.5`, a user needs
   * to enter the partial value `1.`. At this point, parsing the partial value
   * with `parseFloat` or `parseInt` will remove the decimal point, effectively
   * blocking the user from completing their entry.
   */
  onChange: PropTypes.func,
  /**
   * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   */
  onFocus: PropTypes.func,
  /**
   * This is called when the component has focus and a keyboard key is
   * pressed down. The [keydown event](https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event)
   * is passed as the first argument to the function.
   */
  onKeyDown: PropTypes.func,
  description: PropTypes.string,
}

Input.defaultProps = {
  size: "medium",
  type: "text",
  onFocus: noop,
  onBlur: noop,
  children: null,
  value: "",
  autoFill: true,
}

export default Input
