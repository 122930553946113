import React, { useEffect, useCallback, useRef, useState } from "react";

import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";
import Column from "@amzn/meridian/column";
import Link from "@amzn/meridian/link";
import Popover, { PopoverHeader } from "@amzn/meridian/popover";
import Text from "@amzn/meridian/text";

import { getMeetingDaysLabel } from "../../meeting-scheduler/meeting-scheduler-utils.js";
import { addAccessibilityPropertiesToLink } from "../accessibility-utils";
import { ALERTS } from "../shared-constants";

const DaysOfWeekPopover = (props) => {
    const repeatSelected = props.repeatSelected || "none";
    const repeatDays = props.repeatDays || [];
    const setRefreshSuggestions = props.setRefreshSuggestions;

    // Meeting days of week
    const [daysOfWeek, setDaysOfWeek] = [props.daysOfWeek, props.setDaysOfWeek];
    const [daysOfWeekLabel, setDaysOfWeekLabel] = [props.daysOfWeekLabel, props.setDaysOfWeekLabel];
    const disabledDaysOfWeek = props.disabledDaysOfWeek || [];

    // Days of week popover
    const daysOfWeekRef = useRef();
    const [daysOfWeekOpen, setDaysOfWeekOpen] = useState(false);
    const onDaysOfWeekOpen = useCallback(() => setDaysOfWeekOpen(true), []);
    const onDaysOfWeekClose = useCallback(() => {
        setDaysOfWeekOpen(false);
        if (setRefreshSuggestions) {
            setRefreshSuggestions(true);
        }
    }, [setRefreshSuggestions]);

    const onChangeDaysOfWeek = (value) => {
        setDaysOfWeek(value);
        getMeetingDaysLabel(value, repeatSelected, setDaysOfWeekLabel);
    };

    useEffect(() => {
        addAccessibilityPropertiesToLink("amw-days-selection-id", [
            ALERTS.NO_DAYS_OF_WEEK_SELECTED.id
        ]);
    }, []);

    return (
        <React.Fragment>
            <div id="amw-days-selection-id">
                <Text type="b100">
                    <Link type="secondary" ref={daysOfWeekRef} onClick={onDaysOfWeekOpen}>{daysOfWeekLabel}</Link>
                </Text>
            </div>
            <Popover
                anchorNode={daysOfWeekRef.current}
                open={daysOfWeekOpen}
                onClose={onDaysOfWeekClose}
                position="bottom"
            >
                <PopoverHeader closeLabel="Close">{repeatSelected === "none" ? "Only on..." : "First meeting on..."}</PopoverHeader>
                <Column spacing="none">
                    <ButtonGroup type="outline" value={daysOfWeek} onChange={onChangeDaysOfWeek}>
                        <ButtonOption disabled={(repeatSelected !== "none" && !repeatDays.includes("Sun")) || disabledDaysOfWeek.includes("Sun")} value="Sun">S</ButtonOption>
                        <ButtonOption disabled={(repeatSelected !== "none" && !repeatDays.includes("Mon")) || disabledDaysOfWeek.includes("Mon")} value="Mon">M</ButtonOption>
                        <ButtonOption disabled={(repeatSelected !== "none" && !repeatDays.includes("Tue")) || disabledDaysOfWeek.includes("Tue")} value="Tue">T</ButtonOption>
                        <ButtonOption disabled={(repeatSelected !== "none" && !repeatDays.includes("Wed")) || disabledDaysOfWeek.includes("Wed")} value="Wed">W</ButtonOption>
                        <ButtonOption disabled={(repeatSelected !== "none" && !repeatDays.includes("Thu")) || disabledDaysOfWeek.includes("Thu")} value="Thu">T</ButtonOption>
                        <ButtonOption disabled={(repeatSelected !== "none" && !repeatDays.includes("Fri")) || disabledDaysOfWeek.includes("Fri")} value="Fri">F</ButtonOption>
                        <ButtonOption disabled={(repeatSelected !== "none" && !repeatDays.includes("Sat")) || disabledDaysOfWeek.includes("Sat")} value="Sat">S</ButtonOption>
                    </ButtonGroup>
                    {!daysOfWeek.length &&
                        <Text type="b100" color="error">*Select at least one day</Text>
                    }
                </Column>
            </Popover>
        </React.Fragment>
    );
};

export default DaysOfWeekPopover;
