import { SET_TIMEZONES } from "../actions/action-types";
import { updateTimezonesInfo } from "../timezones-utils";

const initialState = {
    browserTimezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timezones: []
};

function timezonesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_TIMEZONES:
            const timezones = updateTimezonesInfo(action.timezones);
            return {
                ...state,
                timezones: timezones
            };
        default:
            return state;
    };
};

export default timezonesReducer;
