import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import SearchField, { SearchSuggestion, SearchSuggestionFooter, SearchSuggestionHeader } from "@amzn/meridian/search-field";
import Text from "@amzn/meridian/text";
import { getPermissionGroupSuggestionsSelector } from "../../../sagas/selector";
import { clearPermissionGroupSuggestions, getPermissionGroupSuggestions } from "../actions";
import { NO_SUGGESTIONS_FOUND } from "../livestream-constants";

const PermissionGroupSearchField = (props) => {
    const [query, setQuery] = useState("");
    const dispatch = useDispatch();
    const groupSuggestions = useSelector(getPermissionGroupSuggestionsSelector);
    const onClearGroupSuggestions = () => dispatch(clearPermissionGroupSuggestions());
    const onGetGroupSuggestions = (query) => dispatch(getPermissionGroupSuggestions(query));

    const onChange = (query) => {
        setQuery(query);
        onClearGroupSuggestions();
        if (query.trim().length > 2) {
            onGetGroupSuggestions({name: query, type: props.type});
        }
    };

    const onClear = () => {
        setQuery("");
        onClearGroupSuggestions();
    };

    const onClick = (name) => {
        setQuery("");
        props.onClick(name);
        onClearGroupSuggestions();
    };

    const searchSuggestion = (suggestion, index) => (
        <SearchSuggestion onClick={(name) => onClick(name)} key={index+"groupSuggestion"}>
            {suggestion.name}
        </SearchSuggestion>
    );

    return (
        <SearchField
            name="select-accountName"
            onChange={onChange}
            onClear={onClear}
            onSubmit={() => {}} // feature disabled
            placeholder="Search account"
            value={query}
        >
            <SearchSuggestionHeader>Name suggestions</SearchSuggestionHeader>
            {(query.trim().length > 2) && groupSuggestions?.length > 0 && groupSuggestions?.map(searchSuggestion)}
            {(query.trim().length > 2) && !groupSuggestions &&
                <Row alignmentHorizontal="center" spacingInset="400 none 400">
                    <Loader size="medium"/>
                </Row>
            }
            {(query.trim().length > 2) && groupSuggestions && !groupSuggestions.length &&
                <Column alignmentHorizontal="center" spacing="none" spacingInset="small">
                    <Text alignment="center">{NO_SUGGESTIONS_FOUND}</Text>
                </Column>
            }
            {query.trim().length <= 2 && <Column/>}
            <SearchSuggestionFooter>
                <Row>
                    {query.trim().length >= 3 &&
                        <Text>{`${groupSuggestions?.length ? groupSuggestions?.length : 0} Result${groupSuggestions?.length === 1 ? "" : "s"}`}</Text>}
                    {query.trim().length < 3 &&
                        <Alert size="small">Requires at least 3 letters.</Alert>}
                </Row>
            </SearchSuggestionFooter>
        </SearchField>
    );
};

export default PermissionGroupSearchField;
