import React from "react"
import PropTypes from "prop-types"
import { css, cx } from "emotion"
import scrimTokens from "@amzn/meridian-tokens/component/scrim"
import { useTheme } from "../theme"
import withAnimateMount, { AnimateFade } from "../animate-mount"
import onDirectClick from "../../_utils/on-direct-click"

const AnimateFadeWithMount = withAnimateMount(AnimateFade)

const styles = t =>
  css({
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: t("elevation"),
    backgroundColor: t("backgroundColor"),
  })

/**
 * Don't bother updating the scrim if it's persisting in a closed state.
 */
const shouldNotUpdate = (prevProps, nextProps) =>
  !prevProps.open && !nextProps.open

const Scrim = React.memo(
  React.forwardRef((props, ref) => {
    const t = useTheme(scrimTokens, "scrim")
    return (
      <AnimateFadeWithMount
        open={props.open}
        onOpen={props.onOpen}
        duration={props.duration || t("motionTiming")}
        easing={props.easing || t("motionFunction")}
        type="fade"
      >
        {({ className, onTransitionEnd }) => (
          // Regarding role=none, see:
          // https://www.scottohara.me/blog/2018/05/05/hidden-vs-none.html#what-does-rolenone-and-rolepresentation-do
          <div
            role="none"
            className={cx(className, styles(t), props.className)}
            onTransitionEnd={onTransitionEnd}
            onMouseDown={onDirectClick(props.onClick)}
            ref={ref}
          >
            {props.children}
          </div>
        )}
      </AnimateFadeWithMount>
    )
  }),
  shouldNotUpdate
)

Scrim.displayName = "Scrim"

Scrim.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  easing: PropTypes.string,
  onClick: PropTypes.func,
  onOpen: PropTypes.func,
}

Scrim.defaultProps = {
  open: false,
  className: null,
}

export default Scrim
