module.exports = {
    WORKFLOWS: {
        HOME: {
            NAME: "home_flow",
            PAGE: "home_page",
            HREF: "#/"
        },
        CALENDAR: {
            NAME: "calendar_flow",
            PAGE: "calendar_page",
            HREF: "#/calendar"
        },
        SIMPLE_MEETING: {
            NAME: "simple_meeting_flow",
            PAGE: undefined,
            HREF: undefined
        },
        STANDARD_MEETING: {
            NAME: "standard_meeting_flow",
            PAGE: "standard_meeting_page",
            HREF: "#/create-meeting"
        },
        EDIT_MEETING: {
            NAME: "edit_meeting_flow",
            PAGE: "standard_meeting_page",
            HREF: "#/edit-meeting"
        },
        ROOM_BOOKING: {
            NAME: "room_booking_flow",
            PAGE: "room_booking_page",
            HREF: "#/room-booking"
        },
        OOTO: {
            NAME: "ooto_flow",
            PAGE: "ooto_page",
            HREF: "#/ooto"
        },
        MAPS: {
            NAME: "maps_flow",
            PAGE: "maps_page",
            HREF: "#/availability-sharing"
        },
        POLL_CREATE: {
            NAME: "poll_create",
            PAGE: "poll_create",
            HREF: "#/create-poll"
        },
        POLL_DASHBOARD: {
            NAME: "poll_dashboard",
            PAGE: "poll_dashboard",
            HREF: "#/polls"
        },
        EDIT_POLL: {
            NAME: "edit_poll",
            PAGE: "poll_edit",
            HREF: "#/edit-poll"
        },
        RESPOND_POLL: {
            NAME: "respond_poll",
            PAGE: "respond_poll",
            HREF: "#/respond",
        },
        LIVESTREAM: { // May need to rename this to LIVESTREAM_INTAKE if more livestream features are added
            NAME: "livestream_flow",
            PAGE: "livestream_page",
            HREF: "#/livestream-intake"
        },
        ROOMS: {
            NAME: "rooms_flow",
            PAGE: "rooms_page",
            HREF: "#/rooms"
        },
        UNAUTHORIZED: {
            NAME: "unauthorized_flow",
            PAGE: "unauthorized_page",
            HREF: "#/unauthorized"
        },
        SETTINGS: {
            NAME: "settings_flow",
            PAGE: "settings_page",
            HREF: "#/preferences"
        },
        MIGRATION: {
            NAME: "migration_flow",
            PAGE: "migration_page",
            HREF: "#/migration"
        }
    }
};
