import React from "react";

import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import alertErrorSmallTokens from "@amzn/meridian-tokens/base/icon/alert-error-small"

const ErrorMessageWrapper = (props) => {
    const errorMessage = props.errorMessage || "This field is required";

    return (
        <div>
            {props.children}
            {props.showErrorMessage &&
                <Row id={props.id} alignmentVertical="top" spacing="300">
                    <Text type="b200">
                        <Icon tokens={alertErrorSmallTokens} />
                    </Text>
                    <Column>
                        <Text color="error">
                            {errorMessage}
                        </Text>
                    </Column>
                </Row>
            }
        </div>
    );
};

export default ErrorMessageWrapper;