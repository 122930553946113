const agendaNamespace = "agenda";

export const FIND_MEETINGS = `${agendaNamespace}.find_meetings`;
export const SET_MEETINGS_LIST = `${agendaNamespace}.set_meetings_list`;
export const GET_MEETING = `${agendaNamespace}.get_meeting`;
export const GET_MEETING_AGENDA = `${agendaNamespace}.get_meeting_agenda`;
export const REFRESH_AGENDA = `${agendaNamespace}.refresh_agenda`;
export const SET_MEETING_AGENDA_LOADED = `${agendaNamespace}.set_meeting_agenda_loaded`;

// Meeting

const meetingNamespace = "meeting";

export const GET_MEETING_AND_SAVE = `${meetingNamespace}.get_meeting_and_save`;
export const GET_MEETING_DETAILS_LIST_AND_SAVE = `${meetingNamespace}.get_meeting_details_list_and_save`;
export const GET_MEETING_ROOM_STATUS_LIST = `${meetingNamespace}.get_meeting_room_status_list`;
export const SET_MEETING_DETAILS = `${meetingNamespace}.set_meeting_details`;
export const SET_MEETING_RESOURCES_DICTIONARY = `${meetingNamespace}.set_meeting_resources_dictionary`;
export const SET_MEETING_ROOM_STATUS_LIST = `${meetingNamespace}.set_meeting_room_status_list`;
export const GET_MEETING_RECURRENCE_PATTERN_AND_SAVE = `${meetingNamespace}.get_meeting_recurrence_pattern_and_save`;
export const SET_MEETING_RECURRENCE_PATTERN = `${meetingNamespace}.set_meeting_recurrence_pattern`;
export const CREATE_MEETING = `${meetingNamespace}.create_meeting`;
export const DELETE_MEETING = `${meetingNamespace}.delete_meeting`;
export const UPDATE_MEETING = `${meetingNamespace}.update_meeting`;
export const SET_MEETING_LIST_LOADED = `${meetingNamespace}.set_meeting_list_loaded`;
export const SET_AWARENESS_MEETING_UNIQUE_ID = `${meetingNamespace}.set_awareness_meeting_unique_id`;

// Group

const groupNamespace = "group";

export const GET_GROUP = `${groupNamespace}.get_group`;
export const BATCH_GET_GROUP = `${groupNamespace}.batch_get_group`;
export const SET_GROUP_DETAILS = `${groupNamespace}.set_group_details`;
export const BATCH_SET_GROUP_DETAILS = `${groupNamespace}.batch_set_group_details`;

// Suggestions

const suggestionsNamespace = "suggestions";

export const GET_SUGGESTIONS = `${suggestionsNamespace}.get_suggestions`;
export const SAVE_SUGGESTIONS = `${suggestionsNamespace}.save_suggestions`;
export const CLEAR_SUGGESTIONS = `${suggestionsNamespace}.clear_suggestions`;
export const SET_SUGGESTIONS_STATE = `${suggestionsNamespace}.set_suggestions_state`;
export const SAVE_CACHED_AVAILABILITY = `${suggestionsNamespace}.save_cached_availability`;
export const GET_CACHED_RESOURCE_AVAILABILITY = `${suggestionsNamespace}.get_cached_resource_availability`;
export const UPDATE_AVAILABILITY_LIST = `${suggestionsNamespace}.update_availability_list`;
export const DELETE_FROM_AVAILABILITY_LIST = `${suggestionsNamespace}.delete_from_availability_list`;

// Room booking 

const roomBookingNamespace = "room-booking";
export const INCREMENT_COUNT = `${roomBookingNamespace}.increment_count`;
export const GET_MEETINGS_ROOM_BOOKING = `${roomBookingNamespace}.get_meetings_room_booking`;
export const SET_MEETINGS_ROOM_BOOKING = `${roomBookingNamespace}.set_meetings_room_booking`;
export const SET_MEETINGS_ROOM_BOOKING_LOADED = `${roomBookingNamespace}.set_meetings_room_booking_loaded`;
export const ADD_MEETING_ROOM_BOOKING = `${roomBookingNamespace}.add_meeting_room_booking`;
export const GET_EXISTING_MEETINGS = `${roomBookingNamespace}.get_existing_meetings`;
export const SET_EXISTING_MEETINGS = `${roomBookingNamespace}.set_existing_meetings`;
export const SET_EXISTING_MEETINGS_LOADED = `${roomBookingNamespace}.set_existing_meetings_loaded`;

// Beta Test

const betaTestNamespace = "beta";

export const SET_UNAUTHORIZED_USER = `${betaTestNamespace}.set_unauthorized_user`;