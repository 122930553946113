import DOMPurify from "dompurify";
import { chimeBridgeInfoTemplate } from './chime-bridge-info';
import { domNodesToHtml, htmlToDomNodes, indicesOfMarkers, replaceContentBetween } from './html-template-utilities';
import { selfServiceInfoBeginRegex, selfServiceInfoEndRegex, creationInfoRegex } from "../body/body-constants";

const newlines = '<div>\n</div><div>\n</div>';
const newline = '<div><br></div>';

// comply with ./chime-bridge-info
const bridgeInfoBeginPattern = '={10,}Conference Bridge Information={10,}';
const bridgeInfoEndPattern = '={40,}';
const bridgeInfoBeginRegex = new RegExp(bridgeInfoBeginPattern, 'i');
const bridgeInfoEndRegex = new RegExp(bridgeInfoEndPattern, 'i');

export const updateBridgeInfoInBody = (bodyHTML, bridge) => {
    bodyHTML = bodyHTML || '';

    let nodes = htmlToDomNodes(bodyHTML);
    let bridgeInfoIndices = indicesOfMarkers(nodes, bridgeInfoBeginRegex, bridgeInfoEndRegex);
    let selfServiceInfoIndices = indicesOfMarkers(nodes, selfServiceInfoBeginRegex, selfServiceInfoEndRegex);
    let creationInfoIndices = indicesOfMarkers(nodes, creationInfoRegex, creationInfoRegex);

    // no bridge selected, so delete bridge info
    if (!bridge || !bridge.bridgeID) {
        return replaceContentBetween(nodes, bridgeInfoIndices, '');
    } else {
        let meetingID = bridge.bridgeID;
        let newBridgeInfo = chimeBridgeInfoTemplate.replace(/\{\{MEETING_ID\}\}/g, meetingID);

        // add newlines so people will be less likely to type in the info area
        newBridgeInfo = (bodyHTML ? newline : newlines) + newBridgeInfo;

        // try to update existing bridge info
        if (bridgeInfoIndices) {
            return DOMPurify.sanitize(replaceContentBetween(nodes, bridgeInfoIndices, newBridgeInfo));
        }
        // existing bridge info not found, then try to replace existing self service info and creation info so that
        // bridge info always shows above them
        else if (selfServiceInfoIndices) {
            let selfServiceInfo = domNodesToHtml(nodes.slice(selfServiceInfoIndices.beginIndex, selfServiceInfoIndices.endIndex + 1));
            return replaceContentBetween(nodes, selfServiceInfoIndices, newBridgeInfo + selfServiceInfo);
        }
        else if (creationInfoIndices) {
            let creationInfo = domNodesToHtml(nodes.slice(creationInfoIndices.beginIndex, creationInfoIndices.endIndex + 1));
            return replaceContentBetween(nodes, creationInfoIndices, newBridgeInfo + creationInfo);
        }

        // no existing bridge info or creation info, just append bridge info to the end
        else {
            return DOMPurify.sanitize(bodyHTML + newBridgeInfo);
        }
    }
}

export default updateBridgeInfoInBody;