import React, { useEffect, useRef, useState } from "react";

import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Table, { TableActionBar, TableActionBarOverlay, TableCell, TableRow } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";

import AttendeeSearchBar from "../../../people/components/attendee-search-bar";
import AttendeePopover from "../../../people/components/attendee-popover";
import { createFavorite, renderFavoriteTypeIcon } from "../../../shared/favorites/favorites-utils";
import { SCREEN_SIZE } from "../../../shared/shared-constants";
import {
    ATTENDEE_TYPE,
    ATTENDEE_LIST_MIN_HEIGHT,
    ATTENDEE_SEARCH_BAR_MIN_WIDTH,
    ATTENDEE_SEARCH_BAR_DEFAULT_POPOVER_HEIGHT
} from "../../../people/people-constants";
import { FAVORITE_TYPE } from "../../../shared/favorites/favorites-constants";

import minusIconTokens from "@amzn/meridian-tokens/base/icon/minus";
import trashTokens from "@amzn/meridian-tokens/base/icon/trash";
import AddLocation from "../../locations/components/add-location";
import { LOCATION_FLOOR_ANY, LOCATION_PROXIMITY } from "../../locations/locations-constants";
import { shortRoomName } from "../../locations/locations-utils";

const FavoritesTable = (props) => {
    const favoriteSearchBarLabelId = "favoriteSearchBarLabel";
    const favoriteActionBarId = "favoriteActionBar";
    const sortColumn = "Favorites";
    const identity = props.identity;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const onGetRASDataForPerson = props.onGetRASDataForPerson;
    const onGetRASDataForGroup = props.onGetRASDataForGroup;
    const onGetPeopleSuggestions = props.onGetPeopleSuggestions;
    const onClearPeopleSuggestions = props.onClearPeopleSuggestions;
    const onGetGroupSuggestions = props.onGetGroupSuggestions;
    const onClearGroupSuggestions = props.onClearGroupSuggestions;
    const onAddFavorite = props.onAddFavorite;
    const onGetRASDataForPersonAndAddToFavorite = props.onGetRASDataForPersonAndAddToFavorite;
    const onRemoveFavoriteByAlias = props.onRemoveFavoriteByAlias;
    const onRemoveFavorite = props.onRemoveFavorite;
    const peopleSuggestions = props.peopleSuggestions;
    const groupSuggestions = props.groupSuggestions;
    const buildings = props.buildings;
    const rooms = props.rooms;
    const locations = props.locations;

    const popoverRefs = useRef([]);

    const [sortDirection, setSortDirection] = useState("ascending");
    const [selectedFavorites, setSelectedFavorites] = useState([]);
    const [popoverAttendeeValue, setPopoverAttendeeValue] = useState();
    const [openFavoritePopover, setOpenFavoritePopover] = useState("");
    const favorites = props.favorites || [];
    const popoverAttendee = props.popoverAttendee;
    const favoriteList = favorites.map((favorite) => favorite.value);

    const favoriteCount = favorites.length;
    const checkedFavoritesCount = selectedFavorites.length;
    const allFavoritesSelected = favoriteCount === checkedFavoritesCount;
    const favoriteHeaderCheckboxIcon = !allFavoritesSelected ? minusIconTokens : undefined;

    useEffect(() => {
        if (openFavoritePopover?.length > 0 && popoverAttendee?.alias === openFavoritePopover) {
            setPopoverAttendeeValue(popoverAttendee);
        }
    }, [popoverAttendee, openFavoritePopover])

    const sortFavorites = (favorites, sortDirection) => {
        let sortResult = sortDirection === "ascending" ? -1 : 1;

        // sort by the type first, Location will always be after the user & group,
        // then sort based on the name
        return favorites.sort((favorite1, favorite2) => {
            if (favorite1.type !== favorite2.type) {
                if (favorite1.type === FAVORITE_TYPE.USER || 
                    favorite2.type === FAVORITE_TYPE.USER) {
                    return favorite1.type === FAVORITE_TYPE.USER ? -1 : 1;
                }

                if (favorite1.type === FAVORITE_TYPE.GROUP || 
                    favorite2.type === FAVORITE_TYPE.GROUP) {
                    return favorite1.type === FAVORITE_TYPE.GROUP ? -1 : 1;
                }

                if (favorite1.type === FAVORITE_TYPE.EXTERNAL_EMAIL || 
                    favorite2.type === FAVORITE_TYPE.EXTERNAL_EMAIL) {
                    return favorite1.type === FAVORITE_TYPE.EXTERNAL_EMAIL ? -1 : 1;
                }

                if (favorite1.type === FAVORITE_TYPE.LOCATION || 
                    favorite2.type === FAVORITE_TYPE.LOCATION) {
                    return favorite1.type === FAVORITE_TYPE.LOCATION ? -1 : 1;
                }

                if (favorite1.type === FAVORITE_TYPE.ROOM || 
                    favorite2.type === FAVORITE_TYPE.ROOM) {
                    return favorite1.type === FAVORITE_TYPE.ROOM ? -1 : 1;
                }

                return 1;
            } else {
                if (favorite1.name < favorite2.name) {
                    return sortResult;
                }
                if (favorite1.name > favorite2.name) {
                    return -sortResult;
                }
                return 0;
            }
        });
    };

    const onSort = (sortState) => {
        if (sortState.sortDirection !== sortDirection) {
            setSortDirection(sortState.sortDirection);
        }
    };

    const onChangeFavoriteHeader = () => {
        const allFavoriteValues = checkedFavoritesCount === 0;
        setAllFavorites(allFavoriteValues);
    };

    const onChangeFavoriteRow = (changedFavorite) => {
        let tempSelectedFavorites = [...selectedFavorites];
        const index = tempSelectedFavorites.findIndex((f) => f.value === changedFavorite.value);
        if (index > -1) {
            tempSelectedFavorites.splice(index, 1);
        } else {
            tempSelectedFavorites.push(changedFavorite);
        }
        setSelectedFavorites(tempSelectedFavorites);
    };

    const onClickFavorite = (favorite) => {
        if (favorite?.type !== FAVORITE_TYPE.USER && favorite?.type !== FAVORITE_TYPE.GROUP) {
            return;
        }

        setPopoverAttendeeValue({type: ATTENDEE_TYPE.PERSON, rasData: undefined});
        setOpenFavoritePopover(favorite.value);
        
        // If rasData is not present, call APIs once to retrieve card data
        if (popoverAttendee === undefined || popoverAttendee?.alias !== favorite.value) {
            if (favorite.type === FAVORITE_TYPE.USER) {
                onGetRASDataForPerson(favorite.value, false);
            } else if (favorite.type === FAVORITE_TYPE.GROUP) {
                onGetRASDataForGroup(favorite.value);
            }
        }
    };

    const onClosePopover = () => setOpenFavoritePopover("");

    const findFavoriteIndex = (favorites, favorite) => {
        if (isLocationType(favorite)) {
            return favorites.findIndex((f) => f.name === favorite.name);
        }

        return favorites.findIndex((f) => f.value === favorite.value);
    };

    const onClickRemoveFavorite = (favorite) => {
        if (findFavoriteIndex(selectedFavorites, favorite) > -1) {
            let tempSelectedFavorites = [...selectedFavorites];
            tempSelectedFavorites.splice(findFavoriteIndex(tempSelectedFavorites, favorite), 1);
            setSelectedFavorites(tempSelectedFavorites);
        }

        onRemoveFavorite(favorite, identity.username);
    };

    const onRemoveFavoritePopoverCard = (alias) => {
        const index = favorites.findIndex(favorite => favorite.value === alias);
        if (index > -1) {
            let tempSelectedFavorites = [...selectedFavorites];
            tempSelectedFavorites.splice(index, 1);
            setSelectedFavorites(tempSelectedFavorites);
        }

        onRemoveFavoriteByAlias(alias, identity.username);
    };

    const isFavorited = (favoriteItem) => {
        if (favorites && favorites.length > 0) {
            switch (favoriteItem.type) {
                case FAVORITE_TYPE.USER:
                case FAVORITE_TYPE.GROUP:
                case FAVORITE_TYPE.EXTERNAL_EMAIL:
                    return favorites.findIndex((favorite) => favorite.value === favoriteItem.value) > -1;
                case FAVORITE_TYPE.LOCATION:
                case FAVORITE_TYPE.ROOM:
                    return favorites.findIndex((favorite) => favorite.name === favoriteItem.name) > -1;
                default:
                    return false;
            }
        }

        return false;
    };

    const isFavoritedForPopoverCard = (user) => {
        return favorites.findIndex((favorite) => favorite.value === user.username) > -1;
    };

    const isLocationType = (favorite) => {
        if (favorite.type !== undefined) {
            return favorite.type === FAVORITE_TYPE.LOCATION || favorite.type === FAVORITE_TYPE.ROOM;
        }

        return false;
    }

    const onAddFavoriteClick = (favorite, type = FAVORITE_TYPE.USER, isLocation = false) => {
        if (favorites === undefined) {
            return;
        }

        let favoriteToAdd;
        if (isLocation) {
            const building = favorite;
            let buildingLabel = (building && building.label) || building.buildingCode || "Unknown building";
            let floor = building.floor !== undefined && building.floor !== LOCATION_FLOOR_ANY ? `floor ${building.floor}` :  "any floor";
            let proximity = building.thisBuildingOnly ? "" : ` ${LOCATION_PROXIMITY.INCLUDE_NEARBY}`;
            let seats = building.minCapacity !== undefined ? `${building.minCapacity}+ seats` : "";

            favorite.buildingName = `${buildingLabel}${proximity}, ${floor}, ${seats}`;

            const favoriteType = isSpecificRoom(favorite) ? FAVORITE_TYPE.ROOM : FAVORITE_TYPE.LOCATION;
            favoriteToAdd = createFavorite(favoriteType, favorite);
        } else {
            // We don't allow users to favorite themselves
            if (favorite.alias === identity.username) {
                return;
            }

            switch (favorite.type) {
                case ATTENDEE_TYPE.PERSON:
                    favoriteToAdd = createFavorite(FAVORITE_TYPE.USER, favorite);
                    break;
                case ATTENDEE_TYPE.GROUP:
                    favoriteToAdd = createFavorite(FAVORITE_TYPE.GROUP, favorite);
                    break;
                case ATTENDEE_TYPE.EXTERNAL_EMAIL:
                    favoriteToAdd = createFavorite(FAVORITE_TYPE.EXTERNAL_EMAIL, {email: favorite.email});
                    break;
                default:
                    favoriteToAdd = createFavorite(type, favorite);
                    break;
            }
        }

        if (!isFavorited(favoriteToAdd)) {
            onAddFavorite(favoriteToAdd, identity.username);
        }
    };

    const onGetRASDataForPersonAndAddToFavoriteClick = (favorite) => {
        onGetRASDataForPersonAndAddToFavorite(favorite, identity.username);
    };

    const isSpecificRoom = (location) => {
        if (location.type?.length > 0 && typeof(location.type) === "string") {
            return location.type.toLowerCase().includes("room");
        }

        return false;
    };

    const onRemoveSelected = () => {
        // Remove the selected attendees
        selectedFavorites.forEach((favorite) => {
            onRemoveFavorite(favorite, identity.username);
        });

        unselectSelected();
    };

    const unselectSelected = () => {
        // Unselect Attendees and Locations
        setAllFavorites(false);
    };

    // Attendee Multi-selection
    const setAllFavorites = (value) => {
        let tempSelectedAttendees = [];
        if (value) {
            favorites.forEach((favorite) => tempSelectedAttendees.push(favorite));
        }
        setSelectedFavorites(tempSelectedAttendees);
    };

    const renderFavorites = (favorites) => {
        return (
            sortFavorites(favorites, sortDirection).map((favorite) => (
                <TableRow key={favorite.value + favorite.name}>
                    <TableCell>
                        <label
                            htmlFor={`${favorite.value}-checkbox`}
                            aria-label={favorite.value}
                        />
                        <Checkbox
                            checked={findFavoriteIndex(selectedFavorites, favorite) > -1}
                            onChange={() => onChangeFavoriteRow(favorite)}
                            id={`${favorite.value}-checkbox`}
                        />
                    </TableCell>
                    <TableCell>
                        <Row spacing="xsmall">
                            {favorite.name === undefined &&
                                <Loader size="small" />
                            }
                            <Link
                                onClick={() => onClickFavorite(favorite)}
                                ref={(ref) => popoverRefs.current[favorite.value] = ref}
                                type="secondary"
                            >
                                <Row>
                                    {renderFavoriteTypeIcon(favorite.type)}
                                    {" " + isSpecificRoom(favorite) ? shortRoomName(favorite.name) : favorite.name + " "}
                                </Row>
                            </Link>
                        </Row>
                        <AttendeePopover
                            attendee={popoverAttendeeValue}
                            attendeeList={favoriteList}
                            anchorNode={popoverRefs.current[favorite.value]}
                            open={openFavoritePopover === favorite.value}
                            position={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "right" : "bottom"}
                            onClose={onClosePopover}
                            onAddFavorite={onAddFavoriteClick}
                            onGetRASDataForPersonAndAddToFavorite={onGetRASDataForPersonAndAddToFavoriteClick}
                            onRemoveFavorite={onRemoveFavoritePopoverCard}
                            onGetRASDataForPerson={onGetRASDataForPerson}
                            isFavorited={isFavoritedForPopoverCard}
                            onFavoriteClick={onAddFavoriteClick}
                        />
                    </TableCell>
                    <TableCell alignmentHorizontal="right">
                        <Row height="16px">
                            <Button
                                onClick={() => onClickRemoveFavorite(favorite)}
                                type="link"
                                label={`Remove favorite ${favorite.name}`}
                                size="small"
                            >
                                <Icon tokens={trashTokens} />
                            </Button>
                        </Row>
                    </TableCell>
                </TableRow>
            ))
        );
    };

    return (
        <Column>
            <Text type="h200">
                Add people and meeting rooms to favorites
            </Text>
            <Row width="100%" minHeight={ATTENDEE_LIST_MIN_HEIGHT} alignmentVertical="top">
                <Table
                    headerRows={1}
                    showDividers={favorites?.length > 0}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSort={onSort}
                    spacing="small"
                >
                    <Column spacingInset="small none" backgroundColor="white">
                        <TableActionBar>
                            <Row
                                spacing="small"
                                alignmentVertical="bottom"
                                alignmentHorizontal="right"
                                widths={["fill", "fit"]}
                                width="100%"
                                wrap="down"
                                id={favoriteActionBarId}
                            >
                                <Column spacing="xxsmall" minWidth={ATTENDEE_SEARCH_BAR_MIN_WIDTH}>
                                    <Text>Add people</Text>
                                    <AttendeeSearchBar isAddingFavorite
                                        identity={identity}
                                        aria-labelledby={favoriteSearchBarLabelId}
                                        peopleSuggestions={peopleSuggestions}
                                        groupSuggestions={groupSuggestions}
                                        width="80%"
                                        popoverHeight={ATTENDEE_SEARCH_BAR_DEFAULT_POPOVER_HEIGHT}
                                        onAddAttendee={onAddFavoriteClick}
                                        onGetPeopleSuggestions={onGetPeopleSuggestions}
                                        onClearPeopleSuggestions={onClearPeopleSuggestions}
                                        onGetGroupSuggestions={onGetGroupSuggestions}
                                        onClearGroupSuggestions={onClearGroupSuggestions}
                                        placeholderText="Add by name or email"
                                    />
                                </Column>
                                <Column spacing="xxsmall">
                                    <AddLocation 
                                        identity={identity}
                                        screenSizeBreakpoint={screenSizeBreakpoint}
                                        buildings={buildings}
                                        rooms={rooms}
                                        locations={locations}
                                        favorites={favorites}
                                        findRooms={props.findRooms}
                                        onLoadFloors={props.onLoadFloors}
                                        onAddFavorite={(location) => onAddFavoriteClick(location, undefined, true)}
                                    />
                                </Column>
                            </Row>
                            {selectedFavorites.length > 0 &&
                                <TableActionBarOverlay
                                    label={`${selectedFavorites.length} selected`}
                                    onClose={unselectSelected}
                                >
                                    <Button
                                        type="link"
                                        onClick={onRemoveSelected}
                                    >
                                        <Row spacing="xsmall">
                                            <Icon tokens={trashTokens} />
                                            {screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                                                <Row>Remove</Row>
                                            }
                                        </Row>
                                    </Button>
                                </TableActionBarOverlay>
                            }
                        </TableActionBar>
                    </Column>
                    <TableRow>
                        <TableCell width="44px">
                            <label
                                htmlFor="favorite-header-checkbox"
                                aria-label="All favorites"
                            />
                            <Checkbox
                                checked={checkedFavoritesCount > 0}
                                onChange={onChangeFavoriteHeader}
                                iconTokens={favoriteHeaderCheckboxIcon}
                                id="favorite-header-checkbox"
                            />
                        </TableCell>
                        <TableCell sortColumn={sortColumn} width="300px">
                            Favorites ({favorites.length})
                        </TableCell>
                        <TableCell width="50px" />
                    </TableRow>
                    {favorites.length > 0 ?
                        renderFavorites(favorites) :
                        <TableRow>
                            <TableCell />
                            <TableCell>
                                <Text type="b200">No favorites</Text>
                            </TableCell>
                        </TableRow>
                    }
                </Table>
            </Row>
        </Column>
    );
};

export default FavoritesTable;