import { format, utcToZonedTime } from "date-fns-tz";
import { TIMEZONE_DISPLAY_TYPE, ERRORS }  from "./timezones-constants";

/* Timezones not supported by date-fns-tz 1.1.3
* timezoneId: Etc/GMT+9 timezoneDisplayName: (UTC-09:00) Coordinated Universal Time-09
* timezoneId: Etc/GMT+8 timezoneDisplayName: (UTC-08:00) Coordinated Universal Time-08
* timezoneId: Etc/GMT+12 timezoneDisplayName: (UTC-12:00) International Date Line West
* timezoneId: Etc/GMT+11 timezoneDisplayName: (UTC-11:00) Coordinated Universal Time-11
* timezoneId: Etc/GMT+2 timezoneDisplayName: (UTC-02:00) Coordinated Universal Time-02
* timezoneId: Etc/GMT-12 timezoneDisplayName: (UTC+12:00) Coordinated Universal Time+12
*/

/**
 * Returns the primary timezone ID (according to timezones.timezones state) for a given timezone ID
 * Can return undefined if the param timezones is undefined or empty OR if the param browserTimezoneId is undefined
 * Also will return ERRORS.INVALID_TIMEZONE if primary timezone not found (sets error state for timezone picker)
 *
 * @param {Array} timezones - List of timezone objects (state: timezones.timezones)
 * @param {String} browserTimezoneId - Timezone ID of users browser (state: timezones.browserTimezoneId)
 * @return {String} timezone.id corresponding to the browserTimezoneId given
 */
export const determinePrimaryBrowserTimezoneId = (timezones, browserTimezoneId) => {
    if (!timezones || !timezones.length || !browserTimezoneId) {
        return undefined;
    }

    const primaryTimezone = timezones.find(
        (timezone) => (browserTimezoneId === timezone.id || timezone.alternativeIDs.includes(browserTimezoneId))
    );
    return (primaryTimezone && primaryTimezone.id) || ERRORS.INVALID_TIMEZONE;
};

/**
 * Returns the date-fns-tz short name, or long name if specified, for a given timezone ID
 *
 * @param {Array} timezones - List of timezone objects (state: timezones.timezones)
 * @param {String} timezoneId - Timezone ID
 * @param {String} displayNameType - The desired type of display name. Defaults to "short"
 * @return {String} standardShortNameDateFns or standardLongNameDateFns for the specified timezone
 */
export const timezoneIdToDisplayName = (timezones, timezoneId, displayNameType) => {
    if (!timezones || !timezones.length) {
        timezones = [];
    }

    const primaryTimezone = timezoneId && timezones.find(
        (timezone) => (timezoneId === timezone.id || timezone.alternativeIDs.includes(timezoneId))
    );

    switch (displayNameType) {
        case TIMEZONE_DISPLAY_TYPE.LONG:
            return (primaryTimezone && primaryTimezone.standardLongNameDateFns) || "Unknown Timezone";
        case TIMEZONE_DISPLAY_TYPE.SHORT:
        default:
            return (primaryTimezone && primaryTimezone.standardShortNameDateFns) || "N/A";
    }
};

/**
 * Updates timezone info (from getTimezones RAS API) with potential daylight saving time changes (using date-fns-tz)
 * For each timezone object:
 *  standardLongNameDateFns added
 *  standardShortNameDateFns added
 *  displayName changed to have current UTC offset
 *
 * @param {Array} timezones - List of timezone objects from getTimezones RAS API
 * @return {Array} List of timezone objects with the updated DST info
 */
export const updateTimezonesInfo = (timezones) => {
    if (!timezones || !timezones.length) {
        return timezones;
    }

    timezones.forEach((timezone) => {
        timezone.standardLongNameDateFns = format(utcToZonedTime(new Date(), timezone.id), "zzzz", {timeZone: timezone.id});
        timezone.standardShortNameDateFns = format(utcToZonedTime(new Date(), timezone.id), "zzz", {timeZone: timezone.id});

        let currentUTCOffset = format(utcToZonedTime(new Date(), timezone.id), "XXX", {timeZone: timezone.id});
        if (currentUTCOffset !== "Z") {
            // all options that have an offset from UTC and supported by date-fns-tz
            timezone.displayName = timezone.displayName.replace(/([+-][0-9][0-9]:[0-9][0-9])/, currentUTCOffset);
        }
        else if (!/(Etc\/GMT[+-])/.test(timezone.id)) {
            // all options that have no offset from UTC and supported by date-fns-tz
            timezone.displayName = timezone.displayName.replace(/([+-][0-9][0-9]:[0-9][0-9])/, "");
        }
        // else: all options that are not supported by date-fns-tz (listed at the top of the file)

        if (timezone.id === "Etc/GMT") {
            // getTimezones RAS API does not return the valid ID "UTC" under alternativeIDs for "Etc/GMT" (https://en.wikipedia.org/wiki/List_of_tz_database_time_zones)
            timezone.alternativeIDs.push("UTC");
        }
        else if (timezone.id === "Asia/Calcutta") {
            // getTimezones RAS API, Google Chrome, and Safari uses deprecated "Asia/Calcutta" while Firefox uses "Asia/Kolkata"
            timezone.alternativeIDs.push("Asia/Kolkata");
        }
    });

    return timezones;
};
