import React from "react";

import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";

import exportSmallTokens from "@amzn/meridian-tokens/base/icon/export-small";

import { KEYCODE, TIME_CONSTANT } from "./shared-constants";
import WarehouseSvg from "../../assets/icons/shared/Warehouse.svg";

/**
 * Generate an id for a component.
 *
 * @param component - the meridian component for which id is generated like button, select, checkbox
 * @param componentName - the name to be used for the component like 'create-meeting', 'add-chime'
 * @parama modalName - the name of the modal if the component is on a modal
 * @returns {string} - generated component id
 */
export const generateComponentId = (component, componentName, modalName) => {
    return `$amw-${(window.location.href.split("/")[4] || "home")}-${component}-${componentName}${modalName ? ('-modal-' + modalName) : ""}$`;
};

export const onEnterOrSpaceDown = (event, functionToInvoke) => {
    const keyCode = event.keyCode || event.which;
    switch (keyCode) {
        case KEYCODE.ENTER:
        case KEYCODE.SPACE:
            functionToInvoke();
            break;
        default:
            break;
    }
};

/**
 * Return the length of an object in bytes
 *
 * @param object - a javascript object
 * @returns {int} - the number of bytes the object is
 */
export const getObjectInBytes = (object) => {
    return new TextEncoder().encode(JSON.stringify(object)).length;
};

/**
 * Store the value for a cookie
 *
 * @param {string} name - the variable name the cookie is stored in
 * @param {string} value - the value of the cookie
 * @param {int} days - the number of days the cookie is valid for (defaulting to 100 years so it doesn't expire)
 * @param {string} path - the absolute path the cookie is valid in
 */
export const setCookie = (name, value, days = TIME_CONSTANT.ONE_HUNDRED_YEARS_IN_DAYS, path = "/") => {
    const expires = days ? new Date(Date.now() + (days * TIME_CONSTANT.ONE_DAY_IN_MS)).toUTCString() : "";
    document.cookie = name + "=" + encodeURIComponent(value) + "; expires=" + expires + "; path=" + path;
};

/**
 * Obtain the value for a stored cookie
 *
 * @param {string} name - the variable name the cookie is stored in
 * @returns {string} - value of the cookie
 */
export const getCookie = (name) => {
    const cookies = document.cookie.split("; ");

    return cookies.reduce((accumulation, currentValue) => {
        const parts = currentValue.split("=");
        return parts[0] === name ? decodeURIComponent(parts[1]) : accumulation;
    }, "");
};

/**
 * Remove a stored cookie
 *
 * @param {string} name - the variable name the cookie is stored in
 * @param {string} path - the absolute path to remove the cookie in
 */
export const deleteCookie = (name, path) => {
    setCookie(name, "", undefined, path);
};

/**
 * Trigger the custom function when the user hits the SPACE or ENTER key
 *
 * @param {Event} event - the html event
 * @param {Function} func - the function that will be called when the user hits SPACE or ENTER key
 */
export const onSpaceOrEnterKeyDown = (event, func) => {
    const keyCode = event.keyCode || event.which;
    switch (keyCode) {
        case KEYCODE.SPACE:
        case KEYCODE.ENTER:
            func();
            break;
        default:
            break;
    }
};

/**
 * Transform a 24h time string into a 12h time string (h:mm a)
 *
 * @param {string} timeString - a string with a time in 24h format (HH:mm)
 */
export const parseTimeString24hTo12h = (timeString) => {
    let timeParts = timeString.split(":");
    let hours = timeParts[0] % 12 || 12;
    let ampm = (timeParts[0] < 12 || timeParts[0] === 24) ? "AM" : "PM";
    return `${hours}:${timeParts[1]} ${ampm}`;
};

/**
 * Return a Meridian link with external icon that opens in a new tab
 *
 * @param {string} label - the label displayed for the link
 * @param {string} href - the href that opening the link goes to
 * @param {string} linkName - name for what the link is for, e.g. livestream-failed-ticket-permalink
 */
export const renderExternalLink = (label, href, linkName = "") => {
    const linkId = generateComponentId("link", linkName || label.replaceAll(" ", "-"));

    return (
        <div id={linkId} style={{display: "inline-block"}}>
            <Link href={href} target="_blank" type="secondary">
                {label}&nbsp;
                <Icon tokens={exportSmallTokens}>
                    Opens in a new window
                </Icon>
            </Link>
        </div>
    );
};

/**
 * Helper method to scroll the window to a certain position
 * @param {int} left the x position
 * @param {int} top the y position
 */
export const windowScrollTo = (left, top) => {
    document.querySelector("main").scrollTo(left, top);
};

/**
 * Noop function used as a placeholder to avoid undefined error
 */
export const Noop = () => {};

export const renderWarehouseIcon = () => {
    return (
        <img src={WarehouseSvg} alt="Warehouse" />
    );
};
