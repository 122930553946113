import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import format from "date-fns/format"
import getDayOfYear from "date-fns/getDayOfYear";

import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import ControlGroup from "@amzn/meridian/control-group";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Popover from "@amzn/meridian/popover";
import PopoverHeader from "@amzn/meridian/popover/popover-header";
import RadioButton from "@amzn/meridian/radio-button";
import Row from "@amzn/meridian/row";
import Tag from "@amzn/meridian/tag";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";
import flashTokens from "@amzn/meridian-tokens/base/icon/flash";
import tealDarkTokens from "@amzn/meridian-tokens/theme/teal-dark";
import tealLightTokens from "@amzn/meridian-tokens/theme/teal-light";

import SuggestionsTips from "../../shared/components/suggestions-tips";

import { getSuggestionsState } from "../../../sagas/selector";
import { KEYCODE } from "../../shared/shared-constants";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { LOCATION, MAX_SUGGESTIONS, SCREEN_SIZE, MODAL_SIZE } from "../meeting-quick-constants";

const MeetingQuickSuggestion = (props) => {

    const isSearchingSuggestions = useSelector(getSuggestionsState);

    const availableRefs = useRef([]);
    const [availableOpen, setAvailableOpen] = useState("");

    const unavailableRefs = useRef([]);
    const [unavailableOpen, setUnavailableOpen] = useState("");

    const tentativeRefs = useRef([]);
    const [tentativeOpen, setTentativeOpen] = useState("");

    const unknownRefs = useRef([]);
    const [unknownOpen, setUnknownOpen] = useState("");

    const formatIso = (date) => format(date, "hh:mm aa");
    const formatIsoDate = (date) => format(date, "EEE, MMM do yyyy");

    const suggestionsRef = useRef();

    useEffect(() => {
        if (props.suggestions && props.suggestions.length !== 0 && props.selectedSuggestionIndex === -1) {
            suggestionsRef.current && suggestionsRef.current.focus();
            suggestionsRef.current && suggestionsRef.current.click();
        }
    }, [props.suggestions, props.selectedSuggestionIndex])

    const getAttendeeStatusPopOver = (ref, attendeesToShow, openPopover, setOpenPopover, suggestionIndex) => {
        return (
            <Popover
                position="right"
                alignment="center"
                anchorNode={ref.current[suggestionIndex]}
                open={openPopover === suggestionIndex}
                onClose={() => setOpenPopover("")}
            >
                <PopoverHeader closeLabel="Close attendee availability details">Attendees</PopoverHeader>
                <Column alignmentHorizontal="left" spacing="small">
                    {
                        attendeesToShow.map(function (attendeeToShow) {
                            const attendee = props.attendees.find((attendee) => attendee.email === attendeeToShow);
                            if (!attendee) {
                                return <Row />;
                            }
                            if (!attendee.name) {
                                return <div tabIndex="0" aria-label={attendee.email}>{attendee.email}</div>;
                            }
                            return <div tabIndex="0" aria-label={attendee.name}>{attendee.name}</div>;
                        })
                    }
                </Column>
            </Popover>
        );
    };

    const getSuggestedTimeLabel = (suggestion) => {
        return formatIso(suggestion.startTime * 1000) + " - " + formatIso(suggestion.endTime * 1000);
    };

    const getSuggestedDateLabel = (suggestion) => {
        const startTimeMillis = suggestion.startTime * 1000;
        const endTimeMillis = suggestion.endTime * 1000;

        const dateLabel = formatIsoDate(startTimeMillis);
        if (getDayOfYear(startTimeMillis) !== getDayOfYear(endTimeMillis)) {
            return dateLabel + " - " + formatIsoDate(endTimeMillis);
        }
        return dateLabel;
    };

    const areAllAttendeesAvailable = (suggestion) => {
        return (suggestion.tentativePeople.length === 0 && suggestion.unavailablePeople.length === 0 && suggestion.unknownPeople.length === 0)
    };

    const onKeyDown = (event, suggestionIndex) => {
        const special = event.shiftKey;
        const keyCode = event.keyCode || event.which;

        if (keyCode === KEYCODE.TAB && special && suggestionIndex === 0) {
            props.getSuggestionsButtonRef && props.getSuggestionsButtonRef.current && props.getSuggestionsButtonRef.current.focus();
            event.preventDefault();
            return;
        }

        if (keyCode === KEYCODE.TAB && suggestionIndex === Math.min(props.suggestions.length, MAX_SUGGESTIONS) - 1) {
            props.subjectRef && props.subjectRef.current && props.subjectRef.current.focus();
            event.preventDefault();
        }
    };

    const getSuggestionCards = () => {
        return (
            <Column spacingInset="none">
                {props.suggestions && props.suggestions.length === 0 ?
                    <Box type="outline" spacingInset="medium" width="100%">
                        <Text type="b300" color="primary">{LOCATION.NO_SUGGESTIONS_FOUND}</Text>
                    </Box>
                    :
                    <ControlGroup>
                        <Column spacing="medium" spacingInset="none" width="100%">
                            {
                                props.suggestions.slice(0, MAX_SUGGESTIONS).map((value, suggestionIndex) => {
                                    return getSuggestionCard(value, suggestionIndex);
                                })
                            }
                        </Column>
                    </ControlGroup>
                }
                {props.suggestions && props.suggestions.length < MAX_SUGGESTIONS &&
                    <SuggestionsTips
                        isSimpleMeeting={true}
                        alignmentHorizontal="center"
                        maxWidth="none"
                        spacing="medium"
                        spacingInset="medium"
                    />
                }
            </Column>
        );
    };

    const getSuggestionCard = (currentSuggestion, suggestionIndex) => {
        return (
            <Box key={suggestionIndex} type="outline" spacingInset="medium" width="100%">
                <RadioButton
                    ref={suggestionIndex === 0 ? suggestionsRef : undefined}
                    value={suggestionIndex}
                    checked={props.selectedSuggestionIndex === suggestionIndex}
                    onChange={() => {props.setSelectedSuggestionIndex(suggestionIndex)}}
                >
                    <Column spacing="xsmall" spacingInset="none">
                        <Column spacing="xsmall" spacingInset="none">
                            <Row spacing="medium" wrap="down" spacingInset="none">
                                <Row spacing="none small none none" wrap="down" spacingInset="none" alignmentVertical="center">
                                    <Text type="h100">{getSuggestedTimeLabel(currentSuggestion)}</Text>
                                    <Text type="b100" color="secondary">{timezoneIdToDisplayName(props.timezones, props.timezoneValue)}</Text>
                                </Row>
                                <Text type="b200" color="secondary">{getSuggestedDateLabel(currentSuggestion)}</Text>
                            </Row>
                            <Row>
                                <Tag type="success">{currentSuggestion.location}</Tag>
                            </Row>
                        </Column>
                        <Column>
                            <div onKeyDown={(event) => onKeyDown(event, suggestionIndex)}>
                                <Theme tokens={{linkForegroundColorDefault: "black"}}>
                                    {areAllAttendeesAvailable(currentSuggestion) ?
                                        <Tag type="success">
                                            <Text type="b200">
                                                <div aria-label="View free attendees">
                                                    <Link
                                                        ref={(ref) => availableRefs.current[suggestionIndex] = ref}
                                                        type="secondary"
                                                        onClick={() => setAvailableOpen(suggestionIndex)}
                                                    >
                                                        All attendees free
                                                    </Link>
                                                </div>
                                            </Text>
                                        </Tag>
                                        :
                                        <Row spacing="small" wrap="down">
                                            {currentSuggestion.tentativePeople.length > 0 &&
                                                <Tag type="warning">
                                                    <Text type="b200">
                                                        <div aria-label={currentSuggestion.tentativePeople.length + (currentSuggestion.tentativePeople.length > 1 ? " attendees " : " attendee ") + "tentative"}>
                                                            <Link
                                                                ref={(ref) => tentativeRefs.current[suggestionIndex] = ref}
                                                                type="secondary"
                                                                onClick={() => setTentativeOpen(suggestionIndex)}
                                                            >
                                                                {currentSuggestion.tentativePeople.length + (currentSuggestion.tentativePeople.length > 1 ? " attendees " : " attendee ") + "tentative"}
                                                            </Link>
                                                        </div>
                                                    </Text>
                                                </Tag>
                                            }
                                            {currentSuggestion.unavailablePeople.length > 0 &&
                                                <Tag type="error">
                                                    <Text type="b200">
                                                        <div aria-label={currentSuggestion.unavailablePeople.length + (currentSuggestion.unavailablePeople.length > 1 ? " attendees " : " attendee ") + "unavailable"}>
                                                            <Link
                                                                ref={(ref) => unavailableRefs.current[suggestionIndex] = ref}
                                                                type="secondary"
                                                                onClick={() => setUnavailableOpen(suggestionIndex)}
                                                            >
                                                                {currentSuggestion.unavailablePeople.length + (currentSuggestion.unavailablePeople.length > 1 ? " attendees " : " attendee ") + "unavailable"}
                                                            </Link>
                                                        </div>
                                                    </Text>
                                                </Tag>
                                            }
                                            {currentSuggestion.unknownPeople.length > 0 &&
                                                <Tag type="warning">
                                                    <Text type="b200">
                                                        <div aria-label={currentSuggestion.unknownPeople.length + (currentSuggestion.unknownPeople.length > 1 ? " attendees " : " attendee ") + "unknown"}>
                                                            <Link
                                                                ref={(ref) => unknownRefs.current[suggestionIndex] = ref}
                                                                type="secondary"
                                                                onClick={() => setUnavailableOpen(suggestionIndex)}
                                                            >
                                                                {currentSuggestion.unknownPeople.length + (currentSuggestion.unknownPeople.length > 1 ? " attendees " : " attendee ") + "unknown"}
                                                            </Link>
                                                        </div>
                                                    </Text>
                                                </Tag>
                                            }
                                        </Row>
                                    }
                                </Theme>
                            </div>
                            {getAttendeeStatusPopOver(availableRefs, currentSuggestion.freePeople, availableOpen, setAvailableOpen, suggestionIndex)}
                            {getAttendeeStatusPopOver(tentativeRefs, currentSuggestion.tentativePeople, tentativeOpen, setTentativeOpen, suggestionIndex)}
                            {getAttendeeStatusPopOver(unavailableRefs, currentSuggestion.unavailablePeople, unavailableOpen, setUnavailableOpen, suggestionIndex)}
                            {getAttendeeStatusPopOver(unknownRefs, currentSuggestion.unknownPeople, unknownOpen, setUnknownOpen, suggestionIndex)}
                        </Column>
                    </Column>
                </RadioButton>
            </Box>
        );
    };

    const getSuggestionScreenReaderText = () => {
        if (props.suggestions && props.suggestions[props.selectedSuggestionIndex]) {
            const suggestion = props.suggestions[props.selectedSuggestionIndex];
            const meetingTime = "meeting time " + getSuggestedTimeLabel(suggestion) + " " +
                                timezoneIdToDisplayName(props.timezones, props.timezoneValue) + " " +
                                getSuggestedDateLabel(suggestion) + ", ";
            const location = "location " + suggestion.location + ", ";

            let availabilities = [];
            if (areAllAttendeesAvailable(suggestion)) {
                availabilities.push(" all attendees are available ");
            } else {
                const tentativePeopleCount = suggestion.tentativePeople.length;
                if (tentativePeopleCount > 0) {
                    availabilities.push(tentativePeopleCount + (tentativePeopleCount > 1 ? " attendees " : " attendee ") + " tentative ");
                }
                const unavailablePeopleCount = suggestion.unavailablePeople.length;
                if (unavailablePeopleCount > 0) {
                    availabilities.push(unavailablePeopleCount + (unavailablePeopleCount > 1 ? " attendees " : " attendee ") + " unavailable ");
                }
                const unknownPeopleCount = suggestion.unknownPeople.length;
                if (unknownPeopleCount > 0) {
                    availabilities.push(unknownPeopleCount + (unknownPeopleCount > 1 ? " attendees " : " attendee ") + " unknown ");
                }
            }

            return (
                (props.suggestions.length === 1 ?
                    "Suggestion "
                    :
                    ("Suggestion " + (props.selectedSuggestionIndex + 1) + " of " + Math.min(props.suggestions.length, MAX_SUGGESTIONS))) + ", " +
                            meetingTime + location + availabilities.join(",")
            );
        }
        return LOCATION.NO_SUGGESTIONS_FOUND;
    };

    return (
        <div>
            {props.screenSizeBreakpoint >= SCREEN_SIZE.QUICK_MEETING_FULL_VIEW &&
                <Theme tokens={tealDarkTokens}>
                    <Column spacing="none" width="fit" spacingInset="medium none medium medium">
                        <Row widths={["20px", "fill"]} spacing="small">
                            <Text>
                                <Icon tokens={flashTokens}/>
                            </Text>
                            <Heading level={2} type="h300">
                                Smart suggestions
                            </Heading>
                        </Row>
                        <Row widths={["20px", "fill"]} spacing="small">
                            <Row/>
                            <Text type="b200" alignment="left">
                                Pick a meeting slot that works best for all participants
                            </Text>
                        </Row>
                    </Column>
                </Theme>
            }
            <Column
                spacing="small"
                spacingInset="none none none medium"
                height={props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ? "100%" : MODAL_SIZE.SUGGESTIONS_HEIGHT}
                overflowY="auto"
            >
                <Column heights={["fit"]} spacing="none" spacingInset="none">
                    <div
                        aria-live="assertive"
                        style={{
                            height: "0px",
                            width: "0px",
                            textIndent: "-10000px"}}
                    >
                        {isSearchingSuggestions ? "Loading suggestions..." : getSuggestionScreenReaderText()}
                    </div>
                </Column>
                {isSearchingSuggestions ?
                    <Column alignmentHorizontal="center">
                        <Row spacing="small">
                            <Loader type="circular" size="medium" />
                            <Theme tokens={tealLightTokens}>
                                <Text type="b200" color="inverted">Loading suggestions...</Text>
                            </Theme>
                        </Row>
                    </Column>
                    :
                    getSuggestionCards()
                }
            </Column>
        </div>
    )
};

export default MeetingQuickSuggestion;