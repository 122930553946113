import focusRingStyles from "../../_styles/focus-ring"

const getStyles = (t, { type, backdrop, state }) => ({
  color: t("foregroundColor", [type, backdrop, state]),
  backgroundColor: t("backgroundColor", [type, backdrop, state]),
  borderColor: t("borderColor", [type, state]),
})

const activeStyles = (t, { type, backdrop }) =>
  getStyles(t, { type, backdrop, state: "pressed" })

const hoverStyles = (t, { type, backdrop }) =>
  getStyles(t, { type, backdrop, state: "hover" })

const defaultStyles = (t, { type, backdrop }) =>
  getStyles(t, { type, backdrop, state: "default" })

const disabledStyles = (t, { type, backdrop }) =>
  getStyles(t, { type, backdrop, state: "disabled" })

const focusStyles = (
  t,
  { focus, borderWidth, borderRadius, backdropColor, linkOrIcon, outlineOffset }
) =>
  focusRingStyles(t.tokens, {
    focus,
    borderWidth,
    borderRadius,
    gapColor: backdropColor,
    dropShadow: !linkOrIcon,
    offset:
      outlineOffset !== undefined ? outlineOffset : t("focusOutlineOffset"),
  })

export { activeStyles, hoverStyles, focusStyles, defaultStyles, disabledStyles }
