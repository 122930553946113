const fieldsetReset = {
  border: 0,
  padding: 0,
  margin: 0,
  "& legend": {
    padding: 0,
  },
}

export default fieldsetReset
