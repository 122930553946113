import React, { useEffect, useState } from "react";

import addTime from "date-fns/add";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { fromUnixTime, getUnixTime } from "date-fns";

import Text from "@amzn/meridian/text";
import Heading from "@amzn/meridian/heading";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import Row from "@amzn/meridian/row";
import Divider from "@amzn/meridian/divider";
import Loader from "@amzn/meridian/loader";
import Icon from "@amzn/meridian/icon";
import Button from "@amzn/meridian/button";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";
import Toggle from "@amzn/meridian/toggle";
import DatePicker from "@amzn/meridian/date-picker";

import closeMediumTokens from "@amzn/meridian-tokens/base/icon/close-medium";
import trashTokens from "@amzn/meridian-tokens/base/icon/trash";

import Box from "../../shared/meridian-custom-components/src/components/box";
import LabeledDurationSelect from "../../shared/components/labeled-duration-select";
import TimezonePicker from "../../shared/components/timezone-search-bar";
import CalendarTimeGrid from "../../shared/custom-calendar-time-grid/containers/calendar-time-grid";
import CreatePollCalendar from "./create-poll-calendar";
import MeetingSuggestions from "../../meeting-scheduler/components/meeting-suggestions";
import StatusLegend from "../../shared/components/status-legend.js";
import ArrowDateWrapper from "../../shared/custom-calendar-time-grid/components/arrow-date-wrapper";
import ActionableModal from "../../shared/components/actionable-modal";
import ErrorMessageWrapper from "../../shared/components/error-message-wrapper";
import PollKeyboardInstructions from "./poll-keyboard-instructions";

import { convertEpoch, getDuration } from "../../meeting-scheduler/meeting-scheduler-utils";
import { getLocalesFromSettingsDateFormat } from "../../shared/settings/settings-utils";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { formatIso, parseDatePickerDateString } from "../../shared/time-utils";
import { Noop } from "../../shared/shared-utils";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { TIME_FORMAT as SETTINGS_TIME_FORMAT } from "../../shared/settings/settings-constants";
import { DURATION, SUGGESTION_VIEW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";
import { getTimeSlotString } from "../poll-utils";
import { MAX_TIME_SLOTS, MAX_TIME_SLOTS_MESSAGE } from "../poll-constants";
import { SINGLE_DAY_VIEW_THRESHOLD } from "../../calendar/calendar-constants";

const CreatePollTimeSlots = (props) => {
    const identity = props.identity;
    const timezones = props.timezones;
    const [timezone, setTimezone] = [props.timezone, props.setTimezone];
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const dateFormat = props.dateFormat;
    const timeFormat = props.timeFormat;
    const attendees = props.attendees;
    const meetingSuggestions = props.meetingSuggestions;
    const suggestionViewType = props.suggestionViewType;
    const onSelectSuggestion = props.onSelectSuggestion;
    const timeSlotsSelected = props.timeSlotsSelected;
    const setTimeSlotsSelected = props.setTimeSlotsSelected;
    const durationSelected = props.duration || DURATION.SIXTY_MINUTES;
    const setDurationSelected = props.setDuration;
    const onSetTimeSlotsSelectedFromGridMode = props.onSetTimeSlotsSelectedFromGridMode;
    const onSetTimeSlotsSelectedFromCalendarMode = props.onSetTimeSlotsSelectedFromCalendarMode;
    const [previousTimezoneValue, setPreviousTimezoneValue] = [props.timezone, props.setTimezone];
    const [previousDurationValue, setPreviousDurationValue] = [props.duration, props.setDuration];
    const showTimeSlotsErrorMessage = props.showTimeSlotsErrorMessage;
    const currentYear = new Date().getFullYear().toString();

    const [selectedTimeCellIds, setSelectedTimeCellIds] = useState([]); // selected calendar time cells
    const [customDurationValue, setCustomDurationValue] = useState(DURATION.SIXTY_MINUTES);
    const [customDurationSelected, setCustomDurationSelected] = useState("min");
    const [prevDuration, setPrevDuration] = useState(
        getDuration(durationSelected, customDurationValue, customDurationSelected)
    );
    const [maxTimeSlotsWarningMessage, setMaxTimeSlotsWarningMessage] = useState("");
    const [showAmazonianCalendar, setShowAmazonianCalendar] = useState(false);
    const [calendarViewType, setCalendarViewType] = useState("workweek");
    const [showClearTimeSlotsModal, setShowClearTimeSlotsModal] = useState(false);
    const [clearTimeSlotsText, setClearTimeSlotsText] = useState("");
    const [temporaryTimezoneValue, setTemporaryTimezoneValue] = useState(timezone);
    const [temporaryDurationValue, setTemporaryDurationValue] = useState(durationSelected);
    const [gridModeDate, setGridModeDate] = useState(formatIso(new Date()));

    /* Step smart suggestions states */
    // eslint-disable-next-line no-unused-vars
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [meetingSuggestionSelected, setMeetingSuggestionSelected] = useState([]);
    const [meetingSuggestionsDetail, setMeetingSuggestionsDetail] = useState({});
    const [isExistingMeetingSelected, setIsExistingMeetingSelected] = useState();
    const [changedTimeWindow, setChangedTimeWindow] = useState(); // current grid window position
    // eslint-disable-next-line no-unused-vars
    const [spansMultipleDates, setSpansMultipleDates] = useState(false);
    const [freeAttendeesCount, setFreeAttendeesCount] = useState(0);
    // Updating timezone picker updates timezone value, but it should not affect the timezone of current suggestions
    // eslint-disable-next-line no-unused-vars
    const [suggestionsTimezoneId, setSuggestionsTimezoneId] = useState(timezone);
    // eslint-disable-next-line no-unused-vars
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
    const [highLightTimeSlot, setHighLightTimeSlot] = useState();
    const [prevCalendarDate, setPrevCalendarDate] = useState();

    useEffect(() => {
        if (gridModeDate !== undefined) {
            setPrevCalendarDate(gridModeDate);
        }
    }, [prevCalendarDate, gridModeDate]);

    const onClickTimeSlot = (timeSlot) => {
        setGridModeDate(formatIso(utcToZonedTime(fromUnixTime(timeSlot.startTime), timezone)));
        setHighLightTimeSlot(timeSlot);
    };

    const onChangeTimeSlotsSelected = (timeSlot) => {
        const index = timeSlotsSelected.findIndex((t) => t.startTime === timeSlot.startTime && t.endTime === timeSlot.endTime);

        if (index === -1) {
            if (timeSlotsSelected?.length === MAX_TIME_SLOTS) {
                setMaxTimeSlotsWarningMessage(MAX_TIME_SLOTS_MESSAGE);
                return;
            }
            setTimeSlotsSelected([...timeSlotsSelected, timeSlot]);
            onSetTimeSlotsSelectedFromGridMode(true);
        } else {
            if (timeSlot.startTime === highLightTimeSlot?.startTime) {
                // clear the highlight time slot on the graph
                setHighLightTimeSlot(undefined);
            }
            const newTimeSlotSelected = [...timeSlotsSelected];
            newTimeSlotSelected.splice(index, 1);
            setTimeSlotsSelected(newTimeSlotSelected);

            // reset the mark
            if (newTimeSlotSelected.length === 0) {
                onSetTimeSlotsSelectedFromGridMode(false);
            }

            // Focus will return back to the selected time slots header for screen readers
            let focusedTimeSlotsHeader = document.getElementById("selected-time-slots");
            focusedTimeSlotsHeader.focus();
        }

        setMaxTimeSlotsWarningMessage("");
    };

    // Change the selected suggestion and update the state with the selected rooms
    const onChangeSelectSuggestion = (suggestion) => {
        const index = meetingSuggestionSelected.findIndex((s) => s.peopleRankIndex === suggestion.peopleRankIndex);
        if (index === -1) {
            setMeetingSuggestionSelected([...meetingSuggestionSelected, suggestion]);
        } else {
            meetingSuggestionSelected.splice(index, 1);
            setMeetingSuggestionSelected([...meetingSuggestionSelected]);
        }

        setIsExistingMeetingSelected(false);
    };

    // Remove all selected time cells from the grid and calendar
    const clearTimeSlots = () => {
        setTimeSlotsSelected([]);
        onChangeSelectedTimeCellIds([]);
    };

    const onChangeSelectedTimeCellIds = (timeCellIds) => {
        setSelectedTimeCellIds(timeCellIds);
        onSetTimeSlotsSelectedFromCalendarMode(timeCellIds !== undefined && timeCellIds.length > 0);
    };

    // TODO: remove duplicate functions, simplify logic to pass any type
    const onChangeTimezoneSelected = (value) => {
        if (previousTimezoneValue === value) {
            return;
        }

        if (timeSlotsSelected?.length > 0) {
            setTemporaryTimezoneValue(value);
            setClearTimeSlotsText("timezone");
            setShowClearTimeSlotsModal(true);
        } else {
            setTimezone(value);
            setPreviousTimezoneValue(value);
        }
    };

    const onChangeTimezoneConfirmed = () => {
        setTimezone(temporaryTimezoneValue);
        setPreviousTimezoneValue(temporaryTimezoneValue);
        clearTimeSlots();
        setShowClearTimeSlotsModal(false);
    };

    const onChangeDurationSelected = (value) => {
        if (previousDurationValue === value) {
            return;
        }

        if (timeSlotsSelected?.length > 0) {
            setTemporaryDurationValue(value);
            setClearTimeSlotsText("duration");
            setShowClearTimeSlotsModal(true);
        } else {
            setDurationSelected(value);
            setPreviousDurationValue(value);
        }
    };

    const onChangeDurationConfirmed = () => {
        setDurationSelected(temporaryDurationValue);
        setPreviousDurationValue(temporaryDurationValue);
        clearTimeSlots();
        setShowClearTimeSlotsModal(false);
    };

    const onClickDelete = () => {
        if (timeSlotsSelected?.length > 0) {
            setClearTimeSlotsText("delete");
            setShowClearTimeSlotsModal(true);
        }
    };

    const onDeleteTimeSlotsConfirmed = () => {
        clearTimeSlots();
        setShowClearTimeSlotsModal(false);
    }

    // Keep for future reference for adding the suggestions
    // const onSelectNewSuggestion = (suggestionIndexDelta) => {
    //     setMeetingSuggestionSelected((meetingSuggestionSelected + suggestionIndexDelta) || 0);
    //     onChangeSelectSuggestion(meetingSuggestions[(meetingSuggestionSelected + suggestionIndexDelta) || 0]);
    //     setChangedTimeWindow(undefined);
    // };

    // const getSelectedSuggestionNumber = () => {
    //     if (!meetingSuggestions || !meetingSuggestions.length) {
    //         return 0;
    //     }

    //     return (meetingSuggestionSelected || 0) + 1;
    // };

    // const toggleSortOption = () => {
    //     if (sortOption === "Availability") {
    //         setSortOption("Earliest time");
    //         meetingSuggestions.sort((suggestion1, suggestion2) => {
    //             return suggestion1.startTime - suggestion2.startTime;
    //         });
    //     } else {
    //         setSortOption("Availability");
    //         meetingSuggestions.sort((suggestion1, suggestion2) => {
    //             return suggestion2.peopleRank - suggestion1.peopleRank;
    //         });
    //     }
    // };

    const setNextDayDate = () => {
        setGridModeDate(formatIso(addTime(parseDatePickerDateString(gridModeDate), {days: 1})));
    };

    const setPreviousDayDate = () => {
        setGridModeDate(formatIso(addTime(parseDatePickerDateString(gridModeDate), {days: -1})));
    };

    const setNextWeekDate = () => {
        setGridModeDate(formatIso(addTime(parseDatePickerDateString(gridModeDate), {days: 7})));
    };

    const setPreviousWeekDate = () => {
        setGridModeDate(formatIso(addTime(parseDatePickerDateString(gridModeDate), {days: -7})));
    };

    return (
        <Column height="100%" spacing="none" spacingInset="none medium none none">
            <Row alignmentHorizontal="justify" spacingInset="medium none medium none" wrap="down">
                <Heading level={2} type="h100">Select maximum 10 time slots in your calendar</Heading>
                <Column>
                    <Toggle checked={showAmazonianCalendar} onChange={setShowAmazonianCalendar}>Amazonian calendar</Toggle>
                </Column>
            </Row>
            <ErrorMessageWrapper id="time-slot-error" errorMessage="This field is required. Select one or more time slots" showErrorMessage={showTimeSlotsErrorMessage}>
                <Row backgroundColor="#F9FAFA" alignmentVertical="center" alignmentHorizontal="justify" spacing="medium" spacingInset="small none" wrap="down" widths={["fill", "fit", "fill"]}>
                    <Row>
                        <Column spacing="none" width="115px">
                            <Text type="b100">Timezone</Text>
                            <TimezonePicker
                                screenSizeBreakpoint={screenSizeBreakpoint}
                                size="medium"
                                timezones={timezones}
                                value={timezone}
                                onChange={onChangeTimezoneSelected}
                            />
                        </Column>
                        <LabeledDurationSelect
                            fifteenMinutesSelectionOnly={true}
                            width="115px"
                            size="medium"
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            durationSelected={durationSelected}
                            setDurationSelected={onChangeDurationSelected}
                            setCustomDurationLabel={Noop}
                            setCustomDurationError={Noop}
                            setIsAllDayEvent={Noop}
                        />
                    </Row>
                    <Column spacing="none" spacingInset="none" alignmentHorizontal="center">
                        {!showAmazonianCalendar && screenSizeBreakpoint > SINGLE_DAY_VIEW_THRESHOLD ?
                            <Row spacingInset="none xlarge none none">
                                <Column spacingInset="none xlarge none none">
                                    <Text id="calendar-week-of-label" type="b100">Week of</Text>
                                </Column>
                            </Row>
                            :
                            <Row spacingInset="none xlarge none none">
                                <Column spacingInset="none xlarge none none">
                                    <Column spacingInset="none medium none none">
                                        <Text id="calendar-date-label" type="b100">Date</Text>
                                    </Column>
                                </Column>
                            </Row>
                        }
                        <ArrowDateWrapper
                            noExtraSpace={true}
                            setPreviousDayDate={showAmazonianCalendar || screenSizeBreakpoint <= SCREEN_SIZE.BREAKPOINT.SM ? setPreviousDayDate : setPreviousWeekDate}
                            setNextDayDate={showAmazonianCalendar || screenSizeBreakpoint <= SCREEN_SIZE.BREAKPOINT.SM ? setNextDayDate : setNextWeekDate}
                            previousDateLabel={`Navigate to previous ${showAmazonianCalendar ? "day" : "week"}`}
                            nextDateLabel={`Navigate to next ${showAmazonianCalendar ? "day" : "week"}`}
                            isLoading={props.isGettingSchedules}
                            buttonSize={screenSizeBreakpoint <= SCREEN_SIZE.BREAKPOINT.MOBILE  ? "small" : "medium"}
                            rowSpacing={screenSizeBreakpoint <= SCREEN_SIZE.BREAKPOINT.MOBILE  ? "none": "small"}
                        >
                            <DatePicker
                                width={screenSizeBreakpoint <= SCREEN_SIZE.BREAKPOINT.MOBILE  ? "169px" : "175px"}
                                size="medium"
                                value={gridModeDate}
                                onChange={setGridModeDate}
                                placeholder="Select date"
                                aria-labelledby={!showAmazonianCalendar && screenSizeBreakpoint > SINGLE_DAY_VIEW_THRESHOLD ? "calendar-week-of-label" : "calendar-date-label"}
                                locale={getLocalesFromSettingsDateFormat(props.dateFormat)}
                            />
                        </ArrowDateWrapper>
                    </Column>
                    <Column alignmentHorizontal="right">
                        {!showAmazonianCalendar && screenSizeBreakpoint > SINGLE_DAY_VIEW_THRESHOLD &&
                            <Column spacing="none">
                                <Text type="b100">&nbsp;</Text>
                                <ButtonGroup value={calendarViewType} onChange={setCalendarViewType}>
                                    <ButtonOption value="workweek">Work week</ButtonOption>
                                    <ButtonOption value="week">Week</ButtonOption>
                                </ButtonGroup>
                            </Column>
                        }
                    </Column>

                    <ActionableModal
                        open={showClearTimeSlotsModal}
                        setOpen={setShowClearTimeSlotsModal}
                        modalTitle={clearTimeSlotsText === "delete" ? "Delete time slots" : `Change ${clearTimeSlotsText}`}
                        modalDescriptions={clearTimeSlotsText === "delete" ?
                            ["Delete all selected time slots?", "This action can’t be undone."]
                            :
                            [`Change time slot ${clearTimeSlotsText}?`, "This will delete previous selected time slots."]}
                        modalConfirmButtonText={clearTimeSlotsText === "delete" ? "Delete" : "Change"}
                        onClickConfirm={
                            clearTimeSlotsText === "delete" ? onDeleteTimeSlotsConfirmed
                            : clearTimeSlotsText === "timezone" ? onChangeTimezoneConfirmed
                                : onChangeDurationConfirmed
                        }
                    />
                </Row>
            </ErrorMessageWrapper>
            {maxTimeSlotsWarningMessage.length > 0 &&
                <Column maxWidth="400px" spacingInset="medium none none none">
                    <Alert type="warning" size="medium" onClose={() => setMaxTimeSlotsWarningMessage("")}>
                        {maxTimeSlotsWarningMessage}
                    </Alert>
                </Column>
            }
            <Row width="100%" spacingInset="medium none none none">
                <PollKeyboardInstructions showAmazonianCalendar={showAmazonianCalendar} />
            </Row>
            {showAmazonianCalendar &&
                <Row alignmentHorizontal="center" spacingInset="medium none none none" spacing="small">
                    <Heading level={2} type="h200">{convertEpoch(getUnixTime(zonedTimeToUtc(parseDatePickerDateString(gridModeDate || prevCalendarDate), timezone)), "longWeekday", timezone)}</Heading>
                    {props.isGettingSchedules &&
                        <Loader size="small" />
                    }
                </Row>
            }
            <Row alignmentVertical="top" spacing="none">
                {showAmazonianCalendar ?
                    <div style={{overflow: "auto"}}>
                        <Column width="100%" alignmentHorizontal="center" spacingInset="medium none">
                            <Column width="100%" alignmentHorizontal="center">
                                <Column width="100%" alignmentHorizontal="center" spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.SM ? "none medium" : "none"}>
                                    {suggestionViewType === SUGGESTION_VIEW_TYPE.GRID &&
                                        <Column width="100%" alignmentHorizontal="center" spacing="none">
                                            <CalendarTimeGrid isMultiSelection noCalendarHeader noLocalTimeZoneInTheHeader showWorkingHour
                                                screenSizeBreakpoint={screenSizeBreakpoint}
                                                attendees={attendees}
                                                setGridAttendeeAvailability={Noop}
                                                freeAttendeesCount={freeAttendeesCount}
                                                setFreeAttendeesCount={setFreeAttendeesCount}
                                                meetingSuggestions={meetingSuggestions}
                                                timeSlotsSelected={timeSlotsSelected}
                                                onChangeTimeSlotsSelected={onChangeTimeSlotsSelected}
                                                meetingSuggestionSelected={meetingSuggestionSelected}
                                                isExistingMeetingSelected={isExistingMeetingSelected}
                                                changedTimeWindow={changedTimeWindow}
                                                setChangedTimeWindow={setChangedTimeWindow}
                                                spansMultipleDates={spansMultipleDates}
                                                onSelectSuggestion={onSelectSuggestion}
                                                durationSelected={durationSelected}
                                                setDurationSelected={setDurationSelected}
                                                customDurationValue={customDurationValue}
                                                setCustomDurationValue={setCustomDurationValue}
                                                customDurationSelected={customDurationSelected}
                                                setCustomDurationSelected={setCustomDurationSelected}
                                                prevDuration={prevDuration}
                                                setPrevDuration={setPrevDuration}
                                                isInitialLoad={isInitialLoad}
                                                setIsInitialLoad={setIsInitialLoad}
                                                timezones={timezones}
                                                suggestionsTimezoneId={timezone}
                                                onChangeSelectSuggestion={onChangeSelectSuggestion}
                                                currentSuggestionIndex={currentSuggestionIndex}
                                                onChangeStep={props.onChangeStep}
                                                prevGetScheduleQuery={props.prevGetScheduleQuery}
                                                setPrevGetScheduleQuery={props.setPrevGetScheduleQuery}
                                                highLightTimeSlot={highLightTimeSlot}
                                                dateFormat={dateFormat}
                                                setTimeSlotsSelected={setTimeSlotsSelected}
                                                gridModeDate={gridModeDate || prevCalendarDate}
                                            />
                                            <Column width="100%" alignmentHorizontal="center" spacingInset="small"
                                                    backgroundColor="white">
                                                <StatusLegend
                                                    orientation="row"
                                                    statuses={["busy", "tentative", "outOfOffice", "meetingHours", "noData"]}
                                                    showStripes={true}
                                                />
                                            </Column>
                                        </Column>
                                    }
                                    {suggestionViewType === SUGGESTION_VIEW_TYPE.LIST && meetingSuggestions.length > 0 &&
                                        <MeetingSuggestions isMultiSelection
                                            identity={identity}
                                            attendees={attendees}
                                            timezones={timezones}
                                            suggestionsTimezoneId={suggestionsTimezoneId}
                                            currentYear={currentYear}
                                            isInitialLoad={isInitialLoad}
                                            setIsInitialLoad={setIsInitialLoad}
                                            meetingSuggestions={meetingSuggestions}
                                            meetingSuggestionSelected={meetingSuggestionSelected}
                                            meetingSuggestionsDetail={meetingSuggestionsDetail}
                                            timeSlotsSelected={timeSlotsSelected}
                                            onChangeTimeSlotsSelected={onChangeTimeSlotsSelected}
                                            setMeetingSuggestionsDetail={setMeetingSuggestionsDetail}
                                            onChangeSelectSuggestion={onChangeSelectSuggestion}
                                            isExistingMeetingSelected={isExistingMeetingSelected}
                                            timeFormat={timeFormat}
                                        />
                                    }
                                </Column>
                            </Column>
                        </Column>
                    </div>
                    :
                    <Column width="100%" spacingInset="medium none">
                        <CreatePollCalendar
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            userEmail={identity.email}
                            calendarViewType={calendarViewType}
                            date={gridModeDate || prevCalendarDate}
                            timezone={timezone}
                            timeFormat={timeFormat}
                            timeSlotDuration={durationSelected}
                            selectedTimeCellIds={selectedTimeCellIds}
                            setSelectedTimeCellIds={onChangeSelectedTimeCellIds}
                            selectedTimeSlots={timeSlotsSelected}
                            setSelectedTimeSlots={setTimeSlotsSelected}
                            setMaxTimeSlotsWarningMessage={setMaxTimeSlotsWarningMessage}
                        />
                    </Column>
                }
            </Row>
            <Divider size="small" />
            <Row alignmentHorizontal="justify" spacingInset="medium none">
                <Heading id="selected-time-slots" level={2} type="h100" tabIndex="-1">Selected time slots ({timeSlotsSelected?.length})</Heading>
                {timeSlotsSelected?.length > 0 &&
                    <Button type="tertiary" onClick={onClickDelete}>
                        {screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.SM ?
                            <React.Fragment>
                                Delete selected times
                            </React.Fragment>
                            :
                            <Icon tokens={trashTokens} />
                        }
                    </Button>
                }
            </Row>
            <Row wrap="down" spacingInset="none none medium none">
                <ul id="list-of-selected-time-slots">
                    {timeSlotsSelected.map((timeSlot) => {
                        // The grid mode can only hold the slot in one day. ex: Tue, Jun 21
                        const dateString = convertEpoch(timeSlot.startTime, "shortWeekdayDate", timezone);
                        let weekDay, month, day;
                        if (dateString.indexOf(",") > -1) {
                            weekDay = dateString.split(",")[0].toUpperCase();
                        }
                        const monthDay = dateString.split(" ");
                        if (monthDay.length > 1) {
                            month = monthDay[monthDay.length - 2].toUpperCase();
                            day = monthDay[monthDay.length - 1];
                        }

                        if (day !== undefined && parseInt(day) < 10) {
                            day = "0" + day;
                        }

                        // Get voiceover date aria-label
                        const timeString = getTimeSlotString(timeSlot.startTime, timeSlot.endTime, timezone);

                        return (
                            <li
                                id="time-slot"
                                key={timeSlot.startTime + ":" + timeSlot.endTime}
                                style={{"cursor": "pointer"}}
                                onClick={() => onClickTimeSlot(timeSlot)}
                            >
                                <Box type="outline" spacingInset="0 150 400 400" minWidth="200px" width="250px">
                                    <Column spacing="none">
                                        <Row alignmentHorizontal="end" spacingInset="xsmall xsmall none none">
                                            <Button propagateClickEvent={false} type="icon" size="small" label={`Delete time slot on ${timeString}`} onClick={() => onChangeTimeSlotsSelected(timeSlot)}>
                                                <Icon tokens={closeMediumTokens} />
                                            </Button>
                                        </Row>
                                        <Row>
                                            <Text type="h400">{day}</Text>
                                            <Text type="b300" color="secondary">{weekDay}</Text>
                                        </Row>
                                        <Row spacingInset="none" spacing="small">
                                            <Text type="b300">{month}</Text>
                                            <Text type="h100">{convertEpoch(timeSlot.startTime, "time", timezone, timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR)}-{convertEpoch(timeSlot.endTime, "time", timezone, timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR)}</Text>
                                            <Text type="b100" color="secondary">{timezoneIdToDisplayName(timezones, timezone)}</Text>
                                        </Row>
                                    </Column>
                                </Box>
                            </li>
                    )})}
                </ul>
            </Row>
        </Column>
    );
};

export default CreatePollTimeSlots;
