import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Loader from "@amzn/meridian/loader";

import { sendNotification, setReminderResponse } from "../actions";
import { getReminderResponse } from "../../../sagas/selector";

import successSvg from "../../../assets/icons/polls/reminder_success.svg";

import "../meeting-polls.css";
import { formatDateTime, showAttendee } from "../poll-utils";
import {
    REMINDER_STATUS,
    RESULT_PAGE_SIZE,
} from "../poll-constants";
import { ALERTS, SCREEN_SIZE } from "../../shared/shared-constants";

const PollPendingCard = (props) => {
    const identity = props.identity;
    const pollDetails = props.pollDetails;
    const attendee = props.attendee;
    const timeFormat = props.timeFormat;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;

    const timezone = pollDetails.timezone;
    const reminderSentTime = (attendee && attendee.reminderSentTime / 1000) || "";
    const [reminderStatus, setReminderStatus] = useState(REMINDER_STATUS.DEFAULT);
    const reminderResponse = useSelector(getReminderResponse);
    const dispatch = useDispatch();

    const onSendReminder = () => {
        if (reminderStatus !== REMINDER_STATUS.LOADING) {
            setReminderStatus(REMINDER_STATUS.LOADING);
            dispatch(sendNotification("reminder", identity.username, pollDetails.pollID, [attendee.email], "", ""));
        }
    };

    useEffect(() => {
        const response = reminderResponse[attendee.email];
        if (reminderStatus === REMINDER_STATUS.LOADING) {
            setReminderStatus(response);
        }
        // eslint-disable-next-line
    }, [reminderResponse]);

    useEffect(() => {
        // When first loading page, set response as default.
        setReminderStatus(REMINDER_STATUS.DEFAULT);
        dispatch(setReminderResponse(attendee.email, REMINDER_STATUS.DEFAULT));
        // eslint-disable-next-line
    }, []);

    const renderAttendee = () => {
        return (
            <Column
                alignmentHorizontal="left"
                spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ? "medium none none large" : "none"}
                spacing="xsmall"
            >
                {showAttendee(attendee)}
                {!attendee.external &&
                    <Text type="b100">
                        {attendee.email}
                    </Text>
                }
            </Column>
        );
    };

    const renderReminderButtonAndTime = () => {
        return (
            <Column spacing="none">
                <Column
                    alignmentHorizontal="left"
                    spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ? "medium none none medium" : "none"}
                    spacing="xsmall"
                >
                    <Row width="100%" spacingInset="small none none small" spacing="xsmall" aria-live="polite">
                        <Button
                            type="tertiary"
                            size="medium"
                            label={`Send reminder to ${attendee.displayName}`}
                            onClick={onSendReminder}
                            minWidth={RESULT_PAGE_SIZE.REMINDER_BUTTON_MIN_WIDTH}
                        >
                            Send reminder
                        </Button>
                        {reminderStatus === REMINDER_STATUS.LOADING ?
                            <Loader size="small" aria-live="polite" label="Sending reminder.." />
                            :
                            reminderStatus === REMINDER_STATUS.SUCCESS &&
                            <div>
                                <span class="visually-hidden">
                                    {`Reminder successfully sent to ${attendee.displayName}`}
                                </span>
                                <img src={successSvg} height="16px" width="16px" alt="" />
                            </div>
                        }
                    </Row>
                    <Column spacingInset="none none none small">
                        {reminderSentTime &&
                            <Text type="b100" color="secondary">
                                Last sent {formatDateTime(reminderSentTime, "shortDate", timezone)} {formatDateTime(reminderSentTime, "time", timezone, timeFormat)}
                            </Text>
                        }
                    </Column>
                </Column>
                <Column alignmentHorizontal="center">
                    {reminderStatus === REMINDER_STATUS.FAILURE &&
                        <Alert
                            type={ALERTS.MEETING_POLL_INVALID_REMINDER.type}
                            size="small"
                            aria-live="polite"
                            aria-label="Reminder failed to send due to an error, try again later"
                        >
                            <Text id={ALERTS.MEETING_POLL_INVALID_REMINDER.id}>
                                {ALERTS.MEETING_POLL_INVALID_REMINDER.value}
                            </Text>
                        </Alert>
                    }
                    {reminderStatus === REMINDER_STATUS.DUPLICATE &&
                        <Alert
                            type={ALERTS.MEETING_POLL_DUPLICATE_REMINDER.type}
                            size="small"
                            aria-live="polite"
                            aria-label="Reminder already sent"
                        >
                            <Text id={ALERTS.MEETING_POLL_DUPLICATE_REMINDER.id}>
                                {ALERTS.MEETING_POLL_DUPLICATE_REMINDER.value}
                            </Text>
                        </Alert>
                    }
                </Column>
            </Column>
        )
    };

    return (
        <section aria-label={"Attendee " + attendee.displayName}>
            <Card spacingInset="none">
                {screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ?
                    <Row
                        alignmentVertical="top"
                        width={RESULT_PAGE_SIZE.CARD_WIDTH}
                        spacing="none"
                        spacingInset="none"
                        widths={["fill", "1px", "45%"]}
                    >
                        {renderAttendee(attendee)}
                        <div style={{
                            height: "111px",
                            borderLeft: "1px solid #E7E9E9"
                        }} />
                        {renderReminderButtonAndTime()}
                    </Row>
                    :
                    <Column
                        alignmentHorizontal="left"
                        width={RESULT_PAGE_SIZE.CARD_MOBILE_VIEW_WIDTH}
                        spacingInset="large"
                        spacing="medium"
                    >
                        {renderAttendee(attendee)}
                        <div className="poll-horizontal-divider" />
                        {renderReminderButtonAndTime()}
                    </Column>
                }
            </Card>
        </section>
    )
};

export default PollPendingCard;