import { cx } from "emotion"
import { memoizeCurry } from "./functional"

const cxMemoizedCurried = memoizeCurry(cx, 1)

/**
 * A memoized version of cx. Only supports two classes.
 */
const cxMemoized = (a, b) => cxMemoizedCurried(a)(b)

export default cxMemoized
