import React, { useEffect } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";

import AttendeeList from "../../people/components/attendee-list";
import LocationList from "../../shared/locations/components/location-list";
import AddLocation from "../../shared/locations/components/add-location";
import Favorites from "../../shared/favorites/components/favorites";

import PatternBackground from "../../../assets/backgrounds/pattern_small_vertical_repeating.svg";

import {
    TABLE_ROW_COLOR,
    TABLE_HEADER_ROW_COLOR,
    INPUT_WIDTH,
    BACKGROUND_OPACITY,
} from "../meeting-scheduler-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import {
    ATTENDEE_SEARCH_BAR_DEFAULT_HEIGHT,
    ATTENDEE_LIST_CELL_MARGIN,
    ATTENDEE_LIST_CELL_HEIGHT,
    ATTENDEE_LIST_DEFAULT_HEIGHT,
    ADD_ROOM_ROW_HEIGHT,
} from "../../people/people-constants";
import { updateQueryParameters } from "../../shared/permalink/permalink-utils";

const StepAttendeesAndRooms = (props) => {
    const flowType = props.flowType;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const identity = props.identity;
    const attendees = props.attendees;
    const findRooms = props.findRooms;
    const buildings = props.buildings;
    const rooms = props.rooms;
    const locations = props.locations;
    const peopleSuggestions = props.peopleSuggestions;
    const groupSuggestions = props.groupSuggestions;
    const favorites = props.favorites;
    const userSettingsBuilding = props.userSettingsBuilding;
    const userSettingsFloor = props.userSettingsFloor;
    const [queryParameters, setQueryParameters] = [props.queryParameters, props.setQueryParameters];

    // useEffect for generating query parameters for Attendees and Rooms
    useEffect(() => {
        if (attendees) {
            const parameters = {
                participantsByPriority: attendees.map((attendee) => attendee["email"]).join(","),
                buildingCode: locations.map((location) => location["buildingCode"]).join(","),
                floor: locations.map((location) => location["floor"]).join(","),
            };

            // Update query parameter if the value is different
            updateQueryParameters(parameters, queryParameters, setQueryParameters);
        }
    }, [attendees, locations, queryParameters, setQueryParameters]);

    return (
        <Row
            widths={screenSizeBreakpoint >= SCREEN_SIZE.FULL_VIEW ? ["fill", "fit", "fill", "fit"] : ["fill", "fit"]}
            alignmentVertical="stretch"
            spacing="none"
            alignmentHorizontal="center"
        >
            {screenSizeBreakpoint >= SCREEN_SIZE.FULL_VIEW &&
                <Column backgroundColor={TABLE_ROW_COLOR}>
                    <div style={{display: "flex"}}>
                        <Table
                            headerRows={1}
                            showDividers={true}
                            spacing="none"
                        >
                            <div style={{
                                display: "flex",
                                height: ATTENDEE_SEARCH_BAR_DEFAULT_HEIGHT,
                                backgroundColor: "white",
                            }} />
                            <TableRow>
                                <TableCell><br/></TableCell>
                            </TableRow>

                        </Table>
                    </div>
                    {findRooms &&
                        <Table
                            headerRows={1}
                            showDividers={false}
                            spacing="none"
                        >
                            <div style={{
                                display: "flex",
                                height: Math.max(ATTENDEE_LIST_DEFAULT_HEIGHT - ATTENDEE_LIST_CELL_MARGIN,
                                    ATTENDEE_LIST_CELL_HEIGHT * attendees.length - ATTENDEE_LIST_CELL_MARGIN),
                                backgroundImage: `url(${PatternBackground})`,
                                backgroundRepeat: "repeat-y",
                                backgroundColor: TABLE_ROW_COLOR,
                                backgroundPosition: "left -250px center",
                                backgroundAttachment: "fixed",
                                opacity: BACKGROUND_OPACITY
                            }} />
                            <div style={{
                                height: ADD_ROOM_ROW_HEIGHT,
                                backgroundColor: TABLE_HEADER_ROW_COLOR,
                                borderBottom: "1px solid #bbc0c1", // Meridian gray-300
                            }} />
                            <TableRow>
                                <TableCell><br /></TableCell>
                            </TableRow>
                        </Table>
                    }
                    <div style={{
                        height: "100%",
                        backgroundImage: `url(${PatternBackground})`,
                        backgroundRepeat: "repeat-y",
                        backgroundColor: {TABLE_ROW_COLOR},
                        backgroundPosition: "left -250px center",
                        backgroundAttachment: "fixed",
                        opacity: BACKGROUND_OPACITY
                    }} />
                </Column>
            }
            <Column heights={["fit"]} spacing="none">
                <Column
                    width={screenSizeBreakpoint > SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? INPUT_WIDTH.TABLE_WIDTH : "100%"}
                    height="100%"
                    heights={["fit", "fill"]}
                    spacing="none"
                    alignmentVertical="top"
                    backgroundColor={TABLE_ROW_COLOR}
                >
                    <AttendeeList
                        flowType={flowType}
                        screenSizeBreakpoint={screenSizeBreakpoint}
                        identity={identity}
                        attendees={attendees}
                        rooms={rooms}
                        locations={locations}
                        peopleSuggestions={peopleSuggestions}
                        groupSuggestions={groupSuggestions}
                        favorites={favorites}
                        findRooms={findRooms}
                        onUpdateFindRooms={props.onUpdateFindRooms}
                        onAddAttendee={props.onAddAttendee}
                        onUpdateAttendee={props.onUpdateAttendee}
                        onRemoveAttendee={props.onRemoveAttendee}
                        onLoadFloors={props.onLoadFloors}
                        onAddRoom={props.onAddRoom}
                        onRemoveRoom={props.onRemoveRoom}
                        onAddLocation={props.onAddLocation}
                        onRemoveLocation={props.onRemoveLocation}
                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                        onGetRASDataForGroup={props.onGetRASDataForGroup}
                        onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                        onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                        onGetGroupSuggestions={props.onGetGroupSuggestions}
                        onClearGroupSuggestions={props.onClearGroupSuggestions}
                        onAddFavorite={props.onAddFavorite}
                        onRemoveFavorite={props.onRemoveFavorite}
                        onAddRoomByEmail={props.onAddRoomByEmail}
                    />
                    {findRooms &&
                        <Column
                            height="100%"
                            heights={["fit", "fill"]}
                            spacing="none"
                            alignmentVertical="top"
                        >
                            <div style={{
                                borderBottom: "1px solid #bbc0c1", // Meridian gray-300
                            }}>
                                <Row
                                    width="100%"
                                    backgroundColor={TABLE_HEADER_ROW_COLOR}
                                    spacingInset="medium"
                                >
                                    <AddLocation
                                        identity={identity}
                                        screenSizeBreakpoint={screenSizeBreakpoint}
                                        buildings={buildings}
                                        rooms={rooms}
                                        locations={locations}
                                        findRooms={props.findRooms}
                                        favorites={favorites}
                                        userSettingsBuilding={userSettingsBuilding}
                                        userSettingsFloor={userSettingsFloor}
                                        userSettingsMinimumSeats={props.userSettingsMinimumSeats}
                                        userSettingsCamera={props.userSettingsCamera}
                                        userSettingsDisplay={props.userSettingsDisplay}
                                        userSettingsNoRestricted={props.userSettingsNoRestricted}
                                        userSettingsNoManaged={props.userSettingsNoManaged}
                                        onUpdateFindRooms={props.onUpdateFindRooms}
                                        onLoadFloors={props.onLoadFloors}
                                        onAddRoom={props.onAddRoom}
                                        onRemoveRoom={props.onRemoveRoom}
                                        onAddLocation={props.onAddLocation}
                                        onRemoveLocation={props.onRemoveLocation}
                                    />
                                    <Text color="secondary">
                                        (Optional)
                                    </Text>
                                </Row>
                            </div>
                            <LocationList
                                identity={identity}
                                flowType={flowType}
                                screenSizeBreakpoint={screenSizeBreakpoint}
                                buildings={buildings}
                                rooms={rooms}
                                locations={locations}
                                findRooms={findRooms}
                                favorites={favorites}
                                onUpdateFindRooms={props.onUpdateFindRooms}
                                onLoadFloors={props.onLoadFloors}
                                onAddRoom={props.onAddRoom}
                                onRemoveRoom={props.onRemoveRoom}
                                onAddLocation={props.onAddLocation}
                                onRemoveLocation={props.onRemoveLocation}
                                onAddFavorite={props.onAddFavorite}
                                onRemoveFavorite={props.onRemoveFavorite}
                            />
                        </Column>
                    }
                </Column>
            </Column>
            {screenSizeBreakpoint >= SCREEN_SIZE.FULL_VIEW &&
                <Column backgroundColor={TABLE_ROW_COLOR}>
                    <div style={{display: "flex"}}>
                        <Table
                            headerRows={1}
                            showDividers={true}
                            spacing="none"
                        >
                            <div style={{
                                display: "flex",
                                height: ATTENDEE_SEARCH_BAR_DEFAULT_HEIGHT,
                                backgroundColor: "white",
                            }} />
                            <TableRow>
                                <TableCell><br/></TableCell>
                            </TableRow>

                        </Table>
                    </div>
                    {findRooms &&
                        <Table
                            headerRows={1}
                            showDividers={false}
                            spacing="none"
                        >
                            <div style={{
                                display: "flex",
                                height: Math.max(ATTENDEE_LIST_DEFAULT_HEIGHT - ATTENDEE_LIST_CELL_MARGIN,
                                    ATTENDEE_LIST_CELL_HEIGHT * attendees.length - ATTENDEE_LIST_CELL_MARGIN),
                                backgroundImage: `url(${PatternBackground})`,
                                backgroundRepeat: "repeat-y",
                                backgroundColor: TABLE_ROW_COLOR,
                                backgroundPosition: "right center",
                                backgroundAttachment: "fixed",
                                opacity: BACKGROUND_OPACITY
                            }} />
                            <div style={{
                                height: ADD_ROOM_ROW_HEIGHT,
                                backgroundColor: TABLE_HEADER_ROW_COLOR,
                                borderBottom: "1px solid #bbc0c1", // Meridian gray-300
                            }} />
                            <TableRow>
                                <TableCell><br /></TableCell>
                            </TableRow>
                        </Table>
                    }
                    <div style={{
                        height: "100%",
                        backgroundImage: `url(${PatternBackground})`,
                        backgroundRepeat: "repeat-y",
                        backgroundColor: {TABLE_ROW_COLOR},
                        backgroundPosition: "right center",
                        backgroundAttachment: "fixed",
                        opacity: BACKGROUND_OPACITY
                    }} />
                </Column>
            }
            {screenSizeBreakpoint > SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR &&
                <Column type="outline">
                    <Favorites
                        identity={identity}
                        attendees={attendees}
                        rooms={rooms}
                        locations={locations}
                        favorites={favorites}
                        onAddAttendee={props.onAddAttendee}
                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                        onAddRoomByEmail={props.onAddRoomByEmail}
                        onAddLocation={props.onAddLocation}
                        onRemoveFavorite={props.onRemoveFavorite}
                    />
                </Column>
            }
        </Row>
    );
};

export default StepAttendeesAndRooms;
