import focusRingTokens from "@amzn/meridian-tokens/component/focus-ring"
import {
  elevationBackground,
  elevationMidground,
  elevationForeground,
} from "@amzn/meridian-tokens/base/elevation"
import { getToken } from "../_utils/token"

/**
 * Styles for our focus ring. Apply these to the focuseable element.
 *
 * NOTE: This applies "position: relative" to the focuseable element in order to
 * position the ring absolutely. This method will not work on elements without
 * a box (e.g. a link that wraps across lines, or an element with display set to
 * "content").
 */

const focusRing = (
  themeTokens,
  {
    // Is the element focused?
    focus,
    // Is the element in an error state?
    error,
    // Should a drop shadow be applied on hover/focus? Can be true, false, or
    // a css box-shadow for a custom shadow.
    dropShadow,
    // The color to fill in the gap between the element and the focus ring
    // (defaults to no fill).
    gapColor,
    // The offset between the outer edge of the element and the outer edge of
    // the ring (negative = the ring inside the element, positive = the ring
    // outside the element).
    offset = 0,
    // The width of the element's border.
    borderWidth = 0,
    // The radius of the element's border. Accepts css top/right/bottom/left
    // border shorthand.
    borderRadius = 0,
    // Use this to configure how the hover state is triggered (e.g. set to
    // "* > &:hover" to trigger the hover state when the element's parent is
    // hovered)
    hoverSelector = "&:hover",
  }
) => {
  const tokens = focusRingTokens(themeTokens)
  const state = focus ? "focus" : error ? "error" : undefined
  const width = getToken(tokens, "focusRingWidth")
  const color = getToken(tokens, "focusRingColor", state)
  const dropShadowCss =
    dropShadow === true
      ? `${getToken(tokens, "focusRingDropShadowOffsetX")}px ${getToken(
          tokens,
          "focusRingDropShadowOffsetY"
        )}px ${getToken(tokens, "focusRingDropShadowBlur")}px ${getToken(
          tokens,
          "focusRingDropShadowSpread"
        )}px ${getToken(tokens, "focusRingDropShadowColor")}`
      : dropShadow
  // If we're supposed to fill in the gap between the focus ring and the
  // element then use an inset box shadow.
  const gapShadowCss =
    color && gapColor
      ? `inset 0 0 0 ${offset - width}px ${gapColor}`
      : undefined
  const dropShadowAndGapShadowCss =
    dropShadowCss && gapShadowCss
      ? `${dropShadowCss}, ${gapShadowCss}`
      : dropShadowCss || gapShadowCss
  return {
    // The focuseable element needs to be relatively positioned so that we can
    // absolutely position the focus ring
    position: "relative",
    // If the ring entirely overlaps the focused element's border, turn the
    // element's border off to avoid artifacts near the rounded corners
    borderColor:
      color && offset >= 0 && width - offset >= borderWidth
        ? "transparent"
        : undefined,
    // Apply the drop shadow to the focuseable element on hover if we're not
    // showing a ring
    [hoverSelector]:
      dropShadowCss && !color ? { boxShadow: dropShadowCss } : undefined,
    // Apply the drop shadow and the gap shaodw to the ring on hover if we're
    // showing an error ring
    [hoverSelector
      .split(",")
      .map(selector => `${selector.trim()}:after`)
      .join(", ")]:
      dropShadowCss && state === "error"
        ? { boxShadow: dropShadowAndGapShadowCss }
        : undefined,
    // If there's focus or an error we move the element into the fixture or
    // foreground (used for input groups). Otherwise we keep it in midground
    zIndex: focus
      ? elevationForeground
      : error
      ? elevationMidground
      : elevationBackground,
    // If we have a focus ring color then display the ring.
    "&:after": color
      ? {
          content: "''",
          pointerEvents: "none",
          position: "absolute",
          top: -offset - borderWidth,
          right: -offset - borderWidth,
          bottom: -offset - borderWidth,
          left: -offset - borderWidth,
          border: `${width}px solid ${color}`,
          borderRadius:
            typeof borderRadius === "string"
              ? borderRadius
                  .split(" ")
                  .map(radius => {
                    const r = parseInt(radius)
                    return r > 0 ? `${parseInt(radius) + offset}px` : "0"
                  })
                  .join(" ")
              : borderRadius > 0
              ? borderRadius + offset
              : 0,
          boxShadow:
            state === "focus" ? dropShadowAndGapShadowCss : gapShadowCss,
        }
      : undefined,
  }
}

export default focusRing
