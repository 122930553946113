import findIndex from "lodash/findIndex"
import findLastIndex from "lodash/findLastIndex"

const focusNodeQuery = "button, input, select, textarea, [tabindex], [href]"

/**
 * Returns true if a node can be focused
 */
const canFocusNode = node =>
  node && node.matches
    ? node.matches(focusNodeQuery)
    : node && node.msMatchesSelector
    ? node.msMatchesSelector(focusNodeQuery)
    : false

const canKeyboardFocusNode = node =>
  canFocusNode(node) && node.getAttribute("tabindex") !== "-1"

const getFocusNodes = node =>
  node ? [].slice.call(node.querySelectorAll(focusNodeQuery)) : []

const getKeyboardFocusNodes = node =>
  getFocusNodes(node).filter(canKeyboardFocusNode)

/**
 * Returns an object containing two arrays of focuseable nodes keyed under
 * "startNodes" and "endNodes". The startNodes array contains every node that,
 * when focused, will cause the parent node to lose focus if the user presses
 * shift + tab (moving back in the tab order). The endNodes array contains every
 * node that, when focused, will cause the parent node to lose focus if the user
 * presses tab (moving forward in the tab order).
 */
const getBoundingFocusNodes = node => {
  const nodes = [
    canFocusNode(node) ? node : undefined,
    ...getFocusNodes(node),
  ].filter(Boolean)
  const startFocusIndex = findIndex(nodes, canKeyboardFocusNode)
  const endFocusIndex = findLastIndex(nodes, canKeyboardFocusNode)
  const startNodes =
    startFocusIndex !== -1 ? nodes.slice(0, startFocusIndex + 1) : nodes
  const endNodes = endFocusIndex !== -1 ? nodes.slice(endFocusIndex) : nodes
  return { startNodes, endNodes }
}

export {
  canFocusNode,
  canKeyboardFocusNode,
  getFocusNodes,
  getKeyboardFocusNodes,
  getBoundingFocusNodes,
}
