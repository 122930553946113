import axios from 'axios';
import {ENDPOINTS} from "./endpointConfig";
export const getCurrentUserInfo = async () => {
    return getLivestreamEndpoint().then(async endpoint => {
        console.log("Fetched livestream endpoint " + endpoint);
        return await axios.get(endpoint + "/userinfo",
            {withCredentials: true})
            .then(async response => {
                const userInfo = response.data;
                return await fetch(endpoint + "/livestream/api/currentUser",
                    {credentials: "include"})
                    .then(response => {
                        return response.json();
                    })
                    .then(currentUser => {
                        userInfo.username = currentUser.username;
                        userInfo.name = currentUser.name;
                        userInfo.title = currentUser.title;
                        userInfo.phone = currentUser.phone;
                        userInfo.email = currentUser.email;
                        return userInfo;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    });
}

const getLivestreamEndpoint = () => {
    const configUrl = `${process.env.PUBLIC_URL}/web-config.json`;
    return axios.get(configUrl).then((res) => {
        return ENDPOINTS[res.data.stage]["IAD"];
    });
}