import { call, put, retry, select } from "redux-saga/effects";
import aws4 from "aws4";

import { getIdentityTokens, getSigningCredentials } from "../../../../sagas/selector";
import { ENDPOINTS, OPERATIONS, OPERATION_PATHS } from "./endpointConfig";
import {
    cleanEmpty,
    fetchRequest,
    getErrorResponse,
    isBadRequestError,
    isErrorResponse,
    isRetryableError,
    stringifyError,
    updateRequesterEmailInParams,
} from "../apig-utils";
import { getStageConfig } from "../../stageConfig/stage-config-utils";
import {
    postRASAPICallMetric,
    postUnknownEdgeLocationMetric,
    postRequesterEmailRetryMetric,
} from "../../../shared/metrics/actions";
import { MAX_ATTEMPTS, RETRY_DELAY_IN_MS } from "../apig-constants";
import { getRegion, REGION_CODE_TO_AWS_STANDARD_MAP } from "./region-mapping";

export function* createMeeting(createMeetingRequest) {
    console.log("Calling createMeeting");
    return yield call(retryableRequesterEmailMakeRequest, [OPERATIONS.CREATE_MEETING, createMeetingRequest]);
}

export function* deleteMeeting(deleteMeetingRequest) {
    console.log("Calling deleteMeeting");
    return yield call(retryableRequesterEmailMakeRequest, [OPERATIONS.DELETE_MEETING, deleteMeetingRequest]);
}

export function* getBuildingWithQueryString(queryStringMap) {
    console.log("Calling getBuildingWithQueryString");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_BUILDING_WITH_QUERY_STRING, {
        queryStringMap
    }]);
}

export function* getConfRoomWithQueryString(queryStringMap) {
    console.log("Calling getConfRoomWithQueryString");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_CONF_ROOM_WITH_QUERY_STRING, {
        queryStringMap
    }]);
}

export function* getExchangePreferences(mailbox) {
    console.log("Calling getExchangePreferences");
    return yield call(retryableRequesterEmailMakeRequest, [OPERATIONS.GET_EXCHANGE_PREFERENCES, {mailbox}]);
}

export function* getGroup(name, expandLargeGroup = true) {
    console.log("Calling getGroup");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_GROUP, {name, expandLargeGroup}]);
}

export function* getMeeting(requestedOnBehalfOf, uniqueOrEntryID, master, propertyNames) {
    console.log("Calling getMeeting");
    return yield call(retryableRequesterEmailMakeRequest, [OPERATIONS.GET_MEETING, {
        requestedOnBehalfOf,
        uniqueOrEntryID,
        master,
        propertyNames
    }]);
}

export function* getPerson(username) {
    console.log("Calling getPerson");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_PERSON, {username}]);
}

export function* getSchedule(interval, listOfmailboxes, startTime, endTime) {
    console.log("Calling getSchedule");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_SCHEDULE, {
        interval,
        listOfmailboxes,
        searchRange: {startTime, endTime}
    }]);
}

export function* getSuggestions(getSuggestionsRequest) {
    console.log("Calling getSuggestions");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_SUGGESTION, getSuggestionsRequest]);
}

export function* getCachedResourceAvailability(suggestionRequest) {
    console.log("Calling getCachedResourceAvailability");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_CACHED_RESOURCE_AVAILABILITY, suggestionRequest]);
}

export function* getTimezones(language) {
    console.log("Calling getTimezones");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_TIMEZONES, {language}]);
}

export function* findGroups(queryString, start = 0, size = 10) {
    console.log("Calling findGroups");
    return yield call(retryableMakeRequest, [OPERATIONS.FIND_GROUPS, {
        queryString,
        start,
        size
    }]);
}

export function* findMeetings(requestedOnBehalfOf, startTime, endTime, maxResults) {
    console.log("Calling findMeetings");
    return yield call(retryableRequesterEmailMakeRequest, [OPERATIONS.FIND_MEETINGS, {
        requestedOnBehalfOf,
        startTime,
        endTime,
        maxResults
    }]);
}

export function* peopleAPISearchAll(query) {
    console.log("Calling peopleAPISearchAll");
    return yield call(retryableMakeRequest, [OPERATIONS.PEOPLE_API_SEARCH_ALL, {
        query,
        nextToken: "",
        size: 10,
        sortBy: "WEIGHT_THEN_FULL_NAME"
    }]);
}

export function* createChimePin(createChimePinRequest) {
    console.log("Calling createChimePin");
    return yield call(retryableMakeRequest, [OPERATIONS.CREATE_CHIME_PIN, createChimePinRequest]);
}

export function* getUserByEmail(getUserByEmailRequest) {
    console.log("Calling chime getUserByEmail");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_USER_BY_EMAIL, getUserByEmailRequest]);
}

export function* turnMeetingRemindersOff(query) {
    console.log("Calling turnMeetingRemindersOff");
    return yield call(retryableMakeRequest, [OPERATIONS.TURN_MEETING_REMINDERS_OFF, query]);
}

export function* updateMeeting(updateMeetingRequest) {
    console.log("Calling updatingMeeting");
    return yield call(retryableRequesterEmailMakeRequest, [OPERATIONS.UPDATE_MEETING, updateMeetingRequest]);
}

export function* reclaimResources(requestedOnBehalfOf, uniqueOrEntryID, resources) {
    console.log("Calling reclaimResources");
    return yield call(retryableMakeRequest, [OPERATIONS.RECLAIM_RESOURCES, { requestedOnBehalfOf, uniqueOrEntryID, resources }]);
}

function* retryableRequesterEmailMakeRequest(params) {
    let response = yield call(retryableMakeRequest, params);

    let parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse) &&
        (isBadRequestError(parsedResponse) || params[0] === OPERATIONS.GET_EXCHANGE_PREFERENCES)) {
        let { retry, retryParams, failedEmail, retryEmail } = updateRequesterEmailInParams(params);

        if (retry) {
            response = yield call(retryableMakeRequest, retryParams);
            parsedResponse = JSON.parse(response);
            yield put(postRequesterEmailRetryMetric(retryParams[0], failedEmail, retryEmail, !isErrorResponse(parsedResponse)));
        }
    }

    return response;
}

function* retryableMakeRequest(params) {
    let response;
    try {
        response = yield retry(MAX_ATTEMPTS, RETRY_DELAY_IN_MS, makeRequest, ...params);
    } catch (e) {
        console.error(`Failed all ${MAX_ATTEMPTS} attempts to call ${params[0]}: `, e);
        const error = stringifyError(e);
        yield put(postRASAPICallMetric(params[0], JSON.stringify({error, request: params[1]}), false));
        return getErrorResponse(e);
    }
    const parsedResponse = JSON.parse(response);
    if (!isErrorResponse(parsedResponse)) {
        console.log(`${params[0]} called successfully.`);
        yield put(postRASAPICallMetric(params[0]));
    }
    return response;
}

function* makeRequest(operation, body) {
    // TODO: define a way to store/retrieve the current stage and region
    const stageConfig = yield getStageConfig();
    const stage = stageConfig.stage;
    const { region, regionMappingError } = getRegion(stageConfig.edgeLocation);
    const endpoint = ENDPOINTS[stage][region] || ENDPOINTS[stage]["PDX"];
    const awsRegion = ENDPOINTS[stage][region] ? REGION_CODE_TO_AWS_STANDARD_MAP[region] : REGION_CODE_TO_AWS_STANDARD_MAP["PDX"];
    const operationPath = OPERATION_PATHS[operation];
    const { accessKeyId, secretAccessKey, sessionToken } = yield select(getSigningCredentials);

    if (regionMappingError) {
        yield put(postUnknownEdgeLocationMetric(regionMappingError.value));
    }

    const { idToken } = yield select(getIdentityTokens);

    const url = new URL(endpoint + operationPath);
    const opts = {
        method: "POST",
        host: url.hostname,
        path: url.pathname,
        service: "resource-adapter",
        headers: {
            "Content-Type": "application/json",
            "X-Amz-Cognito-Id-Token": idToken
        },
        region: awsRegion,
        body: JSON.stringify(cleanEmpty(body))
    };
    aws4.sign(opts, { accessKeyId, secretAccessKey, sessionToken });

    try {
        return yield call(fetchRequest, {url, opts});
    } catch (e) {
        if (isRetryableError(e)) {
            throw e;
        }
        const error = stringifyError(e);
        yield put(postRASAPICallMetric(operation, JSON.stringify({error, request: body}), false));
        return getErrorResponse(e);
    }
}
