import * as Actions from "../actions/action-types";
import { REMINDER_STATUS } from "../poll-constants";

const initialState = {
    meetingPollsLoaded: false,
    pollDetailsLoaded: false,
    processingPoll: false,
    pollDetails: undefined,
    pollList: [],
    createPollRequest: {},
    reminderResponse: {
        all: REMINDER_STATUS.DEFAULT,
    },
    meetingList: [],
    fromPage: "",
    timeSlotsSelectedFromGridMode: false,
    timeSlotsSelectedFromCalendarMode: false,
};

function pollReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_POLL_DETAILS:
            return {
                ...state,
                pollDetails: action.pollDetails,
                pollDetailsLoaded: true
            };
        case Actions.SET_POLL_DETAILS_LOADED:
            return {
                ...state,
                pollDetailsLoaded: action.isLoaded
            };
        case Actions.SET_POLL_LIST:
            return {
                ...state,
                pollList: action.pollList,
                meetingPollsLoaded: true
            };
        case Actions.SET_MEETING_POLLS_LOADED:
            return {
                ...state,
                meetingPollsLoaded: action.isLoaded
            };
        case Actions.CLEAR_POLL_DETAILS:
            return {
                ...state,
                pollDetails: undefined,
                pollDetailsLoaded: false
            };
        case Actions.CLEAR_CREATE_POLL_REQUEST:
            return {
                ...state,
                createPollRequest: {}
            };
        case Actions.SET_CREATE_POLL_REQUEST:
            return {
                ...state,
                createPollRequest: {
                    ...state.createPollRequest,
                    ...action.createPollRequest
                }
            };
        case Actions.SET_REMINDER_RESPONSE:
            return {
                ...state,
                reminderResponse: {
                    ...state.reminderResponse,
                    [action.email]: action.reminderResponse
                }
            };
        case Actions.SET_MEETING_LIST_FOR_AVAILABILITY:
            return {
                ...state,
                meetingList: action.meetingList
            };
        case Actions.SET_MEETING_POLL_FROM_PAGE:
            return {
                ...state,
                fromPage: action.fromPage
            };
        case Actions.SET_MEETING_POLL_TIME_SLOTS_SELECTED_FROM_GRID_MODE:
            return {
                ...state,
                timeSlotsSelectedFromGridMode: action.timeSlotsSelectedFromGridMode
            };
        case Actions.SET_MEETING_POLL_TIME_SLOTS_SELECTED_FROM_CALENDAR_MODE:
            return {
                ...state,
                timeSlotsSelectedFromCalendarMode: action.timeSlotsSelectedFromCalendarMode
            };
        case Actions.CLEAR_MEETING_POLL_TIME_SLOTS_SELECTED_MARKER:
            return {
                ...state,
                timeSlotsSelectedFromCalendarMode: false,
                timeSlotsSelectedFromGridMode: false
            };
        case Actions.CREATE_POLL:
        case Actions.UPDATE_POLL:
            return {
                ...state,
                processingPoll: true
            };
        case Actions.PROCESSING_POLL_COMPLETED:
            return {
                ...state,
                processingPoll: false
            };
        default:
            return state;
    }
}

export default pollReducer;
