import React, { useEffect, useState } from "react";

import DOMPurify from "dompurify";

import Alert from "@amzn/meridian/alert";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "../../shared/meridian-custom-components/src/components/select";
import Text from "@amzn/meridian/text";

import AlertsContainer from "../../shared/containers/alerts-container";
import RichTextEditor from "../../shared/components/rich-text-editor";

import "../meeting-ooto.css";
import PatternBackground from "../../../assets/backgrounds/pattern_small_vertical_repeating.svg";

import { INPUT_WIDTH, INPUT_HEIGHT, BACKGROUND_OPACITY } from "../ooto-scheduler-constants";
import { STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { ALERTS, SCREEN_SIZE } from "../../shared/shared-constants";
import { addAccessibilityPropertiesByPlaceholder } from "../../shared/accessibility-utils";
import { COMPONENT_LABELS } from "../../shared/accessibility-constants";
import { generateComponentId } from "../../shared/shared-utils";
import { updateQueryParameters } from "../../shared/permalink/permalink-utils";
import OOTOReviewCard from "./ooto-review-card";

const StepOOTOAgendaAndReview = (props) => {
    let screenSizeBreakpoint = props.screenSizeBreakpoint;
    let meeting = props.meeting;
    let meetingDetails = props.meetingDetails;
    const identity = props.identity;
    const onChangeStep = props.onChangeStep;
    const [queryParameters, setQueryParameters] = [props.queryParameters, props.setQueryParameters];
    const onUpdateMeeting = props.onUpdateMeeting;
    const [subject, setSubject] = useState(meeting.subject);
    const isSubjectEmpty = props.isSubjectEmpty;
    const MARK_MY_CALENDAR_AS_LABEL_ID = "mark-my-calendar-as-label-id";
    const updateSubject = () => onUpdateMeeting({subject: subject});
    const attendees = props.attendees;
    const reviewAlerts = props.reviewAlerts;
    const timeFormat = props.timeFormat;

    const updateMeetingBody = (value) => {
        onUpdateMeeting({
            body: {
                ...meeting.body,
                value: DOMPurify.sanitize(value).replaceAll("<p>", "<div>").replaceAll("</p>","</div>")
            }
        })
    };

    useEffect(() => {
        addAccessibilityPropertiesByPlaceholder("Mark my calendar as", "Mark my calendar as, selected " + meeting.status);
    }, [meeting.status]);

    // useEffect for generating query parameters for OOTO agenda
    useEffect(() => {
        const parameters = {
            subject: meeting.subject,
            status: meeting.status,
            isResponseRequested: meeting.isResponseRequested,
        };

        // Update query parameter if the value is different
        updateQueryParameters(parameters, queryParameters, setQueryParameters);
    }, [meeting.subject, meeting.status, meeting.isResponseRequested, queryParameters, setQueryParameters]);

    return (
        <Column spacing="none" height={["fit", "fit", "fill"]}>
            <Row alignmentHorizontal="center" alignmentVertical="top" backgroundColor="white">
                <Row width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND : null} spacingInset="small medium">
                    {reviewAlerts.length !== 0 &&
                        <AlertsContainer alerts={reviewAlerts} />
                    }
                </Row>
            </Row>
            <Row
                width="100%"
                wrap="down"
                className="ooto-agenda-top-container"
                backgroundColor={"white"}
                widths={["fill", "fit"]}
                alignmentVertical="top"
                alignmentHorizontal={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "center" : "start"}
            >
                <Column
                    heights={["fit", "fit", "fill"]}
                    spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? "small none" : screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "small none small medium" : "small medium"}
                    width="fill"
                    maxWidth={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? "450px" : null}
                >
                    <Column spacing="xsmall">
                        <Column spacing="none">
                            <Text type="b200">Subject</Text>
                            <Input
                                aria-label={COMPONENT_LABELS.SUBJECT}
                                aria-describedby={ALERTS.INVALID_MEETING_SUBJECT.id}
                                value={subject}
                                onChange={setSubject}
                                onBlur={updateSubject}
                                type="text"
                                error={isSubjectEmpty}
                                placeholder="Enter meeting subject"
                                width="100%"
                                size="small"
                                id={generateComponentId("input", "subject")}
                            />
                        </Column>
                        {isSubjectEmpty &&
                            <Alert type={ALERTS.INVALID_MEETING_SUBJECT.type} size="small">
                                <Text id={ALERTS.INVALID_MEETING_SUBJECT.id}>
                                    {ALERTS.INVALID_MEETING_SUBJECT.value}
                                </Text>
                            </Alert>
                        }
                    </Column>
                    <Column spacing="none">
                        <Text id={MARK_MY_CALENDAR_AS_LABEL_ID} type="b200">Mark my calendar as</Text>
                        <Row wrap="down">
                            <Select
                                value={meeting.status}
                                size="small"
                                onChange={(status) => onUpdateMeeting({status: status})}
                                placeholder="Set mark my calendar as"
                                width={INPUT_WIDTH.STATUS_WIDTH}
                                aria-labelledby={MARK_MY_CALENDAR_AS_LABEL_ID}
                                id={generateComponentId("select", "mark-my-calendar-as")}
                            >
                                <SelectOption value="free" label="Free" />
                                <SelectOption value="tentative" label="Tentative" />
                                <SelectOption value="busy" label="Busy" />
                                <SelectOption value="outOfOffice" label="Out Of The Office" />
                            </Select>
                            <Checkbox
                                checked={meeting.isResponseRequested}
                                onChange={() => onUpdateMeeting({isResponseRequested: !meeting.isResponseRequested})}
                                id={generateComponentId("checkbox", "request-responses")}
                            >
                                Request responses
                            </Checkbox>
                        </Row>
                    </Column>
                    {(props.messageSubjectTimeFormatChanged || props.messageBodyTimeFormatChanged) &&
                        <AlertsContainer id={generateComponentId("banner", "alert-container")} alerts={[props.timeInfoAlert]}/>
                    }
                </Column>
                <Column width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? null : "100%"} spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? "" : "none medium none none"}>
                    <OOTOReviewCard
                        identity={identity}
                        meetingDetailsSelector={meetingDetails}
                        onChangeStep={onChangeStep}
                        attendees={attendees}
                        screenSizeBreakpoint={screenSizeBreakpoint}
                        timeFormat={timeFormat}
                    />
                </Column>
            </Row>
            <Row widths={["fill", "fit", "fill"]} alignmentVertical="top" alignmentHorizontal="center">
                {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                    <div style={{
                        height: "100%",
                        backgroundImage: `url(${PatternBackground})`,
                        backgroundRepeat: "repeat-y",
                        backgroundPosition: "left -250px center",
                        opacity: BACKGROUND_OPACITY}}
                    />
                }
                <Column
                    spacingInset="medium"
                    width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND : null}
                >
                    <div style={{
                        paddingBottom: STEP_NAVIGATION_FOOTER.HEIGHT,
                    }}>
                        <RichTextEditor
                            textValue={meeting.body.value}
                            updateTextValue={updateMeetingBody}
                            height={INPUT_HEIGHT.TEXT_EDITOR_HEIGHT}
                            id={generateComponentId("rich-text-editor", "body")}
                        />
                    </div>
                </Column>
                {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                    <div style={{
                        height: "100%",
                        backgroundImage: `url(${PatternBackground})`,
                        backgroundRepeat: "repeat-y",
                        backgroundPosition: "right -250px center",
                        backgroundAttachment: "fixed",
                        opacity: BACKGROUND_OPACITY}}
                    />
                }
            </Row>
        </Column>
    );
};

export default StepOOTOAgendaAndReview;