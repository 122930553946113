import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import searchFieldTokens from "@amzn/meridian-tokens/component/search-field"
import find from "lodash/find"
import Focus from "../_focus"
import FocusTrap from "../_focus-trap"
import { Style } from "../theme"
import PopoverController from "../_popover-controller"
import OptionList, { OptionListItem } from "../_option-list"
import HighlightSubstring from "../highlight-substring"
import focusRingStyles from "../../_styles/focus-ring"
import textStyles from "../../_styles/text"
import { getTokenHelper } from "../../_utils/token"

const styles = (t, { anchorRect: { width }, focus }) =>
  css(
    focusRingStyles(t.tokens, {
      focus,
      offset: 1,
      borderRadius: t("borderRadius"),
      borderWidth: t("borderWidth"),
    }),
    {
      zIndex: t("popoverElevation"),
      background: t("backgroundColor"),
      border: t.borderCss("border"),
      borderRadius: t("borderRadius"),
      // HACK: we set overflow to hidden to make sure the
      // suggestions' boxy corners don't bleed outside the
      // popover's nice rounded corners. But we only do when
      // the popover is not in focus because when it IS in
      // focus we want to see that nice focus ring which appears
      // on the outside edge of the popover
      overflow: !focus ? "hidden" : undefined,
      outline: "none",
      width: width,
    }
  )

const optionListWrapperStyles = ({ maxHeight }) =>
  css({
    maxHeight,
    overflowX: "hidden",
    overflowY: "auto",
  })

const headerStyles = t =>
  css(textStyles(t), {
    padding: `${t("headerSpacingVertical")}px ${t(
      "headerSpacingHorizontal"
    )}px`,
    borderBottom: t.borderCss("headerBorder"),
    borderColor: t("headerBorderColor"),
    fontSize: t("headerFontSize"),
    lineHeight: t("headerLineHeight"),
    // HACK: IE11 does not support text-align "end" or "start" so we have
    // to use directional left/right. If/when we drop IE11 we can simplify
    // these styles to be:
    //   textAlign: "end"
    textAlign: "right",
    [`[dir="rtl"] &`]: {
      textAlign: "left",
    },
  })

const footerStyles = t =>
  css({
    borderTop: t.borderCss("footerBorder"),
    borderColor: t("footerBorderColor"),
    padding: `${t("footerSpacingVertical")}px ${t(
      "footerSpacingHorizontal"
    )}px`,
  })

class SearchSuggestionPopover extends React.Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    optionListRef: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    suggestions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        onClick: PropTypes.func.isRequired,
        label: PropTypes.string,
        render: PropTypes.func,
      })
    ).isRequired,
    /**
     * See FocusTrap for details on how this works.
     */
    onBeforeBlur: PropTypes.func.isRequired,
    onClickSuggestion: PropTypes.func.isRequired,
    anchorNode: PropTypes.object,
    footer: PropTypes.node,
    maxHeight: PropTypes.number,
    /**
     * A message to show in the popover when no suggestions are present.
     */
    message: PropTypes.node,
  }

  state = {
    selectedValue: undefined,
    defaultPreselectedIndex: undefined,
  }

  onFocusOptionList = () =>
    this.setState({ defaultPreselectedIndex: undefined })

  // Clear the option list's preselection on blur
  onBlurOptionList = () => {
    this.setState({ defaultPreselectedIndex: -1 })
    this.props.onChangePreselectedIndex(-1)
  }

  onClickSuggestion = suggestion => () => {
    this.setState({ selectedValue: suggestion.value })
    suggestion.onClick(suggestion.label)
    this.props.onClickSuggestion()
  }

  componentDidUpdate(prevProps) {
    const { suggestions, query } = this.props
    // If the query has been updated and it does not exists in suggestions,
    // clear the selected Value
    if (
      prevProps.query !== query &&
      !find(suggestions, suggestion => suggestion.label === query)
    ) {
      this.setState({ selectedValue: undefined })
    }
  }

  /**
   * Disable mouse interaction with any non-option children because a) it would
   * blur the select and cause it to close and b) we really don't want anyone
   * including interactive elements here because they'll be inaccessible to
   * keyboard users.
   *
   * NOTE: a `pointerEvents: none` style on the non-option children container is
   * not enough to prevent a blur event on the option list, we have to actually
   * prevent the event's default behavior.
   */
  onMouseDownNonOptionChildren = event => {
    event.preventDefault()
  }

  render() {
    const props = this.props
    const state = this.state
    return (
      <Style
        tokens={searchFieldTokens}
        map={getTokenHelper("searchFieldSuggestionList")}
      >
        {t => (
          <PopoverController
            open={props.open}
            anchorNode={props.anchorNode}
            anchorOrigin="bottom center"
            popoverOrigin="top center"
            offsetVertical={t("popoverOffsetVertical")}
          >
            {({ popoverStyle, anchorRect }) => (
              <Focus
                onFocus={this.onFocusOptionList}
                onBlur={this.onBlurOptionList}
                multipleTargets={true}
              >
                {({ focusProps, focus, focusTrigger }) => (
                  <FocusTrap onBeforeBlur={props.onBeforeBlur}>
                    <div
                      style={popoverStyle}
                      className={styles(t, {
                        anchorRect,
                        focus: focus && focusTrigger === "keyboard",
                      })}
                    >
                      <div
                        className={headerStyles(t)}
                        onMouseDown={this.onMouseDownNonOptionChildren}
                      >
                        {props.header}
                      </div>
                      {props.suggestions.length ? (
                        <div className={optionListWrapperStyles(props)}>
                          <OptionList
                            {...focusProps}
                            onChangePreselectedIndex={props.onChangePreselectedIndex}
                            ref={props.optionListRef}
                            popupId={props.popupId}
                            defaultPreselectedIndex={
                              state.defaultPreselectedIndex
                            }
                          >
                            {props.suggestions.map(suggestion => (
                              <OptionListItem
                                key={suggestion.value}
                                label={suggestion.label}
                                onClick={this.onClickSuggestion(suggestion)}
                                disabled={suggestion.disabled}
                                selected={
                                  suggestion.value === state.selectedValue
                                }
                              >
                                {({ preselected }) =>
                                  suggestion.render ? (
                                    suggestion.render({
                                      query: props.query,
                                      highlighted:
                                        preselected ||
                                        suggestion.value ===
                                          state.selectedValue,
                                    })
                                  ) : (
                                    <HighlightSubstring match={props.query}>
                                      {suggestion.label}
                                    </HighlightSubstring>
                                  )
                                }
                              </OptionListItem>
                            ))}
                          </OptionList>
                        </div>
                      ) : props.message.length ? (
                        <div onMouseDown={this.onMouseDownNonOptionChildren}>
                          {props.message}
                        </div>
                      ) : null}
                      {props.footer && (
                        <div className={footerStyles(t)}>{props.footer}</div>
                      )}
                    </div>
                  </FocusTrap>
                )}
              </Focus>
            )}
          </PopoverController>
        )}
      </Style>
    )
  }
}

export default SearchSuggestionPopover
