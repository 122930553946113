import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import Popover from "@amzn/meridian/popover";

import "../styles/landing.css";

import { ReactComponent as FindARoomIcon } from "../../../assets/icons/shared/find_a_room.svg";
import { ReactComponent as LivestreamIcon } from "../../../assets/icons/shared/livestream.svg";
import { ReactComponent as OOTOIcon } from "../../../assets/icons/shared/out_of_the_office.svg";
import { ReactComponent as SimpleMeetingIcon } from "../../../assets/icons/shared/simple_meeting.svg";
import { ReactComponent as StandardMeetingIcon } from "../../../assets/icons/shared/standard_meeting.svg";
import { ReactComponent as ShareAvailabilityIcon } from "../../../assets/icons/shared/share_availability.svg";
import { ReactComponent as MeetingPollIcon } from "../../../assets/icons/polls/meeting_poll.svg";

import { KEYCODE } from "../../shared/shared-constants";
import { generateComponentId } from "../../shared/shared-utils";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { changePage } from "../../shared/workflow/workflow-utils";

const CreateMeetingMenu = (props) => {
    const dispatch = useDispatch();

    const currentWorkflow = props.currentWorkflow;
    const createButtonRef = props.createButtonRef;
    const [createMenuOpen, setCreateMenuOpen] = [props.createMenuOpen, props.setCreateMenuOpen];

    const onCloseCreateMenu = useCallback(() => setCreateMenuOpen(false), [setCreateMenuOpen]);
    const firstCreateOptionRef = useRef();
    const lastCreateOptionRef = useRef();

    const handleChangePage = (event, newWorkflow) => {
        if (event.ctrlKey || event.metaKey) {
            return;
        }
        changePage(dispatch, currentWorkflow, newWorkflow);
    };

    const onKeyDownFirstCreateOption = (event) => {
        const keyCode = event.keyCode || event.which;
        switch (keyCode) {
            case KEYCODE.TAB:
                if (event.shiftKey) {
                    event.preventDefault();
                    lastCreateOptionRef.current.focus();
                }
                break;
            default:
                break;
        }
    };

    const onKeyDownLastCreateOption = (event) => {
        const keyCode = event.keyCode || event.which;
        switch (keyCode) {
            case KEYCODE.TAB:
                if (!event.shiftKey) {
                    event.preventDefault();
                    firstCreateOptionRef.current.focus();
                }
                break;
            default:
                break;
        }
    };

    return (
        <Popover
            anchorNode={createButtonRef.current}
            open={createMenuOpen}
            onClose={onCloseCreateMenu}
            position="bottom"
            alignment="left"
        >
            <a
                className="createOptionLink"
                style={{marginBottom: "12px"}}
                href={WORKFLOWS.STANDARD_MEETING.HREF}
                onClick={(event) => {handleChangePage(event, WORKFLOWS.STANDARD_MEETING)}}
                onKeyDown={onKeyDownFirstCreateOption}
                ref={firstCreateOptionRef}
                id={generateComponentId("link", "standard-meeting")}
            >
                <div className="createOptionIcon">
                    <StandardMeetingIcon height="20px" width="20px" />
                </div>
                Standard meeting
            </a>
            <button
                className="createOptionButton"
                style={{marginBottom: "12px"}}
                aria-label="Launch the simple meeting creation modal"
                onClick={() => {props.setQuickModalOpen(true); setCreateMenuOpen(false);}}
                id={generateComponentId("button", "simple-meeting")}
            >
                <div className="createOptionIcon">
                    <SimpleMeetingIcon height="20px" width="20px" />
                </div>
                Simple meeting
            </button>
            <a
                className="createOptionLink"
                style={{marginBottom: "12px"}}
                href={WORKFLOWS.ROOM_BOOKING.HREF}
                onClick={(event) => {handleChangePage(event, WORKFLOWS.ROOM_BOOKING)}}
                id={generateComponentId("link", "room-booking")}
            >
                <div className="createOptionIcon">
                    <FindARoomIcon height="20px" width="20px" />
                </div>
                Room booking
            </a>
            <a
                className="createOptionLink"
                style={{marginBottom: "12px"}}
                href={WORKFLOWS.OOTO.HREF}
                onClick={(event) => {handleChangePage(event, WORKFLOWS.OOTO)}}
                id={generateComponentId("link", "ooto")}
            >
                <div className="createOptionIcon">
                    <OOTOIcon height="20px" width="20px" />
                </div>
                OOTO invite
            </a>
            <a
                className="createOptionLink"
                style={{marginBottom: "12px"}}
                href={WORKFLOWS.MAPS.HREF}
                onClick={(event) => {handleChangePage(event, WORKFLOWS.MAPS)}}
                id={generateComponentId("link", "maps")}
            >
                <div className="createOptionIcon">
                    <ShareAvailabilityIcon height="20px" width="20px" />
                </div>
                Availability sharing
            </a>
            <a
                className="createOptionLink"
                style={{marginBottom: "12px"}}
                href={WORKFLOWS.POLL_CREATE.HREF}
                onClick={(event) => {handleChangePage(event, WORKFLOWS.POLL_CREATE)}}
                id={generateComponentId("link", "create-poll")}
            >
                <div className="createOptionIcon">
                    <MeetingPollIcon height="20px" width="20px" />
                </div>
                Meeting poll
            </a>
            <a
                className="createOptionLink"
                href={WORKFLOWS.LIVESTREAM.HREF}
                onClick={(event) => {handleChangePage(event, WORKFLOWS.LIVESTREAM)}}
                onKeyDown={onKeyDownLastCreateOption}
                ref={lastCreateOptionRef}
                id={generateComponentId("link", "livestream")}
            >
                <div className="createOptionIcon">
                    <LivestreamIcon height="20px" width="20px" />
                </div>
                Livestream request
            </a>
        </Popover>
    );
};

export default CreateMeetingMenu;
