import React from "react"
import PropTypes from "prop-types";

/**
 * Pass this as a child to the `Select` component to render it as an option of
 * the select.
 */
class SelectOption extends React.Component {
  static propTypes = {
    /**
     * The option's label.
     *
     * This is shown in the `Select` component's field when the option is
     * selected, and as the text for the option if no `children` prop is provided.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /**
     * The value of the option. This will be passed to the `Select` component's
     * `onChange` handler when this option is selected.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /**
     * Use this to render custom (non-plain-text) children in the option.
     *
     * This should be a function that returns the elements to render. It will
     * receive as its only argument an object containing the following keys:
     *   - `preselected` - a boolean that will be true if the user has
     *     focused on the option with their mouse or keyboard
     *   - `selected` - a boolean that will be true if the user has selected the
     *     option.
     * This data can be used to tailor the display of the custom option children
     * based on the state of the option.
     */
    children: PropTypes.func,
    /**
     * All props prefaced with `data-` are accepted. This is useful for
     * integrating with other libraries such as Amplify Analytics or Cypress.
     *
     * @since 5.x
     */
    "data-*": PropTypes.string,
    /**
     * This disables interaction with the component and applies special visual
     * styles to indicate that it's not interactive.
     */
    disabled: PropTypes.bool,
  }

  render() {
    // The top-level OptionListItem component is used to render this. See
    // ./select-option-list for the implementation.
    return null
  }
}

export default SelectOption
