import * as Actions from "./action-types";

export const loadBuildings = (queryStringMap) => {
    return {type: Actions.LOAD_BUILDINGS, queryStringMap: queryStringMap || {}}
};

export const saveBuildings = (buildingList, buildingListLoaded) => {
    return {type: Actions.SAVE_BUILDINGS, buildingList, buildingListLoaded}
};

export const loadFloors = (buildingCode) => {
    return {type: Actions.LOAD_FLOORS, queryStringMap: {buildingCode}}
};

export const setLoadFloorsStatus = (isLoaded) => {
    return {type: Actions.SET_LOAD_FLOORS_STATUS, isLoaded}
};

export const saveFloors = (floorList, roomList, buildingCodeToUpdate) => {
    return {type: Actions.SAVE_FLOORS, floorList, roomList, buildingCodeToUpdate}
};

export const updateFindRooms = (findRooms) => {
    return {type: Actions.UPDATE_FIND_ROOMS, findRooms};
};

export const addRoom = (room) => {
    return {type: Actions.ADD_ROOM, room};
};

export const removeRoom = (room) => {
    return {type: Actions.REMOVE_ROOM, room};
};

export const addLocation = (location) => {
    return {type: Actions.ADD_LOCATION, location};
};

export const removeLocation = (location) => {
    return {type: Actions.REMOVE_LOCATION, location};
};

export const addRoomByEmail = (room) => {
    return {type: Actions.ADD_ROOM_BY_EMAIL, room};
};

export const clearLocationAndRooms = () => {
    return {type: Actions.CLEAR_LOCATIONS_AND_ROOMS};
};