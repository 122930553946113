import React from "react";

import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import ControlGroup from "@amzn/meridian/control-group";
import DatePicker from "../../shared/meridian-custom-components/src/components/date-picker";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "../../shared/meridian-custom-components/src/components/select";
import Text from "@amzn/meridian/text";
import filterTokens from "@amzn/meridian-tokens/base/icon/filter";

import { getMeetingResponseIcon, getMeetingStatusIcon } from "../../shared/meeting-status-utils";
import { SINGLE_DAY_VIEW_THRESHOLD } from "../calendar-constants";
import { getLocalesFromSettingsDateFormat } from "../../shared/settings/settings-utils";

const CalendarOptions = (props) => {
    const calendarStatusFilter = props.calendarStatusFilter;
    const calendarResponseFilter = props.calendarResponseFilter;

    const CURRENT_VIEW_LABEL_ID = "current-view-label-id";
    const CALENDAR_OPTIONS_DATE_PICKER_INSTRUCTION_ID = "calendar-options-date-picker-instruction-id";

    const invalidInputMessage = (code) => ({
        INVALID_DATE: "This value is not a valid date.",
        DISABLED_DATE: "This date is not available.",
    }[code]);
    const datePickerLabel = props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || props.viewType === "day" ? "Date" : "Week of";

    const renderFilterOption = (type, value, label, id, ariaLabel) => {
        return (
            <Checkbox value={value}>
                <Row alignmentVertical="center" spacing="none" width="226px" widths={["fit", "fill", "fit"]}>
                    <label htmlFor={id} aria-label={ariaLabel} style={{pointerEvents: "none"}}>
                        <Text tag="span" id={id}>{label}</Text>
                    </label>
                    <span />
                    {type === "responseFilter" && getMeetingResponseIcon(value)}
                    {type === "statusFilter" && getMeetingStatusIcon(value)}
                </Row>
            </Checkbox>
        );
    };

    return (
        <div id="calendar-settings" role="complementary" aria-label="Calendar settings" style={{height: "100%"}}>
            <Column height="100%">
                <div style={{display: "none"}}>
                    <Text id={CURRENT_VIEW_LABEL_ID}>Current view</Text>
                    <Text id={CALENDAR_OPTIONS_DATE_PICKER_INSTRUCTION_ID}>
                        {`Type ${datePickerLabel} in MM/DD/YYYY format or press down to enter calendar dialog to select the ${datePickerLabel}`}
                    </Text>
                </div>
                <Select
                    size="xlarge"
                    value={props.viewType}
                    onChange={props.onSetCalendarViewType}
                    placeholder="Enter value..."
                    label="Current view"
                    aria-labelledby={CURRENT_VIEW_LABEL_ID}
                >
                    <SelectOption value="day" label="Day" />
                    <SelectOption value="workweek" label="Work week" />
                    <SelectOption value="week" label="Week" />
                    <SelectOption value="agenda" label="Agenda" />
                </Select>
                <DatePicker
                    value={props.date}
                    onChange={props.onSetCalendarDate}
                    label={datePickerLabel}
                    locale={getLocalesFromSettingsDateFormat(props.dateFormat)}
                    invalidInputMessage={invalidInputMessage}
                    aria-describedby={CALENDAR_OPTIONS_DATE_PICKER_INSTRUCTION_ID}
                    aria-label={datePickerLabel}
                />

                <Column spacing="small" width="100%">
                    <Row spacing="none" spacingInset="none" widths={["fit", "fill", "fit"]}>
                        <Text type="h200">Filter by</Text>
                        <span />
                        <Icon tokens={filterTokens} />
                    </Row>
                    <ControlGroup
                        label="Response"
                        name="calendarResponseFilter[]"
                        onChange={props.onSetCalendarResponseFilter}
                        value={calendarResponseFilter}
                    >
                        {renderFilterOption("responseFilter", "accept", "Attending", "responseFilter-attending", "response filter attending")}
                        {renderFilterOption("responseFilter", "tentative", "Tentative", "responseFilter-tentative", "response filter tentative")}
                        {renderFilterOption("responseFilter", "notResponded", "Pending response", "responseFilter-notResponded", "response filter pending response")}
                    </ControlGroup>
                    <hr width="100%" />

                    <ControlGroup
                        label="Status"
                        name="calendarStatusFilter[]"
                        onChange={props.onSetCalendarStatusFilter}
                        value={calendarStatusFilter}
                    >
                        {renderFilterOption("statusFilter", "busy", "Busy", "statusFilter-busy", "status filter busy")}
                        {renderFilterOption("statusFilter", "tentative", "Tentative", "statusFilter-tentative", "status filter tentative")}
                        {renderFilterOption("statusFilter", "free", "Free", "statusFilter-free", "status filter free")}
                        {renderFilterOption("statusFilter", "outOfOffice", "Out Of The Office", "statusFilter-ooto", "status filter out of the office")}
                        {renderFilterOption("statusFilter", "canceled", "Canceled", "statusFilter-canceled", "status filter canceled")}
                    </ControlGroup>
                </Column>
            </Column>
        </div>
    );
};

export default CalendarOptions;
