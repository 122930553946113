export const livestreamLinkInfoTemplate = `
<b>For Organizers:</b>
<ul>
<li>If you need to change the date, time, location, or any other details for your livestream support,
please go to your <a href="{{TICKET_HREF}}">SIM ticket</a>.</li>
<li><b>Livestream URL:</b> <a href={{LIVESTREAM_HREF}}>{{LIVESTREAM_HREF}}</a><br/></li>
</ul>
<p> For any issues viewing the stream, use the <a href={{REPORT_HREF}}>Report a bug</a> feature
on top right corner of your livestreaming webpage or go the
<a href={{SUPPORT_HREF}}>Support page.</a></p>
`;