import * as Actions from "./action-types";

export const findMeetings = (requestedOnBehalfOf, startTime, endTime, maxResults) => {
    return {type: Actions.FIND_MEETINGS, requestedOnBehalfOf, startTime, endTime, maxResults};
};

export const setMeetingList = (meetingList) => {
    return {type: Actions.SET_MEETINGS_LIST, meetingList};
};

export const setMeetingResourcesDictionary = (meetingResourcesDictionary = new Map()) => {
    return {type: Actions.SET_MEETING_RESOURCES_DICTIONARY, meetingResourcesDictionary};
};

export const getMeeting = (requestedOnBehalfOf, uniqueOrEntryID, master, propertyNames) => {
    return {type: Actions.GET_MEETING, requestedOnBehalfOf, uniqueOrEntryID, master, propertyNames};
};

export const deleteMeeting = (deleteMeetingRequest, redirectTo, setMeetingListAction, event) => {
    return {type: Actions.DELETE_MEETING, deleteMeetingRequest, redirectTo, setMeetingListAction, event};
};

export const getMeetingDetailsAndSave = (requestedOnBehalfOf, uniqueOrEntryID, master, propertyNames, targetMailbox, saveAction) => {
    return {type: Actions.GET_MEETING_AND_SAVE, requestedOnBehalfOf, uniqueOrEntryID, master, propertyNames, targetMailbox, saveAction};
};

export const getMeetingDetailsListAndSave = (meetings) => {
    return {type: Actions.GET_MEETING_DETAILS_LIST_AND_SAVE, meetings};
};

export const getMeetingRecurrencePatternAndSave = (entryID, mailbox) => {
    return {type: Actions.GET_MEETING_RECURRENCE_PATTERN_AND_SAVE, entryID, mailbox};
};

export const setMeetingRecurrencePattern = (recurrence) => {
    return {type: Actions.SET_MEETING_RECURRENCE_PATTERN, recurrence};
};

export const setAwarenessMeetingUniqueId = (awarenessMeetingUniqueId) => {
    return {type: Actions.SET_AWARENESS_MEETING_UNIQUE_ID, awarenessMeetingUniqueId};
}

export const getGroupDetails = (groupName, expandLargeGroup) => {
    return {type: Actions.GET_GROUP, groupName, expandLargeGroup};
};

export const setGroupDetails = (groupName, group) => {
    return {type: Actions.SET_GROUP_DETAILS, groupName, group};
};

export const batchGetGroupDetails = (groupList) => {
    return {type: Actions.BATCH_GET_GROUP, groupList, expandLargeGroup: false};
};

export const batchSetGroupDetails = (batchGroupDetails) => {
    return {type: Actions.BATCH_SET_GROUP_DETAILS, batchGroupDetails};
};

export const getSuggestions = (getSuggestionsRequest) => {
    return {type: Actions.GET_SUGGESTIONS, getSuggestionsRequest};
};

export const saveSuggestions = (suggestions) => {
    return {type: Actions.SAVE_SUGGESTIONS, suggestions};
};

export const clearSuggestions = () => {
    return {type: Actions.CLEAR_SUGGESTIONS};
};

export const setSuggestionsState = (isSearching) => {
    return {type: Actions.SET_SUGGESTIONS_STATE, isSearching};
};

export const createMeeting = (createMeetingRequest, redirectTo, page, permalink) => {
    return {type: Actions.CREATE_MEETING, createMeetingRequest, redirectTo, page, permalink};
};

export const getCachedResourceAvailability = (cachedAvailabilityRequest) => {
    return {type: Actions.GET_CACHED_RESOURCE_AVAILABILITY, cachedAvailabilityRequest};
};

export const setUnauthorizedUser = () => {
    return {type: Actions.SET_UNAUTHORIZED_USER};
};

export const updateMeeting = (updateMeetingRequest, redirectTo, setMeetingListAction) => {
    return {type: Actions.UPDATE_MEETING, updateMeetingRequest, redirectTo, setMeetingListAction};
};

export const refreshAgenda = () => {
    return {type: Actions.REFRESH_AGENDA};
};

export const setMeetingAgendaLoaded = (isLoaded) => {
    return {type: Actions.SET_MEETING_AGENDA_LOADED, isLoaded};
};

export const getMeetingAgenda = (requestedOnBehalfOf, startTime, endTime, maxResults) => {
    return {type: Actions.GET_MEETING_AGENDA, requestedOnBehalfOf, startTime, endTime, maxResults};
};

export const incrementCount = () => {
    return {type: Actions.INCREMENT_COUNT};
};

export const getMeetingsRoomBooking = (requestedOnBehalfOf, startTime, endTime, maxResults) => {
    return {type: Actions.GET_MEETINGS_ROOM_BOOKING, requestedOnBehalfOf, startTime, endTime, maxResults};
};

export const setMeetingsRoomBookingLoaded = (isLoaded) => {
    return {type: Actions.SET_MEETINGS_ROOM_BOOKING_LOADED, isLoaded};
};

export const addMeetingRoomBooking = (meeting) => {
    return {type: Actions.ADD_MEETING_ROOM_BOOKING, meeting};
};

export const getExistingMeetings = (requestedOnBehalfOf, meetings) => {
    return {type: Actions.GET_EXISTING_MEETINGS, requestedOnBehalfOf, meetings};
};

export const setExistingMeetingsLoaded = (isLoaded) => {
    return {type: Actions.SET_EXISTING_MEETINGS_LOADED, isLoaded};
};