/**
 * This script setup the AWSEventsTracker for the app, learn more: https://code.amazon.com/packages/AWSEventsTracker.
 */
const SERVICE_NAME = "AMAZON_MEETING_WEBSITE";

let panoramaInitialized = false;

/**
 * Initialize the variables needed and load the Snowplow script.
 *
 * @param object p The window
 * @param object l The document
 * @param string o "script", the tag name of script elements
 * @param string w The source of the Snowplow script. Make sure you get the latest version.
 * @param string i The Snowplow namespace. The Snowplow user should set this.
 * @param undefined n The new script (to be created inside the function)
 * @param undefined g The first script on the page (to be found inside the function)
 */
function loadSnowplowFunction(p,l,o,w,i,n,g) {
    // Stop if the Snowplow namespace i already exists
    if (!p[i]) {
        // Initialise the "GlobalSnowplowNamespace" array
        p["GlobalSnowplowNamespace"] = p["GlobalSnowplowNamespace"] || [];

        // Add the new Snowplow namespace to the global array so sp.js can find it
        p["GlobalSnowplowNamespace"].push(i);

        // Create the Snowplow function
        p[i] = (...args) => {
            (p[i].q = p[i].q || []).push(args);
        };

        // Initialise the asynchronous queue
        p[i].q = p[i].q || [];

        // Add track custom event function
        p[i].trackCustomEvent = (...args) => {
            [].unshift.call(args, "trackCustomEvent");
            p[i].apply(this, args)
        };

        // Create a new script element
        n = l.createElement(o);

        // Get the first script on the page
        g = l.getElementsByTagName(o)[0];

        // The new script should load asynchronously
        n.async = 1;

        // Load Snowplow
        n.src = w;

        // Insert the Snowplow script before every other script so it executes as soon as possible
        g.parentNode.insertBefore(n,g)
    }
};

/**
 * Initialize the Javascript tracker
 *
 * @param metricEndpoint The endpoint we want to post the panorama logs to
 */
function panoramaTrackerInit(metricEndpoint) {
    // For tracker configs, refer to ArgMap under https://code.amazon.com/packages/AWSEventsTracker/blobs/mainline/--/src/lib/tracker/interfaces.ts
    console.log("Initializing Panorama for metric tracking");
    window.panorama("newTracker", "cf", metricEndpoint, {
        appId: "AmazonMeetingsWebsite",
        configCollectorUrlPath: "/panoramalog",
        console: false,
        pluginsEnabledByDefault: true,
        service: SERVICE_NAME,
    });
};

/**
 * Initialize Panorama
 */
export function panoramaInit(metricsEndpoint) {
    if (!panoramaInitialized) {
        loadSnowplowFunction(window, document, "script", process.env.PUBLIC_URL + "/panorama/snowplow.js", "panorama");
        panoramaTrackerInit(metricsEndpoint);
        panoramaInitialized = true;
    }
};

/**
 * Track a metric that is not tracked by AWSEventsTracker out of the box, learn more: https://w.amazon.com/bin/view/Augurey/Projects/ConsoleAnalyticsV2/Tracker/CustomEvents/
 *
 * @param eventType: The type of metric. ex: 'Feedback' or 'RAS call',
 * @param eventName: The name of the metric. ex: 'User rating' or 'getSuggestion',
 * @param eventContext: The alias of the user. ex: 'camei' or 'amiyyapu'
 * @param eventDetail: Any other details.
 * @param eventValue: The numeric value of the metric.
 * @param idToken: The user's idToken to set the aws-userinfo header.
 */
export function postCustomMetric(eventType, eventName, eventContext, eventDetail, eventValue, idToken) {
    if (panoramaInitialized) {
        // set the aws-userinfo request header
        window.panorama.q.trackerDictionary && window.panorama.q.trackerDictionary.cf.core.setAWSUserInfo(idToken);

        // post the custom metric
        window.panorama("trackCustomEvent", {
            eventType,
            eventName,
            eventContext,
            eventDetail,
            eventValue,
            timestamp: new Date().getTime(),
        });
    }
};

