import * as Actions from "../actions/action-types";

const initialState = {
    meetingAgendaLoaded: false,
    meetingList: undefined,
    meetingDetails: {},
    meetingResourcesDictionary: undefined,
    meetingRecurrence: undefined,
    groupDetails: {},
    awarenessMeetingUniqueId: '',
    refreshCount: 0,
    meetingListRoomBooking: undefined,
    meetingListRoomBookingLoaded: false,
    existingMeetings: [],
    existingMeetingsLoaded: false
};

function agendaReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_MEETINGS_LIST:
            return {
                ...state,
                meetingList: action.meetingList,
            };
        case Actions.SET_MEETING_DETAILS:
            return {
                ...state,
                meetingDetails: {
                    ...action.meeting
                },
                meetingRecurrence: undefined,
            };
        case Actions.SET_MEETING_RECURRENCE_PATTERN:
            return {
                ...state,
                meetingRecurrence: {
                    ...action.recurrence
                }
            };
        case Actions.SET_AWARENESS_MEETING_UNIQUE_ID:
            return {
                ...state,
                awarenessMeetingUniqueId: action.awarenessMeetingUniqueId
            }
        case Actions.SET_GROUP_DETAILS:
            return {
                ...state,
                groupDetails: {
                    ...state.groupDetails,
                    [action.groupName]: action.group
                }
            };
        case Actions.BATCH_SET_GROUP_DETAILS:
            return {
                ...state,
                groupDetails: {
                    ...state.groupDetails,
                    ...action.batchGroupDetails
                }
            };
        case Actions.SET_UNAUTHORIZED_USER:
            return {
                ...state,
                unauthorizedUser: true
            };
        case Actions.SET_MEETING_RESOURCES_DICTIONARY:
            return {
                ...state,
                meetingResourcesDictionary: action.meetingResourcesDictionary,
            };
        case Actions.SET_MEETING_AGENDA_LOADED:
            return {
                ...state,
                meetingAgendaLoaded: action.isLoaded
            };
        case Actions.REFRESH_AGENDA:
            return {
                ...state,
                meetingList: undefined,
                meetingResourcesDictionary: undefined
            };
        case Actions.INCREMENT_COUNT:
            return {
                ...state, 
                refreshCount: state.refreshCount + 1,
            };
        case Actions.SET_MEETINGS_ROOM_BOOKING:
            return {
                ...state,
                meetingListRoomBooking: action.meetingList,
            };
        case Actions.SET_MEETINGS_ROOM_BOOKING_LOADED:
            return {
                ...state,
                meetingListRoomBookingLoaded: action.isLoaded,
            }
        case Actions.ADD_MEETING_ROOM_BOOKING:
            return {
                ...state,
                meetingListRoomBooking: [...state.meetingListRoomBooking, action.meeting],
            }
        case Actions.SET_EXISTING_MEETINGS:
            return {
                ...state,
                existingMeetings: action.existingMeetings,
            }
        case Actions.SET_EXISTING_MEETINGS_LOADED:
            return {
                ...state,
                existingMeetingsLoaded: action.isLoaded,
            }
        default:
            return state;
    }
}

export default agendaReducer;
