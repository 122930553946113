import padStart from "lodash/padStart"

/**
 * This module contains utilities that work with time strings in the format
 * "HH:mm"
 */

/**
 * Convert a date object into a date + time string (e.g. "2018-12-31 03:20").
 */
const toDateTimeString = dateObject =>
  `${padStart(dateObject.getFullYear(), 4, "0")}-${padStart(
    dateObject.getMonth() + 1,
    2,
    "0"
  )}-${padStart(dateObject.getDate(), 2, "0")} ${padStart(
    dateObject.getHours(),
    2,
    "0"
  )}:${padStart(dateObject.getMinutes(), 2, "0")}`

/**
 * Returns hour, minutes, and seconds as numbers time string
 * parseTimeString("02:30") -> { hour: 2, minute: 30, seconds: 0 }
 * parseTimeString("02:30:55") -> { hour: 2, minute: 30, seconds: 55 }
 */
const parseTimeString = timeString => {
  const [
    ,
    hourString,
    minutesString,
    secondsString = "00",
  ] = /([\d]+):([\d]+):?([\d]+)?/.exec(timeString)
  // We use Number over parseInt here because parseInt will assume
  // an 🤖 octal number if there's a leading zero (which will be the
  // case for time strings like "02:00")
  // https://stackoverflow.com/a/4090577
  return {
    hour: Number(hourString),
    minutes: Number(minutesString),
    seconds: Number(secondsString),
  }
}

/*
 * Create a date object at a specific time
 */
const createDateAtTime = timeString => {
  const { hour, minutes, seconds } = parseTimeString(timeString)
  const date = new Date()
  date.setHours(hour)
  date.setMinutes(minutes)
  date.setSeconds(seconds)
  return date
}

/*
 * Adds hours to a time string by an increment. Returns time string
 * addHours("12:33", -3) // returns "09:33"
 */
const addHours = (timeString, increment) => {
  const { hour, minutes } = parseTimeString(timeString)
  return `${padStart(hour + increment, 2, "0")}:${padStart(minutes, 2, "0")}`
}

/**
 * Returns localized time label from a time string.
 * getTimeLabel({ time: "14:00", locale: "zh-CN" }) // returns 下午02:00
 */
const getTimeLabel = ({
  time,
  locale,
  format = { hour: "2-digit", minute: "2-digit" },
}) => createDateAtTime(time).toLocaleTimeString(locale, format)

export {
  parseTimeString,
  toDateTimeString,
  getTimeLabel,
  addHours,
  createDateAtTime,
}
