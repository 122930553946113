import React from "react";
import { useDispatch } from "react-redux";

import MeetingsRating from "./meetings-rating";
import { postRatingMetric } from "../../metrics/actions";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Link from "@amzn/meridian/link";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Textarea from "@amzn/meridian/textarea";

import thankYouEmojiSvg from "../../../../assets/icons/shared/thank_you_emoji.svg";

const MeetingsFeedback = (props) => {
    const userFeedback = props.userFeedback;
    const userRating = props.userRating;
    const onUpdateUserFeedback = props.onUpdateUserFeedback;
    const onUpdateUserRating = props.onUpdateUserRating;
    const [tellUsWhy, setTellUsWhy] = [props.tellUsWhy, props.setTellUsWhy];
    const [submitted, setSubmitted] = [props.submitted, props.setSubmitted];
    const isModal = props.isModal;
    const onClearFeedback = props.onClearFeedback;
    const closeButtonRef = props.closeButtonRef;
    const onClose = props.onClose;

    const dispatch = useDispatch();

    const onSubmit = () => {
        dispatch(postRatingMetric(userFeedback, userRating, true, true));
        setSubmitted(true);
        onClearFeedback();
        closeButtonRef.current.focus();
    };

    return (
        <Column width="100%" spacingInset="none">
            {!tellUsWhy && !submitted &&
                <Column spacing="small" alignmentHorizontal="center">
                    <Row alignmentVertical="bottom">
                        <MeetingsRating userRating={userRating} onUpdateUserRating={onUpdateUserRating}
                                        closeButtonRef={closeButtonRef} isModal={isModal}/>
                    </Row>
                    <Divider/>
                    <Row spacingInset="small" width="100%" widths={["fill", "fit"]}>
                        <Link type="primary" onClick={() => setTellUsWhy(true)} disabled={!userRating}>
                            Tell us why?
                        </Link>
                        {isModal &&
                            <Button type="secondary" onClick={onClose}>
                                Skip
                            </Button>
                        }
                        <Button onClick={() => onSubmit()} disabled={!userRating}>
                            Submit
                        </Button>
                    </Row>
                </Column>
            }
            {tellUsWhy && !submitted &&
                <Column spacing="small">
                    <Row spacingInset="small" width="100%" widths="fill">
                        <div className="amw-textarea">
                            <Textarea
                                id={props.id}
                                value={userFeedback}
                                onChange={onUpdateUserFeedback}
                                placeholder="Enter comment"
                                rows={3}
                                resize="none"
                            />
                        </div>
                    </Row>
                    <Divider/>
                    <Row spacingInset="small" widths={["fill", "fit"]}>
                        <Row>
                            <Button type="tertiary" onClick={() => setTellUsWhy(false)}>
                                Back
                            </Button>
                        </Row>
                        <Button onClick={() => onSubmit()}>
                            Submit
                        </Button>
                    </Row>
                </Column>
            }
            {submitted &&
                <Column spacingInset="none medium medium medium" alignmentHorizontal="center">
                    <img src={thankYouEmojiSvg} alt="thank you emoji icon"/>
                    <Column spacing="small">
                        <Text type="h100" alignment="center">
                            Thank you for your feedback!
                        </Text>
                        <Text type="h100" alignment="center">
                            We really appreciate it.
                        </Text>
                    </Column>
                </Column>
            }
        </Column>
    );
};

export default MeetingsFeedback;