import React from "react";

import {
    ATTENDEE_TYPE,
    ATTENDEE_PRIORITY,
    ATTENDEE_RESPONSE,
    ATTENDEE_ROLE,
    EXCHANGE_RESPONSE,
    ATTENDEE_PHONETOOL_URL_PREFIX,
    ATTENDEE_IMAGE_URL_PREFIX,
    DEFAULT_PRIORITY_ICON_SIZE,
    POLL_PRIORITY,
} from "./people-constants";

import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link";
import Tooltip from "@amzn/meridian/tooltip";

import mailSvg from "../../assets/icons/general/mail.svg";
import pointOfContactSvg from "../../assets/icons/people/point_of_contact.svg";
import restrictedGroupSvg from "../../assets/icons/people/restricted_group.svg";

import { ReactComponent as EssentialIcon } from "../../assets/icons/people/essential.svg";
import { ReactComponent as OptionalIcon } from "../../assets/icons/people/optional.svg";
import { ReactComponent as RequiredIcon } from "../../assets/icons/people/required.svg";
import { ReactComponent as AutoSuggestIcon } from "../../assets/icons/locations/autosuggest.svg";
import { ReactComponent as PollRequiredIcon } from "../../assets/icons/polls/legend_required.svg";
import { ReactComponent as PollOptionalIcon } from "../../assets/icons/polls/legend_optional.svg";

export const papiUserToAttendee = (user) => {
    const alias = user.basicInfo.login ? user.basicInfo.login.toLowerCase() : "undefined";
    const email = ((user.emailAddresses && user.emailAddresses.PINT) || {}).emailAddress
        || alias + "@amazon.com";
    const name = `${user.basicInfo.firstName} ${user.basicInfo.lastName}`;
    const identifier = `${name} (${alias}@)`;
    return {
        alias,
        email,
        name,
        identifier,
        jobTitle: user.basicInfo.businessTitle,
        type: ATTENDEE_TYPE.PERSON,
        status: ATTENDEE_RESPONSE.NO_RESPONSE,
        priority: ATTENDEE_PRIORITY.REQUIRED,
        isPointOfContact: false,
        imageLink: ATTENDEE_IMAGE_URL_PREFIX + alias,
        building: user.workLocation && user.workLocation.building,
        timezoneId: user.basicInfo.timezoneId,
        papiData: user,
    };
};

export const rasUserToAttendee = (user, status = ATTENDEE_RESPONSE.NO_RESPONSE, priority = ATTENDEE_PRIORITY.REQUIRED) => {
    return {
        alias: user.username,
        email: user.email,
        primaryEmail: user.primaryEmail,
        name: user.name,
        identifier: `${user.name || ""} (${user.username}@)`,
        jobTitle: user.title,
        type: ATTENDEE_TYPE.PERSON,
        isPointOfContact: false,
        status: status,
        priority: priority,
        imageLink: ATTENDEE_IMAGE_URL_PREFIX + user.username,
        isOrganizer: status === ATTENDEE_RESPONSE.ORGANIZER,
        building: user.site && user.site.building,
        timezoneId: user.timezoneId,
        rasData: user,
    };
};

export const groupToAttendee = (group, status = ATTENDEE_RESPONSE.NO_RESPONSE, priority = ATTENDEE_PRIORITY.REQUIRED) => {
    return {
        alias: group,
        email: `${group}@amazon.com`,
        name: `${group}@`,
        identifier: `${group}@`,
        jobTitle: "Internal Group",
        type: ATTENDEE_TYPE.GROUP,
        isPointOfContact: false,
        status: status,
        priority: priority,
    };
};

export const groupToAttendeeWithRasData = (group, status = ATTENDEE_RESPONSE.NO_RESPONSE, priority = ATTENDEE_PRIORITY.REQUIRED) => {
    return {
        alias: group.name,
        email: group.email,
        name: group.name,
        identifier: `${group.name}@`,
        jobTitle: "Internal Group",
        type: ATTENDEE_TYPE.GROUP,
        isPointOfContact: false,
        status: status,
        priority: priority,
        rasData: group
    };
};

export const externalEmailToAttendee = (email, status = ATTENDEE_RESPONSE.NO_RESPONSE, priority = ATTENDEE_PRIORITY.REQUIRED) => {
    return {
        alias: email,
        email: email,
        name: email,
        identifier: email,
        type: ATTENDEE_TYPE.EXTERNAL_EMAIL,
        isPointOfContact: false,
        status: status,
        priority: priority,
    };
};

export const chimeEmailToAttendee = (email) => {
    return {
        email: email,
        identifier: email,
        type: ATTENDEE_TYPE.CHIME,
        priority: ATTENDEE_PRIORITY.REQUIRED,
    };
};

export const formatExchangeResponse = (response) => {
    return EXCHANGE_RESPONSE[response || "noResponse"];
};

/**
 * Taken from AMW1.0
 *
 * Validates whether a string is an email.
 * This is slightly stricter than the RFC822 standard, because the standard
 * allows a lot more variety in an email than most email programs, including
 * Outlook, allow
 *
 * @param {String} email - The string to validate
 * @return {Boolean} True if valid, false if not
 *
 * @function
 * @see http://ex-parrot.com/~pdw/Mail-RFC822-Address.html
 */
export const isValidEmail = (email) => {
    const checker = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return checker.test(email);
};

export const renderAddAllButton = (allAdded, underLimit, attendees, onAddAttendees, onRemoveAttendees, attendeeName, groupMemberType) => {
    return (
        <div aria-label={allAdded ? `Remove all ${groupMemberType} of ${attendeeName} from this meeting` : `Add all ${groupMemberType} of ${attendeeName} to this meeting`}>
            <Text type="b100" alignment="left">
                <Link
                    onClick={() => allAdded ? onRemoveAttendees(attendees) : onAddAttendees(attendees)}
                    type="secondary"
                    disabled={!underLimit}
                >
                    {allAdded ? "Remove All" : "Add All"}
                </Link>
            </Text>
        </div>
    );
};

export const renderShowHideButton = (numUsers, expanded, setExpanded, attendeeName, groupMemberType) => {
    return (
        <div aria-label={expanded ? `Hide ${groupMemberType} of ${attendeeName}` : `Show ${groupMemberType} of ${attendeeName}`}>
            <Text type="b100" alignment="left">
                <Link
                    onClick={() => setExpanded(!expanded)}
                    type="secondary"
                >
                    {expanded ? "Hide" : "Show"}
                </Link>
            </Text>
        </div>
    );
};

export const renderUserNameLink = (alias, attendeeName = undefined, memberType = undefined) => {
    return (
        <div aria-label={attendeeName && memberType ? `Phone tool link for ${attendeeName}'s ${memberType} ${alias}` : `Phone tool link for ${alias}`}>
            <Text type="b100" alignment="right">
                <Link
                    href={ATTENDEE_PHONETOOL_URL_PREFIX + alias}
                    target="_blank"
                    rel="noopener noreferrer"
                    type="secondary"
                >
                    {alias}
                </Link>
            </Text>
        </div>
    );
};

export const renderAddButton = (attendee, attendeeList, onAddAttendee, onRemoveAttendee, attendeeName = undefined, memberType = undefined) => {
    const attendeeAdded = attendeeList.includes(attendee.username);
    return (
        <div aria-label={attendeeName && memberType ? `Add ${attendeeName}'s ${memberType} ${attendee.username} to this meeting` : `Add ${attendee.username} to this meeting`}>
            <Text type="b100" alignment="left">
                <Link
                    onClick={() => attendeeAdded ? onRemoveAttendee(attendee) : onAddAttendee(attendee)}
                    type="secondary"
                >
                    {attendeeAdded ? "Remove" : "Add"}
                </Link>
            </Text>
        </div>
    );
};

export const renderEmailLink = (email, type, altText, fontWeight = "normal") => {
    return (
        <div aria-label={`Send email to ${email}`}>
            <Text type={type || "b100"} alignment="left">
                <Link
                    href={`mailto:${email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    type="secondary"
                >
                    <span style={{fontWeight: fontWeight}}>
                        {altText || email}
                    </span>
                </Link>
            </Text>
        </div>
    );
};

export const renderPriorityIcon = (priority, darkMode = false, size = DEFAULT_PRIORITY_ICON_SIZE, filter = "none") => {
    switch (priority) {
        case ATTENDEE_PRIORITY.AUTO_SUGGEST:
            return (
                <AutoSuggestIcon height={size} width={size} style={darkMode ? {filter: "invert(100%)"} : {filter: filter}} />
            );
        case ATTENDEE_PRIORITY.ESSENTIAL:
            return (
                <EssentialIcon height={size} width={size} style={darkMode ? {filter: "invert(100%)"} : {filter: filter}} />
            );
        case ATTENDEE_PRIORITY.OPTIONAL:
            return (
                <OptionalIcon height={size} width={size} style={darkMode ? {filter: "invert(100%)"} : {filter: filter}} />
            );
        case ATTENDEE_PRIORITY.REQUIRED:
        default:
            return (
                <RequiredIcon height={size} width={size} style={darkMode ? {filter: "invert(100%)"} : {filter: filter}} />
            );
    }
};

export const renderPollPriorityIcon = (priority, darkMode = false, size = DEFAULT_PRIORITY_ICON_SIZE) => {
    switch (priority) {
        case POLL_PRIORITY.REQUIRED:
            return (
                <PollRequiredIcon height={size} width={size} aria-hidden="true" style={darkMode ? {filter: "grayscale(0%)"} : {filter: "none"}} />
            );
        case POLL_PRIORITY.OPTIONAL:
            return (
                <PollOptionalIcon height={size} width={size} aria-hidden="true" style={darkMode ? {filter: "grayscale(100%)"} : {filter: "none"}} />
            );
        default:
    }
}

export const renderLivestreamFeatures = (attendee, textWhenNone = "None", textType = "b200", alignmentHorizontal = "left") => {
    const emailTooltip = attendee.isOrganizer ?
        "Organizer receives presenter and viewer emails"
        :
        `Will receive ${attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER ? "presenter" : "viewer"} email invite`;
    const displayPointOfContact = attendee.isPointOfContact;
    const displayRestrictedGroup = attendee.isRestrictedGroup;

    return (
        <Row spacing="small" alignmentHorizontal={alignmentHorizontal}>
            <Tooltip position="right" title={emailTooltip}>
                <img src={mailSvg} alt="mail" />
            </Tooltip>
            {displayPointOfContact &&
                // TODO: Update text when watchers can be assigned during automatic ticket cutting
                // <Tooltip position="right" title="Point of contact will be added as a watcher to the livestream request ticket">
                <Tooltip position="right" title="Will be added as a point of contact to the livestream request ticket">
                    <img src={pointOfContactSvg} alt="point of contact" />
                </Tooltip>
            }
            {displayRestrictedGroup &&
                <Tooltip position="right" title="Members of this group will have access to watch this livestream">
                    <img src={restrictedGroupSvg} alt="restricted group" />
                </Tooltip>
            }
        </Row>
    );
};

// Formats display names from Last, First to First Last
export const formatDisplayName = (displayName) => {
    if (displayName) {
        let name = displayName.split(", ");

        if (name.length > 1) {
            return name[1] + " " + name[0]; // display name is a person
        }

        return displayName; // display name is an email
    }
};

// Get the tooltip for an inputted attendee/group
export const getAttendeeTooltip = (attendee) => {
    let tooltip = attendee.name || attendee.displayName || "";

    if (attendee.alias && !tooltip.includes(attendee.alias)) {
        tooltip += tooltip ? " - " + attendee.alias : attendee.alias;
    } else if (!tooltip) {
        tooltip = attendee.email;
    }

    return tooltip;
};