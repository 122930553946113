import React from "react"
import SelectOption from "./select-option"
import isElementOf from "../../_utils/is-element-of"
import filterPropsByPrefix from "../../_utils/filter-props-by-prefix"

const isOption = isElementOf(SelectOption)

const isOptionValueSelected = (selectedValue, optionValue) =>
  Array.isArray(selectedValue)
    ? selectedValue.indexOf(optionValue) !== -1
    : selectedValue === optionValue

const getOptionsFromChildren = children =>
  React.Children.toArray(children)
    .filter(isOption)
    .map((child, index) => ({
      index,
      label: child.props.label.toString(),
      value: child.props.value,
      children: child.props.children,
      disabled: child.props.disabled,
      passThroughProps: filterPropsByPrefix(child.props, ["data-"]),
    }))

const getNonOptionsFromChildren = children =>
  React.Children.toArray(children).filter(child => child && !isOption(child))

export {
  isOption,
  isOptionValueSelected,
  getOptionsFromChildren,
  getNonOptionsFromChildren,
}
