import React, { useState, useEffect } from "react";

import DOMPurify from "dompurify";

import Alert from "@amzn/meridian/alert";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Loader from "@amzn/meridian/loader";
import Text from "@amzn/meridian/text";
import Toggle from "@amzn/meridian/toggle";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "../../shared/meridian-custom-components/src/components/select";

import PatternBackground from "../../../assets/backgrounds/pattern_small_vertical_repeating.svg";

import RichTextEditor from "../../shared/components/rich-text-editor";
import MeetingReviewCard from "./meeting-review-card";
import MeetingOfPollReviewCard from "./meeting-of-poll-review-card";
import AlertsContainer from "../../shared/containers/alerts-container";

import {
    BACKGROUND_OPACITY,
    COPIED_TO_NEW_ONE_TIME_PIN_GENERATED_TOAST_MESSAGE, FLOW_TYPE,
    INPUT_HEIGHT,
    INPUT_WIDTH,
} from "../meeting-scheduler-constants";
import { STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { ALERTS, SCREEN_SIZE } from "../../shared/shared-constants";
import { chimeEmailToAttendee } from "../../people/people-utils";
import {
    getChimePinFromChimeAttendee,
    getFormattedChimePin,
    isOneTimeChimePinAttendee,
    sortAttendees
} from "../../shared/meeting-utils";
import { generateComponentId } from "../../shared/shared-utils";
import { updateQueryParameters } from "../../shared/permalink/permalink-utils";
import { updateBridgeInfoInBody } from "../../shared/chime/manage-bridge-info";
import { removeChimeBridgeInfo } from "../../shared/chime/html-template-utilities";
import { COMPONENT_LABELS } from "../../shared/accessibility-constants";
import { getInformationalToast } from "../../shared/toasts/toast-utils";
import { TIMEOUT } from "../../shared/toasts/toast-constants";


const StepMeetingAgendaAndReview = (props) => {
    const flowType = props.flowType;
    const identity = props.identity;
    const currentStep = props.currentStep;
    const onChangeStep = props.onChangeStep;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const meeting = props.meeting;
    const attendees = sortAttendees(props.attendees, identity.email);
    const gridAttendeeAvailability = props.gridAttendeeAvailability
    const freeAttendeesCount = props.freeAttendeesCount;
    const onUpdateCurrentMeeting = props.onUpdateCurrentMeeting;
    const onAddAttendee = props.onAddAttendee;
    const onRemoveAttendee = props.onRemoveAttendee;
    const chimeUniqueIds = props.chimeUniqueIds;
    const chimeUser = props.chimeUser;
    const generatedChimeBridge = chimeUniqueIds.AllocatedPins && chimeUniqueIds.AllocatedPins[0].Pin;
    const personalChimeBridge = chimeUser.PersonalPIN;
    const [queryParameters, setQueryParameters] = [props.queryParameters, props.setQueryParameters];
    const loadChimeInLocation = props.loadChimeInLocation;
    const isSubjectEmpty = props.isSubjectEmpty;
    const meetingDetails = props.meetingDetails;
    const onShowToast = props.onShowToast;
    const copiedToNew = props.copiedToNew;
    const rooms = props.rooms;
    const prevRooms = props.prevRooms;
    const prevLocations = props.prevLocations;
    const suggestionViewType = props.suggestionViewType;
    const currentYear = props.currentYear;
    const alerts = props.reviewAlerts || [];
    const timeFormat = props.timeFormat;

    // Meeting suggestion info
    const meetingSuggestions = props.meetingSuggestions;
    const meetingSuggestionSelected = props.meetingSuggestionSelected;
    const [meetingSuggestionsDetail, setMeetingSuggestionsDetail] = [props.meetingSuggestionsDetail, props.setMeetingSuggestionsDetail];
    const onSelectSuggestion = props.onSelectSuggestion;

    // Existing meeting info
    const isExistingMeetingSelected = props.isExistingMeetingSelected;
    const [existingMeetingDetail, setExistingMeetingDetail] = [props.existingMeetingDetail, props.setExistingMeetingDetail];

    // Current room selection info
    const [selectedRooms, setSelectedRooms] = [props.selectedRooms, props.setSelectedRooms];

    // Timezone info
    const timezoneValue = props.timezoneValue;
    const timezones = props.timezones;
    const [suggestionsTimezoneId, setSuggestionsTimezoneId] = useState(timezoneValue);

    const usePersonalChimePin = props.usePersonalChimePin;

    // Meeting duration
    const [durationSelected, setDurationSelected] = [props.durationSelected, props.setDurationSelected];
    const [customDurationValue, setCustomDurationValue] = [props.customDurationValue, props.setCustomDurationValue];
    const [customDurationSelected, setCustomDurationSelected] = [props.customDurationSelected, props.setCustomDurationSelected];

    const [chimePin, setChimePin] = [props.chimePin, props.setChimePin];
    const [copyToNewChimeHandled, setCopyToNewChimeHandled] = [props.copyToNewChimeHandled, props.setCopyToNewChimeHandled];
    const [subject, setSubject] = useState(meeting.subject);
    const [location, setLocation] = useState(meeting.location);
    const [prevChimePin, setPrevChimePin] = useState(chimePin);
    const [customLocation, setCustomLocation] = [props.customLocation, props.setCustomLocation];

    const CHIME_LABEL_ID = "chime-label-id";
    const SHOW_AS_LABEL_ID = "show-as-label-id";

    const updateSubject = () => onUpdateCurrentMeeting({subject: subject});
    const updateLocation = () => onUpdateCurrentMeeting({location: location});
    const updateLocationTo = (newLocation) => onUpdateCurrentMeeting({location: newLocation});
    const updateMeetingBody = (value) => {
        onUpdateCurrentMeeting({
            body: {
                ...meeting.body,
                value: DOMPurify.sanitize(value).replaceAll("<p>", "<div>").replaceAll("</p>","</div>")
            }
        });
    };

    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChimePin = (newChimePin) => {

        if (!chimePin || !isNaN(chimePin)) {
            meeting.requiredAttendees.forEach((attendee) => {
                if (attendee.email.endsWith("@chime.aws")) {
                    onRemoveAttendee({email: attendee.email});
                }
            });
            // Only need to run this code the first time this page loads when copying to new
            if (copiedToNew && !copyToNewChimeHandled && meetingDetails && meetingDetails.entryID) {
                let meetingDetailsAttendees = (meetingDetails.requiredAttendees || []).concat(meetingDetails.optionalAttendees || []);
                let oneTimePinAttendee = meetingDetailsAttendees.find((attendee) => isOneTimeChimePinAttendee(attendee.email));
                if (oneTimePinAttendee) {
                    setPrevChimePin(getChimePinFromChimeAttendee(oneTimePinAttendee.email));
                    const toast = getInformationalToast(COPIED_TO_NEW_ONE_TIME_PIN_GENERATED_TOAST_MESSAGE);
                    toast.toastTimeout = TIMEOUT.LONG;
                    onShowToast(toast);
                }
                setCopyToNewChimeHandled(true);
            }
        }

        setChimePin(newChimePin);

        if (!isNaN(newChimePin)) {
            updateMeetingBody(updateBridgeInfoInBody(meeting.body.value, {bridgeID: newChimePin}));
            if (props.autoCall) {
                onAddAttendee(chimeEmailToAttendee("meet@chime.aws"));
            }
            if (newChimePin === generatedChimeBridge) {
                onAddAttendee(chimeEmailToAttendee(`pin+${newChimePin}@chime.aws`));
            }
        } else {
            props.setAutoCall(false);
            updateMeetingBody(updateBridgeInfoInBody(meeting.body.value, null));
        }
    };

    const handleCustomLocation = (customLocation) => {
        setLocation(customLocation);
        setCustomLocation(customLocation);
    };

    const handleChimePinSelection = ((chimePinSelection) => {
        handleChimePin(chimePinSelection);
        setPrevChimePin(chimePin);
        if (location === "") {
            setCustomLocation(undefined);
        }
    });

    useEffect(() => {
        // If Meeting location is empty then fill in the blank with Chime ID URL
        if (location !== undefined) {
            let newLocation = location;
            if (loadChimeInLocation && customLocation === undefined && chimePin === "none") {
                newLocation = "";
            } else if (loadChimeInLocation && location.includes(prevChimePin)) {
                newLocation = location.replace(prevChimePin, chimePin);
            } else if (loadChimeInLocation && customLocation === undefined) {
                newLocation = chimePin ? "Chime ID: " + chimePin : "";
            } else if (!loadChimeInLocation && !location.includes(customLocation) && customLocation !== undefined) {
                newLocation = customLocation.concat(", ", location);
            } else if (loadChimeInLocation && customLocation !== "") {
                newLocation = customLocation;
            }
            setLocation(newLocation);
            updateLocationTo(newLocation);
        }
        // eslint-disable-next-line
    }, [chimePin, customLocation]);

    // to mimic componentDidMount, running once initially to set initialChimePin
    useEffect(() => {
        if (!chimePin) {
            handleChimePin(generatedChimeBridge);
        }
        // eslint-disable-next-line
    }, []);

    // to mimic componentDidMount, running once initially to set initialChimePin
    useEffect(() => {
        if (!chimePin && personalChimeBridge && generatedChimeBridge) {
            handleChimePin(usePersonalChimePin !== undefined && usePersonalChimePin ? personalChimeBridge : generatedChimeBridge);
        }
        // eslint-disable-next-line
    }, [usePersonalChimePin, personalChimeBridge, generatedChimeBridge]);

    // useEffect for generating query parameters for Meeting Agenda
    useEffect(() => {
        if (attendees) {
            const parameters = {
                subject: subject,
                body: removeChimeBridgeInfo(meeting.body.value),
            };

            // Update query parameter if the value is different
            updateQueryParameters(parameters, queryParameters, setQueryParameters);
        }
    }, [attendees, subject, meeting.body.value, queryParameters, setQueryParameters]);

    return (
        <Row
            widths={["fill", "fit", "fill"]}
            alignmentVertical="top"
        >
            {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                <div style={{
                    height: "100%",
                    backgroundImage: `url(${PatternBackground})`,
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "left -250px center",
                    backgroundAttachment: "fixed",
                    opacity: BACKGROUND_OPACITY}}
                />
            }
            <Column
                heights={["fit", "fill"]}
                maxWidth={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND : null}
                alignmentVertical="top"
            >
                <Row backgroundColor="white" width="100%" wrap="down" widths={["fill", "fit"]} alignmentVertical="top">
                    <Column
                        heights={["fit","fit","fit","fill"]}
                        spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "small none small medium" : "medium"}
                        width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND : null}
                    >
                        {alerts.length !== 0 &&
                            <AlertsContainer alerts={alerts} />
                        }
                        <Column>
                            <Column spacing="xsmall">
                                <Column spacing="none">
                                    <Text type="b200">Meeting subject</Text>
                                    <Input
                                        aria-label={COMPONENT_LABELS.SUBJECT}
                                        aria-describedby={ALERTS.INVALID_MEETING_SUBJECT.id}
                                        value={subject}
                                        onChange={setSubject}
                                        onBlur={updateSubject}
                                        type="text"
                                        error={isSubjectEmpty}
                                        placeholder="Enter meeting subject"
                                        width="100%"
                                        size="small"
                                    />
                                </Column>
                                {isSubjectEmpty &&
                                    <Alert type={ALERTS.INVALID_MEETING_SUBJECT.type} size="small">
                                        <Text id={ALERTS.INVALID_MEETING_SUBJECT.id}>
                                            {ALERTS.INVALID_MEETING_SUBJECT.value}
                                        </Text>
                                    </Alert>
                                }
                            </Column>
                            <Column spacing="none">
                                <Text type="b200">Meeting location</Text>
                                <Input
                                    aria-label={COMPONENT_LABELS.LOCATION}
                                    value={location}
                                    onChange={handleCustomLocation}
                                    onBlur={updateLocation}
                                    type="text"
                                    placeholder="Enter meeting location"
                                    width="100%"
                                    size="small"
                                />
                            </Column>
                            <Row alignmentVertical="bottom" wrap="down">
                                <Column spacing="none" width={INPUT_WIDTH.CHIME_WIDTH}>
                                    <Text id={CHIME_LABEL_ID} type="b200">Chime</Text>
                                    {chimePin ?
                                        <Select
                                            value={chimePin}
                                            size="small"
                                            onChange={handleChimePinSelection}
                                            placeholder="Select chime option"
                                            width={INPUT_WIDTH.CHIME_WIDTH}
                                            aria-labelledby={CHIME_LABEL_ID}
                                        >
                                            <SelectOption value="none" label="None" />
                                            {generatedChimeBridge &&
                                                <SelectOption
                                                    value={generatedChimeBridge}
                                                    label={`Generate ID: ${getFormattedChimePin(generatedChimeBridge)}`}
                                                />
                                            }
                                            {personalChimeBridge &&
                                                <SelectOption
                                                    value={personalChimeBridge}
                                                    label={`Personal ID: ${getFormattedChimePin(personalChimeBridge)}`}
                                                />
                                            }
                                        </Select>
                                        :
                                        <Loader size="small" />
                                    }
                                </Column>
                                <Row spacingInset="none none xsmall none">
                                    <Toggle id={generateComponentId("toggle", "auto-call", "standard-meeting")} checked={props.autoCall} onChange={props.setAutoCall} disabled={chimePin === "none"}>Auto-call participants</Toggle>
                                </Row>
                            </Row>
                            <Row alignmentVertical="bottom" wrap="down">
                                <Column spacing="none">
                                    <Text id={SHOW_AS_LABEL_ID} type="b200">Show as</Text>
                                    <Select
                                        value={meeting.status}
                                        size="small"
                                        onChange={(status) => props.onUpdateCurrentMeeting({status: status})}
                                        placeholder="Select show status as"
                                        width={INPUT_WIDTH.STATUS_WIDTH}
                                        aria-labelledby={SHOW_AS_LABEL_ID}
                                    >
                                        <SelectOption value="free" label="Free" />
                                        <SelectOption value="tentative" label="Tentative" />
                                        <SelectOption value="busy" label="Busy" />
                                        <SelectOption value="outOfOffice" label="Out Of The Office" />
                                    </Select>
                                </Column>
                                <Row spacingInset="none none xsmall none">
                                    <Checkbox
                                        checked={meeting.isPrivate}
                                        onChange={() => props.onUpdateCurrentMeeting({isPrivate: !meeting.isPrivate})}
                                        id={generateComponentId("checkbox", "private")}
                                    >
                                        Private
                                    </Checkbox>
                                    <Checkbox
                                        checked={meeting.isResponseRequested}
                                        onChange={() => props.onUpdateCurrentMeeting({isResponseRequested: !meeting.isResponseRequested})}
                                        id={generateComponentId("checkbox", "request-responses")}
                                    >
                                        Request responses
                                    </Checkbox>
                                </Row>
                            </Row>
                        </Column>
                    </Column>
                    {flowType === FLOW_TYPE.POLL ?
                        <MeetingOfPollReviewCard
                            suggestionViewType={suggestionViewType}
                            currentStep={currentStep}
                            onChangeStep={onChangeStep}
                            currentMeetingSelector={props.getMeeting}
                            attendees={attendees}
                            gridAttendeeAvailability={gridAttendeeAvailability}
                            freeAttendeesCount={freeAttendeesCount}
                            timezones={timezones}
                            suggestionsTimezoneId={suggestionsTimezoneId}
                            setSuggestionsTimezoneId={setSuggestionsTimezoneId}
                            isExistingMeetingSelected={isExistingMeetingSelected}
                            meetingDetails={meetingDetails}
                            existingMeetingDetail={existingMeetingDetail}
                            setExistingMeetingDetail={setExistingMeetingDetail}
                            onSelectSuggestion={onSelectSuggestion}
                            currentYear={currentYear}
                            meetingSuggestions={meetingSuggestions}
                            meetingSuggestionSelected={meetingSuggestionSelected}
                            meetingSuggestionsDetail={meetingSuggestionsDetail}
                            setMeetingSuggestionsDetail={setMeetingSuggestionsDetail}
                            rooms={rooms}
                            prevRooms={prevRooms}
                            selectedRooms={selectedRooms}
                            setSelectedRooms={setSelectedRooms}
                            prevLocations={prevLocations}
                            durationSelected={durationSelected}
                            setDurationSelected={setDurationSelected}
                            customDurationValue={customDurationValue}
                            setCustomDurationValue={setCustomDurationValue}
                            customDurationSelected={customDurationSelected}
                            setCustomDurationSelected={setCustomDurationSelected}
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            timeFormat={timeFormat}
                        />
                        :
                        <MeetingReviewCard
                            suggestionViewType={suggestionViewType}
                            currentStep={currentStep}
                            onChangeStep={onChangeStep}
                            currentMeetingSelector={props.getMeeting}
                            attendees={attendees}
                            gridAttendeeAvailability={gridAttendeeAvailability}
                            freeAttendeesCount={freeAttendeesCount}
                            timezones={timezones}
                            suggestionsTimezoneId={suggestionsTimezoneId}
                            setSuggestionsTimezoneId={setSuggestionsTimezoneId}
                            isExistingMeetingSelected={isExistingMeetingSelected}
                            meetingDetails={meetingDetails}
                            existingMeetingDetail={existingMeetingDetail}
                            setExistingMeetingDetail={setExistingMeetingDetail}
                            onSelectSuggestion={onSelectSuggestion}
                            currentYear={currentYear}
                            meetingSuggestions={meetingSuggestions}
                            meetingSuggestionSelected={meetingSuggestionSelected}
                            meetingSuggestionsDetail={meetingSuggestionsDetail}
                            setMeetingSuggestionsDetail={setMeetingSuggestionsDetail}
                            rooms={rooms}
                            prevRooms={prevRooms}
                            selectedRooms={selectedRooms}
                            setSelectedRooms={setSelectedRooms}
                            prevLocations={prevLocations}
                            durationSelected={durationSelected}
                            setDurationSelected={setDurationSelected}
                            customDurationValue={customDurationValue}
                            setCustomDurationValue={setCustomDurationValue}
                            customDurationSelected={customDurationSelected}
                            setCustomDurationSelected={setCustomDurationSelected}
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            timeFormat={timeFormat}
                        />
                    }
                </Row>
                <Row widths={["fill"]} spacingInset="xsmall medium">
                    <Column heights={["fill"]}>
                        <Row widths={["fill"]}>
                            <div style={{
                                paddingBottom: STEP_NAVIGATION_FOOTER.HEIGHT,
                            }}>
                                <RichTextEditor
                                    key={`${chimePin}.`}
                                    textValue={meeting.body.value}
                                    updateTextValue={updateMeetingBody}
                                    height={INPUT_HEIGHT.TEXT_EDITOR_HEIGHT}
                                />
                            </div>
                        </Row>
                    </Column>
                </Row>
            </Column>
            {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                <div style={{
                    height: "100%",
                    backgroundImage: `url(${PatternBackground})`,
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "right -250px center",
                    backgroundAttachment: "fixed",
                    opacity: BACKGROUND_OPACITY}}
                />
            }
        </Row>
    );
};

export default StepMeetingAgendaAndReview;
