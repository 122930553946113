import React, { useRef, useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import modalTokens from "@amzn/meridian-tokens/component/modal"
import ModalBox, {
  scrimSpacingVertical,
  scrimSpacingHorizontal,
} from "./modal-box"
import { useTheme } from "../theme"
import Scrim from "../_scrim"
import Portal from "../portal"
import Responsive from "../responsive"
import withAnimateMount, { AnimateFadeScale } from "../animate-mount"
import CustomPropTypes from "../../_prop-types"
import { css } from "emotion"
import onDirectClick from "../../_utils/on-direct-click"

const AnimateFadeScaleWithMount = withAnimateMount(AnimateFadeScale)

/**
 * The scrim is the vertical scroll container, and uses flexbox to center the
 * modal in the viewport.
 */
const scrimStyles = css({
  display: "flex",
  alignItems: "center",
  overflow: "auto",
})

/**
 * This wrapper uses margin "auto" to center the modal, but sets a max width and
 * height which force the modal to overflow to the bottom/right of the viewport
 * instead of oveflowing all sides (which looks funky and makes the top/left
 * content inaccessible). For details see:
 * https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
 */
const outerWrapperStyles = css({
  maxWidth: "100%",
  maxHeight: "100%",
  margin: "auto",
})

/**
 * The inner wrapper applies a margin around the modal to ensure a certain
 * amount of scrim is always shown. Applying the margin here as opposed to on
 * the outer wrapper ensures that the margin below the modal is preserved even
 * if the modal overflows the viewport and requires scrolling (otherwise the
 * padding would disappear due to this problem:
 * https://www.brunildo.org/test/overscrollback.html).
 */
const innerWrapperStyles = css({
  padding: `${scrimSpacingVertical}vh ${scrimSpacingHorizontal}vw`,
  // HACK: IE11 will only respect the max-height property of the modal if the
  // modal's parent is a flex column. See:
  // https://stackoverflow.com/questions/44635857/flexbox-max-height-issue-with-ie11
  display: "flex",
  flexDirection: "column",
})

/**
 * Don't bother updating the modal if it's persisting in a closed state.
 */
const shouldNotUpdate = (prevProps, nextProps) =>
  !prevProps.open && !nextProps.open

/**
 * Render a modal in the viewport. On desktop the modal will be shown with a
 * scrim behind it. On mobile the modal will be shown fullscreen. Only one modal
 * should be rendered at a time.
 *
 * NOTE: Do not mount a Modal inside an interactive element. The Modal's DOM
 * element will be rendered at the application root using a [React Portal](https://reactjs.org/docs/portals.html),
 * but events from the Modal will bubble up through the element where it was
 * mounted. If the element is interactive then this may result in unexpected
 * behavior (e.g. a click on the Modal being counted as a click on the element
 * where the Modal was mounted). The React team [recommends solving this](https://github.com/facebook/react/issues/11387#issuecomment-366142349)
 * by rendering the portal-based component *outside* of any interactive elements.
 */
const Modal = React.memo(props => {
  const t = useTheme(modalTokens, "modal")
  const scrimRef = useRef()
  const anchorRef = useRef()

  const updateBackgroundElementsVisibility = useCallback((ariaHidden) => {
    let backgroundElementsToHide = document.getElementById(props.backgroundElementsId || "root")
    if (backgroundElementsToHide) {
      backgroundElementsToHide.setAttribute("aria-hidden", ariaHidden)
    }
  }, [props.backgroundElementsId]);

  // We want an onClose callback, but only if props.onClose is defined
  const onCloseProp = props.onClose
  const onCloseCallback = useCallback(() => {
    if (anchorRef.current && anchorRef.current.focus) {
      anchorRef.current.focus()
    }
    updateBackgroundElementsVisibility(false)
    onCloseProp()
  }, [onCloseProp, anchorRef, updateBackgroundElementsVisibility])
  const onCloseMaybe = onCloseProp ? onCloseCallback : undefined

  const [prevOpen, setPrevOpen] = useState(false)

  useEffect(() => {
    if (props.open) {
      // Remember the node that the modal was opened from. We'll try to focus on
      // this when the modal is closed. We put this logic in a side effect rather
      // then the `modalRef` callback b/c by the time that callback is called
      // it's too late and the `activeElement` might already be an auto-focused
      // element inside of the modal - negating the benefit of remembering which
      // node caused the modal to open.
      anchorRef.current = document.activeElement
    }

    if (props.open !== prevOpen) {
      updateBackgroundElementsVisibility(props.open)
      setPrevOpen(props.open);
    }
  }, [props.open, prevOpen, updateBackgroundElementsVisibility]);

  const modalRef = useCallback(modalNode => {
    if (modalNode) {
      // Get the active element and check if it's inside of the modal
      const activeElementInsideModal = modalNode.contains(
        document.activeElement
      )
      if (!activeElementInsideModal) {
        // Focus the first node in the Modal
        modalNode.focus()

        // Reset the scroll position of the scrim to counteract the browser
        // auto-scrolling to the focused node. Note that we have to wait one
        // event loop for this to work (otherwise we'd be resetting the scroll
        // *before* the browser auto-scrolls).
        // Prevent the browser from scrolling to the focused node to avoid a
        // jitter when the modal opens.
        // TODO: Update this to use the "preventScroll" focus option when Safari
        // implements that and we drop support for IE11.
        if (scrimRef.current) {
          setTimeout(() => {
            scrimRef.current.scrollTop = 0
            scrimRef.current.scrollLeft = 0
          }, 0)
        }
      }
    }
  }, [])

  return (
    <AnimateFadeScaleWithMount
      open={props.open}
      duration={t("motionDuration")}
      easing={t("motionFunction")}
      onOpen={props.onOpen}
    >
      {({ className, onTransitionEnd, open }) => (
        <Responsive
          query="min-width"
          props={{
            fullscreen: {
              default: true,
              [`${t("floatingBreakpoint")}px`]: false,
            },
          }}
        >
          {({ fullscreen }) => {
            const modal = (
              <ModalBox
                {...props}
                onClose={onCloseMaybe}
                t={t}
                scrollContainer={props.scrollContainer}
                fullscreen={fullscreen}
                className={className}
                onTransitionEnd={onTransitionEnd}
                ref={modalRef}
                id={props.id}
              />
            )
            return (
              <Portal>
                {fullscreen ? (
                  modal
                ) : (
                  <Scrim
                    open={props.open || open}
                    className={scrimStyles}
                    onClick={onCloseMaybe}
                    ref={scrimRef}
                  >
                    <div className={outerWrapperStyles}>
                      <div
                        className={innerWrapperStyles}
                        onMouseDown={
                          // The inner wrapper has a little padding
                          // applied to keep the modal away from the
                          // edges of the viewport. This padding appears
                          // as scrim space to the user, but it blocks
                          // clicks to the scrim so we have to add the
                          // click-to-close behavior here as well
                          onDirectClick(onCloseMaybe)
                        }
                      >
                        {modal}
                      </div>
                    </div>
                  </Scrim>
                )}
              </Portal>
            )
          }}
        </Responsive>
      )}
    </AnimateFadeScaleWithMount>
  )
}, shouldNotUpdate)

Modal.displayName = "Modal"

Modal.propTypes = {
  /**
   * The contents of the modal.
   */
  children: PropTypes.node.isRequired,
  /**
   * Is the modal open? If false the modal will not be mounted in the DOM. If
   * changed from false to true the modal will be mounted and will animate
   * open.
   */
  open: PropTypes.bool.isRequired,
  /**
   * Sets the padding on the body of the modal
   */
  bodySpacingInset: CustomPropTypes.spacing(4),
  /**
   * Determines whether or not the click event for the close button will continue to bubble up
   * through the DOM. Read the [Event.stopPropagation() docs](https://developer.mozilla.org/en-US/docs/Web/API/Event/stopPropagation)
   * for details.
   *
   * @since 5.x
   */
  closeButtonPropagateClickEvent: PropTypes.bool,
  /**
   * An accessibility label for the close button. This will be read by screen
   * readers, but will not be present in the UI.
   */
  closeLabel: PropTypes.string,
  /**
   * All props prefaced with `data-` are accepted. This is useful for
   * integrating with other libraries such as Amplify Analytics or Cypress.
   *
   * @since 5.x
   */
  "data-*": PropTypes.string,
  /**
   * Gives the dialog an accessible description by referring to the dialog
   * content that describes the primary message or purpose of the dialog.
   */
  describedById: PropTypes.string,
  /**
   * If the modal is a form (see `onSubmitForm`), this [action](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form) will be passed
   * to the form element.
   *
   * @since 4.x
   */
  formAction: PropTypes.string,
  /**
   * If the modal is a form (see `onSubmitForm`), this [method](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form) will be passed
   * to the form element.
   *
   * @since 4.x
   */
  formMethod: PropTypes.oneOf(["get", "post", "dialog"]),
  /**
   * This determines where the vertical scrollbar is placed for modals with
   * content that overflows vertically. If set to "modal", the scrollbar will
   * be placed inside the modal. The content will overflow inside the modal,
   * but the the modal itself will fit inside the viewport. If set to
   * "viewport", the scrollbar will be placed inside the viewport. The modal
   * will overflow the viewport, and the user will scroll the entire modal to
   * view the content.
   *
   * NOTE: This does not currently affect the horizontal scrollbar for content
   * which is too wide for the modal and cannot wrap. The horizontal scrollbar
   * will always be shown inside the modal.
   *
   * NOTE: This only applies to modals on desktop. On mobile modals are shown
   * fullscreen so there is no meaningful distinction between the modal
   * and the viewport.
   */
  scrollContainer: PropTypes.oneOf(["modal", "viewport"]),
  /**
   * The title of the modal. This will be shown in the modal header. If no
   * title is provided, no header will be shown.
   */
  title: PropTypes.string,
  /**
   * Set the width of the modal using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   *
   * This width only applies to desktop, as modals are displayed
   * full-screen on mobile.
   *
   * A max width is applied to the modal in
   * addition to this width to ensure that the modal is never too wide for the
   * viewport.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If a callback is provided here, a close button will be rendered in the
   * modal. The user will be able to close the modal by clicking on the button
   * or the scrim behind the modal. The callback will be fired whent he user
   * performs either of these actions.
   *
   * If a callback is not provided here, no close button will be rendered and
   * nothing will happen when the user clicks the scrim. This can be helpful
   * for dialog modals that require an action from the user. Note that if
   * you do not provide a close handler here you *must* provide another way
   * for the user to close the modal (e.g. by confirming or denying a dialog
   * action). Otherwise the user will be stuck in the modal.
   */
  onClose: PropTypes.func,
  /**
   * This callback will be fired after the Modal opens.
   */
  onOpen: PropTypes.func,
  /**
   * If a callback is provided here the Modal will behave like a [form](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form).
   *
   * To set the [action and method](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form) of the form, see `formAction` and `formMethod`.
   *
   * If using a Meridian [Button](https://meridian.a2z.com/components/button/?platform=react-web&detailsTab=api) in the footer for submission,
   * be sure to use set the button's `submit` prop to `true`.
   *
   * @since 4.x
   */
  onSubmitForm: PropTypes.func,
  /**
   * Use this to set the id attribute of the modal's div.
   * This can be useful for setting a unique identifier on the div so that it can be found
   *
   * @added by meetex team member (camei@)
   */
  id: PropTypes.string,
  /**
   * Set this to the id of a wrapper element around elements you would like hidden from screen readers while this modal is open.
   * If no value is supplied, the "root" div will be hidden by default.
   *
   * @added by meetex team member (camei@)
   */
  backgroundElementsId: PropTypes.string,
}

Modal.defaultProps = {
  closeButtonPropagateClickEvent: true,
  closeLabel: "Close",
  bodySpacingInset: "medium",
  scrollContainer: "viewport",
}

export default Modal
