import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import noop from "lodash/noop"
import buttonTokens from "@amzn/meridian-tokens/component/button"
import Button from "../button"
import { memoize } from "../../_utils/functional"
import textStyles from "../../_styles/text"
import listResetStyles from "../../_styles/list-reset"

const styles = memoize(t => {
  const { buttonSpacingInsetHorizontalMedium } = buttonTokens(t.tokens)
  return css(textStyles(t), {
    position: "relative",
    padding: `${t("popoverSpacingInset")}px ${t("popoverSpacingInset") -
      buttonSpacingInsetHorizontalMedium}px`,
    "&:before": {
      content: "''",
      position: "absolute",
      top: t("popoverSpacingInset"),
      bottom: t("popoverSpacingInset"),
      left: 0,
      width: t("presetsDividerWidth"),
      backgroundColor: t("presetsDividerColor"),
    },
    // Label
    "& > div": {
      marginLeft: buttonSpacingInsetHorizontalMedium,
    },
    // List
    "& > ul": css(listResetStyles, {
      marginTop: t("popoverSpacingVertical"),
    }),
  })
})

/**
 * This component provides a way for users to select a date via a list of
 * presets.
 */
class DatePickerPresets extends React.Component {
  static propTypes = {
    presets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string),
        ]),
      })
    ).isRequired,
    /**
     * This is called when the user chooses a preset. The value of the preset
     * will be passed as the first argument to the function as a string with the
     * format YYYY-MM-DD.
     */
    onChange: PropTypes.func.isRequired,
    disabledDates: PropTypes.func,
    label: PropTypes.string,
    t: PropTypes.func,
  }

  static defaultProps = {
    label: "Presets",
    disabledDates: noop,
  }

  render() {
    const { t, label, presets, onChange, disabledDates } = this.props
    return (
      <div className={styles(t)}>
        <div>{label}</div>
        <ul>
          {presets.map((preset, index) => (
            <li key={index}>
              <Button
                type="link"
                onClick={() => onChange(preset.value)}
                disabled={
                  Array.isArray(preset.value)
                    ? disabledDates(preset.value[0]) ||
                      disabledDates(preset.value[1])
                    : disabledDates(preset.value)
                }
              >
                {preset.label}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default DatePickerPresets
