import React, { useEffect, useState } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import Tag from "@amzn/meridian/tag";
import Button from "@amzn/meridian/button";
import RadioButton from "@amzn/meridian/radio-button";

import chevronUpSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-up-small";
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";

import { MEETING_CARD } from "../meeting-scheduler-constants.js";
import {
    convertEpoch,
    sortRoomsByName,
} from "../meeting-scheduler-utils.js"
import {
    getAttendeeResponseTypeCount,
    getNonChimeAttendeesCount,
    getAttendeeAvailabilityType,
} from "../existing-meeting-utils.js"
import { shortRoomName } from "../../shared/locations/locations-utils.js";
import { TIMEZONE_DISPLAY_TYPE } from "../../shared/timezones/timezones-constants";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { ATTENDEE_PRIORITY, ATTENDEE_RESPONSE, EXCHANGE_RESPONSE } from "../../people/people-constants.js";
import { formatDisplayName, renderPriorityIcon } from "../../people/people-utils.js";
import { ATTENDEE_TYPE } from "../../people/people-constants";
import { TIME_FORMAT } from "../../shared/settings/settings-constants.js";

const ExistingMeetingOption = (props) => {
    const meetingDetails = props.meetingDetails;
    const timezones = props.timezones;
    const currentYear = props.currentYear;
    const timeFormat = props.timeFormat;
    const suggestionsTimezoneShortName = timezoneIdToDisplayName(timezones || [], props.suggestionsTimezoneId, TIMEZONE_DISPLAY_TYPE.SHORT);

    const [existingMeetingDetail, setExistingMeetingDetail] = [props.existingMeetingDetail, props.setExistingMeetingDetail];
    const isExistingMeetingSelected = props.isExistingMeetingSelected;

    const [timezoneName, setTimezoneName] = useState(undefined); // useEffect will set initial timezoneName

    // Toggle meeting details
    const toggleExistingDetail = (id, type = "") => {
        setExistingMeetingDetail((prevexistingMeetingDetail) => ({
            ...prevexistingMeetingDetail,
            [id + type]: !prevexistingMeetingDetail[id + type],
        }));
    };

    const attendees = meetingDetails.requiredAttendees.concat(meetingDetails.optionalAttendees);

    // Render a group of attendees given the response type
    const getAttendeeResponseGroup = (responseType) => {
        meetingDetails.requiredAttendees.forEach((attendee) => {
            attendee["priority"] = ATTENDEE_PRIORITY.REQUIRED;
        });
        meetingDetails.optionalAttendees.forEach((attendee) => {
            attendee["priority"] = ATTENDEE_PRIORITY.OPTIONAL;
        });

        let responseAttendees = [];

        // Get attendees that match the inputted response type
        attendees.forEach((attendee) => {
            if (((attendee.response === responseType) || (responseType === "noResponse" && !attendee.response)) && !attendee.isOrganizer && !attendee.email.endsWith("@chime.aws")) {
                responseAttendees.push(attendee);
            }
        });

        // Sort attendees by priority, then alphabetically by name
        const priorities = {"Required": 1, "Optional": 2};
        responseAttendees.sort((attendee1, attendee2) => {
            if (attendee1.priority !== attendee2.priority) {
                return priorities[attendee1.priority] - priorities[attendee2.priority];
            }
            return formatDisplayName(attendee1.displayName) > formatDisplayName(attendee2.displayName) ? 1 : -1;
        });

        let attendeeEmails = [];
        responseAttendees.forEach((responseAttendee) => {
            attendeeEmails.push(responseAttendee.email);

            let attendeeData = (props.attendees || []).find((attendee) => attendee.email === responseAttendee.email);
            responseAttendee.type = attendeeData && attendeeData.type;
            responseAttendee.isOrganizer = attendeeData && attendeeData.isOrganizer;
            responseAttendee.timezoneShortName = timezoneIdToDisplayName(timezones || [], attendeeData && attendeeData.timezoneId, TIMEZONE_DISPLAY_TYPE.SHORT);
        });

        return (responseAttendees.length !== 0 &&
            <Column spacing="none">
                <Text type="b100"><b>{EXCHANGE_RESPONSE[responseType]} ({responseAttendees.length}/{getNonChimeAttendeesCount(attendees)})</b></Text>
                {responseAttendees.map((attendee) => {
                    return ((attendeeEmails.indexOf(attendee.email) < MEETING_CARD.DEFAULT_ATTENDEE_COUNT || existingMeetingDetail["existing-meeting" + responseType]) ?
                        <Row key={attendee.email} spacing="xsmall">
                            {attendee.priority === ATTENDEE_PRIORITY.REQUIRED &&
                                renderPriorityIcon(ATTENDEE_PRIORITY.REQUIRED, false, 12)
                            }
                            {attendee.priority === ATTENDEE_PRIORITY.OPTIONAL &&
                                renderPriorityIcon(ATTENDEE_PRIORITY.OPTIONAL, false, 12)
                            }
                            <Column width={attendee.type === ATTENDEE_TYPE.PERSON ? `${MEETING_CARD.ATTENDEE_NAME_WIDTH - MEETING_CARD.ATTENDEE_TIMEZONE_WIDTH}px` : `${MEETING_CARD.ATTENDEE_NAME_WIDTH}px`}>
                                <Text type="b100" breakWord={true}>{formatDisplayName(attendee.displayName)}</Text>
                            </Column>
                            {attendee.type === ATTENDEE_TYPE.PERSON && !attendee.isOrganizer && suggestionsTimezoneShortName !== attendee.timezoneShortName &&
                                <Column width={`${MEETING_CARD.ATTENDEE_TIMEZONE_WIDTH}px`}>
                                    <Text type="b100" color="secondary">
                                        {attendee.timezoneShortName}
                                    </Text>
                                </Column>
                            }
                        </Row>
                        :
                        undefined
                    )
                })}
                {attendeeEmails.length > 5 &&
                    <Column spacing="none">
                        <Text type="b100">
                            <Link type="secondary" onClick={() => toggleExistingDetail("existing-meeting", responseType)}>
                                {existingMeetingDetail["existing-meeting" + responseType] ?
                                    "Show less..."
                                    :
                                    "Show more..."
                                }
                            </Link>
                        </Text>
                    </Column>
                }
            </Column>
        );
    };

    const onSelectExistingMeeting = props.onSelectExistingMeeting;

    // Initialize selection to existing meeting time
    useEffect(() => {
        if (existingMeetingDetail["existing-meeting"] === undefined) {
            onSelectExistingMeeting();
            setExistingMeetingDetail({"existing-meeting": true});
        }
    }, [existingMeetingDetail, onSelectExistingMeeting, setExistingMeetingDetail]);

    // Load timezoneName for suggestion card only for initial timezoneName
    useEffect(() => {
        if (timezones.length && !timezoneName) {
            setTimezoneName(timezoneIdToDisplayName(timezones, meetingDetails.startTimezone.id));
        }
    }, [timezones, meetingDetails.startTimezone, timezoneName]); // meetingDetails.startTimezone should not change

    return (
        <Row alignmentHorizontal="justify" alignmentVertical="top" spacingInset="small" backgroundColor="white" type="outline">
            <Row alignmentHorizontal="justify" alignmentVertical="top" wrap="down" spacing="none">
                <Row minWidth="270px" spacing="none" spacingInset="small">
                    <RadioButton checked={isExistingMeetingSelected} value={isExistingMeetingSelected} onChange={onSelectExistingMeeting} size="large">
                        <Column spacing="none">
                            <Row spacingInset="none" spacing="small">
                                <Text type="h100">{convertEpoch(meetingDetails.time.startTime, "time", meetingDetails.startTimezone.id, timeFormat === TIME_FORMAT.TWELVE_HOUR)} - {convertEpoch(meetingDetails.time.endTime, "time", meetingDetails.startTimezone.id, timeFormat === TIME_FORMAT.TWELVE_HOUR)}</Text>
                                {timezoneName &&
                                    <Text type="b100" color="secondary">{timezoneName}</Text>
                                }
                            </Row>
                            {(convertEpoch(meetingDetails.time.startTime, "date", meetingDetails.startTimezone.id) !== convertEpoch(meetingDetails.time.endTime, "date", meetingDetails.startTimezone.id)) ?
                                <Column spacing="none">
                                    <Text>{convertEpoch(
                                        meetingDetails.time.startTime,
                                        currentYear !== convertEpoch(meetingDetails.time.endTime, "year", meetingDetails.startTimezone.id) ? "shortWeekdayDateYear" : "shortWeekdayDate",
                                        meetingDetails.startTimezone.id)} to
                                    </Text>
                                    <Text>{convertEpoch(
                                        meetingDetails.time.endTime,
                                        currentYear !== convertEpoch(meetingDetails.time.endTime, "year", meetingDetails.startTimezone.id) ? "shortWeekdayDateYear" : "shortWeekdayDate",
                                        meetingDetails.startTimezone.id)}
                                    </Text>
                                </Column>
                                :
                                <Text>{convertEpoch(
                                    meetingDetails.time.startTime,
                                    currentYear !== convertEpoch(meetingDetails.time.startTime, "year", meetingDetails.startTimezone.id) ? "shortWeekdayDateYear" : "shortWeekdayDate",
                                    meetingDetails.startTimezone.id)}
                                </Text>
                            }
                        </Column>
                    </RadioButton>
                </Row>
                <Row alignmentVertical="stretch" wrap="down" spacing="medium" spacingInset="small">
                    {meetingDetails.resources.length !== 0 ?
                        <Column width="250px" alignmentHorizontal="left" spacingInset="none small">
                            {sortRoomsByName(meetingDetails.resources).map((room) => (
                                <Column key={room.email} alignmentHorizontal="left" spacing="xsmall">
                                    <Tag type={room.response === ATTENDEE_RESPONSE.ACCEPT ? "success" : "error"}>
                                        <Column maxWidth="215px">
                                            <Text type="b200" truncate={true}>{shortRoomName(room.displayName)}</Text>
                                        </Column>
                                    </Tag>
                                    <Text type="b100">{EXCHANGE_RESPONSE[room.response]}</Text>
                                </Column>
                            ))}
                        </Column>
                        :
                        <Column width="250px" alignmentHorizontal="left" spacingInset="none small">
                            <Tag type="success">Virtual only</Tag>
                        </Column>
                    }
                    <Column width="250px" spacing="small" spacingInset="none small">
                        <Row alignmentVertical="center" spacing="xsmall" height="20px">
                            <Tag type={getAttendeeAvailabilityType(attendees)}>{getAttendeeResponseTypeCount("accept", attendees) === getNonChimeAttendeesCount(attendees) ? "All attendees accepted" : getAttendeeResponseTypeCount("accept", attendees) + " of " + getNonChimeAttendeesCount(attendees) + " attendees accepted"}</Tag>
                            <Button type="icon" onClick={() => toggleExistingDetail("existing-meeting")} size="small">
                                {existingMeetingDetail["existing-meeting"] ?
                                    <Icon tokens={chevronUpSmallTokens}>Hide attendee details for existing meeting</Icon>
                                    :
                                    <Icon tokens={chevronDownSmallTokens}>View attendee details for existing meeting</Icon>
                                }
                            </Button>
                        </Row>
                        {existingMeetingDetail["existing-meeting"] &&
                            <Column spacing="small">
                                {getAttendeeResponseGroup("decline")}
                                {getAttendeeResponseGroup("tentative")}
                                {getAttendeeResponseGroup("accept")}
                                {getAttendeeResponseGroup("noResponse")}
                            </Column>
                        }
                    </Column>
                </Row>
            </Row>
        </Row>
    )
};

export default ExistingMeetingOption;
