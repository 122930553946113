import React from "react"
import PropTypes from "prop-types"
import capitalize from "lodash/capitalize"
import { css } from "emotion"
import popoverTokens from "@amzn/meridian-tokens/component/popover"
import { elevationPopover } from "@amzn/meridian-tokens/base/elevation"
import { useTheme } from "../theme"
import { flipAlignment } from "../_popover-controller/utils"
import { getUnthemedTokens, memoizeTokenStyles } from "../../_utils/token"
import cxMemoized from "../../_utils/cx-memoized"
import textStyles from "../../_styles/text"

const { popoverArrowWidth } = getUnthemedTokens(popoverTokens)

// Takes an edge (e.g. "top") and turns it into a CSS border property (e.g.
// "borderTop").
const edgeToBorder = edge => `border${capitalize(edge)}`

// Takes an edge (e.g. "top") and a CSS length (e.g. "5px") and creates a
// CSS translation function (e.g. "translateY(5px)") that can be added to an
// element  via the CSS "transform" property.
const edgeToTransform = (edge, amount) =>
  `translate${edge === "left" || edge === "right" ? "X" : "Y"}(${amount})`

// These styles are used to create the little arrow that points away from the
// popover. The method used to create the triangles involves psuedo elements
// with different transparent borders applied to certain sides. A good demo of
// this method can be found here: https://codepen.io/chriscoyier/pen/lotjh. The
// benefit of this method is that it doesn't require any images, but the
// downside is that you can't apply a border to the triangle. To get around
// this a border is faked by placing a slightly offset background-colored
// triangle (the :after pseudo element) on top of a border-colored triangle (the
// :before pseudo element).
const arrowStyles = (
  t,
  { type, arrowPosition, arrowAlignment, arrowOffset }
) => {
  const backgroundColor = t("backgroundColor", type)
  const borderColor = t("borderColor", type)
  const borderWidth = t("borderWidth")
  const arrowHeight = t("arrowHeight")
  const arrowWidthHalf = t("arrowWidth") / 2
  // This is the edge of the popover (either "top" or "left") that is parallel
  // to the direction the arrow is pointing.
  const arrowParallelEdge =
    arrowPosition === "top" || arrowPosition === "bottom" ? "left" : "top"
  // This is the edge of the popover that is opposite the edge that the arrow
  // is positioned on.
  const arrowOppositeEdge = flipAlignment(arrowPosition)
  // This uses maths to determine how far we have to scoot the fill triangle
  // along its axis to reveal exactly `borderWidth` pixels of the border
  // triangle along a line that is perpendicular to the edges of both triangles.
  const fillTriangleOffset =
    borderWidth / Math.sin(Math.atan(arrowWidthHalf / arrowHeight))
  // This translates the arrow in the direction orthogonal to the direction that
  // the arrow is pointing (i.e. left/right for an arrow pointing up/down or
  // up/down for an arrow pointing left/right).
  const orthogonalTransform = {
    top: [`${arrowOffset}px`],
    left: [`${arrowOffset}px`],
    right: [`-${arrowOffset}px`, "-100%"],
    bottom: [`-${arrowOffset}px`, "-100%"],
    center: [`${arrowOffset}px`, "-50%"],
  }[arrowAlignment]
    .map(amount => edgeToTransform(arrowParallelEdge, amount))
    .join(" ")

  return css({
    "&:after, &:before": {
      content: "''",
      position: "absolute",
      height: 0,
      width: 0,
      [arrowOppositeEdge]: "100%",
      [arrowParallelEdge]: {
        top: 0,
        left: 0,
        right: "100%",
        bottom: "100%",
        center: "50%",
      }[arrowAlignment],
      // Add transparent borders to the edges on either side of our colored
      // border to make the colored border triangular
      [edgeToBorder(
        arrowParallelEdge
      )]: `${arrowWidthHalf}px solid transparent`,
      [edgeToBorder(
        flipAlignment(arrowParallelEdge)
      )]: `${arrowWidthHalf}px solid transparent`,
    },
    // This creates a triangle with a background color that is the same color as
    // the popover's border.
    "&:before": {
      [edgeToBorder(
        arrowOppositeEdge
      )]: `${arrowHeight}px solid ${borderColor}`,
      transform: orthogonalTransform,
    },
    // If the popover has a border then this creates a triangle that is the same
    // color as the popover's background color. This triangle is displayed on
    // top of the border colored triangle but is scooted back just a bit to
    // reveal enough of the border triangle to create a faux border around
    // itself.
    "&:after": {
      [edgeToBorder(
        arrowOppositeEdge
      )]: `${arrowHeight}px solid ${backgroundColor}`,
      transform: `${orthogonalTransform} ${edgeToTransform(
        arrowOppositeEdge,
        `${
          arrowOppositeEdge === "top" || arrowOppositeEdge === "left" ? "-" : ""
        }${fillTriangleOffset}px`
      )}`,
    },
  })
}

const styles = memoizeTokenStyles(
  (
    t,
    {
      type,
      spacingInset,
      zIndex,
      minWidth,
      maxWidth,
      arrowPosition,
      arrowAlignment,
      arrowOffset,
      allowOverflow,
    }
  ) => {
    const borderColor = t("borderColor", type)
    return css(
      textStyles(t, { color: type }),
      {
        display: "inline-block",
        position: "relative",
        outline: "none",
        zIndex,
        minWidth,
        maxWidth,
        // Children wrapper styles
        "& > div": {
          // If this is used with the PopoverController component then a max
          // width and height will be set on the outer element of this
          // component. Setting the max width and height to "inherit" here will
          // constrain this inner element to the same width/height as its parent
          // (oddly enough max width/height "100%" won't work because CSS). This
          // ensures that scrollbars will be shown for content that overflows.
          // Without these styles this element just extends outside of its
          // parent.
          maxHeight: allowOverflow ? "inherit" : undefined,
          maxWidth: allowOverflow ? "inherit" : undefined,
          overflow: allowOverflow ? "auto" : "hidden",
          padding: `${t("spacingInsetVertical", spacingInset)}px ${t(
            "spacingInsetHorizontal",
            spacingInset
          )}px`,
          backgroundColor: t("backgroundColor", type),
          borderColor,
          borderWidth: borderColor ? t("borderWidth") : undefined,
          borderStyle: borderColor ? "solid" : undefined,
          borderRadius: t("borderRadius"),
          boxShadow: t.boxShadowCss("dropShadow"),
        },
      },
      arrowPosition
        ? arrowStyles(t, { type, arrowPosition, arrowAlignment, arrowOffset })
        : undefined
    )
  },
  [
    "type",
    "spacingInset",
    "zIndex",
    "maxWidth",
    "minWidth",
    "arrowPosition",
    "arrowAlignment",
    "arrowOffset",
    "allowOverflow",
  ]
)

const PopoverBody = React.forwardRef((props, ref) => {
  const t = useTheme(popoverTokens, "popover")
  return (
    <div
      onKeyDown={props.onKeyDown}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      tabIndex={props.tabIndex}
      style={props.style}
      className={cxMemoized(styles(t, props), props.className)}
      role={props.tabIndex ? "dialog" : "tooltip"}
      aria-labelledby={props.labelledById}
      aria-expanded={props.open}
      id={props.id}
      ref={ref}
    >
      <div>{props.children}</div>
    </div>
  )
})

// This helper generates the arrow props that are necessary to point a popover
// arrow at a particular anchor
PopoverBody.generateArrowProps = ({
  popoverOrigin,
  anchorOrigin,
  anchorRect,
}) => {
  const vertical =
    popoverOrigin.y !== anchorOrigin.y &&
    popoverOrigin.y === flipAlignment(anchorOrigin.y)
  const arrowAlignment = vertical ? popoverOrigin.x : popoverOrigin.y
  return {
    arrowAlignment,
    arrowPosition: vertical ? popoverOrigin.y : popoverOrigin.x,
    arrowOffset:
      arrowAlignment !== "center"
        ? anchorRect[vertical ? "width" : "height"] / 2 - popoverArrowWidth / 2
        : 0,
  }
}

PopoverBody.displayName = "PopoverBody"

PopoverBody.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Set this to true to allow content to overflow with scrollbars.
   *
   * NOTE: This was added in conjunction with the `shrinkToFit` prop on the
   * Popover component. Like that prop, this prop should probably be removed in
   * the next major version and the shrink/overflow behavior made to be the
   * default behavior.
   */
  allowOverflow: PropTypes.bool,
  arrowAlignment: PropTypes.oneOf(["top", "right", "bottom", "left", "center"]),
  arrowOffset: PropTypes.number,
  /**
   * If this property is set then an arrow will be drawn pointing away from the
   * specified side of the popover body.
   */
  arrowPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  /**
   * Sets an `id` attribute on the component.
   *
   * Use this for testing or for accessibility. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   */
  id: PropTypes.string,
  /**
   * The id of an element that labels the popover body. See:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-labelledby_attribute
   */
  labelledById: PropTypes.string,
  /**
   * Define a maximum width for the component. Any CSS width value is
   * acceptable.
   */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Define a minimum width for the component. Any CSS width value is
   * acceptable.
   */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  open: PropTypes.bool,
  spacingInset: PropTypes.oneOf(["none", "small", "medium"]),
  style: PropTypes.object,
  /**
   * Manually specify the order of the component in sequential keyboard navigation.
   *
   * Use this with caution. See docs for the [HTML tabindex attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex)
   * for details on appropriate usage.
   */
  tabIndex: PropTypes.string,
  type: PropTypes.oneOf(["outline", "fill"]),
  zIndex: PropTypes.number,
  /**
   * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   */
  onBlur: PropTypes.func,

  /**
   * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   */
  onFocus: PropTypes.func,
  /**
   * This is called when the component has focus and a keyboard key is
   * pressed down. The [keydown event](https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event)
   * is passed as the first argument to the function.
   */
  onKeyDown: PropTypes.func,
}

PopoverBody.defaultProps = {
  open: true,
  type: "outline",
  arrowAlignment: "center",
  arrowOffset: 0,
  spacingInset: "small",
  zIndex: elevationPopover,
}

export default PopoverBody
