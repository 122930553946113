import React from "react";

import { renderExternalLink } from "../../shared-utils";

const IntakeMeetingCreatedToast = (props) => {
    return (
        <React.Fragment>
            {props.isRecordingEvent ?
                <>
                    <b>Meeting created.</b> Your livestream request has been successfully scheduled. Visit your&nbsp;
                    {renderExternalLink(props.ticketLinkText, props.ticketHref, props.ticketLinkName)}
                </>
                :
                <>
                    <b>Meeting created.</b> Your livestream request has been successfully scheduled. Visit your&nbsp;
                    {renderExternalLink(props.livestreamLinkText, props.livestreamHref, props.livestreamLinkName)}
                    &nbsp;and the&nbsp;
                    {renderExternalLink(props.ticketLinkText, props.ticketHref, props.ticketLinkName)}
                </>
            }

        </React.Fragment>
    );
};

export default IntakeMeetingCreatedToast;