import React, { useEffect, useRef, useState } from "react";

import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Menu, { MenuItem } from "@amzn/meridian/menu";
import Select, { SelectOption } from "../../shared/meridian-custom-components/src/components/select";
import Row from "@amzn/meridian/row";
import Table, { TableActionBar, TableActionBarOverlay, TableCell, TableRow } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";

import minusIconTokens from "@amzn/meridian-tokens/base/icon/minus";
import trashTokens from "@amzn/meridian-tokens/base/icon/trash";

import ConditionalWrapper from "../../shared/components/conditional-wrapper";
import AttendeeSearchBar from "./attendee-search-bar";
import AttendeePopover from "./attendee-popover";
import FavoritesModal from "../../shared/favorites/components/favorites-modal";

import { createFavorite, renderSetFavoriteIcon, renderFavoriteTypeIcon } from "../../shared/favorites/favorites-utils";
import { getMeetingResponseIcon } from "../../shared/meeting-status-utils";
import { formatExchangeResponse, renderPriorityIcon, renderLivestreamFeatures } from "../people-utils";
import { FLOW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import {
    ATTENDEE_RESPONSE,
    ATTENDEE_TYPE,
    ATTENDEE_PRIORITY,
    ATTENDEE_ROLE,
    ATTENDEE_SORT_BY,
    ATTENDEE_LIST_CELL_HEIGHT,
    ATTENDEE_LIST_MIN_HEIGHT,
    ATTENDEE_SEARCH_BAR_MIN_WIDTH,
    ATTENDEE_SEARCH_BAR_DEFAULT_POPOVER_HEIGHT,
} from "../people-constants";
import { FAVORITE_TYPE } from "../../shared/favorites/favorites-constants";

const AttendeeList = (props) => {
    const identity = props.identity;
    const workflow = props.workflow; // name of the current workflow
    const flowType = props.flowType;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const attendees = props.attendees;
    const peopleSuggestions = props.peopleSuggestions;
    const groupSuggestions = props.groupSuggestions;
    const rooms = props.rooms;
    const locations = props.locations;
    const favorites = props.favorites;

    const attendeeList = attendees.map((attendee) => attendee.alias);
    const [sortColumn, setSortColumn] = useState(ATTENDEE_SORT_BY.ATTENDEE);
    const [sortDirection, setSortDirection] = useState("ascending");
    const [selectedAttendees, setSelectedAttendees] = useState([]);
    const attendeeCount = attendees.length;
    const checkedAttendeesCount = selectedAttendees.length;
    const allAttendeesSelected = attendeeCount === checkedAttendeesCount;
    const attendeeHeaderCheckboxIcon = !allAttendeesSelected ? minusIconTokens : undefined;

    // Sorting
    const sortAttendees = (attendees, sortColumn, sortDirection) => {
        let sortResult = sortDirection === "ascending" ? -1 : 1;
        let attributeToCompare;
        if (sortColumn === ATTENDEE_SORT_BY.PRIORITY) {
            attributeToCompare = "priority";
        } else if (sortColumn === ATTENDEE_SORT_BY.STATUS) {
            attributeToCompare = "status";
        } else {
            // sort by attendees
            attributeToCompare = "identifier";
        }

        return attendees.sort((attendee1, attendee2) => {
            if (attendee1[attributeToCompare] < attendee2[attributeToCompare]) {
                return sortResult;
            }
            if (attendee1[attributeToCompare] > attendee2[attributeToCompare]) {
                return -sortResult;
            }
            return 0;
        });
    };

    const onSort = (sortState) => {
        if (sortState.sortColumn !== sortColumn) {
            setSortColumn(sortState.sortColumn);
        }
        if (sortState.sortDirection !== sortDirection) {
            setSortDirection(sortState.sortDirection);
        }
    };

    // Attendee Multi-selection
    const setAllAttendees = (value) => {
        let tempSelectedAttendees = [];
        if (value) {
            attendees.forEach((attendee) => tempSelectedAttendees.push(attendee.identifier));
        }
        setSelectedAttendees(tempSelectedAttendees);
    };

    const onChangeAttendeeHeader = () => {
        const allAttendeeValues = checkedAttendeesCount === 0;
        setAllAttendees(allAttendeeValues);
    };

    const onChangeAttendeeRow = (changedAttendee) => {
        let tempSelectedAttendees = [...selectedAttendees];
        if (tempSelectedAttendees.includes(changedAttendee)) {
            tempSelectedAttendees.splice(tempSelectedAttendees.indexOf(changedAttendee), 1);
        } else {
            tempSelectedAttendees.push(changedAttendee);
        }
        setSelectedAttendees(tempSelectedAttendees);
    };

    const unselectSelected = () => {
        // Unselect Attendees and Locations
        setAllAttendees(false);
    };

    // Popover
    const popoverRefs = useRef([]);
    const [openAttendeePopover, setOpenAttendeePopover] = useState("");
    const onClickAttendee = (attendee) => {
        setOpenAttendeePopover(attendee.identifier);

        // If rasData is not present, call APIs once to retrieve card data
        if (attendee.rasData === undefined) {
            if (attendee.type === ATTENDEE_TYPE.PERSON) {
                props.onGetRASDataForPerson(attendee.alias, false);
            } else if (attendee.type === ATTENDEE_TYPE.GROUP) {
                props.onGetRASDataForGroup(attendee.alias);
            }
        }
    };
    const onClosePopover = () => setOpenAttendeePopover("");

    // Role
    const roleRefs = useRef([]);
    const [openRoleMenu, setOpenRoleMenu] = useState("");
    const onClickRole = (attendee) => setOpenRoleMenu(attendee.identifier);
    const onCloseRole = () => setOpenRoleMenu("");

    const updateAttendeeRole = (attendee, role) => {
        if (attendee.role !== role) {
            attendee.role = role;

            // Automatically update priority for livestream roles
            if (role === ATTENDEE_ROLE.ORGANIZER || role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER) {
                attendee.priority = ATTENDEE_PRIORITY.REQUIRED;
            } else if (role === ATTENDEE_ROLE.LIVESTREAM.VIEWER) {
                attendee.priority = ATTENDEE_PRIORITY.OPTIONAL;
            }

            props.onUpdateAttendee(attendee);
        }
    };

    const onChangeAttendeeRole = (attendee, role) => {
        // Update the attendee's role
        updateAttendeeRole(attendee, role);

        // Hide the role option menu
        setOpenRoleMenu("");
    };

    // Priority
    const priorityRefs = useRef([]);
    const [openPriorityMenu, setOpenPriorityMenu] = useState("");
    const onClickPriority = (attendee) => setOpenPriorityMenu(attendee.identifier);
    const onClosePriority = () => setOpenPriorityMenu("");

    const updateAttendeePriority = (attendee, priority) => {
        if (attendee.priority !== priority) {
            attendee.priority = priority;
            props.onUpdateAttendee(attendee);
        }
    };

    const onChangeAttendeePriority = (attendee, priority) => {
        // Update the attendee's priority
        updateAttendeePriority(attendee, priority);

        // Hide the priority option menu
        setOpenPriorityMenu("");
    };

    const onChangeSelectedPriority = (priority) => {
        // Update the selected attendee's priority
        selectedAttendees.forEach((identifier) => {
            let attendee = attendees.find((attendee) => attendee.identifier === identifier);
            updateAttendeePriority(attendee, priority);
        });

        unselectSelected();
    };

    // Remove
    const onClickRemoveAttendee = (attendee) => {
        // Do not remove the organizer
        if (attendee.status !== ATTENDEE_RESPONSE.ORGANIZER) {
            if (selectedAttendees.includes(attendee.identifier)) {
                let tempSelectedAttendees = [...selectedAttendees];
                tempSelectedAttendees.splice(tempSelectedAttendees.indexOf(attendee.identifier), 1);
                setSelectedAttendees(tempSelectedAttendees);
            }
            props.onRemoveAttendee(attendee);
        }
    };

    const onRemoveSelected = () => {
        // Remove the selected attendees
        selectedAttendees.forEach((identifier) => {
            let attendee = attendees.find((attendee) => attendee.identifier === identifier);
            onClickRemoveAttendee(attendee);
        });

        unselectSelected();
    };

    const onRemoveAttendeeByAlias = (alias) => {
        let attendee = attendees.find((attendee) => attendee.alias === alias);
        if (attendee) {
            onClickRemoveAttendee(attendee)
        }
    };

    // Favorites
    const favorited = (attendee) => {
        if (favorites && favorites.length > 0) {
            switch (attendee.type) {
                case ATTENDEE_TYPE.PERSON:
                case ATTENDEE_TYPE.GROUP:
                    return !!favorites.find((favorite) => favorite.value === attendee.alias);
                case ATTENDEE_TYPE.EXTERNAL_EMAIL:
                    return !!favorites.find((favorite) => favorite.value === attendee.email);
                default:
                    return false;
            }
        }

        return false;
    };

    const onFavoriteClick = (attendee) => {
        if (favorites === undefined) {
            return;
        }

        let favorite;
        switch (attendee.type) {
            case ATTENDEE_TYPE.PERSON:
                favorite = createFavorite(FAVORITE_TYPE.USER, attendee);
                break;
            case ATTENDEE_TYPE.GROUP:
                favorite = createFavorite(FAVORITE_TYPE.GROUP, attendee);
                break;
            case ATTENDEE_TYPE.EXTERNAL_EMAIL:
                favorite = createFavorite(FAVORITE_TYPE.EXTERNAL_EMAIL, {email: attendee.email});
                break;
            default:
                return;
        }

        if (favorited(attendee)) {
            props.onRemoveFavorite(favorite, identity.username);
        } else {
            props.onAddFavorite(favorite, identity.username);
        }
    };

    const attendeeSearchBarLabelId = "attendeeSearchBarLabel";
    const attendeeActionBarId = "attendeeActionBar";

    // Remove meridian action bar background color
    useEffect(() => {
        let attendeeActionBar = document.getElementById(attendeeActionBarId);

        if (attendeeActionBar) {
            attendeeActionBar.parentElement.style.backgroundColor = "white";
        }
    }, []);

    return (
        <Row minHeight={ATTENDEE_LIST_MIN_HEIGHT} alignmentVertical="top">
            <Table
                headerRows={1}
                showDividers={false}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onSort={onSort}
                spacing="small"
            >
                <Column spacingInset="small none" backgroundColor="white">
                    <TableActionBar>
                        <Row
                            alignmentVertical="bottom"
                            alignmentHorizontal="right"
                            widths={["fill", "fit"]}
                            width="100%"
                            wrap="down"
                            id={attendeeActionBarId}
                        >
                            <Row width="100%" wrap="down" widths={workflow === WORKFLOWS.LIVESTREAM.NAME ? ["fit", "fill"] : ["fill"]}>
                                {workflow === WORKFLOWS.LIVESTREAM.NAME &&
                                    <Column spacing="xxsmall" width="150px">
                                        {screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW &&
                                            <Text type="b200">Attendee role</Text>
                                        }
                                        <Select
                                            value={props.selectedAttendeeRole}
                                            onChange={props.setSelectedAttendeeRole}
                                            placeholder="Select attendee role"
                                        >
                                            {Object.keys(ATTENDEE_ROLE.LIVESTREAM).map((option) => (
                                                <SelectOption key={option} value={ATTENDEE_ROLE.LIVESTREAM[option]} label={ATTENDEE_ROLE.LIVESTREAM[option]} />
                                            ))}
                                        </Select>
                                    </Column>
                                }
                                <Column spacing="xxsmall" width="100%" minWidth={ATTENDEE_SEARCH_BAR_MIN_WIDTH}>
                                    {screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW &&
                                        <Text type="b200" id={attendeeSearchBarLabelId}>Add attendees</Text>
                                    }
                                    <AttendeeSearchBar
                                        workflow={props.workflow}
                                        selectedAttendeeRole={props.selectedAttendeeRole}
                                        aria-labelledby={attendeeSearchBarLabelId}
                                        peopleSuggestions={peopleSuggestions}
                                        groupSuggestions={(workflow === WORKFLOWS.LIVESTREAM.NAME && props.selectedAttendeeRole === ATTENDEE_ROLE.LIVESTREAM.PRESENTER) ?
                                            undefined // do not show suggestions for groups if searching for livestream presenters since groups cannot be a presenter
                                            :
                                            groupSuggestions
                                        }
                                        width="100%"
                                        popoverHeight={ATTENDEE_SEARCH_BAR_DEFAULT_POPOVER_HEIGHT}
                                        onAddAttendee={(attendee) => props.onAddAttendee(attendee)}
                                        onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                                        onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                                        onGetGroupSuggestions={props.onGetGroupSuggestions}
                                        onClearGroupSuggestions={props.onClearGroupSuggestions}
                                    />
                                </Column>
                            </Row>
                            {screenSizeBreakpoint <= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR &&
                                <FavoritesModal
                                    identity={identity}
                                    attendees={attendees}
                                    rooms={rooms}
                                    locations={locations}
                                    favorites={favorites}
                                    onAddAttendee={props.onAddAttendee}
                                    onGetRASDataForPerson={props.onGetRASDataForPerson}
                                    onAddRoomByEmail={props.onAddRoomByEmail}
                                    onAddLocation={props.onAddLocation}
                                    onRemoveFavorite={props.onRemoveFavorite}
                                />
                            }
                        </Row>
                        {selectedAttendees.length > 0 &&
                            <TableActionBarOverlay
                                label={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ?
                                    `${selectedAttendees.length} selected` : null}
                                onClose={() => unselectSelected()}
                            >
                                {screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW &&
                                    <Button
                                        type="link"
                                        onClick={() => onChangeSelectedPriority(ATTENDEE_PRIORITY.ESSENTIAL)}
                                    >
                                        <Row spacing="xsmall">
                                            {renderPriorityIcon(ATTENDEE_PRIORITY.ESSENTIAL, true)}
                                            <Row>{ATTENDEE_PRIORITY.ESSENTIAL}</Row>
                                        </Row>
                                    </Button>
                                }
                                <Button
                                    type="link"
                                    onClick={() => onChangeSelectedPriority(ATTENDEE_PRIORITY.REQUIRED)}
                                >
                                    <Row spacing="xsmall">
                                        {screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                                        renderPriorityIcon(ATTENDEE_PRIORITY.REQUIRED, true)
                                        }
                                        <Row>{ATTENDEE_PRIORITY.REQUIRED}</Row>
                                    </Row>
                                </Button>
                                <Button
                                    type="link"
                                    onClick={() => onChangeSelectedPriority(ATTENDEE_PRIORITY.OPTIONAL)}
                                >
                                    <Row spacing="xsmall">
                                        {screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                                        renderPriorityIcon(ATTENDEE_PRIORITY.OPTIONAL, true)
                                        }
                                        <Row>{ATTENDEE_PRIORITY.OPTIONAL}</Row>
                                    </Row>
                                </Button>
                                <Button
                                    type="link"
                                    onClick={() => onRemoveSelected()}
                                >
                                    <Row spacing="xsmall">
                                        <Icon tokens={trashTokens} />
                                        {screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                                        <Row>Remove</Row>
                                        }
                                    </Row>
                                </Button>
                            </TableActionBarOverlay>
                        }
                    </TableActionBar>
                </Column>
                <TableRow>
                    <TableCell width="44px">
                        <label
                            htmlFor="attendee-header-checkbox"
                            aria-label="All attendees"
                        />
                        <Checkbox
                            checked={checkedAttendeesCount > 0}
                            onChange={onChangeAttendeeHeader}
                            iconTokens={attendeeHeaderCheckboxIcon}
                            id="attendee-header-checkbox"
                        />
                    </TableCell>
                    <TableCell sortColumn={ATTENDEE_SORT_BY.ATTENDEE}>
                        Attendees ({attendees.length})
                    </TableCell>
                    <TableCell width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? ((workflow === WORKFLOWS.LIVESTREAM.NAME || flowType === FLOW_TYPE.UPDATE) ? "15%" : "30%") : "10%"} />
                    {workflow === WORKFLOWS.LIVESTREAM.NAME &&
                        <TableCell
                            width="120px"
                            sortColumn={ATTENDEE_SORT_BY.ROLE}
                            alignmentHorizontal={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "left" : "right"}
                        >
                            Role
                        </TableCell>
                    }
                    {flowType === FLOW_TYPE.UPDATE && screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                        <TableCell width="140px" sortColumn={ATTENDEE_SORT_BY.STATUS}>Status</TableCell>
                    }
                    <TableCell
                        width="120px"
                        sortColumn={ATTENDEE_SORT_BY.PRIORITY}
                        alignmentHorizontal={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "left" : "right"}
                    >
                        Priority
                    </TableCell>
                    {workflow === WORKFLOWS.LIVESTREAM.NAME &&
                        <TableCell width="120px" alignmentHorizontal={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "left" : "right"}>
                            Features
                        </TableCell>
                    }
                    {screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                        <TableCell width="50px" />
                    }
                </TableRow>
                {sortAttendees(attendees, sortColumn, sortDirection).map((attendee) => (
                    <TableRow
                        highlight={true}
                        key={attendee.identifier}
                    >
                        <TableCell>
                            <label
                                htmlFor={`${attendee.identifier}-checkbox`}
                                aria-label={attendee.name}
                            />
                            <Checkbox
                                checked={selectedAttendees.includes(attendee.identifier)}
                                onChange={() => onChangeAttendeeRow(attendee.identifier)}
                                id={`${attendee.identifier}-checkbox`}
                            />
                        </TableCell>
                        <TableCell>
                            <Row spacing="xsmall">
                                {attendee.email === undefined &&
                                    <Loader size="small" />
                                }
                                <Link
                                    onClick={() => onClickAttendee(attendee)}
                                    ref={(ref) => popoverRefs.current[attendee.identifier] = ref}
                                    type="secondary"
                                >
                                    <Row>
                                        {attendee.type === ATTENDEE_TYPE.PERSON && renderFavoriteTypeIcon(FAVORITE_TYPE.USER)}
                                        {attendee.type === ATTENDEE_TYPE.GROUP && renderFavoriteTypeIcon(FAVORITE_TYPE.GROUP)}
                                        {attendee.type === ATTENDEE_TYPE.EXTERNAL_EMAIL && renderFavoriteTypeIcon(FAVORITE_TYPE.EXTERNAL_EMAIL)}
                                        {" " + attendee.identifier + " "}
                                    </Row>
                                </Link>
                            </Row>
                            <AttendeePopover
                                attendee={attendee}
                                attendeeList={attendeeList}
                                anchorNode={popoverRefs.current[attendee.identifier]}
                                open={openAttendeePopover === attendee.identifier}
                                position={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "right" : "bottom"}
                                onClose={onClosePopover}
                                onAddAttendee={props.onAddAttendee}
                                onRemoveAttendeeByAlias={onRemoveAttendeeByAlias}
                                onGetRASDataForPerson={props.onGetRASDataForPerson}
                                isFavorited={favorited}
                                onFavoriteClick={onFavoriteClick}
                            />
                        </TableCell>
                        <TableCell>
                            <Row>
                                {renderSetFavoriteIcon(favorited(attendee), onFavoriteClick, attendee, identity.username === attendee.alias)}
                            </Row>
                        </TableCell>
                        {workflow === WORKFLOWS.LIVESTREAM.NAME &&
                            <TableCell>
                                <ConditionalWrapper
                                    condition={attendee.type === ATTENDEE_TYPE.GROUP}
                                    wrapper={(children) =>
                                        <Tooltip position="right" title="Group role cannot be changed">
                                            {children}
                                        </Tooltip>
                                    }
                                >
                                    <Link
                                        onClick={() => onClickRole(attendee)}
                                        ref={(ref) => roleRefs.current[attendee.identifier] = ref}
                                        type="secondary"
                                        disabled={attendee.type === ATTENDEE_TYPE.GROUP}
                                    >
                                        {attendee.role}
                                    </Link>
                                </ConditionalWrapper>
                                <Menu
                                    anchorNode={roleRefs.current[attendee.identifier]}
                                    open={openRoleMenu === attendee.identifier}
                                    position="bottom"
                                    onClose={onCloseRole}
                                >
                                    {attendee.isOrganizer &&
                                        <MenuItem onClick={(value) => onChangeAttendeeRole(attendee, ATTENDEE_ROLE.ORGANIZER)}>
                                            {ATTENDEE_ROLE.ORGANIZER}
                                        </MenuItem>
                                    }
                                    {Object.keys(ATTENDEE_ROLE.LIVESTREAM).map((role) => (
                                        <MenuItem key={role} onClick={(value) => onChangeAttendeeRole(attendee, ATTENDEE_ROLE.LIVESTREAM[role])}>
                                            {ATTENDEE_ROLE.LIVESTREAM[role]}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </TableCell>
                        }
                        {flowType === FLOW_TYPE.UPDATE && screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                            <TableCell>
                                <Row spacing="xsmall" alignmentVertical="top">
                                    {getMeetingResponseIcon(attendee.status)}
                                    <Text>
                                        {formatExchangeResponse(attendee.status)}
                                    </Text>
                                </Row>
                            </TableCell>
                        }
                        <TableCell>
                            <Row
                                spacing="xsmall"
                                alignmentHorizontal={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "left" : "right"}
                            >
                                {renderPriorityIcon(attendee.priority)}
                                <Link
                                    onClick={() => onClickPriority(attendee)}
                                    ref={(ref) => priorityRefs.current[attendee.identifier] = ref}
                                    type="secondary"
                                >
                                    {attendee.priority}
                                </Link>
                            </Row>
                            <Menu
                                anchorNode={priorityRefs.current[attendee.identifier]}
                                open={openPriorityMenu === attendee.identifier}
                                position="bottom"
                                onClose={onClosePriority}
                            >
                                <MenuItem onClick={() => onChangeAttendeePriority(attendee, ATTENDEE_PRIORITY.ESSENTIAL)}>
                                    <Row spacing="xsmall">
                                        {renderPriorityIcon(ATTENDEE_PRIORITY.ESSENTIAL)}&nbsp;{ATTENDEE_PRIORITY.ESSENTIAL}
                                    </Row>
                                </MenuItem>
                                <MenuItem onClick={() => onChangeAttendeePriority(attendee, ATTENDEE_PRIORITY.REQUIRED)}>
                                    <Row spacing="xsmall">
                                        {renderPriorityIcon(ATTENDEE_PRIORITY.REQUIRED)}&nbsp;{ATTENDEE_PRIORITY.REQUIRED}
                                    </Row>
                                </MenuItem>
                                <MenuItem onClick={() => onChangeAttendeePriority(attendee, ATTENDEE_PRIORITY.OPTIONAL)}>
                                    <Row spacing="xsmall">
                                        {renderPriorityIcon(ATTENDEE_PRIORITY.OPTIONAL)}&nbsp;{ATTENDEE_PRIORITY.OPTIONAL}
                                    </Row>
                                </MenuItem>
                            </Menu>
                        </TableCell>
                        {workflow === WORKFLOWS.LIVESTREAM.NAME &&
                            <TableCell height={ATTENDEE_LIST_CELL_HEIGHT}>
                                {renderLivestreamFeatures(
                                    attendee,
                                    "None",
                                    "b300",
                                    screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "left" : "right")
                                }
                            </TableCell>
                        }
                        {screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                            <TableCell alignmentHorizontal="right">
                                <Row height="16px">
                                    <Button
                                        onClick={() => onClickRemoveAttendee(attendee)}
                                        type="link"
                                        disabled={attendee.status === ATTENDEE_RESPONSE.ORGANIZER}
                                        label={`Remove attendee ${attendee.name}`}
                                        size="small"
                                    >
                                        <Icon tokens={trashTokens} />
                                    </Button>
                                </Row>
                            </TableCell>
                        }
                    </TableRow>
                ))}
            </Table>
        </Row>
    );
};

export default AttendeeList;
