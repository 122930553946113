import * as Actions from "../actions/action-types";

const initialState = {
    userFeedback: "",
    userRating: undefined,
    isEligible: false,
    feedbackAlertMessage: "",
};

function feedbackReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.UPDATE_USER_FEEDBACK:
            return {
                ...state,
                userFeedback: action.userFeedback
            };
        case Actions.UPDATE_USER_RATING:
            return {
                ...state,
                userRating: action.userRating
            };
        case Actions.GET_FEEDBACK_ELIGIBILITY:
            return {
                ...state,
            };
        case Actions.SET_IS_ELIGIBLE:
            return {
                ...state,
                isEligible: action.isEligible
            };
        case Actions.SET_FEEDBACK_ALERT_MESSAGE:
            return {
                ...state,
                feedbackAlertMessage: action.feedbackAlertMessage,
            };
        case Actions.CLEAR_FEEDBACK:
            return initialState;
        default:
            return state;
    }
}

export default feedbackReducer;