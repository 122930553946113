import React from "react"
import PropTypes from "prop-types"
import inputTokens from "@amzn/meridian-tokens/component/input"
import SearchButton from "./search-button"
import InputClearButton from "../_input-clear-button"
import Row from "../row"
import refObjectPropType from "../../_prop-types/ref-object"

class SearchFieldSuffix extends React.Component {
  static propTypes = {
    clearButton: PropTypes.bool.isRequired,
    searchButton: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    clearButtonLabel: PropTypes.string,
    /**
     * This disables interaction with the component and applies special visual
     * styles to indicate that it's not interactive.
     */
    disabled: PropTypes.bool,
    inputRef: refObjectPropType,
    searchButtonLabel: PropTypes.string,
    value: PropTypes.any,
  }

  render() {
    const props = this.props
    // HACK: Shrink the radius by 1 so the button radius fits inside the input
    // radius
    const borderRadiusRight = inputTokens(props.t.tokens).inputBorderRadius - 1
    const clearButton = props.clearButton ? (
      <InputClearButton
        hidden={!props.value}
        disabled={props.disabled}
        borderRadiusRight={props.searchButton ? 0 : borderRadiusRight}
        onClick={() => {
          props.onClear()
          props.onChange("")
          if (props.inputRef.current) {
            props.inputRef.current.focus({ preventScroll: true })
          }
        }}
        label={props.clearButtonLabel}
      />
    ) : null
    const searchButton = props.searchButton ? (
      <SearchButton
        active={Boolean(props.value)}
        disabled={props.disabled}
        borderRadiusRight={borderRadiusRight}
        onClick={() => props.onSubmit(props.value)}
      >
        {props.searchButtonLabel}
      </SearchButton>
    ) : null
    // If we're rendering both buttons put them in a row, otherwise just return
    // the one button (or no buttons).
    return searchButton && clearButton ? (
      <Row spacing="none">
        {clearButton}
        {searchButton}
      </Row>
    ) : (
      searchButton || clearButton
    )
  }
}

export default SearchFieldSuffix
