// cookie used for local development, leave empty for origin/dev
const localCookie = "";

export const getJwtIdTokens = (userPoolAppId) => {
    let cookies = document.cookie.split("; ");
    const idTokenRegex = RegExp(`CognitoIdentityServiceProvider.${userPoolAppId}.\\S+.idToken=`, "g");
    const accessTokenRegex = RegExp(`CognitoIdentityServiceProvider.${userPoolAppId}.\\S+.accessToken=`, "g");
    const refreshTokenRegex = RegExp(`CognitoIdentityServiceProvider.${userPoolAppId}.\\S+.refreshToken=`, "g");

    let idToken = getCookie(cookies, idTokenRegex);
    let accessToken = getCookie(cookies, accessTokenRegex);
    let refreshToken = getCookie(cookies, refreshTokenRegex);

    if (!idToken && !accessToken && !refreshToken) {
        cookies = localCookie.replace("cookie: ", "").split("; ");

        idToken = getCookie(cookies, idTokenRegex);
        accessToken = getCookie(cookies, accessTokenRegex);
        refreshToken = getCookie(cookies, refreshTokenRegex);
    }

    return {
        idToken,
        accessToken,
        refreshToken
    };
};

const getCookie = (cookies, regex) => {
    return cookies.reduce((match, cookie) => {
        const matches = cookie.match(regex);
        return match || (matches ? cookie.replace(matches[0], "") : null);
    }, "");
};