import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { zonedTimeToUtc } from "date-fns-tz";

import * as LocationActions from "../../shared/locations/actions";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Responsive from "@amzn/meridian/responsive";
import Row from "@amzn/meridian/row";
import Expander from "@amzn/meridian/expander"
import Input from "@amzn/meridian/input"
import Box from "@amzn/meridian/box";
import Checkbox from "@amzn/meridian/checkbox";
import Text from "@amzn/meridian/text";
import Toggle from "@amzn/meridian/toggle";

import TimezonePicker from "../../shared/components/timezone-search-bar";
import StartTimePicker from "../components/start-time-picker";
import LocationSearchBar from "../../shared/locations/components/location-search-bar";
import SimpleDurationSelect from "../../shared/components/simple-duration-select";

import { generateComponentId } from "../../shared/shared-utils";
import { getFormattedDateTime } from "../../shared/time-utils";
import { pad } from "../utils";
import {
    renderLocationFeatureIcon
} from "../../shared/locations/locations-utils";
import { updateQueryParameters } from "../../shared/permalink/permalink-utils";

import {
    DEFAULT_FEATURE_ICON_SIZE,
    LOCATION_FEATURE
} from "../../shared/locations/locations-constants";
import { ARIA_LABELS, ERRORS } from "../../shared/locations/locations-constants";
import { ALL, MAX_SEATS } from "../../shared/shared-constants";
import { postRoomBookingMetric } from "../../shared/metrics/actions";

const RoomFinderFormDetails = (props) => {
    const dispatch = useDispatch();
    
    const buildingList = props.buildingList;
    const refresh = props.onRefresh;
    const selectedBuilding = props.selectedBuilding;
    const [selectedFloor, setSelectedFloor] = [props.selectedFloor, props.setSelectedFloor];
    const [startTime, setStartTime] = [props.startTime, props.setStartTime];
    const [startTimeStamp, setStartTimeStamp] = [props.startTimeStamp, props.setStartTimeStamp];
    const setStartTimeStampToShow = props.setStartTimeStampToShow;
    const [timezoneValue, setTimezoneValue] = [props.timezoneValue, props.setTimezoneValue];
    const [selectedDuration, setSelectedDuration] = [props.selectedDuration, props.setSelectedDuration];
    const [minimalSeats, setMinimalSeats] = [props.minimalSeats, props.setMinimalSeats];
    const [cameraChecked, setCameraChecked] = [props.cameraChecked, props.setCameraChecked];
    const [displayChecked, setDisplayChecked] = [props.displayChecked, props.setDisplayChecked];
    const [noRestrictChecked, setNoRestrictChecked] = [props.noRestrictChecked, props.setNoRestrictChecked];
    const [noManagedChecked, setNoManagedChecked] = [props.noManagedChecked, props.setNoManagedChecked];
    const [availableOnlyChecked, setAvailableOnlyChecked] = [props.availableOnlyChecked, props.setAvailableOnlyChecked];
    const setSearchNearby = props.setSearchNearby;
    const setNearbyResults = props.setNearbyResults;
    const [queryParameters, setQueryParameters] = [props.queryParameters, props.setQueryParameters];
    const [open, setOpen] = useState();

    const onBuildingSelect = (selectedBuildingCode) => {
        if (selectedBuildingCode !== selectedBuilding) {
            props.setSelectedBuilding(selectedBuildingCode);
            if (selectedBuildingCode === ERRORS.INVALID_BUILDING) {
                setSelectedFloor(ERRORS.INVALID_FLOOR);
                return;
            }
            let buildingInfo = props.buildingList.find((building) => building.value === selectedBuildingCode);
            if (!buildingInfo.floorList) {
                dispatch(LocationActions.loadFloors(selectedBuildingCode));
                setSelectedFloor(undefined);
            } else if (buildingInfo.floorList.length === 0) {
                setSelectedFloor(undefined);
            } else {
                setSelectedFloor(ALL);
                refresh(selectedBuildingCode, ALL, undefined, undefined, undefined);
            }
        }
    };

    const onFloorSelect = (floor) => {
        setSelectedFloor(floor);
        if (floor === ERRORS.INVALID_FLOOR) {
            return;
        }
        refresh(undefined, floor, undefined, undefined, undefined);
    };

    const onStartTimeSelect = (startTime) => {
        setStartTime(startTime);
        refresh(undefined, undefined, startTime, undefined, undefined);
    };

    const onStartTimeStampSelect = (startTimeStamp) => {
        let hour = parseInt(startTimeStamp.split(":")[0]) % 24;
        setStartTimeStamp(`${pad(hour, 2)}:00:00`);
        setStartTimeStampToShow(`${pad(hour, 2)}:00:00`);
    };

    const onTimezoneSelect = (timezone) => {
        const selectedEpoch = zonedTimeToUtc(`${props.startTime} ${props.startTimeStamp}`, props.timezoneValue).getTime();
        const newStartTime = getFormattedDateTime(selectedEpoch, timezone, "yyyy-MM-dd");
        const newStartTimeStamp = getFormattedDateTime(selectedEpoch, timezone, "HH") + ":00:00";

        setTimezoneValue(timezone);
        setStartTime(newStartTime);
        setStartTimeStamp(newStartTimeStamp);
        refresh(undefined, undefined, newStartTime, newStartTimeStamp, timezone);
    };

    const onDurationSelect = (duration) => {  
        dispatch(postRoomBookingMetric("new filters", "duration"));
        setSelectedDuration(duration);
    };

    const onSeatsChange = (value) => {
        dispatch(postRoomBookingMetric("new filters", "minimum seats"));
        setMinimalSeats(Math.min(value, MAX_SEATS));
    };

    const onCameraCheck = (cameraChecked) => {
        dispatch(postRoomBookingMetric("new filters", "camera"));
        setCameraChecked(cameraChecked);
        setDisplayChecked(cameraChecked);
    };

    const onDisplayCheck = (displayChecked) => {
        dispatch(postRoomBookingMetric("new filters", "display"));
        setDisplayChecked(displayChecked);
    };
    
    const onNoRestrictCheck = (noRestrictChecked) => {
        dispatch(postRoomBookingMetric("new filters", "no restricted"));
        setNoRestrictChecked(noRestrictChecked);        
    };

    const onNoManagedChecked = (noManagedChecked) => {
        dispatch(postRoomBookingMetric("new filters", "no hand-managed"));
        setNoManagedChecked(noManagedChecked);  
    };

    const onAvailableOnlyCheck = (availableOnlyChecked) => {
        dispatch(postRoomBookingMetric("new filters", "on available only"));
        setSearchNearby(false);
        setNearbyResults([]);
        setAvailableOnlyChecked(availableOnlyChecked);    
    };

    const onRefresh = () => {
        setSearchNearby(false);
        setNearbyResults([]);
        refresh();
    };

    const generateFloorOptions = () => {
        const buildingInfo = props.buildingList.find((building) => building.value === props.selectedBuilding);
        const floorOptions =  buildingInfo && buildingInfo.floorList ? buildingInfo.floorList.map((floor) => {
            return {
                value: floor,
                label: floor
            }
        }) : [];

        if (floorOptions?.length > 0) {
            floorOptions.unshift({value: ALL, label: ALL});
        }
        return floorOptions;
    };
    const floorOptions = generateFloorOptions();

    useEffect(() => {
        if (selectedFloor === undefined) {
            let buildingInfo = buildingList.find((building) => building.value === selectedBuilding);
            let floorList = buildingInfo && buildingInfo.floorList;
            if (floorList === ERRORS.INVALID_FLOOR_LIST) {
                setSelectedFloor(ERRORS.INVALID_FLOOR);
            } else if (floorList && !floorList.length) {
                setSelectedFloor(ERRORS.BUILDING_WITHOUT_ROOMS);
            } else if (floorList && floorList.length) {
                setSelectedFloor(ALL);
                refresh(selectedBuilding, ALL, undefined, undefined, undefined);
            }
        }
    }, [buildingList, selectedBuilding, refresh, setSelectedFloor, selectedFloor]);

    useEffect(() => {
        const parameters = {
            selectedBuilding: selectedBuilding,
            selectedFloor: selectedFloor,
            startTime: startTime,
            startTimeStamp: startTimeStamp,
            timezoneValue: timezoneValue,
            selectedDuration: selectedDuration,
            minimalSeats: minimalSeats,
            cameraChecked: cameraChecked,
            displayChecked: displayChecked,
            noRestrictChecked: noRestrictChecked,
            noManagedChecked: noManagedChecked,
            availableOnlyChecked: availableOnlyChecked,
        }
        updateQueryParameters(parameters, queryParameters, setQueryParameters);       
    }, [selectedBuilding, selectedFloor, startTime, startTimeStamp, timezoneValue, selectedDuration, minimalSeats, cameraChecked, displayChecked, noRestrictChecked, noManagedChecked, availableOnlyChecked, queryParameters, setQueryParameters]);

    return (
        <Responsive
            query="min-width"
            props={{
                formContainerWidth: {
                    default: 360,
                    "600px": 600,
                    "810px": 810,
                    "900px": 900,
                    "1020px": 1020,
                    "1110px": 1110,
                    "1200px": "100%",
                },
                formSpacing: {
                    default: "xsmall",
                    "810px": "small",
                    "900px": "medium",
                    "1020": "large"
                },
            }}
        >
            {(responsiveProps) =>
                <Column width={"100%"} height={"100%"} spacingInset={"medium xsmall"} spacing={"none"} type={"outline"} alignmentHorizontal={"center"}>
                    <Column alignmentHorizontal="left">
                        <Row alignmentHorizontal={"center"} alignmentVertical={"top"} wrap={"down"}>
                            <Column spacing={"none"} width={"200px"}>
                                <Text type="b200">Building</Text>
                                <LocationSearchBar
                                    isSearchable={true}
                                    isMultiSelect={false}
                                    onLocationSelect={onBuildingSelect}
                                    locationOptions={props.buildingList}
                                    location={props.selectedBuilding}
                                    width={"100%"}
                                    spacing={"small"}
                                    placeholder={"Select building"}
                                    ariaLabel={ARIA_LABELS.BUILDING}
                                />
                            </Column>
                            <Column spacing={"none"} width={"120px"}>
                                <Text type={"b200"}>Floor</Text>
                                <LocationSearchBar
                                    isSearchable={true}
                                    isMultiSelect={false}
                                    onLocationSelect={onFloorSelect}
                                    locationOptions={floorOptions}
                                    location={props.selectedFloor}
                                    width={"100%"}
                                    spacing={"small"}
                                    placeholder={"Select floor"}
                                    ariaLabel={props.selectedFloor === ERRORS.INVALID_FLOOR ?
                                        ARIA_LABELS.FLOOR
                                        :
                                        ARIA_LABELS.ROOM
                                    }
                                />
                            </Column>
                            <Column width="10px"/>
                            <Column spacing="none">
                                <Text type="b200">Start time</Text>
                                <StartTimePicker
                                    onStartTimeSelect={onStartTimeSelect}
                                    onStartTimeStampSelect={onStartTimeStampSelect}
                                    startTime={props.startTime}
                                    setStartTime={props.setStartTime}
                                    startTimeStamp={props.startTimeStamp}
                                    setStartTimeStamp={props.setStartTimeStamp}
                                    timeFormat={props.timeFormat}
                                    dateFormat={props.dateFormat}
                                    step={60}
                                />
                            </Column>
                            <Column spacing="none">
                                <Text type="b200">Timezone</Text>
                                <TimezonePicker
                                    timezones={props.timezones}
                                    onChange={onTimezoneSelect}
                                    value={timezoneValue}
                                    width={80}
                                    size={"small"}
                                />
                            </Column>
                            <Column spacing="none">
                                <Text type="b200">&nbsp;</Text>
                                <Toggle 
                                    id={generateComponentId("toggle", "show-available-rooms-only")} 
                                    size={"medium"}
                                    checked={availableOnlyChecked} 
                                    onChange={onAvailableOnlyCheck}>
                                    Show available rooms only
                                </Toggle>
                            </Column>
                            <Column spacing="none">
                                <Text type="b200">&nbsp;</Text>
                                <Button
                                    type={"tertiary"}
                                    size={"small"}
                                    onClick={onRefresh}
                                    id={generateComponentId('button', 'refresh')}
                                >
                                    Refresh
                                </Button>
                            </Column>
                        </Row>   
                        <Expander open={open} onChange={setOpen} title="More options" type="inline" />
                    </Column>
                    <Column alignmentHorizontal={"left"}>
                        {open && 
                            <Row alignmentHorizontal={"center"} alignmentVertical={"top"} wrap={"down"}>
                                <Column spacing={"none"} width={"200px"}>
                                    <SimpleDurationSelect
                                        duration={selectedDuration}
                                        setDuration={onDurationSelect}
                                        label={"Duration"}
                                        labelId={"duration-select-label-id"}
                                        textSize={"b200"}
                                        width={"200px"}
                                    />
                                </Column>
                                <Column spacing="none">
                                    <Text  type="b200" alignment="left" tag="label">
                                        Minimum seats
                                    </Text>
                                    <Row width="120px">
                                        <Input
                                            value={minimalSeats}
                                            onChange={onSeatsChange}
                                            id={"minimum-seats-input"}
                                            placeholder={"Enter value..."}
                                            type={"number"}
                                            pattern={/^[0-9]*$/}
                                            size={"small"}
                                        />
                                    </Row>
                                </Column>
                                <Column width="10px"/>
                                <Column spacing={"none"} minWidth={"225px"}>
                                    <fieldset style={{border: 0, margin: 0, padding: 0}}>
                                        <legend>
                                            <Text type="b200" alignment="left">Features</Text>
                                        </legend>
                                        <Box type="outline" spacingInset="small">
                                            <Row widths={["fill", "fit"]} spacingInset={"xsmall"}>
                                                <Checkbox checked={cameraChecked} onChange={onCameraCheck}>
                                                    <Text type="b200" alignment="left">Camera</Text>
                                                </Checkbox>
                                                {renderLocationFeatureIcon(LOCATION_FEATURE.CAMERA, DEFAULT_FEATURE_ICON_SIZE, false)}
                                            </Row>
                                            <Row widths={["fill", "fit"]} spacingInset={"xsmall"}>
                                                <Checkbox checked={displayChecked} onChange={onDisplayCheck} disabled={cameraChecked}>
                                                    <Text type="b200" alignment="left">Display</Text>
                                                </Checkbox>
                                                {renderLocationFeatureIcon(LOCATION_FEATURE.DISPLAY, DEFAULT_FEATURE_ICON_SIZE, false)}
                                            </Row>
                                        </Box>
                                    </fieldset>
                                </Column>
                                <Column spacing={"none"} minWidth={"225px"}>
                                    <fieldset style={{border: 0, margin: 0, padding: 0}}>
                                        <legend>
                                            <Text type="b200" alignment="left">Permissions</Text>
                                        </legend>
                                        <Box type="outline" spacingInset="small">
                                            <Row widths={["fill", "fit"]} spacingInset={"xsmall"}>
                                                <Checkbox checked={noRestrictChecked} onChange={onNoRestrictCheck}>
                                                    <Text type="b200" alignment="left">No restricted</Text>
                                                </Checkbox>
                                                {renderLocationFeatureIcon(LOCATION_FEATURE.RESTRICTED, DEFAULT_FEATURE_ICON_SIZE, false)}
                                            </Row>
                                            <Row widths={["fill", "fit"]} spacingInset={"xsmall"}>
                                                <Checkbox checked={noManagedChecked} onChange={onNoManagedChecked}>
                                                    <Text type="b200" alignment="left">No hand-managed</Text>
                                                </Checkbox>
                                                {renderLocationFeatureIcon(LOCATION_FEATURE.HAND_MANAGED, DEFAULT_FEATURE_ICON_SIZE, false)}
                                            </Row>
                                        </Box>
                                    </fieldset>
                                </Column>
                                <Column width="284px"/>
                            </Row> 
                        }
                    </Column>
                </Column>
            }
        </Responsive>
    );
};

export default RoomFinderFormDetails;