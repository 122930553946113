import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createHashHistory, createBrowserHistory } from "history";

import identityReducer from "../features/identity/reducers";
import meetingReducer from "../features/meeting-scheduler/reducers";
import ootoReducer from "../features/meeting-ooto/reducers";
import livestreamReducer from "../features/meeting-livestream/reducers";
import calendarReducer from "../features/calendar/reducers";
import peopleReducer from "../features/people/reducers";
import locationsReducer from "../features/shared/locations/reducers";
import sharedReducer from "../features/shared/reducers";
import timezonesReducer from "../features/shared/timezones/reducers";
import toastReducer from "../features/shared/toasts/reducers";
import suggestionReducer from "../features/shared/reducers/suggestions-reducer";
import chimeReducer from "../features/shared/chime/reducers";
import quickMeetingReducer from "../features/meeting-quick/reducers";
import favoritesReducer from "../features/shared/favorites/reducers";
import workflowReducer from "../features/shared/workflow/reducers";
import timeGridReducer from "../features/shared/custom-calendar-time-grid/reducers";
import exchangePreferencesReducer from "../features/shared/exchange-preferences/reducers";
import feedbackReducer from "../features/shared/feedback/reducers";
import checkInReducer from "../features/shared/check-in/reducers";
import settingsReducer from "../features/shared/settings/reducers";
import pollsReducer from "../features/meeting-polls/reducers";

import createSagaMiddleware from "redux-saga";
import sagas from "../sagas";
import eventIntakeFormReducer from "../features/meeting-livestream/reducers/event-intake-form";

export const history = createBrowserHistory(); //createHashHistory(); //

const getReducers = () => combineReducers({
    router: connectRouter(history),
    identity: identityReducer,
    meeting: meetingReducer,
    ooto: ootoReducer,
    livestream: livestreamReducer,
    calendar: calendarReducer,
    people: peopleReducer,
    locations: locationsReducer,
    shared: sharedReducer,
    timezones: timezonesReducer,
    toast: toastReducer,
    suggestions: suggestionReducer,
    chime: chimeReducer,
    quickMeeting: quickMeetingReducer,
    favorites: favoritesReducer,
    workflow: workflowReducer,
    timeGrid: timeGridReducer,
    exchangePreferences: exchangePreferencesReducer,
    feedback: feedbackReducer,
    checkIn: checkInReducer,
    settings: settingsReducer,
    polls: pollsReducer,
    eventIntakeForm: eventIntakeFormReducer
});

const reducerStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const historyMiddleware = routerMiddleware(history);
    // const store = createStore(
    //     getReducers(),
    //     applyMiddleware(sagaMiddleware, historyMiddleware)
    // );
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(getReducers(), composeEnhancers(
        applyMiddleware(sagaMiddleware, historyMiddleware)
    ));
    sagaMiddleware.run(sagas);

    return store;
};

export default reducerStore;
