import React from "react";

import Row from "@amzn/meridian/row";
import Pagination from "@amzn/meridian/pagination";

const PollDashboardPagination = (props) => {
    const pollsPerPage = props.pollsPerPage;
    const totalPolls = props.totalPolls;
    const [currentPage, setCurrentPage] = [props.currentPage, props.setCurrentPage];
    const pageNumbers = Math.ceil(totalPolls / pollsPerPage);

    return (
        <Row alignmentHorizontal="right" >
            <Pagination
                currentPage={currentPage}
                numberOfPages={pageNumbers}
                onChange={setCurrentPage}
            />
        </Row>
    );
};

export default PollDashboardPagination;