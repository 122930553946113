import React, { useEffect, useRef, useState } from "react";

import Link from "@amzn/meridian/link";
import Row from "@amzn/meridian/row";
import Tag from "@amzn/meridian/tag";

import { renderSetFavoriteIcon } from "../../shared/favorites/favorites-utils";
import { renderPriorityIcon } from "../people-utils";
import Menu, { MenuItem } from "@amzn/meridian/menu";
import { ATTENDEE_PRIORITY } from "../people-constants";

const AttendeeTag = (props) => {
    // Priority
    const priorityRefs = useRef([]);
    const [openPriorityMenu, setOpenPriorityMenu] = useState("");
    const onClickPriority = (attendee) => setOpenPriorityMenu(attendee.identifier);
    const onClosePriority = () => setOpenPriorityMenu("");

    const attendee = props.attendee;
    const attendeePopoverRefs = props.attendeePopoverRefs;
    const allowViewAttendeeDetails = props.allowViewAttendeeDetails;
    const allowChangeAttendeePriority = props.allowChangeAttendeePriority;

    const viewAttendeeDetails = props.viewAttendeeDetails;
    const removeAttendee = props.removeAttendee;
    const allowOrganizerRemoval = props.allowOrganizerRemoval;

    // Remove "X" button from organizer's tag
    useEffect(() => {
        if (attendee.isOrganizer && !allowOrganizerRemoval) {
            let organizerTags = Array.from(document.getElementsByClassName("organizerTag"));

            if (organizerTags.length) {
                organizerTags.forEach((tag) => {
                    tag.parentElement.parentElement.children[1].style.display = "none";
                    tag.style.padding = !allowChangeAttendeePriority ? "0px 12px 0px 4px" : "0px 12px 0px 0px";
                })
            }
        }
    }, [attendee, allowOrganizerRemoval, allowChangeAttendeePriority]);

    // Fix priority icon padding if visible
    useEffect(() => {
        if (allowChangeAttendeePriority) {
            let attendeeTags = Array.from(document.getElementsByClassName("attendeeTag"));

            if (attendeeTags.length) {
                attendeeTags.forEach((tag) => {
                    tag.parentElement.parentElement.style.paddingLeft = "2px";
                })
            }
        }
    }, [attendee, allowChangeAttendeePriority]);

    const updateAttendeePriority = (attendee, priority) => {
        if (attendee.priority !== priority) {
            attendee.priority = priority;
            props.onUpdateAttendee(attendee);
        }
    };

    const onChangeAttendeePriority = (attendee, priority) => {
        // Update the attendee's priority
        updateAttendeePriority(attendee, priority);

        // Hide the priority option menu
        setOpenPriorityMenu("");
    };

    const renderIcon = (attendee) => {
        if (allowChangeAttendeePriority) {
            // Filter to match hex code #077398
            // Computed through https://isotropic.co/tool/hex-color-to-css-filter/
            let priorityIconFilter = "invert(26%) sepia(93%) saturate(1722%) hue-rotate(173deg) brightness(90%) contrast(94%)";

            return (
                <div style={{paddingTop: "4px", paddingRight: "2px"}}>
                    <Link
                        onClick={() => onClickPriority(attendee)}
                        ref={(ref) => priorityRefs.current[attendee.identifier] = ref}
                        type="secondary"
                    >
                        {renderPriorityIcon(attendee.priority, undefined, undefined, priorityIconFilter)}
                    </Link>
                    <Menu
                        anchorNode={priorityRefs.current[attendee.identifier]}
                        open={openPriorityMenu === attendee.identifier}
                        position="bottom"
                        onClose={onClosePriority}
                    >
                        <MenuItem onClick={() => onChangeAttendeePriority(attendee, ATTENDEE_PRIORITY.REQUIRED)}>
                            <Row spacing="xsmall">
                                {renderPriorityIcon(ATTENDEE_PRIORITY.REQUIRED)}&nbsp;{ATTENDEE_PRIORITY.REQUIRED}
                            </Row>
                        </MenuItem>
                        <MenuItem onClick={() => onChangeAttendeePriority(attendee, ATTENDEE_PRIORITY.OPTIONAL)}>
                            <Row spacing="xsmall">
                                {renderPriorityIcon(ATTENDEE_PRIORITY.OPTIONAL)}&nbsp;{ATTENDEE_PRIORITY.OPTIONAL}
                            </Row>
                        </MenuItem>
                    </Menu>
                </div>
            );
        }

        return !attendee.isOrganizer && props.onFavoriteClick && renderSetFavoriteIcon(
            props.isFavorited, props.onFavoriteClick, attendee, attendee.isOrganizer
        );
    }

    return (
        <Tag
            name={attendee}
            type={attendee.isOrganizer ? "neutral" : "theme"}
            closeLabel={attendee.isOrganizer ? "Organizer cannot be removed" : "remove attendee " + (attendee.name || attendee.email)}
            onClose={() => {removeAttendee(attendee)}}
        >
            <div className={attendee.isOrganizer ? "organizerTag attendeeTag" : "attendeeTag"}>
                {allowViewAttendeeDetails ?
                    <Row spacing="xsmall">
                        {renderIcon(attendee)}
                        <div aria-label={attendee.isOrganizer ? "View organizer details" : "View attendee details"}>
                            <Link
                                type="secondary"
                                onClick={() => viewAttendeeDetails(attendee)}
                                ref={(ref) => attendeePopoverRefs.current[attendee.identifier] = ref}
                            >
                                {attendee.name || attendee.email}
                            </Link>
                        </div>
                    </Row>
                    :
                    <Row spacing="xsmall">
                        {renderIcon(attendee)}
                        <div>
                            {attendee.name || attendee.email}
                        </div>
                    </Row>
                }
            </div>
        </Tag>
    );
};

export default AttendeeTag;