import React from "react";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";

import chevronLeftSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";

const ArrowDateWrapper = (props) => {
    return (
        <React.Fragment>
            {props.noExtraSpace ? <span/> : <span width="40px">&nbsp;</span>}
            <span />
            <Row spacing={props.rowSpacing || "small"}>
                <Button
                    type="icon"
                    onClick={props.setPreviousDayDate}
                    label={props.previousDateLabel || "Navigate to previous day"}
                    disabled={props.isLoading}
                    size={props.buttonSize || "medium"}
                >
                    <Icon tokens={chevronLeftSmallTokens} />
                </Button>
                {props.children}
                <Button
                    type="icon"
                    onClick={props.setNextDayDate}
                    disabled={props.isLoading}
                    label={props.nextDateLabel || "Navigate to next day"}
                    size={props.buttonSize || "medium"}
                >
                    <Icon tokens={chevronRightSmallTokens} />
                </Button>
            </Row>
            <span />
        </React.Fragment>
    );
}

export default ArrowDateWrapper;