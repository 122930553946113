import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import noop from "lodash/noop"
import format from "date-fns/format"
import calendarIconTokens from "@amzn/meridian-tokens/base/icon/calendar"
import errorIconTokens from "@amzn/meridian-tokens/base/icon/alert-error-small"
import datePickerTokens from "@amzn/meridian-tokens/component/date-picker"
import inputTokens from "@amzn/meridian-tokens/component/input"
import Input from "../input"
import InputMask from "../input/mask"
import { Style } from "../theme"
import PopoverController from "../_popover-controller"
import Focus from "../_focus"
import PopoverBody from "../_popover-body"
import InputClearButton from "../_input-clear-button"
import inputBoxContext from "../_input-box/context"
import InputMonospacePlaceholder from "../_input-monospace-placeholder"
import { getTokenHelper, memoizeTokenStyles } from "../../_utils/token"
import { parseDateString, toDateString } from "../../_utils/date-string"
import dateLocales, { getDateLocale } from "../../_utils/date-locales"

const styles = memoizeTokenStyles(
  (t, { focus, empty }) =>
    css({
      letterSpacing: focus || !empty ? t("inputValueLetterSpacing") : undefined,
    }),
  ["focus", "empty"]
)

/* eslint-disable react/prop-types */
/**
 * A form control for selecting dates via a numeric input field. This input is
 * unique from other input components in that it tracks the user's partial input
 * via internal state and only triggers onChange once the user has entered a
 * complete, valid date. If the user moves focus away from the input before
 * entering a complete, valid date then the input is cleared automatically.
 */
class DateInputFieldClass extends React.Component {
  localRef = React.createRef()

  setSelectionRangeTimeout = null

  assignRefs = inputNode => {
    const { forwardedRef } = this.props
    this.localRef.current = inputNode
    if (forwardedRef) {
      // Assign the ref with a function (Class components) or assigning .current
      // (modern functional components using the `useRef` hook)
      if (typeof forwardedRef === "function") {
        forwardedRef(inputNode)
      } else {
        forwardedRef.current = inputNode
      }
    }
  }

  getDisplayFormat = () => getDateLocale(this.props.locale).format

  getDisplayValue = value => {
    const date = parseDateString(value)
    return date ? format(date, this.getDisplayFormat()) : ""
  }

  getValue = displayValue => {
    const date = parseDateString(displayValue, this.getDisplayFormat())
    return date ? toDateString(date) : ""
  }

  state = {
    displayValue: this.getDisplayValue(this.props.value),
    focus: false,
  }

  getMask = () => new InputMask(this.getDisplayFormat().replace(/[MDY]/gi, "#"))

  isEmpty = () => !/[0-9]/.test(this.state.displayValue)

  getErrorCode({ mask }) {
    // Only validate if the input is in focus
    if (!this.state.focus) return undefined
    const full =
      !this.isEmpty() &&
      !new RegExp(mask.placeholderChar).test(this.state.displayValue)
    const value = this.getValue(this.state.displayValue)
    return full && !value
      ? "INVALID_DATE"
      : full && this.props.disabledDates(value)
      ? "DISABLED_DATE"
      : undefined
  }

  onFocus = event => {
    this.setState({ focus: true })
    this.props.onFocus(event)
  }

  onBlur = event => {
    const { value, onBlur } = this.props
    this.setState({
      displayValue: this.getDisplayValue(value),
      focus: false,
    })
    onBlur(event)
  }

  onClear = () => {
    const { onChange, onClear } = this.props
    // Tell owner date was cleared, clear display, and call onClear prop
    onChange("")
    onClear()
    this.setState({ displayValue: "" })
    // Move focus back to input so user can begin typing new date
    if (this.localRef.current) {
      this.localRef.current.focus({ preventScroll: true })
      // HACK: Move cursor to beginning.
      // This _should_ be doable in React input mask but, sadly, is not working 😢 https://github.com/sanniassin/react-input-mask/tree/2.0.4#beforemaskedvaluechange--function
      // This waits a beat for input mask to add its value before moving the cursor.
      this.setSelectionRangeTimeout = setTimeout(
        () => this.localRef.current.setSelectionRange(0, 0),
        10
      )
    }
  }

  onChange = displayValue => {
    const { onChange, disabledDates, value } = this.props
    const date = this.getValue(displayValue)
    this.setState({ displayValue })
    // If a valid date was entered then notify the owner
    if (date && !disabledDates(date)) {
      onChange(date)
    } else if (value) {
      // Otherwise tell the owner that the date was cleared (but don't clear the
      // display value so the user can keep editing towards a valid date)
      onChange(undefined)
    }
  }

  componentDidUpdate(prevProps) {
    const { locale, value } = this.props

    // If a new value was passed in via props then reset the display value
    // *unless* it's a situation where the user is editing the input and the
    // value was cleared out because the user's partial input resulted in an
    // invalid date
    if (value !== prevProps.value && !(this.state.focus && !value)) {
      this.setState({ displayValue: this.getDisplayValue(value) })
    }

    // If a new locale was passed in via props then reset the input's display
    // value
    if (locale !== prevProps.locale) {
      this.setState({ displayValue: this.getDisplayValue(value) })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.setSelectionRangeTimeout)
  }

  renderInvalidInputMessage(t, { mask }) {
    const errorCode = this.getErrorCode({ mask })
    const invalidInputMessage = errorCode
      ? this.props.invalidInputMessage(
          errorCode,
          this.getValue(this.state.displayValue)
        )
      : ""
    // Traverse the DOM looking for the little calendar icon that we've added to
    // the input (that's where we want to anchor the error message). This is
    // pretty brittle, but we definitely don't want to update the Input
    // component to provide direct refs to it's various pieces, so this
    // worksforme.
    const anchorNode = this.localRef.current
      ? this.localRef.current.parentNode.parentNode.parentNode.querySelector(
          "[role=img]"
        )
      : undefined
    return (
      <PopoverController
        open={Boolean(invalidInputMessage)}
        anchorNode={anchorNode}
        anchorOrigin="center right"
        popoverOrigin="bottom center"
        offsetVertical={-18}
        offsetHorizontal={-10}
      >
        {({ popoverStyle }) => (
          <PopoverBody
            style={popoverStyle}
            type="fill"
            spacingInset="small"
            zIndex={t("errorTooltipElevation")}
            arrowAlignment="center"
            arrowPosition="bottom"
          >
            {invalidInputMessage}
          </PopoverBody>
        )}
      </PopoverController>
    )
  }

  render() {
    const props = this.props
    const state = this.state
    const focus = props.focus !== undefined ? props.focus : state.focus
    const empty = this.isEmpty()
    const mask = this.getMask()
    const displayFormat = this.getDisplayFormat().toLocaleUpperCase()
    const errorCode = this.getErrorCode({ mask })
    const showClearButton = props.clearButton && !empty

    return (
      <Style tokens={datePickerTokens} map={getTokenHelper("datePicker")}>
        {t => (
          <Focus
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            multipleTargets={true}
          >
            {({ focusProps }) => (
              <inputBoxContext.Provider value={focusProps}>
                <Input
                  ref={this.assignRefs}
                  className={styles(t, { focus, empty })}
                  type="text"
                  value={state.displayValue}
                  suffixIconTokens={
                    showClearButton
                      ? undefined
                      : {
                          // HACK: Keep the calendar icon width & height to ensure the input
                          // size doesn't change when switching icons.
                          iconWidth: calendarIconTokens.iconCalendarWidth,
                          iconHeight: calendarIconTokens.iconCalendarHeight,
                          iconData: errorCode
                            ? errorIconTokens.iconAlertErrorSmallData
                            : calendarIconTokens.iconCalendarData,
                        }
                  }
                  mask={mask}
                  disabled={props.disabled}
                  focus={props.focus}
                  aria-placeholder={props.placeholder}
                  placeholder={focus ? undefined : props.placeholder}
                  size={props.size}
                  label={props.label}
                  error={props.error}
                  width={props.width}
                  suffix={
                    showClearButton ? (
                      <InputClearButton
                        onClick={this.onClear}
                        label={props.clearButtonLabel}
                        // HACK: Shrink the radius by 1 so the button radius fits inside the input
                        // radius
                        borderRadiusRight={
                          inputTokens(t.tokens).inputBorderRadius - 1
                        }
                      />
                    ) : null
                  }
                  onChange={this.onChange}
                  onKeyDown={props.onKeyDown}
                  autoFocus={props.autoFocus}
                  autoFill={props.autoFill}
                  id={props.id}
                  aria-expanded={props.popoverIsOpen}
                  aria-haspopup="dialog"
                  aria-describedby={props["aria-describedby"]}
                  aria-required={props["aria-required"]}
                  aria-invalid={props["aria-invalid"]}
                >
                  {focus || !empty ? (
                    <InputMonospacePlaceholder
                      t={t}
                      mask={mask}
                      disabled={props.disabled}
                      value={state.displayValue}
                      displayFormat={displayFormat}
                    />
                  ) : (
                    undefined
                  )}
                  {this.renderInvalidInputMessage(t, { mask })}
                </Input>
              </inputBoxContext.Provider>
            )}
          </Focus>
        )}
      </Style>
    )
  }
}
/* eslint-enable react/prop-types */

// Forward ref to class component. TODO: update class component
// to functional component.
const DateInputField = React.forwardRef((props, ref) => (
  <DateInputFieldClass {...props} forwardedRef={ref} />
))

DateInputField.displayName = "DateInputField"

DateInputField.propTypes = {
  /**
   * A function that will be called when the value of the date input is
   * changed by the user. The new date will be passed to the function as a
   * string with the format YYYY-MM-DD.
   *
   * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
   * so you must track the values provided to this function somewhere in state
   * and pass them back via the `value` prop in order to make this component
   * interactive.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Use this attribute to pass a space separated list of ids that you would like to use to
   * describe this DateInputField component.
   * ex: This can be used to associate instructions to how to use the calendar from this input field
   *
   * @added by meetex team member (camei@)
   */
  "aria-describedby": PropTypes.string,
  /**
   * Use this attribute to indicate that user input is required on the element before a form may be submitted.
   * ex: set this to true when there is a required field
   *
   * @added by meetex team member (kjoshuaz@)
   */
  "aria-required": PropTypes.string,
  /**
   * Use this attribute to indicate the entered value does not conform to the format expected by the application.
   * ex: set this to true when there is a required field missing
   *
   * @added by meetex team member (kjoshuaz@)
   */
  "aria-invalid": PropTypes.string,
  /**
   * Allow the browser to suggest auto-fill values for this input.
   *
   * Note that not all browsers respect this property. Some browsers (e.g.
   * Chrome 78) may choose to *always* allow auto-fill regardless of the value
   * of this property.
   */
  autoFill: PropTypes.bool,
  /**
   * If set to true then the input will grab focus as soon as it's mounted.
   *
   * This uses HTML's native `autofocus` attribute. Use this sparingly as
   * there are [common accessibility pitfalls](https://www.brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/)
   * associated with this behavior.
   */
  autoFocus: PropTypes.bool,
  /**
   * Appends a button to the input that the user can click to clear the input.
   * This will trigger the `onChange` callback with a value of `""`.
   *
   * @since  5.x
   */
  clearButton: PropTypes.bool,
  /**
   * An accessible label for the clear button. This will not be visible in the UI, but
   * will be read by screen readers.
   *
   * @since  5.x
   */
  clearButtonLabel: PropTypes.string,
  /**
   * This disables interaction with the entire date picker. If you want to
   * disable the selection of specific dates then use the `disabledDates` prop.
   */
  disabled: PropTypes.bool,
  /**
   * A function that can be used to disable specific dates. The function will
   * be passed date strings in the format YYYY-MM-DD and is expected to return
   * true for disabled dates and false otherwise.
   */
  disabledDates: PropTypes.func,
  /**
   * Show the component in an error state. Use this to indicate that the value
   * of the component is invalid.
   *
   * Consider pairing this state with a small error `Alert` component below
   * or to the right of the component explaining what the error is.
   */
  error: PropTypes.bool,
  /**
   * This can be used to force the visible focus state of the input on or
   * off. This only affects the display of the focus ring and the date format
   * placeholder, it does not change the focus state of the actual DOM element
   * as far as the browser is concerned.
   */
  focus: PropTypes.bool,
  /**
   * Sets an `id` attribute on the component.
   *
   * Use this for testing or for accessibility. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   *
   * @since 5.x
   */
  id: PropTypes.string,
  /**
   * A function that returns a message for invalid or disabled dates.
   * The function must return a localized string. The string will be displayed
   * to the user to help guide them.
   *
   * Two arguments will be passed to the function. The first argument will be an error code - either
   * "INVALID_DATE" if the user's date doesn't exist (e.g. 2018-40-40) or
   * "DISABLED_DATE" if the user's date has been disabled via the
   * `disabledDates` prop. The second argument will be the date entered by the
   * user in the format YYYY-MM-DD. This argument will only be provided for
   * the "DISABLED_DATE" error code (the "INVALID_DATE" code means we couldn't
   * actually construct a date).
   */
  invalidInputMessage: PropTypes.func,
  /**
   * A label for the input.
   *
   * If this prop is provided the `size` prop will be ignored.
   */
  label: PropTypes.string,
  /**
   * The locale to use when rendering the input (e.g. input date format,
   * month names, names of days of the week, etc.).
   */
  locale: PropTypes.oneOf(Object.keys(dateLocales)),
  /**
   * Text that is shown in place of the input's value when the input is empty.
   *
   * This text is not visible when a value is entered in the input, so it should
   * not contain essential information that should be persisted. For that,
   * consider using the `label` prop.
   */
  placeholder: PropTypes.string,
  /**
   * Sets the size of the component using a preset.
   *
   * If the `label` prop is provided this prop will be ignored (the label
   * requires a particular amount of space which is not configurable).
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  /**
   * The currently selected date in the format YYYY-MM-DD. Do not pass partial
   * or invalid dates.
   *
   * Update this when `onChange` is called to ensure the component is interactive
   * (see the documentation for the `onChange` prop).
   */
  value: PropTypes.string,
  /**
   * Set the width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   */
  onBlur: PropTypes.func,
  /**
   * This is called when the clear button is clicked (be sure to set `clearButton`
   * to `true`). No arguments are passed to this callback.
   *
   * @since 5.x
   */
  onClear: PropTypes.func,
  /**
   * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   */
  onFocus: PropTypes.func,
  /**
   * This is called when the component has focus and a keyboard key is
   * pressed down. The [keydown event](https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event)
   * is passed as the first argument to the function.
   */
  onKeyDown: PropTypes.func,
  /**
   * Whether the calendar popover dialog is open
   *
   * @added by meetex team member (camei@)
   */
  popoverIsOpen: PropTypes.bool,
}

DateInputField.defaultProps = {
  locale: "en-US",
  onClear: noop,
  onFocus: noop,
  onBlur: noop,
  clearButton: false,
  disabledDates: noop,
  size: "medium",
  invalidInputMessage: code =>
    ({
      INVALID_DATE: "This value is not a valid date.",
      DISABLED_DATE: "This date is not available.",
    }[code]),
  error: false,
  autoFill: true,
}

export default DateInputField
