import React from "react";
import Text from "@amzn/meridian/text";
import Tile from "@amzn/meridian/tile";
import Box from "@amzn/meridian/box";
import {buildIdFromName, buildIdFromTime, buildRoomLabel, getBoxBackgroundColor, getBoxClassName} from "../utils";
import {getFormattedDateTime, getTime} from "../../shared/time-utils";
import {zonedTimeToUtc} from "date-fns-tz";
import TimeTable from "../../shared/time-table/components/time-table";
import {TIME_CONSTANT} from "../../shared/shared-constants";
import "../meeting-roomfinder.css";

const RoomFinderResultsTimeTable = (props) => {

    const createGroups = (results) => {
        let groups = {};
        results.forEach((room) => {
            let name = buildRoomLabel(room);
            groups[name] = {
                label: name,
                id: buildIdFromName(name)
            }
        });
        return groups;
    };

    const createEvent = (startTime, endTime, subject, email, name, groupId, time, location) => {
        return {
            start:getFormattedDateTime(startTime, props.timezoneValue, 'yyyy-MM-dd HH:mm'),
            end: getFormattedDateTime(endTime, props.timezoneValue, 'yyyy-MM-dd HH:mm'),
            time: time ? time : {
                startTime: Math.trunc(startTime / 1000),
                endTime: Math.trunc(endTime / 1000)
            },
            subject: subject,
            email: email,
            name: name,
            groupId: groupId,
            location: location, 
            render: DistributedHour,
        }
    };

    /*
    *  This function sorts the room availabilities for a floor, and fills in windows in between the availabilities
    *  with either "Available/Was available" tiles
    */
    const createDistributedEvents = (results) => {
        let events = [];
        results.forEach((room) => {
            let roomName = buildRoomLabel(room);
            let now = zonedTimeToUtc(Date.now(), props.timezoneValue);
            let availabilityStartTime = zonedTimeToUtc(`${props.startTime} 00:00:00.000`, props.timezoneValue);
            let endOfDay = zonedTimeToUtc(`${props.startTime} 23:59:59.999`, props.timezoneValue);
            room.availabilities = room.availabilities.filter(meeting => meeting.status !== "free" && meeting.time.startTime !== meeting.time.endTime);
            room.availabilities.sort((a, b) => a.time.startTime - b.time.startTime);
            room.availabilities.forEach((meeting) => {
                let startTime = meeting.time.startTime * 1000;
                let endTime = meeting.time.endTime * 1000;
                // if opening is before the meeting we need to push event(s) before pushing meeting
                if (startTime > availabilityStartTime && startTime - availabilityStartTime > TIME_CONSTANT.ONE_MINUTE_IN_MS) {
                    if (availabilityStartTime < now && now < startTime) {
                        events.push(createEvent(availabilityStartTime, now, "Was available", room.email, room.name, roomName));
                        availabilityStartTime = now;
                        if (!room.restricted) {
                            events.push(createEvent(availabilityStartTime, startTime, "Available", room.email, room.name, roomName));
                        } else {
                            events.push(createEvent(availabilityStartTime, startTime, "Available (Restricted)", room.email, room.name, roomName));
                        }
                    } else {
                        if (availabilityStartTime < startTime) {
                            if (!room.restricted) {
                                events.push(createEvent(availabilityStartTime, startTime, now < availabilityStartTime ? "Available" : "Was available",
                                    room.email, room.name, roomName));
                            } else {
                                events.push(createEvent(availabilityStartTime, startTime, now < availabilityStartTime ? "Available (Restricted)" : "Was available",
                                    room.email, room.name, roomName));
                            }
                        }
                    }
                }
                events.push(createEvent(startTime, endTime, meeting.subject, room.email, room.name, roomName, meeting.time, meeting.location));
                availabilityStartTime = endTime;
            });
            // After creating events for all open slots, if we have not reach end of visible day
            // we need to push event(s) to fill remainder of calendar
            if (availabilityStartTime < endOfDay) {
                if (availabilityStartTime < now && now - availabilityStartTime > TIME_CONSTANT.ONE_MINUTE_IN_MS) {
                    events.push(createEvent(availabilityStartTime, now, "Was available", room.email, room.name, roomName));
                    availabilityStartTime = now;
                }
                if (!room.restricted) {
                    events.push(createEvent(availabilityStartTime, endOfDay, "Available", room.email, room.name, roomName));
                } else {
                    events.push(createEvent(availabilityStartTime, endOfDay, "Available (Restricted)", room.email, room.name, roomName));
                }
            }
        });
        return events.filter((event) => event.time.startTime !== event.time.endTime);
    };

    const getStartAndEndTime = (time) => {
        let startTime = getFormattedDateTime(time.startTime * 1000, props.timezoneValue, 'h:mm a');
        let endOfWindow = getTime(zonedTimeToUtc(`${props.startTime} ${props.startTimeStamp}`, props.timezoneValue).getTime());
        let endTime = getFormattedDateTime(Math.min(time.endTime * 1000, endOfWindow), props.timezoneValue, 'h:mm a');
        return `${startTime} to ${endTime}`;
    };

    const DistributedHour = React.memo(({start, end, subject, time, groupId, name, email, location, clipLeft, clipRight, children}) => {
        return (
            <Tile
                id={buildIdFromTime(time, groupId)}
                width={"100%"}
                clipRight={clipRight}
                clipLeft={clipLeft}
                onClick={() => {props.onSelectRoomAndTime(groupId, {start: start, end: end, subject: subject, time: time, name: name, email: email, location: location})}}
                spacingInset={"none"}
                aria-describedby={`${buildIdFromName(groupId)} ${buildIdFromTime(time, groupId)}`}
            >
                <Box className={getBoxClassName(subject)} type={"outline"} spacingInset={"small"}
                     backgroundColor={getBoxBackgroundColor(subject)} width={"100%"} height={55}>
                    <Text truncate={true}>
                        {subject}
                    </Text>
                    <span style={{"display":"none"}} aria-hidden={true}
                          id={`${buildIdFromTime(time, groupId)}`}>
                        {getStartAndEndTime(time)}
                    </span>
                </Box>
            </Tile>
        );
    });

    return <TimeTable
            groups={createGroups(props.roomResults)}
            distributedEvents={createDistributedEvents(props.roomResults)}
            renderHour={undefined}
            startTime={props.startTime}
            setStartTime={props.setStartTime}
            startTimeStamp={props.startTimeStamp}
            setStartTimeStamp={props.setStartTimeStamp}
            setStartTimeInput={props.setStartTimeInput}
            setStartTimeStampInput={props.setStartTimeStampInput}
            timezoneValue={props.timezoneValue}
            onRefresh={props.onRefresh}
        />
};

export default RoomFinderResultsTimeTable;