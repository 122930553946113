import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import closeIconTokens from "@amzn/meridian-tokens/base/icon/close"
import buttonTokens from "@amzn/meridian-tokens/component/button"
import { elevationForeground } from "@amzn/meridian-tokens/base/elevation"
import Button from "../button"
import Icon from "../icon"
import { useTheme } from "../theme"
import { memoizeTokenStyles } from "../../_utils/token"
import useFocus from "../_use-focus"
import { focusStyles } from "../button/button-styles"

const styles = memoizeTokenStyles(
  (t, { hidden, borderRadiusRight, focus }) =>
    css(
      {
        height: "100%",
        visibility: hidden ? "hidden" : undefined,
        // Bring the clear button up over the search button when focused so that its
        // focus ring isn't clipped
        "&:focus": {
          zIndex: elevationForeground,
        },
        // Set the button border radius
        borderRadius: `0 ${borderRadiusRight}px ${borderRadiusRight}px 0`,
        [`[dir="rtl"] &`]: {
          borderRadius: `${borderRadiusRight}px 0 0 ${borderRadiusRight}px`,
        },
      },
      // Set the focus ring border radius
      focusStyles(t, {
        focus,
        borderRadius: `0 ${borderRadiusRight}px ${borderRadiusRight}px 0`,
        outlineOffset: 1,
      }),
      {
        [`[dir="rtl"] &`]: focusStyles(t, {
          focus,
          borderRadius: `${borderRadiusRight}px 0 0 ${borderRadiusRight}px`,
          outlineOffset: 1,
        }),
      }
    ),
  ["hidden", "borderRadiusRight", "focus"]
)

const InputClearButton = props => {
  const { hidden, borderRadiusRight } = props
  const t = useTheme(buttonTokens, "button")
  const { focus, focusTrigger, focusProps } = useFocus(props)
  return (
    <Button
      type="icon"
      onClick={props.onClick}
      disabled={props.disabled}
      className={styles(t, {
        hidden,
        borderRadiusRight,
        focus: focus && focusTrigger === "keyboard",
      })}
      aria-hidden={hidden}
      label={props.label}
      {...focusProps}
    >
      <Icon tokens={closeIconTokens} />
    </Button>
  )
}

InputClearButton.propTypes = {
  borderRadiusRight: PropTypes.number,
  /**
   * This disables interaction with the component and applies special visual
   * styles to indicate that it's not interactive.
   */
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
}

InputClearButton.defaultProps = {
  label: "Clear input",
}

export default InputClearButton
