import * as Actions from "../actions/action-types";

const initialState = {
    calendarSettings: {
        viewType: "day",
        date: "",
    },
    calendarView: {
        meetingListLoaded: false,
        meetingList: [],
        availabilityRawData: {},
        availabilityBlocks: {}
    }
};

function calendarReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_CALENDAR_VIEW_TYPE:
            return {
                ...state,
                calendarSettings: {
                    ...state.calendarSettings,
                    viewType: action.viewType
                }
            };
        case Actions.SET_CALENDAR_DATE:
            return {
                ...state,
                calendarSettings: {
                    ...state.calendarSettings,
                    date: action.date
                }
            };
        case Actions.SET_CALENDAR_STATUS_FILTER:
            return {
                ...state,
                calendarSettings: {
                    ...state.calendarSettings,
                    calendarStatusFilter: action.calendarStatusFilter
                }
            };
        case Actions.SET_CALENDAR_RESPONSE_FILTER:
            return {
                ...state,
                calendarSettings: {
                    ...state.calendarSettings,
                    calendarResponseFilter: action.calendarResponseFilter
                }
            };
        case Actions.FIND_MEETINGS:
            return {
                ...state,
                calendarView: {
                    ...state.calendarView,
                    meetingListLoaded: false,
                    meetingList: []
                }
            };
        case Actions.SET_MEETING_LIST:
            return {
                ...state,
                calendarView: {
                    ...state.calendarView,
                    meetingListLoaded: true,
                    meetingList: action.meetingList
                }
            };
        case Actions.SET_AVAILABILITY_RAW_DATA:
            return {
                ...state,
                calendarView: {
                    ...state.calendarView,
                    availabilityRawData: {...action.availabilityRawData}
                }
            };
        case Actions.SET_AVAILABILITY_BLOCKS:
            return {
                ...state,
                calendarView: {
                    ...state.calendarView,
                    availabilityBlocks: {...action.availabilityBlocks}
                }
            };
        default:
            return state;
    }

}

export default calendarReducer;
