import React, { useCallback, useEffect, useRef } from "react";

import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import RefreshAlert from "../../shared/components/refresh-alert";

import { REFRESH_ALERT_TIMER } from "../../shared/shared-constants";

const MeetingAgendaHeader = (props) => {
    const showRefreshAlert = props.showRefreshAlert;
    const setShowRefreshAlert = props.setShowRefreshAlert;
    const [checkInRefocusInfo, setCheckInRefocusInfo] = [props.checkInRefocusInfo, props.setCheckInRefocusInfo];

    const refreshTimer = useRef();

    const onSetAcceptedOnlyFilter = (acceptedOnlyFilter) => {
        props.onSetAcceptedOnlyFilter(acceptedOnlyFilter);
    };

    const createRefreshTimer = useCallback(() => {
        return window.setTimeout(() => {
            setShowRefreshAlert(true);
        }, REFRESH_ALERT_TIMER);
    }, [setShowRefreshAlert]);

    useEffect(() => {
        refreshTimer.current = createRefreshTimer();
    }, [createRefreshTimer]);

    const onClickRefresh = () => {
        props.onRefreshMeetings();

        // Refocus to first agenda tile after closing check in toast
        setCheckInRefocusInfo({
            ...checkInRefocusInfo,
            className: "agenda-tile",
        });

        refreshTimer.current = createRefreshTimer();
    };

    return (
        <div style={{
            "paddingLeft": "16px",
            "paddingRight": "18px"
        }}>
            <Column>
                <Row widths={["fit"]} alignmentHorizontal="left" alignmentVertical="bottom">
                    {props.isEmptyMeetingList ?
                        <span/>
                        :
                        props.isWrapOptions ?
                            <Column>
                                <Heading level={2} color="inverted" type="h400">Upcoming today</Heading>
                                <Checkbox checked={props.acceptedOnly} onChange={onSetAcceptedOnlyFilter}
                                          disabled={!props.hasAcceptedMeetings}>
                                    <Text color="inverted">Accepted only</Text>
                                </Checkbox>
                            </Column>
                            :
                            <Row widths={["fit", "fit"]} wrap="down">
                                <Heading level={2} color="inverted" type="h400">Upcoming today</Heading>
                                <Checkbox checked={props.acceptedOnly} onChange={onSetAcceptedOnlyFilter}
                                          disabled={!props.hasAcceptedMeetings}>
                                    <Text color="inverted">Accepted only</Text>
                                </Checkbox>
                            </Row>
                    }
                </Row>
                {showRefreshAlert &&
                    <RefreshAlert page="agenda" onClick={onClickRefresh} />
                }
            </Column>
        </div>
    )
};

export default MeetingAgendaHeader;