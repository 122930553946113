import React, { useState, useMemo } from "react";
import Select, { SelectOption } from "../meridian-custom-components/src/components/select";
import Text from "@amzn/meridian/text";
import escapeRegExp from "lodash/escapeRegExp";

const DayOfMonthSearchBar = (props) => {
    const days = Array.from({length: 31}, (_, index) => index + 1);

    const [searchQuery, setSearchQuery] = useState("");

    const DAY_OF_MONTH_SEARCH_BAR_LABEL_ID = "day-of-month-search-bar-label-id";

    const searchRegExp = useMemo(
        () => new RegExp(escapeRegExp(searchQuery), "i"),
        [searchQuery]
    );

    const matchedDays = days.filter((day) => (!searchQuery || searchRegExp.test(day)));

    return (
        <div>
            <div style={{display: "none"}}>
                <Text id={DAY_OF_MONTH_SEARCH_BAR_LABEL_ID}>Day of month</Text>
            </div>
            <Select
                value={props.value}
                onChange={props.onChange}
                searchQuery={searchQuery}
                onSearch={setSearchQuery}
                width={props.width}
                size={props.size}
                popoverMaxHeight={450}
                placeholder="Select day of month"
                aria-labelledby={DAY_OF_MONTH_SEARCH_BAR_LABEL_ID}
            >
                {matchedDays.map((day) => (
                    <SelectOption value={day} label={day} key={day} />
                ))}
            </Select>
        </div>
    );
};

export default DayOfMonthSearchBar;
