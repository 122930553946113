import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import parse from "date-fns/parse";
import addDays from "date-fns/addDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";

import {
    getMeetingListCalendar,
    getMeetingListCalendarLoaded,
} from "../../../sagas/selector";
import { findMeetings } from "../../calendar/actions";

import Column from "@amzn/meridian/column";

import Calendar from "../../calendar/components/calendar";
import LoadingMeetings from "../../landing/components/loading-meeting";

import { CALENDAR_MODE } from "../../calendar/calendar-constants";
import { CREATE_POLL_CALENDAR } from "../poll-constants";

const CreatePollCalendar = (props) => {
    const dispatch = useDispatch();

    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const userEmail = props.userEmail;
    const calendarViewType = props.calendarViewType;

    const date = props.date;
    const calendarStatusFilter = ["busy", "tentative", "outOfOffice"];
    const calendarResponseFilter = ["accept", "tentative", "notResponded"];

    const meetingListLoaded = useSelector(getMeetingListCalendarLoaded);
    const meetingList = useSelector(getMeetingListCalendar);

    const timeFormat = props.timeFormat;
    const timezone = props.timezone;

    // Display the entire day
    const startTime = "00:00:00";
    const endTime = "24:00:00";

    const lastTimeRangeQueried = useRef("");

    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [showRefreshAlert, setShowRefreshAlert] = useState(false);

    const timeSlotDuration = props.timeSlotDuration;

    // Call findMeetings to populate the calendar
    useEffect(() => {
        if (userEmail) {
            const dateObj = parse(date, "yyyy-MM-dd", new Date());
            // Extend start and end of week by additional day to account for meetings from timezone conversion
            let startOfWeekTime = addDays(startOfWeek(dateObj), -1).getTime();
            let endOfWeekTime = addDays(endOfWeek(dateObj), +1).getTime();

            if (lastTimeRangeQueried.current !== `${userEmail}-${startOfWeekTime}-${endOfWeekTime}` || triggerRefresh) {
                dispatch(findMeetings(userEmail, startOfWeekTime, endOfWeekTime, -1));
                lastTimeRangeQueried.current = `${userEmail}-${startOfWeekTime}-${endOfWeekTime}`;
                setTriggerRefresh(false);
            }
        }
    }, [date, userEmail, triggerRefresh, dispatch]);

    return (
        <Column>
            <Column height={CREATE_POLL_CALENDAR.HEIGHT}>
                {timezone ?
                    <Calendar
                        meetingListLoaded={meetingListLoaded}
                        meetings={meetingList}
                        date={date}
                        calendarStatusFilter={calendarStatusFilter}
                        calendarResponseFilter={calendarResponseFilter}
                        viewType={calendarViewType}
                        userEmail={userEmail}
                        screenSizeBreakpoint={screenSizeBreakpoint}
                        setTriggerRefresh={setTriggerRefresh}
                        showRefreshAlert={showRefreshAlert}
                        setShowRefreshAlert={setShowRefreshAlert}
                        selectedStartTime={startTime}
                        selectedEndTime={endTime}
                        trimEvents={true}
                        calendarMode={CALENDAR_MODE.CREATE_POLL}
                        timeSlotDuration={timeSlotDuration}
                        selectedTimeCellIds={props.selectedTimeCellIds}
                        setSelectedTimeCellIds={props.setSelectedTimeCellIds}
                        selectedTimeSlots={props.selectedTimeSlots}
                        setSelectedTimeSlots={props.setSelectedTimeSlots}
                        setMaxTimeSlotsWarningMessage={props.setMaxTimeSlotsWarningMessage}
                        timeFormat={timeFormat}
                        timezone={timezone}
                        createPollCalendar={true}
                    />
                    :
                    <LoadingMeetings
                        loadingMessage="Loading your meetings..."
                        loaderSize="large"
                    />
                }
            </Column>
        </Column>
    );
};

export default CreatePollCalendar;