import React, { useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat"; //BrowserRouter

import Column from "@amzn/meridian/column";

import MeetingFormDetails from "./features/meeting-scheduler/containers/meeting-container";
import OOTOFormDetails from "./features/meeting-ooto/containers/ooto-container";
import CalendarDetails from "./features/calendar/containers/calendar-container";
import RoomFinderContainer from "./features/meeting-roomfinder/containers/room-finder-container";
import MeetingAvailabilitySharing from "./features/availability-sharing/containers/availability-sharing-container";
import PollDashboardContainer from "./features/meeting-polls/containers/poll-dashboard-container";
import SettingsDetails from "./features/shared/settings/containers/settings-container";
import LivestreamContainer from "./features/meeting-livestream/containers/livestream-container";
import MeetingRoomsContainer from "./features/meeting-rooms/containers/meeting-rooms-container";
import LandingPage from "./features/landing/containers/landing-page";
import UnauthorizedAccess from "./features/shared/containers/unauthorized-access";
import Migration from "./features/shared/containers/migration";
import Error404 from "./features/shared/containers/error-404";
import CreatePollContainer from "./features/meeting-polls/containers/create-poll-container";
import PollInternalRespondContainer from "./features/meeting-polls/containers/poll-internal-respond-container";
import ExternalAuthLanding from "./features/external-auth/containers/external-auth-landing";

import { FLOW_TYPE } from "./features/meeting-scheduler/meeting-scheduler-constants";
import {
    getBrowserTimezoneId,
    getIdentity,
    getSelectedPollDetails,
    getSettingsPreferencesLoaded,
    getSettingsPrimaryTimezone,
    getTimezonesList,
    getWorkflow,
} from "./sagas/selector";
import { determinePrimaryBrowserTimezoneId } from "./features/shared/timezones/timezones-utils";
import { savePreferences } from "./features/shared/settings/actions";
import { changePage } from "./features/shared/workflow/workflow-utils";
import { WORKFLOWS } from "./features/shared/workflow/workflow-constants";
import { M365_MIGRATION_REDIRECT_ENABLED, M365_MIGRATION_ALLOWLIST } from "./features/shared/containers/migration";

const Routers = (props) => {
    const store = props.store;
    window.store = store;
    const identity = useSelector(getIdentity);
    const timezones = useSelector(getTimezonesList);
    const browserTimezoneId = useSelector(getBrowserTimezoneId);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const preferencesLoaded = useSelector(getSettingsPreferencesLoaded);
    const selectedPollDetails = useSelector(getSelectedPollDetails);
    const currentWorkflow = useSelector(getWorkflow);

    const dispatch = useDispatch();

    const routeRender = (store, component) => {
        // Redirect unauthorized users to the unauthorized page
        if (store.getState().shared.unauthorizedUser) {
            return <Redirect to="/unauthorized" />;
        }

        // Do not allow users migrated to M365 to change to another page
        if (M365_MIGRATION_REDIRECT_ENABLED && identity && identity.exchangeOnlineMailbox && !M365_MIGRATION_ALLOWLIST.includes(identity.username)) {
            return <Redirect to="/migration" />;
        }

        return component;
    };

    // If the user is migrated to Exchange Online, redirect them to the migration page
    useEffect(() => {
        if (M365_MIGRATION_REDIRECT_ENABLED && identity && identity.exchangeOnlineMailbox && !M365_MIGRATION_ALLOWLIST.includes(identity.username)) {
            let href = window.location.href || "";

            // Do not redirect on the rooms page
            if (!href.endsWith("rooms")) {
                changePage(dispatch, currentWorkflow, WORKFLOWS.MIGRATION);
            }
        }
    }, [dispatch, identity, currentWorkflow]);

    // Initializing some global config values in case there no preferences data in the db for this user
    useEffect(() => {
        if (identity && identity.username && preferencesLoaded) {
            if (
                timezones.length &&
                (!primaryTimezone || primaryTimezone?.length === 0)
            ) {
                const timezoneValue = determinePrimaryBrowserTimezoneId(
                    timezones,
                    browserTimezoneId
                );

                dispatch(
                    savePreferences({
                        timePreferences: { primaryTimezone: timezoneValue },
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, identity, timezones, browserTimezoneId, primaryTimezone, preferencesLoaded]);

    return (
        // <Router>
        //     <CompatRouter>
            <Switch>
                <Route
                    exact={true}
                    path="/"
                    render={() => routeRender(store, <LandingPage />)}
                />
                <Route
                    path="/create-meeting"
                    render={() =>
                        routeRender(
                            store,
                            <MeetingFormDetails
                                flowType={FLOW_TYPE.CREATE}
                                mastheadSize={props.mastheadSizePx}
                            />
                        )
                    }
                />
                {/*<Route*/}
                {/*    path="/create-meeting"*/}
                {/*    component={<MeetingFormDetails*/}
                {/*        flowType={FLOW_TYPE.CREATE}*/}
                {/*        mastheadSize={props.mastheadSizePx}*/}
                {/*    />}*/}
                {/*/>*/}
                <Route path="/create-meeting-from-poll">
                    {selectedPollDetails ?
                        () => routeRender(store,
                            <MeetingFormDetails
                                flowType={FLOW_TYPE.POLL}
                                mastheadSize={props.mastheadSizePx}
                            />
                        )
                        :
                        <Redirect to={{
                            pathname: "/polls",
                            state: { redirectFromCreateMeetingPage: true }
                        }} />
                    }
                </Route>
                <Route
                    exact
                    path="/edit-meeting/entryID=:entryID"
                    render={() =>
                        routeRender(
                            store,
                            <MeetingFormDetails
                                flowType={FLOW_TYPE.UPDATE}
                                mastheadSize={props.mastheadSizePx}
                            />
                        )
                    }
                />
                <Route
                    path="/edit-meeting/step=:step(/entryID=)?:entryID?"
                    render={() =>
                        routeRender(
                            store,
                            <MeetingFormDetails
                                flowType={FLOW_TYPE.UPDATE}
                                mastheadSize={props.mastheadSizePx}
                            />
                        )
                    }
                />
                <Route
                    path="/ooto"
                    render={() =>
                        routeRender(
                            store,
                            <OOTOFormDetails mastheadSize={props.mastheadSizePx} />
                        )
                    }
                />
                <Route
                    path="/calendar"
                    render={() =>
                        routeRender(
                            store,
                            <Column
                                alignmentHorizontal="center"
                                height={`calc(100vh - ${props.mastheadSizePx})`}
                            >
                                <CalendarDetails />
                            </Column>
                        )
                    }
                />
                <Route
                    path="/room-booking"
                    render={() =>
                        routeRender(
                            store,
                            <Column alignmentHorizontal="center">
                                <RoomFinderContainer />
                            </Column>
                        )
                    }
                />
                <Route
                    path="/availability-sharing"
                    render={() =>
                        routeRender(
                            store,
                            <Column alignmentHorizontal="center">
                                <MeetingAvailabilitySharing />
                            </Column>
                        )
                    }
                />
                <Route
                    path="/polls"
                    render={() =>
                        routeRender(
                            store,
                            <PollDashboardContainer
                                mastheadSize={props.mastheadSizePx}
                            />
                        )
                    }
                />
                <Route
                    path="/create-poll"
                    render={() =>
                        routeRender(
                            store,
                            <Column alignmentHorizontal="center">
                                <CreatePollContainer
                                    flowType={FLOW_TYPE.CREATE}
                                    mastheadSize={props.mastheadSizePx}
                                />
                            </Column>
                        )
                    }
                />
                <Route
                    path="/edit-poll/pollID=:pollID"
                    render={() =>
                        routeRender(
                            store,
                            <Column alignmentHorizontal="center">
                                <CreatePollContainer
                                    flowType={FLOW_TYPE.UPDATE}
                                    mastheadSize={props.mastheadSizePx}
                                />
                            </Column>
                        )
                    }
                />
                <Route
                    path="/preferences"
                    render={() =>
                        routeRender(
                            store,
                            <Column alignmentHorizontal="center">
                                <SettingsDetails mastheadSize={props.mastheadSizePx} />
                            </Column>
                        )
                    }
                />
                <Route
                    path="/respond/pollID=:pollID"
                    render={() =>
                        routeRender(
                            store,
                            <PollInternalRespondContainer
                                mastheadSize={props.mastheadSizePx}
                            />
                        )
                    }
                />
                <Route
                    path="/livestream-intake"
                    render={() =>
                        routeRender(
                            store,
                            <LivestreamContainer
                                flowType={FLOW_TYPE.CREATE}
                                mastheadSize={props.mastheadSizePx}
                            />
                        )
                    }
                />
                <Route
                    path="/ext-auth"
                    render={() =>
                        routeRender(
                            store,
                            <ExternalAuthLanding />
                        )
                    }
                />
                <Route
                    path="/rooms"
                    render={() => store.getState().shared.unauthorizedUser ? <Redirect to="/unauthorized" /> : <MeetingRoomsContainer />}
                />
                <Route
                    path="/unauthorized"
                    render={() => {
                        return <UnauthorizedAccess mastheadSize={props.mastheadSizePx} />;
                    }}
                />
                <Route
                    path="/migration"
                    render={() => {
                        return <Migration mastheadSize={props.mastheadSizePx} />;
                    }}
                />
                <Route
                    path="*"
                    render={() => {
                        // Invalid/404 Route
                        return <Error404 mastheadSize={props.mastheadSizePx} />;
                    }}
                />
            </Switch>
            // {/*</CompatRouter>*/}
        // </Router>
    );
}

export default Routers;