import { call, put, takeEvery } from "redux-saga/effects";

import { isErrorResponse } from "../../../api/apig/apig-utils";
import { getExchangePreferences } from "../../../api/apig/ras";
import { TIME_CONSTANT } from "../../shared-constants";
import { showToast } from "../../toasts/actions";
import { getErrorToast } from "../../toasts/toast-utils";
import { setWorkHours } from "../actions";
import * as Actions from "../actions/action-types";


export function* watchGetExchangePreferences() {
    yield takeEvery(Actions.GET_EXCHANGE_PREFERENCES, getExchangePreferencesApi);
}

export function* getExchangePreferencesApi(action) {
    const response = yield call(getExchangePreferences, action.mailbox);
    let getExchangePreferencesResponse = JSON.parse(response);
    let error = false;
    let workHours = getExchangePreferencesResponse.exchangePreferences && getExchangePreferencesResponse.exchangePreferences.userWorkHours;

    if (isErrorResponse(getExchangePreferencesResponse)) {
        const toast = getErrorToast("An error occurred when retrieving your work hours.");
        yield put(showToast(toast));
        error = true;
    }
    if (error || !workHours || !workHours.startTime || !workHours.endTime) {
        workHours = {
            // Minutes after 00:00
            startTime: 9 * TIME_CONSTANT.ONE_HOUR_IN_MIN,
            endTime: 17 * TIME_CONSTANT.ONE_HOUR_IN_MIN
        };
    }

    yield put(setWorkHours(workHours));
}

const sagas = [
  watchGetExchangePreferences(),
];

export default sagas;