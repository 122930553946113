const inputReset = ({ textColor, placeholderColor, empty }) => ({
  display: "block",
  backgroundColor: "transparent",
  border: "none",
  padding: 0,
  width: "100%",
  color: textColor,
  WebkitTextFillColor: empty ? placeholderColor : textColor,
  fontFamily: "inherit",
  fontSize: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit",
  outline: "none",
  // Base built-in style resets
  WebkitAppearance: "none",
  MozAppearance: "textfield",
  // Remove the clear button from search inputs
  "::-ms-clear": { display: "none" },
  "::-ms-reveal": { display: "none" },
  "::-webkit-search-decoration": { display: "none" },
  "::-webkit-search-cancel-button": { display: "none" },
  "::-webkit-search-results-button": { display: "none" },
  "::-webkit-search-results-decoration ": { display: "none" },
  // Remove the built-in error outline
  ":invalid": { boxShadow: "none" },
  ":-moz-submit-invalid": { boxShadow: "none" },
  ":-moz-ui-invalid": { boxShadow: "none" },
  // Remove the numeric input up/down buttons in webkit
  "::-webkit-outer-spin-button": { WebkitAppearance: "none", margin: "0" },
  "::-webkit-inner-spin-button": { WebkitAppearance: "none", margin: "0" },
  // Set the placeholder colors
  "::-moz-placeholder": { opacity: "1", color: placeholderColor },
  ":-moz-placeholder": { opacity: "1", color: placeholderColor },
  "::-ms-input-placeholder": { opacity: "1", color: placeholderColor },
  ":-ms-input-placeholder": { opacity: "1", color: placeholderColor },
  "::-webkit-input-placeholder": { opacity: "1", color: placeholderColor },
  "::placeholder": { opacity: "1", color: placeholderColor },
})

export default inputReset
