import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { getMeetingPollsLoaded } from "../../../sagas/selector";
import { switchWorkflow } from "../../shared/workflow/actions";
import { clearAttendees } from "../../people/actions";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import Select, { SelectOption } from "../../shared/meridian-custom-components/src/components/select";

import refreshTokens from "@amzn/meridian-tokens/base/icon/refresh";

import "../meeting-polls.css";
import illustrationDashboardSvg from "../../../assets/icons/shared/illustration_dashboard.svg";

import PollDashboardPagination from "./poll-dashboard-pagination";
import PollDashboardTile from "./poll-dashboard-tile";
import LoadingMeetings from "../../landing/components/loading-meeting";

import { convertEpoch } from "../../meeting-scheduler/meeting-scheduler-utils";
import { POLL_SORT_BY, THIRTY_DAYS_IN_MS, STATUS } from "../poll-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { TIME_FORMAT as SETTINGS_TIME_FORMAT } from "../../shared/settings/settings-constants";

const PollDashboard = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const userAlias = props.userAlias;
    const pollList = props.pollList;
    const organizerPreferredDate = props.organizerPreferredDate;
    const timezoneValue = props.timezoneValue;
    const timeFormat = props.timeFormat;
    const showResultsPanel = props.showResultsPanel;
    const onOpenResults = props.onOpenResults;
    const onCloseResults = props.onCloseResults;

    const meetingPollsLoaded = useSelector(getMeetingPollsLoaded);
    const [initialPageLoad, setInitialPageLoad] = useState(true);
    const [sortOption, setSortOption] = useState(POLL_SORT_BY.CREATE_TIME);

    const sortPolls = (pollList, sortOption) => {
        return pollList.sort((poll1, poll2) => {
            if (sortOption === POLL_SORT_BY.CREATE_TIME) {
                // Most recent to least recent (e.g. Jan 2, Jan 1, etc.)
                return poll1[sortOption] > poll2[sortOption] ? -1 : 1;
            }
            if (sortOption === POLL_SORT_BY.TITLE) {
                // Alphabetical (e.g. A, B, etc.)
                return poll1[sortOption] > poll2[sortOption] ? 1 : -1;
            }
            if (sortOption === POLL_SORT_BY.STATUS) {
                // Ready, Ongoing, Overdue
                let statusValues = {
                    "Ready": 1,
                    "Ongoing": 2,
                    "Overdue": 3,
                };
                return statusValues[poll1[sortOption]] > statusValues[poll2[sortOption]] ? 1 : -1;
            }
            if (sortOption === POLL_SORT_BY.DUE_DATE) {
                // Least recent to most recent (e.g. Jan 1, Jan 2)
                return poll1[sortOption] > poll2[sortOption] ? 1 : -1;
            }
            if (sortOption === POLL_SORT_BY.VOTES_RESPONSE_RATIO) {
                // Ratio of votes descending (e.g. 100%, 50%, etc.)
                return poll1[sortOption] > poll2[sortOption] ? -1 : 1;
            }
            return 0;
        });
    };

    const showCurrentTime = () => {
        const today = Date.now() / 1000;
        return convertEpoch(today, "time", timezoneValue, timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR);
    };

    // For dashboard page, filter out the polls that organizer is an attendee, only include the polls that user organized
    let organizerPollList = pollList?.filter((poll) => poll.pin === undefined) || [];

    // Add response ratio column to the poll list for sorting purposes
    let newPollList = organizerPollList.map((poll) => {
        return {
            ...poll,
            votesResponseRatio: poll.attendees.filter((attendee) => attendee.response === true).length / poll.attendees.length || 0 // if divide by 0 resulting in NaN, return 0
        };
    });

    // 30 days after the due date will remove the polls from the dashboard
    // So I want to include the polls that have a due date bigger than 30 days
    let tempPollList = newPollList.filter((poll) => poll.expiration >= Math.floor(Date.now()) - THIRTY_DAYS_IN_MS);
    let sortedPollList = sortPolls(tempPollList, sortOption);

    const [currentPage, setCurrentPage] = useState(1); // Default to the first page
    const pollsPerPage = 8; // 8 polls per page

    // Get current polls
    const pollEndIndex = currentPage * pollsPerPage;
    const pollStartIndex = pollEndIndex - pollsPerPage;
    const currentPolls = sortedPollList.slice(pollStartIndex, pollEndIndex);

    const onFindPolls = props.onFindPolls;
    const [refreshPolls, setRefreshPolls] = useState(false);
    const [isRefreshed, setIsRefreshed] = useState(false);
    const onGetPoll = props.onGetPoll;

    const POLL_SORT_BY_LABEL_ID = "poll-sort-by-label-id";

    const findPolls = () => {
        window.setTimeout(() => {
            setRefreshPolls(false);

            let refreshButton = document.getElementById("refresh-button");
            refreshButton.focus();

        }, 300); // 0.3 sec timer
        setInitialPageLoad(false);

        // The start and end time here is for the poll creation time
        // Set at start time at Jan 1 2022 8 AM and end time is the current timestamp in seconds
        let startTime = new Date(new Date().getFullYear(), 0, 1, 8).getTime();
        let endTime = Math.floor(Date.now());

        // TODO: replace with dynamic status
        onFindPolls(userAlias, userAlias, startTime, endTime, [STATUS.ONGOING, STATUS.READY, STATUS.OVERDUE]);
    };

    const onClickRefresh = () => {
        setRefreshPolls(true);
        setIsRefreshed(true);
        findPolls();
    };

    const dispatch = useDispatch();

    const handleChangePage = props.handleChangePage;

    // Prepare poll state and then redirect user to the edit poll workflow
    const onRedirectUpdate = (pollID) => {
        dispatch(clearAttendees()); // Clear attendees after redirect to reset the state

        // Call the getPoll API to get the selected poll details
        onGetPoll(pollID);
        dispatch(push("/edit-poll/pollID=" + pollID));
        dispatch(switchWorkflow(WORKFLOWS.EDIT_POLL));
    };

    return (
        !meetingPollsLoaded && initialPageLoad ?
            <LoadingMeetings
                loadingMessage="Loading meeting polls..."
                loaderSize="medium"
            />
            :
            <Column>
                {sortedPollList.length !== 0 &&
                    <Row alignmentHorizontal="justify" wrap="down" spacingInset="medium">
                        <Row spacing="small">
                            <Text id={POLL_SORT_BY_LABEL_ID} type="h200">Sort by</Text>
                            <Select
                                size="medium"
                                width="180px"
                                value={sortOption}
                                onChange={setSortOption}
                                aria-labelledby={POLL_SORT_BY_LABEL_ID}
                            >
                                <SelectOption value={POLL_SORT_BY.CREATE_TIME} label="Time created" />
                                <SelectOption value={POLL_SORT_BY.TITLE} label="Title" />
                                <SelectOption value={POLL_SORT_BY.STATUS} label="Status" />
                                <SelectOption value={POLL_SORT_BY.DUE_DATE} label="Due date" />
                                <SelectOption value={POLL_SORT_BY.VOTES_RESPONSE_RATIO} label="Votes" />
                            </Select>
                        </Row>
                        {!refreshPolls && timezoneValue ?
                            <Row width="190px" height="24px" alignmentHorizontal="left" aria-live={isRefreshed ? "polite" : "off"}>
                                <Button
                                    id="refresh-button"
                                    type="icon"
                                    size="small"
                                    label={`Refresh polls. Last updated at ${showCurrentTime()}`}
                                    onClick={onClickRefresh}
                                >
                                    <Text type="b300" alignment="left" color="secondary">
                                        {`Updated at ${showCurrentTime()} `}
                                         <Icon tokens={refreshTokens} />
                                    </Text>
                                </Button>
                            </Row>
                            :
                            <Row width="190px" height="24px" alignmentHorizontal="center">
                                <Loader size="small" />
                            </Row>
                        }
                    </Row>
                }
                {sortedPollList.length === 0 &&
                    <Column alignmentHorizontal="center">
                        <img
                            src={illustrationDashboardSvg}
                            style={{height: "350px", width: "350px"}}
                            alt=""
                        />
                        <Row alignmentHorizontal="center">
                            <Button
                                type="tertiary"
                                size="large"
                                href={WORKFLOWS.POLL_CREATE.HREF}
                                onClick={(event) => {handleChangePage(event, WORKFLOWS.POLL_CREATE)}}
                            >
                                Create meeting poll
                            </Button>
                        </Row>
                    </Column>
                }
                <ul id="list-of-polls">
                    {currentPolls.map((poll) => (
                        <PollDashboardTile
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            organizerPreferredDate={organizerPreferredDate}
                            poll={poll}
                            showResultsPanel={showResultsPanel}
                            onOpenResults={onOpenResults}
                            onCloseResults={onCloseResults}
                            onRedirectUpdate={onRedirectUpdate}
                        />
                    ))}
                </ul>
                {sortedPollList.length !== 0 &&
                    <PollDashboardPagination
                        pollsPerPage={pollsPerPage}
                        totalPolls={sortedPollList.length}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </Column>
    );
};

export default PollDashboard;