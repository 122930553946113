/**
 * Takes an object of props and filters them down to only the props with names
 * that start with one of a given set of prefixes.
 */
const filterPropsByPrefix = (props, prefixes) =>
  Object.keys(props)
    .filter(name => prefixes.some(prefix => name.indexOf(prefix) === 0))
    .reduce((result, name) => {
      result[name] = props[name]
      return result
    }, {})

export default filterPropsByPrefix
