import React from "react"
import PropTypes from "prop-types"
import {
  isOptionValueSelected,
  getOptionsFromChildren,
  getNonOptionsFromChildren,
} from "./utils"
import OptionList, { OptionListItem } from "../_option-list"
import HighlightSubstring from "../highlight-substring"

class SelectOptionList extends React.Component {
  static propTypes = {
    /**
     * If set to true then the input will grab focus as soon as it's mounted.
     *
     * This uses HTML's native `autofocus` attribute. Use this sparingly as
     * there are [common accessibility pitfalls](https://www.brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/)
     * associated with this behavior.
     */
    autoFocus: PropTypes.bool,
    children: PropTypes.node,
    defaultPreselectedIndex: PropTypes.number,
    /**
     * Use this to fix the index of the "preselected" option. This will disable
     * the internal state used to track the preselected index.
     *
     * @added by MeetEx team (@camei @alvinyu)
     */
    preselectedIndex: PropTypes.number,
    forwardedRef: PropTypes.func,
    open: PropTypes.bool,
    searchQuery: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ]),
    /**
     * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
     * is passed as the first argument to the function.
     */
    onBlur: PropTypes.func,
    onChangePreselectedIndex: PropTypes.func,
    onClickOption: PropTypes.func,
    /**
     * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
     * is passed as the first argument to the function.
     */
    onFocus: PropTypes.func,
    /**
     * This is called when the component has focus and a keyboard key is
     * pressed down. The [keydown event](https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event)
     * is passed as the first argument to the function.
     */
    onKeyDown: PropTypes.func,
    /**
     * Use this optional attribute to manually set the id of the popup element that is being created.
     * If one is not specified, one will be uniquely generated.
     */
    popupId: PropTypes.string,
  }

  /**
   * Only update if the option list will continue to be open. This prevents the
   * list contents from changing while the Select is in the process of closing,
   * which can be jarring to the user.
   */
  shouldComponentUpdate(nextProps) {
    return nextProps.open
  }

  /**
   * Disable mouse interaction with any non-option children becase a) it would
   * blur the select and cause it to close and b) we really don't want anyone
   * including interactive elements here because they'll be innaccessible to
   * keyboard users.
   *
   * NOTE: a `pointerEvents: none` style on the non-option children container is
   * not enough to prevent a blur event on the option list, we have to actually
   * prevent the event's default behavior.
   */
  onMouseDownNonOptionChildren = event => {
    event.preventDefault()
  }

  renderCustomSearchHighlightSubstring = option => () => (
    <HighlightSubstring match={this.props.searchQuery}>
      {option.label}
    </HighlightSubstring>
  )

  render() {
    const props = this.props
    const options = getOptionsFromChildren(props.children)
    const nonOptionChildren =
      options.length > 0 ? [] : getNonOptionsFromChildren(props.children)
    return options.length ? (
      <OptionList
        autoFocus={props.autoFocus}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        defaultPreselectedIndex={props.defaultPreselectedIndex}
        preselectedIndex={props.preselectedIndex}
        onChangePreselectedIndex={props.onChangePreselectedIndex}
        multiSelectable={Array.isArray(props.value)}
        ref={props.forwardedRef}
        popupId={props.popupId}
      >
        {options.map(option => (
          <OptionListItem
            {...option.passThroughProps}
            key={option.value}
            selected={isOptionValueSelected(props.value, option.value)}
            onClick={() => props.onClickOption(option.value)}
            label={option.label}
            disabled={option.disabled}
          >
            {// If this is a searchable Select and no render override has been
            // passed, use our own query text rendering
            props.searchQuery && option.children === undefined
              ? this.renderCustomSearchHighlightSubstring(option)
              : option.children}
          </OptionListItem>
        ))}
      </OptionList>
    ) : nonOptionChildren.length ? (
      <div onMouseDown={this.onMouseDownNonOptionChildren}>
        {nonOptionChildren}
      </div>
    ) : null
  }
}

function forwardRef(props, ref) {
  return <SelectOptionList {...props} forwardedRef={ref} />
}

forwardRef.displayName = "SelectOptionList"

export default React.forwardRef(forwardRef)
