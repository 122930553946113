import React from "react";
import { useSelector } from "react-redux";

import Divider from "@amzn/meridian/divider";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Box from "@amzn/meridian/box";
import Icon from "@amzn/meridian/icon"
import Tag from "@amzn/meridian/tag";
import clockTokens from "@amzn/meridian-tokens/base/icon/clock";
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil";
import userTokens from "@amzn/meridian-tokens/base/icon/user";

import { convertEpoch } from "../../meeting-scheduler/meeting-scheduler-utils";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { getTimezonesList } from "../../../sagas/selector";
import { STEP } from "../ooto-scheduler-constants";
import { sortAttendees } from "../ooto-scheduler-utils";
import { SCREEN_SIZE, TIME_CONSTANT } from "../../shared/shared-constants";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";

const OOTOReviewCard = (props) => {
    const onChangeStep = props.onChangeStep;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const meetingDetails = useSelector(props.meetingDetailsSelector);
    const identity = props.identity;
    const attendees = sortAttendees(props.attendees, identity.email);
    const timeFormat = props.timeFormat;

    const timezones = useSelector(getTimezonesList);
    const timezoneValue = meetingDetails.startTimezone && meetingDetails.startTimezone.id;
    const primaryBrowserTimezoneId = props.primaryBrowserTimezoneId;
    const browserTimezoneName = timezoneIdToDisplayName(timezones, timezoneValue);

    // Datetime format and date format
    const startTime = meetingDetails && meetingDetails.time && meetingDetails.time.startTime;
    const endTime = meetingDetails && meetingDetails.time && meetingDetails.time.endTime;
    const formatDateTime = (time, format) => {
        return convertEpoch(time, format, primaryBrowserTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR);
    };
    const displayedStartTime = formatDateTime(startTime, "time");
    const displayedEndTime = formatDateTime(endTime, "time") === "12:00 AM" ?
        formatDateTime(endTime - TIME_CONSTANT.ONE_MIN_IN_SEC, "time") :
        formatDateTime(endTime, "time");
    const displayedStartDate = formatDateTime(startTime, "shortWeekdayDate");
    const displayedEndDate = formatDateTime(endTime, "time") === "12:00 AM" ?
        formatDateTime(endTime - TIME_CONSTANT.ONE_MIN_IN_SEC, "shortWeekdayDate") :
        formatDateTime(endTime, "shortWeekdayDate");

    const formatTimezoneName = () => {
        return browserTimezoneName || "";
    };

    return(
        <Column
            spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "medium none" : "medium"}
            height="fill"
            maxHeight="400px"
            alignmentHorizontal="center"
        >
            <Box
                type="outline"
                width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "400px" : "100%"}
                height="fill"
            >
                <Row
                    spacing="medium"
                    spacingInset="300 medium"
                    width="100%"
                    alignmentHorizontal="left"
                    backgroundColor="#F9FAFA"
                >
                    <Text type="h100">Event details</Text>
                </Row>
                <section aria-label={`Time: ${formatDateTime(startTime, "longWeekdayDateYear")},
                                                    ${displayedStartTime} to
                                                    ${displayedEndTime}
                                                    ${formatTimezoneName()}
                                                    `}>
                    <Column spacing="xsmall" spacingInset="medium">
                        <Row alignmentHorizontal="justify" spacingInset="none">
                            {displayedStartDate === displayedEndDate ?
                                <Row alignmentVertical="top" spacing="small">
                                    <Icon tokens={clockTokens} />
                                    <Column spacing="small">
                                        <Row spacingInset="none xxsmall" spacing="xsmall">
                                            <Text type="b200">
                                                <b>{displayedStartTime}&nbsp;-&nbsp;{displayedEndTime}</b>
                                            </Text>
                                            <Text type="b100" color="secondary">
                                                &nbsp;{formatTimezoneName()}
                                            </Text>
                                        </Row>
                                        <Row spacingInset="none xxsmall">
                                            <Text type="b200">
                                                {displayedEndDate}
                                            </Text>
                                        </Row>
                                    </Column>
                                </Row>
                                : // for multiple days time format
                                <Row alignmentVertical="top" spacing="small">
                                    <Icon tokens={clockTokens} />
                                    <Column spacing="small">
                                        <Row spacingInset="xxsmall" spacing="xsmall">
                                            <Text type="b200">
                                                <b>{displayedStartTime}</b>
                                            </Text>
                                            <Text type="b100" color="secondary">
                                                &nbsp;{formatTimezoneName()}
                                            </Text>
                                        </Row>
                                        <Row spacingInset="none xxsmall">
                                            <Text type="b200">
                                                {displayedStartDate}
                                            </Text>
                                        </Row>
                                    </Column>
                                    <Column spacing="small">
                                        <Row spacingInset="xxsmall" spacing="xsmall">
                                            <Text type="b200">
                                                <b>to&nbsp;&nbsp;{displayedEndTime}</b>
                                            </Text>
                                            <Text type="b100" color="secondary">
                                                &nbsp;{formatTimezoneName()}
                                            </Text>
                                        </Row>
                                        <Row spacingInset="none none none 450">
                                            <Text type="b200">
                                                {displayedEndDate}
                                            </Text>
                                        </Row>
                                    </Column>
                                </Row>
                            }
                            <Button
                                type="icon"
                                size="xsmall"
                                label="Edit meeting date or time"
                                onClick={() => onChangeStep(STEP.NOTIFY_PEOPLE)}
                            >
                                <Icon tokens={pencilTokens} />
                            </Button>
                        </Row>
                    </Column>
                </section>
                <Row spacingInset="none medium">
                    <Divider size="small"/>
                </Row>
                <section aria-label={`Attendees: ${attendees.length === 1 ? "1 person will be notified" : attendees.length.toString() + " people will be notified"}`}>
                    <Row spacing="small" spacingInset="small medium" height="fill">
                        <Icon tokens={userTokens} />
                        <Column width="stretch" spacing="small" spacingInset="xxsmall">
                            <Row spacing="xsmall" height="20px">
                                <Tag type="neutral">
                                    {attendees.length === 1 ? "1 person will be notified" : attendees.length.toString() + " people will be notified"}
                                </Tag>
                            </Row>
                        </Column>
                        <Button
                            type="icon"
                            size="xsmall"
                            label="Edit meeting attendees"
                            onClick={() => onChangeStep(STEP.NOTIFY_PEOPLE)}
                        >
                            <Icon tokens={pencilTokens} />
                        </Button>
                    </Row>
                    <Row spacingInset="none 450 small large" height="fill">
                        <Column spacingInset="none none none xxsmall" width="stretch">
                            <Column spacing="none" spacingInset="none none none 400" height="fill" maxHeight="75px" overflowY="auto">
                                {attendees.map((attendee) => {
                                    return (
                                        <Row key={attendee.email} spacing="xsmall">
                                            <Column width="fit">
                                                {attendee.isPointOfContact ?
                                                    <Text type="b100" breakWord={true}>{attendee.name} <b>(Point of Contact)</b></Text>
                                                    :
                                                    <Text type="b100" breakWord={true}>{attendee.name}</Text>
                                                }
                                            </Column>
                                        </Row>
                                    )
                                })}
                            </Column>
                        </Column>
                    </Row>
                </section>
            </Box>
        </Column>
    );
};

export default OOTOReviewCard;