const rtlMap = {
  left: "right",
  right: "left",
  borderLeft: "borderRight",
  borderRight: "borderLeft",
}

const getRtl = str =>
  Object.prototype.hasOwnProperty.call(rtlMap, str) ? rtlMap[str] : str

const horizontalDirectionMap = {
  start: "left",
  end: "right",
}

const getHorizontalDirection = str =>
  Object.prototype.hasOwnProperty.call(horizontalDirectionMap, str)
    ? horizontalDirectionMap[str]
    : str

export { getRtl, getHorizontalDirection }
