import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getIdentity,
    getReminderResponse,
    getSettingsTimeFormat,
    getTimezonesList
} from "../../../sagas/selector";
import { addAttendeeByEmail } from "../../people/actions";
import { sendNotification, setReminderResponse } from "../actions";

import Alert from "@amzn/meridian/alert";
import Sheet from "@amzn/meridian/sheet";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Heading from "@amzn/meridian/heading";
import Tooltip from "@amzn/meridian/tooltip";
import Tab, { TabGroup } from "@amzn/meridian/tab";
import Loader from "@amzn/meridian/loader";

import closeTokens from "@amzn/meridian-tokens/base/icon/close";
import refreshTokens from "@amzn/meridian-tokens/base/icon/refresh";

import pollPersonSvg from "../../../assets/icons/people/poll_person.svg";
import clockSvg from "../../../assets/icons/shared/clock.svg";
import successSvg from "../../../assets/icons/polls/reminder_success.svg";

import PollPendingCard from "../components/poll-pending-card";
import PollResultCard from "../components/poll-result-card";
import LoadingMeetings from "../../landing/components/loading-meeting";

import "../meeting-polls.css";

import {
    formatDateTime,
    renderAttendeeNameList,
    compareAttendee,
    processAvailabilityList,
    compareAvailabilityWithAvailableAttendees
} from "../poll-utils";
import {
    RESULT_PAGE_SIZE,
    REMINDER_STATUS
} from "../poll-constants";
import { ALERTS, SCREEN_SIZE } from "../../shared/shared-constants";

const PollResultsPanel = (props) => {
    const [currentTab, setCurrentTab] = useState("Responded");
    const [refresh, setRefresh] = useState(false);

    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const pollDetails = props.pollDetails;
    const sortedAttendeeList = useMemo(() => (pollDetails && pollDetails.attendees) ? pollDetails.attendees.sort(compareAttendee) : [], [pollDetails]);
    const availabilityList = useMemo(() => (pollDetails && pollDetails.availability && sortedAttendeeList) ?
        processAvailabilityList(pollDetails.availability, sortedAttendeeList).sort(compareAvailabilityWithAvailableAttendees) : [], [pollDetails, sortedAttendeeList]);
    // const reminderTime = pollDetails.reminderTime;
    const timezone = useMemo(() => pollDetails && pollDetails.timezone, [pollDetails]);

    const timezones = useSelector(getTimezonesList);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const identity = useSelector(getIdentity);
    const reminderResponse = useSelector(getReminderResponse);

    const [reminderStatus, setReminderStatus] = useState(REMINDER_STATUS.DEFAULT);

    const dispatch = useDispatch();

    const showCurrentTime = () => {
        const today = Date.now() / 1000;
        return formatDateTime(today, "time", timezone, timeFormat);
    };

    const [refreshTime, setRefreshTime] = useState(showCurrentTime());

    const onClickRefresh = () => {
        setRefresh(true);
        setRefreshTime(showCurrentTime());

        // Retrieve the poll again
        props.onGetPoll(props.pollID);

        setTimeout(function() {
            setRefresh(false);
        }, 1000);
    };

    const respondedAttendeeList = (sortedAttendeeList && sortedAttendeeList.filter((attendee) => attendee.response === true)) || [];

    const renderPollResultTab = (tab, screenSizeBreakpoint) => {
        switch (tab) {
            case "Responded":
                return renderRespondedTab(screenSizeBreakpoint);
            case "Pending":
                return renderPendingTab(screenSizeBreakpoint);
            default:
                return (
                    <Loader />
                );
        }
    };

    const renderRefreshButton = () => {
        return (
            <React.Fragment>
                {refresh ?
                    <Row alignmentHorizontal="center" height="24px" width="160px">
                        <Loader size="medium" />
                    </Row>
                    :
                    <Row alignmentHorizontal="left" height="24px">
                        <Button
                            id="refresh-button"
                            type="icon"
                            onClick={onClickRefresh}
                            size="small"
                            label={`Refresh poll results. Last updated at ${refreshTime}`}
                        >
                            <Text type="b200" color="secondary">
                                Updated at {refreshTime}&nbsp;
                                <Icon tokens={refreshTokens} />
                            </Text>
                        </Button>
                    </Row>
                }
            </React.Fragment>
        );
    };

    const renderRespondedTab = (screenSizeBreakpoint) => {
        return(
            <Column>
                <Row alignmentHorizontal="justify" wrap="down">
                    <Row>
                        <Row spacing="small">
                            <img src={pollPersonSvg} height="16px" width="16px" alt="" />
                            <Tooltip
                                id="attendee-name"
                                position="bottom"
                                title={renderAttendeeNameList(sortedAttendeeList)}
                                maxWidth="200px"
                            >
                                <button class="attendee-button" aria-describedby="attendee-name">
                                    {`${sortedAttendeeList.length} attendee${sortedAttendeeList.length === 1 ? "" : "s"}`}
                                </button>
                            </Tooltip>
                        </Row>
                        <Row spacing="small">
                            <img src={clockSvg} height="16px" width="16px" alt="" />
                            <Text type="b200">
                                {`${availabilityList.length} time slot${availabilityList.length === 1 ? "" : "s"}`}
                            </Text>
                        </Row>
                    </Row>
                    {renderRefreshButton()}
                </Row>
                {availabilityList && availabilityList.length > 0 && availabilityList.map((availability, index) =>
                    <PollResultCard
                        identity={identity}
                        pollDetails={pollDetails}
                        screenSizeBreakpoint={screenSizeBreakpoint}
                        attendeeList={sortedAttendeeList}
                        respondedAttendeeList={respondedAttendeeList}
                        availability={availability}
                        timezone={timezone}
                        timezones={timezones}
                        timeFormat={timeFormat}
                        index={index}
                    />
                )}
            </Column>
        )
    };

    const renderPendingTab = (screenSizeBreakpoint) => {
        return(
            <Column>
                {sortedAttendeeList.length > respondedAttendeeList.length &&
                    <Column alignmentHorizontal="right">
                        <Row
                            alignmentHorizontal="right"
                            height="32px"
                            spacing="xsmall"
                        >
                            <Button
                                type="primary"
                                size="medium"
                                label="Send reminder to all the attendees pending response"
                                onClick={onSendReminderToAll}
                                minWidth={RESULT_PAGE_SIZE.REMINDER_BUTTON_MIN_WIDTH}
                            >
                                Remind all
                            </Button>
                            <Column width="16px">
                                {reminderStatus === REMINDER_STATUS.LOADING ?
                                    <Loader size="small" aria-live="polite" label="Sending reminders.." />
                                    :
                                    reminderStatus === REMINDER_STATUS.SUCCESS &&
                                    <div aria-live="polite">
                                        <span class="visually-hidden">
                                            "Reminders successfully sent to all the attendees pending response"
                                        </span>
                                        <img src={successSvg} height="16px" width="16px" alt="" />
                                    </div>
                                }
                            </Column>
                        </Row>
                        <Column spacingInset="none large none none">
                            {reminderStatus === REMINDER_STATUS.FAILURE &&
                                <Alert type={ALERTS.MEETING_POLL_INVALID_REMINDER.type} size="small" aria-live="polite" aria-label="Reminders failed to send due to an error, try again later">
                                    <Text id={ALERTS.MEETING_POLL_INVALID_REMINDER.id}>
                                        {ALERTS.MEETING_POLL_INVALID_REMINDER.value}
                                    </Text>
                                </Alert>
                            }
                            {reminderStatus === REMINDER_STATUS.DUPLICATE &&
                                <Alert type={ALERTS.MEETING_POLL_DUPLICATE_REMINDER.type} size="small" aria-live="polite" aria-label="Reminders already sent">
                                    <Text id={ALERTS.MEETING_POLL_DUPLICATE_REMINDER.id}>
                                        {ALERTS.MEETING_POLL_DUPLICATE_REMINDER.value}
                                    </Text>
                                </Alert>
                            }
                        </Column>
                    </Column>
                }
                {sortedAttendeeList.map((attendee) => !attendee.response &&
                    <PollPendingCard
                        identity={identity}
                        screenSizeBreakpoint={screenSizeBreakpoint}
                        attendee={attendee}
                        pollDetails={pollDetails}
                        timeFormat={timeFormat}
                    />
                )}
            </Column>
        )
    };

    const onSendReminderToAll = () => {
        if (reminderStatus !== REMINDER_STATUS.LOADING) {
            // We use empty array to flag the notification for all the attendee pending response
            setReminderStatus(REMINDER_STATUS.LOADING);
            let toAddresses = [];
            if (pollDetails?.attendees !== undefined) {
                pollDetails.attendees.forEach((attendee) => {
                    if (!attendee.response) {
                        toAddresses.push(attendee.email);
                    }
                });
            }
            dispatch(sendNotification("reminder", identity.username, pollDetails.pollID, toAddresses, "", "", "all"));
        }
    };

    useEffect(() => {
        const response = reminderResponse.all;
        if (reminderStatus === REMINDER_STATUS.LOADING) {
            setReminderStatus(response);
        }
        // eslint-disable-next-line
    }, [reminderResponse]);

    useEffect(() => {
        // Every time the page reloads, set response as default.
        setReminderStatus(REMINDER_STATUS.DEFAULT);
        dispatch(setReminderResponse("all", REMINDER_STATUS.DEFAULT));
        // eslint-disable-next-line
    }, [pollDetails]);

    useEffect(() => {
        if (pollDetails && pollDetails.attendees && props.open) {
            sortedAttendeeList.forEach((attendee) => {
                dispatch(addAttendeeByEmail(attendee, attendee.priority, attendee.external));
            });
        }
        // eslint-disable-next-line
    }, [dispatch, pollDetails, sortedAttendeeList]);

    return (
        <Sheet
            spacingInset="none"
            side="right"
            type={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.LG ? "push" : "overlay"}
            open={props.open}
            onClose={props.onClose}
        >
            <Row alignmentHorizontal="right" spacingInset="small small none none">
                <Button onClick={props.onClose} type="icon" label="Close poll results panel">
                    <Icon tokens={closeTokens}>Close poll results panel</Icon>
                </Button>
            </Row>
            {pollDetails ?
                <Column
                    alignmentHorizontal="left"
                    width={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL ? "480px" : "320px"}
                    spacing="large"
                    spacingInset="small none small large"
                    minHeight={RESULT_PAGE_SIZE.SHEET_MIN_HEIGHT}
                >
                    <Heading level={4} id="title" tabIndex="-1" className="responsive-text">
                        {pollDetails.subject}
                    </Heading>
                    <TabGroup
                        value={currentTab}
                        onChange={setCurrentTab}
                        type="default"
                        fill="none"
                    >
                        <Tab value="Responded">
                            Responded ({respondedAttendeeList.length})
                        </Tab>
                        <Tab value="Pending">
                            Pending ({sortedAttendeeList.length - respondedAttendeeList.length})
                        </Tab>
                    </TabGroup>
                    {renderPollResultTab(currentTab, screenSizeBreakpoint)}
                </Column>
                :
                <LoadingMeetings
                    loadingMessage="Loading poll details..."
                    loaderSize="medium"
                />
            }
        </Sheet>
    );
};

export default PollResultsPanel;
