import * as Actions from "../actions/action-types";
import * as AttendeeActions from "../../people/actions/action-types";

import { STEP } from "../livestream-constants";
import { ATTENDEE_PRIORITY } from "../../people/people-constants";
import { SUGGESTION_VIEW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";

const initialState = {
    isCreatingMeeting: false,
    currentMeeting: {
        subject: "",
        location: "",
        organizer: "",
        body: {
            value: "",
            type: "html"
        },
        requiredAttendees: [],
        optionalAttendees: [],
        time: {
            startTime: "",
            endTime: "",
        },
        startTimezone: {
            id: ""
        },
        endTimezone: {
            id: ""
        },
        recurrence: null,
        resources: [],
        isPrivate: false,
        isResponseRequested: true,
        status: "busy",
        extendedProperties: {},
    },
    // master: false,
    step: STEP.EVENT_REQUIREMENTS,
    suggestionViewType: SUGGESTION_VIEW_TYPE.LIST,
    selectedStations: [],
    // loadMeeting: false,
};

function livestreamReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.UPDATE_CURRENT_MEETING:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    ...action.meeting
                }
            };
        case Actions.SAVE_DRAFT:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    ...action.meeting
                }
            };
        case Actions.CLEAR_DRAFT:
            return {
                ...state,
                ...initialState
            };
        case AttendeeActions.ADD_ATTENDEE:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    organizer: action.attendee.isOrganizer ?
                        action.attendee.email
                        :
                        state.currentMeeting.organizer,
                    requiredAttendees: (action.attendee.priority === ATTENDEE_PRIORITY.REQUIRED ||
                        action.attendee.priority === ATTENDEE_PRIORITY.ESSENTIAL) &&
                        action.attendee.email &&
                        action.attendee.email !== state.currentMeeting.organizer &&
                        !state.currentMeeting.requiredAttendees.find(
                            (requiredAttendee) => requiredAttendee.email === action.attendee.email) ?
                        [
                            ...state.currentMeeting.requiredAttendees,
                            {email: action.attendee.email, displayName: action.attendee.name}

                        ]
                        :
                        state.currentMeeting.requiredAttendees,
                    optionalAttendees: action.attendee.priority === ATTENDEE_PRIORITY.OPTIONAL &&
                        action.attendee.email &&
                        action.attendee.email !== state.currentMeeting.organizer &&
                        !state.currentMeeting.optionalAttendees.find(
                            (optionalAttendee) => optionalAttendee.email === action.attendee.email) ?
                        [
                            ...state.currentMeeting.optionalAttendees,
                            {email: action.attendee.email, displayName: action.attendee.name}
                        ]
                        :
                        state.currentMeeting.optionalAttendees,
                }
            };
        case AttendeeActions.UPDATE_ATTENDEE:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    requiredAttendees: action.attendee.priority === ATTENDEE_PRIORITY.OPTIONAL ?
                        state.currentMeeting.requiredAttendees.filter(
                            (requiredAttendee) => requiredAttendee.email !== action.attendee.email)
                        :
                        state.currentMeeting.requiredAttendees.find(
                            (requiredAttendee) => requiredAttendee.email === action.attendee.email)  ||
                                action.attendee.email === state.currentMeeting.organizer ?
                            state.currentMeeting.requiredAttendees
                            :
                            [...state.currentMeeting.requiredAttendees, {email: action.attendee.email}],
                    optionalAttendees: action.attendee.priority === ATTENDEE_PRIORITY.OPTIONAL ?
                        state.currentMeeting.optionalAttendees.find(
                            (optionalAttendee) => optionalAttendee.email === action.attendee.email) ||
                                action.attendee.email === state.currentMeeting.organizer ?
                            state.currentMeeting.optionalAttendees
                            :
                            [...state.currentMeeting.optionalAttendees, {email: action.attendee.email}]
                        :
                        state.currentMeeting.optionalAttendees.filter(
                            (optionalAttendee) => optionalAttendee.email !== action.attendee.email)
                }
            };
        case AttendeeActions.REMOVE_ATTENDEE:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    requiredAttendees: state.currentMeeting.requiredAttendees.filter(
                        (requiredAttendee) => requiredAttendee.email !== action.attendee.email),
                    optionalAttendees: state.currentMeeting.optionalAttendees.filter(
                        (optionalAttendee) => optionalAttendee.email !== action.attendee.email),
                }
            };
        case AttendeeActions.CLEAR_ATTENDEES:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    requiredAttendees: state.currentMeeting.requiredAttendees.filter(
                        (requiredAttendee) => requiredAttendee.email === state.currentMeeting.organizer),
                    optionalAttendees: []
                }
            };
        case Actions.SEARCH_SUGGESTION:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    isAllDayEvent: action.isAllDayEvent,
                    recurrence: action.query.recurrence,
                    startTimezone: action.query.timezone,
                    endTimezone: action.query.timezone,
                }
            };
        case Actions.SELECT_SUGGESTION:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    time: {
                        startTime: action.suggestion.startTime,
                        endTime: action.suggestion.endTime
                    },
                    resources: action.resources || state.currentMeeting.resources,
                    location: action.resources && action.resources.length ? action.resources.map((loc) => loc.name).reduce((acc, cur) => `${acc}, ${cur}`) : state.currentMeeting.location,
                }
            };
        case Actions.SET_SUGGESTION_VIEW_TYPE:
            return {
                ...state,
                suggestionViewType: action.suggestionViewType
            };
        case Actions.SELECT_STATIONS:
            return {
                ...state,
                selectedStations: action.selectedStations
            };
        case Actions.CHANGE_STEP:
            return {
                ...state,
                step: action.step
            };
        case Actions.SET_MASTER:
            return {
                ...state,
                master: action.master
            };
        case Actions.SET_CREATING_MEETING_STATE:
            return {
                ...state,
                isCreatingMeeting: action.isCreatingMeeting
            };
        case Actions.UPDATE_RECURRENCE:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    recurrence: action.recurrence,
                }
            };
        case Actions.SET_LOAD_MEETING:
            return {
                ...state,
                loadMeeting: action.loadMeeting
            };
        default:
            return state;
    }
}

export default livestreamReducer;
