import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getIdentity,
    getCurrentMeeting,
    getMeetingStep,
    getMasterState,
    getPeopleSuggestions as getPeopleSuggestionsSelector,
    getGroupSuggestions as getGroupSuggestionsSelector,
    getSuggestions as getMeetingSuggestionsSelector,
    getAttendees,
    getBuildings,
    getFindRooms,
    getMeetingDetails,
    getMeetingList,
    getRooms,
    getLocations,
    getFavorites,
    getTimezonesList,
    getChimeUniqueIds,
    getChimeUser,
    isSearching as isSearchingSelector,
    getSuggestionViewType,
    getWorkHours,
    getLoadMeeting,
    getMeetingRecurrencePattern,
    getSettingsPrimaryTimezone,
    getSettingsSchedulingMode,
    getSettingsTimeFormat,
    getSettingsDateFormat,
    getSettingsEnableAutoCallParticipants,
    getSettingsBuilding,
    getSettingsFloor,
    getSettingsUsePersonalChimePin,
    getSettingsChimeEmail,
    getSettingsWorkingHours,
    getSettingsMinimumSeats,
    getSettingsCamera,
    getSettingsDisplay,
    getSettingsNoRestricted,
    getSettingsNoManaged,
} from "../../../sagas/selector";
import {
    changeStep,
    searchSuggestion,
    selectSuggestion,
    setLoadMeeting,
    setSuggestionViewType,
    updateCurrentMeeting,
    updateRecurrence,
    clearDraft as clearMeetingDetails,
} from "../actions";
import {
    addAttendee,
    updateAttendee,
    removeAttendee,
    getRASDataForPerson,
    getRASDataForGroup,
    getPeopleSuggestions,
    clearPeopleSuggestions,
    getGroupSuggestions,
    clearGroupSuggestions,
    addAttendeeByEmail,
    clearDraft as clearDraftPeople,
} from "../../people/actions";
import { SET_MEETING_DETAILS } from "../../shared/actions/action-types";
import {
    createMeeting,
    getSuggestions as getMeetingSuggestions,
    getMeetingDetailsAndSave,
    getMeetingRecurrencePatternAndSave,
} from "../../shared/actions";
import {
    hideToast,
    showToast,
} from "../../shared/toasts/actions";
import {
    createChimePin,
    getUserByEmail,
    saveChimePin,
} from "../../shared/chime/actions";
import {
    loadFloors,
    updateFindRooms,
    addRoom,
    removeRoom,
    addLocation,
    removeLocation,
    addRoomByEmail,
} from "../../shared/locations/actions";
import {
    addFavorite,
    removeFavorite,
} from "../../shared/favorites/actions";

import Responsive from "@amzn/meridian/responsive";

import MeetingForm from "../components/meeting-form";
import MeetingsToaster from "../../shared/toasts/containers/toast-container";

import { generateNewMeetingId, getChimePinFromChimeAttendee, isOneTimeChimePinAttendee } from "../../shared/meeting-utils";
import { getUrlParameters } from "../../shared/permalink/permalink-utils";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { FLOW_TYPE, REDIRECT_STEP, FIND_GROUP, SUGGESTION_VIEW_TYPE } from "../meeting-scheduler-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { chimeEmailToAttendee, rasUserToAttendee, isValidEmail } from "../../people/people-utils";
import { ATTENDEE_PRIORITY, ATTENDEE_RESPONSE, ATTENDEE_TYPE } from "../../people/people-constants";
import { getUserDefaultLocation } from "../../shared/locations/locations-utils";
import { LOCATION_DEFAULTS, LOCATION_FLOOR_ANY } from "../../shared/locations/locations-constants";
import { removeMeetingsInfo } from "../../shared/body/body-utils";
import { SCHEDULING_MODE, WORKING_HOURS } from "../../shared/settings/settings-constants";
import { getTimeMinutes } from "../../shared/settings/settings-utils";

const MeetingFormDetails = (props) => {
    const flowType = props.flowType;
    const identity = useSelector(getIdentity);
    const meeting = useSelector(getCurrentMeeting);
    const meetingList = useSelector(getMeetingList);
    const meetingDetails = useSelector(getMeetingDetails); // information to edit existing meeting
    const currentStep = useSelector(getMeetingStep);
    const masterState = useSelector(getMasterState);
    const attendees = useSelector(getAttendees).filter((attendee) => attendee.type !== ATTENDEE_TYPE.CHIME);
    const findRooms = useSelector(getFindRooms);
    const buildings = useSelector(getBuildings);
    const rooms = useSelector(getRooms);
    const locations = useSelector(getLocations);
    const peopleSuggestions = useSelector(getPeopleSuggestionsSelector);
    const groupSuggestions = useSelector(getGroupSuggestionsSelector);
    const meetingSuggestions = useSelector(getMeetingSuggestionsSelector);
    const suggestionViewType = useSelector(getSuggestionViewType);
    const favorites = useSelector(getFavorites);
    const isSearchingMeetingSuggestions = useSelector(isSearchingSelector);
    const timezones = useSelector(getTimezonesList);
    const chimeUniqueIds = useSelector(getChimeUniqueIds);
    const chimeUser = useSelector(getChimeUser);
    const exchangeWorkHours = useSelector(getWorkHours);
    // Redux level state variable to track if we are loading a meeting stored in the meetingDetails redux state
    const loadMeeting = useSelector(getLoadMeeting);
    const meetingRecurrencePattern = useSelector(getMeetingRecurrencePattern);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const schedulingMode = useSelector(getSettingsSchedulingMode);
    const enableAutoCallParticipants = useSelector(getSettingsEnableAutoCallParticipants);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const dateFormat = useSelector(getSettingsDateFormat);
    const userSettingsBuilding = useSelector(getSettingsBuilding);
    const userSettingsFloor = useSelector(getSettingsFloor);
    const userSettingsMinimumSeats = useSelector(getSettingsMinimumSeats);
    const userSettingsCamera = useSelector(getSettingsCamera);
    const userSettingsDisplay = useSelector(getSettingsDisplay);
    const userSettingsNoRestricted = useSelector(getSettingsNoRestricted);
    const userSettingsNoManaged = useSelector(getSettingsNoManaged);
    const usePersonalChimePin = useSelector(getSettingsUsePersonalChimePin);
    const chimeEmail = useSelector(getSettingsChimeEmail);
    const settingsWorkHours = useSelector(getSettingsWorkingHours);

    const dispatch = useDispatch();

    const onShowToast = (toast) => dispatch(showToast(toast));
    const onHideToast = (toast) => dispatch(hideToast(toast));
    const onChangeStep = (step) => dispatch(changeStep(step));
    const onCreateMeeting = (meeting) => dispatch(createMeeting({meeting: meeting, sendInvitations: true}, "/"));
    const onUpdateCurrentMeeting = (meeting) => dispatch(updateCurrentMeeting(meeting));
    const onAddAttendee = (attendee) => dispatch(addAttendee(attendee));
    const onUpdateAttendee = (attendee) => dispatch(updateAttendee(attendee));
    const onRemoveAttendee = (attendee) => dispatch(removeAttendee(attendee));
    const onLoadFloors = (buildingCode) => dispatch(loadFloors(buildingCode));
    const onUpdateFindRooms = (findRooms) => dispatch(updateFindRooms(findRooms));
    const onAddRoom = (room) => dispatch(addRoom(room));
    const onRemoveRoom = (room) => dispatch(removeRoom(room));
    const onAddLocation = (location) => dispatch(addLocation(location));
    const onRemoveLocation = (location) => dispatch(removeLocation(location));
    const onAddRoomByEmail = (roomEmail) => dispatch(addRoomByEmail(roomEmail));
    const onGetRASDataForPerson = (alias, addNewAttendee, name) => dispatch(getRASDataForPerson(alias, addNewAttendee, name));
    const onGetRASDataForGroup = (group) => dispatch(getRASDataForGroup(group));
    const onGetPeopleSuggestions = (query) => dispatch(getPeopleSuggestions(query));
    const onClearPeopleSuggestions = () => dispatch(clearPeopleSuggestions());
    const onGetGroupSuggestions = (query) => dispatch(getGroupSuggestions(query, FIND_GROUP.START, FIND_GROUP.SIZE));
    const onClearGroupSuggestions = () => dispatch(clearGroupSuggestions());
    const onAddFavorite = (favorite, alias) => dispatch(addFavorite(favorite, alias));
    const onRemoveFavorite = (favorite, alias) => dispatch(removeFavorite(favorite, alias));
    const onGetMeetingSuggestions = (suggestionRequest) => dispatch(getMeetingSuggestions(suggestionRequest));
    const onSearchSuggestion = (query, isAllDayEvent) => dispatch(searchSuggestion(query, isAllDayEvent));
    const onSelectSuggestion = (suggestion, resources) => dispatch(selectSuggestion(suggestion, resources));
    const onSetSuggestionViewType = (suggestionViewType) => dispatch(setSuggestionViewType(suggestionViewType));
    const onUpdateRecurrence = (recurrence) => dispatch(updateRecurrence(recurrence));
    const onSetLoadMeeting = useCallback((loadMeeting) => dispatch(setLoadMeeting(loadMeeting)), [dispatch]);
    const onGetMeetingRecurrencePatternAndSave = (entryID, mailbox) => dispatch(getMeetingRecurrencePatternAndSave(entryID, mailbox));

    const [addBuilding, setAddBuilding] = useState(false); // flowType === FLOW_TYPE.CREATE to add location later.
    // Component level state variable to track if we are loading an existing meeting into our standard workflow inputs.
    // If loadMeeting is true (came from a meeting details modal),
    // we are loading a meeting that is already in the meetingDetails redux state.
    // If loadMeeting is false AND flowType is UPDATE (came from an edit link directly),
    // then we still need to retrieve the meeting we want to load.
    const [loadExistingMeeting, setLoadExistingMeeting] = useState(flowType === FLOW_TYPE.UPDATE || loadMeeting);

    const [chimePin, setChimePin] = useState();
    const queryParams = getUrlParameters(WORKFLOWS.STANDARD_MEETING.HREF);
    const [invalidUserEmail, setInvalidUserEmail] = useState();
    // initial timezoneValue for updating a meeting, useEffect will set initial timezoneValue for creating a meeting
    const [timezoneValue, setTimezoneValue] = useState((meetingDetails.startTimezone && meetingDetails.startTimezone.id) || primaryTimezone);
    const [autoCall, setAutoCall] = useState(enableAutoCallParticipants);
    const [workHours, setWorkHours] = useState({
        startTime: getTimeMinutes(WORKING_HOURS.START_TIME),
        endTime: getTimeMinutes(WORKING_HOURS.END_TIME),
        days: WORKING_HOURS.DAYS.slice(1, -1),
    });

    const removeMeetingInfoFromBody = (body = {}) => {
        if (!body || !body.value) {
            return body;
        }

        return {
            ...body,
            value: removeMeetingsInfo(body.value)
        };
    };

    const loadAttendeeFromExistingMeeting = (attendee, priority, chimeAttendees) => {
        if (attendee.email && attendee.email.endsWith("@chime.aws")) {
            if (isOneTimeChimePinAttendee(attendee.email)) {
                // Only add chime to meeting if updating a meeting,
                // so we can generate a new one time pin for new meetings
                if (flowType === FLOW_TYPE.UPDATE) {
                    const pin = getChimePinFromChimeAttendee(attendee.email);
                    setChimePin(pin);
                    dispatch(saveChimePin({AllocatedPins: [{Pin: pin}]}));
                    onAddAttendee(chimeEmailToAttendee(`pin+${pin}@chime.aws`));
                }
            } else {
                setAutoCall(enableAutoCallParticipants);
                // Only add chime to meeting if updating a meeting,
                // so we can generate a new one time pin for new meetings
                if (flowType === FLOW_TYPE.UPDATE) {
                    if (chimeAttendees.length === 1) {
                        setChimePin(chimeUser.PersonalPIN);
                    }
                    onAddAttendee(chimeEmailToAttendee(attendee.email));
                }
            }
            // Do not add organizer again
        } else if (attendee.email !== identity.email) {
            dispatch(addAttendeeByEmail(attendee, priority));
        }
    };

    useLayoutEffect(() => {
        if (flowType !== FLOW_TYPE.POLL) {
            dispatch(clearMeetingDetails());
            dispatch(clearDraftPeople());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (settingsWorkHours && settingsWorkHours.days && settingsWorkHours.startTime && settingsWorkHours.endTime) {
            setWorkHours({
                days: settingsWorkHours.days,
                startTime: getTimeMinutes(settingsWorkHours.startTime),
                endTime: getTimeMinutes(settingsWorkHours.endTime),
            });
        } else if (exchangeWorkHours && !settingsWorkHours) {
            setWorkHours({
                ...exchangeWorkHours,
                days: WORKING_HOURS.DAYS,
            });
        }
    }, [exchangeWorkHours, settingsWorkHours]);

    useEffect(() => {
        if (flowType === FLOW_TYPE.UPDATE) {
            document.title = "Edit meeting - Amazon Meetings";
        } else {
            document.title = "Standard meeting - Amazon Meetings";
        }
    }, [flowType]);

    // Load in organizer
    useEffect(() => {
        if (identity && identity.username && attendees && !attendees.find((attendee) => attendee.isOrganizer)) {
            let organizer = rasUserToAttendee(identity, ATTENDEE_RESPONSE.ORGANIZER, ATTENDEE_PRIORITY.REQUIRED);
            dispatch(addAttendee(organizer));
        }
    }, [identity, attendees, dispatch]);

    // On create meeting, load in user's default location
    useEffect(() => {
        if (identity && identity.username && addBuilding && buildings) {
            const { userDefaultBuilding, userDefaultFloor } = getUserDefaultLocation(identity.site, buildings);

            dispatch(addLocation({
                minCapacity: LOCATION_DEFAULTS.MIN_CAPACITY,
                buildingCode: userDefaultBuilding,
                floor: userDefaultFloor,
                resourceList: LOCATION_DEFAULTS.RESOURCE_LIST,
                restrictedAllowed: LOCATION_DEFAULTS.RESTRICTED_ALLOWED,
                hiddenAllowed: LOCATION_DEFAULTS.HIDDEN_ALLOWED,
                thisBuildingOnly: LOCATION_DEFAULTS.THIS_BUILDING_ONLY,
            }));

            setAddBuilding(false);
        }
    }, [identity, flowType, buildings, addBuilding, dispatch]);

    // Load primaryBrowserTimezoneId for timezone selector and meeting request only for initial timezoneValue when creating a meeting
    useEffect(() => {
        if (!timezoneValue && flowType !== FLOW_TYPE.UPDATE) {
            setTimezoneValue(primaryTimezone);
        }
    }, [flowType, timezoneValue, primaryTimezone]);

    // generates a new chime pin if there isn't one
    useEffect(() => {
        if (identity && identity.email && chimeUniqueIds && !chimeUniqueIds.AllocatedPins && (flowType === FLOW_TYPE.CREATE || flowType === FLOW_TYPE.POLL)) {
            dispatch(createChimePin({entities: [{email: identity.email}]}));
            const generatedChimeBridge = chimeUniqueIds.AllocatedPins && chimeUniqueIds.AllocatedPins[0].Pin;
            onAddAttendee(chimeEmailToAttendee(`pin+${generatedChimeBridge}@chime.aws`));
        }
    // eslint-disable-next-line
    }, [identity, chimeUniqueIds, flowType, dispatch]);

    // get personal chime pin if chimeUser is empty.
    useEffect(() => {
        if (identity && identity.username && identity.email && Object.keys(chimeUser).length === 0) {
            dispatch(getUserByEmail({userEmail: chimeEmail ?? identity.email, username: identity.username}));
        }
    }, [identity, chimeEmail, chimeUser, dispatch]);

    useEffect(() => {
        // When creating a meeting, if there is no uniqueID OR we are loading a meeting, create a new uniqueID
        if (identity && identity.username && flowType === FLOW_TYPE.CREATE && (!meeting.uniqueID || loadMeeting)) {
            dispatch(updateCurrentMeeting(({uniqueID: generateNewMeetingId(identity.username)})));
            onSetLoadMeeting(false);
        }
    }, [identity, meeting.uniqueID, loadMeeting, flowType, dispatch, onSetLoadMeeting]);

    useEffect(() => {
        setAutoCall(enableAutoCallParticipants);
    }, [enableAutoCallParticipants]);

    // On edit meeting, load in the meeting details for the entry ID in the URL and redirect to the requested step if present
    useEffect(() => {
        if (identity && identity.username && flowType === FLOW_TYPE.UPDATE) {
            const stepIndex = window.location.href.indexOf("step=");
            if (stepIndex > 0) {
                const redirectStep = window.location.href.substring(stepIndex + 5).split("/")[0].toUpperCase().replace("-", "_");
                if (REDIRECT_STEP[redirectStep]) {
                    dispatch(changeStep(redirectStep));
                }
            }

            if (masterState || !meetingDetails.entryID) {
                const entryIdIndex = window.location.href.indexOf("entryID=");
                if (entryIdIndex > 0) {
                    const entryID = window.location.href.substring(entryIdIndex + 8);

                    const updateMeetingDetails = entryID.startsWith("i:") ? meetingDetails.entryID !== entryID : meetingDetails.uniqueID !== entryID;

                    if (updateMeetingDetails) {
                        dispatch(getMeetingDetailsAndSave(identity.email, entryID, false, "", "", SET_MEETING_DETAILS));
                    }
                }
            }
        };
    }, [identity, flowType, masterState, meetingDetails, dispatch]);

    // On load meeting, update the state with meeting details
    useEffect(() => {
        if (identity && identity.username && loadExistingMeeting && meetingDetails.entryID) {
            // Load attendees and locations excluding chime emails
            let chimeAttendees = meetingDetails.requiredAttendees.filter((attendee) => attendee.email.endsWith("@chime.aws"));
            if (chimeAttendees.length === 0) {
                setChimePin("none");
            }
            // Call createChimePin to have a generated ChimePin ready if one isn't present in the meeting invite
            if (chimeAttendees.length === 0 || (chimeAttendees.length === 1 && chimeAttendees[0].email === "meet@chime.aws")) {
                dispatch(createChimePin({entities: [{email: chimeEmail ?? identity.email}]}));
            }

            meetingDetails.requiredAttendees.forEach((attendee) => {
                loadAttendeeFromExistingMeeting(attendee, ATTENDEE_PRIORITY.REQUIRED, chimeAttendees);
            });
            meetingDetails.optionalAttendees.forEach((attendee) => {
                loadAttendeeFromExistingMeeting(attendee, ATTENDEE_PRIORITY.OPTIONAL, chimeAttendees);
            });
            meetingDetails.resources.forEach((resource) => {
                dispatch(addRoomByEmail(resource));
            });

            // Update the state with existing meeting details
            dispatch(updateCurrentMeeting({
                // Keep the same body if updating, otherwise remove the meeting info if present
                "body": flowType === FLOW_TYPE.UPDATE ? meetingDetails.body : removeMeetingInfoFromBody(meetingDetails.body),
                "location": meetingDetails.location || "",
                // Keep the same organizer if updating, otherwise use the current user's email as the organizer
                "organizer": flowType === FLOW_TYPE.UPDATE ? meetingDetails.organizer : identity.email,
                "isAllDayEvent": meetingDetails.isAllDayEvent,
                "isPrivate": meetingDetails.isPrivate,
                "isResponseRequested": meetingDetails.isResponseRequested,
                "status": meetingDetails.status,
                "subject": meetingDetails.subject,
                "startTimezone": meetingDetails.startTimezone,
                "endTimezone": meetingDetails.endTimezone,
            }));
            setLoadExistingMeeting(false);
        };
    // eslint-disable-next-line
    }, [identity, chimeEmail, flowType, loadExistingMeeting, setLoadExistingMeeting, chimeUser, meetingDetails, dispatch]);

    useEffect(() => {
        if (autoCall) {
            onAddAttendee(chimeEmailToAttendee("meet@chime.aws"));
        } else {
            onRemoveAttendee(chimeEmailToAttendee("meet@chime.aws"));
        }
    // eslint-disable-next-line
    }, [autoCall]);

    // useEffect for On query params load for Attendees, buildingCode, floor and meeting body
    useEffect(() => {
        if (identity && identity.username) {
            setInvalidUserEmail(false);
            // Load participantsByPriority
            if (queryParams.participantsByPriority !== undefined) {
                let userEmailsList = queryParams.participantsByPriority.split(",");
                userEmailsList.forEach((userEmail) => {
                    if (isValidEmail(userEmail)) {
                        if (!userEmail.endsWith("@chime.aws")) {
                            let attendee = {email: userEmail};
                            dispatch(addAttendeeByEmail(attendee, ATTENDEE_PRIORITY.REQUIRED));
                        }
                    } else {
                        setInvalidUserEmail(true);
                    }
                });
            }

            // Load Rooms & Floors
            if (queryParams.buildingCode !== undefined) {
                let buildingCodes = queryParams.buildingCode.split(",");
                let floors = queryParams.floor ? queryParams.floor.split(",") : undefined;
                buildingCodes.forEach((buildingCode, index) => {
                    const floor = (floors === undefined || isNaN(parseInt(floors[index]))) ? LOCATION_FLOOR_ANY : parseInt(floors[index]);
                    dispatch(addLocation({
                        minCapacity: LOCATION_DEFAULTS.MIN_CAPACITY,
                        buildingCode: buildingCode,
                        floor: floor,
                        resourceList: LOCATION_DEFAULTS.RESOURCE_LIST,
                        restrictedAllowed: LOCATION_DEFAULTS.RESTRICTED_ALLOWED,
                        hiddenAllowed: LOCATION_DEFAULTS.HIDDEN_ALLOWED,
                        thisBuildingOnly: LOCATION_DEFAULTS.THIS_BUILDING_ONLY,
                    }));
                });
            }

            // Load Meeting body
            if (queryParams.body !== undefined) {
                dispatch(updateCurrentMeeting({
                    body: {
                        value: queryParams.body,
                    }
                }));
            }
        }
    }, [identity, dispatch, queryParams.participantsByPriority, queryParams.buildingCode, queryParams.floor, queryParams.body]);

    useEffect(() => {
        if (schedulingMode) {
            onSetSuggestionViewType(schedulingMode === SCHEDULING_MODE.GRID_MODE ?
                SUGGESTION_VIEW_TYPE.GRID : SUGGESTION_VIEW_TYPE.LIST);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedulingMode]);

    return (
        <Responsive
            query="min-width"
            props={{
                screenSizeBreakpoint: {
                    default: SCREEN_SIZE.MOBILE_VIEW, // 360px
                    "1200px": SCREEN_SIZE.FULL_VIEW,
                    "900px": SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND,
                    "700px": SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR,
                    "550px": SCREEN_SIZE.PARTIAL_MOBILE_VIEW
                }
            }}
        >
            {(responsiveProps) =>
                <React.Fragment>
                    <MeetingForm
                        flowType={flowType}
                        screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                        mastheadSize={props.mastheadSize}
                        identity={identity}
                        meeting={meeting}
                        meetingList={meetingList}
                        meetingDetails={meetingDetails}
                        loadExistingMeeting={loadExistingMeeting}
                        currentStep={currentStep}
                        masterState={masterState}
                        attendees={attendees}
                        findRooms={findRooms}
                        buildings={buildings}
                        rooms={rooms}
                        locations={locations}
                        queryParams={queryParams}
                        invalidUserEmail={invalidUserEmail}
                        peopleSuggestions={peopleSuggestions}
                        groupSuggestions={groupSuggestions}
                        favorites={favorites}
                        meetingSuggestions={meetingSuggestions}
                        isSearchingMeetingSuggestions={isSearchingMeetingSuggestions}
                        timezones={timezones}
                        chimePin={chimePin}
                        setChimePin={setChimePin}
                        primaryTimezone={primaryTimezone}
                        timezoneValue={timezoneValue}
                        setTimezoneValue={setTimezoneValue}
                        autoCall={autoCall}
                        setAutoCall={setAutoCall}
                        chimeUniqueIds={chimeUniqueIds}
                        chimeUser={chimeUser}
                        workHours={workHours}
                        loadMeeting={loadMeeting}
                        meetingRecurrencePattern={meetingRecurrencePattern}
                        onShowToast={onShowToast}
                        onHideToast={onHideToast}
                        onChangeStep={onChangeStep}
                        onCreateMeeting={onCreateMeeting}
                        onUpdateCurrentMeeting={onUpdateCurrentMeeting}
                        onAddAttendee={onAddAttendee}
                        onUpdateAttendee={onUpdateAttendee}
                        onRemoveAttendee={onRemoveAttendee}
                        onLoadFloors={onLoadFloors}
                        onUpdateFindRooms={onUpdateFindRooms}
                        onAddRoom={onAddRoom}
                        onRemoveRoom={onRemoveRoom}
                        onAddLocation={onAddLocation}
                        onRemoveLocation={onRemoveLocation}
                        onAddRoomByEmail={onAddRoomByEmail}
                        onGetRASDataForPerson={onGetRASDataForPerson}
                        onGetRASDataForGroup={onGetRASDataForGroup}
                        onGetPeopleSuggestions={onGetPeopleSuggestions}
                        onClearPeopleSuggestions={onClearPeopleSuggestions}
                        onGetGroupSuggestions={onGetGroupSuggestions}
                        onClearGroupSuggestions={onClearGroupSuggestions}
                        onAddFavorite={onAddFavorite}
                        onRemoveFavorite={onRemoveFavorite}
                        onGetMeetingSuggestions={onGetMeetingSuggestions}
                        onSearchSuggestion={onSearchSuggestion}
                        onSelectSuggestion={onSelectSuggestion}
                        suggestionViewType={suggestionViewType}
                        onSetSuggestionViewType={onSetSuggestionViewType}
                        onUpdateRecurrence={onUpdateRecurrence}
                        onGetMeetingRecurrencePatternAndSave={onGetMeetingRecurrencePatternAndSave}
                        timeFormat={timeFormat}
                        dateFormat={dateFormat}
                        userSettingsBuilding={userSettingsBuilding}
                        userSettingsFloor={userSettingsFloor}
                        userSettingsMinimumSeats={userSettingsMinimumSeats}
                        userSettingsCamera={userSettingsCamera}
                        userSettingsDisplay={userSettingsDisplay}
                        userSettingsNoRestricted={userSettingsNoRestricted}
                        userSettingsNoManaged={userSettingsNoManaged}
                        usePersonalChimePin={usePersonalChimePin}
                        schedulingMode={schedulingMode}
                    />
                    <MeetingsToaster />
                </React.Fragment>
            }
        </Responsive>
    );
};

export default MeetingFormDetails;
