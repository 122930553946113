import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getIdentity,
    getFavorites,
    getTimezonesList,
    getPeopleSuggestions as getPeopleSuggestionsSelector,
    getGroupSuggestions as getGroupSuggestionsSelector,
    getSettingsPrimaryTimezone,
    getSettingsTimeFormat,
    getSettingsDateFormat,
    getChimeUser,
    getLocations,
    getSettingsUsePersonalChimePin,
    getSettingsSchedulingMode,
    getSettingsCalendarViewForDesktop,
    getSettingsCalendarViewForMobile,
    getSettingsWorkingHours,
    getFindRooms,
    getBuildings,
    getRooms,
    getSettingsEnableAutoCallParticipants,
    getSettingsEnableAutoCallRoomBooking,
    getSettingsBuilding,
    getSettingsFloor,
    getSettingsChimeEmail,
    getSettingsFavoritePopoverAttendee,
    getFloorsLoadStatus,
    getSettingsPreferencesLoaded,
    getSettingsCamera,
    getSettingsDisplay,
    getSettingsNoRestricted,
    getSettingsNoManaged,
    getSettingsMinimumSeats,
    getSettingsAvailableOnly,
} from "../../../../sagas/selector";
import {
    getUserByEmail,
} from "../../../shared/chime/actions";
import {
    getRASDataForPerson,
    getRASDataForGroup,
    getPeopleSuggestions,
    clearPeopleSuggestions,
    getGroupSuggestions,
    clearGroupSuggestions,
    getRASDataForPersonAndAddToFavorite,
} from "../../../people/actions";
import { savePreferencesAysnc } from "../actions";

import Responsive from "@amzn/meridian/responsive";

import SettingsForm from "../components/settings-form";
import { addFavorite, removeFavorite, removeFavoriteByAlias } from "../../favorites/actions";
import { SCREEN_SIZE, MAX_SEATS } from "../../shared-constants";
import { FIND_GROUP } from "../../../meeting-scheduler/meeting-scheduler-constants";
import { loadFloors } from "../../locations/actions";
import MeetingsToaster from "../../toasts/containers/toast-container";
import { doAllEntriesHaveValues } from "../settings-utils";

const SettingsDetails = (props) => {
    const dispatch = useDispatch();

    const identity = useSelector(getIdentity);
    const timezones = useSelector(getTimezonesList);
    const preferencesLoaded = useSelector(getSettingsPreferencesLoaded);

    // email and chime initial values
    const usePersonalChimePin = useSelector(getSettingsUsePersonalChimePin);
    const chimeUser = useSelector(getChimeUser);
    const enableAutoCallParticipants = useSelector(getSettingsEnableAutoCallParticipants);
    const enableAutoCallRoomBooking = useSelector(getSettingsEnableAutoCallRoomBooking);
    const chimeEmail = useSelector(getSettingsChimeEmail);

    // time and locations initial values
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const workingHours = useSelector(getSettingsWorkingHours);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const dateFormat = useSelector(getSettingsDateFormat);
    const floorLoaded = useSelector(getFloorsLoadStatus);

    // room booking initial values
    const building = useSelector(getSettingsBuilding);
    const floor = useSelector(getSettingsFloor);
    const camera = useSelector(getSettingsCamera);
    const display = useSelector(getSettingsDisplay);
    const noRestricted = useSelector(getSettingsNoRestricted);
    const noManaged = useSelector(getSettingsNoManaged);
    const minimumSeats = useSelector(getSettingsMinimumSeats);
    const availableOnly = useSelector(getSettingsAvailableOnly);
    
    // layout preferences initial values
    const schedulingMode = useSelector(getSettingsSchedulingMode);
    const calendarViewDesktop = useSelector(getSettingsCalendarViewForDesktop);
    const calendarViewMobile = useSelector(getSettingsCalendarViewForMobile);

     // favorites
    const findRooms = useSelector(getFindRooms);
    const buildings = useSelector(getBuildings);
    const rooms = useSelector(getRooms);
    const locations = useSelector(getLocations);
    const peopleSuggestions = useSelector(getPeopleSuggestionsSelector);
    const groupSuggestions = useSelector(getGroupSuggestionsSelector);
    const favorites = useSelector(getFavorites);
    const popoverAttendee = useSelector(getSettingsFavoritePopoverAttendee);

    // useEffect will initialize below states
    // email and chime
    const [chimeEmailValue, setChimeEmailValue] = useState(undefined);
    const [enableAutoCallParticipantsValue, setEnableAutoCallParticipantsValue] = useState(undefined);
    const [enableAutoCallRoomBookingValue, setEnableAutoCallRoomBookingValue] = useState(undefined);
    const [usePersonalChimePinValue, setUsePersonalChimePinValue] = useState(undefined);

    // time preferences
    const [primaryTimezoneValue, setPrimaryTimezoneValue] = useState(undefined);
    const [workingHourDaysValue, setWorkingHourDaysValue] = useState(undefined);
    const [workingHourStartTimeValue, setWorkingHourStartTimeValue] = useState(undefined);
    const [workingHourEndTimeValue, setWorkingHourEndTimeValue] = useState(undefined);
    const [timeFormatValue, setTimeFormatValue] = useState(undefined);
    const [dateFormatValue, setDateFormatValue] = useState(undefined);

    // room booking preferences
    const [buildingValue, setBuildingValue] = useState(undefined);
    const [floorValue, setFloorValue] = useState(undefined);
    const [cameraChecked, setCameraChecked] = useState(undefined);
    const [displayChecked, setDisplayChecked] = useState(undefined);
    const [noRestrictedChecked, setNoRestrictedChecked] = useState(undefined);
    const [noManagedChecked, setNoManagedChecked] = useState(undefined);
    const [minimumSeatsValue, setMinimumSeatsValue] = useState(undefined);
    const [availableOnlyChecked, setAvailableOnlyChecked] = useState(undefined);

    // layout preferences
    const [schedulingModeValue, setSchedulingModeValue] = useState(undefined);
    const [calendarViewDesktopValue, setCalendarViewDesktopValue] = useState(undefined);
    const [calendarViewMobileValue, setCalendarViewMobileValue] = useState(undefined);

    const onGetRASDataForPerson = (alias, addNewAttendee, name) => dispatch(getRASDataForPerson(alias, addNewAttendee, name, true));
    const onGetRASDataForGroup = (group) => dispatch(getRASDataForGroup(group, true));
    const onGetPeopleSuggestions = (query) => dispatch(getPeopleSuggestions(query));
    const onClearPeopleSuggestions = () => dispatch(clearPeopleSuggestions());
    const onGetGroupSuggestions = (query) => dispatch(getGroupSuggestions(query, FIND_GROUP.START, FIND_GROUP.SIZE));
    const onClearGroupSuggestions = () => dispatch(clearGroupSuggestions());
    const onAddFavorite = (favorite, alias) => dispatch(addFavorite(favorite, alias));
    const onGetRASDataForPersonAndAddToFavorite = (favorite, alias) => dispatch(getRASDataForPersonAndAddToFavorite(favorite, alias));
    const onRemoveFavorite = (favorite, alias) => dispatch(removeFavorite(favorite, alias));
    const onRemoveFavoriteByAlias = (favoriteAlias, alias) => dispatch(removeFavoriteByAlias(favoriteAlias, alias));
    const onLoadFloors = (buildingCode) => dispatch(loadFloors(buildingCode));
    const onChangeMinimumSeats = (value) => {
        setMinimumSeatsValue(Math.min(value, MAX_SEATS));
    }
    const onCheckCamera = (cameraChecked) => {
        setCameraChecked(cameraChecked);
        setDisplayChecked(cameraChecked);
    }

    useEffect(() => {
        document.title = "Preferences - Amazon Meetings";
    }, []);

    useEffect(() => {
        const preferences = {
            emailAndChime: {
                usePersonalChimePin: usePersonalChimePinValue,
                enableAutoCallParticipants: enableAutoCallParticipantsValue,
                enableAutoCallRoomBooking: enableAutoCallRoomBookingValue,
            },
            timePreferences: {
                primaryTimezone: primaryTimezoneValue,
                workingHours: {
                    days: workingHourDaysValue,
                    startTime: workingHourStartTimeValue,
                    endTime: workingHourEndTimeValue
                },
                timeFormat: timeFormatValue,
                dateFormat: dateFormatValue,
            },
            roomBookingPreferences: {
                building: buildingValue,
                floor: floorValue,  
                camera: cameraChecked,
                display: displayChecked,
                noRestricted: noRestrictedChecked,
                noManaged: noManagedChecked,
                minimumSeats: minimumSeatsValue,
                availableOnly: availableOnlyChecked,
            },
            layoutPreferences: {
                schedulingMode: schedulingModeValue,
                calendarViewDesktop: calendarViewDesktopValue,
                calendarViewMobile: calendarViewMobileValue,
            },
        };

        if (doAllEntriesHaveValues(preferences)) {
            // Exclude this property from the above object in case the chimeEmail is still loading
            // But the customer wants to save the preferences
            preferences.chimeEmail = chimeEmailValue;

            // Only after all the settings initialized,
            // The change to the settings will automatically send to the backend
            dispatch(savePreferencesAysnc(identity.username, preferences));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, chimeEmailValue, usePersonalChimePinValue, enableAutoCallParticipantsValue, enableAutoCallRoomBookingValue,
        primaryTimezoneValue, workingHourDaysValue, workingHourStartTimeValue, workingHourEndTimeValue, timeFormatValue,
        dateFormatValue, schedulingModeValue, calendarViewDesktopValue, calendarViewMobileValue,
        buildingValue, floorValue, cameraChecked, displayChecked, noRestrictedChecked, noManagedChecked, minimumSeatsValue, availableOnlyChecked]);

    // initialize settings states.
    useEffect(() => {
        if (identity && identity.username && identity.email && preferencesLoaded) {
            // email and chime
            if (Object.keys(chimeUser).length === 0 && chimeEmail) {
                dispatch(getUserByEmail({userEmail: chimeEmail, username: identity.username}));
            }
            setUsePersonalChimePinValue(usePersonalChimePin);
            setChimeEmailValue(chimeEmail);
            setEnableAutoCallParticipantsValue(enableAutoCallParticipants);
            setEnableAutoCallRoomBookingValue(enableAutoCallRoomBooking);

            // time preferences
            setPrimaryTimezoneValue(primaryTimezone);
            setTimeFormatValue(timeFormat);
            setDateFormatValue(dateFormat);

            setWorkingHourDaysValue(workingHours?.days);
            setWorkingHourStartTimeValue(workingHours?.startTime);
            setWorkingHourEndTimeValue(workingHours?.endTime);

            // room booking preferences
            setBuildingValue(building);
            setFloorValue(floor);
            setCameraChecked(camera);
            setDisplayChecked(display);
            setNoRestrictedChecked(noRestricted);
            setNoManagedChecked(noManaged);
            setMinimumSeatsValue(minimumSeats);
            setAvailableOnlyChecked(availableOnly);

            // layouts
            setSchedulingModeValue(schedulingMode);
            setCalendarViewDesktopValue(calendarViewDesktop);
            setCalendarViewMobileValue(calendarViewMobile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identity, preferencesLoaded, chimeEmail, dispatch]);

    return (
        <Responsive
            query="min-width"
            props={{
                screenSizeBreakpoint: {
                    default: SCREEN_SIZE.MOBILE_VIEW, // 360px
                    "1200px": SCREEN_SIZE.FULL_VIEW,
                    "900px": SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND,
                    "700px": SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR,
                    "550px": SCREEN_SIZE.PARTIAL_MOBILE_VIEW
                }
            }}
        >
            {(responsiveProps) =>
                <React.Fragment>
                    <SettingsForm
                        mastheadSize={props.mastheadSize}
                        screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                        identity={identity}
                        peopleSuggestions={peopleSuggestions}
                        groupSuggestions={groupSuggestions}
                        timezones={timezones}
                        primaryTimezone={primaryTimezoneValue}
                        timeFormat={timeFormatValue}
                        dateFormat={dateFormatValue}
                        chimeEmail={chimeEmailValue}
                        personalChimeMeetingId={chimeUser.PersonalPIN}
                        enableAutoCallParticipants={enableAutoCallParticipantsValue}
                        enableAutoCallRoomBooking={enableAutoCallRoomBookingValue}
                        locations={locations}
                        workingHourDays={workingHourDaysValue}
                        workingHourStartTime={workingHourStartTimeValue}
                        workingHourEndTime={workingHourEndTimeValue}
                        schedulingMode={schedulingModeValue}
                        calendarViewDesktop={calendarViewDesktopValue}
                        calendarViewMobile={calendarViewMobileValue}
                        onChangePrimaryTimezone={setPrimaryTimezoneValue}
                        onChangeTimeFormat={setTimeFormatValue}
                        onChangeDateFormat={setDateFormatValue}
                        onChangeWorkingHourDays={setWorkingHourDaysValue}
                        onChangeWorkingHourStartTime={setWorkingHourStartTimeValue}
                        onChangeWorkingHourEndTime={setWorkingHourEndTimeValue}
                        onChangeSchedulingMode={setSchedulingModeValue}
                        onChangeCalendarViewDesktop={setCalendarViewDesktopValue}
                        onChangeCalendarViewMobile={setCalendarViewMobileValue}
                        onChangeEnableAutoCallParticipants={setEnableAutoCallParticipantsValue}
                        onChangeEnableAutoCallRoomBooking={setEnableAutoCallRoomBookingValue}
                        favorites={favorites}
                        findRooms={findRooms}
                        buildings={buildings}
                        selectedBuilding={buildingValue}
                        onChangeSelectedBuilding={setBuildingValue}
                        selectedFloor={floorValue}
                        onChangeSelectedFloor={setFloorValue}
                        camera={cameraChecked}
                        display={displayChecked}
                        noRestricted={noRestrictedChecked}
                        noManaged={noManagedChecked}
                        minimumSeats={minimumSeatsValue}
                        availableOnly={availableOnlyChecked}
                        onCheckCamera={onCheckCamera}
                        onCheckDisplay={setDisplayChecked}
                        onCheckNoRestricted={setNoRestrictedChecked}
                        onCheckNoManaged={setNoManagedChecked}
                        onChangeMinimumSeats={onChangeMinimumSeats}
                        onCheckAvailableOnly={setAvailableOnlyChecked}
                        rooms={rooms}
                        onGetRASDataForPerson={onGetRASDataForPerson}
                        onGetRASDataForGroup={onGetRASDataForGroup}
                        onGetPeopleSuggestions={onGetPeopleSuggestions}
                        onClearPeopleSuggestions={onClearPeopleSuggestions}
                        onGetGroupSuggestions={onGetGroupSuggestions}
                        onClearGroupSuggestions={onClearGroupSuggestions}
                        onAddFavorite={onAddFavorite}
                        onGetRASDataForPersonAndAddToFavorite={onGetRASDataForPersonAndAddToFavorite}
                        onRemoveFavorite={onRemoveFavorite}
                        onRemoveFavoriteByAlias={onRemoveFavoriteByAlias}
                        onLoadFloors={onLoadFloors}
                        usePersonalChimePin={usePersonalChimePinValue}
                        onChangeUsePersonalChimePin={setUsePersonalChimePinValue}
                        popoverAttendee={popoverAttendee}
                        floorLoaded={floorLoaded}
                    />
                    <MeetingsToaster />
                </React.Fragment>
            }
        </Responsive>
    );
};

export default SettingsDetails;