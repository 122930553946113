import { TIME_CONSTANT } from "../../shared-constants";
import * as Actions from "../actions/action-types";

const initialState = {
    workHours: {
        // Minutes after 00:00
        // Ex: 1:00 PM would be represented as 780 (13 hours * 60 minutes)
        startTime: undefined,
        endTime: undefined
    }
};

const exchangePreferencesReducer = (state = initialState, action) => {
    switch(action.type) {
        case Actions.SET_WORK_HOURS:
            // Only allow for work hours of 15 min increments (Exchange only allows 30 min increments)
            action.workHours.startTime = action.workHours.startTime - action.workHours.startTime % TIME_CONSTANT.FIFTEEN_MIN;
            action.workHours.endTime = action.workHours.endTime - action.workHours.endTime % TIME_CONSTANT.FIFTEEN_MIN;
            return {
                ...state,
                workHours: action.workHours
            };
        default:
            return state;
    }
};

export default exchangePreferencesReducer;