import React from "react";

const PollRespondedToast = (props) => {
    return (
        <React.Fragment>
            <b>Votes submitted to {props.organizerName}</b>
        </React.Fragment>
    );
};

export default PollRespondedToast;