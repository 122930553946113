import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import TransitionController from "../_transition-controller"
import waitAnimationFrame from "../../_utils/wait-animation-frame"
import { memoize, memoizeByKeys } from "../../_utils/functional"

const getSizeProperty = direction =>
  ({
    left: "width",
    right: "width",
    up: "height",
    down: "height",
  }[direction])

// The container element sets the width or height of the expander and handles
// the expanding and collapsing motion.
const containerStyles = memoizeByKeys(
  ({ state, direction, size, motionDuration, motionFunction, closedSize }) =>
    css(
      {
        left: {
          // Horizontal expanders are inline-flex to ensure they shrink to fit
          // their children's width (vertical expanders shrink to fit by default
          // 'cause that's how CSS layout works).
          display: "inline-flex",
          // The up/left expanders use row/column-reverse so that content is fixed
          // to the bottom/right edges instead of top/left (the default).
          // NOTE: justifyContent: "flex-end" would work here instead *except* for
          // a bug in IE11: https://stackoverflow.com/questions/32885534/how-to-make-flex-end-work-in-ie11
          flexDirection: "row-reverse",
        },
        right: {
          display: "inline-flex",
          flexDirection: "row",
        },
        up: {
          display: "flex",
          flexDirection: "column-reverse",
        },
        down: {
          display: "flex",
          flexDirection: "column",
        },
      }[direction],
      {
        // Don't let a flex parent squish the expander
        flexShrink: 0,
        // Set the width/height
        [getSizeProperty(direction)]:
          state === "entering" || state === "entered" ? size : closedSize,
        // Clip the children (that's kind of the whole point of an expander...)
        overflow: "hidden",
        transition:
          motionDuration && motionFunction
            ? `${getSizeProperty(
                direction
              )} ${motionDuration}ms ${motionFunction}`
            : undefined,
        // Make the whole expander invisible when it's closed so that non of its
        // children are focuseable
        visibility:
          state === "exited" && closedSize === 0 ? "hidden" : undefined,
        // HACK: Set max width to 0 on collapsed horizontal expanders to keep
        // Safari from reverting them to their original widths. See this bug:
        // https://stackoverflow.com/questions/20837933/css-broken-with-inline-block-in-safari-width-of-zero-not-set
        maxWidth:
          state === "exited" && (direction === "left" || direction === "right")
            ? closedSize
            : undefined,
      }
    ),
  [
    "state",
    "direction",
    "size",
    "motionDuration",
    "motionFunction",
    "closedSize",
  ]
)

// The content element automatically holds its dimensions, even when the
// container is collapsed, so that the container knows what size to animate to.
const contentStyles = memoize(direction =>
  css({
    // Ensure that this container fits its contents perfectly
    [getSizeProperty(direction)]: "max-content", // For Firefox
    flexShrink: 0, // For everyone else
    // HACK: Safari randomly fails to paint unless you add this...
    transform: "translateZ(0)",
  })
)

/* eslint-disable react/prop-types */
/**
 * This component makes it easy to animate the size of a content block from 0 to
 * "auto" and back. It handles some nice things like expanding in various
 * directions, keeping children out of the tab order when collapsed, setting an
 * aria-hidden attribute when collapsed, and correctly handling situations when
 * the dimensions of the expander contents change on the fly.
 *
 * This component doesn't render anything to the DOM itself, but it needs at
 * least two nested elements to work. To make this happen it requires that you
 * pass in children as a function. It will call that function, passing it an
 * object containing two more objects keyed under "containerProps" and
 * "contentProps". These objects should be passed through as props to two nested
 * elements by your children function (with the container element as the root of
 * the expander and the content element as the only child of the container).
 * This allows you to take advantage of the expander functionality while
 * maintaining maximum control over the DOM. Example:
 *
 * ```
 * <ExpanderController>
 *   {({ containerProps, contentProps }) => (
 *     <div {...containerProps}>
 *       <div {...contentProps}>
 *         My expandable content here...
 *       <div>
 *     </div>
 *   )}
 * </ExpanderController>
 * ```
 *
 * NOTE: Horizontal ExpanderControllers are inline elements, so extra attention
 * may be needed to avoid unwanted whitespace around them. A couple tricks for
 * removing whitespace include setting `font-size: 0` or `display: flex` on an
 * inline element's parent.
 */
class ExpanderControllerClass extends React.Component {
  state = {
    size: undefined,
    render: this.props.open || this.props.alwaysRenderChildren,
  }

  updateContentRef = node => (this.contentRef = node)

  // This is set in onBeforeOpen, but we use a noop here so that we don't have
  // to check if it exists before calling it.
  cancelFixSize = () => {}

  // Fix the size of the container to the content's natural width. This will
  // allow us to animate the size of the container, since you can't animate
  // width/height to or from "auto".
  fixSize = done =>
    this.setState(
      {
        size:
          this.contentRef &&
          this.contentRef.getBoundingClientRect()[
            getSizeProperty(this.props.direction)
          ],
      },
      done
    )

  onBeforeOpen = done => {
    if (!this.state.render) {
      // If the children aren't rendered yet, do so and then give them a chance
      // to settle before poking through the DOM
      this.setState({ render: true }, () => {
        this.cancelFixSize = waitAnimationFrame(() => this.fixSize(done))
      })
    } else {
      this.fixSize(done)
    }
  }

  // Clear the fixed size so that the contents can fluidly resize while the
  // expander is open
  onAfterOpen = () => {
    this.setState({ size: undefined })
    if (this.props.onAfterOpen) this.props.onAfterOpen()
  }

  onBeforeClose = done => {
    this.cancelFixSize()
    this.fixSize(done)
  }

  onAfterClose = () => {
    if (!this.props.alwaysRenderChildren) {
      this.setState({ render: false })
    }
    if (this.props.onAfterClose) this.props.onAfterClose()
  }

  componentWillUnmount() {
    this.cancelFixSize()
  }

  render() {
    const { children, ...props } = this.props
    const { size, render } = this.state
    return (
      <TransitionController
        in={props.open}
        duration={
          props.motionDuration && props.motionFunction
            ? props.motionDuration
            : 0
        }
        onBeforeEnter={this.onBeforeOpen}
        onAfterEnter={this.onAfterOpen}
        onBeforeExit={this.onBeforeClose}
        onAfterExit={this.onAfterClose}
      >
        {state =>
          render
            ? children({
                state,
                containerProps: {
                  className: containerStyles({ ...props, state, size }),
                  "aria-hidden": !props.closedSize && !props.open,
                },
                contentProps: {
                  className: contentStyles(props.direction),
                  ref: this.updateContentRef,
                },
              })
            : null
        }
      </TransitionController>
    )
  }
}
/* eslint-enable react/prop-types */

// Keep refs from being passed. We're doing this for 4.x - the
// most recent breaking change - so that we can change the
// _actual_ component to functional later without waiting
// for the next breaking change
const ExpanderController = props => <ExpanderControllerClass {...props} />

ExpanderController.propTypes = {
  children: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(["up", "down", "left", "right"]).isRequired,
  open: PropTypes.bool.isRequired,
  /**
   * Set this to true if children should be rendered even when the expander
   * is closed. This can be helpful when, for example, you want the expander
   * to retain vertical or horizontal layout space (for a horizontal or
   * vertical expander, respectively) even after it's collapsed.
   */
  alwaysRenderChildren: PropTypes.bool,
  /**
   * The value the expander should close to. Setting the value to > 0 will
   * keep the expander visible even when closed.
   */
  closedSize: PropTypes.number,
  motionDuration: PropTypes.number,
  motionFunction: PropTypes.string,
  onAfterClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
}

ExpanderController.defaultProps = {
  closedSize: 0,
}

export default ExpanderController
