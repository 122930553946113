module.exports = {
    STATUS_HIGHLIGHT_COLOR: {
        BUSY: "#EE5A46", // Crimson-400
        CANCELED: "#FFFFFF", // Gray-0
        FREE: "#FFFFFF", // Gray-0
        AVAILABLE: "#FFFFFF", // Gray-0
        GRID_FREE: "white", // White (using word instead of hex code to have a distinct value from no data)
        OOTO: "#8169F8", // Violet-400
        TENTATIVE: "#D4780C", // Tangerine-500
        RESTRICTED: "#697576", // Gray-500
        NO_DATA: "#FFFFFF", // Gray-0
    },
    STATUS_COLOR: {
        BUSY: "#FFB09F", // Crimson-200
        CANCELED: "#FFFFFF", // Gray-0
        FREE: "#FFFFFF", // Gray-0
        AVAILABLE: "#FFFFFF", // Gray-0
        GRID_FREE: "white", // White (using word instead of hex code to have a distinct value from no data)
        OOTO: "#C9A8FF", // Violet-200
        TENTATIVE: "#FFE8B1", // Gold-200
        RESTRICTED: "#FFFFFF", // Gray-0
        NO_DATA: "#FFFFFF", // Gray-0
    },
    WORKING_HOURS_STATUS_COLOR: {
        IN: "white",
        OUT: "#F0F1F2", // Gray-100
    },
    STATUS_LABEL: {
        "busy": "Busy",
        "canceled": "Canceled",
        "free": "Free",
        "available": "Available",
        "gridFree": "Free",
        "outOfOffice": "Out Of The Office",
        "tentative": "Tentative",
        "restricted": "Available (Restricted)",
        "noData": "No data",
        "meetingHours": "Meeting hours",
    },
};