import { takeLatest, put, call } from "redux-saga/effects";
import { getUserFavorites, saveUserPreferences } from "../../../api/apig/ams";
import { isErrorResponse } from "../../../api/apig/apig-utils";
import { saveFavorites } from "../../favorites/actions";
import { savePreferences } from "../actions";
import { showToast } from "../../toasts/actions";
import { getErrorToast } from "../../toasts/toast-utils";
import { postSaveUserPreferencesMetric } from "../../metrics/actions";
import { cacheAttendeeWorkingHours } from "../../../people/actions";
import * as Actions from "../actions/action-types";

export function* saveUserPreferencesApi(action) {
    const preferences = action.preferences;

    yield put(savePreferences(preferences));

    const attendee = {
        alias: action.requestedOnBehalfOf,
        workingHours: {
            ...preferences?.timePreferences?.workingHours,
            preferenceTimezone: preferences?.timePreferences?.primaryTimezone,
        }
    };
    // Update the organizer's working hours in the memory cache
    yield put(cacheAttendeeWorkingHours(attendee));

    const fromPreferencePage = action.fromPreferencePage;
    const seriliazedPreferences = JSON.stringify(preferences);

    // The save operation will read the user's existing data first from the ddb,
    // Compare the existing data with the request payload then only update the modified fields.
    const response = yield call(saveUserPreferences, action.requestedOnBehalfOf, seriliazedPreferences);
    const saveUserPreferencesResponse = JSON.parse(response);
    if (isErrorResponse(saveUserPreferencesResponse)) {
        const toast = getErrorToast(
            "An error occurred when saving your preferences, please refresh the page and try again."
        );
        yield put(showToast(toast));

        const responseBody = saveUserPreferencesResponse.body && JSON.parse(saveUserPreferencesResponse.body);
        yield put(postSaveUserPreferencesMetric(action.requestedOnBehalfOf, false, fromPreferencePage, responseBody?.message));
        return;
    }

    yield put(postSaveUserPreferencesMetric(action.requestedOnBehalfOf, true, fromPreferencePage));
}

export function* watchSaveUserPreferences() {
    yield takeLatest(Actions.SAVE_PREFERENCES_ASYNC, saveUserPreferencesApi);
}

export function* getUserPreferencesAndFavoritesApi(action) {
    // Load user favorites if present
    let response = yield call(getUserFavorites, action.requestedOnBehalfOf);
    const userPreferencesAndFavorites = (response && JSON.parse(response)) || {};
    let favorites = [];
    let preferences = [];
    if (isErrorResponse(userPreferencesAndFavorites)) {
        const toast = getErrorToast("An error occurred when retrieving your preferences and favorites.");
        yield put(showToast(toast));
    } else {
        if (userPreferencesAndFavorites.favorites) {
            try {
                // We put this parse in a try catch block in case there is a bad string stored in our preferences ddb
                favorites = JSON.parse(userPreferencesAndFavorites.favorites);
            } catch (e) {
                console.error("Error parsing favorites: ", e);
            }
        }

        if (userPreferencesAndFavorites.preferences) {
            try {
                // We put this parse in a try catch block in case there is a bad string stored in our preferences ddb
                preferences = JSON.parse(userPreferencesAndFavorites.preferences);
            } catch (e) {
                console.error("Error parsing preferences: ", e);
            }
        }
        
    }
    yield put(saveFavorites(favorites, action.requestedOnBehalfOf));
    yield put(saveUserPreferences(preferences));
}

export function* watchGetUserPreferencesAndFavorites() {
    yield takeLatest(Actions.GET_FAVORITES_AND_PREFERENCES_ASYNC, getUserPreferencesAndFavoritesApi);
}

const sagas = [
    watchSaveUserPreferences(),
    watchGetUserPreferencesAndFavorites()
];

export default sagas;