import * as Actions from "../actions/action-types";

const initialState = {
    roomEligibilityDictionary: undefined,
    roomCheckInStatusDictionary: undefined,
};

const checkInReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_ROOM_ELIGIBILITY:
            return {
                ...state,
                roomEligibilityDictionary: new Map([...(state.roomEligibilityDictionary || new Map()), ...action.roomEligibilityDictionary]),
            };
        case Actions.SET_ROOM_CHECK_IN_STATUS:
            return {
                ...state,
                roomCheckInStatusDictionary: new Map([...(state.roomCheckInStatusDictionary || new Map()), ...action.roomCheckInStatusDictionary]),
            };
        default:
            return state;
    }
};

export default checkInReducer;