export const addAccessibilityPropertiesByPlaceholder = (placeholderValue, ariaLabel, ariaDescribedBy) => {
    let element = [...document.getElementsByTagName("input")].find(input => {
        let placeholder = input.attributes.getNamedItem("placeholder");
        return placeholder && placeholder.value === placeholderValue;
    });
    addAriaLabel(element, ariaLabel);
    addAriaDescribedBy(element, ariaDescribedBy);
};

export const addAccessibilityPropertiesToButton = (buttonId, ariaDescribedBy) => {
    const button = document.getElementById(buttonId);
    addAriaDescribedBy(button, ariaDescribedBy);
};

export const addAccessibilityPropertiesToLink = (parentDivId, ariaDescribedBy) => {
    const parentDiv = document.getElementById(parentDivId);
    const linkDiv = parentDiv && parentDiv.children && parentDiv.children[0];
    addAriaDescribedBy(linkDiv, ariaDescribedBy);
};

export const addAccessibilityPropertiesToSearchField = (parentDivId) => {
    const parentDiv = document.getElementById(parentDivId);
    const searchFieldDiv = parentDiv.children[0];
    if (searchFieldDiv) {
        searchFieldDiv.children[0] && searchFieldDiv.children[0].setAttribute("role", "combobox");
        searchFieldDiv.children[0] && searchFieldDiv.children[0].setAttribute("aria-haspopup", "listbox");
    }
    const searchFieldInput = document.querySelectorAll(`#${parentDivId} input`)[0];
    if (searchFieldInput) {
        searchFieldInput.setAttribute("type", "search");
    }
};

const addAriaLabel = (element, ariaLabel) => {
    if (element && ariaLabel) {
        element.setAttribute("aria-label", ariaLabel);
    }
};

const addAriaDescribedBy = (element, ariaDescribedBy) => {
    if (element && ariaDescribedBy) {
        element.setAttribute("aria-describedby", ariaDescribedBy.join(" "));
    }
};

