import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";

import { postRatingMetric } from "../../metrics/actions";
import MeetingsFeedback from "./meetings-feedback";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import Popover from "@amzn/meridian/popover";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import amznMeetingsIcon from "../../../../assets/icons/general/amzn_meetings_icon_32px.svg";
import closeTokens from "@amzn/meridian-tokens/base/icon/close";
import {COMPONENT_IDS} from "../../accessibility-constants";

const MeetingsFeedbackPopover = (props) => {
    const userFeedback = props.userFeedback;
    const userRating = props.userRating;
    const onUpdateUserFeedback= props.onUpdateUserFeedback;
    const onUpdateUserRating = props.onUpdateUserRating;
    const onClearFeedback = props.onClearFeedback;

    const [tellUsWhy, setTellUsWhy] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const feedbackPopoverRef = props.anchorNode;

    const dispatch = useDispatch();
    const feedbackCloseButtonRef = useRef();

    const onClose = () => {
        // Post rating metric if user had input and did not submit
        if (userRating && !submitted) {
            dispatch(postRatingMetric(userFeedback, userRating, false, false));
        }

        // Reset state variables on close
        setTellUsWhy(false);
        setSubmitted(false);

        props.onClose();
        feedbackPopoverRef.focus();
    };

    return (
        <Popover
            anchorNode={props.anchorNode}
            open={props.open}
            onClose={onClose}
            spacingInset="small"
            position="top"
        >
            <div style={{
                boxSizing: "content-box",
                position: "absolute",
                top: "0px",
                left: "0px",
                backgroundColor: "#36C2B4",
                border: "inherit",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                height: "10px",
                width: "336px",
                visibility: submitted ? "hidden" : "visible",
            }} />
            <div style={{
                boxSizing: "content-box",
                position: "absolute",
                top: "10px",
                left: "0px",
                backgroundColor: "#F9FAFA",
                borderLeft: "inherit",
                borderRight: "inherit",
                height: tellUsWhy ? "46px" : "82px",
                width: "336px",
                zIndex: "1",
                visibility: submitted ? "hidden" : "visible",
            }} />
            <Column alignmentHorizontal="center" spacing={submitted ? "none" : "medium"} spacingInset="small" width="320px">
                <div style={{zIndex: "2", width: "100%"}}>
                    <Row spacing="small" spacingInset={tellUsWhy ? "none" : "small none"} width="100%" widths={submitted ? ["fit"] : ["fit", "fill", "fit"]} alignmentHorizontal="right">
                        {!submitted &&
                            <img src={amznMeetingsIcon} alt={""} style={{"width": "25px", "height": "25px"}} />
                        }
                        {!submitted &&
                            <label htmlFor={COMPONENT_IDS.FEEDBACK}>
                                <Text type={tellUsWhy ? "h100": "h200"}>
                                    {tellUsWhy ?
                                        "(Optional) Please tell us why..."
                                        :
                                        "How was your experience using Amazon Meetings?"
                                    }
                                </Text>
                            </label>
                        }
                        <Button onClick={props.onClose} type="icon" size="small" ref={feedbackCloseButtonRef}>
                            <Icon tokens={closeTokens}>
                                {submitted ?
                                    "Close feedback popover. Thank you for your feedback! We really appreciate it"
                                    :
                                    "Close feedback popover"
                                }
                            </Icon>
                        </Button>
                    </Row>
                </div>
                <MeetingsFeedback
                    id={COMPONENT_IDS.FEEDBACK}
                    userRating={userRating}
                    tellUsWhy={tellUsWhy}
                    userFeedback={userFeedback}
                    submitted={submitted}
                    onUpdateUserRating={onUpdateUserRating}
                    setTellUsWhy={setTellUsWhy}
                    onUpdateUserFeedback={onUpdateUserFeedback}
                    setSubmitted={setSubmitted}
                    onClearFeedback={onClearFeedback}
                    closeButtonRef={feedbackCloseButtonRef}
                    isModal={false}
                />
            </Column>
        </Popover>
    );
};

export default MeetingsFeedbackPopover;