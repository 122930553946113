const pollNamespace = "polls";

export const CREATE_POLL = `${pollNamespace}.create_poll`;
export const FIND_POLLS = `${pollNamespace}.find_polls`;
export const SET_POLL_LIST = `${pollNamespace}.set_poll_list`;
export const GET_ATTENDEE_POLL = `${pollNamespace}.get_attendee_poll`;
export const GET_POLL = `${pollNamespace}.get_poll`;
export const SET_POLL_DETAILS = `${pollNamespace}.set_poll_details`;
export const SET_MEETING_POLLS_LOADED = `${pollNamespace}.set_meeting_polls_loaded`;
export const SET_POLL_DETAILS_LOADED = `${pollNamespace}.set_poll_details_loaded`;
export const CLEAR_POLL_DETAILS = `${pollNamespace}.clear_poll_details`;
export const CLEAR_CREATE_POLL_REQUEST = `${pollNamespace}.clear_create_poll_request`;
export const SET_CREATE_POLL_REQUEST = `${pollNamespace}.set_create_poll_request`;
export const DELETE_POLL = `${pollNamespace}.delete_poll`;
export const RESPOND_POLL = `${pollNamespace}.respond_poll`;
export const UPDATE_POLL = `${pollNamespace}.update_poll`;
export const SEND_NOTIFICATION = `${pollNamespace}.send_notification`;
export const SET_REMINDER_RESPONSE = `${pollNamespace}.set_reminder_response`;
export const FIND_MEETINGS_FOR_AVAILABILITY = `${pollNamespace}.find_meetings_for_availability`;
export const SET_MEETING_LIST_FOR_AVAILABILITY = `${pollNamespace}.set_meeting_list_for_availability`;
export const CREATE_MEETING_FOR_POLL = `${pollNamespace}.create_meeting_for_poll`;
export const SET_MEETING_POLL_FROM_PAGE = `${pollNamespace}.set_meeting_poll_from_page`;
export const SET_MEETING_POLL_TIME_SLOTS_SELECTED_FROM_GRID_MODE = `${pollNamespace}.set_meeting_poll_time_slots_selected_from_grid_mode`;
export const SET_MEETING_POLL_TIME_SLOTS_SELECTED_FROM_CALENDAR_MODE = `${pollNamespace}.set_meeting_poll_time_slots_selected_from_calendar_mode`;
export const CLEAR_MEETING_POLL_TIME_SLOTS_SELECTED_MARKER = `${pollNamespace}.clear_meeting_poll_time_slots_selected_marker`;
export const PROCESSING_POLL_COMPLETED = `${pollNamespace}.processing_poll_completed`;
