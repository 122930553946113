const emptyNodeBounds = { top: 0, left: 0, width: 0, height: 0 }

const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
)

/**
 * Check to see if two rectangles, defined by top/left origin and width/height,
 * are equal.
 */
const compareNodeBounds = (a, b) =>
  a === b ||
  (a.top === b.top &&
    a.left === b.left &&
    a.width === b.width &&
    a.height === b.height)

/**
 * This returns a rectangle from a DOM node that represents the size and
 * position of the node relative to the viewport. If the node does not exist
 * then an empty rectangle is returned. Note that the width and height do not
 * take any CSS scale transforms into account.
 */
const getNodeBounds = node => {
  if (!canUseDOM) {
    return emptyNodeBounds
  } else if (node === window) {
    const width = window.innerWidth
    const height = window.innerHeight
    return { top: 0, left: 0, width, height }
  } else if (node === document.documentElement) {
    // For the document element, getBoundingClientRect() will return scroll
    // offset instead of actual position for top/left values (in all browsers
    // except edge). This is inconsistent with the function's behavior for other
    // nodes (in all browsers except edge). Resolve this by just assuming the
    // top/left of the document element is 0/0 (true in all cases except for
    // when the "html" tag has been repositioned with CSS, which is rare).
    const { width, height } = node.getBoundingClientRect()
    return { top: 0, left: 0, width, height }
  } else if (node && node.getBoundingClientRect) {
    // For normal nodes, get the width and height from offsetWidth and
    // offsetHeight instead of getBoundingClientRect. This will ignore any CSS
    // scale transforms that are applied.
    const width = node.offsetWidth
    const height = node.offsetHeight
    const { top, left } = node.getBoundingClientRect()
    return { top, left, width, height }
  } else {
    return emptyNodeBounds
  }
}

export { emptyNodeBounds, compareNodeBounds, getNodeBounds }
