/**
 * WHAT: This function makes it easy to detect a direct click on an element. It
 * will only trigger if both the mouse down and mouse up parts of the click
 * occurr directly on the element itself, and not the element's children.
 *
 * WHY: Listening for a direct click on an element, excluding its children, is
 * pretty tricky. You could try comparing event.target to event.currentTarget in
 * a click handler bound directly to the element, but that would still fire if
 * the click started on another element. For example if the user moused down on
 * a child element, moused over to the parent element, and released then a click
 * even would be fired on the parent where event.target was the same as
 * event.currentTarget. This same limitation applies to the method of trying to
 * call event.stopPropagation on click events from children to keep them from
 * bubbling up to parents. See https://sim.amazon.com/issues/MRDN-592 for why
 * triggering a click that started on another element can be problematic.

 * HOW: Usage of this utility is a little weird, but it's the only consistent
 * solution I could find to the problem. This function's only argument is the
 * onClick handler that should be called when a direct click is made. However
 * instead of returning some sort of more robust click handler it returns a
 * mouse down handler. This handler should be passed to the onMouseDown prop of
 * the element. For example:
 *
 * ```
 * const onClick = () => console.log("clicked directly on div!")
 *
 * // NOTE: onClick can't change after this point as onDirectClick stores it in
 * // memory. If onClick may change just inline this in your jsx (e.g.
 * // `onMouseDown={onDirectClick(onClick)}`).
 * const onMouseDown = onDirectClick(onClick)
 *
 * <div onMouseDown={onMouseDown}>
 *   <p>Clicking on this will not trigger a "direct" click on the parent</p>
 * </div>
 * ```
 *
 * Not super intuitive, but it works. This allows the utility to start listening
 * for clicks only when the user mouses down on the element, avoiding the
 * situation where a mousedown on another element, then a mouseover to our
 * element, then a mouse up would fire a click.
 */
const onDirectClick = callback => {
  // Listeners to add when the user mouses down on our element.
  const addListeners = node => {
    node.addEventListener("click", onClick)
    node.addEventListener("mouseout", onMouseOut)
  }

  // Listeners to remove when the user finishes their click or mouses out of
  // the element.
  const removeListeners = node => {
    node.removeEventListener("click", onClick)
    node.removeEventListener("mouseout", onMouseOut)
  }

  // This is the final "direct" click handler.
  const onClick = event => {
    removeListeners(event.target)
    callback(event)
  }

  // If the user mouses out clear our listeners (and stop listening for a click
  // event, as it would no longer be directly on the element).
  const onMouseOut = event => removeListeners(event.target)

  // If the user mouses down directly on the element that this event is bound
  // to then start listening for a click event to finish things off.
  const onMouseDown = event => {
    if (event.target === event.currentTarget) {
      addListeners(event.target)
    }
  }

  return callback ? onMouseDown : undefined
}

export default onDirectClick
