import React, { useState } from "react";

import Link from "@amzn/meridian/link";
import Text from "@amzn/meridian/text";

import {
    MAPS_CALENDAR_STATE,
    MAPS_AVAILABLE_BLOCK_COLOR,
    MAPS_DRAG_COMPONENT
} from "../../availability-sharing/availability-sharing-constants";

import "../calendar.css";

const AvailabilityBlock = (props) => {
    let [availableMeetingBlockDisplayClass, setAvailableMeetingDisplayClass] = useState("availability_block_unfocused");
    let [availableBlockDisplayClass, setAvailableBlockDisplayClass] = useState("availability_block_hide");
    let isBlockShared = props.state === MAPS_CALENDAR_STATE.EMPTY_SHARED || props.state === MAPS_CALENDAR_STATE.MEETING_SHARED;

    // Add one extra pixel for each whole hour to compensate for the borders and avoid gaps.
    let blockHeight = props.duration * props.baseHeight + (props.duration / 1);

    let [startHour, startMinute] = props.startTime.split(":").map((timePart) => parseInt(timePart));
    let startHourDelta = startHour - parseInt(props.hour);
    let blockTopPosition = ((startMinute / 60) * props.baseHeight) + (startHourDelta * props.baseHeight);

    let event = {
        date: props.date,
        hour: props.hour,
        index: props.index,
        startTime: props.startTime,
        duration: props.duration,
        state: props.state
    };

    return (
        <div style={{ width: "100%" }}> {/* Helper wrapper to bypass a meridian !important style */}
            <div
                className={availableMeetingBlockDisplayClass}
                style={{
                    position: "absolute",
                    top: `${blockTopPosition}px`,
                    left: "0px",
                    zIndex: "2",
                    width: "100%",
                    height: `${blockHeight}px`,
                    backgroundColor: isBlockShared ? MAPS_AVAILABLE_BLOCK_COLOR.BACKGROUND : "none",
                }}
                onClick={() => {
                    if (props.state === MAPS_CALENDAR_STATE.EMPTY_NOT_SHARED || props.state === MAPS_CALENDAR_STATE.MEETING_NOT_SHARED) {
                        props.onSelectAvailabilityBlock();
                    }
                }}
                onMouseOver={(e) => {
                    if (isBlockShared) {
                        setAvailableMeetingDisplayClass("availability_block_focused");
                        setAvailableBlockDisplayClass("availability_block_show");
                    }
                }}
                onMouseLeave={(e) => {
                    if (isBlockShared) {
                        setAvailableMeetingDisplayClass("availability_block_unfocused");
                        setAvailableBlockDisplayClass("availability_block_hide");
                    }
                }}
            >
                {isBlockShared &&
                    <div
                        style={{
                            width: "100%",
                            height: "8px",
                            position: "absolute",
                            top: "0px",
                            zIndex: "4",
                            cursor: "row-resize",
                            userSelect: "none",
                        }}
                        onMouseDown={(e) => props.onStartDrag(e, MAPS_DRAG_COMPONENT.DRAG_START_TIME, event)}
                        draggable="true"
                    >
                        <div
                            style={{
                                width: "100%",
                                position: "absolute",
                                top: "0px",
                                height: "3px",
                                backgroundColor: MAPS_AVAILABLE_BLOCK_COLOR.EDGE
                            }}
                        />
                    </div>
                }
                <div
                    className={availableBlockDisplayClass}
                    style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        color: "black",
                        cursor: "pointer"
                    }}
                    onClick={(event) => {event.stopPropagation();}}
                >
                    <Text type="b200">
                        <Link size="small" onClick={props.onDismissAvailabilityBlock}>Unshare</Link>
                    </Text>
                </div>
                {isBlockShared &&
                    <div
                        style={{
                            width: "100%",
                            height: "8px",
                            position: "absolute",
                            top: `${blockHeight - 8}px`,
                            zIndex: "4",
                            cursor: "row-resize",
                            userSelect: "none",
                        }}
                        onMouseDown={(e) => props.onStartDrag(e, MAPS_DRAG_COMPONENT.DRAG_END_TIME, event)}
                        draggable="true"
                    >
                        <div
                            style={{
                                width: "100%",
                                position: "absolute",
                                top: "5px",
                                height: "3px",
                                backgroundColor: MAPS_AVAILABLE_BLOCK_COLOR.EDGE
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default AvailabilityBlock;
