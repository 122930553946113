module.exports = {
    LANGUAGES: {
        ENGLISH: "en"
    },
    TIMEZONE_DISPLAY_TYPE: {
        SHORT: "short",
        LONG: "long",
    },
    ERRORS: {
        INVALID_TIMEZONE: "invalid_timezone",
        INVALID_TIMEZONE_ID: "invalid-timezone-id",
    },
};