import React, { useEffect, useState } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import Tag from "@amzn/meridian/tag";
import Button from "@amzn/meridian/button";
import Box from "@amzn/meridian/box";
import Divider from "@amzn/meridian/divider";

import clockTokens from "@amzn/meridian-tokens/base/icon/clock";
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil";
import syncTokens from "@amzn/meridian-tokens/base/icon/sync";
import userTokens from "@amzn/meridian-tokens/base/icon/user";
import { ReactComponent as RoomIcon } from "../../../assets/icons/locations/room-black.svg";

import { MEETING_CARD, STEP } from "../meeting-scheduler-constants.js";
import {
    convertEpoch,
    sortRoomsByName,
} from "../meeting-scheduler-utils.js"
import {
    getAttendeeResponseTypeCount,
    getNonChimeAttendeesCount,
    getAttendeeAvailabilityType,
} from "../existing-meeting-utils.js"
import { shortRoomName } from "../../shared/locations/locations-utils.js";
import { TIMEZONE_DISPLAY_TYPE } from "../../shared/timezones/timezones-constants";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { ATTENDEE_PRIORITY, ATTENDEE_RESPONSE, EXCHANGE_RESPONSE } from "../../people/people-constants.js";
import { formatDisplayName, renderPriorityIcon } from "../../people/people-utils.js";
import { ATTENDEE_TYPE } from "../../people/people-constants";
import { parseRecurrence } from "../../shared/meeting-utils";
import { SCREEN_SIZE, TIME_CONSTANT } from "../../shared/shared-constants";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";

const ExistingMeetingReviewCard = (props) => {
    const onChangeStep = props.onChangeStep;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const meetingDetails = props.meetingDetails;
    const timeFormat = props.timeFormat;
    const [existingMeetingDetail, setExistingMeetingDetail] = [props.existingMeetingDetail, props.setExistingMeetingDetail];

    // Timezones
    const timezones = props.timezones;
    const suggestionsTimezoneShortName = timezoneIdToDisplayName(timezones || [], props.suggestionsTimezoneId, TIMEZONE_DISPLAY_TYPE.SHORT);
    const [timezoneName, setTimezoneName] = useState(undefined); // useEffect will set initial timezoneName

    // Datetime format and date format
    const startTime = meetingDetails && meetingDetails.time && meetingDetails.time.startTime;
    const endTime = meetingDetails && meetingDetails.time && meetingDetails.time.endTime;
    const formatDateTime = (time, format) => {
        return convertEpoch(time, format, meetingDetails.startTimezone.id, timeFormat === TIME_FORMAT.TWELVE_HOUR);
    };
    const displayedStartTime = formatDateTime(startTime, "time");
    const displayedEndTime = formatDateTime(endTime, "time") === "12:00 AM" ?
        formatDateTime(endTime - TIME_CONSTANT.ONE_MIN_IN_SEC, "time") :
        formatDateTime(endTime, "time");
    const displayedStartDate= formatDateTime(startTime, "shortWeekdayDate");
    const displayedEndDate = formatDateTime(endTime, "time") === "12:00 AM" ?
        formatDateTime(endTime - TIME_CONSTANT.ONE_MIN_IN_SEC, "shortWeekdayDate") :
        formatDateTime(endTime, "shortWeekdayDate");

    const attendees = meetingDetails.requiredAttendees.concat(meetingDetails.optionalAttendees);

    // Render a group of attendees given the response type
    const getAttendeeResponseGroup = (responseType) => {
        meetingDetails.requiredAttendees.forEach((attendee) => {
            attendee["priority"] = ATTENDEE_PRIORITY.REQUIRED;
        });
        meetingDetails.optionalAttendees.forEach((attendee) => {
            attendee["priority"] = ATTENDEE_PRIORITY.OPTIONAL;
        });

        let responseAttendees = [];

        // Get attendees that match the inputted response type
        attendees.forEach((attendee) => {
            if (((attendee.response === responseType) || (responseType === "noResponse" && !attendee.response)) && !attendee.isOrganizer && !attendee.email.endsWith("@chime.aws")) {
                responseAttendees.push(attendee);
            }
        });

        // Sort attendees by priority, then alphabetically by name
        const priorities = {"Required": 1, "Optional": 2};
        responseAttendees.sort((attendee1, attendee2) => {
            if (attendee1.priority !== attendee2.priority) {
                return priorities[attendee1.priority] - priorities[attendee2.priority];
            }
            return formatDisplayName(attendee1.displayName) > formatDisplayName(attendee2.displayName) ? 1 : -1;
        });

        let attendeeEmails = [];
        responseAttendees.forEach((responseAttendee) => {
            attendeeEmails.push(responseAttendee.email);

            let attendeeData = (props.attendees || []).find((attendee) => attendee.email === responseAttendee.email);
            responseAttendee.type = attendeeData && attendeeData.type;
            responseAttendee.isOrganizer = attendeeData && attendeeData.isOrganizer;
            responseAttendee.timezoneShortName = timezoneIdToDisplayName(timezones || [], attendeeData && attendeeData.timezoneId, TIMEZONE_DISPLAY_TYPE.SHORT);
        });

        return (responseAttendees.length !== 0 &&
            <Column spacing="none">
                <Text type="b100"><b>{EXCHANGE_RESPONSE[responseType]} ({responseAttendees.length}/{getNonChimeAttendeesCount(attendees)})</b></Text>
                {responseAttendees.map((attendee) => {
                    return ((attendeeEmails.includes(attendee.email) || existingMeetingDetail["existing-meeting" + responseType]) ?
                        <Row key={attendee.email} spacing="xsmall">
                            {attendee.priority === ATTENDEE_PRIORITY.REQUIRED &&
                                renderPriorityIcon(ATTENDEE_PRIORITY.REQUIRED, false, 12)
                            }
                            {attendee.priority === ATTENDEE_PRIORITY.OPTIONAL &&
                                renderPriorityIcon(ATTENDEE_PRIORITY.OPTIONAL, false, 12)
                            }
                            <Column width={attendee.type === ATTENDEE_TYPE.PERSON ? `${MEETING_CARD.ATTENDEE_NAME_WIDTH - MEETING_CARD.ATTENDEE_TIMEZONE_WIDTH}px` : `${MEETING_CARD.ATTENDEE_NAME_WIDTH}px`}>
                                <Text type="b100" breakWord={true}>{formatDisplayName(attendee.displayName)}</Text>
                            </Column>
                            {attendee.type === ATTENDEE_TYPE.PERSON && !attendee.isOrganizer && suggestionsTimezoneShortName !== attendee.timezoneShortName &&
                                <Column width={`${MEETING_CARD.ATTENDEE_TIMEZONE_WIDTH}px`}>
                                    <Text type="b100" color="secondary">
                                        {attendee.timezoneShortName}
                                    </Text>
                                </Column>
                            }
                        </Row>
                        :
                        undefined
                    )
                })}
            </Column>
        );
    };

    // Initialize selection to existing meeting time
    useEffect(() => {
        if (existingMeetingDetail["existing-meeting"] === undefined) {
            setExistingMeetingDetail({"existing-meeting": true});
        }
    }, [existingMeetingDetail, setExistingMeetingDetail]);

    // Load timezoneName for review card only for initial timezoneName
    useEffect(() => {
        if (timezones.length && !timezoneName) {
            setTimezoneName(timezoneIdToDisplayName(timezones, meetingDetails.startTimezone.id));
        }
    }, [timezones, meetingDetails.startTimezone, timezoneName]); // meetingDetails.startTimezone should not change

    return (
        <Column
            spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "medium medium medium none" : "medium"}
            width="fill"
            height="fill"
            maxHeight="450px"
            alignmentHorizontal="center"
        >
            <Box
                type="outline"
                width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "400px" : "100%"}
                height="fill"
            >
                <Row
                    spacing="medium"
                    spacingInset="300 medium"
                    width="100%"
                    alignmentHorizontal="left"
                    backgroundColor="#F9FAFA"
                >
                    <Text type="h100">Selected meeting slot</Text>
                </Row>
                <section aria-label={`Time: ${formatDateTime(startTime, "longWeekdayDateYear")},
                                            ${displayedStartTime} to
                                            ${displayedEndTime}    
                                            ${timezoneName}                                   
                                            ${meetingDetails.recurrence ? parseRecurrence(meetingDetails.recurrence) : ""}`}>
                    <Column spacing="small" spacingInset="medium">
                        <Row alignmentHorizontal="justify">
                            {displayedStartDate === displayedEndDate ?
                                <Row alignmentVertical="top" spacing="small">
                                    <Icon tokens={clockTokens} />
                                    <Column spacing="small">
                                        <Row spacingInset="xxsmall" spacing="xsmall">
                                            <Text type="b200">
                                                <b>{displayedStartTime}&nbsp;-&nbsp;{displayedEndTime}</b>
                                            </Text>
                                            <Text type="b100" color="secondary">
                                                &nbsp;{timezoneName}
                                            </Text>
                                        </Row>
                                        <Row spacingInset="none xxsmall">
                                            <Text type="b200">
                                                {displayedEndDate}
                                            </Text>
                                        </Row>
                                    </Column>
                                </Row>
                                : // for multiple days time format
                                <Row alignmentVertical="top" spacing="small">
                                    <Icon tokens={clockTokens} />
                                    <Column spacing="small">
                                        <Row spacingInset="xxsmall" spacing="xsmall">
                                            <Text type="b200">
                                                <b>{displayedStartTime}</b>
                                            </Text>
                                            <Text type="b100" color="secondary">
                                                &nbsp;{timezoneName}
                                            </Text>
                                        </Row>
                                        <Row spacingInset="none xxsmall">
                                            <Text type="b200">
                                                {displayedStartDate}
                                            </Text>
                                        </Row>
                                    </Column>
                                    <Column spacing="small">
                                        <Row spacingInset="xxsmall" spacing="xsmall">
                                            <Text type="b200">
                                                <b>to&nbsp;&nbsp;{displayedEndTime}</b>
                                            </Text>
                                            <Text type="b100" color="secondary">
                                                &nbsp;{timezoneName}
                                            </Text>
                                        </Row>
                                        <Row spacingInset="none none none 450">
                                            <Text type="b200">
                                                {displayedEndDate}
                                            </Text>
                                        </Row>
                                    </Column>
                                </Row>
                            }
                            <Button
                                type="icon"
                                size="xsmall"
                                label="Edit meeting date or time"
                                onClick={() => onChangeStep(STEP.SMART_SUGGESTIONS)}
                            >
                                <Icon tokens={pencilTokens} />
                            </Button>
                        </Row>
                        {meetingDetails.recurrence &&
                        <Row spacing="small">
                            <Icon tokens={syncTokens} />
                            <Text type="b200">{parseRecurrence(meetingDetails.recurrence)}</Text>
                        </Row>
                        }
                    </Column>
                </section>
                <Row spacingInset="none medium">
                    <Divider size="small"/>
                </Row>
                <section aria-label={`Attendees: ${getAttendeeResponseTypeCount("accept", attendees) === getNonChimeAttendeesCount(attendees) ? "All attendees accepted" : getAttendeeResponseTypeCount("accept", attendees) + " of " + getNonChimeAttendeesCount(attendees) + " attendees accepted"}. 
                                      Location: ${meetingDetails.location}`}>
                    <Row spacing="small" spacingInset="small medium" height="fill">
                        <Icon tokens={userTokens} />
                        <Column width="stretch" spacing="small" spacingInset="xxsmall">
                            <Row spacing="xsmall" height="20px">
                                <Tag type={getAttendeeAvailabilityType(attendees)}>
                                    {getAttendeeResponseTypeCount("accept", attendees) === getNonChimeAttendeesCount(attendees) ? "All attendees accepted" : getAttendeeResponseTypeCount("accept", attendees) + " of " + getNonChimeAttendeesCount(attendees) + " attendees accepted"}
                                </Tag>
                            </Row>
                        </Column>
                        <Button
                            type="icon"
                            size="xsmall"
                            label="Edit meeting attendees and rooms"
                            onClick={() => onChangeStep(STEP.ATTENDEES_AND_ROOMS)}
                        >
                            <Icon tokens={pencilTokens} />
                        </Button>
                    </Row>
                        {existingMeetingDetail["existing-meeting"] &&
                        <Row spacingInset="none 450 none large" height="fill">
                            <Column spacingInset="none none none xxsmall" width="stretch">
                                <Column spacing="small" spacingInset="none none none 400" height="fill" maxHeight="75px" overflowY="auto">
                                    {getAttendeeResponseGroup("decline")}
                                    {getAttendeeResponseGroup("tentative")}
                                    {getAttendeeResponseGroup("accept")}
                                    {getAttendeeResponseGroup("noResponse")}
                                </Column>
                            </Column>
                        </Row>
                        }
                    <Column spacing="xsmall" spacingInset="medium">
                        <Row alignmentHorizontal="justify">
                            <Row spacing="small" alignmentVertical="top">
                                <RoomIcon alt="room" />
                                {meetingDetails.resources.length !== 0 ?
                                    <Column width="fill" alignmentHorizontal="left" spacingInset="none none none xxsmall">
                                        {sortRoomsByName(meetingDetails.resources).map((room) => (
                                            <Column key={room.email}>
                                                <Tag type={room.response === ATTENDEE_RESPONSE.ACCEPT ? "success" : "error"}>
                                                    <Column>
                                                        <Text type="b200" truncate={true}>{shortRoomName(room.displayName)}</Text>
                                                    </Column>
                                                </Tag>
                                            </Column>
                                        ))}
                                    </Column>
                                    :
                                    <Column width="fill" alignmentHorizontal="left" spacingInset="none none none xxsmall">
                                        <Tag type="success">Virtual only</Tag>
                                    </Column>
                                }
                            </Row>
                        </Row>
                    </Column>
                </section>
            </Box>
        </Column>
    )
};

export default ExistingMeetingReviewCard;
