import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getIdentity,
    getLivestreamMeeting,
    getLivestreamStep,
    getMasterState,
    getPeopleSuggestions as getPeopleSuggestionsSelector,
    getGroupSuggestions as getGroupSuggestionsSelector,
    getSuggestions as getMeetingSuggestionsSelector,
    getAttendees,
    getBuildings,
    getFindRooms,
    getMeetingDetails,
    getMeetingList,
    getRooms,
    getLocations,
    getFavorites,
    getTimezonesList,
    getPrimaryBrowserTimezoneId,
    getChimeUniqueIds,
    getChimeUser,
    isSearching as isSearchingSelector,
    getLivestreamSuggestionViewType,
    getLivestreamStations,
    getWorkHours,
    getLoadMeeting,
    getSettingsPrimaryTimezone,
    getSettingsWorkingHours,
    getSettingsTimeFormat,
    getSettingsDateFormat,
    getSettingsBuilding,
    getSettingsFloor,
    getSettingsMinimumSeats,
    getSettingsCamera,
    getSettingsDisplay,
    getSettingsNoRestricted,
    getSettingsNoManaged,
} from "../../../sagas/selector";
import {
    changeStep,
    createEventIntakeDetails,
    searchSuggestion,
    selectSuggestion,
    setLoadMeeting,
    setSuggestionViewType,
    selectStations,
    updateCurrentMeeting,
} from "../actions";
import {
    addAttendee,
    updateAttendee,
    removeAttendee,
    getRASDataForPerson,
    getRASDataForGroup,
    getPeopleSuggestions,
    clearPeopleSuggestions,
    getGroupSuggestions,
    clearGroupSuggestions,
    addAttendeeByEmail,
} from "../../people/actions";
import {
    getSuggestions as getMeetingSuggestions,
} from "../../shared/actions";
import {
    hideToast,
    showToast,
} from "../../shared/toasts/actions";
import {
    createChimePin,
    getUserByEmail,
    saveChimePin,
} from "../../shared/chime/actions";
import {
    loadFloors,
    updateFindRooms,
    addRoom,
    removeRoom,
    addLocation,
    removeLocation,
    addRoomByEmail,
} from "../../shared/locations/actions";
import {
    addFavorite,
    removeFavorite,
} from "../../shared/favorites/actions";

import Responsive from "@amzn/meridian/responsive";

import LivestreamForm from "../components/livestream-form";
import MeetingsToaster from "../../shared/toasts/containers/toast-container";

import { generateNewMeetingId, getChimePinFromChimeAttendee, isOneTimeChimePinAttendee } from "../../shared/meeting-utils";
import { LIVESTREAM_BUILDING_CODE } from "../livestream-constants";
import { FLOW_TYPE, FIND_GROUP } from "../../meeting-scheduler/meeting-scheduler-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { chimeEmailToAttendee, rasUserToAttendee } from "../../people/people-utils";
import { ATTENDEE_PRIORITY, ATTENDEE_RESPONSE, ATTENDEE_TYPE } from "../../people/people-constants";
import { getUserDefaultLocation } from "../../shared/locations/locations-utils";
import { LOCATION_DEFAULTS } from "../../shared/locations/locations-constants";
import { removeMeetingsInfo } from "../../shared/body/body-utils";
import { WORKING_HOURS } from "../../shared/settings/settings-constants";
import { getTimeMinutes } from "../../shared/settings/settings-utils";

const LivestreamContainer = (props) => {
    const flowType = props.flowType;
    const identity = useSelector(getIdentity);
    const meeting = useSelector(getLivestreamMeeting);
    const meetingList = useSelector(getMeetingList);
    const meetingDetails = useSelector(getMeetingDetails); // information to edit existing meeting
    const currentStep = useSelector(getLivestreamStep);
    const masterState = useSelector(getMasterState);
    const attendees = useSelector(getAttendees).filter((attendee) => attendee.type !== ATTENDEE_TYPE.CHIME && attendee.email !== undefined);
    const selectedStations = useSelector(getLivestreamStations); // array containing station emails that will receive the event meeting invitation
    const findRooms = useSelector(getFindRooms);
    const buildings = useSelector(getBuildings);
    const rooms = useSelector(getRooms);
    const locations = useSelector(getLocations);
    const peopleSuggestions = useSelector(getPeopleSuggestionsSelector);
    const groupSuggestions = useSelector(getGroupSuggestionsSelector);
    const meetingSuggestions = useSelector(getMeetingSuggestionsSelector);
    const suggestionViewType = useSelector(getLivestreamSuggestionViewType);
    const favorites = useSelector(getFavorites);
    const isSearchingMeetingSuggestions = useSelector(isSearchingSelector);
    const timezones = useSelector(getTimezonesList);
    const primaryBrowserTimezoneId = useSelector(getPrimaryBrowserTimezoneId);
    const userSettingsBuilding = useSelector(getSettingsBuilding);
    const userSettingsFloor = useSelector(getSettingsFloor);
    const userSettingsMinimumSeats = useSelector(getSettingsMinimumSeats);
    const userSettingsCamera = useSelector(getSettingsCamera);
    const userSettingsDisplay = useSelector(getSettingsDisplay);
    const userSettingsNoRestricted = useSelector(getSettingsNoRestricted);
    const userSettingsNoManaged = useSelector(getSettingsNoManaged);
    const chimeUniqueIds = useSelector(getChimeUniqueIds);
    const chimeUser = useSelector(getChimeUser);
    const exchangeWorkHours = useSelector(getWorkHours);
    const settingsWorkHours = useSelector(getSettingsWorkingHours);
    const timezone = useSelector(getSettingsPrimaryTimezone);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const dateFormat = useSelector(getSettingsDateFormat);
    // Redux level state variable to track if we are loading a meeting stored in the meetingDetails redux state
    const loadMeeting = useSelector(getLoadMeeting);

    const dispatch = useDispatch();

    const onShowToast = (toast) => dispatch(showToast(toast));
    const onHideToast = (toast) => dispatch(hideToast(toast));
    const onChangeStep = (step) => dispatch(changeStep(step));
    const onCreateLivestream = async(prepMeeting, viewerMeeting, ticketPermalink, livestreamEventIntakeTicket) => {
        dispatch(createEventIntakeDetails(
            {meeting: prepMeeting, sendInvitations: true},
            {meeting: viewerMeeting, sendInvitations: true},
            ticketPermalink,
            "/",
            {eventIntake: livestreamEventIntakeTicket}));
    }
    const onUpdateCurrentMeeting = (meeting) => dispatch(updateCurrentMeeting(meeting));
    const onAddAttendee = (attendee) => dispatch(addAttendee(attendee));
    const onUpdateAttendee = (attendee) => dispatch(updateAttendee(attendee));
    const onRemoveAttendee = (attendee) => dispatch(removeAttendee(attendee));
    const onLoadFloors = (buildingCode) => dispatch(loadFloors(buildingCode));
    const onUpdateFindRooms = (findRooms) => dispatch(updateFindRooms(findRooms));
    const onAddRoom = (room) => dispatch(addRoom(room));
    const onRemoveRoom = (room) => dispatch(removeRoom(room));
    const onAddLocation = (location) => dispatch(addLocation(location));
    const onRemoveLocation = (location) => dispatch(removeLocation(location));
    const onAddRoomByEmail = (roomEmail) => dispatch(addRoomByEmail(roomEmail));
    const onGetRASDataForPerson = (alias, addNewAttendee, name) => dispatch(getRASDataForPerson(alias, addNewAttendee, name));
    const onGetRASDataForGroup = (group) => dispatch(getRASDataForGroup(group));
    const onGetPeopleSuggestions = (query) => dispatch(getPeopleSuggestions(query));
    const onClearPeopleSuggestions = () => dispatch(clearPeopleSuggestions());
    const onGetGroupSuggestions = (query) => dispatch(getGroupSuggestions(query, FIND_GROUP.START, FIND_GROUP.SIZE));
    const onClearGroupSuggestions = () => dispatch(clearGroupSuggestions());
    const onAddFavorite = (favorite, alias) => dispatch(addFavorite(favorite, alias));
    const onRemoveFavorite = (favorite, alias) => dispatch(removeFavorite(favorite, alias));
    const onGetMeetingSuggestions = (suggestionRequest) => dispatch(getMeetingSuggestions(suggestionRequest));
    const onSearchSuggestion = (query, isAllDayEvent) => dispatch(searchSuggestion(query, isAllDayEvent));
    const onSelectSuggestion = (suggestion, resources) => dispatch(selectSuggestion(suggestion, resources));
    const onSetSuggestionViewType = (suggestionViewType) => dispatch(setSuggestionViewType(suggestionViewType));
    const onSelectStations = (stations) => dispatch(selectStations(stations));
    const onSetLoadMeeting = useCallback((loadMeeting) => dispatch(setLoadMeeting(loadMeeting)), [dispatch]);

    const [addBuilding, setAddBuilding] = useState(false); // flowType === FLOW_TYPE.CREATE to add location later.
    // Component level state variable to track if we are loading an existing meeting into our standard workflow inputs.
    // If loadMeeting is true (came from a meeting details modal),
    // we are loading a meeting that is already in the meetingDetails redux state.
    // If loadMeeting is false AND flowType is UPDATE (came from an edit link directly),
    // then we still need to retrieve the meeting we want to load.
    const [loadExistingMeeting, setLoadExistingMeeting] = useState(flowType === FLOW_TYPE.UPDATE || loadMeeting);

    const [chimePin, setChimePin] = useState();
    // initial timezoneValue for updating a meeting, useEffect will set initial timezoneValue for creating a meeting
    const [timezoneValue, setTimezoneValue] = useState((meetingDetails.startTimezone && meetingDetails.startTimezone.id) || primaryBrowserTimezoneId);
    const [autoCall, setAutoCall] = useState(false);

    const [loadLivestreamBuilding, setLoadLivestreamBuilding] = useState(true);
    const [workHours, setWorkHours] = useState({
        startTime: getTimeMinutes(WORKING_HOURS.START_TIME),
        endTime: getTimeMinutes(WORKING_HOURS.END_TIME),
        days: WORKING_HOURS.DAYS.slice(1, -1),
    });

    const [loadOrganizer, setloadOrganizer] = useState(true);

    const removeMeetingInfoFromBody = (body = {}) => {
        if (!body || !body.value) {
            return body;
        }

        return {
            ...body,
            value: removeMeetingsInfo(body.value)
        };
    };

    const loadAttendeeFromExistingMeeting = (attendee, priority, chimeAttendees) => {
        if (attendee.email && attendee.email.endsWith("@chime.aws")) {
            if (isOneTimeChimePinAttendee(attendee.email)) {
                // Only add chime to meeting if updating a meeting,
                // so we can generate a new one time pin for new meetings
                if (flowType === FLOW_TYPE.UPDATE) {
                    const pin = getChimePinFromChimeAttendee(attendee.email);
                    setChimePin(pin);
                    dispatch(saveChimePin({AllocatedPins: [{Pin: pin}]}));
                    onAddAttendee(chimeEmailToAttendee(`pin+${pin}@chime.aws`));
                }
            } else {
                setAutoCall(true);
                // Only add chime to meeting if updating a meeting,
                // so we can generate a new one time pin for new meetings
                if (flowType === FLOW_TYPE.UPDATE) {
                    if (chimeAttendees.length === 1) {
                        setChimePin(chimeUser.PersonalPIN);
                    }
                    onAddAttendee(chimeEmailToAttendee(attendee.email));
                }
            }
            // Do not add organizer again
        } else if (attendee.email !== identity.email) {
            dispatch(addAttendeeByEmail(attendee, priority));
        }
    };

    useEffect(() => {
        if (flowType === FLOW_TYPE.UPDATE) {
            document.title = "Edit livestream request - Amazon Meetings";
        } else {
            document.title = "Livestream request - Amazon Meetings";
        }
    }, [flowType]);

    // Load in organizer
    useEffect(() => {
        if (identity && identity.username && attendees && !attendees.find((attendee) => attendee.isOrganizer) && loadOrganizer) {
            let organizer = rasUserToAttendee(identity, ATTENDEE_RESPONSE.ORGANIZER, ATTENDEE_PRIORITY.REQUIRED);
            dispatch(addAttendee(organizer));
            setloadOrganizer(false);
        }
    }, [identity, attendees, dispatch, loadOrganizer]);

    // On create meeting, load in user's default location
    useEffect(() => {
        if (identity && identity.username && addBuilding && buildings) {
            const { userDefaultBuilding, userDefaultFloor } = getUserDefaultLocation(identity.site, buildings);

            dispatch(addLocation({
                minCapacity: LOCATION_DEFAULTS.MIN_CAPACITY,
                buildingCode: userDefaultBuilding,
                floor: userDefaultFloor,
                resourceList: LOCATION_DEFAULTS.RESOURCE_LIST,
                restrictedAllowed: LOCATION_DEFAULTS.RESTRICTED_ALLOWED,
                hiddenAllowed: LOCATION_DEFAULTS.HIDDEN_ALLOWED,
                thisBuildingOnly: LOCATION_DEFAULTS.THIS_BUILDING_ONLY,
            }));

            setAddBuilding(false);
        }
    }, [identity, flowType, buildings, addBuilding, dispatch]);

    // Load settingsPrimaryTimeZone for timezone selector and meeting request only for initial timezoneValue when creating a meeting
    useEffect(() => {
        if (timezone && flowType !== FLOW_TYPE.UPDATE) {
            setTimezoneValue(timezone);
        }
    }, [flowType, timezone]);

    useEffect(() => {
        if (settingsWorkHours && settingsWorkHours.days && settingsWorkHours.startTime && settingsWorkHours.endTime) {
            setWorkHours({
                days: settingsWorkHours.days,
                startTime: getTimeMinutes(settingsWorkHours.startTime),
                endTime: getTimeMinutes(settingsWorkHours.endTime),
            });
        } else if (exchangeWorkHours && !settingsWorkHours) {
            setWorkHours({
                ...exchangeWorkHours,
                days: WORKING_HOURS.DAYS,
            });
        }
    }, [exchangeWorkHours, settingsWorkHours]);

    // generates a new chime pin if there isn't one
    useEffect(() => {
        if (identity && identity.email && chimeUniqueIds && !chimeUniqueIds.AllocatedPins && flowType === FLOW_TYPE.CREATE) {
            dispatch(createChimePin({entities: [{email: identity.email}]}));
            const generatedChimeBridge = chimeUniqueIds.AllocatedPins && chimeUniqueIds.AllocatedPins[0].Pin;
            onAddAttendee(chimeEmailToAttendee(`pin+${generatedChimeBridge}@chime.aws`));
        }
    // eslint-disable-next-line
    }, [identity, chimeUniqueIds, flowType, dispatch]);

    // get personal chime pin if chimeUser is empty.
    useEffect(() => {
        if (identity && identity.username && identity.email && Object.keys(chimeUser).length === 0) {
            dispatch(getUserByEmail({userEmail: identity.email, username: identity.username}));
        }
    }, [identity, chimeUser, dispatch]);

    useEffect(() => {
        // When creating a meeting, if there is no uniqueID OR we are loading a meeting, create a new uniqueID
        if (identity && identity.username && flowType === FLOW_TYPE.CREATE && (!meeting.uniqueID || loadMeeting)) {
            dispatch(updateCurrentMeeting(({uniqueID: generateNewMeetingId(identity.username)})));
            onSetLoadMeeting(false);
        }
    }, [identity, meeting.uniqueID, loadMeeting, flowType, dispatch, onSetLoadMeeting]);

    // On load meeting, update the state with meeting details
    useEffect(() => {
        if (identity && identity.username && loadExistingMeeting && meetingDetails.entryID) {
            // Load attendees and locations excluding chime emails
            let chimeAttendees = meetingDetails.requiredAttendees.filter((attendee) => attendee.email.endsWith("@chime.aws"));
            if (chimeAttendees.length === 0) {
                setChimePin("none");
            }
            // Call createChimePin to have a generated ChimePin ready if one isn't present in the meeting invite
            if (chimeAttendees.length === 0 || (chimeAttendees.length === 1 && chimeAttendees[0].email === "meet@chime.aws")) {
                dispatch(createChimePin({entities: [{email: identity.email}]}));
            }

            meetingDetails.requiredAttendees.forEach((attendee) => {
                loadAttendeeFromExistingMeeting(attendee, ATTENDEE_PRIORITY.REQUIRED, chimeAttendees)
            });
            meetingDetails.optionalAttendees.forEach((attendee) => {
                loadAttendeeFromExistingMeeting(attendee, ATTENDEE_PRIORITY.OPTIONAL, chimeAttendees)
            });
            meetingDetails.resources.forEach((resource) => {
                dispatch(addRoomByEmail(resource));
            });

            // Update the state with existing meeting details
            dispatch(updateCurrentMeeting({
                // Keep the same body if updating, otherwise remove the meeting info if present
                "body": flowType === FLOW_TYPE.UPDATE ? meetingDetails.body : removeMeetingInfoFromBody(meetingDetails.body),
                "location": meetingDetails.location || "",
                // Keep the same organizer if updating, otherwise use the current user's email as the organizer
                "organizer": flowType === FLOW_TYPE.UPDATE ? meetingDetails.organizer : identity.email,
                "isAllDayEvent": meetingDetails.isAllDayEvent,
                "isPrivate": meetingDetails.isPrivate,
                "isResponseRequested": meetingDetails.isResponseRequested,
                "status": meetingDetails.status,
                "subject": meetingDetails.subject,
                "startTimezone": meetingDetails.startTimezone,
                "endTimezone": meetingDetails.endTimezone,
            }));
            setLoadExistingMeeting(false);
        };
    // eslint-disable-next-line
    }, [identity, flowType, loadExistingMeeting, setLoadExistingMeeting, chimeUser, meetingDetails, dispatch]);

    useEffect(() => {
        if (autoCall) {
            onAddAttendee(chimeEmailToAttendee("meet@chime.aws"));
        } else {
            onRemoveAttendee(chimeEmailToAttendee("meet@chime.aws"));
        }
    // eslint-disable-next-line
    }, [autoCall]);

    // Load building which contains livestream engineer stations
    useEffect(() => {
        const livestreamBuilding = buildings && buildings.find((building) => building.value === LIVESTREAM_BUILDING_CODE);
        if (livestreamBuilding && livestreamBuilding.value && !livestreamBuilding.floorList && loadLivestreamBuilding) {
            onLoadFloors(LIVESTREAM_BUILDING_CODE);
            setLoadLivestreamBuilding(false);
        }
    // eslint-disable-next-line
    }, [buildings]);

    return (
        <Responsive
            query="min-width"
            props={{
                screenSizeBreakpoint: {
                    default: SCREEN_SIZE.MOBILE_VIEW, // 360px
                    "1200px": SCREEN_SIZE.FULL_VIEW,
                    "900px": SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND,
                    "700px": SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR,
                    "550px": SCREEN_SIZE.PARTIAL_MOBILE_VIEW
                }
            }}
        >
            {(responsiveProps) =>
                <React.Fragment>
                    <LivestreamForm
                        loadLivestreamBuilding={loadLivestreamBuilding}
                        flowType={flowType}
                        screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                        mastheadSize={props.mastheadSize}
                        identity={identity}
                        meeting={meeting}
                        meetingList={meetingList}
                        meetingDetails={meetingDetails}
                        loadExistingMeeting={loadExistingMeeting}
                        currentStep={currentStep}
                        masterState={masterState}
                        attendees={attendees}
                        findRooms={findRooms}
                        buildings={buildings}
                        rooms={rooms}
                        locations={locations}
                        peopleSuggestions={peopleSuggestions}
                        groupSuggestions={groupSuggestions}
                        favorites={favorites}
                        meetingSuggestions={meetingSuggestions}
                        isSearchingMeetingSuggestions={isSearchingMeetingSuggestions}
                        timezones={timezones}
                        userSettingsBuilding={userSettingsBuilding}
                        userSettingsFloor={userSettingsFloor}
                        userSettingsMinimumSeats={userSettingsMinimumSeats}
                        userSettingsCamera={userSettingsCamera}
                        userSettingsDisplay={userSettingsDisplay}
                        userSettingsNoRestricted={userSettingsNoRestricted}
                        userSettingsNoManaged={userSettingsNoManaged}
                        chimePin={chimePin}
                        setChimePin={setChimePin}
                        primaryBrowserTimezoneId={primaryBrowserTimezoneId}
                        timezoneValue={timezoneValue}
                        setTimezoneValue={setTimezoneValue}
                        autoCall={autoCall}
                        setAutoCall={setAutoCall}
                        chimeUniqueIds={chimeUniqueIds}
                        chimeUser={chimeUser}
                        workHours={workHours}
                        loadMeeting={loadMeeting}
                        onShowToast={onShowToast}
                        onHideToast={onHideToast}
                        onChangeStep={onChangeStep}
                        onCreateLivestream={onCreateLivestream}
                        onUpdateCurrentMeeting={onUpdateCurrentMeeting}
                        onAddAttendee={onAddAttendee}
                        onUpdateAttendee={onUpdateAttendee}
                        onRemoveAttendee={onRemoveAttendee}
                        onLoadFloors={onLoadFloors}
                        onUpdateFindRooms={onUpdateFindRooms}
                        onAddRoom={onAddRoom}
                        onRemoveRoom={onRemoveRoom}
                        onAddLocation={onAddLocation}
                        onRemoveLocation={onRemoveLocation}
                        onAddRoomByEmail={onAddRoomByEmail}
                        onGetRASDataForPerson={onGetRASDataForPerson}
                        onGetRASDataForGroup={onGetRASDataForGroup}
                        onGetPeopleSuggestions={onGetPeopleSuggestions}
                        onClearPeopleSuggestions={onClearPeopleSuggestions}
                        onGetGroupSuggestions={onGetGroupSuggestions}
                        onClearGroupSuggestions={onClearGroupSuggestions}
                        onAddFavorite={onAddFavorite}
                        onRemoveFavorite={onRemoveFavorite}
                        onGetMeetingSuggestions={onGetMeetingSuggestions}
                        onSearchSuggestion={onSearchSuggestion}
                        onSelectSuggestion={onSelectSuggestion}
                        suggestionViewType={suggestionViewType}
                        onSetSuggestionViewType={onSetSuggestionViewType}
                        selectedStations={selectedStations}
                        onSelectStations={onSelectStations}
                        timeFormat={timeFormat}
                        dateFormat={dateFormat}
                    />
                    <MeetingsToaster/>
                </React.Fragment>
            }
        </Responsive>
    );
};

export default LivestreamContainer;
