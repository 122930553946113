import { COMPONENT_ALIAS, IS_IT_DOWN_LOGIN_URL, IS_IT_DOWN_MEETINGS_URL, INDICATOR, STATUS } from "./is-it-down-constants";

export const getIsItDownInfo = (region = "PDX", stage) => {
    const isItDownStageAlias = COMPONENT_ALIAS.ALL_REGIONS[stage] || (stage !== undefined && COMPONENT_ALIAS.ALL_REGIONS["Alpha"]);
    const isItDownAllRegions = `${IS_IT_DOWN_MEETINGS_URL}${isItDownStageAlias}.json`;
    const isItDownRegion = `${IS_IT_DOWN_MEETINGS_URL}${COMPONENT_ALIAS[region]}.json`;
    // Until we update the alarms for AMW 2.0, do not retrieve Cloudfront isItDown info
    // const isItDownCloudFront = `${IS_IT_DOWN_MEETINGS_URL}${COMPONENT_ALIAS.CLOUDFRONT}.json`;

    // Check if we are authenticated
    return fetch(IS_IT_DOWN_LOGIN_URL, { credentials: "include" }).then((response) => response.ok && response.json()).then((data) => {
        // If we are not authenticated, authenticate
        if (data && data.authn_endpoint) {
            let idpUrl = data.authn_endpoint;
            idpUrl = removeQueryParam(idpUrl, "redirect_uri");
            const encodedRedirectUri = encodeURIComponent(isItDownAllRegions);
            idpUrl = appendQueryParam(idpUrl, "redirect_uri", encodedRedirectUri);
            return fetch(idpUrl, { credentials: "include" });
        }
        return undefined;
    }).then((response) => response && response.ok && response.text()).then((data) => {
        if (data) {
            // Fetch the amzn_sso_token
            return fetch(`${IS_IT_DOWN_LOGIN_URL}?id_token=${data}`, { credentials: "include" });
        }
    }).then(() => {
        // Check the isItDown status for a custom message, cloudfront errors, and the specific region
        return Promise.all([
            stage && fetch(isItDownAllRegions, { credentials: "include" }).then((response) => response.ok && response.json()),
            fetch(isItDownRegion, { credentials: "include" }).then((response) => response.ok && response.json()),
            // fetch(isItDownCloudFront, { credentials: "include" }).then((response) => response.ok && response.json()),
        ]).then((responses) => {
            let isItDownInfo = [];

            responses.forEach((json, jsonIndex) => {
                if (json) {
                    const isItDownData = json.components?.component;
                    const useOverride = isItDownData.override_status && isItDownData.override_status !== "";
                    const statusIsEmpty = isItDownData.status === STATUS.OK ||
                        isItDownData.status === STATUS.NO_DATA ||
                        isItDownData.status === STATUS.NO_MONITOR_STATE;
                    const shouldShow = !statusIsEmpty || useOverride;

                    if (shouldShow) {
                        let indicator = isItDownData.indicator;
                        let status = isItDownData.status;
                        let type;

                        // ticket data will include ID and severity
                        // e.g. #{id} (Sev-2)
                        let tickets = isItDownData.tickets?.split(" ");

                        if (useOverride) {
                            indicator = isItDownData.override_indicator;
                            status = isItDownData.override_status;
                        } else if (tickets?.length) {
                            let ticketNumber = 1;

                            tickets.forEach((ticket) => {
                                if (ticket.includes("#")) { // tickets IDs start with #
                                    let ticketId = ticket.substring(1);

                                    if (tickets.length > 2) {
                                        status += `\n[View ticket ${ticketNumber}](https://t.corp.amazon.com/issues/${ticketId})`;
                                        ticketNumber++;
                                    } else {
                                        status += `\n[View ticket](https://t.corp.amazon.com/issues/${ticketId})`;
                                    }
                                }
                            });
                        }

                        switch (indicator) {
                            case INDICATOR.SUCCESS:
                                type = "success";
                                break;
                            case INDICATOR.INFORMATIONAL:
                                type = "informational";
                                break;
                            case INDICATOR.WARNING:
                                type = "warning";
                                break;
                            case INDICATOR.ERROR:
                            default:
                                type = "error";
                                break;
                        }

                        const info = {
                            message: status,
                            type: type,
                        };

                        isItDownInfo.push(info);
                    }
                }
            });

            return isItDownInfo;
        });
    }).catch((error) => console.error(error));
};

// Remaining code copied from the openid.xhr.js in the HCTBrowserPlugin

// https://code.amazon.com/packages/HCTBrowserPlugin/blobs/ea69a9127ed7baf5d606a7eedd6dabdaf3ae6c72/--/scripts/openid.xhr.js#L320
// Utility function for deconstructing a URL.
// Need the client ID, which is the hostname[:port] of the url, and need absolute url.
function getUrlProperties(url) {
    // Use the DOM to avoid having to use regex.
    var a = document.createElement('a');
    a.href = url;
    // eslint-disable-next-line no-self-assign
    a.href = a.href; // I'm not kidding.
                     // a.href automatically expands out to the full URL, but in IE the other fields are not automatically updated
                     // so you get hostname="", protocol = ":", etc for a relative URL. But setting href to the full URL updates all
                     // the fields. Hence, this *ridiculous* statement bandages the IE issue.
    var host = (a.hostname + (a.port ? ":" + a.port : "")); // Can't just use a.host because IE sneaks in a :443 if there is no port number.
    var endpoint = a.protocol + "//" + host;
    var pathname = a.pathname || "";
    if (pathname && pathname[0] !== "/") {
        // IE9.0 and below do not include the leading slash
        pathname = "/" + pathname;
    }
    return {
        absoluteUrl: a.href, // Turns a relative URL into an absolute one.
        host: host,
        endpoint: endpoint,
        base: endpoint + pathname,
        query: a.search,
        fragment: a.hash
    };
};

// https://code.amazon.com/packages/HCTBrowserPlugin/blobs/ea69a9127ed7baf5d606a7eedd6dabdaf3ae6c72/--/scripts/openid.xhr.js#L347
// Given a url and query parameter, return the url with all occurrences of the query parameter removed, if it is present.
// Otherwise return the url unaltered.
function removeQueryParam(url, paramName) {
    var urlProps = getUrlProperties(url);
    if (!urlProps.query) {
        return url;
    }
    var parts = urlProps.query.split('&');
    if (parts[0].charAt(0) === "?") {
        parts[0] = parts[0].substring(1);
    }
    var remainingParts = [];
    for (var i = 0; i < parts.length; i++) {
        var p = parts[i];
        if (!p) {
            // Can happen with extraneous leading/trailing '&'s, or double ampersands
            continue;
        }
        var keyVal = p.split('=');
        if (keyVal[0] === paramName) {
            // Found the param we want to remove
            continue;
        }
        remainingParts.push(p);
    }

    var newQuery = "?" + remainingParts.join('&');
    return overwriteQueryStr(urlProps, newQuery);
};

// https://code.amazon.com/packages/HCTBrowserPlugin/blobs/ea69a9127ed7baf5d606a7eedd6dabdaf3ae6c72/--/scripts/openid.xhr.js#L376
// Return a url with the new query string. urlProps is unchanged.
function overwriteQueryStr(urlProps, newQueryStr) {
    return urlProps.base + newQueryStr + urlProps.fragment;
};

// https://code.amazon.com/packages/HCTBrowserPlugin/blobs/ea69a9127ed7baf5d606a7eedd6dabdaf3ae6c72/--/scripts/openid.xhr.js#L381
// Append a query string parameter to a given query string. Return the resulting query string.
function appendQueryParam(queryStr, queryParameter, queryValue) {
    var queryArg = queryParameter + "=" + queryValue;
    if (queryStr.indexOf("?") === -1) {
        return "?" + queryArg;
    } else {
        return queryStr + "&" + queryArg;
    }
};