import format from "date-fns/format";

import { ATTENDEE_TYPE } from "../people/people-constants";
import { timezoneIdToDisplayName } from "../shared/timezones/timezones-utils";

const formatDate = (date) => format(date, "yyyy-MM-dd");
const formatTime = (date) => format(date, "HH:mm:ss");

const DATE_WEEKDAY_FORMAT_PATTERN = "EEEE MMMM dd, yyyy";
const DATETIME_WEEKDAY_FORMAT_PATTERN = "EEEE MMMM dd, yyyy h:mm a";
const TIMEONLY_FORMAT_PATTERN = "h:mm a";
const DATEONLY_FORMAT_PATTERN = "MMM dd";
const DATE_MONTH_FORMAT_PATTERN = "MMM dd h:mm a";
const DATETIME_FORMAT_PATTERN = "MMMM dd, yyyy h:mm a";

export const generateOOTOSubject = (identity, meeting, timezones) => {
    const timeMessage = getFormattedSubjectTimeInfo(meeting, timezones);
    return `${identity.username}@ OOTO - ${timeMessage}`;
};

export const getFormattedSubjectTimeInfo = (meeting, timezones) => {
    const startTimeAsDate = new Date(meeting.time.startTime * 1000);
    const startDate = formatDate(startTimeAsDate);
    const endTimeAsDate = formatTime(new Date(meeting.time.endTime * 1000)) === "00:00:00"
        ? new Date((meeting.time.endTime - 60) * 1000)
        : new Date(meeting.time.endTime * 1000);
    const endDate = formatDate(endTimeAsDate);

    let timeMessage;
    if (meeting.isAllDayEvent) {
        //check if is just one day event
        if (startDate === endDate) {
            timeMessage = format(startTimeAsDate, DATEONLY_FORMAT_PATTERN);
        } else {
            timeMessage = `${format(startTimeAsDate, DATEONLY_FORMAT_PATTERN)} - ${format(endTimeAsDate, DATEONLY_FORMAT_PATTERN)}`;
        }
    } else {
        const timezone = timezoneIdToDisplayName(timezones, meeting.endTimezone.id);
        if (startDate === endDate) {
            timeMessage = `${format(startTimeAsDate, DATE_MONTH_FORMAT_PATTERN)} - ${format(endTimeAsDate, TIMEONLY_FORMAT_PATTERN)} ${timezone}`;
        } else {
            timeMessage = `${format(startTimeAsDate, DATE_MONTH_FORMAT_PATTERN)} - ${format(endTimeAsDate, DATE_MONTH_FORMAT_PATTERN)} ${timezone}`;
        }
    }

    return timeMessage;
}

export const generateOOTOBody = (identity, meeting, timezones) => {
    const timeMessage = getFormattedMeetingTimeInfo(meeting, timezones);

    return `<div>Hello,</div><div><br></div><div>I will be out of the office ${timeMessage}.</div><div><br></div><div>Thanks,</div><div>${identity.name}</div>`;
};

export const getFormattedMeetingTimeInfo = (meeting, timezones) => {
    const startTimeAsDate = new Date(meeting.time.startTime * 1000);
    const endTimeAsDate = formatTime(new Date(meeting.time.endTime * 1000)) === "00:00:00"
        ? new Date((meeting.time.endTime - 60) * 1000)
        : new Date(meeting.time.endTime * 1000);

    let timeMessage;
    if (meeting.isAllDayEvent) {
        //check if is just one day event
        if (formatDate(startTimeAsDate) === formatDate(endTimeAsDate)) {
            timeMessage = `on ${format(startTimeAsDate, DATE_WEEKDAY_FORMAT_PATTERN)}`;
        } else {
            timeMessage = `from ${format(startTimeAsDate, DATE_WEEKDAY_FORMAT_PATTERN)} through ${format(endTimeAsDate, DATE_WEEKDAY_FORMAT_PATTERN)}`;
        }
    } else {
        const startTimezone = timezoneIdToDisplayName(timezones, meeting.startTimezone.id);
        const endTimeZone = timezoneIdToDisplayName(timezones, meeting.endTimezone.id);
        if (formatDate(startTimeAsDate) === formatDate(endTimeAsDate)) {
            timeMessage = `on ${format(startTimeAsDate, DATETIME_WEEKDAY_FORMAT_PATTERN)} - ${format(endTimeAsDate, TIMEONLY_FORMAT_PATTERN)} ${endTimeZone}`;
        } else {
            timeMessage = `from ${format(startTimeAsDate, DATETIME_FORMAT_PATTERN)} ${startTimezone} through ${format(endTimeAsDate, DATETIME_FORMAT_PATTERN)} ${endTimeZone}`;
        }
    }

    return timeMessage;
};

// from updateHTMLEscalationList() in AMW1.0 out-of-office.js
export const removePointOfContactDataFromBody = (message) => {
    // find all empty lines before list
    var escalationList = "(<div><br><\\/div>)*";
    // find the title
    escalationList += "<div><strong>Points? of Contact<\\/strong><\\/div>";
    // continue forward
    escalationList += "<div>.*<\\/div>";
    // until you find the last Email
    escalationList += "<div><strong>Email:</strong>.*<\\/div>";
    // check if the last person has a phone
    escalationList += "(<div><strong>Phone:<\\/strong> [0-9-+]+<\\/div>)?";
    // find all empty lines following the list
    escalationList += "(<div><br><\\/div>)*";
    // regular expression to find the escalation list
    var ESCALATION_LIST_BODY_PATTERN = new RegExp(escalationList);
    // remove old escalation list
    return message.replace(ESCALATION_LIST_BODY_PATTERN, '');
};

export const addPointOfContactDataToBody = (message, pointOfContact) => {
    if (pointOfContact.length > 0) {
        message += "<div><br></div><div><br></div><div><strong>";
        message += "Point" + (pointOfContact.length > 1 ? "s" : "") + " of Contact";
        message += "</strong></div>";

        pointOfContact.forEach((poc) => {
            if (poc.type === ATTENDEE_TYPE.PERSON) {
                message += "<div><a href=\"https://phonetool.amazon.com/users/";
                message += poc.alias + "\"><strong>";
                message += poc.name + "</strong></a></div>";
            } else if (poc.type === ATTENDEE_TYPE.GROUP) {
                message += "<div><strong>" + poc.identifier + "</strong></div>";
                message += "<div>Internal Group</div>";
            }
            message += "<div><strong>Email:</strong> " + poc.email + "</div>";
            if (poc.rasData && poc.rasData.phone) {
                if (poc.rasData.phone.cell) {
                    message += "<div><strong>Phone:</strong> " + poc.rasData.phone.cell + "</div>";
                } else if (poc.rasData.phone.desk) {
                    message += "<div><strong>Phone:</strong> " + poc.rasData.phone.desk + "</div>";
                } else if (poc.rasData.phone.home) {
                    message += "<div><strong>Phone:</strong> " + poc.rasData.phone.home + "</div>";
                }
            }
            message += "<div><br></div>";
        })
    }
    return message;
};

export const replaceTimeInfoAlertTitle = (timeInfoAlert, messageBodyTimeFormatChanged, messageSubjectTimeFormatChanged) => {
    const timeInfoAlertResx = /<b>.*<\/b>/i;
    if (messageBodyTimeFormatChanged && messageSubjectTimeFormatChanged) {
        timeInfoAlert.value = timeInfoAlert.value.replace(timeInfoAlertResx, "<b>subject and message body</b>");
    } else if (messageBodyTimeFormatChanged) {
        timeInfoAlert.value = timeInfoAlert.value.replace(timeInfoAlertResx, "<b>message body</b>");
    } else if (messageSubjectTimeFormatChanged) {
        timeInfoAlert.value = timeInfoAlert.value.replace(timeInfoAlertResx, "<b>subject</b>");
    }

    return timeInfoAlert;
};

// Sort a list of attendees by point of contact, then alphabetically by name
export const sortAttendees = (attendees, organizerEmail = undefined) => {
    if (attendees) {
        attendees.sort((attendee1, attendee2) => {
            if (attendee1.isPointOfContact && !attendee2.isPointOfContact) {
                return -1;
            } else if (!attendee1.isPointOfContact && attendee2.isPointOfContact) {
                return 1;
            }
            if (organizerEmail) {
                if (attendee1.email === organizerEmail) {
                    return -1;
                }
                if (attendee2.email === organizerEmail) {
                    return 1;
                }
            }
            return attendee1.name > attendee2.name ? 1 : -1;
        });
        return attendees;
    }
};