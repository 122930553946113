import { SET_MODAL_STATE } from "../actions/action-types";
import { MODAL_STATE } from "../meeting-quick-constants";

const initialState = {
    modelState: MODAL_STATE.CLOSE
};

function quickMeetingReducer(state = initialState, action) {
    switch (action.type) {
        case SET_MODAL_STATE:
            return {
                ...state,
                modalState: action.modalState
            };
        default:
            return state;
    }
}

export default quickMeetingReducer;