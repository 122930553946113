import React, { useEffect } from "react";

import _ from "lodash";

import RadioButton from "@amzn/meridian/radio-button";
import Row from "@amzn/meridian/row";

import { SUGGESTION_VIEW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";
import { TIMEOUT, TOAST_COMPONENT } from "../toasts/toast-constants";
import { getSuccessToastWithComponent } from "../toasts/toast-utils";
import { useDispatch } from "react-redux";
import { savePreferencesAysnc } from "../settings/actions";
import { SCHEDULING_MODE } from "../settings/settings-constants";

const SuggestionViewToggle = (props) => {
    const dispatch = useDispatch();

    const suggestionViewType = props.value;
    const onChangeSuggestionViewType = props.onChange;
    const identity = props.identity;
    const userPreferencesSchedulingMode = props.schedulingMode;
    const setRefreshSuggestions = props.setRefreshSuggestions;

    const viewTypes = [SUGGESTION_VIEW_TYPE.LIST, SUGGESTION_VIEW_TYPE.GRID];

    // On initial load if the user does not have a default view type set, set it to list
    useEffect(() => {
        if (!suggestionViewType) {
            onChangeSuggestionViewType(SUGGESTION_VIEW_TYPE.LIST);
        }
    }, [onChangeSuggestionViewType, suggestionViewType]);

    const onSetSuggestionViewType = (viewType) => {
        onChangeSuggestionViewType(viewType);
        // When user turns back from grid mode to list mode, refresh the page for one time.
        if (viewType === SUGGESTION_VIEW_TYPE.LIST) {
            setRefreshSuggestions(true);
        }

        // If the new view type is not the user's default, prompt the user with a toast to update it
        if ((viewType === SUGGESTION_VIEW_TYPE.LIST && userPreferencesSchedulingMode !== SCHEDULING_MODE.LIST_MODE) ||
            (viewType === SUGGESTION_VIEW_TYPE.GRID && userPreferencesSchedulingMode !== SCHEDULING_MODE.GRID_MODE)) {
            const toast = getSuccessToastWithComponent();
            toast.toastTimeout = TIMEOUT.QUICK;
            toast.toastActionProps.text = `${_.startCase(viewType)} view enabled.`;
            toast.toastActionProps.functionText = `Save ${viewType} view as your default in Preferences`;
            toast.toastActionProps.function = () => dispatch(
                savePreferencesAysnc(identity.username, 
                    {
                        layoutPreferences: 
                        {
                            schedulingMode: viewType === SUGGESTION_VIEW_TYPE.LIST ? SCHEDULING_MODE.LIST_MODE : SCHEDULING_MODE.GRID_MODE
                        }
                    }, false));
            toast.toastActionProps.close = true;
            toast.toastActionProps.componentName = TOAST_COMPONENT.FUNCTION;

            props.onShowToast(toast);
        }
    };

    const renderRadioButton = (viewType) => {
        return (
           <RadioButton
              checked={suggestionViewType === viewType}
              value={viewType}
              onChange={onSetSuggestionViewType}
              size="medium"
              name="suggestionViewType"
              key={viewType}
           >
              {_.startCase(viewType)}
          </RadioButton>
        );
    };

    return (
        <Row wrap="down">
            {viewTypes.map((viewType) => renderRadioButton(viewType))}
        </Row>
    );
};

export default SuggestionViewToggle;