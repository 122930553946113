import React from "react";
import { useSelector } from "react-redux";

import Divider from "@amzn/meridian/divider";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Box from "@amzn/meridian/box";
import Icon from "@amzn/meridian/icon"
import Tag from "@amzn/meridian/tag";
import clockTokens from "@amzn/meridian-tokens/base/icon/clock";
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil";
import syncTokens from "@amzn/meridian-tokens/base/icon/sync";
import userTokens from "@amzn/meridian-tokens/base/icon/user";

import "../../shared/styles/meeting-details.css";

import ExistingMeetingReviewCard from "./existing-meeting-review-card";
import MeetingSuggestionAttendeeGroup from "./meeting-suggestion-attendee-group";

import { ReactComponent as RoomIcon } from "../../../assets/icons/locations/room-black.svg";

import {
    convertEpoch,
    getAttendeeAvailabilityType,
    getAttendeeCount,
    getTotalAttendees,
    sortRoomsByName
} from "../meeting-scheduler-utils.js";
import { parseRecurrence } from "../../shared/meeting-utils";
import { shortRoomName } from "../../shared/locations/locations-utils";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { ATTENDEE_AVAILABILITY, STEP, SUGGESTION_VIEW_TYPE } from "../meeting-scheduler-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";

const MeetingReviewCard = (props) => {
    const onChangeStep = props.onChangeStep;
    const currentMeeting = useSelector(props.currentMeetingSelector);
    // This is the existing meeting we are updating, consider renaming
    const meetingDetails = props.meetingDetails;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;

    const attendees = props.attendees;
    const gridAttendeeAvailability = props.gridAttendeeAvailability;
    const suggestionViewType = props.suggestionViewType;

    // Conf rooms
    const prevRooms = props.prevRooms;
    const prevLocations = props.prevLocations;
    const selectedRooms = props.selectedRooms // Current room selection info

    // Timezones
    const timezones = props.timezones;
    const suggestionsTimezoneId = props.suggestionsTimezoneId;
    const suggestionsTimezoneName = timezoneIdToDisplayName(timezones, suggestionsTimezoneId);
    const timeFormat = props.timeFormat;

    // Datetime format and date format
    const startTime = currentMeeting && currentMeeting.time && currentMeeting.time.startTime;
    const endTime = currentMeeting && currentMeeting.time && currentMeeting.time.endTime;
    const currentYear = props.currentYear;
    const formatDateTime = (time, format) => {
        return convertEpoch(time, format, suggestionsTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR);
    };
    const displayedStartTime = formatDateTime(startTime, "time");
    const displayedEndTime = formatDateTime(endTime, "time");
    const displayedStartDate = formatDateTime(startTime, "shortWeekdayDate");
    const displayedEndDate = formatDateTime(endTime, "shortWeekdayDate");

    // Meeting suggestion info
    const meetingSuggestions = props.meetingSuggestions;
    const meetingSuggestionSelected = props.meetingSuggestionSelected;
    const meetingSuggestionsDetail = props.meetingSuggestionsDetail;
    const isExistingMeetingSelected = props.isExistingMeetingSelected;
    const [existingMeetingDetail, setExistingMeetingDetail] = [props.existingMeetingDetail, props.setExistingMeetingDetail];
    const currentMeetingSuggestion = meetingSuggestions[meetingSuggestionSelected || 0];

    // Attendee info
    const freeAttendeesCount = props.freeAttendeesCount;

    // If existing meeting was selected in previous step
    // And the user did not select a different time in grid mode
    // Show the existing meeting review card
    const showExistingMeetingReviewCard = isExistingMeetingSelected
        && startTime === meetingDetails?.time.startTime
        && endTime === meetingDetails?.time.endTime;

    // Get availability tag styling based on percentage of available attendees from the grid view
    const getAttendeeAvailabilityTypeFromGridView = (suggestion, freeAttendeesCount) => {
        let free = freeAttendeesCount !== undefined ? freeAttendeesCount : getAttendeeCount(suggestion.freePeople, attendees);
        let total = getTotalAttendees(suggestion, attendees);
        let availability = parseInt((free / total) * 100);

        if (availability > ATTENDEE_AVAILABILITY.SUCCESS) {
            return "success";
        } else if (availability > ATTENDEE_AVAILABILITY.WARNING) {
            return "warning";
        } else {
            return "error";
        }
    };

    // Return a list of attendees for a specific group (free, tentative, unavailable)
    const getAttendeeGroup = (suggestion, suggestionGroup, groupType) => {
        return (getAttendeeCount(suggestionGroup, attendees) > 0 &&
            <MeetingSuggestionAttendeeGroup
                suggestion={suggestion}
                attendees={attendees}
                attendeeGroup={suggestionGroup}
                attendeeGroupType={groupType}
                totalAttendees={getTotalAttendees(suggestion, attendees)}
                meetingSuggestionsDetail={meetingSuggestionsDetail}
                timezones={timezones}
                suggestionsTimezoneId={suggestionsTimezoneId}
                renderForMeetingAgendaAndReviewPage={true}
            />
        );
    };

    return(
        <Column width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? null : "100%"} spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "" : "none medium none none"}>
            {showExistingMeetingReviewCard ?
                <ExistingMeetingReviewCard
                    onChangeStep={onChangeStep}
                    meetingDetails={props.meetingDetails}
                    timezones={timezones}
                    currentYear={currentYear}
                    attendees={attendees}
                    suggestionsTimezoneId={suggestionsTimezoneId}
                    existingMeetingDetail={existingMeetingDetail}
                    setExistingMeetingDetail={setExistingMeetingDetail}
                    screenSizeBreakpoint={screenSizeBreakpoint}
                    timeFormat={timeFormat}
                />
                :
                <Column
                    spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "medium medium medium none" : "medium"}
                    width="fill"
                    height="fill"
                    maxHeight="450px"
                    alignmentHorizontal="center"
                >
                    <Box
                        type="outline"
                        width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "400px" : "100%"}
                        height="fill"
                    >
                        <Row
                            spacing="medium"
                            spacingInset="300 medium"
                            width="100%"
                            alignmentHorizontal="left"
                            backgroundColor="#F9FAFA"
                        >
                            <Text type="h100">Selected meeting slot</Text>
                        </Row>
                        <section aria-label={`Time: ${formatDateTime(startTime, "longWeekdayDateYear")},
                                                    ${displayedStartTime} to
                                                    ${displayedEndTime}
                                                    ${suggestionsTimezoneName}
                                                    ${currentMeeting.recurrence ? parseRecurrence(currentMeeting.recurrence) : ""}`}>
                            <Column spacing="small" spacingInset="medium">
                                <Row alignmentHorizontal="justify">
                                    {displayedStartDate === displayedEndDate ?
                                        <Row alignmentVertical="top" spacing="small">
                                            <Icon tokens={clockTokens} />
                                            <Column spacing="small">
                                                <Row spacingInset="none xxsmall" spacing="xsmall">
                                                    <Text type="b200">
                                                        <b>{displayedStartTime}&nbsp;-&nbsp;{displayedEndTime}</b>
                                                    </Text>
                                                    <Text type="b100" color="secondary">
                                                        &nbsp;{suggestionsTimezoneName}
                                                    </Text>
                                                </Row>
                                                <Row spacingInset="none xxsmall">
                                                    <Text type="b200">
                                                        {displayedEndDate}
                                                    </Text>
                                                </Row>
                                            </Column>
                                        </Row>
                                        : // for multiple days time format
                                        <Row alignmentVertical="top" spacing="small">
                                            <Icon tokens={clockTokens} />
                                            <Column spacing="small">
                                                <Row spacingInset="xxsmall" spacing="xsmall">
                                                    <Text type="b200">
                                                        <b>{displayedStartTime}</b>
                                                    </Text>
                                                    <Text type="b100" color="secondary">
                                                        &nbsp;{suggestionsTimezoneName}
                                                    </Text>
                                                </Row>
                                                <Row spacingInset="none xxsmall">
                                                    <Text type="b200">
                                                        {displayedStartDate}
                                                    </Text>
                                                </Row>
                                            </Column>
                                            <Column spacing="small">
                                                <Row spacingInset="xxsmall" spacing="xsmall">
                                                    <Text type="b200">
                                                        <b>to&nbsp;&nbsp;{displayedEndTime}</b>
                                                    </Text>
                                                    <Text type="b100" color="secondary">
                                                        &nbsp;{suggestionsTimezoneName}
                                                    </Text>
                                                </Row>
                                                <Row spacingInset="none none none 450">
                                                    <Text type="b200">
                                                        {displayedEndDate}
                                                    </Text>
                                                </Row>
                                            </Column>
                                        </Row>
                                    }
                                    <Button
                                        type="icon"
                                        size="xsmall"
                                        label="Edit meeting date or time"
                                        onClick={() => onChangeStep(STEP.SMART_SUGGESTIONS)}
                                    >
                                        <Icon tokens={pencilTokens} />
                                    </Button>
                                </Row>
                                {currentMeeting.recurrence &&
                                    <Row spacing="small">
                                        <Icon tokens={syncTokens} />
                                        <Text type="b200">{parseRecurrence(currentMeeting.recurrence)}</Text>
                                    </Row>
                                }
                            </Column>
                        </section>
                        <Row spacingInset="none medium">
                            <Divider size="small"/>
                        </Row>
                        <section aria-label={`Attendees: ${getAttendeeCount(currentMeetingSuggestion.freePeople, attendees) === getTotalAttendees(currentMeetingSuggestion, attendees) ? "All attendees free" : getAttendeeCount(currentMeetingSuggestion.freePeople, attendees) + " of " + getTotalAttendees(currentMeetingSuggestion, attendees) + " attendees free"}.
                                              Location: ${currentMeeting.location}`}>
                            <Row spacing="small" spacingInset="small medium" height="fill">
                                <Icon tokens={userTokens} />
                                <Column width="stretch" spacing="small" spacingInset="xxsmall">
                                    {suggestionViewType === SUGGESTION_VIEW_TYPE.GRID ?
                                        <Row spacing="xsmall" height="20px">
                                            <Tag type={getAttendeeAvailabilityTypeFromGridView(currentMeetingSuggestion, freeAttendeesCount)}>
                                                {freeAttendeesCount === getTotalAttendees(currentMeetingSuggestion, attendees) ? "All attendees free" : freeAttendeesCount + " of " + getTotalAttendees(currentMeetingSuggestion, attendees) + " attendees free"}
                                            </Tag>
                                        </Row>
                                        :
                                        <Row spacing="xsmall" height="20px">
                                            <Tag type={getAttendeeAvailabilityType(currentMeetingSuggestion, attendees)}>
                                                {getAttendeeCount(currentMeetingSuggestion.freePeople, attendees) === getTotalAttendees(currentMeetingSuggestion, attendees) ? "All attendees free" : getAttendeeCount(currentMeetingSuggestion.freePeople, attendees) + " of " + getTotalAttendees(currentMeetingSuggestion, attendees) + " attendees free"}
                                            </Tag>
                                        </Row>
                                    }
                                </Column>
                                <Button
                                    type="icon"
                                    size="xsmall"
                                    label="Edit meeting attendees and rooms"
                                    onClick={() => onChangeStep(STEP.ATTENDEES_AND_ROOMS)}
                                >
                                    <Icon tokens={pencilTokens} />
                                </Button>
                            </Row>
                            {suggestionViewType === SUGGESTION_VIEW_TYPE.LIST ?
                                <Row spacingInset="none 450 none large" height="fill">
                                    <Column spacingInset="none none none xxsmall" width="stretch">
                                        <Column spacing="small" spacingInset="none none none 400" height="fill" maxHeight="75px" overflowY="auto">
                                            {getAttendeeGroup(currentMeetingSuggestion, currentMeetingSuggestion.unavailablePeople, "Unavailable")}
                                            {getAttendeeGroup(currentMeetingSuggestion, currentMeetingSuggestion.tentativePeople, "Tentative")}
                                            {getAttendeeGroup(currentMeetingSuggestion, currentMeetingSuggestion.freePeople, "Available")}
                                            {getAttendeeGroup(currentMeetingSuggestion, currentMeetingSuggestion.unknownPeople, "Unknown")}
                                        </Column>
                                    </Column>
                                </Row>
                                :
                                <Row spacingInset="none 450 none large" height="fill">
                                    <Column spacingInset="none none none xxsmall" width="stretch">
                                        <Column spacing="small" spacingInset="none none none 400" height="fill" maxHeight="75px" overflowY="auto">
                                            {getAttendeeGroup(currentMeetingSuggestion, gridAttendeeAvailability.unavailablePeople, "Unavailable")}
                                            {getAttendeeGroup(currentMeetingSuggestion, gridAttendeeAvailability.tentativePeople, "Tentative")}
                                            {getAttendeeGroup(currentMeetingSuggestion, gridAttendeeAvailability.freePeople, "Available")}
                                        </Column>
                                    </Column>
                                </Row>
                            }
                            <Column spacing="xsmall" spacingInset="medium">
                                <Row alignmentHorizontal="justify">
                                    <Row spacing="small" alignmentVertical="top">
                                        <RoomIcon alt="room"/>
                                        {(prevRooms.length !== 0 || prevLocations.length !== 0) ?
                                            <Column width="fill" alignmentHorizontal="left" spacing="small" spacingInset="none none none xxsmall">
                                                {sortRoomsByName(prevRooms).map((room) => {
                                                    return (room.email &&
                                                        <Column key={currentMeetingSuggestion.startTime + "-" + room.email}>
                                                            <Tag type={currentMeetingSuggestion.freePeople.includes(room.email) ? "success" : "error"}>
                                                                <Column>
                                                                    <Text type="b200"
                                                                          truncate={true}>{shortRoomName(room.name)}
                                                                    </Text>
                                                                </Column>
                                                            </Tag>
                                                        </Column>
                                                    )
                                                })}
                                                {currentMeetingSuggestion.suggestionRoomList.map((roomList, roomListIndex) => {
                                                    return (roomList.length !== 0 &&
                                                        <Column key={currentMeetingSuggestion.startTime + "-" + roomListIndex}>
                                                            <Tag type="success">
                                                                <Text
                                                                    type="b200"
                                                                    truncate={true}>{shortRoomName(roomList[(selectedRooms[currentMeetingSuggestion.startTime + "-" + roomListIndex]) || 0].room.name)}
                                                                </Text>
                                                            </Tag>
                                                        </Column>
                                                    )
                                                })}
                                            </Column>
                                            :
                                            <Column width="fill" alignmentHorizontal="left" spacingInset="none none none xxsmall">
                                                <Tag type="success">Virtual only</Tag>
                                            </Column>
                                        }
                                    </Row>
                                </Row>
                            </Column>
                        </section>
                    </Box>
                </Column>
            }
        </Column>
    );
};

export default MeetingReviewCard;