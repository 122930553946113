import React from "react";
import { ATTENDEE_TYPE } from "../people-constants";
import UserAttendeeCard from "./user-attendee-card";
import GroupAttendeeCard from "./group-attendee-card";
import ExternalEmailAttendeeCard from "./external-email-attendee-card";

import Popover from "@amzn/meridian/popover";

const AttendeePopover = (props) => {
    // Inputs
    const attendee = props.attendee;
    const attendeeList = props.attendeeList;
    const anchorNode = props.anchorNode;
    const open = props.open;
    const onClose = props.onClose;
    const position = props.position;

    return (
        <Popover
            anchorNode={anchorNode}
            open={open}
            onClose={onClose}
            position={position}
            alignment="center"
        >
            {attendee?.type === ATTENDEE_TYPE.PERSON &&
                <UserAttendeeCard
                    attendee={attendee}
                    attendeeList={attendeeList}
                    onRemoveAttendeeByAlias={props.onRemoveAttendeeByAlias}
                    onGetRASDataForPerson={props.onGetRASDataForPerson}
                    isFavorited={props.isFavorited}
                    onFavoriteClick={props.onFavoriteClick}
                    onGetRASDataForPersonAndAddToFavorite={props.onGetRASDataForPersonAndAddToFavorite}
                    onRemoveFavorite={props.onRemoveFavorite}
                    disableAddFromPopover={props.disableAddFromPopover}
                />
            }
            {attendee?.type === ATTENDEE_TYPE.GROUP &&
                <GroupAttendeeCard
                    attendee={attendee}
                    attendeeList={attendeeList}
                    onAddAttendee={props.onAddAttendee}
                    isFavorited={props.isFavorited}
                    onGetRASDataForPersonAndAddToFavorite={props.onGetRASDataForPersonAndAddToFavorite}
                    onRemoveFavorite={props.onRemoveFavorite}
                    onRemoveAttendeeByAlias={props.onRemoveAttendeeByAlias}
                    onGetRASDataForPerson={props.onGetRASDataForPerson}
                    disableAddFromPopover={props.disableAddFromPopover}
                />
            }
            {attendee?.type === ATTENDEE_TYPE.EXTERNAL_EMAIL &&
                <ExternalEmailAttendeeCard
                    attendee={attendee}
                    isFavorited={props.isFavorited}
                    onFavoriteClick={props.onFavoriteClick}
                    onRemoveFavorite={props.onRemoveFavorite}
                />
            }
        </Popover>
    );
};

export default AttendeePopover;
