import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import format from "date-fns/format";

import { addAttendeeByEmail } from "../../people/actions";
import { findMeetings } from "../../calendar/actions";
import {
    getAttendees,
    getCalendarDate,
    getIdentity,
    getMeetingListCalendar,
    getMeetingListCalendarLoaded,
    getPollDetailsLoaded,
    getSelectedPollDetails,
    getSettingsPrimaryTimezone,
    getSettingsTimeFormat,
    getTimezonesList,
    getWorkflow,
    getMeetingListForAvailability
} from "../../../sagas/selector";
import {
    clearPollDetails,
    getAttendeePoll,
    setPollDetailsLoaded,
    findMeetingsForAvailability
} from "../actions";

import Responsive from "@amzn/meridian/responsive";

import PollRespondForm from "../components/poll-respond-form";
import MeetingsToaster from "../../shared/toasts/containers/toast-container";

import {
    compareAttendee,
    compareAvailability
} from "../poll-utils";
import { getUrlParameters } from "../../shared/permalink/permalink-utils";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";

const formatIso = date => format(date, "yyyy-MM-dd");

const PollInternalRespondContainer = (props) => {
    const currentWorkflow = useSelector(getWorkflow);
    const timezones = useSelector(getTimezonesList);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const calendarDate = useSelector(getCalendarDate) || formatIso(new Date());
    const meetingListLoaded = useSelector(getMeetingListCalendarLoaded);
    const meetingList = useSelector(getMeetingListCalendar);
    const identity = useSelector(getIdentity);
    const attendeeInformationList = useSelector(getAttendees);
    const pollDetails = useSelector(getSelectedPollDetails);
    const pollDetailsLoaded = useSelector(getPollDetailsLoaded);
    const timezone = useSelector(getSettingsPrimaryTimezone) || undefined;
    const meetingsForAvailability = useSelector(getMeetingListForAvailability);
    const queryParams = getUrlParameters(WORKFLOWS.RESPOND_POLL.HREF);

    const attendeeList = pollDetails && pollDetails.attendees && pollDetails.attendees.sort(compareAttendee);
    const availabilityList = pollDetails && pollDetails.organizerAvailability && pollDetails.organizerAvailability.sort(compareAvailability);
    const response = pollDetails && pollDetails.response;
    const attendeeAvailabilityList = pollDetails && pollDetails.availability;
    const expiration = pollDetails && pollDetails.expiration;
    const startTimeForQuery = availabilityList && availabilityList.length > 0 && availabilityList[0].startTime;
    const endTimeForQuery = availabilityList && availabilityList.length > 0 && availabilityList[availabilityList.length - 1].endTime;

    const organizer = useMemo(() => {
        return {
            email: pollDetails?.organizer
        };
    }, [pollDetails]);
    const organizerInfo = attendeeInformationList.find((attendeeInformation) => attendeeInformation.email === organizer.email);

    const dispatch = useDispatch();
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const lastTimeRangeQueried = useRef("");

    useEffect(() => {
        if (identity && identity.username && !pollDetailsLoaded && queryParams.pollID) {
            dispatch(getAttendeePoll(identity.username, queryParams.pollID));
            dispatch(setPollDetailsLoaded(true)); // TODO: Re-design to not use Redux state here
        }
    }, [identity, dispatch, queryParams, pollDetailsLoaded]);

    useEffect(() => {
        if (organizer && organizerInfo === undefined) {
            dispatch(addAttendeeByEmail(organizer));
        }
    }, [dispatch, organizer, organizerInfo])

    // Retrieve user's calendar based on chosen week.
    useEffect(() => {
        if (identity && identity.email) {
            const dateObj = parse(calendarDate, "yyyy-MM-dd", new Date());
            let startOfWeekTime = startOfWeek(dateObj).getTime();
            let endOfWeekTime = endOfWeek(dateObj).getTime();
            if (lastTimeRangeQueried.current !== `${identity.email}-${startOfWeekTime}-${endOfWeekTime}` || triggerRefresh) {
                dispatch(findMeetings(identity.email, startOfWeekTime, endOfWeekTime, -1));
                lastTimeRangeQueried.current = `${identity.email}-${startOfWeekTime}-${endOfWeekTime}`;
                setTriggerRefresh(false);
            }
        }
    }, [identity, calendarDate, triggerRefresh, dispatch]);

    useLayoutEffect(() => {
        dispatch(clearPollDetails());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Identify if there are conflicts between user's calendar and time slots
    useEffect(() => {
        if (identity && identity.email && startTimeForQuery && endTimeForQuery) {
            dispatch(findMeetingsForAvailability(identity.email, startTimeForQuery / 1000, endTimeForQuery / 1000, -1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identity, startTimeForQuery, endTimeForQuery]);

    return (
        <Responsive
            query="min-width"
            props={{
                screenSizeBreakpoint: {...SCREEN_SIZE.RESPONSIVE_BREAKPOINTS}
            }}
        >
            {(responsiveProps) =>
                <React.Fragment>
                    <PollRespondForm
                        currentWorkflow={currentWorkflow}
                        pollID={queryParams.pollID}
                        identity={identity}
                        timezone={timezone}
                        timezones={timezones}
                        timeFormat={timeFormat}
                        organizer={organizerInfo}
                        pollDetails={pollDetails}
                        pollDetailsLoaded={pollDetailsLoaded}
                        attendeeList={attendeeList}
                        availabilityList={availabilityList}
                        mastheadSize={props.mastheadSize}
                        screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                        attendeeInformationList={attendeeInformationList}
                        meetingListLoaded={meetingListLoaded}
                        meetingList={meetingList}
                        userEmail={identity.email}
                        calendarDate={calendarDate}
                        setTriggerRefresh={setTriggerRefresh}
                        response={response}
                        attendeeAvailabilityList={attendeeAvailabilityList}
                        expiration={expiration}
                        meetingsForAvailability={meetingsForAvailability}
                    />
                    <MeetingsToaster />
                </React.Fragment>
            }
        </Responsive>
    );
}

export default PollInternalRespondContainer;