import React from "react"
import PropTypes from "prop-types"

/**
 * This component is used to render a footer in your Modal. It is meant to
 * be used with Modals of type "scrollableRegion," and without it modals of
 * those type will not render correctly.
 */
const ModalFooter = ({ children }) => {
  return <footer>{children}</footer>
}

ModalFooter.propTypes = {
  /**
   * The contents of the footer. Can be any components, but we suggest you
   * include action buttons here (e.g. "Close" and "Cancel") so that they are
   * easily reachable by your customers
   */
  children: PropTypes.node.isRequired,
}

export default ModalFooter
