import React, { useEffect } from "react";

import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";

import DatePicker from "../../shared/meridian-custom-components/src/components/date-picker";
import DateRangePicker from "../../shared/meridian-custom-components/src/components/date-range-picker";
import InputGroup from "../../shared/meridian-custom-components/src/components/input-group";
import TimeSelect, { timeRange } from "../../shared/meridian-custom-components/src/components/time-select";

import MailingList from "../../people/components/mailing-list";
import TimezonePicker from "../../shared/components/timezone-search-bar.js";
import Favorites from "../../shared/favorites/components/favorites";
import PatternBackground from "../../../assets/backgrounds/pattern_small_vertical_repeating.svg";

import { DEFAULT_TIME, TABLE_ROW_COLOR, INPUT_WIDTH, INPUT_HEIGHT, BACKGROUND_OPACITY } from "../ooto-scheduler-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { updateQueryParameters } from "../../shared/permalink/permalink-utils";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";
import { getDefaultTimeFormat, getLocalesFromSettingsDateFormat } from "../../shared/settings/settings-utils";

const StepNotifyPeople = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const identity = props.identity;
    const meeting = props.meeting;
    const onUpdateMeeting = props.onUpdateMeeting;
    const timezones = props.timezones;
    const [timezoneValue, setTimezoneValue] = [props.timezoneValue, props.setTimezoneValue];

    const formatDate = props.formatDate;

    const isAllDayEvent = props.isAllDayEvent;
    const timeFormat = props.timeFormat;
    const dateFormat = props.dateFormat;
    const startTimeAsDate = props.startTimeAsDate;
    const startDate = props.startDate;
    const startTime = props.startTime;
    const endTimeAsDate = props.endTimeAsDate;
    const endDate = props.endDate;
    const endTime = props.endTime;
    const settingsWorkingHours = props.settingsWorkingHours;
    const attendees = props.attendees;
    const [queryParameters, setQueryParameters] = [props.queryParameters, props.setQueryParameters];

    const [dateRange, setDateRange] = [props.dateRange, props.setDateRange];

    const START_DATE_PICKER_INSTRUCTION_ID = "start-date-picker-instruction-id";
    const END_DATE_PICKER_INSTRUCTION_ID = "end-date-picker-instruction-id";
    const START_TIME_LABEL_ID = "start-time-label-id";
    const END_TIME_LABEL_ID = "end-time-label-id";

    const handleIsAllDayEvent = () => {
        if (isAllDayEvent) {
            if (settingsWorkingHours?.startTime && settingsWorkingHours?.endTime) {
                const [startHour, startMin] = settingsWorkingHours?.startTime.split(":");
                const [endHour, endMin] = settingsWorkingHours?.endTime.split(":");
                startTimeAsDate.setHours(startHour, startMin, DEFAULT_TIME.START_TIME.SECONDS);
                endTimeAsDate.setHours(endHour, endMin, DEFAULT_TIME.START_TIME.SECONDS);
            } else {
                startTimeAsDate.setHours(DEFAULT_TIME.START_TIME.HOURS, DEFAULT_TIME.START_TIME.MINUTES, DEFAULT_TIME.START_TIME.SECONDS);
                endTimeAsDate.setHours(DEFAULT_TIME.END_TIME.HOURS, DEFAULT_TIME.END_TIME.MINUTES, DEFAULT_TIME.END_TIME.SECONDS);
            }
        } else {
            startTimeAsDate.setHours(0, 0, 0);
            endTimeAsDate.setHours(24, 0, 0);
        }
        onUpdateMeeting({
            isAllDayEvent: !isAllDayEvent,
            time: {
                startTime: Math.floor(startTimeAsDate.getTime() / 1000),
                endTime: Math.floor(endTimeAsDate.getTime() / 1000)
            }
        })
    }

    const handleDateRangePicker = (dateRange) => {
        let [startDate, endDate] = dateRange;
        setDateRange(dateRange);

        let newStartDate = startDate ? new Date(startDate.replaceAll("-", "/")) : new Date(endDate.replaceAll("-", "/"));
        let newEndDate = endDate ? new Date(endDate.replaceAll("-", "/")) : new Date(startDate.replaceAll("-", "/"));

        newStartDate.setHours(0, 0, 0);
        newEndDate.setHours(24, 0, 0);
        onUpdateMeeting({
            time: {
                startTime: Math.floor(newStartDate.getTime() / 1000),
                endTime: Math.floor(newEndDate.getTime() / 1000)
            }
        });
    }

    const handleStartDatePicker = (date) => {
        let [year, month, day] = date.split('-');
        let newDate = startTimeAsDate;

        newDate.setFullYear(year, month - 1, day);
        setDateRange([formatDate(newDate), endDate]);

        onUpdateMeeting({
            time: {
                ...meeting.time,
                startTime: Math.floor(newDate.getTime() / 1000),
            }
        });
    }

    const handleEndDatePicker = (date) => {
        let [year, month, day] = date.split('-');
        let newDate = endTimeAsDate;

        newDate.setFullYear(year, month - 1, day);
        setDateRange([startDate, formatDate(newDate)]);

        onUpdateMeeting({
            time: {
                ...meeting.time,
                endTime: Math.floor(newDate.getTime() / 1000),
            }
        });
    }

    const handleStartTimeSelector = (time) => {
        let [hours, minutes, seconds] = time.split(':');
        let newDate = startTimeAsDate;

        newDate.setHours(hours, minutes, seconds);

        onUpdateMeeting({
            time: {
                ...meeting.time,
                startTime: Math.floor(newDate.getTime() / 1000),
            }
        });
    }

    const handleEndTimeSelector = (time) => {
        let [hours, minutes, seconds] = time.split(':');
        let newDate = endTimeAsDate;

        newDate.setHours(hours, minutes, seconds);

        onUpdateMeeting({
            time: {
                ...meeting.time,
                endTime: Math.floor(newDate.getTime() / 1000),
            }
        });
    }

    // useEffect for generating query parameters for Notify people
    useEffect(() => {
        if (attendees) {
            const parameters = {
                participantsByPriority: attendees.map((attendee) => attendee["email"]).join(","),
                isAllDayEvent: isAllDayEvent ? "true" : "false",
                startTime: isAllDayEvent ? "" : startTime,
                endTime: isAllDayEvent ? "" : endTime,
                timezone: encodeURIComponent(timezoneValue),
            };

            // Update query parameter if the value is different
            updateQueryParameters(parameters, queryParameters, setQueryParameters);
        }
    }, [attendees, isAllDayEvent, startTime, endTime, timezoneValue, queryParameters, setQueryParameters]);

    return (
        <Row
            widths={["fill", "fit"]}
            alignmentVertical="stretch"
            spacing="none"
        >
            <Column heights={["fit", "fill"]} spacing="none" alignmentHorizontal="stretch">
                <Row widths={["fill", "fit", "fill"]} backgroundColor="white">
                    <Row widths={["fit", "fit", "fit", "fill"]} spacing="small" spacingInset="small medium none" wrap="down" alignmentHorizontal="center"
                         width={screenSizeBreakpoint > SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND : "100%"}
                    >
                        {screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW &&
                            <Column spacing="none">
                                <br/>
                                <Checkbox
                                    checked={isAllDayEvent}
                                    onChange={handleIsAllDayEvent}
                                >
                                    All day
                                </Checkbox>
                            </Column>
                        }
                        {isAllDayEvent &&
                            <Column spacing="none">
                                <Row>
                                    <Column width={INPUT_WIDTH.START_END_WIDTH}>
                                        <Text type="b200">Start</Text>
                                    </Column>
                                    <Text type="b200">End</Text>
                                </Row>
                                <div style={{display: "none"}}>
                                    <Text id={START_DATE_PICKER_INSTRUCTION_ID}>
                                        Enter start date in MM/DD/YYYY format or press down to enter calendar dialog to select a date range
                                    </Text>
                                    <Text id={END_DATE_PICKER_INSTRUCTION_ID}>
                                        Enter end date in MM/DD/YYYY format or press down to enter calendar dialog to select the end date
                                    </Text>
                                </div>
                                <DateRangePicker
                                    width={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? INPUT_WIDTH.DATE_RANGE_PICKER_WIDTH : INPUT_WIDTH.DATE_RANGE_PICKER_MOBILE_WIDTH}
                                    value={dateRange}
                                    startError={dateRange[0] === undefined}
                                    endError={dateRange[1] === undefined}
                                    onChange={handleDateRangePicker}
                                    monthsInView={1}
                                    allowSameStartAndEnd={true}
                                    locale={getLocalesFromSettingsDateFormat(dateFormat)}
                                    startPlaceholder="Select start date"
                                    endPlaceholder="Select end date"
                                    size="small"
                                    ariaDescribedbyStart={START_DATE_PICKER_INSTRUCTION_ID}
                                    ariaDescribedbyEnd={END_DATE_PICKER_INSTRUCTION_ID}
                                    ariaLabelStart="Select meeting range start and end date"
                                    ariaLabelEnd="Select meeting range end date"
                                />
                            </Column>
                        }
                        {!isAllDayEvent && screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW &&
                            <Row alignmentHorizontal="center">
                                <Column spacing="none" key="start">
                                    <Text type="b200">Start</Text>
                                    <div style={{display: "none"}}>
                                        <Text id={START_DATE_PICKER_INSTRUCTION_ID}>
                                            Enter start date in MM/DD/YYYY format or press down to enter calendar dialog to select the start date
                                        </Text>
                                        <Text id={START_TIME_LABEL_ID}>Start time</Text>
                                    </div>
                                    <InputGroup>
                                        <DatePicker
                                            value={startDate}
                                            onChange={handleStartDatePicker}
                                            error={startDate > endDate}
                                            width={INPUT_WIDTH.DATE_PICKER_WIDTH}
                                            locale={getLocalesFromSettingsDateFormat(dateFormat)}
                                            placeholder="Select start date"
                                            size="small"
                                            aria-describedby={START_DATE_PICKER_INSTRUCTION_ID}
                                            aria-label="Select start date"
                                        />
                                        <TimeSelect
                                            value={startTime}
                                            onChange={handleStartTimeSelector}
                                            error={startDate === endDate && startTime >= endTime}
                                            options={timeRange({
                                                start: "00:00:00",
                                                end: "23:59:59",
                                                step: 60 * 30, // 30 minutes
                                            })}
                                            format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                                            width={INPUT_WIDTH.TIME_SELECT_WIDTH}
                                            placeholder="Select start time"
                                            size="small"
                                            aria-labelledby={START_TIME_LABEL_ID}
                                        />
                                    </InputGroup>
                                </Column>
                                <Column spacing="none" key="end">
                                    <Text type="b200">End</Text>
                                    <div style={{display: "none"}}>
                                        <Text id={END_DATE_PICKER_INSTRUCTION_ID}>
                                            Enter end date in MM/DD/YYYY format or press down to enter calendar dialog to select the end date
                                        </Text>
                                        <Text id={END_TIME_LABEL_ID}>End time</Text>
                                    </div>
                                    <InputGroup>
                                        <DatePicker
                                            value={endDate}
                                            onChange={handleEndDatePicker}
                                            error={startDate > endDate}
                                            width={INPUT_WIDTH.DATE_PICKER_WIDTH}
                                            locale={getLocalesFromSettingsDateFormat(dateFormat)}
                                            placeholder="Select end date"
                                            size="small"
                                            aria-describedby={END_DATE_PICKER_INSTRUCTION_ID}
                                            aria-label="Select end date"
                                        />
                                        <TimeSelect
                                            value={endTime}
                                            onChange={handleEndTimeSelector}
                                            error={startDate === endDate && startTime >= endTime}
                                            options={timeRange({
                                                start: "00:30:00",
                                                end: "23:59:59",
                                                step: 60 * 30, // 30 minutes
                                            })}
                                            format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                                            width={INPUT_WIDTH.TIME_SELECT_WIDTH}
                                            placeholder="Select end time"
                                            size="small"
                                            aria-labelledby={END_TIME_LABEL_ID}
                                        />
                                    </InputGroup>
                                </Column>
                            </Row>
                        }
                        {!isAllDayEvent && screenSizeBreakpoint <= SCREEN_SIZE.PARTIAL_MOBILE_VIEW &&
                            <Column>
                                <Column spacing="none" key="start">
                                    <Text type="b200">Start</Text>
                                    <div style={{display: "none"}}>
                                        <Text id={START_DATE_PICKER_INSTRUCTION_ID}>
                                            Enter start date in MM/DD/YYYY format or press down to enter calendar dialog to select the start date
                                        </Text>
                                        <Text id={START_TIME_LABEL_ID}>Start time</Text>
                                    </div>
                                    <InputGroup>
                                        <DatePicker
                                            value={startDate}
                                            onChange={handleStartDatePicker}
                                            error={startDate > endDate}
                                            width={INPUT_WIDTH.DATE_PICKER_WIDTH}
                                            locale={getLocalesFromSettingsDateFormat(dateFormat)}
                                            placeholder="Select start date"
                                            size="small"
                                            aria-describedby={START_DATE_PICKER_INSTRUCTION_ID}
                                            aria-label="Select start date"
                                        />
                                        <TimeSelect
                                            value={startTime}
                                            onChange={handleStartTimeSelector}
                                            error={startDate === endDate && startTime >= endTime}
                                            options={timeRange({
                                                start: "00:00:00",
                                                end: "23:59:59",
                                                step: 60 * 30, // 30 minutes
                                            })}
                                            format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                                            width={INPUT_WIDTH.TIME_SELECT_WIDTH}
                                            placeholder="Select start time"
                                            size="small"
                                            aria-labelledby={START_TIME_LABEL_ID}
                                        />
                                    </InputGroup>
                                </Column>
                                <Column spacing="none" key="end">
                                    <Text type="b200">End</Text>
                                    <div style={{display: "none"}}>
                                        <Text id={END_DATE_PICKER_INSTRUCTION_ID}>
                                            Enter end date in MM/DD/YYYY format or press down to enter calendar dialog to select the end date
                                        </Text>
                                        <Text id={END_TIME_LABEL_ID}>End time</Text>
                                    </div>
                                    <InputGroup>
                                        <DatePicker
                                            value={endDate}
                                            onChange={handleEndDatePicker}
                                            error={startDate > endDate}
                                            width={INPUT_WIDTH.DATE_PICKER_WIDTH}
                                            locale={getLocalesFromSettingsDateFormat(dateFormat)}
                                            placeholder="Select end date"
                                            size="small"
                                            aria-describedby={END_DATE_PICKER_INSTRUCTION_ID}
                                            aria-label="Select end date"
                                        />
                                        <TimeSelect
                                            value={endTime}
                                            onChange={handleEndTimeSelector}
                                            error={startDate === endDate && startTime >= endTime}
                                            options={timeRange({
                                                start: "00:30:00",
                                                end: "23:59:59",
                                                step: 60 * 30, // 30 minutes
                                            })}
                                            format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                                            width={INPUT_WIDTH.TIME_SELECT_WIDTH}
                                            placeholder="Select end time"
                                            size="small"
                                            aria-labelledby={END_TIME_LABEL_ID}
                                        />
                                    </InputGroup>
                                </Column>
                            </Column>
                        }
                        <Row>
                            <Column spacing="none">
                                <Text type="b200">Timezone</Text>
                                {timezones.length ?
                                    <TimezonePicker
                                        width="fit"
                                        size="small"
                                        timezones={timezones}
                                        value={timezoneValue}
                                        onChange={(timezone) => {onUpdateMeeting({startTimezone: {id: timezone}, endTimezone: {id: timezone}}); setTimezoneValue(timezone);}}
                                    />
                                    :
                                    <Loader size="small" />
                                }
                            </Column>
                            {screenSizeBreakpoint <= SCREEN_SIZE.PARTIAL_MOBILE_VIEW &&
                                <Column spacing="none">
                                    <br/>
                                    <Checkbox
                                        checked={isAllDayEvent}
                                        onChange={handleIsAllDayEvent}
                                    >
                                        All day
                                    </Checkbox>
                                </Column>
                            }
                        </Row>
                    </Row>
                </Row>
                <Row
                    widths={["fill", "fit", "fill"]}
                    alignmentVertical="stretch"
                    spacing="none"
                    alignmentHorizontal="center"
                >
                    {screenSizeBreakpoint > SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                        <Column backgroundColor={TABLE_ROW_COLOR}>
                            <Table
                                headerRows={1}
                                showDividers={false}
                                spacing="none"
                            >
                                <div style={{
                                    display: "flex",
                                    height: INPUT_HEIGHT.TIME_SELECT_HEIGHT,
                                    backgroundColor: "white",
                                }} />
                                <TableRow>
                                    <TableCell><br/></TableCell>
                                </TableRow>
                            </Table>
                            <div style={{
                                height: "100%",
                                display: "flex",
                                backgroundImage: `url(${PatternBackground})`,
                                backgroundRepeat: "repeat-y",
                                backgroundColor: {TABLE_ROW_COLOR},
                                backgroundPosition: "left -250px center",
                                backgroundAttachment: "fixed",
                                opacity: BACKGROUND_OPACITY
                            }} />
                        </Column>
                    }
                    <Column
                        width={screenSizeBreakpoint > SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? INPUT_WIDTH.TABLE_WIDTH : "100%"}
                        height="100%"
                        heights="fit"
                        spacing="none"
                        alignmentVertical="top"
                        backgroundColor={TABLE_ROW_COLOR}
                    >
                        <MailingList
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            identity={identity}
                            meeting={meeting}
                            onUpdateMeeting={onUpdateMeeting}
                            attendees={props.attendees}
                            peopleSuggestions={props.peopleSuggestions}
                            groupSuggestions={props.groupSuggestions}
                            pointOfContact={props.pointOfContact}
                            setPointOfContact={props.setPointOfContact}
                            favorites={props.favorites}
                            onAddAttendee={props.onAddAttendee}
                            onUpdateAttendee={props.onUpdateAttendee}
                            onRemoveAttendee={props.onRemoveAttendee}
                            onGetRASDataForPerson={props.onGetRASDataForPerson}
                            onGetRASDataForGroup={props.onGetRASDataForGroup}
                            onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                            onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                            onGetGroupSuggestions={props.onGetGroupSuggestions}
                            onClearGroupSuggestions={props.onClearGroupSuggestions}
                            onAddFavorite={props.onAddFavorite}
                            onRemoveFavorite={props.onRemoveFavorite}
                        />
                    </Column>
                    {screenSizeBreakpoint > SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                        <Column backgroundColor={TABLE_ROW_COLOR}>
                            <Table
                                headerRows={1}
                                showDividers={false}
                                spacing="none"
                            >
                                <div style={{
                                    display: "flex",
                                    height: INPUT_HEIGHT.TIME_SELECT_HEIGHT,
                                    backgroundColor: "white",
                                }} />
                                <TableRow>
                                    <TableCell><br/></TableCell>
                                </TableRow>
                            </Table>
                            <div style={{
                                height: "100%",
                                display: "flex",
                                backgroundImage: `url(${PatternBackground})`,
                                backgroundRepeat: "repeat-y",
                                backgroundColor: {TABLE_ROW_COLOR},
                                backgroundPosition: "right center",
                                backgroundAttachment: "fixed",
                                opacity: BACKGROUND_OPACITY
                            }} />
                        </Column>
                    }
                </Row>

            </Column>
            {screenSizeBreakpoint > SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR &&
                <Column type="outline">
                    <Favorites
                        identity={identity}
                        attendees={props.attendees}
                        favorites={props.favorites}
                        hideLocations={true}
                        onAddAttendee={props.onAddAttendee}
                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                        onRemoveFavorite={props.onRemoveFavorite}
                    />
                </Column>
            }
        </Row>
    );
};

export default StepNotifyPeople;
