module.exports = {
    COPY_PERMALINK: "Copy Permalink to share",
    PERMALINK_COPIED: "Permalink copied successfully",
    FAILED_METRIC_PARAMETERS: {
        PARTICIPANTS_BY_PRIORITY: "participantsByPriority",
        START_END_TIME: "startTime,endTime",
        START_END_DATE: "startDate,endDate",
        DURATION_MINUTES: "durationMinutes",
        SUGGESTION_VIEW: "suggestionViewType",
        FILTER_WEEK_DAYS: "filterWeekDays",
        TIMEZONE: "timezone",
        ALL_DAY_EVENT: "isAllDayEvent",
        STATUS: "status",
        REQUEST_RESPONSES: "isResponseRequested",
    },
    PERMALINK_BUTTON_PARAMETER: "&permalinkButton=true",
    PERMALINK_SOURCE: {
        THIRD_PARTY: "thirdParty",
        PERMALINK_BUTTON: "permalinkButton",
        OUTLOOK_PLUGIN: "outlookPlugin",
    },
};