import TimeSelect from "../meridian-custom-components/src/components/time-select";
import { TIME_CONSTANT } from "../shared-constants";
import { DATE_FORMAT, DATE_FORMAT_LOCALE } from "./settings-constants";

export const getLocalesFromSettingsDateFormat = (dateFormat) => {
    switch (dateFormat) {
        case DATE_FORMAT.DAY_MONTH_YEAR:
            return DATE_FORMAT_LOCALE.DAY_MONTH_YEAR;
        case DATE_FORMAT.YEAR_MONTH_DAY:
            return DATE_FORMAT_LOCALE.YEAR_MONTH_DAY;
        case DATE_FORMAT.MONTH_DAY_YEAR:
        default:
            return DATE_FORMAT_LOCALE.MONTH_DAY_YEAR;
    }
}

export const getTimeMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(":");

    return (+hours) * TIME_CONSTANT.ONE_HOUR_IN_MIN + (+minutes);
}

export const doAllEntriesHaveValues = (object) => {
    if (object !== null && typeof object === "object") {
        return Object.entries(object).every(([,val]) => doAllEntriesHaveValues(val));
    }

    if (typeof object === "string") {
        return object?.length > 0;
    }

    return object !== undefined;
}

export const getDefaultTimeFormat = (isHour12Format) => {
    return {
        ...TimeSelect.defaultProps.format,
        hour12: isHour12Format,
    }
}

// Helper function for filling some required functions but did nothing to avoid warnings
export const Noop = () => {}