import { STATUS } from "../../../meeting-polls/poll-constants";
import * as Actions from "./action-types";

export const postRatingMetric = (feedback, rating, onSubmitClicked, isModal) => {
    return {type: Actions.POST_RATING_METRIC, feedback, rating, onSubmitClicked, isModal};
};

export const postRASAPICallMetric = (operationName, errorDetails = "", success = true) => {
    return {type: Actions.POST_RAS_API_CALL_METRIC, operationName, errorDetails, success};
};

export const postAMSAPICallMetric = (operationName, errorDetails = "", success = true) => {
    return {type: Actions.POST_AMS_API_CALL_METRIC, operationName, errorDetails, success};
};

export const postMCISAPICallMetric = (operationName, errorDetails = "", success = true) => {
    return {type: Actions.POST_AMS_API_CALL_METRIC, operationName, errorDetails, success};
};

export const postCreateMeetingRoomsMetrics = (allRooms, noResponseRooms, acceptedRooms, declinedRooms) => {
    return {type: Actions.POST_CREATE_MEETING_ROOMS_METRICS, allRooms, noResponseRooms, acceptedRooms, declinedRooms};
};

export const postLivestreamApiCallMetric = (operationName, errorDetails = "", success = true) => {
    return {type: Actions.POST_LIVESTREAM_API_CALL_METRIC, operationName, errorDetails, success};
};

/**
 * Action to post a metric when creating a new meeting through the standard workflow
 * that specifies if the new meeting was prepopulated by an existing one
 *
 * @param copiedToNew - Whether the new meeting was prepopulated from an existing meeting
 * @param sameOrganizer - Whether the existing meeting was organized by the user. Ignored if copiedToNew is false.
 * @param hadRecurrence - Whether the existing meeting had a recurrence pattern. Ignored if copiedToNew is false.
 * @param hasRecurrence - Whether the new meeting has a recurrence pattern.
 * @returns {{type: string, copiedToNew: boolean, sameOrganizer: boolean, hadRecurrence: boolean, hasRecurrence: boolean}}
 */
export const postCopyToNewCreateMeetingMetrics = (copiedToNew, sameOrganizer, hadRecurrence, hasRecurrence) => {
    return {
        type: Actions.POST_COPY_TO_NEW_CREATE_MEETING_METRICS,
        copiedToNew,
        sameOrganizer: copiedToNew ? sameOrganizer : undefined,
        hadRecurrence: copiedToNew ? hadRecurrence : undefined,
        hasRecurrence,
    };
};

export const postUnknownEdgeLocationMetric = (edgeLocation) => {
    return {type: Actions.POST_UNKNOWN_EDGE_LOCATION_METRIC, edgeLocation};
};

export const postRequesterEmailRetryMetric = (operation, failedEmail, retryEmail, success) => {
    return {type: Actions.POST_REQUESTER_EMAIL_RETRY_METRIC, operation, failedEmail, retryEmail, success};
};

export const postQueryParamsLinkMetric = (invalidParameters, queryParamsSource) => {
    return {type: Actions.POST_QUERY_PARAMS_LINK_METRIC, invalidParameters, queryParamsSource};
};

export const postAvailabilitySharingCopyToClipboardMetric = (userEmail, timeRangeDurationInDays) => {
    return {type: Actions.POST_AVAILABILITY_SHARING_COPY_TO_CLIPBOARD_METRIC, userEmail, timeRangeDurationInDays};
};

/**
 * Action to post a metric when creating a livestream event on the livestream intake workflow
 * to track ticket creation and meeting creation success/failure
 *
 * @param {boolean} ticketSuccess - If the automated ticket creation succeeded
 * @param {String} ticketId - The id of the automatically created ticket, empty is ticketSuccess is false
 * @param {String} ticketErrorResponse - The error returned by the create ticket API, empty if ticketSuccess is true
 * @param {Array} meetingUniqueIds - Array with unique IDs for each meeting
 * @param {Array} meetingEntryIds - Array with entry IDs for each meeting that was successfully created
 * @param {Array} failedMeetings - Array of all failed meeting creations
 * @returns {{type: string, ticketSuccess: boolean, ticketId: string, ticketErrorResponse: String, meetingUniqueIds: Array, meetingEntryIds: Array, failedMeetings: Array}}
 */
export const postCreateLivestreamEventMetric = (ticketSuccess, ticketId = "", ticketErrorResponse = "", meetingUniqueIds = [], meetingEntryIds = [], failedMeetings = []) => {
    return {
        type: Actions.POST_CREATE_LIVESTREAM_EVENT_METRIC,
        ticketSuccess,
        ticketId,
        ticketErrorResponse,
        meetingUniqueIds,
        meetingEntryIds,
        failedMeetings,
    };
};

/**
 * Action to post a metric when no single valid chime email found for the user
 *
 * @param {String} userAlias - The alias of the user
 * @param {Array} userEmails - The array of all the user emails we have tried
 * @returns {{type: string, userAlias: string, userEmails: Array}}
 */
 export const postNoValidChimeEmailFoundMetric = (userAlias, userEmails = [], validEmail = undefined) => {
    return {
        type: Actions.POST_NO_VALID_CHIME_EMAIL_FOUND_METRIC,
        userAlias,
        userEmails,
        validEmail
    };
};

/**
 * Action to post a metric when user sent a request to save the user preferences to track success/failure
 *
 * @param {String} userAlias - The alias of the user
 * @param {boolean} savePreferencesSuccess - If the preferences saved succeeded
 * @param {String} preferencesErrorResponse - The error returned by the save user preferences API, empty if savePreferencesSuccess is true
 * @returns {{type: string, userAlias: string, savePreferencesSuccess: boolean, preferencesErrorResponse: string}}
 */
 export const postSaveUserPreferencesMetric = (userAlias, savePreferencesSuccess = true, fromPreferencePage = true, preferencesErrorResponse = "") => {
    return {
        type: Actions.POST_SAVE_USER_PREFERENCES_METRIC,
        userAlias,
        savePreferencesSuccess,
        fromPreferencePage,
        preferencesErrorResponse,
    };
};

/**
 * Action to post a metric when updating a meeting poll event
 *
 * @param {String} userAlias - The alias of the user
 * @param {boolean} updatePollSuccess - If the poll updated succeeded
 * @param {String} previousPollStatus - The meeting poll status before the update
 * @param {String} updatePollErrorResponse - The error returned by the udpatePoll API, empty if updatePollSuccess is true
 * @returns {{type: string, userAlias: string, updatePollSuccess: boolean, previousPollStatus: string, updatePollErrorResponse: string}}
 */
 export const postUpdateMeetingPollEventMetric = (userAlias, updatePollSuccess = true, previousPollStatus = STATUS.ONGOING, updatePollErrorResponse = "") => {
    return {
        type: Actions.POST_UPDATE_MEETING_POLL_EVENT_METRIC,
        userAlias,
        updatePollSuccess,
        previousPollStatus,
        updatePollErrorResponse,
    };
};

/**
 * Action to post a metric when creating a meeting poll event
 *
 * @param {String} userAlias - The alias of the user
 * @param {boolean} createPollSuccess - If the meeting poll created succeeded
 * @param {String} meetingPollErrorResponse - The error returned by the create meeting API, empty if createPollSuccess is true
 * @param {Object} metricPayload - The object contains all the metrics we needed, the data should contain 
 * [fromPage, totalExternalAttendees, totalInternalAttendees, totalTimeSlotsSelected, timeSlotsSelectedFromGridMode, timeSlotsSelectedFromCalendarMode]
 * @returns {{type: string, userAlias: string, createPollSuccess: boolean, meetingPollErrorResponse: string, 
 * fromPage: string, pollId: string, totalExternalAttendees: number, totalInternalAttendees: number, 
 * totalTimeSlotsSelected: number, timeSlotsSelectedFromGridMode: boolean, timeSlotsSelectedFromCalendarMode: boolean}}
 */
 export const postCreateMeetingPollEventMetric = (userAlias, createPollSuccess = true, meetingPollErrorResponse = "", metricPayload) => {
    const defaultMetricPayloads = {
        fromPage : "",
        pollId: "", 
        totalExternalAttendees: 0, 
        totalInternalAttendees: 0, 
        totalTimeSlotsSelected: 0,
        timeSlotsSelectedFromGridMode: false,
        timeSlotsSelectedFromCalendarMode: false,
    };

    return {
        type: Actions.POST_CREATE_MEETING_POLL_EVENT_METRIC,
        userAlias,
        createPollSuccess,
        meetingPollErrorResponse,
        ...defaultMetricPayloads,
        ...metricPayload
    };
};

export const postRoomBookingMetric = (event, details, success) => {
    return {type: Actions.POST_ROOM_BOOKING_METRIC, event, details, success};
};