const addDebouncedEventListener = (node, event, listener, options) => {
  let timeout = null
  const debouncedListener = event => {
    window.cancelAnimationFrame(timeout)
    if (window && window.requestAnimationFrame) {
      timeout = window.requestAnimationFrame(() => listener(event))
    }
  }
  if (node && node.addEventListener) {
    node.addEventListener(event, debouncedListener, options)
  }
  return () => {
    window.cancelAnimationFrame(timeout)
    if (node && node.removeEventListener) {
      node.removeEventListener(event, debouncedListener, options)
    }
  }
}

export default addDebouncedEventListener
