import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
    getLatestEventSelector,
    getLivestreamMeeting,
} from "../../../sagas/selector";

import addDays from "date-fns/addDays";
import format from "date-fns/format";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";

import LivestreamNavigationBanner from "./livestream-navigation-banner";
import StepEventRequirements from "./step-event-requirements";
import StepAttendeesAndRooms from "./step-attendees-and-rooms";
import StepEventSchedule from "./step-event-schedule";
import StepEventAgendaAndReview from "./step-event-agenda-and-review";

import HeaderBackground from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";

import { LIVESTREAM_BUILDING_CODE, LIVESTREAM_EXCLUDED_STATION_EMAILS, STEP, STEP_LABEL, AGENDA_GROUP } from "../livestream-constants";
import { FLOW_TYPE, DURATION } from "../../meeting-scheduler/meeting-scheduler-constants";
import { formatWorkHoursTime } from "../../shared/exchange-preferences/exchange-preferences-utils";
import { TIME_FORMAT, TIME_CONSTANT } from "../../shared/shared-constants";
import { renderDurationLabel } from "../../shared/time-utils";
import { NAVIGATION_TYPE, STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { ATTENDEE_PRIORITY, ATTENDEE_ROLE } from "../../people/people-constants";
import { getDuration, getMeetingDaysLabel } from "../../meeting-scheduler/meeting-scheduler-utils";
import { getLivestreamStartDate } from "../livestream-utils";
import { getCurrentUserInfo } from "../../api/apig/livestream/getCurrentUserInfo";

const LivestreamForm = (props) => {
    const flowType = props.flowType;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const mastheadSize = props.mastheadSize;
    const identity = props.identity;
    const meeting = props.meeting;
    const meetingList = props.meetingList;
    const meetingDetails = props.meetingDetails;
    const loadExistingMeeting = props.loadExistingMeeting;
    const addChimePin = props.addChimePin;
    const currentStep = props.currentStep;
    const masterState = props.masterState;
    const attendees = props.attendees;
    const selectedStations = props.selectedStations;
    const findRooms = props.findRooms;
    const buildings = props.buildings;
    const rooms = props.rooms;
    const locations = props.locations;
    const favorites = props.favorites;
    const peopleSuggestions = props.peopleSuggestions &&
        props.peopleSuggestions.filter((person) => person.basicInfo && person.basicInfo.login &&
            !attendees.some((attendee) => attendee.alias === person.basicInfo.login.toLowerCase()));
    const groupSuggestions = props.groupSuggestions &&
        props.groupSuggestions.filter((group) => !attendees.some((attendee) => attendee.alias === group));
    const meetingSuggestions = props.meetingSuggestions;
    const timezones = props.timezones;
    const primaryBrowserTimezoneId = props.primaryBrowserTimezoneId;
    const [timezoneValue, setTimezoneValue] = [props.timezoneValue, props.setTimezoneValue];
    const workHours = props.workHours;
    const onUpdateAttendee = props.onUpdateAttendee;
    const timeFormat = props.timeFormat;
    const dateFormat = props.dateFormat;

    const formatIso = (date) => format(date, "yyyy-MM-dd");

    /* Livestream states */
    const [stations, setStations] = useState(undefined); // Emails of all livestream engineer stations
    const [selectedAttendeeRole, setSelectedAttendeeRole] = useState(ATTENDEE_ROLE.LIVESTREAM.PRESENTER); // Attendees and rooms step attendee type
    const [addOrganizerAsPointOfContact, setAddOrganizerAsPointOfContact] = useState(true); // initialize organizer as point of contact
    const [addedRestrictedGroups, setAddedRestrictedGroups] = useState([]); // track added restricted groups
    const [addedPointsOfContact, setAddedPointsOfContact] = useState([]); // track added points of contact
    const latestEvent = useSelector(getLatestEventSelector);

    /* Event schedule states */
    const [isFirstSearch, setIsFirstSearch] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isAttendeesChanged, setIsAttendeesChanged] = useState(false);
    const [isLocationsChanged, setIsLocationsChanged] = useState(false);
    const [prevAttendees, setPrevAttendees] = useState(attendees);
    const [prevRooms, setPrevRooms] = useState(rooms);
    const [prevLocations, setPrevLocations] = useState(locations);
    const [prevRequiredStationCount, setPrevRequiredStationCount] = useState(1);
    const [meetingSuggestionSelected, setMeetingSuggestionSelected] = useState();
    const [meetingSuggestionsDetail, setMeetingSuggestionsDetail] = useState({});
    const [sortOption, setSortOption] = useState("Availability");
    const [alerts, setAlerts] = useState([]);
    const [isExistingMeetingSelected, setIsExistingMeetingSelected] = useState();
    const [existingMeetingDetail, setExistingMeetingDetail] = useState({});
    const [changedTimeWindow, setChangedTimeWindow] = useState(); // current grid window position
    const [noLivestreamStations, setNoLivestreamStations] = useState(false);
    // Whether we are using an existing meeting to load the defaults for our suggestion search inputs
    // const [loadSearchCriteriaFromMeeting, setLoadSearchCriteriaFromMeeting] = useState(flowType === FLOW_TYPE.UPDATE || loadMeeting);

    // Meeting duration
    const [durationSelected, setDurationSelected] = useState(DURATION.SIXTY_MINUTES);
    const [customDurationValue, setCustomDurationValue] = useState(DURATION.SIXTY_MINUTES);
    const [customDurationSelected, setCustomDurationSelected] = useState("min");
    const [customDurationLabel, setCustomDurationLabel] = useState("60 mins");
    const [customDurationError, setCustomDurationError] = useState(false);
    const [isAllDayEvent, setIsAllDayEvent] = useState(false);
    const [prevDuration, setPrevDuration] = useState(getDuration(durationSelected, customDurationValue, customDurationSelected));

    const currentYear = new Date().getFullYear().toString();

    // Meeting date, time, and timezone
    const defaultStartDate = formatIso(getLivestreamStartDate(5));
    const defaultEndDate = formatIso(addDays(getLivestreamStartDate(5), +7));
    const [dateRangeValue, setDateRangeValue] = useState([defaultStartDate, defaultEndDate]);
    const [startDateError, setStartDateError] = useState(false);
    const [dateRangeLabel, setDateRangeLabel] = useState("8 days");

    const [startTime, setStartTime] = useState(undefined); // Initialized in useEffect
    const [endTime, setEndTime] = useState(undefined); // Initialized in useEffect
    const [timeLabel, setTimeLabel] = useState(""); // Initialized in useEffect

    // Meeting days of week
    const [daysOfWeek, setDaysOfWeek] = useState(["Mon", "Tue", "Wed", "Thu", "Fri"]);
    const [daysOfWeekLabel, setDaysOfWeekLabel] = useState("Only on Mon - Fri");

    // Current room selection info
    const [currentSuggestion, setCurrentSuggestion] = useState();
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
    const [currentRoomListIndex, setCurrentRoomListIndex] = useState(0);
    const [selectedRooms, setSelectedRooms] = useState({});
    const [selectedRoom, setSelectedRoom] = useState();

    /* Event agenda states */
    const [isSubjectEmpty, setIsSubjectEmpty] = useState(false);
    const [copyEventName, setCopyEventName] = useState(true); // use event name as meeting subject
    const [prevEventName, setPrevEventName] = useState(""); // only update event name if user hasn't changed it
    const [agendaTab, setAgendaTab] = useState(AGENDA_GROUP.PRESENTERS);
    const [agendaViewerText, setAgendaViewerText] = useState("");

    // Previous getSchedule query to avoid sending duplicate requests
    const [prevGetScheduleQuery, setPrevGetScheduleQuery] = useState();

    // Alerts shown on the review step
    const [reviewAlerts, setReviewAlerts] = useState([]);

    // Meeting navigation buttons loader
    const [isLastStepLoading, setIsLastStepLoading] = useState(false); // disable send invites button

    // Livestream event requirements
    const [eventRequirements, setEventRequirements] = useState({
        eventName: "",
        livestreamTitle: "",
        viewerCount: "250-999",
        viewerJustification: "", // only if event has less than 250 viewers
        expectedViewerCount: "", // only if event has more than 100,000 viewers
        allowChat: true, // enables the options to select nativeChat or Slack
        multipleLivestreams: false, // event involves multiple livestreams running simultaneously
        restricted: false,
        restrictedGroups: [], // if event is restricted, only allow these groups or persons
        eventRequestType: "LIVE",
        videoPlayback: false, // only if the video is a live event
        platforms: ["HUBBLE"],
        recordingType: "BROADCAST", // only if the user wants to save a recording
        previousBroadcastUrl: "", // only if the user wants to save a Broadcast recording
        signLanguageInterpreter: "NONE",
        nonEnglish: false,
        reuseUrl: false,
        existingLivestreamUrl: "", // only if the user wants to reuse a prior livestream URL
        pointsOfContact: [],
        contactChannels: ["EMAIL", "SLACK"],
        phoneNumber: "", // if the user selects phone as a contact channel, have text field for phone numbers
        notes: "",
        requiredStationCount: 1,
        slackChannel: "",
        slackEnabled: false,
        eventDescription: "",
        broadcastPermission: false,
        slackChannelName: "",
        slackWorkspace: "",
        desiredLivestreamUrl: "",
        loadedEventLivestreamUrl: "",
        existingLivestreamUrlError: "",
        seriesOid: "",
        isAnyPresenterL10: false,
        ccOptions: "DISCLAIMER",
        eventLocation: "REMOTE_OR_VIRTUAL",
        isAudienceInFCs: false, // if large portion of audience belong to Fulfillment Centers or not
        // reactions: false, // TODO: will uncomment once reactions feature is enabled in prod.
    });

    const [eventRequirementErrors, setEventRequirementErrors] = useState({
        eventName: false,
        eventDescription: false,
        viewerJustification: false,
        expectedViewerCount: false,
        platforms: false,
        previousBroadcastUrl: false,
        existingLivestreamUrl: false,
        desiredLivestreamUrl: false,
        pointsOfContact: false,
        contactChannels: false,
        phoneNumber: false,
        slackChannelName: false,
        slackWorkspace: false,
        livestreamTitle: false,
    });

    const restrictedGroupSuggestions = props.groupSuggestions &&
        props.groupSuggestions.filter((group) => !eventRequirements["restrictedGroups"].some((attendee) => attendee.alias === group));
    const pointOfContactSuggestions = props.peopleSuggestions &&
        props.peopleSuggestions.filter((person) => person.basicInfo && person.basicInfo.login &&
            !eventRequirements["pointsOfContact"].some((attendee) => attendee.alias === person.basicInfo.login.toLowerCase()));

    const getPageTitle = () => {
        if (flowType === FLOW_TYPE.UPDATE) {
            return "Livestream event (Edit)";
        }

        return "Livestream event";
    };

    useEffect(() => {
        getCurrentUserInfo().then(() => console.log("Get user info called"))
    }, []);

    // Load in work hours to start and end time filters
    useEffect(() => {
        if (workHours) {
            setDaysOfWeek(workHours.days);
            getMeetingDaysLabel(workHours.days, false, setDaysOfWeekLabel);
        }

        if (workHours?.startTime !== undefined && workHours?.endTime !== undefined) {
            setStartTime(formatWorkHoursTime(workHours.startTime, TIME_FORMAT.HH_MM_SS));
            setEndTime(formatWorkHoursTime(workHours.endTime, TIME_FORMAT.HH_MM_SS));
            setTimeLabel(renderDurationLabel(workHours.startTime, workHours.endTime, TIME_CONSTANT.MIN_NAME));
        }
    }, [workHours])

    // Add organizer as a point of contact once upon loading the workflow
    useEffect(() => {
        if (addOrganizerAsPointOfContact) {
            attendees.forEach((attendee) => {
                if (attendee.isOrganizer) {
                    const previousPointOfContacts = eventRequirements["pointsOfContact"];

                    if (!previousPointOfContacts.includes(attendee)) {
                        // add organizer as point of contact
                        setEventRequirements({
                            ...eventRequirements,
                            pointsOfContact: previousPointOfContacts.concat([attendee]),
                        });

                        attendee.isPointOfContact = true;
                        onUpdateAttendee(attendee);
                    }

                    setAddOrganizerAsPointOfContact(false);
                }
            });
        }
    }, [addOrganizerAsPointOfContact, attendees, onUpdateAttendee, eventRequirements]);

    // Obtain list of livestream station emails
    useEffect(() => {
        const livestreamBuilding = buildings && buildings.find((building) => building.value === LIVESTREAM_BUILDING_CODE);

        // If livestream building has been loaded, obtain livestream engineer station emails
        if (!stations && livestreamBuilding && livestreamBuilding.value && livestreamBuilding.floorList) {
            const stations = (livestreamBuilding.roomList || []).concat(livestreamBuilding.hiddenRoomList || []);

            // Create a list of station emails excluding global back-up units which should not be queried
            const stationEmails = stations
                .map((station) => station.email)
                .filter((stationEmail) => !LIVESTREAM_EXCLUDED_STATION_EMAILS.includes(stationEmail))
                .sort();

            setStations(stationEmails);
        }
    }, [buildings, props.loadLivestreamBuilding, stations]);

    // Initialize attendee roles
    useEffect(() => {
        attendees.forEach((attendee) => {
            if (attendee.email && !attendee.email.endsWith("@chime.aws") && attendee.role === undefined) {
                if (attendee.isOrganizer) {
                    attendee.role = ATTENDEE_ROLE.ORGANIZER;
                } else if (currentStep === STEP.ATTENDEES_AND_ROOMS) {
                    // On the attendees and rooms step, follow the selected role when adding attendees
                    attendee.role = selectedAttendeeRole;
                    if (selectedAttendeeRole === ATTENDEE_ROLE.LIVESTREAM.VIEWER) {
                        attendee.priority = ATTENDEE_PRIORITY.OPTIONAL;
                    }
                } else {
                    attendee.role = ATTENDEE_ROLE.LIVESTREAM.VIEWER;
                    attendee.priority = ATTENDEE_PRIORITY.OPTIONAL;
                }

                onUpdateAttendee(attendee);
            }
        });
    }, [attendees, currentStep, selectedAttendeeRole, onUpdateAttendee]);

    const header = useRef();

    useEffect(() => {
        if (header.current) {
            header.current.focus();
        }
    }, []);


    return (
        <Column height="100%" spacing="none">
            <div style={{"paddingBottom": STEP_NAVIGATION_FOOTER.HEIGHT}}>
                <Column
                    height="100%"
                    minHeight={`calc(100vh - ${mastheadSize} - ${STEP_NAVIGATION_FOOTER.HEIGHT})`} // 100vh - mastheadSize - footer
                    heights={["fit", "fit", "fill"]}
                    spacing="none"
                >
                    <div style={{
                        "backgroundImage": `url(${HeaderBackground})`,
                        "backgroundRepeat": "no-repeat",
                        "backgroundColor": "rgba(26, 69, 78)",
                        "backgroundPosition": "150px -90px",
                        "height": "50px",
                        "width": "100%",
                    }}>
                        <Row height={"100%"} alignmentVertical={"center"} spacingInset={"medium"} widths={["fill"]}>
                            <Heading color="inverted" level={1} type={"h300"} ref={header} tabIndex="-1">{getPageTitle()}</Heading>
                        </Row>
                    </div>
                    <LivestreamNavigationBanner
                        navigationType={NAVIGATION_TYPE.HEADER}
                        flowType={flowType}
                        identity={identity}
                        timezones={timezones}
                        meeting={meeting}
                        meetingList={meetingList}
                        meetingDetails={meetingDetails}
                        eventRequirements={eventRequirements}
                        setEventRequirements={setEventRequirements}
                        eventRequirementErrors={eventRequirementErrors}
                        setEventRequirementErrors={setEventRequirementErrors}
                        attendees={attendees}
                        selectedStations={selectedStations}
                        currentStep={currentStep}
                        masterState={masterState}
                        onChangeStep={props.onChangeStep}
                        onCreateLivestream={props.onCreateLivestream}
                        agendaViewerText={agendaViewerText}
                        steps={[
                            STEP.EVENT_REQUIREMENTS,
                            STEP.ATTENDEES_AND_ROOMS,
                            STEP.EVENT_SCHEDULE,
                            STEP.EVENT_AGENDA_AND_REVIEW,
                        ]}
                        labels={[
                            STEP_LABEL.EVENT_REQUIREMENTS,
                            STEP_LABEL.ATTENDEES_AND_ROOMS,
                            STEP_LABEL.EVENT_SCHEDULE,
                            STEP_LABEL.EVENT_AGENDA_AND_REVIEW,
                        ]}
                        setIsSubjectEmpty={setIsSubjectEmpty}
                        reviewAlerts={reviewAlerts}
                        setReviewAlerts={setReviewAlerts}
                        isSuggestionSelected={meetingSuggestions?.length && meetingSuggestionSelected !== undefined}
                        isSearchingMeetingSuggestions={props.isSearchingMeetingSuggestions}
                        isAttendeeLoading={attendees.filter((attendee) => !attendee.email).length > 0}
                        noLivestreamStations={noLivestreamStations}
                        timeFormat={timeFormat}
                        isLastStepLoading={isLastStepLoading}
                        setIsLastStepLoading={setIsLastStepLoading}
                    />
                    {currentStep === STEP.EVENT_REQUIREMENTS &&
                        <StepEventRequirements
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            eventRequirements={eventRequirements}
                            setEventRequirements={setEventRequirements}
                            eventRequirementErrors={eventRequirementErrors}
                            setEventRequirementErrors={setEventRequirementErrors}
                            setCopyEventName={setCopyEventName}
                            attendees={attendees}
                            pointOfContactSuggestions={pointOfContactSuggestions}
                            restrictedGroupSuggestions={restrictedGroupSuggestions}
                            addedRestrictedGroups={addedRestrictedGroups}
                            setAddedRestrictedGroups={setAddedRestrictedGroups}
                            addedPointsOfContact={addedPointsOfContact}
                            setAddedPointsOfContact={setAddedPointsOfContact}
                            onAddAttendee={props.onAddAttendee}
                            onUpdateAttendee={props.onUpdateAttendee}
                            onRemoveAttendee={props.onRemoveAttendee}
                            onLoadFloors={props.onLoadFloors}
                            onUpdateFindRooms={props.onUpdateFindRooms}
                            onAddRoom={props.onAddRoom}
                            onRemoveRoom={props.onRemoveRoom}
                            onAddLocation={props.onAddLocation}
                            onRemoveLocation={props.onRemoveLocation}
                            onAddRoomByEmail={props.onAddRoomByEmail}
                            onGetRASDataForPerson={props.onGetRASDataForPerson}
                            onGetRASDataForGroup={props.onGetRASDataForGroup}
                            onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                            onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                            onGetGroupSuggestions={props.onGetGroupSuggestions}
                            onClearGroupSuggestions={props.onClearGroupSuggestions}
                            onAddFavorite={props.onAddFavorite}
                            onRemoveFavorite={props.onRemoveFavorite}
                            latestEvent={latestEvent}
                        />
                    }
                    {currentStep === STEP.ATTENDEES_AND_ROOMS &&
                        <StepAttendeesAndRooms
                            flowType={flowType}
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            identity={identity}
                            attendees={attendees}
                            findRooms={findRooms}
                            buildings={buildings}
                            rooms={rooms}
                            locations={locations}
                            peopleSuggestions={peopleSuggestions}
                            groupSuggestions={groupSuggestions}
                            addedRestrictedGroups={addedRestrictedGroups}
                            setAddedRestrictedGroups={setAddedRestrictedGroups}
                            addedPointsOfContact={addedPointsOfContact}
                            setAddedPointsOfContact={setAddedPointsOfContact}
                            userSettingsBuilding={props.userSettingsBuilding}
                            userSettingsFloor={props.userSettingsFloor}
                            userSettingsMinimumSeats={props.userSettingsMinimumSeats}
                            userSettingsCamera={props.userSettingsCamera}
                            userSettingsDisplay={props.userSettingsDisplay}
                            userSettingsNoRestricted={props.userSettingsNoRestricted}
                            userSettingsNoManaged={props.userSettingsNoManaged}
                            favorites={favorites}
                            onAddAttendee={props.onAddAttendee}
                            onUpdateAttendee={props.onUpdateAttendee}
                            onRemoveAttendee={props.onRemoveAttendee}
                            onLoadFloors={props.onLoadFloors}
                            onUpdateFindRooms={props.onUpdateFindRooms}
                            onAddRoom={props.onAddRoom}
                            onRemoveRoom={props.onRemoveRoom}
                            onAddLocation={props.onAddLocation}
                            onRemoveLocation={props.onRemoveLocation}
                            onAddRoomByEmail={props.onAddRoomByEmail}
                            onGetRASDataForPerson={props.onGetRASDataForPerson}
                            onGetRASDataForGroup={props.onGetRASDataForGroup}
                            onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                            onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                            onGetGroupSuggestions={props.onGetGroupSuggestions}
                            onClearGroupSuggestions={props.onClearGroupSuggestions}
                            onAddFavorite={props.onAddFavorite}
                            onRemoveFavorite={props.onRemoveFavorite}
                            selectedAttendeeRole={selectedAttendeeRole}
                            setSelectedAttendeeRole={setSelectedAttendeeRole}
                            onShowToast={props.onShowToast}
                            eventLocation={eventRequirements["eventLocation"]}
                            eventRequestType={eventRequirements["eventRequestType"]}
                        />
                    }
                    {currentStep === STEP.EVENT_SCHEDULE &&
                        <StepEventSchedule
                            flowType={flowType}
                            currentStep={currentStep}
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            mastheadSize={mastheadSize}
                            identity={identity}
                            meeting={meeting}
                            meetingDetails={meetingDetails}
                            loadExistingMeeting={loadExistingMeeting}
                            attendees={attendees}
                            stations={stations}
                            eventRequirements={eventRequirements}
                            findRooms={findRooms}
                            rooms={rooms}
                            locations={locations}
                            meetingSuggestions={meetingSuggestions}
                            isSearchingMeetingSuggestions={props.isSearchingMeetingSuggestions}
                            onGetMeetingSuggestions={props.onGetMeetingSuggestions}
                            onSearchSuggestion={props.onSearchSuggestion}
                            onSelectSuggestion={props.onSelectSuggestion}
                            suggestionViewType={props.suggestionViewType}
                            onSetSuggestionViewType={props.onSetSuggestionViewType}
                            onUpdateCurrentMeeting={props.onUpdateCurrentMeeting}
                            onSelectStations={props.onSelectStations}
                            timezones={timezones}
                            isFirstSearch={isFirstSearch}
                            setIsFirstSearch={setIsFirstSearch}
                            isInitialLoad={isInitialLoad}
                            setIsInitialLoad={setIsInitialLoad}
                            isAttendeesChanged={isAttendeesChanged}
                            setIsAttendeesChanged={setIsAttendeesChanged}
                            isLocationsChanged={isLocationsChanged}
                            setIsLocationsChanged={setIsLocationsChanged}
                            prevAttendees={prevAttendees}
                            setPrevAttendees={setPrevAttendees}
                            prevRooms={prevRooms}
                            setPrevRooms={setPrevRooms}
                            prevLocations={prevLocations}
                            setPrevLocations={setPrevLocations}
                            prevRequiredStationCount={prevRequiredStationCount}
                            setPrevRequiredStationCount={setPrevRequiredStationCount}
                            meetingSuggestionSelected={meetingSuggestionSelected}
                            setMeetingSuggestionSelected={setMeetingSuggestionSelected}
                            meetingSuggestionsDetail={meetingSuggestionsDetail}
                            setMeetingSuggestionsDetail={setMeetingSuggestionsDetail}
                            sortOption={sortOption}
                            setSortOption={setSortOption}
                            alerts={alerts}
                            setAlerts={setAlerts}
                            isExistingMeetingSelected={isExistingMeetingSelected}
                            setIsExistingMeetingSelected={setIsExistingMeetingSelected}
                            existingMeetingDetail={existingMeetingDetail}
                            setExistingMeetingDetail={setExistingMeetingDetail}
                            changedTimeWindow={changedTimeWindow}
                            setChangedTimeWindow={setChangedTimeWindow}
                            setNoLivestreamStations={setNoLivestreamStations}
                            durationSelected={durationSelected}
                            setDurationSelected={setDurationSelected}
                            customDurationValue={customDurationValue}
                            setCustomDurationValue={setCustomDurationValue}
                            customDurationSelected={customDurationSelected}
                            setCustomDurationSelected={setCustomDurationSelected}
                            customDurationLabel={customDurationLabel}
                            setCustomDurationLabel={setCustomDurationLabel}
                            customDurationError={customDurationError}
                            setCustomDurationError={setCustomDurationError}
                            isAllDayEvent={isAllDayEvent}
                            setIsAllDayEvent={setIsAllDayEvent}
                            prevDuration={prevDuration}
                            setPrevDuration={setPrevDuration}
                            currentYear={currentYear}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}
                            startDateError={startDateError}
                            setStartDateError={setStartDateError}
                            dateRangeLabel={dateRangeLabel}
                            setDateRangeLabel={setDateRangeLabel}
                            startTime={startTime}
                            setStartTime={setStartTime}
                            endTime={endTime}
                            setEndTime={setEndTime}
                            timeLabel={timeLabel}
                            setTimeLabel={setTimeLabel}
                            primaryBrowserTimezoneId={primaryBrowserTimezoneId}
                            timezoneValue={timezoneValue}
                            setTimezoneValue={setTimezoneValue}
                            daysOfWeek={daysOfWeek}
                            setDaysOfWeek={setDaysOfWeek}
                            daysOfWeekLabel={daysOfWeekLabel}
                            setDaysOfWeekLabel={setDaysOfWeekLabel}
                            currentSuggestion={currentSuggestion}
                            setCurrentSuggestion={setCurrentSuggestion}
                            currentSuggestionIndex={currentSuggestionIndex}
                            setCurrentSuggestionIndex={setCurrentSuggestionIndex}
                            currentRoomListIndex={currentRoomListIndex}
                            setCurrentRoomListIndex={setCurrentRoomListIndex}
                            selectedRooms={selectedRooms}
                            setSelectedRooms={setSelectedRooms}
                            selectedRoom={selectedRoom}
                            setSelectedRoom={setSelectedRoom}
                            onShowToast={props.onShowToast}
                            onHideToast={props.onHideToast}
                            onChangeStep={props.onChangeStep}
                            prevGetScheduleQuery={prevGetScheduleQuery}
                            setPrevGetScheduleQuery={setPrevGetScheduleQuery}
                            timeFormat={timeFormat}
                            dateFormat={dateFormat}
                        />
                    }
                    {currentStep === STEP.EVENT_AGENDA_AND_REVIEW &&
                        <StepEventAgendaAndReview
                            suggestionViewType={props.suggestionViewType}
                            flowType={flowType}
                            currentStep={currentStep}
                            onChangeStep={props.onChangeStep}
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            identity={identity}
                            meeting={meeting}
                            timezones={timezones}
                            getMeeting={getLivestreamMeeting}
                            meetingSuggestions={meetingSuggestions}
                            meetingSuggestionSelected={meetingSuggestionSelected}
                            meetingSuggestionsDetail={meetingSuggestionsDetail}
                            onSelectSuggestion={props.onSelectSuggestion}
                            currentYear={currentYear}
                            addChimePin={addChimePin}
                            attendees={attendees}
                            eventRequirements={eventRequirements}
                            rooms={rooms}
                            selectedRooms={selectedRooms}
                            setSelectedRooms={setSelectedRooms}
                            prevRooms={prevRooms}
                            setPrevRooms={setPrevRooms}
                            prevLocations={prevLocations}
                            setPrevLocations={setPrevLocations}
                            onUpdateCurrentMeeting={props.onUpdateCurrentMeeting}
                            onAddAttendee={props.onAddAttendee}
                            onRemoveAttendee={props.onRemoveAttendee}
                            chimePin={props.chimePin}
                            setChimePin={props.setChimePin}
                            chimeUniqueIds={props.chimeUniqueIds}
                            chimeUser={props.chimeUser}
                            autoCall={props.autoCall}
                            setAutoCall={props.setAutoCall}
                            loadChimeInLocation={prevLocations.length === 0 && prevRooms.length === 0}
                            isSubjectEmpty={isSubjectEmpty}
                            eventName={eventRequirements["eventName"]}
                            livestreamTitle={eventRequirements["livestreamTitle"]}
                            copyEventName={copyEventName}
                            setCopyEventName={setCopyEventName}
                            prevEventName={prevEventName}
                            setPrevEventName={setPrevEventName}
                            agendaTab={agendaTab}
                            setAgendaTab={setAgendaTab}
                            agendaViewerText={agendaViewerText}
                            setAgendaViewerText={setAgendaViewerText}
                            meetingDetails={meetingDetails}
                            onShowToast={props.onShowToast}
                            durationSelected={durationSelected}
                            customDurationValue={customDurationValue}
                            customDurationSelected={customDurationSelected}
                            reviewAlerts={reviewAlerts}
                            timeFormat={timeFormat}
                        />
                    }
                </Column>
            </div>
            <LivestreamNavigationBanner
                navigationType={NAVIGATION_TYPE.FOOTER}
                flowType={flowType}
                identity={identity}
                timezones={timezones}
                meeting={meeting}
                meetingList={meetingList}
                meetingDetails={meetingDetails}
                eventRequirements={eventRequirements}
                setEventRequirements={setEventRequirements}
                eventRequirementErrors={eventRequirementErrors}
                setEventRequirementErrors={setEventRequirementErrors}
                attendees={attendees}
                selectedStations={selectedStations}
                currentStep={currentStep}
                masterState={masterState}
                onChangeStep={props.onChangeStep}
                onCreateLivestream={props.onCreateLivestream}
                agendaViewerText={agendaViewerText}
                steps={[
                    STEP.EVENT_REQUIREMENTS,
                    STEP.ATTENDEES_AND_ROOMS,
                    STEP.EVENT_SCHEDULE,
                    STEP.EVENT_AGENDA_AND_REVIEW,
                    STEP.REVIEW,
                ]}
                labels={[
                    STEP_LABEL.EVENT_REQUIREMENTS,
                    STEP_LABEL.ATTENDEES_AND_ROOMS,
                    STEP_LABEL.EVENT_SCHEDULE,
                    STEP_LABEL.EVENT_AGENDA_AND_REVIEW,
                    STEP_LABEL.REVIEW,
                ]}
                setIsSubjectEmpty={setIsSubjectEmpty}
                reviewAlerts={reviewAlerts}
                setReviewAlerts={setReviewAlerts}
                isSuggestionSelected={meetingSuggestions?.length && meetingSuggestionSelected !== undefined}
                isSearchingMeetingSuggestions={props.isSearchingMeetingSuggestions}
                isAttendeeLoading={attendees.filter((attendee) => !attendee.email).length > 0}
                noLivestreamStations={noLivestreamStations}
                isLastStepLoading={isLastStepLoading}
                setIsLastStepLoading={setIsLastStepLoading}
            />
        </Column>
    );
};

export default LivestreamForm;