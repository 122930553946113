import React from "react";

import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Loader from "@amzn/meridian/loader";

const getLoadingMessage = (loadingMessage, loaderSize) => {
    return (
        <Row widths="fill" alignmentHorizontal="center" spacingInset="medium">
            <Column alignmentHorizontal="center" spacingInset="large">
                <Heading type="h200" level={2}>
                    {loadingMessage}
                </Heading>
                <Loader size={loaderSize}/>
            </Column>
        </Row>
    );
}

const LoadingMeetings = (props) => {
    return (
        <Row alignmentVertical="center" alignmentHorizontal="center" spacingInset="medium">
            {props.withBoundary ?
                <Column width="100%" maxWidth="1200px" type="outline" backgroundColor="primary">
                    {getLoadingMessage(props.loadingMessage, props.loaderSize)}
                </Column>
                :
                getLoadingMessage(props.loadingMessage, props.loaderSize)
            }
        </Row>
    );
};

export default LoadingMeetings