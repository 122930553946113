import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { getCreatingMeetingState, getSelectedPollDetails } from "../../../sagas/selector";
import { updateMeeting } from "../../shared/actions";
import { createMeetingForPoll } from "../../meeting-polls/actions";
import { SET_MEETINGS_LIST } from "../../shared/actions/action-types";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Theme from "@amzn/meridian/theme";
import Tooltip from "@amzn/meridian/tooltip";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";

import tealLightTokens from "@amzn/meridian-tokens/theme/teal-light";
import arrowLeftLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-left-large";
import arrowRightLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-right-large";

import "../../shared/styles/navigation-buttons.css";
import { ReactComponent as SendMailIcon } from "../../../assets/icons/shared/send_mail.svg";

import { FLOW_TYPE, STEP } from "../meeting-scheduler-constants";
import { ALERTS, PATHS, MAX_PAYLOAD_SIZE } from "../../shared/shared-constants";
import { generateComponentId, getObjectInBytes } from "../../shared/shared-utils";
import { addMeetingsInfo } from "../../shared/body/body-utils";

const MeetingNavigationButtons = (props) => {
    const dispatch = useDispatch();

    // Poll
    const flowType = props.flowType;
    const pollDetails = useSelector(getSelectedPollDetails);
    const isCreatingMeeting = useSelector(getCreatingMeetingState);
    const onCreateMeetingForPoll = (meeting, pollDetails, meetingList) => dispatch(createMeetingForPoll({meeting: meeting, sendInvitations: true}, "/", pollDetails, meetingList));

    const identity = props.identity;
    const meeting = props.meeting;
    const meetingList = props.meetingList;
    const meetingDetails = props.meetingDetails;
    const masterState = props.masterState; // false: creating a meeting or updating a single meeting/occurrence, true: updating a recurring series

    const currentStep = props.currentStep;
    const [prevStep, setPrevStep] = useState(undefined);
    const steps = props.steps;
    const labels = props.labels;
    const stepIndex = steps.indexOf(currentStep);
    const onChangeStep = props.onChangeStep;

    const prevStepLabel = stepIndex > 0 && labels[stepIndex - 1]; // name of prev step for tooltip
    const nextStepLabel = stepIndex < steps.length - 1 && labels[stepIndex + 1]; // name of next step for tooltip
    const nextButtonId = generateComponentId("button", currentStep);

    const setIsSubjectEmpty = props.setIsSubjectEmpty;
    const reviewAlerts = props.reviewAlerts;
    const setReviewAlerts = props.setReviewAlerts;

    const isSuggestionSelected = props.isSuggestionSelected; // disable next button if no meeting suggestion is selected
    const isSearchingMeetingSuggestions = props.isSearchingMeetingSuggestions; // disable back/next button while searching for suggestions
    const isAttendeeLoading = props.isAttendeeLoading; // disable next button if attendee data is still being retrieved (email is undefined)

    const disableNextButton = isSearchingMeetingSuggestions || isAttendeeLoading ||
        (!isSuggestionSelected && currentStep === STEP.SMART_SUGGESTIONS && flowType === FLOW_TYPE.CREATE);

    const [showNextTooltip, setShowNextTooltip] = useState(undefined); // handle tooltip hovering
    const [isLastStepLoading, setIsLastStepLoading] = [props.isLastStepLoading, props.setIsLastStepLoading]; // disable send invites button

    // Return the update meeting request for the API
    const getUpdateMeetingRequest = () => {
        if (flowType === FLOW_TYPE.UPDATE) {
            let changes = JSON.parse(JSON.stringify(meeting));

            delete changes["organizer"]; // cannot change the organizer of an existing meeeting
            delete changes["uniqueID"]; // cannot change the unique ID of an existing meeting

            let updateMeetingRequest = {
                requestedOnBehalfOf: meeting.organizer,
                uniqueOrEntryID: meetingDetails.entryID,
                master: masterState,
                sendInvitations: true,
                changes: changes
            };

            return updateMeetingRequest;
        }
    };

    const isMeetingAgendaValid = () => {
        // If subject is empty, display error and do not proceed to next step
        if (!meeting.subject || !meeting.subject.length || meeting.subject === "") {
            setIsSubjectEmpty(true);
            return false;
        } else {
            setIsSubjectEmpty(false);
            return true;
        }
    };

    const onReviewButton = () => {
        setIsLastStepLoading(true); // During final step, set it to loading to prevent double click
        if (flowType === FLOW_TYPE.CREATE || flowType === FLOW_TYPE.POLL) {
            // Update meeting body with self service info and creation info
            const updatedBody = {
                value: addMeetingsInfo(meeting.body.value, identity.username, meeting.uniqueID, meeting.recurrence),
                type: "html"
            };

            const updatedMeeting = JSON.parse(JSON.stringify(meeting));
            updatedMeeting.body = updatedBody;
            const meetingSize = getObjectInBytes(updatedMeeting);

            // do not send the request if the payload exceeds the limit
            if (meetingSize > MAX_PAYLOAD_SIZE) {
                // show error alert on the review page
                const payloadAlert = JSON.parse(JSON.stringify(ALERTS.MAX_PAYLOAD_SIZE_EXCEEDED));
                payloadAlert.value = payloadAlert.value.replace("request", `request (${(meetingSize / 1024 / 1024).toFixed(2)}MB)`); // show request size in MB

                setReviewAlerts(reviewAlerts
                    .filter((alert) => alert.id !== ALERTS.MAX_PAYLOAD_SIZE_EXCEEDED.id) // remove existing payload size alerts
                    .concat([payloadAlert]));

                // re-enable the button after 1 sec so it's usable after alert is fixed
                window.setTimeout(() => {
                    setIsLastStepLoading(false);
                }, 1000); // 1 sec timer

                return;
            }

            if (flowType === FLOW_TYPE.POLL) {
                onCreateMeetingForPoll(updatedMeeting, pollDetails, meetingList);
            } else {
                props.onCreateMeeting(updatedMeeting);
            }
        } else if (flowType === FLOW_TYPE.UPDATE) {
            const onUpdateMeeting = (updateMeetingRequest, redirectTo, setMeetingListAction) => dispatch(updateMeeting(updateMeetingRequest, redirectTo, setMeetingListAction));

            const meetingRequest = getUpdateMeetingRequest();
            const redirectTo = PATHS.HOMEPAGE;

            let changedMeetingList;
            let setMeetingListAction;

            if (meetingList) {
                // update the changed meeting inside of the meeting list
                changedMeetingList = meetingList.map((meeting) => meeting.entryID !== meetingDetails.entryID ?
                    meeting
                    :
                    {
                        ...meeting,
                        subject: meetingRequest.changes.subject,
                        location: meetingRequest.changes.location,
                        time: meetingRequest.changes.time,
                        isPrivate: meetingRequest.changes.isPrivate,
                        status: meetingRequest.changes.status,
                        isAllDayEvent: meetingRequest.changes.isAllDayEvent
                    }
                );

                // create an action to update the meetings list used by the homepage agenda
                setMeetingListAction = {
                    type: SET_MEETINGS_LIST,
                    meetingList: changedMeetingList,
                };
            }

            onUpdateMeeting(meetingRequest, redirectTo, setMeetingListAction);
        }
    };

    const onBackClick = () => {
        if (flowType === FLOW_TYPE.POLL) {
            dispatch(push("/polls"));
        } else if (stepIndex !== 0) {
            onChangeStep(steps[stepIndex - 1]);
        }
    };

    const onNextClick = () => {
        if (currentStep === STEP.MEETING_AGENDA_AND_REVIEW) { // Meeting agenda and review page
            if (isMeetingAgendaValid()) { // Only send meeting if meeting summary is valid
                onReviewButton();
            }
        } else {
            onChangeStep(steps[stepIndex + 1]);
        }
    };

    // Add a timer to the 'next' button on the review step to prevent double clicking
    useEffect(() => {
        if (prevStep !== currentStep && !isLastStepLoading) {
            setPrevStep(currentStep);
            setIsLastStepLoading(currentStep === STEP.MEETING_AGENDA_AND_REVIEW);
            if (isSearchingMeetingSuggestions || currentStep === STEP.MEETING_AGENDA_AND_REVIEW) {
                // disable tooltip when next button is disabled/loading
                setShowNextTooltip(false);
            }

            window.setTimeout(() => {
                setIsLastStepLoading(false);
            }, 1000); // 1 sec timer
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevStep, setPrevStep, currentStep, isSearchingMeetingSuggestions, isLastStepLoading, disableNextButton]);

    // When the loader is being displayed waiting for a response from the API and the user navigates back & forth - continue to display the loader
    // Once the API response is an error, remove the loader and display the send mail icon to retry
    useEffect(() => {
        if (!isCreatingMeeting && isLastStepLoading && currentStep === STEP.MEETING_AGENDA_AND_REVIEW) {
            window.setTimeout(() => {
                setIsLastStepLoading(false);
            }, 1000); // 1 sec timer
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, isLastStepLoading, isCreatingMeeting]);

    return (
        <section aria-label="Step navigation">
            <Theme tokens={tealLightTokens}>
                <Column spacing={"xsmall"}>
                    <Row>
                        {(isSearchingMeetingSuggestions || isAttendeeLoading) &&
                            <Loader size="small" />
                        }
                        {prevStepLabel &&
                            <Tooltip
                                position="bottom"
                                title={<div>Back to <b>{flowType === FLOW_TYPE.POLL ? "Poll dashboard" : prevStepLabel}</b></div>}
                            >
                                <Button
                                    label={`Back to ${flowType === FLOW_TYPE.POLL ? "Poll dashboard" : prevStepLabel}`}
                                    type="secondary"
                                    circular="true"
                                    onClick={onBackClick}
                                    disabled={isSearchingMeetingSuggestions}
                                >
                                    <Icon tokens={arrowLeftLargeTokens}>Back to {flowType === FLOW_TYPE.POLL ? "Poll dashboard" : prevStepLabel}</Icon>
                                </Button>
                            </Tooltip>
                        }
                        {nextStepLabel ?
                            <Tooltip
                                position="bottom"
                                title={<div>Proceed to <b>{nextStepLabel}</b></div>}
                                open={disableNextButton ? false : showNextTooltip}
                            >
                                <Button
                                    id={nextButtonId}
                                    label={`Proceed to ${nextStepLabel}`}
                                    type="primary"
                                    circular="true"
                                    onClick={onNextClick}
                                    disabled={disableNextButton}
                                    onFocus={() => setShowNextTooltip(true)}
                                    onBlur={() => setShowNextTooltip(false)}
                                    onMouseEnter={() => setShowNextTooltip(true)}
                                    onMouseLeave={() => setShowNextTooltip(false)}
                                >
                                    <Icon tokens={arrowRightLargeTokens} />
                                </Button>
                            </Tooltip>
                            :
                            <Tooltip
                                position="bottom"
                                title={<b>Send invite</b>}
                                open={isLastStepLoading ? false : showNextTooltip}
                            >
                                <button
                                    id={nextButtonId}
                                    className="sendInviteButton"
                                    onClick={onNextClick}
                                    aria-label="Send invite"
                                    disabled={isLastStepLoading}
                                    onFocus={() => setShowNextTooltip(true)}
                                    onBlur={() => setShowNextTooltip(false)}
                                    onMouseEnter={() => setShowNextTooltip(true)}
                                    onMouseLeave={() => setShowNextTooltip(false)}
                                >
                                    {isLastStepLoading ?
                                        <div style={{paddingLeft: "12px"}}>
                                            <Loader size="small" />
                                        </div>
                                        :
                                        <SendMailIcon />
                                    }
                                </button>
                            </Tooltip>
                        }
                    </Row>
                    {isLastStepLoading && currentStep === STEP.MEETING_AGENDA_AND_REVIEW && isCreatingMeeting &&
                        <Row spacingInset={"200 none none none"}>
                            <Alert type={ALERTS.PROCESSING_CREATE_MEETING.type} size="small">
                                <Text id={ALERTS.PROCESSING_CREATE_MEETING.id}>
                                    {ALERTS.PROCESSING_CREATE_MEETING.value}
                                </Text>
                            </Alert>
                        </Row>
                    }
                </Column>
            </Theme>
        </section>
    );
};

export default MeetingNavigationButtons;