module.exports = {
    COMPONENT_ALIAS: {
        ALL_REGIONS: {
            Alpha: "amw-naws-alpha",
            Beta: "amw-naws-beta",
            BetaPrime: "amw-naws-betaprime",
            Prod: "amw-naws-prod",
        },
        CLOUDFRONT: "amw-naws-cloudfront",
        PDX: "amw-naws-pdx",
        IAD: "amw-naws-iad",
        DUB: "amw-naws-dub",
        NRT: "amw-naws-nrt",
    },
    IS_IT_DOWN_LOGIN_URL: "https://is-it-down.amazon.com/sso/login",
    IS_IT_DOWN_MEETINGS_URL: "https://is-it-down.amazon.com/amazonmeetings/components/",
    STATUS: {
        OK: "OK",
        NO_DATA: "no data",
        NO_MONITOR_STATE: "No monitor state.",
    },
    INDICATOR: {
        SUCCESS: 0,
        INFORMATIONAL: 1,
        WARNING: 2,
        ERROR: 3,
    },
};