import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Textarea from "@amzn/meridian/textarea";
import Checkbox from "@amzn/meridian/checkbox";
import Divider from "@amzn/meridian/divider";
import Icon from "@amzn/meridian/icon";
import Input from "@amzn/meridian/input";
import Link from "@amzn/meridian/link";
import PopoverHeader from "@amzn/meridian/popover/popover-header";
import Popover from "@amzn/meridian/popover";
import RadioButton from "@amzn/meridian/radio-button";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Tag from "@amzn/meridian/tag";
import Text from "@amzn/meridian/text";

import AttendeeSearchBar from "../../people/components/attendee-search-bar";
import AttendeePopover from "../../people/components/attendee-popover";
import AttendeeTag from "../../people/components/attendee-tag";

import {
    VIEWER_COUNT_OPTION,
    EVENT_TYPE,
    EVENT_PLATFORM,
    RECORDING_TYPE,
    CONTACT_CHANNEL,
    LIVESTREAM_ALERTS,
    SIGN_LANGUAGE_INTERPRETER_OPTION,
    CLOSED_CAPTIONING_OPTIONS,
    EVENT_LOCATION,
} from "../livestream-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { renderExternalLink } from "../../shared/shared-utils";
import { ATTENDEE_SEARCH_BAR_SMALL_POPOVER_HEIGHT } from "../../people/people-constants";
import { MEETING_BODY_HEIGHT } from "../../meeting-quick/meeting-quick-constants";
import RichTextEditor from "../../shared/components/rich-text-editor";
import copyTokens from "@amzn/meridian-tokens/base/icon/copy";
import exportSmallTokens from "@amzn/meridian-tokens/base/icon/export-small";
import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import PermissionsInputGroup, { PermissionType } from "./permissions-input-group";
import { clearLatestEventDetails, clearLivestreamEventSeriesUrlAvailability, getCurrentUserData, getLatestEventDetails, getLivestreamEventSeriesUrlAvailability } from "../actions";
import { isValidLivestreamSeriesUrl, isValidSlackChannelName, workspaces } from "../livestream-utils";
import {
    getIdentity,
    getLatestEventErrorDetailsSelector,
    getLatestEventSelector,
    getLivestreamEventSeriesUrlAvailabilitySelector,
    getStageConfig
} from "../../../sagas/selector";
import { FLOW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";
import { ENDPOINTS } from "../../api/apig/livestream/endpointConfig";

const StepEventRequirements = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const [eventRequirements, setEventRequirements] = [props.eventRequirements, props.setEventRequirements];
    const [eventRequirementErrors, setEventRequirementErrors] = [props.eventRequirementErrors, props.setEventRequirementErrors];
    const attendees = props.attendees;
    const [addedRestrictedGroups, setAddedRestrictedGroups] = [props.addedRestrictedGroups, props.setAddedRestrictedGroups];
    const [addedPointsOfContact, setAddedPointsOfContact] = [props.addedPointsOfContact, props.setAddedPointsOfContact];
    const urlHelperIconButtonRef = useRef();
    const [urlHelperIcon, setUrlHelperIcon] = useState(false);
    const desiredUrlHelperIconButtonRef = useRef();
    const [desiredUrlHelperIcon, setDesiredUrlHelperIcon] = useState(false);
    const playbackHelperIconButtonRef = useRef();
    const [playbackHelperIcon, setPlaybackHelperIcon] = useState(false);
    const eventDescriptionHelperIconButtonRef = useRef();
    const [eventDescriptionHelperIcon, setEventDescriptionHelperIcon] = useState(false);
    const chatHelperIconButtonRef = useRef();
    const [chatHelperIcon, setChatHelperIcon] = useState(false);
    const slackHelperIconButtonRef = useRef();
    const [slackHelperIcon, setSlackHelperIcon] = useState(false);
    const broadcastChannelsHelperIconButtonRef = useRef();
    const [broadcastChannelsHelperIcon, setBroadcastChannelsHelperIcon] = useState(false);
    const aslInterpreterHelperIconButtonRef = useRef();
    const [aslInterpreterHelperIcon, setAslInterpreterHelperIcon] = useState(false);
    const restrictionsHelperIconButtonRef = useRef();
    const [restrictionsHelperIcon, setRestrictionsHelperIcon] = useState(false);
    const presentationLanguageHelperIconButtonRef = useRef();
    const [presentationLanguageHelperIcon, setPresentationLanguageHelperIcon] = useState(false);
    const streamPlatformsHelperIconButtonRef = useRef();
    const [streamPlatformsHelperIcon, setStreamPlatformsHelperIcon] = useState(false);
    // TODO: will uncomment once reactions feature is enabled in prod.
    // const reactionsHelperIconButtonRef = useRef();
    // const [reactionsHelperIcon, setReactionsHelperIcon] = useState(false);
    const broadcastUploadHelperIconButtonRef = useRef();
    const [broadcastUploadHelperIcon, setBroadcastUploadHelperIcon] = useState(false);
    const pocHelperIconButtonRef = useRef();
    const [pocHelperIcon, setPocHelperIcon] = useState(false);
    const requestTypeHelperIconButtonRef = useRef();
    const [requestTypeHelperIcon, setRequestTypeHelperIcon] = useState(false);
    const livestreamTitleHelperIconButtonRef = useRef();
    const [livestreamTitleHelperIcon, setLivestreamTitleHelperIcon] = useState(false);
    const eventTitleHelperIconButtonRef = useRef();
    const [eventTitleHelperIcon, setEventTitleHelperIcon] = useState(false);
    const postEventDeliveryHelperIconButtonRef = useRef();
    const [postEventDeliveryHelperIcon, setPostEventDeliveryHelperIcon] = useState(false);
    const vipRolesHelperIconButtonRef = useRef();
    const [vipRolesHelperIcon, setVipRolesHelperIcon] = useState(false);
    const ccOptionsHelperIconButtonRef = useRef();
    const [ccOptionsHelperIcon, setCcOptionsHelperIcon] = useState(false);
    const [isNotValidDesiredLivestreamUrl, setIsNotValidDesiredLivestreamUrl] = useState(false);
    const [onHoverHelperIcon, setOnHoverHelperIcon] = useState(false);
    const [latestEventPoc, setLatestEventPoc] = useState(0);
    const [livestreamUrlPrefix, setLivestreamUrlPrefix] = useState("");
    const latestEventErrorDetails = useSelector(getLatestEventErrorDetailsSelector);
    const latestEvent = useSelector(getLatestEventSelector);
    const identity = useSelector(getIdentity);
    const currentUser = identity.username;
    const EVENT_DESCRIPTION_LABEL_ID = "event-description-label-id";
    const maxFormWidth = "650px";
    const maxFormHalfWidth = "300px";
    const existingLivestreamUrlError = props.eventRequirements["existingLivestreamUrlError"];
    const setCopyEventName = props.setCopyEventName;
    const livestreamUrlAvailability = useSelector(getLivestreamEventSeriesUrlAvailabilitySelector);
    const stageConfig = useSelector(getStageConfig);
    const desiredLivestreamUrlLength = livestreamUrlPrefix.length + eventRequirements["desiredLivestreamUrl"].length;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCurrentUserData());
    }, [dispatch]);

    useEffect(() => {
        if (stageConfig) {
            const endpoint = ENDPOINTS[stageConfig.stage]["IAD"];
            const livestreamUrlPrefix = endpoint + "/#/live/";
            setLivestreamUrlPrefix(livestreamUrlPrefix);
        }
    }, [stageConfig]);

    // Update the event requirement object with a new value for a given property
    const updateEventRequirements = (property, value) => {
        let updatedEventRequirements = {
            ...eventRequirements,
            [property]: value,
        };

        setEventRequirements(updatedEventRequirements);
    };

    // Update the event requirement error object with a new value for a given property
    const updateEventRequirementErrors = (property, value) => {
        let updatedEventRequirementErrors = {
            ...eventRequirementErrors,
            [property]: value,
        };

        setEventRequirementErrors(updatedEventRequirementErrors);
    };

    // Loop through array of requirement objects and update the values for each of them
    const updateMultipleEventRequirementErrors = (requirements) => {
        let updatedMultipleEventRequirementErrors = {
            ...eventRequirementErrors,
        };

        requirements.forEach((requirement) => {
            Object.keys(requirement).forEach((property) => {
                updatedMultipleEventRequirementErrors[property] = requirement[property];
            });
        });

        setEventRequirementErrors(updatedMultipleEventRequirementErrors);
    };

    const updateMultipleEventRequirements = (requirements) => {
        let updatedEventRequirements = {
            ...eventRequirements,
        };

        requirements.forEach((requirement) => {
            Object.keys(requirement).forEach((property) => {
                updatedEventRequirements[property] = requirement[property];
            });
        });

        setEventRequirements(updatedEventRequirements);
    };

    useEffect(() => {
        if (latestEventErrorDetails?.errorCode) {
            updateEventRequirements("existingLivestreamUrlError", latestEventErrorDetails.errorCode);
            updateEventRequirementErrors("existingLivestreamUrl", true);
        }
        // eslint-disable-next-line
    },[latestEventErrorDetails]);

    const toggleEventRequirementArrayOption = (property, value) => {
        let eventRequirementArray = eventRequirements[property];

            eventRequirementArray.includes(value) ?
                (property === "platforms" ?
                    eventQualifiersClassification(property, eventRequirementArray.filter((requirement) => requirement !== value))
                    :
                    // Remove event requirement from array if present
                    updateEventRequirements(property, eventRequirementArray.filter((requirement) => requirement !== value)))
                :
                (property === "platforms" ?
                    eventQualifiersClassification(property, eventRequirementArray.concat([value]))
                    :
                    // Add event requirement to array
                    updateEventRequirements(property, eventRequirementArray.concat([value])));
    };

    // Remove the viewer from the list of added restricted groups or points of contact so viewer toast isn't displayed
    const removeAddedViewer = (attendee) => {
        setAddedRestrictedGroups(addedRestrictedGroups.filter((group) => group.email !== attendee.email));
        setAddedPointsOfContact(addedPointsOfContact.filter((pointOfContact) => pointOfContact.email !== attendee.email));
    };

    const [openAttendeePopover, setOpenAttendeePopover] = useState("");
    const onCloseAttendeePopover = () => setOpenAttendeePopover("");
    const attendeePopoverRefs = useRef([]);

    // event handlers
    const viewAttendeeDetails = (attendee) => {
        setOpenAttendeePopover(attendee.identifier);
    };

    const onAddPointOfContact = (pointOfContact) => {
        let addNewPointOfContact = true;
        updateEventRequirementErrors("pointsOfContact", false);

        // Check if attendee has already been added, if so update them
        attendees.forEach((attendee) => {
            if (attendee.email === pointOfContact.email) {
                attendee.isPointOfContact = true;
                props.onUpdateAttendee(attendee);
                addNewPointOfContact = false;
            }
        });

        if (addNewPointOfContact) {
            pointOfContact.isPointOfContact = true;
            props.onAddAttendee(pointOfContact);

            // If rasData is not present, call APIs once to retrieve card data
            if (pointOfContact.rasData === undefined) {
                props.onGetRASDataForPerson(pointOfContact.alias, false);
            }
        }

        toggleEventRequirementArrayOption("pointsOfContact", pointOfContact);
        setAddedPointsOfContact([pointOfContact].concat(addedPointsOfContact));
    };

    const createAttendeeTag = (attendee, allowViewAttendeeDetails, arrayName) => {
        return (
            <AttendeeTag
                attendee={attendee}
                allowOrganizerRemoval={true}
                attendeePopoverRefs={attendeePopoverRefs}
                allowViewAttendeeDetails={allowViewAttendeeDetails}
                removeAttendee={(attendee) => {
                    const viewerAdded = addedRestrictedGroups.filter((group) => group.email === attendee.email).length ||
                        addedPointsOfContact.filter((pointOfContact) => pointOfContact.email === attendee.email).length;

                    if (viewerAdded) { // Remove attendee from meeting
                        props.onRemoveAttendee(attendee);
                    } else {
                        const isRestrictedGroup = eventRequirements["restrictedGroups"].filter((group) => group.email === attendee.email).length;

                        if (isRestrictedGroup) {
                            attendee.isRestrictedGroup = false; // Remove attendee from restricted groups
                        } else {
                            const isPointOfContact = eventRequirements["pointsOfContact"].filter((pointOfContact) => pointOfContact.email === attendee.email).length;

                            if (isPointOfContact) {
                                attendee.isPointOfContact = false; // Remove attendee from points of contact
                            }
                        }
                        props.onUpdateAttendee(attendee);
                    }

                    toggleEventRequirementArrayOption(arrayName, attendee);
                    removeAddedViewer(attendee);
                }}
                viewAttendeeDetails={viewAttendeeDetails}
                key={attendee.name || attendee.email}
            />
        );
    };

    const showAttendeeTags = (arrayName) => {
        let attendeeArray = eventRequirements[arrayName];
        attendeeArray.forEach((attendeeInArray, index) => {
            // Update attendee object with rasData from attendees redux state object
            const matchingAttendee = attendees.find((attendee) => attendee.alias === attendeeInArray.alias);

            if (matchingAttendee?.rasData) {
                attendeeArray[index].rasData = matchingAttendee.rasData;
            }
        });

        const attendeesAliasList = attendeeArray.map((attendee) => attendee.alias);
        return (
            <Row spacing="small" wrap="down">
                {attendeeArray.map((attendee) => (
                    <Row key={attendee.name || attendee.email}>
                        <div style={{
                            height: attendee.isOrganizer ? "27px" : "",
                            paddingTop: "6px",
                        }}>
                            {createAttendeeTag(attendee, true, arrayName)}
                        </div>
                        <AttendeePopover
                            position="bottom"
                            attendee={attendee}
                            attendeeList={attendeesAliasList}
                            anchorNode={attendeePopoverRefs.current[attendee.identifier]}
                            open={openAttendeePopover === attendee.identifier}
                            onClose={onCloseAttendeePopover}
                            onAddAttendee={(attendee) => {
                                props.onAddAttendee(attendee);
                                toggleEventRequirementArrayOption(arrayName, attendee);
                            }}
                            onRemoveAttendeeByAlias={(attendee) => {
                                props.onRemoveAttendeeByAlias(attendee);
                                toggleEventRequirementArrayOption(arrayName, attendee);
                            }}
                            onGetRASDataForPerson={props.onGetRASDataForPerson}
                            disableAddFromPopover={true}
                        />
                    </Row>
                ))}
            </Row>
        );
    };

    const permissionTypeToLabel = useCallback((type) => {
        if (type === PermissionType.PERSON) {
            return "Person";
        } else {
            return "Team"
        }
    }, []);

    const showPermissionTag = (permission) => {
        let permissions = eventRequirements[permission];
        const onClosePermissionTag = (permission) => {
            const currentPermissionTags = eventRequirements["restrictedGroups"];
            const changedPermissionTags = (currentPermissionTags.filter(item => item.name !== permission.name || item.type !== permission.type));
            updateEventRequirements("restrictedGroups", changedPermissionTags);
        };

        return (
            <>
            {permissions?.length > 0 &&
                <Row wrap="down">
                    {permissions.map((permission, index) => (
                        <Tag
                            onClose={() => onClosePermissionTag(permission)}
                            type="theme"
                            key={index+permission.name}
                        >
                            {`${permission.name} (${permissionTypeToLabel(permission.type)})`}
                        </Tag>
                    ))}
                </Row>
            }
            </>
        );
    };

    const onClickSlackCopy = () => {
        const slackChannelName = eventRequirements["slackChannelName"];
        const slackWorkspace = eventRequirements["slackWorkspace"];
        if (slackChannelName?.trim() !== "" && slackWorkspace?.trim() !== "") {
            const slackChannel = eventRequirements["slackChannel"];
            // note that the API is only available in secure HTTPS context
            // TODO TEMP Generic redirect link not working, storing entire URL for now
            navigator?.clipboard?.writeText(slackChannel).then(() => {
                    console.log("copy succeeded.");
                }, (err) => {
                    console.log("copy failed: ", err);
                }
            );
            return;
        }
        if (slackChannelName?.trim() === "") {
            updateEventRequirementErrors("slackChannelName", true);
        }
        if (slackWorkspace?.trim() === "") {
            updateEventRequirementErrors("slackWorkspace", true);
        }
    };

    const onClickSlackOpen = (href) => {
        const slackChannelName = eventRequirements["slackChannelName"];
        const slackWorkspace = eventRequirements["slackWorkspace"];
        if (slackChannelName && slackChannelName?.trim() !== "" && slackWorkspace && slackWorkspace?.trim() !== "") {
            window.open(href, "_blank");
            return;
        }
        if ((!slackWorkspace || slackWorkspace?.trim() === "") && (!slackChannelName || slackChannelName?.trim() === "")) {
            updateMultipleEventRequirementErrors([
                {slackWorkspace: true},
                {slackChannelName: true}
            ]);
        } else {
            if (!slackWorkspace || slackWorkspace?.trim() === "") {
                updateEventRequirementErrors("slackWorkspace", true);
            }
            if (!slackChannelName || slackChannelName?.trim() === "") {
                updateEventRequirementErrors("slackChannelName", true);
            }
        }
    };

    const onChangeLivestreamTitle = (livestreamTitle) => {
        updateMultipleEventRequirements([{"eventName": livestreamTitle},{"livestreamTitle": livestreamTitle}]);
        if (eventRequirementErrors["livestreamTitle"]) {
            updateEventRequirementErrors("livestreamTitle", false);
        }
        if (eventRequirementErrors["eventName"]) {
            updateEventRequirementErrors("eventName", false);
        }
        setCopyEventName(true);
    };

    const onFocusEventTitle = () => {
        const livestreamTitle = eventRequirements["livestreamTitle"];
        const eventTitle = eventRequirements["eventName"];
        if (livestreamTitle === eventTitle) {
            updateEventRequirements("eventName", "");
        }
    };

    const onBlurEventTitle = (element) => {
        if(element.target.value.trim() === "") {
            const livestreamTitle = eventRequirements["livestreamTitle"];
            updateEventRequirements("eventName", livestreamTitle);
        }
    }

    const onChangeEventRequirement = (requirement, value) => {
        updateEventRequirements(requirement, value);
        if (eventRequirementErrors[requirement]) {
            updateEventRequirementErrors(requirement, false);
        }
    };

    const onClickBroadcastUrlOpen = (href) => {
        const previousBroadcastUrl = eventRequirements["previousBroadcastUrl"];
        if (previousBroadcastUrl && previousBroadcastUrl.trim !== "") {
            window.open(href, "_blank");
        } else {
           updateEventRequirementErrors("previousBroadcastUrl", true);
        }
    };

    const onChangeSlackName = (channel) => {
        const href = eventRequirements["slackWorkspace"] + "/app_redirect?channel=" + channel.trim();
        updateMultipleEventRequirements([
            {slackChannel: href},
            {slackChannelName: channel}
        ]);
        if (!isValidSlackChannelName(channel.trim())) {
            updateEventRequirementErrors("slackChannelName", true);
        }
        else if (eventRequirementErrors["slackChannelName"]) {
            updateEventRequirementErrors("slackChannelName", false);
        }
    };

    const SlackChannelErrorMessage = () => {
        if (eventRequirementErrors["slackChannelName"]) {
            if (eventRequirements["slackChannelName"].trim().length) {
                return LIVESTREAM_ALERTS.SLACK_CHANNEL.INVALID_SLACK_CHANNEL.value
            } else {
                return LIVESTREAM_ALERTS.SLACK_CHANNEL.NO_SLACK_CHANNEL.value
            }
        }
        return "";
    };

    const onChangeWorkspace = (selectedWorkspace) => {
        const href = selectedWorkspace + "/app_redirect?channel=" + eventRequirements["slackChannelName"];
        updateMultipleEventRequirements([
            {slackChannel: href},
            {slackWorkspace: selectedWorkspace}
        ]);

        if (eventRequirementErrors["slackWorkspace"]) {
            updateEventRequirementErrors("slackWorkspace", false);
        }
    };

    const onClickLoadEventDetails = () => {
        const existingLivestreamUrl = eventRequirements["existingLivestreamUrl"].trim();
        if (existingLivestreamUrl === "") {
            updateEventRequirementErrors("existingLivestreamUrl", true);
            updateEventRequirements("existingLivestreamUrlError","NO_URL_PROVIDED");
            return;
        }
        // Reset errors if any, before latest event details are populated
        updateMultipleEventRequirementErrors([
            {eventName: false},
            {eventDescription: false},
            {viewerJustification: false},
            {expectedViewerCount: false},
            {platforms: false},
            {previousBroadcastUrl: false},
            {existingLivestreamUrl: false},
            {pointsOfContact: false},
            {contactChannels: false},
            {phoneNumber: false},
            {slackChannelName: false},
            {slackWorkspace: false},
            {livestreamTitle: false},
        ]);
        updateEventRequirements("existingLivestreamUrlError", "");
        const loadEventDetails = {
            seriesUrl: existingLivestreamUrl,
            organizer: currentUser,
        }
        dispatch(getLatestEventDetails(loadEventDetails));
    };

    const onChangeReUseUrl = (value) => {
        if (!value && existingLivestreamUrlError === "SERIES_URL_MISMATCH") {
            const existingUrl = eventRequirements["existingLivestreamUrl"];
            updateMultipleEventRequirements([
                {"desiredLivestreamUrl": existingUrl},
                {"reuseUrl": value},
            ]);
            if (existingUrl.trim() !== "" && !isValidLivestreamSeriesUrl(existingUrl.trim())) {
                updateEventRequirementErrors("desiredLivestreamUrl", true);
                setIsNotValidDesiredLivestreamUrl(true);
            } else {
                dispatch(getLivestreamEventSeriesUrlAvailability(existingUrl.trim()));
            }
        } else {
            updateMultipleEventRequirements([
                {"desiredLivestreamUrl": ""},
                {"reuseUrl": value},
            ]);
            updateEventRequirementErrors("desiredLivestreamUrl", false);
        }
    };

    //load latest event details
    useEffect(() => {
        if (latestEvent) {
            const organizer = currentUser;
            const latestEventPocLength = latestEvent.pointsOfContact?.length
            setLatestEventPoc(latestEvent.pointsOfContact?.includes(organizer) ? latestEventPocLength : (latestEventPocLength + 1));
            const getStreamingPlatforms = (platforms) => {
                return Object.keys(EVENT_PLATFORM).filter(key =>
                    platforms.includes(EVENT_PLATFORM[key].LABEL) && key
                )
            };
            const slackChannelUrl = latestEvent.slackChannelName;
            const slackUrlPath = slackChannelUrl?.split('/');
            const slackChannelName = slackUrlPath[3]?.split('=')[1] || "";
            const slackWorkspace = (slackUrlPath[0] && slackUrlPath[2] && workspaces.find((workspace) =>
                workspace.url === (slackUrlPath[0] + "//" + slackUrlPath[2])
            )).url;

            setCopyEventName(true);

            setEventRequirements({
                ...eventRequirements,
                "eventName": latestEvent.eventTitle || "",
                "livestreamTitle": latestEvent.seriesTitle || "",
                "viewerCount": latestEvent.expectedNumberOfViewers || "250-999",
                "viewerJustification": latestEvent.expectedNumberOfViewersJustification || "",
                "expectedViewerCount": latestEvent.expectedNumberOfViewersAdditional || "",
                "allowChat": latestEvent.chatEnabled || latestEvent.slackChannelEnabled,
                "multipleLivestreams": latestEvent.isMultiTrackEvent || false,
                "restricted": latestEvent.viewershipRestrictions.length > 0,
                "restrictedGroups": latestEvent.viewershipRestrictions || [],
                "videoPlayback": latestEvent.hasVideoOrAudioPlayback || false,
                "platforms": (latestEvent.streamingPlatforms && getStreamingPlatforms(latestEvent.streamingPlatforms)) || ["HUBBLE"],
                "previousBroadcastUrl": latestEvent.broadcastChannelUrl || "",
                "broadcastPermission": latestEvent.uploadBroadcastPermission || false,
                "recordingType": latestEvent.recordingType || "BROADCAST",
                "signLanguageInterpreter": latestEvent.signLanguageEnabled ? "BOOKING" : "NONE",
                "nonEnglish": !latestEvent.presentationLanguage?.includes("English") || false,
                "contactChannels": latestEvent.contactChannels?.contactChannelsType || ["EMAIL", "SLACK"],
                "phoneNumber": latestEvent.contactChannels?.phoneNumber || "",
                "notes": latestEvent.additionalNotes || "",
                "slackEnabled": latestEvent.slackChannelEnabled || false,
                "slackChannel": slackChannelUrl || "",
                "slackChannelName": slackChannelName || "",
                "slackWorkspace": slackWorkspace || "",
                "eventDescription": latestEvent.description || "",
                "loadedEventLivestreamUrl": latestEvent.seriesUrl || eventRequirements["existingLivestreamUrl"],
                "seriesOid": latestEvent.oid || "",
                "isAnyPresenterL10": latestEvent.isAnyPresenterL10 || false,
                "ccOptions": latestEvent.ccOptions || "DISCLAIMER",
                "eventLocation": (eventRequirements.eventRequestType !== "EVENT_PLAYBACK" && latestEvent.eventLocation) || "REMOTE_OR_VIRTUAL",
                "isAudienceInFCs": latestEvent.isAudienceInFCs || false,
                // "reactions": latestEvent.reactionsEnabled || false // TODO: will uncomment once reactions feature is enabled in prod.
            });

            dispatch(clearLatestEventDetails());
        }
    },[currentUser, latestEvent, dispatch, eventRequirements, setEventRequirements, setCopyEventName]);

    useEffect(() => {
        if (latestEventPoc && (latestEventPoc === attendees.length)) {
            setEventRequirements({
                ...eventRequirements,
                "pointsOfContact": attendees
            });
            setLatestEventPoc(0);
            eventQualifiersClassification(); // Settings LS stations count based on event qualifications criteria after fetching latest event details
        }
        // eslint-disable-next-line
    },[attendees, eventRequirements, latestEventPoc, setEventRequirements]);

    useEffect(() => {
        if (livestreamUrlAvailability && livestreamUrlAvailability.verified && !livestreamUrlAvailability.available) {
            updateEventRequirementErrors("desiredLivestreamUrl", true);
            setIsNotValidDesiredLivestreamUrl(false);
        }
        // eslint-disable-next-line
    }, [livestreamUrlAvailability]);

    const onChangeDesiredLivestreamUrl = (desiredLivestreamUrl) => {
        updateEventRequirementErrors("desiredLivestreamUrl", false);
        updateEventRequirements("desiredLivestreamUrl", desiredLivestreamUrl);
        dispatch(clearLivestreamEventSeriesUrlAvailability());
        if (desiredLivestreamUrl.trim() !== "") {
            if (!isValidLivestreamSeriesUrl(desiredLivestreamUrl.trim())) {
                updateEventRequirementErrors("desiredLivestreamUrl", true);
                setIsNotValidDesiredLivestreamUrl(true);
            } else {
                dispatch(getLivestreamEventSeriesUrlAvailability(desiredLivestreamUrl.trim()));
            }
        }
    };

    const onChangeEventRequestType = (eventRequestType) => {
        if (eventRequestType === "RECORDING") {
            updateMultipleEventRequirementErrors([
                {desiredLivestreamUrl: false},
                {existingLivestreamUrl: false},
                {livestreamTitle: false},
                {eventName: false},
                {platforms: false},
                {slackChannelName: false},
                {slackWorkspace: false},
            ]);
        }
        eventQualifiersClassification("eventRequestType", eventRequestType);
    };

    const eventQualifiersClassification = (req = "", value = "") => {
        let requiredStationCount = 1;
        const eventRequestType = (req === "eventRequestType" ? value : eventRequirements["eventRequestType"]);
        const videoPlayback = (req === "videoPlayback" ? value : eventRequirements["videoPlayback"]);
        const isAnyPresenterL10 = (req === "isAnyPresenterL10" ? value : eventRequirements["isAnyPresenterL10"]);
        const platforms = (req === "platforms" ? value : eventRequirements["platforms"]);
        const isPlatformHubble = !(platforms.length === 1 && platforms.includes("HUBBLE"));
        const aslInterpreter = (req === "signLanguageInterpreter" ? value : eventRequirements["signLanguageInterpreter"]);
        const eventLocation = (req === "eventLocation" ? value : eventRequirements["eventLocation"]);
        const isAudienceInFCs = (req === "isAudienceInFCs" ? value : eventRequirements["isAudienceInFCs"]);
        if (eventRequestType === "RECORDING") {
            if (videoPlayback || isAnyPresenterL10 || aslInterpreter === "BOOKING" || aslInterpreter === "HELP_BOOKING") {
                requiredStationCount = 2;
            }
        } else if (eventRequestType === "LIVE") {
            if (videoPlayback || isAnyPresenterL10 || aslInterpreter === "BOOKING" || aslInterpreter === "HELP_BOOKING" || isPlatformHubble || eventLocation === "OFF_CAMPUS" || isAudienceInFCs) {
                requiredStationCount = 2;
            }
        }
        updateMultipleEventRequirements([
            req && {[req]: value},
            {"requiredStationCount": requiredStationCount},
            eventRequestType === "EVENT_PLAYBACK" && {"eventLocation": "REMOTE_OR_VIRTUAL"},
        ]);
    };

    return (
        // TODO: add background behind form
        <Column spacingInset={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "large xlarge" : "small none"}>
            <Column spacingInset="large large large large" type="outline" backgroundColor="#FFFFFF">
                <Column spacingInset="none none medium none">
                    <Text type="h200">
                        Event story
                    </Text>
                    <Column spacing="small" maxWidth={maxFormWidth}>
                        <Row spacing="200">
                            <Text type="b300">
                                What is the type of event?
                            </Text>
                            <div
                                onMouseEnter={() => {setRequestTypeHelperIcon(true)}}
                                onMouseLeave={() => {!onHoverHelperIcon && setRequestTypeHelperIcon(false)}}
                            >
                                <Button
                                    onClick={() => {
                                        setOnHoverHelperIcon(true);
                                        setRequestTypeHelperIcon(true);
                                    }}
                                    type="icon"
                                    ref={requestTypeHelperIconButtonRef}
                                >
                                    <Icon tokens={helpTokens}></Icon>
                                </Button>
                                <Popover
                                    anchorNode={requestTypeHelperIconButtonRef.current}
                                    open={requestTypeHelperIcon}
                                    onClose={() => {
                                        setOnHoverHelperIcon(false);
                                        setRequestTypeHelperIcon(false);
                                    }}
                                    position="end"
                                    alignment="center"
                                    maxWidth={446}
                                >
                                    <PopoverHeader closeLabel="Close">What are the types of events?</PopoverHeader>
                                    <Column>
                                        <Text><b>Live event: </b>An event with content being streamed live by presenters. Audiences can be virtual and/or in person. Pre-recorded video/audio files for playback during the event are also accepted.</Text>
                                        <Text><b>Event playback: </b>An event where there are <b>no live elements</b> e.g. live Q&A. Only pre-recorded video content will be streamed with the intended audience during the live event.</Text>
                                    <Column spacing="xsmall">
                                        <Text><b>Recording only: </b>Recording services when you want to capture both the presenter's video feed and presentation. Recorded content is often used at a later date/event.</Text>
                                        <Text>Note: Chime can be used to record when you need only the presentation and audio feeds recorded.</Text>
                                    </Column>
                                    </Column>
                                </Popover>
                            </div>
                        </Row>
                        <Row wrap="down">
                            {Object.keys(EVENT_TYPE).map((option) => (
                                <RadioButton
                                    key={option}
                                    checked={eventRequirements["eventRequestType"] === option}
                                    value={option}
                                    onChange={(value) => onChangeEventRequestType(value)}
                                    size="medium"
                                    name="eventRequestTypeOption"
                                >
                                    {EVENT_TYPE[option]}
                                </RadioButton>
                            ))}
                        </Row>
                    </Column>
                    {(eventRequirements["eventRequestType"] === "LIVE" || eventRequirements["eventRequestType"] === "EVENT_PLAYBACK") &&
                        <>
                            <Column spacing="small" maxWidth={maxFormWidth}>
                                <Row spacing="200">
                                    <Text color="primary">
                                        Do you have an existing livestream URL you want to re-use?
                                    </Text>
                                    <div
                                        onMouseEnter={() => {setUrlHelperIcon(true)}}
                                        onMouseLeave={() => {!onHoverHelperIcon && setUrlHelperIcon(false)}}
                                    >
                                        <Button
                                            onClick={() => {
                                                setOnHoverHelperIcon(true);
                                                setUrlHelperIcon(true);
                                            }}
                                            type="icon"
                                            ref={urlHelperIconButtonRef}
                                        >
                                            <Icon tokens={helpTokens}></Icon>
                                        </Button>
                                        <Popover
                                            anchorNode={urlHelperIconButtonRef.current}
                                            open={urlHelperIcon}
                                            onClose={() => {
                                                setOnHoverHelperIcon(false);
                                                setUrlHelperIcon(false);
                                            }}
                                            position="end"
                                            alignment="center"
                                            maxWidth={366}
                                        >
                                            <PopoverHeader closeLabel="Close">How do I know if I have an existing URL?</PopoverHeader>
                                            <Column>
                                                <Text>If you, or your team has previously hosted a Livestream and wish to reuse the previous URL for this new event.</Text>
                                            </Column>
                                        </Popover>
                                    </div>
                                </Row>

                                <Row wrap="down">
                                    <RadioButton
                                        checked={eventRequirements["reuseUrl"] === true}
                                        value={true}
                                        onChange={(value) => onChangeReUseUrl(value)}
                                        size="medium"
                                        name="reuseUrlOption"
                                    >
                                        Yes
                                    </RadioButton>
                                    <RadioButton
                                        checked={eventRequirements["reuseUrl"] === false}
                                        value={false}
                                        onChange={(value) => onChangeReUseUrl(value)}
                                        size="medium"
                                        name="reuseUrlOption"
                                    >
                                        No
                                    </RadioButton>
                                </Row>
                            </Column>
                            {eventRequirements["reuseUrl"] &&
                                <Column spacing="xsmall" maxWidth="830px" spacingInset="none large">
                                    <Text type="b300">
                                        Existing Livestream URL
                                    </Text>
                                    <Row width="100%" widths={["fill", "fit"]}>
                                        <Input
                                            value={eventRequirements["existingLivestreamUrl"]}
                                            onChange={(value) => {updateEventRequirements("existingLivestreamUrl", value)}}
                                            error={eventRequirementErrors["existingLivestreamUrl"]}
                                            placeholder="Example: AWS East Region"
                                            prefix={livestreamUrlPrefix}
                                        />
                                        <Button
                                            onClick={onClickLoadEventDetails}
                                            type="secondary"
                                            size="medium"
                                        >
                                            Load event details
                                        </Button>
                                    </Row>
                                    {eventRequirementErrors["existingLivestreamUrl"] &&
                                        <Alert size="small" type={LIVESTREAM_ALERTS.EXISTING_LIVESTREAM_URL[existingLivestreamUrlError].type}>
                                            <Text id={LIVESTREAM_ALERTS.EXISTING_LIVESTREAM_URL[existingLivestreamUrlError].id}>
                                                {LIVESTREAM_ALERTS.EXISTING_LIVESTREAM_URL[existingLivestreamUrlError].value}
                                            </Text>
                                        </Alert>
                                    }
                                </Column>
                            }
                            {!eventRequirements["reuseUrl"] &&
                                <Column spacing="xsmall" maxWidth={maxFormWidth} spacingInset="none large">
                                    <Row spacing="xsmall">
                                        <Text type="b300">Desired Livestream URL</Text>
                                        <div
                                            onMouseEnter={() => {setDesiredUrlHelperIcon(true)}}
                                            onMouseLeave={() => {!onHoverHelperIcon && setDesiredUrlHelperIcon(false)}}
                                        >
                                            <Button
                                                onClick={() => {
                                                    setOnHoverHelperIcon(true);
                                                    setDesiredUrlHelperIcon(true);
                                                }}
                                                type="icon"
                                                ref={desiredUrlHelperIconButtonRef}
                                            >
                                                <Icon tokens={helpTokens}></Icon>
                                            </Button>
                                            <Popover
                                                anchorNode={desiredUrlHelperIconButtonRef.current}
                                                open={desiredUrlHelperIcon}
                                                onClose={() => {
                                                    setOnHoverHelperIcon(false);
                                                    setDesiredUrlHelperIcon(false);
                                                }}
                                                position="end"
                                                alignment="center"
                                                maxWidth={496}
                                            >
                                                <PopoverHeader closeLabel="Close">What special characters are allowed on a Livestream URL?</PopoverHeader>
                                                <Column>
                                                    <Text>Only underscore “_” and hyphen “-” are allowed on a Livestream URL. Other special characters like space, #, %, &, ., /, etc can cause invalid URLs and are not allowed.</Text>
                                                </Column>
                                            </Popover>
                                        </div>
                                    </Row>
                                    <Input
                                        value={eventRequirements["desiredLivestreamUrl"]}
                                        onChange={onChangeDesiredLivestreamUrl}
                                        placeholder="Example: AWS East Region"
                                        error={eventRequirementErrors["desiredLivestreamUrl"]}
                                        prefix={livestreamUrlPrefix}
                                    />
                                    {eventRequirementErrors["desiredLivestreamUrl"] && eventRequirements["desiredLivestreamUrl"].trim().length > 0 && (isNotValidDesiredLivestreamUrl ?
                                        <Alert size="small" type={LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.INVALID_URL_PROVIDED.type}>
                                            <Text id={LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.INVALID_URL_PROVIDED.id}>
                                                {LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.INVALID_URL_PROVIDED.value}
                                            </Text>
                                        </Alert>
                                        :
                                        <Alert size="small" type={LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.URL_ALREADY_EXISTS.type}>
                                            <Text id={LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.URL_ALREADY_EXISTS.id}>
                                                {LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.URL_ALREADY_EXISTS.value}
                                            </Text>
                                        </Alert>)
                                    }
                                    {desiredLivestreamUrlLength > 100 &&
                                        <Alert size="small" type={LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.INVALID_URL_LENGTH.type}>
                                            <Text id={LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.INVALID_URL_LENGTH.id}>
                                                {LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.INVALID_URL_LENGTH.value}
                                            </Text>
                                        </Alert>
                                    }
                                    {eventRequirementErrors["desiredLivestreamUrl"] && eventRequirements["desiredLivestreamUrl"].trim().length === 0 &&
                                        <Alert size="small" type={LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.NO_URL_PROVIDED.type}>
                                            <Text id={LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.NO_URL_PROVIDED.id}>
                                                {LIVESTREAM_ALERTS.DESIRED_LIVESTREAM_URL.NO_URL_PROVIDED.value}
                                            </Text>
                                        </Alert>
                                    }
                                </Column>
                            }
                        </>
                    }
                    <Column spacing="xsmall" maxWidth={maxFormWidth}>
                        <Row spacing="200">
                            <Text type="b300">
                                {(eventRequirements["eventRequestType"] === "LIVE" || eventRequirements["eventRequestType"] === "EVENT_PLAYBACK") ?
                                    "Livestream title"
                                    :
                                    "Recording title"
                                }
                            </Text>
                            <div
                                onMouseEnter={() => {setLivestreamTitleHelperIcon(true)}}
                                onMouseLeave={() => {!onHoverHelperIcon && setLivestreamTitleHelperIcon(false)}}
                            >
                                <Button
                                    onClick={() => {
                                        setOnHoverHelperIcon(true);
                                        setLivestreamTitleHelperIcon(true);
                                    }}
                                    type="icon"
                                    ref={livestreamTitleHelperIconButtonRef}
                                >
                                    <Icon tokens={helpTokens}></Icon>
                                </Button>
                                <Popover
                                    anchorNode={livestreamTitleHelperIconButtonRef.current}
                                    open={livestreamTitleHelperIcon}
                                    onClose={() => {
                                        setOnHoverHelperIcon(false);
                                        setLivestreamTitleHelperIcon(false);
                                    }}
                                    position="end"
                                    alignment="center"
                                    maxWidth={496}
                                >
                                    <PopoverHeader closeLabel="Close">What is the Livestream title?</PopoverHeader>
                                    <Column spacing="xsmall">
                                        <Text>The primary name of your event or event series.</Text>
                                        <Text>Example: “AWS East Region”, “PXT Global”, etc.</Text>
                                    </Column>
                                </Popover>
                            </div>
                        </Row>
                        <Input
                            value={eventRequirements["livestreamTitle"]}
                            onChange={onChangeLivestreamTitle}
                            error={eventRequirementErrors["livestreamTitle"]}
                            placeholder="Example: AWS East Region"
                        />
                        {eventRequirementErrors["livestreamTitle"] &&
                            <Alert size="small" type={LIVESTREAM_ALERTS.NO_LIVESTREAM_TITLE.type}>
                                <Text id={LIVESTREAM_ALERTS.NO_LIVESTREAM_TITLE.id}>
                                    {LIVESTREAM_ALERTS.NO_LIVESTREAM_TITLE.value}
                                </Text>
                            </Alert>
                        }
                        {eventRequirements["livestreamTitle"].length > 100 &&
                            <Alert size="small" type={LIVESTREAM_ALERTS.INVALID_LIVESTREAM_TITLE.type}>
                                <Text id={LIVESTREAM_ALERTS.INVALID_LIVESTREAM_TITLE.id}>
                                    {LIVESTREAM_ALERTS.INVALID_LIVESTREAM_TITLE.value}
                                </Text>
                            </Alert>
                        }
                    </Column>
                    {(eventRequirements["eventRequestType"] === "LIVE" || eventRequirements["eventRequestType"] === "EVENT_PLAYBACK") &&
                        <Column spacing="xsmall" maxWidth={maxFormWidth}>
                            <Row spacing="small" alignmentHorizontal="left">
                                <Text type="b300" >
                                    Event title
                                </Text>
                                <Text color="secondary" type="b300">
                                    (optional)
                                </Text>
                                <div
                                    onMouseEnter={() => {setEventTitleHelperIcon(true)}}
                                    onMouseLeave={() => {!onHoverHelperIcon && setEventTitleHelperIcon(false)}}
                                >
                                    <Button
                                        onClick={() => {
                                            setOnHoverHelperIcon(true);
                                            setEventTitleHelperIcon(true);
                                        }}
                                        type="icon"
                                        ref={eventTitleHelperIconButtonRef}
                                    >
                                        <Icon tokens={helpTokens}></Icon>
                                    </Button>
                                    <Popover
                                        anchorNode={eventTitleHelperIconButtonRef.current}
                                        open={eventTitleHelperIcon}
                                        onClose={() => {
                                            setOnHoverHelperIcon(false);
                                            setEventTitleHelperIcon(false);
                                        }}
                                        position="end"
                                        alignment="center"
                                        maxWidth={496}
                                    >
                                        <PopoverHeader closeLabel="Close">What is the Event title?</PopoverHeader>
                                        <Column spacing="xsmall">
                                            <Text>A secondary name of your event i.e. the subheading of your Livestream title. This should be more specific for the individual event, especially if you will be reusing one Livestream URL for a series of future events.</Text>
                                            <Text>Example: “Q1 Managers Meeting”, “Q4 Global Meeting”, etc.</Text>
                                        </Column>
                                    </Popover>
                                </div>
                            </Row>
                            <Input
                                value={eventRequirements["eventName"]}
                                onBlur={onBlurEventTitle}
                                onChange={(value) => {onChangeEventRequirement("eventName", value); setCopyEventName(true);}}
                                onFocus={onFocusEventTitle}
                                error={eventRequirementErrors["eventName"]}
                                placeholder="Example: Q1 Manager's Meeting"
                            />
                            {eventRequirementErrors["eventName"] &&
                                <Alert size="small" type={LIVESTREAM_ALERTS.NO_EVENT_NAME.type}>
                                    <Text id={LIVESTREAM_ALERTS.NO_EVENT_NAME.id}>
                                        {LIVESTREAM_ALERTS.NO_EVENT_NAME.value}
                                    </Text>
                                </Alert>
                            }
                            {eventRequirements["eventName"].length > 100 &&
                                <Alert size="small" type={LIVESTREAM_ALERTS.INVALID_EVENT_NAME.type}>
                                    <Text id={LIVESTREAM_ALERTS.INVALID_EVENT_NAME.id}>
                                        {LIVESTREAM_ALERTS.INVALID_EVENT_NAME.value}
                                    </Text>
                                </Alert>
                            }
                        </Column>
                    }
                    <Column spacing="xsmall" maxWidth={maxFormWidth}>
                        <Row spacing="200">
                            <Text type="b300">
                                Event description
                            </Text>
                            <div
                                onMouseEnter={() => setEventDescriptionHelperIcon(true)}
                                onMouseLeave={() => !onHoverHelperIcon && setEventDescriptionHelperIcon(false)}
                            >
                                <Button
                                    onClick={() => {
                                        setOnHoverHelperIcon(true);
                                        setEventDescriptionHelperIcon(true)
                                    }}
                                    type="icon"
                                    ref={eventDescriptionHelperIconButtonRef}
                                >
                                    <Icon tokens={helpTokens}></Icon>
                                </Button>
                                <Popover
                                    alignment="center"
                                    anchorNode={eventDescriptionHelperIconButtonRef.current}
                                    closeLabel="Close"
                                    maxWidth={526}
                                    open={eventDescriptionHelperIcon}
                                    onClose={() => {
                                        setEventDescriptionHelperIcon(false);
                                        setOnHoverHelperIcon(false);
                                    }}
                                    position="end"
                                >
                                    <PopoverHeader closeLabel="Close">Why include an event description?</PopoverHeader>
                                    <Column>
                                        <Column spacing="xsmall">
                                            <Text>A good event description can help ensure the success of your event. It makes a first great impression and set the tone and expectation for the rest of your event. It can also peak interest, build attention, and increase engagement among your attendees.</Text>
                                        </Column>
                                        <Column spacing="xsmall">
                                            <Text type="h100">What are the best practices for writing an event description?</Text>
                                            <Text>Be able to answer: (1) What is the event about? and (2) Why attend this event?</Text>
                                            <Text>Be concise and focus on the relevant information. Include specific details about the new event. Know your target attendees’ interest and motivations and tailor your description according to these. Emphasize the benefits of attending the event to increase their interest in the event.</Text>
                                        </Column>
                                        <Column spacing="xsmall">
                                            <Text type="h100">What is an example of a good event description?</Text>
                                            <Text>“Engineering All-hands is the quarterly get-together for all the members of the Engineering team. We will hear senior leadership talk about the Q1 updates and 2024 top priorities. Get to ask team leaders questions that are relevant to your career growth and development.”</Text>
                                        </Column>
                                    </Column>
                                </Popover>
                            </div>
                        </Row>
                        <Column minHeight={192}>
                            <RichTextEditor
                                error={eventRequirementErrors["eventDescription"]}
                                height={MEETING_BODY_HEIGHT}
                                textValue={eventRequirements["eventDescription"]}
                                flowType={FLOW_TYPE.LOAD}
                                updateTextValue={(value) => {onChangeEventRequirement("eventDescription", value)}}
                                uniqueId="event-description"
                                aria-labelledby={EVENT_DESCRIPTION_LABEL_ID}
                            />
                        </Column>
                        <Text color="secondary" type="b200">
                            Details about your event visible to the attendees
                        </Text>
                        {eventRequirementErrors["eventDescription"] &&
                            <Alert size="small" type={LIVESTREAM_ALERTS.INVALID_EVENT_DESCRIPTION.type}>
                                <Text id={LIVESTREAM_ALERTS.INVALID_EVENT_DESCRIPTION.id}>
                                    {LIVESTREAM_ALERTS.INVALID_EVENT_DESCRIPTION.value}
                                </Text>
                            </Alert>
                        }
                    </Column>
                    {(eventRequirements["eventRequestType"] === "LIVE" || eventRequirements["eventRequestType"] === "EVENT_PLAYBACK") &&
                        <>
                            <Column spacing="xsmall" maxWidth={maxFormWidth}>
                                <Text type="b300">
                                    Expected number of viewers
                                </Text>
                                <Row wrap="down">
                                    <Select
                                        width={maxFormHalfWidth}
                                        value={eventRequirements["viewerCount"]}
                                        onChange={(value) => {updateEventRequirements("viewerCount", value)}}
                                        placeholder="Select expected number of viewers"
                                    >
                                        {Object.keys(VIEWER_COUNT_OPTION).map((option) => (
                                            <SelectOption key={option} value={option} label={VIEWER_COUNT_OPTION[option]} />
                                        ))}
                                    </Select>
                                    {eventRequirements["viewerCount"] === "1-249" &&
                                        <Column maxWidth={maxFormWidth}>
                                            <Alert size="medium" type="warning">
                                                <b>Livestream only supports events with at least 250 viewers.</b> Please use Amazon Chime or provide justification for using Livestream to host the event.
                                            </Alert>
                                        </Column>
                                    }
                                </Row>
                            </Column>
                            {eventRequirements["viewerCount"] === "1-249" &&
                                <Column spacing="xsmall" maxWidth={maxFormWidth}>
                                    <Text type="b300">
                                        Justification for using livestream
                                    </Text>
                                    <Textarea
                                        value={eventRequirements["viewerJustification"]}
                                        onChange={(value) => {updateEventRequirements("viewerJustification", value)}}
                                        error={eventRequirementErrors["viewerJustification"]}
                                        placeholder="e.g. Event requires pre-recorded videos to play back"
                                        rows={4}
                                        resize="none"
                                    />
                                    {eventRequirementErrors["viewerJustification"] &&
                                        <Alert size="small" type={LIVESTREAM_ALERTS.NO_VIEWER_JUSTIFICATION.type}>
                                            <Text id={LIVESTREAM_ALERTS.NO_VIEWER_JUSTIFICATION.id}>
                                                {LIVESTREAM_ALERTS.NO_VIEWER_JUSTIFICATION.value}
                                            </Text>
                                        </Alert>
                                    }
                                </Column>
                            }
                            {eventRequirements["viewerCount"] === "100000+" &&
                                <Column spacing="xsmall" maxWidth={maxFormHalfWidth}>
                                    <Text type="b300">
                                        Expected number of viewers
                                    </Text>
                                    <Input
                                        value={eventRequirements["expectedViewerCount"]}
                                        onChange={(value) => {updateEventRequirements("expectedViewerCount", value)}}
                                        error={eventRequirementErrors["expectedViewerCount"]}
                                        placeholder="Enter expected viewer count"
                                    />
                                    {eventRequirementErrors["expectedViewerCount"] &&
                                        <Alert size="small" type={LIVESTREAM_ALERTS.NO_EXPECTED_VIEWER_COUNT.type}>
                                            <Text id={LIVESTREAM_ALERTS.NO_EXPECTED_VIEWER_COUNT.id}>
                                                {LIVESTREAM_ALERTS.NO_EXPECTED_VIEWER_COUNT.value}
                                            </Text>
                                        </Alert>
                                    }
                                </Column>
                            }
                        </>
                    }
                </Column>
                <Divider size="small" />
                <Column spacingInset="medium none none none">
                    <Text type="h200">
                        Event features
                    </Text>
                    {eventRequirements["eventRequestType"] !== "EVENT_PLAYBACK" &&
                        <Column spacing="small" maxWidth={maxFormWidth}>
                            <Row spacing="200">
                                <Text type="b300">
                                    Do you have any video and/or audio files to be played during your event?
                                </Text>
                                <div
                                    onMouseEnter={() => setPlaybackHelperIcon(true)}
                                    onMouseLeave={() => !onHoverHelperIcon && setPlaybackHelperIcon(false)}
                                >
                                    <Button
                                        onClick={() => {
                                            setOnHoverHelperIcon(true);
                                            setPlaybackHelperIcon(true)
                                        }}
                                        type="icon"
                                        ref={playbackHelperIconButtonRef}
                                    >
                                        <Icon tokens={helpTokens}></Icon>
                                    </Button>
                                    <Popover
                                        alignment="center"
                                        anchorNode={playbackHelperIconButtonRef.current}
                                        closeLabel="Close"
                                        maxWidth={526}
                                        open={playbackHelperIcon}
                                        onClose={() => {
                                            setPlaybackHelperIcon(false);
                                            setOnHoverHelperIcon(false);
                                        }}
                                        position="end"
                                    >
                                        <PopoverHeader closeLabel="Close">What is a video playback?</PopoverHeader>
                                        <Column>
                                            <Column spacing="xsmall">
                                                <Text>A media file containing audio and/or video that needs to be played at any time during the Livestream.</Text>
                                            </Column>
                                            <Column spacing="xsmall">
                                                <Text type="h100">What are the requirements to include video and/or audio files for playback during my event?</Text>
                                                <Text>
                                                    All video and/or audio files need to be delivered no later than 48 business hours prior to the start of the Livestream. Files must be saved as a .mp4 or .mp3, and located within Workdocs, Drive, Box.com or Frame.io.
                                                </Text>
                                            </Column>
                                            <Text>The file or the file location must be shared with the Technician(s) assigned via the SIM ticket.</Text>
                                        </Column>
                                    </Popover>
                                </div>
                            </Row>
                            <Row wrap="down">
                                <RadioButton
                                    checked={eventRequirements["videoPlayback"] === true}
                                    value={true}
                                    onChange={(value) => {eventQualifiersClassification("videoPlayback", value)}}
                                    size="medium"
                                    name="videoPlaybackOption"
                                >
                                    Yes
                                </RadioButton>
                                <RadioButton
                                    checked={eventRequirements["videoPlayback"] === false}
                                    value={false}
                                    onChange={(value) => {eventQualifiersClassification("videoPlayback", value)}}
                                    size="medium"
                                    name="videoPlaybackOption"
                                >
                                    No
                                </RadioButton>
                            </Row>
                        </Column>
                    }
                    <Column spacing="small" maxWidth={maxFormWidth}>
                        <Text type="b300">
                            Will this event involve multiple livestream sessions running simultaneously?
                        </Text>
                        <Row wrap="down">
                            <RadioButton
                                checked={eventRequirements["multipleLivestreams"] === true}
                                value={true}
                                onChange={(value) => {
                                    updateEventRequirements("multipleLivestreams", value)
                                }}
                                size="medium"
                                name="multipleLivestreamsOption"
                            >
                                Yes
                            </RadioButton>
                            <RadioButton
                                checked={eventRequirements["multipleLivestreams"] === false}
                                value={false}
                                onChange={(value) => {
                                    updateEventRequirements("multipleLivestreams", value)
                                }}
                                size="medium"
                                name="multipleLivestreamsOption"
                            >
                                No
                            </RadioButton>
                        </Row>
                    </Column>
                    {/*TODO: will uncomment once reactions feature is enabled in prod.*/}
                    {/*<Column maxWidth={maxFormWidth} spacing="small">*/}
                    {/*    <Row spacing="200">*/}
                    {/*        <Text type="b300">*/}
                    {/*            Do you want to allow reactions?*/}
                    {/*        </Text>*/}
                    {/*        <div*/}
                    {/*            onMouseEnter={() => setReactionsHelperIcon(true)}*/}
                    {/*            onMouseLeave={() => !onHoverHelperIcon && setReactionsHelperIcon(false)}*/}
                    {/*        >*/}
                    {/*            <Button*/}
                    {/*                onClick={() => {*/}
                    {/*                    setReactionsHelperIcon(true);*/}
                    {/*                    setOnHoverHelperIcon(true);*/}
                    {/*                }}*/}
                    {/*                type="icon"*/}
                    {/*                ref={reactionsHelperIconButtonRef}*/}
                    {/*            >*/}
                    {/*                <Icon tokens={helpTokens}></Icon>*/}
                    {/*            </Button>*/}
                    {/*            <Popover*/}
                    {/*                anchorNode={reactionsHelperIconButtonRef.current}*/}
                    {/*                open={reactionsHelperIcon}*/}
                    {/*                onClose={() => {*/}
                    {/*                    setReactionsHelperIcon(false);*/}
                    {/*                    setOnHoverHelperIcon(false);*/}
                    {/*                }}*/}
                    {/*                position="end"*/}
                    {/*                alignment="center"*/}
                    {/*                maxWidth={381}*/}
                    {/*            >*/}
                    {/*                <PopoverHeader closeLabel="Close">What is the reactions feature?</PopoverHeader>*/}
                    {/*                <Column>*/}
                    {/*                    <Text>The reaction features allows viewers to share a limited set of emojis (+1, Thumbs up, Thumbs down, Love, Applause, Laughter, TY, Celebrate, Question) throughout a stream. With the event chat, this gives the organizers and presenters an additional tool to gather feedback from their viewers.</Text>*/}
                    {/*                    <Text> Learn more about the&nbsp;*/}
                    {/*                        {renderExternalLink("reactions feature", "https://w.amazon.com/bin/view/Live_Streaming_Platform/Products/Livestream/Organizers/")}*/}
                    {/*                    </Text>*/}
                    {/*                </Column>*/}
                    {/*            </Popover>*/}
                    {/*        </div>*/}
                    {/*    </Row>*/}
                    {/*    <Row wrap="down">*/}
                    {/*        <RadioButton*/}
                    {/*            checked={eventRequirements["reactions"] === true}*/}
                    {/*            value={true}*/}
                    {/*            onChange={(value) => {updateEventRequirements("reactions", value)}}*/}
                    {/*            size="medium"*/}
                    {/*            name="reactionsOption"*/}
                    {/*        >*/}
                    {/*            Yes*/}
                    {/*        </RadioButton>*/}
                    {/*        <RadioButton*/}
                    {/*            checked={eventRequirements["reactions"] === false}*/}
                    {/*            value={false}*/}
                    {/*            onChange={(value) => {updateEventRequirements("reactions", value)}}*/}
                    {/*            size="medium"*/}
                    {/*            name="reactionsOption"*/}
                    {/*        >*/}
                    {/*            No*/}
                    {/*        </RadioButton>*/}
                    {/*    </Row>*/}
                    {/*</Column>*/}
                    {(eventRequirements["eventRequestType"] === "LIVE" || eventRequirements["eventRequestType"] === "EVENT_PLAYBACK") &&
                        <>
                            <Column spacing="small" maxWidth={maxFormWidth}>
                                <Row spacing="200">
                                    <Text type="b300">
                                        Which platform(s) do you want it streamed to?
                                    </Text>
                                    <div
                                        onMouseEnter={() => setStreamPlatformsHelperIcon(true)}
                                        onMouseLeave={() => !onHoverHelperIcon && setStreamPlatformsHelperIcon(false)}
                                    >
                                        <Button
                                            onClick={() => {
                                                setStreamPlatformsHelperIcon(true);
                                                setOnHoverHelperIcon(true);
                                            }}
                                            type="icon"
                                            ref={streamPlatformsHelperIconButtonRef}
                                        >
                                            <Icon tokens={helpTokens}></Icon>
                                        </Button>
                                        <Popover
                                            anchorNode={streamPlatformsHelperIconButtonRef.current}
                                            open={streamPlatformsHelperIcon}
                                            onClose={() => {
                                                setStreamPlatformsHelperIcon(false);
                                                setOnHoverHelperIcon(false);
                                            }}
                                            position="end"
                                            alignment="center"
                                            maxWidth={550}
                                        >
                                            <PopoverHeader closeLabel="Close">Are there additional requirements to stream to public sites?</PopoverHeader>
                                            <Column>
                                                <Text>Yes, we request you to fill out a&nbsp;
                                                    {renderExternalLink("Public Livestreaming Request Form", "https://drive.corp.amazon.com/view/Livestreaming/Public%20Livestreaming%20Request%20Form.pdf?download=true")}
                                                     , which requires getting proper PR and Legal approval for your request.
                                                </Text>
                                                <Text>
                                                    If your request is for an <b>AWS Livestream</b> please complete the following&nbsp;
                                                    {renderExternalLink("additional steps", "https://w.amazon.com/bin/view/AWS_Live_Video/")}
                                                </Text>
                                                <Text>For more information/steps, please visit&nbsp;
                                                    {renderExternalLink("Livestreaming to public sities", "https://it.amazon.com/help/articles/livestreaming-to-public-sites")}
                                                </Text>
                                            </Column>
                                        </Popover>
                                    </div>
                                </Row>
                                <Column spacing="small">
                                    {Object.keys(EVENT_PLATFORM).map((option) => (
                                        <Checkbox
                                            key={option}
                                            checked={eventRequirements["platforms"].includes(option)}
                                            value={option}
                                            onChange={() => {toggleEventRequirementArrayOption("platforms", option)}}
                                        >
                                            {EVENT_PLATFORM[option].AVAILABILITY}: {EVENT_PLATFORM[option].LABEL}
                                        </Checkbox>
                                    ))}
                                </Column>
                                {eventRequirementErrors["platforms"] &&
                                    <Alert size="small" type={LIVESTREAM_ALERTS.NO_PLATFORMS_SELECTED.type}>
                                        <Text id={LIVESTREAM_ALERTS.NO_PLATFORMS_SELECTED.id}>
                                            {LIVESTREAM_ALERTS.NO_PLATFORMS_SELECTED.value}
                                        </Text>
                                    </Alert>
                                }
                            </Column>
                            {eventRequirements["platforms"].includes("HUBBLE") &&
                                <Column spacing="small" maxWidth={maxFormWidth}>
                                    <Column spacing="small">
                                        <Row spacing="200">
                                            <Text type="b300">
                                                Do you want to allow viewer chat?
                                            </Text>
                                            <div
                                                onMouseEnter={() => setChatHelperIcon(true)}
                                                onMouseLeave={() => !onHoverHelperIcon && setChatHelperIcon(false)}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        setChatHelperIcon(true);
                                                        setOnHoverHelperIcon(true);
                                                    }}
                                                    type="icon"
                                                    ref={chatHelperIconButtonRef}
                                                >
                                                    <Icon tokens={helpTokens}></Icon>
                                                </Button>
                                                <Popover
                                                    anchorNode={chatHelperIconButtonRef.current}
                                                    open={chatHelperIcon}
                                                    onClose={() => {
                                                        setChatHelperIcon(false);
                                                        setOnHoverHelperIcon(false);
                                                    }}
                                                    position="end"
                                                    alignment="center"
                                                    maxWidth={460}
                                                >
                                                    <PopoverHeader closeLabel="Close">What are the options for event chat?</PopoverHeader>
                                                    <Column>
                                                        <Text tag="ul">
                                                            <li><b>Native chat: </b>the built-in chat service for Meetings Livestream</li>
                                                            <li><b>Slack: </b>your team can utilize an existing Slack channel for this event</li>
                                                        </Text>
                                                        <Text>Learn the&nbsp;
                                                            {renderExternalLink("differences between using native chat or Slack", "https://w.amazon.com/bin/view/Live_Streaming_Platform/Products/Livestream/Organizers/")}</Text>
                                                    </Column>
                                                </Popover>
                                            </div>
                                        </Row>
                                        <Row wrap="down">
                                            <RadioButton
                                                checked={eventRequirements["allowChat"] === true}
                                                value={true}
                                                onChange={(value) => {
                                                    updateEventRequirements("allowChat", value)
                                                }}
                                                size="medium"
                                                name="allowChatOption"
                                            >
                                                Yes
                                            </RadioButton>
                                            <RadioButton
                                                checked={eventRequirements["allowChat"] === false}
                                                value={false}
                                                onChange={(value) => {
                                                    updateEventRequirements("allowChat", value)
                                                }}
                                                size="medium"
                                                name="allowChatOption"
                                            >
                                                No
                                            </RadioButton>
                                        </Row>
                                    </Column>
                                    {eventRequirements["allowChat"] &&
                                        <Column spacing="small" spacingInset="none large">
                                            <Row>
                                                <RadioButton
                                                    checked={eventRequirements["slackEnabled"] === false}
                                                    onChange={(value) => {updateEventRequirements("slackEnabled", value)}}
                                                    value={false}
                                                >
                                                    Native chat
                                                </RadioButton>
                                                <RadioButton
                                                    checked={eventRequirements["slackEnabled"] === true}
                                                    onChange={(value) => {updateEventRequirements("slackEnabled", value)}}
                                                    value={true}
                                                >
                                                    Slack
                                                </RadioButton>
                                                <div
                                                    onMouseEnter={() => setSlackHelperIcon(true)}
                                                    onMouseLeave={() => !onHoverHelperIcon && setSlackHelperIcon(false)}
                                                >
                                                    <Button
                                                        onClick={() => {
                                                            setSlackHelperIcon(true);
                                                            setOnHoverHelperIcon(true);
                                                        }}
                                                        type="icon"
                                                        ref={slackHelperIconButtonRef}
                                                    >
                                                        <Icon tokens={helpTokens}></Icon>
                                                    </Button>
                                                    <Popover
                                                        anchorNode={slackHelperIconButtonRef.current}
                                                        open={slackHelperIcon}
                                                        onClose={() => {
                                                            setSlackHelperIcon(false);
                                                            setOnHoverHelperIcon(false);
                                                        }}
                                                        position="end"
                                                        alignment="center"
                                                        maxWidth={496}
                                                    >
                                                        <PopoverHeader closeLabel="Close">How do I determine the workspace name for my channel?</PopoverHeader>
                                                        <Column spacing="300">
                                                            <Text>To get the workspace name:</Text>
                                                            <Text tag="ol">
                                                                <li>On your Slack app, right-click on your channel name</li>
                                                                <li>Select Copy / Copy link</li>
                                                                <li>
                                                                    <Text>You will find the workspace name is in the copied link e.g.</Text>
                                                                    <Text>link = “https://amzn-aws.slack.com/archives/xxx.”,</Text>
                                                                    <Text>workspace name = ”AWS”</Text>
                                                                </li>
                                                            </Text>
                                                        </Column>
                                                    </Popover>
                                                </div>
                                            </Row>
                                            {eventRequirements["slackEnabled"] &&
                                                <React.Fragment>
                                                    <Row alignmentVertical="top" widths="fill" width="100%">
                                                        <Column alignmentVertical="top" spacing="200">
                                                            <Text htmlFor="select-workspace" tag="label" type="b300">Workspace name *</Text>
                                                            <Select
                                                                error={eventRequirementErrors["slackWorkspace"]}
                                                                errorMessage={eventRequirementErrors["slackWorkspace"] ? LIVESTREAM_ALERTS.NO_SLACK_WORKSPACE.value : ""}
                                                                id="select-workspace"
                                                                onChange={onChangeWorkspace}
                                                                placeholder="Select a Workspace"
                                                                popoverMaxHeight={150}
                                                                value={eventRequirements["slackWorkspace"]}
                                                                width={360}
                                                            >
                                                                {workspaces.map((workspace) => (
                                                                    <SelectOption value={workspace.url} label={workspace.label} key={workspace.url}/>
                                                                ))}
                                                            </Select>
                                                        </Column>
                                                        <Column alignmentVertical="top" spacing="200">
                                                            <Text htmlFor="slack-channel" tag="label" type="b300">Slack channel name
                                                                *</Text>
                                                            <Row alignmentVertical="top" spacing="200">
                                                                <Input
                                                                    error={eventRequirementErrors["slackChannelName"]}
                                                                    errorMessage={SlackChannelErrorMessage()}
                                                                    id="slack-channel"
                                                                    onChange={onChangeSlackName}
                                                                    placeholder="Example: aws-horticulture"
                                                                    value={eventRequirements["slackChannelName"]}
                                                                    width={392}
                                                                />
                                                                <Button onClick={onClickSlackCopy} type="icon">
                                                                    <Icon tokens={copyTokens}/>
                                                                </Button>
                                                            </Row>
                                                        </Column>
                                                    </Row>
                                                    <Row>
                                                        <Text type="b200">
                                                            <Link
                                                                href={eventRequirements["slackChannel"]}
                                                                onClick={onClickSlackOpen}
                                                                type="secondary"
                                                            >
                                                                Check link{" "}
                                                                <Icon tokens={exportSmallTokens}/>
                                                            </Link>
                                                            &nbsp;to verify channel name
                                                        </Text>
                                                    </Row>
                                                </React.Fragment>
                                            }
                                        </Column>
                                    }
                                </Column>
                            }
                        </>
                    }
                    <Text type="h200">
                        Attendee's needs
                    </Text>
                    <Column spacing="small" maxWidth={maxFormWidth}>
                        <Row spacing="200">
                            <Text type="b300">
                                Are any of the presenters L10/L10+ and/or VIP?
                            </Text>
                            <div
                                onMouseEnter={() => {setVipRolesHelperIcon(true)}}
                                onMouseLeave={() => {!onHoverHelperIcon && setVipRolesHelperIcon(false)}}
                            >
                                <Button
                                    onClick={() => {
                                        setOnHoverHelperIcon(true);
                                        setVipRolesHelperIcon(true);
                                    }}
                                    type="icon"
                                    ref={vipRolesHelperIconButtonRef}
                                >
                                    <Icon tokens={helpTokens}></Icon>
                                </Button>
                                <Popover
                                    anchorNode={vipRolesHelperIconButtonRef.current}
                                    open={vipRolesHelperIcon}
                                    onClose={() => {
                                        setOnHoverHelperIcon(false);
                                        setVipRolesHelperIcon(false);
                                    }}
                                    position="end"
                                    alignment="center"
                                    maxWidth={446}
                                >
                                    <PopoverHeader closeLabel="Close">What are VIP roles?</PopoverHeader>
                                    <Column>
                                        <Text>VIP's are External CEO's or SVP's, heads of state or government, high-ranking politicians, or a notable celebrity that requires special consideration, security, etc.</Text>
                                    </Column>
                                </Popover>
                            </div>
                        </Row>
                        <Row wrap="down">
                            <RadioButton
                                checked={eventRequirements["isAnyPresenterL10"] === true}
                                value={true}
                                onChange={(value) => {eventQualifiersClassification("isAnyPresenterL10", value)}}
                                size="medium"
                                name="isAnyPresenterL10Option"
                            >
                                Yes
                            </RadioButton>
                            <RadioButton
                                checked={eventRequirements["isAnyPresenterL10"] === false}
                                value={false}
                                onChange={(value) => {eventQualifiersClassification("isAnyPresenterL10", value)}}
                                size="medium"
                                name="isAnyPresenterL10Option"
                            >
                                No
                            </RadioButton>
                        </Row>
                    </Column>
                    {eventRequirements["isAnyPresenterL10"] &&
                        <Column spacing="small" spacingInset="none large">
                            <Row spacing="200">
                                <Text type="b300">
                                    Closed captioning options
                                </Text>
                                <div
                                    onMouseEnter={() => {setCcOptionsHelperIcon(true)}}
                                    onMouseLeave={() => {!onHoverHelperIcon && setCcOptionsHelperIcon(false)}}
                                >
                                    <Button
                                        onClick={() => {
                                            setOnHoverHelperIcon(true);
                                            setCcOptionsHelperIcon(true);
                                        }}
                                        type="icon"
                                        ref={ccOptionsHelperIconButtonRef}
                                    >
                                        <Icon tokens={helpTokens}></Icon>
                                    </Button>
                                    <Popover
                                        anchorNode={ccOptionsHelperIconButtonRef.current}
                                        open={ccOptionsHelperIcon}
                                        onClose={() => {
                                            setOnHoverHelperIcon(false);
                                            setCcOptionsHelperIcon(false);
                                        }}
                                        position="end"
                                        alignment="center"
                                        maxWidth={446}
                                    >
                                        <PopoverHeader closeLabel="Close">What are the closed-captioning options?</PopoverHeader>
                                        <Column>
                                            <Text><b>Display a disclaimer - </b>we will notify the viewers via the description box of your Livestream that there could potentially be offensive language. Most customers choose this option.</Text>
                                            <Text><b>Stenographer - </b>Your team can hire a stenographer from one of Amazon’s approved&nbsp;
                                                {renderExternalLink("global vendors.", "https://w.amazon.com/bin/view/DHHCX/Captioningservices/CART")}&nbsp;
                                                During your event, the approved vendor will work with our Technician(s) to provide live open captions. Please be aware that a <b>cost will be charged to your cost center</b> for this service.
                                                For more information, please visit&nbsp;
                                                {renderExternalLink("Amazon Cart Services.", "https://w.amazon.com/bin/view/DHHCX/Captioningservices/CART")}
                                            </Text>
                                        </Column>
                                    </Popover>
                                </div>
                            </Row>
                            <Column spacing="small">
                                {Object.keys(CLOSED_CAPTIONING_OPTIONS).map((option) => (
                                    <RadioButton
                                        checked={option === eventRequirements["ccOptions"]}
                                        value={option}
                                        onChange={(option) => {
                                            updateEventRequirements("ccOptions", option)
                                        }}
                                        size="medium"
                                        name="isAnyPresenterL10Option"
                                    >
                                        {CLOSED_CAPTIONING_OPTIONS[option]}
                                    </RadioButton>
                                ))}
                            </Column>
                        </Column>
                    }
                    <Column maxWidth={maxFormWidth} spacing="small">
                        <Row spacing="200">
                            <Text type="b300">
                                Restrict my stream to specific group(s)?
                            </Text>
                            <div
                                onMouseEnter={() => setRestrictionsHelperIcon(true)}
                                onMouseLeave={() => !onHoverHelperIcon && setRestrictionsHelperIcon(false)}
                            >
                                <Button
                                    onClick={() => {
                                        setRestrictionsHelperIcon(true);
                                        setOnHoverHelperIcon(true);
                                    }}
                                    type="icon"
                                    ref={restrictionsHelperIconButtonRef}
                                >
                                    <Icon tokens={helpTokens}></Icon>
                                </Button>
                                <Popover
                                    anchorNode={restrictionsHelperIconButtonRef.current}
                                    open={restrictionsHelperIcon}
                                    onClose={() => {
                                        setRestrictionsHelperIcon(false);
                                        setOnHoverHelperIcon(false);
                                    }}
                                    position="end"
                                    alignment="center"
                                    maxWidth={530}
                                >
                                    <PopoverHeader closeLabel="Close">How do I determine which permission groups I have access to?</PopoverHeader>
                                    <Column>
                                        <Text>To check, click your&nbsp;
                                            {renderExternalLink("Account Management", "https://permissions.amazon.com/user.mhtml")}&nbsp;
                                            user site.
                                        </Text>
                                        <Text>You can also learn more about&nbsp;
                                            {renderExternalLink("permission groups.", "https://w.amazon.com/bin/view/A4/Support/CommonIssues")}
                                        </Text>
                                    </Column>
                                </Popover>
                            </div>
                        </Row>
                        <Row wrap="down">
                            <RadioButton
                                checked={eventRequirements["restricted"] === true}
                                value={true}
                                onChange={(value) => {updateEventRequirements("restricted", value)}}
                                size="medium"
                                name="restrictedOption"
                            >
                                Yes
                            </RadioButton>
                            <RadioButton
                                checked={eventRequirements["restricted"] === false}
                                value={false}
                                onChange={(value) => {updateEventRequirements("restricted", value)}}
                                size="medium"
                                name="restrictedOption"
                            >
                                No
                            </RadioButton>
                        </Row>
                    </Column>
                    {eventRequirements["restricted"] &&
                        <Column maxWidth={maxFormWidth} spacing="xsmall" spacingInset="none large">
                            <PermissionsInputGroup
                                error={eventRequirementErrors["restrictedGroups"]}
                                onChange={(value) => {onChangeEventRequirement("restrictedGroups", value)}}
                                value={eventRequirements["restrictedGroups"]}
                            />
                            {eventRequirementErrors["restrictedGroups"] &&
                                <Alert size="small" type={LIVESTREAM_ALERTS.NO_RESTRICTED_GROUPS_ADDED.type}>
                                    <Text id={LIVESTREAM_ALERTS.NO_RESTRICTED_GROUPS_ADDED.id}>
                                        {LIVESTREAM_ALERTS.NO_RESTRICTED_GROUPS_ADDED.value}
                                    </Text>
                                </Alert>
                            }
                            {showPermissionTag("restrictedGroups")}
                        </Column>
                    }
                    {eventRequirements["eventRequestType"] !== "EVENT_PLAYBACK" &&
                        <Column spacing="small" maxWidth={maxFormWidth}>
                            <Text type="b300">
                                Event location
                            </Text>
                            <Row wrap="down">
                                {Object.keys(EVENT_LOCATION).map((option) => (
                                    <RadioButton
                                        key={option}
                                        checked={eventRequirements["eventLocation"] === option}
                                        value={option}
                                        onChange={(value) => {eventQualifiersClassification("eventLocation", value)}}
                                        size="medium"
                                        name="eventLocationOption"
                                    >
                                        {EVENT_LOCATION[option]}
                                    </RadioButton>
                                ))}
                            </Row>
                        </Column>
                    }
                    <Column spacing="small" maxWidth={maxFormWidth}>
                        <Text type="b300">
                            Will a large portion of your intended audience be located across Fulfillment centers?
                        </Text>
                        <Row wrap="down">
                            <RadioButton
                                checked={eventRequirements["isAudienceInFCs"] === true}
                                value={true}
                                onChange={(value) => {eventQualifiersClassification("isAudienceInFCs", value)}}
                                size="medium"
                                name="isAudienceInFCsOption"
                            >
                                Yes
                            </RadioButton>
                            <RadioButton
                                checked={eventRequirements["isAudienceInFCs"] === false}
                                value={false}
                                onChange={(value) => {eventQualifiersClassification("isAudienceInFCs", value)}}
                                size="medium"
                                name="isAudienceInFCsOption"
                            >
                                No
                            </RadioButton>
                        </Row>
                    </Column>
                    <Column spacing="small">
                        <Row spacing="200">
                            <Text type="b300">
                                Do you plan to book sign language services for this Livestream event?
                            </Text>
                            <div
                                onMouseEnter={() => setAslInterpreterHelperIcon(true)}
                                onMouseLeave={() => !onHoverHelperIcon && setAslInterpreterHelperIcon(false)}
                            >
                                <Button
                                    onClick={() => {
                                        setAslInterpreterHelperIcon(true);
                                        setOnHoverHelperIcon(true);
                                    }}
                                    type="icon"
                                    ref={aslInterpreterHelperIconButtonRef}
                                >
                                    <Icon tokens={helpTokens}></Icon>
                                </Button>
                                <Popover
                                    anchorNode={aslInterpreterHelperIconButtonRef.current}
                                    open={aslInterpreterHelperIcon}
                                    onClose={() => {
                                        setAslInterpreterHelperIcon(false);
                                        setOnHoverHelperIcon(false);
                                    }}
                                    position="end"
                                    alignment="center"
                                    maxWidth={566}
                                >
                                    <PopoverHeader closeLabel="Close">How do I book Sign Language Interpreting Services for this event?</PopoverHeader>
                                    <Column>
                                        <Text> Please reach out to the&nbsp;
                                            {renderExternalLink("Sign Language Interpreting Services team", "https://w.amazon.com/bin/view/DHH/Sign-Language-Home")}&nbsp;
                                            for further information on booking.
                                        </Text>
                                    </Column>
                                </Popover>
                            </div>
                        </Row>
                        <Row wrap="down">
                            {Object.keys(SIGN_LANGUAGE_INTERPRETER_OPTION).map((option) => (
                                <RadioButton
                                    key={option}
                                    checked={eventRequirements["signLanguageInterpreter"] === option}
                                    value={option}
                                    onChange={(value) => {eventQualifiersClassification("signLanguageInterpreter", value)}}
                                    size="medium"
                                    name="signLanguageInterpreterOption"
                                >
                                    {SIGN_LANGUAGE_INTERPRETER_OPTION[option]}
                                </RadioButton>
                            ))}
                        </Row>
                    </Column>
                    <Column spacing="small" maxWidth={maxFormWidth}>
                        <Row spacing="200">
                            <Text type="b300">
                                Will your presentation be in languages other than English?
                            </Text>
                            <div
                                onMouseEnter={() => {setPresentationLanguageHelperIcon(true)}}
                                onMouseLeave={() => {!onHoverHelperIcon && setPresentationLanguageHelperIcon(false)}}
                            >
                                <Button
                                    onClick={() => {
                                        setOnHoverHelperIcon(true);
                                        setPresentationLanguageHelperIcon(true);
                                    }}
                                    type="icon"
                                    ref={presentationLanguageHelperIconButtonRef}
                                >
                                    <Icon tokens={helpTokens}></Icon>
                                </Button>
                                <Popover
                                    anchorNode={presentationLanguageHelperIconButtonRef.current}
                                    open={presentationLanguageHelperIcon}
                                    onClose={() => {
                                        setOnHoverHelperIcon(false);
                                        setPresentationLanguageHelperIcon(false);
                                    }}
                                    position="end"
                                    alignment="center"
                                    maxWidth={336}
                                >
                                    <PopoverHeader closeLabel="Close">Do you also support closed captions in languages other than English?</PopoverHeader>
                                    <Column>
                                        <Text>We currently only support closed captions in English.</Text>
                                    </Column>
                                </Popover>
                            </div>
                        </Row>
                        <Row wrap="down">
                            <RadioButton
                                checked={eventRequirements["nonEnglish"] === true}
                                value={true}
                                onChange={(value) => {updateEventRequirements("nonEnglish", value)}}
                                size="medium"
                                name="nonEnglishOption"
                            >
                                Yes
                            </RadioButton>
                            <RadioButton
                                checked={eventRequirements["nonEnglish"] === false}
                                value={false}
                                onChange={(value) => {updateEventRequirements("nonEnglish", value)}}
                                size="medium"
                                name="nonEnglishOption"
                            >
                                No
                            </RadioButton>
                        </Row>
                    </Column>

                    <Text type="h200">
                        Post-event requirements
                    </Text>

                    <Column spacing="small" maxWidth={maxFormWidth}>
                        <Row spacing="200">
                            <Text type="b300">
                                Do you want your recording after the stream?
                            </Text>
                            <div
                                onMouseEnter={() => setPostEventDeliveryHelperIcon(true)}
                                onMouseLeave={() => !onHoverHelperIcon && setPostEventDeliveryHelperIcon(false)}
                            >
                                <Button
                                    onClick={() => {
                                        setPostEventDeliveryHelperIcon(true);
                                        setOnHoverHelperIcon(true);
                                    }}
                                    type="icon"
                                    ref={postEventDeliveryHelperIconButtonRef}
                                >
                                    <Icon tokens={helpTokens}></Icon>
                                </Button>
                                <Popover
                                    anchorNode={postEventDeliveryHelperIconButtonRef.current}
                                    open={postEventDeliveryHelperIcon}
                                    onClose={() => {
                                        setPostEventDeliveryHelperIcon(false);
                                        setOnHoverHelperIcon(false);
                                    }}
                                    position="end"
                                    alignment="center"
                                    maxWidth={560}
                                >
                                    <PopoverHeader closeLabel="Close">What can I expect if I choose to have my Livestream recorded?</PopoverHeader>
                                    <Column>
                                        <Text>Our Livestream Technician(s) will deliver a file containing a recording of your event within 48 hours of the end of your event.</Text>
                                        <Column spacing="xsmall">
                                            <Text type="h100">Do you provide post-production editing?</Text>
                                            <Text>Livestream Team will provide basic top and tailing edits to your recording.</Text>
                                        </Column>
                                    </Column>
                                </Popover>
                            </div>
                        </Row>
                        <Row wrap="down">
                            {Object.keys(RECORDING_TYPE).map((option) => (
                                <RadioButton
                                    key={option}
                                    checked={eventRequirements["recordingType"] === option}
                                    value={option}
                                    onChange={(value) => {updateMultipleEventRequirements([
                                        {saveRecording: value !== "NONE"},
                                        {recordingType: value}
                                    ])}}
                                    size="medium"
                                    name="recordingTypeOption"
                                >
                                    {RECORDING_TYPE[option]}
                                </RadioButton>
                            ))}
                        </Row>
                    </Column>
                    {eventRequirements["recordingType"] === "BROADCAST" &&
                        <Column spacing="medium" spacingInset="none large" maxWidth={maxFormWidth}>
                            <Column spacing="xsmall">
                                <Row spacing="200">
                                    <Text type="b300">
                                        Broadcast channel URL
                                    </Text>
                                    <div
                                        onMouseEnter={() => setBroadcastChannelsHelperIcon(true)}
                                        onMouseLeave={() => !onHoverHelperIcon && setBroadcastChannelsHelperIcon(false)}
                                    >
                                        <Button
                                            onClick={() => {
                                                setBroadcastChannelsHelperIcon(true);
                                                setOnHoverHelperIcon(true);
                                            }}
                                            type="icon"
                                            ref={broadcastChannelsHelperIconButtonRef}
                                        >
                                            <Icon tokens={helpTokens}></Icon>
                                        </Button>
                                        <Popover
                                            anchorNode={broadcastChannelsHelperIconButtonRef.current}
                                            open={broadcastChannelsHelperIcon}
                                            onClose={() => {
                                                setBroadcastChannelsHelperIcon(false);
                                                setOnHoverHelperIcon(false);
                                            }}
                                            position="end"
                                            alignment="center"
                                            maxWidth={460}
                                        >
                                            <PopoverHeader closeLabel="Close">What are Broadcast channels?</PopoverHeader>
                                            <Column>
                                                <Text>Broadcast is an Amazon-internal video-on-demand service. Broadcast channels are user or team channels that contain the videos and playlists they own.</Text>
                                                <Text> Learn more about&nbsp;
                                                    {renderExternalLink("Broadcast channels.", "https://w.amazon.com/bin/view/Broadcast/FAQs/Channels")}
                                                </Text>
                                            </Column>
                                        </Popover>
                                    </div>
                                </Row>
                                <Input
                                    value={eventRequirements["previousBroadcastUrl"]}
                                    onChange={(value) => {onChangeEventRequirement("previousBroadcastUrl", value)}}
                                    error={eventRequirementErrors["previousBroadcastUrl"]}
                                    placeholder="Example: 1234"
                                    prefix="https://broadcast.amazon.com/channels/"
                                    type="number"

                                />
                                {eventRequirementErrors["previousBroadcastUrl"] &&
                                    <Alert size="small" type={LIVESTREAM_ALERTS.NO_PREVIOUS_BROADCAST_URL.type}>
                                        <Text id={LIVESTREAM_ALERTS.NO_PREVIOUS_BROADCAST_URL.id}>
                                            {LIVESTREAM_ALERTS.NO_PREVIOUS_BROADCAST_URL.value}
                                        </Text>
                                    </Alert>
                                }
                                <Row>
                                    <Text type="b200">
                                        <Link
                                            href={`https://broadcast.amazon.com/channels/${eventRequirements["previousBroadcastUrl"]}`}
                                            onClick={onClickBroadcastUrlOpen}
                                            type="secondary"
                                        >
                                            Check link{" "}
                                            <Icon tokens={exportSmallTokens}/>
                                        </Link>
                                        &nbsp;to verify channel URL
                                    </Text>
                                </Row>

                            </Column>
                            <Column spacing="xsmall">
                                <Row spacing="200">
                                    <Text type="b300">
                                        Do we have your permission to upload to Broadcast?
                                    </Text>
                                    <div
                                        onMouseEnter={() => setBroadcastUploadHelperIcon(true)}
                                        onMouseLeave={() => {!onHoverHelperIcon && setBroadcastUploadHelperIcon(false)}}
                                    >
                                        <Button
                                            onClick={() => {setBroadcastUploadHelperIcon(true); setOnHoverHelperIcon(true)}}
                                            type="icon"
                                            ref={broadcastUploadHelperIconButtonRef}
                                        >
                                            <Icon tokens={helpTokens}></Icon>
                                        </Button>
                                        <Popover
                                            anchorNode={broadcastUploadHelperIconButtonRef.current}
                                            open={broadcastUploadHelperIcon}
                                            onClose={() => {
                                                setBroadcastUploadHelperIcon(false);
                                                setOnHoverHelperIcon(false);
                                            }}
                                            position="end"
                                            alignment="center"
                                            maxWidth={480}
                                        >
                                            <PopoverHeader closeLabel="Close">Do we have your permission to upload to Broadcast?</PopoverHeader>
                                            <Column>
                                                <Text>Selecting Yes means you are granting permission to the Livestream team to upload your recording onto the provided Broadcast channel URL on your behalf. You acknowledge that as the video owner, you are responsible for the content featured in this recording, and it is not the responsibility of the Livestream team to verify this video's compliance with Amazon's security standards.</Text>
                                            </Column>
                                        </Popover>
                                    </div>
                                </Row>

                                <Row wrap="down">
                                    <RadioButton
                                        checked={eventRequirements["broadcastPermission"] === true}
                                        value={true}
                                        onChange={(value) => {updateEventRequirements("broadcastPermission", value)}}
                                        size="medium"
                                        name="broadcastPermission"
                                    >
                                        Yes
                                    </RadioButton>
                                    <RadioButton
                                        checked={eventRequirements["broadcastPermission"] === false}
                                        value={false}
                                        onChange={(value) => {updateEventRequirements("broadcastPermission", value)}}
                                        size="medium"
                                        name="broadcastPermission"
                                    >
                                        No
                                    </RadioButton>
                                </Row>

                            </Column>
                        </Column>
                    }
                    <Column spacing="small" spacingInset="small none none none" maxWidth={maxFormWidth}>
                        <Text type="h200">
                            Additional details
                        </Text>
                    </Column>
                    <Column spacing="xsmall" maxWidth={maxFormWidth}>
                        <Row spacing="200">
                            <Text type="b300">
                                Primary point(s) of contact
                            </Text>
                            <div
                                onMouseEnter={() => setPocHelperIcon(true)}
                                onMouseLeave={() => !onHoverHelperIcon && setPocHelperIcon(false)}
                            >
                                <Button
                                    onClick={() => {
                                        setPocHelperIcon(true);
                                        setOnHoverHelperIcon(true);
                                    }}
                                    type="icon"
                                    ref={pocHelperIconButtonRef}
                                >
                                    <Icon tokens={helpTokens}></Icon>
                                </Button>
                                <Popover
                                    anchorNode={pocHelperIconButtonRef.current}
                                    open={pocHelperIcon}
                                    onClose={() => {
                                        setPocHelperIcon(false);
                                        setOnHoverHelperIcon(false);
                                    }}
                                    position="end"
                                    alignment="center"
                                    maxWidth={454}
                                >
                                    <PopoverHeader closeLabel="Close">What is the role of the point of contact?</PopoverHeader>
                                    <Column>
                                        <Text>The point of contact is automatically added as a viewer to the event and will receive notifications on event communications/updates occurring in SIM.</Text>
                                        <Text>They can also be contacted by the Livestream team if the organizer is not available.</Text>
                                    </Column>
                                </Popover>
                            </div>
                        </Row>
                        <AttendeeSearchBar
                            placeholder="Enter name or alias"
                            peopleSuggestions={props.pointOfContactSuggestions}
                            width="100%"
                            popoverHeight={ATTENDEE_SEARCH_BAR_SMALL_POPOVER_HEIGHT}
                            onAddAttendee={onAddPointOfContact}
                            onSuggestionSubmit={(pointOfContact) => {
                                toggleEventRequirementArrayOption("pointsOfContact", pointOfContact);
                                setAddedPointsOfContact([pointOfContact].concat(addedPointsOfContact));
                            }}
                            onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                            onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                            onGetGroupSuggestions={props.onGetGroupSuggestions}
                            onClearGroupSuggestions={props.onClearGroupSuggestions}
                        />
                        {eventRequirementErrors["pointsOfContact"] &&
                            <Alert size="small" type={LIVESTREAM_ALERTS.NO_POINT_OF_CONTACTS_ADDED.type}>
                                <Text id={LIVESTREAM_ALERTS.NO_POINT_OF_CONTACTS_ADDED.id}>
                                    {LIVESTREAM_ALERTS.NO_POINT_OF_CONTACTS_ADDED.value}
                                </Text>
                            </Alert>
                        }
                        {showAttendeeTags("pointsOfContact")}
                    </Column>
                    <Column spacing="small" maxWidth={maxFormWidth}>
                        <Text type="b300">
                            Best contact channel
                        </Text>
                        <Row wrap="down">
                            {Object.keys(CONTACT_CHANNEL).map((option) => (
                                <Checkbox
                                    key={option}
                                    checked={eventRequirements["contactChannels"].includes(option)}
                                    value={option}
                                    onChange={() => {toggleEventRequirementArrayOption("contactChannels", option)}}
                                >
                                    {CONTACT_CHANNEL[option]}
                                </Checkbox>
                            ))}
                        </Row>
                        {eventRequirementErrors["contactChannels"] &&
                            <Alert size="small" type={LIVESTREAM_ALERTS.NO_CONTACT_CHANNELS_SELECTED.type}>
                                <Text id={LIVESTREAM_ALERTS.NO_CONTACT_CHANNELS_SELECTED.id}>
                                    {LIVESTREAM_ALERTS.NO_CONTACT_CHANNELS_SELECTED.value}
                                </Text>
                            </Alert>
                        }
                    </Column>
                    {eventRequirements["contactChannels"].includes("PHONE") &&
                        <Column spacing="xsmall" maxWidth={maxFormWidth}>
                            <Text type="b300">
                                Phone #
                            </Text>
                            <Input
                                value={eventRequirements["phoneNumber"]}
                                onChange={(value) => {updateEventRequirements("phoneNumber", value)}}
                                error={eventRequirementErrors["phoneNumber"]}
                            />
                            {eventRequirementErrors["phoneNumber"] &&
                                <Alert size="small" type={LIVESTREAM_ALERTS.NO_PHONE_NUMBER.type}>
                                    <Text id={LIVESTREAM_ALERTS.NO_PHONE_NUMBER.id}>
                                        {LIVESTREAM_ALERTS.NO_PHONE_NUMBER.value}
                                    </Text>
                                </Alert>
                            }
                        </Column>
                    }
                    <Column spacing="xsmall" maxWidth={maxFormWidth}>
                        <Text type="b300">
                            Additional notes (optional)
                        </Text>
                        <Textarea
                            value={eventRequirements["notes"]}
                            onChange={(value) => {updateEventRequirements("notes", value)}}
                            placeholder="Enter notes"
                            rows={4}
                            resize="none"
                        />
                        <Text color="secondary" type="b200">
                            Outline of your estimated event agenda, length of your event e.g. multiple days, etc
                        </Text>
                    </Column>
                </Column>
            </Column>
        </Column>
    );
};

export default StepEventRequirements;
