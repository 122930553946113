import CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import CognitoIdentityServiceProvider from "aws-sdk/clients/cognitoidentityserviceprovider";
import { call } from "redux-saga/effects";

import { getStageConfig } from "../stageConfig/stage-config-utils";

export const makeClient = () => {
    return new CognitoIdentity({
        region: "us-east-1"
    });
};

const invokeClient = (client, operation, params) => {
    return new Promise((resolve, reject) => {
        client[operation](params, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
};

const makeCognitoUserPoolClient = () => {
    return new CognitoIdentityServiceProvider({
        region: "us-east-1"
    });
};

const invokeCognitoUserPoolClient = (client, operation, params) => {
    return new Promise((resolve, reject) => {
        client.initiateAuth(params, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
}

function* getId(idToken) {
    const stageConfig = yield getStageConfig();
    const params = {
        IdentityPoolId: stageConfig.distributionConfig.IdentityPoolId,
        AccountId: stageConfig.distributionConfig.AccountId,
        Logins: {[stageConfig.distributionConfig.IdentityProviderName]: idToken}
    };
    const operation = "getId";
    const client = makeClient();
    return yield call(invokeClient, client, operation, params);
}

function* getCredentialsForIdentity(idToken, identityId) {
    const stageConfig = yield getStageConfig();
    const params = {
        IdentityId: identityId,
        Logins: {[stageConfig.distributionConfig.IdentityProviderName]: idToken}
    };
    const operation = "getCredentialsForIdentity";
    const client = makeClient();
    return yield call(invokeClient, client, operation, params);
}

function* refreshTokens(refreshToken, appClientId) {
    const client = makeCognitoUserPoolClient();
    const params = {
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        AuthParameters: {
            REFRESH_TOKEN: refreshToken
        },
        ClientId: appClientId,
    };
    const operation = "initiateAuth";
    const response = yield call(invokeCognitoUserPoolClient, client, operation, params);

    // Refresh CSRF token
    window.fetch(process.env.PUBLIC_URL + "/web-config.json");

    return response;
}

const CognitoClient = {
    getId,
    getCredentialsForIdentity,
    refreshTokens
};

export default CognitoClient;