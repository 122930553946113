import DOMPurify from "dompurify";

import { creationInfoTemplate } from "./creation-info";
import { selfServiceInfoTemplate } from "./self-service-info";
import { livestreamPrepInfoTemplate } from "./livestream-prep-info";
import { livestreamLinkInfoTemplate } from "./livestream-link-info";
import { selfServiceInfoBeginRegex, selfServiceInfoEndRegex, creationInfoRegex } from "./body-constants";
import { htmlToDomNodes, indicesOfMarkers, replaceContentBetween } from "../chime/html-template-utilities";
import { LIVESTREAM_REPORT_HREF, LIVESTREAM_SUPPORT_HREF } from "../../meeting-livestream/livestream-constants";
import { presenterSeparatorTemplate } from "./presenter-separator";
import { requestTimeInfoTemplate } from "./request-time-info";
import { viewerInfoTemplate } from "./viewer-info";
import { organizerInfoForRecordingTemplate } from "./organizer-info-for-recording";

// Append self service info to the body
export const addSelfServiceInfo = (bodyHTML) => {
    bodyHTML = bodyHTML || "";

    return DOMPurify.sanitize(bodyHTML + "<br />" + selfServiceInfoTemplate);
};

// Remove self service info from the body
export const removeSelfServiceInfo = (bodyHTML) => {
    bodyHTML = bodyHTML || "";

    let nodes = htmlToDomNodes(bodyHTML);
    let selfServiceInfoIndices = indicesOfMarkers(nodes, selfServiceInfoBeginRegex, selfServiceInfoEndRegex);
    return replaceContentBetween(nodes, selfServiceInfoIndices, "");
};

// Append Amazon Meetings and edit links to the body
export const addCreationInfo = (bodyHTML, alias, uniqueID = "", isRecurring = false) => {
    bodyHTML = bodyHTML || "";

    let creationInfo = DOMPurify.sanitize(creationInfoTemplate);
    creationInfo = creationInfo.replace(/\{\{ALIAS\}\}/g, alias);
    creationInfo = creationInfo.replace(/\{\{UNIQUE_ID\}\}/g, uniqueID);
    creationInfo = creationInfo.replace(/\{\{TYPE\}\}/g, isRecurring ? "series" : "meeting");

    return DOMPurify.sanitize(bodyHTML + "<br />" + creationInfo);
};

// Remove Amazon Meetings and edit links from the body
export const removeCreationInfo = (bodyHTML) => {
    bodyHTML = bodyHTML || "";

    let nodes = htmlToDomNodes(bodyHTML);
    let creationInfoIndices = indicesOfMarkers(nodes, creationInfoRegex, creationInfoRegex);
    return replaceContentBetween(nodes, creationInfoIndices, "");
};

// Append self service info and meeting creation info to the body
export const addMeetingsInfo = (bodyHTML, alias, uniqueID = "", isRecurring = false) => {
    bodyHTML = bodyHTML || "";
    bodyHTML = addSelfServiceInfo(bodyHTML);
    bodyHTML = addCreationInfo(bodyHTML, alias, uniqueID, isRecurring);

    return bodyHTML;
};
// Append self service info, request time info and meeting creation to prephold invite
export const addPrepMeetingInfo = (bodyHTML, alias, uniqueID = "", isRecurring = false) => {
    bodyHTML = bodyHTML || "";
    bodyHTML = addSelfServiceInfo(bodyHTML);
    bodyHTML = addRequestTimeInfo(bodyHTML);
    bodyHTML = addCreationInfo(bodyHTML, alias, uniqueID, isRecurring);

    return bodyHTML;
};

// Remove self service info and meeting creation info from the body
export const removeMeetingsInfo = (bodyHTML) => {
    bodyHTML = bodyHTML || "";
    bodyHTML = removeSelfServiceInfo(bodyHTML);
    bodyHTML = removeCreationInfo(bodyHTML);

    return bodyHTML;
};

// Add livestream prep hold info to the beginning of the body
export const addLivestreamPrepInfo = (bodyHTML) => {
    bodyHTML = bodyHTML || "";

    let livestreamPrepInfo = DOMPurify.sanitize(livestreamPrepInfoTemplate);
    return DOMPurify.sanitize(livestreamPrepInfo + "<br />" + bodyHTML);
};

// Add link to the livestream after the meeting agenda
export const addLivestreamLinkInfo = (bodyHTML, isRecordingEvent) => {
    bodyHTML = bodyHTML || "";

    if (isRecordingEvent) {
        let organizerInfoForRecording = DOMPurify.sanitize(organizerInfoForRecordingTemplate);
        return DOMPurify.sanitize(organizerInfoForRecording + bodyHTML);
    } else {
        let livestreamLinkInfo = DOMPurify.sanitize(livestreamLinkInfoTemplate);
        livestreamLinkInfo = livestreamLinkInfo.replace(/\{\{SUPPORT_HREF\}\}/g, LIVESTREAM_SUPPORT_HREF);
        livestreamLinkInfo = livestreamLinkInfo.replace(/\{\{REPORT_HREF\}\}/g, LIVESTREAM_REPORT_HREF);

        return DOMPurify.sanitize(livestreamLinkInfo + bodyHTML);
    }
};

// Add presenter details
export const addPresenterInfo = (bodyHTML) => {
    bodyHTML = bodyHTML || "";

    let presenterSeparator = DOMPurify.sanitize(presenterSeparatorTemplate);

    return DOMPurify.sanitize(presenterSeparator + bodyHTML);
};

// Add viewer invite details
export const addViewerInfo = (bodyHTML, isRecordingEvent) => {
    bodyHTML = bodyHTML || "";

    if (isRecordingEvent) {
        return DOMPurify.sanitize(bodyHTML);
    }

    let viewerInfo = DOMPurify.sanitize(viewerInfoTemplate);
    return DOMPurify.sanitize(viewerInfo + bodyHTML);
};

// Add creation info
export const addRequestTimeInfo = (bodyHTML) => {
    bodyHTML = bodyHTML || "";

    const creationTime = new Date().toUTCString();
    let requestTimeInfo = DOMPurify.sanitize(requestTimeInfoTemplate);
    requestTimeInfo = requestTimeInfo.replace(/\{\{CREATION_TIME\}\}/g, creationTime);

    return DOMPurify.sanitize(bodyHTML + requestTimeInfo);
}