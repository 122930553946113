import React from "react"
import PropTypes from "prop-types"

// Provides the WrappedComponent's displayName
// for easy debugging
const getsDisplayName = WrappedComponent =>
  WrappedComponent.getsDisplayName || WrappedComponent.name || "Component"

const withAnimateMount = WrappedComponent => {
  /**
   * This is a higher order component that provides a consistent API to easily
   * create animations to be used for elements that need to be added and
   * removed to the DOM dynamically. Receives an animation component and returns a
   * wrapped, composed component.
   */
  return class WithAnimateMount extends React.Component {
    static propTypes = {
      /**
       * A function that returns the elements to animate.
       */
      children: PropTypes.func.isRequired,
      /**
       * If true the children will be mounted, otherwise they will be unmounted.
       */
      open: PropTypes.bool.isRequired,
      /**
       * Only applies to height based transitions. Allows for cases when the
       * desired effect is animating height to a value that's different than
       * the element's scrollHeight.
       */
      animateToHeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      /**
       * The duration of the animation. This should be a valid CSS animation
       * duration (e.g. `"100ms"` or `"1s"`).
       */
      duration: PropTypes.string,
      /**
       * The easing function of the animation. This should be a valid CSS easing
       * function (e.g. `"linear"` or `"ease"`).
       */
      easing: PropTypes.string,
      /**
       * The function provided here will be called if the component transitions
       * from a closed state to an open state, or if it is mounted in an open
       * state. Note that this is called as soon as the state changes, which
       * occurs *before* the elements being mounted are actually available in
       * the DOM.
       */
      onOpen: PropTypes.func,
    }

    static defaultProps = {
      duration: "150ms",
      easing: "ease",
      open: false,
    }

    // This provides a nice, clear displayName including the HOC for debugging
    static displayName = `WithAnimateMount(${getsDisplayName(
      WrappedComponent
    )})`

    state = {
      show: false,
    }

    componentDidMount() {
      if (this.props.open) {
        this.updateStateOnOpen()
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.open && this.props.open) {
        this.updateStateOnOpen()
      }
    }

    updateStateOnOpen = () => {
      if (typeof this.props.onOpen !== "undefined" && this.state.show) {
        this.props.onOpen()
      }
      // Remount the node when the open prop is true
      this.setState({
        show: true,
      })
    }

    transitionEnd = () => {
      if (!this.props.open) {
        this.setState({
          show: false,
        })
      }
    }
    render() {
      const { children, duration, easing, open, animateToHeight } = this.props
      const animateToHeightProps =
        typeof animateToHeight !== "undefined"
          ? { animateToHeight: animateToHeight }
          : null

      return (
        this.state.show && (
          <WrappedComponent
            duration={duration}
            easing={easing}
            open={open}
            updateStateOnOpen={this.updateStateOnOpen}
            transitionEnd={this.transitionEnd}
            {...animateToHeightProps}
          >
            {children}
          </WrappedComponent>
        )
      )
    }
  }
}

export default withAnimateMount
