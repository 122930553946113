import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getIdentity,
    getOOTOMeeting,
    getOOTOStep,
    getPeopleSuggestions as getPeopleSuggestionsSelector,
    getGroupSuggestions as getGroupSuggestionsSelector,
    getAttendees,
    getMeetingListCalendar,
    getMeetingList,
    getFavorites,
    getTimezonesList,
    getSettingsPrimaryTimezone,
    getMeetingDetails,
    getLoadOOTO,
    getAwarenessMeeting,
    getAwarenessMeetingUniqueId,
    getSettingsTimeFormat,
    getSettingsDateFormat,
    getSettingsWorkingHours,
} from "../../../sagas/selector";
import {
    changeStep,
    createMeeting,
    updateMeeting,
    getAwarenessMeetingAndSave,
    setLoadOOTO,
    clearDraft as clearDraftOOTO
} from "../actions";
import {
    addAttendee,
    updateAttendee,
    removeAttendee,
    getRASDataForPerson,
    getRASDataForGroup,
    getPeopleSuggestions,
    clearPeopleSuggestions,
    getGroupSuggestions,
    clearGroupSuggestions,
    addAttendeeByEmail,
    clearDraft as clearDraftPeople,
} from "../../people/actions";
import { getTimezones } from "../../shared/timezones/actions";
import { addFavorite, removeFavorite } from "../../shared/favorites/actions";

import OOTOForm from "../components/ooto-form";
import { isValidEmail, rasUserToAttendee } from "../../people/people-utils";
import { ATTENDEE_PRIORITY, ATTENDEE_RESPONSE, ATTENDEE_TYPE } from "../../people/people-constants";
import Responsive from "@amzn/meridian/responsive";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { FLOW_TYPE } from "../ooto-scheduler-constants";
import MeetingsToaster from "../../shared/toasts/containers/toast-container";
import { getUrlParameters } from "../../shared/permalink/permalink-utils";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";

const OOTOFormDetails = (props) => {
    const flowType = props.flowType;
    const identity = useSelector(getIdentity);
    const meeting = useSelector(getOOTOMeeting);
    const meetingListCalendar = useSelector(getMeetingListCalendar);
    const meetingList = useSelector(getMeetingList);
    const currentStep = useSelector(getOOTOStep);
    // Redux level state variable to track an awarenessMeeting
    const awarenessMeeting = useSelector(getAwarenessMeeting);
    // Redux level state variable to track if awarenessMeetingUniqueId was generated
    const awarenessMeetingUniqueId = useSelector(getAwarenessMeetingUniqueId);
    // Redux level state variable to track if we are loading an OOTO meeting
    const loadOOTO = useSelector(getLoadOOTO);
    const meetingDetails = useSelector(getMeetingDetails); // information to edit existing meeting
    const attendees = useSelector(getAttendees).filter((attendee) => attendee.type !== ATTENDEE_TYPE.CHIME);
    const peopleSuggestions = useSelector(getPeopleSuggestionsSelector);
    const groupSuggestions = useSelector(getGroupSuggestionsSelector);
    const timezones = useSelector(getTimezonesList);
    const favorites = useSelector(getFavorites);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const dateFormat = useSelector(getSettingsDateFormat);
    const settingsWorkingHours = useSelector(getSettingsWorkingHours);

    const queryParams = getUrlParameters(WORKFLOWS.OOTO.HREF);
    const [invalidUserEmail, setInvalidUserEmail] = useState();

    const dispatch = useDispatch();

    const onCreateMeeting = () => dispatch(createMeeting(meeting, identity.username), "/");
    const onChangeStep = (step) => dispatch(changeStep(step));
    const onUpdateMeeting = (meeting) => dispatch(updateMeeting(meeting));
    const onAddAttendee = (attendee) => {
        dispatch(addAttendee(attendee));
        if (attendee.rasData === undefined && attendee.type === ATTENDEE_TYPE.PERSON) {
            dispatch(getRASDataForPerson(attendee.alias, false));
        }
    };
    const onUpdateAttendee = (attendee) => dispatch(updateAttendee(attendee));
    const onRemoveAttendee = (attendee) => dispatch(removeAttendee(attendee));
    const onGetRASDataForPerson = (alias, addNewAttendee, name) => dispatch(getRASDataForPerson(alias, addNewAttendee, name));
    const onGetRASDataForGroup = (group) => dispatch(getRASDataForGroup(group));
    const onGetPeopleSuggestions = (query) => dispatch(getPeopleSuggestions(query));
    const onClearPeopleSuggestions = () => dispatch(clearPeopleSuggestions());
    const onGetGroupSuggestions = (query) => dispatch(getGroupSuggestions(query));
    const onClearGroupSuggestions = () => dispatch(clearGroupSuggestions());
    const onGetTimezones = (language) => dispatch(getTimezones(language));
    const onAddFavorite = (favorite, alias) => dispatch(addFavorite(favorite, alias));
    const onRemoveFavorite = (favorite, alias) => dispatch(removeFavorite(favorite, alias));

    // Component level state variable to track if we are loading an existing meeting into our standard workflow inputs.
    // If loadMeeting is true (came from a meeting details modal),
    // we are loading a meeting that is already in the meetingDetails redux state.
    // If loadMeeting is false AND flowType is UPDATE (came from an edit link directly),
    // then we still need to retrieve the meeting we want to load.
    const [pointOfContact, setPointOfContact] = useState([]);
    const [oldMessageBodyTimeInfo, setOldMessageBodyTimeInfo] = useState();
    const [oldMessageSubjectTimeInfo, setOldMessageSubjectTimeInfo] = useState();
    const [messageBodyTimeFormatChanged, setMessageBodyTimeFormatChanged] = useState(false);
    const [messageSubjectTimeFormatChanged, setMessageSubjectTimeFormatChanged] = useState(false);
    const [timezoneValue, setTimezoneValue] = useState(undefined); // useEffect will set initial timezoneValue

    // This function iterates through either the meetings in the agenda or the calendar depending on where the user came from
    const loopThroughMeetingsAndGetAwarenessMeeting = useCallback((meetingList) => {
        for (const meeting of meetingList) {
            if (meeting.uniqueID === awarenessMeetingUniqueId) {
                // We have found the awarenessmeetingUniqueId
                dispatch(getAwarenessMeetingAndSave(identity.email, meeting.entryID, false, "ooto"));
            }
        }
    }, [identity, awarenessMeetingUniqueId, dispatch]);

    useLayoutEffect(() => {
        dispatch(clearDraftOOTO());
        dispatch(clearDraftPeople());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // To follow the convention of edit meeting from Meeting Scheduler
        if (flowType === FLOW_TYPE.UPDATE) {
            document.title = "Edit Out Of The Office - Amazon Meetings";
        } else {
            document.title = "Out Of The Office - Amazon Meetings";
        }
    }, [flowType]);

    useEffect(() => {
        if (identity && identity.username && attendees && !attendees.find((attendee) => attendee.isOrganizer)) {
            let organizer = rasUserToAttendee(identity, ATTENDEE_RESPONSE.ORGANIZER, ATTENDEE_PRIORITY.REQUIRED);
            dispatch(addAttendee(organizer));
        }
    }, [attendees, identity, dispatch]);

    // Load primaryBrowserTimezoneId for timezone picker and meeting request only for initial timezoneValue
    useEffect(() => {
        if (!timezoneValue && primaryTimezone) {
            setTimezoneValue(primaryTimezone);

            onUpdateMeeting({
                startTimezone: {
                    id: primaryTimezone
                },
                endTimezone: {
                    id: primaryTimezone
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezoneValue, primaryTimezone]);

    // Add attendees if a user is copying a meeting
    useEffect(() => {
        if (identity && identity.username && loadOOTO && (meetingDetails.entryID || awarenessMeeting )) {
            let requiredAttendees = meetingDetails.requiredAttendees;
            if (awarenessMeeting && awarenessMeeting?.requiredAttendees) {
                requiredAttendees = awarenessMeeting.requiredAttendees;
            }
            requiredAttendees.forEach((attendee) => {
                if (!attendee.email.endsWith("@chime.aws") && attendee.email !== identity.email) {
                    dispatch(addAttendeeByEmail(attendee));
                }
            });

            // setLoadOOTO to false if it is not a self meeting (indicated by the lack of awarenessMeetingUniqueId)
            // OR if there is self meeting, wait until that API call has resolved and we have an awarenessMeeting object in the redux store
            // before setting loadOOTO to false
            if (!awarenessMeetingUniqueId || awarenessMeeting.entryID) {
                dispatch(setLoadOOTO(false));
            }
        }
    }, [identity, meetingDetails, awarenessMeeting, awarenessMeetingUniqueId, loadOOTO, dispatch, loopThroughMeetingsAndGetAwarenessMeeting]);

    // useEffect to get the AwarenessMeeting if meeting is a self meeting and an awareness meeting for it exists
    useEffect(() => {
        if (identity && identity.username && loadOOTO && awarenessMeetingUniqueId) {
            // This variable determines if the current meeting is a self meeting and if an awareness meeting is available for it
            if (awarenessMeetingUniqueId) {
                // If meetingListCalendar is not empty we know that the user came from the calendar page and need to search the meetings from there
                if (meetingListCalendar?.length > 0) {
                    loopThroughMeetingsAndGetAwarenessMeeting(meetingListCalendar);
                } else {
                    // If the user came directly from the meetingList page we know that the user came from the Agenda page and the findMeetings() call
                    // for all the calendar meeting is not required to find the awareness meeting
                    loopThroughMeetingsAndGetAwarenessMeeting(meetingList);
                }
            }
        }
    }, [identity, awarenessMeetingUniqueId, meetingList, meetingListCalendar, loadOOTO, loopThroughMeetingsAndGetAwarenessMeeting]);

    // useEffect for On query params load for Notify people
    useEffect(() => {
        if (identity && identity.username) {
            let invalidUserEmail = false;
            // Load participantsByPriority
            if (queryParams.participantsByPriority !== undefined) {
                let userEmailsList = queryParams.participantsByPriority.split(",");
                userEmailsList.forEach((userEmail) => {
                    if (isValidEmail(userEmail)) {
                        if (!userEmail.endsWith("@chime.aws")) {
                            let attendee = {email: userEmail};
                            dispatch(addAttendeeByEmail(attendee, ATTENDEE_PRIORITY.REQUIRED));
                        }
                    } else {
                        invalidUserEmail = true;
                    }
                });
            }
            setInvalidUserEmail(invalidUserEmail);
        }
    }, [identity, dispatch, queryParams.participantsByPriority]);

    return (
        <Responsive
            query="min-width"
            props={{
                screenSizeBreakpoint: {
                    default: SCREEN_SIZE.MOBILE_VIEW, // 360px
                    "1200px": SCREEN_SIZE.FULL_VIEW,
                    "900px": SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND,
                    "700px": SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR,
                    "550px": SCREEN_SIZE.PARTIAL_MOBILE_VIEW
                }
            }}
        >
            {(responsiveProps) =>
                <React.Fragment>
                    <OOTOForm
                        mastheadSize={props.mastheadSize}
                        screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                        identity={identity}
                        meeting={meeting}
                        currentStep={currentStep}
                        attendees={attendees}
                        queryParams={queryParams}
                        invalidUserEmail={invalidUserEmail}
                        peopleSuggestions={peopleSuggestions}
                        groupSuggestions={groupSuggestions}
                        pointOfContact={pointOfContact}
                        setPointOfContact={setPointOfContact}
                        timezones={timezones}
                        timezoneValue={timezoneValue}
                        setTimezoneValue={setTimezoneValue}
                        timeFormat={timeFormat}
                        dateFormat={dateFormat}
                        favorites={favorites}
                        onCreateMeeting={onCreateMeeting}
                        onChangeStep={onChangeStep}
                        onUpdateMeeting={onUpdateMeeting}
                        onAddAttendee={onAddAttendee}
                        onUpdateAttendee={onUpdateAttendee}
                        onRemoveAttendee={onRemoveAttendee}
                        onGetRASDataForPerson={onGetRASDataForPerson}
                        onGetRASDataForGroup={onGetRASDataForGroup}
                        onGetPeopleSuggestions={onGetPeopleSuggestions}
                        onClearPeopleSuggestions={onClearPeopleSuggestions}
                        onGetGroupSuggestions={onGetGroupSuggestions}
                        onClearGroupSuggestions={onClearGroupSuggestions}
                        onGetTimezones={onGetTimezones}
                        onAddFavorite={onAddFavorite}
                        onRemoveFavorite={onRemoveFavorite}
                        messageBodyTimeFormatChanged={messageBodyTimeFormatChanged}
                        setMessageBodyTimeFormatChanged={setMessageBodyTimeFormatChanged}
                        messageSubjectTimeFormatChanged={messageSubjectTimeFormatChanged}
                        setMessageSubjectTimeFormatChanged={setMessageSubjectTimeFormatChanged}
                        oldMessageBodyTimeInfo={oldMessageBodyTimeInfo}
                        setOldMessageBodyTimeInfo={setOldMessageBodyTimeInfo}
                        oldMessageSubjectTimeInfo={oldMessageSubjectTimeInfo}
                        setOldMessageSubjectTimeInfo={setOldMessageSubjectTimeInfo}
                        settingsWorkingHours={settingsWorkingHours}
                    />
                    <MeetingsToaster/>
                </React.Fragment>
            }
        </Responsive>
    );
};

export default OOTOFormDetails;