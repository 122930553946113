import React from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";

const MeetingAgendaFooter = (props) => {

    const handleClickViewMore = () => {
        props.onSetViewAllMeetingsFilter(!props.viewAllMeetings);
    };

    const getButtonMessage = () => {
        return props.viewAllMeetings ? "View less" : "View more";
    };

    return (
        <Row spacingInset="none small">
            <Button type="tertiary" onClick={handleClickViewMore}>{getButtonMessage()}</Button>
        </Row>
    )
};

export default MeetingAgendaFooter;