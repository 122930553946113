import React from "react";

import { FAVORITE_TYPE } from "./favorites-constants";
import { LOCATION_DEFAULTS, LOCATION_FEATURE, LOCATION_FLOOR_ANY, LOCATION_PROXIMITY } from "../locations/locations-constants";
import { resourceListsEqual, shortRoomName } from "../locations/locations-utils";
import { onSpaceOrEnterKeyDown } from "../shared-utils";

import Icon from "@amzn/meridian/icon";

import starTokens from "@amzn/meridian-tokens/base/icon/star";
import mailTokens from "@amzn/meridian-tokens/base/icon/mail";
import geopinTokens from "@amzn/meridian-tokens/base/icon/geopin";

import personSvg from "../../../assets/icons/people/person.svg";
import groupSvg from "../../../assets/icons/people/group.svg";
import roomSvg from "../../../assets/icons/locations/room-blue.svg";
import starFilledSvg from "../../../assets/icons/favorites/star_filled.svg";

import "./favorites.css";

export const favoritesEqual = (favorite1, favorite2) => {
    if (favorite1.type !== FAVORITE_TYPE.LOCATION) {
        return favorite1.type === favorite2.type
            && favorite1.value === favorite2.value
            && favorite1.name === favorite2.name;
    } else {
        favorite1 = fillFavoriteDefaults(favorite1);
        favorite2 = fillFavoriteDefaults(favorite2);
        return favorite1.type === favorite2.type
            && favorite1.value === favorite2.value
            && favorite1.name === favorite2.name
            && favorite1.floor === favorite2.floor
            && favorite1.thisBuildingOnly === favorite2.thisBuildingOnly
            && favorite1.minCapacity === favorite2.minCapacity
            && resourceListsEqual(favorite1.resourceList, favorite2.resourceList)
            && favorite1.restrictedAllowed === favorite2.restrictedAllowed
            && favorite1.hiddenAllowed === favorite2.hiddenAllowed;
    }
};

export const isValidFavorite = (favorite) => {
    return favorite.type !== undefined
        && favorite.value !== undefined
        && favorite.name !== undefined
        && (favorite.type !== FAVORITE_TYPE.LOCATION || favorite.floor !== undefined)
};

export const fillFavoriteDefaults = (favorite) => {
    // If a location option is missing, set it to the default value
    if (favorite.type === FAVORITE_TYPE.LOCATION) {
        if (favorite.floor === undefined) {
            favorite.floor = LOCATION_FLOOR_ANY;
        }
        if (favorite.thisBuildingOnly === undefined) {
            favorite.thisBuildingOnly = LOCATION_DEFAULTS.THIS_BUILDING_ONLY;
        }
        if (favorite.minCapacity === undefined) {
            favorite.minCapacity = LOCATION_DEFAULTS.MIN_CAPACITY;
        }
        if (favorite.resourceList === undefined) {
            favorite.resourceList = LOCATION_DEFAULTS.RESOURCE_LIST;
        }
        if (favorite.restrictedAllowed === undefined) {
            favorite.restrictedAllowed = LOCATION_DEFAULTS.RESTRICTED_ALLOWED;
        }
        if (favorite.hiddenAllowed === undefined) {
            favorite.hiddenAllowed = LOCATION_DEFAULTS.HIDDEN_ALLOWED;
        }
    }

    return favorite;
};

export const sortFavorites = (favorites) => {
    return favorites.sort((favorite1, favorite2) => {
        if (favoritesEqual(favorite1, favorite2)) {
            return 0;
        }

        if (favorite1.type !== favorite2.type) {
            return favorite1.type > favorite2.type ? 1 : -1;
        }

        if (favorite1.name !== favorite2.name) {
            return favorite1.name > favorite2.name ? 1 : -1;
        }

        if (favorite1.thisBuildingOnly !== favorite2.thisBuildingOnly) {
            return favorite2.thisBuildingOnly ? 1 : -1;
        }

        if (favorite1.floor !== favorite2.floor) {
            return favorite1.floor > favorite2.floor ? 1 : -1;
        }

        if (favorite1.minCapacity !== favorite2.minCapacity) {
            return favorite1.minCapacity > favorite2.minCapacity ? 1 : -1;
        }

        let resourceList1 = favorite1.resourceList || [];
        let resourceList2 = favorite2.resourceList || [];

        if (resourceList1.length !== resourceList2.length) {
            return resourceList1.length > resourceList2.length ? 1 : -1;
        }

        if (favorite1.restrictedAllowed !== favorite2.restrictedAllowed) {
            return favorite1.restrictedAllowed ? 1 : -1;
        }

        if (favorite1.hiddenAllowed !== favorite2.hiddenAllowed) {
            return favorite1.hiddenAllowed ? 1 : -1;
        }

        return 0;
    });
};

export const createFavorite = (type, favorite) => {
    switch (type) {
        case FAVORITE_TYPE.USER:
        case FAVORITE_TYPE.GROUP:
            return {
                type,
                value: favorite.alias,
                name: favorite.name,
            };
        case FAVORITE_TYPE.EXTERNAL_EMAIL:
            return {
                type,
                value: favorite.email,
                name: favorite.email,
            };
        case FAVORITE_TYPE.ROOM:
            return {
                type,
                value: favorite.email,
                name: shortRoomName(favorite.name),
            };
        case FAVORITE_TYPE.LOCATION:
            return {
                type,
                value: favorite.buildingCode,
                name: favorite.buildingName,
                floor: favorite.floor || LOCATION_FLOOR_ANY,
                thisBuildingOnly: favorite.thisBuildingOnly,
                minCapacity: favorite.minCapacity,
                resourceList: favorite.resourceList || [],
                restrictedAllowed: favorite.restrictedAllowed,
                hiddenAllowed: favorite.hiddenAllowed,
            };
        default:
            return undefined;
    }
};

export const getFavoriteLabel = (favorite) => {
    if (favorite.type === FAVORITE_TYPE.LOCATION) {
        let proximity = favorite.thisBuildingOnly ? "" : ` ${LOCATION_PROXIMITY.INCLUDE_NEARBY}`;
        let floor = favorite.floor === LOCATION_FLOOR_ANY ? "any floor" : `floor ${favorite.floor}`;
        let seats = `${favorite.minCapacity}+ seats`;
        let resources = (favorite.resourceList && favorite.resourceList.includes(LOCATION_FEATURE.CAMERA) ? ", camera" : "") +
                        (favorite.resourceList && favorite.resourceList.includes(LOCATION_FEATURE.DISPLAY) ? ", display" : "");
        let restricted = favorite.restrictedAllowed ? ", restricted allowed" : "";
        let managed = favorite.hiddenAllowed ? ", hand managed allowed" : "";

        return `${favorite.name}${proximity}, ${floor}, ${seats}${resources}${restricted}${managed}`;
    }

    return favorite.name;
};

export const renderFavoriteTypeIcon = (type, favoriteAdded) => {
    if (type === undefined) {
        return;
    }
    
    switch (type) {
        case FAVORITE_TYPE.USER:
            return (
                <img src={personSvg} alt="user" className={favoriteAdded ? "disabled_star" : undefined} />
            );
        case FAVORITE_TYPE.GROUP:
            return (
                <img src={groupSvg} alt="group" className={favoriteAdded ? "disabled_star" : undefined} />
            );
        case FAVORITE_TYPE.EXTERNAL_EMAIL:
            return (
                <Icon tokens={mailTokens} className={favoriteAdded ? "disabled_star" : undefined}>external email</Icon>
            );
        case FAVORITE_TYPE.ROOM:
            return (
                <img src={roomSvg} alt="room" className={favoriteAdded ? "disabled_star" : undefined} />
            );
        case FAVORITE_TYPE.LOCATION:
            return (
                <Icon tokens={geopinTokens} className={favoriteAdded ? "disabled_star" : undefined}>location</Icon>
            );
        default:
            return undefined;
    };
};

const onKeyDown = (event, onClick) => {
    onSpaceOrEnterKeyDown(event, onClick);
};

export const renderSetFavoriteIcon = (favorited, onFavoriteClick, favorite, disabled = false) => {
    const onClick = () => !disabled && onFavoriteClick(favorite);
    return (
        <div
            className={disabled ? "disabled_favorite_icon" : "favorite_icon"}
            aria-label={`${favorited ? "Unfavorite" : "Favorite"} ${getFavoriteLabel(favorite)}`}
            role="button"
            onClick={onClick}
            tabIndex="0"
            onKeyDown={(event) => onKeyDown(event, onClick)}
            aria-disabled={disabled}
        >
            {favorited ?
                <img
                    src={starFilledSvg}
                    alt="favorited icon"
                    width="18px"
                    height="18px"
                />
                :
                <img
                    src={starTokens.iconStarData}
                    alt="favorited icon"
                    width="18px"
                    height="18px"
                    className={disabled ? "disabled_star" : "unfilled_star"}
                />
            }
        </div>
    );
};