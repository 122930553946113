import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import Box from "../box"
import CustomPropTypes from "../../_prop-types"
import { parseSpacing } from "../../_prop-types/parsers"
import { memoize, memoizeByKeys, memoizeArray } from "../../_utils/functional"
import cxMemoized from "../../_utils/cx-memoized"
import { getHorizontalDirection } from "../../_utils/rtl"

const gridColumns = 12

// Turns a raw width value (a string or number) into an object with fields
// containing the type of width and the parsed width values
const parseWidth = width =>
  typeof width === "string" && /^grid-/.test(width)
    ? {
        type: "grid",
        // Turns "grid-X" into a numeric percentage w/ four significant digits (e.g.
        // "grid-4" to 0.3333)
        value:
          Math.floor((10000 * parseInt(width.substr(5))) / gridColumns) / 10000,
      }
    : typeof width === "string" && /^[0-9.]+%\s*$/.test(width)
    ? {
        type: "percentage",
        // Turns "X%" into a numeric percentage w/ four significant digis (e.g. "33.33%"
        // to 0.3333)
        value: Math.floor(100 * parseFloat(width)) / 10000,
      }
    : width === "fit" || width === "fill"
    ? { type: "flex", value: width }
    : width !== undefined && width !== null && width !== ""
    ? { type: "css", value: width }
    : { type: "empty", value: null }

// These styles stretch the inner div to fill the entire box so that a width or
// height applied to the box will also apply to the inner div. This is only
// necessary for rows that wrap (which have two containing divs in order to make
// the gutter spacing work correctly - see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mastering_Wrapping_of_Flex_Items#Creating_gutters_between_items)
const wrapContainerStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
})

const rowStyles = widths =>
  memoizeByKeys(
    ({ wrap, spacing, alignmentHorizontal, alignmentVertical, reverse }) => {
      const spacingValues = parseSpacing(spacing)
      const verticalSpacing = spacingValues[0]
      const horizontalSpacing = spacingValues[1] || verticalSpacing
      return css(
        {
          width:
            wrap === "none" ? undefined : `calc(100% + ${horizontalSpacing}px)`,
          display: "flex",
          flexDirection: reverse ? "row-reverse" : "row",
          flexWrap:
            wrap === "down"
              ? "wrap"
              : wrap === "up"
              ? "wrap-reverse"
              : undefined,
          alignItems:
            {
              top: "flex-start",
              bottom: "flex-end",
            }[alignmentVertical] || alignmentVertical,
          justifyContent:
            {
              left: "flex-start",
              right: "flex-end",
              justify: "space-between",
            }[getHorizontalDirection(alignmentHorizontal)] ||
            getHorizontalDirection(alignmentHorizontal),
          // Reset the extra margins caused by the children on the right/bottom
          // edges. We can't do a simple reset on the first/last child because
          // there may be any number of children on the right/bottom edges if the
          // row wraps.
          margin:
            wrap === "none"
              ? undefined
              : `0 -${horizontalSpacing}px ${
                  wrap === "none" ? 0 : `-${verticalSpacing}px`
                } 0`,
          // Reset extra margins for RTL
          [`[dir="rtl"] &`]: {
            margin:
              wrap === "none"
                ? undefined
                : `0 0 ${
                    wrap === "none" ? 0 : `-${verticalSpacing}px`
                  } -${horizontalSpacing}px`,
          },
          // Set margins and widths on the children of the row. Use !important to
          // override any existing styles.
          /* eslint-disable no-useless-computed-key */
          ["& > *"]: {
            // Make sure that any borders or padding applied to the child don't throw
            // of the widths that we apply
            boxSizing: "border-box !important",
            // Set margins for horizontal spacing and vertical spacing if the row is
            // allowed to wrap (and clear other margins)
            margin: `0 ${horizontalSpacing}px ${
              wrap === "none" ? 0 : verticalSpacing
            }px 0 !important`,
            // Set margins for RTL
            [`[dir="rtl"] &`]: {
              margin: `0 0 ${
                wrap === "none" ? 0 : verticalSpacing
              }px ${horizontalSpacing}px !important`,
            },
          },
          // If the row can't wrap, reset the right margin on the right-most
          // child to avoid extra spacing at the end of the row
          [`& > *:${reverse ? "first" : "last"}-child`]:
            wrap === "none" ? { marginRight: `0 !important` } : undefined,
          // Reset margin for RTL
          [`[dir="rtl"] & > *:${reverse ? "first" : "last"}-child`]:
            wrap === "none" ? { marginLeft: `0px !important` } : undefined,
        },
        // If we've got an array of widths, apply each width to the child whose
        // index in the dom matches the widths's index in the array. If we've
        // got a single height, apply that to all children.
        Array.isArray(widths)
          ? widths.reduce((result, width, index) => {
              result[`& > *:nth-child(${parseInt(index) + 1})`] = childStyles({
                width,
                wrap,
                horizontalSpacing,
              })
              return result
            }, {})
          : { "& > *": childStyles({ width: widths, wrap, horizontalSpacing }) }
      )
    },
    ["wrap", "spacing", "alignmentHorizontal", "alignmentVertical", "reverse"]
  )

const rowStylesWithUniqueWidths = memoizeArray(rowStyles)

const rowStylesWithUniformWidths = memoize(rowStyles)

const childStyles = ({ width: rawWidth, wrap, horizontalSpacing }) => {
  const width = parseWidth(rawWidth)
  return {
    // Stretch the child to fill remaining space if the width is "fill"
    flex:
      width.type === "flex" && width.value === "fill"
        ? "1 !important"
        : undefined,
    // Ensure that the child isn't smashed by flexbox if it has an explicit
    // width set.
    flexShrink:
      width.type === "grid" ||
      width.type === "percentage" ||
      (width.type === "flex" && width.value === "fit") ||
      (width.type === "css" && width.value !== "auto")
        ? `0 !important`
        : undefined,
    width:
      width.type === "grid" || width.type === "percentage"
        ? // For percentage widths (or grid widths), set the element to the
          // specified percentage of the row's width (e.g. 50%) but subtract the
          // gutter spacing from the width to ensure there's enough room for the
          // element and its gutter (e.g. 50% - 16px). For rows that wrap we can
          // subtract the full gutter width since each element has a full gutter
          // to its right (the extra gutter on the right edge of the row is
          // removed with a negative margin on the parent element). For rows
          // that don't wrap the last element doesn't have a gutter to its
          // right, so things are a bit more complicated. In this case there's
          // one less gutter than there are elements in the row (e.g. 3 elements
          // have 2 gutters between them) so subtracting a full gutter from each
          // element's width would be too much. We have to spread out this saved
          // saved gutter space proportional to the percentage of the row that
          // the element takes up. For example if a row has two elements that
          // each take up 50% of the row with a single 16px gutter between them,
          // then we'd subtract 50% of the 16px gutter from each element giving
          // us a final width of 50% - 8px for each element.
          `calc(${width.value * 100}% - ${horizontalSpacing -
            (wrap === "none"
              ? width.value * horizontalSpacing
              : 0)}px) !important`
        : width.type === "css"
        ? // If the size is a plain CSS value then just use that directly
          `${width.value}${
            typeof width.value === "number" ? "px" : ""
          } !important`
        : // And lastly for fit, fill, or empty values leave it up to flexbox
          undefined,
  }
}

/**
 * The Row component lines children up horizontally in a row with even spacing
 * in-between them. If a value is provided for the `wrap` property then the Row
 * component can also be used to stack elements with even vertical spacing
 * between them.
 */
const Row = React.forwardRef((props, ref) => {
  // A row with an explicit height cannot wrap
  const wrap = props.height === undefined ? props.wrap : "none"
  // We need to memoize differently based on whether we got an array or scalar
  // for the "widths" property
  const rowStylesFunc = Array.isArray(props.widths)
    ? rowStylesWithUniqueWidths
    : rowStylesWithUniformWidths
  const rowStyles = rowStylesFunc(props.widths)({ ...props, wrap })
  // Apply the row styles to an extra div inside the box so margins that are
  // applied to the box (e.g. if the row is nested in another row) don't
  // conflict with the margins that are applied by `rowStyles`.
  return React.createElement(
    Box,
    {
      ...props,
      className:
        wrap === "none"
          ? cxMemoized(rowStyles, props.className)
          : cxMemoized(wrapContainerStyles, props.className),
      ref,
    },
    wrap === "none" ? (
      props.children
    ) : (
      <div className={rowStyles}>{props.children}</div>
    )
  )
})

Row.displayName = "Row"

Row.propTypes = {
  /**
   * Specify the horizontal alignment of the elements inside the row.
   *
   * @since 4.x
   */
  alignmentHorizontal: PropTypes.oneOf([
    "left",
    "start",
    "center",
    "right",
    "end",
    "justify",
  ]),
  /**
   * Specify the vertical alignment of the elements inside the row.
   *
   * @since 5.x
   */
  alignmentVertical: PropTypes.oneOf([
    "top",
    "center",
    "bottom",
    "stretch",
    "baseline",
  ]),
  /**
   * Set the column's background color using a preset, or using a string
   * containing any valid CSS color.
   */
  backgroundColor: PropTypes.oneOfType([
    PropTypes.oneOf(["primary", "secondary"]),
    PropTypes.string,
  ]),
  /**
   * The elements to line up in a row. Any elements can be supplied here, although if
   * only one child is supplied the row will not add much value (it's meant
   * for aligning multiple elements). If you only have one child, consider using
   * the `Box` component instead.
   */
  children: PropTypes.node,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  /**
   * All props prefaced with `data-` are accepted. This is useful for
   * integrating with other libraries such as Amplify Analytics or Cypress.
   *
   * @since 5.x
   */
  "data-*": PropTypes.string,
  /**
   * Set the height of the row using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the maximum height of the row using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the maximum width of the row using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the minimum height of the row using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the minimum width of the row using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Controls the vertical overflow behavior of the container. The container
   * can become scrollable by setting the prop to scroll, or the overflow
   * can be hidden by setting the prop to hidden.
   */
  overflowY: PropTypes.oneOf(["auto", "hidden", "scroll"]),
  /**
   * If set to true this will reverse the order in which the elements in the row
   * are rendered (the first element will be rendered where the last normally
   * would, and vice-versa).
   */
  reverse: PropTypes.bool,
  /**
   * Set the spacing between elements in the row using a preset.
   *
   * Two spacing values are allowed (separated by a space) if the `wrap` prop is
   * set. In this case the first value will be used as the vertical spacing
   * between elements and the second value will be used as the horizontal
   * spacing.
   */
  spacing: CustomPropTypes.spacing(2),
  /**
   * Apply padding to the component.
   *
   * CSS-style [1-to-4 value shorthand](https://developer.mozilla.org/en-US/docs/Web/CSS/Shorthand_properties)
   * is accepted (e.g. `"small medium"` for small padding on the top/bottom and
   * medium padding on the left/right).
   */
  spacingInset: CustomPropTypes.spacing(4),
  /**
   * The HTML tag to use when rendering the component to the DOM.
   */
  tag: PropTypes.string,
  /**
   * Set the style of the row using one of Meridian's Box component styles.
   * If omitted the row will not have any decorative visual style applied to
   * it.
   */
  type: PropTypes.oneOf(["outline", "fill"]),
  /**
   * Set the width of the row using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the width of each element in the row.
   *
   * If a single width is provided then it will be applied to every element in
   * the row. If an array of widths is provided then each width will be
   * applied to the element in the row that exists at the same index as the
   * width in the array (e.g. `[<element 0 width>, <element 1 width>, null, <element 3 width>]`).
   *
   * The possible values for each width are:
   *   - `number` - A fixed pixel width.
   *   - `string` - Any valid CSS width.
   *   - `"fit"` - The element's width will shrink to fit its contents.
   *   - `"fill"` - The element's width will fill all remaining space in the row.
   *     If this value is set on multiple elements, they will share the remaining
   *     space in the row equally.
   *   - `"grid-<x>"` - The element will take up "x" grid units on a 12-column grid
   *     ("x" can be 1-12).
   */
  widths: PropTypes.oneOfType([
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  /**
   * Set this to allow elements that overflow the width of the row to wrap. If
   * this is set to `"down"` then elements that overflow to the right will wrap
   * down below the other elements. If set to `"up"` then the overflowing
   * elements will wrap up above the other elements.
   *
   * NOTE: Negative margins are used internally in order to maintain the correct
   * gutter spacing between elements that wrap ([this technique is recommended by MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mastering_Wrapping_of_Flex_Items#Creating_gutters_between_items)).
   * This can cause unwanted horizontal scrolling if the inset spacing of the
   * row is less than the gutter spacing *and* the row is placed in a parent that
   * allows overflow. To resolve this you can either increase the padding/margin
   * around the row (using the `insetSpacing` prop of the row or by applying
   * padding/margin to an element that wraps the row) or turn off the
   * overflow on the row's parent element (by applying an `overflow: hidden`
   * style). You can also turn off wrapping on the row or consider using custom
   * styles to build a layout with something like [CSS grid](https://css-tricks.com/snippets/css/complete-guide-grid/).
   */
  wrap: PropTypes.oneOf(["up", "down", "none"]),
}

Row.defaultProps = {
  wrap: "none",
  spacing: "medium",
  alignmentVertical: "center",
  alignmentHorizontal: "start",
}

export default Row
export { gridColumns }
