import React from "react";
import DOMPurify from "dompurify";

import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import Thumbnail from "@amzn/meridian/thumbnail";
import Link from "@amzn/meridian/link";
import Tooltip from "@amzn/meridian/tooltip";
import Loader from "@amzn/meridian/loader";

import attendeeDecor from "../../../assets/icons/polls/attendees_decor.svg";
// import pollPersonSvg from "../../../assets/icons/people/poll_person.svg";
import clockSvg from "../../../assets/icons/shared/clock.svg";

import { SCREEN_SIZE } from "../../shared/shared-constants";
// import { renderAttendeeNameList } from "../poll-utils";
import { RESPOND_PAGE_SIZE } from "../poll-constants";
import { getAttendeeTooltip } from "../../people/people-utils";

const PollRespondDetailsBox = (props) => {
    const organizer = props.organizer;
    const pollDetails = props.pollDetails;
    // const attendeeList = props.attendeeList;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;

    const prepareEmailBody = (emailBody) => {
        return DOMPurify.sanitize(emailBody).replaceAll("<a", `<a target="_blank"`);
    };

    return (
        <Box
            minHeight={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.LG ? RESPOND_PAGE_SIZE.DETAILS_BOX_MIN_HEIGHT : undefined}
            width="100%"
            type="outline"
        >
            <Row spacingInset="medium none" spacing="none" alignmentVertical="top" alignmentHorizontal="justify">
                <Column spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.SM ? "large none none xlarge" : "large none none medium"} spacing="xlarge">
                    {organizer ?
                        <Row alignmentVertical="center">
                            <Tooltip position="bottom" title={getAttendeeTooltip(organizer)}>
                                <Link
                                    href={"https://phonetool.amazon.com/users/" + organizer.alias}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Thumbnail
                                        key={organizer.name}
                                        size="large"
                                        source={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?login=${organizer.alias}`}
                                        label={organizer.name}
                                    />
                                </Link>
                            </Tooltip>
                            <Column alignmentHorizontal="left">
                                <Heading type="h100">
                                    {organizer.name}
                                </Heading>
                                <Text type="b300" color="secondary">
                                    {organizer.alias}@
                                </Text>
                            </Column>
                        </Row>
                        :
                        <Loader size="large" />
                    }
                    <section>
                        <Row spacing="small">
                            <Heading level={3} type="h300" className="responsive-text">
                                {(pollDetails && pollDetails.subject) || "Untitled meeting poll"}
                            </Heading>
                        </Row>
                    </section>
                    {pollDetails && pollDetails.body &&
                        <section>
                            <div
                                style={{"wordBreak": "break-word"}}
                                dangerouslySetInnerHTML={{__html: prepareEmailBody(pollDetails.body)}}
                            />
                        </section>
                    }
                    <Column spacingInset="none" spacing="medium">
                        <Heading level={4} type="h100">
                            Meeting details
                        </Heading>
                        <Row spacing="small">
                            <img src={clockSvg} height="16px" width="16px" alt="" />
                            <Text type="b200">{pollDetails && pollDetails.duration} minutes</Text>
                        </Row>
                        <Row spacing="small">
                            {/* TODO: Currently hide for braveheart
                            <img src={pollPersonSvg} height="16px" width="16px" alt="attendees" />
                            {attendeeList &&
                                <Tooltip position="bottom"
                                         title={renderAttendeeNameList(attendeeList)}
                                         id="attendee-name"
                                         maxWidth="150px">
                                    <Text type="b200">{attendeeList.length} attendees</Text>
                                </Tooltip>
                            }*/}
                        </Row>
                    </Column>
                </Column>
                {screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.SM &&
                    <Column spacingInset="none medium">
                        <img src={attendeeDecor} alt="" />
                    </Column>
                }
            </Row>
        </Box>
    );
};

export default PollRespondDetailsBox;