import React, { useState } from "react";

import Favorites from "./favorites";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Modal, { ModalFooter } from "../../meridian-custom-components/src/components/modal";
import Row from "@amzn/meridian/row";

import starTokens from "@amzn/meridian-tokens/base/icon/star";

const FavoritesModal = (props) => {
    const identity = props.identity;
    const attendees = props.attendees;
    const rooms = props.rooms;
    const locations = props.locations;
    const favorites = props.favorites;
    const hideLocations = props.hideLocations;

    const [open, setOpen] = useState(false);

    return (
        [
            <Button
                onClick={() => setOpen(true)}
                type="tertiary"
                key="Open favorites"
            >
                <Icon tokens={starTokens} />
            </Button>,
            <Modal
                title="Favorites"
                open={open}
                onClose={() => setOpen(false)}
                key="Favorites"
            >
                <Row alignmentHorizontal="center">
                    <Favorites
                        identity={identity}
                        attendees={attendees}
                        rooms={rooms}
                        locations={locations}
                        favorites={favorites}
                        hideLocations={hideLocations}
                        onAddAttendee={props.onAddAttendee}
                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                        onAddRoomByEmail={props.onAddRoomByEmail}
                        onAddLocation={props.onAddLocation}
                        onRemoveFavorite={props.onRemoveFavorite}
                    />
                </Row>
                <ModalFooter>
                    <Row alignmentHorizontal="right">
                        <Button type="secondary" size="small" onClick={() => setOpen(false)}>
                            Done
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        ]
    );
};

export default FavoritesModal;