module.exports = {
    LOCATION_TYPE: {
        ROOM: "Room",
        FLOOR: "Floor",
        BUILDING: "Building"
    },
    LOCATION_SORT_BY: {
        NAME: "name",
        STATUS: "status",
        TYPE: "type",
    },
    LOCATION_FEATURE: {
        DISPLAY: "video",
        CAMERA: "vtc",
        RESTRICTED: "Restricted",
        HAND_MANAGED: "HandManaged",
    },
    LOCATION_MODAL_TYPE: {
        ADD: "ADD",
        UPDATE: "UPDATE",
    },
    LOCATION_PROXIMITY: {
        IN: "in",
        INCLUDE_NEARBY: "and nearby",
    },
    LOCATION_DEFAULTS: {
        MIN_CAPACITY: 1,
        FLOOR: undefined, // any floor
        RESOURCE_LIST: [], // no resource required
        RESTRICTED_ALLOWED: false,
        HIDDEN_ALLOWED: false,
        THIS_BUILDING_ONLY: true,
    },
    ARIA_LABELS: {
        BUILDING: "Building",
        FLOOR: "Floor",
        ROOM: "Room",
        LOCATION: "Location",
    },
    ERRORS: {
        INVALID_BUILDING: "invalid_building",
        INVALID_BUILDING_ID: "invalid-building-id",

        INVALID_FLOOR: "invalid_floor",
        INVALID_FLOOR_ID: "invalid-floor-id",
        INVALID_FLOOR_LIST: "invalid_floor_list",

        INVALID_LOCATION: "invalid_location",
        INVALID_LOCATION_ID: "invalid-location-id",

        INVALID_ROOM_LIST: "invalid_room_list",

        BUILDING_WITHOUT_ROOMS: "building_without_rooms",
        BUILDING_WITHOUT_ROOMS_ID: "building-without-rooms-id",
    },
    LOCATION_FLOOR_ANY: -999,
    LOCATION_MAX_CAPACITY: 999,
    DEFAULT_FEATURE_ICON_SIZE: "18px",
    USER_DEFAULT_BUILDING: "SEA41",
    USER_DEFAULT_FLOOR: 8,
};