import padStart from "lodash/padStart"
import addSeconds from "date-fns/addSeconds"
import { createDateAtTime } from "../../_utils/time-string"

const toDateTimeString = dateObject =>
  `${padStart(dateObject.getHours(), 2, "0")}:${padStart(
    dateObject.getMinutes(),
    2,
    "0"
  )}:${padStart(dateObject.getSeconds(), 2, "0")}`

/**
 * Options for the timeRange method
 *
 * @typedef timeRangeOptions
 * @type {object}
 * @property {string} start - The first time slot. Time should be passed as a
 *   "HH:mm:ss" string, for example "06:15:10".
 * @property {string} end - The last possible time slot (rendered as an option if
 *   `step` steps on this exact time). Time should be passed as a "HH:mm:ss" string,
 *   for example "20:15:10".
 * @property {number} [step=3600] - The steps in seconds between options. Defaults to
 *   3600 seconds (1 hour).
 */

/**
 *
 * @param {timeRangeOptions} options - Options that determine how the range is
 *   generated.
 * @returns {string[]} - Array of times in the format "HH:mm:ss"
 */
const timeRange = ({ start: startString, end: endString, step = 3600 }) => {
  const start = toDateTimeString(createDateAtTime(startString))
  const end = toDateTimeString(createDateAtTime(endString))
  const times = []
  let time = start
  while (time <= end) {
    times.push(time)
    const dateObject = createDateAtTime(time)
    let nextTime = toDateTimeString(addSeconds(dateObject, step))
    // if we've looped to the next day, break
    if (nextTime < time) break

    // DST fix -- if time === nextTime (say, because there are two 2 AMs), take a double-step
    if (nextTime === time) {
      nextTime = toDateTimeString(addSeconds(dateObject, step * 2))
    }
    time = nextTime
  }
  return times
}

export default timeRange
