import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import escapeRegExp from "lodash/escapeRegExp"
import clockTokens from "@amzn/meridian-tokens/base/icon/clock"
import timeRange from "./time-range"
import Select, { SelectOption } from "../select"
import SelectContext from "../select/context"
import dateLocales from "../../_utils/date-locales"
import { getTimeLabel } from "../../_utils/time-string"

const TimeSelect = React.forwardRef((props, ref) => {
  const { showIcon, options, locale, format, disabledOptions } = props
  const [query, setQuery] = useState()

  const suffixIconTokens = useMemo(() => (showIcon ? clockTokens : {}), [
    showIcon,
  ])

  const timeOptions = useMemo(
    () =>
      options.map(time => ({
        time,
        label: getTimeLabel({ time, locale, format }),
        disabled: disabledOptions ? disabledOptions(time) : false,
      })),
    [options, locale, format, disabledOptions]
  )

  const matchedOptions = timeOptions.filter(
    option => !query || new RegExp(escapeRegExp(query), "i").test(option.label)
  )

  return (
    <SelectContext.Provider value={{ suffixIconTokens }}>
      <Select
        ref={ref}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        placeholder={props.placeholder}
        label={props.label}
        size={props.size}
        searchQuery={query}
        onSearch={setQuery}
        width={props.width}
        minWidth={props.minWidth}
        popoverMaxHeight={props.popoverMaxHeight}
        error={props.error}
        suffix={props.suffix}
        id={props.id}
        aria-labelledby={props["aria-labelledby"]}
        aria-describedby={props["aria-describedby"]}
        aria-required={props["aria-required"]}
        aria-invalid={props["aria-invalid"]}
      >
        {!matchedOptions.length ? props.children : null}
        {matchedOptions.map(option => (
          <SelectOption
            key={option.time}
            value={option.time}
            label={option.label}
            disabled={option.disabled}
          />
        ))}
      </Select>
    </SelectContext.Provider>
  )
})

TimeSelect.displayName = "TimeSelect"

TimeSelect.propTypes = {
  /**
   * Rendered when a user inputs a filter that returns no options.
   *
   * ```
   * <TimeSelect value={value} onChange={setValue}>
   *   <Column alignmentVertical="center" spacingInset="xlarge">
   *     <Text alignment="center">No results</Text>
   *   </Column>
   * </TimeSelect>
   * ```
   */
  children: PropTypes.node,
  /**
   * Use this attribute to associate a text label with a TimeSelect component.
   * It accepts the id of a label element. This label only needs the context.
   * The selected value will be appended to this label by this component automatically.
   * Note: It appears the placeholder prop must have a value for this to work.
   *
   * @since 5.x
   */
  "aria-labelledby": PropTypes.string,
  /**
   * Use this attribute to pass a space separated list of ids that you would like to use to
   * describe this TimeSelect component.
   * ex: This can be used to associate error labels to this component so that users will understand
   * when this component has a bad value
   *
   * @added by meetex team member (camei@)
   */
  "aria-describedby": PropTypes.string,
  /**
   * Use this attribute to indicate that user input is required on the element before a form may be submitted.
   * ex: set this to true when there is a required field
   *
   * @added by meetex team member (kjoshuaz@)
   */
  "aria-required": PropTypes.string,
  /**
   * Use this attribute to indicate the entered value does not conform to the format expected by the application.
   * ex: set this to true when there is a required field missing
   *
   * @added by meetex team member (kjoshuaz@)
   */
  "aria-invalid": PropTypes.string,
  /**
   * This disables interaction with the component and applies special visual
   * styles to indicate that it's not interactive.
   */
  disabled: PropTypes.bool,
  /**
   * A function that can be used to disable specific times. The function will
   * be passed time strings in the format HH:MM:ss and is expected to return
   * true for disabled times and false otherwise.
   */
  disabledOptions: PropTypes.func,
  /**
   * Show the component in an error state. Use this to indicate that the value
   * of the component is invalid.
   *
   * Consider pairing this state with a small error `Alert` component below
   * or to the right of the component explaining what the error is.
   */
  error: PropTypes.bool,
  /**
   * The format of the time. See [Intl.DateTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat) for available values.
   *
   * NOTE: This cannot be changed after the component is mounted.
   */
  format: PropTypes.object,
  /**
   * Sets an `id` attribute on the component's form element.
   *
   * Use this for testing or for accessibility. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   */
  id: PropTypes.string,
  /**
   * A label for the input.
   *
   * If this prop is provided the `size` prop will be ignored and set to `xlarge`.
   */
  label: PropTypes.string,
  /**
   * The locale to use when rendering times. See the [Intl API](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl) for more information
   * about date internationalization in JS.
   */
  locale: PropTypes.oneOf(Object.keys(dateLocales)),
  /**
   * Set the minimum width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set of options in the format "HH:mm:ss" (ex. ["00:30:00", "23:00:45"])
   */
  options: PropTypes.arrayOf(PropTypes.string),
  /**
   * Text that is shown in place of the select's value when nothing is selected.
   *
   * This text is not visible when an option is selected, so it should
   * not contain essential information that should be persisted. For that,
   * consider using the `label` prop.
   */
  placeholder: PropTypes.string,
  /**
   * Sets the maximum height of the popover containing the select's options.
   * This is helpful if you have a very long list of options but don't want
   * to show a very tall popover. The option list is scrollable, so any
   * options that overflow the popover will still be accessible.
   */
  popoverMaxHeight: PropTypes.number,
  /**
   * Set to false to hide the clock icon from the input.
   */
  showIcon: PropTypes.bool,
  /**
   * Sets the size of the component using a preset.
   *
   * If the `label` prop is provided this prop will be ignored (the label
   * requires a particular amount of space which is not configurable).
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  /**
   * Static text to show in an end-cap appended to the component.
   *
   * To add a selectable suffix, see the `InputGroup` component.
   *
   * @since 5.x
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The value of the currently selected time option in the format HH:mm:ss (ex. "23:50:10")
   *
   * Update this when `onChange` is called to ensure the component is interactive
   * (see the documentation for the `onChange` prop).
   */
  value: PropTypes.string,
  /**
   * Set the width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * A function that will be called when the user attempts to change the value
   * of the component (e.g. by selecting an option). The new value will be
   * passed as the first argument to this function.
   *
   * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
   * so you must track the values provided to this function somewhere in state
   * and pass them back via the `value` prop in order to make this component
   * interactive.
   */
  onChange: PropTypes.func,
}

TimeSelect.defaultProps = {
  format: { hour: "2-digit", minute: "2-digit" },
  // 1 hour increments from 12am to 11pm
  options: timeRange({ start: "00:00:00", end: "23:00:00" }),
  showIcon: true,
  size: "medium",
  locale: "en-US",
}

export default TimeSelect
