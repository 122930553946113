import Text from "@amzn/meridian/text";
import TimeSelect from "../../shared/meridian-custom-components/src/components/time-select";
import Row from "@amzn/meridian/row";
import React, {useState} from "react";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";
import { getDefaultTimeFormat } from "../../shared/settings/settings-utils";

const ReservationTimeSelector = (props) => {
    const timeFormat = props.timeFormat;
    const [selectedEndTime, setSelectedEndTime] = useState(props.selectedEndTime);
    const [selectedStartTime, setSelectedStartTime] = useState(props.selectedStartTime);

    const FROM_LABEL_ID = "from-label-id";
    const TO_LABEL_ID = "to-label-id";

    const onSetSelectedStartTime = (newStartTime) => {
        setSelectedStartTime(newStartTime);
        let startIndex = props.timeOptions.indexOf(newStartTime);
        if (newStartTime >= selectedEndTime) {
            setSelectedEndTime(props.timeOptions[startIndex + 1]);
            props.setSelectedEndTime(props.timeOptions[startIndex + 1]);
        }
        props.setSelectedStartTime(newStartTime);
    };

    const onSetSelectedEndTime = (newEndTime) => {
        setSelectedEndTime(newEndTime);
        props.setSelectedEndTime(newEndTime);
        if (newEndTime <= selectedStartTime) {
            let endTimeIndex = props.timeOptions.indexOf(newEndTime);
            setSelectedStartTime(props.timeOptions[endTimeIndex - 1]);
        }
    };

    return (
        <Row spacing={"xsmall"} widths="fit" alignmentHorizontal="left" wrap="down">
            <Text id={FROM_LABEL_ID}>From </Text>
            <Row spacing="xsmall" widths="fit">
                <TimeSelect
                    onChange={onSetSelectedStartTime}
                    size="small"
                    options={props.timeOptions.slice(0, -1)}
                    format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                    width={125}
                    value={selectedStartTime}
                    popoverMaxHeight={305}
                    aria-labelledby={FROM_LABEL_ID}
                />
                <Text id={TO_LABEL_ID}>to</Text>
                <TimeSelect
                    value={selectedEndTime}
                    onChange={onSetSelectedEndTime}
                    size="small"
                    options={props.endTimeOptions}
                    format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                    width={125}
                    popoverMaxHeight={305}
                    aria-labelledby={TO_LABEL_ID}
                />
                <Text>{props.timezoneName}</Text>
            </Row>
        </Row>
    )
};

export default ReservationTimeSelector;