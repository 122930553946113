import { call, put, takeLatest } from "redux-saga/effects";

import { createChimePin, getUserByEmail } from "../../../api/apig/ras";
import { CREATE_CHIME_PIN, GET_USER_BY_EMAIL } from "../actions/action-types";
import { saveChimePin, saveGetUserByEmail } from "../actions";
import { showToast } from "../../toasts/actions";

import { isErrorResponse } from "../../../api/apig/apig-utils";
import { getErrorToast, getErrorToastWithComponent } from "../../toasts/toast-utils";
import { TIMEOUT, TOAST_COMPONENT } from "../../toasts/toast-constants";

export function* watchGetUserByEmail() {
    yield takeLatest(GET_USER_BY_EMAIL, getUserByEmailApi);
}

export function* getUserByEmailApi(action) {
    const response = yield call(getUserByEmail, action.getUserByEmailRequest);
    const userDetails = JSON.parse(response);
    if (isErrorResponse(userDetails)) {
        const toast = getErrorToastWithComponent();
        toast.toastActionProps.text = `There is an issue retrieving the Chime information for ${action.getUserByEmailRequest.userEmail}. To resolve, please visit the ` ;
        toast.toastActionProps.linkText = "FAQ related to Amazon Chime ";
        toast.toastActionProps.href = "https://w.amazon.com/bin/view/Meetex/AmazonMeetings/help/chime_related/";
        toast.toastActionProps.componentName = TOAST_COMPONENT.LINK;
        toast.toastTimeout = TIMEOUT.NEVER;
        yield put(showToast(toast));
        return;
    }
    yield put(saveGetUserByEmail(userDetails));
}

export function* watchCreateChimePin() {
    yield takeLatest(CREATE_CHIME_PIN, createChimePinApi);
}

export function* createChimePinApi(action) {
    const response = yield call(createChimePin, action.createChimePinRequest);
    const uniqueIds = JSON.parse(response);
    if (isErrorResponse(uniqueIds)) {
        const toast = getErrorToast("An error occurred when creating a chime pin.");
        yield put(showToast(toast));
        return;
    }

    yield put(saveChimePin(uniqueIds));
}

const sagas = [
    watchGetUserByEmail(),
    watchCreateChimePin(),
];

export default sagas;