import React from "react";

import Column from "@amzn/meridian/column";
import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import differenceInMinutes from "date-fns/differenceInMinutes";
import parse from "date-fns/parse";

import CalendarDatePicker from "./calendar-date-picker";
import CalendarWeeklyView from "./calendar-weekly-view";
import MeetingAgenda from "../../shared/components/meeting-agenda";
import { SINGLE_DAY_VIEW_THRESHOLD, CALENDAR_MODE } from "../calendar-constants";

const formatIsoDate = (date) => format(date, "yyyy-MM-dd");
const formatIsoTime = (date) => format(date, "HH:mm");

const Calendar = (props) => {
    const events = meetingsToCalendarEvents(props.meetings, props.calendarStatusFilter, props.calendarResponseFilter, props.timezone);
    const timeRangeStart = (props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || props.viewType === "day") ?
        props.date : formatIsoDate(startOfWeek(parse(props.date, "yyyy-MM-dd", new Date())));
    const {earliestStartTimes, latestEndTimes, allDayEvents, overlappingEvents} = processCalendarData(events, props.date, props.screenSizeBreakpoint, props.viewType);
    const calendarMode = props.calendarMode || CALENDAR_MODE.DEFAULT;
    const timeFormat = props.timeFormat;
    return (
        <React.Fragment>
            <Column heights={["fit", "fill"]} height="100%" width="100%">
                <CalendarDatePicker
                    date={props.date}
                    onSetCalendarDate={props.onSetCalendarDate}
                    viewType={props.viewType}
                    onCalendarOptionsModalOpen={props.onCalendarOptionsModalOpen}
                    screenSizeBreakpoint={props.screenSizeBreakpoint}
                    setTriggerRefresh={props.setTriggerRefresh}
                    showRefreshAlert={props.showRefreshAlert}
                    setShowRefreshAlert={props.setShowRefreshAlert}
                    calendarMode={calendarMode}
                    boundByDateRange={props.boundByDateRange}
                    createPollCalendar={props.createPollCalendar}
                    workflow={props.workflow}
                />
                {(props.viewType === "day" || props.viewType === "workweek" || props.viewType === "week") &&
                    <CalendarWeeklyView
                        meetingListLoaded={props.meetingListLoaded}
                        events={events}
                        allDayEvents={allDayEvents}
                        overlappingEvents={overlappingEvents}
                        startingWorkHours={props.selectedStartTime ? {week: parseInt(props.selectedStartTime.substring(0, 2))} : earliestStartTimes}
                        endingWorkHours={props.selectedEndTime ? {week: getEndTimeBasedOnMinutes(props.selectedEndTime)} : latestEndTimes}
                        date={timeRangeStart}
                        userEmail={props.userEmail}
                        viewType={props.viewType}
                        daysOfWeek={props.daysOfWeek}
                        screenSizeBreakpoint={props.screenSizeBreakpoint}
                        trimEvents={props.trimEvents}
                        calendarMode={calendarMode}
                        availabilityBlocks={props.availabilityBlocks}
                        onUpdateAvailability={props.onUpdateAvailability}
                        availabilityMinDuration={props.availabilityMinDuration}
                        timeSlotDuration={props.timeSlotDuration}
                        selectedTimeCellIds={props.selectedTimeCellIds}
                        setSelectedTimeCellIds={props.setSelectedTimeCellIds}
                        selectedTimeSlots={props.selectedTimeSlots}
                        setSelectedTimeSlots={props.setSelectedTimeSlots}
                        setMaxTimeSlotsWarningMessage={props.setMaxTimeSlotsWarningMessage}
                        timeFormat={timeFormat}
                        timezone={props.timezone}
                    />
                }
                {props.viewType === "agenda" &&
                    <MeetingAgenda
                        meetingListLoaded={props.meetingListLoaded}
                        meetingList={events}
                        emptyDueToFilters={isObjectEmpty(events) && props.meetings.length > 0}
                        isCalendarView={true}
                        userEmail={props.userEmail}
                        screenSizeBreakpoint={props.screenSizeBreakpoint}
                        page="calendar"
                        timeFormat={timeFormat}
                        primaryTimezone={props.primaryTimezone}
                        timezone={props.timezone}
                        workflow={props.workflow}
                    />
                }
            </Column>
        </React.Fragment>
    );
};

// Parse findMeetings response into a map of events separated per day. Also filter the events based on the calendar filter
const meetingsToCalendarEvents = (meetings, calendarStatusFilter, calendarResponseFilter, timezone) => {
    let events = {};
    meetings.forEach((meeting) => {
        let meetingSubject = meeting.subject || "";
        // Filter out meetings whose status is not present in the active filters
        if (calendarStatusFilter.indexOf(meeting.status) === -1 ||
           (calendarStatusFilter.indexOf("canceled") === -1 && meetingSubject.startsWith("Canceled"))
        ) {
            return;
        }
        // Do not filter out meetings that do not have a response. These are meeting blocks without attendees
        if (meeting.response) {
            // Filter out meetings whose response is not present in the active filters
            if ((calendarResponseFilter.indexOf(meeting.response) === -1 && meeting.response !== "organizer") ||
                (calendarResponseFilter.indexOf("accept") === -1 && meeting.response === "organizer")) {
                return;
            }
        }

        let startTime = new Date(meeting.time.startTime * 1000);
        let endTime = new Date(meeting.time.endTime * 1000);

        if (timezone) {
            // Convert the meeting time to the specified timezone
            startTime = new Date(startTime.toLocaleString("en-US", {
                            timeZone: timezone,
                        }));
            endTime = new Date(endTime.toLocaleString("en-US", {
                            timeZone: timezone,
                        }));
        }

        let durationInHours = differenceInMinutes(endTime, startTime) / 60;
        let oneDayOrLonger = durationInHours >= 24;
        let isAllDayEvent = meeting.isAllDayEvent || oneDayOrLonger;
        if (isAllDayEvent) {
            // Round startTime down to previous midnight
            startTime = new Date(new Date(startTime.getTime()).setHours(0, 0, 0, 0));
            // If endTime is not midnight, round endTime up to next midnight
            // I am choosing to ignore seconds and milliseconds
            if (endTime.getHours() !== 0 || endTime.getMinutes() !== 0) {
                endTime = new Date(new Date(endTime.getTime()).setHours(24, 0, 0, 0));
            }
            // Recalculate the duration
            durationInHours = differenceInMinutes(endTime, startTime) / 60;
        } else if (startTime.getDay() !== endTime.getDay()) {
            // Round endTime to the next midnight after startTime
            endTime = new Date(new Date(startTime.getTime()).setHours(24, 0, 0, 0));
            // Recalculate the duration
            durationInHours = differenceInMinutes(endTime, startTime) / 60;
        }

        let startDate = formatIsoDate(startTime);
        if (!events.hasOwnProperty(startDate)) {
            events[startDate] = [];
        }
        events[startDate].push({
            startTime: {
                time: formatIsoTime(startTime),
                day: startTime.getDay(),
                hour: startTime.getHours(),
                minute: startTime.getMinutes(),
                timestamp: startTime.getTime()
            },
            endTime: {
                time: formatIsoTime(endTime),
                day: endTime.getDay(),
                hour: endTime.getHours(),
                minute: endTime.getMinutes(),
                timestamp: endTime.getTime()
            },
            time: meeting.time,
            subject: meetingSubject,
            location: meeting.location,
            entryID: meeting.entryID,
            response: meeting.response,
            status: meeting.status,
            duration: durationInHours,
            isAllDayEvent: isAllDayEvent,
            isPrivate: meeting.isPrivate,
            isRecurring: meeting.isRecurring,
        });
    });
    return events;
};

/**
 * Calculate the following data to render the calendar:
 * - Earliest meeting that is not an allDay event
 * - End time of the latest meeting that is not an allDay event
 * - List of allDay events
 * - Meeting overlap data
 */
const processCalendarData = (events, calendarDate, screenSizeBreakpoint, viewType) => {
    const dates = Object.keys(events).sort();
    // TODO: Get this hours from exchange work hours
    let defaultEarliestHour = 9;
    let defaultLatestHour = 17;
    let earliestStartTimes = {week: defaultEarliestHour};
    let latestEndTimes = {week: defaultLatestHour};
    let allDayEvents = {};
    let overlappingEvents = {};

    dates.forEach((date) => {
        let dateEvents = events[date];
        overlappingEvents[date] = {};
        earliestStartTimes[date] = defaultEarliestHour;
        latestEndTimes[date] = defaultLatestHour;
        dateEvents.forEach((event) => {
            if (event.isAllDayEvent) {
                if (!allDayEvents.hasOwnProperty(date)) {
                    allDayEvents[date] = [];
                }
                if (screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || viewType === "day") {
                    let parsedDate = parse(calendarDate, "yyyy-MM-dd", new Date());
                    if (event.startTime.timestamp < parsedDate.getTime() && event.endTime.timestamp > parsedDate.getTime()) {
                        if (!allDayEvents.hasOwnProperty(calendarDate)) {
                            allDayEvents[calendarDate] = [];
                        }
                        allDayEvents[calendarDate].push(event);
                    }
                }
                allDayEvents[date].push(event);
                return;
            }

            // Time windows for the current day only
            if (event.startTime.hour < earliestStartTimes[date]) {
                earliestStartTimes[date] = event.startTime.hour;
            }
            if (event.endTime.hour > latestEndTimes[date]) {
                latestEndTimes[date] = event.endTime.hour;
            }
            // Time window for the whole week
            if (event.startTime.hour < earliestStartTimes.week) {
                earliestStartTimes.week = event.startTime.hour;
            }
            if (event.endTime.hour > latestEndTimes.week) {
                latestEndTimes.week = event.endTime.hour;
            }

            // If the meeting does not end on the same day, set the latest end time to midnight
            if (event.startTime.day !== event.endTime.day) {
                latestEndTimes[date] = 23;
                latestEndTimes.week = 23;
            }

            let previousOverlappingMeetings = 0;
            let maxPreviousPadding = 0;
            let meetingsOverlapping = [];
            let maxOverlap = 1;
            for (let meetingId in overlappingEvents[date]) {
                let meeting = overlappingEvents[date][meetingId];
                if (!meeting) return;
                // If meeting overlaps with any of the previously checked meetings
                if (doMeetingsOverlap(event, meeting)) {
                    meetingsOverlapping.push(meeting.meetingID);
                    previousOverlappingMeetings++;
                    // If the overlap was already processed by transitivity, we can skip
                    if (overlappingEvents[date][meetingId] &&
                        overlappingEvents[date][meetingId].meetingsOverlapping &&
                        overlappingEvents[date][meetingId].meetingsOverlapping.indexOf(event.entryID) !== -1) {
                        continue;
                    }

                    overlappingEvents[date][meetingId].overlappingMeetings++;

                    // Add transitive overlaps. If meeting A overlaps with B and B with C, add overlap between A and C.
                    // This only goes one level of adjacency, we probably need to implement a per day time matrix
                    // or a recursive method to address all the edge cases of multiple meetings overlapping at the same time.
                    overlappingEvents[date][meetingId].meetingsOverlapping.forEach((adjacentMeetingId) => {
                        if (overlappingEvents[date][adjacentMeetingId].meetingsOverlapping.indexOf(event.entryID) === -1) {
                            overlappingEvents[date][adjacentMeetingId].meetingsOverlapping.push(event.entryID);
                            overlappingEvents[date][adjacentMeetingId].overlappingMeetings++;
                        }
                    });

                    overlappingEvents[date][meetingId].meetingsOverlapping.push(event.entryID);
                    maxPreviousPadding = Math.max(maxPreviousPadding, overlappingEvents[date][meetingId].additionalPadding);
                    maxOverlap = Math.max(maxOverlap, overlappingEvents[date][meetingId].overlappingMeetings);
                }
            }

            overlappingEvents[date][event.entryID] = {
                startTime: event.startTime,
                endTime: event.endTime,
                meetingID: event.entryID,
                additionalPadding: maxPreviousPadding + previousOverlappingMeetings,
                overlappingMeetings: maxOverlap,
                subject: event.subject,
                meetingsOverlapping: meetingsOverlapping,
                duration: event.duration
            };
        });
    });

    return {
        earliestStartTimes,
        latestEndTimes,
        allDayEvents,
        overlappingEvents
    };
};

// This method includes some relaxation to count overlap between meetings shorter than 15 minutes
// since 15 minutes is the minimum size of tiles being rendered in the calendar.
const doMeetingsOverlap = (meeting, meetingToCompare) => {
    let meetingStart = meeting.startTime.timestamp;
    let meetingEnd = meeting.endTime.timestamp;
    let meetingToCompareStart = meetingToCompare.startTime.timestamp;
    let meetingToCompareEnd = meetingToCompare.endTime.timestamp;

    if (meeting.duration < 0.25) {
        meetingEnd += Math.round((0.25 - meeting.duration) * 60 * 1000);
    }
    if (meetingToCompare.duration < 0.25) {
        meetingToCompareEnd += Math.round((0.25 - meetingToCompare.duration) * 60 * 1000);
    }

    return meetingStart < meetingToCompareEnd && meetingToCompareStart < meetingEnd;
};

const isObjectEmpty = (object) => {
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }

    return true;
};

// Check the end time for the calendar and see if it spans some minutes over the hour or the time ends at the hour o'clock
const getEndTimeBasedOnMinutes = (selectedEndTime) => {
    let [endHour, endMinutes] = selectedEndTime.split(":").map((timePart) => parseInt(timePart));
    return endMinutes > 0 ? endHour : endHour - 1;
}

export default Calendar;
