import React from "react";

import Icon from "@amzn/meridian/icon";

import checkKnockoutTokens from "@amzn/meridian-tokens/base/icon/check-knockout";
import helpKnockoutTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import minusKnockoutTokens from "@amzn/meridian-tokens/base/icon/minus-knockout";
import closeKnockoutTokens from "@amzn/meridian-tokens/base/icon/close-knockout";

import { STATUS_HIGHLIGHT_COLOR } from "./meeting-status-constants";

export const getMeetingResponseIcon = (response, isCanceled, statusText = "") => {
    if (isCanceled) {
        return (<span>{statusText} <Icon tokens={closeKnockoutTokens} /></span>);
    }

    switch (response) {
        case "accept":
        case "organizer":
            return (<span>{statusText} <Icon tokens={checkKnockoutTokens} /></span>);
        case "tentative":
            return (<span>{statusText} <Icon tokens={helpKnockoutTokens} /></span>);
        case "decline":
            return (<span>{statusText} <Icon tokens={closeKnockoutTokens} /></span>);
        case "noResponse":
        case "notResponded":
        default:
            return (<span>{statusText} <Icon tokens={minusKnockoutTokens} /></span>);
    }
};

export const getAttendeeResponseIcon = (response) => {
    switch (response) {
        case "accept":
        case "organizer":
            return (<div style={{position:"absolute", right:"0px", bottom:"0px", color:"green"}}><Icon tokens={checkKnockoutTokens} /></div>);
        case "tentative":
            return (<div style={{position:"absolute", right:"0px", bottom:"0px", color:"black"}}><Icon tokens={helpKnockoutTokens} /></div>);
        case "decline":
            return (<div style={{position:"absolute", right:"0px", bottom:"0px", color:"red"}}><Icon tokens={closeKnockoutTokens} /></div>);
        case "noResponse":
        case "notResponded":
        default:
            return (<div style={{position:"absolute", right:"0px", bottom:"0px", color:"#077398"}}><Icon tokens={minusKnockoutTokens} /></div>);
    }
};

export const getMeetingStatusIcon = (response, isCanceled) => {
    if (isCanceled) {
        return (<div style={{backgroundColor: STATUS_HIGHLIGHT_COLOR.CANCELED, display: "inline", width: "20px", height: "20px", borderRadius: "50%"}} />);
    }
    
    const border = "0.5px solid #485050"; // Gray-600
    switch (response) {
        case "busy":
            return (<div style={{backgroundColor: STATUS_HIGHLIGHT_COLOR.BUSY, display: "inline", width: "20px", height: "20px", borderRadius: "50%"}} />);
        case "tentative":
            return (<div style={{backgroundColor: STATUS_HIGHLIGHT_COLOR.TENTATIVE, display: "inline", width: "20px", height: "20px", borderRadius: "50%" }} />);
        case "free":
            return (<div style={{backgroundColor: STATUS_HIGHLIGHT_COLOR.FREE, border, display: "inline", width: "20px", height: "20px", borderRadius: "50%" }} />);
        case "outOfOffice":
            return (<div style={{backgroundColor: STATUS_HIGHLIGHT_COLOR.OOTO, display: "inline", width: "20px", height: "20px", borderRadius: "50%" }} />);
        case "canceled":
        default:
            return (<div style={{backgroundColor: STATUS_HIGHLIGHT_COLOR.CANCELED, border, display: "inline", width: "20px", height: "20px", borderRadius: "50%"}} />);
    }
};

export const getMeetingStatusColor = (status, isCanceled) => {
    if (isCanceled) {
        return STATUS_HIGHLIGHT_COLOR.CANCELED;
    }
    switch (status) {
        case "busy":
            return STATUS_HIGHLIGHT_COLOR.BUSY;
        case "canceled":
            return STATUS_HIGHLIGHT_COLOR.CANCELED;
        case "free":
            return STATUS_HIGHLIGHT_COLOR.FREE;
        case "available":
            return STATUS_HIGHLIGHT_COLOR.AVAILABLE;
        case "gridFree":
        case "meetingHours":
            return STATUS_HIGHLIGHT_COLOR.GRID_FREE;
        case "outOfOffice":
            return STATUS_HIGHLIGHT_COLOR.OOTO;
        case "restricted":
            return STATUS_HIGHLIGHT_COLOR.RESTRICTED;
        case "noData":
            return STATUS_HIGHLIGHT_COLOR.NO_DATA;
        case "tentative":
        default:
            return STATUS_HIGHLIGHT_COLOR.TENTATIVE;
    }
};

export const getResponseLabel = (response, isCanceled) => {
    if (isCanceled) {
        return "Canceled";
    }
    switch (response) {
        case "organizer":
            return "Organizer";
        case "accept":
            return "Attending";
        case "tentative":
            return "Tentative";
        case "notResponded":
            return "Not responded";
        default:
            return "";
    }
};