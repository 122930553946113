module.exports = {
    AUTO_RELEASED: "AutoReleased",
    CHECKED_IN: "CheckedIn",
    RELEASED: "Released",
    PENDING: "Pending",
    NOT_REQUIRED: "NotRequired",
    CHECK_IN_ICON_TOOL_TIP: "Check-in required room pending confirmation",
    CHECK_IN_BUTTON_TOOL_TIP: "Check-in confirmation not required",
    CHECK_IN_FEATURE_ENABLED: true,
    CHECK_IN_ERROR: {
        // MCI Error Messages: https://code.amazon.com/packages/MeetExMciApiModel/blobs/9f73a1a75db3d0f9a5cf705796ac4c34a961a2c4/--/src/com/amazon/meetexmciapimodel/model/Constants.java#L41-L56
        MEETING_NOT_FOUND: "Error: Meeting not found",
    }
};