import { call, put, retry, select } from "redux-saga/effects";
import aws4 from "aws4";

import { getIdentityTokens, getSigningCredentials } from "../../../../sagas/selector";
import { ENDPOINTS, OPERATIONS, OPERATION_PATHS } from "./endpointConfig";
import {
    cleanEmpty,
    fetchRequest,
    getErrorResponse,
    isRetryableError,
    stringifyError,
} from "../apig-utils";
import { getStageConfig } from "../../stageConfig/stage-config-utils";
import { postAMSAPICallMetric } from "../../../shared/metrics/actions";
import { MAX_ATTEMPTS, RETRY_DELAY_IN_MS } from "../apig-constants";

export function* getUserFavorites(requestedOnBehalfOf) {
    console.log("Calling getUserFavorites");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_USER_FAVORITES, {
        requestedOnBehalfOf,
        delegator: requestedOnBehalfOf,
        retrieve: "favorites",
    }]);
}

export function* getUserPreferences(requestedOnBehalfOf) {
    console.log("Calling getUserPreferences");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_USER_FAVORITES, {
        requestedOnBehalfOf,
        delegator: requestedOnBehalfOf,
        retrieve: "preferences",
    }]);
}

export function* getUserPreferencesByUserAlias(requestedOnBehalfOf, userAlias) {
    console.log("Calling getUserPreferencesByUserAlias", requestedOnBehalfOf, userAlias);
    return yield call(retryableMakeRequest, [OPERATIONS.GET_USER_FAVORITES, {
        requestedOnBehalfOf,
        delegator: userAlias,
        retrieve: "preferences",
    }]);
}

export function* saveUserFavorites(requestedOnBehalfOf, favorites) {
    console.log("Calling saveUserFavorites");
    return yield call(retryableMakeRequest, [OPERATIONS.SAVE_USER_FAVORITES, {
        requestedOnBehalfOf,
        delegator: requestedOnBehalfOf,
        favorites,
    }]);
}

export function* saveUserPreferences(requestedOnBehalfOf, preferences) {
    console.log("Calling saveUserPreferences");
    return yield call(retryableMakeRequest, [OPERATIONS.SAVE_USER_PREFERENCES, {
        requestedOnBehalfOf,
        delegator: requestedOnBehalfOf,
        preferences,
    }]);
}

export function* createPoll(createPollRequest) {
    console.log("Calling createPoll");
    return yield call(retryableMakeRequest, [OPERATIONS.CREATE_POLL, {
        ...createPollRequest
    }]);
}

export function* findPolls(requestedOnBehalfOf, identity, startTime, endTime, pollStatus) {
    console.log("Calling findPolls");
    return yield call(retryableMakeRequest, [OPERATIONS.FIND_POLLS, {
        requestedOnBehalfOf,
        identity,
        startTime,
        endTime,
        pollStatus
    }]);
}

export function* getAttendeePoll(requestedOnBehalfOf, pollID) {
    console.log("Calling getAttendeePoll");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_ATTENDEE_POLL, {
        requestedOnBehalfOf,
        pollID
    }]);
}

export function* getPoll(requestedOnBehalfOf, pollID) {
    console.log("Calling getPoll");
    return yield call(retryableMakeRequest, [OPERATIONS.GET_POLL, {
        requestedOnBehalfOf,
        pollID
    }]);
}

export function* deletePoll(deletePollRequest) {
    console.log("Calling deletePoll");
    return yield call(retryableMakeRequest, [OPERATIONS.DELETE_POLL, {
        ...deletePollRequest
    }]);
}

export function* respondPoll(requestedOnBehalfOf, pollID, availability) {
    console.log("Calling getPoll");
    return yield call(retryableMakeRequest, [OPERATIONS.RESPOND_POLL, {
        requestedOnBehalfOf,
        pollID,
        availability
    }]);
}

export function* updatePoll(updatePollRequest) {
    console.log("Calling updatePoll");
    return yield call(retryableMakeRequest, [OPERATIONS.UPDATE_POLL, {
        ...updatePollRequest
    }]);
}

export function* sendNotification(type, requestedOnBehalfOf, pollID, toAddresses, subject, body) {
    console.log("Calling sendNotification");
    return yield call(retryableMakeRequest, [OPERATIONS.SEND_NOTIFICATION, {
        type,
        requestedOnBehalfOf,
        pollID,
        toAddresses,
        subject,
        body
    }]);
}

export function* getFeedbackEligibility(Alias) {
    console.log(`Calling getFeedbackEligibility for ${Alias}`);
    return yield call(retryableMakeRequest, [OPERATIONS.GET_FEEDBACK_ELIGIBILITY, {
        Alias
    }]);
}

function* retryableMakeRequest(params) {
    let response;
    try {
        response = yield retry(MAX_ATTEMPTS, RETRY_DELAY_IN_MS, makeRequest, ...params);
    } catch (e) {
        console.error(`An error occurred in all ${MAX_ATTEMPTS} attempts to call ${params[0]}: `, e);
        const error = stringifyError(e);
        yield put(postAMSAPICallMetric(params[0], JSON.stringify({error, request: params[1]}), false));
        return getErrorResponse(e);
    }
    console.log(`${params[0]} called successfully.`);
    yield put(postAMSAPICallMetric(params[0]));
    return response;
}

function* makeRequest(operation, body) {
    // TODO: define a way to store/retrieve the current stage and region
    const stageConfig = yield getStageConfig();
    const stage = stageConfig.stage, region = "PDX";
    const endpoint = ENDPOINTS[stage][region];
    const operationPath = OPERATION_PATHS[operation];
    const { accessKeyId, secretAccessKey, sessionToken } = yield select(getSigningCredentials);

    const { idToken } = yield select(getIdentityTokens);

    const url = new URL(endpoint + operationPath);
    const opts = {
        method: "POST",
        host: url.hostname,
        path: url.pathname,
        service: "execute-api",
        headers: {
            "Content-Type": "application/json",
            "X-Amz-Cognito-Id-Token": idToken
        },
        region: "us-west-2",
        body: JSON.stringify(cleanEmpty(body))
    };
    aws4.sign(opts, { accessKeyId, secretAccessKey, sessionToken });

    try {
        return yield call(fetchRequest, {url, opts});
    } catch (e) {
        if (isRetryableError(e)) {
            throw e;
        }
        const error = stringifyError(e);
        yield put(postAMSAPICallMetric(operation, JSON.stringify({error, request: body}), false));
        return getErrorResponse(e);
    }
}