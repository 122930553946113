/**
 * Memoized access to Intl.DateTimeFormat.
 */

import isSameYear from "date-fns/isSameYear"
import isSameDay from "date-fns/isSameDay"
import { memoizeCurry } from "./functional"

const formatIncludesTime = format =>
  format && (format.hour || format.minute || format.second)

/**
 * Memoized access to the the Intl.DateTimeFormat API
 */
const intlDateTimeFormatCurried = memoizeCurry(
  (format, locale) => new Intl.DateTimeFormat(locale, format),
  1
)

/**
 * Format a date using the Intl.DateTimeFormat API. See
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 * for formatting options.
 *
 * NOTE: This has a few known limitations in IE11:
 *   - If the timeZone format option is set the date will be shown in UTC,
 *     regardless of the timezone requested.
 *   - Certain combinations of format options that IE11 doesn't support will
 *     result in the full ISO date being rendered (e.g.
 *     `{ weekday: "short", day: "numeric" }` will render as something like
 *     "Mon, October 29, 2018 12:00:00AM" for the locale en-US in IE11).
 */
const intlDateTimeFormat = (date, format, locale = "en-US") => {
  // HACK: IE11 will explode if timeZone is set to anything but UTC. Instead
  // of erroring out this code will just fall back to UTC in IE11. This is
  // better than crashing the app, but is obviously not ideal in terms of UX. We
  // should warn our users about this in our documentation and encourage them to
  // either drop IE11 support or consider a polyfill or alternate method of
  // formatting dates.
  if (format && format.timeZone && format.timeZone !== "UTC") {
    try {
      return intlDateTimeFormatCurried(format)(locale).format(date)
    } catch (e) {
      return (
        intlDateTimeFormatCurried({ ...format, timeZone: "UTC" })(
          locale
        ).format(date) + " UTC"
      )
    }
  } else {
    return intlDateTimeFormatCurried(format)(locale).format(date)
  }
}

/**
 * Format a date range using the Intl.DateTimeFormat API (uses some custom
 * logic as the Intl formatRange method is not well supported). See
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 * for formatting options.
 */
const intlDateTimeRangeFormat = (
  startDate,
  endDate,
  format,
  locale = "en-US"
) => {
  // If we were given the exact same date times, or date times on the same day
  // and we're not showing time components, then just render one.
  if (
    startDate === endDate ||
    (isSameDay(startDate, endDate) && !formatIncludesTime(format))
  ) {
    return [intlDateTimeFormat(startDate, format, locale), null]
  }
  // If we're showing dates (no time components) and the start & end dates fall
  // in the same year, don't show the year for the start date (e.g. show
  // "May 23 - May 25, 2020" instead of "My 23, 2020 - May 25, 2020").
  else if (isSameYear(startDate, endDate) && !formatIncludesTime(format)) {
    const startFormat = Object.assign({}, format, {
      year: undefined,
      era: undefined,
    })
    return [
      intlDateTimeFormat(startDate, startFormat, locale),
      intlDateTimeFormat(endDate, format, locale),
    ]
  }
  // If we're showing a time range that occurs on a single day, don't show the
  // timezone in the first date and don't show the date in the second date (e.g.
  // show "January 1, 2020, 8 AM - 10 AM PST" instead of
  // "January 1, 2020, 8 AM PST - January 1, 2020, 10 AM PST")
  else if (isSameDay(startDate, endDate) && formatIncludesTime(format)) {
    const startFormat = Object.assign({}, format, { timeZoneName: undefined })
    const endFormat = Object.assign({}, format, {
      weekday: undefined,
      year: undefined,
      month: undefined,
      day: undefined,
      era: undefined,
    })
    return [
      intlDateTimeFormat(startDate, startFormat, locale),
      intlDateTimeFormat(endDate, endFormat, locale),
    ]
  }
  // If none of our special cases matched just return each fully formed date
  else {
    return [
      intlDateTimeFormat(startDate, format, locale),
      intlDateTimeFormat(endDate, format, locale),
    ]
  }
}

/**
 * Format to parts a date using the Intl.DateTimeFormat API. See
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 * for formatting options.
 */
const intlDateTimeFormatToParts = (date, format, locale = "en-US") => {
  // HACK: IE11 does not have allow formatToParts. Instead of erroring out this
  // code will fall back to parsing via a regular expression on a time
  // formatted to "en-US" with 2-digit hour/minute/second
  const canDo = Intl.DateTimeFormat.prototype.formatToParts
  if (canDo) {
    return (
      intlDateTimeFormatCurried(format)(locale)
        .formatToParts(date)
        // HACK: Some implementations of the Intl API lower-case the "P" in
        // "dayPeriod" (looking at you Node...)
        .map(part =>
          part.type === "dayperiod" ? { ...part, type: "dayPeriod" } : part
        )
    )
  } else {
    const formattedString = intlDateTimeFormat(
      date,
      { hour: "2-digit", minute: "2-digit", second: "2-digit" },
      "en-US"
    ).replace(/\u200E/gi, "") // remove any hidden spaces that might throw off our regex https://stackoverflow.com/questions/60981701/ie11-intl-datetimeformat-output-wont-match-regex
    const [, hour, minute, second, dayPeriod] = formattedString.match(
      /(\d{2}):(\d{2}):(\d{2}) (\w{2})/i
    )
    return [
      { type: "hour", value: hour },
      { type: "literal", value: ":" },
      { type: "minute", value: minute },
      { type: "literal", value: ":" },
      { type: "second", value: second },
      { type: "literal", value: " " },
      { type: "dayPeriod", value: dayPeriod },
    ]
  }
}

/**
 * Get resolved options from a format/locale using the Intl.DateTimeFormat API. See
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
 * for options.
 */
const intlDateTimeFormatResolvedOptions = (format, locale = "en-US") => {
  // HACK: IE11 does not have allow resolvedOptions. Instead of erroring out
  // this code will fall back to the typical response for "en-US" with format
  // set to 2-digit hour/minute/second. Even if we can use resolvedOptions, we
  // merge in the default options because some environments may leave certain
  // options off (like hourCycle in Node...).
  const canDo = Intl.DateTimeFormat.prototype.resolvedOptions
  const defaultOptions = {
    locale: "en-US",
    calendar: "gregory",
    numberingSystem: "latn",
    timeZone: "UTC",
    hourCycle: "h12",
    is12HourTimeFormat: true,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 0,
  }
  if (canDo) {
    return {
      ...defaultOptions,
      ...intlDateTimeFormatCurried(format)(locale).resolvedOptions(),
    }
  } else {
    return defaultOptions
  }
}

export {
  intlDateTimeFormat,
  intlDateTimeRangeFormat,
  intlDateTimeFormatToParts,
  intlDateTimeFormatResolvedOptions,
}
