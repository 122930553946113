import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";

import MeetingsToaster from "../../shared/toasts/containers/toast-container";
import MeetingsFeedbackContainer from "../../shared/feedback/containers/meetings-feedback-container";
import { loadFloors } from "../../shared/locations/actions";
import { getBuildingsWithData } from "../../../sagas/selector";
import { BUILDING_COLUMNS, ROOM_COLUMNS } from "../meetings-rooms-constants";

const MeetingRoomsContainer = () => {
    const dispatch = useDispatch();

    const onLoadFloors = (buildingCode) => dispatch(loadFloors(buildingCode));

    const buildingList = useSelector(getBuildingsWithData);

    const [selectedBuildingCode, setSelectedBuildingCode] = useState(undefined);
    const [buildingFilterQuery, setBuildingFilterQuery] = useState("");
    const [roomFilterQuery, setRoomFilterQuery] = useState("");

    const [buildingsSortColumn, setBuildingsSortColumn] = useState(BUILDING_COLUMNS.BUILDING_CODE);
    const [buildingsSortDirection, setBuildingsSortDirection] = useState("ascending");
    const [roomsSortColumn, setRoomsSortColumn] = useState(ROOM_COLUMNS.NAME);
    const [roomsSortDirection, setRoomsSortDirection] = useState("ascending");

    useEffect(() => {
        document.title = "Rooms - Amazon Meetings";
    }, []);

    // Check if the string includes the query ignoring whitespace and casing
    const stringIncludesQuery = (stringToCheck, query) => {
        if (!stringToCheck || !query) {
            return false;
        }

        return stringToCheck.replace(/\s/g, "").toLowerCase().includes(query.replace(/\s/g, "").toLowerCase());
    };

    const getBuilding = (buildingCode) => {
        if (!buildingCode) {
            return undefined;
        }

        return buildingList?.find((building) => building.buildingCode === buildingCode);
    };

    const filteredBuildingList = buildingList?.filter((building) => {
            return buildingFilterQuery?.length < 1
                || stringIncludesQuery(building.buildingCode, buildingFilterQuery)
                || stringIncludesQuery(building.buildingName, buildingFilterQuery)
                || stringIncludesQuery(building.city, buildingFilterQuery)
                || stringIncludesQuery(building.country, buildingFilterQuery);
        });

    const roomList = getBuilding(selectedBuildingCode)?.roomList || [];
    const filteredRoomList = roomList.filter((room) => {
            return roomFilterQuery?.length < 1
                || stringIncludesQuery(room.buildingCode, roomFilterQuery)
                || stringIncludesQuery(room.name, roomFilterQuery)
                || stringIncludesQuery(room.email, roomFilterQuery)
                || stringIncludesQuery(room.city, roomFilterQuery);
        });

    const onGetRoomsForBuilding = (buildingCode) => {
        if (buildingCode && buildingCode !== selectedBuildingCode) {
            setSelectedBuildingCode(buildingCode);
            setBuildingFilterQuery(buildingCode);
            const building = getBuilding(buildingCode);
            if (building && !building.floorList) {
                onLoadFloors(buildingCode);
            }
        }
    };

    const onSortBuildings = (sortState) => {
        if (sortState) {
            setBuildingsSortColumn(sortState.sortColumn);
            setBuildingsSortDirection(sortState.sortDirection);
        }
    };

    const onSortRooms = (sortState) => {
        if (sortState) {
            setRoomsSortColumn(sortState.sortColumn);
            setRoomsSortDirection(sortState.sortDirection);
        }
    };

    const sortBuildings = (buildings, sortColumn, sortDirection) => {
        let sortResult = sortDirection === "ascending" ? -1 : 1;
        let attributeToCompare;

        switch (sortColumn) {
            case BUILDING_COLUMNS.BUILDING_NAME:
                attributeToCompare = "buildingName";
                break;
            case BUILDING_COLUMNS.CITY:
                attributeToCompare = "city";
                break;
            case BUILDING_COLUMNS.COUNTRY:
                attributeToCompare = "country";
                break;
            case BUILDING_COLUMNS.LATITUDE:
                attributeToCompare = "latitude";
                break;
            case BUILDING_COLUMNS.LONGITUDE:
                attributeToCompare = "longitude";
                break;
            case BUILDING_COLUMNS.BUILDING_CODE:
            default:
                attributeToCompare = "buildingCode";
                break;
        }

        return buildings.sort((building1, building2) => {
            if (building1[attributeToCompare] === undefined && building2[attributeToCompare] === undefined) {
                return 0;
            }
            if (building2[attributeToCompare] === undefined || building1[attributeToCompare] < building2[attributeToCompare]) {
                return sortResult;
            }
            if (building1[attributeToCompare] === undefined || building1[attributeToCompare] > building2[attributeToCompare]) {
                return -sortResult;
            }
            return 0;
        });
    };

    const sortRooms = (rooms, sortColumn, sortDirection) => {
        let sortResult = sortDirection === "ascending" ? -1 : 1;
        let attributeToCompare;

        switch (sortColumn) {
            case ROOM_COLUMNS.BUILDING_CODE:
                attributeToCompare = "buildingCode";
                break;
            case ROOM_COLUMNS.FLOOR:
                attributeToCompare = "floor";
                break;
            case ROOM_COLUMNS.CAPACITY:
                attributeToCompare = "capacity";
                break;
            case ROOM_COLUMNS.RESTRICTED:
                attributeToCompare = "restricted";
                break;
            case ROOM_COLUMNS.HAND_MANAGED:
                attributeToCompare = "managed";
                break;
            case ROOM_COLUMNS.PHONE:
                attributeToCompare = "phone";
                break;
            case ROOM_COLUMNS.RESOURCE_LIST:
                attributeToCompare = "resourceList";
                break;
            case ROOM_COLUMNS.CITY:
                attributeToCompare = "city";
                break;
            case ROOM_COLUMNS.HIDDEN:
                attributeToCompare = "hidden";
                break;
            case ROOM_COLUMNS.EMAIL:
                attributeToCompare = "email";
                break;
            case ROOM_COLUMNS.NAME:
            default:
                attributeToCompare = "name";
                break;
        }

        return rooms.sort((room1, room2) => {
            if (room1[attributeToCompare] === undefined && room2[attributeToCompare] === undefined) {
                return 0;
            }
            if (room2[attributeToCompare] === undefined || room1[attributeToCompare] < room2[attributeToCompare]) {
                return sortResult;
            }
            if (room1[attributeToCompare] === undefined || room1[attributeToCompare] > room2[attributeToCompare]) {
                return -sortResult;
            }
            return 0;
        });
    };

    return <Column>
        <Row
            type="outline"
            spacingInset="medium xsmall"
            alignmentVertical="top"
            alignmentHorizontal="left"
            widths="fit"
            wrap="down"
        >
            <Text type="h400">Buildings</Text>
            <Input
                value={buildingFilterQuery}
                onChange={setBuildingFilterQuery}
                type="text"
                placeholder="Filter buildings..."
                width="300px"
            />
            <Button onClick={() => setBuildingFilterQuery("")}>
                Clear filter
            </Button>
        </Row>
        {buildingList?.length > 0 ?
            <Table
                headerRows={1}
                stickyHeaderColumn={true}
                stickyHeaderRow={true}
                showStripes={true}
                spacing="small"
                sortColumn={buildingsSortColumn}
                sortDirection={buildingsSortDirection}
                onSort={onSortBuildings}
            >
                <TableRow>
                    <TableCell sortColumn={BUILDING_COLUMNS.BUILDING_CODE}>{BUILDING_COLUMNS.BUILDING_CODE}</TableCell>
                    <TableCell sortColumn={BUILDING_COLUMNS.BUILDING_NAME}>{BUILDING_COLUMNS.BUILDING_NAME}</TableCell>
                    <TableCell sortColumn={BUILDING_COLUMNS.CITY}>{BUILDING_COLUMNS.CITY}</TableCell>
                    <TableCell sortColumn={BUILDING_COLUMNS.COUNTRY}>{BUILDING_COLUMNS.COUNTRY}</TableCell>
                    <TableCell sortColumn={BUILDING_COLUMNS.LATITUDE}>{BUILDING_COLUMNS.LATITUDE}</TableCell>
                    <TableCell sortColumn={BUILDING_COLUMNS.LONGITUDE}>{BUILDING_COLUMNS.LONGITUDE}</TableCell>
                    <TableCell>{BUILDING_COLUMNS.GOOGLE_MAP_LINK}</TableCell>
                    <TableCell>{BUILDING_COLUMNS.ROOMS}</TableCell>
                    <TableCell>{BUILDING_COLUMNS.UPDATE_TT_LINK}</TableCell>
                </TableRow>
                {sortBuildings(filteredBuildingList, buildingsSortColumn, buildingsSortDirection).map((building) =>
                    <TableRow>
                        <TableCell>{building.buildingCode}</TableCell>
                        <TableCell>{building.buildingName}</TableCell>
                        <TableCell>{building.city}</TableCell>
                        <TableCell>{building.country}</TableCell>
                        <TableCell>{building.latitude}</TableCell>
                        <TableCell>{building.longitude}</TableCell>
                        <TableCell>
                            {(building.latitude && building.longitude) ?
                                <Link
                                    href={`https://maps.google.com/maps/search/${building.latitude},${building.longitude}`}
                                    target="_blank"
                                    type="secondary"
                                >
                                    {building.buildingCode} on google maps
                                </Link>
                                :
                                "Unavailable"
                            }
                        </TableCell>
                        <TableCell>
                            <Button onClick={() => onGetRoomsForBuilding(building.buildingCode)}>
                                Get Rooms
                            </Button>
                        </TableCell>
                        <TableCell>
                            <Link
                                href={`https://tt.amazon.com/new?impact=3&priority=Low&category=IT%20Support&type=Email%20-%20Exchange%20-%20Outlook%20-%20Calendaring&item=Conference%20Room%20Creation%20-%20Issues&tags=Amazon_Meetings%2C%20Amazon_Meetings_Data_Correction&bldg=${building.buildingCode}&short_description=Please%20Update%20Info%20for%20this%20Room%20List%20(${building.buildingCode})&details=ROOM%20LIST%3A%20${building.buildingCode}-Rooms%0A%0A(please%20describe%20the%20necessary%20update)`}
                                target="_blank"
                                type="secondary"
                            >
                                Create TT
                            </Link>
                        </TableCell>
                    </TableRow>
                )}
            </Table>
            :
            <Loader type="circular" size="large" />
        }
        {selectedBuildingCode &&
            <React.Fragment>
                <Row
                    type="outline"
                    spacingInset="medium xsmall"
                    alignmentVertical="top"
                    alignmentHorizontal="left"
                    widths="fit"
                    wrap="down"
                >
                    <Text type="h400">Rooms</Text>
                    <Input
                        value={roomFilterQuery}
                        onChange={setRoomFilterQuery}
                        type="text"
                        placeholder="Filter rooms..."
                        width="300px"
                    />
                    <Button onClick={() => setRoomFilterQuery("")}>
                        Clear filter
                    </Button>
                </Row>
                {roomList.length > 0 ?
                    <Table
                        headerRows={1}
                        stickyHeaderColumn={true}
                        stickyHeaderRow={true}
                        showStripes={true}
                        spacing="small"
                        sortColumn={roomsSortColumn}
                        sortDirection={roomsSortDirection}
                        onSort={onSortRooms}
                    >
                        <TableRow>
                            <TableCell sortColumn={ROOM_COLUMNS.NAME}>{ROOM_COLUMNS.NAME}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.BUILDING_CODE}>{ROOM_COLUMNS.BUILDING_CODE}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.FLOOR}>{ROOM_COLUMNS.FLOOR}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.CAPACITY}>{ROOM_COLUMNS.CAPACITY}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.RESTRICTED}>{ROOM_COLUMNS.RESTRICTED}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.HAND_MANAGED}>{ROOM_COLUMNS.HAND_MANAGED}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.PHONE}>{ROOM_COLUMNS.PHONE}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.RESOURCE_LIST}>{ROOM_COLUMNS.RESOURCE_LIST}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.CITY}>{ROOM_COLUMNS.CITY}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.HIDDEN}>{ROOM_COLUMNS.HIDDEN}</TableCell>
                            <TableCell sortColumn={ROOM_COLUMNS.EMAIL}>{ROOM_COLUMNS.EMAIL}</TableCell>
                            <TableCell>{ROOM_COLUMNS.UPDATE_TT_LINK}</TableCell>
                        </TableRow>
                        {sortRooms(filteredRoomList, roomsSortColumn, roomsSortDirection).map((room) =>
                            <TableRow>
                                <TableCell>{room.name}</TableCell>
                                <TableCell>{room.buildingCode}</TableCell>
                                <TableCell>{room.floor}</TableCell>
                                <TableCell>{room.capacity}</TableCell>
                                <TableCell>{JSON.stringify(room.restricted)}</TableCell>
                                <TableCell>{JSON.stringify(room.managed)}</TableCell>
                                <TableCell>{room.phone}</TableCell>
                                <TableCell>{JSON.stringify(room.resourceList)}</TableCell>
                                <TableCell>{room.city}</TableCell>
                                <TableCell>{JSON.stringify(room.hidden)}</TableCell>
                                <TableCell>{room.email}</TableCell>
                                <TableCell>
                                    <Link
                                        href={`https://tt.amazon.com/new?impact=3&priority=Low&category=IT%20Support&type=Email%20-%20Exchange%20-%20Outlook%20-%20Calendaring&item=Conference%20Room%20Creation%20-%20Issues&tags=Amazon_Meetings%2C%20Amazon_Meetings_Data_Correction&bldg=${room.buildingCode}&short_description=Please%20Update%20Info%20for%20this%20Conf%20Room&details=CONF%20ROOM%20EMAIL%3A%20${room.email}%0A%0A(please%20describe%20the%20necessary%20update)`}
                                        target="_blank"
                                        type="secondary"
                                    >
                                        Create TT
                                    </Link>
                                </TableCell>
                            </TableRow>
                        )}
                    </Table>
                    :
                    <Loader type="circular" size="large" />
                }
            </React.Fragment>
        }
        <MeetingsToaster />
        <MeetingsFeedbackContainer />
    </Column>;
}

export default MeetingRoomsContainer;