import React from "react";
import { useSelector } from "react-redux";

import Divider from "@amzn/meridian/divider";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Box from "@amzn/meridian/box";
import Icon from "@amzn/meridian/icon"
import clockTokens from "@amzn/meridian-tokens/base/icon/clock";
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil";
import userTokens from "@amzn/meridian-tokens/base/icon/user";

import "../../shared/styles/meeting-details.css";

import MeetingSuggestionAttendeeGroup from "./meeting-suggestion-attendee-group";

import { ReactComponent as ViewerIcon } from "../../../assets/icons/livestream/viewers.svg";

import {
    getAttendeeCount,
    getAttendeeTagText,
    getTotalAttendees,
    getStationFreeStatus,
} from "../livestream-utils";
import { LIVESTREAM_PREPARATION_MINUTES, VIEWER_COUNT_OPTION } from "../livestream-constants";
import { convertEpoch } from "../../meeting-scheduler/meeting-scheduler-utils.js";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { STEP } from "../livestream-constants";
import { ATTENDEE_ROLE } from "../../people/people-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";

const LivestreamReviewCard = (props) => {
    const onChangeStep = props.onChangeStep;
    const meetingDetails = useSelector(props.meetingDetailsSelector);
    const screenSizeBreakpoint = props.screenSizeBreakpoint;

    const attendees = props.attendees;
    const eventRequirements = props.eventRequirements;

    // Timezones
    const timezones = props.timezones;
    const suggestionsTimezoneId = meetingDetails && meetingDetails.endTimezone?.id;
    const suggestionsTimezoneName = timezoneIdToDisplayName(timezones, suggestionsTimezoneId);

    // Datetime format and date format
    const timeFormat = props.timeFormat;
    const startTime = meetingDetails && meetingDetails.time && meetingDetails.time.startTime;
    const endTime = meetingDetails && meetingDetails.time && meetingDetails.time.endTime;
    const formatDateTime = (time, format) => {
        return convertEpoch(time, format, suggestionsTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR);
    };

    const prepStartTime = startTime;
    const prepEndTime = prepStartTime + (LIVESTREAM_PREPARATION_MINUTES * 60);

    const eventStartTime = prepStartTime + (LIVESTREAM_PREPARATION_MINUTES * 60);
    const eventEndTime = endTime;

    const displayedPrepStartTime = formatDateTime(prepStartTime, "time");
    const displayedPrepEndTime = formatDateTime(prepEndTime, "time");
    const displayedPrepStartDate = formatDateTime(prepStartTime, "shortWeekdayDate");

    const displayedEventStartTime = formatDateTime(eventStartTime, "time");
    const displayedEventEndTime = formatDateTime(eventEndTime, "time");
    const displayedEventEndDate = formatDateTime(eventEndTime, "shortWeekdayDate");

    // Meeting suggestion info
    const meetingSuggestions = props.meetingSuggestions;
    const meetingSuggestionSelected = props.meetingSuggestionSelected;
    const meetingSuggestionsDetail = props.meetingSuggestionsDetail;
    const currentMeetingSuggestion = meetingSuggestions[meetingSuggestionSelected];

    // Return a list of attendees for a specific group (free, tentative, unavailable)
    const getAttendeeGroup = (suggestion, suggestionGroup, groupType) => {
        // only display the attendee if they are the organizer, a presenter, or a point of contact
        let filteredSuggestionGroup = [];
        attendees.forEach((attendee) => {
            if (suggestionGroup.includes(attendee.email) &&
                (attendee.isOrganizer || attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER || attendee.isPointOfContact)) {
                filteredSuggestionGroup.push(attendee.email);
            }
        });

        let displayLivestreamEngineers = (groupType === "Available" && getStationFreeStatus(suggestion, eventRequirements)) ||
            (groupType === "Unavailable" && !getStationFreeStatus(suggestion, eventRequirements));

        return ((getAttendeeCount(filteredSuggestionGroup, attendees) > 0 || displayLivestreamEngineers) &&
            <MeetingSuggestionAttendeeGroup
                suggestion={suggestion}
                attendees={attendees}
                attendeeGroup={filteredSuggestionGroup}
                attendeeGroupType={groupType}
                displayLivestreamEngineers={displayLivestreamEngineers}
                totalAttendees={getTotalAttendees(suggestion, attendees)}
                meetingSuggestionsDetail={meetingSuggestionsDetail}
                timezones={timezones}
                suggestionsTimezoneId={suggestionsTimezoneId}
            />
        );
    };

    return(
        <React.Fragment>
            <Column spacingInset="medium medium" width="fill" height="fill" maxHeight="450px" alignmentHorizontal="center">
                <Box type="outline" width={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "400px" : "340px"} height="fill">
                    <Row spacing="medium" spacingInset="300 medium" width="100%" alignmentHorizontal="left" backgroundColor="#F9FAFA">
                        <Text type="h100">Selected event slot</Text>
                    </Row>
                    <section aria-label={`Time: ${formatDateTime(startTime, "longWeekdayDateYear")},
                                                ${displayedPrepStartTime} to
                                                ${displayedEventEndTime}
                                                ${suggestionsTimezoneName}`}>
                        <Column spacing="xsmall" spacingInset="medium">
                            <Row alignmentHorizontal="justify" alignmentVertical="top">
                                {displayedPrepStartDate === displayedEventEndDate ?
                                    <Row alignmentVertical="top" spacing="small" height="fill">
                                        <Icon tokens={clockTokens} />
                                        <Column spacing="xsmall">
                                            <Column spacing="none">
                                                <Text type="b100" color="secondary">
                                                    Prep time
                                                </Text>
                                                <Row spacing="xsmall">
                                                    <Text type="b200">
                                                        <b>{displayedPrepStartTime}&nbsp;-&nbsp;{displayedPrepEndTime}</b>
                                                    </Text>
                                                    <Text type="b100" color="secondary">
                                                        &nbsp;{suggestionsTimezoneName}
                                                    </Text>
                                                </Row>
                                            </Column>
                                            <Column spacing="none">
                                                <Text type="b100">
                                                    Event time
                                                </Text>
                                                <Row spacing="xsmall">
                                                    <Text type="b200">
                                                        <b>{displayedEventStartTime}&nbsp;-&nbsp;{displayedEventEndTime}</b>
                                                    </Text>
                                                    <Text type="b100" color="secondary">
                                                        &nbsp;{suggestionsTimezoneName}
                                                    </Text>
                                                </Row>
                                            </Column>
                                            <Row spacingInset="none xxsmall">
                                                <Text type="b200">
                                                    {displayedEventEndDate}
                                                </Text>
                                            </Row>
                                        </Column>
                                    </Row>
                                    : // for multiple days time format
                                    <Row alignmentVertical="top" spacing="small">
                                        <Icon tokens={clockTokens} />
                                        <Column spacing="xsmall">
                                            <Row spacingInset="xxsmall" spacing="xsmall">
                                                <Text type="b200">
                                                    <b>{displayedPrepStartTime}</b>
                                                </Text>
                                                <Text type="b100" color="secondary">
                                                    &nbsp;{suggestionsTimezoneName}
                                                </Text>
                                            </Row>
                                            <Row spacingInset="none xxsmall">
                                                <Text type="b200">
                                                    {displayedPrepStartDate}
                                                </Text>
                                            </Row>
                                        </Column>
                                        <Column spacing="xsmall">
                                            <Row spacingInset="xxsmall" spacing="xsmall">
                                                <Text type="b200">
                                                    <b>to&nbsp;&nbsp;{displayedEventEndTime}</b>
                                                </Text>
                                                <Text type="b100" color="secondary">
                                                    &nbsp;{suggestionsTimezoneName}
                                                </Text>
                                            </Row>
                                            <Row spacingInset="none none none 450">
                                                <Text type="b200">
                                                    {displayedEventEndDate}
                                                </Text>
                                            </Row>
                                        </Column>
                                    </Row>
                                }
                                <Button
                                    type="icon"
                                    label="Edit event date or time"
                                    onClick={() => onChangeStep(STEP.EVENT_SCHEDULE)}
                                >
                                    <Icon tokens={pencilTokens} />
                                </Button>
                            </Row>
                        </Column>
                    </section>
                    <Row spacingInset="none medium">
                        <Divider size="small"/>
                    </Row>
                    <section aria-label={`Attendees: ${getAttendeeTagText(currentMeetingSuggestion, attendees, eventRequirements)}.
                                          Location: ${meetingDetails.location}`}>
                        <Row spacing="small" spacingInset="none medium" height="fill">
                            <Icon tokens={userTokens} />
                            <Column width="stretch" spacing="xsmall" spacingInset="none xxsmall">
                                <Text type="b200">{getAttendeeTagText(currentMeetingSuggestion, attendees, eventRequirements)}</Text>
                            </Column>
                            <Button
                                type="icon"
                                label="Edit event attendees and rooms"
                                onClick={() => onChangeStep(STEP.ATTENDEES_AND_ROOMS)}
                            >
                                <Icon tokens={pencilTokens} />
                            </Button>
                        </Row>
                        <Row spacingInset="none large" height="fill">
                            <Column spacingInset="none none none xxsmall" width="stretch">
                                <Column spacing="small" spacingInset="none none none 400" height="fill" maxHeight="75px" overflowY="auto">
                                    {getAttendeeGroup(currentMeetingSuggestion, currentMeetingSuggestion.unavailablePeople, "Unavailable")}
                                    {getAttendeeGroup(currentMeetingSuggestion, currentMeetingSuggestion.tentativePeople, "Tentative")}
                                    {getAttendeeGroup(currentMeetingSuggestion, currentMeetingSuggestion.freePeople, "Available")}
                                    {getAttendeeGroup(currentMeetingSuggestion, currentMeetingSuggestion.unknownPeople, "Unknown")}
                                </Column>
                            </Column>
                        </Row>
                        <Column spacing="xsmall" spacingInset="small none">
                            <Row spacing="small" spacingInset="none medium" alignmentVertical="top" height="fill">
                                <ViewerIcon width="30px" height="24px" />
                                <Column width="stretch" spacing="none" spacingInset="xxsmall">
                                    <Text type="b200">Expected number of viewers</Text>
                                    <Text type="b200"><b>{VIEWER_COUNT_OPTION[eventRequirements["viewerCount"]]}</b></Text>
                                </Column>
                                <Button
                                    type="icon"
                                    label="Edit event requirements"
                                    onClick={() => onChangeStep(STEP.EVENT_REQUIREMENTS)}
                                >
                                    <Icon tokens={pencilTokens} />
                                </Button>
                            </Row>
                        </Column>
                    </section>
                </Box>
            </Column>
        </React.Fragment>
    );
};

export default LivestreamReviewCard;