import {call, put, retry} from "redux-saga/effects";
import {cleanEmpty, getErrorResponse, isRetryableError, stringifyError, axiosRequest} from "../apig-utils";
import {postLivestreamApiCallMetric} from "../../../shared/metrics/actions";
import {OPERATION_PATHS, OPERATIONS_METHOD} from "./endpointConfig";
import {MAX_ATTEMPTS, RETRY_DELAY_IN_MS} from "../apig-constants";
import {getEndpoint, replacePathParameters} from "./utils";

export function* permissionPeopleSearch(query) {
    console.log("Calling permissionPeopleSearch");
    return yield call(retryableMakeRequest, [OPERATION_PATHS.PERMISSION_PEOPLE_SEARCH, OPERATIONS_METHOD.POST, {
        query,
        nextToken: "",
        size: 10,
        sortBy: "WEIGHT_THEN_FULL_NAME",
    }]);
}

export function* permissionGroupSearch(query) {
    console.log("Calling permissionGroupSearch");
    return yield call(retryableMakeRequest, [OPERATION_PATHS.PERMISSION_GROUP_SEARCH, OPERATIONS_METHOD.POST, {
        query: query.name,
        type: query.type,
    }]);
}

export function* getCurrentUser() {
    console.log("Calling getCurrentUser");
    return yield call(retryableMakeRequest, [OPERATION_PATHS.CURRENT_USER, OPERATIONS_METHOD.GET, null]);
}

export function* createEventIntake(eventIntake) {
    console.log("Calling createEventIntake");
    return yield call(retryableMakeRequest, [OPERATION_PATHS.CREATE_EVENT_INTAKE, OPERATIONS_METHOD.POST, eventIntake]);
}

export function* getLatestCreatedEventDetails(seriesUrl) {
    console.log("Calling getLatestCreatedEventDetails");
    const encodedUrl = encodeURIComponent(seriesUrl);
    const path = replacePathParameters(OPERATION_PATHS.LATEST_CREATED_EVENT, {
        url: encodedUrl,
    });
    return yield call(retryableMakeRequest, [path, OPERATIONS_METHOD.GET]);
}

export function* getLivestreamEventSeriesApi(livestreamSeriesUrl) {
    const encodedUrl = encodeURIComponent(livestreamSeriesUrl);
    const path = replacePathParameters(OPERATION_PATHS.GET_SERIES_URL_AVAILABILITY, {
        url: encodedUrl,
    });
    return yield call(retryableMakeRequest, [path, OPERATIONS_METHOD.GET]);
}

function* retryableMakeRequest(params) {
    let response;
    try {
        response = yield retry(MAX_ATTEMPTS, RETRY_DELAY_IN_MS, makeRequest, ...params);
    } catch (e) {
        console.error(`An error occurred in all ${MAX_ATTEMPTS} attempts to call ${params[0]}: `, e);
        const error = stringifyError(e);
        yield put(postLivestreamApiCallMetric(params[0], JSON.stringify({error, request: params[1]}), false));
        return getErrorResponse(e);
    }
    console.log(`${params[0]} called successfully.`);
    yield put(postLivestreamApiCallMetric(params[0]));
    return response;
}

function* makeRequest(path, method, body) {
    const endpoint = yield getEndpoint();

    const url = new URL(endpoint + path);
    if (body) {
        body = JSON.stringify(cleanEmpty(body));
    }
    const opts = {
        url: url.toString(),
        method: method,
        headers: {
            "Content-Type": "application/json"
        },
        data: body,
        withCredentials: true
    };

    try {
        return yield call(axiosRequest, {url, opts});
    } catch (e) {
        if (isRetryableError(e)) {
            throw e;
        }
        const error = stringifyError(e);
        yield put(postLivestreamApiCallMetric(path, JSON.stringify({error, request: body}), false));
        return getErrorResponse(e);
    }
}