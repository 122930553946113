import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from 'react-router-dom';

import { getUserFeedback, getUserRating } from "../../../sagas/selector";
import { clearFeedback, updateUserFeedback, updateUserRating } from "../../shared/feedback/actions";

import Row from "@amzn/meridian/row";

import "../styles/navigation.css";

import MeetingsFeedbackPopover from "../../shared/feedback/components/meetings-feedback-popover";

import BackgroundLarge from "../../../assets/backgrounds/pattern_large_dark.svg";

import { HOMEPAGE_FOOTER } from "../../shared/shared-constants";

const MeetingsFooter = () => {
    const feedbackPopoverRef = useRef();
    const userFeedback = useSelector(getUserFeedback);
    const userRating = useSelector(getUserRating);

    const dispatch = useDispatch();

    const onUpdateUserFeedback = (feedback) => dispatch(updateUserFeedback(feedback));
    const onUpdateUserRating = (rating) => dispatch(updateUserRating(rating));
    const onClearFeedback = () => dispatch(clearFeedback());

    const [feedbackPopoverOpen, setFeedbackPopoverOpen] = useState(false);

    return (
        <Switch>
            <Route exact={true} path={["/", "/migration"]}>
                <div style={{
                    "backgroundImage": `url(${BackgroundLarge})`,
                    "backgroundRepeat": "no-repeat",
                    "backgroundPosition": "0% 50%",
                    "backgroundColor": "rgba(26, 69, 78)",
                }}>
                    <Row spacingInset="none medium" alignmentHorizontal="right" alignmentVertical="bottom" width="100%" height={HOMEPAGE_FOOTER.HEIGHT}>
                        <button
                            className="feedbackButton"
                            onClick={() => setFeedbackPopoverOpen(true)}
                            ref={feedbackPopoverRef}
                        >
                            Feedback
                        </button>
                        <MeetingsFeedbackPopover
                            open={feedbackPopoverOpen}
                            anchorNode={feedbackPopoverRef.current}
                            userFeedback={userFeedback}
                            userRating={userRating}
                            onClose={() => setFeedbackPopoverOpen(false)}
                            onUpdateUserFeedback={onUpdateUserFeedback}
                            onUpdateUserRating={onUpdateUserRating}
                            onClearFeedback={onClearFeedback}
                        />
                    </Row>
                </div>
            </Route>
        </Switch>
    );
};

export default MeetingsFooter;