// replaces the placeholders in the path with the values
import {getStageConfig} from "../../stageConfig/stage-config-utils";
import {ENDPOINTS} from "./endpointConfig";

export const replacePathParameters = (path, params) => {
    for (const param in params) {
        path = path.replace('{' + param + '}', params[param]);
    }
    return path;
};

export function* getEndpoint() {
    const stageConfig = yield getStageConfig();
    const stage = stageConfig.stage, region = "IAD";
    return ENDPOINTS[stage][region];
}