import React from "react";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Modal, { ModalFooter } from "../meridian-custom-components/src/components/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import oopsEmojiSvg from "../../../assets/icons/shared/oops_emoji.svg";

import MeetingsModalHeader from "./meetings-modal-header";

const ActionableModal = (props) => {
    const modalTitle = props.modalTitle;
    const modalDescriptions = props.modalDescriptions;
    const modalConfirmButtonText = props.modalConfirmButtonText || "Yes, I'm sure";
    const onClickConfirm = props.onClickConfirm;
    const [open, setOpen] = [props.open, props.setOpen];

    const actionableModalId = "actionableModal";

    return (
        <Modal
            open={open}
            bodySpacingInset="none"
            width="504px"
            backgroundElementsId={props.backgroundElementsId}
        >
            <MeetingsModalHeader
                modalId={actionableModalId}
                modalTitle={modalTitle}
                showCustomCloseButton={true}
                onCloseModal={() => setOpen(false)}
            />
            <Row id={actionableModalId} spacingInset="medium">
                <Column>
                    <img src={oopsEmojiSvg} style={{"height": "70px", "width": "65px"}}
                         alt="oops actionable modal icon"/>
                </Column>
                <Column spacing="none">
                    {modalDescriptions?.map((desciption) => (
                        <Text type="b300">{desciption}</Text>
                    ))}
                </Column>
            </Row>
            <ModalFooter>
                <Row spacing="medium" alignmentHorizontal="right">
                    <Button type="secondary" size="medium" onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        type="primary"
                        size="medium"
                        onClick={onClickConfirm}
                    >
                        {modalConfirmButtonText}
                    </Button>
                </Row>
            </ModalFooter>
        </Modal>
    )
};

export default ActionableModal;