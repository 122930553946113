import * as Actions from "../actions/action-types";

const initialState = {
    ticketId: "",
    url: "",
    peopleSuggestions: undefined,
    permissionGroupSuggestions: [],
    livestreamIntakeLoaded: true,
};

function eventIntakeFormReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SAVE_PERMISSION_PEOPLE_SUGGESTIONS:
            return {
                ...state,
                peopleSuggestions: action.people,
            };
        case Actions.CLEAR_PERMISSION_PEOPLE_SUGGESTIONS:
            return {
                ...state,
                peopleSuggestions: undefined,
            };
        case Actions.SAVE_PERMISSION_GROUP_SUGGESTIONS:
            return {
                ...state,
                permissionGroupSuggestions: action.payload.groups,
            };
        case Actions.CLEAR_PERMISSION_GROUP_SUGGESTIONS:
            return {
                ...state,
                permissionGroupSuggestions: undefined,
            };
        case Actions.SAVE_CREATE_EVENT_INTAKE_DETAILS:
            return {
                ...state,
                ticketId: action.payload.eventIntakeResponse.ticketId,
                url: action.payload.eventIntakeResponse.url,
            };
        case Actions.SAVE_LATEST_EVENT_DETAILS:
            return {
                ...state,
                latestEvent: action.payload.latestEvent,
                latestEventErrorDetails: undefined,
            };
        case Actions.SET_LATEST_EVENT_ERROR_DETAILS:
            return {
                ...state,
                latestEventErrorDetails: action.payload.errorDetails,
                latestEvent: undefined,
            };
        case Actions.CLEAR_LATEST_EVENT_DETAILS:
            return {
                ...state,
                latestEvent: undefined,
                latestEventErrorDetails: undefined,
            };
        case Actions.SET_LIVESTREAM_EVENT_SERIES_URL_AVAILABILITY:
            return {
                ...state,
                url_availability: action.payload.url_availability,
            };
        case Actions.CLEAR_LIVESTREAM_EVENT_SERIES_URL_AVAILABILITY:
            return {
                ...state,
                url_availability: {available: false, verified: false},
            };
        case Actions.SET_LIVESTREAM_INTAKE_LOADED:
            return {
                ...state,
                livestreamIntakeLoaded: action.isLoaded,
            };
        default:
            return state;
    }
}

export default eventIntakeFormReducer;
