import React from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link";

import { MEETING_CARD } from "../../meeting-scheduler/meeting-scheduler-constants";
import { ATTENDEE_PRIORITY, ATTENDEE_TYPE } from "../../people/people-constants.js";
import { TIMEZONE_DISPLAY_TYPE } from "../../shared/timezones/timezones-constants";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { renderPriorityIcon } from "../../people/people-utils.js";

const MeetingSuggestionAttendeeGroup = (props) => {
    const suggestion = props.suggestion;

    const attendees = (props.attendees || []).map((attendee) => {
        return {
            ...attendee,
            timezoneShortName: timezoneIdToDisplayName(props.timezones || [], attendee.timezoneId, TIMEZONE_DISPLAY_TYPE.SHORT)
        }
    });
    const attendeeGroup = props.attendeeGroup;
    const attendeeGroupType = props.attendeeGroupType;
    const totalAttendees = props.totalAttendees;

    const displayLivestreamEngineers = props.displayLivestreamEngineers;
    const meetingSuggestionsDetail = props.meetingSuggestionsDetail;
    const toggleSuggestionDetail = props.toggleSuggestionDetail;

    const suggestionsTimezoneShortName = timezoneIdToDisplayName(props.timezones || [], props.suggestionsTimezoneId, TIMEZONE_DISPLAY_TYPE.SHORT);

    // Sort the group of attendees by going through the sorted list of all attendees
    let sortedGroupAttendees = [];
    attendees.forEach((attendee) => {
        if (attendeeGroup.includes(attendee.email)) {
            sortedGroupAttendees.push(attendee.email);
        }
    });

    const groupAttendeeCount = sortedGroupAttendees.length + (props.displayLivestreamEngineers ? 1 : 0);

    return (
        <Column spacing="none">
            {groupAttendeeCount !== 0 &&
                <Text type="b100"><b>{attendeeGroupType} ({groupAttendeeCount}{attendeeGroupType !== "Unknown" && `/${totalAttendees}`})</b></Text>
            }
            {displayLivestreamEngineers &&
                <Row spacing="xsmall">
                    {renderPriorityIcon(ATTENDEE_PRIORITY.ESSENTIAL, false, 12)}
                    <Column width={MEETING_CARD.ATTENDEE_NAME_WIDTH}>
                        <Text type="b100" breakWord={true}>Livestream engineers</Text>
                    </Column>
                </Row>
            }
            {attendees.map((attendee) => {
                return ((attendeeGroup.includes(attendee.email) && (sortedGroupAttendees.indexOf(attendee.email) < MEETING_CARD.DEFAULT_ATTENDEE_COUNT || meetingSuggestionsDetail[suggestion.startTime + attendeeGroupType])) ?
                    <Row key={attendee.email} spacing="xsmall">
                        {attendee.priority === ATTENDEE_PRIORITY.ESSENTIAL &&
                            renderPriorityIcon(ATTENDEE_PRIORITY.ESSENTIAL, false, 12)
                        }
                        {attendee.priority === ATTENDEE_PRIORITY.REQUIRED &&
                            renderPriorityIcon(ATTENDEE_PRIORITY.REQUIRED, false, 12)
                        }
                        {attendee.priority === ATTENDEE_PRIORITY.OPTIONAL &&
                            renderPriorityIcon(ATTENDEE_PRIORITY.OPTIONAL, false, 12)
                        }
                        <Column width={attendee.type === ATTENDEE_TYPE.PERSON ? `${MEETING_CARD.ATTENDEE_NAME_WIDTH - MEETING_CARD.ATTENDEE_TIMEZONE_WIDTH}px` : `${MEETING_CARD.ATTENDEE_NAME_WIDTH}px`}>
                            <Text type="b100" breakWord={true}>{attendee.name}</Text>
                        </Column>
                        {attendee.type === ATTENDEE_TYPE.PERSON && !attendee.isOrganizer && suggestionsTimezoneShortName !== attendee.timezoneShortName &&
                            <Column width={`${MEETING_CARD.ATTENDEE_TIMEZONE_WIDTH}px`}>
                                <Text type="b100" color="secondary">
                                    {attendee.timezoneShortName}
                                </Text>
                            </Column>
                        }
                    </Row>
                    :
                    undefined
                )
            })}
            {sortedGroupAttendees.length > 5 &&
                <Column spacing="none">
                    <Text type="b100">
                        <Link type="secondary" onClick={() => toggleSuggestionDetail(suggestion.startTime, attendeeGroupType)}>
                            {meetingSuggestionsDetail[suggestion.startTime + attendeeGroupType] ?
                                "Show less..."
                                :
                                "Show more..."
                            }
                        </Link>
                    </Text>
                </Column>
            }
        </Column>
    )
};

export default MeetingSuggestionAttendeeGroup;
