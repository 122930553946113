import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getIdentity, getStageConfig, getWorkflow, isUnauthorizedUser } from "../../../sagas/selector";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Masthead , { MastheadTitle, MastheadMenuButton } from "@amzn/meridian/masthead";
import Row from "@amzn/meridian/row";
import Theme from "@amzn/meridian/theme";
import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark";
import blueLightTokens from "@amzn/meridian-tokens/theme/blue-light";

import DiscardMeetingModal from "../../shared/components/discard-meeting-modal";
import MeetingsAnnouncements from "../../announcements/components/meetings-announcements";
import MeetingsSideMenu from "./meetings-side-menu";
import MeetingsHelpMenu from "./meetings-help-menu";
import IsItDownNotification from "../../shared/is-it-down/components/is-it-down-notification";

import AMWLogo_color from "../../../assets/logos/amzn_meetings_logo_color.svg";
import AMWLogo_white from "../../../assets/logos/amzn_meetings_logo_white.svg";
import AMWBeta_color from "../../../assets/logos/amzn_meetings_beta_color.svg";
import AMWBeta_white from "../../../assets/logos/amzn_meetings_beta_white.svg";

import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { changePage } from "../../shared/workflow/workflow-utils";
import { SIDE_MENU_WIDTH, HELP_MENU_WIDTH } from "../navigation-constants";
import { M365_MIGRATION_REDIRECT_ENABLED, M365_MIGRATION_ALLOWLIST } from "../../shared/containers/migration";

const MeetingsMasthead = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const stageConfig = useSelector(getStageConfig);
    const displayBeta = stageConfig && stageConfig.stage && stageConfig.stage !== "Prod";

    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const onOpenSideMenu = () => {
        setHelpMenuOpen(false);
        setSideMenuOpen(true);
    };
    const onCloseSideMenu = () => setSideMenuOpen(false);

    const [helpMenuOpen, setHelpMenuOpen] = useState(false);
    const onOpenHelpMenu = () => {
        setSideMenuOpen(false);
        setHelpMenuOpen(true);
    };
    const onCloseHelpMenu = () => setHelpMenuOpen(false);

    const dispatch = useDispatch();
    const unauthorizedUser = useSelector(isUnauthorizedUser);
    const currentWorkflow = useSelector(getWorkflow);
    const identity = useSelector(getIdentity);
    const isExchOnlineUser = M365_MIGRATION_REDIRECT_ENABLED && identity && identity.exchangeOnlineMailbox && !M365_MIGRATION_ALLOWLIST.includes(identity.username);
    const darkMode = window.location.href.split("/")[4] === "" || isExchOnlineUser;

    const [currentPage, setCurrentPage] = useState("/");
    const [discardMeetingModalOpen, setDiscardMeetingModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("Discard meeting");

    const MASTHEAD_ID = "masthead-id";

    return (
        <div>
            {!unauthorizedUser &&
                <div>
                    <Theme tokens={darkMode ? blueDarkTokens : blueLightTokens}>
                        {!isExchOnlineUser && <IsItDownNotification />}
                        <Masthead size={props.mastheadSize}>
                            <MastheadMenuButton id="mastheadSiteNavigationButton" onClick={() => sideMenuOpen ? setSideMenuOpen(false) : onOpenSideMenu()} open={!isExchOnlineUser} children="Site navigation" />
                            <MastheadTitle
                                href={WORKFLOWS.HOME.HREF}
                                onClick={() => {
                                    // Do not allow redirects for users migrated to M365
                                    if (isExchOnlineUser) { return; }
                                    if (currentWorkflow === WORKFLOWS.STANDARD_MEETING ||
                                        currentWorkflow === WORKFLOWS.EDIT_MEETING ||
                                        currentWorkflow === WORKFLOWS.OOTO ||
                                        currentWorkflow === WORKFLOWS.LIVESTREAM ||
                                        currentWorkflow === WORKFLOWS.POLL_CREATE ||
                                        currentWorkflow === WORKFLOWS.EDIT_POLL) {
                                        if (currentWorkflow === WORKFLOWS.POLL_CREATE || currentWorkflow === WORKFLOWS.EDIT_POLL) {
                                            setModalTitle("Discard meeting poll");
                                        } else {
                                            setModalTitle("Discard meeting");
                                        }
                                        setDiscardMeetingModalOpen(true);
                                    } else {
                                        changePage(dispatch, currentWorkflow, WORKFLOWS.HOME);
                                    }
                                }}
                            >
                                <Row spacing="small" alignmentVertical="bottom">
                                    <img alt="Amazon Meetings" src={darkMode ? AMWLogo_white : AMWLogo_color}/>
                                    {displayBeta &&
                                        <img alt="beta" src={darkMode ? AMWBeta_white : AMWBeta_color} style={{paddingBottom: "1px"}} />
                                    }
                                </Row>
                            </MastheadTitle>
                            {!isExchOnlineUser && <MeetingsAnnouncements screenSizeBreakpoint={screenSizeBreakpoint} darkMode={darkMode} />}
                            <Button type="icon" size="small" onClick={() => onOpenHelpMenu()}>
                                <Icon tokens={helpTokens}>Info</Icon>
                            </Button>
                        </Masthead>
                    </Theme>
                    <MeetingsSideMenu
                        open={sideMenuOpen}
                        onClose={onCloseSideMenu}
                        sideMenuSize={SIDE_MENU_WIDTH}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        displayBeta={displayBeta}
                    />
                    <MeetingsHelpMenu open={helpMenuOpen} onClose={onCloseHelpMenu} helpMenuSize={HELP_MENU_WIDTH} />
                    <DiscardMeetingModal
                        modalTitle={modalTitle}
                        open={discardMeetingModalOpen}
                        setOpen={setDiscardMeetingModalOpen}
                        backgroundElementsId={MASTHEAD_ID}
                        newWorkflow={WORKFLOWS.HOME}
                    />
                </div>
            }
        </div>
    );
};

export default MeetingsMasthead;
