export const MAPS_CALENDAR_STATE = {
    EMPTY_NOT_SHARED: 1,
    EMPTY_SHARED: 2,
    MEETING_NOT_SHARED: 3,
    MEETING_SHARED: 4
};

export const MAPS_AVAILABLE_BLOCK_COLOR = {
    BACKGROUND: "#8CD474",
    EDGE: "#0AABC4",
}

export const MAPS_DRAG_COMPONENT = {
    DRAG_START_TIME: "startTime",
    DRAG_END_TIME: "endTime"
};
