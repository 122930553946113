const _ = require('lodash');

export const indicesOfMarkers = (nodes, beginRegex, endRegex) => {
    let beginIndex = _.findIndex(nodes, function (node) {
        return beginRegex.test(node.textContent);
    });
    if (beginIndex < 0) {
        return null;
    }

    // Find the index of the first occurrence starting from beginIndex.
    // Search from beginIndex because sometimes beginRegex === endRegex.
    let endIndex = _.findIndex(nodes.slice(beginIndex), function (node) {
        return endRegex.test(node.textContent);
    });
    if (endIndex >= 0) {
        endIndex += beginIndex;
    } else {
        return null;
    }

    // include adjacent blank lines
    while (beginIndex > 0 && nodes[beginIndex - 1].textContent.trim() === '') {
        beginIndex--;
    }
    while (endIndex < nodes.length - 1 && nodes[endIndex + 1].textContent.trim() === '') {
        endIndex++;
    }

    return {beginIndex: beginIndex, endIndex: endIndex};
}

export const replaceContentBetween = (nodes, indices, newContent) => {
    if (indices) {
        let htmlBefore = domNodesToHtml(nodes.slice(0, indices.beginIndex));
        let htmlAfter = domNodesToHtml(nodes.slice(indices.endIndex + 1));
        return htmlBefore + newContent + htmlAfter;
    }
    // if indices are not found, just return HTML of original nodes
    return domNodesToHtml(nodes);
}

export const htmlToDomNodes = (html) => {
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return [].slice.call(tempDiv.children);
}

export const domNodesToHtml = (nodes) => {
    return nodes.map(function (x) {
        return x.outerHTML;
    }).join('');
}

export const removeChimeBridgeInfo = (meetingBody) => {
    // comply with ./chime-bridge-info
    const bridgeInfoBeginPattern = '={10,}Conference Bridge Information={10,}';
    const bridgeInfoEndPattern = '={40,}';
    const bridgeInfoBeginRegex = new RegExp(bridgeInfoBeginPattern, 'i');
    const bridgeInfoEndRegex = new RegExp(bridgeInfoEndPattern, 'i');

    let bodyHTML = meetingBody || "";
    let nodes = htmlToDomNodes(bodyHTML);
    let bridgeInfoIndices = indicesOfMarkers(nodes, bridgeInfoBeginRegex, bridgeInfoEndRegex);

    return replaceContentBetween(nodes, bridgeInfoIndices, "");
}

export const getChimePinFromMeetingBody = (meetingBody) => {
    const pinPattern = /(chime.aws\/)(?!dialinnumber)(\d+)/; // safari doesn't support looking behind
    let chimePin = "";
    if (meetingBody && meetingBody.value) {
        chimePin = (meetingBody.value.match(pinPattern) || [])[0];
        if (chimePin) {
            chimePin = chimePin.replace(/\D/g, "");
        }
    }
    return chimePin;
}