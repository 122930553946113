const namespace = 'ooto';

export const CREATE_MEETING = `${namespace}.create_meeting`;
export const CLEAR_DRAFT = `${namespace}.clear_draft`;
export const TURN_MEETING_REMINDERS_OFF = `${namespace}.turn_meeting_reminders_off`;
export const GET_MEETING = `${namespace}.get_meeting`;
export const GET_AWARENESS_MEETING_AND_SAVE = `${namespace}.get_awareness_meeting_and_save`;

export const UPDATE_MEETING_ASYNC = `${namespace}.update_meeting_async`;
export const UPDATE_MEETING = `${namespace}.update_meeting`;

export const CHANGE_STEP = `${namespace}.change_step`;

export const SET_MASTER = `${namespace}.set_master`;
export const SET_CREATING_MEETING_STATE = `${namespace}.set_creating_meeting_state`;
export const SET_AWARENESS_MEETING = `${namespace}.set_awareness_meeting`;
export const SET_LOAD_OOTO = `${namespace}.set_load_ooto`;
