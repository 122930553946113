import React, { useEffect, useState } from "react";

import isBefore from "date-fns/isBefore";

import { useSelector } from "react-redux";
import { getMeetingResponseIcon, getMeetingStatusColor, getResponseLabel } from "../../shared/meeting-status-utils";

import CalendarWeekGridView from "@amzn/meridian/calendar-week-grid-view";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Tile from "@amzn/meridian/tile";
import Heading from "@amzn/meridian/heading";
import Link from "@amzn/meridian/link";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import setDay from "date-fns/setDay";
import parse from "date-fns/parse";
import differenceInDays from "date-fns/differenceInDays";
import addMinutes from "date-fns/addMinutes";

import MeetingDetails from "../../shared/containers/meeting-details";
import LoadingMeetings from "../../landing/components/loading-meeting";
import AvailabilityBlock from "./availability-block";

import { SET_MEETING_DETAILS } from "../../shared/actions/action-types";
import { getMeetingDetails, getMeetingListCalendar } from "../../../sagas/selector";
import { SINGLE_DAY_VIEW_THRESHOLD, CALENDAR_MODE } from "../calendar-constants";
import { SET_MEETING_LIST } from "../actions/action-types";
import { convertEpoch } from "../../meeting-scheduler/meeting-scheduler-utils";
import { convertDateTimezone, parseAndAddTime } from "../../shared/time-utils";
import { MAPS_CALENDAR_STATE, MAPS_DRAG_COMPONENT } from "../../availability-sharing/availability-sharing-constants";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";
import { KEYCODE, TIME_CONSTANT } from "../../shared/shared-constants";
import { getTimeSlotString } from "../../meeting-polls/poll-utils";
import { CREATE_POLL_TIME_CELL, MAX_TIME_SLOTS, MAX_TIME_SLOTS_MESSAGE } from "../../meeting-polls/poll-constants";

const formatHeaderDate = (date) => format(date, "EEEE d");
const formatIsoTime = (date) => format(date, "HH:mm");
const getDayOfWeek = (date) => {
    switch (getDay(date)) {
        case 0: return "sunday";
        case 1: return "monday";
        case 2: return "tuesday";
        case 3: return "wednesday";
        case 4: return "thursday";
        case 5: return "friday";
        case 6: return "saturday";
        default: return "";
    }
};

const CalendarWeeklyView = (props) => {
    const meetingList = useSelector(getMeetingListCalendar);
    const meetingListLoaded = props.meetingListLoaded;
    const events = props.events;
    const timeFormat = props.timeFormat;
    const calendarMode = props.calendarMode;
    const viewType = props.viewType;
    const allDayLabel = "All Day";

    const baseTileHeight = 100;
    const baseCalendarPadding = 16;
    const accentWidth = 9;
    const defaultMeridianPadding = 8;
    const dividerAdjustment = defaultMeridianPadding + (calendarMode === CALENDAR_MODE.CREATE_POLL ? 8 : 0);
    const tileHeight = baseTileHeight - (calendarMode === CALENDAR_MODE.CREATE_POLL ? 16 : 0);

    const allDayEventHeightCoefficient = 0.3;
    const workWeekDays = ["monday", "tuesday", "wednesday", "thursday", "friday"];
    const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    const availabilityBlocks = props.availabilityBlocks;
    let [, setUpdatedDuration] = useState(undefined); // Variable used only to render and update the duration of the block

    const timeSlotDuration = props.timeSlotDuration || 60;
    const [selectedTimeCellIds, setSelectedTimeCellIds] = [props.selectedTimeCellIds || [], props.setSelectedTimeCellIds];
    const [selectedTimeSlots, setSelectedTimeSlots] = [props.selectedTimeSlots || [], props.setSelectedTimeSlots];

    const date = props.date;
    const timezone = props.timezone;
    const now = convertDateTimezone(new Date(), timezone, false);
    const currentWorkWeekEndTime = setDay(parse(props.date, "yyyy-MM-dd", new Date()), 6);

    let [meetingIdOfSelectedTile, setMeetingIdOfSelectedTile] = useState("");

    let mostAllDayEventsInADay = 0;
    for (let key in props.allDayEvents) {
        if (props.allDayEvents[key].length > mostAllDayEventsInADay) {
            mostAllDayEventsInADay = props.allDayEvents[key].length;
        }
    }

    const getTimes = (startingWorkHours, endingWorkHours, selectedDate) => {
        let start, end;
        if (props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || viewType === "day") {
            start = startingWorkHours[selectedDate] || startingWorkHours.week;
            end = endingWorkHours[selectedDate] || endingWorkHours.week;
        } else {
            start = startingWorkHours.week;
            end = endingWorkHours.week;
        }
        let times = [];
        for (let i = start; i <= end; i++) {
            times.push(`${i}`);
        }

        return times;
    };
    const times = getTimes(props.startingWorkHours, props.endingWorkHours, props.date);
    if (Object.keys(props.allDayEvents).length) {
        times.unshift(allDayLabel);
    }

    // Calculate tile layout props based on meeting and overlap data
    const calculateLayout = (event, index, list, overlapParams, date) => {
        let totalEvents = (overlapParams && overlapParams.overlappingMeetings) || 1;

        if (calendarMode === CALENDAR_MODE.CREATE_POLL) {
            // Allow half the space for the user to click on the create poll workflow
            totalEvents *= 2;
        }

        let additionalPadding = (overlapParams && overlapParams.additionalPadding) || 0;
        let startingMinutes = event.startTime.minute;
        let maxAllDayEventDuration = differenceInDays(currentWorkWeekEndTime, parse(date, "yyyy-MM-dd", new Date()));
        let allDayEventDuration = (props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || viewType === "day") ?
            1 : Math.min(Math.trunc(event.duration / 24), maxAllDayEventDuration);
        // Adjust event length to fit in time window if it overflows
        let eventDuration = !props.trimEvents ? event.duration :
            (event.startTime.hour + event.duration > props.endingWorkHours.week + 1 ?
                (props.endingWorkHours.week + 1 - event.startTime.hour) : event.duration);

        return {
            // Subtracting two pixels to the total length since with an event that overflows the time range at the end of the calendar it's not fitting and adds a scrollbar
            // The overflow still seems to happen when there are no meetings at the end and the time ends at the hour o'clock
            "height": `${(event.isAllDayEvent ? allDayEventHeightCoefficient : (Math.max(eventDuration, 0.25)) * tileHeight) - 2}px`, // Minimum height of 15 minutes (0.25 of an hour)
            "width": `calc(${event.isAllDayEvent ? (allDayEventDuration * 100) : (100 / totalEvents)}% - ${accentWidth}px)`,
            "top": `${(event.isAllDayEvent ? (index * allDayEventHeightCoefficient) : (startingMinutes && startingMinutes / 60)) * tileHeight}px`,
            "left": `${(additionalPadding) * (100 / totalEvents)}%`
        }
    };

    const getEvents = (events, groupId) => {
        let eventsStartedEarly = [];
        if (props.trimEvents && parseInt(groupId) === props.startingWorkHours.week) {
            eventsStartedEarly = events.filter((event) => {
                return !event.isAllDayEvent && event.startTime.hour < props.startingWorkHours.week && (event.endTime.hour > props.startingWorkHours.week || (event.endTime.hour === props.startingWorkHours.week && event.endTime.minute > 0));
            }).map((event) => {
                return {
                    ...event,
                    startTime: {
                        ...event.startTime,
                        hour: props.startingWorkHours.week,
                        minute: 0,
                        time: `${props.startingWorkHours.week}:00`
                    },
                    duration: event.duration - (props.startingWorkHours.week - event.startTime.hour)
                        //- (event.startTime.minute / 60) // TODO: need to check for meetings starting at times like 9:30 since they seem to be causing problems
                };
            });
        }
        let eventsStartedInHour = events.filter((event) => {
            return !event.isAllDayEvent && event.startTime.time.startsWith(groupId);
        });
        return eventsStartedInHour.concat(eventsStartedEarly);
    };

    const GroupHeader = ({ groupId }) => {
        if (groupId === allDayLabel) {
            return <Text type="h200" alignment="right">{allDayLabel}</Text>
        }

        let hour = groupId > 9 ? groupId : "0" + groupId;
        let period = "";

        if (timeFormat === TIME_FORMAT.TWELVE_HOUR) {
            hour = groupId > 12 ? groupId - 12 : groupId;
            period = groupId >= 12 ? " PM" : " AM";
        }

        return (
            <Column spacing="none" spacingInset="none" alignmentVertical="top">
                <Text type="h200" alignment="right">
                    {hour}
                </Text>
                <Text type="b200" alignment="right">
                    {period}
                </Text>
                {`${now.getHours()}` === groupId &&
                    <div style={{
                        width: "100%",
                        position: "absolute",
                        left: "0px",
                        top: `${((now.getMinutes() && now.getMinutes() / 60) * baseTileHeight) - defaultMeridianPadding}px`,
                        zIndex: 2,
                        pointerEvents: "none"
                    }}>
                        <hr style={{border: "1px dashed #0AABC4"}}  />
                    </div>
                }
            </Column>
        );
    };

    const renderDayHeader = ({date}) => {
        return <Text type="h100">{formatHeaderDate(parse(date, "yyyy-MM-dd", new Date()))}</Text>
    };

    const getDaysOfWeekToShowInCalendar = () => {
        if (props.daysOfWeek && props.daysOfWeek.length) {
            return props.daysOfWeek;
        }

        switch (viewType) {
            case "day":
                return [getDayOfWeek(parse(date, "yyyy-MM-dd", new Date()))];
            case "workweek":
                return props.screenSizeBreakpoint > SINGLE_DAY_VIEW_THRESHOLD ? workWeekDays : [getDayOfWeek(parse(date, "yyyy-MM-dd", new Date()))];
            case "week":
            default:
        }       return props.screenSizeBreakpoint > SINGLE_DAY_VIEW_THRESHOLD ? weekDays : [getDayOfWeek(parse(date, "yyyy-MM-dd", new Date()))];
    };

    const onOpenPopover = (event) => {
        setMeetingIdOfSelectedTile(event.entryID);
    };

    const onClosePopover = () => {
        let meetingTileElement = document.getElementById(meetingIdOfSelectedTile);
        meetingTileElement && meetingTileElement.focus();
        setMeetingIdOfSelectedTile("");
    };

    // Drag handlers for availability blocks for MAPS
    let [dragging, setDragging] = useState(undefined);
    let [dragEvent, setDragEvent] = useState(undefined);
    let [blockStartTime, setBlockStartTime] = useState(undefined);
    let [blockStartDuration, setBlockStartDuration] = useState(undefined);
    let [startY, setStartY] = useState(undefined);

    const onStartDrag = (e, dragComponent, event) => {
        // only left mouse button and if dragging is not yet defined
        if (e.button !== 0 || dragging !== undefined) {
            return;
        }

        // save current state
        setDragging(dragComponent);
        setDragEvent(event);
        setBlockStartTime(event.startTime);
        setBlockStartDuration(event.duration);
        setStartY(e.clientY);

        e.stopPropagation();
        e.preventDefault();
    };

    const onDrag = (e) => {
        if (dragging === undefined) {
            return;
        }

        let deltaY = e.clientY - startY;
        let intervalChanges = Math.floor(deltaY / (baseTileHeight / 4));
        let minDuration = props.availabilityMinDuration / 60;
        let newDuration;

        let newEvent = availabilityBlocks[dragEvent.date][parseInt(dragEvent.hour)][dragEvent.index];

        switch (dragging) {
            case MAPS_DRAG_COMPONENT.DRAG_START_TIME:
                newDuration = blockStartDuration - (intervalChanges * 0.25);
                if (newDuration < minDuration) return;

                let newStartDate = parseAndAddTime(dragEvent.date, dragEvent.startTime, intervalChanges * 0.25);
                newEvent.startTime = formatIsoTime(newStartDate);
                newEvent.duration = newDuration;
                availabilityBlocks[dragEvent.date][parseInt(dragEvent.hour)][dragEvent.index] = newEvent;
                setUpdatedDuration(newDuration);
                break;
            case MAPS_DRAG_COMPONENT.DRAG_END_TIME:
                newDuration = blockStartDuration + (intervalChanges * 0.25);
                if (newDuration < minDuration) return;

                newEvent.duration = newDuration;
                availabilityBlocks[dragEvent.date][parseInt(dragEvent.hour)][dragEvent.index] = newEvent;
                setUpdatedDuration(newDuration);
                break;
            default:
                break;
        }

        e.stopPropagation();
        e.preventDefault();
    };

    const onEndDrag = (e) => {
        setDragging(undefined);
        setUpdatedDuration(undefined);

        let newEvent = availabilityBlocks[dragEvent.date][parseInt(dragEvent.hour)][dragEvent.index];
        // When dragging an availability block, if the new duration goes from a free calendar to a meeting, and vice versa, we couldn't just set the block using a fixed Shared value, because we have to update it based on if it belongs to meeting or empty block.
        // So we set this updateSharedStateOnly flag for this circumstance.
        let updateSharedStateOnly = false;

        if (dragging === MAPS_DRAG_COMPONENT.DRAG_START_TIME) {
            // If new startTime is after the original, mark the time between originalStart and newStart as NOT_SHARED
            if (newEvent.startTime > blockStartTime) {
                props.onUpdateAvailability(dragEvent.state - 1, dragEvent.date, dragEvent.date, blockStartTime, newEvent.startTime);
            }
            // If new startTime is before the original, mark the time between newStart and originalStart as SHARED
            else {
                updateSharedStateOnly = true;
                props.onUpdateAvailability(dragEvent.state, dragEvent.date, dragEvent.date, newEvent.startTime, blockStartTime, updateSharedStateOnly);
            }
        }

        if (dragging === MAPS_DRAG_COMPONENT.DRAG_END_TIME) {
            let originalEndDate = parseAndAddTime(dragEvent.date, dragEvent.startTime, blockStartDuration);
            let newEndDate = parseAndAddTime(dragEvent.date, dragEvent.startTime, newEvent.duration);
            // If new endTime is after the original, mark the time in between originalEnd and newEnd as SHARED
            if (newEvent.duration > blockStartDuration) {
                updateSharedStateOnly = true;
                props.onUpdateAvailability(dragEvent.state, dragEvent.date, dragEvent.date, formatIsoTime(originalEndDate), formatIsoTime(newEndDate), updateSharedStateOnly);
            }
            // If new endTime is before the original, mark the time in between newEnd and originalEnd as NOT_SHARED
            else {
                props.onUpdateAvailability(dragEvent.state - 1, dragEvent.date, dragEvent.date, formatIsoTime(newEndDate), formatIsoTime(originalEndDate));
            }
        }

        e.stopPropagation();
        e.preventDefault();
    };

    // Return the ID for a time cell given the time it starts on
    // e.g. timeCell_2022-01-30_08_00
    const getTimeCellId = (date, hour, minute) => {
        return `timeCell_${date}_${hour}_${minute}`;
    };

    // Return the date object for a given time cell ID
    const convertTimeCellIdToDate = (timeCellId) => {
        const date = timeCellId.split("_")[1];
        const hour = timeCellId.split("_")[2];
        const minute = timeCellId.split("_")[3];

        return new Date(`${date}T${hour}:${minute}:00`);
    };

    // Return the time cell ID for a given date object
    const convertDateToTimeCellId = (date) => {
        if (Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date)) {
            const timezoneOffset = date.getTimezoneOffset() * (60 * 1000); // timezone offset in milliseconds
            const dateTimeString = new Date(date.getTime() - timezoneOffset).toISOString();

            const dateString = dateTimeString.split("T")[0];
            const timeString = dateTimeString.split("T")[1];
            const hour = timeString.split(":")[0];
            const minute = timeString.split(":")[1];

            return getTimeCellId(dateString, hour, minute);
        }
    };

    // Return the ID for a time cell offset from a given time cell ID
    const getOffsetTimeCellId = (timeCellId, offset = CREATE_POLL_TIME_CELL.MINUTES) => {
        const currentTime = convertTimeCellIdToDate(timeCellId);
        const newTime = addMinutes(currentTime, offset);

        return convertDateToTimeCellId(newTime);
    };

    // First time calendar is being loaded on create poll (or swapping between workflows and rendering calendar again)
    const [createPollFirstLoad, setCreatePollFirstLoad] = useState(true);
    const [timeCellChanged, setTimeCellChanged] = useState(false);
    // Time cell in the calendar which can be focused through tabindex
    const [focusableTimeCellId, setFocusableTimeCellId] = useState();
    const [refocusTimeCell, setRefocusTimeCell] = useState(false);

    // Returns an array containing all of the time cells for a time slot based on starting time and duration
    const getTimeSlotCells = (date, hour, minute, duration) => {
        let currentTimeCellId = getTimeCellId(date, hour, minute);
        let currentTimeCell = document.getElementById(currentTimeCellId);

        let timeCells = [currentTimeCell];

        // How many time cells fit into the time slot
        const timeSlotCellCount = duration / CREATE_POLL_TIME_CELL.MINUTES;

        // Obtain the remaining time cells
        for (let i = 1; i < timeSlotCellCount; i++) {
            let nextTimeCellId = getOffsetTimeCellId(currentTimeCellId);
            let nextTimeCell = document.getElementById(nextTimeCellId);

            if (nextTimeCell) {
                timeCells.push(nextTimeCell);

                currentTimeCellId = nextTimeCellId;
            }
        }

        return timeCells;
    };

    // Return an object containing the style for a given time cell ID based on its position in the time slot
    const calculateTimeCellStyle = (hour, minute, duration, timeCellId) => {
        const timeCellStyle = timeSlotSelectable(hour, minute, duration) ? {
            border: "2px solid #077398",
            borderTop: "2px solid #077398",
            borderBottom: "2px solid #077398",
            borderRadius: "4px",
        }
        :
        {};

        const timeSlotCellCount = duration / CREATE_POLL_TIME_CELL.MINUTES;
        const timeCellIndex = selectedTimeCellIds.indexOf(timeCellId);

        if (timeCellIndex > -1) {
            timeCellStyle.border = "4px solid #077398";
            timeCellStyle.borderTop = "4px solid #077398";
            timeCellStyle.borderBottom = "4px solid #077398";

            if (timeSlotCellCount > 1) {
                if (timeCellIndex % timeSlotCellCount === 0) {
                    // time cell is the first in the time slot
                    timeCellStyle.borderBottom = null;
                    timeCellStyle.borderRadius = "4px 4px 0px 0px";
                } else if (timeCellIndex % timeSlotCellCount === timeSlotCellCount - 1) {
                    // time cell is the last in the time slot
                    timeCellStyle.borderTop = null;
                    timeCellStyle.borderRadius = "0px 0px 4px 4px";
                } else {
                    // time cell is in the middle of the time slot
                    timeCellStyle.borderTop = null;
                    timeCellStyle.borderBottom = null;
                    timeCellStyle.borderRadius = "0px";
                }
            }
        }

        return timeCellStyle;
    };

    // Return the aria-label for the currently focused cell
    const calculateTimeCellAriaLabel = (duration, timeCellId) => {
        let ariaLabel = "";
        let firstTimeCellId = timeCellId;

        const selected = selectedTimeCellIds.includes(timeCellId);
        let includesSelected = false;

        if (selected) {
            // Determine the time slot that the focused time cell is in
            const timeSlotCellCount = timeSlotDuration / CREATE_POLL_TIME_CELL.MINUTES;
            const timeCellIndex = selectedTimeCellIds.indexOf(timeCellId);
            // The index of the time cell relative to the time slot it is a part of
            const timeSlotCellIndex = timeCellIndex % timeSlotCellCount;

            firstTimeCellId = getOffsetTimeCellId(timeCellId, -(timeSlotCellIndex * CREATE_POLL_TIME_CELL.MINUTES));
        } else {
            const timeCellInfo = timeCellId.split("_");
            const timeCells = getTimeSlotCells(timeCellInfo[1], timeCellInfo[2], timeCellInfo[3], duration);

            // Check if any time cells overlap with selected time slots
            for (let i = 0; i < timeCells.length; i++) {
                if (selectedTimeCellIds.includes(timeCells[i]?.id)) {
                    firstTimeCellId = timeCells[i].id;
                    includesSelected = true;
                    break;
                }
            }
        }

        // Get the start and end time of the time slot
        const startTime = convertTimeCellIdToDate(firstTimeCellId).getTime() / 1000;
        const endTime = convertTimeCellIdToDate(getOffsetTimeCellId(firstTimeCellId, duration)).getTime() / 1000;
        const selectedTime = convertTimeCellIdToDate(timeCellId).getTime() / 1000;
        const selectedTimeString = convertEpoch(selectedTime, "time", timezone);

        const timeString = getTimeSlotString(startTime, endTime, timezone);

        if (selected) {
            ariaLabel = `Unmark ${timeString} as available, current selected block is ${selectedTimeString}`;
        } else if (includesSelected) {
            ariaLabel = `Time slot conflicts with selected time slot at ${timeString}`;
        } else if (!selected) {
            ariaLabel = `Mark ${timeString} as available`;
        }

        return ariaLabel;
    };

    // Returns whether the given time slot for hour X (0 to 23) and minute Y (00, 15, 30, 45) and duration Z (in minutes)
    // is a valid selectable time slot that a customer can choose to include in their meeting poll
    // Currently a time slot is valid as long as it meets the following:
    // 1. Does not include midnight (starting/ending on midnight is OK)
    const timeSlotSelectable = (hour, minute, duration) => {
        // Calculate how many cells are in a time slot based on duration
        const timeSlotCellCount = duration / CREATE_POLL_TIME_CELL.MINUTES;

        // Hide (timeSlotCellCount - 1) time slots.
        // We want to hide any time slots that include midnight (ending on midnight is OK).
        // If we have a duration of 1 hour, each time slot is 4 cells, so we need to hide 3 time slots.
        // Those 3 time slots start at 11:45, 11:30, and 11:15.
        // If we have a duration of 2 hours, each time slot is 8 cells, so we need to hide 7 time slots.
        // Those 7 time slots start at 11:45, 11:30, 11:15, 11:00, 10:45, 10:30, and 10:15.
        const timeSlotsToHide = timeSlotCellCount - 1;

        // Calculate how many cells (including this one) we have left in the day
        // ((24 - hour) * 4): Every hour has 4 cells
        // ((+minute) / 15): Every 15 minutes is 1 less cell
        const timeCellsRemaining = ((24 - hour) * 4) - ((+minute) / 15);

        // If we have more cells remaining that the number we have to hide,
        // that means this time slot does not include midnight
        const doesNotIncludeMidnight = timeCellsRemaining > timeSlotsToHide

        return doesNotIncludeMidnight;
    }

    // Return a time cell rendered on the calendar for creating polls
    const renderPollTimeCell = (date, hour, minute, duration) => {
        const timeCellId = getTimeCellId(date, hour, minute);

        // How many time cells fit into the time slot
        const timeSlotCellCount = duration / CREATE_POLL_TIME_CELL.MINUTES;

        const maxTimeSlotsReached = (selectedTimeCellIds.length / timeSlotCellCount) >= MAX_TIME_SLOTS;

        // time slot is before current date
        const occursInPast = isBefore(convertTimeCellIdToDate(timeCellId), convertDateTimezone(new Date(), timezone, false));

        const onMouseOver = (event) => {
            const timeCells = getTimeSlotCells(date, hour, minute, duration);

            const selected = selectedTimeCellIds.includes(timeCells[0].id);

            const isDisabled = maxTimeSlotsReached || // max time slots selected
                timeCells.some((timeCell) => selectedTimeCellIds.includes(timeCell.id)) || // time cells overlap with selected time slots
                !timeSlotSelectable(hour, minute, duration) || // time slot is not selectable because it includes midnight
                occursInPast; // time slot is before current date

            if (selected) {
                event.target.style.cursor = "pointer";
            } else if (isDisabled) {
                event.target.style.cursor = "not-allowed";
            } else {
                let currentTimeCell;

                // Style each of the time cells within the time slot
                for (let i = 0; i < timeCells.length; i++) {
                    currentTimeCell = timeCells[i];

                    currentTimeCell.style.opacity = "1";
                    currentTimeCell.style.border = "2px solid #077398";

                    if (duration !== CREATE_POLL_TIME_CELL.MINUTES) {
                        if (i === 0) {
                            // First cell in the time slot
                            currentTimeCell.style.borderRadius = "4px 4px 0px 0px";
                            currentTimeCell.style.borderTop = "2px solid #077398";
                            currentTimeCell.style.borderBottom = null;
                        } else if (i === timeCells.length - 1) {
                            // Last cell in the time slot
                            currentTimeCell.style.borderRadius = "0px 0px 4px 4px";
                            currentTimeCell.style.borderTop = null;
                            currentTimeCell.style.borderBottom = "2px solid #077398";
                        } else {
                            // Cell within the time slot
                            currentTimeCell.style.borderRadius = "0";
                            currentTimeCell.style.borderTop = null;
                            currentTimeCell.style.borderBottom = null;
                        }
                    }
                }
            }
        };

        const onMouseOut = (event) => {
            const timeCells = getTimeSlotCells(date, hour, minute, duration);

            let currentTimeCell;

            // Hide any time cells within the time slot if unselected and unfocused
            for (let i = 0; i < timeCells.length; i++) {
                currentTimeCell = timeCells[i];

                if (!selectedTimeCellIds.includes(currentTimeCell.id) &&
                    currentTimeCell.id !== document.activeElement.id &&
                    !occursInPast) {
                    currentTimeCell.style.opacity = "0";
                }
            }
        };

        const onFocus = (event) => {
            const timeCells = getTimeSlotCells(date, hour, minute, duration);

            const selected = selectedTimeCellIds.includes(timeCells[0].id);

            if (!selected) {
                event.target.style.opacity = "1";
            }
        };

        const onBlur = (event) => {
            const timeCells = getTimeSlotCells(date, hour, minute, duration);

            const selected = selectedTimeCellIds.includes(timeCells[0].id);

            if (!selected && !occursInPast) {
                event.target.style.opacity = "0";
            }
        };

        const onClick = (event) => {
            const timeCells = getTimeSlotCells(date, hour, minute, duration);

            const selected = selectedTimeCellIds.includes(timeCells[0].id);

            // Check if any time cells overlap with selected time slots
            const includesSelected = timeCells.some((timeCell) => selectedTimeCellIds.includes(timeCell.id));

            const isDisabled = !timeSlotSelectable(hour, minute, duration) || // time slot is not selectable because it includes midnight
                occursInPast; // time slot is before current date

            if (selected) {
                // Remove selected time slot
                let newSelectedTimeCellIds = selectedTimeCellIds;

                const timeCellIndex = selectedTimeCellIds.indexOf(timeCellId);

                // The index of the time cell relative to the time slot it is a part of
                const timeSlotCellIndex = timeCellIndex % timeSlotCellCount;

                // Loop through the previous and next time cells within the time slot to find all time cells to unselect
                let currentTimeCellId = timeCellId;

                // Array containing time cells for the selected time slot
                let timeSlotCells = [currentTimeCellId];

                for (let i = 0; i < timeSlotCellIndex; i++) {
                    // Previous time cells within the time slot
                    let previousTimeCellId = getOffsetTimeCellId(currentTimeCellId, -CREATE_POLL_TIME_CELL.MINUTES);

                    timeSlotCells.push(previousTimeCellId);

                    currentTimeCellId = previousTimeCellId;
                }

                currentTimeCellId = timeCellId;

                for (let i = timeSlotCellIndex + 1; i < timeSlotCellCount; i++) {
                    // Next time cells within the time slot
                    let nextTimeCellId = getOffsetTimeCellId(currentTimeCellId, CREATE_POLL_TIME_CELL.MINUTES);

                    timeSlotCells.push(nextTimeCellId);

                    currentTimeCellId = nextTimeCellId;
                }

                // Filter out the time cells within the time slot being unselected
                timeSlotCells.forEach((timeCellId) => {
                    newSelectedTimeCellIds = newSelectedTimeCellIds.filter((selectedTimeCell) => selectedTimeCell !== timeCellId);
                });

                setSelectedTimeCellIds(newSelectedTimeCellIds);
                props.setMaxTimeSlotsWarningMessage("");
            } else if (maxTimeSlotsReached) {
                // Maximum amount of time slots selected
                props.setMaxTimeSlotsWarningMessage(MAX_TIME_SLOTS_MESSAGE);
                // TODO: make a toast display with message?
            } else if (!includesSelected && !isDisabled) {
                // Select time slot
                let newSelectedTimeCellIds = [...selectedTimeCellIds];
                // TODO: investigate optimization, this is faster but does not trigger state changes
                // let newSelectedTimeCellIds = selectedTimeCellIds;

                let currentTimeCell;

                // Style each of the time cells within the selected time slot
                for (let i = 0; i < timeCells.length; i++) {
                    currentTimeCell = timeCells[i];
                    newSelectedTimeCellIds.push(currentTimeCell.id);

                    currentTimeCell.style.opacity = "1";
                    currentTimeCell.style.border = "4px solid #077398";

                    if (duration !== CREATE_POLL_TIME_CELL.MINUTES) {
                        if (i === 0) {
                            // First cell in the time slot
                            currentTimeCell.style.borderRadius = "4px 4px 0px 0px";
                            currentTimeCell.style.borderBottom = null;
                        } else if (i === timeCells.length - 1) {
                            // Last cell in the time slot
                            currentTimeCell.style.borderRadius = "0px 0px 4px 4px";
                            currentTimeCell.style.borderTop = null;
                        } else {
                            // Cell within the time slot
                            currentTimeCell.style.borderRadius = "0";
                            currentTimeCell.style.borderTop = null;
                            currentTimeCell.style.borderBottom = null;
                        }
                    }
                }

                setSelectedTimeCellIds(newSelectedTimeCellIds);
                setTimeCellChanged(true);
            }
        };

        const onKeyDown = (event) => {
            const key = event.which || event.keyCode;

            let focusedTimeCellId;

            switch (key) {
                case KEYCODE.UP:
                    // Previous time in the same day
                    focusedTimeCellId = getOffsetTimeCellId(timeCellId, -CREATE_POLL_TIME_CELL.MINUTES);
                    event.preventDefault();
                    break;
                case KEYCODE.DOWN:
                    // Next time in the same day
                    focusedTimeCellId = getOffsetTimeCellId(timeCellId, CREATE_POLL_TIME_CELL.MINUTES);
                    event.preventDefault();
                    break;
                case KEYCODE.LEFT:
                    // Same time in the previous day
                    focusedTimeCellId = getOffsetTimeCellId(timeCellId, -TIME_CONSTANT.ONE_DAY_IN_MIN);
                    event.preventDefault();
                    break;
                case KEYCODE.RIGHT:
                    // Same time in the next day
                    focusedTimeCellId = getOffsetTimeCellId(timeCellId, TIME_CONSTANT.ONE_DAY_IN_MIN);
                    event.preventDefault();
                    break;
                case KEYCODE.SPACE:
                    // Same cell as currently focused
                    setFocusableTimeCellId(timeCellId);
                    setRefocusTimeCell(true);
                    // Perform the click action on the focused time slot
                    onClick(event);
                    event.preventDefault();
                    break;
                default:
                    break;
            }

            let focusedTimeCell = document.getElementById(focusedTimeCellId);

            if (focusedTimeCell) {
                // Unfocus currently focused cell and set focus to the new one
                document.activeElement.setAttribute("tabindex", "-1");
                focusedTimeCell.setAttribute("tabindex", "0");
                focusedTimeCell.focus();
            }
        };

        const timeCellStyle = calculateTimeCellStyle(hour, minute, duration, timeCellId);

        return (
            <div
                key={timeCellId}
                id={timeCellId}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onClick={onClick}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                tabIndex={timeCellId === focusableTimeCellId ? "0" : "-1"}
                style={
                    occursInPast ? {
                        backgroundColor: "#F0F1F2", // Meridian Gray-100,
                        width: "100%",
                        height: "100%",
                    } : {
                        opacity: selectedTimeCellIds.includes(timeCellId) ? "1" : "0",
                        border: timeCellStyle.border,
                        borderTop: timeCellStyle.borderTop,
                        borderBottom: timeCellStyle.borderBottom,
                        borderRadius: timeCellStyle.borderRadius,
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                    }
                }
                aria-label={calculateTimeCellAriaLabel(duration, timeCellId)}
            >
            </div>
        );
    };

    const WeekDay = React.memo(
        ({ date, groupId }) => {
            const dayEvents = events && events[date] ? events[date] : [];
            const hour = groupId.length < 2 ? `0${groupId}` : groupId;
            const hourEvents = groupId === allDayLabel ? props.allDayEvents[date] : getEvents(dayEvents, hour);
            const overlappingMeetingParams = props.overlappingEvents[date] || {};
            const isMeetingInShareRange = calendarMode === CALENDAR_MODE.AVAILABILITY_SHARING &&
                (date >= availabilityBlocks.availabilityRangeStart && date <= availabilityBlocks.availabilityRangeEnd);
            return (
                <Row height={`${groupId === allDayLabel ? (0.25 * mostAllDayEventsInADay * baseTileHeight) : baseTileHeight - baseCalendarPadding}px`}
                 width="100%" spacing="none" spacingInset="none" alignmentVertical="top">
                    {hourEvents && hourEvents.map((event, index, list) =>
                        <div key={index} style={{zIndex: "1"}} className={event.isAllDayEvent ? "allDayTile" : "eventTile"}>
                            <Tile id={event.entryID}
                                accent={getMeetingStatusColor(event.status, event.subject.startsWith("Canceled"))}
                                layout={calculateLayout(event, index, list, overlappingMeetingParams[event.entryID], date)}
                                aria-describedby={`${date}-${hour}-${index}-status ${date}-${hour}-${index}-response`}
                                onClick={() => onOpenPopover(event)}
                            >
                                <Row widths={["fill", "fit", "fit"]} spacing="none" spacingInset="none" alignmentVertical="top">
                                    <Column width="80%" spacingInset="none" spacing="none" alignmentVertical="top">
                                        <Heading level={2} type="b200" truncate={true}>
                                            {event.subject || "Untitled meeting"}
                                        </Heading>
                                        {event.duration >= 0.5 && !event.isAllDayEvent &&
                                            <Text type="b100" color="secondary" truncate={true}>
                                                {event.location || ""}
                                            </Text>
                                        }
                                    </Column>
                                    {(calendarMode === CALENDAR_MODE.AVAILABILITY_SHARING && !event.isAllDayEvent && isMeetingInShareRange) &&
                                        <Text type="b200">
                                            <Link onClick={() => props.onUpdateAvailability(MAPS_CALENDAR_STATE.MEETING_SHARED, date, date, event.startTime.time, event.endTime.time)}>
                                                Share
                                            </Link>
                                        </Text>
                                    }
                                    {calendarMode === CALENDAR_MODE.DEFAULT &&
                                        <div style={{lineHeight: "16px"}}>
                                            {getMeetingResponseIcon(event.response, event.subject.startsWith("Canceled"))}
                                        </div>
                                    }
                                    <div style={{display: "none"}}>
                                        <Text id={`${date}-${hour}-${index}-status`}>status: {event.status}</Text>
                                        <Text id={`${date}-${hour}-${index}-response`}>response: {getResponseLabel(event.response, event.subject.startsWith("Canceled"))}</Text>
                                    </div>
                                </Row>
                            </Tile>
                        </div>
                    )}
                    {calendarMode === CALENDAR_MODE.CREATE_POLL && groupId !== allDayLabel &&
                        <Column width="100%" height="100%" spacing="none" heights={["25%", "25%", "25%", "25%"]}>
                            {["00", "15", "30", "45"].map((timeCellMinutes) => (
                                 renderPollTimeCell(date, hour, timeCellMinutes, timeSlotDuration)
                            ))}
                        </Column>
                    }
                    {calendarMode === CALENDAR_MODE.AVAILABILITY_SHARING &&
                        availabilityBlocks[date] && availabilityBlocks[date][parseInt(hour)] &&
                        availabilityBlocks[date][parseInt(hour)].map((availabilityBlock, index) =>
                            availabilityBlock.state !== MAPS_CALENDAR_STATE.MEETING_NOT_SHARED &&
                            <AvailabilityBlock
                                key={`available-block-${date}-${hour}-${index}`}
                                index={index}
                                startTime={availabilityBlock.startTime}
                                duration={availabilityBlock.duration}
                                baseHeight={baseTileHeight}
                                state={availabilityBlock.state}
                                onSelectAvailabilityBlock={() => {props.onUpdateAvailability(MAPS_CALENDAR_STATE.EMPTY_SHARED, date, date, availabilityBlock.startTime, availabilityBlock.endTime)}}
                                onDismissAvailabilityBlock={() => {props.onUpdateAvailability(availabilityBlock.state === MAPS_CALENDAR_STATE.MEETING_SHARED ? MAPS_CALENDAR_STATE.MEETING_NOT_SHARED : MAPS_CALENDAR_STATE.EMPTY_NOT_SHARED, date, date, availabilityBlock.startTime, availabilityBlock.endTime)}}
                                hour={hour}
                                date={date}
                                onStartDrag={onStartDrag}
                            />
                    )}
                    {`${now.getHours()}` === groupId &&
                        <div style={{
                            width: "100%",
                            position: "absolute",
                            left: "0px",
                            top: `${((now.getMinutes() && now.getMinutes() / 60) * baseTileHeight) - defaultMeridianPadding}px`,
                            zIndex: 3,
                            pointerEvents: "none"
                        }}>
                            <hr style={{border: "1px dashed #0AABC4"}}  />
                        </div>
                    }
                    {groupId !== allDayLabel &&
                        <div style={{
                            width: "100%",
                            position: "absolute",
                            left: "0px",
                            top: `${(0.5 * baseTileHeight) - dividerAdjustment}px`,
                            opacity: "50%",
                            pointerEvents: "none"
                        }}>
                            <hr/>
                        </div>
                    }
                </Row>
            );
        },
        (prevProps, nextProps) => prevProps.date === nextProps.date
    );

    // Remove Meridian's padding around calendar cells for create poll
    useEffect(() => {
        if (meetingListLoaded && calendarMode === CALENDAR_MODE.CREATE_POLL) {
            const calendarCells = document.querySelectorAll("div[mdn-calendar-stacked-events]");

            calendarCells.forEach((cell) => {
                cell.style.padding = 0;
            });
        }
    }, [meetingListLoaded, calendarMode, viewType, date]);

    // Refocus calendar scroll to earliest event tile's position
    const [refocusScroll, setRefocusScroll] = useState(true);
    useEffect(() => {
        if (meetingListLoaded && calendarMode === CALENDAR_MODE.CREATE_POLL && refocusScroll) {
            let calendarContainer = document.getElementById("calendar-week-container")?.children[0];
            // First event tile is the earliest in the day (does not include all day events)
            let firstEventTile = document.getElementsByClassName("eventTile")[0];

            if (calendarContainer) {
                // Get the first time cell in the calendar to set initial tabindex
                setFocusableTimeCellId(document.querySelectorAll("*[id^='timeCell']")[0]?.id);

                if (firstEventTile) {
                    // Get position of the tile relative to the calendar container
                    const scrollPosition = firstEventTile.getBoundingClientRect().top - calendarContainer.getBoundingClientRect().top;

                    calendarContainer.scrollTop = scrollPosition - 50;

                    setRefocusScroll(false);
                }
            }
        } else if (!meetingListLoaded) {
            // Meeting list has changed, trigger another refocus
            setRefocusScroll(true);
        }
    }, [calendarMode, refocusScroll, meetingListLoaded]);

    // Refocus onto the selected time cell
    useEffect(() => {
        if (refocusTimeCell && document.activeElement.id !== focusableTimeCellId) {
            setRefocusTimeCell(false);

            // Add a small delay to focus action for component rendering
            window.setTimeout(() => {
                let focusedTimeCell = document.getElementById(focusableTimeCellId);

                if (focusedTimeCell) {
                    focusedTimeCell.focus();
                }
            }, 50);
        }
    }, [refocusTimeCell, focusableTimeCellId]);

    // Sync selected time slots with time cells on calendar
    useEffect(() => {
        if (calendarMode === CALENDAR_MODE.CREATE_POLL) {
            if (createPollFirstLoad) {
                // Use selected time slots for calendar time cells
                let newTimeCellIds = [];

                const timeSlotCellCount = timeSlotDuration / CREATE_POLL_TIME_CELL.MINUTES;

                selectedTimeSlots.forEach((timeSlot) => {
                    // Obtain all time cell ids for selected time slots
                    let currentTimeSlotId = convertDateToTimeCellId(convertDateTimezone(new Date(timeSlot.startTime * 1000), timezone, false));
                    newTimeCellIds.push(currentTimeSlotId);

                    for (let i = 1; i < timeSlotCellCount; i++) {
                        let nextTimeCellId = getOffsetTimeCellId(currentTimeSlotId);

                        newTimeCellIds.push(nextTimeCellId);

                        currentTimeSlotId = nextTimeCellId;
                    }
                });

                setSelectedTimeCellIds(newTimeCellIds);
                setCreatePollFirstLoad(false);
            } else {
                // User is changing time slots through calendar, sync time slots and calendar time cells
                const timeSlotCellCount = timeSlotDuration / CREATE_POLL_TIME_CELL.MINUTES;
                const calendarTimeSlotCount = selectedTimeCellIds.length / timeSlotCellCount;

                if (calendarTimeSlotCount > selectedTimeSlots.length) {
                    // Number of calendar time slots is greater than selected time slots
                    if (timeCellChanged) {
                        // Calendar time slot was added
                        let newSelectedTimeSlots = [...selectedTimeSlots];

                        for (let i = 0; i < calendarTimeSlotCount; i++) {
                            let firstCellIndex = i * timeSlotCellCount;
                            let lastCellIndex = firstCellIndex + timeSlotCellCount - 1;
                            let slotStartTime = convertDateTimezone(convertTimeCellIdToDate(selectedTimeCellIds[firstCellIndex]), timezone) / 1000;
                            let slotEndTime = convertDateTimezone(convertTimeCellIdToDate(getOffsetTimeCellId(selectedTimeCellIds[lastCellIndex])), timezone) / 1000;

                            if (!selectedTimeSlots.some((timeSlot) => (
                                timeSlot.startTime === slotStartTime
                            ))) {
                                // Time slot is not in the selected time slots
                                newSelectedTimeSlots.push({
                                    startTime: slotStartTime,
                                    endTime: slotEndTime
                                });
                            }
                        }

                        setSelectedTimeSlots(newSelectedTimeSlots);
                        setTimeCellChanged(false);
                    } else {
                        // Time slot was removed

                        let newSelectedTimeCellIds = [];

                        for (let i = 0; i < calendarTimeSlotCount; i++) {
                            let firstCellIndex = i * timeSlotCellCount;
                            let lastCellIndex = firstCellIndex + timeSlotCellCount - 1;
                            let slotStartTime = convertDateTimezone(convertTimeCellIdToDate(selectedTimeCellIds[firstCellIndex]), timezone) / 1000;

                            if (selectedTimeSlots.some((timeSlot) => (
                                timeSlot.startTime === slotStartTime
                            ))) {
                                // Time slot is in the selected time slots
                                selectedTimeCellIds.slice(firstCellIndex, lastCellIndex + 1).forEach((timeCellId) => {
                                    newSelectedTimeCellIds.push(timeCellId);
                                });
                            }
                        }

                        setSelectedTimeCellIds(newSelectedTimeCellIds);
                    }
                } else if (selectedTimeSlots.length > calendarTimeSlotCount) {
                    // Calendar time slot was removed
                    let newSelectedTimeSlots = [];

                    selectedTimeSlots.forEach((timeSlot) => {
                        let currentTimeSlotId = convertDateToTimeCellId(convertDateTimezone(new Date(timeSlot.startTime * 1000), timezone, false));

                        if (selectedTimeCellIds.includes(currentTimeSlotId)) {
                            newSelectedTimeSlots.push(timeSlot);
                        }
                    })

                    setSelectedTimeSlots(newSelectedTimeSlots);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimeSlots, selectedTimeCellIds]);

    return (
        !meetingListLoaded ?
            <LoadingMeetings
                loadingMessage="Loading your meetings..."
                loaderSize="large"
            />
            :
            <Column height="100%" width="100%" type="outline" id="calendar-week-container">
                {
                    /* Adding this check because there is conflict in style between the Calendar and MAPS otherwise.
                     * The div with the drag handlers doesn't allow you to scroll down in the calendar page to see all your
                     * meeting events for the day. Hope we can find an appropriate fix for this later.
                     */
                }
                {calendarMode === CALENDAR_MODE.AVAILABILITY_SHARING ?
                    <div
                        onMouseMove={dragging && onDrag}
                        onMouseUp={dragging && onEndDrag}
                        onMouseLeave={dragging && onEndDrag}
                    >
                        <CalendarWeekGridView
                            viewDate={date}
                            renderDay={WeekDay}
                            groupIds={times}
                            renderGroupHeader={GroupHeader}
                            days={getDaysOfWeekToShowInCalendar()}
                            groupHeaderWidth={50}
                            renderDayHeader={renderDayHeader}
                        />
                    </div>
                    :
                    <CalendarWeekGridView
                        viewDate={date}
                        renderDay={WeekDay}
                        groupIds={times}
                        renderGroupHeader={GroupHeader}
                        days={getDaysOfWeekToShowInCalendar()}
                        groupHeaderWidth={50}
                        renderDayHeader={renderDayHeader}
                    />
                }
                <MeetingDetails
                    open={meetingIdOfSelectedTile !== ""}
                    entryID={meetingIdOfSelectedTile}
                    onClose={onClosePopover}
                    saveAction={SET_MEETING_DETAILS}
                    selector={getMeetingDetails}
                    userEmail={props.userEmail}
                    meetingListAction={SET_MEETING_LIST}
                    meetingList={meetingList}
                    timeFormat={timeFormat}
                />
            </Column>
    );
};

export default CalendarWeeklyView;
