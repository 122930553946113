import React from "react";
import { useDispatch } from "react-redux";

import { deletePoll } from "../actions";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import oopsEmojiSvg from "../../../assets/icons/shared/oops_emoji.svg";

import MeetingsModalHeader from "../../shared/components/meetings-modal-header";
import Modal, { ModalFooter } from "../../shared/meridian-custom-components/src/components/modal";

const DeletePollModal = (props) => {
    const dispatch = useDispatch();
    const identity = props.identity;
    const pollDetails = props.pollDetails;
    const [open, setOpen] = [props.open, props.setOpen];
    const getMeetingListAction = props.getMeetingListAction;
    const meetingList = props.meetingList;

    const onDeleteMeeting = props.onDeleteMeeting;
    const onDeletePoll = (deletePollRequest) => dispatch(deletePoll(deletePollRequest));

    const onClickDelete = () => {
        let deletePollRequest = {
            identity: pollDetails.identity,
            pollID: pollDetails.pollID,
            requestedOnBehalfOf: pollDetails.requestedOnBehalfOf,
            organizer: pollDetails.organizer

            // TODO: Handle delegation for deletePoll in the future
            // delegate: "test",
            // ccDelegate: false
        };

        const calendarHolds = pollDetails?.calendarHolds;

        for (const calendarHold of calendarHolds) {
            let deleteMeetingRequest = {
                requestedOnBehalfOf: identity.email, // RAS is using email for this attribute instead of alias
                uniqueOrEntryID: calendarHold.entryID,
                master: false, // Set to false for occurrence
                sendCancellations: false,
                body: {
                    value: pollDetails.body,
                    type: "html"
                }
            };

            let setMeetingListAction;

            if (meetingList) {
                // Get new meeting list with deleted meeting omitted
                let changedMeetingList = meetingList.filter((meeting) => meeting.entryID !== calendarHold.entryID);

                setMeetingListAction = getMeetingListAction(changedMeetingList);
            }
            onDeleteMeeting(deleteMeetingRequest, "/polls", setMeetingListAction);
        }
        onDeletePoll(deletePollRequest);
        setOpen(false);
    };

    const deletePollModalId = "deletePollModal";

    return (
        <Modal
            open={open}
            bodySpacingInset="none"
            width="504px"
            backgroundElementsId={props.backgroundElementsId}
        >
            <MeetingsModalHeader
                modalId={deletePollModalId}
                modalTitle="Delete meeting poll"
                showCustomCloseButton={true}
                onCloseModal={() => setOpen(false)}
            />
            <Row id={deletePollModalId} spacingInset="medium">
                <Column>
                    <img src={oopsEmojiSvg} style={{"height": "70px", "width": "65px"}}
                         alt="oops discard meeting icon"/>
                </Column>
                <Column spacing="none">
                    <Text type="b300">Delete your meeting poll?</Text>
                    <Text type="b300">This action cannot be undone.</Text>
                </Column>
            </Row>
            <ModalFooter>
                <Row spacing="medium" alignmentHorizontal="right">
                    <Button
                        type="secondary"
                        size="medium"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        size="medium"
                        onClick={onClickDelete}
                    >
                        Delete
                    </Button>
                </Row>
            </ModalFooter>
        </Modal>
    )
};

export default DeletePollModal;