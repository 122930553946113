import { call, all, delay, takeEvery, takeLatest, put, retry } from "redux-saga/effects";
import { push } from "connected-react-router";

import _ from "lodash";

import * as Actions from "../actions/action-types";
import * as ChimeActions from "../../shared/chime/actions/action-types";
import * as LocationActions from "../../shared/locations/actions/action-types";
import * as MeetingActions from "../../meeting-scheduler/actions/action-types";
import * as OOTOActions from "../../meeting-ooto/actions/action-types";
import * as LivestreamActions from "../../meeting-livestream/actions/action-types";
import * as PeopleActions from "../../people/actions/action-types";

import { refreshAgenda, setMeetingAgendaLoaded } from "../../shared/actions";
import { createMeeting, getMeeting } from "../../api/apig/ras";
import { postCreateMeetingRoomsMetrics, postCreateLivestreamEventMetric } from "../../shared/metrics/actions";
import { UPDATE_AVAILABILITY_LIST } from "../../shared/actions/action-types";
import { showToast } from "../../shared/toasts/actions";
import { switchWorkflow } from "../../shared/workflow/actions/index";

import {
    TIME_CONSTANT,
    GET_MEETING_MAX_ATTEMPTS,
    GET_MEETING_RETRY_DELAY_IN_MS,
    ROOM_RESPONSE
} from "../../shared/shared-constants";
import { isErrorResponse } from "../../api/apig/apig-utils";
import { getSuccessToastWithComponent, getErrorToast } from "../../shared/toasts/toast-utils";
import { TIMEOUT, TOAST_COMPONENT } from "../../shared/toasts/toast-constants";
import { pathToWorkflow } from "../../shared/workflow/workflow-utils";
import {
    createEventIntake,
    getCurrentUser,
    getLatestCreatedEventDetails,
    getLivestreamEventSeriesApi,
    permissionGroupSearch,
    permissionPeopleSearch
} from "../../api/apig/livestream";
import {
    clearPermissionGroupSuggestions,
    clearPermissionPeopleSuggestions,
    saveCreateEventIntakeDetails,
    saveLatestEventDetails,
    savePermissionGroupSuggestions,
    savePermissionPeopleSuggestions,
    setLatestEventErrorDetails,
    setLivestreamEventSeriesUrlAvailability,
    setLivestreamIntakeLoaded,
} from "../actions";
import { STATUS_CODE } from "../../api/apig/apig-constants";
import { clearAttendees, getRASDataForPerson } from "../../people/actions";
import { getSystemErrorToast, LIVESTREAM_ERROR_CODES } from "../livestream-utils";
import { getEndpoint } from "../../api/apig/livestream/utils";

export function* createLivestreamApi(action) {
    // show the loading indicator
    yield put(setMeetingAgendaLoaded(false));

    let toast, scheduleToast;
    let response;

    const prepMeeting = action.prepMeetingRequest;
    const viewerMeeting = action.viewerMeetingRequest;
    const endpoint = yield getEndpoint();
    const livestreamHref = endpoint.replace("api.", "") + "/#/live/" + action.url;
    const isRecordingEvent = action?.eventIntake?.eventIntake?.eventRequestType === "RECORDING";

    const meetingUniqueIds = [
        `Prep: ${prepMeeting.meeting.uniqueID}`,
        `Viewer: ${viewerMeeting.meeting.uniqueID}`
    ];
    let meetingEntryIds = [];

    // livestream event ticket
    const ticketId = action.ticketId;
    const ticketHref = `https://t.corp.amazon.com/${ticketId}`;

    let failedMeetings = []; // Track which meeting creations failed

    // Add the ticket link to the prep meeting body
    prepMeeting.meeting.body.value = prepMeeting.meeting.body.value.replaceAll("{{TICKET_HREF}}", ticketHref);

    // Add the livestream link to the prep meeting body
    prepMeeting.meeting.body.value = prepMeeting.meeting.body.value.replaceAll("{{LIVESTREAM_HREF}}", livestreamHref);

    // Add the livestream link to the viewer meeting body
    viewerMeeting.meeting.body.value = viewerMeeting.meeting.body.value.replaceAll("{{LIVESTREAM_HREF}}", livestreamHref);

    // Create the prep hold meeting
    response = yield call(createMeeting, prepMeeting);
    const prepMeetingResponse = JSON.parse(response);

    if (isErrorResponse(prepMeetingResponse)) {
        failedMeetings.push("prep hold");

        // If prep meeting fails, do not try to schedule presenter and viewer meetings and display error toast
        let scheduleToast = getSystemErrorToast();

        yield put(showToast(scheduleToast));

        if (ticketId && action.redirectTo) {
            // If the ticket was successfully created, navigate away from workflow to prevent creating multiple tickets
            yield put(push(action.redirectTo));
            yield put(switchWorkflow(pathToWorkflow()));
        }

        // Post event creation metrics
        yield put(postCreateLivestreamEventMetric(true, ticketId, "", meetingUniqueIds, meetingEntryIds, failedMeetings));

        return;
    } else {
        // Prep meeting creation succeeded, proceed with other meeting creations

        // TODO: update RAS to contain the livestream extended property so these can be added
        // // Link the meetings together by adding each other's uniqueID in their extended properties
        // prepMeeting.meeting.extendedProperties.livestream = {
        //     __type: "com.amazon.resourceadapter#StringValue",
        //     value: JSON.stringify({
        //         livestreamMeetingType: "prepMeeting",
        //         presenterMeetingUniqueId: presenterMeeting.meeting.uniqueID,
        //         viewerMeetingUniqueId: viewerMeeting.meeting.uniqueID
        //     })
        // };

        // presenterMeeting.meeting.extendedProperties.livestream = {
        //     __type: "com.amazon.resourceadapter#StringValue",
        //     value: JSON.stringify({
        //         livestreamMeetingType: "presenterMeeting",
        //         prepMeetingUniqueId: prepMeeting.meeting.uniqueID,
        //         viewerMeetingUniqueId: viewerMeeting.meeting.uniqueID
        //     })
        // };

        // viewerMeeting.meeting.extendedProperties.livestream = {
        //     __type: "com.amazon.resourceadapter#StringValue",
        //     value: JSON.stringify({
        //         livestreamMeetingType: "viewerMeeting",
        //         prepMeetingUniqueId: prepMeeting.meeting.uniqueID,
        //         presenterMeetingUniqueId: presenterMeeting.meeting.uniqueID
        //     })
        // };

        if (prepMeetingResponse.entryID) {
            meetingEntryIds.push(`Prep: ${prepMeetingResponse.entryID}`);
        }

        // Create the viewer meeting
        response = yield call(createMeeting, viewerMeeting);
        const viewerMeetingResponse = JSON.parse(response);

        if (isErrorResponse(viewerMeetingResponse)) {
            failedMeetings.push("viewer");
        } else if (viewerMeetingResponse.entryID) {
            meetingEntryIds.push(`Viewer: ${viewerMeetingResponse.entryID}`);
        }

        if (failedMeetings.length) {
            scheduleToast = getSystemErrorToast();
        }
    }

    // Meeting creation clears draft and redirects user to the homepage.
    // TODO: Need to fix states so we only clear the flow that the user was working on
    yield put({type: MeetingActions.CLEAR_DRAFT});
    yield put({type: OOTOActions.CLEAR_DRAFT});
    yield put({type: LivestreamActions.CLEAR_DRAFT});
    yield put({type: PeopleActions.CLEAR_DRAFT});
    yield put({type: LocationActions.CLEAR_LOCATIONS_AND_ROOMS});
    yield put({type: ChimeActions.CLEAR_CHIME_PIN});
    if (action.redirectTo) {
        yield put(push(action.redirectTo));
        yield put(switchWorkflow(pathToWorkflow()));
    }

    // verify if the meeting was created and accepted by all rooms
    let parsedMeeting;
    try {
        const meetingHasRooms = prepMeeting.meeting
            && prepMeeting.meeting.resources
            && prepMeeting.meeting.resources.length > 0;
        if (meetingHasRooms) {
            // adding delay before getMeeting to allow rooms to send response
            yield delay(TIME_CONSTANT.MEETING_CREATE_VERIFICATION_WAIT_TIME);

            // Success: returns the stringified meeting with all rooms responded,
            // Semi-success: throws if final attempt still has rooms that did not respond,
            // Error: returns a stringified error if there was an issue getting the meeting
            const meeting = yield retry(GET_MEETING_MAX_ATTEMPTS, GET_MEETING_RETRY_DELAY_IN_MS, getMeetingWithRoomResponses, [
                prepMeeting.meeting.organizer,
                prepMeetingResponse.entryID,
                false, // TODO: handle recurring meeting
                ""
            ]);
            parsedMeeting = JSON.parse(meeting);
        } else {
            // If there were no rooms, do not call getMeeting since we do not need to verify if rooms accepted.
            parsedMeeting = {resources: []};
        }
    } catch (e) {
        // The meeting retrieved on the final attempt had some rooms that did not respond,
        // so this error was thrown with the retrieved meeting object stored in the error message.
        // This is a workaround for now since retry does not support this use case.
        // We can revisit this implementation if we find similar use cases in the future.
        parsedMeeting = JSON.parse(e.message);
    }

    if (isErrorResponse(parsedMeeting)) {
        toast = getErrorToast("An error occurred when verifying your meeting, please check your email for meeting confirmation(s).");
    } else {
        // room response status verification
        const allRooms = parsedMeeting.resources || [];
        const noResponseRooms = allRooms.filter((room) => room.response !== ROOM_RESPONSE.ACCEPT && room.response !== ROOM_RESPONSE.DECLINE);
        const acceptedRooms = allRooms.filter((room) => room.response === ROOM_RESPONSE.ACCEPT);
        const declinedRooms = allRooms.filter((room) => room.response === ROOM_RESPONSE.DECLINE);

        yield put(postCreateMeetingRoomsMetrics(allRooms, noResponseRooms, acceptedRooms, declinedRooms));

        if (acceptedRooms.length !== allRooms.length) {
            if (!_.isEmpty(declinedRooms)) {
                let declinedError = "The following room(s) declined your invitation: ROOM_LIST. " +
                    "Detailed reasons can be found in the response email. Please choose another room and update the meeting and ticket.";
                const declinedRoomNames = _.map(declinedRooms, 'displayName');

                toast = getErrorToast(declinedError.replace(/ROOM_LIST/, declinedRoomNames.join(',')));
            } else {
                let noResponseError = "The following room(s) did not send a response yet: ROOM_LIST. " +
                    "Please check your email for their responses.";
                const noResponseRoomNames = _.map(noResponseRooms, 'displayName');

                toast = getErrorToast(noResponseError.replace(/ROOM_LIST/, noResponseRoomNames.join(',')));
            }
        } else {
            if (prepMeeting.meeting.subject.includes("via Amazon Meetings")) {
                yield put({
                    type: UPDATE_AVAILABILITY_LIST,
                    suggestion: {
                        isException: false,
                        isPrivate: false,
                        isRecurring: false,
                        location: prepMeeting.meeting.resources[0].name,
                        status: "busy",
                        subject: prepMeeting.meeting.displayName || prepMeeting.meeting.subject,
                        time: prepMeeting.meeting.time
                    },
                    email: prepMeeting.meeting.resources[0].email
                });
            }
            toast = getSuccessToastWithComponent();
            toast.toastActionProps.livestreamLinkText = "Livestream URL";
            toast.toastActionProps.livestreamLinkName = "Livestream URL";
            toast.toastActionProps.livestreamHref = livestreamHref;
            toast.toastActionProps.ticketHref = ticketHref;
            toast.toastActionProps.ticketLinkText = "generated ticket";
            toast.toastActionProps.ticketLinkName = "generated ticket";
            toast.toastActionProps.isRecordingEvent = isRecordingEvent;
            toast.toastActionProps.componentName = TOAST_COMPONENT.INTAKE_MEETING_CREATED;
            toast.toastTimeout = TIMEOUT.LONG;
        }
    }

    // need to refresh the meeting after the meeting is fully created.
    // otherwise won't be able to get the correct eligibility/checkIn status.
    yield put(refreshAgenda());

    yield put(showToast(toast)); // Toast for prep meeting creation
    if (scheduleToast) {
        yield put(showToast(scheduleToast)); // Toast for failed meeting creations
    }
    // Post event creation metrics
    yield put(postCreateLivestreamEventMetric(true, ticketId, "", meetingUniqueIds, meetingEntryIds, failedMeetings));
}

function* getMeetingWithRoomResponses(params) {
    const response = yield call(getMeeting,
        params[0], // organizer
        params[1], // entryID
        params[2], // master
        params[3], // propertyNames
    );
    const parsedMeeting = JSON.parse(response);
    const resources = parsedMeeting.resources || [];
    const noResponseRooms = resources.filter((room) => room.response !== ROOM_RESPONSE.ACCEPT && room.response !== ROOM_RESPONSE.DECLINE);
    if (noResponseRooms.length > 0) {
        throw Error(response);
    }

    // Rooms responded or there was an error, do not retry
    return response;
}

export function* updateCurrentMeeting(action) {
    yield put({type: Actions.UPDATE_CURRENT_MEETING, meeting: action.meeting});
}

export function* watchUpdateCurrentMeeting() {
    yield takeEvery(Actions.UPDATE_MEETING_ASYNC, updateCurrentMeeting);
}

// livestream sagas for group and people
export function* watchPermissionPeopleSearch() {
    yield takeLatest(Actions.GET_PERMISSION_PEOPLE_SUGGESTIONS, permissionPeopleSearchApi);
}

export function* permissionPeopleSearchApi(action) {
    yield put(clearPermissionPeopleSuggestions());
    // Debounce, wait 500ms before sending a request
    yield delay(500);

    console.log("Calling permissionPeopleSearch in sagas for: " + action.query);
    const response = yield call(permissionPeopleSearch, action.query);
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        console.error(`An error occurred when finding users for query "${action.query}".`);
    }
    const people = (parsedResponse && parsedResponse.records) || [];

    yield put(savePermissionPeopleSuggestions(people));
}

export function* watchPermissionGroupSearch() {
    yield takeLatest(Actions.GET_PERMISSION_GROUP_SUGGESTIONS, permissionGroupSearchApi);
}

export function* permissionGroupSearchApi(action) {
    yield put(clearPermissionGroupSuggestions());
    yield delay(500);
    console.log("Calling permissionGroupSearch in sagas for: ", action.payload.query);
    const response = yield call(permissionGroupSearch, action.payload.query);
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        console.error("An error occurred when finding permission groups for query: ", action.payload.query);
        return;
    }
    const groups = (parsedResponse && parsedResponse.records) || [];
    yield put(savePermissionGroupSuggestions(groups));
}

export function* watchCreateEventIntakeDetails() {
    yield takeLatest(Actions.CREATE_EVENT_INTAKE_DETAILS, createEventIntakeApi);
}

export function* createEventIntakeApi(action) {
    console.log("Calling createEventIntake in sagas for: ", action.eventIntake.eventIntake);
    let ticketToast;
    yield put(setLivestreamIntakeLoaded(false)); // Show the loading indicator

    const response = yield call(createEventIntake, action.eventIntake.eventIntake);
    const ticketResponse = JSON.parse(response);

    const ticketError = ticketResponse.status && (
        ticketResponse.status === STATUS_CODE.BAD_REQUEST ||
        ticketResponse.status === STATUS_CODE.UNAUTHORIZED ||
        ticketResponse.status === STATUS_CODE.FORBIDDEN ||
        ticketResponse.status === STATUS_CODE.INTERNAL_SERVER_ERROR
    );

    const ticketId = (!ticketError && ticketResponse?.ticketId) || ""; // ID for the created ticket, blank if ticket creation fails

    if (!ticketId) {
        ticketToast = getSystemErrorToast();
        yield put(showToast(ticketToast));
    }

    if (isErrorResponse(ticketResponse) || !ticketId) {
        console.error("An error occurred when creating event intake form: ", action.eventIntake);
        yield put(setLivestreamIntakeLoaded(true)); // Remove the loading indicator
        return;
    }
    yield put(saveCreateEventIntakeDetails(ticketResponse));
    const eventIntakeApiActions = {...action, ...ticketResponse};
    const livestreamResponse = yield call(createLivestreamApi, eventIntakeApiActions);
    const parsedResponse = livestreamResponse && JSON.parse(livestreamResponse);
    if (parsedResponse && isErrorResponse(parsedResponse)) {
        console.error("An error occurred when sending invites");
        yield put(setLivestreamIntakeLoaded(true)); // Remove the loading indicator
        return;
    }
    yield put(setLivestreamIntakeLoaded(true)); // Remove the loading indicator
    return ticketId;
}

export function* watchCurrentUser() {
    const response = yield call(getCurrentUser);
    const parsedResponse = response && JSON.parse(response);
    if (parsedResponse && isErrorResponse(parsedResponse)) {
        console.error("An error occurred when finding current user");
        return;
    }
}

export function* watchGetLatestEventDetails() {
    yield takeEvery(Actions.GET_LATEST_EVENT_DETAILS, getLatestEventDetailsApi);
}

export function* getLatestEventDetailsApi(action) {
    const response = yield call(getLatestCreatedEventDetails, action.payload.seriesUrl);
    const parsedResponse = JSON.parse(response);

    if (isErrorResponse(parsedResponse)) {
        console.error("An error occurred when retrieving latest event details for: ", action.payload.seriesUrl);
        yield put(setLatestEventErrorDetails({errorCode: LIVESTREAM_ERROR_CODES.SERIES_NOT_EXISTS}));
        return;
    } else if (JSON.stringify(parsedResponse) === "{}") {
        yield put(setLatestEventErrorDetails({errorCode: LIVESTREAM_ERROR_CODES.EVENT_NOT_EXISTS}));
        return;
    }
    yield put(clearAttendees());
    if (parsedResponse.pointsOfContact?.length) {
        yield all(parsedResponse?.pointsOfContact?.map(poc =>
            poc !== action.payload.organizer && put(getRASDataForPerson(poc, true, "", {}, true))
        ));
    }
    yield put(saveLatestEventDetails(parsedResponse));
}

export function* watchGetLivestreamEventSeriesUrlAvailability() {
    yield takeLatest(Actions.GET_LIVESTREAM_EVENT_SERIES_URL_AVAILABILITY, getLivestreamEventSeriesUrlAvailability);
}

export function* getLivestreamEventSeriesUrlAvailability(action) {
    yield delay(500);
    const response = yield call(getLivestreamEventSeriesApi, action.payload.url);
    const parsedResponse = JSON.parse(response);
    const url_availability = {available: parsedResponse?.isLivestreamSeriesAvailable, verified: true};
    yield put(setLivestreamEventSeriesUrlAvailability(url_availability))
}

const sagas = [
    watchPermissionPeopleSearch(),
    watchPermissionGroupSearch(),
    watchCreateEventIntakeDetails(),
    watchCurrentUser(),
    watchUpdateCurrentMeeting(),
    watchGetLatestEventDetails(),
    watchGetLivestreamEventSeriesUrlAvailability()
];

export default sagas;