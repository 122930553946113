import React from "react";

import {
    DEFAULT_FEATURE_ICON_SIZE,
    ERRORS,
    LOCATION_FEATURE,
    LOCATION_FLOOR_ANY,
    USER_DEFAULT_BUILDING,
    USER_DEFAULT_FLOOR
} from "./locations-constants";
import { fillFavoriteDefaults } from "../favorites/favorites-utils";

import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";

import cameraSvg from "../../../assets/icons/locations/camera.svg";
import displaySvg from "../../../assets/icons/locations/display.svg";
import restrictedSvg from "../../../assets/icons/locations/restricted.svg";
import handManagedSvg from "../../../assets/icons/locations/hand_managed.svg";

export const renderFeatures = (room, textWhenNone = "None", textType = "b200", alignmentHorizontal = "left") => {
    let resourceList = room.resourceList || [];
    return (
        <Row spacing="small" alignmentHorizontal={alignmentHorizontal}>
            {resourceList.includes(LOCATION_FEATURE.CAMERA) && renderLocationFeatureIcon(LOCATION_FEATURE.CAMERA)}
            {resourceList.includes(LOCATION_FEATURE.DISPLAY) && renderLocationFeatureIcon(LOCATION_FEATURE.DISPLAY)}
            {room.restricted && renderLocationFeatureIcon(LOCATION_FEATURE.RESTRICTED)}
            {room.managed && renderLocationFeatureIcon(LOCATION_FEATURE.HAND_MANAGED)}
            {!resourceList.includes(LOCATION_FEATURE.CAMERA) &&
                !resourceList.includes(LOCATION_FEATURE.DISPLAY) &&
                !room.restricted &&
                !room.managed &&
                    <Text type={textType} alignment="left">{textWhenNone}</Text>
            }
        </Row>
    );
};

export const renderLocationFeatureIcon = (feature, size = DEFAULT_FEATURE_ICON_SIZE, setAlt = true) => {
    switch (feature) {
        case LOCATION_FEATURE.CAMERA:
            return (
                <Tooltip position="top" title="Camera">
                    <img src={cameraSvg} height={size} width={size} alt={setAlt ? "Camera" : ""} />
                </Tooltip>
            );
        case LOCATION_FEATURE.DISPLAY:
            return (
                <Tooltip position="top" title="Display">
                    <img src={displaySvg} height={size} width={size} alt={setAlt ? "Display" : ""} />
                </Tooltip>
            );
        case LOCATION_FEATURE.RESTRICTED:
            return (
                <Tooltip position="top" title="Restricted">
                    <img src={restrictedSvg} height={size} width={size} alt={setAlt ? "Restricted" : ""} />
                </Tooltip>
            );
        case LOCATION_FEATURE.HAND_MANAGED:
            return (
                <Tooltip position="top" title="Hand Managed">
                    <img src={handManagedSvg} height={size} width={size} alt={setAlt ? "Hand Managed" : ""} />
                </Tooltip>
            );
        default:
            return null;
    }
};

export const getBuildingLabel = (building) => {
    if (building === undefined) {
        return "No building";
    }

    if (building.buildingName) {
        return `${building.buildingName} (${building.buildingCode})`;
    }

    if (building.city && building.country) {
        return `${building.buildingCode} (${building.city}, ${building.country})`;
    }

    if (building.city) {
        return `${building.buildingCode} (${building.city})`;
    }

    if (building.country) {
        return `${building.buildingCode} (${building.country})`;
    }

    return `${building.buildingCode} (${building.buildingCode})`;
};

export const sortBuildingsByBuildingCode = (buildingList) => {
    return buildingList.sort((buildingA, buildingB) => {
        if (buildingA.buildingCode.toUpperCase() < buildingB.buildingCode.toUpperCase()) {
            return -1;
        }
        if (buildingA.buildingCode.toUpperCase() > buildingB.buildingCode.toUpperCase()) {
            return 1;
        }
        return 0
    });
};

export const floorListToFloorOptions = (floorList, includeAny = true) => {
    let floorOptions = [];

    if (floorList && floorList.length) {
        floorOptions = floorList.map((floor) => {
            return {
             label: floor === -1 ? "Unknown" : floor,
             value: floor
            };
        });
    }

    if (includeAny && floorOptions.length !== 0) {
        floorOptions.unshift({label: "Any", value: LOCATION_FLOOR_ANY});
    }

    return floorOptions;
};

export const roomListToRoomOptions = (roomList) => {
    let roomOptions = [];

    if (roomList && roomList.length) {
        roomOptions = roomList.map((room) => {
            return {
                ...room,
                label: shortRoomName(room.name),
                value: room.email
            };
        });
    }

    return roomOptions;
};

export const locationInLocations = (locations, locationToCheck) => {
    if (!locations || locations.length === 0 || !locationToCheck) {
        return false;
    }

    let matchingLocation = locations.filter((location) => locationsEqual(location, locationToCheck));

    return matchingLocation.length !== 0;
};

export const buildingsEqual = (location1, location2) => {
    return location1.value === location2.value
        && location1.name === location2.name
        && location1.thisBuildingOnly === location2.thisBuildingOnly;
};

export const locationsEqual = (location1, location2) => {
    return location1.buildingCode === location2.buildingCode
        && location1.floor === location2.floor
        && location1.minCapacity === location2.minCapacity
        && resourceListsEqual(location1.resourceList, location2.resourceList)
        && location1.restrictedAllowed === location2.restrictedAllowed
        && location1.hiddenAllowed === location2.hiddenAllowed
        && location1.thisBuildingOnly === location2.thisBuildingOnly;
};

export const locationAndFavoriteEqual = (location, favorite) => {
    favorite = fillFavoriteDefaults(favorite);
    return location.buildingCode === favorite.value
        && (location.floor || LOCATION_FLOOR_ANY) === favorite.floor
        && location.minCapacity === favorite.minCapacity
        && resourceListsEqual(location.resourceList, favorite.resourceList)
        && location.restrictedAllowed === favorite.restrictedAllowed
        && location.hiddenAllowed === favorite.hiddenAllowed
        && location.thisBuildingOnly === favorite.thisBuildingOnly;
};

export const resourceListsEqual = (resourceList1 = [], resourceList2 = []) => {
    if (resourceList1.includes(LOCATION_FEATURE.CAMERA)
        !== resourceList2.includes(LOCATION_FEATURE.CAMERA)) {
        return false;
    }

    if (resourceList1.includes(LOCATION_FEATURE.DISPLAY)
        !== resourceList2.includes(LOCATION_FEATURE.DISPLAY)) {
        return false;
    }

    return true;
};

export const shortRoomName = (roomName) => removeRoomAirportCode(removeRoomPrefixes(roomName, roomName));

const removeRoomPrefixes = (str, defaultText) => {
    if (!str) { return defaultText; }
    if (!str.replace) { return str; }
    return str.replace(/^CONF [A-Z]{2} /, '') || defaultText;
};

const removeRoomAirportCode = (str, defaultText) => {
    if (!str) { return defaultText; }
    if (!str.replace) { return str; }
    return str.replace(/^[A-Z]{3} /, '') || defaultText;
};

/**
 * Returns the users default location as {building, floor}. If user doesn't have a default building, defaults to
 * USER_DEFAULT_BUIlDING and USER_DEFAULT_FLOOR. If a user has a default building, but no default floor, then the first
 * floor option is selected. This could be set to INVALID_FLOOR if there are no floor options.
 *
 * @typedef {Object} userDefaultLocation
 * @property {String} userDefaultBuilding
 * @property {Number|String} userDefaultFloor
 *
 * @param {Object} site - Site object in identity state (state: identity.identity.site)
 * @param {Array} buildings - List of buildings and its info (state.locations.buildingList)
 * @return {userDefaultLocation} object of userDefaultBuilding and userDefaultFloor
 */
export const getUserDefaultLocation = (site, buildings) => {
    let userBuildingCode = site && site.building;
    let userBuilding = userBuildingCode && buildings && buildings.find((building) => building.value === userBuildingCode);

    // If we cannot find the user's building, use the default building
    let userDefaultBuilding = USER_DEFAULT_BUILDING;
    let userDefaultFloor = USER_DEFAULT_FLOOR;
    if (userBuilding) {
        userDefaultBuilding = userBuildingCode;
        if (site && site.floor && parseInt(site.floor)) {
            userDefaultFloor = parseInt(site.floor);
        } else {
            let floorList = userBuilding.floorList;
            userDefaultFloor = (floorList && floorList !== ERRORS.INVALID_FLOOR_LIST && userBuilding.floorList.length && userBuilding.floorList[0]) || ERRORS.INVALID_FLOOR;
        }
    }

    return {
        userDefaultBuilding,
        userDefaultFloor
    };
};