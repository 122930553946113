import React, { useCallback, useEffect, useState } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import PermissionGroupSearchField from "./permission-group-search-field";
import PersonSearchField from "./person-search-field";
import Text from "@amzn/meridian/text";

export const PermissionType = {
    PERSON: "person",
    TEAM: "team",
}
const PermissionsInputGroup = (props) => {
    const error = props.error ?? false;
    const [permissionGroupSearchFieldValue, setPermissionGroupSearchFieldValue] = useState("");
    const [permissionType, setPermissionType] = useState(PermissionType.TEAM);
    const [personSearchFieldValue, setPersonSearchFieldValue] = useState("");

    const typeToLabel = useCallback((type) => {
        if (type === PermissionType.PERSON) {
            return "Person";
        } else {
            return "Team";
        }
    }, []);

    const onChangeSearch = props.onChange;
    const permissions = props.value;
    const onClickHandle = useCallback((name) => {onChangeSearch(permissions
        .filter(item => item.name !== name || item.type !== permissionType)
        .concat([{name: name, type: permissionType}]))}, [permissionType, onChangeSearch, permissions]);

    useEffect(() => {
        if (permissionGroupSearchFieldValue !== "") {
            onClickHandle(permissionGroupSearchFieldValue);
            setPermissionGroupSearchFieldValue("");
        }
    }, [permissionGroupSearchFieldValue, onClickHandle]);

    useEffect(() => {
        if (personSearchFieldValue !== "") {
            onClickHandle(personSearchFieldValue);
            setPersonSearchFieldValue("");
        }
    }, [personSearchFieldValue, onClickHandle]);

    return (
        <Row>
            <Column alignmentVertical="top" spacing="200">
                <Text htmlFor="select-accountType" tag="label" type="b300">Account type</Text>
                <Select
                    name="select-accountType"
                    error={error}
                    onChange={setPermissionType}
                    value={permissionType}
                    width={200}
                >
                    <SelectOption value={PermissionType.PERSON} label={typeToLabel(PermissionType.PERSON)}/>
                    <SelectOption value={PermissionType.TEAM} label={typeToLabel(PermissionType.TEAM)}/>
                </Select>
            </Column>
            <Column alignmentVertical="top" spacing="200" width="100%">
                <Text htmlFor="select-accountName" tag="label" type="b300">Account name</Text>
                {permissionType === PermissionType.PERSON ?
                    <PersonSearchField updateValue={setPersonSearchFieldValue} value=""/>
                    :
                    <PermissionGroupSearchField onClick={setPermissionGroupSearchFieldValue} type={permissionType}/>
                }
            </Column>
        </Row>
    );
};

export default PermissionsInputGroup;
