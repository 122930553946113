import { HIDE_TOAST, SHOW_TOAST } from "../actions/action-types";
import { MAX_TOASTS } from "../toast-constants";

const initialState = {
    toasts: []
};

const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_TOAST:
            let oldToasts = state.toasts.length >= MAX_TOASTS ?
                state.toasts.slice(-(MAX_TOASTS - 1)) : state.toasts;
            // Only push the current toast if it isn't already in the toasts array
            return oldToasts.find((toast) => (action.toast.toastMessage === toast.toastMessage)) ?
                state
                :
                {
                ...state,
                    toasts: [
                        ...oldToasts,
                        {
                            id: action.toast.id,
                            toastType: action.toast.toastType,
                            toastActionType: action.toast.toastActionType,
                            timeout: action.toast.toastTimeout,
                            // for a toast with a simple message
                            toastMessage: action.toast.toastMessage,
                            // for a toast with a component
                            toastActionProps: action.toast.toastActionProps,
                        }
                    ]
                }
        case HIDE_TOAST:
            return {
                toasts: state.toasts.filter((toast) => toast.id !== action.id) || []
            };
        default:
            return state;
    }
};

export default toastReducer;