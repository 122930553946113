import {postCustomMetric} from "./panorama"

export const clientSideErrorTelemetryInit = (context, idToken) => {
    sendConsoleErrorLogs(context, idToken);
    sendUncaughtErrors(context, idToken);
}

export const sendConsoleErrorLogs = (context, idToken) => {
    const console = (function (defaultConsole) {
        return {
            log: function (text) {
                defaultConsole.log.apply(defaultConsole, arguments);
            },
            info: function (text) {
                defaultConsole.info.apply(defaultConsole, arguments);
            },
            warn: function (text) {
                defaultConsole.warn.apply(defaultConsole, arguments);
            },
            error: function (text) {
                defaultConsole.error.apply(defaultConsole, arguments);
                postCustomMetric("Client side log", "errorLog", context, Object.values(arguments), 1, idToken);
            }
        };
    }(window.console))

    window.console = console;
}

export const sendUncaughtErrors = (context, idToken) => {
    window.onunhandledrejection = (event) => {
        postCustomMetric("Client side log", "uncaughtRejection", context, event.reason, 1, idToken);
        return true;
    };
    window.onerror = (message, file, line, column, errorObj) => {
        const error = JSON.stringify({ message, file, line, column, stacktrace: errorObj ? errorObj.stack : "" });
        postCustomMetric("Client side log", "uncaughtError", context, error, 1, idToken);
        return true;
    }
}