import { call, put, retry, select } from "redux-saga/effects";
import aws4 from "aws4";

import { getIdentityTokens, getSigningCredentials } from "../../../../sagas/selector";
import { ENDPOINTS, OPERATIONS, OPERATION_PATHS } from "./endpointConfig";
import {
    cleanEmpty,
    fetchRequest,
    getErrorResponse,
    isRetryableError,
    stringifyError,
} from "../apig-utils";
import { getStageConfig } from "../../stageConfig/stage-config-utils";
import { postMCISAPICallMetric, postUnknownEdgeLocationMetric } from "../../../shared/metrics/actions";
import { MAX_ATTEMPTS, RETRY_DELAY_IN_MS } from "../apig-constants";
import { getRegion } from "../ras/region-mapping";

const HttpMethod = {
    POST: "POST",
    GET: "GET"
};

export function* checkInRoom(source, meetings) {
    console.log("Calling checkInRoom");
    const operationPath = OPERATION_PATHS[OPERATIONS.CHECK_IN_ROOM].replace(":source", source)
    return yield call(retryableMakeRequest, [operationPath, HttpMethod.POST, { meetings }]);
}

export function* releaseRoom(source, meetingStartTime, roomEmail) {
    console.log("Calling releaseRoom");
    const operationPath = OPERATION_PATHS[OPERATIONS.RELEASE_ROOM].replace(":source", source)
    return yield call(retryableMakeRequest, [operationPath, HttpMethod.POST, {
        meetingStartTime,
        roomEmail
    }]);
}

export function* getRoomEligibility(roomEmail) {
    console.log("Calling getRoomEligibility");
    const operationPath = OPERATION_PATHS[OPERATIONS.GET_ROOM_ELIGIBILITY].replace(":roomEmail", roomEmail)
    return yield call(retryableMakeRequest, [operationPath, HttpMethod.GET]);
}

export function* getCheckInStatus(meetings) {
    console.log("Calling getCheckInStatus");
    const operationPath = OPERATION_PATHS[OPERATIONS.GET_ROOM_CHECK_IN_STATUS];
    return yield call(retryableMakeRequest, [operationPath, HttpMethod.POST, { meetings }]);
}

function* retryableMakeRequest(params) {
    let response;
    try {
        response = yield retry(MAX_ATTEMPTS, RETRY_DELAY_IN_MS, makeRequest, ...params);
    } catch (e) {
        console.error(`An error occurred in all ${MAX_ATTEMPTS} attempts to call ${params[0]}: `, e);
        const error = stringifyError(e);
        yield put(postMCISAPICallMetric(params[0], JSON.stringify({error, request: params[1]}), false));
        return getErrorResponse(e);
    }
    console.log(`${params[0]} called successfully.`);
    yield put(postMCISAPICallMetric(params[0]));
    return response;
}

function* makeRequest(operationPath, httpMethod, body) {
    // TODO: define a way to store/retrieve the current stage and region
    const stageConfig = yield getStageConfig();
    const stage = stageConfig.stage;
    const { region, regionMappingError } = getRegion(stageConfig.edgeLocation);
    const endpointConfig = ENDPOINTS[stage][region] || ENDPOINTS[stage]["PDX"];
    const { accessKeyId, secretAccessKey, sessionToken } = yield select(getSigningCredentials);

    if (regionMappingError) {
        yield put(postUnknownEdgeLocationMetric(regionMappingError.value));
    }

    const { idToken } = yield select(getIdentityTokens);

    const url = new URL(endpointConfig.Endpoint + operationPath);
    const opts = {
        method: httpMethod,
        host: url.hostname,
        path: url.pathname,
        service: "execute-api",
        region: endpointConfig.Region ?? "us-west-2",
        headers: {
            "X-Amz-Cognito-Id-Token": idToken
        }
    };

    if (httpMethod === HttpMethod.POST) {
        opts.headers["Content-Type"] = "application/json";
        opts.body = JSON.stringify(cleanEmpty(body));
    }
    aws4.sign(opts, { accessKeyId, secretAccessKey, sessionToken });

    try {
        return yield call(fetchRequest, {url, opts});
    } catch (e) {
        if (isRetryableError(e)) {
            throw e;
        }
        const error = stringifyError(e);
        yield put(postMCISAPICallMetric(operationPath, JSON.stringify({error, request: body}), false));
        return getErrorResponse(e);
    }
}