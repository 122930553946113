import React, { useState } from "react";

import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";

import DiscardMeetingModal from "../../shared/components/discard-meeting-modal";

import mailTokens from "@amzn/meridian-tokens/base/icon/mail";
import trashTokens from "@amzn/meridian-tokens/base/icon/trash";
import copyTokens from "@amzn/meridian-tokens/base/icon/copy";

import "../meeting-polls.css";

import {
    NAVIGATION_TYPE,
    STEP_NAVIGATION_FOOTER,
    STEP_NAVIGATION_HEADER,
} from "../../navigation/navigation-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { FLOW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";

const CreatePollNavigationBar = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const navigationType = props.navigationType; // Header or footer
    const flowtype = props.flowtype;
    const setDeletePollModalOpen = props.setDeletePollModalOpen;
    const pollID = props.pollID;
    const isProcessingPoll = props.isProcessingPoll;

    const [discardMeetingModalOpen, setDiscardMeetingModalOpen] = useState(false);
    const MASTHEAD_ID = "masthead-id";

    const onClickDelete = () => {
        setDeletePollModalOpen(true);
    };

    const onClickUpdate = () => {
        props.onUpdateButtonClick();
    };

    const onClickSend = () => {
        props.onSendButtonClick();
    };

    // The icon/text for the edit poll buttons
    const responsiveButtonText = (iconTokens, text) => {
        return screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XS ?
            <Row spacing="small">
                <Icon tokens={iconTokens} />
                &nbsp;{text}
            </Row>
            :
            <Icon tokens={iconTokens} />;
    };

    const renderDeleteButton = () => (
        <Button id={"edit-poll-delete-button"} type="secondary" onClick={onClickDelete}>
            {responsiveButtonText(trashTokens, "Delete")}
        </Button>
    );

    const renderResultsButton = () => (
        <Button id={"edit-poll-results-panel-button"} type="secondary" onClick={() => props.onOpenResults(pollID)}>
            {responsiveButtonText(copyTokens, "Results")}
        </Button>
    );

    const renderUpdateButton = (buttonId) => (
        <Button onClick={onClickUpdate} id={buttonId} disabled={isProcessingPoll}>
            {responsiveButtonText(mailTokens, "Update")}
        </Button>
    );

    const renderSendButton = (buttonId) => (
        <Button onClick={onClickSend} id={buttonId} disabled={isProcessingPoll}>
            <Icon tokens={mailTokens} />
            Send
        </Button>
    );

    const handlePageChangeToDashboard = () => {
        setDiscardMeetingModalOpen(true);
    };

    const renderBreadcrumb = () => {
        return (
            <nav aria-label="Breadcrumb">
                <BreadcrumbGroup>
                    <Breadcrumb
                        onClick={handlePageChangeToDashboard}
                        href={WORKFLOWS.POLL_DASHBOARD.HREF}
                    >
                        Dashboard
                    </Breadcrumb>
                    {flowtype === FLOW_TYPE.UPDATE ?
                        <Breadcrumb href={WORKFLOWS.EDIT_POLL.HREF}>
                            Edit meeting poll
                        </Breadcrumb>
                        :
                        <Breadcrumb href={WORKFLOWS.POLL_CREATE.HREF}>
                            Create meeting poll
                        </Breadcrumb>
                    }
                </BreadcrumbGroup>
            </nav>
        );
    };

    const renderNavigationButtons = () => {
        return navigationType === NAVIGATION_TYPE.HEADER ? (
            <React.Fragment>
                {flowtype === FLOW_TYPE.UPDATE ?
                    <Row>
                        {renderDeleteButton()}
                        {renderResultsButton()}
                        {renderUpdateButton("edit-poll-update-header-button")}
                    </Row>
                    :
                    renderSendButton("create-poll-send-header-button")
                }
            </React.Fragment>
        ) : (
            <React.Fragment>
                {flowtype === FLOW_TYPE.UPDATE ?
                    renderUpdateButton("edit-poll-update-footer-button")
                    :
                    renderSendButton("create-poll-send-footer-button")
                }
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {navigationType === NAVIGATION_TYPE.HEADER ?
                <div style={{
                    overflow: "hidden",
                    backgroundColor: "white",
                    width: "100%",
                    borderBottom: "1px solid #bbc0c1", // Meridian gray-300
                }}>
                    {screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.MD ?
                        <Row
                            height={STEP_NAVIGATION_HEADER.HEIGHT}
                            spacingInset="medium"
                            alignmentHorizontal="justify"
                            alignmentVertical="center"
                        >
                            {renderBreadcrumb()}
                            {renderNavigationButtons()}
                        </Row>
                        :
                        <Column spacingInset="medium">
                            <Row alignmentHorizontal="right">
                                {renderNavigationButtons()}
                            </Row>
                            <Row alignmentHorizontal="left">
                                {renderBreadcrumb()}
                            </Row>
                        </Column>
                    }
                </div>
                :
                <div style={{
                    position: "fixed",
                    left: 0,
                    bottom: 0,
                    width: "100%",
                    zIndex: "30",
                    backgroundColor: "white",
                    borderTop: "1px solid #bbc0c1", // Meridian gray-300
                }}>
                    <Row
                        height={STEP_NAVIGATION_FOOTER.HEIGHT}
                        width="100%"
                        spacingInset="medium"
                        alignmentHorizontal="right"
                    >
                        {renderNavigationButtons()}
                    </Row>
                </div>
            }
            <DiscardMeetingModal
                modalTitle="Discard meeting poll"
                open={discardMeetingModalOpen}
                setOpen={setDiscardMeetingModalOpen}
                backgroundElementsId={MASTHEAD_ID}
                newWorkflow={WORKFLOWS.POLL_DASHBOARD}
            />
        </React.Fragment>
    );
};

export default CreatePollNavigationBar;
