import { BatchInterceptor } from "@mswjs/interceptors";
import { FetchInterceptor } from '@mswjs/interceptors/lib/interceptors/fetch';
import axios from 'axios';

const CSRF_HEADER_NAME = "x-csrf-token";
const HTTP_VERB_MUTATIONS = ['POST', 'PUT', 'PATCH', 'DELETE'];
const MCI_URL_MATCHER = "meeting-check-in.meetex.enterprise-engineering.aws.dev";
const LIVESTREAM_URL_MATCHER = "/livestream/api";

let csrfToken;
let livestreamCsrfToken;
const interceptor = new BatchInterceptor({
    name: 'csrf-interceptor',
    interceptors: [
        new FetchInterceptor(),
    ],
});

// Enable the interception of requests.
interceptor.apply();

// Listen to any request being dispatched
interceptor.on('request', (request, _requestId) => {
    console.log('*** Request Interceptor Called ***');
    if (HTTP_VERB_MUTATIONS.includes(request.method)) {
        if (livestreamCsrfToken && (request.url || "").includes(LIVESTREAM_URL_MATCHER)) {
            request.headers.set(CSRF_HEADER_NAME, livestreamCsrfToken);
        } else if (csrfToken && !(request.url || "").includes(MCI_URL_MATCHER)) {
            request.headers.set(CSRF_HEADER_NAME, csrfToken);
        }
    }
});

// Listen to any responses sent
interceptor.on('response', (response, request) => {
    console.log('*** Response Interceptor Called ***');
    if (response.status === 200 && request.method === 'GET' && response.headers.has(CSRF_HEADER_NAME)) {
        if ((response.url || "").includes(LIVESTREAM_URL_MATCHER)) {
            livestreamCsrfToken = response.headers.get(CSRF_HEADER_NAME);
        } else {
            // Read CSRF token from the response headers
            csrfToken = response.headers.get(CSRF_HEADER_NAME);
        }
    }
});
axios.interceptors.response.use((response) => {
    console.log("Axios Response Interceptor...");
    if (response.status === 200 && response.headers.has(CSRF_HEADER_NAME)
        && response.config.url.includes(LIVESTREAM_URL_MATCHER) && response.config.method === "get") {
        livestreamCsrfToken = response.headers.get(CSRF_HEADER_NAME);
    }
    return response;
});
axios.interceptors.request.use((request) => {
        console.log("Axios Request Interceptor...");
        if (request.method === "post" && request.url.includes(LIVESTREAM_URL_MATCHER)) {
            request.headers.set(CSRF_HEADER_NAME, livestreamCsrfToken)
        }
        return request;
    }
);