import { call, all, fork } from "redux-saga/effects";

import { startup, loadMetadata, refreshIdentityTokens, /* isAuthorizedUser */ } from "./startup.js";

import meetingSagas from "../features/meeting-scheduler/sagas";
import identitySagas from "../features/identity/sagas";
import agendaSagas from "../features/shared/sagas";
import peopleSagas from "../features/people/sagas";
import locationsSagas from "../features/shared/locations/sagas";
import timezonesSagas from "../features/shared/sagas";
import calendarSagas from "../features/calendar/sagas";
import sharedSagas from "../features/shared/sagas";
import ootoSagas from "../features/meeting-ooto/sagas";
import chimeSagas from "../features/shared/chime/sagas";
import favoritesSagas from "../features/shared/favorites/sagas";
import suggestionsSagas from "../features/shared/sagas";
import metricsSagas from "../features/shared/metrics/sagas";
import timeGridSagas from "../features/shared/custom-calendar-time-grid/sagas";
import exchangePreferencesSagas from "../features/shared/exchange-preferences/sagas";
import checkInSagas from "../features/shared/check-in/sagas";
import settingsSagas from "../features/shared/settings/sagas";
import livestreamSagas from "../features/meeting-livestream/sagas";
import pollSagas from "../features/meeting-polls/sagas";
import feedbackSagas from "../features/shared/feedback/sagas";

export default function* combinedSaga() {
    yield call(startup);
    // TODO: cleanup unauthorized code
    // yield fork(isAuthorizedUser);
    yield fork(refreshIdentityTokens);
    yield fork(loadMetadata);
    yield all([
        ...meetingSagas,
        ...ootoSagas,
        ...identitySagas,
        ...agendaSagas,
        ...peopleSagas,
        ...locationsSagas,
        ...timezonesSagas,
        ...calendarSagas,
        ...sharedSagas,
        ...chimeSagas,
        ...favoritesSagas,
        ...suggestionsSagas,
        ...metricsSagas,
        ...timeGridSagas,
        ...exchangePreferencesSagas,
        ...checkInSagas,
        ...settingsSagas,
        ...livestreamSagas,
        ...pollSagas,
        ...feedbackSagas,
    ]);
}
