import React, { useState, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import parseISO from "date-fns/parseISO"
import startOfMonth from "date-fns/startOfMonth"
import endOfMonth from "date-fns/endOfMonth"
import noop from "lodash/noop"
import uniqueId from "lodash/uniqueId"
import DatePickerPopover from "./date-picker-popover"
import DatePickerPresets from "./date-picker-presets"
import DateInputField from "../date-input-field"
import DateInputCalendar from "../_date-input-calendar"
import {
  toDateString,
  isWithinRange,
  parseDateString,
} from "../../_utils/date-string"
import dateLocales from "../../_utils/date-locales"

/**
 * A form control for selecting dates. Uses the Input, DateInputCalendar, and
 * PopoverController components to create a nice interface for quickly selecting
 * a single date.
 */
const DatePicker = React.forwardRef((props, ref) => {
  const {
    onChange,
    defaultViewDate,
    value,
    now,
    popoverId: popoverIdProp,
  } = props
  const popoverId = useMemo(
    () => (popoverIdProp ? popoverIdProp : uniqueId("datepicker-")),
    [popoverIdProp]
  )
  /**
   * Return the date that the calendar popup should be initialized to show. If
   * a view date was explicitly set via props then use that, otherwise use the
   * selected date if there is a valid one, otherwise fallback to the current
   * date.
   */
  const getCalendarViewDate = useCallback(
    () => defaultViewDate || (parseDateString(value) && value) || now,
    [defaultViewDate, value, now]
  )

  const [calendarViewDate, setCalendarViewDate] = useState(
    getCalendarViewDate()
  )

  /**
   * Change the months shown by the calendar picker to include a given date.
   * This handler ignores empty dates and doesn't do anything if the provided
   * date is already visible in the calendar picker.
   */
  const onChangeCalendarViewDate = useCallback(
    date => {
      const parsedCalendarViewDate = parseISO(calendarViewDate)
      const firstDate = toDateString(startOfMonth(parsedCalendarViewDate))
      const lastDate = toDateString(endOfMonth(parsedCalendarViewDate))
      if (date && !isWithinRange(date, firstDate, lastDate)) {
        setCalendarViewDate(date)
      }
    },
    [calendarViewDate]
  )

  const onChangeField = useCallback(
    value => {
      onChange(value)
      // Update the view to ensure that the new date is visible
      onChangeCalendarViewDate(value)
    },
    [onChange, onChangeCalendarViewDate]
  )

  /**
   * Before opening the popover, make sure that the view date is correct (in
   * case the selected date was changed since the last time the popover was
   * opened or the user messed with the view date before previously closing the
   * popover).
   */
  const onBeforeOpenPopover = useCallback(
    () => onChangeCalendarViewDate(getCalendarViewDate()),
    [onChangeCalendarViewDate, getCalendarViewDate]
  )

  return (
    <DatePickerPopover
      valueFilled={Boolean(props.value)}
      width={props.width}
      disabled={props.disabled}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      id={popoverId}
      anchor={({ onFocus, onBlur, popoverIsOpen }) => (
        <DateInputField
          size={props.size}
          value={props.value}
          onChange={onChangeField}
          focus={popoverIsOpen ? true : undefined}
          disabled={props.disabled}
          disabledDates={props.disabledDates}
          label={props.label}
          placeholder={props.placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          invalidInputMessage={props.invalidInputMessage}
          error={props.error}
          locale={props.locale}
          autoFocus={props.autoFocus}
          autoFill={false}
          ref={ref}
          clearButton={props.clearButton}
          clearButtonLabel={props.clearButtonLabel}
          onClear={props.onClear}
          id={props.id}
          popoverIsOpen={popoverIsOpen}
          aria-describedby={props["aria-describedby"]}
          aria-required={props["aria-required"]}
          aria-invalid={props["aria-invalid"]}
        />
      )}
      onBeforeOpen={onBeforeOpenPopover}
    >
      {({ t, popoverIsOpen, closePopover }) => [
        <DateInputCalendar
          key="calendar"
          locale={props.locale}
          disabledDates={props.disabledDates}
          value={props.value}
          onChange={value => {
            props.onChange(value)
            closePopover()
          }}
          viewDate={calendarViewDate}
          onChangeViewDate={onChangeCalendarViewDate}
          now={props.now}
          monthsInView={props.monthsInView}
          monthWidth={props.monthWidth}
          previousMonthLabel={props.previousMonthLabel}
          nextMonthLabel={props.nextMonthLabel}
          renderDay={props.renderDay}
          animateMonthChange={popoverIsOpen}
          aria-label={props["aria-label"] || props.label || props.placeholder}
        />,
        props.presets.length > 0 ? (
          <DatePickerPresets
            key="presets"
            t={t}
            presets={props.presets}
            label={props.presetsLabel}
            onChange={value => {
              props.onChange(value)
              closePopover()
            }}
            disabledDates={props.disabledDates}
          />
        ) : null,
      ]}
    </DatePickerPopover>
  )
})

DatePicker.displayName = "DatePicker"

DatePicker.propTypes = {
  /**
   * Called any time the user enters a full, valid, enabled date into the date
   * picker. The new date will be provided to the function as a date string
   * in the format YYYY-MM-DD.
   *
   * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
   * so you must track the values provided to this function somewhere in state
   * and pass them back via the `value` prop in order to make this component
   * interactive.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Use this attribute to pass a space separated list of ids that you would like to use to
   * describe this DatePicker component.
   * ex: This can be used to associate error labels to this component so that users will understand
   * when this component has a bad value
   *
   * @added by meetex team member (camei@)
   */
  "aria-describedby": PropTypes.string,
  /**
   * Use this attribute to associate a text label with the calendar popup
   *
   * @added by meetex team member (camei@)
   */
  "aria-label": PropTypes.string,
  /**
   * If set to true then the input will grab focus as soon as it's mounted.
   *
   * This uses HTML's native `autofocus` attribute. Use this sparingly as
   * there are [common accessibility pitfalls](https://www.brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/)
   * associated with this behavior.
   */
  autoFocus: PropTypes.bool,
  /**
   * Appends a button to the input that the user can click to clear the input.
   * This will trigger the `onChange` callback with a value of `""`.
   *
   * @since  5.x
   */
  clearButton: PropTypes.bool,
  /**
   * An accessible label for the clear button. This will not be visible in the UI, but
   * will be read by screen readers.
   *
   * @since  5.x
   */
  clearButtonLabel: PropTypes.string,
  /**
   * This date is used to determine which month the calendar should display
   * by default. The date must be provided in the format YYYY-MM-DD. If not
   * provided then the calendar will display the month of `value`, or
   * `now` if no value is provided.
   */
  defaultViewDate: PropTypes.string,
  /**
   * Determines if the entire date picker is disabled. If you want to disable
   * specific dates then use the `disabledDates` prop.
   */
  disabled: PropTypes.bool,
  /**
   * A function that can be used to disable specific dates. The function will
   * be passed date strings in the format YYYY-MM-DD and is expected to return
   * true for disabled dates and false otherwise.
   */
  disabledDates: PropTypes.func,
  /**
   * Show the component in an error state. Use this to indicate that the value
   * of the component is invalid.
   *
   * Consider pairing this state with a small error `Alert` component below
   * or to the right of the component explaining what the error is.
   */
  error: PropTypes.bool,
  /**
   * Sets an `id` attribute on the component.
   *
   * Use this for testing or for accessibility. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   *
   * @since 5.x
   */
  id: PropTypes.string,
  /**
   * A function that returns a message for invalid or disabled dates.
   * The function must return a localized string. The string will be displayed
   * to the user to help guide them.
   *
   * Two arguments will be passed to the function. The first argument will be an error code - either
   * "INVALID_DATE" if the user's date doesn't exist (e.g. 2018-40-40) or
   * "DISABLED_DATE" if the user's date has been disabled via the
   * `disabledDates` prop. The second argument will be the date entered by the
   * user in the format YYYY-MM-DD. This argument will only be provided for
   * the "DISABLED_DATE" error code (the "INVALID_DATE" code means we couldn't
   * actually construct a date).
   */
  invalidInputMessage: PropTypes.func,
  /**
   * The label for the date input field.
   */
  label: PropTypes.string,
  /**
   * The locale to use when rendering the component (e.g. month names, names of
   * days of the week, etc.). See the [Intl API](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl).
   */
  locale: PropTypes.oneOf(Object.keys(dateLocales)),
  /**
   * Set the width of a single month.
   *
   * @since 4.x
   */
  monthWidth: PropTypes.number,
  /**
   * The number of calendar months to display at a time.
   */
  monthsInView: PropTypes.number,
  /**
   * A localized label for the next month button.
   */
  nextMonthLabel: PropTypes.string,
  /**
   * Define today's date (in the format YYYY-MM-DD). This date will be
   * marked in the calendar with a special indicator.
   */
  now: PropTypes.string,
  /**
   * Text that is shown in place of the input's value when the input is empty.
   *
   * This text is not visible when a value is entered in the input, so it should
   * not contain essential information that should be persisted. For that,
   * consider using the `label` prop.
   */
  placeholder: PropTypes.string,
  /**
   * Sets an `id` attribute on the popover container.
   *
   * Use this for testing or for accessibility. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   *
   * @since 5.x
   */
  popoverId: PropTypes.string,
  /**
   * A list of presets to allow quick selection of common dates.
   *
   * If provided, these presets will be shown as buttons in the date picker's
   * calendar popover.
   */
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  /**
   * A localized label to be shown above the list of presets.
   */
  presetsLabel: PropTypes.string,
  /**
   * A localized label for the previous month button.
   */
  previousMonthLabel: PropTypes.string,
  /**
   * A function that can be used to customize the rendering of a single day. The
   * function is passed an object which contains the following props.
   *
   * `date`: `string` of date in the format YYYY-MM-DD
   *
   * `label`: `string` of the localized day of the month (e.g. "21")
   *
   * `current`: `boolean` set to `true` if the date is todays date
   *
   * `selected`: `boolean` set to `true` if date is selected
   *
   * `disabled`: `boolean` set to `true` if date is disabled
   *
   * `range`: `boolean` set to `true` if date is in the range of selected dates
   *
   * @since 4.x
   */
  renderDay: PropTypes.func,
  /**
   * Sets the size of the component using a preset.
   *
   * If the `label` prop is provided this prop will be ignored (the label
   * requires a particular amount of space which is not configurable).
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  /**
   * The currently selected date as formatted as a YYYY-MM-DD date string.
   *
   * Update this when `onChange` is called to ensure the component is interactive
   * (see the documentation for the `onChange` prop).
   */
  value: PropTypes.string,
  /**
   * Set the width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   *
   * @since 5.x
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * This is called when the entire component (input + popover calendar) loses focus.
   * The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event) is passed as the first argument to the function.
   *
   * @since 5.x
   */
  onBlur: PropTypes.func,
  /**
   * This is called when the clear button is clicked (be sure to set `clearButton`
   * to `true`). No arguments are passed to this callback.
   *
   * @since 5.x
   */
  onClear: PropTypes.func,
  /**
   * This is called when the entire component (input + popover calendar) gains focus.
   * The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event) is passed as the first argument to the function.
   *
   * @since 5.x
   */
  onFocus: PropTypes.func,
}

DatePicker.defaultProps = {
  locale: "en-US",
  disabledDates: noop,
  now: toDateString(new Date()),
  presets: [],
  size: "medium",
  invalidInputMessage: code =>
    ({
      INVALID_DATE: "This value is not a valid date.",
      DISABLED_DATE: "This date is not available.",
    }[code]),
  error: false,
}

export default DatePicker
