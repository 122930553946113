import React from "react";

import FormSection from "./form-section";
import FavoritesTable from "./favorites-table";

import "../settings.css";

const Favorites = (props) => {
    return (
        <FormSection
            children={
                <FavoritesTable
                    identity={props.identity} 
                    favorites={props.favorites}
                    findRooms={props.findRooms}
                    buildings={props.buildings}
                    locations={props.locations}
                    rooms={props.rooms}
                    onGetRASDataForPerson={props.onGetRASDataForPerson}
                    onGetRASDataForGroup={props.onGetRASDataForGroup}
                    onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                    onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                    onGetGroupSuggestions={props.onGetGroupSuggestions}
                    onClearGroupSuggestions={props.onClearGroupSuggestions}
                    onAddFavorite={props.onAddFavorite}
                    onGetRASDataForPersonAndAddToFavorite={props.onGetRASDataForPersonAndAddToFavorite}
                    onRemoveFavorite={props.onRemoveFavorite}
                    peopleSuggestions={props.peopleSuggestions}
                    groupSuggestions={props.groupSuggestions}
                    onRemoveFavoriteByAlias={props.onRemoveFavoriteByAlias}
                    onLoadFloors={props.onLoadFloors}
                    popoverAttendee={props.popoverAttendee}
                />
            }
        />
    );
};

export default Favorites;