import React from "react"
import PropTypes from "prop-types"
import escapeRegExp from "lodash/escapeRegExp"
import { css } from "emotion"
import { typographyFontWeightH300 } from "@amzn/meridian-tokens/base/typography"

const maxMatchLength = 100

const defaultMatchedStyles = css({
  fontWeight: typographyFontWeightH300,
})

const makeSubstringWithMatch = (text, match) => ({
  text,
  match,
})

/**
 * Parses string into an ordered array of objects with substrings
 * and if was a match or not.
 * getSubstringWithMatchBool(/abc/g, "abc def abc")
 * [ {text: "abc", match: true},
 *   {text: "def", match: false},
 *   {text: "abc", match: true} ]
 */
const getSubstringsWithMatchBool = (regexp, string) => {
  const substringsWithMatchBool = []
  let startBeforeIndex = 0
  let match
  do {
    match = regexp.exec(string)
    if (match) {
      const { length } = match[0]
      const matchText = string.substr(match.index, length)
      const beforeText = string.substr(
        startBeforeIndex,
        match.index - startBeforeIndex
      )
      if (beforeText) {
        substringsWithMatchBool.push(makeSubstringWithMatch(beforeText, false))
      }
      substringsWithMatchBool.push(makeSubstringWithMatch(matchText, true))
      startBeforeIndex = match.index + length
    }
    // Keep looking for matches if we previously found a match
    // and our regular expression is global (i.e. get all matches)
  } while (match && regexp.global)

  // If there's still some text leftover at the end, gobble it up
  if (startBeforeIndex < string.length) {
    const restText = string.substr(startBeforeIndex)
    substringsWithMatchBool.push(makeSubstringWithMatch(restText, false))
  }

  return substringsWithMatchBool
}

const HighlightSubstring = ({ children, match, className }) => {
  const string = Array.isArray(children) ? children.join("") : children
  const regexp =
    match instanceof RegExp
      ? match
      : new RegExp(escapeRegExp(match.substr(0, maxMatchLength)), "i")
  const substrings = getSubstringsWithMatchBool(regexp, string)
  return (
    <span>
      {substrings.map(({ text, match }, index) =>
        !match ? (
          <React.Fragment key={index}>{text}</React.Fragment>
        ) : (
          <span key={index} className={className}>
            {text}
          </span>
        )
      )}
    </span>
  )
}

HighlightSubstring.propTypes = {
  /**
   * The string(s) to highlight.
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  /**
   * A string or regular expression used to identify which substring(s) should
   * be highlighted.
   *
   * If a string is provided, the first substring that matches the string
   * exactly will be highlighted.
   *
   * If a regular expression is provided, any substring matching the regular
   * expression will be highlighted (use a greedy regular expression to
   * highlight all matches).
   */
  match: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
    .isRequired,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
}

HighlightSubstring.defaultProps = {
  className: defaultMatchedStyles,
}

export default HighlightSubstring
