import React from "react";

import displayImage from "../../assets/icons/locations/display.svg";
import phoneImage from "../../assets/icons/locations/phone.svg";
import cameraImage from "../../assets/icons/locations/camera.svg";
import {TILE_COLOR} from "./room-finder-constants";
import {USER_DEFAULT_BUILDING} from "../shared/locations/locations-constants";
import {shortRoomName} from "../shared/locations/locations-utils";
import {formatDisplayName} from "../people/people-utils";
import {zonedTimeToUtc} from "date-fns-tz";
import "../meeting-roomfinder/meeting-roomfinder.css";

export const filterResults = (results) => {
    return results
        .map((result) => {
            return {
                ...result,
                availabilities: (result.availabilities || []).map((availability) => {
                    return {
                        ...availability,
                        subject: formatDisplayName(availability.subject), // format names as First Last
                    }
                })
            };
        }
    );
};

export const getTokens = (feature) => {
    switch (feature) {
        case "video":
            return <img src={displayImage} alt="Icon depicting display feature"/>;
        case "phone":
            return <img src={phoneImage} alt="Icon depicting phone feature"/>;
        case "vtc":
            return <img src={cameraImage} alt="Icon depicting webcam feature"/>;
        default:
            break;
    }
};

export const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const formDetailsLoaded = (site, buildingList) => {
    if (!buildingList || !buildingList.length) {
        return false;
    }

    let buildingCode = site && site.building;
    let building = buildingCode && buildingList.find((building) => building.value === buildingCode);
    // If we cannot find the building, find the default building
    if (!building) {
        building = buildingList.find((building) => building.value === USER_DEFAULT_BUILDING);
    }

    return !!(building && building.floorList);
};

export const buildCreateMeetingRequest = (requestStartTime, requestEndTime, roomName, roomEmail, timezoneValue, email, name, includeChimePin, autoCall) => {
    let start = requestStartTime;
    let end = requestEndTime;
    return {
        meeting: {
            body: {
                type: "html",
                value: "<div></div>"
            },
            isPrivate: false,
            isResponseRequested: true,
            organizer: email,
            location: roomName,
            optionalAttendees: [],
            requiredAttendees: includeChimePin && autoCall ? [{email: "meet@chime.aws"}] : [],
            resources: [{email: roomEmail,
                name: roomName
            }],
            subject: `Reserved by ${name} via Amazon Meetings`,
            startTimezone: {
                id: timezoneValue
            },
            endTimezone: {
                id: timezoneValue
            },
            status: "busy",
            time: {
                startTime: Math.trunc(start.getTime() / 1000),
                endTime: Math.trunc(end.getTime() / 1000)
            },
            displayName: name
        },
        sendInvitations: true,
        confirmRoomAvailability: true,
        includeChimePin: includeChimePin
    }
};

export const createReservationTimes = (date, time, timezone) => {
    return zonedTimeToUtc(`${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)} ${time}`, timezone);
};

export const buildRoomLabel = (room) => {
    return (shortRoomName(room.name)).replace(room.buildingCode, "");
};

export const getBoxBackgroundColor = (subject) => {
    switch (subject) {
        case "Available (Restricted)":
        case "Restricted": return TILE_COLOR.RESTRICTED;
        case "Available": return TILE_COLOR.AVAILABLE;
        case "Was available": return TILE_COLOR.WAS_AVAILABLE;
        default:
            return TILE_COLOR.BUSY;
    }
};

export const getBoxClassName = (subject) => {
    switch (subject) {
        case "Available (Restricted)": return "room-finder-results-time-table-distributed-hour-box-available-restricted"
        case "Available":
        case "Was available": return "";
        default:
            return "room-finder-results-time-table-distributed-hour-box-busy";
    }
};

export const buildIdFromName = (groupId = "") => {
    return `${groupId.replace(/[^a-zA-Z0-9()]/g, "-").replace(/[()]/g,"").replace(/-+/g,'-')}`
};

export const buildIdFromTime = (time = {endTime: 0}, groupId = "") => {
    return `${time.endTime}-${buildIdFromName(groupId)}`;
};

export const isFloorExistInTheBuilding = (buildingInfo, floor) => {
    return buildingInfo?.floorList?.length > 0 && buildingInfo.floorList.includes(floor);
}