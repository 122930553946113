import React, { useState } from "react";

import {
    renderUserNameLink,
    renderAddButton,
    renderAddAllButton,
    renderShowHideButton,
    renderEmailLink
} from "../people-utils";
import _ from "lodash";
import { ATTENDEE_POPOVER_MAX_LIST_SIZE, MEMBER_TYPE, GROUP_MEMBER_TYPE } from "../people-constants";

import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Thumbnail from "@amzn/meridian/thumbnail";
import Icon from "@amzn/meridian/icon";
import exportTokens from "@amzn/meridian-tokens/base/icon/export-small";
import {renderSetFavoriteIcon} from "../../shared/favorites/favorites-utils";
import {useSelector} from "react-redux";
import {getIdentity} from "../../../sagas/selector";

const UserAttendeeCard = (props) => {
    const attendee = props.attendee;
    const attendeeList = props.attendeeList;
    const onGetRASDataForPersonAndAddToFavorite = props.onGetRASDataForPersonAndAddToFavorite;
    const onRemoveFavorite = props.onRemoveFavorite;
    const isFavorited = props.isFavorited;

    const coworkers = attendee.rasData && attendee.rasData.coworkers &&
        attendee.rasData.coworkers.filter((coworker) => !!coworker.name);
    const directs = attendee.rasData && attendee.rasData.directs &&
        attendee.rasData.directs.filter((direct) => !!direct.name);

    const onRemoveAttendeeByAlias = props.onRemoveAttendeeByAlias;
    const onGetRASDataForPerson = props.onGetRASDataForPerson;

    const identity = useSelector(getIdentity);

    const allAttendeesAdded = (attendees) => {
        return attendees && !attendees.some((attendee) => !attendeeList.includes(attendee.username));
    };

    const attendeesUnderLimit = (attendees) => {
        return attendees && attendees.filter((attendee) => !attendeeList.includes(attendee.username)).length <= ATTENDEE_POPOVER_MAX_LIST_SIZE;
    };

    const allDirectsAdded = allAttendeesAdded(directs);
    const allCoworkersAdded = allAttendeesAdded(coworkers);
    const directsUnderLimit = attendeesUnderLimit(directs);
    const coworkersUnderLimit = attendeesUnderLimit(coworkers);
    const [directsExpanded, setDirectsExpanded] = useState(false);
    const [coworkersExpanded, setCoworkersExpanded] = useState(false);
    const disableAddFromPopover = props.disableAddFromPopover || onGetRASDataForPersonAndAddToFavorite !== undefined;

    const onAddUser = (user) => {
        if (onGetRASDataForPersonAndAddToFavorite !== undefined) {
            if (!isFavorited(user)) {
                onGetRASDataForPersonAndAddToFavorite(user.username);
            }
        } else if (!attendeeList.includes(user.username)) {
            onGetRASDataForPerson(user.username, true, user.name);
        }
    };

    const onRemoveUser = (user) => {
        if (onRemoveFavorite !== undefined) {
            onRemoveFavorite(user.username);
        } else if (attendeeList.includes(user)) {
            onRemoveAttendeeByAlias(user.username);
        }
    };

    const onAddUsers = (attendeesToAdd) => {
        attendeesToAdd.forEach((attendee) => onAddUser(attendee));
    };

    const onRemoveUsers = (attendeesToRemove) => {
        attendeesToRemove.forEach((attendee) => onRemoveUser(attendee));
    };

    return (
        <div>
            {attendee.rasData ?
                <Column spacing="small">
                    <Row>
                        <Link
                            href={"https://phonetool.amazon.com/users/" + attendee.rasData.username}
                            target="_blank"
                            rel="noopener noreferrer"
                            tabIndex="-1"
                        >
                            <Thumbnail
                                size="medium"
                                source={attendee.imageLink}
                                label={"Thumbnail for " + attendee.rasData.name}
                            />
                        </Link>
                        <Column spacing="none">
                            <Row spacing="xsmall">
                                {identity.username !== attendee.alias && props.onFavoriteClick && renderSetFavoriteIcon(props.isFavorited(attendee), props.onFavoriteClick, attendee)}
                                <div aria-label={"Phone tool link for " + attendee.rasData.name}>
                                    <Link
                                        href={"https://phonetool.amazon.com/users/" + attendee.rasData.username}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        type="secondary"
                                    >
                                        <span style={{padding: "0 5px 0 0", fontWeight: "bold"}}>
                                            {attendee.rasData.name}
                                        </span>
                                        <Icon tokens={exportTokens} />
                                    </Link>
                                </div>
                            </Row>
                            <Text type="b100">{attendee.rasData.title}</Text>
                            <Text type="b100">{attendee.rasData.department}</Text>
                        </Column>
                    </Row>
                    <Column maxHeight="250px" overflowY="auto" spacing="small">
                        <Row widths={["grid-4", "grid-8"]}>
                            <Text alignment="right" type="b200">Email</Text>
                            {renderEmailLink(attendee.rasData.primaryEmail || attendee.rasData.email)}
                        </Row>
                        <Row widths={["grid-4", "grid-8"]}>
                            <Text alignment="right" type="b200">Phone</Text>
                            <Text alignment="left" type="b100">
                                {(attendee.rasData.phone && (attendee.rasData.phone.desk || attendee.rasData.phone.cell || attendee.rasData.phone.home)) || "N/A"}
                            </Text>
                        </Row>
                        <Divider size="small" />

                        <Row widths={["grid-4", "grid-8"]}>
                            <Text alignment="right" type="b200">Manager</Text>
                            {attendee.rasData.manager && attendee.rasData.manager.username ?
                                <section aria-label={"Manager for " + attendee.rasData.name}>
                                    <Row widths={["fit", "fill"]}>
                                        {renderUserNameLink(attendee.rasData.manager.username, attendee.rasData.name, MEMBER_TYPE.MANAGER)}
                                        {!disableAddFromPopover && renderAddButton(attendee.rasData.manager, attendeeList, onAddUser, onRemoveUser, attendee.rasData.name, MEMBER_TYPE.MANAGER)}
                                    </Row>
                                </section>
                                :
                                <Text type="b100" alignment="left">N/A</Text>
                            }
                        </Row>
                        <Row widths={["grid-4", "grid-8"]}>
                            <Text alignment="right" type="b200">Directs</Text>
                            {directs && directs.length ?
                                <Row widths={["fit", "fit", "fill"]}>
                                    <Text alignment="left" type="b100">{attendee.rasData.directs.length}</Text>
                                    {!disableAddFromPopover && renderAddAllButton(allDirectsAdded, directsUnderLimit, attendee.rasData.directs, onAddUsers, onRemoveUsers, attendee.name, GROUP_MEMBER_TYPE.DIRECTS)}
                                    {renderShowHideButton(attendee.rasData.directs.length, directsExpanded, setDirectsExpanded, attendee.name, GROUP_MEMBER_TYPE.DIRECTS)}
                                </Row>
                                :
                                <Text alignment="left" type="b100">N/A</Text>
                            }
                        </Row>
                        {directs && directs.length !== undefined && directsExpanded &&
                            <Column spacing="xsmall">
                                {_.orderBy(directs, 'username').map((direct) => (
                                    <Row widths={["grid-4", "grid-8"]} key={direct.username}>
                                        {renderUserNameLink(direct.username)}
                                        {!disableAddFromPopover && renderAddButton(direct, attendeeList, onAddUser, onRemoveUser)}
                                    </Row>
                                ))}
                            </Column>
                        }
                        <Row widths={["grid-4", "grid-8"]}>
                            <Text alignment="right" type="b200">Coworkers</Text>
                            {coworkers && coworkers.length ?
                                <Row widths={["fit", "fit", "fill"]}>
                                    <Text alignment="left" type="b100">{attendee.rasData.coworkers.length}</Text>
                                    {!disableAddFromPopover && renderAddAllButton(allCoworkersAdded, coworkersUnderLimit, attendee.rasData.coworkers, onAddUsers, onRemoveUsers, attendee.name, GROUP_MEMBER_TYPE.COWORKERS)}
                                    {renderShowHideButton(attendee.rasData.coworkers.length, coworkersExpanded, setCoworkersExpanded, attendee.name, GROUP_MEMBER_TYPE.COWORKERS)}
                                </Row>
                                :
                                <Text alignment="left" type="b100">N/A</Text>
                            }
                        </Row>
                        {coworkers && coworkers.length !== undefined && coworkersExpanded &&
                            <Column spacing="xsmall">
                                {_.orderBy(coworkers, 'username').map((coworker) => (
                                    <Row widths={["grid-4", "grid-8"]} key={coworker.username}>
                                        {renderUserNameLink(coworker.username)}
                                        {!disableAddFromPopover && renderAddButton(coworker, attendeeList, onAddUser, onRemoveUser)}
                                    </Row>
                                ))}
                            </Column>
                        }
                    </Column>
                </Column>
                :
                <Loader />
            }
        </div>
    );
};

export default UserAttendeeCard;
