import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"
import defaultThemeTokens from "@amzn/meridian-tokens/theme/blue-light"
import { getTokenHelper } from "../../_utils/token"
import { memoizeCurry } from "../../_utils/functional"

/**
 * Context for passing theme tokens down the tree.
 */
const ThemeContext = createContext(defaultThemeTokens)

/**
 * Merge two token objects together.
 */
const mergeTokens = memoizeCurry((a, b) => ({ ...a, ...b }), 1)

/**
 * Takes in an object of theme tokens and a function or object of tokens to
 * theme.
 */
const applyTheme = memoizeCurry(
  (themeTokens, tokens) =>
    typeof tokens === "function"
      ? mergeTokens(tokens(themeTokens))(themeTokens)
      : tokens
      ? mergeTokens(tokens)(themeTokens)
      : themeTokens,
  1
)

/**
 * A hook that grants access to theme tokens. Optionally pass in themeable
 * tokens as a function or non-themeable tokens as an object to mix with the
 * theme tokens. If tokens are provided, a prefixKey can be passed to allow easier
 * selection of tokens with a common prefix. For example:
 *   const t = useTheme(buttonTokens, "button")
 *   const buttonBgColor = t("backgroundColor")
 */
const useTheme = (
  tokens,
  prefixKey = "",
  _legacyHelperForBackwardsCompatibilityDoNotUse = null
) => {
  const themeTokens = useContext(ThemeContext)
  const currentTokens = applyTheme(themeTokens)(tokens)
  return typeof _legacyHelperForBackwardsCompatibilityDoNotUse === "function"
    ? _legacyHelperForBackwardsCompatibilityDoNotUse(currentTokens)
    : _legacyHelperForBackwardsCompatibilityDoNotUse
    ? currentTokens
    : getTokenHelper(prefixKey)(currentTokens)
}

/**
 * Set the theme. Changes are applied to all the component's descendants.
 */
function Theme({ tokens, children }) {
  const contextTokens = useContext(ThemeContext)
  const currentTokens = mergeTokens(contextTokens)(tokens)
  return (
    <ThemeContext.Provider value={currentTokens}>
      {children}
    </ThemeContext.Provider>
  )
}

Theme.propTypes = {
  /**
   * The components to apply the theme to.
   */
  children: PropTypes.element.isRequired,
  /**
   * An object of theme tokens imported from the Meridian token library.
   */
  tokens: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ])
  ).isRequired,
}

/**
 * IMPORTANT: For backwards-compatibility only! Use the "useTheme" hook instead.
 */
function Style({ tokens, map, children }) {
  return children(useTheme(tokens, "", map || true))
}

Style.propTypes = {
  children: PropTypes.func.isRequired,
  map: PropTypes.func,
  tokens: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

export default Theme
export { useTheme, ThemeContext, Style }
