module.exports = {
    FAVORITE_TYPE: {
        USER: 0,
        GROUP: 1,
        LOCATION: 2,
        ROOM: 3,
        EXTERNAL_EMAIL: 4,
    },
    FAVORITES_COOKIE: "amazon-meetings-favorites",
    FAVORITES_DEFAULT_WIDTH: "260px",
    UPDATE_USER_FAVORITES_DEBOUNCE_DELAY: 5000, // 5000 ms or 5 seconds
};