import React, { useState, useCallback } from "react";

import LocationModal from "./location-modal";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import geopinTokens from "@amzn/meridian-tokens/base/icon/geopin";

import { LOCATION_MODAL_TYPE } from "../locations-constants";
import { SCREEN_SIZE } from "../../shared-constants";

const AddLocation = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const findRooms = props.findRooms;

    const [addARoomModalOpen, setAddARoomModalOpen] = useState(false);

    const onClickOpenAddARoom = useCallback(() => {
        if (props.isAddRoomNotApplicable) {
            props.displayErrorMessage();
        } else {
            setAddARoomModalOpen(true);
        }
        // eslint-disable-next-line
    }, [props.isAddRoomNotApplicable]);

    return (
        <Row spacing="medium" alignmentHorizontal="left">
            <Button
                type="tertiary"
                disabled={!findRooms}
                onClick={onClickOpenAddARoom}
            >
                <Row spacing="xsmall">
                    <Row>
                        {screenSizeBreakpoint < SCREEN_SIZE.PARTIAL_MOBILE_VIEW ?
                            "Add"
                            :
                            "Add room"
                        }
                    </Row>
                    {screenSizeBreakpoint < SCREEN_SIZE.PARTIAL_MOBILE_VIEW && <Icon tokens={geopinTokens} />}
                </Row>
            </Button>
            <LocationModal
                modalType={LOCATION_MODAL_TYPE.ADD}
                modalOpen={addARoomModalOpen}
                identity={props.identity}
                buildings={props.buildings}
                rooms={props.rooms}
                locations={props.locations}
                userSettingsBuilding={props.userSettingsBuilding}
                userSettingsFloor={props.userSettingsFloor}
                userSettingsMinimumSeats={props.userSettingsMinimumSeats}
                userSettingsCamera={props.userSettingsCamera}
                userSettingsDisplay={props.userSettingsDisplay}
                userSettingsNoRestricted={props.userSettingsNoRestricted}
                userSettingsNoManaged={props.userSettingsNoManaged}
                onCloseModal={() => setAddARoomModalOpen(false)}
                onLoadFloors={props.onLoadFloors}
                onAddRoom={props.onAddRoom}
                onRemoveRoom={props.onRemoveRoom}
                onAddLocation={props.onAddLocation}
                onRemoveLocation={props.onRemoveLocation}
                onAddFavorite={props.onAddFavorite}
            />
        </Row>
    );
};

export default AddLocation;