import React from "react"
import PropTypes from "prop-types"
import { css, cx } from "emotion"
import buttonTokens from "@amzn/meridian-tokens/component/button"
import { useTheme } from "../theme"
import Clickable from "../_clickable"
import useFocus from "../_use-focus"
import Icon from "../icon"
import {
  activeStyles,
  hoverStyles,
  focusStyles,
  defaultStyles,
  disabledStyles,
} from "./button-styles"
import textStyles from "../../_styles/text"
import { memoizeTokenStyles } from "../../_utils/token"
import isColorDark from "../../_utils/is-color-dark"
import isElementOf from "../../_utils/is-element-of"
import filterPropsByPrefix from "../../_utils/filter-props-by-prefix"

const isIcon = isElementOf(Icon)

const styles = memoizeTokenStyles(
  (
    t,
    {
      size,
      type,
      focus,
      disabled,
      minWidth,
      backdropColor = t.tokens.themeBackgroundPrimaryDefault,
      iconOnly,
      circular,
    }
  ) => {
    const state = disabled ? "disabled" : "default"
    const linkOrIcon = type === "link" || type === "icon"
    const backdrop =
      linkOrIcon && isColorDark(backdropColor)
        ? "darkBackdrop"
        : linkOrIcon
        ? "lightBackdrop"
        : undefined
    const borderColor = t("borderColor", [type, state])
    const borderWidth = borderColor ? t("borderWidth") : 0
    const borderStyle = borderColor ? "solid" : undefined
    const borderRadius = circular && iconOnly ? 999 : t("borderRadius")
    return css(
      textStyles(t, { fontSize: size }),
      disabled
        ? disabledStyles(t, { type, backdrop })
        : defaultStyles(t, { type, backdrop }),
      {
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        outline: "none",
        position: "relative",
        minWidth,
        height: t("height", size),
        // Make icon-only buttons square w/ no padding and all other buttons
        // flexible with padding.
        width: iconOnly ? t("height", size) : undefined,
        flexShrink: iconOnly ? 0 : undefined,
        padding: iconOnly
          ? undefined
          : `0 ${t("spacingInsetHorizontal", size)}px`,
        transition: t.transitionCss("motion", [
          "color",
          "background-color",
          "border-color",
        ]),
        borderRadius,
        borderStyle,
        borderWidth,
        whiteSpace: "nowrap",
        // HACK: IE11 will only show a pseudo element on a button if
        // overflow is set to visible, and our focus ring is displayed with a
        // pseudo element.
        overflow: "visible",
        // HACK: IE11 does not support margin-inline-* or margin-block-* so we have
        // to use directional margin-left/right. If/when we drop IE11 we can simplify
        // these styles to be:
        // "& > * + *": {
        //   marginInlineStart: t("spacingInsetHorizontal", size),
        // },
        "& > * + *": {
          marginLeft: t("spacingInsetHorizontal", size),
          marginRight: 0,
        },
        [`[dir="rtl"] & > * + *`]: {
          marginLeft: 0,
          marginRight: t("spacingInsetHorizontal", size),
        },
        ":hover": !disabled ? hoverStyles(t, { type, backdrop }) : undefined,
        ":active": !disabled ? activeStyles(t, { type, backdrop }) : undefined,
      },
      !disabled
        ? focusStyles(t, {
            focus,
            borderWidth,
            borderRadius,
            backdropColor,
            linkOrIcon,
          })
        : undefined
    )
  },
  [
    "size",
    "type",
    "focus",
    "disabled",
    "backdropColor",
    "minWidth",
    "iconOnly",
    "circular",
  ]
)

// Used to wrap button text in spans. This allows the margins applied by the
// button to take effect, since you can't apply a margin to a text node. This
// was implemented with the <Button><Icon /> text</Button> use case in mind
// where spacing needs to be applied between the icon and the text. Note that
// this is not applied if the button only has one child.
const wrapStringSiblingsInSpans = children => {
  const arr = React.Children.toArray(children)
  return arr.length > 1
    ? arr.map((child, index) =>
        typeof child === "string" ? <span key={index}>{child}</span> : child
      )
    : children
}

/**
 * Render a button. Note that won't necessarily result in a "button" tag in the
 * DOM - if you provide an href attribute then an "a" tag will be used for
 * accessibility purposes.
 */
const Button = React.forwardRef((props, ref) => {
  const t = useTheme(buttonTokens, "button")
  // Does the button contain only an icon (no text)?
  const iconOnly =
    React.Children.count(props.children) === 1 && isIcon(props.children)
  // Pull popovers closer to icon/link buttons so that they're offset from the
  // edge of the icon/text (which is visible), not the edge of the button
  // element (which is not visible). The magic "24" for the icon variant is the
  // size of a standard icon. By subtracting that from the height of the button
  // and dividing by 2 we get the distance between the invisible edge of the
  // button and the visible edge of the icon.
  const popoverOffset =
    props.type === "icon"
      ? -(t("height", props.size) - 24) / 2
      : props.type === "link"
      ? -(t("height", props.size) - t("fontSize", props.size)) / 2
      : undefined
  const { focus, focusTrigger, focusProps } = useFocus(props)
  const passThroughProps = filterPropsByPrefix(props, ["data-"])
  return (
    <Clickable
      {...passThroughProps}
      ref={ref}
      className={cx(
        styles(t, {
          type: props.type,
          size: props.size,
          disabled: props.disabled,
          circular: props.circular,
          backdropColor: props.backdropColor,
          minWidth: props.minWidth,
          focus: focus && focusTrigger === "keyboard",
          iconOnly,
        }),
        props.className
      )}
      disabled={props.disabled}
      id={props.id}
      href={props.href}
      rel={props.rel}
      target={props.target}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      propagateClickEvent={props.propagateClickEvent}
      tabIndex={props.tabIndex}
      download={props.download}
      submit={props.submit}
      aria-controls={props["aria-controls"] || undefined}
      aria-expanded={props["aria-expanded"] || undefined}
      aria-haspopup={props["aria-haspopup"] || undefined}
      aria-hidden={props["aria-hidden"] || undefined}
      aria-label={props.label || undefined}
      mdn-popover-offset={popoverOffset}
      {...focusProps}
    >
      {wrapStringSiblingsInSpans(props.children)}
    </Clickable>
  )
})

Button.displayName = "Button"

Button.propTypes = {
  /**
   * The button's contents. This can be a string label, an Icon, or a
   * combination of a string label and an Icon.
   */
  children: PropTypes.node.isRequired,
  /**
   * Identifies the element (or elements) whose contents or presence are controlled by the current element.
   * Please refer to this [guide](https://www.w3.org/TR/wai-aria-1.1/#aria-haspopup) for details.
   *
   * @since 5.x
   */
  "aria-controls": PropTypes.string,
  /**
   * Indicates whether the element, or another grouping element it controls,
   * is currently expanded or collapsed. Please refer to this [guide](https://www.w3.org/TR/wai-aria-1.1/#aria-haspopup) for details.
   *
   * @since 5.x
   */
  "aria-expanded": PropTypes.string,
  /**
   * Indicates the availability and type of interactive popup element,
   * such as menu or dialog, that can be triggered by an element. Please refer
   * to this [guide](https://www.w3.org/TR/wai-aria-1.1/#aria-haspopup) for
   * details.
   *
   * @since 5.x
   */
  "aria-haspopup": PropTypes.oneOf([
    true,
    "menu",
    "listbox",
    "tree",
    "grid",
    "dialog",
  ]),
  /**
   * This can be used to hide the element from screen readers. Only use this if
   * the element is also visually hidden.
   */
  "aria-hidden": PropTypes.bool,
  /**
   * This optimizes the button for display when placed over a particular
   * background color. This is only helpful if the button is placed over a
   * background color other than the current theme's primary background color.
   */
  backdropColor: PropTypes.string,
  /**
   * Render a circular button. This is only applicable to icon-only buttons.
   * Buttons with text labels cannot be circular.
   *
   * @since 4.x
   */
  circular: PropTypes.bool,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  /**
   * All props prefaced with `data-` are accepted. This is useful for adding selectors
   * for integrating with other libraries such as Amplify Analytics or Cypress
   *
   * @since 4.x
   */
  "data-*": PropTypes.string,
  /**
   * This disables interaction with the component and applies special visual
   * styles to indicate that it's not interactive.
   */
  disabled: PropTypes.bool,
  /**
   * Use this in conjunction with the `href` prop to render a button that
   * triggers the download of a file. See docs for the [HTML download attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download).
   */
  download: PropTypes.string,
  /**
   * If a URL is provided here, the component will behave like a link.
   *
   * Note that you must either set this prop or the `onClick` prop (or both).
   */
  href: PropTypes.string,
  /**
   * Sets an `id` attribute on the component's form element.
   *
   * Use this for testing or for accessibility. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   *
   * @since 4.x
   */
  id: PropTypes.string,
  /**
   * An accessible label for the component. This will not be visible in the UI, but
   * will be read by screen readers.
   *
   * See docs for the [aria-label attribute](https://www.w3.org/TR/wai-aria/#aria-label)
   * for details on appropriate usage.
   *
   */
  label: PropTypes.string,
  /**
   * Sets the minimum width on the button. The button may grow wider than this
   * value depending on the length of its label, but it will not grow narrow
   * than this value.
   */
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Determines whether or not the click event will continue to bubble up
   * through the DOM. Read the [Event.stopPropagation() docs](https://developer.mozilla.org/en-US/docs/Web/API/Event/stopPropagation)
   * for details.
   *
   * @since 4.x
   */
  propagateClickEvent: PropTypes.bool,
  /**
   * The relationship of the linked URL to the current page. Only applicable if
   * `href` is set.
   *
   * See docs for the [a tag's rel attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-rel) for details.
   *
   * @since 4.x
   */
  rel: PropTypes.string,
  /**
   * Sets the size of the component using a preset.
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  /**
   * If set to true the button will automatically trigger the submit action of
   * any `<form />` that it's placed in.
   */
  submit: PropTypes.bool,
  /**
   * Manually specify the order of the component in sequential keyboard navigation.
   *
   * Use this with caution. See docs for the [HTML tabindex attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex)
   * for details on appropriate usage.
   */
  tabIndex: PropTypes.string,
  /**
   * Determine where the linked URL is displayed. This is only applicable if
   * `href` is provided but `onClick` is not.
   *
   * IMPORTANT: If you set this prop to `"_blank"`, make sure to set `rel` to
   * `"noopener noreferrer"` to avoid performance and security issues.
   *
   * See docs for the [a tag's target attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target) for details.
   */
  target: PropTypes.string,
  /**
   * This determines the visual style of the button.
   */
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "link", "icon"]),
  /**
   * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   */
  onBlur: PropTypes.func,
  /**
   * This will be triggered when the component is clicked. If the `href` prop is
   * set, that value will be passed as the first argument to this function.
   *
   * If this prop is used along with the `href` prop, the default link behavior
   * of the component will be disabled and the click handler will be responsible
   * for implementing the behavior of the component.
   *
   * These features make it easy to implement accessible client-side navigation.
   * If you have a client-side navigation function of the form `navigate(<url>)`, you
   * can integrate it into this component by setting a URL via `href` and then
   * passing `navigate` directly to this prop (i.e. `href={url} onClick={navigate}`).
   * See the [Routing and navigation guide](https://meridian.a2z.com/for-developers/guides/routing-and-navigation/?platform=react-web)
   * for details.
   *
   * Note that you must set either this prop or the `href` prop (or both).
   */
  onClick: PropTypes.func,
  /**
   * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   */
  onFocus: PropTypes.func,
  /**
   * This is called when the mouse cursor moves over the component. The [mouseenter event](https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseenter_event)
   * is passed as the first argument to the function.
   */
  onMouseEnter: PropTypes.func,
  /**
   * This is called when the mouse cursor moves off of the component. The [mouseleave event](https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseleave_event)
   * is passed as the first argument to the function.
   */
  onMouseLeave: PropTypes.func,
}

Button.defaultProps = {
  propagateClickEvent: true,
  size: "medium",
  type: "primary",
}

export default Button
