import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Row from "@amzn/meridian/row";
import Tooltip from "@amzn/meridian/tooltip";
import Icon from "@amzn/meridian/icon";
import Button from "@amzn/meridian/button";
import shareToken from "@amzn/meridian-tokens/base/icon/share";
import checkKnockoutTokens from "@amzn/meridian-tokens/base/icon/check-knockout";
import { colorGreen300 } from "@amzn/meridian-tokens/base/color";

import { generateComponentId } from "../../shared-utils";
import { getPermalink, getTooltipTitle, onPermalinkHover } from "../permalink-utils";
import { COPY_PERMALINK, PERMALINK_COPIED } from "../permalink-constants";

import { postRoomBookingMetric } from "../../metrics/actions";

const PermalinkButton = (props) => {
    const dispatch = useDispatch();

    const path = props.path;
    const queryParameters = props.queryParameters;
    const [isCopiedToClipboard, setIsCopiedToClipboard] = useState({
        title: COPY_PERMALINK,
        isCopied: false
    });

    // Custom icon background color
    const greenCheckKnockoutTokens = {
        iconData: checkKnockoutTokens.iconCheckKnockoutData.replace("currentColor", colorGreen300),
        iconWidth: 16,
        iconHeight: 16,
    };

    const onClick = () => {
        getPermalink(path, queryParameters, setIsCopiedToClipboard);
        if (props.page === "room booking") {
            dispatch(postRoomBookingMetric("permalink", "generate"));
        }
    }

    return (
        <section>
            <Row alignmentHorizontal="end">
                <span role="status" aria-live="polite" style={{position:"absolute", clip:"rect(1px,1px,1px,1px)"}}>
                    {isCopiedToClipboard.isCopied ? PERMALINK_COPIED : ""}
                </span>
                <Tooltip
                    position="bottom"
                    title={getTooltipTitle(isCopiedToClipboard, greenCheckKnockoutTokens)}
                >
                    <Button
                        backdropColor="#1A454E" // rgba(26, 69, 78) - Teal color used for page header
                        type="icon"
                        size="small"
                        onMouseEnter={() => onPermalinkHover(path, queryParameters, setIsCopiedToClipboard)}
                        onFocus={() => onPermalinkHover(path, queryParameters, setIsCopiedToClipboard)}
                        onClick={onClick}
                        id={generateComponentId("button", "get-permalink")}
                        label="Generate Permalink"
                    >
                        <Icon tokens={shareToken} />
                    </Button>
                </Tooltip>
            </Row>
        </section>
    );
};

export default PermalinkButton;