const suggestionHeight = 50;
const attendeeListCellHeight = 40;
const attendeeSearchBarHeight = 102;
const attendeeTableHeaderHeight = 42;
const addRoomRowHeight = 72; // height of "Add room" button row

module.exports = {
    ATTENDEE_TYPE: {
        PERSON: "PERSON",
        GROUP: "GROUP",
        EXTERNAL_EMAIL: "EXTERNAL_EMAIL",
        CHIME: "CHIME",
    },
    MEMBER_TYPE: {
        PERSON: "user",
        GROUP: "group",
        EXTERNAL_EMAIL: "external",
        MANAGER: "manager",
    },
    GROUP_MEMBER_TYPE: {
        COWORKERS: "coworkers",
        DIRECTS: "directs",
        MEMBERS: "members",
    },
    ATTENDEE_RESPONSE: {
        NO_RESPONSE: undefined,
        ACCEPT: "accept",
        TENTATIVE: "tentative",
        DECLINE: "decline",
        ORGANIZER: "organizer",
    },
    EXCHANGE_RESPONSE: {
        "noResponse": "No response",
        "accept": "Accepted",
        "tentative": "Tentative",
        "decline": "Declined",
        "organizer": "Organizer",
    },
    ATTENDEE_PRIORITY: {
        REQUIRED: "Required",
        OPTIONAL: "Optional",
        ESSENTIAL: "Essential",
        ORGANIZER: "Organizer",
        AUTO_SUGGEST: "AutoSuggest",
    },
    ATTENDEE_ROLE: {
        ORGANIZER: "Organizer",
        LIVESTREAM: {
            PRESENTER: "Presenter",
            VIEWER: "Viewer",
        },
    },
    POLL_PRIORITY: {
        REQUIRED: "Required",
        OPTIONAL: "Optional",
    },
    ATTENDEE_SORT_BY: {
        ATTENDEE: "attendee",
        STATUS: "status",
        PRIORITY: "priority",
        POINT_OF_CONTACT: "isPointOfContact",
        ROLE: "role",
    },
    ATTENDEE_SEARCH_BAR_MIN_WIDTH: "311px",
    ATTENDEE_SEARCH_BAR_DEFAULT_WIDTH: "390px",
    ATTENDEE_SEARCH_BAR_DEFAULT_HEIGHT: attendeeSearchBarHeight,
    ATTENDEE_SEARCH_BAR_SUGGESTION_HEIGHT: suggestionHeight,
    ATTENDEE_SEARCH_BAR_DEFAULT_POPOVER_HEIGHT: 7 * suggestionHeight,
    ATTENDEE_SEARCH_BAR_SMALL_POPOVER_HEIGHT: 5 * suggestionHeight,
    ATTENDEE_SEARCH_BAR_MINIMUM_QUERY_LENGTH: 3,
    ATTENDEE_LIST_CELL_MARGIN: 16,
    ATTENDEE_LIST_CELL_HEIGHT: attendeeListCellHeight,
    ATTENDEE_LIST_DEFAULT_HEIGHT: 6 * attendeeListCellHeight,
    ATTENDEE_LIST_MIN_HEIGHT: attendeeSearchBarHeight + attendeeTableHeaderHeight + (6 * attendeeListCellHeight),
    ADD_ROOM_ROW_HEIGHT: addRoomRowHeight,
    TRUNCATION_DEFAULT: {
        PEOPLE_SUGGESTIONS: 5,
        GROUP_SUGGESTIONS: 5,
    },
    DEBOUNCE_DELAY: 500, // 500 ms
    ATTENDEE_POPOVER_MAX_LIST_SIZE: 30,
    ATTENDEE_PHONETOOL_URL_PREFIX: "https://phonetool.amazon.com/users/",
    ATTENDEE_IMAGE_URL_PREFIX: "https://internal-cdn.amazon.com/badgephotos.amazon.com/?login=",
    DEFAULT_PRIORITY_ICON_SIZE: "16px",
    EMAIL_SUGGESTIONS_TIP: "Press enter to manually add email(s).",
    PRESENTER_SUGGESTIONS_TIP: "Groups cannot be added as presenters.",
    NO_ATTENDEE_SUGGESTIONS: "No attendee suggestions.",
    UNKNOWN_JOB_TITLE: "Unknown job title",
};
