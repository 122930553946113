import React from "react";

import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import Text from "@amzn/meridian/text";

import { COPY_PERMALINK, PERMALINK_COPIED, PERMALINK_BUTTON_PARAMETER } from "./permalink-constants";

// Fetches the query parameters from a URL for a given path and returns a dictionary of the values
export const getUrlParameters = (path) => {
    const pathRegex = new RegExp(path + "(\\?|\\/)?", "g");
    const newUrl = window.location.hash.replace(pathRegex, "");
    const urlParameters = new URLSearchParams(newUrl);

    const queryParameters = {};

    urlParameters.forEach((value, parameter) => {
        queryParameters[parameter] = value;
    });

    return queryParameters;
};

// Passing the meeting path and parameters to generate PermaLink and returns the Permalink
export const buildPermalink = (path, parameters) => {
    let url = `${window.location.protocol}//${window.location.host}/${path}?`;
    for (const parameter in parameters) {
        if (parameters[parameter]) {
            url += `&${parameter}=${parameters[parameter]}`;
        }
    }
    url += PERMALINK_BUTTON_PARAMETER;
    url = encodeURI(url.trim());
    return url;
};

// Takes in query parameters of a component and stores them in a state variable
export const updateQueryParameters = (parameters, queryParameters, setQueryParameters) => {
    const updatedQueryParameters = JSON.parse(JSON.stringify(queryParameters));

    // Update query parameter if the value is different
    for (const parameter in parameters) {
        if (parameters[parameter] !== updatedQueryParameters[parameter]) {
            updatedQueryParameters[parameter] = parameters[parameter];
        }
    }

    if (JSON.stringify(queryParameters) !== JSON.stringify(updatedQueryParameters)) {
        setQueryParameters(updatedQueryParameters);
    }
};

export const getPermalink = (path, queryParameters, setIsCopiedToClipboard) => {
    navigator.clipboard.writeText(buildPermalink(path, queryParameters));
    setIsCopiedToClipboard({
        title: PERMALINK_COPIED,
        isCopied: true
    });
};

export const onPermalinkHover = (path, queryParameters, setIsCopiedToClipboard) => {
    setIsCopiedToClipboard({
        title: COPY_PERMALINK,
        isCopied: false
    });
};

export const getTooltipTitle = (isCopiedToClipboard, greenCheckKnockoutTokens) => {
    return (isCopiedToClipboard.isCopied ?
        <Row spacing="small">
            <Icon tokens={greenCheckKnockoutTokens} />
            <Text color="inverted">{isCopiedToClipboard.title}</Text>
        </Row>
        :
        isCopiedToClipboard.title
    );
};