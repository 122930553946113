// Configs for CheckInServices
export const ENDPOINTS = {
	Alpha: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	Beta: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	BetaPrime: {
		PDX: {
			Endpoint: "https://gamma.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	Prod: {
		PDX: {
			Endpoint: "https://pdxprod.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-west-2",
		},

		// Below endpoints are not working for now, once MCI fixed it we will re-enable it
		// IAD: {
		// 	Endpoint: "https://iadprod.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
		// 	Region: "us-west-1",
		// },
		// DUB: {
		// 	Endpoint: "https://dubprod.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
		// 	Region: "eu-west-1",
		// },
		// NRT: {
		// 	Endpoint: "https://nrtprod.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
		// 	Region: "ap-notheast-1",
		// },

		IAD: {
			Endpoint: "https://pdxprod.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-west-2",
		},
		DUB: {
			Endpoint: "https://pdxprod.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-west-2",
		},
		NRT: {
			Endpoint: "https://pdxprod.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-west-2",
		},
	},

	// Dev Config
	alvinyu: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	amiyyapu: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	amberyi: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	anirudps: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	camei: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	daverod: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",//"https://gamma.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	kjoshuaz: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	mahtabr: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	niskar: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	tejavk: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	uccdev: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	},
	yangzhuo: {
		PDX: {
			Endpoint: "https://beta.meeting-check-in.meetex.enterprise-engineering.aws.dev/",
			Region: "us-east-1",
		},
	}
};

export const OPERATIONS = {
	CHECK_IN_ROOM: "checkInRoom",
	RELEASE_ROOM: "releaseRoom",
	GET_ROOM_ELIGIBILITY: "getRoomEligibility",
	GET_ROOM_CHECK_IN_STATUS: "getCheckInStatus",
};

export const OPERATION_PATHS = {
	checkInRoom: "checkin/:source",
	releaseRoom: "release/:source",
	getRoomEligibility: "room/:roomEmail/eligibility",
	getCheckInStatus: "checkin/status",
};