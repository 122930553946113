import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import format from "date-fns/format";
import { addDays, isAfter, isValid, parse } from "date-fns";

import {
    getOOTOMeeting,
    getTimezonesList,
} from "../../../sagas/selector";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";

import OOTONavigationBanner from "./ooto-navigation-banner";
import StepNotifyPeople from "./step-notify-people";
import StepOOTOAgendaAndReview from "./step-ooto-agenda-and-review";
import PermalinkButton from "../../shared/permalink/components/permalink-button";

import HeaderBackground from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";

import { DEFAULT_TIME, STEP, STEP_LABEL, ALERTS } from "../ooto-scheduler-constants";
import { NAVIGATION_TYPE, STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { STATUS_LABEL } from "../../shared/meeting-status-constants";
import { getFormattedMeetingTimeInfo, replaceTimeInfoAlertTitle } from "../ooto-scheduler-utils";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { FAILED_METRIC_PARAMETERS, PERMALINK_SOURCE } from "../../shared/permalink/permalink-constants";
import { getUrlParameters } from "../../shared/permalink/permalink-utils";
import { postQueryParamsLinkMetric } from "../../shared/metrics/actions";

const OOTOForm = (props) => {
    const mastheadSize = props.mastheadSize;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const currentStep = props.currentStep;
    const meeting = props.meeting;
    const attendees = props.attendees;
    const queryParams = props.queryParams;
    const invalidUserEmail = props.invalidUserEmail;
    const onUpdateMeeting = props.onUpdateMeeting;
    const identity = props.identity;
    const timeFormat = props.timeFormat;
    const dateFormat = props.dateFormat;
    const settingsWorkingHours = props.settingsWorkingHours;
    const [isQueryParamsCheck, setIsQueryParamsCheck] = useState(true);

    // queryParameters for generation of PermaLink
    const [queryParameters, setQueryParameters] = useState({});

    const peopleSuggestions = props.peopleSuggestions &&
        props.peopleSuggestions.filter((person) => person.basicInfo && person.basicInfo.login &&
            !attendees.some((attendee) => attendee.alias === person.basicInfo.login.toLowerCase()));
    const groupSuggestions = props.groupSuggestions &&
        props.groupSuggestions.filter((group) => !attendees.some((attendee) => attendee.alias === group));

    const formatDate = (date) => format(date, "yyyy-MM-dd");
    const formatTime = (date) => format(date, "HH:mm:ss");

    const {time, isAllDayEvent} = meeting;

    const startTimeAsDate = time.startTime && new Date(time.startTime * 1000);
    const startTime = formatTime(startTimeAsDate);
    const startDate = formatDate(startTimeAsDate);

    const endTimeAsDate = time.endTime && new Date(time.endTime * 1000);
    const endDate = (formatTime(endTimeAsDate) === "00:00:00")
        ? formatDate(endTimeAsDate.setDate(endTimeAsDate.getDate() - 1))
        : formatDate(endTimeAsDate);
    const endTime = formatTime(endTimeAsDate);

    const [dateRange, setDateRange] = useState([startDate, endDate]);
    const timezones = useSelector(getTimezonesList);
    const [timezoneValue, setTimezoneValue] = [props.timezoneValue, props.setTimezoneValue];
    let timeInfoAlert = JSON.parse(JSON.stringify(ALERTS.OOTO_INCORRECT_TIME_INFO));
    timeInfoAlert.value += getFormattedMeetingTimeInfo(meeting, timezones);
    timeInfoAlert = replaceTimeInfoAlertTitle(timeInfoAlert, props.messageBodyTimeFormatChanged, props.messageSubjectTimeFormatChanged);

    const isDatePickerError = !dateRange[0] || !dateRange[1] || startDate > endDate || (!isAllDayEvent && startDate === endDate && startTime >= endTime);

    /* Step OOTO agenda states */
    const [isSubjectEmpty, setIsSubjectEmpty] = useState(false);

    // Alerts shown on the review step
    const [reviewAlerts, setReviewAlerts] = useState([ALERTS.OOTO_INFORMATION]);

    // Meeting navigation buttons loader
    const [isLastStepLoading, setIsLastStepLoading] = useState(false); // disable send invite ooto button

    const dispatch = useDispatch();

    // useEffect for On query params load for OOTO Notify people
    useEffect(() => {
        if (isQueryParamsCheck && invalidUserEmail !== undefined && identity && identity.username &&
            (queryParams.timezone === undefined || (timezones.length > 0 && timezoneValue !== undefined))) {
            let invalidParameters = [];
            let queryParamsSource = PERMALINK_SOURCE.THIRD_PARTY;
            if (queryParams.permalinkButton) {
                queryParamsSource = PERMALINK_SOURCE.PERMALINK_BUTTON;
            } else if (queryParams.outlookPlugin) {
                queryParamsSource = PERMALINK_SOURCE.OUTLOOK_PLUGIN;
            }
            if (invalidUserEmail) {
                invalidParameters.push(FAILED_METRIC_PARAMETERS.PARTICIPANTS_BY_PRIORITY);
            }
            // Load OOTO Start and End Dates
            if (queryParams.startDate !== undefined && queryParams.endDate !== undefined) {
                if (!isAfter(new Date(queryParams.startDate), new Date(queryParams.endDate)) &&
                    isValid(parse(queryParams.startDate, "yyyy-MM-dd", new Date())) &&
                    isValid(parse(queryParams.endDate, "yyyy-MM-dd", new Date()))) {
                    let newStartDate = new Date(queryParams.startDate);
                    let newEndDate = new Date(queryParams.endDate);
                    newStartDate.setHours(0, 0, 0);
                    newEndDate.setHours(24, 0, 0);
                    onUpdateMeeting({
                        time: {
                            startTime: Math.floor(newStartDate.getTime() / 1000),
                            endTime: Math.floor(newEndDate.getTime() / 1000)
                        }
                    });
                    setDateRange([queryParams.startDate, queryParams.endDate]);
                } else {
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.START_END_DATE);
                }
            }

            // Load OOTO isAllDayEvent, start Time and End Time
            if (queryParams.isAllDayEvent !== undefined) {
                if (queryParams.isAllDayEvent === "true" || queryParams.isAllDayEvent === "false") {
                    let startTime = new Date().setHours(DEFAULT_TIME.START_TIME.HOURS, DEFAULT_TIME.START_TIME.MINUTES, DEFAULT_TIME.START_TIME.SECONDS);
                    let endTime = new Date().setHours(DEFAULT_TIME.END_TIME.HOURS, DEFAULT_TIME.END_TIME.MINUTES, DEFAULT_TIME.END_TIME.SECONDS);

                    if (queryParams.startTime !== undefined &&
                        queryParams.endTime !== undefined) {
                        if (isValid(parse(queryParams.startTime, "HH:mm:ss", new Date())) &&
                            isValid(parse(queryParams.endTime, "HH:mm:ss", new Date()))) {
                            let [startHour, startMinute] = queryParams.startTime.split(":");
                            let [endHour, endMinutes] = queryParams.endTime.split(":");
                            let newStartDate, newEndDate;
                            if (queryParams.startDate !== undefined &&
                                queryParams.endDate !== undefined &&
                                isValid(parse(queryParams.startDate, "yyyy-MM-dd", new Date())) &&
                                isValid(parse(queryParams.endDate, "yyyy-MM-dd", new Date()))) {
                                newStartDate = addDays(new Date(queryParams.startDate), 1);
                                startTime = newStartDate.setHours(startHour, startMinute, 0);
                                newEndDate = addDays(new Date(queryParams.endDate), 1);
                                endTime = newEndDate.setHours(endHour, endMinutes, 0);
                            } else {
                                startTime = new Date().setHours(startHour, startMinute, 0);
                                endTime = new Date().setHours(endHour, endMinutes, 0);
                            }
                        } else {
                            invalidParameters.push(FAILED_METRIC_PARAMETERS.START_END_TIME);
                        }
                    } else if (settingsWorkingHours?.startTime && settingsWorkingHours?.endTime) {
                        const [startHour, startMin] = settingsWorkingHours?.startTime.split(":");
                        const [endHour, endMin] = settingsWorkingHours?.endTime.split(":");
                        startTime = new Date().setHours(startHour, startMin, DEFAULT_TIME.START_TIME.SECONDS);
                        endTime = new Date().setHours(endHour, endMin, DEFAULT_TIME.START_TIME.SECONDS);
                    }

                    onUpdateMeeting({
                        isAllDayEvent: queryParams.isAllDayEvent === "true",
                        time: {
                            startTime: Math.floor(startTime / 1000),
                            endTime: Math.floor(endTime / 1000)
                        }
                    });
                } else {
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.ALL_DAY_EVENT);
                }
            }

            // Load timezone from permalink
            if (queryParams.timezone !== undefined) {
                const timezone = timezones.find((timezone) => timezone.id === decodeURIComponent(queryParams.timezone));
                if (timezone === undefined) {
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.TIMEZONE);
                } else {
                    setTimezoneValue(timezone.id);
                }
            }

            // Load meeting status from Query Params
            if (queryParams.status !== undefined) {
                let status;
                if (Object.keys(STATUS_LABEL).includes(queryParams.status)) {
                    status = queryParams.status;
                } else {
                    status = "outOfOffice";
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.STATUS);
                }
                onUpdateMeeting({
                    status: status
                });
            }

            // Load meeting Response requested from Query Params
            if (queryParams.isResponseRequested !== undefined) {
                if (queryParams.isResponseRequested === "true" || queryParams.isResponseRequested === "false") {
                    onUpdateMeeting({
                        isResponseRequested: queryParams.isResponseRequested === "true"
                    });
                } else {
                    invalidParameters.push(FAILED_METRIC_PARAMETERS.REQUEST_RESPONSES);
                }
            }

            if (window.location.href.includes("?") && Object.keys(getUrlParameters(WORKFLOWS.OOTO.HREF)).length) {
                dispatch(postQueryParamsLinkMetric(invalidParameters, queryParamsSource));
            }

            setIsQueryParamsCheck(false);
        }
    }, [identity, dispatch, settingsWorkingHours, onUpdateMeeting, dateRange, timezones, timezoneValue, setTimezoneValue, invalidUserEmail, queryParams, isQueryParamsCheck]);

    const header = useRef();

    // Begin new workflow content with focus on the <h1> heading
    useEffect(() => {
        if (header.current) {
            header.current.focus();
        }
    }, []);

    return (
        <Column height="100%" spacing="none">
            <div style={{"paddingBottom": STEP_NAVIGATION_FOOTER.HEIGHT}}>
                <Column
                    height="100%"
                    minHeight={`calc(100vh - ${mastheadSize} - ${STEP_NAVIGATION_FOOTER.HEIGHT})`} // 100vh - mastheadSize - footer
                    heights={["fit", "fit", "fill"]}
                    spacing="none"
                >
                    <div style={{
                        "backgroundImage": `url(${HeaderBackground})`,
                        "backgroundRepeat": "no-repeat",
                        "backgroundColor": "rgba(26, 69, 78)",
                        "backgroundPosition": "200px -90px",
                        "height": "50px",
                        "width": "100%",
                    }}>
                        <Row height={"100%"} alignmentVertical={"center"} spacingInset={"medium"} widths={["fill", "fit"]}>
                            <Heading color="inverted" level={1} type={"h300"} ref={header} tabIndex="-1">Out Of The Office</Heading>
                            <PermalinkButton
                                path={WORKFLOWS.OOTO.HREF}
                                queryParameters={queryParameters}
                            />
                        </Row>
                    </div>
                    <OOTONavigationBanner
                        navigationType={NAVIGATION_TYPE.HEADER}
                        currentStep={currentStep}
                        meeting={meeting}
                        identity={identity}
                        timezones={timezones}
                        onUpdateMeeting={onUpdateMeeting}
                        onChangeStep={props.onChangeStep}
                        onCreateMeeting={props.onCreateMeeting}
                        steps={[
                            STEP.NOTIFY_PEOPLE,
                            STEP.OOTO_AGENDA_AND_REVIEW
                        ]}
                        labels={[
                            STEP_LABEL.NOTIFY_PEOPLE,
                            STEP_LABEL.OOTO_AGENDA_AND_REVIEW
                        ]}
                        setIsSubjectEmpty={setIsSubjectEmpty}
                        reviewAlerts={reviewAlerts}
                        setReviewAlerts={setReviewAlerts}
                        isAttendeeLoading={attendees.filter((attendee) => !attendee.email).length > 0}
                        isDatePickerError={isDatePickerError}
                        timezoneValue={props.timezoneValue}
                        oldMessageBodyTimeInfo={props.oldMessageBodyTimeInfo}
                        setOldMessageBodyTimeInfo={props.setOldMessageBodyTimeInfo}
                        oldMessageSubjectTimeInfo={props.oldMessageSubjectTimeInfo}
                        setOldMessageSubjectTimeInfo={props.setOldMessageSubjectTimeInfo}
                        setMessageBodyTimeFormatChanged={props.setMessageBodyTimeFormatChanged}
                        setMessageSubjectTimeFormatChanged={props.setMessageSubjectTimeFormatChanged}
                        pointOfContact={props.pointOfContact}
                        timeInfoAlert={timeInfoAlert}
                        queryParams={queryParams}
                        isLastStepLoading={isLastStepLoading}
                        setIsLastStepLoading={setIsLastStepLoading}
                    />
                    {currentStep === STEP.NOTIFY_PEOPLE &&
                        <StepNotifyPeople
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            identity={props.identity}
                            meeting={props.meeting}
                            attendees={attendees}
                            peopleSuggestions={peopleSuggestions}
                            groupSuggestions={groupSuggestions}
                            pointOfContact={props.pointOfContact}
                            setPointOfContact={props.setPointOfContact}
                            formatDate={formatDate}
                            formatTime={formatTime}
                            time={time}
                            isAllDayEvent={isAllDayEvent}
                            startTimeAsDate={startTimeAsDate}
                            startTime={startTime}
                            startDate={startDate}
                            endTimeAsDate={endTimeAsDate}
                            endDate={endDate}
                            endTime={endTime}
                            settingsWorkingHours={settingsWorkingHours}
                            dateRange={dateRange}
                            timeFormat={timeFormat}
                            dateFormat={dateFormat}
                            setDateRange={setDateRange}
                            queryParameters={queryParameters}
                            setQueryParameters={setQueryParameters}
                            timezones={props.timezones}
                            timezoneValue={props.timezoneValue}
                            setTimezoneValue={props.setTimezoneValue}
                            favorites={props.favorites}
                            onUpdateMeeting={props.onUpdateMeeting}
                            onAddAttendee={props.onAddAttendee}
                            onUpdateAttendee={props.onUpdateAttendee}
                            onRemoveAttendee={props.onRemoveAttendee}
                            onGetRASDataForPerson={props.onGetRASDataForPerson}
                            onGetRASDataForGroup={props.onGetRASDataForGroup}
                            onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                            onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                            onGetGroupSuggestions={props.onGetGroupSuggestions}
                            onClearGroupSuggestions={props.onClearGroupSuggestions}
                            onAddFavorite={props.onAddFavorite}
                            onRemoveFavorite={props.onRemoveFavorite}
                        />
                    }
                    {currentStep === STEP.OOTO_AGENDA_AND_REVIEW &&
                        <StepOOTOAgendaAndReview
                            identity={identity}
                            onChangeStep={props.onChangeStep}
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            meeting={props.meeting}
                            meetingDetails={getOOTOMeeting}
                            onUpdateMeeting={props.onUpdateMeeting}
                            attendees={attendees}
                            messageBodyTimeFormatChanged={props.messageBodyTimeFormatChanged}
                            messageSubjectTimeFormatChanged={props.messageSubjectTimeFormatChanged}
                            timeInfoAlert={timeInfoAlert}
                            queryParameters={queryParameters}
                            setQueryParameters={setQueryParameters}
                            isSubjectEmpty={isSubjectEmpty}
                            reviewAlerts={reviewAlerts}
                            timeFormat={timeFormat}
                        />
                    }
                </Column>
            </div>
            <OOTONavigationBanner
                navigationType={NAVIGATION_TYPE.FOOTER}
                currentStep={currentStep}
                meeting={meeting}
                identity={identity}
                timezones={timezones}
                onUpdateMeeting={onUpdateMeeting}
                onChangeStep={props.onChangeStep}
                onCreateMeeting={props.onCreateMeeting}
                steps={[
                    STEP.NOTIFY_PEOPLE,
                    STEP.OOTO_AGENDA_AND_REVIEW
                ]}
                labels={[
                    STEP_LABEL.NOTIFY_PEOPLE,
                    STEP_LABEL.OOTO_AGENDA_AND_REVIEW
                ]}
                setIsSubjectEmpty={setIsSubjectEmpty}
                reviewAlerts={reviewAlerts}
                setReviewAlerts={setReviewAlerts}
                isAttendeeLoading={attendees.filter((attendee) => !attendee.email).length > 0}
                isDatePickerError={isDatePickerError}
                timezoneValue={props.timezoneValue}
                oldMessageBodyTimeInfo={props.oldMessageBodyTimeInfo}
                setOldMessageBodyTimeInfo={props.setOldMessageBodyTimeInfo}
                oldMessageSubjectTimeInfo={props.oldMessageSubjectTimeInfo}
                setOldMessageSubjectTimeInfo={props.setOldMessageSubjectTimeInfo}
                setMessageBodyTimeFormatChanged={props.setMessageBodyTimeFormatChanged}
                setMessageSubjectTimeFormatChanged={props.setMessageSubjectTimeFormatChanged}
                pointOfContact={props.pointOfContact}
                timeInfoAlert={timeInfoAlert}
                queryParams={queryParams}
                isLastStepLoading={isLastStepLoading}
                setIsLastStepLoading={setIsLastStepLoading}
            />
        </Column>
    );
};

export default OOTOForm;