// Thanks StackOverflow!
const getBrightness = hex => {
  const decimal = parseInt(hex.substring(1), 16) // convert hex (rrggbb) to decimal
  const r = (decimal >> 16) & 0xff // extract red
  const g = (decimal >> 8) & 0xff // extract green
  const b = (decimal >> 0) & 0xff // extract blue
  return 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
}

const isColorDark = hex => getBrightness(hex) < 128

export default isColorDark
