import React, { useState, useMemo } from "react";

import { escapeRegExp } from "lodash";

import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Select, { SelectOption } from "../meridian-custom-components/src/components/select";
import Text from "@amzn/meridian/text";

import { ALERTS, SCREEN_SIZE } from "../../shared/shared-constants";
import { ERRORS } from "../timezones/timezones-constants";

const TimezonePicker = (props) => {
    const timezones = props.timezones;
    const value = props.value;
    const width = props.width;

    const sortTimezonesByUTCOffset = (timezones) => {
        const getUTCOffset = (timezone) => {
            return parseFloat(timezone.displayName.substring(4, timezone.displayName.indexOf(")")).replace(":", ".") || 0);
        };

        const compareUTCOffsets = (timezone1, timezone2) => {
            return getUTCOffset(timezone1) - getUTCOffset(timezone2);
        };

        timezones.sort(compareUTCOffsets);
    };

    sortTimezonesByUTCOffset(timezones);

    const [searchQuery, setSearchQuery] = useState();

    const searchRegExp = useMemo(
        () => new RegExp(escapeRegExp(searchQuery), "i"),
        [searchQuery]
    );

    const matchedTimezoneAltID = (timezoneAltID) => searchRegExp.test(timezoneAltID);

    const matchedTimezones = timezones.filter(
        (timezone) => (!searchQuery || searchRegExp.test(timezone.id)
                                    || searchRegExp.test(timezone.displayName)
                                    || searchRegExp.test(timezone.standardLongName)
                                    || searchRegExp.test(timezone.standardShortName)
                                    || searchRegExp.test(timezone.standardLongNameDateFns)
                                    || searchRegExp.test(timezone.standardShortNameDateFns)
                                    || (timezone.alternativeIDs && timezone.alternativeIDs.some(matchedTimezoneAltID)))
    );

    const TIMEZONE_PICKER_LABEL_ID = "timezone-picker-label-id";

    return ([
        <div style={{display: "none"}}>
            <Text id={TIMEZONE_PICKER_LABEL_ID}>Timezone</Text>
        </div>,
        <Column spacing="none">
            {(value || timezones) ?
                <Select
                    value={value}
                    onChange={props.onChange}
                    searchQuery={searchQuery}
                    onSearch={setSearchQuery}
                    placeholder="Select timezone"
                    width={props.mobileWidth ? props.screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? width : props.mobileWidth : width}
                    size={props.size}
                    aria-labelledby={TIMEZONE_PICKER_LABEL_ID}
                    aria-describedby={[ALERTS.INVALID_TIMEZONE.id, ERRORS.INVALID_TIMEZONE_ID].join(" ")}
                    error={value === ERRORS.INVALID_TIMEZONE}
                >
                    {matchedTimezones.map((timezone) => (
                        <SelectOption
                            value={timezone.id}
                            label={timezone.standardShortNameDateFns ? timezone.standardShortNameDateFns : timezone.standardLongNameDateFns}
                            key={timezone.id}
                        >
                            {() => (
                                <React.Fragment>
                                    {timezone.displayName}
                                </React.Fragment>
                            )}
                        </SelectOption>
                    ))}
                    {matchedTimezones.length === 0 &&
                        <Column
                            alignmentVertical="center"
                            spacing="small"
                            spacingInset="xlarge"
                        >
                            <Text alignment="center">No matching timezones</Text>
                        </Column>
                    }
                </Select>
                :
                <Loader size="small" />
            }
            {value === ERRORS.INVALID_TIMEZONE &&
                <Text id={ERRORS.INVALID_TIMEZONE_ID} type="b100" position="absolute" color="error"><b>Timezone</b> invalid</Text>
            }
        </Column>
    ]);
};

export default TimezonePicker;
