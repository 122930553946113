import React from "react";

import Popover from "@amzn/meridian/popover";

const PollAttendeePopover = (props) => {
    // Inputs
    // const attendee = props.attendee;
    const anchorNode = props.anchorNode;
    const open = props.open;
    const onClose = props.onClose;
    const position = props.position;

    // TODO attendee-user-card
    return (
        <Popover
            anchorNode={anchorNode}
            open={open}
            onClose={onClose}
            position={position}
            alignment="center"
        >
        </Popover>
    );
};

export default PollAttendeePopover;