import { takeEvery, put } from "redux-saga/effects";
import * as Actions from "../actions/action-types";

export function* updateCurrentMeeting(action) {
    yield put({type: Actions.UPDATE_CURRENT_MEETING, meeting: action.meeting});
}

export function* watchUpdateCurrentMeeting() {
    yield takeEvery(Actions.UPDATE_MEETING_ASYNC, updateCurrentMeeting);
}

const sagas = [
    watchUpdateCurrentMeeting()
];

export default sagas;