import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScheduleAvailability } from "../actions";
import {
    getBrowserTimezoneId,
    getIdentity,
    getScheduleAvailabilities,
    isGettingSchedules as isGettingSchedulesSelector,
} from "../../../../sagas/selector";

import addTime from "date-fns/add";
import differenceInMinutes from "date-fns/differenceInMinutes";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";

import Column from "@amzn/meridian/column";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Tag from "@amzn/meridian/tag";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";

import CalendarDayGrid from "../components/calendar-day-grid";
import ArrowDateWrapper from "../components/arrow-date-wrapper";

import BlackStripesBackground from "../../../../assets/backgrounds/black_stripes_small.svg";
import GrayStripesBackground from "../../../../assets/backgrounds/gray_stripes_small.svg";
import { ReactComponent as PersonIcon } from "../../../../assets/icons/people/person.svg";
import { ReactComponent as RoomIcon } from "../../../../assets/icons/locations/room-blue.svg";

import { renderPriorityIcon } from "../../../people/people-utils";
import { ATTENDEE_PRIORITY } from "../../../people/people-constants";
import { shortRoomName } from "../../../shared/locations/locations-utils";
import { STATUS_COLOR, STATUS_HIGHLIGHT_COLOR, STATUS_LABEL, WORKING_HOURS_STATUS_COLOR } from "../../../shared/meeting-status-constants";
import { ATTENDEE_AVAILABILITY, FLOW_TYPE } from "../../../meeting-scheduler/meeting-scheduler-constants";
import { STEP } from "../../../meeting-scheduler/meeting-scheduler-constants";
import { convertEpoch, getDuration } from "../../../meeting-scheduler/meeting-scheduler-utils";
import { getEndTime } from "../custom-calendar-time-grid-utils";
import { determinePrimaryBrowserTimezoneId, timezoneIdToDisplayName } from "../../timezones/timezones-utils";
import { TIMEZONE_DISPLAY_TYPE } from "../../timezones/timezones-constants";
import { STATUS } from "../custom-calendar-time-grid-constants";
import { Noop } from "../../shared-utils";
import { SCREEN_SIZE } from "../../shared-constants";
import { parseDatePickerDateString } from "../../time-utils";
import { zonedTimeToUtc } from "date-fns-tz";
import { DEFAULT_WORKING_HOURS } from "../../settings/settings-constants";
import { getUnixTime } from "date-fns";

const formatIso = (date) => format(date, "yyyy-MM-dd");
const formatDate = (date) => format(date, "MMMM d, yyyy");
const formatDayOfWeek = (date) => format(date, "EEEE");

const CalendarTimeGrid = (props) => {
    const identity = useSelector(getIdentity);
    const schedules = useSelector(getScheduleAvailabilities);
    const isGettingSchedules = useSelector(isGettingSchedulesSelector);
    const browserTimezoneId = useSelector(getBrowserTimezoneId);

    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const flowType = props.flowType;
    const meeting = props.meeting;
    const meetingDetails = props.meetingDetails;
    const timezones = props.timezones;
    const suggestionsTimezoneId = props.suggestionsTimezoneId;
    const attendees = props.attendees;
    const setGridAttendeeAvailability = props.setGridAttendeeAvailability;
    const meetingRooms = meeting?.resources; // List of all chosen rooms and auto suggested rooms
    const openRoomSwapModal = props.openRoomSwapModal;
    const isMultiSelection = props.isMultiSelection;
    const noCalendarHeader = props.noCalendarHeader;
    const gridModeDate = props.gridModeDate;
    const highLightTimeSlot = props.highLightTimeSlot;
    const noLocalTimeZoneInTheHeader = props.noLocalTimeZoneInTheHeader;
    const showWorkingHour = props.showWorkingHour;

    const [isInitialLoad, setIsInitialLoad] = [props.isInitialLoad, props.setIsInitialLoad];
    const [selectedRooms, setSelectedRooms] = [props.selectedRooms, props.setSelectedRooms];

    // The grid header will display the local brower time zone since the header time range is fixed as (0 - 24)
    // The primayTimeZone user set in preferences will show at the first row in the grid table as the suggestion
    const localBrowserTimezoneValue = noLocalTimeZoneInTheHeader ? suggestionsTimezoneId : determinePrimaryBrowserTimezoneId(timezones, browserTimezoneId);
    const localBrowserTimezoneDisplayName = `${timezoneIdToDisplayName(timezones, localBrowserTimezoneValue, TIMEZONE_DISPLAY_TYPE.LONG)} (${timezoneIdToDisplayName(timezones, localBrowserTimezoneValue, TIMEZONE_DISPLAY_TYPE.SHORT)})`;
    const [changedTimeWindow, setChangedTimeWindow] = [props.changedTimeWindow, props.setChangedTimeWindow]; // storing the time that the window has been updated to

    const dispatch = useDispatch();
    const timeSlider = useRef(null);
    const currentHourBlock = useRef(null);

    const [refocusSlider, setRefocusSlider] = useState(true);
    const [dragging, setDragging] = useState(undefined); // determine if the time window is currently being dragged

    // Meeting Suggestions
    const meetingSuggestions = props.meetingSuggestions;
    const meetingSuggestionSelected = props.meetingSuggestionSelected === undefined ? 0 : props.meetingSuggestionSelected;
    const isExistingMeetingSelected = props.isExistingMeetingSelected;
    const [prevIsExistingMeetingSelected, setPrevIsExistingMeetingSelected] = useState(isExistingMeetingSelected);
    const onChangeSelectSuggestion = props.onChangeSelectSuggestion;
    const onSelectSuggestion = props.onSelectSuggestion;

    const [resetSuggestion, setResetSuggestion] = useState(false);

    // Array containing list of previous queries to getSchedule
    const [prevGetScheduleQuery, setPrevGetScheduleQuery] = [props.prevGetScheduleQuery, props.setPrevGetScheduleQuery];

    // Start time initialization, based on the suggestion selected
    const [previousSuggestion, setPreviousSuggestion] = useState(meetingSuggestionSelected);
    const currentMeetingSuggestion = meetingSuggestions.length && meetingSuggestions[meetingSuggestionSelected];
    const [selectedStartTime, setSelectedStartTime] = useState(changedTimeWindow ||
        (isExistingMeetingSelected ?
            new Date(meetingDetails.time.startTime * 1000)
            :
            currentMeetingSuggestion ? new Date(currentMeetingSuggestion.startTime * 1000) : new Date())
    );

    // Meeting duration
    const duration = props.customDurationValue && getDuration(props.durationSelected, props.customDurationValue, props.customDurationSelected);
    const [prevDuration, setPrevDuration] = [props.prevDuration, props.setPrevDuration];

    const timeSelectorRenderHour = (groupId, hour) => {
        return (
            <Row width="100%" spacingInset="none" spacing="none">
                {groupId}-{hour}
            </Row>
        );
    };

    const timeSelectorRenderGroupHeader = () => {
        return (
            <Row alignmentHorizontal="right" width="100%" spacingInset="xsmall small">
                <Tag type={getAttendeeAvailabilityType(currentMeetingSuggestion, freeAttendeesCount)}>{freeAttendeesCount === getTotalAttendees(currentMeetingSuggestion) ? "All attendees free" : freeAttendeesCount + " of " + getTotalAttendees(currentMeetingSuggestion) + " attendees free"}</Tag>
            </Row>
        );
    };

    const timeSelectorOptions = {
        renderHeader: timeSelectorRenderGroupHeader,
        renderHour: timeSelectorRenderHour,
        selectedStartTime: selectedStartTime,
        setSelectedStartTime: setSelectedStartTime,
        duration: props.durationSelected,
        setDuration: props.setDurationSelected,
        customDurationValue: props.customDurationValue,
        setCustomDurationValue: props.setCustomDurationValue,
        customDurationSelected: props.customDurationSelected,
        setCustomDurationSelected: props.setCustomDurationSelected,
        setCustomDurationLabel: props.setCustomDurationLabel,
        currentMeetingSuggestion: currentMeetingSuggestion,
        onSelectSuggestion: onSelectSuggestion,
        sliderRef: timeSlider,
        currentHourBlockRef: currentHourBlock,
    };

    const updateDuration = (newDuration) => {
        timeSelectorOptions.setDuration("custom");
        timeSelectorOptions.setCustomDurationValue(Math.round(newDuration * 60));
        timeSelectorOptions.setCustomDurationSelected("min");
    };

    const getAttendeeTimezone = (attendee) => {
        // user's preferenceTimezone > user's exchange timezone > default timezone
        return attendee?.workingHours?.preferenceTimezone ?? attendee?.timezoneId ?? undefined;
    };

    // Handle changing the selected meeting suggestion, resetting a suggestion, or changing to a suggestion
    if (!isExistingMeetingSelected && (meetingSuggestionSelected !== previousSuggestion || resetSuggestion || prevIsExistingMeetingSelected)) {
        setPrevIsExistingMeetingSelected(false);
        setPreviousSuggestion(meetingSuggestionSelected);
        setSelectedStartTime((!resetSuggestion && changedTimeWindow) || new Date(currentMeetingSuggestion?.startTime * 1000));

        props.setDurationSelected("custom");
        const suggestionDurationInMinutes = (currentMeetingSuggestion.endTime - currentMeetingSuggestion.startTime) / 60;
        props.setCustomDurationValue(suggestionDurationInMinutes);
        props.setCustomDurationSelected("min");
        props.setCustomDurationLabel(`${suggestionDurationInMinutes} mins`);

        if (resetSuggestion) {
            onSelectSuggestion({
                ...currentMeetingSuggestion,
                startTime: currentMeetingSuggestion.startTime,
                endTime: currentMeetingSuggestion.endTime,
            });
            setChangedTimeWindow(undefined);
        }

        setResetSuggestion(false);
        setRefocusSlider(true);
    }

    // Handle when resetting existing meeting and switching from a suggestion to the existing meeting
    if (isExistingMeetingSelected && (resetSuggestion || !prevIsExistingMeetingSelected)) {
        setPrevIsExistingMeetingSelected(true);
        setSelectedStartTime(new Date(meetingDetails.time.startTime * 1000));

        if (resetSuggestion) {
            setChangedTimeWindow(undefined);
            const startTime = new Date(meetingDetails.time.startTime * 1000);
            const endTime = new Date(meetingDetails.time.endTime * 1000);
            const currentMeetingDuration = differenceInMinutes(endTime, startTime);
            updateDuration(currentMeetingDuration / 60);
            onSelectSuggestion({
                ...currentMeetingSuggestion,
                startTime: meetingDetails.time.startTime ,
                endTime: meetingDetails.time.endTime,
            });
        }

        setResetSuggestion(false);
        setRefocusSlider(true);
    }

    let newStartDate = selectedStartTime;
    let newStartTime = startOfDay(parse(formatIso(newStartDate), "yyyy-MM-dd", new Date()));

    if (gridModeDate !== undefined && suggestionsTimezoneId !== undefined) {
        const newDate = parseDatePickerDateString(gridModeDate);
        // start time of the current date
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newStartDate = newDate;
        newStartTime = zonedTimeToUtc(newDate, suggestionsTimezoneId);
    }

    const date = formatIso(selectedStartTime);
    const dateLabel = formatDate(selectedStartTime);
    const dayOfWeekLabel = formatDayOfWeek(selectedStartTime);
    const startTime = newStartTime;
    const endTime = addTime(newStartTime, {days: 1});
    const daySchedules = schedules && schedules[startTime.getTime()];

    // Conf rooms
    const prevRooms = props.prevRooms;
    const prevLocations = props.prevLocations;

    // Details about users, conf rooms and timezones
    let mailboxDetails = {};
    let attendeeMailboxes = [];
    let roomMailboxes = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let combinedMailboxes = []; // Combined list of attendees and rooms to get availability schedules
    let additionalTimezones = [];

    attendees.forEach((attendee) => {
        const attendeeTimezoneId = showWorkingHour ? getAttendeeTimezone(attendee) : attendee.timezoneId;
        mailboxDetails[attendee.email] = {
            ...attendee,
            displayName: attendee.name,
            timezoneId: attendeeTimezoneId
        };

        // Grid mode timezone is using suggestionsTimezone instead of local time zone
        const primaryTimezoneId = noLocalTimeZoneInTheHeader ? suggestionsTimezoneId : browserTimezoneId;

        attendeeMailboxes.push(attendee.email);
        if (attendeeTimezoneId &&
            attendeeTimezoneId !== primaryTimezoneId &&
            !additionalTimezones.some((tz) => tz.timezoneId === attendeeTimezoneId)) {
            additionalTimezones.push({
                timezoneId: attendeeTimezoneId,
                displayName: `${timezoneIdToDisplayName(timezones, attendeeTimezoneId, TIMEZONE_DISPLAY_TYPE.LONG)} (${timezoneIdToDisplayName(timezones, attendeeTimezoneId, TIMEZONE_DISPLAY_TYPE.SHORT)})`
            });
        }
    });
    // If a timezone different than the user's got selected, add it to the list so we can show both local and selected timezone's times
    if (suggestionsTimezoneId !== browserTimezoneId && !additionalTimezones.some((tz) => tz.timezoneId === suggestionsTimezoneId)) {
        let suggestionsTimezoneDisplayName = `${timezoneIdToDisplayName(timezones, suggestionsTimezoneId, TIMEZONE_DISPLAY_TYPE.LONG)} (${timezoneIdToDisplayName(timezones, suggestionsTimezoneId, TIMEZONE_DISPLAY_TYPE.SHORT)})`;

        if (suggestionsTimezoneDisplayName !== localBrowserTimezoneDisplayName) {
            additionalTimezones.push({
                timezoneId: suggestionsTimezoneId,
                displayName: suggestionsTimezoneDisplayName
            });
        }
    }

    if (prevRooms !== undefined && prevRooms.length > 0) {
        prevRooms.forEach((room) => {
            mailboxDetails[room.email] = {
                email: room.email,
                displayName: room.name,
                priority: ATTENDEE_PRIORITY.REQUIRED,
            };

            roomMailboxes.push(room.email);
        });
    }

    currentMeetingSuggestion && currentMeetingSuggestion.suggestionRoomList.forEach((roomList, roomListIndex) => {
        if (roomList.length) {
            let currentRoom = roomList[selectedRooms[currentMeetingSuggestion.startTime + "-" + roomListIndex] || 0].room;

            mailboxDetails[currentRoom.email] = {
                email: currentRoom.email,
                displayName: currentRoom.name,
                roomListIndex: roomListIndex,
                priority: ATTENDEE_PRIORITY.AUTO_SUGGEST,
            };

            roomMailboxes.push(currentRoom.email);
        }
    });

    combinedMailboxes = attendeeMailboxes.concat(roomMailboxes);

    // Get the number of attendees for the given array
    const getAttendeeCount = (attendeeArray) => {
        let total = 0;
        attendees.forEach((attendee) => {
            if (attendeeArray.includes(attendee.email)) {
                total += 1;
            }
        });
        return total;
    };

    // Get the total number of attendees for a suggestion
    const getTotalAttendees = (suggestion) => {
        let total = 0;
        attendees.forEach((attendee) => {
            if (suggestion?.freePeople?.includes(attendee.email) || suggestion?.tentativePeople?.includes(attendee.email) || suggestion?.unavailablePeople?.includes(attendee.email)) {
                total += 1;
            }
        });
        return total;
    };

    // Get availability tag styling based on percentage of available attendees
    const getAttendeeAvailabilityType = (suggestion, freeAttendeesCount) => {
        let free = freeAttendeesCount !== undefined ? freeAttendeesCount : getAttendeeCount(suggestion.freePeople);
        let total = getTotalAttendees(suggestion);
        let availability = parseInt((free / total) * 100);

        if (availability > ATTENDEE_AVAILABILITY.SUCCESS) {
            return "success";
        } else if (availability > ATTENDEE_AVAILABILITY.WARNING) {
            return "warning";
        } else {
            return "error";
        }
    };

    const [freeAttendeesCount, setFreeAttendeesCount] = [props.freeAttendeesCount, props.setFreeAttendeesCount];

    // Update attendee availability based on the current position of the time window
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateAttendeeAvailabilityTag = (startTime = selectedStartTime, hours = duration, attendees = attendeeMailboxes) => {
        let startIndex = startTime.getHours() * 4 + parseInt(startTime.getMinutes() / 15);
        let endIndex = startIndex + Math.ceil(hours * 60 / 15);

        if (daySchedules) {
            let freeAttendeesCount = 0;
            let freePeople = [];
            let tentativePeople = [];
            let unavailablePeople = [];

            // check if each attendee is free during the time window
            Object.keys(daySchedules).forEach((schedule) => {
                if (attendees.includes(schedule)) {
                    let availability = daySchedules[schedule].availability.slice(startIndex, endIndex);
                    if (availability.every((status) => status === STATUS.FREE)) {
                        freeAttendeesCount += 1;
                        freePeople.push(schedule);
                    } else if (availability.some((status) => status === STATUS.BUSY) || availability.some((status) => status === STATUS.OOTO)) {
                        unavailablePeople.push(schedule);
                    } else if (availability.some((status) => status === STATUS.TENTATIVE)) {
                        tentativePeople.push(schedule);
                    } else { // for no data so unknown
                        unavailablePeople.push(schedule);
                    }
                }
            });
            setFreeAttendeesCount(freeAttendeesCount);
            setGridAttendeeAvailability({
                freePeople: freePeople,
                tentativePeople: tentativePeople,
                unavailablePeople: unavailablePeople
            });
        }
    };

    // Return an aria label containing a summarized availability for attendees based on the focused time and duration
    const calculateAttendeeAvailabilitySummary = (startTime = selectedStartTime, hours = duration, attendees = attendeeMailboxes) => {
        let summary = "";

        let startIndex = startTime.getHours() * 4 + parseInt(startTime.getMinutes() / 15);
        let endIndex = startIndex + Math.ceil(hours * 60 / 15);

        if (daySchedules) {
            let totalAttendeesCount = 0;
            let freeAttendeesCount = 0;
            let freePeople = [];

            // check if each attendee is free during the time window
            Object.keys(daySchedules).forEach((schedule) => {
                if (attendees.includes(schedule)) {
                    let availability = daySchedules[schedule].availability.slice(startIndex, endIndex);
                    if (availability.every((status) => status === STATUS.FREE)) {
                        freeAttendeesCount++;
                        freePeople.push(schedule);
                    }
                    totalAttendeesCount++;
                }
            });

            if (freeAttendeesCount === 0) {
                summary = "No attendees free";
            } else if (totalAttendeesCount === freeAttendeesCount) {
                summary = "All attendees free";
            } else {
                summary = `${freeAttendeesCount} of ${totalAttendeesCount} attendees free: ${freePeople.join(", ")}`;
            }
        }

        return summary;
    };

    // Initialize selected rooms for each suggestion to the first room
    useEffect(() => {
        if (identity && identity.username && isInitialLoad && meetingSuggestions.length && prevLocations) {
            let rooms = {};

            meetingSuggestions.forEach((suggestion) => {
                suggestion.suggestionRoomList.forEach((roomList, roomListIndex) => {
                    rooms[suggestion.startTime + "-" + roomListIndex] = 0;
                });
            });

            if (flowType === FLOW_TYPE.CREATE) {
                onChangeSelectSuggestion(meetingSuggestions[0], rooms);
            }

            setSelectedRooms(rooms);
            setIsInitialLoad(false);
        }
    }, [identity, isInitialLoad, meetingSuggestions, flowType, prevLocations, setSelectedRooms, setIsInitialLoad, onChangeSelectSuggestion]);

    // Obtain availability schedules for all attendees and selected rooms
    useEffect(() => {
        if (identity && identity.username) {
            let isMailboxMissing = false;

            // If any mailbox schedules are missing, do another getSchedules call
            if (daySchedules) {
                const dayScheduleMailboxes = Object.keys(daySchedules);

                combinedMailboxes.forEach((mailbox) => {
                    if (!dayScheduleMailboxes.includes(mailbox)) {
                        isMailboxMissing = true;
                    }
                });
            }

            // Check if the current mailboxes/time have been searched for
            const isQueryNew = prevGetScheduleQuery ? !(
                JSON.stringify(prevGetScheduleQuery.combinedMailboxes.sort()) === JSON.stringify(combinedMailboxes.sort()) &&
                prevGetScheduleQuery.startTime.getTime() === startTime.getTime() &&
                prevGetScheduleQuery.endTime.getTime() === endTime.getTime()
            ) : true;

            if (!isGettingSchedules && ((isObjectEmpty(daySchedules) || isMailboxMissing) && isQueryNew)) {
                if (combinedMailboxes?.length > 0 && combinedMailboxes.every(mailbox => mailbox !== undefined)) {
                    dispatch(getScheduleAvailability(
                        15,
                        combinedMailboxes,
                        startTime,
                        endTime,
                    ));
                }

                // add current query to list of obtained queries to avoid duplicate API calls
                setPrevGetScheduleQuery({combinedMailboxes, startTime, endTime});
            }
        }
    }, [dispatch, isGettingSchedules, attendees, startTime, endTime, identity, daySchedules, currentMeetingSuggestion, meeting, combinedMailboxes, prevGetScheduleQuery, setPrevGetScheduleQuery]);

    // Update the meeting time if the duration has been changed
    useEffect(() => {
        if (prevDuration !== duration && dragging === undefined) {
            onSelectSuggestion({
                ...currentMeetingSuggestion,
                startTime: selectedStartTime.getTime() / 1000,
                endTime: getEndTime(selectedStartTime.getTime(), duration).getTime() / 1000,
            });

            setPrevDuration(duration);
            updateAttendeeAvailabilityTag(undefined, duration);
        }
    }, [prevDuration, duration, onSelectSuggestion, selectedStartTime, currentMeetingSuggestion, setPrevDuration, dragging, updateAttendeeAvailabilityTag]);

    // If the availability schedules change, update the availability tag
    useEffect(() => {
        updateAttendeeAvailabilityTag();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daySchedules]);

    // Focus the view to the position of the time slider
    useEffect(() => {
        if (refocusSlider) {
            if (isMultiSelection) {
                currentHourBlock.current && currentHourBlock.current.scrollIntoView({block: "nearest", inline: "center"});
            } else {
                timeSlider.current && timeSlider.current.scrollIntoView({block: "nearest", inline: "center"});
            }
            setRefocusSlider(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refocusSlider]);

    const renderFirstCell = () => {
        return (
            <Row alignmentHorizontal={screenSizeBreakpoint <= SCREEN_SIZE.BREAKPOINT.SM ? "left" : "right"} alignmentVertical="center" width="100%">
                <Text type="h100">{localBrowserTimezoneDisplayName}</Text>
            </Row>
        );
    };

    const renderHeader = (hour) => {
        return (
            <Row alignmentHorizontal="left" alignmentVertical="center" width="100%">
                <Text type="h200">
                    {hour}
                </Text>
            </Row>
        );
    };

    const renderGroupHeader = (groupId) => {
        return (mailboxDetails[groupId] ?
            <Row alignmentVertical="center" width="100%" height="36px" widths={["fit", "fit", "fill", "fit"]} spacing="small" spacingInset="small">
                {renderPriorityIcon(mailboxDetails[groupId].priority, false)}
                <Row spacing="none" spacingInset="none" width="200px">
                    <Text type="b300" truncate={true}>{mailboxDetails[groupId].displayName}</Text>
                </Row>
                <span />
                <Text type="b100" color="secondary">
                    {mailboxDetails[groupId].timezoneId !== suggestionsTimezoneId ?
                        timezoneIdToDisplayName(timezones, mailboxDetails[groupId].timezoneId, TIMEZONE_DISPLAY_TYPE.SHORT)
                        :
                        ""
                    }
                </Text>
            </Row>
            :
            <Row alignmentVertical="center" width="100%" height="36px">
                {groupId}
            </Row>
        );
    };

    const renderSectionHeader = (sectionLabel, action) => {
        return (
            <Row alignmentVertical="center" width="100%" height="24px" widths={["fit", "fill", "fit"]} spacingInset="small">
                <Text type="h100">{sectionLabel}</Text>
                {action &&
                    <React.Fragment>
                        <span />
                        <div style={{cursor: "pointer", verticalAlign: "middle"}}>
                            <Text type="b100" tag="span">
                                <Link type="secondary" onClick={action}>Update</Link>
                            </Text>
                            {sectionLabel.startsWith("Attendees") && <PersonIcon height="12px" width="12px" onClick={action} style={{paddingLeft: "4px"}} />}
                            {sectionLabel.startsWith("Rooms") && <RoomIcon height="12px" width="12px" onClick={action} style={{paddingLeft: "4px"}} />}
                        </div>
                    </React.Fragment>
                }
            </Row>
        );
    };

    const renderRoomGroupHeader = (groupId) => {
        return (mailboxDetails[groupId] ?
            <Row alignmentVertical="center" width="100%" height="36px" widths={["fit", "fit", "fill", "fit"]} spacing="small" spacingInset="small">
                {renderPriorityIcon(mailboxDetails[groupId].priority, false)}
                <Row spacing="none" spacingInset="none" width="200px">
                    <Text type="b300" truncate={true}>{shortRoomName(mailboxDetails[groupId].displayName)}</Text>
                </Row>
                <span />
                {mailboxDetails[groupId].hasOwnProperty("roomListIndex") &&
                    <Text type="b100">
                        <Link type="secondary" onClick={() => {openRoomSwapModal(currentMeetingSuggestion.startTime, meetingSuggestionSelected, mailboxDetails[groupId].roomListIndex)}}>Change</Link>
                    </Text>
                }
            </Row>
            :
            <Row alignmentVertical="center" width="100%" height="36px">
                {groupId}
            </Row>
        );
    };

    const getStatusColorAndLabel = (groupId, hour, index) => {
        if (isObjectEmpty(daySchedules)) {
            return [STATUS_COLOR.NO_DATA, STATUS_HIGHLIGHT_COLOR.NO_DATA, STATUS_LABEL.noData];
        }
        const statusNumber = daySchedules[groupId] ? daySchedules[groupId].availability[(parseInt(hour) * 4) + index] : 4;
        switch (statusNumber) {
            case 0: // Free
                return [STATUS_COLOR.GRID_FREE, STATUS_HIGHLIGHT_COLOR.GRID_FREE, STATUS_LABEL.gridFree];
            case 1: // Tentative
                return [STATUS_COLOR.TENTATIVE, STATUS_HIGHLIGHT_COLOR.TENTATIVE, STATUS_LABEL.tentative];
            case 2: // Busy
                return [STATUS_COLOR.BUSY, STATUS_HIGHLIGHT_COLOR.BUSY, STATUS_LABEL.busy];
            case 3: // OOTO
                return [STATUS_COLOR.OOTO, STATUS_HIGHLIGHT_COLOR.OOTO, STATUS_LABEL.outOfOffice];
            case 4: // No data
            default:
                return [STATUS_COLOR.NO_DATA, STATUS_HIGHLIGHT_COLOR.NO_DATA, STATUS_LABEL.noData];
        }
    };

    const renderAttendeeWorkingHourBackground = (groupId, hour) => {
        const attendee = attendees.find((data) => data.email === groupId);
        const attendeeWorkingHours = attendee?.workingHours ?? DEFAULT_WORKING_HOURS;
        const attendeeTimezone = getAttendeeTimezone(attendee) || suggestionsTimezoneId;

        const currentGridModeDate = gridModeDate !== undefined ? parseDatePickerDateString(gridModeDate) : selectedStartTime;
        currentGridModeDate.setHours(hour);

        const currentEpochTime = getUnixTime(zonedTimeToUtc(currentGridModeDate, suggestionsTimezoneId));
        const attendeeCurrentWeekDay = convertEpoch(currentEpochTime, "shortWeekday", attendeeTimezone);
        const attendeeCurrentHour = parseInt(convertEpoch(currentEpochTime, "hour", attendeeTimezone, false).split(":")[0]);

        const [workingHoursStartTimeHour, workingHoursStartTimeMinute] = attendeeWorkingHours.startTime.split(":").map((data) => parseInt(data));
        const [workingHoursEndTimeHour, workingHoursEndTimeMinute] = attendeeWorkingHours.endTime.split(":").map((data) => parseInt(data));

        if (attendeeWorkingHours.days.includes(attendeeCurrentWeekDay)) {
            // only half block is within the working hours, eg: workingHour (09:30 - 17:00) currentHour 09:00
            if (workingHoursStartTimeMinute === 30 && attendeeCurrentHour <= workingHoursStartTimeHour && workingHoursStartTimeHour < (attendeeCurrentHour + 1)) {
                return `linear-gradient(90deg, ${WORKING_HOURS_STATUS_COLOR.OUT} 50%, ${WORKING_HOURS_STATUS_COLOR.IN} 50%)`;
            }
            // only half block is within the working hours, eg: workingHour (09:30 - 17:30) currentHour 17:00
            if (workingHoursEndTimeMinute === 30 && attendeeCurrentHour <= workingHoursEndTimeHour && workingHoursEndTimeHour < (attendeeCurrentHour + 1)) {
                return `linear-gradient(90deg, ${WORKING_HOURS_STATUS_COLOR.IN} 50%, ${WORKING_HOURS_STATUS_COLOR.OUT} 50%)`;;
            }
            // the current full block is within the working hours, eg: workingHour (09:00 - 17:00) currentHour 10:00
            if (workingHoursStartTimeHour <= attendeeCurrentHour && attendeeCurrentHour + 1 <= workingHoursEndTimeHour) {
                return WORKING_HOURS_STATUS_COLOR.IN;
            }
        }

        return WORKING_HOURS_STATUS_COLOR.OUT;
    };

    const renderHour = (groupId, hour) => {
        const hourStartTimes = ["0", "15", "30", "45"];
        const hourStartColorsAndLabel = hourStartTimes.map((hourStart, index) => getStatusColorAndLabel(groupId, hour, index));
        // Use prev hour color and next hour color to check if they are continuous block
        let prevHourColor, nextHourColor;

        if (hour > 0) {
            prevHourColor = getStatusColorAndLabel(groupId, hour - 1, 3)[0];
        }

        if (hour < 23) {
            nextHourColor = getStatusColorAndLabel(groupId, hour + 1, 0)[0];
        }

        const hourBlockWidths = []; // widths of continuous status blocks
        const hourBlockColorsAndLabel = []; // colors and label of continuous status blocks

        let currentWidth = 25;

        // Compare the current time's status with the next to determine block sizes and colors
        for (let time = 0; time < hourStartTimes.length; time++) {
            if (time === hourStartTimes.length - 1 || hourStartColorsAndLabel[time][0] !== hourStartColorsAndLabel[time + 1][0]) {
                hourBlockWidths.push(currentWidth);
                hourBlockColorsAndLabel.push(hourStartColorsAndLabel[time]);

                currentWidth = 25;
            } else {
                currentWidth += 25;
            }
        }
        return (
            <Row width="100%" spacingInset="none" spacing="none">
                {hourBlockWidths.map((block, index) => {
                    let backgroundStripes;
                    switch (hourBlockColorsAndLabel[index][0]) {
                        case STATUS_COLOR.TENTATIVE:
                            backgroundStripes = BlackStripesBackground;
                            break;
                        case STATUS_COLOR.NO_DATA:
                            backgroundStripes = GrayStripesBackground;
                            break;
                        default:
                            backgroundStripes = "";
                    }

                    // Default css styles for each block
                    let borderRadius = ["5px", "5px", "5px", "5px"];
                    let isFirstBlock = true;
                    if (index === 0) {
                        // If the first block has the same color as the previous hour then clear css styles
                        if (prevHourColor && prevHourColor === hourBlockColorsAndLabel[index][0]) {
                            borderRadius[0] = "0";
                            borderRadius[3] = "0";
                            isFirstBlock = false;
                        }
                    }

                    if (index === hourBlockWidths.length - 1) {
                        // If the last block has the same color as the next hour then clear css styles
                        if (nextHourColor && nextHourColor === hourBlockColorsAndLabel[index][0]) {
                            borderRadius[1] = "0";
                            borderRadius[2] = "0";
                        }
                    }

                    // Set opacity here to show workingHours
                    const opacity = hourBlockColorsAndLabel[index][0] === STATUS_COLOR.GRID_FREE ? "0" : "1";

                    return (
                        <Tooltip
                            position="bottom"
                            title={hourBlockColorsAndLabel[index][2]}
                        >
                            <div
                                style={{
                                    backgroundColor: hourBlockColorsAndLabel[index][0],
                                    backgroundImage: `url(${backgroundStripes})`,
                                    backgroundRepeat: "no-repeat",
                                    width: `${block}%`,
                                    height: showWorkingHour ? "30px" : "40px",
                                    borderRadius: borderRadius.join(" "),
                                    opacity: opacity
                                }}
                                key={`hour-${block}-${index}`}
                            >
                                {isFirstBlock && hourBlockColorsAndLabel[index][0] !== STATUS_COLOR.NO_DATA &&
                                    <div style={{
                                        width: "10px",
                                        height: "100%",
                                        backgroundColor: hourBlockColorsAndLabel[index][1],
                                        borderRadius: "5px 0 0 5px",
                                        border: (hourStartColorsAndLabel[index][0] === STATUS_COLOR.FREE ||  hourStartColorsAndLabel[index][0] === STATUS_COLOR.CANCELED) ? "0.5px solid #E7E9E9" : "0"
                                    }}/>
                                }
                            </div>
                        </Tooltip>
                    );
                })}
            </Row>
        );
    };

    const onChangeRecurrenceTime = props.onChangeRecurrenceTime || Noop;

    const setNextDayDate = () => {
        setDayDateHelper(1);
    };

    const setPreviousDayDate = () => {
        setDayDateHelper(-1);
    };

    const setDayDateHelper = (addedDays) => {
        const newSelectedStartTime = addTime(selectedStartTime,  {days: addedDays});
        const startTime = newSelectedStartTime.getTime() / 1000;

        onSelectSuggestion({
            ...currentMeetingSuggestion,
            startTime: startTime,
            endTime: getEndTime(newSelectedStartTime.getTime(), duration).getTime() / 1000,
        });

        setSelectedStartTime(newSelectedStartTime);
        setChangedTimeWindow(newSelectedStartTime);

        onChangeRecurrenceTime(startTime);
    };

    const sections = [
        {
            label: `Attendees (${attendees.length})`,
            members: attendees.map((attendee) => attendee.email),
            renderGroupHeader: renderGroupHeader,
            action: isMultiSelection ? undefined : () => {
                props.onChangeStep(STEP.ATTENDEES_AND_ROOMS);
            }
        }
    ];
    if (meetingRooms?.length) {
        sections.push(
            {
                label: `Rooms (${meetingRooms.length})`,
                members: meetingRooms.map((room) => room.email),
                renderGroupHeader: renderRoomGroupHeader,
                action: isMultiSelection ? undefined : () => {
                    props.onChangeStep(STEP.ATTENDEES_AND_ROOMS);
                }
            }
        );
    }

    // If there are no suggestions render an empty Row to keep the same height added by the list view
    if (!meetingSuggestions.length && !props.isMultiSelection) {
        return <Row><span /></Row>;
    }

    return (
        <div style={{
            width: "100%",
            border: "0px",
        }}>
            {!noCalendarHeader &&
                <Row alignmentHorizontal="center" widths={["fit", "fill", "fit", "fill", "fit"]} width="100%" backgroundColor={"white"} spacing="none" spacingInset="small" wrap="down">
                    <ArrowDateWrapper setPreviousDayDate={setPreviousDayDate} setNextDayDate={setNextDayDate} isLoading={isGettingSchedules}>
                        <Column width="200px" spacing="none" alignmentHorizontal="center">
                            <Text type="h200">{dateLabel}</Text>
                            <Row spacing="small">
                                <Text type="b200" color="secondary">{dayOfWeekLabel}</Text>
                                {isGettingSchedules &&
                                    <Loader size="small" />
                                }
                            </Row>
                        </Column>
                    </ArrowDateWrapper>
                    <Link type="secondary" onClick={() => {setResetSuggestion(true)}}>Reset</Link>
                </Row>
            }
            <div style={{
                width: "100%",
                overflow: "auto"
            }}>
                <Row>
                    <CalendarDayGrid
                        screenSizeBreakpoint={screenSizeBreakpoint}
                        showWorkingHour={showWorkingHour}
                        isMultiSelection={isMultiSelection}
                        updateAttendeeAvailabilityTag={updateAttendeeAvailabilityTag}
                        calculateAttendeeAvailabilitySummary={calculateAttendeeAvailabilitySummary}
                        setChangedTimeWindow={setChangedTimeWindow}
                        spansMultipleDates={props.spansMultipleDates}
                        renderHeader={renderHeader}
                        renderGroupHeader={renderGroupHeader}
                        renderHour={renderHour}
                        renderFirstCell={renderFirstCell}
                        groupColumnWidth={screenSizeBreakpoint <= SCREEN_SIZE.BREAKPOINT.SM ? 180 : 310}
                        sections={sections}
                        renderSectionHeader={renderSectionHeader}
                        renderAttendeeWorkingHourBackground={renderAttendeeWorkingHourBackground}
                        date={date}
                        timeSelectorOptions={timeSelectorOptions}
                        startHour={0}
                        endHour={23}
                        additionalTimezones={additionalTimezones}
                        dragging={dragging}
                        setDragging={setDragging}
                        updateDuration={updateDuration}
                        prevDuration={prevDuration}
                        duration={duration}
                        meetingSuggestions={meetingSuggestions}
                        timeSlotsSelected={props.timeSlotsSelected}
                        onChangeTimeSlotsSelected={props.onChangeTimeSlotsSelected}
                        highLightTimeSlot={highLightTimeSlot}
                        setRefocusSlider={setRefocusSlider}
                        gridModeDate={gridModeDate}
                        gridModeTimeZone={suggestionsTimezoneId}
                    />
                </Row>
            </div>
        </div>
    );
};

const isObjectEmpty = (object) => {
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }

    return true;
};

export default CalendarTimeGrid;
