import * as Actions from "./action-types";

export const checkInRoom = (source, meetings, roomDisplayNames, refocusInfo) => {
    return {type: Actions.CHECK_IN_ROOM, source, meetings, roomDisplayNames, refocusInfo};
};

export const releaseRoom = (source, meetingStartTime, roomEmail) => {
    return {type: Actions.RELEASE_ROOM, source, meetingStartTime, roomEmail};
};

export const getRoomEligibility = (rooms) => {
    return {type: Actions.GET_ROOM_ELIGIBILITY, rooms};
};

export const reclaimResources = (requestedOnBehalfOf, uniqueOrEntryID, resources) => {
    return {type: Actions.RECLAIM_RESOURCES, requestedOnBehalfOf, uniqueOrEntryID, resources};
};

export const checkInAndReclaimResources = (checkInSources, checkInMeetings, checkInRoomDisplayNames, releaseRequestedOnBehalfOf, releaseMeetingUniqueOrEntryID, releaseResources, refocusInfo) => {
    return {type: Actions.CHECK_IN_AND_RECLAIM_RESOURCES, checkInSources, checkInMeetings, checkInRoomDisplayNames, releaseRequestedOnBehalfOf, releaseMeetingUniqueOrEntryID, releaseResources, refocusInfo};
};

export const getCheckInStatus = (meetings) => {
    return {type: Actions.GET_ROOM_CHECK_IN_STATUS, meetings};
};

export const setCheckInStatus = (roomCheckInStatusDictionary = new Map()) => {
    return {type: Actions.SET_ROOM_CHECK_IN_STATUS, roomCheckInStatusDictionary};
};

export const setRoomEligibility = (roomEligibilityDictionary = new Map()) => {
    return {type: Actions.SET_ROOM_ELIGIBILITY, roomEligibilityDictionary};
};