import React, { useRef, useCallback, useEffect, useMemo } from "react"
import keycode from "keycode"
import { css, cx } from "emotion"
import PropTypes from "prop-types"
import sheetTokens from "@amzn/meridian-tokens/component/sheet"
import modalTokens from "@amzn/meridian-tokens/component/modal"
import scrimTokens from "@amzn/meridian-tokens/component/scrim"
import { useTheme } from "../theme"
import ExpanderController from "../_expander-controller"
import Scrim from "../_scrim"
import Portal from "../portal"
import FocusTrap from "../_focus-trap"
import useFocus from "../_use-focus"
import { getKeyboardFocusNodes } from "../../_utils/focus"
import { getToken, memoizeTokenStyles } from "../../_utils/token"
import { parseSpacing } from "../../_prop-types/parsers"
import filterPropsByPrefix from "../../_utils/filter-props-by-prefix"
import { getRtl } from "../../_utils/rtl"

const borderSides = {
  left: "borderRight",
  right: "borderLeft",
  bottom: "borderTop",
}

const containerStyles = memoizeTokenStyles(
  (t, { side, spacingInset, backgroundColor }) =>
    css({
      [borderSides[side]]: t.borderCss("border"),
      [`[dir="rtl"] &`]: {
        [getRtl(borderSides[side])]: t.borderCss("border"),
      },
      "& > div": {
        backgroundColor,
        padding: `${parseSpacing(spacingInset)}px`,
        outline: "none",
        overflowY: "auto",
        boxSizing: "border-box",
        maxHeight:
          side === "bottom"
            ? `calc(100vh - ${t("minimumPageReveal")}px)`
            : undefined,
      },
    }),
  ["side", "spacingInset", "backgroundColor"]
)

const mediaQuery = floatingBreakpoint =>
  `@media (min-width: ${floatingBreakpoint}px)`
const modalStyles = (tScrim, tModal, t, { side }) => {
  return css(
    {
      zIndex: tScrim("elevation") + 1,
      [mediaQuery(tModal.tokens.modalFloatingBreakpoint)]: {
        zIndex: tScrim("elevation") - 1,
      },
      position: "fixed",
      boxShadow: `${t("shadowOffset", [side, "x"])}px ${t("shadowOffset", [
        side,
        "y",
      ])}px ${t("shadow", "blur")}px ${t("shadow", "spread")}px ${t(
        "shadow",
        "color"
      )}}`,
    },
    side === "bottom"
      ? {
          bottom: 0,
          left: 0,
          right: 0,
        }
      : {
          top: 0,
          bottom: 0,
          [side]: 0,
          [`[dir="rtl"] &`]: {
            [side]: "auto",
            [getRtl(side)]: 0,
          },
        }
  )
}

const overlayScrimStyles = ({ tScrim, tModal }) =>
  css({
    zIndex: tScrim("elevation"),
    [mediaQuery(tModal.tokens.modalFloatingBreakpoint)]: {
      zIndex: tScrim("elevation") - 2,
    },
  })

// Overlay expanders are put into a portal
const OverlayExpanderController = props => (
  <Portal>
    <ExpanderController {...props} />
  </Portal>
)

/**
 * This component renders a sheet that enters/exits on a particular side of
 * the screen. The sheet can appear as a overlay above other components or
 * displace surrounding components.
 *
 * @since 5.x
 */
const Sheet = props => {
  const { open, side, onClose, spacingInset, onOpen } = props
  // Always render children if closed size is set greater than 0 or
  // we're explicitly told via props
  const alwaysRenderChildren =
    (props.closedSize !== undefined && props.closedSize > 0) ||
    props.alwaysRenderChildren
  // If we're always visible, force type to be push
  const type = alwaysRenderChildren ? "push" : props.type
  const t = useTheme(sheetTokens, "sheet")
  // HACK: using modal floatingBreakpoint tokens - but we should really add this to the Sheet tokens and update this.
  const tModal = useTheme(modalTokens, "modal")
  const tScrim = useTheme(scrimTokens, "scrim")
  const hasMounted = useRef(false)
  const contentRef = useRef() // DOM node wrapping sheet content
  const anchorRef = useRef() // DOM element that originally opened sheet

  const initializeContentRef = useCallback(
    contentProps => element => {
      contentRef.current = element
      contentProps.ref(element)
    },
    []
  )

  const backgroundColor = useMemo(
    () =>
      getToken(t.tokens, "themeBackground", [
        props.backgroundColor,
        "default",
      ]) || props.backgroundColor,
    [t, props.backgroundColor]
  )

  // Put "overlay" sheets, which are essentially modals, in an expander that has built
  // in portal and focus trap.
  const Expander = useCallback(
    props =>
      React.createElement(
        type === "overlay" ? OverlayExpanderController : ExpanderController,
        props
      ),
    [type]
  )

  // Put "overlay" sheets, which are essentially modals, in a focus trap
  const SheetWrapper = useCallback(
    props =>
      React.createElement(
        type === "overlay" ? FocusTrap : React.Fragment,
        props
      ),
    [type]
  )

  // Overlay sheets are essentially modals. Here we add an keyboard listener
  // that closes the sheet when the user presses the "esc" key.
  // https://www.w3.org/TR/wai-aria-practices-1.1/#dialog_modal
  useEffect(() => {
    if (type === "overlay" && onClose) {
      const onKeyDown = e => {
        if (keycode(e) === "esc") onClose()
      }
      window.addEventListener("keydown", onKeyDown)
      return () => window.removeEventListener("keydown", onKeyDown)
    }
  }, [type, onClose])

  // On open, move focus to the first focusable element. On close, move focus
  // back to the original element that opened the sheet.
  const onAfterOpen = useCallback(() => {
    // Run this effect only after the component has mounted. Else, sheet
    // that load as `open` will immediately take over the active element.
    if (hasMounted.current && contentRef.current) {
      const firstFocusableNode = getKeyboardFocusNodes(contentRef.current)[0]
      if (firstFocusableNode) firstFocusableNode.focus()
      else contentRef.current.focus()
    }
  }, [])

  // Keeping this code commented for now because we might need it depending on what Doug replied to my accessibility questions
  // const onAfterClose = useCallback(() => {
  //   if (anchorRef.current) {
  //     anchorRef.current = document.activeElement;
  //     anchorRef.current.focus();
  //   }
  // }, [])

  // When opening, record which element opened the sheet
  useEffect(() => {
    if (open) anchorRef.current = document.activeElement
    hasMounted.current = true
  }, [open])

  useEffect(() => {
    if (open && onOpen) onOpen()
  }, [open, onOpen])

  const { focusProps } = useFocus({
    multipleNodes: true,
    onFocus: props.onFocus,
    onBlur: props.onBlur,
  })

  const passThroughProps = filterPropsByPrefix(props, ["data-"])

  return (
    <Expander
      open={open}
      direction={side === "bottom" ? "down" : side}
      motionDuration={props.motionDuration || parseInt(t("motionTiming"))}
      motionFunction={props.motionFunction || t("motionFunction")}
      onAfterOpen={onAfterOpen}
      //onAfterClose={onAfterClose}
      alwaysRenderChildren={alwaysRenderChildren}
      closedSize={props.closedSize}
    >
      {({ state, containerProps, contentProps }) => {
        // The children of a sheet might be interested in the state of the expander
        // to dynamically hide/show components for various stages. For example, skinny
        // SideMenu shows different links based on the expander state.
        const children =
          typeof props.children === "function"
            ? props.children({ expanderState: state })
            : props.children
        return (
          <React.Fragment>
            <SheetWrapper>
              <div
                {...containerProps}
                className={cx(
                  props.className,
                  containerStyles(t, { side, spacingInset, backgroundColor }),
                  type === "overlay"
                    ? modalStyles(tScrim, tModal, t, { side })
                    : undefined,
                  containerProps.className
                )}
              >
                <div
                  {...passThroughProps}
                  {...focusProps}
                  className={contentProps.className}
                  id={props.id}
                  ref={initializeContentRef(contentProps)}
                  role={type === "overlay" ? "dialog" : undefined}
                  aria-modal={type === "overlay" ? true : undefined}
                  tabIndex="-1"
                  aria-labelledby={props.labelledById}
                  aria-describedby={props.describedById}
                  onKeyDown={props.onKeyDown}
                >
                  {children}
                </div>
              </div>
            </SheetWrapper>
            {type === "overlay" ? (
              <Scrim
                className={overlayScrimStyles({ tScrim, tModal })}
                onClick={props.onClose}
                open={state === "entering" || state === "entered"}
                duration={t("motionTiming")}
              />
            ) : null}
          </React.Fragment>
        )
      }}
    </Expander>
  )
}

Sheet.displayName = "Sheet"

Sheet.propTypes = {
  /**
   * Set this to true if children should be rendered even when the sheet
   * is closed. This can be helpful when, for example, you want the sheet
   * to retain vertical or horizontal layout space (for a horizontal or
   * vertical sheet, respectively) even after it's collapsed.
   */
  alwaysRenderChildren: PropTypes.bool,
  /**
   * The sheet's background color. Any CSS color value is acceptable.
   *
   * Note that accessibility should be carefully considered when selecting a
   * background color. A [minimum contrast ratio of 4.5:1](https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html) between text and
   * background must be maintained. For large text a contrast ratio of 3:1 is acceptable.
   * You can use [a contrast checker](https://webaim.org/resources/contrastchecker/) to verify that your chosen background color
   * maintains an appropriate level of contrast. You can [read more here](https://meridian.a2z.com/guides/accessibility/?platform=react-web) about accessibility in Meridian.
   */
  backgroundColor: PropTypes.oneOfType([
    PropTypes.oneOf([
      "primary",
      "secondary",
      "alternatePrimary",
      "alternateSecondary",
    ]),
    PropTypes.string,
  ]),
  /**
   * The contents of the sheet. When a sheet is opened (and alwaysRenderChildren and closedSize
   * are _not_ set), the first focusable element will gain focus. Ideally this element should be
   * a close button so users can quickly toggle the sheet off.
   *
   * Children can also be a function that receives as its only argument an object with a key
   * `expanderState`. Expander state describes the phase of sheet's animation: "entering"/"entered"
   * when `open` is set to `true`, and "exiting"/"exited" when `open` is set to `false`. This
   * can be useful to conditionally show different components in the sheet based on the sheet's
   * animation phase.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  /**
   * Causes the sheet to close to a fixed pixel height/width value.
   *
   * Forces `alwaysRenderChildren` to be `true` and type to be `push`.
   */
  closedSize: PropTypes.number,
  /**
   * All props prefaced with `data-` are accepted. This is useful for
   * integrating with other libraries such as Amplify Analytics or Cypress.
   *
   * @since 5.x
   */
  "data-*": PropTypes.string,
  /**
   * Sets the `aria-describedby` attribute on the dialog element. Use this prop to
   * give screen readers an accessible description of the contents of the sheet which
   * will be read on open.
   *
   * See the documentation for the [aria-describedby attribute](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute).
   */
  describedById: PropTypes.string,
  /**
   * Sets an `id` attribute on the component's outer DOM element.
   *
   * Use this for testing, for accessibility, or to target custom elements
   * inside the component. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   */
  id: PropTypes.string,
  /**
   * Sets the `aria-labelledby` attribute on the dialog element. Use this prop to
   * give screen readers an accessible title/label for the contents of the sheet which
   * will be read on open.
   *
   * See the documentation for the [aria-describedby attribute](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute).
   */
  labelledById: PropTypes.string,
  /**
   * The duration it should take to animate in milliseconds.
   */
  motionDuration: PropTypes.number,
  /**
   * The animation function to use when animating. Learn more about [animation functions](https://developer.mozilla.org/en-US/docs/Web/CSS/animation-timing-function).
   */
  motionFunction: PropTypes.string,
  /**
   * The state of the sheet. If true the sheet will be open, otherwise it will be closed.
   */
  open: PropTypes.bool,
  /**
   * The side of the screen the sheet will appear. For type `overlay`, the sheet
   * is automatically fixed to the chosen side. For type `push`, however, setting
   * side _only_ affects how the sheet animates. Composing a `push` Sheet inside
   * the app's layout is the responsibility of the developer.
   */
  side: PropTypes.oneOf(["left", "right", "bottom"]),
  /**
   * Apply padding around the contents of the sheet using a preset.
   */
  spacingInset: PropTypes.oneOf(["none", "small", "medium"]),
  /**
   * sheets come in two flavors, push and overlay. Push displaces other elements on the
   * screen when it enters/exits. Overlay, on the other hand, enters/exits on top of the
   * application accompanied by a scrim (semi-transparent  overlay that covers the application
   * behind the sheet)
   */
  type: PropTypes.oneOf(["push", "overlay"]),
  /**
   * This is called when the sheet loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   */
  onBlur: PropTypes.func,
  /**
   * Required for type overlay. Called when a user presses the scrim (semi-transparent
   * overlay that covers the application behind the sheet) or hits the "escape" key.
   */
  onClose: PropTypes.func,
  /**
   * This is called when the sheet gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   */
  onFocus: PropTypes.func,
  /**
   * This is called when the component has focus and a keyboard key is
   * pressed down. The [keydown event](https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event)
   * is passed as the first argument to the function.
   */
  onKeyDown: PropTypes.func,
  /**
   * Called when the sheet is opened or when it is mounted open.
   */
  onOpen: PropTypes.func,
}

Sheet.defaultProps = {
  type: "push",
  open: false,
  side: "right",
  spacingInset: "medium",
  backgroundColor: "alternatePrimary",
}

export default Sheet
