import * as Actions from "../actions/action-types";

import { ATTENDEE_RESPONSE } from "../people-constants";

const initialState = {
    peopleSuggestions: [],
    groupSuggestions: [],
    attendees: [],
    externalAttendees: [],
    attendeesWorkingHoursCache: {},
};

function peopleReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PEOPLE_SUGGESTIONS:
            return {
                ...state,
                peopleSuggestions: undefined
            };
        case Actions.SAVE_PEOPLE_SUGGESTIONS:
            return {
                ...state,
                peopleSuggestions: action.people
            };
        case Actions.CLEAR_PEOPLE_SUGGESTIONS:
            return {
                ...state,
                peopleSuggestions: []
            };
        case Actions.GET_GROUP_SUGGESTIONS:
            return {
                ...state,
                groupSuggestions: undefined
            };
        case Actions.SAVE_GROUP_SUGGESTIONS:
            return {
                ...state,
                groupSuggestions: action.groups
            };
        case Actions.CLEAR_GROUP_SUGGESTIONS:
            return {
                ...state,
                groupSuggestions: []
            };
        case Actions.ADD_ATTENDEE:
            return state.attendees.find((attendee) => attendee.alias === action.attendee.alias) ? state :
                {
                    ...state,
                    attendees: [
                        ...state.attendees,
                        action.attendee
                    ]
                };
        case Actions.ADD_EXTERNAL_ATTENDEE:
            return state.externalAttendees.find((attendee) => attendee.alias === action.attendee.alias) ? state :
                {
                    ...state,
                    externalAttendees: [
                        ...state.externalAttendees,
                        action.attendee
                    ]
                };
        case Actions.UPDATE_ATTENDEE:
            return {
                ...state,
                attendees: state.attendees.map((attendee) =>
                    attendee.alias === action.attendee.alias ? action.attendee : attendee)
            };
        case Actions.UPDATE_EXTERNAL_ATTENDEE:
            return {
                ...state,
                externalAttendees: state.externalAttendees.map((attendee) =>
                    attendee.alias === action.attendee.alias ? action.attendee : attendee)
            };
        case Actions.REMOVE_ATTENDEE:
            return {
                ...state,
                attendees: state.attendees.filter((attendee) =>
                    attendee.alias !== action.attendee.alias || attendee.status === ATTENDEE_RESPONSE.ORGANIZER)
            };
        case Actions.REMOVE_EXTERNAL_ATTENDEE:
            return {
                ...state,
                externalAttendees: state.externalAttendees.filter((attendee) =>
                    attendee.alias !== action.attendee.alias || attendee.status === ATTENDEE_RESPONSE.ORGANIZER)
            };
        case Actions.CLEAR_ATTENDEES:
            return {
                ...state,
                attendees: state.attendees.filter((attendee) =>
                    attendee.status === ATTENDEE_RESPONSE.ORGANIZER)
            };
        case Actions.CLEAR_DRAFT:
            return {
                ...state,
                ...initialState
            };
        case Actions.UPDATE_PERSON_ATTENDEE_WITH_RAS_DATA:
            return {
                ...state,
                attendees: state.attendees.map((attendee) =>
                    attendee.alias === action.person.username
                        ? { ...attendee, rasData: action.person }
                        : attendee)
            };
        case Actions.UPDATE_GROUP_ATTENDEE_WITH_RAS_DATA:
            return {
                ...state,
                attendees: state.attendees.map((attendee) =>
                    attendee.alias === action.group.name
                        ? { ...attendee, rasData: action.group }
                        : attendee)
            };
        case Actions.CACHE_ATTENDEE_WORKING_HOURS:
            state.attendeesWorkingHoursCache[action.attendee.alias] = 
                action.attendee.workingHours;
            return state;
        default:
            return state;
    }
}

export default peopleReducer;
