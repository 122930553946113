import React from "react"
import ReactInputMask from "react-input-mask"

/**
 * This class can be used to instantiate an input mask object that can be passed
 * to the `mask` prop of the `Input` component. For example a basic US phone
 * number mask can be created like this:
 *
 * ```
 * import Input from "@amzn/meridian/input"
 * import InputMask from "@amzn/meridian/input/mask"
 *
 * const phoneMask = new InputMask("(###)###-####")
 * <Input mask={phoneMask} />
 * ```
 */
class InputMask {
  constructor(mask, options = {}) {
    const {
      /* Use the unicode "figure space" character for the default placeholder
      character. This char is the width of a single number, which ensures that
      the mask in an empty or partially-filled input is rendered with perfect
      spacing for numeric input, and good-enough spacing for alpha-numeric input
      (which is less common in masks). For example an input with the mask
      "(###)###-####" will render as "(   )   -    " or "(208)59 -    " as the
      user types instead of "()-" or "(208)59-". */
      placeholderChar = String.fromCharCode(8199),
      /* These characters identify which characters represent the placeholders
      in a mask string. By default "#" is a placeholder for numbers, "x" is a
      placeholder for letters, and "*" is a placeholder for both. */
      formatChars = {
        "#": "[0-9]",
        x: "[A-Za-z]",
        "*": "[A-Za-z0-9]",
      },
      /* If set to true the mask will show in the input even when the input is
      blurred */
      alwaysShow = false,
      beforeMaskedValueChange = undefined,
    } = options
    this.alwaysShow = alwaysShow
    this.mask = mask
    this.placeholderChar = placeholderChar
    this.formatChars = formatChars
    this.beforeMaskedValueChange = beforeMaskedValueChange
  }

  getElement(props, options = {}) {
    const {
      alwaysShow = this.alwaysShow,
      placeholderChar = this.placeholderChar,
      formatChars = this.formatChars,
      beforeMaskedValueChange = this.beforeMaskedValueChange,
    } = options
    return (
      <ReactInputMask
        {...props}
        alwaysShowMask={alwaysShow}
        maskChar={placeholderChar}
        formatChars={formatChars}
        beforeMaskedValueChange={beforeMaskedValueChange}
        mask={this.mask}
      />
    )
  }
}

export default InputMask
