module.exports = {
    TIME_FORMAT: {
        TWELVE_HOUR: "12 hour",
        TWENTY_FOUR_HOUR: "24 hour",
    },

    DATE_FORMAT: {
        MONTH_DAY_YEAR: "MM/DD/YYYY",
        DAY_MONTH_YEAR: "DD/MM/YYYY",
        YEAR_MONTH_DAY: "YYYY-MM-DD",
    },

    DATE_FORMAT_LOCALE: {
        MONTH_DAY_YEAR: "en-US",
        DAY_MONTH_YEAR: "en-GB",
        YEAR_MONTH_DAY: "en-CA",
    },

    WORKING_HOURS: {
        DAYS: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        START_TIME: "09:00:00",
        END_TIME: "17:30:00",
    },

    DEFAULT_WORKING_HOURS: {
        days: ["Mon", "Tue", "Wed", "Thu", "Fri"],
        startTime: "09:00:00",
        endTime: "17:00:00",
    },

    SCHEDULING_MODE: {
        LIST_MODE: "List",
        GRID_MODE: "Grid",
    },
    
    CALENDAR_VIEW_DESKTOP: {
        AGENDA: "Agenda",
        DAY: "Day",
        WORK_WEEK: "Work Week",
        WEEK: "Week",
    },

    CALENDAR_VIEW_MOBILE: {
        AGENDA: "Agenda",
        DAY: "Day",
        THREE_DAY: "3-day",
    },
    
    // Will add this in Phase 2/3
    // THEME: {
    //     LIGHT: "Light",
    //     DARK: "Dark",
    //     CUSTOM: "Custom",
    // },

    CHIME_MEETING_ID: {
        GENERATE_NEW_ID: "Generate a new meeting ID",
        PERSONAL_ID: "Use my personal meeting ID: ",
    },

    TABS: {
        FAVORITES: "Favorites",
        TEMPLATES: "Templates",
    },

    SIDE_MENU_ITEMS: {
        CHIME_OPTIONS: "Amazon Chime options",
        TIME_SCHEDULE: "Time and schedule",
        ROOM_BOOKING: "Room booking",
        LAYOUTS: "Layouts",
        FAVORITES: "Favorites",
        // OOTO_TEMPLATES: "OOTO templates",  // Release in future
    },
};