import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { findPolls, getPoll } from "../actions";
import {
    getIdentity,
    getMeetingPollsLoaded,
    getPollListForUser,
    getSelectedPollDetails,
    getSettingsPrimaryTimezone,
    getSettingsTimeFormat,
} from "../../../sagas/selector";

import Responsive from "@amzn/meridian/responsive";

import MeetingsToaster from "../../shared/toasts/containers/toast-container";
import MeetingsFeedbackContainer from "../../shared/feedback/containers/meetings-feedback-container";

import { getErrorToast } from "../../shared/toasts/toast-utils";

import PollForm from "../components/poll-form";

import { STATUS } from "../poll-constants";
import { showToast } from "../../shared/toasts/actions";
import { SCREEN_SIZE } from "../../shared/shared-constants";

const PollDashboardContainer = (props) => {
    const redirectFromCreateMeetingPage = props.location?.state?.redirectFromCreateMeetingPage;
    const identity = useSelector(getIdentity);
    const userAlias = identity.username;
    const userFullName = identity.name;
    const dispatch = useDispatch();
    const pollList = useSelector(getPollListForUser);
    const meetingPollsLoaded = useSelector(getMeetingPollsLoaded);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const setOpenResults = props.setOpenResults;
    const pollDetails = useSelector(getSelectedPollDetails);
    const [timezoneValue, setTimezoneValue] = useState(primaryTimezone);

    const onFindPolls = (requestedOnBehalfOf, identity, startTime, endTime, pollStatus) => dispatch(findPolls(requestedOnBehalfOf, identity, startTime, endTime, pollStatus));
    const onGetPoll = (pollID) => dispatch(getPoll(userAlias, pollID));

    // useEffect to call find polls for current user
    useEffect(() => {
        if (identity) {
            // The start and end time here is for the poll creation time
            // Set at start time at Jan 1 2022 8 AM and end time is the current timestamp in seconds
            let startTime = new Date(new Date().getFullYear(), 0, 1, 8).getTime();
            let endTime = Math.floor(Date.now());

            // TODO: replace with dynamic status
            onFindPolls(userAlias, userAlias, startTime, endTime, [STATUS.ONGOING, STATUS.READY, STATUS.OVERDUE]);
        }
        // eslint-disable-next-line
    }, [identity, dispatch]);

    useEffect(() => {
        if (!timezoneValue) {
            setTimezoneValue(primaryTimezone);
        }
    }, [timezoneValue, primaryTimezone]);

    useEffect(() => {
        if (redirectFromCreateMeetingPage) {
            const toast = getErrorToast("<b>Things didn't go as planned.</b> To create a meeting, choose a poll from dashbooard.");
            dispatch(showToast(toast));
        }
    }, [dispatch, redirectFromCreateMeetingPage]);

    return (
        <Responsive
            query="min-width"
            props={{
                screenSizeBreakpoint: {...SCREEN_SIZE.RESPONSIVE_BREAKPOINTS}
            }}
        >
            {(responsiveProps) =>
                <React.Fragment>
                    <PollForm
                        identity={identity}
                        userFullName={userFullName}
                        userAlias={userAlias}
                        pollList={pollList}
                        mastheadSize={props.mastheadSize}
                        screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                        onFindPolls={onFindPolls}
                        meetingPollsLoaded={meetingPollsLoaded}
                        setOpenResults={setOpenResults}
                        timezoneValue={timezoneValue}
                        timeFormat={timeFormat}
                        pollDetails={pollDetails}
                        onGetPoll={onGetPoll}
                    />
                    <MeetingsToaster />
                    <MeetingsFeedbackContainer />
                </React.Fragment>
            }
        </Responsive>
    );
};

export default withRouter(PollDashboardContainer);
