import { useEffect } from "react";

import { KEYCODE } from "../../shared-constants.js";

const ToastFocusWrapper = (props) => {
    const alertId = props.alertId;

    useEffect(() => {
        let toastAlert = document.getElementById(alertId);

        // Trap focus within toasts containing alerts defined with role="alertdialog"
        // Accessibility issue: https://issues.amazon.com/issues/BA-5584
        if (toastAlert) {
            // Code adapted from reference: https://hidde.blog/using-javascript-to-trap-focus-in-an-element/
            let focusableElements = toastAlert.querySelectorAll('a:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');

            let firstFocusableElement = focusableElements[0];
            let lastFocusableElement = focusableElements[focusableElements.length - 1];

            toastAlert.addEventListener("keydown", function(event) {
                if (event.key === "Tab" || event.keyCode === KEYCODE.TAB) {
                    if ( event.shiftKey ) { // Shift + Tab
                        if (document.activeElement === firstFocusableElement) {
                            lastFocusableElement.focus();
                            event.preventDefault();
                        }
                    } else { // Tab
                        if (document.activeElement === lastFocusableElement) {
                            firstFocusableElement.focus();
                            event.preventDefault();
                        }
                    }
                }
            });
        }
    }, [alertId]);

    return (null);
};

export default ToastFocusWrapper;