import * as Actions from "../actions/action-types";

import { locationInLocations, locationsEqual } from "../locations-utils";

const initialState = {
    buildingList: [], //undefined?
    findRooms: true,
    rooms: [],
    locations: [],
    floorsLoaded: false,
};

function locationsReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SAVE_BUILDINGS:
            return {
                ...state,
                buildingList: action.buildingList,
            };
        case Actions.SAVE_FLOORS:
            return {
                ...state,
                buildingList:
                    state.buildingList.map((building) =>
                        building.buildingCode === action.buildingCodeToUpdate ? {
                                ...building,
                                floorList: action.floorList,
                                roomList: action.roomList.filter((room) => !room.hidden),
                                hiddenRoomList: action.roomList.filter((room) => room.hidden),
                            }
                            :
                            building
                    ),
                floorsLoaded: true,
            };
        case Actions.SET_LOAD_FLOORS_STATUS:
            return {
                ...state,
                floorsLoaded: action.isLoaded,
            }
        case Actions.UPDATE_FIND_ROOMS:
            return {
                ...state,
                findRooms: action.findRooms
            };
        case Actions.ADD_ROOM:
            return state.rooms.find((room) => room.email === action.room.email) ? state :
                {
                    ...state,
                    rooms: [
                        ...state.rooms,
                        action.room
                    ]
                };
        case Actions.REMOVE_ROOM:
            return {
                ...state,
                rooms: state.rooms.filter((room) =>
                    room.email !== action.room.email)
            };
        case Actions.ADD_LOCATION:
            return locationInLocations(state.locations, action.location) ? state :
                {
                    ...state,
                    locations: [
                        ...state.locations,
                        action.location
                    ]
                };
        case Actions.REMOVE_LOCATION:
            return locationInLocations(state.locations, action.location) ?
                {
                    ...state,
                    locations: state.locations.filter((location) =>
                        !locationsEqual(location, action.location))
                }
                :
                state;
        case Actions.CLEAR_LOCATIONS_AND_ROOMS:
            return {
                ...state,
                rooms: [],
                locations: []
            }
        default:
            return state;
    }
}

export default locationsReducer;