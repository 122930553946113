import React, { useCallback, useEffect, useRef, useState } from "react";

import format from "date-fns/format";
import getDayOfYear from "date-fns/getDayOfYear";
import isWeekend from "date-fns/isWeekend";
import parseISO from "date-fns/parseISO";

import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Image from "@amzn/meridian/_image";
import Input from "../../shared/meridian-custom-components/src/components/input";
import InputGroup from "../../shared/meridian-custom-components/src/components/input-group";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Popover, { PopoverHeader } from "@amzn/meridian/popover";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "../../shared/meridian-custom-components/src/components/select";
import Text from "@amzn/meridian/text";
import Toggle from "@amzn/meridian/toggle";

import AttendeePopover from "../../people/components/attendee-popover";
import AttendeeSearchBar from "../../people/components/attendee-search-bar";
import AttendeeTag from "../../people/components/attendee-tag";
import LocationSearchBar from "../../shared/locations/components/location-search-bar";
import LabeledDateRangePicker from "../../shared/components/labeled-date-range-picker";
import LabeledTimeSelect from "../../shared/components/labeled-time-select";
import RichTextEditor from "../../shared/components/rich-text-editor";

import QuickMeetingIcon from "../../../assets/meeting-quick/quick-meeting.svg";

import "../meeting-quick.css";

import { generateComponentId } from "../../shared/shared-utils";
import { getFormattedChimePin, sortAttendees } from "../../shared/meeting-utils";
import { addAccessibilityPropertiesToButton } from "../../shared/accessibility-utils";
import { COMPONENT_LABELS } from "../../shared/accessibility-constants";
import {
    CANCEL_AND_REVIEW_BUTTON_HEIGHT,
    MAX_ATTENDEE_TAGS,
    MEETING_BODY_HEIGHT,
    MODAL_SIZE,
    MODAL_TITLE,
    SCREEN_SIZE,
} from "../meeting-quick-constants";
import { ATTENDEE_TYPE } from "../../people/people-constants";
import { ALERTS, KEYCODE } from "../../shared/shared-constants";

const MeetingQuickForm = (props) => {
    const [openAttendeePopover, setOpenAttendeePopover] = useState("");
    const onCloseAttendeePopover = () => setOpenAttendeePopover("");
    const attendeePopoverRefs = useRef([]);

    const [openAttendeesLinkPopover, setOpenAttendeesLinkPopover] = useState(false);
    const onOpenAttendeesLinkPopover = () => setOpenAttendeesLinkPopover(true);
    const onCloseAttendeesLinkPopover = () => setOpenAttendeesLinkPopover(false);
    const attendeesLinkPopoverRef = useRef();

    const attendees = sortAttendees(props.attendees);
    const attendeesAliasList = attendees.map((attendee) => attendee.alias);

    const generatedChimeBridge = props.chimeUniqueIds.AllocatedPins && props.chimeUniqueIds.AllocatedPins[0].Pin;
    const personalChimeBridge = props.chimeUser.PersonalPIN;

    const DURATION_SELECT_LABEL_ID = "duration-select-label-id";
    const CUSTOM_DURATION_UNIT_SELECT_LABEL_ID = "custom-duration-unit-select-label-id";
    const CHIME_SELECT_LABEL_ID = "chime-select-label-id";
    const DESCRIPTION_LABEL_ID = "description-label-id";

    // event handlers
    const viewAttendeeDetails = (attendee) => {
        setOpenAttendeePopover(attendee.identifier);

        // If rasData is not present, call APIs once to retrieve card data
        if (attendee.rasData === undefined) {
            if (attendee.type === ATTENDEE_TYPE.PERSON) {
                props.onGetRASDataForPerson(attendee.alias, false);
            } else if (attendee.type === ATTENDEE_TYPE.GROUP) {
                props.onGetRASDataForGroup(attendee.alias);
            }
        }
    };

    const removeAttendee = (attendee) => {
        if (attendees.length <= 9) {
            onCloseAttendeesLinkPopover();
        }

        props.onRemoveAttendee(attendee);
    };

    // helpers
    const formatIsoTime = (date) => format(date, "hh:mm aa");
    const formatIsoDate = (date) => format(date, "EEE, MMM do yyyy");
    const isDisabledDate = useCallback((date) => isWeekend(parseISO(date)), []);

    const refreshSuggestionsButtonId = generateComponentId("button", "refresh-suggestions", "quick-meeting");

    const createAttendeeTag = (attendee, allowViewAttendeeDetails) => {
        return (
            <AttendeeTag
                attendee={attendee}
                attendeePopoverRefs={attendeePopoverRefs}
                allowViewAttendeeDetails={allowViewAttendeeDetails}
                isFavorited={props.isFavorited(attendee)}
                onFavoriteClick={props.onFavoriteClick}
                removeAttendee={removeAttendee}
                viewAttendeeDetails={viewAttendeeDetails}
                key={attendee.name || attendee.email}
            />
        );
    };

    const showAttendeeTags = () => {
        return (
            attendees.map((attendee) => (
                <Row key={attendee.name || attendee.email}>
                    <div style={{
                        height: attendee.isOrganizer ? "27px" : "",
                        paddingTop: "4px",
                    }}>
                        {createAttendeeTag(attendee, true)}
                    </div>
                    <AttendeePopover
                        position="bottom"
                        attendee={attendee}
                        attendeeList={attendeesAliasList}
                        anchorNode={attendeePopoverRefs.current[attendee.identifier]}
                        open={openAttendeePopover === attendee.identifier}
                        onClose={onCloseAttendeePopover}
                        onAddAttendee={props.onAddAttendee}
                        onRemoveAttendeeByAlias={props.onRemoveAttendeeByAlias}
                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                        isFavorited={props.isFavorited}
                        onFavoriteClick={props.onFavoriteClick}
                    />
                </Row>
            ))
        );
    };

    const createAttendeesLinkPopover = () => {
        return (
            <Popover
                position="right"
                alignment="center"
                anchorNode={attendeesLinkPopoverRef.current}
                open={openAttendeesLinkPopover}
                onClose={onCloseAttendeesLinkPopover}>
                <PopoverHeader closeLabel="Close attendee list">Attendees</PopoverHeader>
                <Column alignmentHorizontal="left" spacing="small">
                    {
                        attendees && attendees.map((attendee) => (
                            createAttendeeTag(attendee, false)
                        ))
                    }
                </Column>
            </Popover>
        );
    };

    const getSuggestedTimeLabel = (suggestion) => {
        if (!suggestion) {
            return undefined;
        }
        return formatIsoTime(suggestion.startTime * 1000) + " - " + formatIsoTime(suggestion.endTime * 1000);
    };

    const getSuggestedDateLabel = (suggestion) => {
        if (!suggestion) {
            return undefined;
        }

        const startTimeMillis = suggestion.startTime * 1000;
        const endTimeMillis = suggestion.endTime * 1000;

        const dateLabel = formatIsoDate(startTimeMillis);
        if (getDayOfYear(startTimeMillis) !== getDayOfYear(endTimeMillis)) {
            return dateLabel + " - " + formatIsoDate(endTimeMillis);
        }
        return dateLabel;
    };

    const onKeyDown = (event) => {
        const keyCode = event.keyCode || event.which;
        const special = event.shiftKey;

        if (!special && keyCode === KEYCODE.TAB) {
            event.preventDefault();
            props.closeButtonRef && props.closeButtonRef.current && props.closeButtonRef.current.focus();
        }
    };

    useEffect(() => {
        addAccessibilityPropertiesToButton(refreshSuggestionsButtonId, [
            ALERTS.NO_SUGGESTION_SELECTED.id
        ]);
    }, [refreshSuggestionsButtonId]);

    return (
        <Column
            spacingInset="none none medium none"
            spacing="none"
        >
            <Row alignmentVertical="top" widths={["20px", "fill"]} spacing="small" spacingInset={props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ? "small" : "medium"}>
                <Column spacing="none" spacingInset="none">
                    <Image
                        viewportHeight="20px" viewportAspectRatio={1}
                        src={QuickMeetingIcon} size="20px" />
                </Column>
                <Column spacing="none">
                    <Heading level={2} type="h200">
                        {MODAL_TITLE}
                    </Heading>
                    <Text type="b200" alignment="left">
                        Schedule time with a few attendees and an optional room
                    </Text>
                </Column>
            </Row>
            <Column
                spacing="medium"
                spacingInset={props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ? "small" : "none medium none medium"}
                maxHeight={props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ? "100%" : MODAL_SIZE.MEETING_FORM_HEIGHT}
                overflowY="auto"
            >
                <Column spacing="small">
                    <Heading level={3} type="h100">
                        Add attendees and locations
                    </Heading>
                    <Column spacing="none">
                        <Column spacing="none">
                            <Row alignmentHorizontal="justify">
                                <Text type="b100" alignment="left">Attendees</Text>
                                <Text type="b100">
                                    <Link type="secondary" onClick={props.onBrowseFavorites}>Browse favorites</Link>
                                </Text>
                            </Row>
                            <AttendeeSearchBar
                                width="100%"
                                peopleSuggestions={props.peopleSuggestions}
                                groupSuggestions={props.groupSuggestions}
                                onAddAttendee={props.onAddAttendee}
                                onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                                onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                                onGetGroupSuggestions={props.onGetGroupSuggestions}
                                onClearGroupSuggestions={props.onClearGroupSuggestions}
                                onRemoveAttendeeByAlias={props.onRemoveAttendeeByAlias}
                            />
                            <Row spacingInset="xsmall none" spacing="xsmall" width="100%" wrap="down">
                                {attendees && attendees.length <= MAX_ATTENDEE_TAGS ?
                                    showAttendeeTags()
                                    :
                                    <div aria-label="View attendee list">
                                        <Link type="secondary" onClick={onOpenAttendeesLinkPopover} ref={attendeesLinkPopoverRef}>
                                            {attendees.length + " attendees"}
                                        </Link>
                                    </div>
                                }
                                {createAttendeesLinkPopover()}
                            </Row>
                        </Column>
                        <Column>
                            {props.alerts.includes(ALERTS.INVALID_ATTENDEES.value) &&
                                <Alert type={ALERTS.INVALID_ATTENDEES.type} size="small">
                                    <Text id={ALERTS.INVALID_ATTENDEES.id}>
                                        {ALERTS.INVALID_ATTENDEES.value}
                                    </Text>
                                </Alert>
                            }
                        </Column>
                        <Column spacing="none">
                            <Text type="b100" alignment="left">Room</Text>
                            <LocationSearchBar
                                width="100%"
                                isSearchable={true}
                                isMultiSelect={false}
                                location={props.location}
                                onLocationSelect={props.setLocation}
                                locationOptions={props.getRoomOptions()}
                                placeholder="Select meeting room"
                                ariaLabel="Room"
                            />
                        </Column>
                    </Column>
                </Column>
                <Column spacing="small">
                    <Heading level={3} type="h100">
                        Add meeting details
                    </Heading>
                    <Column spacing="small">
                        <Row spacing="medium" alignmentVertical="top" widths={["50%", "50%"]}>
                            <Column spacing="none" spacingInset="none">
                                <Text id={DURATION_SELECT_LABEL_ID} type="b100" alignment="left">Duration</Text>
                                <Select
                                    size="small"
                                    minWidth="125px"
                                    value={props.duration}
                                    onChange={props.setDuration}
                                    placeholder="Select meeting duration"
                                    aria-labelledby={DURATION_SELECT_LABEL_ID}
                                >
                                    <SelectOption label="15 mins" value="15" />
                                    <SelectOption label="30 mins" value="30" />
                                    <SelectOption label="45 mins" value="45" />
                                    <SelectOption label="1 hour" value="60" />
                                    <SelectOption label="1 hour, 30 mins" value="90" />
                                    <SelectOption label="2 hours" value="120" />
                                    <SelectOption label="Custom..." value="custom" />
                                </Select>
                            </Column>
                            {props.duration === "custom" &&
                                <Column spacingInset="none" alignmentHorizontal="stretch" width="100%" spacing="xsmall">
                                    <Column spacing="none">
                                        <Text type="b100" alignment="left">Custom duration</Text>
                                        <div style={{display: "none"}}>
                                            <Text id={CUSTOM_DURATION_UNIT_SELECT_LABEL_ID}>Custom duration unit</Text>
                                        </div>
                                        <InputGroup>
                                            <Input
                                                aria-label="Custom meeting duration"
                                                aria-describedby={[ALERTS.INVALID_MEETING_DURATION.id, ALERTS.MEETING_DURATION_NOT_SPECIFIED.id].join(" ")}
                                                size="small"
                                                type="number"
                                                width="50%"
                                                value={props.customDuration}
                                                onChange={props.setCustomDuration}
                                                pattern={/^\d{0,4}(\.\d{0,2})?$/}
                                                placeholder="Enter custom meeting duration"
                                                error={props.alerts.includes(ALERTS.INVALID_MEETING_DURATION.value) || props.alerts.includes(ALERTS.MEETING_DURATION_NOT_SPECIFIED.value)}
                                            />
                                            <Select
                                                width="50%"
                                                size="small"
                                                value={props.customDurationUnits}
                                                onChange={props.setCustomDurationUnits}
                                                placeholder="Select custom duration units"
                                                aria-labelledby={CUSTOM_DURATION_UNIT_SELECT_LABEL_ID}
                                            >
                                                <SelectOption label={"min"} value={"min"} />
                                                <SelectOption label={"hour"} value={"hour"} />
                                                <SelectOption label={"day"} value={"day"} />
                                            </Select>
                                        </InputGroup>
                                    </Column>
                                    <Column spacing="xsmall">
                                        {props.alerts.includes(ALERTS.INVALID_MEETING_DURATION.value) &&
                                            <Alert type={ALERTS.INVALID_MEETING_DURATION.type} size="small">
                                                <Text id={ALERTS.INVALID_MEETING_DURATION.id}>
                                                    {ALERTS.INVALID_MEETING_DURATION.value}
                                                </Text>
                                            </Alert>
                                        }
                                        {props.alerts.includes(ALERTS.MEETING_DURATION_NOT_SPECIFIED.value) &&
                                            <Alert type={ALERTS.INVALID_MEETING_DURATION.type} size="small">
                                                <Text id={ALERTS.MEETING_DURATION_NOT_SPECIFIED.id}>
                                                    {ALERTS.MEETING_DURATION_NOT_SPECIFIED.value}
                                                </Text>
                                            </Alert>
                                        }
                                    </Column>
                                </Column>
                            }
                        </Row>
                    </Column>
                    <Column spacing="xsmall">
                        <div className="amw-date-range-picker">
                            <LabeledDateRangePicker
                                startLabelWidth="50%"
                                dateRangePickerWidth="100%"
                                rowSpacing="small"
                                hideSelectionMessage={true}
                                screenSizeBreakpoint={props.screenSizeBreakpoint}
                                dateRangeValue={props.dateRangeValue}
                                setDateRangeValue={props.setDateRangeValue}
                                dateRangeLabel={props.dateRangeLabel}
                                setDateRangeLabel={props.setDateRangeLabel}
                                presets={props.presets}
                                startTime={props.startTime}
                                endTime={props.endTime}
                                isDisabledDate={isDisabledDate}
                                dateFormat={props.dateFormat}
                            />
                        </div>
                        <Column spacing="xsmall">
                            {props.alerts.includes(ALERTS.INVALID_START_DATE.value) &&
                                <Alert type={ALERTS.INVALID_START_DATE.type} size="small">
                                    <Text id={ALERTS.INVALID_START_DATE.id}>
                                        {ALERTS.INVALID_START_DATE.value}
                                    </Text>
                                </Alert>
                            }
                            {props.alerts.includes(ALERTS.INVALID_END_DATE.value) &&
                                <Alert type={ALERTS.INVALID_END_DATE.type} size="small">
                                    <Text id={ALERTS.INVALID_END_DATE.id}>
                                        {ALERTS.INVALID_END_DATE.value}
                                    </Text>
                                </Alert>
                            }
                            {props.alerts.includes(ALERTS.MAX_DAYS_EXCEEDED.value) &&
                                <Alert type={ALERTS.MAX_DAYS_EXCEEDED.type} size="small">
                                    <Text id={ALERTS.MAX_DAYS_EXCEEDED.id}>
                                        {ALERTS.MAX_DAYS_EXCEEDED.value}
                                    </Text>
                                </Alert>
                            }
                            {props.alerts.includes(ALERTS.MEETING_DURATION_LONGER_THAN_DATE_RANGE.value) &&
                                <Alert type={ALERTS.MEETING_DURATION_LONGER_THAN_DATE_RANGE.type} size="small">
                                    <Text id={ALERTS.MEETING_DURATION_LONGER_THAN_DATE_RANGE.id}>
                                        {ALERTS.MEETING_DURATION_LONGER_THAN_DATE_RANGE.value}
                                    </Text>
                                </Alert>
                            }
                        </Column>
                    </Column>
                    <Column width="100%" spacing="xsmall">
                        <div className="amw-time-range-picker">
                            <LabeledTimeSelect
                                timeSelectWidth="100%"
                                timeSelectMobileWidth="156px"
                                rowSpacing="medium"
                                hideSelectionMessage={true}
                                screenSizeBreakpoint={props.screenSizeBreakpoint}
                                isAllDayEvent={false}
                                startTime={props.startTime}
                                setStartTime={props.setStartTime}
                                endTime={props.endTime}
                                setEndTime={props.setEndTime}
                                timeLabel={props.timeLabel}
                                setTimeLabel={props.setTimeLabel}
                                timeFormat={props.timeFormat}
                            />
                        </div>
                        <Column spacing="xsmall">
                            {props.alerts.includes(ALERTS.INVALID_TIME_WINDOW.value) &&
                                <Alert type={ALERTS.INVALID_TIME_WINDOW.type} size="small">
                                    <Text id={ALERTS.INVALID_TIME_WINDOW.id}>
                                        {ALERTS.INVALID_TIME_WINDOW.value}
                                    </Text>
                                </Alert>
                            }
                            {props.alerts.includes(ALERTS.MEETING_DURATION_LONGER_THAN_TIME_WINDOW.value) &&
                                <Alert type={ALERTS.MEETING_DURATION_LONGER_THAN_TIME_WINDOW.type} size="small">
                                    <Text id={ALERTS.MEETING_DURATION_LONGER_THAN_TIME_WINDOW.id}>
                                        {ALERTS.MEETING_DURATION_LONGER_THAN_TIME_WINDOW.value}
                                    </Text>
                                </Alert>
                            }
                        </Column>
                    </Column>
                </Column>
                <Column spacing="small">
                    <Heading level={3} type="h100">
                        Get smart suggestions
                    </Heading>
                    <Column spacingInset="none" spacing="small" alignmentVertical="bottom">
                        <Row widths={["50%", "50%"]} spacing="small" alignmentVertical="center">
                            <Button
                                ref={props.getSuggestionsButtonRef}
                                type="tertiary"
                                size="small"
                                onClick={props.onGetSuggestions}
                                id={refreshSuggestionsButtonId}
                                disabled={!props.timezoneValue}
                                label={props.isFirstSearch ? "Get suggestions" : "Refresh suggestions"}
                            >
                                {(!props.startTime || !props.endTime || !props.timezoneValue) &&
                                    <Loader size="small" />
                                }
                                {props.isFirstSearch ? "Get suggestions" : "Refresh suggestions"}
                            </Button>
                            <Column spacing="none" spacingInset="small" alignmentHorizontal="left">
                                {props.selectedSuggestionIndex === -1 || !props.suggestions || props.suggestions.length === 0 ?
                                    (props.alerts.includes(ALERTS.NO_SUGGESTION_SELECTED.value) ?
                                        <Alert type={ALERTS.NO_SUGGESTION_SELECTED.type} size="small">
                                            <Text id={ALERTS.NO_SUGGESTION_SELECTED.id}>
                                                {ALERTS.NO_SUGGESTION_SELECTED.value}
                                            </Text>
                                        </Alert>
                                        :
                                        <Text type="b200" color="secondary">
                                            No suggestion selected
                                        </Text>
                                    )
                                    :
                                    <Column spacing="none">
                                        <Text type="b200" color="secondary">
                                            {getSuggestedTimeLabel(props.suggestions[props.selectedSuggestionIndex])}
                                        </Text>
                                        <Text type="b200" color="secondary">
                                            {getSuggestedDateLabel(props.suggestions[props.selectedSuggestionIndex])}
                                        </Text>
                                    </Column>
                                }
                            </Column>
                        </Row>
                    </Column>
                    {props.durationExceedsRoomLimit &&
                        <Alert type={ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT.type} size="medium">
                            <Column spacing="small">
                                <Text id={ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT.id}>
                                    {ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT.value}
                                </Text>
                                <Text id={ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT_SUGGESTION.id}>
                                    {ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT_SUGGESTION.value}
                                </Text>
                            </Column>
                        </Alert>
                    }
                </Column>
                <Column spacing="small">
                    <Heading level={3} type="h100">
                        Add meeting agenda
                    </Heading>
                    <Column spacing="small">
                        <Column spacing="xsmall">
                            <Column spacing="none">
                                <Text type="b100">Meeting subject</Text>
                                <Input
                                    ref={props.subjectRef}
                                    size="small"
                                    aria-label={COMPONENT_LABELS.SUBJECT}
                                    aria-describedby={ALERTS.INVALID_MEETING_SUBJECT.id}
                                    value={props.meetingSubject}
                                    onChange={props.setMeetingSubject}
                                    error={props.alerts.includes(ALERTS.INVALID_MEETING_SUBJECT.value)}
                                    placeholder="Enter meeting subject" />
                            </Column>
                            <Column spacing="none">
                                {props.alerts.includes(ALERTS.INVALID_MEETING_SUBJECT.value) &&
                                    <Alert type={ALERTS.INVALID_MEETING_SUBJECT.type} size="small">
                                        <Text id={ALERTS.INVALID_MEETING_SUBJECT.id}>
                                            {ALERTS.INVALID_MEETING_SUBJECT.value}
                                        </Text>
                                    </Alert>
                                }
                            </Column>
                        </Column>
                        <Row>
                            <Column spacing="none" width="100%">
                                <Text id={CHIME_SELECT_LABEL_ID} type="b100" alignment="left">Chime</Text>
                                <Row widths={["fill", "fit"]} wrap="down">
                                {props.chimePin === undefined ?
                                    <Loader size="small" />
                                    :
                                    <Select
                                        size="small"
                                        value={props.chimePin}
                                        onChange={props.onSetChimePin}
                                        placeholder="Select chime option"
                                        aria-labelledby={CHIME_SELECT_LABEL_ID}
                                        minWidth="130px"
                                    >
                                        <SelectOption label="None" value="none"/>
                                        {generatedChimeBridge &&
                                            <SelectOption
                                                value={generatedChimeBridge}
                                                label={`Generate ID: ${getFormattedChimePin(generatedChimeBridge)}`}
                                            />
                                        }
                                        {personalChimeBridge &&
                                            <SelectOption
                                                value={personalChimeBridge}
                                                label={`Personal ID: ${getFormattedChimePin(personalChimeBridge)}`}
                                            />
                                        }
                                    </Select>
                                }
                                    <Row spacingInset="none none xsmall none">
                                        <Toggle id={generateComponentId("toggle", "auto-call", "simple-meeting")} checked={props.autoCall} onChange={props.setAutoCall} disabled={props.chimePin === "none"}>Auto-call participants</Toggle>
                                    </Row>
                                </Row>
                            </Column>
                        </Row>
                        <Column spacing="none" width="100%">
                            <Row>
                                <Checkbox
                                    checked={props.isPrivate}
                                    onChange={props.setIsPrivate}
                                    id={generateComponentId("checkbox", "private")}
                                >
                                    Private
                                </Checkbox>
                                <Checkbox
                                    checked={props.isResponseRequested}
                                    onChange={props.setIsResponseRequested}
                                    id={generateComponentId("checkbox", "request-responses")}
                                >
                                    Request responses
                                </Checkbox>
                            </Row>
                        </Column>
                        <Column spacing="none">
                            <Text id={DESCRIPTION_LABEL_ID} type="b100">Description</Text>
                            <div style={{paddingBottom: CANCEL_AND_REVIEW_BUTTON_HEIGHT}}>
                                <RichTextEditor
                                    height={MEETING_BODY_HEIGHT}
                                    textValue={props.meetingBody}
                                    updateTextValue={props.setMeetingBody}
                                    aria-labelledby={DESCRIPTION_LABEL_ID}
                                />
                            </div>
                        </Column>
                        <Row spacing="medium" alignmentHorizontal="right" spacingInset="small none none medium">
                            <Button
                                type="secondary"
                                size="medium"
                                id={generateComponentId("button", "cancel", "simple-meeting")}
                                onClick={() => {props.setDiscardMeetingModalOpen(true)}}
                            >
                                Cancel
                            </Button>
                            <div onKeyDown={onKeyDown}>
                                <Button
                                    type="primary"
                                    size="medium"
                                    id={generateComponentId("button", "review", "simple-meeting")}
                                    onClick={() => {props.onShowReview(true)}}
                                    ref={props.reviewButtonRef}
                                >
                                    Review
                                </Button>
                            </div>
                        </Row>
                    </Column>
                </Column>
            </Column>
        </Column>
    );
};

export default MeetingQuickForm;