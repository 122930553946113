import React, { useEffect, useRef, useState } from "react";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Theme from "@amzn/meridian/theme";
import Text from "@amzn/meridian/text";
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu";

import EmailAndChime from "./email-and-chime";
import TimePreferences from "./time-preferences";
import RoomBookingPreferences from "./room-booking-preferences";
import LayoutPreferences from "./layout-preferences";
import Favorites from "./favorites";
import OOTOTemplates from "./ooto-templates";

import HeaderBackground from "../../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";
import PatternBackground from "../../../../assets/backgrounds/pattern_small_vertical_repeating.svg";
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark";

import "../settings.css";

import { BACKGROUND_OPACITY, SCREEN_SIZE } from "../../shared-constants";
import { SIDE_MENU_ITEMS } from "../settings-constants";
import { WORKFLOWS } from "../../workflow/workflow-constants";
import { getUrlParameters } from "../../permalink/permalink-utils";

const SettingsForm = (props) => {
    const mastheadSize = props.mastheadSize;
    const timezones = props.timezones;
    const locations = props.locations;
    const [selectedItem, setSelectedItem] = useState(SIDE_MENU_ITEMS.CHIME_OPTIONS);
    const [sideMenuOpen, setSideMenuOpen] = useState(false);

    const onClickLink = (item) => {
        setSelectedItem(item);
        // only used in mobile mode
        setSideMenuOpen(false);
    };

    const renderSideMenuItems = () => Object.entries(SIDE_MENU_ITEMS).map(([key, val]) => (
        <SideMenuLink
            key={key}
            href={val}
            selected={selectedItem === val}
            onClick={onClickLink}
        >
            {val}
        </SideMenuLink>
    ));

    const renderPanelItems = () => (
        <React.Fragment>
            {selectedItem === SIDE_MENU_ITEMS.CHIME_OPTIONS &&
                <EmailAndChime
                    screenSizeBreakpoint={props.screenSizeBreakpoint}
                    chimeEmail={props.chimeEmail}
                    personalChimeMeetingId={props.personalChimeMeetingId}
                    usePersonalChimePin={props.usePersonalChimePin}
                    onChangeUsePersonalChimePin={props.onChangeUsePersonalChimePin}
                    enableAutoCallParticipants={props.enableAutoCallParticipants}
                    enableAutoCallRoomBooking={props.enableAutoCallRoomBooking}
                    onChangeChimeMeetingIdSelected={
                        props.onChangeChimeMeetingIdSelected
                    }
                    onChangeEnableAutoCallParticipants={props.onChangeEnableAutoCallParticipants}
                    onChangeEnableAutoCallRoomBooking={props.onChangeEnableAutoCallRoomBooking}
                />
            }
            {selectedItem === SIDE_MENU_ITEMS.TIME_SCHEDULE &&
                <TimePreferences
                    locations={locations}
                    timezones={timezones}
                    primaryTimezone={props.primaryTimezone}
                    timeFormat={props.timeFormat}
                    dateFormat={props.dateFormat}
                    onLoadFloors={props.onLoadFloors}
                    workingHourDays={props.workingHourDays}
                    workingHourStartTime={props.workingHourStartTime}
                    workingHourEndTime={props.workingHourEndTime}
                    onChangePrimaryTimezone={props.onChangePrimaryTimezone}
                    onChangeTimeFormat={props.onChangeTimeFormat}
                    onChangeDateFormat={props.onChangeDateFormat}
                    onChangeWorkingHourDays={props.onChangeWorkingHourDays}
                    onChangeWorkingHourStartTime={props.onChangeWorkingHourStartTime}
                    onChangeWorkingHourEndTime={props.onChangeWorkingHourEndTime}
                />
            }
            {selectedItem === SIDE_MENU_ITEMS.ROOM_BOOKING &&
                <RoomBookingPreferences
                    buildingList={props.buildings}
                    selectedBuilding={props.selectedBuilding}
                    onChangeSelectedBuilding={props.onChangeSelectedBuilding}
                    selectedFloor={props.selectedFloor}
                    floorLoaded={props.floorLoaded}
                    onChangeSelectedFloor={props.onChangeSelectedFloor}
                    onLoadFloors={props.onLoadFloors}
                    camera={props.camera}
                    display={props.display}
                    noRestricted={props.noRestricted}
                    noManaged={props.noManaged}
                    minimumSeats={props.minimumSeats}
                    availableOnly={props.availableOnly}
                    onCheckCamera={props.onCheckCamera}
                    onCheckDisplay={props.onCheckDisplay}
                    onCheckNoRestricted={props.onCheckNoRestricted}
                    onCheckNoManaged={props.onCheckNoManaged}
                    onChangeMinimumSeats={props.onChangeMinimumSeats}
                    onCheckAvailableOnly={props.onCheckAvailableOnly}
                />
            }
            {selectedItem === SIDE_MENU_ITEMS.LAYOUTS &&
                <LayoutPreferences
                    schedulingMode={props.schedulingMode}
                    calendarViewDesktop={props.calendarViewDesktop}
                    calendarViewMobile={props.calendarViewMobile}
                    onChangeSchedulingMode={props.onChangeSchedulingMode}
                    onChangeCalendarViewDesktop={props.onChangeCalendarViewDesktop}
                    onChangeCalendarViewMobile={props.onChangeCalendarViewMobile}
                />
            }
            {selectedItem === SIDE_MENU_ITEMS.FAVORITES &&
                <Favorites
                    identity={props.identity}
                    favorites={props.favorites}
                    findRooms={props.findRooms}
                    buildings={props.buildings}
                    locations={locations}
                    rooms={props.rooms}
                    onGetRASDataForPerson={props.onGetRASDataForPerson}
                    onGetRASDataForGroup={props.onGetRASDataForGroup}
                    onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                    onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                    onGetGroupSuggestions={props.onGetGroupSuggestions}
                    onClearGroupSuggestions={props.onClearGroupSuggestions}
                    onAddFavorite={props.onAddFavorite}
                    onGetRASDataForPersonAndAddToFavorite={props.onGetRASDataForPersonAndAddToFavorite}
                    onRemoveFavorite={props.onRemoveFavorite}
                    peopleSuggestions={props.peopleSuggestions}
                    groupSuggestions={props.groupSuggestions}
                    onLoadFloors={props.onLoadFloors}
                    onUpdateFindRooms={props.onUpdateFindRooms}
                    onAddRoom={props.onAddRoom}
                    onRemoveRoom={props.onRemoveRoom}
                    onAddLocation={props.onAddLocation}
                    onRemoveLocation={props.onRemoveLocation}
                    onAddRoomByEmail={props.onAddRoomByEmail}
                    onRemoveFavoriteByAlias={props.onRemoveFavoriteByAlias}
                    popoverAttendee={props.popoverAttendee}
                />
            }
            {selectedItem === SIDE_MENU_ITEMS.OOTO_TEMPLATES &&
                <OOTOTemplates />
            }
        </React.Fragment>
    );

    const header = useRef();

    // Begin new workflow content with focus on the <h1> heading
    useEffect(() => {
        if (header.current) {
            header.current.focus();
        }
    }, []);

    useEffect(() => {
        let queryParameters = getUrlParameters(WORKFLOWS.SETTINGS.HREF);

        // Set initial settings tab if it is in URL
        let tab = queryParameters.tab;
        if (tab && SIDE_MENU_ITEMS[tab]) {
            setSelectedItem(SIDE_MENU_ITEMS[tab]);
        }
    }, []);

    return (
        <Row height={"100vh"} width={"100vw"}>
            <Column
                height="100%"
                minHeight={`calc(100vh - ${mastheadSize} - ${"10px"})`} // 100vh - mastheadSize - footer
                heights={["fit", "fit", "fill"]}
                width="100%"
                spacing="none"
            >
                <div
                    style={{
                        backgroundImage: `url(${HeaderBackground})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgba(26, 69, 78)",
                        backgroundPosition: "200px -90px",
                        height: "50px",
                        width: "100%",
                    }}
                >
                    <Theme tokens={blueDarkTokens}>
                        <Row
                            height={"100%"}
                            alignmentVertical={"center"}
                            spacingInset={"medium"}
                        >
                            <Heading level={1} type={"h300"} ref={header} tabIndex="-1">
                                Preferences
                            </Heading>
                        </Row>
                    </Theme>
                </div>
                <Row id="settings-header-description">
                    <Text>
                        Customize your Amazon Meetings: set Amazon Chime options, default working hours, page layouts, and add people and rooms to favorites.
                    </Text>
                </Row>
                {props.screenSizeBreakpoint <= SCREEN_SIZE.MOBILE_VIEW ?
                    <React.Fragment>
                        <Row spacingInset={"medium"}>
                            <Button onClick={() => setSideMenuOpen(true)}>Open Preferences Menu</Button>
                            <SideMenu type="overlay" open={sideMenuOpen} onClose={() => setSideMenuOpen(false)}>
                                {renderSideMenuItems()}
                            </SideMenu>
                        </Row>
                        <Row>
                            {renderPanelItems()}
                        </Row>
                    </React.Fragment>
                    :
                    <Row
                        widths={["5%", "23%", "67%", "5%"]}
                        alignmentVertical="top"
                        alignmentHorizontal="center"
                    >
                        <div
                            style={{
                                height: "100%",
                                backgroundImage: `url(${PatternBackground})`,
                                backgroundRepeat: "repeat-y",
                                backgroundPosition: "left -250px center",
                                opacity: BACKGROUND_OPACITY,
                            }}
                        />
                        <Column id="settings-sidemenu-container">
                            <div className="settings-sidemenu">
                                <SideMenu>
                                    {renderSideMenuItems()}
                                </SideMenu>
                            </div>
                        </Column>
                        <Column height={"100%"}>
                            {renderPanelItems()}
                        </Column>
                        <div
                            style={{
                                height: "100%",
                                backgroundImage: `url(${PatternBackground})`,
                                backgroundRepeat: "repeat-y",
                                backgroundPosition: "right -250px center",
                                backgroundAttachment: "fixed",
                                opacity: BACKGROUND_OPACITY,
                            }}
                        />
                    </Row>}
            </Column>
        </Row>
    );
};

export default SettingsForm;
