/**
 * Author: shekwong@
 */

const END = {}

/**
 * Memoize a function with one argument.
 *
 * @example
 * const square = a => a * a
 * const memoizedSquare = memoize(square)
 * memoizedSquare(2) // 4
 */
function memoize(f) {
  const memo = new Map()
  return i => {
    if (memo.has(i)) {
      return memo.get(i)
    }
    const result = f(i)
    memo.set(i, result)
    return result
  }
}

/**
 * Memoize a function with N args by currying it
 *
 * @example
 * const multiply = (a, b) => a * b
 * const memoizedMultiply = memoize(multiply, 1)
 * memoizedMultiply(2)(3) // 6
 */
function memoizeCurry(f, depth) {
  const a = f
  if (depth === 0) {
    return memoize(a)
  }
  return memoize(x => memoizeCurry(a.bind(null, x), depth - 1))
}

/**
 * A helper function used by memoizeByKeys.
 */
function memoizeByKeysCurry(f, resolver, depth) {
  const a = f
  if (depth === 0) {
    return memoize(resolver)
  }
  return memoize(x => memoizeByKeysCurry(a.bind(null, x), resolver, depth - 1))
}

/**
 * Memoize a function that accepts an object as its only argument. You must
 * specify the keys in the object to compare when determining whether to re-use
 * a previous calculation.
 *
 * NOTE: This can only support properties in the object that are simple (e.g.
 * strings, numbers, booleans, not nested objects or arrays).
 *
 * @example
 * const multiply = ({ a, b }) => a * b
 * cont memoizedMultiply = memoizeByKeys(multiply, ["a", "b"])
 * memoizedMultiply({ a: 2, b: 3 }) // 6
 */
function memoizeByKeys(f, keys) {
  let args = undefined
  const g = memoizeByKeysCurry(f, () => f(args), keys.length - 1)
  return a => {
    if (!a) {
      let c = g
      keys.forEach(() => {
        c = c(undefined)
      })
      return c
    }
    args = a
    let c = g
    keys.forEach(k => {
      c = c(args[k])
    })
    return c
  }
}

/**
 * A helper function used by memoizeArray.
 */
function memoizeByVarArgsCurry(f) {
  let a = f
  return memoize(x =>
    x === END ? a() : memoizeByVarArgsCurry(a.bind(null, x))
  )
}

/**
 * Memoize a function that accepts an array as its only argument.
 *
 * @example
 * const multiply = numbers => numbers.reduce((result, num) => result * num)
 * cont memoizedMultiply = memoizeArray(multiply)
 * memoizedMultiply([2, 3, 4]) // 24
 */
function memoizeArray(f) {
  const memoized = memoizeByVarArgsCurry((...args) => f(args))
  return vals => {
    let g = memoized
    vals.forEach(v => {
      g = g(v)
    })
    return g(END)
  }
}

export { memoize, memoizeCurry, memoizeByKeys, memoizeArray }
