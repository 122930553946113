module.exports = {
    DEFAULT_GROUP_COLUMN_WIDTH: 200,
    DEFAULT_COLUMN_WIDTH: 160, // Should be a multiple of 4, so every 15 minute interval is even
    TIME_ROW_HEIGHT: 36,
    ADDITIONAL_TIMEZONE_ROW_HEIGHT: 22,
    TIME_SELECTOR_Z_INDEX: 23,
    TIME_SELECTOR_ROW_HEIGHT: 24,
    TIME_SELECTOR_BORDER_WIDTH: 2,
    TIME_SELECTOR_CIRCLE_RADIUS: 9,
    TIME_SELECTOR_TIME_HEIGHT: 18,
    TIME_SELECTOR_TIME_WIDTH: 58,
    TIME_SELECTOR_TIME_OFFSET: 6,
    DRAG_COMPONENT: {
        // Drag to change time of meeting without changing duration
        TIME: "time",
        // Drag to change start time and change duration
        START_TIME: "start_time",
        // Drag to change end time and change duration
        END_TIME: "end_time",
    },
    TIME_SELECTOR_COLOR: {
        BACKGROUND: "#0AABC4",
        EDGE: "#0AABC4",
        TIME: "#B5EBF3"
    },
    STATUS: {
        FREE: 0,
        TENTATIVE: 1,
        BUSY: 2,
        OOTO: 3
    },
    FIFTEEN_MINUTES: 15,
    MIN_BLOCK_UNIT: 0.25, // 15 minutes / 60 minutes = 0.25
    TIME_BLOCK_POSITION: {
        START: 0,
        MIDDLE: 1,
        END: 2
    },
};
