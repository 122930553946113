import React from "react"
import SearchSuggestion from "./search-suggestion"
import SearchSuggestionFooter from "./search-suggestion-footer"
import SearchSuggestionHeader from "./search-suggestion-header"
import isElementOf from "../../_utils/is-element-of"

const getSuggestions = children =>
  React.Children.toArray(children)
    .filter(isElementOf(SearchSuggestion))
    .map((child, index) => {
      const { children, onClick, disabled } = child.props
      const [label, render] =
        typeof children === "function"
          ? [children({}).props.suggestion, children]
          : [children, null]
      return {
        value: index,
        disabled,
        render,
        label,
        onClick,
      }
    })

const getFooter = children =>
  children.filter(isElementOf(SearchSuggestionFooter))[0]

const getHeader = children => {
  const header = children.filter(isElementOf(SearchSuggestionHeader))[0]
  return header ? header.props.children : "Search suggestions"
}

const getMessage = children =>
  children.filter(
    child =>
      !isElementOf(SearchSuggestion)(child) &&
      !isElementOf(SearchSuggestionFooter)(child) &&
      !isElementOf(SearchSuggestionHeader)(child)
  )

const getNonSuggestions = children => {
  const childrenArray = React.Children.toArray(children)
  const footer = getFooter(childrenArray)
  const header = getHeader(childrenArray)
  const message = getMessage(childrenArray)

  return {
    footer,
    header,
    message,
  }
}

export { getNonSuggestions, getSuggestions }
