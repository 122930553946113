import eachDayOfInterval from "date-fns/eachDayOfInterval"
import endOfWeek from "date-fns/endOfWeek"
import startOfWeek from "date-fns/startOfWeek"
import startOfMonth from "date-fns/startOfMonth"
import endOfMonth from "date-fns/endOfMonth"
import parseISO from "date-fns/parseISO"
import getDayOfWeekIndex from "date-fns/getDay"
import { getDateLocale } from "./date-locales"
import { intlDateTimeFormat } from "./intl-date-time-format"
import { toDateString, isSameMonth, getDay } from "./date-string"

const weekDays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

/**
 * Return all the days in a calendar month along with localized labels for the
 * month and days of the week.
 */
const getCalendarMonth = ({
  date,
  locale,
  monthLabelFormat,
  headerLabelFormat = { weekday: "short" }, // default to short format (eg: Mon instead of Monday)
}) => {
  const dateObject = parseISO(date)
  const weekStartsOn = getDateLocale(locale).weekStart
  const days = eachDayOfInterval({
    start: startOfWeek(startOfMonth(dateObject), { weekStartsOn }),
    end: endOfWeek(endOfMonth(dateObject), { weekStartsOn }),
  }).map(dateObject => {
    const dateString = toDateString(dateObject)
    return {
      dateObject,
      dateString,
      label: getDay(dateString),
      long: intlDateTimeFormat(
        dateObject,
        { month: "long", day: "numeric", year: "numeric" },
        locale
      ),
      inMonth: isSameMonth(dateString, date),
    }
  })
  const dayOfWeekLabels = days.slice(0, 7).map(({ dateObject }) => {
    // We add this normalized, English weekday value to pass to renderHeader
    // so developers customizing the header won't need to write logic for
    // each locale.
    const weekday = weekDays[getDayOfWeekIndex(dateObject)]
    return {
      label: intlDateTimeFormat(dateObject, headerLabelFormat, locale),
      long: intlDateTimeFormat(dateObject, { weekday: "long" }, locale),
      weekday,
    }
  })
  const monthLabel =
    monthLabelFormat && intlDateTimeFormat(dateObject, monthLabelFormat, locale)
  return { days, dayOfWeekLabels, monthLabel }
}

export default getCalendarMonth
