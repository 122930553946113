// Configs for ResourceAdapterService
export const ENDPOINTS = {
    Alpha: {
        PDX: "https://api.pdx.alpha.ras.meetings.enterprise-engineering.aws.dev/alpha/"
    },
    Beta: {
        PDX: "https://api.pdx.beta.ras.meetings.enterprise-engineering.aws.dev/beta/",
        IAD: "https://api.iad.beta.ras.meetings.enterprise-engineering.aws.dev/beta/",
        DUB: "https://api.dub.beta.ras.meetings.enterprise-engineering.aws.dev/beta/",
        NRT: "https://api.nrt.beta.ras.meetings.enterprise-engineering.aws.dev/beta/"
    },
    BetaPrime: {
        PDX: "https://s0xff53913.execute-api.us-west-2.amazonaws.com/prod/", // BetaPrime will talk to NAWS RAS Prod
        IAD: "https://uh3jzld160.execute-api.us-east-1.amazonaws.com/prod/",
        DUB: "https://szlky18beh.execute-api.eu-west-1.amazonaws.com/prod/",
        NRT: "https://yxgybr2pm2.execute-api.ap-northeast-1.amazonaws.com/prod/",
    },
    Prod: {
        PDX: "https://api.pdx.prod.ras.meetings.enterprise-engineering.aws.dev/prod/",
        IAD: "https://api.iad.prod.ras.meetings.enterprise-engineering.aws.dev/prod/",
        DUB: "https://api.dub.prod.ras.meetings.enterprise-engineering.aws.dev/prod/",
        NRT: "https://api.nrt.prod.ras.meetings.enterprise-engineering.aws.dev/prod/",
    },

    // Dev Config
    alvinyu: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    amiyyapu: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    amberyi: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    anirudps: {
    	PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    camei: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    daverod: {
        PDX: "https://api.pdx.alpha.ras.meetings.enterprise-engineering.aws.dev/alpha/", // Alpha
        // PDX: "https://api.pdx.beta.ras.meetings.enterprise-engineering.aws.dev/beta/",
        // IAD: "https://api.iad.beta.ras.meetings.enterprise-engineering.aws.dev/beta/",
        // DUB: "https://api.dub.beta.ras.meetings.enterprise-engineering.aws.dev/beta/",
        // NRT: "https://api.nrt.beta.ras.meetings.enterprise-engineering.aws.dev/beta/"
    },
    kjoshuaz: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    mahtabr: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    migueldz: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    niskar: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    tejavk: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    uccdev: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    yangzhuo: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    },
    ziyuyang: {
        PDX: "https://dce6c8vtlb.execute-api.us-west-2.amazonaws.com/alpha/"
    }
};

export const OPERATIONS = {
    CREATE_MEETING: "createMeeting",
    CREATE_CHIME_PIN: "createPin",
    DELETE_MEETING: "deleteMeeting",
    FIND_GROUPS: "findGroups",
    FIND_MEETINGS: "findMeetings",
    GET_BUILDING_WITH_QUERY_STRING: "getBuildingWithQueryString",
    GET_CACHED_RESOURCE_AVAILABILITY: "getCachedResourceAvailability",
    GET_CONF_ROOM_WITH_QUERY_STRING: "getConfRoomWithQueryString",
    GET_EXCHANGE_PREFERENCES: "getExchangePreferences",
    GET_GROUP: "getGroup",
    GET_MEETING: "getMeeting",
    GET_PERSON: "getPerson",
    GET_SCHEDULE: "getSchedule",
    GET_SUGGESTION: "getSuggestion",
    GET_TIMEZONES: "getTimezones",
    GET_USER_BY_EMAIL: "getUserByEmail",
    PEOPLE_API_SEARCH_ALL: "peopleAPISearchAll",
    TURN_MEETING_REMINDERS_OFF: "turnMeetingRemindersOff",
    UPDATE_MEETING: "updateMeeting",
    RECLAIM_RESOURCES: "reclaimResources",
};

// Paths can be obtained from:
// https://code.amazon.com/packages/ResourceAdapterLambdaCDK/blobs/mainline/--/src/resource-adapter-lambda/RequestHandlers/operations.js
export const OPERATION_PATHS = {
    createMeeting: "api/meetings/create",
    createPin: "api/chime/create",
    deleteMeeting: "api/meetings/delete",
    findGroups: "api/groups/find",
    findMeetings: "api/meetings/find",
    getBuildingWithQueryString: "api/buildings/search",
    getCachedResourceAvailability: "api/availability/cached",
    getConfRoomWithQueryString: "api/conference-rooms/search",
    getExchangePreferences: "api/preferences/exchange/user",
    getGroup: "api/groups/get",
    getMeeting: "api/meetings/get",
    getPerson: "api/people",
    getSchedule: "api/scheduling/schedule",
    getSuggestion: "api/scheduling/suggestion",
    getTimezones: "api/timezones",
    getUserByEmail: "api/chime/user/get",
    peopleAPISearchAll: "api/people/search",
    turnMeetingRemindersOff: "api/meeting/update/reminders/off",
    updateMeeting: "api/meetings/update",
    reclaimResources: "api/meeting/update/reclaim",
};
