import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Modal, { ModalFooter } from "../meridian-custom-components/src/components/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import oopsEmojiSvg from "../../../assets/icons/shared/oops_emoji.svg";

import MeetingsModalHeader from "./meetings-modal-header";

import { getWorkflow } from "../../../sagas/selector";
import { clearDraft as clearDraftMeeting } from "../../meeting-scheduler/actions";
import { clearDraft as clearDraftOOTO } from "../../meeting-ooto/actions";
import { clearDraft as clearDraftLivestream } from "../../meeting-livestream/actions";
import { clearDraft as clearDraftPeople } from "../../people/actions";
import { clearLocationAndRooms } from "../locations/actions";
import { clearMeetingPollTimeSlotsSelectedMarker } from "../../meeting-polls/actions";
import { WORKFLOWS } from "../workflow/workflow-constants";
import { changePage } from "../workflow/workflow-utils";

const DiscardMeetingModal = (props) => {
    const dispatch = useDispatch();
    const currentWorkflow = useSelector(getWorkflow);

    const [open, setOpen] = [props.open, props.setOpen];
    const onClose = props.onClose;

    const onClickConfirm = () => {
        if (onClose) {
            onClose();
        }

        // TODO: Need to fix states so we only clear the flow that the user was working on
        switch (currentWorkflow) {
            case WORKFLOWS.STANDARD_MEETING:
            case WORKFLOWS.EDIT_MEETING:
            case WORKFLOWS.OOTO:
            case WORKFLOWS.LIVESTREAM:
                dispatch(clearDraftMeeting());
                dispatch(clearDraftOOTO());
                dispatch(clearDraftLivestream());
                dispatch(clearDraftPeople());
                dispatch(clearLocationAndRooms());
                break;
            case WORKFLOWS.POLL_CREATE:
            case WORKFLOWS.EDIT_POLL:
                dispatch(clearDraftPeople());
                dispatch(clearMeetingPollTimeSlotsSelectedMarker());
                break;
            case WORKFLOWS.SIMPLE_MEETING: // All states cleared in the resetQuickMeetingModal function whenever modal is closed
            default:
                break;
        }
        setOpen(false);
        changePage(dispatch, currentWorkflow, props.newWorkflow);
    };

    const discardModalId = "discardModal";

    return (
        <Modal
            open={open}
            bodySpacingInset="none"
            width="504px"
            backgroundElementsId={props.backgroundElementsId}
        >
            <MeetingsModalHeader
                modalId={discardModalId}
                modalTitle={props.modalTitle || "Discard meeting"}
                showCustomCloseButton={true}
                onCloseModal={() => setOpen(false)}
            />
            <Row id={discardModalId} spacingInset="medium">
                <Column>
                    <img src={oopsEmojiSvg} style={{"height": "70px", "width": "65px"}}
                         alt="oops discard meeting icon"/>
                </Column>
                <Column spacing="none">
                    <Text type="b300">{currentWorkflow === WORKFLOWS.POLL_CREATE || currentWorkflow === WORKFLOWS.EDIT_POLL ? `Discard your meeting poll?` : `Are you sure you want to discard your meeting?`}</Text>
                    <Text type="b300">This action cannot be undone.</Text>
                </Column>
            </Row>
            <ModalFooter>
                <Row spacing="medium" alignmentHorizontal="right">
                    <Button type="secondary" size="medium" onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        type="primary"
                        size="medium"
                        onClick={onClickConfirm}
                    >
                        Yes, I'm sure
                    </Button>
                </Row>
            </ModalFooter>
        </Modal>
    )
};

export default DiscardMeetingModal;