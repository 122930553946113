import React, { useState } from "react";

import _ from "lodash";

import {
    groupToAttendee,
    externalEmailToAttendee,
    renderAddAllButton,
    renderShowHideButton,
    renderUserNameLink,
    renderAddButton,
    renderEmailLink
}  from "../people-utils";
import { ATTENDEE_POPOVER_MAX_LIST_SIZE, GROUP_MEMBER_TYPE } from "../people-constants";
import { renderFavoriteTypeIcon } from "../../shared/favorites/favorites-utils";
import { FAVORITE_TYPE } from "../../shared/favorites/favorites-constants";

import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

const GroupAttendeeCard = (props) => {
    const attendee = props.attendee;
    const attendeeList = props.attendeeList;
    const members = (attendee.rasData && _.orderBy(attendee.rasData.members, "username", "asc")) || [];
    const isFavorited = props.isFavorited;

    const onGetRASDataForPersonAndAddToFavorite = props.onGetRASDataForPersonAndAddToFavorite;
    const onRemoveFavorite = props.onRemoveFavorite;

    const allAttendeesAdded = (attendees) => {
        return attendees && !attendees.some((attendee) => !attendeeList.includes(attendee.username));
    };

    const attendeesUnderLimit = (attendees) => {
        return attendees && attendees.filter((attendee) => !attendeeList.includes(attendee.username)).length <= ATTENDEE_POPOVER_MAX_LIST_SIZE;
    };

    const allMembersAdded = allAttendeesAdded(members);
    const membersUnderLimit = attendeesUnderLimit(members);
    const [membersExpanded, setMembersExpanded] = useState(false);
    const disableAddFromPopover = props.disableAddFromPopover || onGetRASDataForPersonAndAddToFavorite !== undefined;

    const onAddMember = (member) => {
        if (onGetRASDataForPersonAndAddToFavorite !== undefined) {
            if (!isFavorited(member)) {
                onGetRASDataForPersonAndAddToFavorite(member.username);
            }
        } else if (!attendeeList.includes(member.username)) {
            if (member.type === "user") {
                props.onGetRASDataForPerson(member.username, true);
            } else if (member.type === "group") {
                props.onAddAttendee(groupToAttendee(member.username));
            } else {
                // example member object from amazon-meetings-interest:
                // {type: "unknown", username: "git-me-meetingexperience@amazon.com"}
                props.onAddAttendee(externalEmailToAttendee(member.username))
            }
        }
    };

    const onRemoveMember = (member) => {
        if (onRemoveFavorite !== undefined) {
            onRemoveFavorite(member.username);
        } else {
            props.onRemoveAttendeeByAlias(member.username)
        }
    };

    const onAddMembers = (members) => {
        members.forEach((member) => onAddMember(member));

        if (onGetRASDataForPersonAndAddToFavorite === undefined) {
            // remove this group if add all members is selected
            props.onRemoveAttendeeByAlias(attendee.alias);
        }
    };

    const onRemoveMembers = (membersToRemove) => {
        membersToRemove.forEach((member) => onRemoveMember(member));
    };

    return (
        attendee.rasData ?
            <Column spacing="small">
                <Row spacing="xsmall">
                    {renderFavoriteTypeIcon(FAVORITE_TYPE.GROUP)}
                    {renderEmailLink(attendee.email, "b300", attendee.identifier, "bold")}
                </Row>
                <Divider size="small" />
                <Row widths={!disableAddFromPopover && ["grid-4", "grid-8"]}>
                    <Text alignment="right" type="b200">Members</Text>
                    {members.length ?
                        <Row widths={!disableAddFromPopover && ["fit", "fit", "fill"]}>
                            <Text alignment="left" type="b100">{members.length}</Text>
                            {!disableAddFromPopover && renderAddAllButton(allMembersAdded, membersUnderLimit, members, onAddMembers, onRemoveMembers, attendee.name, GROUP_MEMBER_TYPE.MEMBERS)}
                            {renderShowHideButton(members.length, membersExpanded, setMembersExpanded, attendee.name, GROUP_MEMBER_TYPE.MEMBERS)}
                        </Row>
                        :
                        <Text alignment="left" type="b100">N/A</Text>
                    }
                </Row>
                {members.length && membersExpanded &&
                    <Column spacing="xsmall" maxHeight="250px" overflowY="auto">
                        {members.map((member) => (
                            <Row widths={["grid-4", "grid-8"]} key={member.username}>
                                {member.type === "user" ?
                                    renderUserNameLink(member.username)
                                    :
                                    <Text alignment="right" type="b100">{member.username}</Text>
                                }
                                {!disableAddFromPopover && renderAddButton(member, attendeeList, onAddMember, onRemoveMember)}
                            </Row>
                        ))}
                    </Column>
                }
            </Column>
            :
            <Loader />
    );
};

export default GroupAttendeeCard;
