import React, { useEffect, useState } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
// import Expander from "@amzn/meridian/expander";
import Card, { CardCheckbox } from "@amzn/meridian/card";
import Tag from "@amzn/meridian/tag";
import Tooltip from "@amzn/meridian/tooltip";

import "../meeting-polls.css";

import {
    formatDateTime,
    showAttendee,
    getAvailabilityStatusAccent,
} from "../poll-utils";
import { renderPollPriorityIcon } from "../../people/people-utils";
import { POLL_PRIORITY } from "../../people/people-constants";
import { MEETING_RESPONSE, MEETING_STATUS } from "../../shared/shared-constants";

const PollRespondCard = (props) => {
    const respondedAttendeeList = props.respondedAttendeeList;
    const availability = props.availability;
    const noneBoxChecked = props.noneBoxChecked;
    const [availabilityVotes, setAvailabilityVotes] = [props.availabilityVotes, props.setAvailabilityVotes];
    const response = props.response;
    const attendeeAvailabilityList = props.attendeeAvailabilityList;
    const meetingsForAvailability = props.meetingsForAvailability;

    // Time and Timezone
    const startTime = availability.startTime;
    const endTime = availability.endTime;
    const startTimeInSeconds = startTime / 1000;
    const endTimeInSeconds = endTime / 1000;
    const timezone = props.timezone;
    const timeFormat = props.timeFormat;
    const browserTimezoneName = props.browserTimezoneName;

    const [checked, setChecked] = useState(false);

    // Commented out based on current design
    //const [expanderOpen, setExpanderOpen] = useState();
    const expanderOpen = false;

    const availableAttendeeList = (respondedAttendeeList && availability && respondedAttendeeList.filter(
        (attendee) => attendee.availability && attendee.availability.find(
            (attendeeAvailability) => attendeeAvailability.startTime <= availability.startTime && attendeeAvailability.endTime >= availability.endTime) !== undefined
    )) || [];
    const unavailableAttendeeList = (respondedAttendeeList && respondedAttendeeList.filter(
        (attendee) => availableAttendeeList && !availableAttendeeList.includes(attendee)
    )) || [];
    const meetingsInConflict = (meetingsForAvailability && meetingsForAvailability.filter(
        (meeting) => meeting.status && meeting.status !== MEETING_STATUS.FREE && meeting.time && startTimeInSeconds && endTimeInSeconds &&
            ((meeting.time.startTime <= startTimeInSeconds && meeting.time.endTime > startTimeInSeconds) || (meeting.time.startTime >= startTimeInSeconds && meeting.time.startTime < endTimeInSeconds))
    )) || [];
    const availabilityStatus = meetingsInConflict.length > 0 ? "Conflict" : "";

    const onSelectCard = () => {
        if (!noneBoxChecked && !checked) {
            setChecked(true);
            // Check if already put in votes
            if (!availabilityVotes.find((currentAvailability) => JSON.stringify(currentAvailability) === JSON.stringify(availability))) {
                setAvailabilityVotes([...availabilityVotes, availability]);
            }
        } else {
            setChecked(false);
            setAvailabilityVotes(availabilityVotes.filter((currentAvailability) =>
                JSON.stringify(currentAvailability) !== JSON.stringify(availability)
            ));
        }
    };

    const renderMeetingInTooltip = (meeting) => {
        switch(meeting.response) {
            case MEETING_RESPONSE.NOT_RESPONDED:
                return <Text type="b200" color="inverted">[NotResponded] {meeting.subject}</Text>;
            case MEETING_RESPONSE.ACCEPT:
                return <Text type="b200" color="inverted">[Attending] {meeting.subject}</Text>;
            case MEETING_RESPONSE.TENTATIVE:
                return <Text type="b200" color="inverted">[Tentative] {meeting.subject}</Text>;
            case MEETING_RESPONSE.ORGANIZER:
                return <Text type="b200" color="inverted">[{meeting.status === MEETING_STATUS.OUT_OF_OFFICE ? "OOTO" : "Organizer"}] {meeting.subject}</Text>
            default:
               return;
        }
    };

    const renderMeetingsInConflict = (meetings) => {
        return (
            <React.Fragment>
                <Text type="b200" color="inverted">This time slot conflicts with:</Text>
                {meetings && meetings.map((meeting) =>
                    <Column spacing="none">
                        {renderMeetingInTooltip(meeting)}
                    </Column>
                )}
            </React.Fragment>
        );
    };

    useEffect(() => {
        if (noneBoxChecked && checked) {
            setChecked(false);
        }
    }, [noneBoxChecked, checked]);

    useEffect(() => {
        if (response && attendeeAvailabilityList?.length > 0 && attendeeAvailabilityList.some((attendeeAvailability) =>
            JSON.stringify(attendeeAvailability) === JSON.stringify(availability))) {
            onSelectCard();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendeeAvailabilityList]);

    useEffect(() => {
        const checkBox = document.getElementById("card-checkbox-" + props.index);
        if (checkBox) {
            checkBox.ariaChecked = undefined;
            if (checked) {
                checkBox.setAttribute("checked", "");
            } else {
                checkBox.removeAttribute("checked");
            }
        }
        const card = document.getElementById("card-" + props.index)?.firstChild;
        if (card) {
            card.removeAttribute("role");
        }
    }, [checked, props.index]);

    return (
        <div id={"card-" + props.index}>
            <Card
                spacingInset="medium"
                checked={checked}
                disabled={noneBoxChecked}
                onClick={onSelectCard}
                value={checked}
                class="tag-text"
            >
                <Row alignmentVertical="top" spacingInset="none">
                    <div>
                        <CardCheckbox id={"card-checkbox-" + props.index} />
                    </div>
                    <Column spacing="none" spacingInset="none">
                        <Row spacing="small" spacingInset="none">
                            <Column alignmentHorizontal="left" spacing="xxsmall" spacingInset="small none none none">
                                <Text type="h300">
                                    {formatDateTime(startTimeInSeconds, "dayOfDate", timezone)}
                                </Text>
                                <Text type="b200" color="secondary">
                                    {formatDateTime(startTimeInSeconds, "shortMonth", timezone).toUpperCase()}
                                </Text>
                            </Column>
                            <Column alignmentHorizontal="left" spacing="small" spacingInset="small none none none">
                                <Text type="b100" color="secondary">
                                    {formatDateTime(startTimeInSeconds, "shortWeekday", timezone).toUpperCase()}
                                </Text>
                                <Row spacing="xxsmall" width="115px">
                                    <div className="tag-text">
                                        {formatDateTime(startTimeInSeconds, "time", timezone, timeFormat).replace(/[A-Z\s+]/g, "")}-{formatDateTime(endTimeInSeconds, "time", timezone, timeFormat)}
                                    </div>
                                    <Text type="b100" color="secondary">
                                        {browserTimezoneName}
                                    </Text>
                                </Row>
                            </Column>
                        </Row>
                        <Column width="100px" spacingInset="none">
                            {availabilityStatus &&
                                <Tooltip
                                    position="right"
                                    title={renderMeetingsInConflict(meetingsInConflict)}
                                    id="meetings-in-conflict"
                                    maxWidth="500px"
                                >
                                    <Tag type={`${getAvailabilityStatusAccent(availabilityStatus)}`}>
                                        {availabilityStatus}
                                    </Tag>
                                </Tooltip>
                            }
                        </Column>
                    </Column>
                    {/* Comment out currently based on new design
                    <div className="poll-card-divider" />
                    <Column spacing="none" spacingInset="none">
                        <Row spacing="large" spacingInset="none">
                            <Column spacing="xsmall" spacingInset="small none none none">
                                <Text type="h100" color="secondary">
                                    Available
                                </Text>
                                <Text type="h500">
                                    {availableAttendeeList.length}
                                </Text>
                            </Column>
                            <Column spacing="xsmall" spacingInset="small none none small">
                                <Text type="h100" color="secondary">
                                    Unavailable
                                </Text>
                                <Text type="h500">
                                    {unavailableAttendeeList.length}
                                </Text>
                            </Column>
                        </Row>
                            <Expander
                                open={expanderOpen}
                                onChange={setExpanderOpen}
                                title="Attendees"
                                type="inline"
                            />
                    </Column>
                    */}
                </Row>
                {expanderOpen &&
                    <Column spacing="none" spacingInset="none none none large">
                        <Row spacing="medium" spacingInset="none none large none">
                            <Row spacing="small">
                                {renderPollPriorityIcon(POLL_PRIORITY.REQUIRED)}
                                <Text type="b100">
                                    Required
                                </Text>
                            </Row>
                            <Row spacing="small">
                                {renderPollPriorityIcon(POLL_PRIORITY.OPTIONAL)}
                                <Text type="b100">
                                    Optional
                                </Text>
                            </Row>
                        </Row>
                        <Row
                            alignmentVertical="top"
                            alignmentHorizontal="left"
                            spacing="xlarge"
                        >
                            <Column spacing="small">
                                <div className="tag-text">
                                    Available ({availableAttendeeList.length}/{respondedAttendeeList.length})
                                </div>
                                {availableAttendeeList && availableAttendeeList.map((attendee) =>
                                    <Column spacing="small">
                                        {showAttendee(attendee)}
                                    </Column>
                                )}
                            </Column>
                            <Column spacing="small" spacingInset="none none none large">
                                <div className="tag-text">
                                    Unavailable ({unavailableAttendeeList.length}/{respondedAttendeeList.length})
                                </div>
                                {unavailableAttendeeList && unavailableAttendeeList.map((attendee) =>
                                    <Column spacing="small">
                                        {showAttendee(attendee)}
                                    </Column>
                                )}
                            </Column>
                        </Row>
                    </Column>
                }
            </Card>
        </div>
    )
};

export default PollRespondCard;