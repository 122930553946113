import React from "react"
import PropTypes from "prop-types"
import { cx, css } from "emotion"
import keycode from "keycode"
import addMonths from "date-fns/addMonths"
import addDays from "date-fns/addDays"
import startOfMonth from "date-fns/startOfMonth"
import endOfMonth from "date-fns/endOfMonth"
import parseISO from "date-fns/parseISO"
import eachDayOfInterval from "date-fns/eachDayOfInterval"
import uniqueId from "lodash/uniqueId"
import noop from "lodash/noop"
import miniCalendarTokens from "@amzn/meridian-tokens/component/mini-calendar"
import chevronRightTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small"
import chevronLeftTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small"
import { elevationForeground } from "@amzn/meridian-tokens/base/elevation"
import MiniCalendarPage from "./mini-calendar-page"
import Button from "../button"
import Icon from "../icon"
import Focus from "../_focus"
import CarouselController from "../_carousel-controller"
import { Style } from "../theme"
import { getTokenHelper, memoizeTokenStyles } from "../../_utils/token"
import {
  isWithinRange,
  toDateString,
  differenceInMonthsFromRange,
  differenceInCalendarMonths,
} from "../../_utils/date-string"
import dateLocales from "../../_utils/date-locales"

const styles = memoizeTokenStyles(
  (t, { monthsInView, monthWidth }) =>
    css({
      boxSizing: "border-box",
      width:
        (monthWidth || t("monthWidth")) * monthsInView +
        t("spacingInset") * (monthsInView + 1),
      position: "relative",
      // Month navigation buttons
      "& > button": {
        position: "absolute",
        // NOTE: Scoot the nav buttons up by half the difference between the height
        // of the button (32px) and the height of the DayInput month header (24px).
        // The resulting 4px shift will ensure that the button and the header are
        // vertically aligned. A simpler flex-box based solution can't be used
        // because the button and the header can't be siblings (becuse the header
        // must shift left/right as the user changes the view but the button must
        // remain fixed).
        top: t("spacingInset") - 4,
        zIndex: elevationForeground,
        "&:nth-of-type(1)": {
          left: t("spacingInset"),
        },
        "&:nth-of-type(2)": {
          right: t("spacingInset"),
        },
      },
    }),
  ["monthsInView", "monthWidth"]
)

const containerStyles = css({
  outline: "none",
})

/**
 * This component renders a month calendar and allows the user to either select
 * a day in the current month or to browse other months.
 */
class MiniCalendar extends React.Component {
  static propTypes = {
    /**
     * This date is used to determine which month the calendar should display
     * by default. The date must be provided in the format YYYY-MM-DD.
     */
    viewDate: PropTypes.string.isRequired,
    /**
     * A function that will be called whenever the calendar wants to update the
     * view date itself.
     */
    onChangeViewDate: PropTypes.func.isRequired,
    /**
     * Determines whether changing months is animated with a sliding motion.
     */
    animateMonthChange: PropTypes.bool,
    /**
     * Children to be rendered in the container div, after the calendar itself.
     * This is an advanced feature meant for internal use.
     */
    children: PropTypes.node,
    /**
     * A function that can be used to disable specific dates. The function will
     * be passed date strings in the format YYYY-MM-DD and is expected to return
     * true for disabled dates and false otherwise.
     */
    disabledDates: PropTypes.func,
    /**
     * The locale to use when rendering the input (e.g. month names, names of
     * days of the week, etc.). See the [Intl API](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl)
     * for more information about date internationalization in JS.
     */
    locale: PropTypes.oneOf(Object.keys(dateLocales)),
    /**
     * Set the width of a single month.
     */
    monthWidth: PropTypes.number,
    /**
     * The number of calendar months to display at a time.
     */
    monthsInView: PropTypes.number,
    /**
     * A localized label for the next month button.
     */
    nextMonthLabel: PropTypes.string,
    /**
     * Define today's date (in the format YYYY-MM-DD). This date will be
     * marked in the calendar with a special indicator.
     */
    now: PropTypes.string,
    /**
     * A localized label for the previous month button.
     */
    previousMonthLabel: PropTypes.string,
    /**
     * A function that can be used to display specific dates as part of a range.
     * The function will be passed date strings in the format YYYY-MM-DD and is
     * expected to return true for dates in the range and false otherwise.
     */
    rangeDates: PropTypes.func,
    /**
     * A function that can be used to customize the rendering of a single day. The
     * function is passed an object which contains the following props.
     * ```
     * date:      `string` of date in the format YYYY-MM-DD
     * label:     `string` of the localized day of the month (e.g. "21")
     * current:   `boolean` set to `true` if the date is todays date
     * selected:  `boolean` set to `true` if date is selected
     * disabled:  `boolean` set to `true` if date is disabled
     * range:     `boolean` set to `true` if date is in the range of selected dates
     * ```
     */
    renderDay: PropTypes.func,
    /**
     * A function that can be used to display specific dates as selected. The
     * function will be passed date strings in the format YYYY-MM-DD and is
     * expected to return true for selected dates and false otherwise.
     */
    selectedDates: PropTypes.func,
    /**
     * A function that will be called when the user clicks on a date in the
     * calendar. The date will be passed to the function as a string with the
     * the format YYYY-MM-DD.
     */
    onClickDate: PropTypes.func,
    /**
     * A function that will be called when the user hovers over a date in the
     * calendar. The date will be passed to the function as a string with the
     * format YYYY-MM-DD.
     */
    onMouseEnterDate: PropTypes.func,
    /**
     * Use this attribute to associate a text label with the calendar popup
     *
     * @added by meetex team member (camei@)
     */
    "aria-label": PropTypes.string,
  }

  static defaultProps = {
    locale: "en-US",
    previousMonthLabel: "Previous month",
    nextMonthLabel: "Next month",
    monthsInView: 1,
    now: toDateString(new Date()),
    onClick: noop,
    selectedDates: () => false,
    rangeDates: () => false,
    disabledDates: () => false,
    animateMonthChange: true,
  }

  state = {
    focusDate: undefined,
  }

  id = uniqueId("mini-cal-")

  // Given a date and a number of days to step by (positive for stepping into
  // the future and negative for stepping into the past), increment the date
  // until the next enabled date is found. Note that this only checks dates
  // within 90 days of the given date to avoid stepping for infinity if all the
  // dates in the direction requested are disabled.
  getNextEnabledDate = (date, step) => {
    const { disabledDates } = this.props
    let result = addDays(parseISO(date), step)
    let tries = 0
    while (tries < 90 && disabledDates(toDateString(result))) {
      result = addDays(result, step)
      tries += 1
    }
    const nextEnabledDate = toDateString(result)
    return disabledDates(nextEnabledDate) ? undefined : nextEnabledDate
  }

  // Shift the view date X months into the future (positive) or past (negative)
  onShiftViewDate = months => {
    if (months !== 0) {
      const { viewDate, onChangeViewDate } = this.props
      onChangeViewDate(toDateString(addMonths(parseISO(viewDate), months)))
    }
  }

  onClickDate = date => {
    if (this.props.onClickDate) {
      this.props.onClickDate(date)
    }
    this.setState({ focusDate: date })
  }

  onKeyDownCalendarPage = event => {
    const { viewDate, monthsInView, onClickDate } = this.props
    const { focusDate } = this.state
    const key = keycode(event)
    const dayChange = { up: -7, right: 1, down: 7, left: -1 }[key]
    const nextFocusDate =
      focusDate && dayChange
        ? this.getNextEnabledDate(focusDate, dayChange)
        : undefined
    let keyUsed = false

    // Move the focus to a new date when the user navigates with arrow keys
    if (nextFocusDate && nextFocusDate !== focusDate) {
      this.setState({ focusDate: nextFocusDate })
      // If the user focused a date outside of the view then change the view
      const differenceInMonths = differenceInMonthsFromRange(
        nextFocusDate,
        viewDate,
        toDateString(addMonths(parseISO(viewDate), monthsInView - 1))
      )
      this.onShiftViewDate(differenceInMonths)
      keyUsed = true
    }

    // Select the focused date when the user hits enter or space
    if (focusDate && (key === "enter" || key === "space")) {
      onClickDate(focusDate)
      keyUsed = true
    }

    // Don't perform the normal action associated with the key (e.g. scroll for
    // arrows/space) if the key was used for an input-related action
    if (keyUsed) {
      event.preventDefault()
    }
  }

  onFocusCalendarPage = () => {
    const { viewDate, monthsInView, disabledDates } = this.props
    const { focusDate } = this.state
    const startOfView = startOfMonth(parseISO(viewDate))
    const endOfView = endOfMonth(
      addMonths(parseISO(viewDate), monthsInView - 1)
    )
    // Make sure the focused day is in view when the user focuses on the input
    const focusInView =
      focusDate &&
      isWithinRange(
        focusDate,
        toDateString(startOfView),
        toDateString(endOfView)
      )
    const nextFocusDate = !focusInView
      ? eachDayOfInterval({ start: startOfView, end: endOfView })
          .map(date => toDateString(date))
          .filter(date => !disabledDates(date))[0]
      : undefined
    if (nextFocusDate) {
      this.setState({ focusDate: nextFocusDate })
    }
  }

  renderNavButton(direction) {
    const { previousMonthLabel, nextMonthLabel } = this.props
    const { icon, change, label } = {
      left: {
        icon: chevronLeftTokens,
        change: -1,
        label: previousMonthLabel,
      },
      right: {
        icon: chevronRightTokens,
        change: 1,
        label: nextMonthLabel,
      },
    }[direction]
    return (
      <Button
        type="icon"
        size="small"
        onClick={() => this.onShiftViewDate(change)}
      >
        <Icon tokens={icon}>{label}</Icon>
      </Button>
    )
  }

  render() {
    const props = this.props
    const state = this.state
    const viewDateIndex = differenceInCalendarMonths(props.viewDate, props.now)
    return (
      <Style tokens={miniCalendarTokens} map={getTokenHelper("miniCalendar")}>
        {t => (
          <div
            className={styles(t, {
              monthsInView: props.monthsInView,
              monthWidth: props.monthWidth,
            })}
          >
            {this.renderNavButton("left")}
            {this.renderNavButton("right")}
            <Focus onFocus={this.onFocusCalendarPage}>
              {({ focus, focusTrigger, focusProps }) => {
                const focusDate =
                  focus && focusTrigger === "keyboard"
                    ? state.focusDate
                    : undefined
                return (
                  <CarouselController
                    renderContainer={({ props: containerProps }) => (
                      /* eslint-disable-next-line jsx-a11y/role-supports-aria-props */
                      <div
                        {...containerProps}
                        className={cx(
                          containerProps.className,
                          containerStyles
                        )}
                        onKeyDown={this.onKeyDownCalendarPage}
                        tabIndex="0"
                        aria-activedescendant={
                          focusDate ? `${this.id}-${focusDate}` : undefined
                        }
                        {...focusProps}
                        role="dialog"
                        aria-label={props["aria-label"]}
                      />
                    )}
                    renderSlide={({ props: slideProps, index }) => (
                      <MiniCalendarPage
                        {...slideProps}
                        hidden={slideProps["aria-hidden"]}
                        t={t}
                        focusDate={focusDate}
                        viewDate={toDateString(
                          addMonths(parseISO(props.now), index)
                        )}
                        now={props.now}
                        selectedDates={props.selectedDates}
                        rangeDates={props.rangeDates}
                        disabledDates={props.disabledDates}
                        dateIds={date => `${this.id}-${date}`}
                        locale={props.locale}
                        onClickDate={this.onClickDate}
                        onMouseEnterDate={props.onMouseEnterDate}
                        renderDay={props.renderDay}
                      />
                    )}
                    slideIndex={viewDateIndex}
                    slidesInView={props.monthsInView}
                    motionDuration={
                      props.animateMonthChange
                        ? parseInt(t("monthMotionDuration"))
                        : 0
                    }
                    motionFunction={t("monthMotionFunction")}
                    spacingInset={t("spacingInset")}
                    spacingHorizontal={t("spacingInset")}
                  />
                )
              }}
            </Focus>
            {props.children}
          </div>
        )}
      </Style>
    )
  }
}

export default MiniCalendar
