import React from "react"
import PropTypes from "prop-types"

/**
 * Pass this as a child to the `SearchField` component to render a header above
 * the suggestions in the search suggestion popover.
 *
 * Use this to label a list of search suggestions.
 */
class SearchSuggestionHeader extends React.Component {
  static propTypes = {
    /**
     * The label to show in the header.
     */
    children: PropTypes.string.isRequired,
  }

  render() {
    // Actual rendering is done in ./search-suggestion-popover.js
    return null
  }
}

export default SearchSuggestionHeader
