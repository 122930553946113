import { FAVORITE_TYPE } from "../features/shared/favorites/favorites-constants";
import { getBuildingLabel } from "../features/shared/locations/locations-utils";
import {
    getMeetingSuggestions,
    getQuickMtgSuggestions,
    mapCachedAvailabilityList
} from "../features/shared/meeting-utils";
import { determinePrimaryBrowserTimezoneId } from "../features/shared/timezones/timezones-utils";

// Identity

export const getIdentity = (state) => state.identity.identity;
export const getIdentityTokens = (state) => state.identity.identityTokens;
export const getSigningCredentials = (state) => state.identity.signingCredentials;
export const getStageConfig = (state) => state.identity.stageConfig;

// Meeting

export const getCurrentMeeting = (state) => state.meeting.currentMeeting;
export const getMeetingStep = (state) => state.meeting.step;
export const getMasterState = (state) => state.meeting.master;
export const getCreatingMeetingState = (state) => state.meeting.isCreatingMeeting;
export const getSuggestionViewType = (state) => state.meeting.suggestionViewType;
export const getLoadMeeting = (state) => state.meeting.loadMeeting;

// OOTO

export const getOOTOMeeting = (state) => state.ooto.currentMeeting;
export const getOOTOStep = (state) => state.ooto.step;
export const getAwarenessMeeting = (state) => state.ooto.awarenessMeeting;
export const getLoadOOTO = (state) => state.ooto.loadOOTO;
export const getCreatingMeetingStateOOTO = (state) => state.ooto.isCreatingMeeting;

// Livestream

export const getLivestreamMeeting = (state) => state.livestream.currentMeeting;
export const getLivestreamStep = (state) => state.livestream.step;
export const getLivestreamSuggestionViewType = (state) => state.livestream.suggestionViewType;
export const getLivestreamStations = (state) => state.livestream.selectedStations;
export const getLivestreamCreatingMeetingState = (state) => state.livestream.isCreatingMeeting;
export const getPermissionPeopleSuggestionsSelector = (state) => state.eventIntakeForm.peopleSuggestions;
export const getPermissionGroupSuggestionsSelector = (state) => state.eventIntakeForm.permissionGroupSuggestions;
export const getLatestEventSelector = (state) => state.eventIntakeForm.latestEvent;
export const getLatestEventErrorDetailsSelector = (state) => state.eventIntakeForm.latestEventErrorDetails;
export const getLivestreamEventSeriesUrlAvailabilitySelector = (state) => state.eventIntakeForm?.url_availability;
export const getLivestreamIntakeLoadedState = (state) => state.eventIntakeForm?.livestreamIntakeLoaded;

// Calendar

export const getCalendarViewType = (state) => state.calendar.calendarSettings.viewType;
export const getCalendarDate = (state) => state.calendar.calendarSettings.date;
export const getCalendarStatusFilter = (state) => state.calendar.calendarSettings.calendarStatusFilter;
export const getCalendarResponseFilter = (state) => state.calendar.calendarSettings.calendarResponseFilter;

export const getMeetingListCalendarLoaded = (state) => state.calendar.calendarView.meetingListLoaded;
export const getMeetingListCalendar = (state) => state.calendar.calendarView.meetingList;

export const getAvailabilityRawData = (state) => state.calendar.calendarView.availabilityRawData;
export const getAvailabilityBlockData = (state) => state.calendar.calendarView.availabilityBlocks;

// People

export const getPeopleSuggestions = (state) => state.people.peopleSuggestions;
export const getGroupSuggestions = (state) => state.people.groupSuggestions;
export const getAttendees = (state) => state.people.attendees;
export const getExternalAttendees = (state) => state.people.externalAttendees;
export const getAttendeesWorkingHoursCache = (state) => state.people.attendeesWorkingHoursCache;

// Locations

export const getFindRooms = (state) => state.locations.findRooms;
export const getBuildings = (state) => state.locations.buildingList &&
    state.locations.buildingList.map((building) => {
        return {
            label: getBuildingLabel(building),
            value: building.buildingCode,
            floorList: building.floorList,
            roomList: building.roomList,
            hiddenRoomList: building.hiddenRoomList,
        }
    });
export const getBuildingsWithData = (state) => state.locations.buildingList;
export const getRooms = (state) => state.locations.rooms;
export const getLocations = (state) => state.locations.locations;
export const getFloorsLoadStatus = (state) => state.locations.floorsLoaded;

// Schedule

export const getScheduleAvailabilities = (state) => state.timeGrid.schedules;
export const isGettingSchedules = (state) => state.timeGrid.isGettingSchedules;

// Suggestion

export const isSearching = (state) => state.suggestions.isSearching;
export const getSuggestions = (state) => getMeetingSuggestions(state.suggestions);
export const getCachedAvailabilitySuggestions = (state) => mapCachedAvailabilityList(state.suggestions.cachedAvailabilityList);

// Timezones

export const getTimezonesList = (state) => state.timezones.timezones;
export const getBrowserTimezoneId = (state) => state.timezones.browserTimezoneId;
export const getPrimaryBrowserTimezoneId = (state) => determinePrimaryBrowserTimezoneId(state.timezones.timezones, state.timezones.browserTimezoneId);

// Toast

export const getToasts = (state) => state.toast.toasts;

// Chime

export const getChimeUniqueIds = (state) => state.chime.chimeUniqueIds;
export const getChimeUser = (state) => state.chime.chimeUser;

// Shared & Agenda

export const getMeetingAgendaLoaded = (state) => state.shared.meetingAgendaLoaded;
export const getMeetingList = (state) => state.shared.meetingList;
export const getMeetingDetails = (state) => state.shared.meetingDetails;
export const getAwarenessMeetingUniqueId = (state) => state.shared.awarenessMeetingUniqueId;
export const getMeetingResourcesDictionary = (state) => state.shared.meetingResourcesDictionary;
export const getMeetingRecurrencePattern = (state) => state.shared.meetingRecurrence;
export const getSharedGroupDetails = (state) => state.shared.groupDetails;
export const isUnauthorizedUser = (state) => state.shared.unauthorizedUser;
export const getRefreshCount = (state) => state.shared.refreshCount;
export const getMeetingListRoomBooking = (state) => state.shared.meetingListRoomBooking;
export const getExistingMeetingsRoomBooking = (state) => state.shared.existingMeetings;
export const getExistingMeetingsLoaded = (state) => state.shared.existingMeetingsLoaded;

// Check In

export const getRoomEligibilityDictionary = (state) => state.checkIn.roomEligibilityDictionary;
export const getRoomCheckInStatusDictionary = (state) => state.checkIn.roomCheckInStatusDictionary;

// Quick Meeting

export const getQuickMeetingModalState = (state) => state.quickMeeting.modalState;
export const getSuggestionsState = (state) => state.suggestions.isSearching;
export const getQuickMeetingSuggestions = (state, location) => getQuickMtgSuggestions(state.suggestions, location, getFavoriteRooms(state));

// Favorites

export const getFavorites = (state) => state.favorites && state.favorites.favorites;
export const getFavoriteRooms = (state) => state.favorites && state.favorites.favorites ?
    state.favorites.favorites.filter((favorite) => favorite.type === FAVORITE_TYPE.ROOM) : [];

// Workflow

export const getWorkflow = (state) => state.workflow.workflow;

// Work Hours

export const getWorkHours = (state) => state.exchangePreferences.workHours;

// Feedback

export const getUserFeedback = (state) => state.feedback.userFeedback;
export const getUserRating = (state) => state.feedback.userRating;
export const getIsEligible = (state) => state.feedback.isEligible;
export const getFeedbackAlertMessage = (state) => state.feedback.feedbackAlertMessage;
export const getAlias = (state) => state.identity.identity.username;

// Settings
export const getUserPreferences = (state) => ({
    emailAndChime: state.settings.emailAndChime,
    timePreferences: state.settings.timePreferences,
    roomBookingPreferences: state.settings.roomBookingPreferences,
    layoutPreferences: state.settings.layoutPreferences,
    chimeEmail: state.settings.chimeEmail
});

export const getSettingsChimeEmail = (state) => state.settings.chimeEmail;
export const getSettingsUsePersonalChimePin = (state) => state.settings.emailAndChime.usePersonalChimePin;
export const getSettingsEnableAutoCallParticipants = (state) => state.settings.emailAndChime.enableAutoCallParticipants;
export const getSettingsEnableAutoCallRoomBooking = (state) => state.settings.emailAndChime.enableAutoCallRoomBooking;

export const getSettingsPrimaryTimezone = (state) => state.settings.timePreferences.primaryTimezone;
export const getSettingsWorkingHours = (state) => state.settings.timePreferences.workingHours;
export const getSettingsTimeFormat = (state) => state.settings.timePreferences.timeFormat;
export const getSettingsDateFormat = (state) => state.settings.timePreferences.dateFormat;

export const getSettingsBuilding = (state) => state.settings.roomBookingPreferences.building;
export const getSettingsFloor = (state) => state.settings.roomBookingPreferences.floor;
export const getSettingsCamera = (state) => state.settings.roomBookingPreferences.camera;
export const getSettingsDisplay = (state) => state.settings.roomBookingPreferences.display;
export const getSettingsNoRestricted = (state) => state.settings.roomBookingPreferences.noRestricted;
export const getSettingsNoManaged = (state) => state.settings.roomBookingPreferences.noManaged;
export const getSettingsMinimumSeats = (state) => state.settings.roomBookingPreferences.minimumSeats;
export const getSettingsAvailableOnly = (state) => state.settings.roomBookingPreferences.availableOnly;

export const getSettingsSchedulingMode = (state) => state.settings.layoutPreferences.schedulingMode;
export const getSettingsCalendarViewForDesktop = (state) => state.settings.layoutPreferences.calendarViewDesktop;
export const getSettingsCalendarViewForMobile = (state) => state.settings.layoutPreferences.calendarViewMobile;

export const getSettingsFavoritePopoverAttendee = (state) => state.settings.favoritesPopoverAttendee;
export const getSettingsPreferencesLoaded = (state) => state.settings.preferencesLoaded;

// Polls

export const getSelectedPollDetails = (state) => state.polls.pollDetails;
export const getPollListForUser = (state) => state.polls.pollList;
export const getMeetingPollsLoaded = (state) => state.polls.meetingPollsLoaded;
export const getPollDetailsLoaded = (state) => state.polls.pollDetailsLoaded;
export const getCreatePollRequest = (state) => state.polls.createPollRequest;
export const isProcessingPoll = (state) => state.polls.processingPoll;
export const getReminderResponse = (state) => state.polls.reminderResponse;
export const getMeetingListForAvailability = (state) => state.polls.meetingList;
export const getMeetingPollFromPage = (state) => state.polls.fromPage;
export const getMeetingPollTimeSlotsSelectedFromGridMode = (state) => state.polls.timeSlotsSelectedFromGridMode;
export const getMeetingPollTimeSlotsSelectedFromCalendarMode = (state) => state.polls.timeSlotsSelectedFromCalendarMode;