import React, { useMemo, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import keycode from "keycode"
import closeTokens from "@amzn/meridian-tokens/base/icon/close"
import {
  elevationForeground,
  elevationModal,
} from "@amzn/meridian-tokens/base/elevation"
import uniqueId from "lodash/uniqueId"
import ModalFooter from "./modal-footer"
import Button from "../button"
import Icon from "../icon"
import FocusTrap from "../_focus-trap"
import CustomPropTypes from "../../_prop-types"
import { parseSpacing } from "../../_prop-types/parsers"
import { css, cx } from "emotion"
import isElementOf from "../../_utils/is-element-of"
import textStyles from "../../_styles/text"
import useFocus from "../_use-focus"
import focusRingStyles from "../../_styles/focus-ring"
import filterPropsByPrefix from "../../_utils/filter-props-by-prefix"

const closeButtonWidth = 32 // px
const scrimSpacingVertical = 10 // vh
const scrimSpacingHorizontal = 10 //vw

const isFooter = isElementOf(ModalFooter)
const isNotFooter = child => !isFooter(child)

const styles = (
  t,
  {
    focus,
    scrollContainer,
    fullscreen,
    width,
    bodySpacingInset,
    showTitle,
    showCloseButton,
  }
) => {
  const padding = parseSpacing(bodySpacingInset)
  return css(
    /**
     * Fullscreen vs non-fullscreen styles
     */
    fullscreen
      ? {
          // Fullscreen modals aren't shown in a scrim so we've got to handle
          // layout here
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: elevationModal,
          // Use momentum scrolling on iOS. See:
          // https://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements/
          overflowY: "scroll",
          WebkitOverflowScrolling: "touch",
        }
      : {
          // Apply the user-provided width if we're not showing a fullscreen
          // modal
          width,
          // This allows the absolutely-positioned close button to anchor itself
          // to the modal
          position: "relative",
          boxShadow: t.boxShadowCss("dropShadow"),
          maxHeight:
            scrollContainer === "modal"
              ? `${100 - scrimSpacingVertical * 2}vh`
              : undefined,
          maxWidth: `${100 - scrimSpacingHorizontal * 2}vw`,
          borderRadius: t("borderRadius"),
        },
    focus
      ? focusRingStyles(t.tokens, { focus, borderRadius: t("borderRadius") })
      : {},
    {
      /**
       * Common styles
       */
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      backgroundColor: t("bodyBackgroundColor"),
      outline: "none",
      /**
       * Header styles
       */
      "& > header": css(textStyles(t), {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minHeight: t("headerMinHeight"),
        paddingTop: t("headerSpacingInsetTop"),
        paddingBottom: t("headerSpacingInsetBottom"),
        // HACK: IE11 does not support padding-inline-* so we have
        // to use directional padding-left/right. If/when we drop IE11
        // and we update the token names, we can simplify these styles to be:
        // {
        //   paddingInlineStart: t("headerSpacingInsetStart")
        //   paddingInlineEnd: t("headerSpacingInsetEnd")
        // }
        paddingRight: t("headerSpacingInsetRight"),
        paddingLeft: t("headerSpacingInsetLeft"),
        [`[dir="rtl"] &`]: {
          paddingLeft: t("headerSpacingInsetRight"),
          paddingRight: t("headerSpacingInsetLeft"),
        },
        backgroundColor: t("headerBackgroundColor"),
        color: t("headerForegroundColor"),
        fontWeight: t("headerFontWeight"),
      }),
      /**
       * Body styles
       */
      "& > div": {
        padding: parseSpacing(bodySpacingInset)
          .map(value => `${value}px`)
          .join(" "),
        // HACK: IE11 does not support padding-inline-* so we have
        // to use directional padding-left/right. If/when we drop IE11 we can simplify
        // these styles to be:
        // {
        //   paddingInlineEnd: showCloseButton && !showTitle
        //             ? `calc(${padding[1] || padding[0]}px + ${closeButtonWidth}px)`
        //             : undefined,
        // }

        // Add extra right-padding on modals with no title and a close button
        paddingRight:
          showCloseButton && !showTitle
            ? `calc(${padding[1] || padding[0]}px + ${closeButtonWidth}px)`
            : undefined,
        [`[dir="rtl"] &`]: {
          paddingLeft:
            showCloseButton && !showTitle
              ? `calc(${padding[1] || padding[0]}px + ${closeButtonWidth}px)`
              : undefined,
          // HACK: Need to remove the additional right padding until we
          // update to use paddingInlineStart
          paddingRight: `${t("headerSpacingInsetLeft")}px !important`,
        },
        overflowY: scrollContainer === "modal" ? "auto" : undefined,
        overflowX: "auto",
        // In fullscreen mode the body should fill up any screen space not used by
        // the header or footer
        flex: fullscreen ? "auto" : undefined,
      },
      /**
       * Footer styles
       */
      "& > footer": {
        borderTop: t.borderCss("footerBorder"),
        padding: t("footerSpacingInset"),
      },
    }
  )
}

const closeButtonStyles = t =>
  css({
    position: "absolute",
    top: t("closeButtonSpacingTop"),
    right: t("closeButtonSpacingRight"),
    [`[dir="rtl"] &`]: {
      left: t("closeButtonSpacingRight"),
      right: "auto",
    },
    zIndex: elevationForeground,
  })

/* eslint-disable react/prop-types, react/display-name */
// The element we use at the root of the modal depends on
// the presence of an `onSubmit` prop. If the method
// is present, the modal uses a <form> element, else
// a <div>
const ModalBoxRoot = React.forwardRef((props, ref) =>
  React.createElement(props.onSubmit ? "form" : "div", {
    ...props,
    tabIndex: 0,
    ref,
  })
)
/* eslint-enable react/prop-types, react/display-name */

/**
 * This component can be used to render a modal for your application
 */
const ModalBox = React.forwardRef((props, ref) => {
  const { onClose, onSubmitForm, formAction, formMethod } = props
  const id = useMemo(() => uniqueId("modal-"), [])
  const headerId = props.title ? `${id}-header` : undefined
  const footer = React.Children.toArray(props.children).filter(isFooter)[0]
  const { focus, focusTrigger, focusProps, focusDescendent } = useFocus(props)

  // Call the onClose handler if the esc key is pressed
  const onKeyDownDocument = useCallback(
    event => {
      if (keycode(event) === "esc") {
        onClose()
      }
    },
    [onClose]
  )

  // Bind the onKeyDownDocument handler to the window
  useEffect(() => {
    if (onClose) {
      window.addEventListener("keydown", onKeyDownDocument)
    }
    return () => window.removeEventListener("keydown", onKeyDownDocument)
  }, [onClose, onKeyDownDocument])

  const onSubmit = useCallback(
    event => {
      if (!formAction && !formMethod) event.preventDefault()
      onSubmitForm()
    },
    [formAction, formMethod, onSubmitForm]
  )

  const passThroughProps = filterPropsByPrefix(props, ["data-"])

  return (
    <FocusTrap>
      <ModalBoxRoot
        {...passThroughProps}
        {...focusProps}
        className={cx(
          props.className,
          styles(props.t, {
            focus: focus && focusTrigger === "keyboard" && !focusDescendent,
            scrollContainer: props.scrollContainer,
            width: props.width,
            fullscreen: props.fullscreen,
            bodySpacingInset: props.bodySpacingInset,
            showTitle: Boolean(props.title),
            showCloseButton: Boolean(onClose),
          })
        )}
        onTransitionEnd={props.onTransitionEnd}
        onSubmit={onSubmitForm ? onSubmit : undefined}
        action={props.formAction}
        method={props.formMethod}
        aria-labelledby={headerId}
        aria-describedby={props.describedById}
        aria-modal={true}
        id={props.id}
        ref={ref}
      >
        {props.title ? <header id={headerId}>{props.title}</header> : null}
        {onClose ? (
          <Button
            onClick={onClose}
            size="small"
            type="icon"
            backdropColor={
              props.title
                ? props.t("headerBackgroundColor")
                : props.t("bodyBackgroundColor")
            }
            className={closeButtonStyles(props.t)}
            propagateClickEvent={props.closeButtonPropagateClickEvent}
          >
            <Icon tokens={closeTokens}>{props.closeLabel}</Icon>
          </Button>
        ) : null}
        <div>{React.Children.toArray(props.children).filter(isNotFooter)}</div>
        {footer ? footer : null}
      </ModalBoxRoot>
    </FocusTrap>
  )
})

ModalBox.displayName = "ModalBox"

ModalBox.propTypes = {
  closeLabel: PropTypes.string.isRequired,
  bodySpacingInset: CustomPropTypes.spacing(4),
  children: PropTypes.node,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  closeButtonPropagateClickEvent: PropTypes.bool,
  describedById: PropTypes.string,
  formAction: PropTypes.string,
  formMethod: PropTypes.oneOf(["get", "post", "dialog"]),
  fullscreen: PropTypes.bool,
  scrollContainer: PropTypes.oneOf(["modal", "viewport"]),
  t: PropTypes.func,
  title: PropTypes.string,
  /**
   * Set the width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  onSubmitForm: PropTypes.func,
  onTransitionEnd: PropTypes.func,
  /**
   * Use this to set the id attribute of this modal.
   * This can be useful for setting a unique identifier on the div so that it can be found
   *
   * @added by meetex team member (camei@)
   */
  id: PropTypes.string,
}

export default ModalBox
export { scrimSpacingVertical, scrimSpacingHorizontal }
