import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getWorkflow } from "../../../sagas/selector";
import { clearAttendees } from "../../people/actions";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";

import PollDashboard from "./poll-dashboard";
import PollDashboardHeader from "./poll-dashboard-header";
import PollResultsPanel from "../containers/poll-results-container";

import HeaderBackground from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";

import "../meeting-polls.css";
import { getFormattedDateTime } from "../../shared/time-utils";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { changePage } from "../../shared/workflow/workflow-utils";
import { MAPS_WIKI_LINK } from "../poll-constants";

const PollForm = (props) => {
    const userAlias = props.userAlias;
    const userFullName = props.userFullName;
    const pollList = props.pollList;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const mastheadSize = props.mastheadSize;
    const onFindPolls = props.onFindPolls;
    const timezoneValue = props.timezoneValue;
    const timeFormat = props.timeFormat;
    const pollDetails = props.pollDetails;
    const onGetPoll = props.onGetPoll;

    const newCurrentEpoch = Date.now();
    const organizerPreferredDate = getFormattedDateTime(newCurrentEpoch, timezoneValue, "MM-dd-yyyy");
    const organizerPreferredHour = getFormattedDateTime(newCurrentEpoch, timezoneValue, "HH");

    const currentWorkflow = useSelector(getWorkflow);
    const [selectedPollId, setSelectedPollId] = useState("");
    const [openResults, setOpenResults] = useState(false);
    const [showResultsPanel, setShowResultsPanel] = useState(false);
    const [attendeeList, setAttendeeList] = useState([]);
    const [updateAttendeeList, setUpdateAttendeeList] = useState(false);

    const dispatch = useDispatch();
    const onClearAttendees = () => dispatch(clearAttendees());

    const onClickOpenResults = useCallback(() => setOpenResults(true), []);
    const onClickCloseResults = useCallback(() => setOpenResults(false), []);

    const onOpenResults = (pollID) => {
        onClickOpenResults();
        setShowResultsPanel(true);
        setSelectedPollId(pollID);

        // Call the getPoll API when they open the results panel
        // This API call will take some time, the set variables above will finish first
        onGetPoll(pollID);
    };

    const onCloseResults = () => {
        onClickCloseResults();
        onClearAttendees(); // Clear attendees when closing the results panel
        setAttendeeList([]);
        setShowResultsPanel(false);
    };

    const handleChangePage = (event, newWorkflow) => {
        if (event.ctrlKey || event.metaKey) {
            return;
        }
        changePage(dispatch, currentWorkflow, newWorkflow);
    };

    const header = useRef();

    // Begin new workflow content with focus on the <h1> heading
    useEffect(() => {
        document.title = "Meeting polls dashboard - Amazon Meetings";
        if (header.current) {
            header.current.focus();
        }
    }, []);

    return (
        <Column height="100%" spacing="none">
            <div style={{
                "paddingBottom": STEP_NAVIGATION_FOOTER.HEIGHT,
                "backgroundColor": "white"
            }}>
                <Column
                    height="100%"
                    minHeight={`calc(100vh - ${mastheadSize} - ${STEP_NAVIGATION_FOOTER.HEIGHT})`} // 100vh - mastheadSize - footer
                    heights={["fit", "fill"]}
                    spacing="none"
                >
                    <div style={{
                        "backgroundImage": `url(${HeaderBackground})`,
                        "backgroundRepeat": "no-repeat",
                        "backgroundColor": "rgba(26, 69, 78)",
                        "backgroundPosition": "150px -90px",
                        "height": "50px",
                        "width": "100%",
                    }}>
                        <Row height="100%" alignmentHorizontal="justify" alignmentVertical="center" spacingInset="medium">
                            <Heading color="inverted" level={1} type={"h300"} ref={header} tabIndex="-1">Meeting polls dashboard</Heading>
                            <a
                                href={MAPS_WIKI_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-MAPS"
                            >
                                How does a meeting poll work?
                            </a>
                        </Row>
                    </div>
                    <Row alignmentHorizontal="center" alignmentVertical="top" spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.MD ? "xlarge" : "medium"}>
                        <Column
                            width="100%"
                            maxWidth= {openResults ? "700px" :"1200px"}
                            spacing="xsmall"
                        >
                            <PollDashboardHeader
                                userFullName={userFullName}
                                organizerPreferredDate={organizerPreferredDate}
                                organizerPreferredHour={organizerPreferredHour}
                                showResultsPanel={showResultsPanel}
                                handleChangePage={handleChangePage}
                            />
                            <PollDashboard
                                userAlias={userAlias}
                                userFullName={userFullName}
                                pollList={pollList}
                                screenSizeBreakpoint={screenSizeBreakpoint}
                                onFindPolls={onFindPolls}
                                organizerPreferredDate={organizerPreferredDate}
                                organizerPreferredHour={organizerPreferredHour}
                                setOpenResults={setOpenResults}
                                showResultsPanel={showResultsPanel}
                                setShowResultsPanel={setShowResultsPanel}
                                onCloseResults={onCloseResults}
                                setSelectedPollId={setSelectedPollId}
                                timezoneValue={timezoneValue}
                                timeFormat={timeFormat}
                                pollDetails={pollDetails}
                                attendeeList={attendeeList}
                                onOpenResults={onOpenResults}
                                onGetPoll={onGetPoll}
                                handleChangePage={handleChangePage}
                            />
                        </Column>
                        <Column height="100%">
                            <PollResultsPanel
                                pollID={selectedPollId}
                                open={openResults}
                                onClose={onCloseResults}
                                onGetPoll={onGetPoll}
                                attendeeList={attendeeList}
                                pollDetails={pollDetails}
                                updateAttendeeList={updateAttendeeList}
                                setUpdateAttendeeList={setUpdateAttendeeList}
                                setAttendeeList={setAttendeeList}
                                screenSizeBreakpoint={screenSizeBreakpoint}
                            />
                        </Column>
                    </Row>
                </Column>
            </div>
        </Column>
    );
};

export default PollForm;