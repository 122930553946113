import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";

import HeaderBackground from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";
import IllusionResponse404 from "../../../assets/icons/polls/Illustration_response_404.svg";

import PollRespondDetailsBox from "./poll-respond-details-box";
import PollRespondAvailabilityBox from "./poll-respond-availability-box";
import PollRespondAgenda from "./poll-respond-agenda";
import LoadingMeetings from "../../landing/components/loading-meeting";

import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { changePage } from "../../shared/workflow/workflow-utils";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { generateComponentId } from "../../shared/shared-utils";
import { STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { MAPS_WIKI_LINK, RESPOND_PAGE_SIZE, STATUS } from "../poll-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";

const PollRespondForm = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const mastheadSize = props.mastheadSize;
    const currentWorkflow = props.currentWorkflow;
    const identity = props.identity;
    const pollID = props.pollID;
    const attendeeInformationList = props.attendeeInformationList;
    const pollDetails = props.pollDetails;
    const attendeeList = props.attendeeList;
    const availabilityList = props.availabilityList;
    const organizer = props.organizer;
    const meetingList = props.meetingList;
    const meetingListLoaded = props.meetingListLoaded;
    const userEmail = props.userEmail;
    const calendarDate = props.calendarDate;
    const setTriggerRefresh = props.setTriggerRefresh;
    const response = props.response;
    const attendeeAvailabilityList = props.attendeeAvailabilityList;
    const expiration = props.expiration;
    const timezone = props.timezone;
    const pollDetailsLoaded = props.pollDetailsLoaded;

    const [agendaOpen, setAgendaOpen] = useState(false);

    const dispatch = useDispatch();

    // Time and Timezone
    const timezones = props.timezones;
    const timeFormat = props.timeFormat;
    const browserTimezoneName = timezoneIdToDisplayName(timezones, timezone) || "";

    const respondedAttendeeList = (attendeeList && attendeeList.filter((attendee) => attendee.response === true)) || [];

    const pollAvailable = pollDetails ?
        pollDetails.status ?
            pollDetails.status === STATUS.ONGOING || pollDetails.status === STATUS.READY
            :
            undefined || (pollDetails.statusCode && pollDetails.statusCode !== 404)
        :
        undefined;

    const header = useRef();

    // Begin new workflow content with focus on the <h1> heading
    useEffect(() => {
        document.title = "Respond to meeting poll - Amazon Meetings";
        if (header.current) {
            header.current.focus();
        }
    }, []);

    return <Column height="100%" spacing="none">
        <div style={{
            "paddingBottom": STEP_NAVIGATION_FOOTER.HEIGHT,
            "backgroundColor": "white"
        }}>
            <Column
                height="100%"
                minHeight={`calc(100vh - ${mastheadSize} - ${STEP_NAVIGATION_FOOTER.HEIGHT})`} // 100vh - mastheadSize - footer
                heights={["fit", "fill"]}
                spacing="medium"
                alignmentVertical="center"
            >
                <div style={{
                    "backgroundImage": `url(${HeaderBackground})`,
                    "backgroundRepeat": "no-repeat",
                    "backgroundColor": "rgba(26, 69, 78)",
                    "backgroundPosition": "150px -90px",
                    "height": "50px",
                    "width": "100%",
                }}>
                    <Row height="100%" alignmentHorizontal="justify" alignmentVertical="center" spacingInset="medium" width={["fill", "fit"]}>
                        <Heading
                            color="inverted"
                            level={1}
                            type="h300"
                            ref={header}
                            tabIndex="-1"
                        >
                            Respond to meeting poll
                        </Heading>
                        <a
                            href={MAPS_WIKI_LINK}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{"color": "white"}}
                            className="link-MAPS"
                        >
                            How does a meeting poll work?
                        </a>
                    </Row>
                </div>
                {pollDetailsLoaded && pollAvailable !== undefined ?
                    pollAvailable ?
                        <Column alignmentHorizontal="center" spacingInset="none medium">
                            <Row spacingInset="large none">
                                <Heading level={2} alignment="left" className="responsive-text">
                                    Hello{identity && identity.name ? ` ${identity.name}` : ""}, you've received a meeting poll invitation{organizer?.name ? ` from ${organizer.name}` : ""}
                                </Heading>
                            </Row>
                            <Row
                                spacing="medium"
                                alignmentHorizontal="center"
                                alignmentVertical="top"
                                wrap="down"
                                width="100%"
                                maxWidth={RESPOND_PAGE_SIZE.PAGE_MAX_WIDTH}
                                widths={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.LG ? ["fit", "fill"] : ["fit", "fit"]}
                            >
                                <Column alignmentHorizontal="center" width={screenSizeBreakpoint < SCREEN_SIZE.BREAKPOINT.LG ? "100%" : undefined} maxWidth={RESPOND_PAGE_SIZE.DETAILS_BOX_MAX_WIDTH}>
                                    {agendaOpen ?
                                        <PollRespondAgenda
                                            agendaOpen={agendaOpen}
                                            setAgendaOpen={setAgendaOpen}
                                            availabilityList={availabilityList}
                                            screenSizeBreakpoint={screenSizeBreakpoint}
                                            meetingListLoaded={meetingListLoaded}
                                            meetings={meetingList}
                                            userEmail={userEmail}
                                            calendarDate={calendarDate}
                                            setTriggerRefresh={setTriggerRefresh}
                                            timezone={timezone}
                                            timeFormat={timeFormat}
                                        />
                                        :
                                        <PollRespondDetailsBox
                                            organizer={organizer}
                                            pollDetails={pollDetails}
                                            attendeeList={attendeeList}
                                            screenSizeBreakpoint={screenSizeBreakpoint}
                                        />
                                    }
                                </Column>
                                <Column alignmentHorizontal="center" width={screenSizeBreakpoint < SCREEN_SIZE.BREAKPOINT.LG ? "100%" : undefined} maxWidth={RESPOND_PAGE_SIZE.AVAILABILITY_BOX_MAX_WIDTH}>
                                    <PollRespondAvailabilityBox
                                        pollID={pollID}
                                        identity={identity}
                                        availabilityList={availabilityList}
                                        screenSizeBreakpoint={screenSizeBreakpoint}
                                        timezone={timezone}
                                        timezones={timezones}
                                        timeFormat={timeFormat}
                                        browserTimezoneName={browserTimezoneName}
                                        organizer={organizer}
                                        respondedAttendeeList={respondedAttendeeList}
                                        pollDetails={pollDetails}
                                        attendeeInformationList={attendeeInformationList}
                                        setAgendaOpen={setAgendaOpen}
                                        response={response}
                                        attendeeAvailabilityList={attendeeAvailabilityList}
                                        expiration={expiration}
                                        meetingsForAvailability={props.meetingsForAvailability}
                                    />
                                </Column>
                            </Row>
                        </Column>
                        :
                        <Column alignmentHorizontal="center" spacing="large">
                            <img src={IllusionResponse404} alt="background" style={{maxWidth: "100%", paddingTop: "24px", paddingBottom: "24px"}} />
                            <Text type="h400">
                                This meeting poll is no longer available{pollDetails?.subject ? ":" : ""}
                            </Text>
                            {pollDetails?.subject &&
                                <Text type="h300" className="responsive-text">
                                    [{pollDetails?.subject}]
                                </Text>
                            }
                            <Column width="260px" spacing="none" spacingInset="large none none none">
                                <Button
                                    type="primary"
                                    size="large"
                                    href={WORKFLOWS.CALENDAR.HREF}
                                    onClick={() => changePage(dispatch, currentWorkflow, WORKFLOWS.CALENDAR)}
                                    id={generateComponentId("button", "view-calendar")}
                                >
                                    Go to my calendar
                                </Button>
                            </Column>
                        </Column>
                    :
                    <LoadingMeetings
                        loadingMessage="Loading meeting polls..."
                        loaderSize="medium"
                    />
                }
            </Column>
        </div>
    </Column>;
};

export default PollRespondForm;