const livestreamNamespace = 'livestream';

export const UPDATE_MEETING_ASYNC = `${livestreamNamespace}.update_meeting_async`;
export const ADD_ATTENDEE_ASYNC = `${livestreamNamespace}.add_attendee_async`;
export const REMOVE_ATTENDEE_ASYNC = `${livestreamNamespace}.remove_attendee_async`;

export const UPDATE_CURRENT_MEETING = `${livestreamNamespace}.update_current_meeting`;
export const ADD_ATTENDEE = `${livestreamNamespace}.add_attendee`;
export const REMOVE_ATTENDEE = `${livestreamNamespace}.remove_attendee`;
export const SAVE_DRAFT = `${livestreamNamespace}.save_draft`;
export const CLEAR_DRAFT = `${livestreamNamespace}.clear_draft`;

export const SEARCH_SUGGESTION = `${livestreamNamespace}.search_suggestion`;
export const SELECT_SUGGESTION = `${livestreamNamespace}.select_suggestion`;
export const SET_SUGGESTION_VIEW_TYPE = `${livestreamNamespace}.set_suggestion_view_type`;
export const SELECT_STATIONS = `${livestreamNamespace}.select_stations`;

export const CHANGE_STEP = `${livestreamNamespace}.change_step`;

export const SET_MASTER = `${livestreamNamespace}.set_master`;
export const SET_CREATING_MEETING_STATE = `${livestreamNamespace}.set_creating_meeting_state`;
export const UPDATE_RECURRENCE = `${livestreamNamespace}.update_recurrence`;

export const SET_LOAD_MEETING = `${livestreamNamespace}.set_load_meeting`;

// Livestream search groups and people
const peopleNamespace = "peopleAndGroups";
export const CLEAR_PERMISSION_PEOPLE_SUGGESTIONS = `${peopleNamespace}.clear_people_suggestions`;
export const CLEAR_PERMISSION_GROUP_SUGGESTIONS = `${peopleNamespace}.clear_permission_group_suggestions`;
export const GET_PERMISSION_PEOPLE_SUGGESTIONS = `${peopleNamespace}.get_people_suggestions`;
export const GET_PERMISSION_GROUP_SUGGESTIONS = `${peopleNamespace}.get_permission_group_suggestions`;
export const SAVE_PERMISSION_PEOPLE_SUGGESTIONS = `${peopleNamespace}.save_people_suggestions`;
export const SAVE_PERMISSION_GROUP_SUGGESTIONS = `${peopleNamespace}.save_permission_group_suggestions`;

// Livestream EventIntake
const createEventIntakeNamespace = "createEventIntake";
export const CREATE_EVENT_INTAKE_DETAILS = `${createEventIntakeNamespace}.create_event_intake_details`;
export const SAVE_CREATE_EVENT_INTAKE_DETAILS = `${createEventIntakeNamespace}.save_create_event_intake_details`;
export const GET_LATEST_EVENT_DETAILS = `${createEventIntakeNamespace}.get_latest_event_details`;
export const SAVE_LATEST_EVENT_DETAILS = `${createEventIntakeNamespace}.save_latest_event_details`;
export const CLEAR_LATEST_EVENT_DETAILS = `${createEventIntakeNamespace}.clear_latest_event_details`;
export const SET_LATEST_EVENT_ERROR_DETAILS = `${createEventIntakeNamespace}.set_latest_event_error_details`;
export const GET_LIVESTREAM_EVENT_SERIES_URL_AVAILABILITY = `${createEventIntakeNamespace}.get_livestream_event_series_url_availability`;
export const SET_LIVESTREAM_EVENT_SERIES_URL_AVAILABILITY = `${createEventIntakeNamespace}.set_livestream_event_series_url_availability`;
export const CLEAR_LIVESTREAM_EVENT_SERIES_URL_AVAILABILITY = `${createEventIntakeNamespace}.clear_livestream_event_series_url_availability`;
export const SET_LIVESTREAM_INTAKE_LOADED = `${createEventIntakeNamespace}.set_livestream-intake-loaded`;

const userNamespace = "livestreamUser";
export const GET_CURRENT_USER_DATA = `${userNamespace}.get_current_user_data`;