import React, {useState, useEffect} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import CalendarDayGridView from "@amzn/meridian/calendar-day-grid-view"
import Text from "@amzn/meridian/text"
import {pad} from "../../../meeting-roomfinder/utils";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon"

import chevronLeftSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small"
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small"
import chevronDoubleLeftTokens from "@amzn/meridian-tokens/base/icon/chevron-double-left"
import chevronDoubleRightTokens from "@amzn/meridian-tokens/base/icon/chevron-double-right"
import {LOCATION_FEATURE} from "../../locations/locations-constants";
import {renderLocationFeatureIcon} from "../../locations/locations-utils";
import {getFormattedDateTime, getTime} from "../../time-utils";
import {zonedTimeToUtc, format} from "date-fns-tz";
import {TIME_CONSTANT} from "../../shared-constants";
import Responsive from "@amzn/meridian/responsive";

const TimeTable = (props) => {
    const now = getFormattedDateTime(zonedTimeToUtc(Date.now(), props.timezoneValue), props.timezoneValue, 'yyyy-MM-dd HH:mm');

    const getEndTime = (startTime) => {
        let hours = parseInt(startTime.split(":")[0]);
        return hours + 4 < 24 ? `${pad(hours + 4, 2)}:00` : "23:00";
    };

    const getStartTime = (startTime) => {
       let hours = parseInt(startTime.split(":")[0]);
       return hours < 20 ? `${pad(hours, 2)}:00` : "19:00";
    };

    const GroupHeader = ({ groupId }) => (
        <Row alignmentHorizontal="right" alignmentVertical="center" height={"100%"} spacing={"small"}>
            <Text type="h100" alignment="right" id={props.groups[groupId].id}>
                {props.groups[groupId].label}
            </Text>
            {(props.groups[groupId].label).toUpperCase().includes("RESTRICTED") ?
                renderLocationFeatureIcon(LOCATION_FEATURE.RESTRICTED, 22)
                :
                ""
            }
        </Row>
    );

    const getDateHeader = (short) => {
        return getFormattedDateTime(zonedTimeToUtc(`${props.startTime} ${props.startTimeStamp}`, props.timezoneValue), props.timezoneValue, short ? 'LLL d' : 'eee, LLL d, yyyy'  );
    };

    const loadToday = () => {
        let now = zonedTimeToUtc(Date.now(), props.timezoneValue);
        let day = format(Date.now(), 'yyyy-MM-dd', props.timezoneValue);
        let hour = getFormattedDateTime(getTime(now.getTime(), props.timezoneValue), props.timezoneValue, 'HH:mm:ss');
        props.setStartTime(day);
        props.setStartTimeStamp(hour);
        props.onRefresh(undefined, undefined, day, hour, undefined);
    };

    const onIncreaseDay = () => {
        let date = zonedTimeToUtc(`${props.startTime} ${props.startTimeStamp}`, props.timezoneValue);
        date.setTime(date.getTime() + TIME_CONSTANT.ONE_DAY_IN_MS);
        let day = format(date.getTime(), 'yyyy-MM-dd', props.timezoneValue);
        props.setStartTime(day);
        props.setStartTimeInput(day);
        props.onRefresh(undefined, undefined, day, props.startTimeStamp, undefined);
    };

    const onDecreaseDay = () => {
        let date = zonedTimeToUtc(`${props.startTime} ${props.startTimeStamp}`, props.timezoneValue);
        date.setTime(date.getTime() - TIME_CONSTANT.ONE_DAY_IN_MS);
        let day = format(date.getTime(), 'yyyy-MM-dd', props.timezoneValue);
        props.setStartTime(day);
        props.setStartTimeInput(day);
        props.onRefresh(undefined, undefined, day, props.startTimeStamp, undefined);
    };

    const onIncreaseHour = () => {
        let hour = (parseInt(props.startTimeStamp.split(":")[0]) + 1) % 24;
        props.setStartTimeStamp(`${pad(hour, 2)}:00:00`);
        props.setStartTimeStampInput(`${pad(hour, 2)}:00:00`);
    };

    const onDecreaseHour = () => {
        let hour = (parseInt(props.startTimeStamp.split(":")[0]) - 1) % 24;
        props.setStartTimeStamp(`${pad(hour, 2)}:00:00`);
        props.setStartTimeStampInput(`${pad(hour, 2)}:00:00`);
    };

    // Getting the width of the window to calculate column width
    // (Calculating a value for column width since Meridian CalendarDayGridView only supports pixel width values)
    // https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    };

    const useWindowDimensions = () => {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowDimensions;
    };

    const width = useWindowDimensions().width;

    // Obtaining the width of the vertical scrollbar to subtract from window width
    // https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
    const getScrollbarWidth = () => {
        // Creating invisible container
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement('div');
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

        // Removing temporary elements from the DOM
        outer.parentNode.removeChild(outer);

        return scrollbarWidth;
    };

    return (
        <Responsive
            query="min-width"
            props={{
                calendarContainerWidth: {
                    default: 360,
                    "600px": 600,
                    "810px": 810,
                    "900px": 900,
                    "1020px": 1020,
                    "1110px": 1110,
                    "1200px": "100%",
                },
                hourColumnWidth: {
                    default: 180,
                    "1020px": 170,
                    "1110px": 185,
                    "1200px": ((width - getScrollbarWidth()) / 6),
                },
                groupHeaderColumnWidth: {
                    default: 178,
                    "1020px": 168,
                    "1110px": 183,
                    "1200px": ((width - getScrollbarWidth()) / 6) - 2,
                },
                calendarHeaderWidth: {
                    default: "fit",
                    "600px": [75, "fit", 75]
                },
                calendarHeaderSpacing: {
                    default: "xsmall",
                    "600px": "medium"
                },
                calendarHeaderShort: {
                    default: true,
                    "600px": false
                }
            }}
        >
            {(responsiveProps) =>
                <Column
                    type="outline"
                    heights={["fit", "fill"]}
                    spacing="none"
                    spacingInset="none"
                    width={responsiveProps.calendarContainerWidth}
                >
                    <Row
                        spacingInset="medium"
                        alignmentHorizontal="justify"
                        widths={responsiveProps.calendarHeaderWidth}
                    >
                        {responsiveProps.calendarHeaderShort ? <span/> :
                            <Button type="tertiary" onClick={loadToday}>
                                Now
                            </Button>
                        }
                        <Row spacing={responsiveProps.calendarHeaderSpacing}>
                            <Button type={"icon"} onClick={onDecreaseDay}>
                                <Icon tokens={chevronDoubleLeftTokens}>View previous day</Icon>
                            </Button>
                            <Button type={"icon"} onClick={onDecreaseHour}
                                    disabled={parseInt(props.startTimeStamp.split(":")[0]) === 0}>
                                <Icon tokens={chevronLeftSmallTokens}>Decrease hour</Icon>
                            </Button>
                            <Heading level={2} type={"h300"}>{getDateHeader(responsiveProps.calendarHeaderShort)}</Heading>
                            <Button type={"icon"} onClick={onIncreaseHour}
                                    disabled={parseInt(props.startTimeStamp.split(":")[0]) >= 19}>
                                <Icon tokens={chevronRightSmallTokens}>Increase hour</Icon>
                            </Button>
                            <Button type={"icon"} onClick={onIncreaseDay}>
                                <Icon tokens={chevronDoubleRightTokens}>View next day</Icon>
                            </Button>
                        </Row>
                        <span/>
                    </Row>
                    <CalendarDayGridView
                        now={now}
                        viewDate={`${props.startTime} ${props.startTimeStamp}`}
                        groupIds={Object.keys(props.groups).sort()}
                        distributedEvents={props.distributedEvents}
                        renderGroupHeader={GroupHeader}
                        renderHour={() => null}
                        timeRangeStart={getStartTime(props.startTimeStamp)}
                        timeRangeEnd={getEndTime(props.startTimeStamp)}
                        showNow={true}
                        groupHeaderWidth={responsiveProps.groupHeaderColumnWidth}
                        hourColumnWidth={responsiveProps.hourColumnWidth}
                        showStackedEvents={false}
                        distributedEventSpacingVertical={"small"}
                    />
                </Column>
            }
        </Responsive>
    )

};

export default TimeTable;