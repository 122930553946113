import React from "react"
import PropTypes from "prop-types"
import noop from "lodash/noop"
import waitAnimationFrame from "../../_utils/wait-animation-frame"

const noopWithCallback = callback => callback()

/**
 * This component makes it easy to apply complex transitions to elements. You
 * tell it whether the children should be transitioned in or out and what the
 * duration of the transition should be. It will provide you with the state of
 * the transition via a render prop. The state may be one of four values:
 * "entering", "entered", "exiting", or "exited". Six lifecycle hooks are
 * available: onBeforeEnter, onEnter, onAfterEnter, onBeforeExit, onExit, and
 * onAfterExit.
 */
class TransitionController extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    duration: PropTypes.number.isRequired,
    in: PropTypes.bool.isRequired,
    /**
     * Called right after the state has changed to "entered".
     */
    onAfterEnter: PropTypes.func,
    /**
     * Called right after the state has changed to "exited".
     */
    onAfterExit: PropTypes.func,
    /**
     * Called right before the state will change from "exited" to "entering".
     * This can be syncronous or asyncronous, but either way it must accept a
     * callback as its first argument and execute that callback when it's
     * finished doing its thing.
     */
    onBeforeEnter: PropTypes.func,
    /**
     * Called right before the state will change from "entered" to "exiting".
     * This can be syncronous or asyncronous, but either way it must accept a
     * callback as its first argument and execute that callback when it's
     * finished doing its thing.
     */
    onBeforeExit: PropTypes.func,
    /**
     * Called right after the state has changed to "entering". This can be
     * syncronous or asyncronous, but either way it must accept a callback as
     * its first argument and execute that callback when it's finished doing its
     * thing.
     */
    onEnter: PropTypes.func,
    /**
     * Called right after the state has changed to "exited". This can be
     * syncronous or asyncronous, but either way it must accept a callback as
     * its first argument and execute that callback when it's finished doing its
     * thing.
     */
    onExit: PropTypes.func,
  }

  static defaultProps = {
    onBeforeEnter: noopWithCallback,
    onEnter: noopWithCallback,
    onAfterEnter: noop,
    onBeforeExit: noopWithCallback,
    onExit: noopWithCallback,
    onAfterExit: noop,
  }

  state = { state: this.props.in ? "entered" : "exited" }

  cancelWait = noop
  callback = noop

  /**
   * This runs a callback after waiting one animation frame, cancelling any
   * previous callback that might be queued. This is helpful when the value of
   * props.in changes mid transition.
   */
  waitAnimationFrame = (callback, skip) => {
    if (skip) {
      callback()
    } else {
      this.cancelWait()
      this.cancelWait = waitAnimationFrame(callback)
    }
  }

  /**
   * This runs a callback after waiting a particular duration, cancelling any
   * previous callback that might be queued. This is helpful when the value of
   * props.in changes mid transition.
   */
  waitDuration = callback => {
    let timeout
    this.cancelWait()
    this.cancelWait = () => clearTimeout(timeout)
    timeout = setTimeout(callback, this.props.duration)
  }

  /**
   * This takes a function that accepts a callback. It then wraps that function
   * so that it will only execute its callback if another callback hasn't been
   * queued. This is helpful when the value of props.in changes mid transition
   * and we want to ignore any stale callbacks.
   */
  withCallback = func => callback => {
    this.callback = callback
    func(() => {
      if (this.callback === callback) {
        callback()
      }
    })
  }

  /**
   * This orchestrates a transition. The basic flow is:
   * 1) cancel any existing timeouts
   * 2) call onBefore
   * 3) wait for a single animation frame *if* onBefore is defined (so that the
   *    DOM has a chance to settle)
   * 4) update the state to transitioning
   * 5) wait for a single animation frame *if* onTransition is defined (so that
   *    the DOM has a chance to settle)
   * 6) call onTransition
   * 7) wait for the duration of the animation
   * 8) update the state to transitioned
   * 9) wait for a single animation frame
   * 10) call onAfter
   */
  transition({ onBefore, onTransition, onAfter, stateDuring, stateAfter }) {
    const onBeforeWithCallback = this.withCallback(onBefore)
    const onTransitionWithCallback = this.withCallback(onTransition)
    this.cancelWait()
    onBeforeWithCallback(() =>
      this.waitAnimationFrame(
        () =>
          this.setState({ state: stateDuring }, () =>
            this.waitAnimationFrame(
              () =>
                onTransitionWithCallback(() =>
                  this.waitDuration(() =>
                    this.setState(
                      { state: stateAfter },
                      this.waitAnimationFrame(onAfter, onAfter === noop)
                    )
                  )
                ),
              // Don't wait an extra animation frame if no onTransition hook is
              // provided
              onTransition === noopWithCallback
            )
          ),
        // Don't wait an extra animation frame if no onBefore hook is provided
        onBefore === noopWithCallback
      )
    )
  }

  transitionIn() {
    this.transition({
      onBefore: this.props.onBeforeEnter,
      onTransition: this.props.onEnter,
      onAfter: this.props.onAfterEnter,
      stateDuring: "entering",
      stateAfter: "entered",
    })
  }

  transitionOut() {
    this.transition({
      onBefore: this.props.onBeforeExit,
      onTransition: this.props.onExit,
      onAfter: this.props.onAfterExit,
      stateDuring: "exiting",
      stateAfter: "exited",
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.in && !prevProps.in) {
      this.transitionIn()
    } else if (!this.props.in && prevProps.in) {
      this.transitionOut()
    }
  }

  componentWillUnmount() {
    this.cancelWait()
  }

  render() {
    return this.props.children(this.state.state)
  }
}

export default TransitionController
