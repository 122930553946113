import { all, call, takeEvery, put } from "redux-saga/effects";
import * as Actions from "../actions/action-types";
import {
    checkInRoom,
    getCheckInStatus,
    getRoomEligibility,
    releaseRoom,
} from "../../../api/apig/mcis";
import { reclaimResources } from "../../../api/apig/ras";
import { getSuccessToastWithComponent, getErrorToastWithComponent } from "../../toasts/toast-utils";
import { showToast } from "../../toasts/actions";
import { isErrorResponse } from "../../../api/apig/apig-utils";
import { setCheckInStatus, setRoomEligibility } from "../actions";
import { refreshAgenda, setMeetingAgendaLoaded } from "../../actions";
import { TIMEOUT, TOAST_COMPONENT } from "../../toasts/toast-constants";

export function* checkInAndReclaimResourcesApi(action) {
    // Show the loading indicator.
    yield put(setMeetingAgendaLoaded(false));

    const {
        checkInSources,
        checkInMeetings,
        checkInRoomDisplayNames,
        releaseRequestedOnBehalfOf,
        releaseMeetingUniqueOrEntryID,
        releaseResources,
    } = action;

    const requests = [];

    if (checkInSources?.length > 0) {
        requests.push(call(checkInRoom, checkInSources, checkInMeetings));
    }

    if (releaseResources?.length > 0) {
        requests.push(
            call(
                reclaimResources,
                releaseRequestedOnBehalfOf,
                releaseMeetingUniqueOrEntryID,
                releaseResources
            )
        );
    }

    const response = yield all(requests);

    const checkInRoomResponse =
        checkInMeetings?.length > 0 ? JSON.parse(response[0]) : null;

    const reclaimResourcesResponse =
        releaseResources?.length > 0
            ? checkInMeetings?.length > 0
                ? JSON.parse(response[1])
                : JSON.parse(response[0])
            : null;

    let hasError = false;
    if (checkInRoomResponse && isErrorResponse(checkInRoomResponse)) {
        const toast = getErrorToastWithComponent();
        toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CHECK_IN;
        toast.toastActionProps.text = "An error occurred when checking in meeting room(s), please try again.";
        toast.toastTimeout = TIMEOUT.NEVER;
        toast.toastActionProps.ariaLabel = "Error checking in meeting room(s)";
        toast.toastActionProps.refocusInfo = action.refocusInfo;
        yield put(showToast(toast));
        hasError = true;
    }
    if (reclaimResourcesResponse && isErrorResponse(reclaimResourcesResponse)) {
        const toast = getErrorToastWithComponent()
        toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CHECK_IN;
        toast.toastActionProps.text = "An error occurred when releasing meeting room(s), please try again.";
        toast.toastTimeout = TIMEOUT.NEVER;
        toast.toastActionProps.ariaLabel = "Error releasing meeting room(s)";
        toast.toastActionProps.refocusInfo = action.refocusInfo;
        yield put(showToast(toast));
        hasError = true;
    }

    if (hasError) {
        return;
    }

    const checkInToast = checkInRoomResponse ? getSuccessToastWithComponent() : null;

    if (checkInToast) {
        checkInToast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CHECK_IN;
        checkInToast.toastActionProps.text = `<b>You have successfully checked into room ${checkInRoomDisplayNames.join(",")}.</b> Any unselected check-in required rooms will then be released for other meetings.`;
        checkInToast.toastTimeout = TIMEOUT.NEVER;
        checkInToast.toastActionProps.ariaLabel = "Meeting room checked in";
        checkInToast.toastActionProps.refocusInfo = action.refocusInfo;
        yield put(showToast(checkInToast));
    }

    // After fully checked-in meetings & released rooms refresh the agenda
    yield put(refreshAgenda());
}

export function* watchCheckInAndReclaimResources() {
    yield takeEvery(
        Actions.CHECK_IN_AND_RECLAIM_RESOURCES,
        checkInAndReclaimResourcesApi
    );
}

export function* checkInRoomApi(action) {
    yield put(setMeetingAgendaLoaded(false));

    const response = yield call(checkInRoom, action.source, action.meetings);
    const checkInRoomResponse = JSON.parse(response);

    if (isErrorResponse(checkInRoomResponse)) {
        const toast = getErrorToastWithComponent();
        toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CHECK_IN;
        toast.toastActionProps.text = "An error occurred when checking in meeting room(s), please try again.";
        toast.toastTimeout = TIMEOUT.NEVER;
        toast.toastActionProps.ariaLabel = "Error checking in meeting room(s)";
        toast.toastActionProps.refocusInfo = action.refocusInfo;

        // Remove loading indicator
        yield put(setMeetingAgendaLoaded(true));
        yield put(showToast(toast));
        return;
    }

    const toast = getSuccessToastWithComponent();
    toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CHECK_IN;
    toast.toastActionProps.text  = `You have successfully checked into room ${action.roomDisplayNames.join(",")}`;
    toast.toastTimeout = TIMEOUT.NEVER;
    toast.toastActionProps.ariaLabel = "Meeting room checked in";
    toast.toastActionProps.refocusInfo = action.refocusInfo;

    yield put(showToast(toast));
    yield put(refreshAgenda());
}

export function* watchCheckInRoom() {
    yield takeEvery(Actions.CHECK_IN_ROOM, checkInRoomApi);
}

// Currently we are using RAS reclaim resources api for releasing rooms.
// Below API was not used but might be used in the future iteration.
export function* releaseRoomApi(action) {
    yield call(
        releaseRoom,
        action.source,
        action.meetingStartTime,
        action.roomEmail
    );
}

export function* watchReleaseRoom() {
    yield takeEvery(Actions.RELEASE_ROOM, releaseRoomApi);
}

export function* reclaimResourcesApi(action) {
    yield put(setMeetingAgendaLoaded(false));

    const response = yield call(
        reclaimResources,
        action.requestedOnBehalfOf,
        action.uniqueOrEntryID,
        action.resources
    );
    const reclaimResourcesResponse = JSON.parse(response);
    if (isErrorResponse(reclaimResourcesResponse)) {
        const toast = getErrorToastWithComponent();
        toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CHECK_IN;
        toast.toastActionProps.text = "An error occurred when releasing meeting room(s), please try again.";
        toast.toastTimeout = TIMEOUT.NEVER;
        toast.toastActionProps.ariaLabel = "Error releasing meeting room(s)";

        // Remove loading indicator
        yield put(setMeetingAgendaLoaded(true));
        yield put(showToast(toast));
        return;
    }

    const toast = getSuccessToastWithComponent();
    toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CHECK_IN;
    toast.toastActionProps.text = `You have successfully released room ${action.resources.map((res) => res.displayName).join(",")}. Thank you for freeing up the rooms for other meetings.`;
    toast.toastTimeout = TIMEOUT.NEVER;
    toast.toastActionProps.ariaLabel = "Meeting room released";
    yield put(showToast(toast));
    yield put(refreshAgenda());
}

export function* watchReclaimResources() {
    yield takeEvery(Actions.RECLAIM_RESOURCES, reclaimResourcesApi);
}

export function* getRoomEligibilityApi(action) {
    const roomEligibilityDictionary = new Map();

    if (action.rooms && Array.isArray(action.rooms)) {
        const responses = yield all(
            action.rooms.map((roomEmail) => call(getRoomEligibility, roomEmail))
        );

        for (const response of responses) {
            const parsedResponse = JSON.parse(response);
            if (isErrorResponse(parsedResponse)) {
                const toast = getErrorToastWithComponent();
                toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CHECK_IN;
                toast.toastActionProps.text = "An error occurred when retrieving eligibility status for meeting room(s).";
                toast.toastTimeout = TIMEOUT.NEVER;
                toast.toastActionProps.ariaLabel = "Error retrieving eligibility status for meeting room(s)";
                yield put(showToast(toast));
                continue;
            }

            roomEligibilityDictionary.set(
                parsedResponse.roomEmail,
                parsedResponse.eligible
            );
        }
    }

    yield put(setRoomEligibility(roomEligibilityDictionary));
}

export function* watchGetRoomEligibility() {
    yield takeEvery(Actions.GET_ROOM_ELIGIBILITY, getRoomEligibilityApi);
}

export function* getCheckInStatusApi(action) {
    const roomCheckInStatusDictionary = new Map();

    const response = yield call(getCheckInStatus, action.meetings);
    const checkInRoomStatusResponse = JSON.parse(response);

    if (
        checkInRoomStatusResponse.success?.length === 0 &&
        checkInRoomStatusResponse.failure?.length > 0
    ) {
        const toast = getErrorToastWithComponent();
        toast.toastActionProps.componentName = TOAST_COMPONENT.MEETING_CHECK_IN;
        toast.toastActionProps.text = "An error occurred when getting check-in status for meeting room(s), please try again.";
        toast.toastTimeout = TIMEOUT.NEVER;
        toast.toastActionProps.ariaLabel = "Error getting check-in status for meeting room(s)";
        yield put(showToast(toast));
        return;
    }

    checkInRoomStatusResponse.success?.forEach((response) => {
        roomCheckInStatusDictionary.set(
            `${response.request.roomEmail}&${response.request.meetingStartTime}`,
            response.status
        );
    });

    const checkInFailures = checkInRoomStatusResponse.failure || checkInRoomStatusResponse.error?.failure || [];

    checkInFailures.forEach((response) => {
        roomCheckInStatusDictionary.set(
            `${response.request.roomEmail}&${response.request.meetingStartTime}`,
            response.message
        );
    });

    yield put(setCheckInStatus(roomCheckInStatusDictionary));
}

export function* watchCheckInStatus() {
    yield takeEvery(Actions.GET_ROOM_CHECK_IN_STATUS, getCheckInStatusApi);
}

const sagas = [
    watchCheckInRoom(),
    watchReleaseRoom(),
    watchGetRoomEligibility(),
    watchReclaimResources(),
    watchCheckInStatus(),
    watchCheckInAndReclaimResources(),
];

export default sagas;