import React from "react";

import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "../meridian-custom-components/src/components/select";
import Row from "@amzn/meridian/row";

const SimpleDurationSelect = (props) => {
    const label = props.label;
    const labelId = props.labelId;
    const width = props.width || "180px";
    const placeholder = props.width ? "Select duration" : "Select min duration";

    return (
        <Row spacing="medium" alignmentVertical="top">
            <Column spacing="none" spacingInset="none">
                <Text id={labelId} type={props.textSize} alignment="left">{label}</Text>
                <Select
                    size={"small"}
                    width={width}
                    value={props.duration}
                    onChange={props.setDuration}
                    placeholder={placeholder}
                    aria-labelledby={labelId}
                >
                    <SelectOption label="15 mins" value="15" />
                    <SelectOption label="30 mins" value="30" />
                    <SelectOption label="45 mins" value="45" />
                    <SelectOption label="1 hour" value="60" />
                    <SelectOption label="1 hour, 30 mins" value="90" />
                    <SelectOption label="2 hours" value="120" />
                </Select>
            </Column>
        </Row>
    );
};

export default SimpleDurationSelect;
