import React, { useState, useEffect } from "react";

import DOMPurify from "dompurify";

import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Column from "@amzn/meridian/column";
import Toggle from "@amzn/meridian/toggle";
import Select, { SelectOption } from "../../shared/meridian-custom-components/src/components/select";
import Checkbox from "@amzn/meridian/checkbox";
import Tab, { TabGroup } from "@amzn/meridian/tab";

import PatternBackground from "../../../assets/backgrounds/pattern_small_vertical_repeating.svg";

import RichTextEditor from "../../shared/components/rich-text-editor";
import LivestreamReviewCard from "./livestream-review-card";
import AlertsContainer from "../../shared/containers/alerts-container";

import { AGENDA_GROUP } from "../livestream-constants";
import {
    BACKGROUND_OPACITY,
    INPUT_HEIGHT,
    INPUT_WIDTH,
} from "../../meeting-scheduler/meeting-scheduler-constants";
import { STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { ALERTS, SCREEN_SIZE } from "../../shared/shared-constants";
import { chimeEmailToAttendee } from "../../people/people-utils";
import { getFormattedChimePin } from "../../shared/meeting-utils";
import { generateComponentId } from "../../shared/shared-utils";
import { updateBridgeInfoInBody } from "../../shared/chime/manage-bridge-info";
import { COMPONENT_LABELS } from "../../shared/accessibility-constants";

const StepEventAgendaAndReview = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const meeting = props.meeting;
    const onUpdateCurrentMeeting = props.onUpdateCurrentMeeting;
    const onAddAttendee = props.onAddAttendee;
    const onRemoveAttendee = props.onRemoveAttendee;
    const chimeUniqueIds = props.chimeUniqueIds;
    const chimeUser = props.chimeUser;
    const generatedChimeBridge = chimeUniqueIds.AllocatedPins && chimeUniqueIds.AllocatedPins[0].Pin;
    const personalChimeBridge = chimeUser.PersonalPIN;
    const isSubjectEmpty = props.isSubjectEmpty;
    const eventName = props.eventName.trim();
    const livestreamTitle = props.livestreamTitle.trim();
    const alerts = props.reviewAlerts || [];
    const timeFormat = props.timeFormat;

    const [chimePin, setChimePin] = [props.chimePin, props.setChimePin];
    const [copyEventName, setCopyEventName] = [props.copyEventName, props.setCopyEventName];
    const [prevEventName, setPrevEventName] = [props.prevEventName, props.setPrevEventName];
    const [agendaTab, setAgendaTab] = [props.agendaTab, props.setAgendaTab];
    const [agendaViewerText, setAgendaViewerText] = [props.agendaViewerText, props.setAgendaViewerText];
    const [subject, setSubject] = useState(meeting.subject);
    const [location, setLocation] = useState(meeting.location);

    const CHIME_LABEL_ID = "chime-label-id";
    const SHOW_AS_LABEL_ID = "show-as-label-id";

    const updateSubject = () => onUpdateCurrentMeeting({subject: subject});
    const updateSubjectTo = (newSubject) => onUpdateCurrentMeeting({subject: newSubject});
    const updateLocation = () => onUpdateCurrentMeeting({location: location});
    const updateLocationTo = (newLocation) => onUpdateCurrentMeeting({location: newLocation});
    const updateMeetingBody = (value) => {
        onUpdateCurrentMeeting({
            body: {
                ...meeting.body,
                value: DOMPurify.sanitize(value).replaceAll("<p>", "<div>").replaceAll("</p>","</div>")
            }
        });
    };
    const updateViewerMeetingBody = (value) => {
        setAgendaViewerText(DOMPurify.sanitize(value).replaceAll("<p>", "<div>").replaceAll("</p>","</div>"));
    };

    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChimePin = (newChimePin) => {
        props.setAutoCall(newChimePin !== "none");

        if (!chimePin || !isNaN(chimePin)) {
            meeting.requiredAttendees.forEach((attendee) => {
                if (attendee.email.endsWith("@chime.aws")) {
                    onRemoveAttendee({email: attendee.email});
                }
            });
        }

        setChimePin(newChimePin);

        if (!isNaN(newChimePin)) {
            updateMeetingBody(updateBridgeInfoInBody(meeting.body.value, {bridgeID: newChimePin}));
            if (props.autoCall) {
                onAddAttendee(chimeEmailToAttendee("meet@chime.aws"));
            }
            if (newChimePin === generatedChimeBridge) {
                onAddAttendee(chimeEmailToAttendee(`pin+${newChimePin}@chime.aws`));
            }
        } else {
            updateMeetingBody(updateBridgeInfoInBody(meeting.body.value, null));
        }
    };

    // to mimic componentDidMount, running once initially to set initialChimePin
    useEffect(() => {
        if (!chimePin) {
            handleChimePin(generatedChimeBridge);
        }
    // eslint-disable-next-line
    }, []);

    // If the livestream has an event name, use it as the meeting subject by default
    useEffect(() => {
        // Only update the subject if the user has not changed it
        if (eventName && copyEventName && subject === prevEventName) {
            const meetingSubject = livestreamTitle === eventName ? eventName : (livestreamTitle + ": " + eventName);
            setSubject(meetingSubject);
            updateSubjectTo(meetingSubject);
            setPrevEventName(meetingSubject);
            setCopyEventName(false);
        }
    // eslint-disable-next-line
    }, [eventName, copyEventName, subject, prevEventName, livestreamTitle]);

    // Fill in the Meeting location with whatever the event location, that is chosen on step-1 and if room is added in step-2, display the room location.
    useEffect(() => {
        const eventLocation = props.eventRequirements.eventLocation;
        if (eventLocation === "OFF_CAMPUS") {
            setLocation("Off campus");
            updateLocationTo("Off campus");
        } else if (eventLocation === "REMOTE_OR_VIRTUAL" || (eventLocation === "MEETING_ROOM" && !meeting.location.includes("CONF"))) {
            setLocation("Remote/virtual");
            updateLocationTo("Remote/virtual");
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Row
            widths={["fill", "fit", "fill"]}
            alignmentVertical="top"
        >
            {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                <div style={{
                    height: "100%",
                    backgroundImage: `url(${PatternBackground})`,
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "left -250px center",
                    backgroundAttachment: "fixed",
                    opacity: BACKGROUND_OPACITY}}
                />
            }
            <Column
                heights={["fit", "fill"]}
                maxWidth={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? "1000px" : null}
                alignmentVertical="top"
            >
                <Row backgroundColor="white" width="100%" maxWidth={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "" : "360px"} wrap="down" widths={["fill", "fit"]} alignmentVertical="top" >
                    <Column
                        heights={["fit","fit","fit","fill"]}
                        spacingInset="small medium"
                        width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND : null}
                    >
                        {alerts.length !== 0 &&
                            <AlertsContainer alerts={alerts} />
                        }
                        <Column >
                            <Column spacing="xsmall">
                                <Column spacing="none">
                                    <Text type="b200">Meeting subject</Text>
                                    <Input
                                        aria-label={COMPONENT_LABELS.SUBJECT}
                                        aria-describedby={ALERTS.INVALID_MEETING_SUBJECT.id}
                                        value={subject}
                                        onChange={setSubject}
                                        onBlur={updateSubject}
                                        type="text"
                                        error={isSubjectEmpty}
                                        placeholder="Enter meeting subject"
                                        width="100%"
                                        size="small"
                                    />
                                </Column>
                                {isSubjectEmpty &&
                                    <Alert type={ALERTS.INVALID_MEETING_SUBJECT.type} size="small">
                                        <Text id={ALERTS.INVALID_MEETING_SUBJECT.id}>
                                            {ALERTS.INVALID_MEETING_SUBJECT.value}
                                        </Text>
                                    </Alert>
                                }
                            </Column>
                            <Column spacing="none">
                                <Text type="b200">Meeting location</Text>
                                <Input
                                    aria-label={COMPONENT_LABELS.LOCATION}
                                    disabled={true}
                                    value={location}
                                    onChange={setLocation}
                                    onBlur={updateLocation}
                                    type="text"
                                    placeholder="Enter meeting location"
                                    width="100%"
                                    size="small"
                                />
                            </Column>
                            <Column spacing="small">
                                <Row alignmentVertical="bottom" wrap="down">
                                    <Column spacing="none">
                                        <Text id={CHIME_LABEL_ID} type="b200">Chime</Text>
                                        <Select
                                            value={chimePin}
                                            size="small"
                                            onChange={handleChimePin}
                                            placeholder="Select chime option"
                                            width={INPUT_WIDTH.CHIME_WIDTH}
                                            aria-labelledby={CHIME_LABEL_ID}
                                            disabled={true}
                                        >
                                            <SelectOption value="none" label="None" />
                                            {generatedChimeBridge &&
                                                <SelectOption
                                                    value={generatedChimeBridge}
                                                    label={`Generate ID: ${getFormattedChimePin(generatedChimeBridge)}`}
                                                />
                                            }
                                            {personalChimeBridge &&
                                                <SelectOption
                                                    value={personalChimeBridge}
                                                    label={`Personal ID: ${getFormattedChimePin(personalChimeBridge)}`}
                                                />
                                            }
                                        </Select>
                                    </Column>
                                    <Row spacingInset="none none xsmall none">
                                        <Toggle id={generateComponentId("toggle", "auto-call", "livestream-intake")} checked={props.autoCall} onChange={props.setAutoCall} disabled={true}>Auto-call participants</Toggle>
                                    </Row>
                                </Row>
                                <Alert type="warning" size="small">
                                    Chime is reserved for the livestream engineers and presenters to prepare for the livestream. <b>Please do not provide Chime info to viewers.</b>
                                </Alert>
                            </Column>
                            <Row alignmentVertical="bottom" wrap="down">
                                <Column spacing="none">
                                    <Text id={SHOW_AS_LABEL_ID} type="b200">Show as</Text>
                                    <Select
                                        value={meeting.status}
                                        size="small"
                                        onChange={(status) => props.onUpdateCurrentMeeting({status: status})}
                                        placeholder="Select show status as"
                                        width={INPUT_WIDTH.STATUS_WIDTH}
                                        aria-labelledby={SHOW_AS_LABEL_ID}
                                    >
                                        <SelectOption value="free" label="Free" />
                                        <SelectOption value="tentative" label="Tentative" />
                                        <SelectOption value="busy" label="Busy" />
                                        <SelectOption value="outOfOffice" label="Out Of The Office" />
                                    </Select>
                                </Column>
                                <Row spacingInset="none none xsmall none">
                                    <Checkbox
                                        checked={meeting.isPrivate}
                                        onChange={() => props.onUpdateCurrentMeeting({isPrivate: !meeting.isPrivate})}
                                        id={generateComponentId("checkbox", "private")}
                                    >
                                        Private
                                    </Checkbox>
                                    <Checkbox
                                        checked={meeting.isResponseRequested}
                                        onChange={() => props.onUpdateCurrentMeeting({isResponseRequested: !meeting.isResponseRequested})}
                                        id={generateComponentId("checkbox", "request-responses")}
                                    >
                                        Request responses
                                    </Checkbox>
                                </Row>
                            </Row>
                        </Column>
                    </Column>
                    <Column>
                        <LivestreamReviewCard
                            suggestionViewType={props.suggestionViewType}
                            currentStep={props.currentStep}
                            onChangeStep={props.onChangeStep}
                            meetingDetailsSelector={props.getMeeting}
                            attendees={props.attendees}
                            eventRequirements={props.eventRequirements}
                            timezones={props.timezones}
                            suggestionsTimezoneId={props.timezoneValue}
                            isExistingMeetingSelected={props.isExistingMeetingSelected}
                            meetingDetails={props.meetingDetails}
                            onSelectSuggestion={props.onSelectSuggestion}
                            currentYear={props.currentYear}
                            meetingSuggestions={props.meetingSuggestions}
                            meetingSuggestionSelected={props.meetingSuggestionSelected}
                            meetingSuggestionsDetail={props.meetingSuggestionsDetail}
                            durationSelected={props.durationSelected}
                            customDurationValue={props.customDurationValue}
                            customDurationSelected={props.customDurationSelected}
                            screenSizeBreakpoint={props.screenSizeBreakpoint}
                            timeFormat={timeFormat}
                        />
                    </Column>
                </Row>
                <Column spacing="none" spacingInset="none medium">
                    <TabGroup value={agendaTab} onChange={setAgendaTab} type="classic">
                        {Object.keys(AGENDA_GROUP).map((agendaGroup) => (
                            <Tab key={agendaGroup} value={AGENDA_GROUP[agendaGroup]}>{AGENDA_GROUP[agendaGroup]}</Tab>
                        ))}
                    </TabGroup>
                    <Column>
                        <div style={{
                            paddingBottom: STEP_NAVIGATION_FOOTER.HEIGHT,
                        }}>
                            {agendaTab === AGENDA_GROUP.PRESENTERS ?
                                <RichTextEditor
                                    key={`${chimePin}.`}
                                    textValue={meeting.body.value}
                                    updateTextValue={updateMeetingBody}
                                    height={INPUT_HEIGHT.TEXT_EDITOR_HEIGHT}
                                />
                                :
                                <RichTextEditor
                                    key={`ViewerAgenda`}
                                    textValue={agendaViewerText}
                                    updateTextValue={updateViewerMeetingBody}
                                    height={INPUT_HEIGHT.TEXT_EDITOR_HEIGHT}
                                />
                            }
                        </div>
                    </Column>
                </Column>
            </Column>
            {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                <div style={{
                    height: "100%",
                    backgroundImage: `url(${PatternBackground})`,
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "right -250px center",
                    backgroundAttachment: "fixed",
                    opacity: BACKGROUND_OPACITY}}
                />
            }
        </Row>
    );
};

export default StepEventAgendaAndReview;
