import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showToast } from "../../shared/toasts/actions";
import { respondPoll } from "../actions";
import { getPollDetailsLoaded } from "../../../sagas/selector";

import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Card, { CardCheckbox } from "@amzn/meridian/card";

import PollRespondCard from "./poll-respond-card";
import ErrorMessageWrapper from "../../shared/components/error-message-wrapper";

import { SCREEN_SIZE } from "../../shared/shared-constants";
import { formatDateTime } from "../poll-utils";
import { DEFAULT_ORGANIZER_NAME } from "../poll-constants";
import { TIMEOUT, TOAST_ACTION_TYPE, TOAST_COMPONENT, TOAST_TYPE } from "../../shared/toasts/toast-constants";

const PollRespondAvailabilityBox = (props) => {
    const pollDetailsLoaded = useSelector(getPollDetailsLoaded);
    const pollID = props.pollID;
    const identity = props.identity;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const organizer = props.organizer;
    const pollDetails = props.pollDetails;
    const availabilityList = props.availabilityList;
    const respondedAttendeeList = props.respondedAttendeeList;
    const timeFormat = props.timeFormat;
    const browserTimezoneName = props.browserTimezoneName;
    const setAgendaOpen = props.setAgendaOpen;
    const response = props.response;
    const attendeeAvailabilityList = props.attendeeAvailabilityList;
    const expiration = props.expiration ? new Date(props.expiration / 1000) : "";
    const timezone = props.timezone;

    const [noneBoxChecked, setNoneBoxChecked] = useState(false);
    const onClickMyCalendar = useCallback(() => setAgendaOpen(true), [setAgendaOpen]);
    const [availabilityVotes, setAvailabilityVotes] = useState([]);

    const [showVotesErrorMessage, setShowVotesErrorMessage] = useState(false);

    const dispatch = useDispatch();

    const errorValidation = (availabilityVotes, noneBoxChecked) => {
        let hasError = false;

        // Check votes
        if (availabilityVotes?.length > 0 || noneBoxChecked) {
            setShowVotesErrorMessage(false);
        } else {
            hasError = true;
            setShowVotesErrorMessage(true);
        }

        return hasError;
    }

    const onSubmitVotes = () => {
        const hasError = errorValidation(availabilityVotes, noneBoxChecked);

        if (!hasError) {
            dispatch(respondPoll(identity.username, pollID, availabilityVotes));
            dispatch(showToast({
                toastType: TOAST_TYPE.SUCCESS,
                toastActionType: TOAST_ACTION_TYPE.DISPLAY_COMPONENT,
                toastActionProps: {
                    componentName: TOAST_COMPONENT.POLL_RESPONDED,
                    organizerName: organizer?.name || DEFAULT_ORGANIZER_NAME
                },
                toastTimeout: TIMEOUT.DEFAULT,
            }));
        }
    };

    const onSelectNoneCard = () => {
        if (noneBoxChecked) {
            setNoneBoxChecked(false);
        } else {
            setNoneBoxChecked(true);
            setAvailabilityVotes([]);
        }
    };

    useEffect(() => {
        const card = document.getElementById("card-none-checked")?.firstChild;
        if (card) {
            card.removeAttribute("role");
        }
        const labelCollection = document.getElementsByTagName("label");
        if (labelCollection) {
            for (let i = 0; i < labelCollection.length; i++) {
                labelCollection[i].setAttribute("for", "card-checkbox");
            }
        }
    });

    return(
        <Box
            width="100%"
            type="outline"
        >
            {pollDetailsLoaded &&
                <Column spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.SM ? "medium xlarge" : "medium"}>
                    <Row spacing="small" alignmentHorizontal="center">
                        <Text type="b200" color="secondary">Respond by</Text>
                        {pollDetails && expiration &&
                            <React.Fragment>
                                <Text type="b200">
                                    {formatDateTime(expiration, "shortWeekdayDate", timezone).replace(",", "")}&nbsp;
                                    {formatDateTime(expiration, "time", timezone, timeFormat)}
                                </Text>
                                <Text type="b200" color="secondary">
                                    {browserTimezoneName}
                                </Text>
                            </React.Fragment>
                        }
                    </Row>
                    <Row alignmentHorizontal="justify" spacingInset="large none none none" wrap="down">
                        <Column alignmentVertical="center" spacing="small">
                            <Heading level={3} type="h100" id="checkbox-group">
                                Select times that work for you
                            </Heading>
                        </Column>
                        <Button
                            type="tertiary"
                            size="medium" l
                            abel="Change left tab to my calendar"
                            onClick={onClickMyCalendar}
                        >
                            My calendar
                        </Button>
                    </Row>
                    <div role="group" aria-labelledby="checkbox-group">
                        <Column spacing="medium">
                            <Column>
                                {availabilityList && availabilityList.length > 0 && availabilityList.map((availability, index) =>
                                    <PollRespondCard
                                        index={index}
                                        identity={identity}
                                        screenSizeBreakpoint={screenSizeBreakpoint}
                                        respondedAttendeeList={respondedAttendeeList}
                                        availability={availability}
                                        timezone={timezone}
                                        timeFormat={timeFormat}
                                        browserTimezoneName={browserTimezoneName}
                                        noneBoxChecked={noneBoxChecked}
                                        setNoneBoxChecked={setNoneBoxChecked}
                                        availabilityVotes={availabilityVotes}
                                        setAvailabilityVotes={setAvailabilityVotes}
                                        response={response}
                                        attendeeAvailabilityList={attendeeAvailabilityList}
                                        meetingsForAvailability={props.meetingsForAvailability}
                                    />
                                )}
                            </Column>
                            <div id="card-none-checked">
                                <Card
                                    spacingInset="medium"
                                    checked={noneBoxChecked}
                                    onClick={onSelectNoneCard}
                                    value={noneBoxChecked}
                                >
                                    <Row>
                                        <div aria-label="None of these times work">
                                            <CardCheckbox id="card-checkbox" />
                                        </div>
                                        <Text type="h100">
                                            None of these work
                                        </Text>
                                    </Row>
                                </Card>
                            </div>
                        </Column>
                    </div>
                    <ErrorMessageWrapper errorMessage="Select at least one option" showErrorMessage={showVotesErrorMessage}>
                    </ErrorMessageWrapper>
                    <Column spacing="none" spacingInset="large none none none">
                        <Button
                            type="primary"
                            size="large"
                            onClick={onSubmitVotes}
                        >
                            Submit votes
                        </Button>
                    </Column>
                </Column>
            }
        </Box>
    )
};

export default PollRespondAvailabilityBox;