import React, { useEffect, useState } from "react";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import Modal, { ModalFooter } from "../../shared/meridian-custom-components/src/components/modal";
import Row from "@amzn/meridian/row";
import Theme from "@amzn/meridian/theme";
import blueLightTokens from "@amzn/meridian-tokens/theme/blue-light";
import closeTokens from "@amzn/meridian-tokens/base/icon/close-small";
import tealLightTokens from "@amzn/meridian-tokens/theme/teal-light";
import tealDarkTokens from "@amzn/meridian-tokens/theme/teal-dark";

import DiscardMeetingModal from "../../shared/components/discard-meeting-modal";
import MeetingQuickFavoritesTab from "./meeting-quick-favorites";
import MeetingQuickForm from "./meeting-quick-form";
import MeetingQuickReview from "./meeting-quick-review";
import MeetingQuickSuggestion from "./meeting-quick-suggestion";
import MeetingQuickTabsMobile from "./meeting-quick-tabs-mobile";

import "../meeting-quick.css";

import { KEYCODE } from "../../shared/shared-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { MODAL_STATE, SCREEN_SIZE, MODAL_SIZE, COLOR } from "../meeting-quick-constants";

const MeetingQuickModal = (props) => {
    // useEffect dependencies
    const chimePin = props.chimePin;
    const onSetChimePin = props.onSetChimePin;
    const chimeUniqueIds = props.chimeUniqueIds;
    const chimeUser = props.chimeUser;
    const usePersonalChimePin = props.usePersonalChimePin;
    const [discardMeetingModalOpen, setDiscardMeetingModalOpen] = [props.discardMeetingModalOpen, props.setDiscardMeetingModalOpen];

    const SIMPLE_MEETING_MODAL_ID = "simple-meeting-modal-id";

    const [isLastStepLoading, setIsLastStepLoading] = useState(false); // disable send invites button

    // fill in chime pin field when generated chime pin is available and initial chime pin has not been set.
    useEffect(() => {
        if (chimeUser && chimeUniqueIds && chimeUniqueIds.AllocatedPins && chimeUniqueIds.AllocatedPins.length > 0 && chimePin === undefined) {
            onSetChimePin(usePersonalChimePin ? chimeUser.PersonalPIN : chimeUniqueIds.AllocatedPins && chimeUniqueIds.AllocatedPins[0].Pin, true);
        }
    }, [onSetChimePin, chimeUniqueIds, chimePin, chimeUser, usePersonalChimePin]);

    const onKeyDown = (event) => {
        const keyCode = event.keyCode || event.which;
        switch (keyCode) {
            case KEYCODE.ESCAPE:
                setDiscardMeetingModalOpen(true);
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    const onKeyDownCloseButton = (event) => {
        const keyCode = event.keyCode || event.which;
        const special = event.shiftKey;

        if (keyCode === KEYCODE.SPACE || keyCode === KEYCODE.ENTER) {
            setDiscardMeetingModalOpen(true);
            event.preventDefault();
            return;
        }

        if (special && keyCode === KEYCODE.TAB) {
            event.preventDefault();
            props.reviewButtonRef && props.reviewButtonRef.current && props.reviewButtonRef.current.focus();
        }
    };

    const onSendClick = () => {
        setIsLastStepLoading(true); // During final step, set it to loading to prevent double click
        props.onSendMeetingRequest();
        // re-enable the button after 1 sec in case the meeting creation fails
        window.setTimeout(() => {
            setIsLastStepLoading(false);
        }, 1000); // 1 sec timer
    };


    const getMeetingFooter = () => {
        return (
            <ModalFooter>
                <Row alignmentHorizontal="justify"
                     backgroundColor={props.screenSizeBreakpoint >= SCREEN_SIZE.QUICK_MEETING_FULL_VIEW && props.showReview ? COLOR.TEAL : COLOR.WHITE}
                     spacingInset={props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ? "none" : "medium"}
                >
                    <Theme tokens={props.screenSizeBreakpoint >= SCREEN_SIZE.QUICK_MEETING_FULL_VIEW && props.showReview ? tealDarkTokens : tealLightTokens}>
                        <Button type="tertiary" size="medium" onClick={() => props.onShowReview(false)}>Back</Button>
                        <Row spacing="medium" alignmentHorizontal="right">
                            <Theme tokens={tealLightTokens}>
                                <Button type="secondary" size="medium" onClick={() => {setDiscardMeetingModalOpen(true)}}>Cancel</Button>
                            </Theme>
                            <Button type="primary" size="medium" disabled={isLastStepLoading} onClick={onSendClick}>Send invite(s)</Button>
                        </Row>
                    </Theme>
                </Row>
            </ModalFooter>
        )
    };

    return (
        <React.Fragment>
            <Theme tokens={blueLightTokens}>
                <div onKeyDown={onKeyDown}>
                    <Modal
                        id={SIMPLE_MEETING_MODAL_ID}
                        width={props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ? "100%" : MODAL_SIZE.WIDTH}
                        open={props.quickMeetingModalState === MODAL_STATE.OPEN}
                        scrollContainer="viewport"
                        bodySpacingInset="none"
                    >
                        <div style={{
                            background: (props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW || props.showReview) ? COLOR.WHITE : COLOR.TEAL,
                            position: "relative",
                        }}>
                            <span
                                aria-label="Close"
                                role="button"
                                ref={props.closeButtonRef}
                                tabIndex="0"
                                className={(props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW || props.showReview) ? "close-dark" : "close-light"}
                                style={{
                                    padding: props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ? "16px" : "",
                                    position: props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ? "absolute" : "",
                                    right: props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ? "0" : "",
                                }}
                                onClick={() => setDiscardMeetingModalOpen(true)}
                                onKeyDown={onKeyDownCloseButton}
                            >
                                <Icon tokens={closeTokens} />
                            </span>
                            <div aria-label=" ">
                                <Column height={MODAL_SIZE.HEIGHT} heights={["fill"]} alignmentHorizontal="stretch">
                                    {props.screenSizeBreakpoint >= SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ?
                                        (!props.showReview ?
                                            <Row alignmentVertical="stretch" spacing="none" widths={["55%", "45%"]}>
                                                <Column backgroundColor="#FFFFFF">
                                                    <MeetingQuickForm
                                                        screenSizeBreakpoint={props.screenSizeBreakpoint}
                                                        favorites={props.favorites}
                                                        onAddFavorite={props.onAddFavorite}
                                                        onRemoveFavorite={props.onRemoveFavorite}
                                                        onBrowseFavorites={props.onBrowseFavorites}
                                                        onFavoriteClick={props.onFavoriteClick}
                                                        isFavorited={props.isFavorited}
                                                        location={props.location}
                                                        setLocation={props.setLocation}
                                                        getRoomOptions={props.getRoomOptions}
                                                        duration={props.duration}
                                                        setDuration={props.setDuration}
                                                        customDuration={props.customDuration}
                                                        setCustomDuration={props.setCustomDuration}
                                                        customDurationUnits={props.customDurationUnits}
                                                        setCustomDurationUnits={props.setCustomDurationUnits}
                                                        showCustomDuration={props.showCustomDuration}
                                                        setShowCustomDuration={props.setShowCustomDuration}
                                                        getDurationInMinutes={props.getDurationInMinutes}
                                                        meetingSubject={props.meetingSubject}
                                                        setMeetingSubject={props.setMeetingSubject}
                                                        meetingBody={props.meetingBody}
                                                        setMeetingBody={props.setMeetingBody}
                                                        isPrivate={props.isPrivate}
                                                        setIsPrivate={props.setIsPrivate}
                                                        isResponseRequested={props.isResponseRequested}
                                                        setIsResponseRequested={props.setIsResponseRequested}
                                                        chimePin={props.chimePin}
                                                        onSetChimePin={props.onSetChimePin}
                                                        autoCall={props.autoCall}
                                                        setAutoCall={props.setAutoCall}
                                                        chimeUniqueIds={props.chimeUniqueIds}
                                                        chimeUser={props.chimeUser}
                                                        attendees={props.attendees}
                                                        timezoneValue={props.timezoneValue}
                                                        onCloseQuickMeetingModal={props.onCloseQuickMeetingModal}
                                                        showQuickSuggestions={props.showQuickSuggestions}
                                                        onGetSuggestions={props.onGetSuggestions}
                                                        peopleSuggestions={props.peopleSuggestions}
                                                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                                                        onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                                                        onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                                                        groupSuggestions={props.groupSuggestions}
                                                        onGetRASDataForGroup={props.onGetRASDataForGroup}
                                                        onGetGroupSuggestions={props.onGetGroupSuggestions}
                                                        onClearGroupSuggestions={props.onClearGroupSuggestions}
                                                        onAddAttendee={props.onAddAttendee}
                                                        onRemoveAttendee={props.onRemoveAttendee}
                                                        onRemoveAttendeeByAlias={props.onRemoveAttendeeByAlias}
                                                        alerts={props.alerts}
                                                        durationExceedsRoomLimit={props.durationExceedsRoomLimit}
                                                        setIsFirstSearch={props.setIsFirstSearch}
                                                        isFirstSearch={props.isFirstSearch}
                                                        selectedSuggestionIndex={props.selectedSuggestionIndex}
                                                        suggestions={props.suggestions}
                                                        onShowReview={props.onShowReview}
                                                        setDiscardMeetingModalOpen={setDiscardMeetingModalOpen}
                                                        subjectRef={props.subjectRef}
                                                        getSuggestionsButtonRef={props.getSuggestionsButtonRef}
                                                        closeButtonRef={props.closeButtonRef}
                                                        reviewButtonRef={props.reviewButtonRef}
                                                        dateRangeValue={props.dateRangeValue}
                                                        setDateRangeValue={props.setDateRangeValue}
                                                        dateRangeLabel={props.dateRangeLabel}
                                                        setDateRangeLabel={props.setDateRangeLabel}
                                                        startTime={props.startTime}
                                                        setStartTime={props.setStartTime}
                                                        endTime={props.endTime}
                                                        setEndTime={props.setEndTime}
                                                        timeLabel={props.timeLabel}
                                                        setTimeLabel={props.setTimeLabel}
                                                        presets={props.presets}
                                                        timeFormat={props.timeFormat}
                                                        dateFormat={props.dateFormat}
                                                    />
                                                </Column>
                                                {props.showQuickSuggestions ?
                                                    <MeetingQuickSuggestion
                                                        attendees={props.attendees}
                                                        timezones={props.timezones}
                                                        timezoneValue={props.timezoneValue}
                                                        suggestions={props.suggestions}
                                                        selectedSuggestionIndex={props.selectedSuggestionIndex}
                                                        setSelectedSuggestionIndex={props.setSelectedSuggestionIndex}
                                                        meetingSubject={props.meetingSubject}
                                                        setMeetingSubject={props.setMeetingSubject}
                                                        meetingBody={props.meetingBody}
                                                        setMeetingBody={props.setMeetingBody}
                                                        setShowQuickSuggestions={props.setShowQuickSuggestions}
                                                        onCloseQuickSuggestions={props.onCloseQuickSuggestions}
                                                        onCloseQuickMeetingModal={props.onCloseQuickMeetingModal}
                                                        onSendMeetingRequest={props.onSendMeetingRequest}
                                                        alerts={props.alerts}
                                                        screenSizeBreakpoint={props.screenSizeBreakpoint}
                                                        subjectRef={props.subjectRef}
                                                        getSuggestionsButtonRef={props.getSuggestionsButtonRef}
                                                    />
                                                    :
                                                    <MeetingQuickFavoritesTab
                                                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                                                        screenSizeBreakpoint={props.screenSizeBreakpoint}
                                                        favoritesRef={props.favoritesRef}
                                                        onAddAttendee={props.onAddAttendee}
                                                    />
                                                }
                                            </Row>
                                            :
                                            <MeetingQuickReview 
                                                reviewAlerts={props.reviewAlerts}
                                                timeFormat={props.timeFormat} 
                                            />
                                        )
                                        :
                                        <Row alignmentVertical="stretch" spacing="none" widths={["fill"]}>
                                            <MeetingQuickTabsMobile
                                                screenSizeBreakpoint={props.screenSizeBreakpoint}
                                                favorites={props.favorites}
                                                onAddFavorite={props.onAddFavorite}
                                                onRemoveFavorite={props.onRemoveFavorite}
                                                onBrowseFavorites={props.onBrowseFavorites}
                                                onFavoriteClick={props.onFavoriteClick}
                                                isFavorited={props.isFavorited}
                                                duration={props.duration}
                                                setDuration={props.setDuration}
                                                customDuration={props.customDuration}
                                                setCustomDuration={props.setCustomDuration}
                                                customDurationUnits={props.customDurationUnits}
                                                setCustomDurationUnits={props.setCustomDurationUnits}
                                                showCustomDuration={props.showCustomDuration}
                                                setShowCustomDuration={props.setShowCustomDuration}
                                                getDurationInMinutes={props.getDurationInMinutes}
                                                location={props.location}
                                                setLocation={props.setLocation}
                                                getRoomOptions={props.getRoomOptions}
                                                chimePin={props.chimePin}
                                                onSetChimePin={props.onSetChimePin}
                                                chimeUniqueIds={props.chimeUniqueIds}
                                                chimeUser={props.chimeUser}
                                                attendees={props.attendees}
                                                timezones={props.timezones}
                                                timezoneValue={props.timezoneValue}
                                                onCloseQuickMeetingModal={props.onCloseQuickMeetingModal}
                                                showQuickSuggestions={props.showQuickSuggestions}
                                                onGetSuggestions={props.onGetSuggestions}
                                                suggestions={props.suggestions}
                                                selectedSuggestionIndex={props.selectedSuggestionIndex}
                                                setSelectedSuggestionIndex={props.setSelectedSuggestionIndex}
                                                meetingSubject={props.meetingSubject}
                                                setMeetingSubject={props.setMeetingSubject}
                                                meetingBody={props.meetingBody}
                                                setMeetingBody={props.setMeetingBody}
                                                isPrivate={props.isPrivate}
                                                setIsPrivate={props.setIsPrivate}
                                                isResponseRequested={props.isResponseRequested}
                                                setIsResponseRequested={props.setIsResponseRequested}
                                                setShowQuickSuggestions={props.setShowQuickSuggestions}
                                                onSendMeetingRequest={props.onSendMeetingRequest}
                                                peopleSuggestions={props.peopleSuggestions}
                                                onGetRASDataForPerson={props.onGetRASDataForPerson}
                                                onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                                                onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                                                groupSuggestions={props.groupSuggestions}
                                                onGetRASDataForGroup={props.onGetRASDataForGroup}
                                                onGetGroupSuggestions={props.onGetGroupSuggestions}
                                                onClearGroupSuggestions={props.onClearGroupSuggestions}
                                                onAddAttendee={props.onAddAttendee}
                                                onRemoveAttendee={props.onRemoveAttendee}
                                                onRemoveAttendeeByAlias={props.onRemoveAttendeeByAlias}
                                                alerts={props.alerts}
                                                setShowFavorites={props.setShowFavorites}
                                                showFavorites={props.showFavorites}
                                                showReview={props.showReview}
                                                onShowReview={props.onShowReview}
                                                setDiscardMeetingModalOpen={setDiscardMeetingModalOpen}
                                                setIsFirstSearch={props.setIsFirstSearch}
                                                isFirstSearch={props.isFirstSearch}
                                                dateRangeValue={props.dateRangeValue}
                                                setDateRangeValue={props.setDateRangeValue}
                                                dateRangeLabel={props.dateRangeLabel}
                                                setDateRangeLabel={props.setDateRangeLabel}
                                                startTime={props.startTime}
                                                setStartTime={props.setStartTime}
                                                endTime={props.endTime}
                                                setEndTime={props.setEndTime}
                                                timeLabel={props.timeLabel}
                                                setTimeLabel={props.setTimeLabel}
                                                presets={props.presets}
                                                backgroundElementsId={SIMPLE_MEETING_MODAL_ID}
                                                autoCall={props.autoCall}
                                                setAutoCall={props.setAutoCall}
                                                timeFormat={props.timeFormat}
                                                dateFormat={props.dateFormat}
                                            />
                                        </Row>
                                    }
                                </Column>
                            </div>
                        </div>
                        {props.showReview ?
                            (props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_FULL_VIEW ?
                                getMeetingFooter()
                                :
                                <div> {getMeetingFooter()} </div>
                            )
                            :
                            <Row />
                        }
                    </Modal>
                    <DiscardMeetingModal
                        open={discardMeetingModalOpen}
                        setOpen={props.setDiscardMeetingModalOpen}
                        backgroundElementsId={SIMPLE_MEETING_MODAL_ID}
                        newWorkflow={WORKFLOWS.HOME}
                        onClose={props.onCloseQuickMeetingModal}
                    />
                </div>
            </Theme>
        </React.Fragment>
    );
}

export default MeetingQuickModal;