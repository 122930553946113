/**
 * Determines if two components are the same. Uses some tricks to smooth out
 * wrinkles with react-hot-loader in development mode
 *
 * @example
 * import Checkbox from "@amzn/meridian/checkbox"
 * import Radio from "@amzn/meridian/radio"
 *
 * console.log(isSameComponent(Checkbox, Checkbox)) // true
 * console.log(isSameComponent(Checkbox, Radio)) // false
 */
const isSameComponent = (a, b) => {
  if (!a || !b) {
    // Bail if either component wasn't provided
    return false
  } else if (process.env.NODE_ENV !== "production" && (a.name || b.name)) {
    // If a name is set then rely on that in development mode. The component
    // name is more reliable than the type in development mode becaues of a
    // limitation of react-hot-loader (see:
    // https://github.com/gaearon/react-hot-loader/issues/304). Don't use this
    // in production though because minification will change the name and break
    // the comparison.
    const aName = a.displayName || a.name
    const bName = b.displayName || b.name
    return aName === bName
  } else {
    // Just check if the components reference the same class/function.
    return a === b
  }
}

/**
 * Determines if an element is an instance of a particular component.
 *
 * @example
 * import Checkbox from "@amzn/meridian/checkbox"
 *
 * const isCheckbox = isElementOf(Checkbox)
 * console.log(isCheckbox(<Checkbox />)) // true
 */
const isElementOf = component => element => {
  if (!component || !element) {
    // Bail if either the component or element wasn't provided
    return false
  } else if (element.type && element.type.WrappedComponent) {
    // If the element's component has a static WrappedComponent property, assume
    // we're working with a HoC and compare the wrapped component instead of the
    // HoC component. The connect HoC from redux uses this pattern, so this
    // check ensures that isElementOf works correctly on redux-connected
    // components.
    return (
      isSameComponent(element.type.WrappedComponent, component) ||
      isSameComponent(element.type.WrappedComponent, component.WrappedComponent)
    )
  } else {
    // If WrappedComonent isn't set do a normal comparison of element.type
    // (the component that element was instantiated from) and component.
    return isSameComponent(element.type, component)
  }
}

export default isElementOf
