import React, { useMemo, useState } from "react";

import escapeRegExp from "lodash/escapeRegExp";

import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "../../meridian-custom-components/src/components/select";
import Text from "@amzn/meridian/text";

import { ALERTS } from "../../shared-constants";
import { ARIA_LABELS, ERRORS } from "../locations-constants";

const determineErrorType = (ariaLabel) => {
    switch (ariaLabel) {
        case ARIA_LABELS.BUILDING:
            return [ERRORS.INVALID_BUILDING, ERRORS.INVALID_BUILDING_ID, ALERTS.INVALID_BUILDING.id];
        case ARIA_LABELS.FLOOR:
            return [ERRORS.INVALID_FLOOR, ERRORS.INVALID_FLOOR_ID, ALERTS.INVALID_FLOOR.id];
        case ARIA_LABELS.ROOM:
            return [ERRORS.BUILDING_WITHOUT_ROOMS, ERRORS.BUILDING_WITHOUT_ROOMS_ID, ALERTS.BUILDING_WITHOUT_ROOMS.id];
        case ARIA_LABELS.LOCATION:
        default:
            return [ERRORS.INVALID_LOCATION, ERRORS.INVALID_LOCATION_ID, ALERTS.INVALID_LOCATION.id];
    }
};

/**
 * Location component, the selected value are returned by the callback specified in props.
 * If multi-select then a list of locations are returned, or else a single location is returned.
 * @param props - specify if the component should be multi-select, searchable and callback to handle the selected location
 */
const LocationSearchBar = (props) => {
    const ariaLabel = props.ariaLabel || ARIA_LABELS.LOCATION;
    const locationOptions = props.locationOptions || [];
    const location = props.location === undefined ? (locationOptions.length && locationOptions[0]) : props.location;
    const onLocationSelect = props.onLocationSelect;

    const [errorType, errorTypeId, alertTypeId] = determineErrorType(ariaLabel);

    let searchRegExp;
    let matchedOptions;

    let [searchQuery, setSearchQuery] = useState("");

    const LOCATION_SEARCH_BAR_LABEL_ID = `${(ariaLabel).toLowerCase()}-search-bar-label-id`;

    searchRegExp = useMemo(
        () => new RegExp(escapeRegExp(searchQuery), "i"),
        [searchQuery]
    );
    matchedOptions = locationOptions.filter(
        (option) => !searchQuery || searchRegExp.test(option.label)
    );

    return (
        <Row widths={props.width} spacing={props.spacing}>
            <div style={{display: "none"}}>
                <Text id={LOCATION_SEARCH_BAR_LABEL_ID}>{ariaLabel}</Text>
            </div>
            <Column spacing="none">
                {locationOptions.length !== 0 && location !== undefined ?
                    <Select
                        size="small"
                        value={location}
                        onChange={(location) => onLocationSelect(location)}
                        searchQuery={props.isSearchable ? searchQuery : undefined}
                        onSearch={props.isSearchable ? setSearchQuery : undefined}
                        placeholder={props.placeholder}
                        aria-labelledby={LOCATION_SEARCH_BAR_LABEL_ID}
                        aria-describedby={[alertTypeId, errorTypeId].join(" ")}
                        error={location === errorType}
                    >
                        {matchedOptions.map(option => (
                            <SelectOption
                                label={option.label}
                                value={option.value}
                                key={option.label}
                            />
                        ))}
                        {!matchedOptions.length ?
                            <Column
                                alignmentVertical="center"
                                spacing="small"
                                spacingInset="xlarge"
                            >
                                <Text alignment="center">No results</Text>
                            </Column>
                            :
                            null
                        }
                    </Select>
                    :
                    location !== errorType ?
                        <Loader size="small" />
                        :
                        <Select
                            size="small"
                            disabled={true}
                        >
                        </Select>
                }
                {location === errorType &&
                    <Text id={errorTypeId} type="b100" position="absolute" color="error"><b>{ariaLabel}</b> not found</Text>
                }
            </Column>
        </Row>

    );
};

export default LocationSearchBar;