import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { getUserFeedback, getUserRating } from "../../../sagas/selector";

import { postRatingMetric } from "../../shared/metrics/actions";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Expander from "@amzn/meridian/expander";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Sheet from "@amzn/meridian/sheet";
import Text from "@amzn/meridian/text";

import closeTokens from "@amzn/meridian-tokens/base/icon/close";
import helpTokens from "@amzn/meridian-tokens/base/icon/help";

import MeetingsFeedback from "../../shared/feedback/components/meetings-feedback";
import StatusLegend from "../../shared/components/status-legend";

import { COMPONENT_IDS } from "../../shared/accessibility-constants";
import { clearFeedback, updateUserFeedback, updateUserRating } from "../../shared/feedback/actions";
import { renderExternalLink } from "../../shared/shared-utils";

const MeetingsHelpMenu = (props) => {
    // Inputs
    const open = props.open;
    const menuCloseButtonRef = useRef();

    const userFeedback = useSelector(getUserFeedback);
    const userRating = useSelector(getUserRating);

    const dispatch = useDispatch();

    const onUpdateUserFeedback = (feedback) => dispatch(updateUserFeedback(feedback));
    const onUpdateUserRating = (rating) => dispatch(updateUserRating(rating));
    const onClearFeedback = () => dispatch(clearFeedback());

    const [tellUsWhy, setTellUsWhy] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onClose = () => {
        // Post rating metric if user had input and did not submit
        if (userRating && !submitted) {
            dispatch(postRatingMetric(userFeedback, userRating, false));
        }

        // Reset state variables on close
        setTellUsWhy(false);
        setSubmitted(false);

        props.onClose();
    };

    const ControlledExpander = (props) => {
        const [expanderOpen, setExpanderOpen] = useState();

        return (
            <Expander open={expanderOpen} onChange={setExpanderOpen} {...props} />
        );
    };

    return (
        <Sheet open={open} onClose={onClose} type="overlay" side="right">
            <Column spacing="small" height="100%" heights={["fill", "fit"]} width={props.helpMenuSize}>
                <Column alignmentVertical="top">
                    <Row widths={["fit", "fill", "fit"]}>
                        <Icon tokens={helpTokens}>Info</Icon>
                        <Heading level={2} type="h300">Help and resources</Heading>
                        <Button onClick={onClose} type="icon" ref={menuCloseButtonRef}>
                            <Icon tokens={closeTokens}>Close dialog</Icon>
                        </Button>
                    </Row>
                    <Divider size="large" />
                    <Heading level={3} type="h100">Tutorials and help</Heading>
                    <Column spacing="small">
                        {renderExternalLink("Website features", "https://w.amazon.com/bin/view/Meetex/AM2/#HFeatures")}
                        {renderExternalLink("Frequently asked questions (FAQ)", "https://w.amazon.com/bin/view/Meetex/AM2/#HFAQ")}
                        {renderExternalLink("Amazon Meetings wiki", "https://w.amazon.com/bin/view/Meetex/AM2/")}
                        {renderExternalLink("Set up a Polycom bridge", "https://tiny.amazon.com/1biewzgdp/polycomconfportalexception")}
                        {renderExternalLink("Request access to our Meetings APIs", "https://w.amazon.com/bin/view/UnifiedCommunications/Amazon_Meetings/Onboarding_ResourceAdapter/")}
                        {renderExternalLink("Permalink query parameters", "https://w.amazon.com/bin/view/Meetex/AM2/Resources/QueryParameters/")}
                    </Column>
                    <Heading level={3} type="h100">Meeting Room Check In</Heading>
                    <Column spacing="small">
                        {renderExternalLink("Meeting Check-in", "https://w.amazon.com/bin/view/Meetex/SkyLantern/CheckIn/")}
                    </Column>
                    <Heading level={3} type="h100">Conference room management</Heading>
                    <Column spacing="small">
                        {renderExternalLink("Create new conference rooms", "https://it.amazon.com/articles/infrastructure/meeting-spaces/create-resource-mailbox")}
                        {renderExternalLink("Room corrections", "https://it.amazon.com/articles/infrastructure/meeting-spaces/modify-existing-resource-mailbox")}
                    </Column>
                    <Heading level={3} type="h100">Feedback</Heading>
                    <Column spacing="small">
                        {renderExternalLink("Feature requests", "https://w.amazon.com/bin/view/Meetex/AM2/ContactUs/#H3FeatureRequests")}
                        {renderExternalLink("Bug reports", "https://w.amazon.com/bin/view/Meetex/AM2/ContactUs/#H1AmazonMeetingsWebsite")}
                    </Column>
                    <ControlledExpander title="Meeting status legend" type="list">
                        <StatusLegend
                            orientation="column"
                            statuses={["busy", "tentative", "free", "outOfOffice", "canceled"]}
                            showStripes={false}
                        />
                    </ControlledExpander>
                </Column>
                <div style={{
                    paddingTop: "8px",
                }}>
                    <Divider size="large" />
                </div>
                <Column alignmentHorizontal="center" spacing="small" spacingInset="none" width="100%">
                    {!submitted &&
                        <label htmlFor={COMPONENT_IDS.FEEDBACK}>
                            <Text type="h100">
                                {tellUsWhy ?
                                    "(Optional) Please tell us why..."
                                    :
                                    "Rate your experience"
                                }
                            </Text>
                        </label>
                    }
                    <MeetingsFeedback
                        id={COMPONENT_IDS.FEEDBACK}
                        userRating={userRating}
                        tellUsWhy={tellUsWhy}
                        userFeedback={userFeedback}
                        submitted={submitted}
                        onUpdateUserRating={onUpdateUserRating}
                        setTellUsWhy={setTellUsWhy}
                        onUpdateUserFeedback={onUpdateUserFeedback}
                        setSubmitted={setSubmitted}
                        onClearFeedback={onClearFeedback}
                        closeButtonRef={menuCloseButtonRef}
                    />
                </Column>
            </Column>
        </Sheet>
    );
};

export default MeetingsHelpMenu;