module.exports = {
    SCREEN_SIZE: {
        QUICK_MEETING_FULL_VIEW_LOCATION: 840,
        QUICK_MEETING_FULL_VIEW: 721,
        QUICK_MEETING_MOBILE_VIEW: 380,
        QUICK_MEETING_SMALL_MOBILE_VIEW: 200,
    },
    MODAL_SIZE: {
        WIDTH: "942px",
        HEIGHT: "768px",
        MEETING_FORM_HEIGHT: "674px",
        FAVORITES_HEIGHT: "690px",
        MOBILE_FAVORITES_HEIGHT: "450px",
        SUGGESTIONS_HEIGHT: "660px",
        REVIEW_HEIGHT: "690px",
    },
    MAX_ATTENDEE_TAGS: 8,
    OTHER_MEETING_TYPES: "other-meeting-types",
    FAVORITES: "favorites",
    MAX_SUGGESTIONS: 5,
    MODAL_STATE: {
        OPEN: 0,
        CLOSE: 1
    },
    DURATION: {
        ONE_MINUTE_IN_SECONDS: 60,
        FIFTEEN_MINUTES_IN_SECONDS: 15 * 60,
        TWO_WEEKS_IN_SECONDS: 2 * 7 * 24 * 60 * 60,
        THIRTY_MINUTES: 30,
        ONE_HOUR_IN_MINUTES: 60,
        TWENTY_FOUR_HOURS_IN_MINUTES: 24 * 60,
        MAX_DURATION_IN_MINUTES: 60 * 24 * 60, // 60 days
    },
    MODAL_TITLE: "Simple meeting",
    LOCATION: {
        NO_SUGGESTIONS_FOUND: "No suggestions found. Try adjusting your search criteria for better suggestions.",
        VIRTUAL_ONLY: "Virtual Only"
    },
    COLOR: {
        WHITE: "#FFFFFF",
        TEAL: "#1B454E"
    },
    MEETING_BODY_HEIGHT: "150px",
    CANCEL_AND_REVIEW_BUTTON_HEIGHT: "42px",
};