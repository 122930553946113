import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getMeetingList,
	getIdentity,
	getSigningCredentials,
	getMeetingResourcesDictionary,
	getRoomEligibilityDictionary,
	getRoomCheckInStatusDictionary,
	getMeetingAgendaLoaded,
	getTimezonesList,
    getSettingsTimeFormat,
    getSettingsPrimaryTimezone,
} from "../../../sagas/selector";
import { refreshAgenda, getMeetingAgenda } from "../../shared/actions";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";

import MeetingAgenda from "../../shared/components/meeting-agenda";
import MeetingAgendaHeader from "../components/meeting-agenda-header";
import MeetingAgendaFooter from "../components/meeting-agenda-footer";
import LoadingMeetings from "../components/loading-meeting";

const UpcomingMeetingsDetails = (props) => {
    const signingCredentials = useSelector(getSigningCredentials);
    const { email, username } = useSelector(getIdentity);
    const meetingAgendaLoaded = useSelector(getMeetingAgendaLoaded);
    const meetingList = useSelector(getMeetingList);
    const meetingResourcesDictionary = useSelector(getMeetingResourcesDictionary);
    const roomEligibilityDictionary = useSelector(getRoomEligibilityDictionary);
    const roomCheckInStatusDictionary = useSelector(getRoomCheckInStatusDictionary);
    const timezones = useSelector(getTimezonesList);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const timeFormat = useSelector(getSettingsTimeFormat);

    const [acceptedOnly, setAcceptedOnly] = useState(true);
    const [viewAllMeetings, setViewAllMeetings] = useState(false);
    const [showRefreshAlert, setShowRefreshAlert] = useState(false);
    // store the element info to refocus to after closing a check in toast
    const [checkInRefocusInfo, setCheckInRefocusInfo] = useState({});

    let hasAcceptedMeetings = meetingList ?
        meetingList.some((meeting) => ['accept', 'organizer'].includes(meeting.response))
        : false;

    const dispatch = useDispatch();

    // Set agenda meeting list to undefined to trigger a refresh
    const onRefreshMeetings = () => {
        dispatch(refreshAgenda());
        setShowRefreshAlert(false);
    };

    useEffect(() => {
        if (signingCredentials && email && meetingList === undefined) {
            console.log(`Inside upcoming meetings container. Dispatching getMeetingAgenda for: ${email}`);
            dispatch(getMeetingAgenda(email, Date.now(), (new Date()).setHours(23, 55, 0), -1));
        }

        if (meetingList) {
            let hasAcceptedMeetings = meetingList.some((meeting) => ['accept', 'organizer'].includes(meeting.response));
            setAcceptedOnly(hasAcceptedMeetings);
        }
    }, [email, dispatch, signingCredentials, meetingList, setAcceptedOnly]);

    return (
        !meetingAgendaLoaded ?
            <LoadingMeetings
                loadingMessage="Loading your upcoming meetings..."
                loaderSize="large"
                withBoundary={true}
            />
            :
            <Row alignmentHorizontal="center" alignmentVertical="center">
                <Column width="100%" maxWidth="1200px" spacing={"xsmall"}>
                    <MeetingAgendaHeader
                        acceptedOnly={acceptedOnly}
                        viewAllMeetings={viewAllMeetings}
                        hasAcceptedMeetings={hasAcceptedMeetings}
                        showRefreshAlert={showRefreshAlert}
                        setShowRefreshAlert={setShowRefreshAlert}
                        onRefreshMeetings={onRefreshMeetings}
                        isMobileView={props.isMobileView}
                        isWrapOptions={props.isWrapOptions}
                        onSetAcceptedOnlyFilter={setAcceptedOnly}
                        onSetViewAllMeetingsFilter={setViewAllMeetings}
                        isEmptyMeetingList={!meetingList?.length}
                        checkInRefocusInfo={checkInRefocusInfo}
                        setCheckInRefocusInfo={setCheckInRefocusInfo}
                    />
                    <MeetingAgenda
                        meetingAgendaLoaded={meetingAgendaLoaded}
                        meetingList={meetingList || []}
                        meetingResourcesDictionary={meetingResourcesDictionary}
                        roomCheckInStatusDictionary={roomCheckInStatusDictionary}
                        roomEligibilityDictionary={roomEligibilityDictionary}
                        emptyDueToFilters={false}
                        acceptedOnly={acceptedOnly}
                        viewAllMeetings={viewAllMeetings}
                        isMobileView={props.isMobileView}
                        userEmail={email}
                        page="homepage"
                        timezones={timezones}
                        primaryTimezone={primaryTimezone}
                        timeFormat={timeFormat}
                        username={username}
                        checkInRefocusInfo={checkInRefocusInfo}
                        setCheckInRefocusInfo={setCheckInRefocusInfo}
                    />
                    {meetingList?.length > 6 ?
                        <MeetingAgendaFooter
                            viewAllMeetings={viewAllMeetings}
                            hasAcceptedMeetings={hasAcceptedMeetings}
                            onSetViewAllMeetingsFilter={setViewAllMeetings}
                            onSetAcceptedOnlyFilter={setAcceptedOnly}
                        />
                        : ""
                    }
                </Column>
            </Row>
    );
};

export default UpcomingMeetingsDetails;