import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setCalendarDate } from "../../calendar/actions";

import Sheet from "@amzn/meridian/sheet";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";

import closeTokens from "@amzn/meridian-tokens/base/icon/close";

import Calendar from "../../calendar/components/calendar";

import {
    RESPOND_PAGE_SIZE,
    SCREEN_SIZE,
    CALENDAR_RESPONSE_FILTER,
    CALENDAR_STATUS_FILTER,
    VIEW_TYPE
} from "../poll-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";

const PollRespondAgenda = (props) => {
    const calendarStatusFilter = [CALENDAR_STATUS_FILTER.BUSY, CALENDAR_STATUS_FILTER.TENTATIVE, CALENDAR_STATUS_FILTER.OUT_OF_OFFICE];
    const calendarResponseFilter = [CALENDAR_RESPONSE_FILTER.ACCEPT, CALENDAR_RESPONSE_FILTER.TENTATIVE, CALENDAR_RESPONSE_FILTER.NOT_RESPONDED];
    const timeFormat = props.timeFormat;
    const timezone = props.timezone;
    const userEmail = props.userEmail;
    const meetingListLoaded = props.meetingListLoaded;
    const calendarDate = props.calendarDate;
    const setTriggerRefresh = props.setTriggerRefresh;
    const screenSizeBreakpoint = props.screenSizeBreakpoint

    // Agenda
    const [agendaOpen, setAgendaOpen] = [props.agendaOpen, props.setAgendaOpen];
    const onCloseAgenda = useCallback(() => setAgendaOpen(false), [setAgendaOpen]);
    const onClickClose = useCallback(() => setAgendaOpen(false), [setAgendaOpen]);

    // const availabilityList = props.availabilityList;

    const dispatch = useDispatch();

    const onSetCalendarDate = (date) => {
        dispatch(setCalendarDate(date));
    };

    // Adjusting Meridian styling to make agenda adjust to screen width properly
    useEffect(() => {
        if (agendaOpen) {
            let respondSheetChild = document.getElementById("respondSheetChild");

            if (respondSheetChild) {
                let respondSheet = respondSheetChild.parentElement;
                let respondSheetWrapper = respondSheet.parentElement;

                if (respondSheet && respondSheetWrapper) {
                    respondSheet.style.width = "100%";
                    respondSheetWrapper.style.width = "100%";
                }
            }
        }
    }, [agendaOpen, screenSizeBreakpoint]);

    return (
        <Sheet type={screenSizeBreakpoint >= SCREEN_SIZE.RESPOND_PAGE_PARTIAL_MOBILE_VIEW ? "push" : "overlay"} side="left" open={agendaOpen} onClose={onCloseAgenda}>
            <div id="respondSheetChild">
                <Column
                    spacing="none"
                    maxWidth={RESPOND_PAGE_SIZE.AGENDA_PAGE_MAX_WIDTH}
                >
                    <Row alignmentHorizontal="right">
                        <Button onClick={onClickClose} type="icon">
                            <Icon tokens={closeTokens}>Close dialog</Icon>
                        </Button>
                    </Row>
                    <Calendar
                        meetingListLoaded={meetingListLoaded}
                        meetings={props.meetings}
                        date={calendarDate}
                        calendarStatusFilter={calendarStatusFilter}
                        calendarResponseFilter={calendarResponseFilter}
                        onSetCalendarDate={onSetCalendarDate}
                        viewType={VIEW_TYPE.AGENDA}
                        userEmail={userEmail}
                        screenSizeBreakpoint={screenSizeBreakpoint}
                        timeFormat={timeFormat}
                        primaryTimezone={timezone}
                        timezone={timezone}
                        setTriggerRefresh={setTriggerRefresh}
                        workflow={WORKFLOWS.RESPOND_POLL.NAME}
                    />
                </Column>
            </div>
        </Sheet>
    );
};

export default PollRespondAgenda;