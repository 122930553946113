import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import DOMPurify from "dompurify";

import {
    getPeopleSuggestions as getPeopleSuggestionsSelector,
    getGroupSuggestions as getGroupSuggestionsSelector,
    getSuggestions as getMeetingSuggestionsSelector,
    getIdentity,
    getFavorites,
    getAttendees,
    getTimezonesList,
    getSettingsPrimaryTimezone,
    getSettingsWorkingHours,
    getSettingsTimeFormat,
    getSettingsDateFormat,
    getExternalAttendees,
    isGettingSchedules as isGettingSchedulesSelector,
    getSelectedPollDetails,
    getChimeUser,
    getSettingsChimeEmail,
    getPollDetailsLoaded,
    isProcessingPoll as isProcessingPollSelector,
    getMeetingList,
    getMeetingPollTimeSlotsSelectedFromGridMode,
    getMeetingPollTimeSlotsSelectedFromCalendarMode,
    getMeetingPollFromPage,
} from "../../../sagas/selector";
import {
    updateAttendee,
    clearDraft as clearDraftPeople,
    clearGroupSuggestions,
    clearPeopleSuggestions,
    getGroupSuggestions,
    getPeopleSuggestions,
    getRASDataForGroup,
    getRASDataForPerson,
    removeAttendee,
    addExternalAttendee,
    updateExternalAttendee,
    removeExternalAttendee,
    addAttendeeByEmail,
    addAttendeeAndFetchWorkingHours,
} from "../../people/actions";
import { searchSuggestion, selectSuggestion, setSuggestionViewType } from "../../meeting-scheduler/actions";
import { addFavorite, removeFavorite, removeFavoriteByAlias } from "../../shared/favorites/actions";
import { showToast } from "../../shared/toasts/actions";
import { deleteMeeting, getSuggestions } from "../../shared/actions";
import {
    clearPollDetails,
    createPoll,
    getPoll,
    setMeetingPollTimeSlotsSelectedFromCalendarMode,
    setMeetingPollTimeSlotsSelectedFromGridMode,
    updatePoll
} from "../actions";
import { createChimePin, saveChimePin } from "../../shared/chime/actions";
import { SET_MEETINGS_LIST } from "../../shared/actions/action-types";
import { switchWorkflow } from "../../shared/workflow/actions";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Responsive from "@amzn/meridian/responsive";

import HeaderBackground from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";

import MeetingsToaster from "../../shared/toasts/containers/toast-container";
import PermalinkButton from "../../shared/permalink/components/permalink-button";
import CreatePollForm from "../components/create-poll-form";
import CreatePollNavigationBar from "../components/create-poll-navigation-bar";
import DeletePollModal from "../components/delete-poll-modal";
import LoadingMeetings from "../../landing/components/loading-meeting";
import PollResultsPanel from "./poll-results-container";

import "../meeting-polls.css";
import { getUrlParameters } from "../../shared/permalink/permalink-utils";
import { getChimePinFromChimeAttendee, isOneTimeChimePinAttendee } from "../../shared/meeting-utils";
import { SCREEN_SIZE } from "../../shared/shared-constants";
import { chimeEmailToAttendee, rasUserToAttendee } from "../../people/people-utils";
import { getErrorToast } from "../../shared/toasts/toast-utils";
import { ATTENDEE_PRIORITY, ATTENDEE_RESPONSE, ATTENDEE_TYPE } from "../../people/people-constants";
import { NAVIGATION_TYPE, STEP_NAVIGATION_FOOTER } from "../../navigation/navigation-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { DURATION, FIND_GROUP, SUGGESTION_VIEW_TYPE, FLOW_TYPE } from "../../meeting-scheduler/meeting-scheduler-constants";
import { EDIT_POLL_ID, MAPS_WIKI_LINK, STATUS } from "../poll-constants";

const CreatePollContainer = (props) => {
    const dispatch = useDispatch();
    const flowType = props.flowType;
    const mastheadSize = props.mastheadSize;
    // hide the list mode and comment below for future use
    // const suggestionViewType = useSelector(getSuggestionViewType);
    const suggestionViewType = SUGGESTION_VIEW_TYPE.GRID;
    const identity = useSelector(getIdentity);
    const peopleSuggestions = useSelector(getPeopleSuggestionsSelector);
    const groupSuggestions = useSelector(getGroupSuggestionsSelector);
    const favorites = useSelector(getFavorites);
    const attendees = useSelector(getAttendees).filter((attendee) => attendee.type !== ATTENDEE_TYPE.CHIME);
    const externalAttendees = useSelector(getExternalAttendees);
    const timezones = useSelector(getTimezonesList);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const workHours = useSelector(getSettingsWorkingHours);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const dateFormat = useSelector(getSettingsDateFormat);
    const meetingSuggestions = useSelector(getMeetingSuggestionsSelector);
    const isGettingSchedules = useSelector(isGettingSchedulesSelector);
    const isProcessingPoll = useSelector(isProcessingPollSelector);
    const fromPage = useSelector(getMeetingPollFromPage);
    const timeSlotsSelectedFromGridMode = useSelector(getMeetingPollTimeSlotsSelectedFromGridMode);
    const timeSlotsSelectedFromCalendarMode = useSelector(getMeetingPollTimeSlotsSelectedFromCalendarMode);

    // queryParameters for generation of PermaLink
    // eslint-disable-next-line no-unused-vars
    const [queryParameters, setQueryParameters] = useState({});
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [expiration, setExpiration] = useState();
    const [timezone, setTimezone] = useState(undefined);
    const [duration, setDuration] = useState(DURATION.SIXTY_MINUTES);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isInitialPollLoad, setIsInitialPollLoad] = useState(true);
    // Previous getSchedule query to avoid sending duplicate requests
    const [prevGetScheduleQuery, setPrevGetScheduleQuery] = useState();

    const [timeSlotsSelected, setTimeSlotsSelected] = useState([]);

    // Error Message state
    const [showSubjectErrorMessage, setShowSubjectErrorMessage] = useState(false);
    const [showTimeSlotsErrorMessage, setShowTimeSlotsErrorMessage] = useState(false);
    const [showAddDetailsErrorMessage, setShowAddDetailsErrorMessage] = useState(false);

    const onGetRASDataForPerson = (alias, addNewAttendee, name) => dispatch(getRASDataForPerson(alias, addNewAttendee, name, true));
    const onGetRASDataForGroup = (group) => dispatch(getRASDataForGroup(group, true));
    const onGetPeopleSuggestions = (query) => dispatch(getPeopleSuggestions(query));
    const onClearPeopleSuggestions = () => dispatch(clearPeopleSuggestions());
    const onGetGroupSuggestions = (query) => dispatch(getGroupSuggestions(query, FIND_GROUP.START, FIND_GROUP.SIZE));
    const onClearGroupSuggestions = () => dispatch(clearGroupSuggestions());
    const onAddFavorite = (favorite, alias) => dispatch(addFavorite(favorite, alias));
    const onRemoveFavorite = (favorite, alias) => dispatch(removeFavorite(favorite, alias));
    const onRemoveFavoriteByAlias = (favoriteAlias, alias) => dispatch(removeFavoriteByAlias(favoriteAlias, alias));
    const onAddAttendee = (userAlias, attendee) => dispatch(addAttendeeAndFetchWorkingHours(userAlias, attendee));
    const onAddExternalAttendee = (attendee) => dispatch(addExternalAttendee(attendee));
    const onUpdateAttendee = (attendee) => dispatch(updateAttendee(attendee));
    const onUpdateExternalAttendee = (attendee) => dispatch(updateExternalAttendee(attendee));
    const onRemoveAttendee = (attendee) => dispatch(removeAttendee(attendee));
    const onRemoveExternalAttendee = (attendee) => dispatch(removeExternalAttendee(attendee));
    const onRemoveAttendeeByAlias = (alias) => {
        let attendee = attendees.find((attendee) => attendee.alias === alias);
        if (attendee) {
            onRemoveAttendee(attendee);
        }
    };

    const onGetMeetingSuggestions = (suggestionRequest) => dispatch(getSuggestions(suggestionRequest));
    const onSearchSuggestion = (query, isAllDayEvent) => dispatch(searchSuggestion(query, isAllDayEvent));
    const onSelectSuggestion = (suggestion, resources) => dispatch(selectSuggestion(suggestion, resources));
    const onSetSuggestionViewType = (suggestionViewType) => dispatch(setSuggestionViewType(suggestionViewType));

    const onSetTimeSlotsSelectedFromGridMode = (fromGridMode) => dispatch(setMeetingPollTimeSlotsSelectedFromGridMode(fromGridMode));
    const onSetTimeSlotsSelectedFromCalendarMode = (fromCalendarMode) => dispatch(setMeetingPollTimeSlotsSelectedFromCalendarMode(fromCalendarMode));
    const onShowToast = (toast) => dispatch(showToast(toast));

    useLayoutEffect(() => {
        dispatch(clearPollDetails());
        dispatch(clearDraftPeople());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Filter out the organizer from the list of attendees
    // The design for createPoll API right now is that organizer will not be in the list of attendees
    // Up for discussion should there be a benefit to include it into the list
    const pollAttendees = attendees.filter((attendee) => !attendee.isOrganizer);

    const errorValidation = (request) => {
        let hasError = false;

        // Check Subject
        if (request.subject?.length > 0) {
            setShowSubjectErrorMessage(false);
        } else {
            hasError = true;
            setShowSubjectErrorMessage(true);
        }

        // Making attendees as optional for now, keeping this code commented out in case we want to make it required later on
        // if (attendees?.length > 0) {
        //     setShowInviteAttendeesErrorMessage(false);
        // } else {
        //     hasError = true;
        //     setShowInviteAttendeesErrorMessage(true);
        // }

        // Check Time Slots
        if (request.timezone?.length > 0 && request.duration !== undefined &&
            request.duration > 0 && request.availability?.length > 0) {
            setShowTimeSlotsErrorMessage(false);
        } else {
            hasError = true;
            setShowTimeSlotsErrorMessage(true);
        }

        // Check Add Details
        if (request.expiration !== undefined && request.expiration > 0) {
            setShowAddDetailsErrorMessage(false);
        } else {
            hasError = true;
            setShowAddDetailsErrorMessage(true);
        }

        return hasError;
    };

    const onSendButtonClick = () => {
        const createPollRequest = {
            attendees: [...pollAttendees.map((attendee) => ({
                displayName: attendee.name,
                email: attendee.email,
                identity: attendee.alias,
                priority: attendee.priority,
                external: false
            })), ...externalAttendees.map((attendee) => ({
                displayName: attendee.name,
                email: attendee.email,
                identity: attendee.identifier,
                priority: attendee.priority,
                external: true
            }))],
            timezone: timezone,
            subject: subject,
            body: DOMPurify.sanitize(body).replaceAll("<p>", "<div>").replaceAll("</p>","</div>"),
            identity: identity?.username,
            organizer: identity?.email,
            expiration: expiration,
            requestedOnBehalfOf: identity?.username,
            duration: duration,
            availability: timeSlotsSelected,
            calendarHolds: [],
        };

        const hasError = errorValidation(createPollRequest);

        // All the fields have been filled in
        if (!hasError) {
            dispatch(createPoll(createPollRequest, fromPage, timeSlotsSelectedFromGridMode, timeSlotsSelectedFromCalendarMode));
        }
	};

    const [chimePin, setChimePin] = useState();
    const chimeUser = useSelector(getChimeUser);
    const chimeEmail = useSelector(getSettingsChimeEmail);

    const loadAttendeeFromExistingPoll = (attendee, priority, chimeAttendees) => {
        if (attendee.email && attendee.email.endsWith("@chime.aws")) {
            if (isOneTimeChimePinAttendee(attendee.email)) {
                // Only add chime to meeting if updating a meeting,
                // so we can generate a new one time pin for new meetings
                if (flowType === FLOW_TYPE.UPDATE) {
                    const pin = getChimePinFromChimeAttendee(attendee.email);
                    setChimePin(pin);
                    dispatch(saveChimePin({AllocatedPins: [{Pin: pin}]}));
                    onAddAttendee(identity.username, chimeEmailToAttendee(`pin+${pin}@chime.aws`));
                }
            } else {
                //setAutoCall(enableAutoCallParticipants);

                // Only add chime to meeting if updating a meeting,
                // so we can generate a new one time pin for new meetings
                if (flowType === FLOW_TYPE.UPDATE) {
                    if (chimeAttendees.length === 1) {
                        setChimePin(chimeUser.PersonalPIN);
                    }
                    onAddAttendee(identity.username, chimeEmailToAttendee(attendee.email));
                }
            }
            // Do not add organizer again
        } else if (attendee.email !== identity.email) {
            attendee.external ? dispatch(addAttendeeByEmail(attendee, priority, true)) : dispatch(addAttendeeByEmail(attendee, priority));
        }
    };

    const pollDetails = useSelector(getSelectedPollDetails);

    const pollDetailsLoaded = useSelector(getPollDetailsLoaded);
    const queryParams = getUrlParameters(WORKFLOWS.EDIT_POLL.HREF);
    const [deletePollModalOpen, setDeletePollModalOpen] = useState(false);

    const meetingList = useSelector(getMeetingList) || [];
    const meetingListAction = SET_MEETINGS_LIST;

    const onDeleteMeeting = (deleteMeetingRequest, redirectTo, setMeetingListAction) => dispatch(deleteMeeting(deleteMeetingRequest, redirectTo, setMeetingListAction));
    const onUpdatePoll = (updatePollRequest, createMeetingRequests, deleteMeetingRequests, setMeetingListAction, previousPollStatus) => dispatch(updatePoll(updatePollRequest, createMeetingRequests, deleteMeetingRequests, setMeetingListAction, previousPollStatus));

    const requiredAttendees = attendees.filter((attendee) => {
        return attendee.priority === ATTENDEE_PRIORITY.REQUIRED || attendee.priority === ATTENDEE_PRIORITY.ESSENTIAL
    });

    const optionalAttendees = attendees.filter((attendee) => {
        return attendee.priority === ATTENDEE_PRIORITY.OPTIONAL
    });

    // Get the action object used to change the meeting list state within the sagas
    const getMeetingListAction = (changedMeetingList) => {
        let setMeetingListAction;

        if (meetingListAction && changedMeetingList) {
            setMeetingListAction = {
                type: meetingListAction,
                meetingList: changedMeetingList,
            };
        }

        return setMeetingListAction;
    };

    const onUpdateButtonClick = () => {
        let updatePollRequest = {
            identity: pollDetails.identity,
            pollID: pollDetails.pollID,
            requestedOnBehalfOf: pollDetails.requestedOnBehalfOf,
            organizer: pollDetails.organizer,
            changes: {
                attendees: [...pollAttendees.map((attendee) => ({
                    displayName: attendee.name,
                    email: attendee.email,
                    identity: attendee.alias,
                    priority: attendee.priority,
                    external: false,
                    response: !!attendee.status
                })), ...externalAttendees.map((attendee) => ({
                    displayName: attendee.name,
                    email: attendee.email,
                    identity: attendee.identifier,
                    priority: attendee.priority,
                    external: true,
                    response: !!attendee.status
                }))],
                timezone: timezone,
                subject: subject,
                body: DOMPurify.sanitize(body).replaceAll("<p>", "<div>").replaceAll("</p>","</div>"),
                expiration: expiration,
                duration: duration,
                availability: timeSlotsSelected,
                calendarHolds: pollDetails?.calendarHolds,
                // TODO: Figure out how to handle the edge case where users update a poll and expiration is less than current time
                status: STATUS.ONGOING // Update the status back to Ongoing after update
            }

            // TODO: Handle delegation for deletePoll in the future
            // delegate: "test",
            // ccDelegate: false
        };

        // Compare the availability from the request body and the existing availability, there are two possibilities:
        // 1. Delete an existing calendar hold
        // 2. Create a new calendar hold
        // Note: Keep the maximum of 10 calendar holds when making these changes

        const existingCalendarHolds = pollDetails?.calendarHolds;
        const deleteMeetingRequests = [];

        let setMeetingListAction;

        // 1. Delete existing calendar holds case
        for (const calendarHold of existingCalendarHolds) {

            const existingTimeSlot = timeSlotsSelected.filter((timeSlot) =>
                calendarHold.startTime === timeSlot.startTime && calendarHold.endTime === timeSlot.endTime
            );

            // If existing timeslot is part of the existing Availabilities then no action required
            if (existingTimeSlot.length > 0) {
                continue;
            }

            // If the existing availability is no longer part of the timeslots selected, then delete
            let deleteMeetingRequest = {
                requestedOnBehalfOf: identity.email, // RAS is using email for this attribute instead of alias
                uniqueOrEntryID: calendarHold.entryID,
                master: false, // Set to false for occurrence
                sendCancellations: false,
                body: {
                    value: pollDetails.body,
                    type: "html"
                }
            };

            if (meetingList) {
                // get new meeting list with deleted meeting omitted
                let changedMeetingList = meetingList.filter((meeting) => meeting.entryID !== calendarHold.entryID);
                setMeetingListAction = getMeetingListAction(changedMeetingList);
            }
            deleteMeetingRequests.push(deleteMeetingRequest);
        }

        const createMeetingRequests = [];

        // 2. Create new calendar holds case
        for (const timeSlot of timeSlotsSelected) {
            const existingTimeSlot = existingCalendarHolds.filter((calendarHold) =>
                calendarHold.startTime === timeSlot.startTime && calendarHold.endTime === timeSlot.endTime
            );

            // If existing timeslot is part of the existing Availabilities then no action required
            if (existingTimeSlot.length > 0) {
                continue;
            }

            const meeting = {
                subject: `Automatic calendar hold for meeting poll: [${subject}]`,
                organizer: pollDetails.organizer,
                body: {
                    value: DOMPurify.sanitize(body).replaceAll("<p>", "<div>").replaceAll("</p>","</div>"),
                    type: "html"
                },
                requiredAttendees: requiredAttendees.map((attendee) => ({
                    email: attendee.email
                })),
                optionalAttendees: optionalAttendees.map((attendee) => ({
                    email: attendee.email
                })),
                endTimezone: {id: timezone},
                startTimezone: {id: timezone},
                time: {
                    startTime: timeSlot.startTime,
                    endTime: timeSlot.endTime
                },
                status: "tentative",
                // Reminder set to false, reminderMinutesBeforeStart parameter required to be passed in so I include it with default value
                reminder: {isReminderSet: false, reminderMinutesBeforeStart: 1}
            };
            createMeetingRequests.push(meeting);
        }

        const hasError = errorValidation(updatePollRequest.changes);

        // All the fields have been filled in
        if (!hasError) {
            onUpdatePoll(updatePollRequest, createMeetingRequests, deleteMeetingRequests, setMeetingListAction, pollDetails?.status);
        }
    };

    const userAlias = identity.username;

    const [openResults, setOpenResults] = useState(false);
    const [selectedPollId, setSelectedPollId] = useState("");

    const onGetPoll = (pollID) => dispatch(getPoll(userAlias, pollID));

    const onOpenResults = (pollID) => {
        setOpenResults(true);
        setSelectedPollId(pollID);

        // Call the getPoll API when they open the results panel
        // This API call will take some time, the set variables above will finish first
        onGetPoll(pollID);
    };

    const onCloseResults = () => {
        setOpenResults(false);
    };

    const onChangeSubject = (value) => {
        setSubject(value);
        if (value?.length > 0) {
            setShowSubjectErrorMessage(false);
        }
    };

    // Load primaryBrowserTimezoneId for timezone selector and meeting request only for initial timezoneValue when creating a meeting
    useEffect(() => {
        if (!timezone && flowType !== FLOW_TYPE.UPDATE) {
            setTimezone(primaryTimezone);
        }
    }, [flowType, timezone, primaryTimezone]);

    useEffect(() => {
        if (flowType === FLOW_TYPE.UPDATE) {
            document.title = "Edit meeting poll - Amazon Meetings";
        } else {
            document.title = "Create meeting poll - Amazon Meetings";
        }
    }, [flowType]);

    // If pollDetails is not defined, then call the getPoll API call based on the URL query parameters
    // This is to handle if user directly paste the edit poll url
    useEffect(() => {
        // Regular expression to check if pollID is a valid UUID
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        // If the meeting pollID is invalid, then redirect to dashboard page
        if (flowType === FLOW_TYPE.UPDATE && !regexExp.test(queryParams.pollID)) {
            const toast = getErrorToast("Meeting poll ID is invalid");
            onShowToast(toast);
            dispatch(push("/polls"));
            dispatch(switchWorkflow(WORKFLOWS.POLL_DASHBOARD));
            return;
        } else if (identity && identity.username && pollDetails === undefined && flowType === FLOW_TYPE.UPDATE && isInitialLoad) {
            dispatch(getPoll(identity.username, queryParams.pollID));
            setIsInitialLoad(false);
        }
        // eslint-disable-next-line
    },[identity, queryParams, dispatch, flowType, pollDetails, isInitialLoad]);

    // On edit meeting poll workflow, update the state with the existing selected poll details
    useEffect(() => {
        if (identity && identity.username && flowType === FLOW_TYPE.UPDATE && pollDetailsLoaded && isInitialPollLoad) {
            if (pollDetails?.pollID === undefined) {
                const toast = getErrorToast(`Meeting poll no longer exist for pollID [${queryParams.pollID}]`);
                onShowToast(toast);
                dispatch(push("/polls"));
                dispatch(switchWorkflow(WORKFLOWS.POLL_DASHBOARD));
                return;
            }

            let chimeAttendees = pollDetails?.attendees.filter((attendee) => attendee.email.endsWith("@chime.aws"));
            if (chimeAttendees.length === 0) {
                setChimePin("none");
            }
            // Call createChimePin to have a generated ChimePin ready if one isn't present in the meeting invite
            if (chimeAttendees.length === 0 || (chimeAttendees.length === 1 && chimeAttendees[0].email === "meet@chime.aws")) {
                dispatch(createChimePin({entities: [{email: chimeEmail ?? identity.email}]}));
            }

            let pollAttendees = pollDetails?.attendees;

            // eslint-disable-next-line
            pollAttendees?.forEach((attendee) => {
                loadAttendeeFromExistingPoll(attendee, attendee.priority, chimeAttendees)
            });

            setTimeSlotsSelected(pollDetails.availability);
            setBody(pollDetails.body);
            setSubject(pollDetails.subject);
            setExpiration(pollDetails.expiration);
            setTimezone(pollDetails.timezone);
            setDuration(pollDetails.duration);
            setIsInitialPollLoad(false);
        }
        // eslint-disable-next-line
    }, [identity, chimeEmail, flowType, chimeUser, pollDetails, dispatch, pollDetailsLoaded, isInitialPollLoad]);

    // Load in organizer
    useEffect(() => {
        if (identity && identity.username) {
            let organizer = rasUserToAttendee(identity, ATTENDEE_RESPONSE.ORGANIZER, ATTENDEE_PRIORITY.REQUIRED);
            onAddAttendee(identity.username, organizer);
        }
        // eslint-disable-next-line
    }, [identity, dispatch]);

    const header = useRef();

    // Begin new workflow content with focus on the <h1> heading
    useEffect(() => {
        if (header.current) {
            header.current.focus();
        }
    }, []);

    return (
        <Responsive query="min-width" props={{
            screenSizeBreakpoint: {...SCREEN_SIZE.RESPONSIVE_BREAKPOINTS}
        }}>
            {(responsiveProps) => (
                <React.Fragment>
                    <Column height="100%" spacing="none" width="100%">
                        <div style={{"paddingBottom": STEP_NAVIGATION_FOOTER.HEIGHT}}>
                            <Column
                                height="100%"
                                minHeight={`calc(100vh - ${mastheadSize} - ${STEP_NAVIGATION_FOOTER.HEIGHT})`} // 100vh - mastheadSize - footer
                                spacing="none"
                            >
                                <div style={{
                                    "backgroundImage": `url(${HeaderBackground})`,
                                    "backgroundRepeat": "no-repeat",
                                    "backgroundColor": "rgba(26, 69, 78)",
                                    "backgroundPosition": "200px -90px",
                                    "height": "50px",
                                    "width": "100%",
                                }}>
                                    <Row height="100%" alignmentHorizontal="justify" alignmentVertical="center" spacingInset="medium">
                                        <Heading color="inverted" level={1} type="h300" ref={header} tabIndex="-1">
                                            {flowType === FLOW_TYPE.UPDATE ? "Edit meeting poll" : "Create meeting poll"}
                                        </Heading>
                                        <Row spacing="small">
                                            <a
                                                href={MAPS_WIKI_LINK}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link-MAPS"
                                            >
                                                How does a meeting poll work?
                                            </a>
                                            <PermalinkButton
                                                path={WORKFLOWS.POLL_CREATE.HREF}
                                            />
                                        </Row>
                                    </Row>
                                </div>
                                <CreatePollNavigationBar
                                    screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                                    onSendButtonClick={onSendButtonClick}
                                    navigationType={NAVIGATION_TYPE.HEADER}
                                    flowtype={flowType}
                                    onUpdateButtonClick={onUpdateButtonClick}
                                    setDeletePollModalOpen={setDeletePollModalOpen}
                                    onOpenResults={onOpenResults}
                                    pollID={pollDetails?.pollID}
                                    isProcessingPoll={isProcessingPoll}
                                />
                                <Row alignmentHorizontal="center" alignmentVertical="top" spacingInset="small" wrap="down">
                                    <Column
                                        width="100%"
                                        maxWidth= {openResults ? "1200px" :"2400px"}
                                        spacing="xsmall"
                                    >
                                        <CreatePollForm
                                            screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                                            identity={identity}
                                            subject={subject}
                                            setSubject={onChangeSubject}
                                            body={body}
                                            setBody={setBody}
                                            attendees={attendees}
                                            externalAttendees={externalAttendees}
                                            timezones={timezones}
                                            timezone={timezone}
                                            workHours={workHours}
                                            timeFormat={timeFormat}
                                            dateFormat={dateFormat}
                                            duration={duration}
                                            setDuration={setDuration}
                                            setTimezone={setTimezone}
                                            onAddAttendee={onAddAttendee}
                                            onAddExternalAttendee={onAddExternalAttendee}
                                            onUpdateAttendee={onUpdateAttendee}
                                            onUpdateExternalAttendee={onUpdateExternalAttendee}
                                            onRemoveAttendee={onRemoveAttendee}
                                            onRemoveExternalAttendee={onRemoveExternalAttendee}
                                            onRemoveAttendeeByAlias={onRemoveAttendeeByAlias}
                                            expiration={expiration}
                                            setExpiration={setExpiration}
                                            peopleSuggestions={peopleSuggestions}
                                            groupSuggestions={groupSuggestions}
                                            favorites={favorites}
                                            onGetPeopleSuggestions={onGetPeopleSuggestions}
                                            onGetGroupSuggestions={onGetGroupSuggestions}
                                            onClearGroupSuggestions={onClearGroupSuggestions}
                                            onClearPeopleSuggestions={onClearPeopleSuggestions}
                                            onGetRASDataForPerson={onGetRASDataForPerson}
                                            onGetRASDataForGroup={onGetRASDataForGroup}
                                            onAddFavorite={onAddFavorite}
                                            onRemoveFavorite={onRemoveFavorite}
                                            onRemoveFavoriteByAlias={onRemoveFavoriteByAlias}
                                            meetingSuggestions={meetingSuggestions}
                                            suggestionViewType={suggestionViewType}
                                            onGetMeetingSuggestions={onGetMeetingSuggestions}
                                            onSearchSuggestion={onSearchSuggestion}
                                            onSelectSuggestion={onSelectSuggestion}
                                            onSetSuggestionViewType={onSetSuggestionViewType}
                                            onShowToast={onShowToast}
                                            prevGetScheduleQuery={prevGetScheduleQuery}
                                            setPrevGetScheduleQuery={setPrevGetScheduleQuery}
                                            timeSlotsSelected={timeSlotsSelected}
                                            setTimeSlotsSelected={setTimeSlotsSelected}
                                            showSubjectErrorMessage={showSubjectErrorMessage}
                                            showTimeSlotsErrorMessage={showTimeSlotsErrorMessage}
                                            showAddDetailsErrorMessage={showAddDetailsErrorMessage}
                                            isGettingSchedules={isGettingSchedules}
                                            flowType={flowType}
                                            chimePin={chimePin}
                                            onSetTimeSlotsSelectedFromGridMode={onSetTimeSlotsSelectedFromGridMode}
                                            onSetTimeSlotsSelectedFromCalendarMode={onSetTimeSlotsSelectedFromCalendarMode}
                                        />
                                        <CreatePollNavigationBar
                                            screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                                            onSendButtonClick={onSendButtonClick}
                                            navigationType={NAVIGATION_TYPE.FOOTER}
                                            flowtype={flowType}
                                            onUpdateButtonClick={onUpdateButtonClick}
                                            setDeletePollModalOpen={setDeletePollModalOpen}
                                            onOpenResults={onOpenResults}
                                            pollID={pollDetails?.pollID}
                                            isProcessingPoll={isProcessingPoll}
                                        />
                                    </Column>
                                    {openResults &&
                                        <Column height="100%">
                                            {pollDetailsLoaded ?
                                                <PollResultsPanel
                                                    pollID={selectedPollId}
                                                    open={onOpenResults}
                                                    onClose={onCloseResults}
                                                    onGetPoll={onGetPoll}
                                                    pollDetails={pollDetails}
                                                />
                                                :
                                                <LoadingMeetings
                                                    loadingMessage="Loading poll details..."
                                                    loaderSize="medium"
                                                />
                                            }
                                        </Column>
                                    }
                                    <DeletePollModal
                                        identity={identity}
                                        pollDetails={pollDetails}
                                        open={deletePollModalOpen}
                                        setOpen={setDeletePollModalOpen}
                                        backgroundElementsId={EDIT_POLL_ID}
                                        newWorkflow={WORKFLOWS.POLL_DASHBOARD}
                                        getMeetingListAction={getMeetingListAction}
                                        onDeleteMeeting={onDeleteMeeting}
                                        meetingList={meetingList}
                                    />
                                </Row>
                            </Column>
                        </div>
                    </Column>
                    <MeetingsToaster />
                </React.Fragment>
            )}
        </Responsive>
    );
};

export default CreatePollContainer;