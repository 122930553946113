import * as Actions from "../actions/action-types";

const initialState = {
    identity: {
        alias: undefined,
        email: undefined,
        site: {
            building: undefined,
            floor: undefined
        }
    },
    identityTokens: {
        idToken: "",
        accessToken: "",
        refreshToken: "",
        idTokenExpiration: undefined
    },
    signingCredentials: undefined,
    stageConfig: undefined,
};

function identityReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.UPDATE_IDENTITY_CREDENTIALS:
            return {
                ...state,
                identityTokens: {
                    idToken: action.idToken,
                    accessToken: action.accessToken,
                    refreshToken: action.refreshToken,
                    idTokenExpiration: action.idTokenExpiration,
                }
            };
        case Actions.UPDATE_SIGNING_CREDENTIALS:
            return {
                ...state,
                signingCredentials: {
                    accessKeyId: action.AccessKeyId,
                    secretAccessKey: action.SecretKey,
                    sessionToken: action.SessionToken,
                    expiration: action.Expiration
                }
            };
        case Actions.UPDATE_STAGE_CONFIG:
            return {
                ...state,
                stageConfig: {
                    stage: action.stage,
                    region: action.region,
                }
            };
        case Actions.SET_CURRENT_USER:
            return {
                ...state,
                identity: {
                    ...state.identity,
                    ...action.user
                }
            };
        default:
            return state;
    }

}

export default identityReducer;
