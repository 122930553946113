import PropTypes from "prop-types"

// Create a custom validator for ref objects - { current: <Element> } - that
// won't explode when run in Node (where Element doesn't exist).
const refObject = PropTypes.shape({
  current:
    typeof Element === "undefined"
      ? PropTypes.object
      : PropTypes.instanceOf(Element),
})

export default refObject
