import spacingTokens from "@amzn/meridian-tokens/base/spacing"
import { getToken } from "../_utils/token"

// Parses the value for a spacing prop. This turns a string of spacing names
// like "medium" or "small medium" into an array of spacing values like ["16px"]
// or ["8px", "16px"].
const parseSpacing = spacing =>
  spacing
    ? spacing
        .split(" ")
        .map(spacing => getToken(spacingTokens, "spacing", spacing) || 0)
    : []

// Parses the value for a alignment prop. Given a valid alignment prop value
// this will return an array of two values representing x and y alignment
// respectively.
const parseAlignment = alignment => {
  const values = alignment.split(" ")
  return {
    x: values[1],
    y: values[0],
  }
}

export { parseSpacing, parseAlignment }
