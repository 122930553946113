import React from "react";
import {useSelector} from "react-redux";

import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Image from "@amzn/meridian/_image";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import AlertsContainer from "../../shared/containers/alerts-container";
import MeetingDetails from "../../shared/containers/meeting-details";

import QuickMeetingIcon from "../../../assets/meeting-quick/quick-meeting.svg";

import {MODAL_SIZE, MODAL_TITLE} from "../meeting-quick-constants";
import {getCurrentMeeting} from "../../../sagas/selector";

const MeetingQuickReview = (props) => {

    const meeting = useSelector(getCurrentMeeting);
    const alerts = props.reviewAlerts;
    const timeFormat = props.timeFormat;
    
    return (
        <Column spacingInset="medium none medium medium">
            <Row alignmentVertical="top" widths={["20px", "fill"]} spacing="small" spacingInset="none">
                <Image viewportHeight="20px" viewportAspectRatio={1} src={QuickMeetingIcon} size="20px" />
                <Column spacing="none">
                    <Heading level={2} type="h200">
                        {MODAL_TITLE}
                    </Heading>
                    <Text type="b200" alignment="left">
                        Schedule time with a few attendees and an optional room
                    </Text>
                </Column>
            </Row>
            <Column overflowY="auto" height={MODAL_SIZE.REVIEW_HEIGHT} spacingInset="none none medium none">
                {alerts.length !== 0 &&
                    <AlertsContainer alerts={alerts} />
                }
                {(meeting && meeting.time && meeting.time.startTime) &&
                    <MeetingDetails
                        selector={getCurrentMeeting}
                        renderAsModal={false}
                        timeFormat={timeFormat}
                    />
                }
            </Column>
        </Column>

    )
};

export default MeetingQuickReview;