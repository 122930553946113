import React from "react";
import DatePicker from "../../shared/meridian-custom-components/src/components/date-picker";
import Text from "@amzn/meridian/text";
import TimeSelect from "../../shared/meridian-custom-components/src/components/time-select";
import timeRange from "@amzn/meridian/time-select/time-range";
import InputGroup from "../../shared/meridian-custom-components/src/components/input-group";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";
import { getDefaultTimeFormat, getLocalesFromSettingsDateFormat } from "../../shared/settings/settings-utils";

const StartTimePicker = (props) => {
    const timeFormat = props.timeFormat;
    const dateFormat = props.dateFormat;
    const START_TIME_PICKER_LABEL_ID = "start-time-picker-label-id";
    const START_TIME_PICKER_INSTRUCTION_ID = "start-time-picker-instruction-id";

    return ([
        <div style={{display: "none"}}>
            <Text id={START_TIME_PICKER_LABEL_ID}>Start Time</Text>
            <Text id={START_TIME_PICKER_INSTRUCTION_ID}>
                Type start date in MM/DD/YYYY format or press down to enter calendar dialog to select the start date
            </Text>
        </div>,
        <InputGroup width={"fit"} size={"small"}>
            <DatePicker
                size="small"
                value={props.startTime}
                onChange={(startTime) => {props.onStartTimeSelect(startTime)}}
                placeholder="Select start date"
                locale={getLocalesFromSettingsDateFormat(dateFormat)}
                aria-describedby={START_TIME_PICKER_INSTRUCTION_ID}
                aria-label="Select start date"
            />
            <TimeSelect
                value={props.startTimeStamp}
                onChange={(startTimeStamp) => {props.onStartTimeStampSelect(startTimeStamp)}}
                size="large"
                width="125px"
                popoverMaxHeight={305}
                options={timeRange({
                    start: props.timeRangeStart || "00:00:00",
                    end: props.timeRangeEnd || "23:59:59",
                    step: 60 * (props.step || 15), // 15 minutes
                    })}
                format={getDefaultTimeFormat(timeFormat === TIME_FORMAT.TWELVE_HOUR)}
                placeholder={"Select start time"}
                aria-labelledby={START_TIME_PICKER_LABEL_ID}
            />
        </InputGroup>
    ]);
};

export default StartTimePicker;