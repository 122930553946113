import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { zonedTimeToUtc } from "date-fns-tz";

import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import Modal, { ModalFooter } from "../../shared/meridian-custom-components/src/components/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Thumbnail from "@amzn/meridian/thumbnail";
import Tooltip from "@amzn/meridian/tooltip";
import Toggle from "@amzn/meridian/toggle";
import Loader from "@amzn/meridian/loader";
import Alert from "@amzn/meridian/alert";

import ChimePINSelect from "../components/chime-PIN-select";

import restrictedSvg from "../../../assets/icons/locations/restricted.svg";
import chimeLogo from "../../../assets/people/chime-logo.svg";
import calendarTokens from "@amzn/meridian-tokens/base/icon/calendar";
import clockTokens from "@amzn/meridian-tokens/base/icon/clock";
import geopinTokens from "@amzn/meridian-tokens/base/icon/geopin";
import userTokens from "@amzn/meridian-tokens/base/icon/user";
import infoKnockoutTokens from "@amzn/meridian-tokens/base/icon/info-knockout";

import ReservationTimeSelector from "../components/reservation-time-selector";

import { STATUS_COLOR } from "../../shared/meeting-status-constants";
import { RESERVE_ROOM_CREATE_MEETING, TILE_COLOR } from "../room-finder-constants";
import { TIME_CONSTANT } from "../../shared/shared-constants";
import { generateComponentId, onEnterOrSpaceDown, renderExternalLink } from "../../shared/shared-utils";
import { createTimeOptions, getFormattedDateTime } from "../../shared/time-utils";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { getBoxBackgroundColor } from "../utils";
import { getChimePinFromMeetingBody } from "../../shared/chime/html-template-utilities";
import { getMeetingDetailsAndSave } from "../../shared/actions";
import {
    postRoomBookingMetric
} from "../../shared/metrics/actions";

const RoomFinderModal = (props) => {
    const dispatch = useDispatch();
    const meetingDetails = useSelector(props.selector);
    const pinFromBody = getChimePinFromMeetingBody(meetingDetails.body);
    const timezones = props.timezones;
    const timeFormat = props.timeFormat;
    const timezoneValue = props.timezoneValue;
    const enableAutoCall = props.enableAutoCall;
    const durationSelected = props.durationSelected;
    const timezoneName = timezoneIdToDisplayName(timezones, timezoneValue);
    const isCreatingMeeting = props.isCreatingMeeting;
    const [isLastStepLoading, setIsLastStepLoading] = [props.isLastStepLoading, props.setIsLastStepLoading]; // disable Reserve button

    const tileStartTime = zonedTimeToUtc(props.selectedEvent.time.startTime * 1000, timezoneValue);
    const startTime = zonedTimeToUtc(`${props.startTime} ${props.startTimeStamp}`, timezoneValue) > tileStartTime ?
        zonedTimeToUtc( `${props.startTime} ${props.startTimeStamp}`, timezoneValue) : tileStartTime;
    const endTime = zonedTimeToUtc(props.selectedEvent.time.endTime * 1000, timezoneValue);
    const now = zonedTimeToUtc((new Date()).getTime(), timezoneValue);
    let reservationStart = startTime > now ? startTime : now;
    reservationStart.setSeconds(0);

    const timeOptions = createTimeOptions(tileStartTime, props.startTime, props.startTimeStamp, endTime, timezoneValue);
    let defaultTimeOption = timeOptions.indexOf(`${getFormattedDateTime(startTime, timezoneValue, "HH:mm")}:00`);
    if (defaultTimeOption === -1) {
        defaultTimeOption = 0;
    }

    let selectedStartTime = props.selectedStartTime || timeOptions[defaultTimeOption];
    let startTimeOptions = timeOptions.slice(0, -1);
    let selectedEndTime;
    if (props.selectedEndTime) {
        selectedEndTime = props.selectedEndTime;
    } else {
        if (zonedTimeToUtc(`${props.startTime} ${timeOptions[defaultTimeOption + 1]}`, timezoneValue) - zonedTimeToUtc(`${props.startTime} ${timeOptions[defaultTimeOption]}`, timezoneValue) < TIME_CONSTANT.TEN_MINUTES_IN_MS) {
            selectedEndTime = timeOptions.length - defaultTimeOption - 1 >= durationSelected / 15 + 1 ? timeOptions[defaultTimeOption + durationSelected / 15 + 1] : timeOptions[timeOptions.length - 1]; 
        } else {
            selectedEndTime = timeOptions.length - defaultTimeOption - 1 >= durationSelected / 15 ? timeOptions[defaultTimeOption + durationSelected / 15] : timeOptions[timeOptions.length - 1]; 
        }
    }

    let endTimeOptions = timeOptions.slice(1);

    const onSetSelectedStartTime = (newStartTime) => {
        props.onSetSelectedStartTime(newStartTime);
    };

    const onSetSelectedEndTime = (newEndTime) => {
        props.onSetSelectedEndTime(newEndTime);
    };

    const onClickReserveButton = () => {
        setIsLastStepLoading(true); // Set it Reserve button to loading while the API is processing
        let chimePinForReserve = chimePin;
        if (chimePin && chimePin[0] === "i") {
            chimePinForReserve = pinFromBody;
            dispatch(postRoomBookingMetric("chime PIN Input", props.identity.username));
        }
        props.onClickReserveButton(reservationStart, selectedStartTime, selectedEndTime, props.selectedEvent.name, props.selectedEvent.email, chimePinForReserve, autoCall);
    };

    const name = props.identity && props.identity.name;
    const alias = props.identity && props.identity.username;
    const isRoomRestricted = props.selectedRoom && props.selectedRoom.includes("Restricted");
    const [acceptedBookingRestricted, setAcceptedBookingRestricted] = useState(false);
    const [autoCall, setAutoCall] = useState(enableAutoCall);
    const [chimePin, setChimePin] = useState();
    const [disableAutoCall, setDisableAutoCall] = useState(false);

    // set the generated chime pin as the default selection
    useEffect(() => {
        setChimePin(props.usePersonalChimePin ? 
            props.personalChimeBridge : props.generatedChimeBridge);
    }, [props.generatedChimeBridge, props.personalChimeBridge, props.usePersonalChimePin, props.selectedStartTime, props.selectedEndTime]);

    useEffect(() => {
        setAutoCall(enableAutoCall);
    }, [enableAutoCall]);

    useEffect(() => {
        if (chimePin && chimePin[0] === "i") {
            dispatch(getMeetingDetailsAndSave(props.email, chimePin, false, "ooto", "", props.saveAction));
        }
    }, [dispatch, props.email, props.saveAction, chimePin]);

    // When the createMeeting API is processing, continue to disable the "Reserve" button when the user closes the room-booking modal and re-opens
    // Once the API response is an error, enable the Reserve button back
    useEffect(() => {
        if (isCreatingMeeting && isLastStepLoading) {
            props.setOpen(true);
        }
        if (!isCreatingMeeting && isLastStepLoading) {
            setIsLastStepLoading(false);
            props.setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoCall, isCreatingMeeting, isLastStepLoading]);
    
    const onHandleChimePin = (newChimePin) => {
        setAutoCall(enableAutoCall ? newChimePin !== "none" && newChimePin[0] !== "i" : false);
        setDisableAutoCall(newChimePin === "none" || newChimePin[0] === "i");
        setChimePin(newChimePin);
    };

    return (props.selectedEvent.subject?.includes("Available") ?
        <Modal
            open={props.open}
            onClose={props.onClose}
            closeLabel={"Close"}
            describedById={"modal-description"}
            width={515}
        >
            <div style={{
                boxSizing: "content-box",
                position: "absolute",
                top: "0px",
                left: "0px",
                backgroundColor: isRoomRestricted ? TILE_COLOR.RESTRICTED : STATUS_COLOR.BUSY,
                border: "inherit",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                height: "10px",
                width: "100%",
                zIndex: "1",
            }} />
            <Column spacing={"medium"} spacingInset={"small none"}>
                <Heading level={1} type="h400" >
                    <Row alignmentVertical={"center"}>
                        Reserve a{isRoomRestricted ? " restricted" : ""} room
                    </Row>
                </Heading>
                <Divider />
                <Row spacing="small">
                    <Icon tokens={calendarTokens} />
                    <Column spacing="small">
                        <Text type="h100">{getFormattedDateTime(reservationStart.getTime(), timezoneValue, 'EEEE, LLLL d, yyyy')}</Text>
                    </Column>
                </Row>
                <Column spacing="small">
                    <Row spacing="small">
                        <Icon tokens={clockTokens} />
                        <ReservationTimeSelector
                            selectedStartTime={selectedStartTime}
                            setSelectedStartTime={onSetSelectedStartTime}
                            selectedEndTime={selectedEndTime}
                            setSelectedEndTime={onSetSelectedEndTime}
                            startTimeOptions={startTimeOptions}
                            endTimeOptions={endTimeOptions}
                            timezoneName={timezoneName}
                            timeOptions={timeOptions}
                            timeFormat={timeFormat}
                        />
                    </Row>
                    <Row spacingInset="none none none large">
                        <Text type="b100">
                            <Icon tokens={infoKnockoutTokens} /> &nbsp;
                            This room is currently available from {getFormattedDateTime(tileStartTime.getTime(), timezoneValue, 'h:mm a')} to {getFormattedDateTime(endTime.getTime(), timezoneValue, 'h:mm a')}
                        </Text>
                    </Row>
                </Column>
                <Divider />
                <Row spacing="small" alignmentVertical="center" widths={["grid-1", "grid-10", "fill", "grid-1"]}>
                    <Icon tokens={geopinTokens} />
                    <Text>{props.selectedRoom}</Text>
                    <span />
                    {isRoomRestricted &&
                        <Tooltip position="top" title="Restricted">
                            <img src={restrictedSvg} height={22} width={22} alt="Restricted" />
                        </Tooltip>
                    }
                </Row>
                <Divider />
                <Row spacing="small">
                    <Icon tokens={userTokens} />
                    <Tooltip position="top" title={name + " - " + alias}>
                        <Link
                            href={"https://phonetool.amazon.com/users/" + alias}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Thumbnail key={alias} source={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?login=${alias}`} size="small" label={`Phone tool link for ${alias}`} />
                        </Link>
                    </Tooltip>
                </Row>
                <Divider />
                <Row alignmentHorizontal="justify" wrap="down">
                    <Row widths={["fill", "fit"]}>
                        <Column spacing="none">
                            <img src={chimeLogo} height={22} width={22} alt="Chime" />
                        </Column>
                        <Column>
                            {props.generatedChimeBridge === undefined ?
                                <Loader size="small" />
                                :
                                <ChimePINSelect
                                    chimePin={chimePin}
                                    onHandleChimePin={onHandleChimePin}
                                    generatedChimeBridge={props.generatedChimeBridge}
                                    personalChimeBridge={props.personalChimeBridge}
                                    selectedStartTime={selectedStartTime}
                                    selectedEndTime={selectedEndTime}
                                    date={props.date}
                                    meetingList={props.meetingList}
                                    timezone={props.timezone}
                                    email={props.email}
                                    saveAction={props.saveAction}
                                    selector={props.selector}
                                    timezones={props.timezones}
                                    timeFormat={timeFormat}
                                />
                            }
                        </Column>
                    </Row>
                    <Column>
                        <Toggle id={generateComponentId("toggle", "auto-call", "reserve-room")} disabled={disableAutoCall === true} checked={autoCall} onChange={setAutoCall}>Auto-call</Toggle>
                    </Column>
                </Row>
                {isRoomRestricted &&
                    <Column spacing="medium">
                        <Divider />
                        <Checkbox checked={acceptedBookingRestricted} onChange={setAcceptedBookingRestricted}>
                            I understand that this room is restricted and may decline my meeting if I do not have the necessary permissions to book it. For more details&nbsp;
                            {renderExternalLink("click here", "https://w.amazon.com/bin/view/Meetex/AmazonMeetings/help/restricted-rooms/")}
                        </Checkbox>
                    </Column>
                }
            </Column>
            <ModalFooter>
                <Column spacing={"xsmall"}>
                    <Row alignmentHorizontal="right">
                        <div
                            role="button"
                            tabIndex="0"
                            aria-disabled={isRoomRestricted && !acceptedBookingRestricted}
                            onKeyDown={(event) => {!(isRoomRestricted && !acceptedBookingRestricted) && onEnterOrSpaceDown(event, onClickReserveButton)}}
                            onClick={() => {!(isRoomRestricted && !acceptedBookingRestricted) && onClickReserveButton()}}
                            id={generateComponentId('button', 'reserve', 'reserve-room')}
                        >
                            <Button
                                type="primary"
                                disabled={(isRoomRestricted && !acceptedBookingRestricted && chimePin?.length > 0) || isLastStepLoading}
                                tabIndex="-1"
                            >
                                Reserve
                            </Button>
                        </div>
                    </Row>
                    {isLastStepLoading && isCreatingMeeting &&
                        <Row spacingInset={"200 none none none"} alignmentHorizontal="right">
                            <Alert type={RESERVE_ROOM_CREATE_MEETING.type} size="small">
                                <Text id={RESERVE_ROOM_CREATE_MEETING.id}>
                                    {RESERVE_ROOM_CREATE_MEETING.value}
                                </Text>
                            </Alert>
                        </Row>
                    }
                </Column>
            </ModalFooter>
        </Modal>
        :
        <Modal
            open={props.open}
            onClose={props.onClose}
            closeLabel={"Close"}
            describedById={"modal-description"}
            width={515}
        >
            <div style={{
                boxSizing: "content-box",
                position: "absolute",
                top: "0px",
                left: "0px",
                backgroundColor: getBoxBackgroundColor(props.selectedEvent.subject),
                border: "inherit",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                height: "10px",
                width: "100%",
            }} />
            <Column spacing={"medium"} spacingInset={"small none"}>
                {isRoomRestricted ?
                    <Heading level={1} type="h400">{props.selectedEvent.subject === "Was available" ? props.selectedEvent.subject : "Restricted access"}</Heading>
                    :
                    <Heading level={1} type="h400">{props.selectedEvent.subject === "Was available" ? props.selectedEvent.subject : "Reserved by " + props.selectedEvent.subject}</Heading>
                }
                <Divider />
                <Row spacing="small">
                    <Icon tokens={calendarTokens} />
                    <Column spacing="small">
                        <Text type="h100">{getFormattedDateTime(reservationStart.getTime(), timezoneValue, 'EEEE, LLLL d, yyyy')}</Text>
                    </Column>
                </Row>
                <Row spacing="small">
                    <Icon tokens={clockTokens} />
                    <Text>{getFormattedDateTime(tileStartTime.getTime(), timezoneValue, 'h:mm a')} to {getFormattedDateTime(endTime.getTime(), timezoneValue, 'h:mm a')} {timezoneName}</Text>
                </Row>
                <Divider />
                {props.selectedRoom &&
                    <Row spacing="small">
                        <Icon tokens={geopinTokens} />
                        <Text>{props.selectedRoom}</Text>
                    </Row>
                }
            </Column>
        </Modal>
    )
};

export default RoomFinderModal;