import React, { useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import keycode from "keycode"
import noop from "lodash/noop"
import buttonReset from "../../_styles/button-reset"
import linkReset from "../../_styles/link-reset"
import { memoizeByKeys } from "../../_utils/functional"
import cxMemoized from "../../_utils/cx-memoized"
import filterPropsByPrefix from "../../_utils/filter-props-by-prefix"

const styles = memoizeByKeys(
  ({ tag, disabled }) =>
    css(
      {
        button: {
          ...buttonReset,
          // HACK: IE offsets the contents of buttons on mouse down (in the active
          // state) to create a faux 3D effect. These styles are applied to every
          // child of  the button in order to counter this effect so that we are
          // the masters of our own button-content-position-destiny.
          "& > *": {
            position: "relative",
          },
        },
        a: linkReset,
      }[tag],
      {
        cursor: disabled ? "not-allowed" : "pointer",
        outline: "none",
      }
    ),
  ["tag", "disabled"]
)

const getPropsForNativeLink = props => ({
  href: props.disabled ? undefined : props.href,
  target: props.target,
  rel: props.rel,
  "aria-disabled": props.disabled || undefined,
  role: props.role !== "link" ? props.role : undefined,
  tabIndex: props.disabled ? "-1" : props.tabIndex,
  onClick: props.onClick,
})

const getPropsForNativeButton = props => ({
  disabled: props.disabled || undefined,
  type: props.submit ? "submit" : "button",
  role: props.role !== "button" ? props.role : undefined,
  tabIndex: props.tabIndex,
  onClick: props.onClick,
})

const getPropsForCustomClickable = props => ({
  href: props.disabled ? undefined : props.href,
  target: props.target,
  rel: props.rel,
  "aria-disabled": props.disabled || undefined,
  tabIndex: props.disabled ? "-1" : props.tabIndex || "0",
  role: props.role,
  onKeyDown: event => {
    const key = keycode(event)
    const focus = document.activeElement === event.currentTarget
    // Trigger a click on enter-key-down for buttons and links
    if (focus && key === "enter") {
      props.onClick(event)
    }
    // Disable scrolling on space-key-down for buttons
    if (focus && key === "space" && props.role === "button") {
      event.preventDefault()
    }
  },
  onKeyUp:
    props.role === "button"
      ? event => {
          const key = keycode(event)
          const focus = document.activeElement === event.currentTarget
          // Trigger a click on space-key-up for buttons
          if (focus && key === "space") {
            // NOTE: event.preventDefault() will have already been called at
            // this point to keep the space key from triggering a scroll
            // (see `onKeyDown` above), so instead of passing the original
            // event this will pass a mock event that looks like it hasn't
            // had preventDefault() called.
            props.onClick({ defaultPrevented: false, preventDefault: noop })
          }
        }
      : undefined,
  onClick: props.onClick,
})

/**
 * Simulate a link click by creating an anchor tag and clicking on it. This
 * allows features like the "rel" attribute to be used on Clickable that have
 * an href but aren't rendered as an "a" tag (e.g. a Card that might be rendered
 * as a div so it can contain action buttons/links).
 */
const simulateLinkClick = linkAttributes => {
  // Create a link element that we can click
  const link = document.createElement("a")
  // Assign attributes to the link (e.g. href). Filter out undefined attributes.
  // React normally does this for us, but the DOM doesn't so we have to do it
  // manually to avoid things like `download="undefined"` in the DOM.
  Object.assign(
    link,
    Object.keys(linkAttributes).reduce((result, key) => {
      if (linkAttributes[key] !== undefined) {
        result[key] = linkAttributes[key]
      }
      return result
    }, {})
  )
  // Add the link to the DOM (necessary for this to work in some browsers like
  // FF)
  document.body.appendChild(link)
  // Trigger a click on the link
  link.click()
  // Remove the link from the dom.
  document.body.removeChild(link)
}

/**
 * This component determines whether an `a` or `button` tag is most appropriate
 * given the props passed to it. It will render the element to the dom with its
 * styles completely reset. It also handles link disabling (not a native feature
 * of the web) and default event prevention for links with click handlers.
 *
 * The goal of this component is to provide a uniform API for clickable elements
 * while ensuring that we are always using the correct html element under the
 * hood. This allows us to can take advantage of default browser behavior and
 * maintain good accessibility.
 *
 * This component also allows nesting so that clickable parents can have
 * clickable children and the streams won't get crossed. For these use cases the
 * `tag` prop can be used to manually choose the html tag rendered by the
 * component, since clickable elements should not be nested inside native
 * buttons or links.
 */

const Clickable = React.forwardRef((props, ref) => {
  const {
    onClick,
    href,
    target,
    submit,
    disabled,
    rel,
    download,
    tabIndex,
    propagateClickEvent,
  } = props

  // Figure out the role of the clickable element and the tag to render to the
  // DOM
  const nativeRole = href && !submit ? "link" : "button"
  const role = props.role !== undefined ? props.role : nativeRole
  const tag = props.tag ? props.tag : nativeRole === "link" ? "a" : "button"
  const passThroughProps = filterPropsByPrefix(props, [
    "data-",
    "mdn-",
    "aria-",
  ])

  // What happens when the Clickable is clicked. Pretty key.
  const onClickTag = useCallback(
    event => {
      // If the default event behavior was already prevented (e.g. by a nested
      // Clickable inside this one) then don't do anything
      if (!event.defaultPrevented) {
        const nativeBehavior =
          (tag === "a" && href) || (tag === "button" && submit)
        // Call `preventDefault` for elements that are either disabled, have a
        // custom onClick handler, or do not have any native browser behavior.
        // This keeps any parent Clickable elements higher up in the DOM tree from
        // also handling the event. This doesn't stop propagation though - other
        // elements may want to react to the event even if its default behavior
        // was cancelled.
        if (disabled || onClick || !nativeBehavior) {
          event.preventDefault()
        }

        // If we were explicitly told to stop propagation on the event then
        // do so now
        if (!propagateClickEvent) {
          event.stopPropagation()
        }

        // If we're not disabled then handle the click
        if (!disabled) {
          // If the element has native behavior and we don't have a custom
          // onClick handler, then fall back to the native behavior (and stop
          // propagation to keep anyone else from handling the click).
          if (nativeBehavior && !onClick) {
            event.stopPropagation()
          }
          // If we've got an element with an href and the user has attempted to
          // open it in a new tab (via control-click, command-click, or
          // middle-mouse-button click) then do that
          else if (
            href &&
            (event.ctrlKey || event.metaKey || event.button === 1)
          ) {
            simulateLinkClick({
              href,
              target: "_blank",
              rel: "noopener noreferrer",
              download,
            })
          }
          // If we were given a custom onClick handle then call it and pass the
          // href (if we have one). This makes it easy to do html5-history-style
          // links like this:
          //   <Clickable href="/path" onClick={push}>
          //     my accessible html5 link
          //   </Clickable>
          else if (onClick) {
            onClick(href)
          }
          // For non-link elements with an href and no onClick handler we have
          // to manually navigate to the href
          else if (href) {
            simulateLinkClick({ href, target, rel, download })
          }
        }
      }
    },
    [
      onClick,
      href,
      target,
      submit,
      disabled,
      tag,
      rel,
      download,
      propagateClickEvent,
    ]
  )

  // Generate props that are specific to the tag we're rendering (e.g. a link,
  // a button, and a div will all require different props in order to acheive
  // a particular behavior). Memoize these to reduce the number of large objects
  // created on each render.
  const clickableProps = useMemo(() => {
    const props = {
      onClick: onClickTag,
      role,
      disabled,
      href,
      rel,
      target,
      tabIndex,
      submit,
    }
    return tag === "a" && href
      ? getPropsForNativeLink(props)
      : tag === "button"
      ? getPropsForNativeButton(props)
      : getPropsForCustomClickable(props)
  }, [tag, onClickTag, role, disabled, href, rel, target, tabIndex, submit])
  // HACK: Wrap string children in a span so that the IE positioning
  // hack (see the button styles above) can take effect
  const applyIE11StyleHack =
    tag === "button" &&
    React.Children.toArray(props.children).filter(
      child => typeof child !== "string"
    ).length === 0
  return React.createElement(
    tag,
    {
      ...passThroughProps,
      ...clickableProps,
      onBlur: props.onBlur,
      onFocus: props.onFocus,
      onMouseMove: props.onMouseMove,
      onMouseEnter: props.onMouseEnter,
      onMouseLeave: props.onMouseLeave,
      onMouseDown: props.onMouseDown,
      onMouseUp: props.onMouseUp,
      id: props.id,
      download: props.download,
      title: props.title,
      className: cxMemoized(
        styles({ tag, disabled: props.disabled }),
        props.className
      ),
      ref,
    },
    applyIE11StyleHack ? <span>{props.children}</span> : props.children
  )
})

Clickable.displayName = "Clickable"

Clickable.propTypes = {
  /**
   * All props prefixed with `aria-` are accepted. See the [W3C aria docs](https://w3c.github.io/using-aria/) for
   * relevant props.
   *
   * @since 4.x
   */
  "aria-*": PropTypes.string,
  /**
   * The contents of the component.
   */
  children: PropTypes.node,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  /**
   * All props prefaced with `data-` are accepted. This is useful for adding selectors
   * for integrating with other libraries such as Amplify Analytics or Cypress
   *
   * @since 4.x
   */
  "data-*": PropTypes.string,
  /**
   * This disables interaction with the component and applies special visual
   * styles to indicate that it's not interactive.
   */
  disabled: PropTypes.bool,
  /**
   * See docs for the [a tag's download attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download).
   */
  download: PropTypes.string,
  /**
   * If a URL is provided here, the component will behave like a link.
   *
   * Note that you must either set this prop or the `onClick` prop (or both).
   */
  href: PropTypes.string,
  /**
   * Sets an `id` attribute on the component's form element.
   *
   * Use this for testing or for accessibility. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   */
  id: PropTypes.string,
  /**
   * Determines whether or not the clickable propagates the click event.
   * Setting to false will run `event.stopPropagation()`.
   */
  propagateClickEvent: PropTypes.bool,
  /**
   * The relationship of the linked URL to the current page. Only applicable if
   * `href` is set.
   *
   * See docs for the [a tag's rel attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-rel) for details.
   */
  rel: PropTypes.string,
  /**
   * See docs for the [aria role attribute](https://www.w3.org/TR/wai-aria/#host_general_role).
   */
  role: PropTypes.string,
  /**
   * See docs for the "submit" value of the [button tag's type attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type).
   */
  submit: PropTypes.bool,
  /**
   * Manually specify the order of the component in sequential keyboard navigation.
   *
   * Use this with caution. See docs for the [HTML tabindex attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex)
   * for details on appropriate usage.
   */
  tabIndex: PropTypes.string,
  /**
   * The tag used to render the component in the DOM. This defaults to either
   * `a` or `button`, whichever is the more appropriate choice given the other
   * props provided (e.g. `href` or `onClick`).
   */
  tag: PropTypes.string,
  /**
   * Determine where the linked URL is displayed. This is only applicable if
   * `href` is provided but `onClick` is not.
   *
   * IMPORTANT: If you set this prop to `"_blank"`, make sure to set `rel` to
   * `"noopener noreferrer"` to avoid performance and security issues.
   *
   * See docs for the [a tag's target attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target) for details.
   */
  target: PropTypes.string,
  /**
   * Add a title to the element. Read [the MDN docs on title](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/title)
   * for details.
   */
  title: PropTypes.string,
  /**
   * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   */
  onBlur: PropTypes.func,
  /**
   * This will be triggered when the component is clicked. If the `href` prop is
   * set, that value will be passed as the first argument to this function.
   *
   * If this prop is used along with the `href` prop, the default link behavior
   * of the component will be disabled and the click handler will be responsible
   * for implementing the behavior of the component.
   *
   * These features make it easy to implement accessible client-side navigation.
   * If you have a client-side navigation function of the form `navigate(<url>)`, you
   * can integrate it into this component by setting a URL via `href` and then
   * passing `navigate` directly to this prop (i.e. `href={url} onClick={navigate}`).
   * See the [Routing and navigation guide](https://meridian.a2z.com/for-developers/guides/routing-and-navigation/?platform=reactjs)
   * for details.
   *
   * Note that you must set either this prop or the `href` prop (or both).
   */
  onClick: PropTypes.func,
  /**
   * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   */
  onFocus: PropTypes.func,
  /**
   * This is called when the mouse is pressed down on the component. The [mousedown event](https://developer.mozilla.org/en-US/docs/Web/API/Element/mousedown_event)
   * is passed as the first argument to the function.
   */
  onMouseDown: PropTypes.func,
  /**
   * This is called when the mouse cursor moves over the component. The [mouseenter event](https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseenter_event)
   * is passed as the first argument to the function.
   */
  onMouseEnter: PropTypes.func,
  /**
   * This is called when the mouse cursor moves off of the component. The [mouseleave event](https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseleave_event)
   * is passed as the first argument to the function.
   */
  onMouseLeave: PropTypes.func,
  /**
   * This is called when the mouse cursor moves within the component. The [mousemove event](https://developer.mozilla.org/en-US/docs/Web/API/Element/mousemove_event)
   * is passed as the first argument to the function.
   */
  onMouseMove: PropTypes.func,
  /**
   * This is called when a mouse press is released on the component. The [mouseup event](https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseup_event)
   * is passed as the first argument to the function.
   */
  onMouseUp: PropTypes.func,
}

Clickable.defaultProps = {
  propagateClickEvent: true,
}

export default Clickable
