import React from "react"
import PropTypes from "prop-types"
import { cx, css } from "emotion"
import waitAnimationFrame from "../../_utils/wait-animation-frame"

const mountStyled = ({ open, height, duration, easing }) =>
  css({
    transform: `translateY(${open ? "0" : "100%"})`,
    height,
    overflow: "auto",
    transition: `transform ${duration} ${easing}`,
  })

class AnimateSlideIn extends React.Component {
  static propTypes = {
    /**
     * A function that returns the elements to animate.
     */
    children: PropTypes.func.isRequired,
    /**
     * If true the children will be mounted, otherwise they will be unmounted.
     */
    open: PropTypes.bool.isRequired,
    /**
     * Allows the parent AnimateMount component to worry about unmounting the
     * component so we can focus on animation
     */
    transitionEnd: PropTypes.func.isRequired,
    /**
     * Allows the parent AnimateMount component to worry about mounting the
     * component so we can focus on animation
     */
    updateStateOnOpen: PropTypes.func.isRequired,
    /**
     * Allows for cases when the desired effect is animating height to
     * a value that's different than the element's scrollHeight
     */
    animateToHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Accepts the shared css class provided by the AnimateMount parent
     */
    className: PropTypes.string,
    /**
     * The duration of the animation. This should be a valid CSS animation
     * duration (e.g. `"100ms"` or `"1s"`).
     */
    duration: PropTypes.string,
    /**
     * The easing function of the animation. This should be a valid CSS easing
     * function (e.g. `"linear"` or `"ease"`).
     */
    easing: PropTypes.string,
  }

  static defaultProps = {
    duration: "150ms",
    easing: "ease",
  }

  state = {
    open: false,
  }

  isMounted = false

  componentDidMount() {
    this.isMounted = true
    if (this.props.open) {
      this.updateStateOnOpen()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.updateStateOnOpen()
    } else if (prevProps.open && !this.props.open) {
      this.updateStateOnUnmount()
    }
  }

  componentWillUnmount() {
    this.isMounted = false
  }

  updateStateOnOpen = () => {
    // Tell the wrapper component to remount the node when the open prop is true
    this.props.updateStateOnOpen()
    // Wait one animation frame so that the DOM can settle after mounting the
    // element, then set the state to trigger the mount animation
    waitAnimationFrame(this.updateStateOnMount)
  }

  updateStateOnUnmount = () => this.setState({ open: false })

  updateStateOnMount = () => {
    if (this.isMounted) this.setState({ open: true })
  }

  render() {
    const state = this.state
    const props = this.props
    const styles = mountStyled({
      open: state.open,
      duration: props.duration,
      easing: props.easing,
      height: props.animateToHeight,
    })
    return props.children({
      open: state.open,
      className: cx(props.className, styles),
      onTransitionEnd: this.props.transitionEnd,
    })
  }
}

export default AnimateSlideIn
