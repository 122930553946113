module.exports = {
    TOAST_ACTION_TYPE: {
        DISPLAY_COMPONENT: "DisplayComponent",
        NONE: "None"
    },
    TOAST_COMPONENT: {
        MEETING_CONFLICT: "MeetingConflict",
        MEETING_CREATED: "MeetingCreated",
        MEETING_DECLINED: "MeetingDeclined",
        MEETING_DELETED: "MeetingDeleted",
        MEETING_UPDATED: "MeetingUpdated",
        OOTO_CREATED: "OOTOCreated",
        LINK: "Link",
        FUNCTION: "Function",
        POLL_RESPONDED: "PollResponded",
        MEETING_CHECK_IN: "MeetingCheckIn",
        SYSTEM_ERROR: "SystemError",
        INTAKE_MEETING_CREATED: "IntakeMeetingCreated"
    },
    TOAST_TYPE: {
        SUCCESS: "success",
        ERROR: "error",
        WARNING: "warning",
        INFORMATIONAL: "informational",
    },
    TOAST_ID: {
        SESSION_EXPIRED: "1001"
    },
    MAX_TOASTS: 4,
    TIMEOUT: {
        QUICK: 5 * 1000, // 5 seconds
        DEFAULT: 10 * 1000, // 10 seconds
        LONG: 20 * 1000, // 20 seconds
        NEVER: undefined // toast will not timeout
    }
};
