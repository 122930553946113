import { useState, useCallback, useRef, useMemo, useEffect } from "react"
import noop from "lodash/noop"

/**
 * @callback focusEventCallback
 * @param {*} focusEvent
 * @returns *
 */

/**
 * Options for the useFocus hook
 *
 * @typedef focusOptions
 * @type {object}
 * @property {boolean} [disabled=false] - A bug in React means that onBlur is not called
 *   when an element with focus is disabled. This prop allows you to manually
 *   inform the Focus component when the element(s) that it manages are disabled
 *   so that it can turn off its internal focus state. See:
 *   https://github.com/facebook/react/issues/9142
 * @property {boolean} [multipleNodes=false] - Turn this on if you are tracking the focus
 *   of multiple DOM nodes (i.e. if you are applying the `focusProps` returned
 *   from this hook to multiple elements).
 * @property {focusEventCallback} [onBlur] - This is called when the component loses
 *   focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
 *   is passed as the first argument to the function.
 * @property {focusEventCallback} [onFocus] - This is called when the component gains
 *   focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
 *   is passed as the first argument to the function.
 */

/**
 * Focus state returned by the useFocus hook
 *
 * @typedef focusReturn
 * @type {object}
 * @property {boolean} focus - Are the tracked node(s) focused?
 * @property {('keyboard'|'mouse')} focusTrigger - The type of device that
 *   triggered the focus.
 * @property {Object} focusProps - An object containing onFocus and onBlur
 *   event handlers that can be bound to any node you want to track focus for.
 */

/**
 * This hook helps track the focus state of a DOM node or set of DOM nodes.
 *
 * When to use this component: if you need to make a distinction between mouse
 * focus and keyboard focus, or if you need to treat multiple focuseable dom
 * nodes as a single focuseable unit. If you don't need these features you
 * should probably just track focus using local state or style using the CSS
 * :focus selector.
 *
 * This component tracks focus state for its children, including the type of
 * device that triggered the focus (keyboard or pointer). This component
 * essentially takes the place of the CSS pseudo-selectors `:focus-within` and
 * `:focus-visible`, which are not well supported. It also allows you to
 * simulate something like `:focus-visible-within`, which doesn't exist in CSS
 * at all. See:
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-within
 *   - https://drafts.csswg.org/selectors-4/#the-focus-visible-pseudo
 *
 * ```
 * // The button wrapper will be outlined in blue if the button or the div is
 * // focused with the keyboard, and will not be outlined if the elements are
 * // focused with the mouse (or if they are not focused at all).
 * const MyFocuseableButton = () => {
 *   const { focus, focusTrigger, focusProps } = useFocus()
 *   return (
 *     <div style={{
 *       outline: focus && focusTrigger === "keyboard" ? "1px solid blue" : "none"
 *     }}>
 *       <button {...focusProps}>Click me!</button>
 *       <div tabIndex={0}>Also click me!</div>
 *     </div>
 *   )
 * }
 * ```
 *
 * NOTE: Components that use this hook will re-render when the nodes that are
 * are being tracked by the hook are focused or blurred. To avoid re-rendering
 * an unnecessary amount of components on focus & blur, call this hook at the
 * lowest level possible. In other words, don't call this hook in a high-level
 * component that holds a ton of children just to track the focus on a single
 * child. Instead wrap the child in its own component and call the hook there,
 * limiting focus-related renders to just that child.
 *
 * @param {focusOptions} options - Options that determine how focus is tracked.
 * @returns {focusReturn}
 */
const useFocus = ({
  disabled,
  multipleNodes,
  onBlur: onBlurCallback = noop,
  onFocus: onFocusCallback = noop,
} = {}) => {
  const [[focus, focusDescendent], setFocus] = useState([false, null])
  const focusedNode = useRef()
  const blurTimeout = useRef()

  const onFocus = useCallback(
    event => {
      clearTimeout(blurTimeout.current)
      focusedNode.current = event ? event.target : undefined
      const nextFocusDescendent =
        Boolean(event) && event.currentTarget !== focusedNode.current
      if (!focus || focusDescendent !== nextFocusDescendent) {
        setFocus([true, nextFocusDescendent])
      }
      if (!focus) {
        onFocusCallback(event, getInteractionDevice())
      }
    },
    [onFocusCallback, focus, focusDescendent]
  )

  const onBlurComplete = useCallback(
    event => {
      setFocus([false, false])
      onBlurCallback(event, getInteractionDevice())
    },
    [onBlurCallback]
  )

  const onBlur = useCallback(
    event => {
      if (event && multipleNodes) {
        clearTimeout(blurTimeout.current)
        // Don't trigger `onBlurComplete` right away if we're managing focus for
        // multiple elements. Instead, wait briefly using setTimeout to see if
        // onFocus is called for another element we're tracking. If it is then
        // we consider the state to still be focused, otherwise we can move ahead
        // and call `onBlurComplete`.
        event.persist()
        blurTimeout.current = setTimeout(() => {
          if (event.target === focusedNode.current) {
            onBlurComplete(event)
          }
        }, 0)
      } else {
        onBlurComplete(event)
      }
    },
    [onBlurComplete, multipleNodes]
  )

  const focusProps = useMemo(() => ({ onFocus, onBlur }), [onFocus, onBlur])

  useEffect(() => {
    // When the node(s) are focused and we flip from enabled to disabled, clear
    // the focus (see the docs for the disabled param re: why this is
    // necessary).
    if (focus && disabled) {
      setFocus([false, false])
    }
    // Clear the timeout when unmounting
    return () => clearTimeout(blurTimeout.current)
  }, [focus, disabled])

  return {
    focus,
    focusProps,
    focusDescendent,
    focusTrigger: getInteractionDevice(),
  }
}

/**
 * This tracks whether the user is using their mouse or keyboard to interact
 * with the application. This state is tracked outside of React because it
 * updates on every mousedown or keydown in the window. Tracking this inside of
 * React using component state would trigger a re-render of every component
 * with useFocus for every one of these events, which would result in a ton of
 * unnecessary re-rendering. The value of this state is only relevant when a new
 * element is focused anyway, so `onFocus` can fetch the new value when that
 * happens.
 */
const getInteractionDevice = (() => {
  let device = "keyboard"
  if (typeof window !== "undefined" && window.addEventListener) {
    window.addEventListener("mousedown", () => (device = "mouse"), true)
    window.addEventListener("keydown", () => (device = "keyboard"), true)
  }
  return () => device
})()

export default useFocus
