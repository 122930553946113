import React, { useContext } from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import inputTokens from "@amzn/meridian-tokens/component/input"
import context from "./context"
import { useTheme } from "../theme"
import getIconCss from "../../_utils/get-icon-css"
import { getIconFromTokens, memoizeTokenStyles } from "../../_utils/token"
import focusRingStyles from "../../_styles/focus-ring"
import textStyles from "../../_styles/text"
import noTextWrapStyles from "../../_styles/no-text-wrap"
import { getRtl } from "../../_utils/rtl"

/**
 * Styles
 */

/* Styles for the root element */
const styles = memoizeTokenStyles(
  (t, { cursor, size, focus, error, disabled, width }) => {
    const state = disabled
      ? "disabled"
      : focus
      ? "focus"
      : error
      ? "error"
      : "default"
    return css(
      textStyles(t, {
        fontSize: size,
        lineHeight: "default",
        color: disabled ? "disabled" : "default",
      }),
      {
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "row",
        justifyContent: "stretch",
        cursor: disabled ? "not-allowed" : cursor,
        borderRadius: t("borderRadius"),
        backgroundColor: t("backgroundColor", state),
        height: t("height", size),
        maxWidth: t("widthMax"),
        width,
        outline: "none",
        // If an explicit width is set then don't allow the input to flex
        flex: width ? "none !important" : undefined,
        borderWidth: t("borderWidth"),
        borderColor: t("borderColor", state),
        borderStyle: "solid",
      },
      !disabled
        ? focusRingStyles(t.tokens, {
            focus,
            error,
            offset: 1,
            dropShadow: true,
            borderWidth: t("focusRingBorderWidth"),
            borderRadius: t("borderRadius"),
          })
        : undefined
    )
  },
  ["cursor", "size", "focus", "error", "disabled", "width"]
)

/* Styles for each segment of the input (e.g. an endcap, an icon, the input
container) */
const columnStyles = memoizeTokenStyles(
  (t, { size, label }) => {
    const horizontalPadding = t("spacingInsetHorizontal")
    const verticalPadding = t("spacingInsetVertical", size)
    // If we have a label then reduce the top padding until the label is the same
    // number of pixels from the top as the original top padding when it's floated
    // down over the input
    const topPadding =
      label && verticalPadding > 0
        ? verticalPadding - t("labelFloatDistance", size)
        : verticalPadding
    return css({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: `${topPadding}px ${horizontalPadding}px ${verticalPadding}px ${horizontalPadding}px`,
    })
  },
  ["size", "label"]
)

const endcapBorderRadius = (radius, side) =>
  side === "left"
    ? `${radius}px 0 0 ${radius}px`
    : `0 ${radius}px ${radius}px 0`

const endcapBorderShadow = (t, { state, side }) =>
  side === "left"
    ? ` 1px 0 0 0 ${t("borderColor", state)}`
    : `-1px 0 0 0 ${t("borderColor", state)}`

/* Styles for the prefix/suffix caps on either end of the input */
const endcapStyles = memoizeTokenStyles(
  (t, { disabled, side, size, label }) => {
    const state = disabled ? "disabled" : "default"
    return css(
      columnStyles(t, { size, label }),
      {
        flexShrink: 0,
        backgroundColor: t("endcapBackgroundColor"),
        borderRadius: endcapBorderRadius(t("endcapBorderRadius"), side),
        boxShadow: endcapBorderShadow(t, { state, side: side }),
        [`[dir="rtl"] &`]: {
          borderRadius: endcapBorderRadius(
            t("endcapBorderRadius"),
            getRtl(side)
          ),
          boxShadow: endcapBorderShadow(t, { state, side: getRtl(side) }),
        },
      },
      noTextWrapStyles
    )
  },
  ["disabled", "side", "size", "label"]
)

/* Styles for everything in-between the endcaps */
// since the aislestyle and columnstyle shares the same attributes
// we can memoize them together
const aisleStyles = memoizeTokenStyles(
  (t, { size, label }) =>
    css(columnStyles(t, { size, label }), {
      flex: "auto",
      overflow: "hidden",
    }),
  ["size", "label"]
)

const labelStyles = memoizeTokenStyles(
  (t, { animated, focus, disabled, floated, size }) => {
    const colorState = disabled ? "disabled" : focus ? "focus" : "default"
    return css(
      {
        display: "block",
        cursor: "inherit",
        color: t("labelColor", colorState),
        fontSize: t("labelFontSizeDefault"),
        marginBottom: t("spacingVertical"),
        transformOrigin: "top left",
        [`[dir="rtl"] &`]: {
          transformOrigin: "top right",
        },
        transform: floated
          ? `translateY(0px) scale(${t("labelFontSizeFloated") /
              t("labelFontSizeDefault")})`
          : `translateY(${t("labelFloatDistance", size)}px) scale(1)`,
        transition: animated
          ? t.transitionCss("labelMotion", ["color", "transform"])
          : undefined,
      },
      noTextWrapStyles
    )
  },
  ["animated", "focus", "disabled", "floated", "size"]
)

const iconStyles = memoizeTokenStyles(
  (t, { tokens, disabled, position }) => {
    const state = disabled ? "disabled" : "default"
    const { width, data } = getIconFromTokens(tokens)
    return css({
      flexShrink: 0,
      width,
      // HACK: IE11 does not support margin-inline-* or margin-block-* so we have
      // to use directional margin-left/right. If/when we drop IE11 we can simplify
      // these styles to be:
      // {
      //   marginInlineStart:
      //    position === "prefix"
      //      ? t("spacingInsetHorizontal")
      //      : 0,
      //   marginInlineEnd:
      //    position == "prefix"
      //      ? 0
      //      : t("spacingInsetHorizontal"),
      // },
      margin:
        position === "prefix"
          ? `0 0 0 ${t("spacingInsetHorizontal")}px`
          : `0 ${t("spacingInsetHorizontal")}px 0 0`,
      [`[dir="rtl"] &`]: {
        margin:
          position === "prefix"
            ? `0 ${t("spacingInsetHorizontal")}px 0 0`
            : `0 0 0 ${t("spacingInsetHorizontal")}px`,
      },
      backgroundImage: getIconCss(data, t("foregroundColor", state)),
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    })
  },
  ["tokens", "disabled", "position"]
)

const childrenStyles = memoizeTokenStyles(
  (t, { size, disabled, empty, label, floated }) => {
    const state = disabled ? "disabled" : "default"
    return css({
      position: "relative",
      color: empty ? t("placeholderColor", state) : undefined,
      minHeight: t("lineHeight", size),
      lineHeight: t("lineHeight", size),
      opacity: label && !floated ? 0 : 1,
      pointerEvents: disabled ? "none" : undefined,
    })
  },
  ["size", "disabled", "empty", "label", "floated"]
)

/**
 * Component
 */

function InputBox(props) {
  const t = useTheme(inputTokens, "input")
  const { onFocus, onBlur } = useContext(context)
  const styleProps = {
    ...props,
    size: props.size,
    floated:
      !props.label ||
      (props.labelFloated !== undefined
        ? props.labelFloated
        : !props.empty || props.focus),
  }
  return (
    <div
      className={styles(t, styleProps)}
      onMouseDown={props.onMouseDown}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex="-1"
    >
      {props.prefix ? (
        <div className={endcapStyles(t, { ...styleProps, side: "left" })}>
          {props.prefix}
        </div>
      ) : null}
      {props.prefixIconTokens ? (
        <div
          role="img"
          aria-hidden="true"
          className={iconStyles(t, {
            ...styleProps,
            tokens: props.prefixIconTokens,
            position: "prefix",
          })}
        />
      ) : null}
      <div className={aisleStyles(t, styleProps)}>
        {props.label && (props.size === "xlarge" || props.size === "auto") ? (
          <label className={labelStyles(t, styleProps)} htmlFor={props.htmlFor}>
            {props.label}
          </label>
        ) : null}
        <div className={childrenStyles(t, styleProps)}>{props.children}</div>
      </div>
      {props.suffixIconTokens ? (
        <div
          role="img"
          aria-hidden="true"
          className={iconStyles(t, {
            ...styleProps,
            tokens: props.suffixIconTokens,
            position: "suffix",
          })}
        />
      ) : null}
      {props.suffix ? (
        typeof props.suffix === "string" ? (
          <div className={endcapStyles(t, { ...styleProps, side: "right" })}>
            {props.suffix}
          </div>
        ) : (
          props.suffix
        )
      ) : null}
    </div>
  )
}

InputBox.propTypes = {
  animated: PropTypes.bool,
  children: PropTypes.node,
  cursor: PropTypes.oneOf(["default", "text", "pointer"]),
  /**
   * This disables interaction with the component and applies special visual
   * styles to indicate that it's not interactive.
   */
  disabled: PropTypes.bool,
  empty: PropTypes.bool,
  /**
   * Show the component in an error state. Use this to indicate that the value
   * of the component is invalid.
   *
   * Consider pairing this state with a small error `Alert` component below
   * or to the right of the component explaining what the error is.
   */
  error: PropTypes.bool,
  focus: PropTypes.bool,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  /**
   * If this is set it will force the float state of the label. True = the
   * label is floated up above the input, false = the label is resting over
   * the input.
   */
  labelFloated: PropTypes.bool,
  /**
   * Static text to show in an end-cap prepended to the input.
   *
   * To add a selectable prefix to an input, see the `InputGroup` component.
   */
  prefix: PropTypes.string,
  /**
   * Pass an object of icon tokens here (imported from `@amzn/meridian-tokens/base/icon/<icon-name>`)
   * in order to show an icon prepended to the input's text-entry area.
   */
  prefixIconTokens: PropTypes.object,
  /**
   * Sets the size of the component using a preset.
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge", "auto"]),
  /**
   * Static text to show in an end-cap appended to the input.
   *
   * To add a selectable suffix to an input, see the `InputGroup` component.
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Pass an object of icon tokens here (imported from `@amzn/meridian-tokens/base/icon/<icon-name>`)
   * in order to show an icon appended to the input's text-entry area.
   */
  suffixIconTokens: PropTypes.object,
  /**
   * Set the width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onMouseDown: PropTypes.func,
}

InputBox.defaultProps = {
  size: "medium",
  cursor: "default",
  animated: true,
}

export default InputBox
