
// Mapping obtained from https://www.feitsui.com/en/article/3
const EDGE_LOCATION_TO_REGION_MAP = {
    //**** PDX Region ****
    // NA
    "DFW3": "PDX",
    "DFW50": "PDX",
    "DFW53": "PDX",
    "DFW55": "PDX",
    "DEN52": "PDX",
    "DEN50": "PDX",
    "HIO50": "PDX",
    "IAH50": "PDX",
    "LAX3": "PDX",
    "LAX50": "PDX",
    "PHX50": "PDX",
    "SLC50": "PDX",
    "SFO5": "PDX",
    "SFO20": "PDX",
    "SFO53": "PDX",
    "SEA19": "PDX",
    "YVR50": "PDX",

    //**** IAD Region ****
    // NA
    "IAD50": "IAD",
    "IAD66": "IAD",
    "IAD79": "IAD",
    "IAD89": "IAD",
    "ATL50": "IAD",
    "ATL51": "IAD",
    "ATL56": "IAD",
    "BOS50": "IAD",
    "ORD50": "IAD",
    "ORD51": "IAD",
    "ORD52": "IAD",
    "ORD53": "IAD",
    "JAX1": "IAD",
    "MIA3": "IAD",
    "MSP50": "IAD",
    "JFK51": "IAD",
    "EWR50": "IAD",
    "EWR52": "IAD",
    "EWR53": "IAD",
    "PHL50": "IAD",
    "IND6": "IAD",
    "YUL62": "IAD",
    "YTO50": "IAD",

    // LATAM
    "EZE51": "IAD",
    "GIG51": "IAD",
    "GRU1": "IAD",
    "GRU3": "IAD",
    "GRU50": "IAD",
    "SCL50": "IAD",
    "BOG50": "IAD",
    "QRO50": "IAD",
    "QRO51": "IAD",

    //**** DUB Region ****
    // EU
    "VIE50": "DUB",
    "BAH53": "DUB",
    "BRU50": "DUB",
    "SOF50": "DUB",
    "ZAG50": "DUB",
    "PRG50": "DUB",
    "CPH50": "DUB",
    "HEL50": "DUB",
    "MRS52": "DUB",
    "CDG3": "DUB",
    "CDG50": "DUB",
    "CDG52": "DUB",
    "TXL52": "DUB",
    "DUS51": "DUB",
    "FRA2": "DUB",
    "FRA6": "DUB",
    "FRA50": "DUB",
    "FRA53": "DUB",
    "FRA56": "DUB",
    "HAM50": "DUB",
    "MUC50": "DUB",
    "MUC51": "DUB",
    "ATH50": "DUB",
    "BUD50": "DUB",
    "DUB2": "DUB",
    "MXP64": "DUB",
    "PMO50": "DUB",
    "FCO50": "DUB",
    "OSL50": "DUB",
    "WAW50": "DUB",
    "LIS50": "DUB",
    "OTP50": "DUB",
    "MAD50": "DUB",
    "MAD51": "DUB",
    "ARN1": "DUB",
    "ARN54": "DUB",
    "ZRH50": "DUB",
    "AMS1": "DUB",
    "AMS50": "DUB",
    "AMS54": "DUB",
    "LHR3": "DUB",
    "LHR50": "DUB",
    "LHR52": "DUB",
    "LHR61": "DUB",
    "LHR62": "DUB",
    "MAN50": "DUB",

    // INDIA
    "BLR50": "DUB",
    "MAA50": "DUB",
    "MAA51": "DUB",
    "HYD50": "DUB",
    "CCU50": "DUB",
    "BOM50": "DUB",
    "BOM51": "DUB",
    "BOM52": "DUB",
    "DEL51": "DUB",
    "DEL54": "DUB",

    // Africa and Middle East
    "TLV50": "DUB",
    "NBO50": "DUB",
    "CPT52": "DUB",
    "JNB50": "DUB",
    "DXB50": "DUB",
    "FJR50": "DUB",

    //**** NRT Region ****
    // Asia
    "BJS": "NRT",
    "HKG54": "NRT",
    "HKG60": "NRT",
    "HKG62": "NRT",
    "SHA": "NRT",
    "SZX": "NRT",
    "TPE50": "NRT",
    "TPE51": "NRT",
    "TPE52": "NRT",
    "ZHY": "NRT",
    "KIX56": "NRT",
    "NRT12": "NRT",
    "NRT20": "NRT",
    "NRT51": "NRT",
    "NRT57": "NRT",
    "ICN51": "NRT",
    "ICN54": "NRT",
    "ICN55": "NRT",
    "BKK50": "NRT",

    // Pacific
    "MEL50": "NRT",
    "PER50": "NRT",
    "SYD1": "NRT",
    "SYD4": "NRT",
    "CGK52": "NRT",
    "KUL50": "NRT",
    "AKL50": "NRT",
    "MNL50": "NRT",
    "SIN2": "NRT",
    "SIN5": "NRT",
    "SIN52": "NRT",

};

export const REGION_CODE_TO_AWS_STANDARD_MAP = {
    "PDX": "us-west-2",
    "IAD": "us-east-1",
    "DUB": "eu-west-1",
    "NRT": "ap-northeast-1"
};

export function getRegion(edgeLocation) {
    let regionMappingError = undefined;
    if (!edgeLocation) {
        regionMappingError = {type: "UnknownEdgeLocation", value: "null"};
        return {region: "PDX", regionMappingError};
    }

    let edgeLocationRegion = edgeLocation.split("-")[0];
    if (!EDGE_LOCATION_TO_REGION_MAP.hasOwnProperty(edgeLocationRegion)) {
        regionMappingError = {type: "UnknownEdgeLocation", value: edgeLocationRegion}
    }
    return {region: EDGE_LOCATION_TO_REGION_MAP[edgeLocationRegion] || "PDX", regionMappingError};
}
