import * as Actions from "../actions/action-types";
import * as AttendeeActions from "../../people/actions/action-types";
import { ATTENDEE_PRIORITY, MEMBER_TYPE } from "../../people/people-constants";
import { STEP } from "../ooto-scheduler-constants";

const initialState = {
    currentMeeting: {
        uniqueID: "",
        organizer: "",
        subject: "",
        body: {
            value: "",
            type: "html"
        },
        location: "",
        time: {
            startTime: Math.floor(new Date().setHours(0, 0, 0) / 1000),
            endTime: Math.floor(new Date().setHours(24, 0, 0) / 1000)
        },
        requiredAttendees: [],
        optionalAttendees: [],
        isPrivate: false,
        isResponseRequested: false,
        isAllDayEvent: true,
        startTimezone: {
            id: ""
        },
        endTimezone: {
            id: ""
        },
        recurrence: null,
        reminder: {
            isReminderSet: false,
            reminderMinutesBeforeStart: 15
        },
        extendedProperties: {
            managed: {
                __type: "com.amazon.resourceadapter#StringValue",
                value: "false"
            },
            resources: {
                __type: "com.amazon.resourceadapter#StringValue",
                value: "[]"
            }
        },
        allowNewTimeProposal: false,
        resources: [],
        status: "outOfOffice",
    },
    step: STEP.NOTIFY_PEOPLE,
    loadOOTO: false,
    awarenessMeeting: {},
    isCreatingMeeting: false,
};

function ootoReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.UPDATE_MEETING:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    ...action.meeting
                }
            };
        case Actions.CLEAR_DRAFT:
            return {
                ...state,
                ...initialState
            };
        case Actions.CHANGE_STEP:
            return {
                ...state,
                step: action.step
            };
        case Actions.SET_LOAD_OOTO:
            return {
                ...state,
                loadOOTO: action.loadOOTO
            };
        case Actions.SET_AWARENESS_MEETING: 
            return {
                ...state,
                awarenessMeeting: action.awarenessMeeting
            };
        case AttendeeActions.ADD_ATTENDEE:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    organizer: action.attendee.isOrganizer ?
                        action.attendee.email
                        :
                        state.currentMeeting.organizer,
                    requiredAttendees: (action.attendee.priority === ATTENDEE_PRIORITY.REQUIRED) &&
                        action.attendee.email &&
                        !action.attendee.email.endsWith("@chime.aws") &&
                        !state.currentMeeting.requiredAttendees.find(
                        (requiredAttendee) => requiredAttendee.email === action.attendee.email) ?
                            [
                                ...state.currentMeeting.requiredAttendees,
                                {
                                    email: action.attendee.email,
                                    memberType: MEMBER_TYPE[action.attendee.type],
                                    displayName: action.attendee.name
                                }
                            ]
                            :
                            state.currentMeeting.requiredAttendees,
                }
            };
        case AttendeeActions.UPDATE_ATTENDEE:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    requiredAttendees: state.currentMeeting.requiredAttendees.find(
                        (requiredAttendee) => requiredAttendee.email === action.attendee.email) ?
                            state.currentMeeting.requiredAttendees
                            :
                            [
                                ...state.currentMeeting.requiredAttendees,
                                {
                                    email: action.attendee.email,
                                    memberType: MEMBER_TYPE[action.attendee.type],
                                    displayName: action.attendee.name
                                }
                            ]
                }
            };
        case AttendeeActions.REMOVE_ATTENDEE:
            return {
                ...state,
                currentMeeting: {
                    ...state.currentMeeting,
                    requiredAttendees: state.currentMeeting.requiredAttendees.filter(
                        (requiredAttendee) => requiredAttendee.email !== action.attendee.email),
                }
            };
        case Actions.SET_CREATING_MEETING_STATE:
            return {
                ...state,
                isCreatingMeeting: action.isCreatingMeeting
            };
        default:
            return state;
    }
}

export default ootoReducer;