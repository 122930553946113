import React, { useEffect, useState } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Heading from "@amzn/meridian/heading";
import ControlGroup from "@amzn/meridian/control-group"
import Checkbox from "@amzn/meridian/checkbox"
import Input from "@amzn/meridian/input";
import Toggle from "@amzn/meridian/toggle";

import FormSection from "./form-section";
import FormInputList from "./form-input-list";

import LocationSearchBar from "../../locations/components/location-search-bar";
import { ARIA_LABELS, ERRORS } from "../../locations/locations-constants";
import "../settings.css";

const RoomBookingPreferences = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const selectedBuilding = props.selectedBuilding;
    const selectedFloor = props.selectedFloor;
    const buildingList = props.buildingList;
    const onLoadFloors = props.onLoadFloors;
    const onChangeSelectedFloor = props.onChangeSelectedFloor;
    const floorLoaded = props.floorLoaded;
    const camera = props.camera;
    const display = props.display;
    const noRestricted = props.noRestricted;
    const noManaged = props.noManaged;
    const minimumSeats = props.minimumSeats;
    const availableOnly = props.availableOnly;
    const onCheckCamera = props.onCheckCamera;
    const onCheckDisplay = props.onCheckDisplay;
    const onCheckNoRestricted = props.onCheckNoRestricted;
    const onCheckNoManaged = props.onCheckNoManaged;
    const onChangeMinimumSeats = props.onChangeMinimumSeats;
    const onCheckAvailableOnly = props.onCheckAvailableOnly;

    const [prevSelectedBuilding, setPrevSelectedBuilding] = useState(undefined);
    const [floorOptions, setFloorOptions] = useState([]);

    const generateFloorOptions = () => {
        let buildingInfo = buildingList.find((building) => building.value === selectedBuilding);

        if (buildingInfo) {
            if (buildingInfo.floorList?.length > 0) {
                return buildingInfo.floorList.map((floor) => {
                    return {
                        value: floor,
                        label: floor
                    };
                });
            }

            // Will need to return the error floor if there are no floors for this building,
            // Otherwise the loading indicator will be forever
            if (buildingInfo.floorList?.length === 0) {
                return [
                    {
                        value: ERRORS.INVALID_FLOOR,
                        label: ERRORS.INVALID_FLOOR
                    }
                ];
            }
        }

        return [];
    };

    const setDefaultSelectedFloor = (floorList) => {
        // Only when the selected floor is not in the floorList we will reset it to the first index
        if (floorList?.length > 0 && floorList.findIndex((floor) => floor.value === selectedFloor) < 0) {
            onChangeSelectedFloor(floorList[0].value);
        }
    };
    
    useEffect(() => {
        if (selectedBuilding !== prevSelectedBuilding) {
            setPrevSelectedBuilding(selectedBuilding);
            if (selectedBuilding === ERRORS.INVALID_BUILDING) {
                onChangeSelectedFloor(ERRORS.INVALID_FLOOR);
                return;
            }

            let buildingInfo = buildingList.find((building) => building.value === selectedBuilding);
            if (!buildingInfo?.floorList) {
                onLoadFloors(selectedBuilding); 
            } else {
                setDefaultSelectedFloor(generateFloorOptions());
            }
        }

        if (floorLoaded) {
            const floorOptionsValue = generateFloorOptions();
            setFloorOptions(floorOptionsValue);
            setDefaultSelectedFloor(floorOptionsValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBuilding, floorLoaded]);

    const onFloorSelect = (floor) => {
        if (floor === ERRORS.INVALID_FLOOR) {
            return;
        }
        onChangeSelectedFloor(floor);
    };

    return (
        <FormSection 
            children={
                <FormInputList 
                    screenSizeBreakpoint={screenSizeBreakpoint}
                    inputItems={[
                        {
                            inputComponent: 
                                <Row alignmentVertical="top">
                                    <Column spacing="small">
                                        <Heading level={5}>Building</Heading>
                                        <LocationSearchBar
                                            isSearchable={true}
                                            isMultiSelect={false}
                                            onLocationSelect={props.onChangeSelectedBuilding}
                                            locationOptions={buildingList}
                                            location={selectedBuilding}
                                            width={"100%"}
                                            spacing={"small"}
                                            placeholder={"Select building"}
                                            ariaLabel={ARIA_LABELS.BUILDING}
                                        />
                                        <Text type="b200" color="secondary">Default location for room booking</Text>
                                    </Column>
                                    <Column spacing="small" maxWidth={"150px"}>
                                        <Heading level={5}>Floor</Heading>
                                        <LocationSearchBar
                                            isSearchable={true}
                                            isMultiSelect={false}
                                            onLocationSelect={onFloorSelect}
                                            locationOptions={floorOptions}
                                            location={selectedFloor}
                                            width={"100%"}
                                            spacing={"small"}
                                            placeholder={"Select floor"}
                                            ariaLabel={selectedFloor === ERRORS.INVALID_FLOOR ?
                                                ARIA_LABELS.FLOOR
                                                :
                                                ARIA_LABELS.ROOM
                                            }
                                        />
                                    </Column>
                                </Row>
                        },
                        {
                            header: "Room results",
                            inputComponent:
                                <Toggle 
                                    size="medium"
                                    checked={availableOnly} 
                                    onChange={onCheckAvailableOnly}>
                                    Show available rooms only
                                </Toggle>
                        },
                        {
                            inputComponent: <hr className="email-and-chime-divider" />
                        },
                        {
                            header: "Features",
                            inputComponent:
                                <ControlGroup
                                name="features"
                                >
                                    <Checkbox value="camera" checked={camera} onChange={onCheckCamera}>Camera</Checkbox>
                                    <Checkbox value="display" checked={display} onChange={onCheckDisplay} disabled={camera}>Display</Checkbox>
                                </ControlGroup>
                        },
                        {
                            header: "Permissions",
                            inputComponent:
                                <ControlGroup
                                name="permissions"
                                >
                                    <Checkbox value="no-restricted" checked={noRestricted} onChange={onCheckNoRestricted}>No restricted</Checkbox>
                                    <Checkbox value="no-managed" checked={noManaged} onChange={onCheckNoManaged}>No hand-managed</Checkbox>
                                </ControlGroup>
                        },
                        {
                            header: "Minimum Seats",
                            inputComponent:
                                <Column spacing="small">
                                    <Input
                                        value={minimumSeats}
                                        onChange={onChangeMinimumSeats}
                                        id = "minimal-seats-setting"
                                        placeholder="Enter value..."
                                        type = "number"
                                        pattern={/^[0-9]*$/}
                                        size="small"
                                    />
                                    <Text type="b200" color="secondary">Room capacity</Text>
                                </Column>
                        }
                    ]}
                />
            }
        />
    );
};

export default RoomBookingPreferences;