import { pad } from "../../meeting-roomfinder/utils";
import { TIME_FORMAT, TIME_CONSTANT } from "../shared-constants";

/**
 * Returns the specified format of the work hours time passed in as a string
 *
 * @param {Int} time - workHours startTime or endTime state (expressed in minutes)
 * @param {String} format - how workHours time should be formatted (defaults to HH:mm:ss)
 * @return {String} the formatted work hours time as a string
 */
export const formatWorkHoursTime = (time, format) => {
    if (time === undefined) {
        return "";
    }

    switch (format) {
        case TIME_FORMAT.HH_MM_SS:
        default:
            return pad(Math.floor(time / TIME_CONSTANT.ONE_HOUR_IN_MIN), 2) + ":" + pad(time % TIME_CONSTANT.ONE_HOUR_IN_MIN, 2) + ":00";
    }
};