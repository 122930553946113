import React, { useCallback, useEffect, useRef } from "react";

import format from "date-fns/format";
import addTime from "date-fns/add";
import setDay from "date-fns/setDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import differenceInCalendarWeeks from "date-fns/differenceInCalendarWeeks";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import calendarTokens from "@amzn/meridian-tokens/base/icon/calendar";
import chevronLeftSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small"

import RefreshAlert from "../../shared/components/refresh-alert";

import { SINGLE_DAY_VIEW_THRESHOLD, FULL_WEEK_VIEW_THRESHOLD, CALENDAR_MODE } from "../calendar-constants";
import { REFRESH_ALERT_TIMER } from "../../shared/shared-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";

const formatIso = date => format(date, "yyyy-MM-dd");
const formatIsoDayOfMonth = date => format(date, "MMM d");
const nextWeek = date => addTime(date, {weeks: 1});
const previousWeek = date => addTime(date, {weeks: -1});

const CalendarDatePicker = (props) => {
    const calendarMode = props.calendarMode;

    const showRefreshAlert = props.showRefreshAlert;
    const setShowRefreshAlert = props.setShowRefreshAlert;
    const parsedDate = parse(props.date, "yyyy-MM-dd", new Date());

    // Date Range variables for availability sharing
    const startDateRange = props.boundByDateRange && props.boundByDateRange[0] && parse(props.boundByDateRange[0], "yyyy-MM-dd", new Date());
    const endDateRange = props.boundByDateRange && props.boundByDateRange[1] && parse(props.boundByDateRange[1], "yyyy-MM-dd", new Date());
    const weeksInRange = calendarMode === CALENDAR_MODE.AVAILABILITY_SHARING ?
        showNaNasZero(differenceInCalendarWeeks(endDateRange, startDateRange) + 1) : 0;
    const weekIndex = calendarMode === CALENDAR_MODE.AVAILABILITY_SHARING ?
        showNaNasZero(differenceInCalendarWeeks(parsedDate, startDateRange) + 1) : 0;

    const refreshTimer = useRef();

    const setTodayDate = () => {
        props.onSetCalendarDate(formatIso(new Date()));
    };

    const setNextWeekDate = () => {
        props.onSetCalendarDate(formatIso(nextWeek(setDay(parsedDate, 1))));
    };

    const setPreviousWeekDate = () => {
        props.onSetCalendarDate(formatIso(previousWeek(setDay(parsedDate, 1))));
    };

    const setNextDayDate = () => {
        props.onSetCalendarDate(formatIso(addTime(parsedDate, {days: 1})));
    };

    const setPreviousDayDate = () => {
        props.onSetCalendarDate(formatIso(addTime(parsedDate, {days: -1})));
    };

    const formatDateToMatchViewType = () => {
        switch (props.viewType) {
            case "day":
                return `${formatIsoDayOfMonth(parsedDate)}, ${parsedDate.getFullYear()}`;
            case "workweek":
                if (props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD) {
                    return `${formatIsoDayOfMonth(parsedDate)}, ${parsedDate.getFullYear()}`;
                }
                return `${formatIsoDayOfMonth(setDay(parsedDate, 1))} - ${formatIsoDayOfMonth(setDay(parsedDate, 5))}, ${parsedDate.getFullYear()}`;
            case "week":
            case "agenda":
            default:
                if (props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD) {
                    return `${formatIsoDayOfMonth(parsedDate)}, ${parsedDate.getFullYear()}`;
                }
                return `${formatIsoDayOfMonth(startOfWeek(parsedDate))} - ${formatIsoDayOfMonth(endOfWeek(parsedDate))}, ${parsedDate.getFullYear()}`;
        }
    };

    const createRefreshTimer = useCallback(() => {
        return window.setTimeout(() => {
            setShowRefreshAlert(true);
        }, REFRESH_ALERT_TIMER);
    }, [setShowRefreshAlert]);

    useEffect(() => {
        refreshTimer.current = createRefreshTimer();
    }, [createRefreshTimer]);

    const onClickRefresh = () => {
        props.setTriggerRefresh(true);
        props.setShowRefreshAlert(false);
        refreshTimer.current = createRefreshTimer();
    };

    return (
        <Column>
            <Row alignmentHorizontal="center" widths={["fit", "fill", "fit"]} width="100%" spacing="none">
                {(props.screenSizeBreakpoint >= SINGLE_DAY_VIEW_THRESHOLD && calendarMode === CALENDAR_MODE.DEFAULT && props.workflow !== WORKFLOWS.RESPOND_POLL.NAME) &&
                    <div style={{position: "absolute", left: "16px"}}>
                        <Button minWidth="140px" type="tertiary" alignment="right" onClick={setTodayDate}>Today</Button>
                    </div>
                }
                {calendarMode === CALENDAR_MODE.AVAILABILITY_SHARING &&
                    <Row>
                        <Button type="icon" size="small" onClick={setPreviousWeekDate} label="Previous week" disabled={weekIndex === 1}>
                            <Icon tokens={chevronLeftSmallTokens} />
                        </Button>
                        <Text type="h100">{`Week ${weekIndex} of ${weeksInRange}`}</Text>
                        <Button type="icon" size="small" onClick={setNextWeekDate} label="Next week" disabled={weekIndex === weeksInRange}>
                            <Icon tokens={chevronRightSmallTokens} />
                        </Button>
                    </Row>
                }
                <Row alignmentHorizontal="center">
                    {calendarMode === CALENDAR_MODE.DEFAULT &&
                        <Button type="icon"
                                onClick={(props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || props.viewType === "day") ? setPreviousDayDate : setPreviousWeekDate}
                                label={(props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || props.viewType === "day") ? "Navigate to previous day" : "Navigate to previous week"}>
                            <Icon tokens={chevronLeftSmallTokens}/>
                        </Button>
                    }

                    <div aria-live="polite" role="status">
                        <Heading level={props.createPollCalendar ? 3 : 2} type="h200">{formatDateToMatchViewType()}</Heading>
                    </div>

                    {calendarMode === CALENDAR_MODE.DEFAULT &&
                        <Button type="icon"
                                onClick={(props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || props.viewType === "day") ? setNextDayDate : setNextWeekDate}
                                label={(props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || props.viewType === "day") ? "Navigate to next day" : "Navigate to next week"}>
                            <Icon tokens={chevronRightSmallTokens}/>
                        </Button>
                    }
                </Row>
                {calendarMode === CALENDAR_MODE.AVAILABILITY_SHARING &&
                    <div style={{width: "150px"}} />
                }

                {calendarMode === CALENDAR_MODE.DEFAULT && props.onCalendarOptionsModalOpen &&
                    (props.screenSizeBreakpoint <= SINGLE_DAY_VIEW_THRESHOLD || (props.viewType === "week" && props.screenSizeBreakpoint === FULL_WEEK_VIEW_THRESHOLD)) &&
                    <div style={{position: "absolute", right: "16px"}}>
                        <Button onClick={props.onCalendarOptionsModalOpen}>
                            <Icon tokens={calendarTokens} />
                        </Button>
                    </div>
                }
            </Row>
            {showRefreshAlert &&
                <RefreshAlert page="calendar" onClick={onClickRefresh} />
            }
        </Column>
    );
};

const showNaNasZero = (number) => {
    return isNaN(number) ? 0 : number;
}

export default CalendarDatePicker;
