const buttonReset = {
  display: "inline-block",
  padding: 0,
  border: 0,
  outline: "none",
  backgroundColor: "transparent",
  textAlign: "inherit",
  color: "inherit",
  fontFamily: "inherit",
  fontSize: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit",
  WebkitAppearance: "none",
  WebkitMarginBefore: 0,
  WebkitMarginAfter: 0,
  WebkitMarginStart: 0,
  WebkitMarginEnd: 0,
  "::-moz-focus-inner": {
    border: 0,
  },
}

export default buttonReset
