// Default configuration
(function (window) {
    window.Amazon = window.Amazon || {};
    window.Amazon.IDP = window.Amazon.IDP || {};
    window.Amazon.IDP.config = window.Amazon.IDP.config || {};
    window.Amazon.IDP.config.excludeDomains = window.Amazon.IDP.config.excludeDomains || [];

    window.Amazon.IDP.config.shouldAuthenticate = function (xhrArgs) {
        return xhrArgs.url?.includes("live.meetex.enterprise-engineering.aws.dev") ||
            xhrArgs.url?.includes("livestream.amazon.dev");
    };
})(window);