import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { css } from "emotion"
import noop from "lodash/noop"
import uniqueId from "lodash/uniqueId"
import keycode from "keycode"
import searchFieldTokens from "@amzn/meridian-tokens/component/search-field"
import searchIconTokens from "@amzn/meridian-tokens/base/icon/search"
import SearchFieldSuffix from "./search-field-suffix"
import SearchSuggestionPopover from "./search-suggestion-popover"
import { getNonSuggestions, getSuggestions } from "./utils"
import Input from "../input"
import { Style } from "../theme"
import Focus from "../_focus"
import { getTokenHelper } from "../../_utils/token"

const styles = css({ position: "relative" })

/* eslint-disable react/prop-types */
class SearchFieldClass extends React.Component {
  state = {
    open: false,
    preselectedIndex: undefined,
  }

  // Generate a unique id that can be used to reference this element in the DOM
  id = this.props.id || uniqueId("search-field-")

  popupId = uniqueId("suggestion-list-")

  onInputKeyDown = event => {
    const { onSubmit, value } = this.props
    const key = keycode(event)
    if (key === "enter") {
      onSubmit(value)
    }
  }

  updateRootRef = ref => {
    if (ref) {
      this.rootRef = ref
      this.inputDomNode = this.rootRef.querySelector("input")
      // If we are forwarding a ref, assign forwarded ref to input element
      if (this.props.forwardedRef) {
        const { forwardedRef } = this.props
        // Assign the ref with a function (Class components) or assigning .current
        // (modern functional components using the `useRef` hook)
        if (typeof forwardedRef === "function") {
          forwardedRef(this.inputDomNode)
        } else {
          forwardedRef.current = this.inputDomNode
        }
      }
    }
  }

  updateOptionListRef = optionListRef => {
    if (optionListRef && optionListRef !== this.optionListRef) {
      this.optionListRef = optionListRef
      /* eslint-disable-next-line react/no-find-dom-node */
      this.optionListNode = ReactDOM.findDOMNode(optionListRef)
    }
  }

  updateCloseNodeRef = ref => (this.closeNodeRef = ref)

  getLastInputFieldDomNode = () => {
    const inputFields = this.rootRef.querySelectorAll(
      "input, button:not([aria-hidden])"
    )
    return inputFields[inputFields.length - 1]
  }

  onChange = value => {
    // Call the onClear callback if this change clears out the input value
    if (this.props.value && !value) {
      this.props.onClear()
    }
    this.props.onChange(value)
  }

  // Close the search suggestions by removing focus from it, but first make sure that
  // the text input is the last element with focus so we don't leave the tab
  // index way out in React portal land (the popover element is actually
  // somewhere completely separate in the DOM, so if that's the last thing
  // with focus before we close the suggestions then the next time the user hits
  // tab they'll find themselves somewhere unexpected in the document).
  onClose = () => {
    this.closeNodeRef.focus({ preventScroll: true })
    this.closeNodeRef.blur()
    this.setState({ open: false })
  }

  onClickSuggestion = () => {
    // If closeOnClickSuggestion is false, focus back to the input element
    if (!this.props.closeOnClickSuggestion) {
      this.inputDomNode.focus({ preventScroll: true })
    } else {
      this.onClose()
    }
  }

  // Handle closing the suggestions with the "esc" key and handle tab navigation
  // from the text input to the popover (which is a React portal and exists
  // outside of the normal tab index).
  onSearchFieldKeyDown = event => {
    const { children } = this.props
    const suggestions = getSuggestions(children)
    // Only handle the keypress if we have search suggestions and the event
    // wasn't already handled by the option list (as indicated by the
    // "defaultPrevented" property on the event)
    if (suggestions.length > 0 && !event.defaultPrevented) {
      const key = keycode(event)
      if (key === "esc") {
        // Stop propagating the event upwards. This ensures that a parent element
        // (e.g. a modal) won't also handle the escape key.
        event.stopPropagation()
        // If the user hits escape then close the suggestions and focus
        // back on the input
        this.inputDomNode.focus()
      } else if (
        key === "tab" &&
        !event.shiftKey &&
        document.activeElement === this.getLastInputFieldDomNode()
      ) {
        // If the user hits tab on the text input then transfer the focus to the
        // option list and preselect the first option
        this.optionListNode.focus()
        this.optionListRef.onFocusOptionIndex(0)
        this.optionListRef.onChangePreselectedIndex(0)
        this.optionListRef.scrollOptionIntoView(0)
        event.preventDefault()
      } else if (key === "down") {
        // If the user hits the down arrow on the text input then transfer the
        // focus to the option list and preselect the first option
        this.optionListNode.focus()
        this.optionListRef.onFocusOptionIndex(0)
        this.optionListRef.onChangePreselectedIndex(0)
        this.optionListRef.scrollOptionIntoView(0)
        event.preventDefault()
      }
    }
  }

  onFocus = event => {
    if (!this.state.open) {
      this.props.onFocus(event)
      this.setState({ open: true })
    }
  }

  onBlur = event =>
    this.setState({ open: false }, () => this.props.onBlur(event))

  onBeforeBlurPopover = direction => {
    // Close the popover if the user tabs forward out of it
    if (direction === 1) {
      this.onClose()
      // Focus on the search field if the user tabs backwards out of the popover
    } else if (direction === -1) {
      this.getLastInputFieldDomNode().focus()
    }
    // Prevent the blur's default behavior because we're going to handle focus
    // ourselves
    return false
  }

  // keep track of the currently focused index in the option list
  onChangePreselectedIndex = index => {
    this.setState({ preselectedIndex: index })
    if (this.optionListRef) {
      this.optionListRef.onFocusOptionIndex(index)
    }
  }

  render() {
    const props = this.props
    const { open, preselectedIndex } = this.state
    const suggestions = getSuggestions(props.children)
    const { footer, header, message } = getNonSuggestions(props.children)
    const popoverOpen = open && (suggestions.length > 0 || message.length > 0)

    return (
      <Style tokens={searchFieldTokens} map={getTokenHelper("search-field")}>
        {t => (
          <span>
            <Focus
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              multipleTargets={true}
            >
              {({ focusProps }) => (
                <div
                  className={styles}
                  ref={this.updateRootRef}
                  onKeyDown={this.onSearchFieldKeyDown}
                  {...focusProps}
                >
                  <Input
                    aria-label={props["aria-label"]}
                    aria-labelledby={props["aria-labelledby"]}
                    aria-autocomplete="list"
                    aria-owns={this.popupId}
                    aria-controls={this.popupId}
                    aria-expanded={popoverOpen}
                    aria-haspopup="listbox"
                    aria-activedescendant={preselectedIndex > -1 ? `${this.popupId}-option-${preselectedIndex}` : undefined}
                    role="combobox"
                    size={props.label ? "xlarge" : props.size}
                    label={props.label}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    width={props.width}
                    disabled={props.disabled}
                    onChange={this.onChange}
                    onKeyDown={this.onInputKeyDown}
                    prefixIconTokens={
                      !props.searchButton ? searchIconTokens : undefined
                    }
                    suffix={
                      <SearchFieldSuffix
                        t={t}
                        inputRef={{ current: this.inputDomNode }}
                        {...this.props}
                      />
                    }
                    autoFocus={props.autoFocus}
                    id={props.id}
                    autoFill={false}
                    description={props.description}
                  />
                  <SearchSuggestionPopover
                    onChangePreselectedIndex={this.onChangePreselectedIndex}
                    popupId={this.popupId}
                    open={popoverOpen}
                    anchorNode={this.rootRef}
                    suggestions={suggestions}
                    query={props.value}
                    header={header}
                    footer={footer}
                    message={message}
                    optionListRef={this.updateOptionListRef}
                    onBeforeBlur={this.onBeforeBlurPopover}
                    onClickSuggestion={this.onClickSuggestion}
                    maxHeight={props.popoverMaxHeight}
                  />
                </div>
              )}
            </Focus>
            {/* HACK: This is used when focus needs to be transferred away
            from the popover (b/c it's being closed) but we don't have
            anywhere else to put the focus. */}
            <span tabIndex="-1" ref={this.updateCloseNodeRef} />
          </span>
        )}
      </Style>
    )
  }
}
/* eslint-enable react/prop-types */

// Forward ref to class component. TODO: update class component
// to functional component.
const SearchField = React.forwardRef((props, ref) => (
  <SearchFieldClass {...props} forwardedRef={ref} />
))

SearchField.displayName = "SearchField"

SearchField.propTypes = {
  /**
   * Prop used to set an aria-label to the search input for accessibility.
   *
   * @added by MeetEx team (@camei @alvinyu)
   */
  "aria-label": PropTypes.string,
  /**
   * Prop used to set aria-labelledby to the search input for accessibility.
   *
   * @added by MeetEx team (@camei @alvinyu)
   */
  "aria-labelledby": PropTypes.string,
  /**
   * The value of the search field.
   *
   * Update this when `onChange` is called to ensure the component is interactive
   * (see the documentation for the `onChange` prop).
   */
  value: PropTypes.any.isRequired,
  /**
   * A function that will be called when the user attempts to change the value
   * of the component (e.g. by typing in the input). The new value will be
   * passed as the first argument to this function.
   *
   * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
   * so you must track the values provided to this function somewhere in state
   * and pass them back via the `value` prop in order to make this component
   * interactive.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * This function is called when the user presses the "enter" key while focused
   * on the search field or when they click the search button (see the
   * `searchButton` prop).
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * If set to true then the input will grab focus as soon as it's mounted.
   *
   * This uses HTML's native `autofocus` attribute. Use this sparingly as
   * there are [common accessibility pitfalls](https://www.brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/)
   * associated with this behavior.
   */
  autoFocus: PropTypes.bool,
  /**
   * A list of `SearchSuggestion` elements that represent suggestions to present to
   * the user.
   *
   * If no `SearchSuggestion` elements are provided then any other elements passed here
   * will be rendered in place of them. This allows you to render things like a
   * loading indicator while waiting for asynchronously loaded suggestions or a
   * message to the user when no suggestions are available.
   */
  children: PropTypes.node,
  /**
   * Appends a button to the input that the user can click to clear the input.
   * This will trigger the `onChange` callback with a value of `""`.
   */
  clearButton: PropTypes.bool,
  /**
   * An accessible label for the clear button. This will not be visible in the UI, but
   * will be read by screen readers.
   */
  clearButtonLabel: PropTypes.string,
  /**
   * By default, selecting a suggestion will close the suggestion popover and remove
   * focus from the SearchField. If set to `false`, selecting a suggestion will instead
   * move focus back to the SearchField's input allowing a user to quickly edit
   * the suggestion.
   *
   * @since 5.x
   */
  closeOnClickSuggestion: PropTypes.bool,
  /**
   * This disables interaction with the component and applies special visual
   * styles to indicate that it's not interactive.
   */
  disabled: PropTypes.bool,
  /**
   * Sets an `id` attribute on the component's form element.
   *
   * Use this for testing or for accessibility. Do not use this in order to apply override styles
   * to the component. The markup and styles rendered by Meridian components
   * are private APIs, and may change without notice.
   *
   * @since 5.x
   */
  id: PropTypes.string,
  /**
   * A label for the search field's input.
   *
   * If this prop is provided the `size` prop will be ignored and set to `xlarge`.
   *
   * @since 4.x
   */
  label: PropTypes.string,
  /**
   * Text that is shown in place of the input's value when the input is empty.
   *
   * This text is not visible when a value is entered in the input, so it should
   * not contain essential information that should be persisted.
   */
  placeholder: PropTypes.string,
  /**
   * Sets the maximum height of the popover containing any search suggestions.
   *
   * This is helpful if you have a very long list of suggestions, or suggestions
   * that take up a significant amount of vertical space. The popover is
   * scrollable, so any suggestions that overflow the popover will still be
   * accessible.
   *
   * @since 4.x
   */
  popoverMaxHeight: PropTypes.number,
  /**
   * Appends a search button to the input that the user can click to trigger the
   * `onSubmit` callback.
   */
  searchButton: PropTypes.bool,
  /**
   * An accessible label for the search button. This will not be visible in the UI, but
   * will be read by screen readers.
   */
  searchButtonLabel: PropTypes.string,
  /**
   * Sets the size of the component using a preset.
   */
  size: PropTypes.oneOf(["small", "medium", "xlarge"]),
  /**
   * The data type of the search field's value.
   */
  type: PropTypes.oneOf(["text", "number"]),
  /**
   * Set the width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   *
   * @since 4.x
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * This is called when the component loses focus. The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event)
   * is passed as the first argument to the function.
   */
  onBlur: PropTypes.func,
  /**
   * This is called when the clear button is clicked *or* when the search
   * field's text value is manually cleared out (e.g. by selecting the current
   * text value and pressing backspace).
   *
   * No arguments are passed to this callback.
   *
   * @since 4.x
   */
  onClear: PropTypes.func,
  /**
   * This is called when the component gains focus. The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event)
   * is passed as the first argument to the function.
   *
   * Note that the target of the focus event passed to this function may not
   * always reference the search field's text input. If the user first focuses
   * on the search field's search button or clear button the event target may
   * reference that element.
   */
  onFocus: PropTypes.func,
  /**
   * Description to be read out for accessibility
   *
   * @added by MeetEx team (@camei @alvinyu)
   */
  description: PropTypes.string,
}

SearchField.defaultProps = {
  size: "medium",
  type: "text",
  onFocus: noop,
  onBlur: noop,
  onClear: noop,
  clearButton: true,
  searchButton: false,
  searchButtonLabel: "Search",
  clearButtonLabel: "Clear search",
  /**
   * We derive 165 by picking the value that will show 4 search
   * suggestions (36px height) + half the height (18px) to show a
   * "peak" of the next value so that users know there are more
   * options to scroll too. NOTE: this is just our best guess because
   * suggestion cells might wrap / be taller then 36px
   */
  popoverMaxHeight: 165,
  closeOnClickSuggestion: true,
}
export default SearchField
