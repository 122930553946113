/**
 * This module contains utilities that work with date strings in the format
 * "YYYY-MM-DD". These functions are much faster than the full-featured
 * functions from the date-fns library, so use them when possible.
 */

import padStart from "lodash/padStart"

const isoDateFormat = "yyyy-MM-dd HH:mm"

/**
 * Extract the value from a date string given a full format string and the
 * tokens within the format string that we want to parse. If an exact value
 * isn't found then undefined is returned. Examples:
 * getValueAtFormatTokens("2012-01-01", "yyyy-MM-dd", "yyyy") -> "2012".
 * getValueAtFormatTokens("haha-01-01", "yyyy-MM-dd", "yyyy") -> undefined.
 * getValueAtFormatTokens("201 -01-01", "yyyy-MM-dd", "yyyy") -> undefined.
 * getValueAtFormatTokens("201", "yyyy-MM-dd", "yyyy") -> undefined.
 */
const getValueAtFormatTokens = (dateString, formatString, formatSubstring) => {
  const firstIndex = formatString.indexOf(formatSubstring)
  const lastIndex = firstIndex + formatSubstring.length
  const value = dateString.slice(firstIndex, lastIndex).replace(/[^0-9]/g, "")
  return value.length === lastIndex - firstIndex ? parseInt(value) : undefined
}

/**
 * Convert a date-only string (e.g. "11/01/2012") into a date object given a
 * particular format (e.g. MM/DD/YYYY). If the date string doesn't match the
 * format or the date is invalid (e.g. 40/40/2012) then `undefined` is returned.
 * This should be used when parsing user input because `date-fns/parseISO` will
 * accept invalid input like "doh-01-31" and return a random/fallback date.
 */
const parseDateString = (dateString = "", format = isoDateFormat) => {
  const year = getValueAtFormatTokens(dateString, format, "yyyy")
  const month = getValueAtFormatTokens(dateString, format, "MM") - 1
  const day = getValueAtFormatTokens(dateString, format, "dd")
  const dateObject = new Date(year, month, day)
  // Invalid dates are tricky to detect. Here we're looking to see if the date
  // can't be coerced to a number (this is what happens if you pass a non-number
  // into the Date constructor) or if the year, month, or day numbers that we
  // get back from the date don't match what we put it (this is what happens if
  // you pass something like month "40" into the Date constructor).
  return isNaN(dateObject) ||
    dateObject.getFullYear() !== year ||
    dateObject.getMonth() !== month ||
    dateObject.getDate() !== day
    ? undefined
    : dateObject
}

/**
 * Convert a date object into a date string (e.g. "2012-11-01").
 */
const toDateString = dateObject =>
  `${padStart(dateObject.getFullYear(), 4, "0")}-${padStart(
    dateObject.getMonth() + 1,
    2,
    "0"
  )}-${padStart(dateObject.getDate(), 2, "0")}`

/**
 * Determines if two dates in the format "YYYY-MM-DD" fall in the same month.
 * Faster than date-fns when dealing with string dates.
 */
const isSameMonth = (a, b) => a.slice(0, 7) === b.slice(0, 7)

/**
 * Determines if two dates in the format "YYYY-MM-DD HH:mm" fall in the same day.
 * Faster than date-fns when dealing with string dates.
 */
const isSameDay = (a, b) => a.slice(0, 10) === b.slice(0, 10)

/**
 * Returns the day portion of a date in the format YYYY-MM-DD. Faster than
 * date-fns when dealing with string dates.
 */
const getDay = date => parseInt(date.slice(8, 10))

/**
 * Returns the month portion of a date in the format YYYY-MM-DD. Faster than
 * than date-fns when dealing with string dates.
 */
const getMonth = date => parseInt(date.slice(5, 7))

/**
 * Returns the year portion of a date in the format YYYY-MM-DD. Faster than
 * date-fns when dealing with string dates.
 */
const getYear = date => parseInt(date.slice(0, 4))

/**
 * Returns a unitless number that can be used to compare one date with another
 * chronologically. This in abstract way of doing this, but it's much faster than
 * actually parsing the dates and comparing them that way.
 */
const getComparable = date =>
  getYear(date) * 10000 + getMonth(date) * 100 + getDay(date)

/**
 * Returns true if a date falls in the range identified by a start and an end
 * date. Faster than date-fns when dealing with string dates.
 */
const isWithinRange = (date, start, end) => {
  const startComparable = getComparable(start)
  const endComparable = getComparable(end)
  const dateComparable = getComparable(date)
  return dateComparable >= startComparable && dateComparable <= endComparable
}

/**
 * Returns true if a comes before b. Faster than date-fns when dealing with
 * string dates.
 */
const isBefore = (a, b) => getComparable(a) < getComparable(b)

/**
 * Returns the number of calendar months between two date strings. Faster than
 * date-fns when dealing with string dates.
 */

const differenceInCalendarMonths = (a, b) =>
  (getYear(a) - getYear(b)) * 12 + getMonth(a) - getMonth(b)

/**
 * Like differenceInCalendarMonths, but the difference is calculated by
 * comparing a single date to a range. If the date is anywhere in the range the
 * difference is 0, otherwise the difference is between the single date and the
 * clostest date in the range.
 */
const differenceInMonthsFromRange = (date, start, end) => {
  const differenceStart = differenceInCalendarMonths(date, start)
  const differenceEnd = differenceInCalendarMonths(date, end)
  return differenceStart < 0
    ? differenceStart
    : differenceEnd > 0
    ? differenceEnd
    : 0
}

export {
  parseDateString,
  toDateString,
  isSameMonth,
  isSameDay,
  isWithinRange,
  isBefore,
  getDay,
  getMonth,
  getYear,
  differenceInCalendarMonths,
  differenceInMonthsFromRange,
}
