module.exports = {
    NEARBY_FLOOR_COUNT: 4, // number of nearby floor options to display
    TILE_COLOR: {
        AVAILABLE: "#FFFFFF", // Gray-0
        WAS_AVAILABLE: "#FFFFFF", // Gray-0
        BUSY: "#FFB09F", // Crimson-200
        RESTRICTED: "#FFFFFF", // Gray-0
    },
    // Informational Alerts
    RESERVE_ROOM_CREATE_MEETING: {
        "type": "informational",
        "value": "Processing",
        "id": "amw-meeting-create-meeting-processing-id",
    },
}