import React, { useState } from "react";
import { useDispatch } from "react-redux";

import format from "date-fns/format";

import {
    checkInAndReclaimResources,
    reclaimResources,
} from "../check-in/actions";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Checkbox from "@amzn/meridian/checkbox";
import Divider from "@amzn/meridian/divider";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Modal, {
    ModalFooter,
} from "../meridian-custom-components/src/components/modal";

import closeTokens from "@amzn/meridian-tokens/base/icon/close-small";
import oopsEmojiSvg from "../../../assets/icons/shared/oops_emoji.svg";
import DismissPattern from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";

import { renderDurationLabel } from "../time-utils";
import {
    timezoneIdToDisplayName,
} from "../timezones/timezones-utils";
import { convertEpoch } from "../../meeting-scheduler/meeting-scheduler-utils";
import { generateComponentId, onSpaceOrEnterKeyDown } from "../shared-utils";
import { ROOM_RESPONSE, TIME_CONSTANT } from "../shared-constants.js";
import "../styles/release-check-in-meeting-modal.css";
import { shortRoomName } from "../locations/locations-utils";
import { TIME_FORMAT } from "../settings/settings-constants";

const ReleaseOrCheckInMeetingModal = (props) => {
    const DATE_WEEKDAY_FORMAT_PATTERN = "EEEE, MMMM dd, yyyy";
    const CHECK_IN_ENABLE_MESSAGE = "(check-in required)";
    const CHECK_IN_NOT_ENABLE_MESSAGE = "(check-in not required)";
    const ROOM_RELEASE_THRESHOLD = "10";

    const dispatch = useDispatch();
    const [open, setOpen] = [props.open, props.setOpen];

    const onClose = props.onClose;
    const isCheckIn = props.isCheckIn;
    const isSingleRoom = props.isSingleRoom;
    const roomCheckInStatusDictionary = props.roomCheckInStatusDictionary;
    const meeting = props.meeting;
    const timezones = props.timezones;
    const primaryTimezone = props.primaryTimezone;
    const timeFormat = props.timeFormat;
    const browserTimezoneName = timezoneIdToDisplayName(
        timezones,
        primaryTimezone
    );

    const [selectedRooms, setSelectedRooms] = useState([]);

    const formatDateTime = (time, format) => {
        return convertEpoch(time, format, primaryTimezone, timeFormat === TIME_FORMAT.TWELVE_HOUR);
    };

    const formatTimezoneName = () => {
        return browserTimezoneName || "";
    };

    const onSelectedRoomsChange = (room) => {
        let selectedRoomIndex = selectedRooms.findIndex(selectedRoom => selectedRoom.email === room.email);

        if (selectedRoomIndex > -1) {
            selectedRooms.splice(selectedRoomIndex, 1);
        } else {
            selectedRooms.push(room);
        }
        setSelectedRooms([...selectedRooms]);
    };

    const closeModal = () => {
        setOpen(false);
        setSelectedRooms([]);
    };

    const onKeyDown = (event) => {
        onSpaceOrEnterKeyDown(event, closeModal);
    };

    const getReadableDate = (time) => {
        try {
            let date = new Date(time * 1000);
            return format(date, DATE_WEEKDAY_FORMAT_PATTERN);
        } catch (e) {
            return time;
        }
    };

    const onClickConfirm = () => {
        if (onClose) {
            onClose();
        }

        if (isCheckIn) {
            const roomsToCheckIn = selectedRooms.map((room) => ({
                meetingStartTime: meeting.startTime,
                roomEmail: room.email,
            }));
            const roomsToRelease = meeting.resources
                .filter((res) => {
                    return roomCheckInStatusDictionary?.has(`${res.email}&${meeting.time.startTime}`) &&
                        roomCheckInStatusDictionary.get(`${res.email}&${meeting.time.startTime}`) &&
                        res.response === ROOM_RESPONSE.ACCEPT &&
                        !selectedRooms.find(room => room.email === res.email);
                })
                .map((res) => ({ email: res.email }));

            // checkIn selected rooms & release unselected rooms.
            dispatch(
                checkInAndReclaimResources(
                    "amw",
                    roomsToCheckIn,
                    selectedRooms.map((room) => room.displayName),
                    props.userEmail,
                    meeting.uniqueID,
                    roomsToRelease,
                    props.checkInRefocusInfo,
                )
            );
        } else {
            isSingleRoom
                ? dispatch(
                        reclaimResources(
                            props.userEmail,
                            meeting.uniqueID,
                            meeting.resources
                        )
                  )
                : dispatch(
                        reclaimResources(props.userEmail, meeting.uniqueID, selectedRooms)
                  );
        }

        closeModal();
    };

    return (
        <Modal
            open={open}
            bodySpacingInset="none"
            width="504px"
            backgroundElementsId={props.backgroundElementsId}
            id={generateComponentId("modal", "release-check-in-modal")}
        >
            <Column spacingInset="none">
                <div
                    style={{
                        height: "56px",
                        padding: "0",
                        backgroundImage: `url(${DismissPattern})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgb(26, 69, 78)",
                        backgroundPosition: "150px -90px",
                        width: "100%",
                    }}
                >
                    <span
                        aria-label="Close"
                        role="button"
                        id="close"
                        tabIndex="0"
                        style={{
                            float: "right",
                            display: "inline-block",
                            padding: "10px 10px",
                            cursor: "pointer",
                            color: "#FFFFFF",
                        }}
                        onKeyDown={onKeyDown}
                        onClick={() => closeModal()}
                    >
                        <Icon tokens={closeTokens} />
                    </span>
                    <Column spacingInset="medium">
                        <Heading type="h300" level={2} color="inverted">
                            {isCheckIn
                                ? "Check-in room(s)"
                                : `Release room${isSingleRoom ? "" : "(s)"}`}
                        </Heading>
                    </Column>
                </div>
            </Column>
            {isSingleRoom ? (
                <Row spacingInset="medium">
                    <Column>
                        <img
                            src={oopsEmojiSvg}
                            style={{ height: "70px", width: "65px" }}
                            alt="oops discard meeting icon"
                        />
                    </Column>
                    <Column spacing="none">
                        <Text type="b300">Are you sure you want to release this room?</Text>
                        <Text type="b300">This action cannot be undone.</Text>
                    </Column>
                </Row>
            ) : (
                <div style={{ padding: "10px" }}>
                    <Row
                        alignmentHorizontal="left"
                        spacingInset="medium medium xsmall medium"
                    >
                        <Text type="h300">{meeting.subject || "Untitled meeting"}</Text>
                    </Row>
                    <Column spacing="xsmall" spacingInset="medium">
                        <Text type="b300" alignment="left">
                            {getReadableDate(meeting.startTime)}
                        </Text>
                        <Text type="b300" alignment="left">
                            {meeting.isAllDayEvent
                                ? "All day event"
                                : `${formatDateTime(meeting.startTime, "time")} to
                                    ${formatDateTime(meeting.endTime, "time")}
                                    ${formatTimezoneName()}
                                    (${renderDurationLabel(meeting.startTime, meeting.endTime, TIME_CONSTANT.SEC_NAME)})`}
                        </Text>
                    </Column>
                    <Row spacingInset="none medium">
                        <Divider spacingBefore="xxsmall" spacingAfter="xxsmall" />
                    </Row>
                    <Row spacingInset="medium">
                        {isCheckIn ? (
                            <Text type="b300">Select rooms to check-in.</Text>
                        ) : (
                            <Text type="b300">Select rooms to release.</Text>
                        )}
                    </Row>
                    <Column spacingInset="none medium medium medium">
                        {meeting.resources.map((room) => {
                            const skylanternText = isCheckIn
                                ? roomCheckInStatusDictionary.get(`${room.email}&${meeting.time.startTime}`) &&
                                  room.response === ROOM_RESPONSE.ACCEPT
                                    ? CHECK_IN_ENABLE_MESSAGE
                                    : CHECK_IN_NOT_ENABLE_MESSAGE
                                : "";
                            return (
                                <Checkbox
                                    key={room.email}
                                    checked={
                                        selectedRooms.filter((res) => res.email === room.email)
                                            .length > 0
                                    }
                                    onChange={() => onSelectedRoomsChange(room)}
                                    disabled={skylanternText === CHECK_IN_NOT_ENABLE_MESSAGE}
                                >
                                    <Text
                                        color={skylanternText === CHECK_IN_NOT_ENABLE_MESSAGE ? "secondary" : "primary"}
                                    >{`${shortRoomName(room.displayName)} ${skylanternText}`}</Text>
                                </Checkbox>
                            );
                        })}
                    </Column>
                    <Row spacingInset="none none none medium">
                        <Text type="h100">NOTE:</Text>
                    </Row>
                    <Row spacingInset="none none none medium">
                        {isCheckIn ? (
                            <Text type="b300">
                                Unselected rooms will then be <b>released</b> for other
                                meetings.
                            </Text>
                        ) : (
                            <Text type="b300">
                                Select rooms to release. Released rooms will be freed-up for
                                other meetings.
                            </Text>
                        )}
                    </Row>
                    <Row spacingInset="none none none medium">
                        {isCheckIn ? (
                            <Text type="b300">
                                Without check-in confirmations, rooms requiring check-ins will
                                be released <b>{ROOM_RELEASE_THRESHOLD} minutes</b> after the meeting start time.
                            </Text>
                        ) : (
                            <Text type="b300">
                                Select rooms to release. Released rooms will be freed-up for
                                other meetings.
                            </Text>
                        )}
                    </Row>
                </div>
            )}
            <ModalFooter>
                <Row spacing="medium" alignmentHorizontal="right">
                    <Button
                        type="secondary"
                        size="medium"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        size="medium"
                        onClick={onClickConfirm}
                        disabled={!isSingleRoom && selectedRooms.length === 0}
                    >
                        {isCheckIn
                            ? "Check-in room(s)"
                            : isSingleRoom
                                ? "Yes, I'm sure"
                                : "Release room(s)"
                        }
                    </Button>
                </Row>
            </ModalFooter>
        </Modal>
    );
};

export default ReleaseOrCheckInMeetingModal;