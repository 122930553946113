import { call } from "redux-saga/effects";

const DEFAULT_CONFIG = { // Alpha Config
    "stage": "Alpha",
    "isProd": false,
    "distributionConfig": {
        "Region": "us-east-1",
        "IdentityPoolId": "us-east-1:9cbfa40d-0aca-4c38-a639-c0e1054b9f70",
        "IdentityProviderName": "cognito-idp.us-east-1.amazonaws.com/us-east-1_pg1ZrKlmd",
        "AccountId": "250142179699",
        "UserPoolId": "us-east-1_pg1ZrKlmd",
        "UserPoolAppId": "4sk5cb1fhafbkev38rn4njklqa"
    }
};

let stageConfig;

export function* getStageConfig() {
    if (!stageConfig) {
        const configUrl = `${process.env.PUBLIC_URL}/web-config.json`;
        let configResponse = yield call(fetchConfig, configUrl, DEFAULT_CONFIG);
        stageConfig = {
            ...configResponse.config,
            edgeLocation: configResponse.edgeLocation
        }
    }
    return stageConfig;
}

function* fetchConfig(url, defaultConfig) {
    return yield call(() => {
        return fetch(url)
            .then(async (response) => {
                return {
                    config: await response.json(),
                    edgeLocation: response.headers.get('x-amz-cf-pop')
                };
            })
            .catch(() => {
                console.log(`Failed to fetch config from ${url}. Setting default config.`);
                return defaultConfig;
            });
    });
}