import React, { useMemo } from "react"
import { css } from "emotion"
import PropTypes from "prop-types"
import { memoizeTokenStyles } from "../../_utils/token"

// All of the complicated CSS here is required in order to make the characters
// in the placeholder (e.g. Y, M, D, -, and separators) line up with the numeric
// characters in the user's input, which do not have the same character widths.
const placeholderStyles = memoizeTokenStyles(
  (t, { disabled }) =>
    css({
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: "none",
      color: t("inputPlaceholderColor", disabled ? "disabled" : "default"),
      whiteSpace: "pre",
      // Apply the same letter spacing that the input has to the inline blocks
      // that hold each placeholder character
      "& > * + *": {
        marginLeft: t("inputValueLetterSpacing"),
      },
    }),
  ["disabled"]
)

const placeholderCharStyles = memoizeTokenStyles(
  (t, { placeholderChar, empty, separator, actualChar }) =>
    css(
      {
        position: "relative",
        display: "inline-block",
        // This pseudo element reserves an approximate amount of space for the
        // placeholder character that an actual character in the input would take
        // up. It does this by using for its contents the actual character (if
        // filled) or a "0" when it is placeholding for empty characters (all digit
        // chars are the same width) or an identical separator char (e.g. a ":"
        // for US times) when it lines up with one of those. This ensures that the
        // placeholder characters line up fully with the input characters, even
        // if they have different character widths.
        "&:before": {
          color: "transparent",
          content: separator
            ? `'${placeholderChar}'`
            : empty
            ? `'0'`
            : `'${actualChar}'`,
        },
      },
      // For non-separator characters use the after psuedo element to show a shrunk-
      // down  version of the character exactly centered in the character box where
      // the corresponding input char would be rendered. This allows a placeholder
      // character that is much wider than a digit (e.g. the "M") to still line
      // up with the digit.
      separator
        ? undefined
        : {
            "&:after": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateY(-50%) translateX(-50%)",
              opacity: empty ? 1 : 0,
              content: `'${placeholderChar}'`,
              fontSize: t("inputPlaceholderFontSize"),
              lineHeight: t("inputPlaceholderLineHeight"),
            },
          }
    ),
  ["placeholderChar", "empty", "separator", "actualChar"]
)

const InputMonospacePlaceholder = props => {
  const { t, mask, disabled, value } = props
  // If given a display format (like "YYYY/MM/DD") via props, use that.
  // Otherwise, construct from the mask replacing all non-literals with "–"
  const displayFormat = useMemo(
    () => props.displayFormat || mask.mask.replace(/[#*]/gi, "–"),
    [mask, props.displayFormat]
  )
  return (
    <div className={placeholderStyles(t, { disabled })}>
      {displayFormat.split("").map((_, index) => {
        const placeholderChar = displayFormat[index]
        const empty = !value[index] || value[index] === mask.placeholderChar
        const separator = !mask.formatChars[mask.mask[index]]
        const actualChar = value[index]
        return (
          <div
            key={index}
            className={placeholderCharStyles(t, {
              placeholderChar,
              empty,
              separator,
              actualChar,
            })}
          />
        )
      })}
    </div>
  )
}

InputMonospacePlaceholder.propTypes = {
  mask: PropTypes.object.isRequired,
  /**
   * Tokens should have the following keys (values can obviously vary)
   * input: {
   *   valueLetterSpacing: "2px",
   *   placeholder: {
   *     fontSize: "13px",
   *     lineHeight: "18px",
   *     color: {
   *       default: "theme-foreground-secondary-default",
   *       disabled: "theme-foreground-primary-disabled",
   *     },
   *   },
   * }
   **/
  t: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  displayFormat: PropTypes.string,
}

export default InputMonospacePlaceholder
