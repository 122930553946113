module.exports = {
    LIVESTREAM_BUILDING_CODE: "JLT21", // Livestream station emails are stored in a building (retrieveable with getConfRoomWithQueryString)
    LIVESTREAM_EXCLUDED_STATION_EMAILS: [ // Global back-up units which should not be referenced for availability
        "JLT21-AP.07@amazon.com",
        "JLT21-AP.08@amazon.com",
        "JLT21-AP.14@amazon.com",
        "JLT21-AP.15@amazon.com",
        "JLT21-AP.16@amazon.com",
        "JLT21-EU.01@amazon.com",
        "JLT21-EU.02@amazon.com",
        "JLT21-EU.05@amazon.com",
        "JLT21-EU.06@amazon.com",
        "JLT21-EU.16@amazon.com",
        "JLT21-EU.17@amazon.com",
        "JLT21-EU.18@amazon.com",
        "JLT21-EU.19@amazon.com",
        "JLT21-EU.20@amazon.com",
        "JLT21-US.09@amazon.com",
        "JLT21-US.10@amazon.com",
        "JLT21-US.21@amazon.com",
    ],
    LIVESTREAM_STATION_PAIRS: {
        // APJC
        "JLT21-AP.09@amazon.com": "JLT21-AP.10@amazon.com",
        "JLT21-AP.11@amazon.com": "JLT21-AP.12@amazon.com",
        "JLT21-AP.13@amazon.com": "JLT21-AP.14@amazon.com",
        // INDIA
        "JLT21-AP.01@amazon.com": "JLT21-AP.02@amazon.com",
        "JLT21-AP.03@amazon.com": "JLT21-AP.04@amazon.com",
        "JLT21-AP.05@amazon.com": "JLT21-AP.06@amazon.com",
        // EMEA
        "JLT21-EU.03@amazon.com": "JLT21-EU.04@amazon.com",
        "JLT21-EU.07@amazon.com": "JLT21-EU.08@amazon.com",
        "JLT21-EU.09@amazon.com": "JLT21-EU.10@amazon.com",
        "JLT21-EU.12@amazon.com": "JLT21-EU.13@amazon.com",
        "JLT21-EU.14@amazon.com": "JLT21-EU.15@amazon.com",
        // AMER
        "JLT21-US.01@amazon.com": "JLT21-US.02@amazon.com",
        "JLT21-US.03@amazon.com": "JLT21-US.04@amazon.com",
        "JLT21-US.05@amazon.com": "JLT21-US.06@amazon.com",
        "JLT21-US.07@amazon.com": "JLT21-US.08@amazon.com",
        "JLT21-US.11@amazon.com": "JLT21-US.12@amazon.com",
        "JLT21-US.13@amazon.com": "JLT21-US.14@amazon.com",
        "JLT21-US.15@amazon.com": "JLT21-US.16@amazon.com",
        "JLT21-US.17@amazon.com": "JLT21-US.18@amazon.com",
        "JLT21-US.19@amazon.com": "JLT21-US.20@amazon.com",
        "JLT21-US.22@amazon.com": "JLT21-US.23@amazon.com",
        "JLT21-US.24@amazon.com": "JLT21-US.25@amazon.com",
        "JLT21-US.26@amazon.com": "JLT21-US.27@amazon.com",
        "JLT21-US.28@amazon.com": "JLT21-US.29@amazon.com",
        "JLT21-US.30@amazon.com": "JLT21-US.31@amazon.com",
    },
    LIVESTREAM_PREPARATION_MINUTES: 30,
    STEP: {
        EVENT_REQUIREMENTS: "EVENT_REQUIREMENTS",
        ATTENDEES_AND_ROOMS: "ATTENDEES_AND_ROOMS",
        EVENT_SCHEDULE: "EVENT_SCHEDULE",
        EVENT_AGENDA_AND_REVIEW: "EVENT_AGENDA_AND_REVIEW",
    },
    STEP_LABEL: {
        EVENT_REQUIREMENTS: "Event requirements",
        ATTENDEES_AND_ROOMS: "Attendees and rooms",
        EVENT_SCHEDULE: "Event schedule",
        EVENT_AGENDA_AND_REVIEW: "Event agenda and review",
    },
    VIEWER_COUNT_OPTION: {
        "1-249": "1 - 249",
        "250-999": "250 - 999",
        "1000-4999": "1,000 - 4,999",
        "5000-9999": "5,000 - 9,999",
        "10000-49999": "10,000 - 49,999",
        "50000-100000": "50,000 - 100,000",
        "100000+": "100,000+",
    },
    EVENT_TYPE: {
        LIVE: "Live event",
        EVENT_PLAYBACK: "Event playback",
        RECORDING: "Recording only",
    },
    EVENT_PLATFORM: {
        HUBBLE: {
            LABEL: "livestream.amazon.dev",
            AVAILABILITY: "Internal",
        },
        TWITCH: {
            LABEL: "Twitch",
            AVAILABILITY: "Public",
        },
        TWITTER: {
            LABEL: "X (Twitter)",
            AVAILABILITY: "Public",
        },
        YOUTUBE: {
            LABEL: "YouTube",
            AVAILABILITY: "Public",
        },
        FACEBOOK: {
            LABEL: "Facebook",
            AVAILABILITY: "Public",
        },
    },
    RECORDING_TYPE: {
        BROADCAST: "Broadcast upload",
        PRIVATE_DRIVE: "30-day private drive",
        NONE: "No",
    },
    CLOSED_CAPTIONING_OPTIONS: {
        DISCLAIMER: "Display a disclaimer on event description",
        NEED_STENOGRAPHER: "Hire a stenographer",
    },
    EVENT_LOCATION: {
        REMOTE_OR_VIRTUAL: "Remote/virtual",
        MEETING_ROOM: "Amazon AV/VC meeting room",
        OFF_CAMPUS: "Off campus",
    },
    SIGN_LANGUAGE_INTERPRETER_OPTION: {
        BOOKING: "Yes",
        NONE: "No",
        HELP_BOOKING: "I need help booking one",
    },
    CONTACT_CHANNEL: {
        EMAIL: "Email",
        SLACK: "Slack",
        CHIME: "Chime",
        PHONE: "Phone",
    },
    AGENDA_GROUP: {
        PRESENTERS: "Presenters",
        VIEWERS: "Viewers",
    },
    LIVESTREAM_ENDPOINT: "https://livestream.amazon.dev",
    LIVESTREAM_SUPPORT_HREF: "https://w.amazon.com/bin/view/Livestream_Support",
    LIVESTREAM_REPORT_HREF: "https://t.corp.amazon.com/create/templates/f905112e-05e2-48b0-9c26-5ba66b7484eb",
    LIVESTREAM_URL_CHARACTER_LIMIT: 100, // matches character limit for Gather (https://issues.amazon.com/issues/MEETEX-6319)
    LIVESTREAM_FAILED_TICKET_LINK_NAME: "livestream-failed-ticket-permalink",
    LIVESTREAM_SYSTEM_ERROR: "livestream-system-error",
    LIVESTREAM_NO_STATIONS_LINK_NAME: "no-livestream-stations-permalink",
    LIVESTREAM_NO_STATIONS_TOAST_ID: "LIVESTREAM_NO_STATIONS_TOAST_ID",
    SUGGESTION_WIDTH: 1100,
    LIVESTREAM_URL_PATH_PARAMETER: "/#/live/",
    LIVESTREAM_PERMALINK_DETAILS: {
        NO_STATIONS_TITLE: "This ticket was created via permalink.",
        NO_STATIONS: "An event time was selected with no Livestream engineers available. Your Livestream can't be scheduled until a member of our Livestream Team has confirmed availability.",
        CREATE_TICKET_ERROR: "This ticket was created via permalink. An issue occurred during Livestream intake, check to see if calendar invites were successfully created. If not, manual calendar updates need to be sent.",
    },
    LIVESTREAM_ALERTS: {
        // Error alerts
        NO_EVENT_NAME: {
            "type": "error",
            "value": "Event name is required.",
            "id": "amw-no-event-name",
        },
        NO_LIVESTREAM_TITLE: {
            "type": "error",
            "value": "Livestream title is required.",
            "id": "amw-no-livestream-title",
        },
        INVALID_EVENT_NAME: {
            "type": "error",
            "value": "Event name cannot exceed 100 characters.",
            "id": "amw-invalid-event-name",
        },
        INVALID_LIVESTREAM_TITLE: {
            "type": "error",
            "value": "Livestream title cannot exceed 100 characters.",
            "id": "amw-invalid-livestream-title",
        },
        NO_VIEWER_JUSTIFICATION: {
            "type": "error",
            "value": "A justification for creating this event is required.",
            "id": "amw-no-viewer-justification",
        },
        NO_EXPECTED_VIEWER_COUNT: {
            "type": "error",
            "value": "Expected viewer count is required.",
            "id": "amw-no-viewer-justification",
        },
        NO_RESTRICTED_GROUPS_ADDED: {
            "type": "error",
            "value": "Add at least one restricted group for this event or disable this option.",
            "id": "amw-no-restricted-groups-added",
        },
        NO_PLATFORMS_SELECTED: {
            "type": "error",
            "value": "Select at least one streaming platform.",
            "id": "amw-no-platforms-selected",
        },
        NO_PREVIOUS_BROADCAST_URL: {
            "type": "error",
            "value": "Broadcast channel URL is required.",
            "id": "amw-no-previous-broadcast-url",
        },
        INVALID_EVENT_DESCRIPTION: {
            "type": "error",
            "value": "Event description is required.",
            "id": "amw-invalid-event-description",
        },
        EXISTING_LIVESTREAM_URL: {
            "NO_URL_PROVIDED": {
                "type": "error",
                "value": "Existing livestream URL is required.",
                "id": "amw-no-existing-livestream-url",
            },
            "SERIES_NOT_EXISTS": {
                "type": "error",
                "value": "Livestream URL is not found.",
                "id": "amw-series-not-exists",
            },
            "EVENT_NOT_EXISTS": {
                "type": "warning",
                "value": "No event details found for the existing livestream URL.",
                "id": "amw-event-not-exists",
            },
            "SERIES_URL_MISMATCH": {
                "type": "warning",
                "value": "Livestream URL is not found. If you wish to save as a new livestream, select No existing URL.",
                "id": "amw-series-url-mismatch",
            },
        },
        DESIRED_LIVESTREAM_URL: {
            "INVALID_URL_PROVIDED": {
                "type": "error",
                "value": "This field contains an invalid character. Only alphanumeric characters, “_” and  “-” are allowed.",
                "id": "amw-invalid-desired-livestream-url",
            },
            "INVALID_URL_LENGTH": {
                "type": "error",
                "value": "Desired livestream url cannot exceed 100 characters.",
                "id": "amw-invalid-desired-livestream-url-length",
            },
            "NO_URL_PROVIDED": {
                "type": "error",
                "value": "Desired livestream URL is required.",
                "id": "amw-no-desired-livestream-url",
            },
            "URL_ALREADY_EXISTS": {
                "type": "error",
                "value": "Livestream URL already in use.",
                "id": "amw-desired-livestream-url-already-exists",
            },
        },
        NO_POINT_OF_CONTACTS_ADDED: {
            "type": "error",
            "value": "Add at least one point of contact for this event.",
            "id": "amw-no-point-of-contacts-added",
        },
        NO_CONTACT_CHANNELS_SELECTED: {
            "type": "error",
            "value": "Select at least one method to be contacted by.",
            "id": "amw-no-contact-channels-selected",
        },
        NO_PHONE_NUMBER: {
            "type": "error",
            "value": "Phone number is required.",
            "id": "amw-no-phone-number",
        },
        NO_SLACK_WORKSPACE: {
            "type": "error",
            "value": "Slack Workspace is required.",
            "id": "amw-no-slack-workspace",
        },
        SLACK_CHANNEL: {
            "NO_SLACK_CHANNEL": {
                "type": "error",
                "value": "Slack Channel is required.",
                "id": "amw-no-slack-channel",
            },
            "INVALID_SLACK_CHANNEL": {
                "type": "error",
                "value": "This field contains an invalid character. Only alphanumeric characters, “_” and “-” are allowed.",
                "id": "amw-invalid-slack-channel",
            },
        },
        LIVESTREAM_PROCESSING_CREATE_MEETING: {
            "type": "informational",
            "value": "Processing",
            "id": "amw-meeting-create-meeting-processing-id",
        },
    },
    NO_SUGGESTIONS_FOUND: "No suggestions found.",
};
