import {
    CLEAR_SUGGESTIONS,
    GET_SUGGESTIONS,
    SAVE_SUGGESTIONS,
    SET_SUGGESTIONS_STATE,
    UPDATE_AVAILABILITY_LIST,
    SAVE_CACHED_AVAILABILITY,
    DELETE_FROM_AVAILABILITY_LIST
} from "../actions/action-types";

const initialState = {
    suggestionList: [],
    cachedAvailabilityList: [],
    isSearching: false
};

const suggestionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_SUGGESTIONS:
            return {
                ...state,
                isSearching: false,
                suggestionList: action.suggestions.suggestionList
            };
        case GET_SUGGESTIONS:
            return {
                ...state,
                isSearching: true,
            };
        case CLEAR_SUGGESTIONS:
            return {
                ...state,
                isSearching: false,
                suggestionList: []
            };
        case SET_SUGGESTIONS_STATE:
            return {
                ...state,
                isSearching: action.isSearching
            };
        case SAVE_CACHED_AVAILABILITY:
            return {
                ...state,
                cachedAvailabilityList: action.suggestions.cachedAvailabilityList
            };
        case UPDATE_AVAILABILITY_LIST:
            return {
                ...state,
                cachedAvailabilityList:
                    state.cachedAvailabilityList.map((room) =>
                        room.email === action.email ? {
                                ...room,
                                availabilities: room.availabilities.concat([action.suggestion])
                            }
                            :
                            room
                    )
            };
        case DELETE_FROM_AVAILABILITY_LIST:
            return {
                ...state,
                cachedAvailabilityList:
                    state.cachedAvailabilityList.map((room) =>
                        room.email === action.email ? {
                                ...room,
                                availabilities: room.availabilities.filter((availability) => (!(availability.time.startTime === action.time.startTime && availability.time.endTime === action.time.endTime)))
                            }
                            :
                            room
                    )
            }
        default:
            return state;
    }
};

export default suggestionReducer;
