const locationsNamespace = "locations";

export const LOAD_BUILDINGS = `${locationsNamespace}.load_buildings`;
export const SAVE_BUILDINGS = `${locationsNamespace}.save_buildings`;
export const LOAD_FLOORS = `${locationsNamespace}.load_floors`;
export const SAVE_FLOORS = `${locationsNamespace}.save_floors`;
export const SET_LOAD_FLOORS_STATUS = `${locationsNamespace}.set_load_floors_status`;

export const UPDATE_FIND_ROOMS = `${locationsNamespace}.update_find_rooms`;
export const ADD_ROOM = `${locationsNamespace}.add_room`;
export const REMOVE_ROOM = `${locationsNamespace}.remove_room`;
export const ADD_LOCATION = `${locationsNamespace}.add_location`;
export const REMOVE_LOCATION = `${locationsNamespace}.remove_location`;
export const ADD_ROOM_BY_EMAIL = `${locationsNamespace}.add_room_by_email`;
export const CLEAR_LOCATIONS_AND_ROOMS = `${locationsNamespace}.clear_locations_and_rooms`;