import React, { useEffect } from "react";

import Row from "@amzn/meridian/row";
import Responsive from "@amzn/meridian/responsive";

import ImageCard from "../components/image-card";

import oopsEmojiSvg from "../../../assets/icons/shared/oops_emoji.svg";
import PatternBackground from "../../../assets/backgrounds/pattern_small_vertical_repeating.svg";

import { BACKGROUND_OPACITY, ERROR, SCREEN_SIZE } from "../../shared/shared-constants";

const Error404 = (props) => {
    const mastheadSize = props.mastheadSize;

    // If the user is accessing an meeting link from the legacy website, reformat the URL to the correct URL for 2.0 for Edit Meeting and Query Params
    useEffect(() => {
        let url = window.location.href;
        if (url.includes("meeting/new?")) {
            if (!url.includes("getSuggestions")) {
                url = url.replace("meeting/new?", "edit-meeting/");
            } else {
                url = url.replace("meeting/new", "create-meeting");
                url = url.replace("getSuggestions", "");
            }
            window.location.replace(url);
        }
    }, []);

    return (
        <Responsive query="min-width" props={{
            screenSizeBreakpoint: {
                default: SCREEN_SIZE.MOBILE_VIEW,
                "900px": SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND,
            },
        }}>
            {(responsiveProps) => (
                <Row widths={["fill", "fit", "fill"]} alignmentVertical="top">
                    {responsiveProps.screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                        <div style={{
                            height: `calc(100vh - ${mastheadSize})`,
                            backgroundImage: `url(${PatternBackground})`,
                            backgroundRepeat: "repeat-y",
                            backgroundPosition: "left -250px center",
                            backgroundAttachment: "fixed",
                            opacity: BACKGROUND_OPACITY,
                        }} />
                    }
                    <ImageCard
                        imgSrc={oopsEmojiSvg}
                        errorType={ERROR.NOT_FOUND.VALUE}
                        titleText={ERROR.NOT_FOUND.TITLE}
                        subtitleText={ERROR.NOT_FOUND.SUBTITLE}
                    />
                    {responsiveProps.screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND &&
                        <div style={{
                            height: `calc(100vh - ${mastheadSize})`,
                            backgroundImage: `url(${PatternBackground})`,
                            backgroundRepeat: "repeat-y",
                            backgroundPosition: "right -250px center",
                            backgroundAttachment: "fixed",
                            opacity: BACKGROUND_OPACITY,
                        }} />
                    }
                </Row>
            )}
        </Responsive>
    );
};

export default Error404;