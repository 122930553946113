const peopleNamespace = "people";

export const ADD_ATTENDEE = `${peopleNamespace}.add_attendee`;
export const ADD_EXTERNAL_ATTENDEE = `${peopleNamespace}.add_external_attendee`;
export const UPDATE_ATTENDEE = `${peopleNamespace}.update_attendee`;
export const UPDATE_EXTERNAL_ATTENDEE = `${peopleNamespace}.update_external_attendee`;
export const REMOVE_ATTENDEE = `${peopleNamespace}.remove_attendee`;
export const REMOVE_EXTERNAL_ATTENDEE = `${peopleNamespace}.remove_external_attendee`;
export const CLEAR_ATTENDEES = `${peopleNamespace}.clear_attendees`;
export const CLEAR_DRAFT = `${peopleNamespace}.clear_draft`;


export const GET_PEOPLE_SUGGESTIONS = `${peopleNamespace}.get_people_suggestions`;
export const SAVE_PEOPLE_SUGGESTIONS = `${peopleNamespace}.save_people_suggestions`;
export const CLEAR_PEOPLE_SUGGESTIONS = `${peopleNamespace}.clear_people_suggestions`;

export const GET_GROUP_SUGGESTIONS = `${peopleNamespace}.get_group_suggestions`;
export const SAVE_GROUP_SUGGESTIONS = `${peopleNamespace}.save_groups_suggestions`;
export const CLEAR_GROUP_SUGGESTIONS = `${peopleNamespace}.clear_group_suggestions`;

export const GET_RAS_DATA_FOR_PERSON = `${peopleNamespace}.get_ras_data_for_person`;
export const GET_RAS_DATA_FOR_PERSON_AND_ADD_TO_FAVORITE = `${peopleNamespace}.get_ras_data_for_person_and_add_to_favorite`;
export const UPDATE_PERSON_ATTENDEE_WITH_RAS_DATA = `${peopleNamespace}.update_person_attendee_with_ras_data`;

export const GET_RAS_DATA_FOR_GROUP = `${peopleNamespace}.get_ras_data_for_group`;
export const UPDATE_GROUP_ATTENDEE_WITH_RAS_DATA = `${peopleNamespace}.update_group_attendee_with_ras_data`;

export const ADD_ATTENDEE_BY_EMAIL = `${peopleNamespace}.add_attendee_by_email`;
export const ADD_ATTENDEE_AND_FETCH_WORKING_HOURS = `${peopleNamespace}.add_attendee_and_fetch_working_hours`;
export const CACHE_ATTENDEE_WORKING_HOURS = `${peopleNamespace}.cache_attendee_working_hours`;