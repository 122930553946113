
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import differenceInDays from "date-fns/differenceInDays";
import getDaysInMonth from "date-fns/getDaysInMonth";
import format from "date-fns/format";

import { ATTENDEE_AVAILABILITY, DAYS_OF_WEEK } from "./meeting-scheduler-constants";

// Convert epoch seconds to various time string formats
export const convertEpoch = (epoch, conversion, timezone = undefined, is12HourTimeFormat = true, locale = "en-US") => {
    let date = new Date(0);
    date.setUTCSeconds(epoch);

    switch (conversion) {
        case "date": // 12/31/2021
            return date.toLocaleString(locale, {
                timeZone: timezone,
                year: "numeric",
                month: "numeric",
                day: "numeric"
            });
        case "longWeekdayDateYear": // Friday, December 31, 2021
            return date.toLocaleString(locale, {
                timeZone: timezone,
                year: "numeric",
                month: "long",
                day: "numeric",
                weekday: "long"
            });
        case "shortWeekdayDateYear": // Fri, Dec 31, 2021
            return date.toLocaleString(locale, {
                timeZone: timezone,
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short"
            });
        case "longWeekdayDate": // Friday, December 31
            return date.toLocaleString(locale, {
                timeZone: timezone,
                month: "long",
                day: "numeric",
                weekday: "long"
            });
        case "shortWeekdayDate": // Fri, Dec 31
            return date.toLocaleString(locale, {
                timeZone: timezone,
                month: "short",
                day: "numeric",
                weekday: "short"
            });
        case "longDateYear": // December 31, 2021
            return date.toLocaleString(locale, {
                timeZone: timezone,
                year: "numeric",
                month: "long",
                day: "numeric",
            });
        case "shortDateYear": // Dec 31, 2021
            return date.toLocaleString(locale, {
                timeZone: timezone,
                year: "numeric",
                month: "short",
                day: "numeric",
            });
        case "longDate": // December 31
            return date.toLocaleString(locale, {
                timeZone: timezone,
                month: "long",
                day: "numeric",
            });
        case "shortDate": // Dec 31
            return date.toLocaleString(locale, {
                timeZone: timezone,
                month: "short",
                day: "numeric",
            });
        case "year": // 2021
            return date.toLocaleString(locale, {
                timeZone: timezone,
                year: "numeric"
            });
        case "time": // 12:00 AM
            return date.toLocaleString(locale, {
                timeZone: timezone,
                hour: "numeric",
                minute: "numeric",
                hour12: is12HourTimeFormat
            });
        case "hour": // 24:00:00
            let hourString = date.toLocaleString(locale, {
                timeZone: timezone,
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: is12HourTimeFormat
            });
            if (hourString.startsWith("24")) {
                hourString = "00" + hourString.substring(2);
            }
            return hourString;
        case "shortMonth": // Dec
            return date.toLocaleString(locale, {
                timeZone: timezone,
                month: "short",
            });
        case "longWeekday": // Friday
            return date.toLocaleString(locale, {
                timeZone: timezone,
                weekday: "long"
            });
        case "shortWeekday": // Fri
            return date.toLocaleString(locale, {
                timeZone: timezone,
                weekday: "short"
            });
        case "dayOfDate": // 25
            return date.toLocaleString(locale, {
                timeZone: timezone,
                day: "numeric"
            });
        default:
            return null;
    }
};

// Returns the ordinal date based on the given number
export const getOrdinalDay = (day) => {
    if (day > 3 && day < 21) {
        return day + "th";
    }

    switch (day % 10) {
        case 1: return day + "st";
        case 2: return day + "nd";
        case 3: return day + "rd";
        default: return day + "th";
    }
};

// Return a string with a range of days when consecutive, and otherwise a comma separated list of days
export const getDaysOfWeekLabel = (days, abbreviated = true) => {
    if (days.length > 1) {
        let allDaysOfWeek = abbreviated ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let daysOfWeekLabel = [];

        allDaysOfWeek.forEach((day) => {
            if (days.includes(day)) {
                daysOfWeekLabel.push(day);
            }
        });

        let consecutive = true;
        for (let i = 0; i < daysOfWeekLabel.length - 1; i++) {
            if (allDaysOfWeek.indexOf(daysOfWeekLabel[i + 1]) - allDaysOfWeek.indexOf(daysOfWeekLabel[i]) > 1) {
                consecutive = false;
                break;
            }
        }

        if (consecutive) {
            return daysOfWeekLabel[0] + (abbreviated ? " - " : " to ") + daysOfWeekLabel[daysOfWeekLabel.length - 1];
        } else {
            return daysOfWeekLabel.join(", ");
        }
    } else if (days.length === 1) {
        return days[0];
    } else {
        return "";
    }
};

// Update the days of week label based on the selected days
export const getMeetingDaysLabel = (days, repeat, setDaysOfWeekLabel) => {
    let label = "Only on ";
    if (repeat !== "none") {
        label = "First meeting on ";
    }

    let daysOfWeekLabel = getDaysOfWeekLabel(days);

    if (daysOfWeekLabel) {
        setDaysOfWeekLabel(label + getDaysOfWeekLabel(days));
    } else {
        setDaysOfWeekLabel("No days of week selected");
    }
};

// Sorts an array of rooms alphabetically by name/display name
export const sortRoomsByName = (rooms) => {
    return rooms.sort((room1, room2) => {
        let name1 = room1.name || room1.displayName;
        let name2 = room2.name || room2.displayName;

        return name1 > name2 ? 1 : -1;
    });
};

// Get the inputted duration for the meeting in hours
export const getDuration = (durationSelected, customDurationValue, customDurationSelected) => {
    if (durationSelected === "all day" || durationSelected === "custom") {
        if (customDurationSelected === "min") {
            return (parseInt(customDurationValue) / 60) || 0;
        } else if (customDurationSelected === "hour") {
            return parseFloat(customDurationValue) || 0;
        } else if (customDurationSelected === "day") {
            return (parseFloat(customDurationValue) * 24) || 0;
        }
    } else {
        return durationSelected / 60;
    }
};

// Get an array of days of week from a daysOfWeek recurrence object
export const getRecurrenceDaysOfWeek = (daysOfWeek) => {
    let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    return days.filter((day) => daysOfWeek[day]);
};

// Convert array of day of week strings to integers
export const convertDaysOfWeek = (daysOfWeek) => {
    let days = [];

    daysOfWeek.forEach((day) => {
        days.push(DAYS_OF_WEEK.indexOf(day) % 7);
    });

    return days;
};

// Convert string for week of month to integer
export const convertWeekOfMonth = (weekOfMonth) => {
    switch (weekOfMonth) {
        case "first":
            return 1;
        case "second":
            return 2;
        case "third":
            return 3;
        case "fourth":
            return 4
        default:
            return weekOfMonth;
    }
};

// Get the next specified day of week a number of weeks forward from a given date
export const getNextDayOfWeek = (date, dayOfWeek, weeksForward = 1) => {
    let nextDate = new Date(date.getTime());
    nextDate.setDate(date.getDate() + (((7 + dayOfWeek - date.getDay()) % 7) || 7));
    nextDate = addDays(nextDate, 7 * (weeksForward - 1));
    return nextDate;
};

// Get the date for a specified day of week a number of weeks back from a given date
// Note: if the dayOfWeek is the same as the date's day of week, the starting date will be returned
export const getPrevDayOfWeek = (date, dayOfWeek, weeksBack = 1) => {
    let prevDate = getNextDayOfWeek(date, dayOfWeek);
    prevDate.setDate(prevDate.getDate() - (7 * weeksBack));
    return prevDate;
};

// Check if the given date is equal to the correct day
// Helper for getNthDateOfMonth
const checkNthDate = (nthDate, type, dayOfWeek) => {
    switch (type) {
        case "dayOfWeek":
            return nthDate.getDay() === dayOfWeek;
        case "weekday":
            return nthDate.getDay() !== 0 && nthDate.getDay() !== 6;
        case "weekend":
        case "weekendDay":
        default:
            return nthDate.getDay() === 0 || nthDate.getDay() === 6;
    }
};

// Get the nth specified day of week/weekday/weekend within the month of a given date
// n will be an integer from 1-4 (first, second, third, fourth) or the string "last"
export const getNthDateOfMonth = (date, n, type, dayOfWeek) => {
    if (type === "day") {
        if (n === "last") {
            return new Date(date.getFullYear(), date.getMonth() + 1, 0); // last day of month
        } else {
            return new Date(date.getFullYear(), date.getMonth(), n); // nth day of month
        }
    }

    if (DAYS_OF_WEEK.includes(type)) {
        type = "dayOfWeek";
    }

    if (n === "last") {
        // Find last date type in month
        if (type === "dayOfWeek") {
            let lastDayOfWeek = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            return getPrevDayOfWeek(lastDayOfWeek, dayOfWeek);
        } else if (type === "weekday") {
            let lastWeekday = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            // find nearest weekday if last date is on a weekend
            if (lastWeekday.getDay() === 0) {
                lastWeekday.setDate(lastWeekday.getDate() - 2);
            } else if (lastWeekday.getDay() === 6) {
                lastWeekday.setDate(lastWeekday.getDate() - 1);
            }

            return lastWeekday;
        } else if (type === "weekend" || type === "weekendDay") {
            let lastWeekend = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            // if last date is on a weekday, find the previous Sunday
            if (lastWeekend.getDay() !== 0 && lastWeekend.getDay() !== 6) {
                lastWeekend.setDate(lastWeekend.getDate() - lastWeekend.getDay());
            }

            return lastWeekend;
        }
    } else {
        // Find nth date type in month
        let nthDate = new Date(date.getFullYear(), date.getMonth(), 1);
        let count = 0;

        while (count < n && nthDate.getMonth() === date.getMonth() && !(checkNthDate(nthDate, type, dayOfWeek) && ++count === n)) {
            // loop to find the correct nth date
            nthDate.setDate(nthDate.getDate() + 1);
        }

        return nthDate;
    }
};

// Count the number of specific days of the week between two dates
// https://stackoverflow.com/questions/25562173/calculate-number-of-specific-weekdays-between-dates
export const countDaysOfWeek = (startDate, endDate, daysOfWeek) => {
    const daysBetween = differenceInDays(endDate, startDate) + 1;

    const sum = (a, b) => {
        return a + Math.floor((daysBetween + (startDate.getDay() + 6 - b) % 7) / 7);
    };

    return daysOfWeek.reduce(sum, 0)
};

// Count the number of days a weekly recurring series will repeat between two dates
export const countWeeklyRecurrenceDays = (startDate, endByDate, interval, daysOfWeek) => {
    const newStartDate = getNextDayOfWeek(startDate, 0); // get first Sunday after start date
    const firstWeekEndDate = endByDate.getTime() <= getNextDayOfWeek(startDate, 6).getTime() ? endByDate : getNextDayOfWeek(startDate, 6);

    // calculate the number of meetings in the first week
    const firstWeekOccurrences = startDate.getDay() !== 6 ? countDaysOfWeek(startDate, firstWeekEndDate, daysOfWeek) : 1;

    let remainingOccurrences = 0;
    if (newStartDate <= endByDate) {
        daysOfWeek.forEach((day) => {
            // add number of times the series will repeat on the specific day of week based on how many times it occurs in the range
            let daysInRange = countDaysOfWeek(newStartDate, endByDate, [day]);
            remainingOccurrences += Math.floor(daysInRange / interval);
        });
    }

    return firstWeekOccurrences + remainingOccurrences;
};

/**
 * Returns a date object for when a weekly recurring series ends
 *
 * @param {Date object} startDate - Date the weekly series starts
 * @param {int} occurrences - Number of meetings a recurring series has
 * @param {int} interval - How often the weekly series repeats (i.e. will repeat every X weeks)
 * @return {Array} daysOfWeek - Array of integers for which days of week the series repeats on
 */
export const getWeeklyRecurrenceEndDate = (startDate, occurrences, interval, daysOfWeek) => {
    if (occurrences && daysOfWeek && daysOfWeek.length !== 0) {
        let remainingOccurrences = new Array(daysOfWeek.length).fill(0); // initialize array of 0s to track how many times each day of week repeats

        let firstDayIndex = daysOfWeek.indexOf(startDate.getDay());
        let dayIncrement = 1;
        // if the startDate is not one of the days of week, find the next possible day of week the series can start on
        while (firstDayIndex === -1) {
            firstDayIndex = daysOfWeek.indexOf(addDays(startDate, dayIncrement).getDay());
            dayIncrement++;
        }

        let lastDayOfWeek = daysOfWeek[(occurrences + firstDayIndex - 1) % daysOfWeek.length];

        // determine how many occurrences are remaining for each day of week
        for (let i = firstDayIndex; i < occurrences + firstDayIndex; i++) {
            remainingOccurrences[i % daysOfWeek.length]++;
        }

        // determine how many weeks in the future the last occurrence is
        let weeksForward = remainingOccurrences[daysOfWeek.indexOf(lastDayOfWeek)];
        weeksForward = startDate.getDay() === lastDayOfWeek ? (weeksForward - 1) * interval : weeksForward * interval;

        return getNextDayOfWeek(startDate, lastDayOfWeek, weeksForward);
    }
};

// Return an object containing the last date and number of occurrences for a recurring series
export const getRecurrenceEndInfo = (recurrence, startTime) => {
    if (!recurrence || !startTime || !recurrence.interval) {
        return;
    }

    // Only one of these variables will be defined since the user chooses which one the series uses
    const endByDate = recurrence.endByDate ? new Date(recurrence.endByDate * 1000) : undefined;
    const endAfterNOccurrences = recurrence.endAfterNOccurrences;

    if (endByDate === undefined && endAfterNOccurrences === undefined) {
        return {};
    }

    const formatDate = (date) => format(date, "MMM d, yyyy");
    const repeatSelected = recurrence.type;
    const startDate = new Date(startTime * 1000);
    const interval = recurrence.interval;
    const daysOfWeek = convertDaysOfWeek(getRecurrenceDaysOfWeek(recurrence.daysOfWeek));
    const monthRepeat = recurrence.monthlyType === "absolute" ? "day" : convertWeekOfMonth(recurrence.weekOfMonth);
    const monthOption = recurrence.monthlyType === "absolute" ? recurrence.dayOfMonth : recurrence.dayOfWeek;

    const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const endBy = endByDate ? new Date(endByDate.getFullYear(), endByDate.getMonth(), endByDate.getDate()) : undefined;
    let occurrences = endAfterNOccurrences;
    let lastDate;

    if (repeatSelected === "day" || repeatSelected === "daily") {
        if (endByDate) {
            // calculate the number of occurrences for the series
            const daysBetween = differenceInDays(endBy, start) + 1;
            occurrences = Math.ceil(daysBetween / interval);
        }

        // find the date of the last occurrence in the series
        const daysAfter = (occurrences - 1) * interval;
        lastDate = addDays(start, daysAfter);
    } else if ((repeatSelected === "week" || repeatSelected === "weekly") && daysOfWeek && daysOfWeek.length !== 0) {
        if (endByDate) {
            occurrences = countWeeklyRecurrenceDays(start, endBy, interval, daysOfWeek);
        }

        lastDate = getWeeklyRecurrenceEndDate(start, occurrences, interval, daysOfWeek);
    } else if (repeatSelected === "month" || repeatSelected === "monthly") {
        if (monthRepeat === "day") {
            if (endByDate) {
                let monthsBetween = (endBy.getMonth() - start.getMonth()) + (12 * (endBy.getFullYear() - start.getFullYear()));
                const daysInEndByMonth = getDaysInMonth(endBy);

                if (monthOption <= endBy.getDate() || (monthOption > daysInEndByMonth && endBy.getDate() === daysInEndByMonth)) {
                    // if the series can have an occurrence in the end by month, increment
                    monthsBetween++;
                }

                occurrences = Math.ceil(monthsBetween / interval);
            }

            let lastMonthDate = addMonths(start, (occurrences - 1) * interval);
            const daysInLastMonth = getDaysInMonth(lastMonthDate);

            lastDate = new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth(), Math.min(daysInLastMonth, monthOption));
        } else {
            if (endByDate) {
                let monthsBetween = (endBy.getMonth() - start.getMonth()) + (12 * (endBy.getFullYear() - start.getFullYear()));

                // determine the nth day of the end by month, checking if the series can repeat on this date
                const endByMonthDate = getNthDateOfMonth(endBy, monthRepeat, monthOption, convertDaysOfWeek([monthOption])[0]);

                if (endByMonthDate.getDate() <= endBy.getDate()) {
                    // nth day of month is within the date range, meaning occurrence can be on it
                    monthsBetween++;
                }

                occurrences = Math.ceil(monthsBetween / interval);
            }

            const lastMonthDate = addMonths(start, (occurrences - 1) * interval); // the last month in which the series will repeat
            lastDate = getNthDateOfMonth(lastMonthDate, monthRepeat, monthOption, convertDaysOfWeek([monthOption])[0]);
        }
    }

    return {
        lastDate: lastDate,
        formattedLastDate: lastDate ? formatDate(lastDate) : undefined,
        occurrences: occurrences,
    };
};

// Format the recurring meeting end info into a message
export const getRecurrenceMessage = (recurrence, startTime) => {
    const recurrenceEndInfo = getRecurrenceEndInfo(recurrence, startTime);

    if (recurrenceEndInfo) {
        if (recurrenceEndInfo.formattedLastDate === undefined && recurrenceEndInfo.occurrences === undefined) {
            return "Your recurring series will repeat forever.";
        }

        return `Your recurring series will end on ${recurrenceEndInfo.formattedLastDate} after ${recurrenceEndInfo.occurrences} occurrence${recurrenceEndInfo.occurrences > 1 ? "s" : ""}.`;
    }

    return "";
};

// Get the number of attendees for the given array
export const getAttendeeCount = (attendeeArray, attendees) => {
    let total = 0;
    attendees.forEach((attendee) => {
        if (attendeeArray.includes(attendee.email)) {
            total += 1;
        }
    });
    return total;
};

// Get the total number of attendees for a suggestion
export const getTotalAttendees = (suggestion, attendees) => {
    let total = 0;
    attendees.forEach((attendee) => {
        if (suggestion.freePeople.includes(attendee.email) || suggestion.tentativePeople.includes(attendee.email) || suggestion.unavailablePeople.includes(attendee.email)) {
            total += 1;
        }
    });
    return total;
};

// Get availability tag styling based on percentage of available attendees
export const getAttendeeAvailabilityType = (suggestion, attendees) => {
    let free = getAttendeeCount(suggestion.freePeople, attendees);
    let total = getTotalAttendees(suggestion, attendees);
    let availability = parseInt((free / total) * 100);

    if (availability > ATTENDEE_AVAILABILITY.SUCCESS) {
        return "success";
    } else if (availability > ATTENDEE_AVAILABILITY.WARNING) {
        return "warning";
    } else {
        return "error";
    }
};

// Get text to be displayed on the attendee tag
export const getAttendeeTagText = (suggestion, attendees) => {
    if (getAttendeeCount(suggestion.freePeople, attendees) === getTotalAttendees(suggestion, attendees)) {
        return "All attendees free";
    }

    return `${getAttendeeCount(suggestion.freePeople, attendees)} of ${getTotalAttendees(suggestion, attendees)} attendees free`;
};