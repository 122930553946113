import * as Actions from "./action-types";

export const addAttendee = (attendee) => {
    return {type: Actions.ADD_ATTENDEE, attendee};
};

export const addExternalAttendee = (attendee) => {
    return {type: Actions.ADD_EXTERNAL_ATTENDEE, attendee};
};

export const updateAttendee = (attendee) => {
    return {type: Actions.UPDATE_ATTENDEE, attendee};
};

export const updateExternalAttendee = (attendee) => {
    return {type: Actions.UPDATE_EXTERNAL_ATTENDEE, attendee};
};

export const removeAttendee = (attendee) => {
    return {type: Actions.REMOVE_ATTENDEE, attendee};
};

export const removeExternalAttendee = (attendee) => {
    return {type: Actions.REMOVE_EXTERNAL_ATTENDEE, attendee};
};

export const clearAttendees = () => {
    return {type: Actions.CLEAR_ATTENDEES};
};

export const clearDraft = () => {
    return {type: Actions.CLEAR_DRAFT}
};

export const getPeopleSuggestions = (query) => {
    return {type: Actions.GET_PEOPLE_SUGGESTIONS, query};
};

export const savePeopleSuggestions = (people) => {
    return {type: Actions.SAVE_PEOPLE_SUGGESTIONS, people};
};

export const clearPeopleSuggestions = () => {
    return {type: Actions.CLEAR_PEOPLE_SUGGESTIONS};
};

export const getGroupSuggestions = (query, start, size) => {
    return {type: Actions.GET_GROUP_SUGGESTIONS, query, start, size};
};

export const saveGroupSuggestions = (groups) => {
    return {type: Actions.SAVE_GROUP_SUGGESTIONS, groups};
};

export const clearGroupSuggestions = () => {
    return {type: Actions.CLEAR_GROUP_SUGGESTIONS};
};

export const getRASDataForPerson = (alias, addNewAttendee, name, setSettingsPopoverAttendee, addPointOfContact = false) => {
    return {type: Actions.GET_RAS_DATA_FOR_PERSON, alias, addNewAttendee, name, setSettingsPopoverAttendee, addPointOfContact};
};

export const getRASDataForPersonAndAddToFavorite = (alias, name) => {
    return {type: Actions.GET_RAS_DATA_FOR_PERSON_AND_ADD_TO_FAVORITE, alias, name};
};

export const updatePersonAttendeeWithRasData = (person) => {
    return {type: Actions.UPDATE_PERSON_ATTENDEE_WITH_RAS_DATA, person}
};

export const getRASDataForGroup = (name, setSettingsPopoverAttendee) => {
    return {type: Actions.GET_RAS_DATA_FOR_GROUP, name, setSettingsPopoverAttendee};
};

export const updateGroupAttendeeWithRasData = (group) => {
    return {type: Actions.UPDATE_GROUP_ATTENDEE_WITH_RAS_DATA, group}
};

export const addAttendeeByEmail = (attendee, priority, addExternalEmail = false) => {
    return {type: Actions.ADD_ATTENDEE_BY_EMAIL, attendee, priority, addExternalEmail};
};

export const addAttendeeAndFetchWorkingHours = (requestedOnBehalfOf, attendee) => {
    return {type: Actions.ADD_ATTENDEE_AND_FETCH_WORKING_HOURS, requestedOnBehalfOf, attendee};
};

export const cacheAttendeeWorkingHours = (attendee) => {
    return {type: Actions.CACHE_ATTENDEE_WORKING_HOURS, attendee};
};
