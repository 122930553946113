import React, { useEffect, useState, useCallback, useRef } from "react";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { getMeetingDetailsAndSave, batchGetGroupDetails } from "../actions";
import { parseRecurrence } from "../meeting-utils";
import { getResponseLabel, getMeetingResponseIcon, getMeetingStatusColor, getAttendeeResponseIcon } from "../meeting-status-utils";
import {
    getRooms,
    getLocations,
    getSharedGroupDetails,
    getTimezonesList,
    getWorkflow,
    getSettingsPrimaryTimezone,
} from "../../../sagas/selector";
import { deleteMeeting, updateMeeting } from "../../shared/actions";
import { clearAttendees } from "../../people/actions";
import { getAttendeeTooltip } from "../../people/people-utils";
import { removeRoom, removeLocation } from "../locations/actions";
import { setLoadMeeting, setMaster } from "../../meeting-scheduler/actions";
import { setLoadOOTO } from "../../meeting-ooto/actions";
import { switchWorkflow } from "../workflow/actions";
import { incrementCount } from "../../shared/actions";
import { generateComponentId } from "../shared-utils";
import { renderDurationLabel } from "../time-utils";
import { convertEpoch } from "../../meeting-scheduler/meeting-scheduler-utils";
import { timezoneIdToDisplayName } from "../timezones/timezones-utils";
import { getChimePinFromMeetingBody } from "../chime/html-template-utilities";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Link from "@amzn/meridian/link";
import Heading from "@amzn/meridian/heading";
import Box from "@amzn/meridian/box";
import Icon from "@amzn/meridian/icon"
import Loader from "@amzn/meridian/loader";
import Thumbnail from "@amzn/meridian/thumbnail";
import Tooltip from "@amzn/meridian/tooltip";
import Menu, { MenuItem } from "@amzn/meridian/menu";
import Modal, { ModalFooter } from "../meridian-custom-components/src/components/modal";
import Checkbox from "@amzn/meridian/checkbox";
import ControlGroup from "@amzn/meridian/control-group";

import calendarTokens from "@amzn/meridian-tokens/base/icon/calendar";
import geopinTokens from "@amzn/meridian-tokens/base/icon/geopin";
import lockTokens from "@amzn/meridian-tokens/base/icon/lock";
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil";
import syncTokens from "@amzn/meridian-tokens/base/icon/sync";
import trashTokens from "@amzn/meridian-tokens/base/icon/trash";
import undoTokens from "@amzn/meridian-tokens/base/icon/undo";
import userTokens from "@amzn/meridian-tokens/base/icon/user";

import groupIcon from "../../../assets/people/group.png";
import unknownIcon from "../../../assets/people/unknown.png";
import chimeLogo from "../../../assets/people/chime-logo.svg";
import "../styles/meeting-details.css";

import RichTextEditor from "../components/rich-text-editor.js";
import { MODAL_CONSTANT, RESPONSE_PRIORITY, TIME_CONSTANT } from "../shared-constants.js";
import { renderExternalLink } from "../shared-utils";
import { STEP } from "../../meeting-scheduler/meeting-scheduler-constants";
import { WORKFLOWS } from "../workflow/workflow-constants";
import { STATUS_LABEL } from "../../shared/meeting-status-constants";
import { TIME_FORMAT } from "../settings/settings-constants";

import {
    postRoomBookingMetric
} from "../../shared/metrics/actions";

const MeetingDetails = (props) => {
    const dispatch = useDispatch();
    const meetingDetails = useSelector(props.selector);
    const groupDetails = useSelector(getSharedGroupDetails);
    const currentWorkflow = useSelector(getWorkflow);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const isOrganizer = meetingDetails.response === "organizer";
    const [showMoreAttendees, setShowMoreAttendees] = useState(false);
    const renderAsModal = props.hasOwnProperty("renderAsModal") ? props.renderAsModal : true; // render the meeting details as a modal or as a box, renders as modal by default

    const timezones = useSelector(getTimezonesList);
    const [primaryBrowserTimezoneId, setPrimaryBrowserTimezoneId] = useState(meetingDetails.startTimezone ? meetingDetails.startTimezone.id : undefined); // We will use timezone from meeting by default, useEffect will handle that in other cases.
    const browserTimezoneName = timezoneIdToDisplayName(timezones, primaryBrowserTimezoneId);

    const startTime = meetingDetails && meetingDetails.time && meetingDetails.time.startTime;
    const endTime = meetingDetails && meetingDetails.time && meetingDetails.time.endTime;
    const timeFormat = props.timeFormat;
    const page = props.page;

    const formatDateTime = (time, format) => {
        return convertEpoch(time, format, primaryBrowserTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR);
    };

    const formatTimezoneName = () => {
        return browserTimezoneName || "";
    };

    const [attendeeResponseFilter, setAttendeeResponseFilter] = useState(["accept", "decline", "tentative", "notResponded"]);

    const renderFilterOption = (response, label, id, ariaLabel) => {
        return (
            <Checkbox value={response}>
                <Row alignmentVertical="center" spacing="none" width="160px" widths={["fit", "fill", "fit"]}>
                    <label htmlFor={id} aria-label={ariaLabel} style={{pointerEvents: "none"}}>
                        <Text tag="span" id={id} type="b200">{label}&nbsp;({numberOfAttendees(response)})</Text>
                    </label>
                    <span />
                    {getAttendeeResponseIcon(response)}
                </Row>
            </Checkbox>
        );
    };

    const processAttendee = (attendee) => {
        let portrait, displayNameMatchesEmail;
        let isChime = false;
        let responsePriority;
        let alias = attendee.email.substring(0, attendee.email.indexOf("@"));
        if (checkOrganizer(attendee)) {
            responsePriority = RESPONSE_PRIORITY.organizer;
        } else if (!attendee.response) {
            responsePriority = RESPONSE_PRIORITY.notResponded;
        } else {
            responsePriority = RESPONSE_PRIORITY[attendee.response];
        }

        if (attendee.email && attendee.email.endsWith("@chime.aws")) {
            portrait = chimeLogo;
            alias = "";
            isChime = true;
        } else if (attendee.email && attendee.email.split("@")[1]?.includes("amazon")) {
            portrait = `https://internal-cdn.amazon.com/badgephotos.amazon.com/?login=${alias}`;
        } else {
            portrait = unknownIcon; // display the unknown profile picture from phonetool if attendee is not from Amazon
        }

        displayNameMatchesEmail = attendee.displayName === attendee.email;

        return {
            ...attendee,
            alias,
            portrait,
            name: attendee.displayName === undefined ? "" : attendee.displayName.split(",").reverse().join(" ").trim(),
            displayNameMatchesEmail,
            isChime,
            responsePriority,
        };
    };

    const compareAttendee = (a, b) => {
        if (a.responsePriority === b.responsePriority) {
            return ('' + getAttendeeTooltip(a)).localeCompare(getAttendeeTooltip(b));
        } else {
            return a.responsePriority - b.responsePriority;
        }
    };

    const checkOrganizer = (attendee) => {
        return meetingDetails && attendee.email === meetingDetails.organizer;
    };

    const checkOptional = (attendee) => {
        return meetingDetails && meetingDetails.optionalAttendees && meetingDetails.optionalAttendees.find((optionalAttendee) => optionalAttendee.email === attendee.email);
    };

    const attendeeList = meetingDetails && meetingDetails.requiredAttendees && meetingDetails.optionalAttendees && [
        ...(meetingDetails.requiredAttendees.find((attendee) => attendee.email === meetingDetails.organizer) === undefined ? [processAttendee({email: meetingDetails.organizer})] : []),
        ...meetingDetails.requiredAttendees.map(processAttendee),
        ...meetingDetails.optionalAttendees.map(processAttendee)
    ].sort(compareAttendee);
    const attendeeListOfChimePin = attendeeList && attendeeList.filter((attendee) => attendee.email.endsWith("@chime.aws"));
    const nonChimeAttendeeList = attendeeList && attendeeList.filter((attendee) => !attendee.email.endsWith("@chime.aws"));
    const pinFromBody = getChimePinFromMeetingBody(meetingDetails.body);

    const onDeleteMeeting = (deleteMeetingRequest, redirectTo, setMeetingListAction) => {
        if (page === "room booking") {
            dispatch(incrementCount());
        }
        dispatch(postRoomBookingMetric(`delete a meeting from ${page}`, props.username));
        dispatch(deleteMeeting(deleteMeetingRequest, redirectTo, setMeetingListAction, props.event))
    };
    const onUpdateMeeting = (updateMeetingRequest, redirectTo, setMeetingListAction) => dispatch(updateMeeting(updateMeetingRequest, redirectTo, setMeetingListAction));

    useEffect(() => {
        if ((props.open || !renderAsModal) && meetingDetails && meetingDetails.hasOwnProperty("time")) {
            let attendeesToQueryFor = [];
            attendeeList.forEach((attendee) => {
                if (!groupDetails[attendee.email]) {
                    let alias = attendee.email.substring(0, attendee.email.indexOf("@"));

                    // As far as I can tell
                    // Amazonians have their real name as their displayName
                    // Groups have the group alias as their displayName
                    // or the displayName matches the email and ends in amazon.com
                    // External emails have their email as their displayName
                    // Chime emails have their email as their displayName
                    if (alias === attendee.displayName || (attendee.email === attendee.displayName && attendee.email.endsWith("amazon.com"))) {
                        attendeesToQueryFor.push(attendee.email);
                    }
                }
            });
            if (attendeesToQueryFor.length > 0) {
                dispatch(batchGetGroupDetails(attendeesToQueryFor));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingDetails, props.open, renderAsModal]);

    // Load primaryBrowserTimezoneId for time and timezone displayed only for initial primaryBrowserTimezoneId
    useEffect(() => {
        if (timezones.length && primaryTimezone && !primaryBrowserTimezoneId) {
            setPrimaryBrowserTimezoneId(primaryTimezone);
        }
    }, [timezones, primaryBrowserTimezoneId, primaryTimezone]);

    const prepareEmailBody = (emailBody) => {
        return DOMPurify.sanitize(emailBody).replaceAll("<a", `<a target="_blank"`);
    };

    const deleteButtonRef = useRef();
    const [deleteButtonOpen, setDeleteButtonOpen] = useState(false);
    const onClickDeleteMenu = useCallback(() => setDeleteButtonOpen(true), []);
    const onCloseDeleteMenu = useCallback(() => setDeleteButtonOpen(false), []);

    const updateButtonRef = useRef();
    const [updateButtonOpen, setupdateButtonOpen] = useState(false);
    const onClickUpdateMenu = useCallback(() => setupdateButtonOpen(true), []);
    const onCloseUpdateMenu = useCallback(() => setupdateButtonOpen(false), []);

    const copyToNewButtonRef = useRef();
    const [copyToNewButtonOpen, setCopyToNewButtonOpen] = useState(false);
    const onClickCopyToNewMenu = useCallback(() => setCopyToNewButtonOpen(true), []);
    const onCloseCopyToNewMenu = useCallback(() => setCopyToNewButtonOpen(false), []);

    const [changeBody, setChangeBody] = useState("");
    const [changeMode, setChangeMode] = useState(""); // empty (view), update, or delete
    const [isChangeMaster, setIsChangeMaster] = useState(false); // occurrence or series

    const rooms = useSelector(getRooms);
    const locations = useSelector(getLocations);

    const redirectTo = props.redirectTo;
    const meetingListAction = props.meetingListAction;
    const meetingList = props.meetingList;
    const meetingRequest = props.meetingRequest;

    // Only show "Copy to New" option when viewing a meeting details modal on the home page or calendar page
    const showCopyToNew = currentWorkflow === WORKFLOWS.HOME || currentWorkflow === WORKFLOWS.CALENDAR;
    const showEditFooter = meetingDetails && meetingDetails.hasOwnProperty("time") && props.entryID && (isOrganizer || showCopyToNew);
    const showCreateFooter = meetingDetails && meetingDetails.hasOwnProperty("time") && !props.entryID;

    // Get the action object used to change the meeting list state within the sagas
    const getMeetingListAction = (changedMeetingList) => {
        let setMeetingListAction;

        if (meetingListAction && changedMeetingList) {
            setMeetingListAction = {
                type: meetingListAction,
                meetingList: changedMeetingList,
            };
        }

        return setMeetingListAction;
    };

    // Cancel/delete the selected meeting and display a toast for the response
    const onClickDelete = () => {
        if (props.entryID) {
            let deleteMeetingRequest = {
                "requestedOnBehalfOf": meetingDetails.organizer,
                "uniqueOrEntryID": meetingDetails.entryID,
                "master": isChangeMaster,
                "sendCancellations": true,
                "body": {
                    "value": changeBody,
                    "type": "html"
                }
            };

            let setMeetingListAction;

            if (meetingList) {
                // get new meeting list with deleted meeting omitted
                let changedMeetingList = meetingList.filter((meeting) => meeting.entryID !== meetingDetails.entryID);

                setMeetingListAction = getMeetingListAction(changedMeetingList);
            }

            onDeleteMeeting(deleteMeetingRequest, redirectTo, setMeetingListAction);
            onClose();
        }
    };

    // Update the selected meeting and display a toast for the response
    const onClickUpdate = () => {
        let setMeetingListAction;

        if (props.entryID) {
            let updateMeetingRequest = {
                "requestedOnBehalfOf": meetingDetails.organizer,
                "uniqueOrEntryID": isChangeMaster ? meetingDetails.masterEntryID : meetingDetails.entryID,
                "master": isChangeMaster,
                "sendInvitations": true,
                "changes": {
                    "body": {
                        "value": changeBody,
                        "type": "html"
                    }
                }
            };

            onUpdateMeeting(updateMeetingRequest, redirectTo, setMeetingListAction);
        } else if (meetingRequest) {
            if (meetingList) {
                // update the changed meeting inside of the meeting list
                let changedMeetingList = meetingList.map((meeting) => meeting.entryID !== meetingDetails.entryID ?
                    meeting
                    :
                    {
                        ...meeting,
                        subject: meetingRequest.changes.subject,
                        location: meetingRequest.changes.location,
                        time: meetingRequest.changes.time,
                        isPrivate: meetingRequest.changes.isPrivate,
                        status: meetingRequest.changes.status,
                        isAllDayEvent: meetingRequest.changes.isAllDayEvent
                    }
                );

                setMeetingListAction = getMeetingListAction(changedMeetingList);
            }

            onUpdateMeeting(meetingRequest, redirectTo, setMeetingListAction);
        }

        onClose();
    };

    // Reset list of attendees and locations and set loadMeeting flag
    const prepareMeetingState = (type) => {
        dispatch(setMaster(isChangeMaster));

        if (props.entryID) {
            dispatch(clearAttendees());
            rooms.forEach((room) => {
                dispatch(removeRoom(room));
            });
            locations.forEach((location) => {
                dispatch(removeLocation(location));
            });
        }
        // The type parameter lets us know if we are loading a standard meeting or an OOTO invite
        if (type === "OOTO") {
            dispatch(setLoadOOTO(true));
        } else {
            dispatch(setLoadMeeting(true));
        }
    };

    // Prepare meeting state and then redirect user to the edit meeting workflow
    const onRedirectUpdate = (step = undefined) => {
        prepareMeetingState("STANDARD");

        let entryID = isChangeMaster ? meetingDetails.masterEntryID : meetingDetails.entryID;

        if (step) { // Redirect to a specific step
            dispatch(push("/edit-meeting/step=" + step + "/entryID=" + entryID));
        } else {
            dispatch(push("/edit-meeting/entryID=" + entryID));
        }
        dispatch(switchWorkflow(WORKFLOWS.EDIT_MEETING));
    };

    // Prepare meeting state and then redirect user to the create meeting workflow
    const onCopyToStandard = (step = undefined) => {
        prepareMeetingState("STANDARD");
        if (step) { // Redirect to a specific step
            dispatch(push("/create-meeting/step=" + step));
        } else {
            dispatch(push("/create-meeting"));
        }
        dispatch(switchWorkflow(WORKFLOWS.STANDARD_MEETING));
    };

    // Prepare meeting state and then redirect user to the ooto workflow
    const onCopyToOOTO = async (step = undefined) => {
        prepareMeetingState("OOTO");
        if (step) { // Redirect to a specific step
            dispatch(push("/ooto/step=" + step));
        } else {
            dispatch(push("/ooto"));
        }
        dispatch(switchWorkflow(WORKFLOWS.OOTO));
    };

    const onOpen = () => {
        if (props.entryID) {
            dispatch(getMeetingDetailsAndSave(props.userEmail, props.entryID, false, "ooto", "", props.saveAction));
        }
        setChangeMode("");
    };

    const onClose = () => {
        if (props.entryID) {
            dispatch({type: props.saveAction, meeting: {}});
        }
        props.onClose();
    };

    const renderMultiBadgerLink = () => {
        let badgerURL = "https://prod.multi-badger.tools.amazon.dev/?mode=grid&logins=";

        attendeeList.forEach((attendee) => {
            if (attendee.alias && attendee.email.includes("amazon")) {
                badgerURL += `${attendee.alias}@,`;
            }
        });

        return renderExternalLink("View meeting roster", badgerURL);
    };

    const shouldShowResponseIcon = (attendee) => {
        return ((checkOrganizer(attendee) && attendeeResponseFilter.includes("accept")) ||
            (attendeeResponseFilter.includes("notResponded") && !attendee.response && !checkOrganizer(attendee)) ||
            (attendee.response && attendeeResponseFilter.includes(attendee.response)));
    };

    const numberOfAttendees = (response) => {
        if (response === "notResponded") {
            return nonChimeAttendeeList && nonChimeAttendeeList.filter((attendee) => !attendee.response).length - 1; // Exclude the organizer
        } else if (response === "accept") {
            return nonChimeAttendeeList && nonChimeAttendeeList.filter((attendee) => attendee.response === response).length + 1; // Include the organizer
        } else {
            return nonChimeAttendeeList && nonChimeAttendeeList.filter((attendee) => attendee.response === response).length;
        }
    };

    const renderAttendeesAsOrganizer = () => {
        let attendeeListNumber = 0;
        return (
            <section aria-label="Meeting attendees">
                <ControlGroup
                    label="Response"
                    name="attendeeResponse[]"
                    onChange={setAttendeeResponseFilter}
                    value={attendeeResponseFilter}
                >
                    {renderFilterOption("accept", "Attending", "responseFilter-attending", "response filter attending")}
                    {renderFilterOption("decline", "Declined", "responseFilter-declined", "response filter declined")}
                    {renderFilterOption("tentative", "Tentative", "responseFilter-tentative", "response filter tentative")}
                    {renderFilterOption("notResponded", "Pending response", "responseFilter-notResponded", "response filter pending response")}
                </ControlGroup>
                <hr/>
                {nonChimeAttendeeList && nonChimeAttendeeList.length > 0 &&
                    <Column spacing="small" alignmentHorizontal="left"
                            alignmentVertical="center" width="100%">
                        {nonChimeAttendeeList.map((attendee) =>
                            shouldShowResponseIcon(attendee) && ++attendeeListNumber &&
                                <React.Fragment>
                                    {(showMoreAttendees || (!showMoreAttendees && attendeeListNumber - 1 < MODAL_CONSTANT.MODAL_ATTENDEE_COUNT)) &&
                                        <Row spacing="small" spacingInset="none">
                                            <Tooltip position="left" title={getAttendeeTooltip(attendee)}>
                                                {/*
                                                    If an Amazonian's email matches their displayName (if this is even possible),
                                                    their portrait will still load correctly, but they will not have a phonetool link
                                                */}
                                                {(groupDetails[attendee.email] && groupDetails[attendee.email].status !== 500) || attendee.displayNameMatchesEmail ?
                                                    <Row spacing="none" alignmentVertical="bottom">
                                                        <div style={{position: "relative", width: "100%", height: "100%"}}>
                                                            <span tabIndex="0">
                                                                <Thumbnail
                                                                    key={attendee.displayName}
                                                                    size="small"
                                                                    source={groupDetails[attendee.email] && groupDetails[attendee.email].status !== 500 ? groupIcon : attendee.portrait}
                                                                    label={getAttendeeTooltip(attendee)}
                                                                />
                                                            </span>
                                                            <div style={{
                                                                backgroundColor: "white",
                                                                width: "16px",
                                                                height: "16px",
                                                                borderRadius: "8px",
                                                                position: "absolute",
                                                                right: "0px",
                                                                bottom: "0px"
                                                            }}>
                                                                {getAttendeeResponseIcon(checkOrganizer(attendee) ? "organizer" : attendee.response)}
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    :
                                                    <Row spacing="none" alignmentVertical="bottom" alignmentHorizontal="right">
                                                        <div style={{position: "relative", width: "100%", height: "100%"}}>
                                                            <Link
                                                                href={"https://phonetool.amazon.com/users/" + attendee.alias}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Thumbnail
                                                                    key={attendee.displayName}
                                                                    size="small"
                                                                    source={attendee.portrait}
                                                                    label={`Phone tool link for ${attendee.name} ${attendee.alias}`}
                                                                />
                                                            </Link>
                                                            <div style={{
                                                                backgroundColor: "white",
                                                                width: "16px",
                                                                height: "16px",
                                                                borderRadius: "8px",
                                                                position: "absolute",
                                                                right: "0px",
                                                                bottom: "0px"
                                                            }}>
                                                                {getAttendeeResponseIcon(checkOrganizer(attendee) ? "organizer" : attendee.response)}
                                                            </div>
                                                        </div>
                                                    </Row>
                                                }
                                            </Tooltip>
                                            <Column alignmentVertical="center"
                                                    alignmentHorizontal="left" spacing="none">
                                                <Text type="h100">
                                                    {attendee.name || attendee.displayName || attendee.alias}
                                                </Text>
                                                <Text type="b100">
                                                    {checkOrganizer(attendee) && "Organizer"}
                                                    {checkOptional(attendee) && "Optional"}
                                                </Text>
                                            </Column>
                                        </Row>
                                    }
                                </React.Fragment>
                        )}
                        {attendeeListNumber > MODAL_CONSTANT.MODAL_ATTENDEE_COUNT && !showMoreAttendees &&
                            <button onClick={() => setShowMoreAttendees(true)}
                                    className="expandAttendeesButton"
                                    aria-label={`Show ${attendeeListNumber - MODAL_CONSTANT.MODAL_ATTENDEE_COUNT} more attendees`}>
                                +{attendeeListNumber - MODAL_CONSTANT.MODAL_ATTENDEE_COUNT}
                            </button>
                        }
                        <Row spacing="medium" alignmentHorizontal="left"
                             alignmentVertical="center" wrap="down">
                            {renderMultiBadgerLink()}
                        </Row>
                    </Column>
                }
            </section>
        );
    }

    const renderAttendees = () => {
        return(
            <section aria-label="Meeting attendees">
                {nonChimeAttendeeList && nonChimeAttendeeList.length > 0 &&
                    <Column spacing="small" alignmentHorizontal="left"
                            alignmentVertical="center" width="100%">
                        {nonChimeAttendeeList.map((attendee, index) =>
                            <React.Fragment key={index}>
                                {(showMoreAttendees || (!showMoreAttendees && index < MODAL_CONSTANT.MODAL_ATTENDEE_COUNT)) &&
                                    <Row spacing="small" spacingInset="none">
                                        <Tooltip position="left" title={getAttendeeTooltip(attendee)}>
                                            {/*
                                                If an Amazonian's email matches their displayName (if this is even possible),
                                                their portrait will still load correctly, but they will not have a phonetool link
                                            */}
                                            {(groupDetails[attendee.email] && groupDetails[attendee.email].status !== 500) || attendee.displayNameMatchesEmail ?
                                                <Row spacing="none" alignmentVertical="bottom">
                                                    <div style={{position: "relative", width: "100%", height: "100%"}}>
                                                        <span tabIndex="0">
                                                            <Thumbnail
                                                                key={attendee.displayName}
                                                                size="small"
                                                                source={groupDetails[attendee.email] && groupDetails[attendee.email].status !== 500 ? groupIcon : attendee.portrait}
                                                                label={getAttendeeTooltip(attendee)}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>
                                                :
                                                <Row spacing="none" alignmentVertical="bottom" alignmentHorizontal="right">
                                                    <div style={{position: "relative", width: "100%", height: "100%"}}>
                                                        <Link
                                                            href={"https://phonetool.amazon.com/users/" + attendee.alias}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <Thumbnail
                                                                key={attendee.displayName}
                                                                size="small"
                                                                source={attendee.portrait}
                                                                label={`Phone tool link for ${attendee.name} ${attendee.alias}`}
                                                            />
                                                        </Link>
                                                    </div>
                                                </Row>
                                            }
                                        </Tooltip>
                                        <Column alignmentVertical="center"
                                                alignmentHorizontal="left" spacing="none">
                                            <Text type="h100">
                                                {attendee.name || attendee.displayName || attendee.alias}
                                            </Text>
                                            <Text type="b100">
                                                {checkOrganizer(attendee) && "Organizer"}
                                                {checkOptional(attendee) && "Optional"}
                                            </Text>
                                        </Column>
                                    </Row>
                                }
                            </React.Fragment>
                        )}
                        {nonChimeAttendeeList.length > MODAL_CONSTANT.MODAL_ATTENDEE_COUNT && !showMoreAttendees &&
                            <button onClick={() => setShowMoreAttendees(true)}
                                    className="expandAttendeesButton"
                                    aria-label={`Show ${nonChimeAttendeeList.length - MODAL_CONSTANT.MODAL_ATTENDEE_COUNT} more attendees`}>
                                +{nonChimeAttendeeList.length - MODAL_CONSTANT.MODAL_ATTENDEE_COUNT}
                            </button>
                        }
                        <Row spacing="medium" alignmentHorizontal="left"
                             alignmentVertical="center" wrap="down">
                            {renderMultiBadgerLink()}
                        </Row>
                    </Column>
                }
            </section>
        );
    }

    const renderMeetingDetails = () => {
        return (
            <React.Fragment>
                <section aria-label="Meeting details">
                    <Column spacing="medium" spacingInset="none" width="100%">
                        {meetingDetails && meetingDetails.hasOwnProperty("time") ?
                            <React.Fragment>
                                <section aria-label={`Subject: ${meetingDetails.subject || "Untitled meeting"}, ${meetingDetails.isPrivate ? "Private meeting" : ""}`}>
                                    {renderAsModal &&
                                        <Column spacingInset="xsmall none"/>
                                    }
                                    <Row spacing="small">
                                        <Heading level={1} type="h400">{meetingDetails.subject || "Untitled meeting"}</Heading>
                                        {meetingDetails.isPrivate &&
                                            <Tooltip position="right" title="Private">
                                                <Icon tokens={lockTokens} />
                                            </Tooltip>
                                        }
                                    </Row>
                                    <Text color="secondary">{STATUS_LABEL[meetingDetails.status]}</Text>
                                </section>
                                <hr />
                                <Row spacing="medium" spacingInset="none" alignmentVertical="top"
                                     alignmentHorizontal="left">
                                    <Column spacing="medium" spacingInset="none" width="100%" alignmentHorizontal="stretch">
                                        <section aria-label={`Time: ${formatDateTime(startTime, "longWeekdayDateYear")},
                                                                ${formatDateTime(startTime, "time")} to
                                                                ${formatDateTime(endTime, "time")}
                                                                ${formatTimezoneName()}
                                                                ${renderDurationLabel(startTime, endTime, TIME_CONSTANT.SEC_NAME)},
                                                                ${meetingDetails.recurrence ? parseRecurrence(meetingDetails.recurrence) : ""}`}>
                                        <Column spacing="small">
                                            <Row alignmentHorizontal="justify">
                                                <Row alignmentVertical="top" spacing="small">
                                                    <Icon tokens={calendarTokens} />
                                                    <Column spacing="small">
                                                        <Text type="h100">{formatDateTime(startTime, "longWeekdayDateYear")}</Text>
                                                        <Text>
                                                            {`${formatDateTime(startTime, "time")} to
                                                              ${formatDateTime(endTime, "time")}
                                                              ${formatTimezoneName()}
                                                              (${renderDurationLabel(startTime, endTime, TIME_CONSTANT.SEC_NAME)})`}
                                                        </Text>
                                                    </Column>
                                                </Row>
                                                {changeMode === "update" &&
                                                    <Button type="icon" label="Edit meeting date or time"
                                                            onClick={() => onRedirectUpdate(STEP.SMART_SUGGESTIONS)}>
                                                        <Icon tokens={pencilTokens} />
                                                    </Button>
                                                }
                                            </Row>
                                            {meetingDetails.recurrence &&
                                                <Row spacing="small">
                                                    <Icon tokens={syncTokens} />
                                                    <Text>{parseRecurrence(meetingDetails.recurrence)}</Text>
                                                </Row>
                                            }
                                        </Column>
                                    </section>
                                    {props.entryID &&
                                        <React.Fragment>
                                            <hr />
                                            <section aria-label={`Response: ${getResponseLabel(meetingDetails.response, meetingDetails.hasOwnProperty("subject") ? meetingDetails.subject.startsWith("Canceled") : false)}`}>
                                                <Row alignmentVertical="center" spacingInset="none xsmall">
                                                    <Text>
                                                        {getMeetingResponseIcon(meetingDetails.response, meetingDetails.hasOwnProperty("subject") ? meetingDetails.subject.startsWith("Canceled") : false)}&nbsp;&nbsp;&nbsp;{getResponseLabel(meetingDetails.response, meetingDetails.hasOwnProperty("subject") ? meetingDetails.subject.startsWith("Canceled") : false)}
                                                    </Text>
                                                </Row>
                                            </section>
                                        </React.Fragment>
                                    }
                                    {((attendeeListOfChimePin && attendeeListOfChimePin.length !== 0) || pinFromBody) &&
                                        <React.Fragment>
                                            <hr />
                                            <section aria-label={`Chime pin: ${pinFromBody}`}>
                                                <Row alignmentVertical="center" spacingInset="none" spacing="small">
                                                    <img src={chimeLogo} height="24px" width="24px" alt="Chime" />
                                                    <Text>
                                                        Chime enabled
                                                        {attendeeListOfChimePin.find((attendee) => attendee.email === "meet@chime.aws") && ", Auto-call enabled"}
                                                    </Text>
                                                </Row>
                                            </section>
                                        </React.Fragment>
                                    }
                                    <React.Fragment>
                                        <hr />
                                        <section aria-label={`Location: ${meetingDetails.location}`}>
                                            <Column spacing="medium">
                                                <Row alignmentHorizontal="justify">
                                                    <Row spacing="small">
                                                        <Icon tokens={geopinTokens} />
                                                        <Text>{meetingDetails.location || "None"}</Text>
                                                    </Row>
                                                    {changeMode === "update" &&
                                                        <Button type="icon" label="Edit location"
                                                                onClick={() => onRedirectUpdate(STEP.ATTENDEES_AND_ROOMS)}>
                                                            <Icon tokens={pencilTokens} />
                                                        </Button>
                                                    }
                                                </Row>
                                            </Column>
                                        </section>
                                    </React.Fragment>
                                    {meetingDetails.body && meetingDetails.body.value && prepareEmailBody(meetingDetails.body.value) && !changeMode &&
                                        <React.Fragment>
                                            <hr />
                                            <section aria-label="Meeting body">
                                                <div style={{"wordBreak": "break-word"}} dangerouslySetInnerHTML={{__html: prepareEmailBody(meetingDetails.body && meetingDetails.body.value)}} />
                                            </section>
                                        </React.Fragment>
                                    }
                                    {changeMode &&
                                        <React.Fragment>
                                            <hr />
                                            <RichTextEditor
                                                key={props.entryID}
                                                textValue={prepareEmailBody(meetingDetails.body && meetingDetails.body.value)}
                                                setTextValue={setChangeBody}
                                                displayToolbar={false}
                                            />
                                        </React.Fragment>
                                    }
                                    </Column>
                                    <Column spacing="medium" spacingInset="none" width="220px">
                                        <Row spacing="small" spacingInset="none">
                                            <Icon tokens={userTokens}/>
                                            <Text type="h100">Attendees&nbsp;({nonChimeAttendeeList ? nonChimeAttendeeList.length : 0})</Text>
                                            <span/>
                                            {changeMode === "update" &&
                                                <Button type="icon" label="Edit meeting attendees"
                                                        onClick={() => onRedirectUpdate(STEP.ATTENDEES_AND_ROOMS)}>
                                                    <Icon tokens={pencilTokens}/>
                                                </Button>
                                            }
                                        </Row>
                                        <hr/>
                                        {meetingDetails && meetingDetails.hasOwnProperty("time") && props.entryID && meetingDetails.response === "organizer"  ?
                                                renderAttendeesAsOrganizer()
                                                :
                                                renderAttendees()
                                        }
                                    </Column>
                                </Row>
                            </React.Fragment>
                            :
                            <Column alignmentHorizontal="center" spacing="medium" spacingInset="xlarge none">
                                <Text>Retrieving meeting details</Text>
                                <Loader />
                            </Column>
                        }
                    </Column>
                </section>
            </React.Fragment>
        );
    };

    return renderAsModal ?
        <Modal
            open={props.open}
            onClose={onClose}
            scrollContainer="modal"
            closeLabel="Close meeting details"
            onOpen={onOpen}
            width={MODAL_CONSTANT.MODAL_WIDTH}
        >
            <div style={{
                boxSizing: "content-box",
                position: "absolute",
                top: "0px",
                left: "0px",
                backgroundColor: (meetingDetails && meetingDetails.status) ? getMeetingStatusColor(meetingDetails.status, meetingDetails.hasOwnProperty("subject") && meetingDetails.subject.startsWith("Canceled")) : "",
                border: "inherit",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                height: "10px",
                width: "100%",
                zIndex: "1"
            }} />

            {renderMeetingDetails()}

            {showEditFooter &&
                <ModalFooter>
                    <Row alignmentHorizontal={isOrganizer ? "justify" : "right"}>
                        {isOrganizer &&
                            <React.Fragment>
                                {meetingDetails.isRecurring && meetingDetails.masterEntryID ?
                                    <React.Fragment>
                                        {!changeMode ?
                                            <Button
                                                type="icon"
                                                label="Send meeting cancellation"
                                                onClick={onClickDeleteMenu}
                                                ref={deleteButtonRef}
                                            >
                                                <Icon tokens={trashTokens}/>
                                            </Button>
                                            :
                                            <Button
                                                type="icon"
                                                label="Return to meeting details"
                                                onClick={() => setChangeMode("")}
                                            >
                                                <Icon tokens={undoTokens}/>
                                            </Button>
                                        }
                                        <Menu
                                            anchorNode={deleteButtonRef.current}
                                            open={deleteButtonOpen}
                                            onClose={onCloseDeleteMenu}
                                            position="top"
                                            alignment="left"
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    setChangeMode("delete");
                                                    setIsChangeMaster(false);
                                                    onCloseDeleteMenu();
                                                }}
                                            >
                                                Occurrence
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    setChangeMode("delete");
                                                    setIsChangeMaster(true);
                                                    onCloseDeleteMenu();
                                                }}
                                            >
                                                Series
                                            </MenuItem>
                                        </Menu>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {!changeMode ?
                                            <Button
                                                type="icon"
                                                label="Send meeting cancellation"
                                                onClick={() => setChangeMode("delete")}
                                            >
                                                <Icon tokens={trashTokens}/>
                                            </Button>
                                            :
                                            <Button
                                                type="icon"
                                                label="Return to meeting details"
                                                onClick={() => setChangeMode("")}
                                            >
                                                <Icon tokens={undoTokens}/>
                                            </Button>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {isOrganizer && changeMode ?
                            <Column>
                                {changeMode === "delete" ?
                                    <Button type="primary" onClick={onClickDelete}>
                                        {meetingDetails.isRecurring && meetingDetails.masterEntryID ? (isChangeMaster ? "Cancel series" : "Cancel occurrence") : "Cancel meeting"}
                                    </Button>
                                    :
                                    <Button type="primary" onClick={onClickUpdate}>
                                        Save
                                    </Button>
                                }
                            </Column>
                            :
                            <Row>
                                {showCopyToNew &&
                                    <React.Fragment>
                                        <Button type={isOrganizer ? "tertiary" : "primary"} ref={copyToNewButtonRef} onClick={onClickCopyToNewMenu} >
                                            Copy as...
                                        </Button>
                                        <Menu
                                            anchorNode={copyToNewButtonRef.current}
                                            open={copyToNewButtonOpen}
                                            position="top"
                                            alignment="center"
                                            onClose={onCloseCopyToNewMenu}
                                        >
                                          <MenuItem onClick={onCopyToStandard}>Standard meeting</MenuItem>
                                          <MenuItem onClick={onCopyToOOTO}>OOTO invite</MenuItem>
                                        </Menu>
                                    </React.Fragment>
                                }
                                {isOrganizer &&
                                    (meetingDetails.isRecurring && meetingDetails.masterEntryID ?
                                        <React.Fragment>
                                            <Button type="primary" onClick={onClickUpdateMenu} ref={updateButtonRef}>Edit</Button>
                                            <Menu
                                                anchorNode={updateButtonRef.current}
                                                open={updateButtonOpen}
                                                onClose={onCloseUpdateMenu}
                                                position="top"
                                                alignment="right"
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        setChangeMode("update");
                                                        setIsChangeMaster(false);
                                                        onCloseUpdateMenu();
                                                    }}
                                                >
                                                    Occurrence
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        setChangeMode("update");
                                                        setIsChangeMaster(true);
                                                        onCloseUpdateMenu();
                                                    }}
                                                >
                                                    Series
                                                </MenuItem>
                                            </Menu>
                                        </React.Fragment>
                                        :
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                setChangeMode("update");
                                                setIsChangeMaster(false);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    )
                                }
                            </Row>
                        }
                    </Row>
                </ModalFooter>
            }
            {showCreateFooter &&
                <ModalFooter>
                    <Row widths="fill">
                        <span/>
                        <Button onClick={onClose} type="secondary" id={generateComponentId('button', 'cancel', 'meeting-details')}>Cancel</Button>
                        {meetingRequest ?
                            <Button onClick={onClickUpdate} type="primary" id={generateComponentId('button', 'save', 'meeting-details')}>Save</Button>
                            :
                            <Button onClick={props.onSend} type="primary" id={generateComponentId('button', 'send', 'meeting-details')}>Send</Button>
                        }
                    </Row>
                </ModalFooter>
            }
        </Modal>
        :
        <Column width="100%" maxWidth="1000px" spacing="none">
            <div style={{
                boxSizing: "content-box",
                position: "relative",
                top: "10px",
                left: "0px",
                backgroundColor: (meetingDetails && meetingDetails.status) ? getMeetingStatusColor(meetingDetails.status) : "",
                border: "inherit",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                height: "10px",
                width: "100%",
            }} />
            <Box type="outline" backgroundColor="white" spacingInset="large">
                {renderMeetingDetails()}
            </Box>
        </Column>
};

export default MeetingDetails;
