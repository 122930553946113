import addDays from "date-fns/addDays";
import isWeekend from "date-fns/isWeekend";
import format from "date-fns/format";

import { ATTENDEE_ROLE } from "../people/people-constants";
import { ATTENDEE_AVAILABILITY } from "../meeting-scheduler/meeting-scheduler-constants";
import {
    LIVESTREAM_BUILDING_CODE,
    LIVESTREAM_ENDPOINT,
    LIVESTREAM_REPORT_HREF,
    LIVESTREAM_STATION_PAIRS,
    LIVESTREAM_SYSTEM_ERROR,
    LIVESTREAM_URL_CHARACTER_LIMIT,
    LIVESTREAM_URL_PATH_PARAMETER,
} from "./livestream-constants";
import { getErrorToastWithComponent } from "../shared/toasts/toast-utils";
import { TIMEOUT, TOAST_COMPONENT } from "../shared/toasts/toast-constants";

// Count the number of words in a string
export const isRichTextValid = (richText) => {
    let text = new DOMParser().parseFromString(richText, 'text/html');
    return (text.body.textContent ?? "").length > 0;
};

// Return the default suggestion search start date
export const getLivestreamStartDate = (businessDaysForward) => {
    let startDate = new Date();

    while (businessDaysForward > 0) {
        startDate = addDays(startDate, +1);
        if (!isWeekend(startDate)) { // ignore weekends
            businessDaysForward -= 1;
        }
    }

    return startDate;
};

// Select livestream stations based on how many stations are required for an event given a suggestion
export const selectLivestreamStations = (suggestion, requiredStationCount) => {
    const availableStations = suggestion.freePeople.filter((email) => email.includes(LIVESTREAM_BUILDING_CODE));
    const randomizedStations = [...availableStations].sort(() => 0.5 - Math.random()); // Randomization on available stations from RAS response

    if (randomizedStations.length) {
        if (requiredStationCount === 1) {
            return [randomizedStations[0]];
        } else if (requiredStationCount === 2) {
            let stationPair = [];

            // Assign two stations from the same livestream engineer
            // Station pair mappings in livestream-constants.js
            randomizedStations.forEach((stationEmail) => {
                if (!stationPair.length) {
                    const pairEmail = LIVESTREAM_STATION_PAIRS[stationEmail];

                    // If the station's paired email is available, select the two stations as the pair
                    if (pairEmail && randomizedStations.includes(pairEmail)) {
                        stationPair = [stationEmail, pairEmail];
                    }
                }
            });

            return stationPair;
        }
    }

    return [];
};

/**
 * @param {String} eventName - Name of the Livestream event
 * @param {Date} date - JavaScript date object which is the start of the livestream event meeting
 * Returns a link to the livestream event included in event invites
 */
export const generateLivestreamLink = (eventName, date) => {
    const formatUrlDate = (date) => format(date, "yyyyMMdd_HH:mm");

    const baseUrl = `${LIVESTREAM_ENDPOINT}${LIVESTREAM_URL_PATH_PARAMETER}`;
    const urlDate = `_${formatUrlDate(date)}`;

    const characterLimit = LIVESTREAM_URL_CHARACTER_LIMIT - baseUrl.length - urlDate.length;

    eventName = eventName.replaceAll(" ", "_");
    // Remove restricted characters
    eventName = eventName.replaceAll(".", "").replaceAll("/", "").replaceAll("|", "");
    // Limit event name to character limit to match URL limit for Gather (https://issues.amazon.com/issues/MEETEX-6319)
    eventName = eventName.slice(0, characterLimit);

    return `${baseUrl}${eventName}${urlDate}`;
};

// Check if event requirements are valid
export const checkEventRequirementErrors = (eventRequirements, eventRequirementErrors, setEventRequirements, setEventRequirementErrors) => {
    const updatedEventRequirementErrors = {
        ...eventRequirementErrors,
    };

    const updatedEventRequirements = {
        ...eventRequirements,
    };

    // If the user wanted to provide specific url which he wants, then they must provide Desired livestream url
    if (eventRequirements["eventRequestType"] !== "RECORDING" && !eventRequirementErrors["desiredLivestreamUrl"] && eventRequirements["desiredLivestreamUrl"].trim().length === 0) {
        updatedEventRequirementErrors["desiredLivestreamUrl"] = !eventRequirements["reuseUrl"];
    }

    // Livestream title must be entered and at most 100 characters
    updatedEventRequirementErrors["livestreamTitle"] = !eventRequirements["livestreamTitle"].trim().length || eventRequirements["livestreamTitle"].trim().length > 100;

    // Event name must be entered and at most 100 characters
    updatedEventRequirementErrors["eventName"] = !eventRequirements["eventName"].trim().length || eventRequirements["eventName"].trim().length > 100;

    // Viewer justification must be added if viewer count is less than 250
    updatedEventRequirementErrors["viewerJustification"] = eventRequirements["viewerCount"] === "1-249" && !eventRequirements["viewerJustification"].length;

    // Expected viewer count must be added if viewer count is over 100,000
    updatedEventRequirementErrors["expectedViewerCount"] = eventRequirements["viewerCount"] === "100000+" && !eventRequirements["expectedViewerCount"].length;

    // If the user restricts the stream to specific groups, at least one group must be added
    updatedEventRequirementErrors["restrictedGroups"] = eventRequirements["restricted"] && !eventRequirements["restrictedGroups"].length;

    // At least one streaming platform must be selected for the event
    updatedEventRequirementErrors["platforms"] = !eventRequirements["platforms"].length;

    // If the user wants a Broadcast recording, they must add a Broadcast channel URL
    updatedEventRequirementErrors["previousBroadcastUrl"] = eventRequirements["recordingType"] === "BROADCAST" && !eventRequirements["previousBroadcastUrl"].length;

    // Event description must be provided
    updatedEventRequirementErrors["eventDescription"] = !isRichTextValid(eventRequirements["eventDescription"]);

    // If the user would like to reuse a previous livestream URL, they must provide the URL
    // If the tye of event is recording, then no need of these error validations as existing url field is not part of a recording event.
    if (eventRequirements["eventRequestType"] !== "RECORDING") {
        if (updatedEventRequirements.reuseUrl && eventRequirements["existingLivestreamUrl"].trim().length && (eventRequirements["existingLivestreamUrl"].trim() !== eventRequirements["loadedEventLivestreamUrl"])) {
            updatedEventRequirementErrors["existingLivestreamUrl"] = true;
            setEventRequirements({
                ...eventRequirements,
                existingLivestreamUrlError: "SERIES_URL_MISMATCH",
            });
        } else {
            const existingLivestreamUrlStatus = eventRequirements["reuseUrl"] && !eventRequirements["existingLivestreamUrl"].trim();
            updatedEventRequirementErrors["existingLivestreamUrl"] = existingLivestreamUrlStatus;
            if (existingLivestreamUrlStatus) {
                setEventRequirements({
                    ...eventRequirements,
                    existingLivestreamUrlError: "NO_URL_PROVIDED",
                });
            }
        }
    } else {
        updatedEventRequirementErrors["existingLivestreamUrl"] = false;
    }

    // At least one point of contact must be added to the event
    updatedEventRequirementErrors["pointsOfContact"] = !eventRequirements["pointsOfContact"].length;

    // At least one contact channel for points of contact must be selected
    updatedEventRequirementErrors["contactChannels"] = !eventRequirements["contactChannels"].length;

    // At least one slack workspace must be selected
    updatedEventRequirementErrors["slackWorkspace"] = eventRequirements["allowChat"] && eventRequirements["slackEnabled"] && !eventRequirements["slackWorkspace"].length;

    // At least one slack channel name must be given as input
    if (!eventRequirementErrors["slackChannelName"]) {
        updatedEventRequirementErrors["slackChannelName"] = eventRequirements["allowChat"] && eventRequirements["slackEnabled"] && !eventRequirements["slackChannelName"].trim().length;
    }

    // If the user selected phone as a contact channel, they must provide a phone number
    updatedEventRequirementErrors["phoneNumber"] = eventRequirements["contactChannels"].includes("PHONE") && !eventRequirements["phoneNumber"].length;

    setEventRequirementErrors(updatedEventRequirementErrors);

    return Object.keys(updatedEventRequirementErrors).some((requirement) => updatedEventRequirementErrors[requirement]);
};

// Get the number of presenters for the given array
export const getPresenterCount = (attendeeArray, attendees) => {
    let total = 0;
    attendees.forEach((attendee) => {
        if (attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER && attendeeArray.includes(attendee.email)) {
            total += 1;
        }
    });
    return total;
};

// Get the total number for organizer and presenters for the given array
export const getAttendeeCount = (attendeeArray, attendees) => {
    let total = 0;
    attendees.forEach((attendee) => {
        if ((attendee.isOrganizer || attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER) &&
            attendeeArray.includes(attendee.email)) {
            total += 1;
        }
    });
    return total;
};

// Return true if the required count of free stations can be found during the suggestion time
export const getStationFreeStatus = (suggestion, eventRequirements) => {
    const selectedStations = selectLivestreamStations(suggestion, eventRequirements["requiredStationCount"]);

    return selectedStations.length !== 0;
};

// Get the total number of presenters for a suggestion
export const getTotalPresenters = (suggestion, attendees) => {
    let total = 0;
    attendees.forEach((attendee) => {
        if ((suggestion.freePeople.includes(attendee.email) || suggestion.tentativePeople.includes(attendee.email) || suggestion.unavailablePeople.includes(attendee.email)) &&
            attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER) {
            total += 1;
        }
    });
    return total;
};

// Get the total number for organizer, presenters, and points of contact for a suggestion
export const getTotalAttendees = (suggestion, attendees) => {
    let total = 0;
    attendees.forEach((attendee) => {
        if ((suggestion.freePeople.includes(attendee.email) || suggestion.tentativePeople.includes(attendee.email) || suggestion.unavailablePeople.includes(attendee.email)) &&
            (attendee.isOrganizer || attendee.role === ATTENDEE_ROLE.LIVESTREAM.PRESENTER || attendee.isPointOfContact)) {
            total += 1;
        }
    });
    return total + 1; // add 1 to account for livestream engineers
};

// Get availability tag styling based on livestream engineer and presenter availability
export const getAttendeeAvailabilityType = (suggestion, attendees, eventRequirements) => {
    let organizerFree = false;

    attendees.forEach((attendee) => {
        if (attendee.isOrganizer && suggestion.freePeople.includes(attendee.email)) {
            organizerFree = true;
        }
    });

    // If the organizer or livestream engineers are unavailable return error for tag type
    if (!organizerFree || !getStationFreeStatus(suggestion, eventRequirements)) {
        return "error";
    }

    // If organizer and livestream engineers are available, return styling based on presenter availability
    let free = getPresenterCount(suggestion.freePeople, attendees);
    let total = getTotalPresenters(suggestion, attendees);
    let availability = parseInt((free / total) * 100);

    if (total === 0) {
        // There are no presenters so they are all free
        return "success";
    }

    // Check which threshold the attendee availability is in to display tag styling
    if (availability > ATTENDEE_AVAILABILITY.SUCCESS) {
        return "success";
    } else if (availability > ATTENDEE_AVAILABILITY.WARNING) {
        return "warning";
    } else {
        return "error";
    }
};

// Get text displayed in the attendee availability tag
export const getAttendeeTagText = (suggestion, attendees, eventRequirements) => {
    const presenterCount = getPresenterCount(suggestion.freePeople, attendees);
    const totalPresenters = getTotalPresenters(suggestion, attendees);

    const presenterText = presenterCount === totalPresenters ? "Presenters free" :
        presenterCount === 0 && totalPresenters > 0 ? "Presenters unavailable" : `${presenterCount} of ${totalPresenters} Presenters free`;
    const livestreamEngineerText = `Livestream engineers ${getStationFreeStatus(suggestion, eventRequirements) ? "free" : "unavailable"}`;

    let organizerText = "Organizer unavailable";
    let tagText = "";

    attendees.forEach((attendee) => {
        if (attendee.isOrganizer && suggestion.freePeople.includes(attendee.email)) {
            organizerText = ""; // Do not display organizer availability if they are available
        }
    });

    if (presenterCount === totalPresenters && livestreamEngineerText.includes("free")) {
        tagText = "Presenters and Livestream engineers free";
    } else if ((presenterCount === 0 && totalPresenters > 0) && !livestreamEngineerText.includes("free")) {
        tagText = "Presenters and Livestream engineers unavailable";
    } else {
        tagText = `${presenterText}, ${livestreamEngineerText}`;
    }

    return organizerText ? `${tagText}, ${organizerText}` : tagText;
};

export const workspaces = [
    {label: "ALL Workspaces", url: "https://slack.com"},
    {label: "AWS", url: "https://amzn-aws.slack.com"},
    {label: "Customer Service", url: "https://amzn-custserv.slack.com"},
    {label: "Devices", url: "https://amzn-devices.slack.com"},
    {label: "FGBS (Finance)", url: "https://amzn-fgbs.slack.com"},
    {label: "Global Affairs and Biz' Dev'", url: "https://amzn-gca-bd.slack.com"},
    {label: "Legal", url: "https://amzn-legal.slack.com"},
    {label: "Media & Ads", url: "https://amzn-media.slack.com"},
    {label: "Operations", url: "https://amzn-ops.slack.com"},
    {label: "People eXperience and Technology (HR)", url: "https://amzn-pxt.slack.com"},
    {label: "S-Team", url: "https://amzn-steam.slack.com"},
    {label: "World Wide Consumer (WWC)", url: "https://amzn-wwc.slack.com"},
    {label: "Affinity Groups", url: "https://amzn-affinity.slack.com"},
    {label: "Community", url: "https://amzn-community.slack.com"},
    {label: "Bravehearts", url: "https://amzn-bravehearts.slack.com"},
    {label: "Amazon External", url: "https://amzn-external.slack.com"},
    {label: "Amazon Chime", url: "https://amzn-chime.slack.com"},
    {label: "Audible", url: "https://amzn-audible.slack.com"},
    {label: "Executive Assistants", url: "https://amzn-ea.slack.com"},
    {label: "Graphiq", url: "https://graphiq.slack.com"},
    {label: "I.T.", url: "https://amzn-it.slack.com"},
    {label: "Kuiper", url: "https://amzn-kuiper.slack.com"},
    {label: "Prime Air", url: "https://amzn-primeair.slack.com"},
    {label: "Security", url: "https://amzn-security.slack.com"},
    {label: "WorkChat", url: "https://amzn-workchat.slack.com"},
];

export const LIVESTREAM_ERROR_CODES = {
    SERIES_NOT_EXISTS: "SERIES_NOT_EXISTS",
    EVENT_NOT_EXISTS: "EVENT_NOT_EXISTS"
};

export const getSystemErrorToast = () => {
    const systemErrorToast = getErrorToastWithComponent();
    systemErrorToast.id = `${new Date().getTime()}-schedule`;
    systemErrorToast.toastTimeout = TIMEOUT.NEVER;
    systemErrorToast.toastMessage = "We can’t complete your request at this time. To resolve, please try again later or";
    systemErrorToast.toastActionProps.text = "We can’t complete your request at this time. To resolve, please try again later or";
    systemErrorToast.toastActionProps.linkText = "report any issue to the Livestream team.";
    systemErrorToast.toastActionProps.href = LIVESTREAM_REPORT_HREF;
    systemErrorToast.toastActionProps.linkName = LIVESTREAM_SYSTEM_ERROR;
    systemErrorToast.toastActionProps.componentName = TOAST_COMPONENT.SYSTEM_ERROR;
    return systemErrorToast;
};

export const isValidLivestreamSeriesUrl = (seriesUrl) => {
    try {
        // Allowing alphanumerical values with only two special characters hyphen(-) and underscore(_)
        const pattern = new RegExp("^([\\w-]*)$", "i");
        return pattern.test(seriesUrl);
    } catch (e) {
        return false;
    }
};

export const isValidSlackChannelName = (channelName) => {
    return isValidLivestreamSeriesUrl(channelName); // re-use the validation of desired url for validating slack channel name
}
