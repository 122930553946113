import React from "react"
import PropTypes from "prop-types"

/**
 * This can be passed as a child to the `SearchField` component to render a
 * selectable search suggestion in a popover below that component.
 */
class SearchSuggestion extends React.Component {
  static propTypes = {
    /**
     * The contents of the suggestion.
     *
     * If this is a plain string then matching substrings will automatically be
     * highlighted based on the value of the search field.
     *
     * If this is a function then it will receive an object containing the
     * following keys:
     * - `suggestion` - the value of the `suggestion` prop
     * - `query` - the current value of the search field
     * - `highlighted` - a boolean indicating whether or not the suggestion should
     *   be shown in a highlighted state (this will be true if the user focuses
     *   on the suggestion or if they select it).
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    /**
     * This disables interaction with the component and applies special visual
     * styles to indicate that it's not interactive.
     */
    disabled: PropTypes.bool,
    /**
     * A unique value representing the current suggestion.
     */
    suggestion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * This is called when the user selects the suggestion. The value of the
     * `suggestion` prop is passed as the only argument to this function.
     */
    onClick: PropTypes.func,
  }

  render() {
    // Actual rendering is done via the internal `OptionListItem` component. See
    // ./search-suggestion-popover.js for the implementation.
    return null
  }
}

export default SearchSuggestion
