import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import PopoverController from "../_popover-controller"
import { memoizeTokenStyles } from "../../_utils/token"
import textStyles from "../../_styles/text"
import Sheet from "../sheet"

const popoverStyles = memoizeTokenStyles(
  (t, { anchorWidth }) =>
    css(textStyles(t), {
      boxSizing: "border-box",
      minWidth: anchorWidth,
      overflowY: "auto",
      outline: "none",
      backgroundColor: t("optionListBackgroundColor"),
      borderRadius: t("optionListBorderRadius"),
      border: t.borderCss("optionListBorder"),
      boxShadow: t.boxShadowCss("optionListShadow"),
      zIndex: t("optionListElevation"),
    }),
  ["anchorWidth"]
)

class SelectOptionListContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
    open: PropTypes.bool,
    popoverAnchorNode: PropTypes.object,
    popoverMaxHeight: PropTypes.number,
    t: PropTypes.func,
    type: PropTypes.oneOf(["popover", "bottom-sheet"]),
    /**
     * NOTE: This is only available for the bottom sheet because the popover
     * does not trigger any close events on its own.
     */
    onCloseBottomSheet: PropTypes.func,
    /**
     * NOTE: This is only available for the popover because the bottom sheet's
     * onOpen prop is executed before the bottom sheet is actually rendered to
     * the DOM.
     */
    onOpenPopover: PropTypes.func,
  }

  renderPopover = () => (
    <PopoverController
      open={this.props.open}
      anchorNode={this.props.popoverAnchorNode}
      anchorOrigin="bottom left"
      popoverOrigin="top left"
      offsetVertical={this.props.t("optionListPopoverOffsetVertical")}
      maxHeight={this.props.popoverMaxHeight}
      shrinkToFit={true}
      onOpen={this.props.onOpenPopover}
    >
      {({ popoverStyle, anchorRect }) => (
        <div
          id={this.props.id}
          style={popoverStyle}
          className={popoverStyles(this.props.t, {
            anchorWidth: anchorRect.width,
          })}
        >
          {this.props.children}
        </div>
      )}
    </PopoverController>
  )

  renderBottomSheet = () => (
    <Sheet
      id={this.props.id}
      onClose={this.props.onCloseBottomSheet}
      open={this.props.open}
      type="overlay"
      side="bottom"
    >
      {this.props.children}
    </Sheet>
  )

  render() {
    return this.props.type === "popover"
      ? this.renderPopover()
      : this.renderBottomSheet()
  }
}

export default SelectOptionListContainer
