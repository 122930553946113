// Configs for livestream website
export const ENDPOINTS = {
    Alpha: {
        IAD: "https://beta.live.meetex.enterprise-engineering.aws.dev"
    },
    Beta: {
        IAD: "https://gamma.live.meetex.enterprise-engineering.aws.dev"
    },
    Prod: {
        IAD: "https://livestream.amazon.dev"
    },

    // Dev Config
    aakkn: {
        IAD: "https://beta.live.meetex.enterprise-engineering.aws.dev"
    },
    alvinyu: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    amiyyapu: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    amberyi: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    anirudps: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    camei: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    daverod: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    kjoshuaz: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    mahtabr: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    niskar: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    tejavk: {
        IAD: "https://beta.live.meetex.enterprise-engineering.aws.dev"
    },
    uccdev: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    yangzhuo: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    },
    ziyuyang: {
        IAD: "https://api.beta.live.meetex.enterprise-engineering.aws.dev"
    }
};

export const OPERATION_PATHS = {
    PERMISSION_PEOPLE_SEARCH: "/livestream/api/people/search",
    PERMISSION_GROUP_SEARCH: "/livestream/api/permissions/search",
    CURRENT_USER: "/livestream/api/currentUser",
    CREATE_EVENT_INTAKE: "/livestream/api/eventIntake",
    LATEST_CREATED_EVENT: "/livestream/api/eventSeries/{url}/latestEvent",
    GET_SERIES_URL_AVAILABILITY: "/livestream/api/seriesUrlAvailability/{url}"
};

export const OPERATIONS_METHOD = {
    GET: "GET",
    POST: "POST"
};
