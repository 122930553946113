import React from "react";

import FormInputList from "./form-input-list";
import RadioGroup from "./radio-group";
import FormSection from "./form-section";
import { CALENDAR_VIEW_DESKTOP, CALENDAR_VIEW_MOBILE, SCHEDULING_MODE } from "../settings-constants";

import calendarTokens from "@amzn/meridian-tokens/base/icon/calendar"
import menuTokens from "@amzn/meridian-tokens/base/icon/menu"

import "../settings.css";

const LayoutPreferences = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;

    // Will add this in Phase 2/3
    // const [theme, setTheme] = useState(THEME.LIGHT);

    return (
        <FormSection 
            children={
                <FormInputList 
                    largeMargin={true}
                    screenSizeBreakpoint={screenSizeBreakpoint}
                    inputItems={[
                        {
                            header: "Scheduling mode",
                            inputComponent: 
                            <RadioGroup
                                radios={[
                                    {
                                        value: SCHEDULING_MODE.LIST_MODE,
                                        icon: menuTokens,
                                        descriptions: ["A list of smart suggestions", " for meeting times and places"]
                                    },
                                    {
                                        value: SCHEDULING_MODE.GRID_MODE,
                                        icon: calendarTokens,
                                        descriptions: ["A visual timeline of rooms", " and attendees availability"]
                                    },
                                ]}   
                                checkedValue={props.schedulingMode} 
                                setCheckedValue={props.onChangeSchedulingMode} 
                            />
                        },
                        {
                            header: "Calendar view for desktop and tablet",
                            inputComponent: 
                                <RadioGroup
                                    radios={[
                                        {
                                            value: CALENDAR_VIEW_DESKTOP.AGENDA,
                                        },
                                        {
                                            value: CALENDAR_VIEW_DESKTOP.DAY,
                                        },
                                        {
                                            value: CALENDAR_VIEW_DESKTOP.WORK_WEEK,
                                        },
                                        {
                                            value: CALENDAR_VIEW_DESKTOP.WEEK,
                                        },
                                    ]}   
                                    checkedValue={props.calendarViewDesktop} 
                                    setCheckedValue={props.onChangeCalendarViewDesktop} 
                                />
                        },
                        {
                            header: "Calendar view for mobile device",
                            inputComponent: 
                                <RadioGroup
                                    radios={[
                                        {
                                            value: CALENDAR_VIEW_MOBILE.AGENDA,
                                        },
                                        {
                                            value: CALENDAR_VIEW_MOBILE.DAY,
                                        },
                                    ]}
                                    checkedValue={props.calendarViewMobile} 
                                    setCheckedValue={props.onChangeCalendarViewMobile} 
                                />
                        },
                        // Will add this in future
                        // {
                        //     header: "Theme",
                        //     inputComponent: 
                        //         <RadioGroup
                        //             radios={[
                        //                 {
                        //                     value: THEME.LIGHT,
                        //                 },
                        //                 {
                        //                     value: THEME.DARK,
                        //                 },
                        //                 {
                        //                     value: THEME.CUSTOM,
                        //                 },
                        //             ]}   
                        //             checkedValue={theme} 
                        //             setCheckedValue={setTheme} 
                        //         />
                        // }
                ]}
            />
            }
        />
    );
};

export default LayoutPreferences;