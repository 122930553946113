import React from "react"
import PropTypes from "prop-types"
import parseISO from "date-fns/parseISO"
import startOfMonth from "date-fns/startOfMonth"
import endOfMonth from "date-fns/endOfMonth"
import keycode from "keycode"
import noop from "lodash/noop"
import DatePickerPopover from "../date-picker/date-picker-popover"
import DatePickerPresets from "../date-picker/date-picker-presets"
import DateInputField from "../date-input-field"
import DateRangeInputCalendar from "../_date-range-input-calendar"
import {
  toDateString,
  isWithinRange,
  parseDateString,
} from "../../_utils/date-string"
import dateLocales from "../../_utils/date-locales"

/* eslint-disable react/prop-types */
/**
 * A form control for selecting date ranges.
 */
class DateRangePickerClass extends React.Component {
  /**
   * Return the date that the calendar popup should be initialized to show. If
   * a view date was explicitly set via props then use that, otherwise use the
   * selected date if there is a valid one, otherwise fallback to the current
   * date.
   */
  getCalendarViewDate = () =>
    this.props.defaultViewDate ||
    (parseDateString(this.props.value[0]) && this.props.value[0]) ||
    (parseDateString(this.props.value[1]) && this.props.value[1]) ||
    this.props.now

  state = {
    calendarViewDate: this.getCalendarViewDate(),
    input: "start",
  }

  onChangeField = input => inputValue => {
    const { value, allowSameStartAndEnd, onChange } = this.props
    onChange(
      // The fields should use the exact same logic that the calendar does when
      // it comes to changing dates
      DateRangeInputCalendar.getNextValue({
        value,
        input,
        inputValue,
        allowSameStartAndEnd,
      })
    )
    // Update the view to ensure that the new date is visible
    this.onChangeCalendarViewDate(inputValue)
  }

  /**
   * Change the months shown by the calendar picker to include a given date.
   * This handler ignores empty dates and doesn't do anything if the provided
   * date is already visible in the calendar picker.
   */
  onChangeCalendarViewDate = date => {
    const { calendarViewDate } = this.state
    const parsedCalendarViewDate = parseISO(calendarViewDate)
    const firstDate = toDateString(startOfMonth(parsedCalendarViewDate))
    const lastDate = toDateString(endOfMonth(parsedCalendarViewDate))
    if (date && !isWithinRange(date, firstDate, lastDate)) {
      this.setState({ calendarViewDate: date })
    }
  }

  /**
   * Before opening the popover, make sure that the view date is correct (in
   * case the selected date was changed since the last time the popover was
   * opened or the user messed with the view date before previously closing the
   * popover).
   */
  onBeforeOpenPopover = () =>
    this.onChangeCalendarViewDate(this.getCalendarViewDate())

  onKeyDownEndDateField = event => {
    // If the user hits tab on the end date field (to navigate via keyboard to
    // the calendar input) then move the input selection (not the focus, but the
    // input that will be filled when a date is selected via the calendar) back
    // to the start date. This allows keyboard-only users to follow the normal
    // selection flow (start date first, then end date) after tabbing through
    // the input fields to get to the calendar input.
    if (keycode(event) === "tab") {
      this.setState({ input: "start" })
    }
  }

  renderField = ({
    input,
    clearButton,
    clearButtonLabel,
    onClear,
    ariaDescribedby,
    error,
    popoverIsOpen,
    onFocus,
    onBlur,
  }) => {
    const props = this.props
    const value = props.value[input === "start" ? 0 : 1]
    return (
      <DateInputField
        key={input}
        value={value}
        onChange={this.onChangeField(input)}
        focus={popoverIsOpen && this.state.input === input ? true : undefined}
        disabled={props.disabled}
        disabledDates={props.disabledDates}
        label={this.props[input === "start" ? "startLabel" : "endLabel"]}
        placeholder={
          this.props[input === "start" ? "startPlaceholder" : "endPlaceholder"]
        }
        size={props.size}
        onKeyDown={input === "end" ? this.onKeyDownEndDateField : undefined}
        onFocus={event => {
          this.setState({ input })
          // When an input field is focused and the popover is open, shift the
          // calendar view to include the selected value for that input (start
          // or end date).
          if (popoverIsOpen) {
            this.onChangeCalendarViewDate(value)
          }
          onFocus(event)
        }}
        onBlur={onBlur}
        invalidInputMessage={props.invalidInputMessage}
        error={error}
        locale={props.locale}
        autoFocus={props.autoFocus && input === "start"}
        autoFill={false}
        clearButton={clearButton}
        clearButtonLabel={clearButtonLabel}
        onClear={onClear}
        popoverIsOpen={popoverIsOpen}
        aria-describedby={ariaDescribedby}
      />
    )
  }

  render() {
    const props = this.props
    const { calendarViewDate, input } = this.state
    return (
      <DatePickerPopover
        valueFilled={Boolean(props.value[0] && props.value[1])}
        width={props.width}
        disabled={props.disabled}
        anchor={anchorProps => [
          this.renderField({
            input: "start",
            error: props.startError,
            clearButton: props.clearButton,
            clearButtonLabel: props.startClearButtonLabel,
            onClear: props.onStartClear,
            ariaDescribedby: props.ariaDescribedbyStart,
            ...anchorProps,
          }),
          this.renderField({
            input: "end",
            error: props.endError,
            clearButton: props.clearButton,
            clearButtonLabel: props.endClearButtonLabel,
            onClear: props.onEndClear,
            ariaDescribedby: props.ariaDescribedbyEnd,
            ...anchorProps,
          }),
        ]}
        onBeforeOpen={this.onBeforeOpenPopover}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      >
        {({ t, popoverIsOpen, closePopover }) => [
          <DateRangeInputCalendar
            key="calendar"
            locale={props.locale}
            disabledDates={props.disabledDates}
            value={props.value}
            onChange={value => {
              // Close the popover if we have a complete date range and an end
              // date was just selected
              if (input === "end" && value[0] && value[1]) {
                closePopover()
              } else {
                // Otherwise move the calendar input to the next date
                this.setState({ input: !value[0] ? "start" : "end" })
              }

              // Notify the user of the change
              props.onChange(value)
              if (props.setRefreshSuggestions) {
                props.setRefreshSuggestions(true)
              }
            }}
            viewDate={calendarViewDate}
            onChangeViewDate={this.onChangeCalendarViewDate}
            now={props.now}
            monthsInView={props.monthsInView}
            monthWidth={props.monthWidth}
            previousMonthLabel={props.previousMonthLabel}
            nextMonthLabel={props.nextMonthLabel}
            input={input}
            renderDay={props.renderDay}
            animateMonthChange={popoverIsOpen}
            allowSameStartAndEnd={props.allowSameStartAndEnd}
            ariaLabelStart={props.ariaLabelStart}
            ariaLabelEnd={props.ariaLabelEnd}
          />,
          props.presets.length > 0 ? (
            <DatePickerPresets
              key="presets"
              t={t}
              presets={props.presets}
              label={props.presetsLabel}
              onChange={value => {
                props.onChange(value)
                closePopover()
                if (props.setRefreshSuggestions) {
                  props.setRefreshSuggestions(true)
                }
              }}
              disabledDates={props.disabledDates}
            />
          ) : null,
        ]}
      </DatePickerPopover>
    )
  }
}
/* eslint-enable react/prop-types */

// Keep refs from being passed. We're doing this for 4.x - the
// most recent breaking change - so that we can change the
// _actual_ component to functional later without waiting
// for the next breaking change
const DateRangePicker = props => <DateRangePickerClass {...props} />

DateRangePicker.displayName = "DateRangePicker"

DateRangePicker.propTypes = {
  /**
   * Called any time the user enters a full, valid, enabled date into the date
   * picker. Updated dates will be provided to the function as an array of
   * date strings in the format YYYY-MM-DD.
   *
   * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
   * so you must track the values provided to this function somewhere in state
   * and pass them back via the `value` prop in order to make this component
   * interactive.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * If set to true the user will be able to choose the same date for the start
   * and the end of the date range.
   *
   * This can be helpful if a single date or a date range is acceptable, or if
   * the date range picker is used in conjunction with time pickers (not yet
   * part of Meridian, [+1 the backlog SIM](https://sim.amazon.com/issues/MRDN-438))
   * to allow the selection of a time range that may fall within a single day or
   * span multiple days.
   *
   * @since 4.x
   */
  allowSameStartAndEnd: PropTypes.bool,
  /**
   * Use this attribute to pass a space separated list of ids that you would like to use to
   * describe the start date picker in this DateRange component.
   * ex: This can be used to associate instructions or error labels to this component
   *
   * @added by meetex team member (camei@)
   */
  ariaDescribedbyStart: PropTypes.string,
  /**
   * Use this attribute to pass a space separated list of ids that you would like to use to
   * describe the end date picker in this DateRangePicker component.
   * ex: This can be used to associate instructions or error labels to this component
   *
   * @added by meetex team member (camei@)
   */
  ariaDescribedbyEnd: PropTypes.string,
  /**
   * Use this attribute to associate a text label with calendar popup when selecting the start date
   *
   * @added by meetex team member (camei@)
   */
  ariaLabelStart: PropTypes.string,
  /**
   * Use this attribute to associate a text label with calendar popup when selecting the end date
   *
   * @added by meetex team member (camei@)
   */
  ariaLabelEnd: PropTypes.string,
  /**
   * If set to true then the input will grab focus as soon as it's mounted.
   *
   * This uses HTML's native `autofocus` attribute. Use this sparingly as
   * there are [common accessibility pitfalls](https://www.brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/)
   * associated with this behavior.
   */
  autoFocus: PropTypes.bool,
  /**
   * Appends a button to both the start and end inputs that the user can click to
   * clear the input. This will trigger the `onChange` callback with a value of `""`.
   *
   * @since  5.x
   */
  clearButton: PropTypes.bool,
  /**
   * This date is used to determine which month the calendar should display
   * by default. The date must be provided in the format YYYY-MM-DD. If not
   * provided then the calendar will display the month of `value`, or
   * `now` if no value is provided.
   */
  defaultViewDate: PropTypes.string,
  /**
   * This disables interaction with the entire date picker. If you want to
   * disable the selection of specific dates then use the `disabledDates` prop.
   */
  disabled: PropTypes.bool,
  /**
   * A function that can be used to disable specific dates. The function will
   * be passed date strings in the format YYYY-MM-DD and is expected to return
   * true for disabled dates and false otherwise.
   */
  disabledDates: PropTypes.func,
  /**
   * An accessible label for the end clear button. This will not be visible in the UI, but
   * will be read by screen readers.
   *
   * @since  5.x
   */
  endClearButtonLabel: PropTypes.string,
  /**
   * Used to set the input on the ending range into a more general error state, such as when it
   * cannot be blank.
   */
  endError: PropTypes.bool,
  /**
   * The label for the end date input field.
   */
  endLabel: PropTypes.string,
  /**
   * The placeholder for the start date input field.
   */
  endPlaceholder: PropTypes.string,
  /**
   * A function that returns an error message for invalid or disabled dates.
   * The function must return a localized string. The string will be displayed
   * to the user to help guide them.
   *
   * Two arguments will be passed to the function. The first argument will be an error code - either
   * "INVALID_DATE" if the user's date doesn't exist (e.g. 2018-40-40) or
   * "DISABLED_DATE" if the user's date has been disabled via the
   * `disabledDates` prop. The second argument will be the date entered by the
   * user in the format YYYY-MM-DD. This argument will only be provided for
   * the "DISABLED_DATE" error code (the "INVALID_DATE" code means we couldn't
   * actually construct a date).
   */
  invalidInputMessage: PropTypes.func,
  /**
   * The locale to use when rendering the input (e.g. month names, names of
   * days of the week, etc.). See the [Intl API](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl).
   */
  locale: PropTypes.oneOf(Object.keys(dateLocales)),
  /**
   * Set the width of a single month.
   *
   * @since 4.x
   */
  monthWidth: PropTypes.number,
  /**
   * The number of calendar months to display at a time.
   */
  monthsInView: PropTypes.number,
  /**
   * A localized label for the next month button.
   */
  nextMonthLabel: PropTypes.string,
  /**
   * Define today's date (in the format YYYY-MM-DD). This date will be
   * marked in the calendar with a special indicator.
   */
  now: PropTypes.string,
  /**
   * A list of presets to allow quick selection of common date ranges.
   *
   * If provided, these presets will be shown as buttons in the date picker's
   * calendar popover.
   */
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  /**
   * A localized label to be shown above the list of presets.
   */
  presetsLabel: PropTypes.string,
  /**
   * A localized label for the previous month button.
   */
  previousMonthLabel: PropTypes.string,
  /**
   * A function that can be used to customize the rendering of a single day. The
   * function is passed an object which contains the following props.
   *
   * `date`: `string` of date in the format YYYY-MM-DD
   *
   * `label`: `string` of the localized day of the month (e.g. "21")
   *
   * `current`: `boolean` set to `true` if the date is todays date
   *
   * `selected`: `boolean` set to `true` if date is selected
   *
   * `disabled`: `boolean` set to `true` if date is disabled
   *
   * `range`: `boolean` set to `true` if date is in the range of selected dates
   *
   * @since 4.x
   */
  renderDay: PropTypes.func,
  /**
   * Sets the size of the component using a preset.
   *
   * If the `label` prop is provided this prop will be ignored (the label
   * requires a particular amount of space which is not configurable).
   */
  size: PropTypes.oneOf(["medium", "large", "xlarge"]),
  /**
   * An accessible label for the start clear button. This will not be visible in the UI, but
   * will be read by screen readers.
   *
   * @since  5.x
   */
  startClearButtonLabel: PropTypes.string,
  /**
   * Used to set the input on the starting range into a more general error state, such as when it
   * cannot be blank.
   */
  startError: PropTypes.bool,
  /**
   * The label for the start date input field.
   */
  startLabel: PropTypes.string,
  /**
   * The placeholder for the start date input field.
   */
  startPlaceholder: PropTypes.string,
  /**
   * The currently selected dates as an array of date strings in the format
   * YYYY-MM-DD.
   *
   * Update this when `onChange` is called to ensure the component is interactive
   * (see the documentation for the `onChange` prop).
   */
  value: PropTypes.arrayOf(PropTypes.string),
  /**
   * Set the width of the component using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   *
   * @since 5.x
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * This is called when the entire component (inputs + popover calendars) loses focus.
   * The [blur event](https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event) is passed as the first argument to the function.
   *
   * @since 5.x
   */
  onBlur: PropTypes.func,
  /**
   * This is called when the end clear button is clicked (be sure to set
   * `endClearButton` to `true`). No arguments are passed to this callback.
   *
   * @since 5.x
   */
  onEndClear: PropTypes.func,
  /**
   * This is called when the entire component (inputs + popover calendars) gains focus.
   * The [focus event](https://developer.mozilla.org/en-US/docs/Web/API/Element/focus_event) is passed as the first argument to the function.
   *
   * @since 5.x
   */
  onFocus: PropTypes.func,
  /**
   * This is called when the start clear button is clicked (be sure to set
   * `startClearButton` to `true`). No arguments are passed to this callback.
   *
   * @since 5.x
   */
  onStartClear: PropTypes.func,
}

DateRangePicker.defaultProps = {
  locale: "en-US",
  disabledDates: noop,
  now: toDateString(new Date()),
  presets: [],
  value: [],
  size: "medium",
  invalidInputMessage: code =>
    ({
      INVALID_DATE: "This value is not a valid date.",
      DISABLED_DATE: "This date is not available.",
    }[code]),
}

export default DateRangePicker
