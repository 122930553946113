/**
 * Flip an alignment string (e.g. from "top" to "bottom" etc)
 */
const flipAlignment = alignment =>
  ({
    top: "bottom",
    bottom: "top",
    left: "right",
    right: "left",
    center: "center",
  }[alignment])

/**
 * Flip an origin (in the form of { x: "left", y: "bottom"}) across either one
 * or both axis.
 */
const flipOrigin = (origin, flipX, flipY) => ({
  x: flipX ? flipAlignment(origin.x) : origin.x,
  y: flipY ? flipAlignment(origin.y) : origin.y,
})

/**
 * Given an anchor origin, popover origin, anchor rectangle, viewport rectangle,
 * any arbitrary extra space, and an axis, determine how much space is available
 * in the viewport along the provided axis to render a popover.
 */
const getAvailableSpaceOnAxis = (
  axis,
  { anchorOrigin, popoverOrigin, anchorRect, viewportRect, extraSpace }
) => {
  const edge = axis === "x" ? "left" : "top"
  const dimension = axis === "x" ? "width" : "height"
  const oppositeEdge = flipAlignment(edge)
  // Determine the position along the requested axis in the viewport that
  // represents the edge of the available space for the popover
  const popoverEdge =
    anchorOrigin[axis] === edge
      ? anchorRect[edge]
      : anchorOrigin[axis] === oppositeEdge
      ? anchorRect[edge] + anchorRect[dimension]
      : anchorOrigin[axis] === "center"
      ? anchorRect[edge] + anchorRect[dimension] / 2
      : undefined
  // Calculate the available space along the requested axis that the popover can
  // use to render
  const availableSpace =
    popoverOrigin[axis] === edge
      ? viewportRect[dimension] - popoverEdge - extraSpace[axis]
      : popoverOrigin[axis] === oppositeEdge
      ? popoverEdge - extraSpace[axis]
      : popoverOrigin[axis] === "center"
      ? 2 *
        (Math.min(viewportRect[dimension] - popoverEdge, popoverEdge) -
          extraSpace[axis])
      : undefined
  return Math.max(0, availableSpace)
}

// Given an anchor origin, popover origin, anchor rectangle, viewport rectangle,
// and any arbitrary extra space, determine how much space is available in the
// viewport to render a popover.
const getAvailableSpace = args => {
  return {
    x: getAvailableSpaceOnAxis("x", args),
    y: getAvailableSpaceOnAxis("y", args),
  }
}

/**
 * Given an axis and a bunch of other information, this determines the
 * transform that, when applied to the popover and anchor origins, will result
 * in the best origins to display the popover. The transform will either be a
 * shift left or right (or up or down) along the axis (e.g. center -> left or
 * center -> right) or a flip across the axis entirely (to the other side of the
 * anchor).
 *
 * Required args:
 *  - anchorOrigin
 *  - popoverOrigin
 *  - anchorRect
 *  - viewportRect
 *  - popoverRect
 *  - extraSpace
 */
const getBestOriginTransformOnAxis = (axis, args) => {
  const getSpace = (anchorOrigin, popoverOrigin) =>
    getAvailableSpaceOnAxis(axis, { ...args, anchorOrigin, popoverOrigin })
  const edge = axis === "x" ? "left" : "top"
  const dimension = axis === "x" ? "width" : "height"
  const oppositeEdge = flipAlignment(edge)
  const spaceWithPreferredOrigin = getSpace(
    args.anchorOrigin,
    args.popoverOrigin
  )
  const preferredOriginIsGood =
    spaceWithPreferredOrigin >= args.popoverRect[dimension]
  // If the popover is center-aligned on the anchor then try shifting it
  // left/right or up/down (depending on the axis)
  const tryShift =
    args.popoverOrigin[axis] === "center" &&
    args.anchorOrigin[axis] === "center" &&
    !preferredOriginIsGood
  const spaceShiftedStart = tryShift
    ? getSpace(
        { ...args.anchorOrigin, [axis]: edge },
        { ...args.popoverOrigin, [axis]: edge }
      )
    : 0
  const spaceShiftedEnd = tryShift
    ? getSpace(
        { ...args.anchorOrigin, [axis]: oppositeEdge },
        { ...args.popoverOrigin, [axis]: oppositeEdge }
      )
    : 0
  const shift =
    spaceShiftedStart > spaceWithPreferredOrigin &&
    spaceShiftedStart > spaceShiftedEnd
      ? edge
      : spaceShiftedEnd > spaceWithPreferredOrigin
      ? oppositeEdge
      : undefined
  // If shifting didn't work then try flipping the popover over the axis
  // entirely (to the other side of the anchor).
  const tryFlip = !preferredOriginIsGood && !shift
  const flip =
    tryFlip &&
    getSpace(
      flipOrigin(args.anchorOrigin, axis === "x", axis === "y"),
      flipOrigin(args.popoverOrigin, axis === "x", axis === "y")
    ) > spaceWithPreferredOrigin
  return shift
    ? { type: "shift", axis, alignment: shift }
    : flip
    ? { type: "flip", axis }
    : { type: "none" }
}

/**
 * This is used to apply a transform from `getBestOriginTransformOnAxis` to an
 * origin object.
 */
const transformOrigin = (transform, origin) => {
  switch (transform.type) {
    case "shift":
      return { ...origin, [transform.axis]: transform.alignment }
    case "flip":
      return flipOrigin(origin, transform.axis === "x", transform.axis === "y")
    default:
      return origin
  }
}

/**
 * Determine the anchor and popover origins that will work best for displaying
 * the popover. If there is enough space to show the popover at the origins
 * requested by the user then that will always be treated as the best option.
 * If there is not enough space at those origins *and* there are alternate
 * origins that will buy us more space, the origins that will give us the most
 * space are treated as the best option.
 *
 * Required args:
 *  - anchorOrigin
 *  - popoverOrigin
 *  - anchorRect
 *  - viewportRect
 *  - popoverRect
 *  - extraSpace
 *
 * Note that a popover may be shifted side to side or flipped over it's anchor
 * entirely, but it will never moved to an adjacent side (e.g. from the left to
 * the top). Designers/developers should be able to choose good enough defaults
 * for their popovers that this kind of transform won't be necessary to render
 * the popovers within the viewport.
 */
const getBestOrigin = args => {
  // Determine the transforms along the x and y axis that will result in the
  // best display of the popover (not running off any edges of the viewport).
  const transformX = getBestOriginTransformOnAxis("x", args)
  const transformY = getBestOriginTransformOnAxis("y", args)
  // Apply the transforms to both the anchor origin and the popover origin
  const bestAnchorOrigin = transformOrigin(
    transformY,
    transformOrigin(transformX, args.anchorOrigin)
  )
  const bestPopoverOrigin = transformOrigin(
    transformY,
    transformOrigin(transformX, args.popoverOrigin)
  )
  // Return the new origins along with the available space that they obtained
  // for the popover
  return {
    anchorOrigin: bestAnchorOrigin,
    popoverOrigin: bestPopoverOrigin,
    availableSpace: getAvailableSpace({
      ...args,
      anchorOrigin: bestAnchorOrigin,
      popoverOrigin: bestPopoverOrigin,
    }),
  }
}

export { flipAlignment, flipOrigin, getAvailableSpace, getBestOrigin }
