import React from "react";

import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link";
import Alert from "@amzn/meridian/alert";

import { REFRESH_ALERT_TIMER } from "../shared-constants";

const RefreshAlert = (props) => {
    const page = props.page;
    const onRefresh = props.onClick;

    return (
        <Alert size="large" type="informational">
            <Text type="h100">Your {page} is { parseInt(REFRESH_ALERT_TIMER / 1000 / 60) }+ minutes out of date. &nbsp;
                <Link onClick={() => onRefresh()}>
                    Click here to refresh your {page}.
                </Link>
            </Text>
        </Alert>
    );
};

export default RefreshAlert;