import React, { useEffect } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Input from "../../shared/meridian-custom-components/src/components/input";
import InputGroup from "../../shared/meridian-custom-components/src/components/input-group";
import Select, { SelectOption } from "../meridian-custom-components/src/components/select";

import { ALERTS, SCREEN_SIZE } from "../shared-constants";
import { DURATION } from "../../meeting-scheduler/meeting-scheduler-constants";
import { FIFTEEN_MINUTES } from "../custom-calendar-time-grid/custom-calendar-time-grid-constants";

const DURATION_SELECT_OPTIONS = [
    {
        label: "15 mins",
        value: 15
    },
    {
        label: "25 mins*",
        value: 25
    },
    {
        label: "30 mins",
        value: 30
    },
    {
        label: "45 mins",
        value: 45
    },
    {
        label: "55 mins*",
        value: 55
    },
    {
        label: "1 hour",
        value: 60
    },
    {
        label: "1 hour, 30 mins",
        value: 90
    },
    {
        label: "2 hours",
        value: 120
    },
    {
        label: "3 hours",
        value: 180
    },
    {
        label: "Custom...",
        value: "custom"
    },
];

const LabeledDurationSelect = (props) => {
    const fifteenMinutesSelectionOnly = props.fifteenMinutesSelectionOnly;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const setRefreshSuggestions = props.setRefreshSuggestions;
    const [durationSelected, setDurationSelected] = [props.durationSelected, props.setDurationSelected];
    const [customDurationValue, setCustomDurationValue] = [props.customDurationValue, props.setCustomDurationValue];
    const [customDurationSelected, setCustomDurationSelected] = [props.customDurationSelected, props.setCustomDurationSelected];
    const [customDurationLabel, setCustomDurationLabel] = [props.customDurationLabel, props.setCustomDurationLabel];
    const [customDurationError, setCustomDurationError] = [props.customDurationError, props.setCustomDurationError];
    // We do not currently need isAllDayEvent defined to disable the custom duration selector
    // If we add the ability to set the isAllDayEvent flag again, we may need to add this back. Commenting for now
    // const [isAllDayEvent, setIsAllDayEvent] = [props.isAllDayEvent, props.setIsAllDayEvent];
    const setIsAllDayEvent = props.setIsAllDayEvent;

    const LABELED_DURATION_SELECT_LABEL_ID = "labeled-duration-select-label-id";
    const CUSTOM_LABELED_DURATION_UNIT_SELECT_LABEL_ID = "custom-labeled-duration-unit-select-label-id";

    const onChangeDurationSelected = (value) => {
        setDurationSelected(value);
        setIsAllDayEvent(value === "all day");
        setCustomDurationError(false);

        if (value === "all day") {
            setCustomDurationValue(1);
            setCustomDurationSelected("day");
            getCustomDurationLabel(1, "day");
        }
        if (setRefreshSuggestions) {
            setRefreshSuggestions(true);
        }
    };

    const onChangeCustomDurationValue = (value) => {
        getCustomDurationLabel(value);
        setCustomDurationValue(value);
        if (setRefreshSuggestions) {
            setRefreshSuggestions(true);
        }
    };

    const onChangeCustomDurationSelected = (value) => {
        getCustomDurationLabel(undefined, value);
        setCustomDurationSelected(value);
        if (setRefreshSuggestions) {
            setRefreshSuggestions(true);
        }
    };

    // Update the custom duration label based on the inputted value
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getCustomDurationLabel = (duration = customDurationValue, durationSelected = customDurationSelected) => {
        let customDurationLabel = "";
        if (!duration) {
            customDurationLabel = "invalid";
            setCustomDurationError(true);
        } else {
            let durationInMinutes = 0;

            if (durationSelected === "min") {
                durationInMinutes = parseInt(duration);
            } else if (durationSelected === "hour") {
                durationInMinutes = parseInt(duration * 60);
            } else if (durationSelected === "day") {
                durationInMinutes = parseInt(duration * 60 * 24);
            };

            if (durationInMinutes <= 0) {
                customDurationLabel = "invalid";
                setCustomDurationError(true);
            } else if (durationInMinutes > DURATION.MAX_MINUTES) {
                customDurationLabel = `cannot exceed ${DURATION.MAX_DAYS} days`;
                setCustomDurationError(true);
            } else {
                setCustomDurationError(false);

                let customDurationLabelArray = [];

                let days = parseInt(durationInMinutes / 60 / 24);
                let hours = parseInt(durationInMinutes / 60 % 24);
                let minutes = parseInt(durationInMinutes % 60);

                if (days) {
                    days === 1 ? customDurationLabelArray.push("1 day") : customDurationLabelArray.push(days + " days");
                }
                if (hours) {
                    hours === 1 ? customDurationLabelArray.push("1 hour") : customDurationLabelArray.push(hours + " hours");
                }
                if (minutes) {
                    minutes === 1 ? customDurationLabelArray.push("1 min") : customDurationLabelArray.push(minutes + " mins");
                }

                customDurationLabel = customDurationLabelArray.join(", ");
            }
        }

        setCustomDurationLabel(customDurationLabel);
    };

    // Update the custom duration label if the value changes
    useEffect(() => {
        getCustomDurationLabel();
    }, [customDurationValue, getCustomDurationLabel]);

    const getSelectOptions = () => {
        let options = DURATION_SELECT_OPTIONS;
        if (fifteenMinutesSelectionOnly) {
            options = options.filter(({label, value}) => typeof(value) === "number" && value % FIFTEEN_MINUTES === 0);
        }

        return options.map(({label, value}) => <SelectOption label={label} value={value} />)
    };

    return (
        <Row width={props.width || (screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "" : "330px")} wrap="down" spacing="small" alignmentVertical="stretch">
            <Column spacing="none">
                <Text id={LABELED_DURATION_SELECT_LABEL_ID} type="b100" alignment="left">Duration</Text>
                <Select
                    disabled={props.disabled}
                    width={props.width || (screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? ((durationSelected === "all day" || durationSelected === "custom") ? "150px" : "150px") : "330px")}
                    size={props.size || "small"}
                    value={durationSelected}
                    onChange={onChangeDurationSelected}
                    placeholder="Select meeting duration"
                    aria-labelledby={LABELED_DURATION_SELECT_LABEL_ID}
                    aria-describedby={ALERTS.MEETING_DURATION_LONGER_THAN_TIME_WINDOW.id}
                >
                    {getSelectOptions()}
                </Select>
                {(durationSelected === 25 || durationSelected === 55) &&
                    <Text type="b100">
                        *Suggestions offset 5 mins
                    </Text>
                }
            </Column>
            {(durationSelected === "all day" || durationSelected === "custom") &&
                <Column width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "150px" : "330px"} spacing="none">
                    <Text type="b100">&nbsp;</Text>
                    <div style={{display: "none"}}>
                        <Text id={CUSTOM_LABELED_DURATION_UNIT_SELECT_LABEL_ID}>Custom duration unit</Text>
                    </div>
                    <InputGroup>
                        <Input
                            width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "70px" : "165px"}
                            size={props.size || "small"}
                            type="number"
                            pattern={durationSelected === "all day" ? /^[0-9]*$/ : /^\d+\.?\d*$/}
                            value={customDurationValue}
                            onChange={onChangeCustomDurationValue}
                            error={customDurationError}
                            aria-label="Custom duration"
                            aria-describedby={[ALERTS.INVALID_MEETING_DURATION.id, ALERTS.MEETING_DURATION_NOT_SPECIFIED.id].join(" ")}
                        />
                        <Select
                            width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "80px" : "165px"}
                            size={props.size || "small"}
                            value={customDurationSelected}
                            onChange={onChangeCustomDurationSelected}
                            // disabled={isAllDayEvent}
                            placeholder="Select custom duration unit"
                            aria-labelledby={CUSTOM_LABELED_DURATION_UNIT_SELECT_LABEL_ID}
                        >
                            <SelectOption label="min" value="min" />
                            <SelectOption label="hour" value="hour" />
                            <SelectOption label="day" value="day" />
                        </Select>
                    </InputGroup>
                    <Text type="b100" color={customDurationError ? "error" : null}><b>Duration</b> {customDurationLabel}</Text>
                </Column>
            }
        </Row>
    );
};

export default LabeledDurationSelect;
