const identityNamespace = "identity";

// Identity to be set only once, on startup
export const SET_IDENTITY = `${identityNamespace}.set_identity`;

export const GET_PERSON = `${identityNamespace}.get_person`;

export const SET_CURRENT_USER = `${identityNamespace}.set_current_user`;

export const UPDATE_SIGNING_CREDENTIALS = `${identityNamespace}.update_signing_credentials`;
export const UPDATE_IDENTITY_CREDENTIALS = `${identityNamespace}.update_identity_credentials`;
export const UPDATE_STAGE_CONFIG = `${identityNamespace}.update_stage_config`;

export const GET_SIGNING_CREDENTIALS_ASYNC = `${identityNamespace}.get_signing_credentials_async`;

export const REFRESH_TOKENS = `${identityNamespace}.refresh_tokens`;
