import React from "react";

import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";

const NoMeetingsOnCalendar = (props) => {
    return (
        <Row alignmentVertical="center" alignmentHorizontal="center" spacingInset="medium">
            <Column width="100%" type="outline" backgroundColor="primary">
                <Row widths="fill" alignmentHorizontal="center" spacingInset="medium">
                    <Column alignmentHorizontal="center" spacingInset="xlarge">
                        <Heading type="h200" level={2}>
                            No meetings to display{props.emptyDueToFilters ? " with current filters" : ""}
                        </Heading>
                    </Column>
                </Row>
            </Column>
        </Row>
    );
};

export default NoMeetingsOnCalendar;