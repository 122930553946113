const namespace = 'meeting';

export const UPDATE_MEETING_ASYNC = `${namespace}.update_meeting_async`;
export const ADD_ATTENDEE_ASYNC = `${namespace}.add_attendee_async`;
export const REMOVE_ATTENDEE_ASYNC = `${namespace}.remove_attendee_async`;

export const UPDATE_CURRENT_MEETING = `${namespace}.update_current_meeting`;
export const ADD_ATTENDEE = `${namespace}.add_attendee`;
export const REMOVE_ATTENDEE = `${namespace}.remove_attendee`;
export const SAVE_DRAFT = `${namespace}.save_draft`;
export const CLEAR_DRAFT = `${namespace}.clear_draft`;

export const SEARCH_SUGGESTION = `${namespace}.search_suggestion`;
export const SELECT_SUGGESTION = `${namespace}.select_suggestion`;
export const SET_SUGGESTION_VIEW_TYPE = `${namespace}.set_suggestion_view_type`;

export const CHANGE_STEP = `${namespace}.change_step`;

export const SET_MASTER = `${namespace}.set_master`;
export const SET_CREATING_MEETING_STATE = `${namespace}.set_creating_meeting_state`;
export const UPDATE_RECURRENCE = `${namespace}.update_recurrence`;

export const SET_LOAD_MEETING = `${namespace}.set_load_meeting`;