module.exports = {
    STEP: {
        ATTENDEES_AND_ROOMS: "ATTENDEES_AND_ROOMS",
        SMART_SUGGESTIONS: "SMART_SUGGESTIONS",
        MEETING_AGENDA_AND_REVIEW: "MEETING_AGENDA_AND_REVIEW",
    },
    STATUS: {
        ONGOING: "Ongoing",
        READY: "Ready",
        COMPLETE: "Complete",
        CANCELED: "Canceled",
        OVERDUE: "Overdue",
    },
    PRIORITY: {
        REQUIRED: {
            NUMBER: 0,
        },
        OPTIONAL: {
            NUMBER: 1,
        }
    },
    SCREEN_SIZE: {
        POLL_RESULT_MOBILE_VIEW: 360,
        POLL_RESULT_FULL_VIEW: 500,
        RESPOND_PAGE_FULL_VIEW: 1200,
        RESPOND_PAGE_PARTIAL_VIEW: 900,
        RESPOND_PAGE_PARTIAL_MOBILE_VIEW: 550,
        RESPOND_PAGE_MOBILE_VIEW: 360,
    },
    RESULT_PAGE_SIZE: {
        MOBILE_VIEW_WIDTH: "360px",
        CARD_MOBILE_VIEW_WIDTH: "260px",
        CARD_WIDTH: "424px",
        SHEET_MIN_HEIGHT: "960px",
        REMINDER_BUTTON_MIN_WIDTH: "130px",
    },
    POLL_SORT_BY: {
        CREATE_TIME: "createTime",
        TITLE: "subject",
        STATUS: "status",
        DUE_DATE: "expiration",
        VOTES_RESPONSE_RATIO: "votesResponseRatio"
    },
    THIRTY_DAYS_IN_MS: 30 * 24 * 60 * 60 * 1000,
    ONE_DAY_IN_MS: 24 * 60 * 60 * 1000,
    EDIT_POLL_ID: "edit-poll-id",
    DEFAULT_ORGANIZER_NAME: "organizer",
    RESPOND_PAGE_SIZE: {
        PAGE_MAX_WIDTH: "1200px",
        DETAILS_BOX_MIN_HEIGHT: "866px",
        DETAILS_BOX_MAX_WIDTH: "456px",
        DETAILS_BOX_MIN_WIDTH: "330px",
        AVAILABILITY_BOX_MIN_HEIGHT: "500px",
        AVAILABILITY_BOX_MAX_WIDTH: "592px",
        AVAILABILITY_BOX_MIN_WIDTH: "330px",
        CARD_MAX_WIDTH: "472px",
        AGENDA_PAGE_MAX_WIDTH: "680px",
    },
    MAX_TIME_SLOTS: 10,
    MAX_TIME_SLOTS_MESSAGE: "You can select up to 10 time slots.",
    CREATE_POLL_TEXT_EDITOR: {
        HEIGHT: 200
    },
    CREATE_POLL_CALENDAR: {
        HEIGHT: 600
    },
    CREATE_POLL_TIME_CELL: {
        MINUTES: 15, // minutes for each time cell in the calendar
    },
    CALENDAR_RESPONSE_FILTER: {
        ACCEPT: "accept",
        TENTATIVE: "tentative",
        NOT_RESPONDED: "notResponded",
    },
    CALENDAR_STATUS_FILTER: {
        BUSY: "busy",
        TENTATIVE: "tentative",
        OUT_OF_OFFICE: "outOfOffice",
    },
    VIEW_TYPE: {
        AGENDA: "agenda"
    },
    MAPS_WIKI_LINK: "https://w.amazon.com/bin/view/Meetex/AM2/Roadmaps/MAPS/meeting_poll/",
    REMINDER_STATUS: {
        DEFAULT: "default",
        SUCCESS: "success",
        FAILURE: "failure",
        LOADING: "loading",
        DUPLICATE: "duplicate",
    },
    REMINDER_TIMEOUT_IN_MS:{
        SUCCESS: 2000,
        FAILURE: 30000,
    },
    AVAILABILITY_STATUS: {
        CONFLICT: "Conflict",
        OUT_OF_THE_OFFICE: "OOTO"
    }
 };
