import React from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import { getMeetingStatusColor } from "../../shared/meeting-status-utils";
import { STATUS_LABEL } from "../../shared/meeting-status-constants";

import GrayStripesBackground from "../../../assets/backgrounds/gray_stripes_small.svg";

const StatusLegend = (props) => {
    const orientation = props.orientation;
    const statuses = props.statuses;
    const showStripes = props.showStripes;

    const renderStatusIcon = (status) => {
        let backgroundStripes;
        let backgroundPosition;
        let statusBorder = "";
        let borderRadius = "50%";

        if (showStripes) {
            switch (status) {
                case "meetingHours":
                    statusBorder = "1px solid rgba(105, 117, 118, 0.5)";
                    borderRadius = "";
                    break;
                case "noData":
                    backgroundStripes = GrayStripesBackground;
                    backgroundPosition = "-1px -1px";
                    statusBorder = "1px solid rgba(105, 117, 118, 0.5)";
                    break;
                default:
                    backgroundStripes = "";
                    backgroundPosition = "";
            }
        }

        if (status === "free" || status === "canceled" || status === "available") {
            statusBorder = "0.5px solid #485050"; // Gray-600
        }

        return (
            <Row key={status} alignmentVertical="center" spacing="small">
                <div style={{
                    backgroundColor: getMeetingStatusColor(status),
                    backgroundImage: `url(${backgroundStripes})`,
                    backgroundPosition: backgroundPosition,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "16px",
                    border: statusBorder,
                    borderRadius: borderRadius,
                    display: "inline",
                    height: "16px",
                    width: "16px",
                }} />
                <Text tag="span">{STATUS_LABEL[status]}</Text>
            </Row>
        );
    };

    return (
        orientation === "column" ?
            <Column spacing="xsmall">
                {statuses.map((status) => (
                    renderStatusIcon(status)
                ))}
            </Column>
            :
            <Row alignmentHorizontal="center" wrap="down">
                {statuses.map((status) => (
                    renderStatusIcon(status)
                ))}
            </Row>
    );
}

export default StatusLegend;