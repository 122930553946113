import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { postRatingMetric } from "../../metrics/actions";
import MeetingsFeedback from "./meetings-feedback";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Modal from "@amzn/meridian/modal";
import Icon from "@amzn/meridian/icon";
import Heading from "@amzn/meridian/heading";
import Alert from "@amzn/meridian/alert";

import closeTokens from "@amzn/meridian-tokens/base/icon/close-small";
import HeadingPattern from "../../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";
import { COMPONENT_IDS } from "../../accessibility-constants";
import { clearFeedback, setIsEligible } from "../actions";

const MeetingsFeedbackModal = (props) => {
    const userFeedback = props.userFeedback;
    const userRating = props.userRating;
    const onUpdateUserFeedback = props.onUpdateUserFeedback;
    const onUpdateUserRating = props.onUpdateUserRating;
    const onClearFeedback = props.onClearFeedback;
    const feedbackAlertMessage = props.feedbackAlertMessage;
    const [tellUsWhy, setTellUsWhy] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();
    const onClose = () => {
        if (submitted && userRating) {
            dispatch(postRatingMetric(userFeedback, userRating, true, true));
        }
        // Post rating metric if user did not submit
        else if (!submitted) {
            dispatch(postRatingMetric(userFeedback, userRating, false, true));
        }
        // Reset state variables on close
        props.onClose();
        setTellUsWhy(false);
        setSubmitted(false);
        dispatch(setIsEligible(false));
        dispatch(clearFeedback());
    };

    return (
        <Modal
            open={props.open}
            anchorNode={props.anchorNode}
            width="500px"
            height="372px"
            bodySpacingInset="none"
        >
            <div style={{
                height: "56px",
                padding: "0",
                backgroundImage: `url(${HeadingPattern})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: "rgb(26, 69, 78)",
                backgroundPosition: "-50px -90px",
                width: "100%"
                }}
            >
                    <span
                        aria-label="Close"
                        role="button"
                        id="close"
                        tabIndex="0"
                        style={{
                            float: "right",
                            display: "inline-block",
                            padding: "10px 10px",
                            cursor: "pointer",
                            color: "#FFFFFF"
                        }}
                        onKeyDown={onClose}
                        onClick={() => onClose()}
                    >
                    <Icon tokens={closeTokens} />
                </span>
                <div style={{paddingLeft: "25px"}}>
                    <Column spacingInset="medium">
                        <Heading type="h300" level={2} color="inverted">
                            Rate your experience
                        </Heading>
                    </Column>
                </div>
            </div>
            <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                <Column spacing="small" alignmentHorizontal="left" spacingInset="100">
                    <div style={{
                        left: "30px",
                        paddingLeft: "10px",
                        paddingTop: "24px",
                        visibility: !submitted ? "visible" : "hidden"
                        }}
                    >
                        <div style={{visibility: !userRating && !submitted ? "visible" : "hidden"}}>
                            <Row style={{visibility: !userRating && !submitted ? "visible" : "hidden"}}>
                                <Alert type="success" size="small" children={feedbackAlertMessage} />
                            </Row>
                        </div>
                        <div style={{
                            paddingTop: "24px",
                            paddingBottom: "10px",
                            visibility: !submitted ? "visible" : "hidden"
                            }}
                        >
                            <span style={{fontWeight: 'bold'}}>
                                {tellUsWhy ?
                                    "(Optional) Please tell us why"
                                    :
                                    "How was your experience using Amazon Meetings?"
                                }
                            </span>
                        </div>
                    </div>
                </Column>
                <div style={{paddingBottom: "30px"}}>
                    <MeetingsFeedback
                        id={COMPONENT_IDS.FEEDBACK}
                        userRating={userRating}
                        tellUsWhy={tellUsWhy}
                        userFeedback={userFeedback}
                        submitted={submitted}
                        onUpdateUserRating={onUpdateUserRating}
                        setTellUsWhy={setTellUsWhy}
                        onUpdateUserFeedback={onUpdateUserFeedback}
                        setSubmitted={setSubmitted}
                        onClearFeedback={onClearFeedback}
                        onClose={onClose}
                        isModal={true}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default MeetingsFeedbackModal;