import React from "react";

import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import TimeSelect, { timeRange } from "../meridian-custom-components/src/components/time-select";

import { ALERTS, TIME_FORMAT, SCREEN_SIZE, TIME_CONSTANT } from "../shared-constants";
import { TIME_FORMAT as SETTINGS_TIME_FORMAT } from "../../shared/settings/settings-constants";
import { formattedTimeToTimeUnit, renderDurationLabel } from "../time-utils";
import { getDefaultTimeFormat } from "../settings/settings-utils";

const LabeledTimeSelect = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const isAllDayEvent = props.isAllDayEvent;
    const timeFormat = props.timeFormat;
    const setRefreshSuggestions = props.setRefreshSuggestions;

    const [startTime, setStartTime] = [props.startTime, props.setStartTime];
    const [endTime, setEndTime] = [props.endTime, props.setEndTime];
    const [timeLabel, setTimeLabel] = [props.timeLabel, props.setTimeLabel];

    const BETWEEN_LABEL_ID = "between-label-id";
    const AND_LABEL_ID = "end-time-label-id";
    const INVALID_TIME_WINDOW_ERROR_ID = "invalid-time-window-error-id";

    // Create an array of times from 12:15 AM to 12:00 AM
    let endTimeRange = timeRange({
        start: "00:15:00",
        end: "23:59:59",
        step: TIME_CONSTANT.FIFTEEN_MIN * TIME_CONSTANT.ONE_MIN_IN_SEC,
    });
    endTimeRange.push("00:00:00");

    const onChangeStartTime = (value) => {
        updateTimeLabel(value, endTime);
        setStartTime(value);
        if (setRefreshSuggestions) {
            setRefreshSuggestions(true);
        }
    };

    const onChangeEndTime = (value) => {
        updateTimeLabel(startTime, value);
        setEndTime(value);
        if (setRefreshSuggestions) {
            setRefreshSuggestions(true);
        }
    };

    // Update the time window label with the selected number of hours
    const updateTimeLabel = (startTime, endTime) => {
        // if end time is 12:00 AM, then treat it as midnight of the next day
        if (endTime === "00:00:00") {
            endTime = "24:00:00";
        }

        const startTimeSec = formattedTimeToTimeUnit(startTime, TIME_FORMAT.HH_MM_SS, TIME_CONSTANT.SEC_NAME);
        const endTimeSec = formattedTimeToTimeUnit(endTime, TIME_FORMAT.HH_MM_SS, TIME_CONSTANT.SEC_NAME);
        setTimeLabel(renderDurationLabel(startTimeSec, endTimeSec, TIME_CONSTANT.SEC_NAME));
    };

    const timeSelectWidth = props.timeSelectWidth || "130px";
    const timeSelectMobileWidth = props.timeSelectMobileWidth || "161px";
    const rowSpacing = props.rowSpacing || "small";

    return (
        <Column spacing="none">
            <Row spacing={rowSpacing} widths={["50%", "50%"]}>
                <Column minWidth="130px" spacing="none">
                    <Text id={BETWEEN_LABEL_ID} type="b100">Between</Text>
                    {startTime ?
                        <TimeSelect
                            width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? timeSelectWidth : timeSelectMobileWidth}
                            value={isAllDayEvent ? "00:00:00" : startTime}
                            onChange={onChangeStartTime}
                            size="small"
                            placeholder="Select start time"
                            options={timeRange({
                                start: "00:00:00",
                                end: "23:59:59",
                                step: TIME_CONSTANT.FIFTEEN_MIN * TIME_CONSTANT.ONE_MIN_IN_SEC,
                            })}
                            format={getDefaultTimeFormat(timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR)}
                            disabled={isAllDayEvent}
                            error={timeLabel === "invalid"}
                            aria-labelledby={BETWEEN_LABEL_ID}
                            aria-describedby={[ALERTS.INVALID_TIME_WINDOW.id, INVALID_TIME_WINDOW_ERROR_ID].join(" ")}
                        />
                        :
                        <Loader size="small" />
                    }
                </Column>
                <Column minWidth="130px" spacing="none">
                    <Text id={AND_LABEL_ID} type="b100">And</Text>
                    {endTime ?
                        <TimeSelect
                            width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? timeSelectWidth : timeSelectMobileWidth}
                            value={isAllDayEvent ? "00:00:00" : endTime}
                            onChange={onChangeEndTime}
                            size="small"
                            placeholder="Select end time"
                            options={isAllDayEvent ? ["00:00:00"] : endTimeRange}
                            format={getDefaultTimeFormat(timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR)}
                            disabled={isAllDayEvent}
                            error={timeLabel === "invalid"}
                            aria-labelledby={AND_LABEL_ID}
                            aria-describedby={[ALERTS.INVALID_TIME_WINDOW.id, INVALID_TIME_WINDOW_ERROR_ID].join(" ")}
                        />
                        :
                        <Loader size="small"/>
                    }
                </Column>
            </Row>
            {!props.hideSelectionMessage &&
                <Text id={INVALID_TIME_WINDOW_ERROR_ID} type="b100" position="absolute" color={timeLabel === "invalid" ? "error" : null}><b>Time window</b> {isAllDayEvent ? "24 hours" : timeLabel}</Text>
            }
        </Column>
    );
};

export default LabeledTimeSelect;
