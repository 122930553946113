import React, { useEffect } from "react";

import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import DateRangePicker from "../meridian-custom-components/src/components/date-range-picker";

import DaysOfWeekPopover from "../../shared/components/days-of-week-popover.js";

import { ALERTS, SCREEN_SIZE, TIME_CONSTANT } from "../shared-constants";
import { getLocalesFromSettingsDateFormat } from "../settings/settings-utils";

const LabeledDateRangePicker = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;

    const [daysOfWeek, setDaysOfWeek] = [props.daysOfWeek, props.setDaysOfWeek];
    const [daysOfWeekLabel, setDaysOfWeekLabel] = [props.daysOfWeekLabel, props.setDaysOfWeekLabel];
    const repeatDays = props.repeatDays;
    const repeatSelected = props.repeatSelected;

    const customRepeatInterval = props.customRepeatInterval;
    const customRepeatEndSelected = props.customRepeatEndSelected;
    const customRepeatEndDate = props.customRepeatEndDate;
    const setCustomRepeatError = props.setCustomRepeatError;
    const setRefreshSuggestions = props.setRefreshSuggestions;

    const [dateRangeValue, setDateRangeValue] = [props.dateRangeValue, props.setDateRangeValue];
    const [dateRangeLabel, setDateRangeLabel] = [props.dateRangeLabel, props.setDateRangeLabel];

    const presets = props.presets;
    const startTime = props.startTime;
    const endTime = props.endTime;

    const startLabelWidth = props.startLabelWidth || "155px";
    const dateRangePickerWidth = props.dateRangePickerWidth || "330px";
    const rowSpacing = props.rowSpacing || "medium";
    const isDisabledDate = props.isDisabledDate || undefined;
    const disabledDaysOfWeek = props.disabledDaysOfWeek || [];

    const LABELED_START_DATE_PICKER_INSTRUCTION_ID = "labeled-start-date-picker-instruction-id";
    const LABELED_END_DATE_PICKER_INSTRUCTION_ID = "labeled-end-date-picker-instruction-id";

    const onChangeDateRange = (value) => {
        getDateRangeLabel(value);
        setDateRangeValue(value);

        if (customRepeatInterval && customRepeatEndSelected === "by") {
            setCustomRepeatError(Date.parse(customRepeatEndDate) < Date.parse(value[0]));
        }
    };

    // Update the custom duration label based on the inputted value
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getDateRangeLabel = (dateRange = dateRangeValue) => {
        let startEpoch;
        let endEpoch;
        if (dateRange[0]) {
            startEpoch = new Date(dateRange[0] + "T" + startTime).getTime() / TIME_CONSTANT.ONE_SEC_IN_MS;
        }
        if (dateRange[1]) {
            endEpoch = new Date(dateRange[1] + "T" + endTime).getTime() / TIME_CONSTANT.ONE_SEC_IN_MS;
        }
        if (startEpoch && endEpoch) {
            let dateDifference = (endEpoch - startEpoch) / TIME_CONSTANT.ONE_DAY_IN_SEC;
            if (parseInt(dateDifference) === 0) {
                setDateRangeLabel("1 day");
            } else {
                setDateRangeLabel(parseInt(dateDifference + 1) + " days");
            }
        } else {
            setDateRangeLabel("invalid");
        }
    };

    // Update the date range label if the value changes
    useEffect(() => {
        getDateRangeLabel();
    }, [dateRangeValue, getDateRangeLabel]);

    return (
        <Column spacing="none" spacingInset="none">
            <Row spacingInset="none" spacing={rowSpacing}>
                <Column width={startLabelWidth}>
                    <Text type="b100">Start</Text>
                </Column>
                <Text type="b100">End</Text>
            </Row>
            <div style={{display: "none"}}>
                <Text id={LABELED_START_DATE_PICKER_INSTRUCTION_ID}>
                    Enter start date in MM/DD/YYYY format or press down to enter calendar dialog to select a date range
                </Text>
                <Text id={LABELED_END_DATE_PICKER_INSTRUCTION_ID}>
                    Enter end date in MM/DD/YYYY format or press down to enter calendar dialog to select the end date
                </Text>
            </div>
            {startTime && endTime ?
                <DateRangePicker
                    size="small"
                    width={dateRangePickerWidth}
                    allowSameStartAndEnd={true}
                    value={dateRangeValue}
                    onChange={onChangeDateRange}
                    locale={getLocalesFromSettingsDateFormat(props.dateFormat)}
                    startError={props.startDateError || !dateRangeValue[0] || Date.parse(customRepeatEndDate) < Date.parse(dateRangeValue[0])}
                    endError={!dateRangeValue[1]}
                    presets={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? presets : undefined}
                    startPlaceholder="Select meeting start date"
                    endPlaceholder="Select meeting end date"
                    disabledDates={isDisabledDate}
                    ariaDescribedbyStart={[LABELED_START_DATE_PICKER_INSTRUCTION_ID, ALERTS.INVALID_START_DATE.id, ALERTS.MAX_DAYS_EXCEEDED.id].join(" ")}
                    ariaDescribedbyEnd={[LABELED_END_DATE_PICKER_INSTRUCTION_ID, ALERTS.INVALID_END_DATE.id, ALERTS.MAX_DAYS_EXCEEDED.id].join(" ")}
                    ariaLabelStart="Select meeting range start and end date"
                    ariaLabelEnd="Select meeting range end date"
                    setRefreshSuggestions={setRefreshSuggestions}
                />
                :
                <Loader size="small" />
            }
            {!props.hideSelectionMessage &&
                <Row alignmentVertical="top">
                    <Column width="155px">
                        <DaysOfWeekPopover
                            repeatSelected={repeatSelected}
                            repeatDays={repeatDays}
                            daysOfWeek={daysOfWeek}
                            setDaysOfWeek={setDaysOfWeek}
                            daysOfWeekLabel={daysOfWeekLabel}
                            setDaysOfWeekLabel={setDaysOfWeekLabel}
                            setRefreshSuggestions={setRefreshSuggestions}
                            disabledDaysOfWeek={disabledDaysOfWeek}
                        />
                    </Column>
                    <Column width="155px">
                        <Text type="b100" color={dateRangeLabel === "invalid" ? "error" : null}><b>Date range</b> {dateRangeLabel}</Text>
                    </Column>
                </Row>
            }
        </Column>
    );
};

export default LabeledDateRangePicker;
