module.exports = {
    BACKGROUND_OPACITY: 0.05,
    COOKIE: {
        LAST_VIEWED_ANNOUNCEMENT_TIME: "lastViewedAnnouncementTime",
        SUGGESTION_VIEW_TYPE: "suggestionViewType",
    },
    ERROR: {
        NOT_FOUND: {
            VALUE: 404,
            TITLE: "Error 404: Oops!",
            SUBTITLE: "The page you are looking for doesn't exist or has moved"
        },
        UNAUTHORIZED: {
            VALUE: 403,
            TITLE: "How did you get here?",
            SUBTITLE: "Please wait a bit longer to be part of the Amazon Meetings 2.0 Experience"
        },
    },
    REFRESH_ALERT_TIMER: 10 * 60 * 1000, // 10 minutes
    HOMEPAGE_FOOTER: {
        HEIGHT: "80px",
    },
    IMAGE_CARD: {
        IMAGE_WIDTH: 120,
        IMAGE_HEIGHT: 130,
    },
    MODAL_CONSTANT: {
        MODAL_WIDTH: 800,
        MODAL_ATTENDEE_COUNT: 10,
    },
    PATHS: {
        HOMEPAGE: "/",
    },
    RECURRENCE_TYPE: {
        DAILY: "com.amazon.resourceadapter#DailyRecurrence",
        WEEKLY: "com.amazon.resourceadapter#WeeklyRecurrence",
        ABSOLUTE_MONTHLY: "com.amazon.resourceadapter#AbsoluteMonthlyRecurrence",
        RELATIVE_MONTHLY: "com.amazon.resourceadapter#RelativeMonthlyRecurrence",
    },
    SCREEN_SIZE: {
        BREAKPOINT: {
            "XL": 1148,
            "LG": 960,
            "MD": 768,
            "SM": 640,
            "XS": 480,
            "MOBILE": 360,
        },
        RESPONSIVE_BREAKPOINTS: {
            "1148px": 1148,
            "960px": 960,
            "768px": 768,
            "640px": 640,
            "480px": 480,
            "360px": 360,
            "default": 320, // smallest screen size supported (for accessibility, equal to 1280px at 400% zoom)
        },
        FULL_VIEW: 1200,
        VIEW_WITHOUT_SIDE_BACKGROUND: 900,
        VIEW_WITHOUT_FAVORITES_SIDEBAR: 700,
        PARTIAL_MOBILE_VIEW: 550,
        MOBILE_VIEW: 360,
    },
    TIME_FORMAT: {
        HH_MM_SS: "HH:mm:ss",
    },
    TIME_CONSTANT: {
        DAY_NAME: "day",
        HOUR_NAME: "hour",
        MIN_NAME: "min",
        SEC_NAME: "sec",
        FIFTEEN_MIN: 15,
        ONE_DAY_IN_MS: 24 * 60 * 60 * 1000,
        ONE_HOUR_IN_MS: 60 * 60 * 1000,
        ONE_MINUTE_IN_MS: 60 * 1000,
        TEN_MINUTES_IN_MS: 10 * 60 * 1000,
        FIFTEEN_MINUTES_IN_MS: 15 * 60 * 1000,
        ONE_SEC_IN_MS: 1000,
        ONE_MIN_IN_SEC: 60,
        ONE_HOUR_IN_SEC: 60 * 60,
        ONE_DAY_IN_SEC: 24 * 60 * 60,
        ONE_WEEK_IN_SEC: 7 * 24 * 60 * 60,
        ONE_HOUR_IN_MIN: 60,
        ONE_DAY_IN_MIN: 24 * 60,
        ONE_HUNDRED_YEARS_IN_DAYS: 365 * 100,
        MEETING_CREATE_VERIFICATION_WAIT_TIME: 2000, // in milliseconds
    },
    GET_MEETING_MAX_ATTEMPTS: 4,
    GET_MEETING_RETRY_DELAY_IN_MS: 3000,
    MAX_PAYLOAD_SIZE: 5.9 * 1024 * 1024, // APIG has 10MB limit and AWS Lambda has 6MB limit, using 5.9MB to leave space for request headers
    KEYCODE: {
        TAB: 9,
        ENTER: 13,
        ESCAPE: 27,
        SPACE: 32,
        UP: 38,
        DOWN: 40,
        LEFT: 37,
        RIGHT: 39,
    },
    ROOM_RESPONSE: {
        ACCEPT: "accept",
        DECLINE: "decline",
    },
    ALERTS: {
        // Error alerts
        INVALID_ATTENDEES: {
            "type": "error",
            "value": "No attendees chosen for your meeting.",
            "id": "amw-invalid-attendees-id",
        },
        INVALID_START_DATE: {
            "type": "error",
            "value": "No start date selected.",
            "id": "amw-invalid-start-date-id",
        },
        INVALID_END_DATE: {
            "type": "error",
            "value": "No end date selected.",
            "id": "amw-invalid-end-date-id",
        },
        MAX_DAYS_EXCEEDED: {
            "type": "error",
            "value": "Date range is longer than the maximum allowed range of 62 days.",
            "id": "amw-max-days-exceeded-id",
        },
        MEETING_DURATION_NOT_SPECIFIED: {
            "type": "error",
            "value": "Meeting duration not specified.",
            "id": "amw-meeting-duration-not-specified-id",
        },
        INVALID_MEETING_DURATION: {
            "type": "error",
            "value": "Invalid duration inputted (maximum 60 days).",
            "id": "amw-invalid-meeting-duration-inputted-id",
        },
        INVALID_MEETING_SUBJECT: {
            "type": "error",
            "value": "Meeting subject is required.",
            "id": "amw-meeting-subject-not-specified-id",
        },
        NO_SUGGESTION_SELECTED: {
            "type": "error",
            "value": "No suggestion selected.",
            "id": "amw-no-suggested-selected-id",
        },
        INVALID_TIME_WINDOW: {
            "type": "error",
            "value": "Invalid time window.",
            "id": "amw-invalid-time-window-id",
        },
        INVALID_TIMEZONE: {
            "type": "error",
            "value": "No timezone selected.",
            "id": "amw-invalid-timezone-id",
        },
        MEETING_DURATION_LONGER_THAN_TIME_WINDOW: {
            "type": "error",
            "value": "Meeting duration is longer than the selected time window.",
            "id": "amw-duration-longer-than-time-window-id",
        },
        MEETING_DURATION_LONGER_THAN_DATE_RANGE: {
            "type": "error",
            "value": "Meeting duration is longer than selected date range.",
            "id": "amw-duration-longer-than-date-range-id",
        },
        NO_DAYS_OF_WEEK_SELECTED: {
            "type": "error",
            "value": "No days of week selected.",
            "id": "amw-no-days-of-week-id",
        },
        NO_RECUR_DAYS_OF_WEEK_SELECTED: {
            "type": "error",
            "value": "No days of week selected for recurrence.",
            "id": "amw-no-recur-days-of-week-id",
        },
        NO_RECURRENCE_INTERVAL_INPUTTED: {
            "type": "error",
            "value": "No interval for recurrences inputted.",
            "id": "amw-no-recur-interval-id",
        },
        NO_RECURRENCE_MEETING_COUNT_INPUTTED: {
            "type": "error",
            "value": "No number of meetings for recurrence inputted.",
            "id": "amw-no-recur-meeting-count-id",
        },
        RECURRENCE_DATE_BEFORE_START_DATE: {
            "type": "error",
            "value": "Recurrence end date is before suggestion search range's start date.",
            "id": "amw-recurrence-date-before-start-date-id",
        },
        INVALID_BUILDING: {
            "type": "error",
            "value": "No building selected.",
            "id": "amw-invalid-building-id",
        },
        BUILDING_WITHOUT_ROOMS: {
            "type": "error",
            "value": "We could not find any meeting rooms in the selected building. To create new meeting room accounts, please",
            "id": "amw-building-without-rooms-id",
            "linkText": "create a ticket",
            "link": "https://w.amazon.com/bin/view/NewExchangeResourceMailboxRequest#Scythe",
        },
        INVALID_FLOOR: {
            "type": "error",
            "value": "No floor selected.",
            "id": "amw-invalid-floor-id",
        },
        INVALID_LOCATION: {
            "type": "error",
            "value": "No location selected.",
            "id": "amw-invalid-location-id",
        },
        MAILBOX_SEARCH_LIMIT_EXCEEDED: {
            "type": "error",
            "value": "Number of attendees is more than the maximum allowed amount of 500.",
            "id": "amw-mailbox-search-limit-exceeded-id",
        },
        MAX_PAYLOAD_SIZE_EXCEEDED: {
            "type": "error",
            "value": "Your meeting request is too large (max 6MB). Please shorten the text body.",
            "id": "amw-max-payload-size-exceeded-id",
        },
        INVALID_LIVESTREAM_SEARCH_RANGE: {
            "type": "error",
            "value": "Your start date is too close to the current date or is in the past. Please allow at least 24 hours from the current time for livestream engineers to accommodate your request.",
            "id": "amw-invalid-livestream-search-range",
        },
        MEETING_POLL_INVALID_REMINDER: {
            "type": "error",
            "value": "Try again later",
            "id": "amw-meeting-poll-invalid-reminder-id",
        },

        // Warning Alerts
        DURATION_EXCEEDS_ROOM_BOOKING_LIMIT: {
            "type": "warning",
            "value": "Conference rooms at Amazon can only be booked for a max of 24 hours at at time. Your room reservation will be declined.",
            "id": "amw-duration-exceeds-room-booking-limit-id",
        },
        DURATION_EXCEEDS_ROOM_BOOKING_LIMIT_SUGGESTION: {
            "type": "warning",
            "value": "Consider reducing your meeting duration to 24 hours or less, or submitting multiple meeting room reservation requests.",
            "id": "amw-duration-exceeds-room-booking-limit-suggestion-id",
        },
        NO_SUGGESTIONS_FOUND: {
            "type": "warning",
            "value": "No suggestions meet all your requirements.",
            "id": "amw-no-suggestions-found-id",
        },

        // Informational Alerts
        MEETING_SEARCH_LIMIT: {
            "type": "informational",
            "value": "Meeting suggestions are for the first two months of the search range.",
            "id": "amw-meeting-search-limit-id",
        },
        MEETING_RECURRENCE_CHANGED: {
            "type": "informational",
            "value": "Your recurrence pattern has changed - refresh to update your suggestions and meeting details.",
            "id": "amw-meeting-recurrence-changed-id",
        },
        LIVESTREAM_PREPARATION_TIME: {
            "type": "informational",
            "value": "A 30-minute livestream preparation meeting is automatically scheduled before your livestream event and is factored into the suggested meeting times.",
            "id": "amw-livestream-preparation-time-id",
        },
        MEETING_POLL_DUPLICATE_REMINDER: {
            "type": "informational",
            "value": "Reminder already sent",
            "id": "amw-meeting-poll-duplicate-reminder-id",
        },
        PROCESSING_CREATE_MEETING: {
            "type": "informational",
            "value": "Processing",
            "id": "amw-meeting-create-meeting-processing-id",
        },
    },
    RESPONSE_PRIORITY: {
        "organizer": -1,
        "accept": 0,
        "decline": 1,
        "tentative": 2,
        "notResponded": 3,
    },
    MEETING_RESPONSE: {
        ORGANIZER: "organizer",
        ACCEPT: "accept",
        DECLINE: "decline",
        TENTATIVE: "tentative",
        NOT_RESPONDED: "notResponded",
    },
    MEETING_STATUS: {
        FREE: "free",
        BUSY: "busy",
        TENTATIVE: "tentative",
        OUT_OF_OFFICE: "outOfOffice",
    },
    ALL: "All",
    ONE_MINUTE: 60000,
    MAX_SEATS: 999,
};