import React from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import keycode from "keycode"
import includes from "lodash/includes"
import find from "lodash/find"
import findLast from "lodash/findLast"
import noop from "lodash/noop"
import {
  getKeyboardFocusNodes,
  getBoundingFocusNodes,
  canKeyboardFocusNode,
} from "../../_utils/focus"

/**
 * This component allows you to trap the keyboard focus within a particular
 * section of the DOM (the component's children). An optional onBeforeBlur event
 * handler can be defined that allows you to execute custom behavior when the
 * user attempts to move the focus outside of the trap by pressing the tab key.
 *
 * NOTE: This component only handles keyboard focus. If the mouse is used to
 * move focus outside of the trap then the trap will not prevent the focus
 * change and onBeforeBlur will not be called.
 *
 * NOTE: This component must be wrapped around a single element to work
 * properly.
 *
 * NOTE: This component only manages focus for elements that are
 * descendents in the real DOM. This means that it will ignore any portals
 * nested inside of it in the virtual DOM.
 */
class FocusTrap extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * This is called when the focus trap is about to lose focus because of
     * keyboard input.
     *
     * If the function is not provided or does not return a value then the blur
     * will be blocked and focus will be looped within the trap. If the function
     * is provided and returns false then the blur will be blocked but the focus
     * will not automatically be looped. If the function is provided and returns
     * true then focus will be allowed to leave the trap.
     *
     * The function is called with a single argument that indicates the
     * direction of the tab change: -1 if the user is moving backwards in the
     * tab order, or 1 if the user is moving fowards in the tab order.
     *
     * IMPORTANT: As mentioned above, this will only be called when the trap is
     * about to lose focus due to keyboard input. This will not be called if
     * focus is moved outside the trap via mouse input.
     */
    onBeforeBlur: PropTypes.func,
  }

  static defaultProps = {
    onBeforeBlur: noop,
  }

  rootNode = undefined

  focus() {
    const node = getKeyboardFocusNodes(this.rootNode)[0]
    if (node) {
      node.focus()
    }
  }

  focusEnd() {
    const nodes = getKeyboardFocusNodes(this.rootNode)
    const node = nodes[nodes.length - 1]
    if (node) {
      node.focus()
    }
  }

  /**
   * Takes in a keydown event, returns 0 if focus is not leaving, 1 if it's
   * leaving to the next node in the tab order, and -1 if it's leaving to the
   * previous node in the tab order.
   */
  willBlur(event, startNodes, endNodes) {
    const key = keycode(event)
    const { target, shiftKey } = event
    return includes(endNodes, target) && key === "tab" && !shiftKey
      ? 1
      : includes(startNodes, target) && key === "tab" && shiftKey
      ? -1
      : 0
  }

  onKeyDown = event => {
    if (keycode(event) === "tab") {
      const { startNodes, endNodes } = getBoundingFocusNodes(this.rootNode)
      const willBlur = this.willBlur(event, startNodes, endNodes)
      const allowBlur = willBlur && this.props.onBeforeBlur(willBlur)
      const firstFocusNode = find(startNodes, canKeyboardFocusNode)
      const lastFocusNode = findLast(endNodes, canKeyboardFocusNode)
      // If onBeforeBlur returned nothing then loop the focus ourselves
      if (willBlur === -1 && allowBlur === undefined && lastFocusNode) {
        lastFocusNode.focus()
      }
      if (willBlur === 1 && allowBlur === undefined && firstFocusNode) {
        firstFocusNode.focus()
      }
      // If allowBlur was not explicitly set to true then cancel the default event
      // behavior
      if (willBlur && allowBlur !== true) {
        event.preventDefault()
      }
    }
  }

  componentDidMount() {
    /* eslint-disable-next-line react/no-find-dom-node */
    this.rootNode = ReactDOM.findDOMNode(this)
    this.rootNode.addEventListener("keydown", this.onKeyDown)
  }

  componentWillUnmount() {
    this.rootNode.removeEventListener("keydown", this.onKeyDown)
  }

  render() {
    return this.props.children
  }
}

export default FocusTrap
