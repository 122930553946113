import React, { useRef, useState } from "react";
import { isEqualWith } from "lodash";

import Row from "@amzn/meridian/row";
import Expander from "@amzn/meridian/expander"
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Text from "@amzn/meridian/text";

import CreatePollInviteAttendees from "./create-poll-invite-attendees";
import CreatePollAddDetails from "./create-poll-add-details";
import ErrorMessageWrapper from "../../shared/components/error-message-wrapper";
import CreatePollTimeSlots from "./create-poll-time-slots";

import { SCREEN_SIZE } from "../../shared/shared-constants";

const CreatePollTimeSlotsMemoized = React.memo(CreatePollTimeSlots, (prevProps, currentProps) =>
    isEqualWith(prevProps, currentProps, (prop1, prop2) => typeof(prop1) === "function" && typeof(prop2) === "function" ? true : undefined)
);

const CreatePollForm = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const [openInviteAttendees, setOpenInviteAttendees] = useState(true);
    const [openSelectTimeSlots, setOpenSelectTimeSlots] = useState(true);
    const [openAddDetails, setOpenAddDetails] = useState(true);
    const [voteDueDate, setVoteDueDate] = useState();
    const [voteDueTime, setVoteDueTime] = useState();
    const [isDueChanged, setIsDueChanged] = useState(false);
    const [openExternalEmailPopover, setOpenExternalEmailPopover] = useState(false);
    const externalEmailPopoverRef = useRef();

    return (
        <Row alignmentHorizontal="center">
            <Column
                spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.MD ? "large" : "small"}
                width="100%"
                maxWidth="1200px"
                alignmentVertical="stretch"
                spacing="medium"
            >
                <ErrorMessageWrapper id="title-error" showErrorMessage={props.showSubjectErrorMessage}>
                    <Column spacing="200">
                        <Text tag="label" htmlFor="poll-title" type="b300">
                            Add title
                        </Text>
                        <Input id="poll-title" placeholder="Title" size="large" value={props.subject} onChange={props.setSubject} aria-required="true" aria-describedby={props.showSubjectErrorMessage === true ? "title-error" : ""} aria-invalid={props.showSubjectErrorMessage === true ? "true" : "false"} />
                    </Column>
                </ErrorMessageWrapper>
                <Expander open={openInviteAttendees} onChange={setOpenInviteAttendees} title="Invite attendees" type="box">
                    <CreatePollInviteAttendees
                        identity={props.identity}
                        screenSizeBreakpoint={screenSizeBreakpoint}
                        peopleSuggestions={props.peopleSuggestions}
                        groupSuggestions={props.groupSuggestions}
                        favorites={props.favorites}
                        attendees={props.attendees}
                        externalAttendees={props.externalAttendees}
                        onAddAttendee={props.onAddAttendee}
                        onAddExternalAttendee={props.onAddExternalAttendee}
                        onUpdateAttendee={props.onUpdateAttendee}
                        onUpdateExternalAttendee={props.onUpdateExternalAttendee}
                        onRemoveAttendee={props.onRemoveAttendee}
                        onRemoveExternalAttendee={props.onRemoveExternalAttendee}
                        onRemoveAttendeeByAlias={props.onRemoveAttendeeByAlias}
                        onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                        onGetGroupSuggestions={props.onGetGroupSuggestions}
                        onClearGroupSuggestions={props.onClearGroupSuggestions}
                        onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                        onGetRASDataForGroup={props.onGetRASDataForGroup}
                        onAddFavorite={props.onAddFavorite}
                        onRemoveFavorite={props.onRemoveFavorite}
                        onRemoveFavoriteByAlias={props.onRemoveFavoriteByAlias}
                        externalEmailPopoverRef={externalEmailPopoverRef}
                        setOpenExternalEmailPopover={setOpenExternalEmailPopover}
                    />
                </Expander>

                <ErrorMessageWrapper id="time-slots-error" errorMessage="Required field is missing. Select one or more time slots" showErrorMessage={props.showTimeSlotsErrorMessage}>
                    <Expander open={openSelectTimeSlots} onChange={setOpenSelectTimeSlots} title="Select time slots" type="box" aria-describedby={props.showTimeSlotsErrorMessage === true ? "time-slots-error" : ""}>
                        <CreatePollTimeSlotsMemoized
                            identity={props.identity}
                            attendees={props.attendees}
                            screenSizeBreakpoint={props.screenSizeBreakpoint}
                            timezones={props.timezones}
                            timezone={props.timezone}
                            setTimezone={props.setTimezone}
                            timeFormat={props.timeFormat}
                            dateFormat={props.dateFormat}
                            workHours={props.workHours}
                            duration={props.duration}
                            setDuration={props.setDuration}
                            meetingSuggestions={props.meetingSuggestions}
                            suggestionViewType={props.suggestionViewType}
                            onGetMeetingSuggestions={props.onGetMeetingSuggestions}
                            onSearchSuggestion={props.onSearchSuggestion}
                            onSelectSuggestion={props.onSelectSuggestion}
                            onSetSuggestionViewType={props.onSetSuggestionViewType}
                            onShowToast={props.onShowToast}
                            prevGetScheduleQuery={props.prevGetScheduleQuery}
                            setPrevGetScheduleQuery={props.setPrevGetScheduleQuery}
                            timeSlotsSelected={props.timeSlotsSelected}
                            setTimeSlotsSelected={props.setTimeSlotsSelected}
                            isGettingSchedules={props.isGettingSchedules}
                            onSetTimeSlotsSelectedFromGridMode={props.onSetTimeSlotsSelectedFromGridMode}
                            onSetTimeSlotsSelectedFromCalendarMode={props.onSetTimeSlotsSelectedFromCalendarMode}
                            showTimeSlotsErrorMessage={props.showTimeSlotsErrorMessage}
                        />
                    </Expander>
                </ErrorMessageWrapper>
                <ErrorMessageWrapper id="add-details-error" errorMessage="Required field is missing. Add a vote due date" showErrorMessage={props.showAddDetailsErrorMessage}>
                    <Expander open={openAddDetails} onChange={setOpenAddDetails} title="Add details" type="box" aria-describedby={props.showAddDetailsErrorMessage === true ? "add-details-error" : ""}>
                        <CreatePollAddDetails
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            expiration={props.expiration}
                            setExpiration={props.setExpiration}
                            body={props.body}
                            timeFormat={props.timeFormat}
                            dateFormat={props.dateFormat}
                            setBody={props.setBody}
                            timezones={props.timezones}
                            timezone={props.timezone}
                            flowType={props.flowType}
                            timeSlotsSelected={props.timeSlotsSelected}
                            voteDueDate={voteDueDate}
                            setVoteDueDate={setVoteDueDate}
                            voteDueTime={voteDueTime}
                            setVoteDueTime={setVoteDueTime}
                            isDueChanged={isDueChanged}
                            setIsDueChanged={setIsDueChanged}
                            showAddDetailsErrorMessage={props.showAddDetailsErrorMessage}
                        />
                    </Expander>
                </ErrorMessageWrapper>
                {openExternalEmailPopover && externalEmailPopoverRef.current !== undefined &&
                    <div id="create-poll-invite-external-email-tooltip" style={{
                        "position": "fixed",
                        "top": externalEmailPopoverRef.current.getBoundingClientRect().top + externalEmailPopoverRef.current.clientHeight,
                        "left": externalEmailPopoverRef.current.getBoundingClientRect().left,
                        "width": externalEmailPopoverRef.current.clientWidth,
                        "zIndex": 1000,
                        "border": "1px solid #E7E9E9",
                        "borderRadius": "4px",
                        "padding": "32px 32px",
                        "transition": "transform 0.1s ease,box-shadow 0.1s ease",
                        "transform": "translateY(0px)",
                        "backgroundColor": "#FFFFFF",
                        "boxShadow": "0px 2px 4px 0px rgba(11,12,12,0.16)",
                    }}>
                        <Text type="b300">Use semicolon to separate email recipients.</Text>
                        <Text type="b300">Press enter to add email(s).</Text>
                    </div>
                }
            </Column>
        </Row>
    );
};

export default CreatePollForm;