import React, { useCallback, useEffect, useState }from "react";
import { useDispatch, useSelector } from "react-redux";

import Select, { SelectOptionGroup, SelectOption } from "@amzn/meridian/select";
import Button from "@amzn/meridian/button";

import { getExistingMeetings } from "../../shared/actions";

import { getExistingMeetingsLoaded, getExistingMeetingsRoomBooking } from "../../../sagas/selector";

import { zonedTimeToUtc } from "date-fns-tz";
import { getReadableTime } from "../../shared/time-utils";
import { getFormattedChimePin } from "../../shared/meeting-utils";
import { generateComponentId } from "../../shared/shared-utils";


const ChimePINSelect = (props) => {
    const existingMeetings = useSelector(getExistingMeetingsRoomBooking);
    const existingMeetingsLoaded = useSelector(getExistingMeetingsLoaded);
    const CHIME_SELECT_LABEL_ID = "chime-select-label-id";
    const meetingList = props.meetingList;
    const selectedStartTime = props.selectedStartTime;
    const selectedEndTime = props.selectedEndTime;
    const date = props.date;
    const timezone = props.timezone; 
    const timezones = props.timezones;
    const timeFormat = props.timeFormat;
    const email = props.email;
    const [showLoadExistingMeetings, setShowLoadExistingMeetings] = useState(true);
    const [loadExistingMeetingsClicked, setLoadExistingMeetingsClicked] = useState(false);

    const dispatch = useDispatch();

    const onGetExistingMeetings = useCallback(() => {
        let startTime = (zonedTimeToUtc(`${date} ${selectedStartTime}`, timezone)).getTime() / 1000;
        let endTime = (zonedTimeToUtc(`${date} ${selectedEndTime}`, timezone)).getTime() / 1000;

        const meetings = meetingList?.filter((meeting) => 
        ((meeting.time.startTime >= startTime && meeting.time.startTime < endTime) ||
        (meeting.time.endTime > startTime && meeting.time.endTime <= endTime)) && 
        (meeting.response === "accept" || meeting.response === "organizer" || meeting.response === "notResponded") && 
        (meeting.status === "busy" || meeting.status === "tentative"));

        dispatch(getExistingMeetings(email, meetings));
    }, [date, meetingList, selectedEndTime, selectedStartTime, timezone, email, dispatch]);

    const onLoadExistingMeetingsClick = useCallback(() => {
        setLoadExistingMeetingsClicked(true);
        setShowLoadExistingMeetings(false);
        onGetExistingMeetings();
    }, [setLoadExistingMeetingsClicked, onGetExistingMeetings]);

    const timezoneShortName = (selectedTimezone) => {
        let timezoneFound = timezones.find((timezone) => (timezone.id === selectedTimezone));
        if (timezoneFound === undefined) {
            return selectedTimezone;
        }
        return timezoneFound.standardShortNameDateFns ? timezoneFound.standardShortNameDateFns : timezoneFound.standardLongNameDateFns;
    };

    const optionLabel = ((meeting) => {
        return getReadableTime(meeting.time.startTime, timezone, timeFormat) + "-" + getReadableTime(meeting.time.endTime, timezone, timeFormat) + "(" + timezoneShortName(timezone) + ") " + meeting.subject;
    });

    useEffect(() => {
        setShowLoadExistingMeetings(true);
        setLoadExistingMeetingsClicked(false);
    }, [selectedStartTime, selectedEndTime]);

    return (
        <Select
            id={generateComponentId("dropdown", "chime", "reserve-room")}
            size="small"
            value={props.chimePin}
            onChange={props.onHandleChimePin}
            placeholder="Select chime option"
            aria-labelledby={CHIME_SELECT_LABEL_ID}
            minWidth="130px"
        >
            <SelectOption label="None" value="none" />
            {props.generatedChimeBridge &&
                <SelectOption
                    value={props.generatedChimeBridge}
                    label={`Generate ID: ${getFormattedChimePin(props.generatedChimeBridge)}`}
                />
            }
            {props.personalChimeBridge &&
                <SelectOption
                    value={props.personalChimeBridge}
                    label={`Personal ID: ${getFormattedChimePin(props.personalChimeBridge)}`}
                />
            }
            {showLoadExistingMeetings && !loadExistingMeetingsClicked && (
                <SelectOption label="" value="">
                    {() => (
                        <Button type={"link"} onClick={onLoadExistingMeetingsClick} size="small">
                            Load existing meetings
                        </Button>
                    )}
                </SelectOption>
            )}
            {!showLoadExistingMeetings && loadExistingMeetingsClicked && (
                existingMeetingsLoaded ? 
                    existingMeetings?.length > 0 ?
                        existingMeetings.map((meeting) => {
                            return (
                                <SelectOption value={meeting.entryID} label={optionLabel(meeting)}/>
                            );
                        })
                        :
                        <SelectOptionGroup label="No results" />
                    :
                    <SelectOptionGroup label="Loading..." />
            )}
        </Select>
    );
};

export default ChimePINSelect;