import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { css, cx } from "emotion"
import range from "lodash/range"
import { format } from "date-fns";
import {
  elevationBackground,
  elevationForeground,
} from "@amzn/meridian-tokens/base/elevation"
import addDays from "date-fns/addDays"
import getCalendarMonth from "../../_utils/get-calendar-month"
import { toDateString, getYear, getMonth } from "../../_utils/date-string"
import { memoizeTokenStyles } from "../../_utils/token"
import textStyles from "../../_styles/text"
import focusRingStyles from "../../_styles/focus-ring"
import { getDateLocale } from "../../_utils/date-locales"

const daysInWeek = 7

const styles = memoizeTokenStyles(
  (t, { renderingCustomDay, dayCount }) => {
    const dayBorderRadius = t("dayBorderRadius")
    return css(
      textStyles(t),
      // HACK: CSS "grid" for IE11
      {
        display: "-ms-grid",
        msGridColumns: `(1fr)[${daysInWeek}]`,
        ...range(dayCount + daysInWeek).reduce((gridItemStyles, index) => {
          gridItemStyles[`& > *:nth-child(${index + 2})`] = {
            display: "block",
            msGridColumn: 1 + (index % daysInWeek),
            msGridRow: 2 + Math.floor(index / daysInWeek),
          }
          return gridItemStyles
        }, {}),
      },
      {
        display: "grid",
        gridTemplateColumns: `repeat(${daysInWeek}, 1fr)`,

        /**
         * Month header styles
         */

        "& > time:first-child": {
          display: "block",
          textAlign: "center",
          fontWeight: t("monthHeaderFontWeight"),
          fontSize: t("monthHeaderFontSize"),
          margin: 0,
          gridColumn: "1 / -1",
          // HACK: CSS "grid" for IE11
          msGridRow: 1,
          msGridColumn: 1,
          msGridColumnSpan: daysInWeek,
        },

        /**
         * Day of week header styles
         */

        "& > span": {
          fontWeight: t("dayOfWeekHeaderFontWeight"),
          fontSize: t("dayOfWeekHeaderFontSize"),
          position: "relative",
          textAlign: "center",
          marginTop: t("spacingVertical") * 2,
          marginBottom: t("spacingVertical"),
        },

        /**
         * Day styles
         */

        "& > * + time:not([aria-hidden])": {
          position: "relative",
          textAlign: "center",
          cursor: "pointer",
          borderRadius: dayBorderRadius,
          color: t("dayForegroundColorDefault"),
          backgroundColor: t("dayBackgroundColorDefault"),
          zIndex: elevationBackground,
          transition: t.transitionCss("dayMotion", [
            "color",
            "background-color",
            "border-radius",
          ]),
          // If we're *not* rendering a custom day then fix the aspect ratio at
          // 0.85, otherwise leave the aspect ratio up to the custom day renderer.
          height: renderingCustomDay ? undefined : 34,
          "& > span": renderingCustomDay
            ? undefined
            : {
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
          // Selected
          "&[mdn-mini-cal-state=selected]": {
            color: t("dayForegroundColorSelected"),
            backgroundColor: t("dayBackgroundColorSelected"),
          },
          // Highlighted
          "&[mdn-mini-cal-state=highlighted]": {
            color: t("dayForegroundColorHighlighted"),
            backgroundColor: t("dayBackgroundColorHighlighted"),
          },
          // Disabled
          "&[mdn-mini-cal-state=disabled]": {
            color: t("dayForegroundColorDisabled"),
            backgroundColor: t("dayBackgroundColorDisabled"),
            cursor: "not-allowed",
          },
          // Hover/pressed
          "&:not([mdn-mini-cal-state=disabled])": {
            "&:hover": {
              color: t("dayForegroundColorHover"),
              backgroundColor: t("dayBackgroundColorHover"),
            },
            "&:active": {
              color: t("dayForegroundColorPressed"),
              backgroundColor: t("dayBackgroundColorPressed"),
            },
          },
          // Current day indicator
          "&[aria-current='date']:before": {
            content: `""`,
            position: "absolute",
            width: t("indicatorWidth"),
            height: t("indicatorHeight"),
            backgroundColor: t("indicatorColor"),
            borderRadius: t("indicatorBorderRadius"),
            left: "50%",
            bottom: t("indicatorSpacingVertical"),
            transform: "translateX(-50%)",
          },
          // When rendering custom days, add padding to the bottom
          // so the indicator does not clash with the content. Add
          // padding to top to get vertical centering.
          padding: renderingCustomDay
            ? `${t("indicatorHeight") + t("indicatorSpacingVertical")}px 0`
            : undefined,
          // Focus ring
          "&[mdn-mini-cal-focus]": css(
            {
              // This is used to ensure that the focus ring is shown above all adjacent
              // elements. Revisit this once we move from an outline-based focus ring to
              // a psuedo-element-based focus ring.
              zIndex: elevationForeground,
            },
            focusRingStyles(t.tokens, {
              focus: true,
              borderRadius: dayBorderRadius,
              gapColor: t.tokens.themeBackgroundPrimaryDefault,
              offset: 4,
            })
          ),
          // Left cap
          "&:not([mdn-mini-cal-left-cap])": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
          // Right cap
          "&:not([mdn-mini-cal-right-cap])": {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      }
    )
  },
  ["renderingCustomDay", "dayCount"]
)

const shouldNotUpdate = (prevProps, nextProps) =>
  prevProps.hidden && nextProps.hidden

const MiniCalendarPage = React.memo(
  React.forwardRef((props, ref) => {
    const { viewDate, locale } = props
    const { days, dayOfWeekLabels, monthLabel } = useMemo(
      () =>
        getCalendarMonth({
          date: viewDate,
          locale,
          monthLabelFormat: { month: "long", year: "numeric" },
          headerLabelFormat: {
            weekday: getDateLocale(locale).compactDayOfWeekFormat,
          },
        }),
      [viewDate, locale]
    )
    const renderingCustomDay = props.renderDay !== undefined
    let rangeStarted = false;
    return (
      <div
        ref={ref}
        className={cx(
          styles(props.t, { renderingCustomDay, dayCount: days.length }),
          props.className
        )}
        aria-hidden={props.hidden}
        mdn-mini-cal-page=""
        role="application"
      >
        <time aria-live="polite" dateTime={`${getYear(viewDate)}-${getMonth(viewDate)}`}>
          {monthLabel}
        </time>
        {dayOfWeekLabels.map(({ label, long }) => (
          <span key={long} title={long}>
            {label}
          </span>
        ))}
        {days.map(({ dateObject, dateString, label, inMonth }) => {
          // If the day falls within the given month, render
          if (inMonth) {
            const selected = props.selectedDates(dateString)
            const disabled = props.disabledDates(dateString)
            const range = props.rangeDates(dateString)
            const current = dateString === props.now
            const leftCap =
              !range || !props.rangeDates(toDateString(addDays(dateObject, -1)))
            const rightCap =
              !range || !props.rangeDates(toDateString(addDays(dateObject, 1)))
            const state = selected
              ? "selected"
              : disabled
              ? "disabled"
              : range
              ? "highlighted"
              : undefined
            const ariaState = selected
              ? !range
                ? "Selected, "
                : rangeStarted
                  ? "Selected end date, "
                  : "Selected start date, "
              : disabled
                ? ""
                : range
                  ? "Selected, "
                  : ""
            if (selected) {
                rangeStarted = true;
            }
            const ariaLabel = `${current ? "Current date, " : ""}${ariaState}${format(dateObject, "EEEE MMMM d, yyyy")}`
            return (
              // NOTE: mdn-text is used to tell any Text components dropped into the
              // calendar via the `renderDay` function to inherit the text styles of the
              // calendar by default.
              // NOTE: keyboard interaction is handled up at the calendar level
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <time
                key={dateString}
                dateTime={dateString}
                aria-current={current ? "date" : undefined}
                mdn-mini-cal-state={state}
                mdn-mini-cal-focus={
                  dateString === props.focusDate ? "" : undefined
                }
                mdn-mini-cal-left-cap={leftCap ? "" : undefined}
                mdn-mini-cal-right-cap={rightCap ? "" : undefined}
                mdn-text=""
                onClick={
                  props.onClickDate && !disabled
                    ? () => props.onClickDate(dateString)
                    : undefined
                }
                id={props.dateIds(dateString)}
                onMouseEnter={
                  props.onMouseEnterDate
                    ? () =>
                        props.onMouseEnterDate(
                          disabled ? undefined : dateString
                        )
                    : undefined
                }
                onMouseLeave={
                  props.onMouseEnterDate
                    ? () => props.onMouseEnterDate(undefined)
                    : undefined
                }
                role="button"
                aria-label={ariaLabel}
                aria-disabled={disabled}
              >
                {renderingCustomDay ? (
                  props.renderDay({
                    label,
                    date: dateString,
                    current,
                    selected,
                    disabled,
                    range,
                  })
                ) : (
                  <span>{label}</span>
                )}
              </time>
            )
          } else {
            return (
              <time key={dateString} dateTime={dateString} aria-hidden={true} />
            )
          }
        })}
      </div>
    )
  }),
  shouldNotUpdate
)

MiniCalendarPage.propTypes = {
  dateIds: PropTypes.func.isRequired,
  disabledDates: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  now: PropTypes.string.isRequired,
  rangeDates: PropTypes.func.isRequired,
  selectedDates: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  viewDate: PropTypes.string.isRequired,
  /**
   * A class to add to the component's [class attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/class).
   *
   * This should *not* be used to override existing styles on the component. Meridian's
   * internal CSS and HTML are private APIs and may change at any time,
   * potentially breaking custom style overrides.
   */
  className: PropTypes.string,
  focusDate: PropTypes.string,
  hidden: PropTypes.bool,
  renderDay: PropTypes.func,
  onClickDate: PropTypes.func,
  onMouseEnterDate: PropTypes.func,
}

MiniCalendarPage.displayName = "MiniCalendarPage"

export default MiniCalendarPage
