import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { hideToast } from "../actions";
import { getMeetingDetails, getSettingsTimeFormat, getToasts } from "../../../../sagas/selector";
import { SET_MEETING_DETAILS } from "../../actions/action-types";

import Alert from "@amzn/meridian/alert";
import Toaster from "@amzn/meridian/toaster";

import ToastFocusWrapper from "../components/toast-focus-wrapper";
import FunctionToast from "../components/toast-function";
import LinkToast from "../components/toast-link";
import MeetingCreatedToast from "../components/toast-meeting-created";
import MeetingDeletedToast from "../components/toast-meeting-deleted";
import MeetingDetails from "../../containers/meeting-details";
import MeetingUpdatedToast from "../components/toast-meeting-updated";
import OOTOCreatedToast from "../components/toast-ooto-created";
import PollRespondedToast from "../components/toast-poll-responded";

import { TOAST_ACTION_TYPE, TOAST_COMPONENT } from "../toast-constants";
import SystemErrorToast from "../components/toast-system-error";
import IntakeMeetingCreatedToast from "../components/toast-intake-meeting-created";

const MeetingsToaster = () => {
    const dispatch = useDispatch();
    const toasts = useSelector(getToasts);
    const timeFormat = useSelector(getSettingsTimeFormat);

    const [openMeetingDetailsModal, setOpenMeetingDetailsModal] = useState(false);
    const [entryId, setEntryId] = useState();
    const [userEmail, setUserEmail] = useState();

    const onCloseToast = useCallback((id) => {
        dispatch(hideToast(id));
    }, [dispatch]);

    // Open the meeting details modal with the provided info and close the toast
    const onOpenModal = (toast, entryId, userEmail) => {
        setEntryId(entryId);
        setUserEmail(userEmail);
        setOpenMeetingDetailsModal(true);
        toast.onClose();
    };

    const getComponent = (toast) => {
        const entryId = toast.toastActionProps.entryId;
        const userEmail = toast.toastActionProps.userEmail;
        const openToastModal = () => onOpenModal(toast, entryId, userEmail);

        switch (toast.toastActionProps.componentName) {
            case TOAST_COMPONENT.MEETING_CREATED:
                return (
                    <MeetingCreatedToast
                        subject={toast.toastActionProps.subject}
                        openToastModal={openToastModal}
                    />
                );
            case TOAST_COMPONENT.MEETING_DELETED:
                return (
                    <MeetingDeletedToast
                        subject={toast.toastActionProps.subject}
                        master={toast.toastActionProps.master}
                    />
                );
            case TOAST_COMPONENT.MEETING_UPDATED:
                return (
                    <MeetingUpdatedToast
                        subject={toast.toastActionProps.subject}
                        master={toast.toastActionProps.master}
                        openToastModal={openToastModal}
                    />
                );
            case TOAST_COMPONENT.OOTO_CREATED:
                return (
                    <OOTOCreatedToast
                        subject={toast.toastActionProps.subject}
                        openToastModal={openToastModal}
                    />
                );
            case TOAST_COMPONENT.LINK:
                return (
                    <LinkToast
                        text={toast.toastActionProps.text}
                        linkText={toast.toastActionProps.linkText}
                        href={toast.toastActionProps.href}
                        linkName={toast.toastActionProps.linkName}
                        alertId={getToastProperties(toast).alertId}
                    />
                );
            case TOAST_COMPONENT.FUNCTION:
                return (
                    <FunctionToast
                        text={toast.toastActionProps.text}
                        functionText={toast.toastActionProps.functionText}
                        function={toast.toastActionProps.function}
                        onClose={toast.toastActionProps.close ? getToastProperties(toast).onClose : undefined}
                        allowedSteps={toast.toastActionProps.allowedSteps}
                        allowedWorkflows={toast.toastActionProps.allowedWorkflows}
                    />
                );
            case TOAST_COMPONENT.POLL_RESPONDED:
                return (
                    <PollRespondedToast
                        organizerName={toast.toastActionProps.organizerName}
                    />
                );
            case TOAST_COMPONENT.SYSTEM_ERROR:
                return (
                    <SystemErrorToast
                        text={toast.toastActionProps.text}
                        linkText={toast.toastActionProps.linkText}
                        href={toast.toastActionProps.href}
                        linkName={toast.toastActionProps.linkName}
                    />
                );
            case TOAST_COMPONENT.INTAKE_MEETING_CREATED:
                return (
                    <IntakeMeetingCreatedToast
                        livestreamHref={toast.toastActionProps.livestreamHref}
                        livestreamLinkName={toast.toastActionProps.livestreamLinkName}
                        livestreamLinkText={toast.toastActionProps.livestreamLinkText}
                        ticketHref={toast.toastActionProps.ticketHref}
                        ticketLinkName={toast.toastActionProps.ticketLinkName}
                        ticketLinkText={toast.toastActionProps.ticketLinkText}
                        isRecordingEvent={toast.toastActionProps.isRecordingEvent}
                    />
                );
            default:
                return (
                    <React.Fragment>
                        {toast.toastActionProps.text}&nbsp;
                    </React.Fragment>
                );
        }
    };

    const getToastProperties = (toast) => {
        const toastProperties = {
            alertId: `toast-alert-${new Date().getTime()}`,
            autoFocus: true,
            onClose: toast.onClose,
            ariaLabel: toast.ariaLabel
        };

        if (toast.toastActionProps) {
            if (toast.toastActionProps.autoFocus !== undefined) {
                toastProperties.autoFocus = toast.toastActionProps.autoFocus;
            }

            if (toast.toastActionProps.ariaLabel !== undefined) {
                toastProperties.ariaLabel = toast.toastActionProps.ariaLabel;
            }

            toastProperties.onClose = () => {
                if (toast.toastActionProps.closeFunction !== undefined) {
                    toast.toastActionProps.closeFunction();
                }

                toast.onClose();

                // Refocus to a particular HTML element after closing the toast
                let refocusInfo = toast.toastActionProps.refocusInfo;
                if (refocusInfo && Object.keys(refocusInfo).length !== 0) {
                    if (refocusInfo.elementId) {
                        // Refocus to the specified element ID
                        document.getElementById(refocusInfo.elementId)?.focus();
                    } else if (refocusInfo.className) {
                        // Refocus to the first element that matches the specified class name
                        document.getElementsByClassName(refocusInfo.className)[0]?.focus()
                    } else if (refocusInfo.element) {
                        // Refocus to the specified HTML element
                        refocusInfo.element.focus();
                    }
                }
            }
        }

        return toastProperties;
    };

    return (
        <React.Fragment>
            <Toaster toasts={toasts} onCloseToast={onCloseToast}>
                {(toast) => {
                    return (
                        <Alert
                            toast={true}
                            id={getToastProperties(toast).alertId}
                            onClose={getToastProperties(toast).onClose}
                            type={toast.toastType}
                            width="100%"
                            inaccessibleAutoFocus={getToastProperties(toast).autoFocus}
                            aria-modal="true"
                            aria-label={`${getToastProperties(toast).ariaLabel}. `}
                        >
                            <ToastFocusWrapper alertId={getToastProperties(toast).alertId} />
                            {toast.toastActionType === TOAST_ACTION_TYPE.DISPLAY_COMPONENT ?
                                getComponent(toast)
                                :
                                <div dangerouslySetInnerHTML={{__html: toast.toastMessage}} />
                            }
                        </Alert>
                    );
                }}
            </Toaster>
            <MeetingDetails
                open={openMeetingDetailsModal}
                entryID={entryId}
                onClose={() => setOpenMeetingDetailsModal(false)}
                saveAction={SET_MEETING_DETAILS}
                selector={getMeetingDetails}
                userEmail={userEmail}
                timeFormat={timeFormat}
            />
        </React.Fragment>
    );
};

export default MeetingsToaster;