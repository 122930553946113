import { call } from "redux-saga/effects";
import { STATUS_CODE } from "./apig-constants";
import { OPERATIONS } from "./ras/endpointConfig";
import axios from "axios";

// remove the uninitialized properties in the request to avoid the type check errors from APIG
// https://stackoverflow.com/a/57625661/3499519
export const cleanEmpty = (obj) => {
    if (Array.isArray(obj)) {
        return obj
            .map(v => (v && typeof v === 'object') ? cleanEmpty(v) : v)
            .filter(v => !(v == null));
    } else {
        return Object.entries(obj)
            .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
            .reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
    }
};

export function* axiosRequest({url, opts}) {
    return yield call(
        () => axios.request(opts).then((res) => {
            return JSON.stringify(res.data);
        }).catch(err => {
            if (err.response) {
                throw err.response;
            }
            throw err;
        })
    );
}

export function* fetchRequest({url, opts}) {
    const response = yield call(
        () => fetch(url, opts)
            .then((res) => {
                if (!res.ok) {
                    throw res;
                }
                return res.text();
            })
            .catch(async(error) => {
                if (error.text) {
                    await error.text().then((errorResponse) => {
                        console.error(`Failed to request ${url}. ${errorResponse}`);
                        //QuotaExceededException Response
                        // {"data":{"__type":"com.amazon.resourceadapter#QuotaExceededException",
                        // "message":"Mailbox <amazon_alias>@amazon.de is over
                        // quota"},"statusCode":500,"statusMessage":"Internal Server Error"}
                        const parsedErrorResponse = JSON.parse(errorResponse);
                        error.message = parsedErrorResponse.message || parsedErrorResponse.data?.message; // propagate error message
                        error.failure = parsedErrorResponse.failure; // propagate meeting check in failures
                    });
                } else {
                    console.error(`Failed to request ${url}: `, error);
                }
                throw(error);
            })
    );
    return response;
}

export const isRetryableError = (error) => {
    if (error === undefined) {
        return true;
    }

    if (error.status === STATUS_CODE.BAD_REQUEST) {
        return false;
    }

    return true;
};

export const getErrorResponse = (error) => {
    return `{"status": ${STATUS_CODE.INTERNAL_SERVER_ERROR}, "error": ${stringifyError(error)}}`
};

// TODO: We should improve error handling to detect other kinds of errors as well, not only 500.
export const isErrorResponse = (response) => {
    return response.status && response.status === STATUS_CODE.INTERNAL_SERVER_ERROR;
};

export const isBadRequestError = (response) => {
    return isErrorResponse(response) && response.error && response.error.statusCode === STATUS_CODE.BAD_REQUEST;
};

export const isMultiStatus = (response) => {
    return response.statusCode && response.statusCode === STATUS_CODE.MULTI_STATUS;
};

const shouldRetryEmail = (email = "") => {
    if (!email.includes("@")) {
        return false;
    }

    let emailTail = email.substring(email.indexOf("@"));
    return emailTail.includes("amazon") && emailTail !== "@ant.amazon.com";
};

const getRetryEmail = (email = "") => {
    return email.substring(0, email.indexOf("@")) + "@ant.amazon.com";
};

/**
 * Returns whether we should retry the request.
 * If we are retrying, it also returns the new request payload to retry with
 *
 * @param params - the parameters for the makeRequest. index 0: operation, index 1: request object
 * @returns {{retry: *, retryParams: *[], failedEmail: *, retryEmail: *}}
 * retry: whether to retry or not
 * retryParams: the new parameters to retry with
 * failedEmail: the requester's email that resulted in a failed call
 * retryEmail: the requester's email that we will retry with
 */
export const updateRequesterEmailInParams = (params) => {
    let updatedRequest = params[1];
    let retry, failedEmail, retryEmail;

    switch (params[0]) {
        case OPERATIONS.DELETE_MEETING:
        case OPERATIONS.FIND_MEETINGS:
        case OPERATIONS.GET_MEETING:
        case OPERATIONS.UPDATE_MEETING:
            failedEmail = updatedRequest.requestedOnBehalfOf;
            retry = shouldRetryEmail(failedEmail);
            if (retry) {
                retryEmail = getRetryEmail(failedEmail)
                updatedRequest.requestedOnBehalfOf = retryEmail;
            }
            break;
        case OPERATIONS.CREATE_MEETING:
            failedEmail = updatedRequest.meeting && updatedRequest.meeting.organizer;
            retry = shouldRetryEmail(failedEmail);
            if (retry) {
                retryEmail = getRetryEmail(failedEmail);
                updatedRequest.meeting.organizer = retryEmail;
            }
            break;
        case OPERATIONS.GET_EXCHANGE_PREFERENCES:
            failedEmail = updatedRequest.mailbox;
            retry = shouldRetryEmail(failedEmail);
            if (retry) {
                retryEmail = getRetryEmail(failedEmail);
                updatedRequest.mailbox = retryEmail;
            }
            break;
        default:
            retry = false;
    }

    return {
        retry,
        retryParams: [params[0], updatedRequest],
        failedEmail,
        retryEmail,
    };
};

export const stringifyError = (error) => {
    let stringifiedError = JSON.stringify(error);
    if (stringifiedError && stringifiedError !== "{}") {
        return stringifiedError;
    }

    // if we failed to stringify the error, we can at least stringify the status code
    return JSON.stringify({
        statusCode: error.status,
    });
};