import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { getCurrentMeeting, getSettingsTimeFormat, getSuggestionsState } from "../../../sagas/selector";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Image from "@amzn/meridian/_image";
import Modal from "../../shared/meridian-custom-components/src/components/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";

import MeetingQuickForm from "./meeting-quick-form";
import MeetingQuickSuggestion from "./meeting-quick-suggestion";
import MeetingQuickFavoritesTab from "./meeting-quick-favorites";
import MeetingDetails from "../../shared/containers/meeting-details";

import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark";
import closeTokens from "@amzn/meridian-tokens/base/icon/close-small";
import flashTokens from "@amzn/meridian-tokens/base/icon/flash";
import QuickMeetingIcon from "../../../assets/meeting-quick/quick-meeting.svg";
import starFilledSvg from "../../../assets/icons/favorites/star_filled_light.svg";

import { COLOR } from "../meeting-quick-constants";

const MeetingQuickTabsMobile = (props) => {
    const isSearchingSuggestions = useSelector(getSuggestionsState);
    const timeFormat = useSelector(getSettingsTimeFormat);
    
    const closeFavoritesButtonRef = useRef();
    const closeSuggestionsButtonRef = useRef();

    return (
        <Column spacingInset="small" spacing="none">
            {!props.showReview ?
                <MeetingQuickForm
                    screenSizeBreakpoint={props.screenSizeBreakpoint}
                    favorites={props.favorites}
                    onAddFavorite={props.onAddFavorite}
                    onRemoveFavorite={props.onRemoveFavorite}
                    onBrowseFavorites={props.onBrowseFavorites}
                    onFavoriteClick={props.onFavoriteClick}
                    isFavorited={props.isFavorited}
                    location={props.location}
                    setLocation={props.setLocation}
                    getRoomOptions={props.getRoomOptions}
                    duration={props.duration}
                    setDuration={props.setDuration}
                    customDuration={props.customDuration}
                    setCustomDuration={props.setCustomDuration}
                    customDurationUnits={props.customDurationUnits}
                    setCustomDurationUnits={props.setCustomDurationUnits}
                    showCustomDuration={props.showCustomDuration}
                    setShowCustomDuration={props.setShowCustomDuration}
                    getDurationInMinutes={props.getDurationInMinutes}
                    meetingSubject={props.meetingSubject}
                    setMeetingSubject={props.setMeetingSubject}
                    meetingBody={props.meetingBody}
                    setMeetingBody={props.setMeetingBody}
                    isPrivate={props.isPrivate}
                    setIsPrivate={props.setIsPrivate}
                    isResponseRequested={props.isResponseRequested}
                    setIsResponseRequested={props.setIsResponseRequested}
                    chimePin={props.chimePin}
                    onSetChimePin={props.onSetChimePin}
                    chimeUniqueIds={props.chimeUniqueIds}
                    chimeUser={props.chimeUser}
                    attendees={props.attendees}
                    timezoneValue={props.timezoneValue}
                    onCloseQuickMeetingModal={props.onCloseQuickMeetingModal}
                    showQuickSuggestions={props.showQuickSuggestions}
                    onGetSuggestions={props.onGetSuggestions}
                    peopleSuggestions={props.peopleSuggestions}
                    onGetRASDataForPerson={props.onGetRASDataForPerson}
                    onGetPeopleSuggestions={props.onGetPeopleSuggestions}
                    onClearPeopleSuggestions={props.onClearPeopleSuggestions}
                    groupSuggestions={props.groupSuggestions}
                    onGetRASDataForGroup={props.onGetRASDataForGroup}
                    onGetGroupSuggestions={props.onGetGroupSuggestions}
                    onClearGroupSuggestions={props.onClearGroupSuggestions}
                    onAddAttendee={props.onAddAttendee}
                    onRemoveAttendee={props.onRemoveAttendee}
                    onRemoveAttendeeByAlias={props.onRemoveAttendeeByAlias}
                    alerts={props.alerts}
                    selectedSuggestionIndex={props.selectedSuggestionIndex}
                    suggestions={props.suggestions}
                    showReview={props.showReview}
                    onShowReview={props.onShowReview}
                    setDiscardMeetingModalOpen={props.setDiscardMeetingModalOpen}
                    setIsFirstSearch={props.setIsFirstSearch}
                    isFirstSearch={props.isFirstSearch}
                    dateRangeValue={props.dateRangeValue}
                    setDateRangeValue={props.setDateRangeValue}
                    dateRangeLabel={props.dateRangeLabel}
                    setDateRangeLabel={props.setDateRangeLabel}
                    startTime={props.startTime}
                    setStartTime={props.setStartTime}
                    endTime={props.endTime}
                    setEndTime={props.setEndTime}
                    timeLabel={props.timeLabel}
                    setTimeLabel={props.setTimeLabel}
                    presets={props.presets}
                    autoCall={props.autoCall}
                    setAutoCall={props.setAutoCall}
                    timeFormat={props.timeFormat}
                    dateFormat={props.dateFormat}
                />
                :
                <Column spacingInset="small">
                    <Column height="100%">
                        <Row alignmentVertical="top" widths={["20px", "fill"]} spacing="small" spacingInset="none">
                            <Image viewportHeight="20px" viewportAspectRatio={1} src={QuickMeetingIcon} size="20px" />
                            <Column spacing="none">
                                <Heading level={2} type="h200">
                                    Simple meeting
                                </Heading>
                                <Text type="b200" alignment="left">
                                    Schedule time with a few attendees and an optional room
                                </Text>
                            </Column>
                        </Row>
                        <MeetingDetails
                            selector={getCurrentMeeting}
                            renderAsModal={false}
                            timeFormat={timeFormat}
                        />
                    </Column>
                </Column>
            }
            <Modal
                open={props.showQuickSuggestions}
                bodySpacingInset="none"
                backgroundElementsId={props.backgroundElementsId}
            >
                <div
                    style={{
                        background: COLOR.TEAL,
                        padding: "0px 16px 16px 0px",
                        minHeight: `calc(100vh - 16px)`, // 100vh - bottom padding
                    }}
                >
                    <span
                        aria-label="Close"
                        role="button"
                        ref={closeSuggestionsButtonRef}
                        tabIndex="0"
                        className={props.showReview ? "close-dark" : "close-light"}
                        style={{padding: "13px 0px 0px 0px"}}
                        onClick={() => props.setShowQuickSuggestions(false)}
                    >
                        <Icon tokens={closeTokens} />
                    </span>
                    <Theme tokens={blueDarkTokens}>
                        <Column spacing="none" width="fit" spacingInset="medium none none medium">
                            <Row widths={["20px", "fill"]} spacing="small">
                                <Text>
                                    <Icon tokens={flashTokens}/>
                                </Text>
                                <Heading level={2} type="h300">
                                    Smart suggestions
                                </Heading>
                            </Row>
                            <Row widths={["20px", "fill"]} spacing="small">
                                <Row/>
                                <Text type="b200" alignment="left">
                                    Pick a meeting slot that works best
                                </Text>
                            </Row>
                        </Column>
                    </Theme>
                    <MeetingQuickSuggestion
                        screenSizeBreakpoint={props.screenSizeBreakpoint}
                        attendees={props.attendees}
                        timezones={props.timezones}
                        timezoneValue={props.timezoneValue}
                        suggestions={props.suggestions}
                        selectedSuggestionIndex={props.selectedSuggestionIndex}
                        setSelectedSuggestionIndex={props.setSelectedSuggestionIndex}
                        meetingSubject={props.meetingSubject}
                        setMeetingSubject={props.setMeetingSubject}
                        meetingBody={props.meetingBody}
                        setMeetingBody={props.setMeetingBody}
                        setShowQuickSuggestions={props.setShowQuickSuggestions}
                        onCloseQuickMeetingModal={props.onCloseQuickMeetingModal}
                        onSendMeetingRequest={props.onSendMeetingRequest}
                        alerts={props.alerts}
                    />
                    {!isSearchingSuggestions &&
                        <Theme tokens={blueDarkTokens}>
                            <Row alignmentHorizontal="right" spacingInset="medium none none medium">
                                <Button
                                    size="medium"
                                    ref={closeSuggestionsButtonRef}
                                    onClick={() => props.setShowQuickSuggestions(false)}
                                >
                                    Done
                                </Button>
                            </Row>
                        </Theme>
                    }
                </div>
            </Modal>
            <Modal
                open={props.showFavorites}
                bodySpacingInset="none"
                backgroundElementsId={props.backgroundElementsId}
            >
                <div
                    style={{
                        background: COLOR.TEAL,
                        padding: "0px 16px 16px 0px",
                        minHeight: `calc(100vh - 16px)`, // 100vh - bottom padding
                    }}
                >
                    <span
                        aria-label="Close"
                        role="button"
                        ref={closeFavoritesButtonRef}
                        tabIndex="0"
                        className={props.showReview ? "close-dark" : "close-light"}
                        style={{padding: "13px 0px 0px 0px"}}
                        onClick={() => props.setShowFavorites(false)}
                    >
                        <Icon tokens={closeTokens} />
                    </span>
                    <Theme tokens={blueDarkTokens}>
                        <Column spacing="none" width="fit" spacingInset="medium none medium medium">
                            <Row widths={["20px", "fill"]} spacing="small">
                                <img src={starFilledSvg} alt="favorited icon" width="22px" height="22px"/>
                                <Heading level={2} type="h300">
                                    Favorites
                                </Heading>
                            </Row>
                            <Row widths={["20px", "fill"]} spacing="small">
                                <Row/>
                                <Text type="b200" alignment="left">
                                    Add your favorites to your meetings with a single click
                                </Text>
                            </Row>
                        </Column>
                    </Theme>
                    <MeetingQuickFavoritesTab
                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                        screenSizeBreakpoint={props.screenSizeBreakpoint}
                        onAddAttendee={props.onAddAttendee}
                    />
                    <Theme tokens={blueDarkTokens}>
                        <Row alignmentHorizontal="right">
                            <Button
                                size="medium"
                                onClick={() => props.setShowFavorites(false)}
                            >
                                Done
                            </Button>
                        </Row>
                    </Theme>
                </div>
            </Modal>
        </Column>
    );
}

export default MeetingQuickTabsMobile;
