const calendarNamespace = "calendar";

export const SET_CALENDAR_VIEW_TYPE = `${calendarNamespace}.set_calendar_view_type`;
export const SET_CALENDAR_DATE = `${calendarNamespace}.set_calendar_date`;
export const SET_CALENDAR_STATUS_FILTER = `${calendarNamespace}.set_calendar_status_filter`;
export const SET_CALENDAR_RESPONSE_FILTER = `${calendarNamespace}.set_calendar_response_filter`;

export const FIND_MEETINGS = `${calendarNamespace}.find_meetings`;
export const SET_MEETING_LIST = `${calendarNamespace}.set_meeting_list`;

export const SET_AVAILABILITY_RAW_DATA = `${calendarNamespace}.set_availability_raw_data`;
export const SET_AVAILABILITY_BLOCKS = `${calendarNamespace}.set_availability_blocks`;
