import React from "react";

import { renderExternalLink } from "../../shared-utils.js";

const LinkToast = (props) => {
    return (
        <React.Fragment>
            {props.text}&nbsp;
            {renderExternalLink(props.linkText, props.href, props.linkName)}
        </React.Fragment>
    );
};

export default LinkToast;