import React from "react";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Responsive from "@amzn/meridian/responsive";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import { HOMEPAGE_FOOTER } from "../../shared/shared-constants";
import PatternLarge from "../../../assets/backgrounds/pattern_large_dark.svg";
import PatternSmall from "../../../assets/backgrounds/pattern_small_vertical.svg";
import thankYouSmile from "../../../assets/icons/migration/thank-you-smile.svg";

export const M365_MIGRATION_REDIRECT_ENABLED = false;

export const M365_MIGRATION_ALLOWLIST = [
    "alvinyu",
    "gertas",
    "adriandv",
    "srudat",
    "oeckhout"
];

const Migration = () => {
    return (
        <Responsive query="min-width" props={{
            isWrapOptions: {
                default: true,
                "640px": false
            }
        }}>
            {(props) => (
                <Column width="100%" spacing="450">
                    <div aria-labelledby="Main Content" style={{
                        "backgroundImage": `url(${!props.isWrapOptions ? PatternLarge : PatternSmall})`,
                        "backgroundRepeat": "no-repeat",
                        "backgroundPosition": "115% 50%",
                        "backgroundColor": "rgba(26, 69, 78)",
                        "maxHeight": "350px",
                        "width": "100%",
                    }}>
                        <Row maxWidth="630px" spacingInset={props.isWrapOptions ? "none" : "none none none 600"}>
                            <Row spacingInset={props.isWrapOptions ? "none" : "none none none 600"}>
                                <Column width="100%" height="350px" maxWidth="630px" alignmentVertical="bottom" spacingInset={props.isWrapOptions ? "none large" : "none none none 400"}>
                                    <Row height="116px" />
                                    <img src={thankYouSmile} style={{"height": "108px", "width": "100px"}} alt="" />
                                    <Row height="48px" />
                                    <Row alignmentVertical="bottom" spacingInset="none none small none">
                                        <Text type="h500" color="inverted">Thanks for being an Amazon Meetings customer over the past 8 years!</Text>
                                    </Row>
                                </Column>
                            </Row>
                        </Row>
                    </div>
                    <Row maxWidth="630px" spacingInset={props.isWrapOptions ? "none" : "none none none 600"}>
                        <Row spacingInset={props.isWrapOptions ? "none" : "none none none 600"}>
                            <Column maxWidth="630px" spacingInset={props.isWrapOptions ? "none large" : "none none none 400"}>
                                <Column spacing="450">
                                    <Text type="b300">Your mailbox has been migrated to Exchange Online. Now you have access to M365 including Outlook and Teams for meeting scheduling, room booking and more.</Text>
                                    <Text type="b300">We will deprecate Amazon Meetings website once all Amazonians have been migrated to Exchange Online.</Text>
                                    <Text type="b300">We want to hear from you! Tell us about your experience with Outlook and Teams including feature request and pain points in the Feedback section. Our team is still committed to delivering the best meeting experience for Amazonians. And we hope you’ll continue to use our other Amazon Meetings products too!</Text>
                                    <Text type="b300">-Amazon Meetings team</Text>
                                    <Row width="232px">
                                        <Button type="primary" href="https://teams.microsoft.com/">Go to Microsoft Teams</Button>
                                    </Row>
                                </Column>
                                <Column spacing="300" alignmentVertical="bottom">
                                    <Column height="32px" />
                                    <Text type="h500">To send a Livestream request</Text>
                                    <Column spacing="none">
                                        <Text type="b300">Please use the following link to cut a ticket.</Text>
                                        <Text type="b300">Livestream intake will be available in February 2024.</Text>
                                    </Column>
                                    <Column height="8px" />
                                    <Row maxWidth="232px">
                                        <Button type="tertiary" target="_blank" href="https://t.corp.amazon.com/create/templates/6e5ae1ef-d225-4bba-a821-0b3bf07b98f3">Send Livestream request</Button>
                                    </Row>
                                </Column>
                                <Column height={HOMEPAGE_FOOTER.HEIGHT} />
                            </Column>
                        </Row>
                    </Row>
                </Column>
            )}
        </Responsive>
    );
};

export default Migration;