import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { getWorkflow } from "../../../sagas/selector";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Heading from '@amzn/meridian/heading';
import closeTokens from "@amzn/meridian-tokens/base/icon/close";

import "../styles/navigation.css";

import Sheet from "../../shared/meridian-custom-components/src/components/sheet"
import DiscardMeetingModal from "../../shared/components/discard-meeting-modal";

import { ReactComponent as MeetingsLogo } from "../../../assets/logos/amzn_meetings_logo_color.svg";
import { ReactComponent as BetaLogo } from "../../../assets/logos/amzn_meetings_beta_color.svg";

import { generateComponentId, renderExternalLink } from "../../shared/shared-utils";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { changePage } from "../../shared/workflow/workflow-utils";

const MeetingsSideMenu = (props) => {
    const dispatch = useDispatch();
    const currentWorkflow = useSelector(getWorkflow);

    const [discardMeetingModalOpen, setDiscardMeetingModalOpen] = useState(false);
    const [newWorkflow, setNewWorkflow] = useState(); // always set when discard modal is opened
    const [modalTitle, setModalTitle] = useState("Discard meeting");

    const open = props.open;
    const onClose = props.onClose;
    const sideMenuSize = props.sideMenuSize;

    const SIDE_MENU_ID = "side-menu-id";

    const handleChangePage = (event, newWorkflow) => {
        if (event.ctrlKey || event.metaKey) {
            return;
        }
        if (!newWorkflow || currentWorkflow.PAGE === newWorkflow.PAGE) {
            event.preventDefault();
        } else if (currentWorkflow === WORKFLOWS.STANDARD_MEETING ||
            currentWorkflow === WORKFLOWS.EDIT_MEETING ||
            currentWorkflow === WORKFLOWS.OOTO ||
            currentWorkflow === WORKFLOWS.LIVESTREAM ||
            currentWorkflow === WORKFLOWS.POLL_CREATE ||
            currentWorkflow === WORKFLOWS.EDIT_POLL) {

            if (currentWorkflow === WORKFLOWS.POLL_CREATE || currentWorkflow === WORKFLOWS.EDIT_POLL) {
                setModalTitle("Discard meeting poll");
            } else {
                setModalTitle("Discard meeting");
            }

            setNewWorkflow(newWorkflow);
            setDiscardMeetingModalOpen(true);
            event.preventDefault();
        } else {
            changePage(dispatch, currentWorkflow, newWorkflow);
        }
        onClose();
    };

    return (
        <React.Fragment>
            <Sheet open={open} onClose={onClose} type="overlay" side="left" spacingInset="none" labelledById="SiteNavigationLabel">
                <span id="SiteNavigationLabel" style={{visibility: "hidden"}}>Site navigation</span>
                <Column spacing="small" spacingInset="none" height="100%" width={sideMenuSize}>
                    <Row alignmentHorizontal="justify" spacingInset="medium">
                        <a
                            href={WORKFLOWS.HOME.HREF}
                            id={generateComponentId("link", "home")}
                            onClick={(event) => handleChangePage(event, WORKFLOWS.HOME)}
                        >
                            <Row spacing="small" alignmentVertical="bottom">
                                <MeetingsLogo width="136px" height="45px" />
                                {props.displayBeta &&
                                    <BetaLogo width="48px" height="36px" style={{paddingBottom: "4px"}} />
                                }
                            </Row>
                        </a>
                        <Button onClick={onClose} type="icon">
                            <Icon tokens={closeTokens}>Close dialog</Icon>
                        </Button>
                    </Row>

                    <Column spacing="medium" spacingInset="none xsmall">
                        <Divider size="small" />
                        <a
                            className={currentWorkflow.PAGE === WORKFLOWS.CALENDAR.PAGE ? "sideMenuLinkSelected" : "sideMenuLink"}
                            href={WORKFLOWS.CALENDAR.HREF}
                            id={generateComponentId("link", "calendar")}
                            onClick={(event) => {handleChangePage(event, WORKFLOWS.CALENDAR)}}
                            aria-current={currentWorkflow.PAGE === WORKFLOWS.CALENDAR.PAGE ? "page" : null}
                        >
                            <div className={currentWorkflow.PAGE === WORKFLOWS.CALENDAR.PAGE ? "sideMenuLinkBarSelected" : "sideMenuLinkBar"}></div>
                            Calendar
                        </a>
                        <Divider size="small" />
                        <a
                            className={currentWorkflow.PAGE === WORKFLOWS.STANDARD_MEETING.PAGE ? "sideMenuLinkSelected" : "sideMenuLink"}
                            href={WORKFLOWS.STANDARD_MEETING.HREF}
                            id={generateComponentId("link", "create-a-meeting")}
                            onClick={(event) => {handleChangePage(event, WORKFLOWS.STANDARD_MEETING)}}
                            aria-current={currentWorkflow.PAGE === WORKFLOWS.STANDARD_MEETING.PAGE ? "page" : null}
                        >
                            <div className={currentWorkflow.PAGE === WORKFLOWS.STANDARD_MEETING.PAGE ? "sideMenuLinkBarSelected" : "sideMenuLinkBar"}></div>
                            Create a meeting
                        </a>
                        <a
                            className={currentWorkflow.PAGE === WORKFLOWS.ROOM_BOOKING.PAGE ? "sideMenuLinkSelected" : "sideMenuLink"}
                            href={WORKFLOWS.ROOM_BOOKING.HREF}
                            id={generateComponentId("link", "room-booking")}
                            onClick={(event) => {handleChangePage(event, WORKFLOWS.ROOM_BOOKING)}}
                            aria-current={currentWorkflow.PAGE === WORKFLOWS.ROOM_BOOKING.PAGE ? "page" : null}
                        >
                            <div className={currentWorkflow.PAGE === WORKFLOWS.ROOM_BOOKING.PAGE ? "sideMenuLinkBarSelected" : "sideMenuLinkBar"}></div>
                            Room booking
                        </a>
                        <a
                            className={currentWorkflow.PAGE === WORKFLOWS.OOTO.PAGE ? "sideMenuLinkSelected" : "sideMenuLink"}
                            href={WORKFLOWS.OOTO.HREF}
                            id={generateComponentId("link", "out-of-the-office")}
                            onClick={(event) => {handleChangePage(event, WORKFLOWS.OOTO)}}
                            aria-current={currentWorkflow.PAGE === WORKFLOWS.OOTO.PAGE ? "page" : null}
                        >
                            <div className={currentWorkflow.PAGE === WORKFLOWS.OOTO.PAGE ? "sideMenuLinkBarSelected" : "sideMenuLinkBar"}></div>
                            Out Of The Office
                        </a>
                        <a
                            className={currentWorkflow.PAGE === WORKFLOWS.MAPS.PAGE ? "sideMenuLinkSelected" : "sideMenuLink"}
                            href={WORKFLOWS.MAPS.HREF}
                            id={generateComponentId("link", "availability-sharing")}
                            onClick={(event) => {handleChangePage(event, WORKFLOWS.MAPS)}}
                            aria-current={currentWorkflow.PAGE === WORKFLOWS.MAPS.PAGE ? "page" : null}
                        >
                            <div className={currentWorkflow.PAGE === WORKFLOWS.MAPS.PAGE ? "sideMenuLinkBarSelected" : "sideMenuLinkBar"}></div>
                            Availability sharing
                        </a>
                        <a
                            className={currentWorkflow.PAGE === WORKFLOWS.POLL_DASHBOARD.PAGE ? "sideMenuLinkSelected" : "sideMenuLink"}
                            href={WORKFLOWS.POLL_DASHBOARD.HREF}
                            id={generateComponentId("link", "poll-dashboard")}
                            onClick={(event) => {handleChangePage(event, WORKFLOWS.POLL_DASHBOARD)}}
                            aria-current={currentWorkflow.PAGE === WORKFLOWS.POLL_DASHBOARD.PAGE ? "page" : null}
                        >
                            <div className={currentWorkflow.PAGE === WORKFLOWS.POLL_DASHBOARD.PAGE ? "sideMenuLinkBarSelected" : "sideMenuLinkBar"}></div>
                            Meeting polls dashboard
                        </a>
                        <a
                            className={currentWorkflow.PAGE === WORKFLOWS.LIVESTREAM.PAGE ? "sideMenuLinkSelected" : "sideMenuLink"}
                            href={WORKFLOWS.LIVESTREAM.HREF}
                            id={generateComponentId("link", "livestream-event")}
                            onClick={(event) => {handleChangePage(event, WORKFLOWS.LIVESTREAM)}}
                            aria-current={currentWorkflow.PAGE === WORKFLOWS.LIVESTREAM.PAGE ? "page" : null}
                        >
                            <div className={currentWorkflow.PAGE === WORKFLOWS.LIVESTREAM.PAGE ? "sideMenuLinkBarSelected" : "sideMenuLinkBar"}></div>
                            Livestream request
                        </a>
                        <Divider size="small" />
                        <a
                            className={currentWorkflow.PAGE === WORKFLOWS.SETTINGS.PAGE ? "sideMenuLinkSelected" : "sideMenuLink"}
                            href={WORKFLOWS.SETTINGS.HREF}
                            id={generateComponentId("link", "preferences")}
                            onClick={(event) => {handleChangePage(event, WORKFLOWS.SETTINGS)}}
                            aria-current={currentWorkflow.PAGE === WORKFLOWS.SETTINGS.PAGE ? "page" : null}
                        >
                            <div className={currentWorkflow.PAGE === WORKFLOWS.SETTINGS.PAGE ? "sideMenuLinkBarSelected" : "sideMenuLinkBar"}></div>
                            Preferences
                        </a>
                        <Divider size="small" />
                        <Row>
                            <Column width={"6px"}/>
                            <Column>
                                <Heading level={3} type={"h100"}>Amazon Meetings products:</Heading>
                                <Column spacing="small">
                                    {renderExternalLink("Amazon Meetings Website", "https://w.amazon.com/bin/view/Meetex/AM2/")}
                                    {renderExternalLink("Amazon Meetings Slack App", "https://w.amazon.com/bin/view/Meetex/AmazonMeetingsSlackApp/")}
                                    {renderExternalLink("Amazon Meetings Livestream", "http://livestream.amazon.dev/")}
                                </Column>
                            </Column>
                        </Row>
                        <Divider size="small" />
                        <Row>
                            <Column width={"6px"}/>
                            <Column>
                                <Heading level={3} type={"h100"}>Other products:</Heading>
                                <Column spacing="small">
                                    {renderExternalLink("Skylantern", "https://w.amazon.com/bin/view/Meetex/SkyLantern/")}
                                    {renderExternalLink("One Touch Meeting (OTM)", "https://w.amazon.com/bin/view/CorporateInfrastructureDevelopment/AVDevs/Projects/Touch10/OneTouchMeeting/SchedulerService/DesignDoc2.0/")}                                
                                </Column>
                            </Column> 
                        </Row>                       
                    </Column>
                </Column>
            </Sheet>
            <DiscardMeetingModal
                modalTitle={modalTitle}
                open={discardMeetingModalOpen}
                setOpen={setDiscardMeetingModalOpen}
                backgroundElementsId={SIDE_MENU_ID}
                newWorkflow={newWorkflow}
                onClose={onClose}
            />
        </React.Fragment>
        /* Revert back to Meridian side menu component once they fix accessibility issues
        <SideMenu open={open} onClose={() => onClose()} type="overlay" maxWidth={sideMenuSize} width={sideMenuSize}>
            <SideMenuTitle href={WORKFLOWS.HOME.HREF} onClick={() => {changePage(dispatch, currentWorkflow, WORKFLOWS.HOME); onClose();}} onClose={onClose}>
                <MeetingsLogo width="136px" height="45px" />
            </SideMenuTitle>

            <SideMenuLink href={WORKFLOWS.CALENDAR.HREF} selected={currentWorkflow.PAGE === WORKFLOWS.CALENDAR.PAGE} onClick={() => {changePage(dispatch, currentWorkflow, WORKFLOWS.CALENDAR); onClose();}} onClose={onClose} id={generateComponentId("side-menu-link", "calendar")}>Calendar</SideMenuLink>

            <SideMenuLink href={WORKFLOWS.STANDARD_MEETING.HREF} selected={currentWorkflow.PAGE === WORKFLOWS.STANDARD_MEETING.PAGE} onClick={() => {changePage(dispatch, currentWorkflow, WORKFLOWS.STANDARD_MEETING); onClose();}} onClose={onClose} id={generateComponentId("side-menu-link", "create-a-meeting")}>Create a meeting</SideMenuLink>

            <SideMenuLink href={WORKFLOWS.ROOM_BOOKING.HREF} selected={currentWorkflow.PAGE === WORKFLOWS.ROOM_BOOKING.PAGE} onClick={() => {changePage(dispatch, currentWorkflow, WORKFLOWS.ROOM_BOOKING); onClose();}} onClose={onClose} id={generateComponentId("side-menu-link", "room-booking")}>Room booking</SideMenuLink>

            <SideMenuLink href={WORKFLOWS.OOTO.HREF} selected={currentWorkflow.PAGE === WORKFLOWS.OOTO.PAGE} onClick={() => {changePage(dispatch, currentWorkflow, WORKFLOWS.OOTO); onClose();}} onClose={onClose} id={generateComponentId("side-menu-link", "out-of-the-office")}>Out Of The Office</SideMenuLink>
        </SideMenu>
         */
    );
};

export default MeetingsSideMenu;
