import pascalCase from "./pascal-case"
import upperFirst from "./upper-first"
import lowerFirst from "./lower-first"
import { memoize, memoizeCurry, memoizeByKeys } from "./functional"

/**
 * Select a single token from a token object given a key (and optionally a
 * "suffix" key or list of suffix keys). If the token doesn't exist then an
 * attempt is made to replace the last suffix key with "default" and get the
 * value for that token. If that token also doesn't exist then `undefined` is
 * returned.
 *
 * @example
 * const tokens = {
 *   backgroundColorDefault: "#fff",
 *   backgroundColorHover: "#ccc",
 *   backgroundColorPressed: "#f00",
 * }
 * getToken(tokens, "backgroundColor", "active") // #fff
 * getTokens(tokens, "background", ["color", "pressed"]) // #f00
 * getToken(tokens, "myBackgroundColor") // undefined
 */
const getToken = (tokens, key, suffixKeys) => {
  // If we got an array of suffix keys, turn them into a PascalCaseString and
  // look up the token
  if (suffixKeys && Array.isArray(suffixKeys)) {
    const value = tokens[`${key}${pascalCase(suffixKeys)}`]
    return value !== undefined
      ? value
      : tokens[`${key}${pascalCase(suffixKeys.slice(0, -1))}Default`]
    // If we got a single suffix key then upper case the first character and look
    // up the token
  } else if (suffixKeys) {
    const value = tokens[`${key}${upperFirst(suffixKeys)}`]
    return value !== undefined ? value : tokens[`${key}Default`]
    // If we didn't get any suffix keys then do a simple lookup
  } else {
    return tokens[key]
  }
}

/**
 * This will, given a root key and token object, return a special helper that
 * includes token utilities with those inputs already applied. Calling the
 * helper as a function is equivalent to calling `getToken`, but the helper also
 * includes several properties that make it easier to construct CSS from tokens.
 * This helper is useful when working with an object of tokens that all have the
 * same prefix.
 *
 * @example
 * const tokens = {
 *   buttonBorderWidth: 1,
 *   buttonBorderStyle: "dotted",
 *   buttonBorderColor: "#f00",
 * }
 * const t = getTokenHelper("button")(tokens)
 * t("borderWidth") // 1
 * t.borderCss("border") // "1px dotted #f00"
 * t.tokens === tokens // true
 *
 */
const getTokenHelper = memoizeCurry((prefixKey, tokens) => {
  const helper = function(key, suffixKeys) {
    return getToken(
      tokens,
      prefixKey ? `${prefixKey}${upperFirst(key)}` : key,
      suffixKeys
    )
  }
  helper.tokens = tokens
  // Pass in "myBorder" and shorthand border css will be generated based on
  // "myBorderWidth" and "myBorderColor" tokens.
  helper.borderCss = key => {
    const width = helper(key, "width")
    const color = helper(key, "color")
    return width && color ? `${width}px solid ${color}` : undefined
  }
  // Pass in "myShadow" and shorthand box shadow css will be generated based on
  // "myShadowOffsetX", "myShadowOffsetY", "myShadowBlur", "myShadowSpread",
  // and "myShadowColor" tokens
  helper.boxShadowCss = key => {
    const color = helper(key, "color")
    return color
      ? `${helper(key, "offsetX")}px ${helper(key, "offsetY")}px ${helper(
          key,
          "blur"
        )}px ${helper(key, "spread")}px ${color}`
      : undefined
  }
  // Pass in "myMotion" and a single css property or array of properties and
  // shorthand transition css will be generated that animates those properties
  // based on "myMotionFunction" and "myMotionDuration" tokens
  helper.transitionCss = (key, properties) => {
    const transition = `${helper(key, "duration")} ${helper(key, "function")}`
    return Array.isArray(properties)
      ? properties.map(property => `${property} ${transition}`).join(", ")
      : `${properties} ${transition}`
  }
  return helper
}, 1)

/**
 * Gets icon data from a set of icon tokens
 *
 * ```
 * const tokens = {
 *   iconCheckmarkData: "<svg />",
 *.  iconCheckmarkWidth: 12,
 *   iconCheckmarkHeight: 10,
 * }
 * // Returns { data: "<svg />", width: 12, height: 10 }
 * getIconFromTokens(tokens)
 * ```
 */
const getIconFromTokens = tokens =>
  Object.keys(tokens).reduce((result, key) => {
    if (key.indexOf("Data") > 0) {
      result.data = tokens[key]
    } else if (key.indexOf("Rtl") > 0) {
      result.dataRtl = tokens[key]
    } else if (key.indexOf("Width") > 0) {
      result.width = tokens[key]
    } else if (key.indexOf("Height") > 0) {
      result.height = tokens[key]
    }
    return result
  }, {})

/**
 * If you pass in a function that returns themed tokens when given a theme
 * object (e.g. any of our "component" tokens), this will call that function
 * with an empty theme object and filter out any tokens that are undefined. This
 * is useful for quickly accessing unthemed component tokens. Use this sparingly
 * as what is and is not themed may change in the future and require a refactor
 * of any code using this function.
 */
const getUnthemedTokens = tokens => {
  const unthemedTokens = tokens({})
  return Object.keys(unthemedTokens).reduce((result, key) => {
    if (unthemedTokens[key] !== undefined) {
      result[key] = unthemedTokens[key]
    }
    return result
  }, {})
}

/**
 * Takes an object of tokens and removes the prefix from each token name (object
 * key). The result is returned with the first character of the new keys
 * lowercased.
 *
 * ```
 * const tokens = {
 *   iconCheckmarkData: "<svg />",
 *.  iconCheckmarkWidth: 12,
 *   iconCheckmarkHeight: 10,
 * }
 * // Returns { data: "<svg />", width: 12, height: 10 }
 * removetokenPrefixes(tokens, "iconCheckmark")
 * ```
 */
const removeTokenPrefixes = (tokens, prefix) => {
  const match = new RegExp(`^${prefix}`)
  return Object.keys(tokens).reduce((result, key) => {
    if (match.test(key)) {
      result[lowerFirst(key.replace(match, ""))] = tokens[key]
    }
    return result
  }, {})
}

/**
 * Memoize f(tokens, props) that is used extensively throughout meridian components,
 * specify the keys in the object to compare when determining whether to re-use
 * a previous calculation.
 *
 * @example
 * const style = memoizeTokenStyles((t, {a, b}) => css({a, b}), ["a", "b"])
 * style(tokens, {a, b})
 */
const memoizeTokenStyles = (f, keys) => {
  const fn = memoize(t => memoizeByKeys(props => f(t, props), keys))
  return (t, props) => fn(t)(props)
}

export {
  getToken,
  getTokenHelper,
  getIconFromTokens,
  getUnthemedTokens,
  removeTokenPrefixes,
  memoizeTokenStyles,
}
