import { push } from "connected-react-router";

import { switchWorkflow } from "./actions";
import { clearMeetingPollTimeSlotsSelectedMarker, setMeetingPollFromPage } from "../../meeting-polls/actions";
import { WORKFLOWS } from "./workflow-constants";

export const changePage = (dispatch, currentWorkflow, newWorkflow) => {
    if (currentWorkflow.NAME === newWorkflow.NAME || currentWorkflow.PAGE === newWorkflow.PAGE) {
        return;
    }

    if (newWorkflow.NAME === WORKFLOWS.POLL_CREATE.NAME) {
        dispatch(setMeetingPollFromPage(currentWorkflow.NAME));
        // When change page to create poll all the selectedTimeSlots will be empty need to reset this marker as well
        dispatch(clearMeetingPollTimeSlotsSelectedMarker());
    }
    
    dispatch(switchWorkflow(newWorkflow));
    if (newWorkflow.HREF) {
        // Push adds the '#' to the beginning of the parameter sent
        dispatch(push(newWorkflow.HREF.slice(1, undefined)));
    }
};

export const pathToWorkflow = (path = undefined) => {
    if (!path && window && window.location && window.location.href) {
        path = "#/" + window.location.href.split("/")[4];
        // Ignoring query params when trying to discover the user's workflow
        path = path.split("?")[0];
    }

    switch(path) {
        case WORKFLOWS.HOME.HREF:
            return WORKFLOWS.HOME;
        case WORKFLOWS.CALENDAR.HREF:
            return WORKFLOWS.CALENDAR;
        case WORKFLOWS.STANDARD_MEETING.HREF:
            return WORKFLOWS.STANDARD_MEETING;
        case WORKFLOWS.EDIT_MEETING.HREF:
            return WORKFLOWS.EDIT_MEETING;
        case WORKFLOWS.ROOM_BOOKING.HREF:
            return WORKFLOWS.ROOM_BOOKING;
        case WORKFLOWS.OOTO.HREF:
            return WORKFLOWS.OOTO;
        case WORKFLOWS.MAPS.HREF:
            return WORKFLOWS.MAPS;
        case WORKFLOWS.POLL_CREATE.HREF:
            return WORKFLOWS.POLL_CREATE;
        case WORKFLOWS.POLL_DASHBOARD.HREF:
            return WORKFLOWS.POLL_DASHBOARD;
        case WORKFLOWS.EDIT_POLL.HREF:
            return WORKFLOWS.EDIT_POLL;
        case WORKFLOWS.RESPOND_POLL.HREF:
            return WORKFLOWS.RESPOND_POLL;
        case WORKFLOWS.LIVESTREAM.HREF:
            return WORKFLOWS.LIVESTREAM;
        case WORKFLOWS.UNAUTHORIZED.HREF:
            return WORKFLOWS.UNAUTHORIZED;
        case WORKFLOWS.SETTINGS.HREF:
            return WORKFLOWS.SETTINGS;
        default:
            return WORKFLOWS.HOME;
    }
};
