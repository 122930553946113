import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";

import { getStageConfig } from "../../../../sagas/selector";

import Alert from "@amzn/meridian/alert";

import { getIsItDownInfo } from "../is-it-down-utils";
import { renderExternalLink } from "../../shared-utils";

const IsItDownNotification = (props) => {
    const stageConfig = useSelector(getStageConfig);
    const region = stageConfig && stageConfig.region;
    const stage = stageConfig && stageConfig.stage;

    const [isItDownInfo, setIsItDownInfo] = useState();

    useEffect(() => {
        if (region && stage) {
            // Call promise to obtain is it down info
            getIsItDownInfo(region, stage).then((info) => {
                setIsItDownInfo(info);
            });
        }
    }, [region, stage]);

    const removeNotification = (index) => {
        let info = isItDownInfo;

        info.splice(index, 1);

        setIsItDownInfo([...info]);
    };

    // Convert URLs from the message into links
    const parseAlertMessage = (message) => {
        let updatedMessage = message;

        // Regex to match URLs in a markdown format, e.g. [Amazon Meetings](https://meetings.amazon.com)
        // Reference: https://davidwells.io/snippets/regex-match-markdown-links
        const markdownUrlRegex = new RegExp(/^\[([\w\s\d.,/\\?=#!&*$^()"']+)\]\((https?:\/\/[\w\d./?=#]+(-?[\w\d./?=#]+)+)\)$/gm);

        // Regex to match URLs that aren't surrounded by single/double quotes to avoid matching with URLs within HTML
        // Reference: https://stackoverflow.com/questions/18103343/selecting-urls-using-regexp-but-ignoring-them-when-surrounded-by-double-quotes
        const urlRegex = new RegExp(/(?=(([^'"]+"){2})*[^'"]*$)((https?:\/\/)?(\w+\.)+\w{2,}(:[0-9]+)?((\/(\w+-?)+\w+)+(\.\w+)?)?\/?)/gm);

        // Replace markdown URL with the HTML markup for an external Meridian link using the label and URL from the match
        const replaceMarkdownUrl = (match) => {
            const label = match.substring(match.indexOf("[") + 1, match.indexOf("]"));
            const href = match.substring(match.indexOf("(") + 1, match.indexOf(")"));

            return ReactDOMServer.renderToStaticMarkup(renderExternalLink(label, href));
        };

        // Replace matched url with the HTML markup for an external Meridian link
        const replaceUrl = (match) => {
            return ReactDOMServer.renderToStaticMarkup(renderExternalLink(match, match));
        };

        // Update any URLs from the message with external Meridian links
        updatedMessage = updatedMessage.replace(markdownUrlRegex, replaceMarkdownUrl);
        updatedMessage = updatedMessage.replace(urlRegex, replaceUrl);

        return (
            <span dangerouslySetInnerHTML={{__html: updatedMessage}} />
        );
    };

    return (isItDownInfo && isItDownInfo.length ?
        isItDownInfo.map((info, index) => (
            <Alert type={info.type} size="medium" onClose={() => removeNotification(index)}>
                {parseAlertMessage(info.message)}
            </Alert>
        ))
        :
        null
    );
};

export default IsItDownNotification;