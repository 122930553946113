/**
 * Generate a CSS value for an encoded svg icon. This essentially just sets the
 * color and wraps the data in a "url()".
 *
 * @example
 * getIconCss(data, "#000")
 */
const getIconCss = (data, color) => {
  return data && color
    ? `url("${data.replace(
        new RegExp("currentColor", "g"),
        // NOTE: Encode any "#" chars in the color value to future proof against
        // this change: https://www.chromestatus.com/feature/5656049583390720
        color.replace(new RegExp("#", "g"), "%23")
      )}")`
    : "none"
}

export default getIconCss
