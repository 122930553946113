import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import AppLayout from "@amzn/meridian/app-layout";
import Responsive from "@amzn/meridian/responsive";

import MeetingMasthead from "./features/navigation/components/meetings-masthead";
import MeetingsFooter from "./features/navigation/components/meetings-footer";
import reducerStore, { history } from "./reducers";

import { SCREEN_SIZE } from "./features/shared/shared-constants";
import Routers from "./routers";
import "./index.css";

import "./features/api/interceptors/csrf_token_interceptor";

const store = reducerStore();


render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Responsive
				query="min-width"
				props={{
					mastheadSize: {
						default: "large",
						"550px": "small",
					},
					mastheadSizePx: {
						default: "57px",
						"550px": "37px",
					},
					screenSizeBreakpoint: {...SCREEN_SIZE.RESPONSIVE_BREAKPOINTS}
				}}
			>
				{(props) => (
					<AppLayout
						headerComponent={MeetingMasthead}
						// sidebarComponent={SideMenu}
						footerComponent={MeetingsFooter}
						backgroundColor="alternateSecondary"
					>
						<MeetingMasthead
							mastheadSize={props.mastheadSize}
							screenSizeBreakpoint={props.screenSizeBreakpoint}
						/>
						<Routers
							store={store}
							mastheadSizePx={props.mastheadSizePx}
						/>
						{props.screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.SM &&
							<div
								style={{
									position: "fixed",
									left: 0,
									bottom: 0,
									width: "100%",
								}}
							>
								<MeetingsFooter />
							</div>
						}
					</AppLayout>
				)}
			</Responsive>
		</ConnectedRouter>
	</Provider>,
	document.getElementById("root")
);

//        <React.StrictMode>
//<App />
//</React.StrictMode>
