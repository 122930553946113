module.exports = {
    STEP: {
        ATTENDEES_AND_ROOMS: "ATTENDEES_AND_ROOMS",
        SMART_SUGGESTIONS: "SMART_SUGGESTIONS",
        MEETING_AGENDA_AND_REVIEW: "MEETING_AGENDA_AND_REVIEW",
    },
    REDIRECT_STEP: {
        "ATTENDEES_AND_ROOMS": "ATTENDEES_AND_ROOMS",
        "SMART_SUGGESTIONS": "SMART_SUGGESTIONS",
        "MEETING_AGENDA_AND_REVIEW": "MEETING_AGENDA_AND_REVIEW",
    },
    STEP_LABEL: {
        ATTENDEES_AND_ROOMS: "Attendees and rooms",
        SMART_SUGGESTIONS: "Smart suggestions",
        MEETING_AGENDA_AND_REVIEW: "Meeting agenda and review",
    },
    TABLE_ROW_COLOR: "#f0f1f2", // Meridian gray-100
    TABLE_HEADER_ROW_COLOR: "#FFFFFF", // Meridian gray-0
    BACKGROUND_OPACITY: 0.05,
    FLOW_TYPE: {
        CREATE: "create",
        UPDATE: "update",
        POLL: "poll",
        LOAD: "load",
    },
    SUGGESTION_VIEW_TYPE: {
        LIST: "list",
        GRID: "grid",
    },
    ATTENDEE_AVAILABILITY: {
        SUCCESS: 75,
        WARNING: 50,
    },
    DURATION: {
        THIRTY_MINUTES: 30,
        SIXTY_MINUTES: 60,
        MAX_MINUTES: 60 * 24 * 60, // 60 days
        MAX_DAYS: 60,
    },
    SEARCH_LIMIT: {
        DAYS: 62,
        MAILBOXES: 500,
    },
    FIND_GROUP: {
        START: 0,
        SIZE: 20,
    },
    MEETING_CARD: {
        DEFAULT_ATTENDEE_COUNT: 5,
        ATTENDEE_NAME_WIDTH: 215,
        ATTENDEE_TIMEZONE_WIDTH: 45,
    },
    INPUT_WIDTH: {
        TABLE_WIDTH: 900,
        STATUS_WIDTH: 180,
        CHIME_WIDTH: 250,
    },
    INPUT_HEIGHT: {
        TEXT_EDITOR_HEIGHT: 600,
    },
    DAYS_OF_WEEK: [
        "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",
        "sun", "mon", "tue", "wed", "thu", "fri", "sat",
        "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",
        "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday",
    ],
    COPIED_TO_NEW_ONE_TIME_PIN_GENERATED_TOAST_MESSAGE: "The copied meeting had a One Time Pin. We have generated a new One Time Pin for this meeting and attempted to update the location and meeting body accordingly. Please verify these fields were updated correctly.",
};
