export const CALENDAR_MODE = {
    DEFAULT: "default",
    AVAILABILITY_SHARING: "availabilitySharing",
    CREATE_POLL: "createPoll",
};

export const CALENDAR_TILE_WIDTH = {
    "day": {
        "1200": 836,
        "900": 632,
        "600": 340,
        "360": 260
    },
    "workweek": {
        "1200": 140,
        "900": 100,
        "600": 340,
        "360": 260
    }
};

export const SINGLE_DAY_VIEW_THRESHOLD = 600;
export const FULL_WEEK_VIEW_THRESHOLD = 900;
export const FULL_CALENDAR_VIEW = 1200;
