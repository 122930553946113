import React, { useCallback, useEffect, useState } from "react";

import differenceInDays from "date-fns/differenceInDays";
import differenceInMinutes from "date-fns/differenceInMinutes";

import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Icon from "@amzn/meridian/icon";
import Loader from "@amzn/meridian/loader";
import Link from "@amzn/meridian/link";
import Modal, { ModalFooter } from "../../shared/meridian-custom-components/src/components/modal";
import RadioButton from "@amzn/meridian/radio-button";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "../../shared/meridian-custom-components/src/components/select";
import Text from "@amzn/meridian/text";

import AlertsContainer from "../../shared/containers/alerts-container.js";
import LabeledDurationSelect from "../../shared/components/labeled-duration-select.js";
import LabeledRepeatSelect from "../../shared/components/labeled-repeat-select.js";
import LabeledDateRangePicker from "../../shared/components/labeled-date-range-picker.js";
import LabeledTimeSelect from "../../shared/components/labeled-time-select.js";
import ExistingMeetingOption from "./existing-meeting-option.js";
import MeetingSuggestions from "./meeting-suggestions.js";
import TimezonePicker from "../../shared/components/timezone-search-bar.js";
import CalendarTimeGrid from "../../shared/custom-calendar-time-grid/containers/calendar-time-grid";
import StatusLegend from "../../shared/components/status-legend.js";
import SuggestionsTips from "../../shared/components/suggestions-tips";
import SuggestionViewToggle from "../../shared/components/suggestion-view-toggle";
import chevronLeftSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";

import PatternBackground from "../../../assets/backgrounds/pattern_small_vertical_repeating.svg";

import { BACKGROUND_OPACITY, FLOW_TYPE, SEARCH_LIMIT, SUGGESTION_VIEW_TYPE, STEP } from "../meeting-scheduler-constants";
import { ALERTS, SCREEN_SIZE, TIME_CONSTANT, TIME_FORMAT } from "../../shared/shared-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { LOCATION_FEATURE } from "../../shared/locations/locations-constants";
import { ERRORS } from "../../shared/timezones/timezones-constants";
import { shortRoomName, renderLocationFeatureIcon } from "../../shared/locations/locations-utils";
import { sortAttendees } from "../../shared/meeting-utils";
import { updateQueryParameters } from "../../shared/permalink/permalink-utils";
import { convertEpoch, getRecurrenceMessage } from "../meeting-scheduler-utils.js";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { ERRORS as TIMEZONE_ERRORS } from "../../shared/timezones/timezones-constants";
import { formattedTimeToTimeUnit, roundTimeToNearestQuarterHour } from "../../shared/time-utils";
import { TIMEOUT, TOAST_COMPONENT } from "../../shared/toasts/toast-constants";
import { getInformationalToastWithComponent } from "../../shared/toasts/toast-utils";
import { TIME_FORMAT as SETTINGS_TIME_FORMAT } from "../../shared/settings/settings-constants";

const StepSmartSuggestions = (props) => {
    const flowType = props.flowType;
    const currentStep = props.currentStep;
    const onChangeStep = props.onChangeStep;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const mastheadSize = props.mastheadSize;
    const identity = props.identity;
    const meeting = props.meeting;
    const meetingDetails = props.meetingDetails;
    const masterState = props.masterState;
    const loadExistingMeeting = props.loadExistingMeeting;
    const attendees = sortAttendees(props.attendees, identity.email);
    const setGridAttendeeAvailability = props.setGridAttendeeAvailability;
    const findRooms = props.findRooms;
    const rooms = props.rooms;
    const locations = props.locations;
    const primaryTimezone = props.primaryTimezone;
    const meetingSuggestions = props.meetingSuggestions;
    const isSearchingMeetingSuggestions = props.isSearchingMeetingSuggestions;
    const timezones = props.timezones;
    const timezone = meeting.startTimezone.id || primaryTimezone;
    const onGetMeetingSuggestions = props.onGetMeetingSuggestions;
    const onSearchSuggestion = props.onSearchSuggestion;
    const onSelectSuggestion = props.onSelectSuggestion;
    const suggestionViewType = props.suggestionViewType;
    const onSetSuggestionViewType = props.onSetSuggestionViewType;
    const onUpdateRecurrence = props.onUpdateRecurrence;
    const onUpdateCurrentMeeting = props.onUpdateCurrentMeeting;
    const dateFormat = props.dateFormat;
    const timeFormat = props.timeFormat;
    const [queryParameters, setQueryParameters] = [props.queryParameters, props.setQueryParameters];

    // General states
    const [isFirstSearch, setIsFirstSearch] = [props.isFirstSearch, props.setIsFirstSearch];
    const [isInitialLoad, setIsInitialLoad] = [props.isInitialLoad, props.setIsInitialLoad];
    const [isAttendeesChanged, setIsAttendeesChanged] = [props.isAttendeesChanged, props.setIsAttendeesChanged];
    const [isLocationsChanged, setIsLocationsChanged] = [props.isLocationsChanged, props.setIsLocationsChanged];
    const [isRepeatChanged, setIsRepeatChanged] = [props.isRepeatChanged, props.setIsRepeatChanged];
    const [prevAttendees, setPrevAttendees] = [props.prevAttendees, props.setPrevAttendees];
    const [prevRooms, setPrevRooms] = [props.prevRooms, props.setPrevRooms];
    const [prevLocations, setPrevLocations] = [props.prevLocations, props.setPrevLocations];
    const [meetingSuggestionSelected, setMeetingSuggestionSelected] = [props.meetingSuggestionSelected, props.setMeetingSuggestionSelected];
    const [meetingSuggestionsDetail, setMeetingSuggestionsDetail] = [props.meetingSuggestionsDetail, props.setMeetingSuggestionsDetail];
    const [sortOption, setSortOption] = [props.sortOption, props.setSortOption];
    const [alerts, setAlerts] = [props.alerts, props.setAlerts];
    const [isExistingMeetingSelected, setIsExistingMeetingSelected] = [props.isExistingMeetingSelected, props.setIsExistingMeetingSelected];
    const [existingMeetingDetail, setExistingMeetingDetail] = [props.existingMeetingDetail, props.setExistingMeetingDetail];
    const [changedTimeWindow, setChangedTimeWindow] = [props.changedTimeWindow, props.setChangedTimeWindow];

    // Auto refresh
    const [refreshSuggestions, setRefreshSuggestions] = useState(false);

    // Meeting duration
    const [durationSelected, setDurationSelected] = [props.durationSelected, props.setDurationSelected];
    const [customDurationValue, setCustomDurationValue] = [props.customDurationValue, props.setCustomDurationValue];
    const [customDurationSelected, setCustomDurationSelected] = [props.customDurationSelected, props.setCustomDurationSelected];
    const [customDurationLabel, setCustomDurationLabel] = [props.customDurationLabel, props.setCustomDurationLabel];
    const [customDurationError, setCustomDurationError] = [props.customDurationError, props.setCustomDurationError];
    const [isAllDayEvent, setIsAllDayEvent] = [props.isAllDayEvent, props.setIsAllDayEvent];
    const [prevDuration, setPrevDuration] = [props.prevDuration, props.setPrevDuration];

    // Meeting recurrence
    const [repeatDays, setRepeatDays] = [props.repeatDays, props.setRepeatDays];
    const [repeatSelected, setRepeatSelected] = [props.repeatSelected, props.setRepeatSelected];
    const [customRepeatLabel, setCustomRepeatLabel] = [props.customRepeatLabel, props.setCustomRepeatLabel];
    const [customRepeatError, setCustomRepeatError] = [props.customRepeatError, props.setCustomRepeatError];

    // Meeting recurrence toast
    const closeRecurrenceToast = props.closeRecurrenceToast;
    const onShowToast = props.onShowToast;
    const [repeatToastId, setRepeatToastId] = [props.repeatToastId, props.setRepeatToastId];
    const [checkRecurrenceToast, setCheckRecurrenceToast] = [props.checkRecurrenceToast, props.setCheckRecurrenceToast];

    // Meeting recurrence modal
    const [customRepeatInterval, setCustomRepeatInterval] = [props.customRepeatInterval, props.setCustomRepeatInterval];
    const [customRepeatPeriod, setCustomRepeatPeriod] = [props.customRepeatPeriod, props.setCustomRepeatPeriod];
    const [customRepeatDays, setCustomRepeatDays] = [props.customRepeatDays, props.setCustomRepeatDays];
    const [customRepeatMonthSelected, setCustomRepeatMonthSelected] = [props.customRepeatMonthSelected, props.setCustomRepeatMonthSelected];
    const [customRepeatMonthDay, setCustomRepeatMonthDay] = [props.customRepeatMonthDay, props.setCustomRepeatMonthDay];
    const [customRepeatMonthDayOfWeek, setCustomRepeatMonthDayOfWeek] = [props.customRepeatMonthDayOfWeek, props.setCustomRepeatMonthDayOfWeek];
    const [customRepeatEndSelected, setCustomRepeatEndSelected] = [props.customRepeatEndSelected, props.setCustomRepeatEndSelected];
    const [customRepeatEndDate, setCustomRepeatEndDate] = [props.customRepeatEndDate, props.setCustomRepeatEndDate];
    const [customRepeatOccurrences, setCustomRepeatOccurrences] = [props.customRepeatOccurrences, props.setCustomRepeatOccurrences];
    const [prevCustomRepeatDays, setPrevCustomRepeatDays] = [props.prevCustomRepeatDays, props.setPrevCustomRepeatDays];

    // Presets for the date range picker
    const presets = props.presets;
    const currentYear = props.currentYear;

    // Meeting date, time, and timezone
    const [dateRangeValue, setDateRangeValue] = [props.dateRangeValue, props.setDateRangeValue];
    const [dateRangeLabel, setDateRangeLabel] = [props.dateRangeLabel, props.setDateRangeLabel];
    const [startTime, setStartTime] = [props.startTime, props.setStartTime];
    const [endTime, setEndTime] = [props.endTime, props.setEndTime];
    const [timeLabel, setTimeLabel] = [props.timeLabel, props.setTimeLabel];
    const [timezoneValue, setTimezoneValue] = [props.timezoneValue, props.setTimezoneValue];

    // Meeting days of week
    const [daysOfWeek, setDaysOfWeek] = [props.daysOfWeek, props.setDaysOfWeek];
    const [daysOfWeekLabel, setDaysOfWeekLabel] = [props.daysOfWeekLabel, props.setDaysOfWeekLabel];

    // Current room selection info
    const [currentSuggestion, setCurrentSuggestion] = [props.currentSuggestion, props.setCurrentSuggestion];
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = [props.currentSuggestionIndex, props.setCurrentSuggestionIndex];
    const [currentRoomListIndex, setCurrentRoomListIndex] = [props.currentRoomListIndex, props.setCurrentRoomListIndex];
    const [selectedRooms, setSelectedRooms] = [props.selectedRooms, props.setSelectedRooms];
    const [selectedRoom, setSelectedRoom] = [props.selectedRoom, props.setSelectedRoom];

    // Room swap modal
    const [roomSwapOpen, setRoomSwapOpen] = useState(false);
    const onRoomSwapOpen = useCallback(() => setRoomSwapOpen(true), []);
    const onRoomSwapClose = useCallback(() => setRoomSwapOpen(false), []);

    // Suggestions
    const [noEssentialSuggestionCalled, setNoEssentialSuggestionCalled] = useState(false);
    // Updating timezone picker updates timezone value, but it should not affect the timezone of current suggestions
    const [suggestionsTimezoneId, setSuggestionsTimezoneId] = useState(timezoneValue);

    const [spansMultipleDates, setSpansMultipleDates] = useState(false);
    const [durationExceedsRoomLimit, setDurationExceedsRoomLimit] = useState(false);

    const SORT_BY_LABEL_ID = "sort-by-label-id";

    // Open the room swap modal with information for the selected suggestion and corresponding room list
    const openRoomSwapModal = (startTime, suggestionIndex, roomListIndex) => {
        setCurrentSuggestionIndex(suggestionIndex);
        setCurrentRoomListIndex(roomListIndex);
        setCurrentSuggestion(startTime + "-" + roomListIndex);
        setSelectedRoom(selectedRooms[startTime + "-" + roomListIndex] || 0);
        onRoomSwapOpen();
    };

    // Close the room swap modal and save the selected room
    const closeRoomSwapModal = () => {
        let rooms = selectedRooms;
        rooms[currentSuggestion] = selectedRoom;

        onChangeSelectSuggestion(meetingSuggestions[currentSuggestionIndex], rooms);
        setSelectedRooms(rooms);
        onRoomSwapClose();
    };

    // Copy all recurrence info into an object
    const copyPrevRepeatInfo = (prevRepeatSelected = repeatSelected) => {
        const prevRepeatInfo = {
            repeatSelected: prevRepeatSelected,
            customRepeatInterval: customRepeatInterval,
            customRepeatPeriod: customRepeatPeriod,
            customRepeatDays: customRepeatDays,
            customRepeatMonthSelected: customRepeatMonthSelected,
            customRepeatMonthDay: customRepeatMonthDay,
            customRepeatMonthDayOfWeek: customRepeatMonthDayOfWeek,
            customRepeatEndSelected: customRepeatEndSelected,
            customRepeatEndDate: customRepeatEndDate,
            customRepeatOccurrences: customRepeatOccurrences,
            customRepeatLabel: customRepeatLabel,
        };

        return prevRepeatInfo;
    };

    // Custom meeting recurrence modal
    const [repeatOpen, setRepeatOpen] = [props.repeatOpen, props.setRepeatOpen];
    const onRepeatOpen = useCallback(() => setRepeatOpen(true), [setRepeatOpen]);
    const onRepeatClose = useCallback(() => setRepeatOpen(false), [setRepeatOpen]);

    const [prevRepeatInfo, setPrevRepeatInfo] = useState(copyPrevRepeatInfo());

    // Open the recurrence modal and save the previous recurrence info
    const openRepeatModal = (prevRepeatSelected = repeatSelected) => {
        closeRecurrenceToast();
        setPrevRepeatInfo(copyPrevRepeatInfo(prevRepeatSelected));
        onRepeatOpen();
    };

    // Update the meeting recurrence if "Any" is selected
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getUpdatedRecurrence = (startTime) => {
        let recurrence = meeting.recurrence;

        if (recurrence && prevCustomRepeatDays.includes("Any")) {
            // Change recurrence repeat day to match the selected suggestion
            const dayOfWeek = new Date(startTime * 1000).getDay();
            recurrence.daysOfWeek = {
                sunday: dayOfWeek === 0,
                monday: dayOfWeek === 1,
                tuesday: dayOfWeek === 2,
                wednesday: dayOfWeek === 3,
                thursday: dayOfWeek === 4,
                friday: dayOfWeek === 5,
                saturday: dayOfWeek === 6,
            };
            onUpdateRecurrence(recurrence);
        }

        return recurrence;
    };

    // Open a toast with the end date and number of occurrences for the recurring series
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const displayRecurrenceToast = (recurrence = meeting.recurrence, startTime) => {
        let recurrenceMessage = getRecurrenceMessage(recurrence, startTime || (meeting.time && meeting.time.startTime));

        if (recurrenceMessage) {
            closeRecurrenceToast();

            const now = new Date().getTime();
            setRepeatToastId(now);

            const toast = getInformationalToastWithComponent();
            toast.id = now;
            toast.toastTimeout = TIMEOUT.NEVER;
            toast.toastActionProps.text = recurrenceMessage;
            toast.toastActionProps.autoFocus = false;
            toast.toastActionProps.functionText = `Update meeting frequency`;
            toast.toastActionProps.function = openRepeatModal;
            toast.toastActionProps.close = true;
            toast.toastActionProps.closeFunction = () => setRepeatToastId(); // clear toast id when closed
            toast.toastActionProps.allowedWorkflows = flowType === FLOW_TYPE.CREATE ? [WORKFLOWS.STANDARD_MEETING.NAME] : [WORKFLOWS.EDIT_MEETING.NAME];
            toast.toastActionProps.allowedSteps = [STEP.SMART_SUGGESTIONS];
            toast.toastActionProps.componentName = TOAST_COMPONENT.FUNCTION;

            onShowToast(toast);
        }
    };

    // Update recurrence info and display recurrence toast
    const onChangeRecurrenceTime = (startTime) => {
        const recurrence = getUpdatedRecurrence(startTime);
        displayRecurrenceToast(recurrence, startTime);
    };

    // Change the selected suggestion and update the state with the selected rooms
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeSelectSuggestion = (suggestion, suggestionRoomList = selectedRooms) => {
        let resources = [];

        // Parse specific room selections
        prevRooms.forEach((room) => {
            let resource = {};

            resource.email = room.email;
            resource.name = room.name;

            resources.push(resource);
        });

        // Parse selected rooms from auto-suggest
        for (let [currentSuggestion, selectedRoom] of Object.entries(suggestionRoomList)) {
            let roomListIndex = currentSuggestion.split("-")[1];
            if (currentSuggestion.includes(suggestion.startTime) && suggestion.suggestionRoomList[roomListIndex][selectedRoom]) {
                let resource = {};
                let room = suggestion.suggestionRoomList[roomListIndex][selectedRoom].room;

                resource.email = room.email;
                resource.name = room.name;

                resources.push(resource);
            }
        }

        setMeetingSuggestionSelected(suggestion.peopleRankIndex);
        setIsExistingMeetingSelected(false);
        onSelectSuggestion(suggestion, resources);

        onChangeRecurrenceTime(suggestion.startTime);
    };

    // Update the state when selecting the existing meeting
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSelectExistingMeeting = () => {
        setIsExistingMeetingSelected(true);

        setDurationSelected("custom");
        const startTime = new Date(meetingDetails.time.startTime * 1000);
        const endTime = new Date(meetingDetails.time.endTime * 1000);
        const currentMeetingDuration = differenceInMinutes(endTime, startTime);
        setCustomDurationValue(currentMeetingDuration);
        setCustomDurationSelected("min");
        setCustomDurationLabel(`${currentMeetingDuration} mins`);

        let resources = [];

        // Parse specific room selections
        prevRooms.forEach((room) => {
            resources.push({
                email: room.email,
                name: room.name
            });
        });

        let suggestion = {
            "startTime": meetingDetails.time.startTime,
            "endTime": meetingDetails.time.endTime
        };

        onSelectSuggestion(suggestion, resources);

        onChangeRecurrenceTime(meetingDetails.time.startTime);
    };

    // Toggle the meeting suggestion ordering between earliest time and availability (peopleRank from RAS API)
    const toggleSortOption = () => {
        if (sortOption === "Availability") {
            setSortOption("Earliest time");
            meetingSuggestions.sort((suggestion1, suggestion2) => {
                return suggestion1.startTime - suggestion2.startTime;
            });
        } else {
            setSortOption("Availability");
            meetingSuggestions.sort((suggestion1, suggestion2) => {
                return suggestion2.peopleRank - suggestion1.peopleRank;
            });
        }
    };

    // Validate all input fields and return alerts if there are any
    const getAlerts = (removeEssentialPriority) => {
        let alerts = [];

        const durationInMin = getDurationInMinutes();

        // Warning alerts
        if (timeLabel !== "invalid") {
            const startTimeMin = formattedTimeToTimeUnit(startTime, TIME_FORMAT.HH_MM_SS, TIME_CONSTANT.MIN_NAME);
            const endTimeMin = formattedTimeToTimeUnit(endTime === "00:00:00" ? "24:00:00" : endTime, TIME_FORMAT.HH_MM_SS, TIME_CONSTANT.MIN_NAME);

            let differenceInMin = endTimeMin - startTimeMin;
            if (startTimeMin > endTimeMin) {
                // treat the end time selected as the end time of the next day
                differenceInMin += TIME_CONSTANT.ONE_DAY_IN_MIN;
            }

            if (durationInMin < TIME_CONSTANT.ONE_DAY_IN_MIN && durationInMin > differenceInMin) {
                alerts.push(ALERTS.MEETING_DURATION_LONGER_THAN_TIME_WINDOW);
            }
        }
        if (dateRangeLabel !== "invalid") {
            let dateDifference = differenceInDays(
                new Date(dateRangeValue[1] + "T" + startTime),
                new Date(dateRangeValue[0] + "T" + startTime)
            );

            if (dateDifference >= SEARCH_LIMIT.DAYS) {
                if (repeatSelected === "none") {
                    alerts.push(ALERTS.MAX_DAYS_EXCEEDED);
                } else {
                    alerts.push(ALERTS.MEETING_SEARCH_LIMIT);
                }
            }

            if (parseInt(durationInMin / TIME_CONSTANT.ONE_DAY_IN_MIN) > dateDifference + 1) {
                alerts.push(ALERTS.MEETING_DURATION_LONGER_THAN_DATE_RANGE);
            }
        }
        if (attendees.length > SEARCH_LIMIT.MAILBOXES) {
            alerts.push(ALERTS.MAILBOX_SEARCH_LIMIT_EXCEEDED);
        }

        // Error alerts
        if ((durationSelected === "all day" || durationSelected === "custom") && customDurationError) {
            alerts.push(ALERTS.INVALID_MEETING_DURATION);
        }
        if (!dateRangeValue[0]) { // start date
            alerts.push(ALERTS.INVALID_START_DATE);
        }
        if (!dateRangeValue[1]) { // end date
            alerts.push(ALERTS.INVALID_END_DATE);
        }
        if (!daysOfWeek.length) {
            alerts.push(ALERTS.NO_DAYS_OF_WEEK_SELECTED);
        }
        if (timeLabel === "invalid") {
            alerts.push(ALERTS.INVALID_TIME_WINDOW);
        }
        if (repeatSelected !== "none" && customRepeatError) {
            if (customRepeatPeriod === "week" && !customRepeatDays.length) {
                alerts.push(ALERTS.NO_RECUR_DAYS_OF_WEEK_SELECTED);
            }
            if (!customRepeatInterval) {
                alerts.push(ALERTS.NO_RECURRENCE_INTERVAL_INPUTTED);
            }
            if (customRepeatEndSelected === "after" && !customRepeatOccurrences) {
                alerts.push(ALERTS.NO_RECURRENCE_MEETING_COUNT_INPUTTED);
            }
            if (customRepeatEndSelected === "by" && Date.parse(customRepeatEndDate) < Date.parse(dateRangeValue[0])) {
                alerts.push(ALERTS.RECURRENCE_DATE_BEFORE_START_DATE);
            }
        }
        if (timezoneValue === ERRORS.INVALID_TIMEZONE) {
            alerts.push(ALERTS.INVALID_TIMEZONE);
        }

        if (!alerts.find((alert) => alert.type === "error" || alert.type === "warning") && removeEssentialPriority) {
            alerts.push(ALERTS.NO_SUGGESTIONS_FOUND);
        }

        // Show a warning alert if the meeting includes a room and the duration is longer than a day
        setDurationExceedsRoomLimit((rooms.length !== 0 || locations.length !== 0) && durationInMin > TIME_CONSTANT.ONE_DAY_IN_MIN); // using custom flag so alert can be styled

        return alerts;
    };

    // Get the inputted duration for the meeting in minutes
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getDurationInMinutes = () => {
        if (durationSelected === "all day" || durationSelected === "custom") {
            if (customDurationSelected === "min") {
                return parseInt(customDurationValue) || 0;
            } else if (customDurationSelected === "hour") {
                return parseInt(customDurationValue * 60) || 0;
            } else if (customDurationSelected === "day") {
                return parseInt(customDurationValue * 60 * 24) || 0;
            }
        } else {
            return durationSelected;
        }
    };

    // API Function: Get the recurrence attributes based on the inputted values in the repeat menu
    const getRecurrence = () => {
        let __type = "com.amazon.resourceadapter#WeeklyRecurrence";
        let type = "weekly";
        let repeatInterval = 1;
        let dayOfWeek = new Date(dateRangeValue[0] + "T" + startTime).toLocaleString("en-US", {timeZone: timezoneValue, weekday: "long"}).toLowerCase();
        let weekOfMonth = "first";
        let monthlyType = "absolute";

        if (repeatSelected === "none") {
            return null;
        } else {
            if (customRepeatPeriod === "day") {
                __type = "com.amazon.resourceadapter#DailyRecurrence";
                type = "daily";
            } else if (customRepeatPeriod === "month") {
                if (customRepeatMonthSelected === "day") {
                    __type = "com.amazon.resourceadapter#AbsoluteMonthlyRecurrence";
                } else {
                    __type = "com.amazon.resourceadapter#RelativeMonthlyRecurrence";
                    weekOfMonth = customRepeatMonthSelected;
                    monthlyType = "relative";
                }

                type = "monthly";

                let dayOptions = {
                    "Sun": "sunday",
                    "Mon": "monday",
                    "Tue": "tuesday",
                    "Wed": "wednesday",
                    "Thu": "thursday",
                    "Fri": "friday",
                    "Sat": "saturday",
                    "day": "day",
                    "weekday" : "weekday",
                    "weekend": "weekendDay"
                };

                dayOfWeek = dayOptions[customRepeatMonthDayOfWeek];
            }

            repeatInterval = parseInt(customRepeatInterval);
        }

        let recurrence = {
            "__type": __type,
            "interval": repeatInterval,
            "neverEnding": false,
            "daysOfWeek": {
                "sunday": repeatDays.includes("Sun"),
                "monday": repeatDays.includes("Mon"),
                "tuesday": repeatDays.includes("Tue"),
                "wednesday": repeatDays.includes("Wed"),
                "thursday": repeatDays.includes("Thu"),
                "friday": repeatDays.includes("Fri"),
                "saturday": repeatDays.includes("Sat"),
            },
            "firstDayOfWeek": "sunday",
            "dayOfMonth": customRepeatMonthDay,
            "dayOfWeek": dayOfWeek,
            "type": type,
            "monthlyType": monthlyType,
            "weekOfMonth": weekOfMonth,
            "isUndefined": {
                "weekly": true,
                "relativeMonthly": true,
                "absoluteMonthly": true
            }
        };

        if (repeatSelected !== "none" && customRepeatEndSelected === "by") {
            recurrence["endByDate"] = new Date(customRepeatEndDate + "T" + startTime).getTime() / 1000; // epoch for the end date of the recurrence
        } else if (repeatSelected !== "none" && customRepeatEndSelected === "after") {
            recurrence["endAfterNOccurrences"] = parseInt(customRepeatOccurrences);
        } else if (repeatSelected !== "none" && customRepeatEndSelected === "none") {
            recurrence["neverEnding"] = true;
        } else {
            recurrence["endByDate"] = new Date(dateRangeValue[1] + "T" + startTime).getTime() / 1000; // epoch for the "End by" date
        }

        return recurrence;
    };

    // API Function: Get a list of participants for a meeting
    const getWishlistParticipantList = (removeEssentialPriority) => {
        let wishlistParticipantList = [];

        attendees.forEach((attendee) => {
            let currentAttendee = {
                exchangeMailbox: attendee.email
            };

            if (attendee.priority !== "Organizer" && attendee.priority !== "Required") {
                if (attendee.priority !== "Essential" || !removeEssentialPriority) {
                    currentAttendee["priorityLabel"] = attendee.priority.toLowerCase();
                }
            }

            wishlistParticipantList.push(currentAttendee);
        });

        rooms.forEach((room) => {
            wishlistParticipantList.push({
                exchangeMailbox: room.email
            });
        });

        return wishlistParticipantList;
    };

    // API Function: Call RAS API to get list of meeting suggestions from a suggestion request
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSuggestions = (removeEssentialPriority = false) => {
        closeRecurrenceToast();

        setRefreshSuggestions(false);
        const alerts = getAlerts(removeEssentialPriority);
        let errors = alerts.filter((alert) => alert.type === "error");
        if (errors.length) {
            setAlerts(errors);
        } else {
            setAlerts(alerts);

            // Getting simple filters time of day
            let startTimeDate = new Date(dateRangeValue[0] + "T" + startTime);
            let endTimeDate = new Date(dateRangeValue[1] + "T" + endTime);

            let startHour = startTimeDate.getHours();
            let startMinute = startTimeDate.getMinutes();
            let endHour = endTimeDate.getHours();
            let endMinute = endTimeDate.getMinutes();

            let duration = getDurationInMinutes();
            if (durationSelected === "all day" || (durationSelected === "custom" && duration >= 60 * 24)) {
                startHour = 0;
                startMinute = 0;
                endHour = 0;
                endMinute = 0;
            }

            let startTimeString = "00:00:00";
            let endTimeString = "23:59:59";

            let startDate = new Date(dateRangeValue[0] + "T" + startTimeString);
            let endDate = new Date(dateRangeValue[1] + "T" + endTimeString);

            // If the start date is today, then the start time is set to the nearest 15 min to avoid suggestions in the past
            let currentDate = new Date();
            if (currentDate.toLocaleDateString() === startTimeDate.toLocaleDateString()) {
                startDate = roundTimeToNearestQuarterHour(currentDate);
            }

            let startEpoch = startDate.getTime() / 1000;
            let endEpoch = endDate.getTime() / 1000;

            // If the suggestion search range is longer than two months, set the end date to two months after the start
            let dateDifference = (endEpoch - startEpoch) / 60 / 60 / 24;
            if (dateDifference >= SEARCH_LIMIT.DAYS) {
                endDate = new Date(dateRangeValue[0] + "T" + endTimeString);
                endDate.setDate(endDate.getDate() + 61);
                endEpoch = endDate.getTime() / 1000;
            }

            let suggestionRequest = {
                "duration": duration,
                "simpleFilters": {
                    "dayOfWeek": {
                        "monday": daysOfWeek.includes("Mon"),
                        "tuesday": daysOfWeek.includes("Tue"),
                        "wednesday": daysOfWeek.includes("Wed"),
                        "thursday": daysOfWeek.includes("Thu"),
                        "friday": daysOfWeek.includes("Fri"),
                        "saturday": daysOfWeek.includes("Sat"),
                        "sunday": daysOfWeek.includes("Sun"),
                    },
                    "timeOfDay": [
                        {
                            "startHour": startHour,
                            "startMinute": startMinute,
                            "endHour": endHour,
                            "endMinute": endMinute
                        }
                    ],
                    "timeZone": timezoneValue
                },
                "requestedOnBehalfOf": meeting.organizer,
                "searchDateRange": {
                    "startTime": startEpoch,
                    "endTime": endEpoch,
                },
                "wishlistRoomList": [{
                    "wishlistParticipantList": getWishlistParticipantList(removeEssentialPriority)
                }],
                "groupedBy": "timeslot",
                "timezone": {
                    "id": timezoneValue
                },
            };

            let recurrence = getRecurrence();
            if (recurrence) {
                suggestionRequest["recurrence"] = recurrence;
            }

            let wishlistRoomAttributes = locations;
            if (findRooms && wishlistRoomAttributes.length) {
                wishlistRoomAttributes.forEach((roomAttributes) => {
                    const wishlistRoomAttributes = {
                        ...roomAttributes,
                        "resourceList": roomAttributes.resourceList && roomAttributes.resourceList.length !== 0 ? roomAttributes.resourceList : undefined,
                    };
                    if (!suggestionRequest["wishlistRoomList"][0].wishlistRoomAttributes) {
                        suggestionRequest["wishlistRoomList"][0]["wishlistRoomAttributes"] = wishlistRoomAttributes;
                    } else {
                        suggestionRequest["wishlistRoomList"].push({
                            wishlistParticipantList: [],
                            wishlistRoomAttributes: wishlistRoomAttributes,
                        });
                    }
                });
            }

            if (durationSelected === 25 || durationSelected === 55) {
                // move meeting suggestions forward 5 minutes
                suggestionRequest["addOffsetMinutes"] = 5;
            }

            setIsInitialLoad(true);
            setIsLocationsChanged(!isFirstSearch && (prevRooms !== rooms || prevLocations !== locations));
            setIsAttendeesChanged(!isFirstSearch && JSON.stringify(prevAttendees) !== JSON.stringify(attendees));
            setIsRepeatChanged(false);
            setPrevAttendees(attendees);
            setPrevRooms(rooms);
            setPrevLocations(locations);
            setPrevDuration(getDurationInMinutes());
            setPrevCustomRepeatDays(customRepeatDays);
            setMeetingSuggestionsDetail({});
            setSortOption("Availability");
            setCurrentSuggestion();
            setCurrentSuggestionIndex(0);
            setCurrentRoomListIndex(0);
            setSelectedRooms({});
            setSelectedRoom(0);
            setSuggestionsTimezoneId(timezoneValue);
            setChangedTimeWindow(undefined); // resetting the position of the grid window

            if (flowType === FLOW_TYPE.CREATE) {
                setMeetingSuggestionSelected(0);
            } else {
                setMeetingSuggestionSelected();
                setIsExistingMeetingSelected(true);

                if (meetingDetails.time) {
                    let resources = [];

                    // Parse specific room selections
                    rooms.forEach((room) => {
                        resources.push({
                            email: room.email,
                            name: room.name,
                        });
                    });

                    let suggestion = {
                        "startTime": meetingDetails.time.startTime,
                        "endTime": meetingDetails.time.endTime
                    };

                    onSelectSuggestion(suggestion, resources);
                }
            }

            onSearchSuggestion(suggestionRequest, isAllDayEvent);
            onGetMeetingSuggestions(suggestionRequest);

            if (flowType === FLOW_TYPE.UPDATE) {
                setCheckRecurrenceToast(true);

                if (suggestionViewType === SUGGESTION_VIEW_TYPE.GRID) {
                    setIsExistingMeetingSelected(false);
                    setMeetingSuggestionSelected(0);
                }
            }

            setIsFirstSearch(false);
        }

        setNoEssentialSuggestionCalled(removeEssentialPriority);
    };

    const getSelectedSuggestionNumber = () => {
        if (!meetingSuggestions || !meetingSuggestions.length) {
            return 0;
        }

        return (meetingSuggestionSelected || 0) + 1;
    };

    const onSelectNewSuggestion = (suggestionIndexDelta) => {
        setMeetingSuggestionSelected((meetingSuggestionSelected + suggestionIndexDelta) || 0);
        onChangeSelectSuggestion(meetingSuggestions[(meetingSuggestionSelected + suggestionIndexDelta) || 0]);
        setChangedTimeWindow(undefined);
    };

    const onChangeTimezoneValue = (timezone) => {
        setTimezoneValue(timezone);
        onUpdateCurrentMeeting({startTimezone: {id: timezone}, endTimezone: {id: timezone}});
        setRefreshSuggestions(true);
    };

    const [freeAttendeesCount, setFreeAttendeesCount] = [props.freeAttendeesCount, props.setFreeAttendeesCount];

    // If "Any" is selected while a meeting is being edited, update the recurrence to use the existing meeting option upon getting suggestions
    useEffect(() => {
        if (flowType === FLOW_TYPE.UPDATE && checkRecurrenceToast) {
            let recurrence = meeting.recurrence;
            const startTime = meetingDetails.time && meetingDetails.time.startTime;

            if (checkRecurrenceToast && prevCustomRepeatDays.includes("Any")) {
                recurrence = getUpdatedRecurrence(startTime);
            }
            if (repeatToastId === undefined) {
                displayRecurrenceToast(recurrence, startTime);
            }

            setCheckRecurrenceToast(false);
        }
    }, [prevCustomRepeatDays, checkRecurrenceToast, setCheckRecurrenceToast, flowType, meetingDetails, getUpdatedRecurrence, repeatToastId, meeting.recurrence, displayRecurrenceToast]);

    // When the meeting time changes, check if it spans multiple dates
    useEffect(() => {
        const startDate = new Date(meeting.time.startTime * 1000);
        const endDate = new Date(meeting.time.endTime * 1000);

        const duration = getDurationInMinutes();

        if (duration <= TIME_CONSTANT.ONE_DAY_IN_MIN) {
            // false if the meeting ends at midnight
            const endsAtMidnight = endDate.toTimeString().split(" ")[0] === "00:00:00";
            setSpansMultipleDates(startDate.getDate() !== endDate.getDate() && !endsAtMidnight);
        } else {
            setSpansMultipleDates(true);
        }
    }, [meeting.time, setSpansMultipleDates, getDurationInMinutes]);

    // Get suggestions upon loading the step when editing a meeting or when the selected attendees or locations have changed
    useEffect(() => {
        if (identity && identity.username && currentStep === STEP.SMART_SUGGESTIONS && meeting.organizer && !loadExistingMeeting && startTime && endTime && timezoneValue && timezoneValue !== TIMEZONE_ERRORS.INVALID_TIMEZONE) {
            const currentMeetingAttendeeCount = ((meetingDetails && meetingDetails.requiredAttendees) || [])
                                                .concat((meetingDetails && meetingDetails.optionalAttendees) || [])
                                                .filter((attendee) => !(attendee.email.endsWith("@chime.aws")))
                                                .length;
            const currentMeetingResourceCount = ((meetingDetails && meetingDetails.resources) || []).length;

            if ((isFirstSearch && (flowType === FLOW_TYPE.CREATE || (flowType === FLOW_TYPE.UPDATE && attendees.length >= currentMeetingAttendeeCount && rooms.length >= currentMeetingResourceCount))) ||
                (!isFirstSearch && (JSON.stringify(prevAttendees) !== JSON.stringify(attendees) || prevRooms !== rooms || prevLocations !== locations || (isRepeatChanged && !customRepeatError)))) {
                getSuggestions();
            }
        }
    }, [identity, currentStep, meeting, loadExistingMeeting, isFirstSearch, flowType, getSuggestions, prevAttendees, attendees, prevRooms, rooms, prevLocations, locations, isRepeatChanged, customRepeatError, startTime, endTime, timezoneValue, meetingDetails]);

    useEffect(() => {
        if (!isFirstSearch && !isSearchingMeetingSuggestions && meetingSuggestions && meetingSuggestions.length === 0 && !noEssentialSuggestionCalled) {
            getSuggestions(true);
        }
    }, [isFirstSearch, isSearchingMeetingSuggestions, meetingSuggestions, noEssentialSuggestionCalled, getSuggestions]);

    // If there is no recurrence toast display it, check once upon loading this step
    useEffect(() => {
        if (repeatToastId === undefined) {
            displayRecurrenceToast();
        }
    // eslint-disable-next-line
    }, []);

    // useEffect for generating query parameters for smart Meeting Suggestions
    useEffect(() => {
        let filterWeekDays = "";
        let durationMinutes = "";
        if (attendees) {
            filterWeekDays = daysOfWeek.map((dayOfWeek) => {
                switch (dayOfWeek) {
                    case "Tue": {
                        return dayOfWeek + "sday";
                    }
                    case "Wed": {
                        return dayOfWeek + "nesday";
                    }
                    case "Thu": {
                        return dayOfWeek + "rsday";
                    }
                    case "Sat": {
                        return dayOfWeek + "urday";
                    }
                    default: {
                        return dayOfWeek + "day";
                    }
                }
            });
            filterWeekDays = filterWeekDays.join(",");
            if (durationSelected === "custom") {
                durationMinutes = customDurationValue;
            } else {
                durationMinutes = durationSelected;
            }
            const parameters = {
                durationMinutes: durationMinutes,
                startTime: startTime,
                endTime: endTime,
                suggestionViewType: suggestionViewType,
                filterWeekDays: filterWeekDays,
                timezone: encodeURIComponent(timezoneValue),
            };

            // Update query parameter if the value is different
            updateQueryParameters(parameters, queryParameters, setQueryParameters);
        }
    }, [attendees, locations, daysOfWeek, suggestionViewType, durationSelected, dateRangeValue, startTime, endTime, customDurationValue, timezoneValue, queryParameters, setQueryParameters]);

    // When suggestion inputs update, call getSuggestion
    useEffect(() => {
        if (refreshSuggestions) {
            getSuggestions();
        }
        // eslint-disable-next-line
    }, [refreshSuggestions]);

    return (
        <Column height="100%" spacing="none">
            <Column spacing={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "medium" : "small"} spacingInset="medium" backgroundColor="white" alignmentHorizontal="center">
                <Row width="100%" maxWidth={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "" : "360px"} wrap="down" spacing="small" alignmentHorizontal="center" alignmentVertical="stretch">
                    <Row spacing="small" wrap="down" alignmentHorizontal="center" alignmentVertical="stretch">
                        <LabeledDurationSelect
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            durationSelected={durationSelected}
                            setDurationSelected={setDurationSelected}
                            customDurationValue={customDurationValue}
                            setCustomDurationValue={setCustomDurationValue}
                            customDurationSelected={customDurationSelected}
                            setCustomDurationSelected={setCustomDurationSelected}
                            customDurationLabel={customDurationLabel}
                            setCustomDurationLabel={setCustomDurationLabel}
                            customDurationError={customDurationError}
                            setCustomDurationError={setCustomDurationError}
                            isAllDayEvent={isAllDayEvent}
                            setIsAllDayEvent={setIsAllDayEvent}
                            setRefreshSuggestions={setRefreshSuggestions}
                        />
                        <LabeledRepeatSelect
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            meeting={meeting}
                            disabled={flowType === FLOW_TYPE.UPDATE && meetingDetails.isRecurring && !masterState}
                            dateRangeValue={dateRangeValue}
                            daysOfWeek={daysOfWeek}
                            setDaysOfWeek={setDaysOfWeek}
                            setDaysOfWeekLabel={setDaysOfWeekLabel}
                            setRepeatDays={setRepeatDays}
                            repeatSelected={repeatSelected}
                            setRepeatSelected={setRepeatSelected}
                            customRepeatLabel={customRepeatLabel}
                            setCustomRepeatLabel={setCustomRepeatLabel}
                            customRepeatError={customRepeatError}
                            setCustomRepeatError={setCustomRepeatError}
                            customRepeatInterval={customRepeatInterval}
                            setCustomRepeatInterval={setCustomRepeatInterval}
                            customRepeatPeriod={customRepeatPeriod}
                            setCustomRepeatPeriod={setCustomRepeatPeriod}
                            customRepeatDays={customRepeatDays}
                            setCustomRepeatDays={setCustomRepeatDays}
                            customRepeatMonthSelected={customRepeatMonthSelected}
                            setCustomRepeatMonthSelected={setCustomRepeatMonthSelected}
                            customRepeatMonthDay={customRepeatMonthDay}
                            setCustomRepeatMonthDay={setCustomRepeatMonthDay}
                            customRepeatMonthDayOfWeek={customRepeatMonthDayOfWeek}
                            setCustomRepeatMonthDayOfWeek={setCustomRepeatMonthDayOfWeek}
                            customRepeatEndSelected={customRepeatEndSelected}
                            setCustomRepeatEndSelected={setCustomRepeatEndSelected}
                            customRepeatEndDate={customRepeatEndDate}
                            setCustomRepeatEndDate={setCustomRepeatEndDate}
                            customRepeatOccurrences={customRepeatOccurrences}
                            setCustomRepeatOccurrences={setCustomRepeatOccurrences}
                            displayRecurrenceToast={displayRecurrenceToast}
                            repeatToastId={repeatToastId}
                            repeatOpen={repeatOpen}
                            onRepeatClose={onRepeatClose}
                            openRepeatModal={openRepeatModal}
                            prevRepeatInfo={prevRepeatInfo}
                            isRepeatChanged={isRepeatChanged}
                            setIsRepeatChanged={setIsRepeatChanged}
                            locationAdded={rooms.length !== 0 || locations.length !== 0}
                            dateFormat={dateFormat}
                        />
                        <LabeledDateRangePicker
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            daysOfWeek={daysOfWeek}
                            setDaysOfWeek={setDaysOfWeek}
                            daysOfWeekLabel={daysOfWeekLabel}
                            setDaysOfWeekLabel={setDaysOfWeekLabel}
                            repeatDays={repeatDays}
                            setRepeatDays={setRepeatDays}
                            repeatSelected={repeatSelected}
                            setRepeatSelected={setRepeatSelected}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}
                            dateRangeLabel={dateRangeLabel}
                            setDateRangeLabel={setDateRangeLabel}
                            presets={presets}
                            startTime={startTime}
                            endTime={endTime}
                            customRepeatInterval={customRepeatInterval}
                            customRepeatEndSelected={customRepeatEndSelected}
                            customRepeatEndDate={customRepeatEndDate}
                            setCustomRepeatError={setCustomRepeatError}
                            dateFormat={dateFormat}
                            setRefreshSuggestions={setRefreshSuggestions}
                        />
                    </Row>
                    <Row spacing="small" wrap="down" alignmentHorizontal="center" alignmentVertical="stretch">
                        <LabeledTimeSelect
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            isAllDayEvent={isAllDayEvent}
                            startTime={startTime}
                            setStartTime={setStartTime}
                            endTime={endTime}
                            setEndTime={setEndTime}
                            timeLabel={timeLabel}
                            setTimeLabel={setTimeLabel}
                            timeFormat={timeFormat}
                            setRefreshSuggestions={setRefreshSuggestions}
                        />
                        <Column spacing="none" width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "150px" : "330px"}>
                            <Text type="b100">Timezone</Text>
                            {timezones.length ?
                                <TimezonePicker
                                    screenSizeBreakpoint={screenSizeBreakpoint}
                                    width="150px"
                                    mobileWidth="330px"
                                    size="small"
                                    timezones={timezones}
                                    value={timezoneValue}
                                    onChange={onChangeTimezoneValue}
                                />
                                :
                                <Loader size="small" />
                            }
                        </Column>
                        <Column spacing="none">
                            <Text type="b100">&nbsp;</Text>
                            <Button type="primary" size="small" onClick={getSuggestions} disabled={!startTime || !endTime || !timezoneValue} minWidth="185px">
                                <Row spacingInset="none small">
                                    {(!startTime || !endTime || !timezoneValue) &&
                                        <Loader size="small" />
                                    }
                                    <Text type="b300" color="inverted">
                                        {isFirstSearch ? "Get suggestions" : "Refresh suggestions"}
                                    </Text>
                                </Row>
                            </Button>
                        </Column>
                    </Row>
                </Row>
            </Column>
            <Divider size="small" />
            <Column spacing={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "medium" : "small"} spacingInset="small medium" backgroundColor="white" alignmentHorizontal="center">
                <Row width="100%" maxWidth="1275px" wrap="down" spacing={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "small" : "medium"} alignmentHorizontal="justify">
                    {suggestionViewType === SUGGESTION_VIEW_TYPE.GRID ?
                        isExistingMeetingSelected ?
                            <Row>
                                <Link type="secondary" onClick={() => {onSelectNewSuggestion(0)}} disabled={!meetingSuggestions.length}>
                                    Suggestions{isFirstSearch ? "" : ` (${meetingSuggestions.length})`}
                                </Link>
                                <Text type="h100">Existing meeting</Text>
                            </Row>
                            :
                            <Row>
                                <Button type="icon" size="small" onClick={() => {onSelectNewSuggestion(-1)}} label={"Previous suggestion"} disabled={!meetingSuggestions.length || meetingSuggestionSelected === 0 || meetingSuggestionSelected === undefined || isSearchingMeetingSuggestions}>
                                    <Icon tokens={chevronLeftSmallTokens} />
                                </Button>
                                <Text type="h100">{isFirstSearch ? "Suggestions" : `Suggestion ${getSelectedSuggestionNumber()} of ${meetingSuggestions.length}`}</Text>
                                <Button type="icon" size="small" onClick={() => {onSelectNewSuggestion(1)}} label={"Next suggestion"} disabled={!meetingSuggestions.length || meetingSuggestionSelected + 1 === meetingSuggestions.length || meetingSuggestionSelected === undefined || isSearchingMeetingSuggestions}>
                                    <Icon tokens={chevronRightSmallTokens} />
                                </Button>
                                {flowType === FLOW_TYPE.UPDATE &&
                                    <Link type="secondary" onClick={onSelectExistingMeeting}>
                                        Existing meeting
                                    </Link>
                                }
                            </Row>
                        :
                        <Row spacing={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "large" : "medium"} wrap="down">
                            <Text type="h100">Suggestions{isFirstSearch ? "" : ` (${meetingSuggestions.length})`}</Text>
                            <Row spacing="small">
                                <Text id={SORT_BY_LABEL_ID} type="h100">Sort by</Text>
                                <Select
                                    size="small"
                                    width="130px"
                                    value={sortOption}
                                    onChange={toggleSortOption}
                                    aria-labelledby={SORT_BY_LABEL_ID}
                                >
                                    <SelectOption value="Availability" label="Availability" />
                                    <SelectOption value="Earliest time" label="Earliest time" />
                                </Select>
                            </Row>
                        </Row>
                    }
                    <SuggestionViewToggle identity={identity} schedulingMode={props.schedulingMode} value={suggestionViewType} onChange={onSetSuggestionViewType} onShowToast={props.onShowToast} setRefreshSuggestions={setRefreshSuggestions} />
                </Row>
            </Column>
            <Divider size="small" />
            <Row widths={["fill", "fit", "fill"]} alignmentVertical="top" spacing="none">
                {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND && (suggestionViewType === SUGGESTION_VIEW_TYPE.LIST || !meetingSuggestions.length) &&
                    <div style={{
                        height: "100%",
                        // minHeight = 100vh - mastheadSize - (header (50px) + navigation header and footer (140px) + input fields (210px))
                        minHeight: `calc(100vh - ${mastheadSize} - 400px)`,
                        backgroundImage: `url(${PatternBackground})`,
                        backgroundRepeat: "repeat-y",
                        backgroundPosition: "left -250px center",
                        backgroundAttachment: "fixed",
                        opacity: BACKGROUND_OPACITY
                    }}/>
                }
                <div style={{overflow: "auto"}}>
                    <Column width="100%" maxWidth={(suggestionViewType === SUGGESTION_VIEW_TYPE.LIST || !meetingSuggestions.length) ? "1200px" : ""} alignmentHorizontal="center" spacingInset="medium none">
                        {alerts.length !== 0 &&
                            <AlertsContainer alerts={alerts} spacingInset={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "none medium" : "none"} />
                        }
                        {durationExceedsRoomLimit &&
                            <Alert type={ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT.type} size="medium">
                                <Column spacing="small">
                                    <Text id={ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT.id}>
                                        {ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT.value}
                                    </Text>
                                    <Text id={ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT_SUGGESTION.id}>
                                        {ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT_SUGGESTION.value}
                                    </Text>
                                </Column>
                            </Alert>
                        }
                        <Column width="100%" alignmentHorizontal="center">
                            {flowType === FLOW_TYPE.UPDATE && Object.keys(meetingDetails).length !== 0 && suggestionViewType === SUGGESTION_VIEW_TYPE.LIST &&
                                <Column spacingInset={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "none medium" : "none"}>
                                    <Column spacingInset="none medium">
                                        <Text type="h100">Existing meeting details</Text>
                                    </Column>
                                    <ExistingMeetingOption
                                        onChangeStep={onChangeStep}
                                        meetingDetails={meetingDetails}
                                        timezones={timezones}
                                        currentYear={currentYear}
                                        attendees={attendees}
                                        onSelectExistingMeeting={onSelectExistingMeeting}
                                        suggestionsTimezoneId={suggestionsTimezoneId}
                                        isExistingMeetingSelected={isExistingMeetingSelected}
                                        existingMeetingDetail={existingMeetingDetail}
                                        setExistingMeetingDetail={setExistingMeetingDetail}
                                        timeFormat={timeFormat}
                                    />
                                    <Divider />
                                </Column>
                            }
                            {isFirstSearch ?
                                <Column maxWidth="350px" alignmentHorizontal="center" spacingInset="medium">
                                    <Text type="b300" alignment="center">Select 'Get suggestions' after adjusting when you would like to meet.</Text>
                                </Column>
                                :
                                <React.Fragment>
                                    {isSearchingMeetingSuggestions ?
                                        <Column alignmentHorizontal="center">
                                            {(isAttendeesChanged || isLocationsChanged) &&
                                                <Text type="h100">Selected {isAttendeesChanged && isLocationsChanged ? "attendees and locations" : isAttendeesChanged ? "attendees" : "locations"} have changed.</Text>
                                            }
                                            <Text type="h100">Searching for meeting suggestions...</Text>
                                            <Loader type="circular" size="large" />
                                        </Column>
                                        :
                                        <Column width="100%" alignmentHorizontal="center" spacingInset={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "none medium" : "none"}>
                                            {suggestionViewType === SUGGESTION_VIEW_TYPE.GRID && meetingSuggestions.length > 0 &&
                                                <Column width="100%" alignmentHorizontal="center" spacing="none">
                                                    {/* TODO: Remove alert once grid mode supports meetings across multiple days */}
                                                    {spansMultipleDates &&
                                                        <Column spacingInset="none none medium none">
                                                            <Alert type="warning" size="medium">
                                                                Grid view support for meetings that start and end on different days is limited. &nbsp;
                                                                <Link onClick={() => onSetSuggestionViewType(SUGGESTION_VIEW_TYPE.LIST)}>
                                                                    Click here to switch to list view.
                                                                </Link>
                                                            </Alert>
                                                        </Column>
                                                    }
                                                    <CalendarTimeGrid
                                                        flowType={flowType}
                                                        attendees={attendees}
                                                        setGridAttendeeAvailability={setGridAttendeeAvailability}
                                                        freeAttendeesCount={freeAttendeesCount}
                                                        setFreeAttendeesCount={setFreeAttendeesCount}
                                                        prevRooms={prevRooms}
                                                        prevLocations={prevLocations}
                                                        meeting={meeting}
                                                        meetingDetails={meetingDetails}
                                                        meetingSuggestions={meetingSuggestions}
                                                        meetingSuggestionSelected={meetingSuggestionSelected}
                                                        isExistingMeetingSelected={isExistingMeetingSelected}
                                                        changedTimeWindow={changedTimeWindow}
                                                        setChangedTimeWindow={setChangedTimeWindow}
                                                        spansMultipleDates={spansMultipleDates}
                                                        onSelectSuggestion={onSelectSuggestion}
                                                        durationSelected={durationSelected}
                                                        setDurationSelected={setDurationSelected}
                                                        customDurationValue={customDurationValue}
                                                        setCustomDurationValue={setCustomDurationValue}
                                                        customDurationSelected={customDurationSelected}
                                                        setCustomDurationSelected={setCustomDurationSelected}
                                                        setCustomDurationLabel={setCustomDurationLabel}
                                                        prevDuration={prevDuration}
                                                        setPrevDuration={setPrevDuration}
                                                        selectedRooms={selectedRooms}
                                                        setSelectedRooms={setSelectedRooms}
                                                        isInitialLoad={isInitialLoad}
                                                        setIsInitialLoad={setIsInitialLoad}
                                                        timezones={timezones}
                                                        suggestionsTimezoneId={suggestionsTimezoneId}
                                                        onChangeSelectSuggestion={onChangeSelectSuggestion}
                                                        openRoomSwapModal={openRoomSwapModal}
                                                        currentSuggestionIndex={currentSuggestionIndex}
                                                        onChangeStep={props.onChangeStep}
                                                        prevGetScheduleQuery={props.prevGetScheduleQuery}
                                                        setPrevGetScheduleQuery={props.setPrevGetScheduleQuery}
                                                        onChangeRecurrenceTime={onChangeRecurrenceTime}
                                                        setRefreshSuggestions={setRefreshSuggestions}
                                                    />
                                                    {meetingSuggestions.length > 0 &&
                                                        <Column width="100%" alignmentHorizontal="center" spacingInset="small"
                                                                backgroundColor="white">
                                                            <StatusLegend
                                                                orientation="row"
                                                                statuses={["busy", "tentative", "outOfOffice", "noData"]}
                                                                showStripes={true}
                                                            />
                                                        </Column>
                                                    }
                                                </Column>
                                            }
                                            {suggestionViewType === SUGGESTION_VIEW_TYPE.LIST && meetingSuggestions.length > 0 &&
                                                <MeetingSuggestions
                                                    flowType={flowType}
                                                    identity={identity}
                                                    meeting={meeting}
                                                    attendees={attendees}
                                                    timezones={timezones}
                                                    suggestionsTimezoneId={suggestionsTimezoneId}
                                                    currentYear={currentYear}
                                                    prevRooms={prevRooms}
                                                    prevLocations={prevLocations}
                                                    isInitialLoad={isInitialLoad}
                                                    setIsInitialLoad={setIsInitialLoad}
                                                    meetingSuggestions={meetingSuggestions}
                                                    meetingSuggestionSelected={meetingSuggestionSelected}
                                                    meetingSuggestionsDetail={meetingSuggestionsDetail}
                                                    setMeetingSuggestionsDetail={setMeetingSuggestionsDetail}
                                                    selectedRooms={selectedRooms}
                                                    setSelectedRooms={setSelectedRooms}
                                                    onChangeSelectSuggestion={onChangeSelectSuggestion}
                                                    openRoomSwapModal={openRoomSwapModal}
                                                    isExistingMeetingSelected={isExistingMeetingSelected}
                                                    timeFormat={timeFormat}
                                                />
                                            }
                                            {meetingSuggestions.length < 10 &&
                                                <SuggestionsTips
                                                    isSimpleMeeting={false}
                                                    alignmentHorizontal={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "left" : "center"}
                                                    maxWidth="876px"
                                                    spacing={screenSizeBreakpoint > 786 ? "large xlarge" : "medium"}
                                                    spacingInset={screenSizeBreakpoint > 786 ? "large xlarge" : "medium"}
                                                />
                                            }
                                            <React.Fragment>
                                                <Modal
                                                    title="Change room"
                                                    open={roomSwapOpen}
                                                    onClose={closeRoomSwapModal}
                                                    scrollContainer="modal"
                                                    closeLabel="Close"
                                                    describedById="room-swap-modal"
                                                >
                                                    {meetingSuggestions.length !== 0 &&
                                                        <Column width="fit" id="room-swap-modal">
                                                            <Text>
                                                                Select your preferred room that's available during your suggestion's time slot.
                                                            </Text>
                                                            <Text>
                                                                <b>Selected Time:</b> {convertEpoch(meetingSuggestions[currentSuggestionIndex].startTime, "time", timezone, timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR)} - {convertEpoch(meetingSuggestions[currentSuggestionIndex].endTime, "time", timezone, timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR)}, {convertEpoch(meetingSuggestions[currentSuggestionIndex].endTime, "longWeekdayDateYear", timezone)} {timezoneIdToDisplayName(timezones, timezone)}
                                                            </Text>
                                                            <Divider size="small" />
                                                            {meetingSuggestions[currentSuggestionIndex].suggestionRoomList[currentRoomListIndex].map((roomInfo, roomIndex) => (
                                                                <Column key={roomInfo.room.name}>
                                                                    <Row alignmentHorizontal="justify">
                                                                        <RadioButton size="large" checked={selectedRoom === roomIndex} value={roomIndex} onChange={setSelectedRoom}>
                                                                            {shortRoomName(roomInfo.room.name)}
                                                                        </RadioButton>
                                                                        <Row width="200px" wrap="down" alignmentHorizontal="right" spacing="small">
                                                                            <Row width="80px" alignmentHorizontal="right">
                                                                                <Text alignment="right">{roomInfo.room.capacity === 1 ? "1 seat" : roomInfo.room.capacity + " seats"}</Text>
                                                                            </Row>
                                                                            <Row width="110px" alignmentHorizontal="right" spacing="xsmall">
                                                                                {roomInfo.room.resourceList.includes(LOCATION_FEATURE.CAMERA) && renderLocationFeatureIcon(LOCATION_FEATURE.CAMERA, 22)}
                                                                                {roomInfo.room.resourceList.includes(LOCATION_FEATURE.DISPLAY) && renderLocationFeatureIcon(LOCATION_FEATURE.DISPLAY, 22)}
                                                                                {roomInfo.room.restricted && renderLocationFeatureIcon(LOCATION_FEATURE.RESTRICTED, 22)}
                                                                                {roomInfo.room.managed && renderLocationFeatureIcon(LOCATION_FEATURE.HAND_MANAGED, 22)}
                                                                                {(!roomInfo.room.resourceList.includes(LOCATION_FEATURE.CAMERA) &&
                                                                                    !roomInfo.room.resourceList.includes(LOCATION_FEATURE.DISPLAY) &&
                                                                                    !roomInfo.room.restricted &&
                                                                                    !roomInfo.room.managed) &&
                                                                                        <Text>None</Text>
                                                                                }
                                                                            </Row>
                                                                        </Row>
                                                                    </Row>
                                                                    <Divider size="small" />
                                                                </Column>
                                                            ))}
                                                        </Column>
                                                    }
                                                    <ModalFooter>
                                                        <Row alignmentHorizontal="right" widths="fit">
                                                            <Button type="primary" size="medium" onClick={closeRoomSwapModal}>
                                                                Done
                                                            </Button>
                                                        </Row>
                                                    </ModalFooter>
                                                </Modal>
                                            </React.Fragment>
                                        </Column>
                                    }
                                </React.Fragment>
                            }
                        </Column>
                    </Column>
                </div>
                {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND && (suggestionViewType === SUGGESTION_VIEW_TYPE.LIST || !meetingSuggestions.length) &&
                    <div style={{
                        height: "100%",
                        // minHeight = 100vh - mastheadSize - (header (50px) + navigation header/footer (140px) + input fields (210px))
                        minHeight: `calc(100vh - ${mastheadSize} - 400px)`,
                        backgroundImage: `url(${PatternBackground})`,
                        backgroundRepeat: "repeat-y",
                        backgroundPosition: "right -250px center",
                        backgroundAttachment: "fixed",
                        opacity: BACKGROUND_OPACITY}}
                    />
                }
            </Row>
        </Column>
    );
};

export default StepSmartSuggestions;