import React, { useCallback, useEffect, useState } from "react";

import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import differenceInMinutes from "date-fns/differenceInMinutes";
import isBefore from "date-fns/isBefore";
import isSaturday from "date-fns/isSaturday";
import parseISO from "date-fns/parseISO";

import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Icon from "@amzn/meridian/icon";
import Loader from "@amzn/meridian/loader";
import Link from "@amzn/meridian/link";
import Modal, { ModalFooter } from "../../shared/meridian-custom-components/src/components/modal";
import RadioButton from "@amzn/meridian/radio-button";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "../../shared/meridian-custom-components/src/components/select";
import Text from "@amzn/meridian/text";

import AlertsContainer from "../../shared/containers/alerts-container.js";
import LabeledDurationSelect from "../../shared/components/labeled-duration-select.js";
import LabeledDateRangePicker from "../../shared/components/labeled-date-range-picker.js";
import LabeledTimeSelect from "../../shared/components/labeled-time-select.js";
import MeetingSuggestions from "./meeting-suggestions.js";
import TimezonePicker from "../../shared/components/timezone-search-bar.js";
import CalendarTimeGrid from "../../shared/custom-calendar-time-grid/containers/calendar-time-grid";
import StatusLegend from "../../shared/components/status-legend.js";
import SuggestionsTips from "../../shared/components/suggestions-tips";
import chevronLeftSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";

import PatternBackground from "../../../assets/backgrounds/pattern_small_vertical_repeating.svg";

import {
    STEP,
    LIVESTREAM_PREPARATION_MINUTES,
    SUGGESTION_WIDTH,
} from "../livestream-constants";
import {
    BACKGROUND_OPACITY,
    FLOW_TYPE,
    SEARCH_LIMIT,
    SUGGESTION_VIEW_TYPE,
} from "../../meeting-scheduler/meeting-scheduler-constants";
import { ALERTS, SCREEN_SIZE, TIME_CONSTANT, TIME_FORMAT } from "../../shared/shared-constants";
import { LOCATION_FEATURE } from "../../shared/locations/locations-constants";
import { ERRORS } from "../../shared/timezones/timezones-constants";
import { shortRoomName, renderLocationFeatureIcon } from "../../shared/locations/locations-utils";
import { ATTENDEE_PRIORITY } from "../../people/people-constants";
import { sortAttendees } from "../../shared/meeting-utils";
import { selectLivestreamStations } from "../livestream-utils";
import { generateLivestreamTicketPermalink } from "../livestream-ticket-utils";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { LIVESTREAM_NO_STATIONS_LINK_NAME, LIVESTREAM_NO_STATIONS_TOAST_ID, LIVESTREAM_PERMALINK_DETAILS } from "../livestream-constants";
import { formattedTimeToTimeUnit, roundTimeToNearestQuarterHour } from "../../shared/time-utils";
import { convertEpoch } from "../../meeting-scheduler/meeting-scheduler-utils";
import { getWarningToast, getWarningToastWithComponent } from "../../shared/toasts/toast-utils";
import { TOAST_COMPONENT, TIMEOUT } from "../../shared/toasts/toast-constants";
import { TIME_FORMAT as SETTINGS_TIME_FORMAT } from "../../shared/settings/settings-constants";

const StepEventSchedule = (props) => {
    const flowType = props.flowType;
    const currentStep = props.currentStep;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const mastheadSize = props.mastheadSize;
    const identity = props.identity;
    const meeting = props.meeting;
    const meetingDetails = props.meetingDetails;
    const loadExistingMeeting = props.loadExistingMeeting;
    const attendees = sortAttendees(props.attendees, identity.email);
    const stations = props.stations;
    const eventRequirements = props.eventRequirements;
    const findRooms = props.findRooms;
    const rooms = props.rooms;
    const locations = props.locations;
    const meetingSuggestions = props.meetingSuggestions;
    const isSearchingMeetingSuggestions = props.isSearchingMeetingSuggestions;
    const timezones = props.timezones;
    const primaryBrowserTimezoneId = props.primaryBrowserTimezoneId;
    const timezone = meeting.startTimezone.id || primaryBrowserTimezoneId;
    const onGetMeetingSuggestions = props.onGetMeetingSuggestions;
    const onSearchSuggestion = props.onSearchSuggestion;
    const onSelectSuggestion = props.onSelectSuggestion;
    const suggestionViewType = props.suggestionViewType;
    const onUpdateCurrentMeeting = props.onUpdateCurrentMeeting;
    const onSetSuggestionViewType = props.onSetSuggestionViewType;
    const onSelectStations = props.onSelectStations;
    const dateFormat = props.dateFormat;
    const timeFormat = props.timeFormat;

    // General states
    const [isFirstSearch, setIsFirstSearch] = [props.isFirstSearch, props.setIsFirstSearch];
    const [isInitialLoad, setIsInitialLoad] = [props.isInitialLoad, props.setIsInitialLoad];
    const [isAttendeesChanged, setIsAttendeesChanged] = [props.isAttendeesChanged, props.setIsAttendeesChanged];
    const [isLocationsChanged, setIsLocationsChanged] = [props.isLocationsChanged, props.setIsLocationsChanged];
    const [prevAttendees, setPrevAttendees] = [props.prevAttendees, props.setPrevAttendees];
    const [prevRooms, setPrevRooms] = [props.prevRooms, props.setPrevRooms];
    const [prevLocations, setPrevLocations] = [props.prevLocations, props.setPrevLocations];
    const [prevRequiredStationCount, setPrevRequiredStationCount] = [props.prevRequiredStationCount, props.setPrevRequiredStationCount];
    const [meetingSuggestionSelected, setMeetingSuggestionSelected] = [props.meetingSuggestionSelected, props.setMeetingSuggestionSelected];
    const [meetingSuggestionsDetail, setMeetingSuggestionsDetail] = [props.meetingSuggestionsDetail, props.setMeetingSuggestionsDetail];
    const [sortOption, setSortOption] = [props.sortOption, props.setSortOption];
    const [alerts, setAlerts] = [props.alerts, props.setAlerts];
    const [isExistingMeetingSelected, setIsExistingMeetingSelected] = [props.isExistingMeetingSelected, props.setIsExistingMeetingSelected];
    const [changedTimeWindow, setChangedTimeWindow] = [props.changedTimeWindow, props.setChangedTimeWindow];

    // Auto refresh
    const [refreshSuggestions, setRefreshSuggestions] = useState(false);

    // Meeting duration
    const [durationSelected, setDurationSelected] = [props.durationSelected, props.setDurationSelected];
    const [customDurationValue, setCustomDurationValue] = [props.customDurationValue, props.setCustomDurationValue];
    const [customDurationSelected, setCustomDurationSelected] = [props.customDurationSelected, props.setCustomDurationSelected];
    const [customDurationLabel, setCustomDurationLabel] = [props.customDurationLabel, props.setCustomDurationLabel];
    const [customDurationError, setCustomDurationError] = [props.customDurationError, props.setCustomDurationError];
    const [isAllDayEvent, setIsAllDayEvent] = [props.isAllDayEvent, props.setIsAllDayEvent];
    const [prevDuration, setPrevDuration] = [props.prevDuration, props.setPrevDuration];

    const onShowToast = props.onShowToast;
    const onHideToast = props.onHideToast;

    // Presets for the date range picker
    const presets = props.presets;
    const currentYear = props.currentYear;

    // Meeting date, time, and timezone
    const [dateRangeValue, setDateRangeValue] = [props.dateRangeValue, props.setDateRangeValue];
    const [startDateError, setStartDateError] = [props.startDateError, props.setStartDateError];
    const [dateRangeLabel, setDateRangeLabel] = [props.dateRangeLabel, props.setDateRangeLabel];
    const [startTime, setStartTime] = [props.startTime, props.setStartTime];
    const [endTime, setEndTime] = [props.endTime, props.setEndTime];
    const [timeLabel, setTimeLabel] = [props.timeLabel, props.setTimeLabel];
    const [timezoneValue, setTimezoneValue] = [props.timezoneValue, props.setTimezoneValue];

    // Meeting days of week
    const [daysOfWeek, setDaysOfWeek] = [props.daysOfWeek, props.setDaysOfWeek];
    const [daysOfWeekLabel, setDaysOfWeekLabel] = [props.daysOfWeekLabel, props.setDaysOfWeekLabel];

    // Current room selection info
    const [currentSuggestion, setCurrentSuggestion] = [props.currentSuggestion, props.setCurrentSuggestion];
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = [props.currentSuggestionIndex, props.setCurrentSuggestionIndex];
    const [currentRoomListIndex, setCurrentRoomListIndex] = [props.currentRoomListIndex, props.setCurrentRoomListIndex];
    const [selectedRooms, setSelectedRooms] = [props.selectedRooms, props.setSelectedRooms];
    const [selectedRoom, setSelectedRoom] = [props.selectedRoom, props.setSelectedRoom];

    // Room swap modal
    const [roomSwapOpen, setRoomSwapOpen] = useState(false);
    const onRoomSwapOpen = useCallback(() => setRoomSwapOpen(true), []);
    const onRoomSwapClose = useCallback(() => setRoomSwapOpen(false), []);

    // Suggestions
    const [noEssentialSuggestionCalled, setNoEssentialSuggestionCalled] = useState(false);
    // Updating timezone picker updates timezone value, but it should not affect the timezone of current suggestions
    const [suggestionsTimezoneId, setSuggestionsTimezoneId] = useState(timezoneValue);

    const [spansMultipleDates, setSpansMultipleDates] = useState(false);
    const [durationExceedsRoomLimit, setDurationExceedsRoomLimit] = useState(false);

    const SORT_BY_LABEL_ID = "sort-by-label-id";

    // Open the room swap modal with information for the selected suggestion and corresponding room list
    const openRoomSwapModal = (startTime, suggestionIndex, roomListIndex) => {
        setCurrentSuggestionIndex(suggestionIndex);
        setCurrentRoomListIndex(roomListIndex);
        setCurrentSuggestion(startTime + "-" + roomListIndex);
        setSelectedRoom(selectedRooms[startTime + "-" + roomListIndex] || 0);
        onRoomSwapOpen();
    };

    // Close the room swap modal and save the selected room
    const closeRoomSwapModal = () => {
        let rooms = selectedRooms;
        rooms[currentSuggestion] = selectedRoom;

        onChangeSelectSuggestion(meetingSuggestions[currentSuggestionIndex], rooms);
        setSelectedRooms(rooms);
        onRoomSwapClose();
    };

    // Change the selected suggestion and update the state with the selected rooms
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeSelectSuggestion = (suggestion, suggestionRoomList = selectedRooms) => {
        let resources = [];

        // Parse specific room selections
        prevRooms.forEach((room) => {
            let resource = {};

            resource.email = room.email;
            resource.name = room.name;

            resources.push(resource);
        });

        // Parse selected rooms from auto-suggest
        for (let [currentSuggestion, selectedRoom] of Object.entries(suggestionRoomList)) {
            let roomListIndex = currentSuggestion.split("-")[1];
            if (currentSuggestion.includes(suggestion.startTime) && suggestion.suggestionRoomList[roomListIndex][selectedRoom]) {
                let resource = {};
                let room = suggestion.suggestionRoomList[roomListIndex][selectedRoom].room;

                resource.email = room.email;
                resource.name = room.name;

                resources.push(resource);
            }
        }

        setMeetingSuggestionSelected(suggestion.peopleRankIndex);
        setIsExistingMeetingSelected(false);
        onSelectSuggestion(suggestion, resources);

        const livestreamStations = selectLivestreamStations(suggestion, eventRequirements["requiredStationCount"]);
        onSelectStations(livestreamStations);

        if (!livestreamStations.length) {
            props.setNoLivestreamStations(true);

            const livestreamMeeting = JSON.parse(JSON.stringify(meeting));
            livestreamMeeting.time.startTime = suggestion.startTime;
            livestreamMeeting.time.endTime = suggestion.endTime;
            const roomLocation = suggestion.location === "No room included" ? "Remote/virtual" : suggestion.location;
            const roomSelected = resources && resources.length ? resources.map((loc) => loc.name).reduce((acc, cur) => `${acc}, ${cur}`) : roomLocation; // Setting latest room location details
            const updateLocationInMeeting = {...livestreamMeeting, location: roomSelected}; // updating room location in existing object
            const ticketPermalink = generateLivestreamTicketPermalink(eventRequirements, updateLocationInMeeting, attendees, timezones, [], timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR, LIVESTREAM_PERMALINK_DETAILS.NO_STATIONS);
            const permalinkToast = getWarningToastWithComponent();

            permalinkToast.toastActionProps.componentName = TOAST_COMPONENT.LINK;
            permalinkToast.toastActionProps.text = "You have selected a schedule with no livestream engineers available. To create a request to the livestream team with the provided details, please";
            permalinkToast.toastActionProps.linkText = "open a SIM ticket";
            permalinkToast.toastActionProps.href = ticketPermalink;
            permalinkToast.toastActionProps.linkName = LIVESTREAM_NO_STATIONS_LINK_NAME;
            permalinkToast.id = LIVESTREAM_NO_STATIONS_TOAST_ID;
            permalinkToast.toastTimeout = TIMEOUT.NEVER;

            onShowToast(permalinkToast);
        } else {
            onHideToast(LIVESTREAM_NO_STATIONS_TOAST_ID);
            props.setNoLivestreamStations(false);

            // If the selected suggestion is within 48 hours, display an alert notifiying them that their request is less likely to be accepted
            const dateDifference = differenceInDays(
                new Date(suggestion.startTime * 1000),
                new Date()
            );

            if (dateDifference < 2) {
                const toast = getWarningToast("<b>Selected event time is less than 48 hours away.</b> Livestream engineer availability cannot be automatically confirmed.");

                onShowToast(toast);
            }
        }
    };

    // Update the state when selecting the existing meeting
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSelectExistingMeeting = () => {
        setIsExistingMeetingSelected(true);

        setDurationSelected("custom");
        const startTime = new Date(meetingDetails.time.startTime * 1000);
        const endTime = new Date(meetingDetails.time.endTime * 1000);
        const currentMeetingDuration = differenceInMinutes(endTime, startTime);
        setCustomDurationValue(currentMeetingDuration);
        setCustomDurationSelected("min");
        setCustomDurationLabel(`${currentMeetingDuration} mins`);

        let resources = [];

        // Parse specific room selections
        prevRooms.forEach((room) => {
            resources.push({
                email: room.email,
                name: room.name
            });
        });

        let suggestion = {
            "startTime": meetingDetails.time.startTime,
            "endTime": meetingDetails.time.endTime
        };

        onSelectSuggestion(suggestion, resources);
        onSelectStations([]);
    };

    // Toggle the meeting suggestion ordering between earliest time and availability (peopleRank from RAS API)
    const toggleSortOption = () => {
        if (sortOption === "Availability") {
            setSortOption("Earliest time");
            meetingSuggestions.sort((suggestion1, suggestion2) => {
                return suggestion1.startTime - suggestion2.startTime;
            });
        } else {
            setSortOption("Availability");
            meetingSuggestions.sort((suggestion1, suggestion2) => {
                return suggestion2.peopleRank - suggestion1.peopleRank;
            });
        }
    };

    // Validate all input fields and return alerts if there are any
    const getAlerts = (removeEssentialPriority) => {
        let alerts = [];

        const durationInMin = getDurationInMinutes();

        // Info alerts
        alerts.push(ALERTS.LIVESTREAM_PREPARATION_TIME);

        // Warning alerts
        if (timeLabel !== "invalid") {
            const startTimeMin = formattedTimeToTimeUnit(startTime, TIME_FORMAT.HH_MM_SS, TIME_CONSTANT.MIN_NAME);
            const endTimeMin = formattedTimeToTimeUnit(endTime === "00:00:00" ? "24:00:00" : endTime, TIME_FORMAT.HH_MM_SS, TIME_CONSTANT.MIN_NAME);

            let differenceInMin = endTimeMin - startTimeMin;
            if (startTimeMin > endTimeMin) {
                // treat the end time selected as the end time of the next day
                differenceInMin += TIME_CONSTANT.ONE_DAY_IN_MIN;
            }

            if (durationInMin < TIME_CONSTANT.ONE_DAY_IN_MIN && durationInMin > differenceInMin) {
                alerts.push(ALERTS.MEETING_DURATION_LONGER_THAN_TIME_WINDOW);
            }
        }
        if (dateRangeLabel !== "invalid") {
            let dateDifference = differenceInDays(
                new Date(dateRangeValue[1] + "T" + startTime),
                new Date(dateRangeValue[0] + "T" + startTime)
            );

            if (dateDifference >= SEARCH_LIMIT.DAYS) {
                alerts.push(ALERTS.MAX_DAYS_EXCEEDED);
            }

            if (parseInt(durationInMin / TIME_CONSTANT.ONE_DAY_IN_MIN) > dateDifference + 1) {
                alerts.push(ALERTS.MEETING_DURATION_LONGER_THAN_DATE_RANGE);
            }
        }
        if (attendees.length > SEARCH_LIMIT.MAILBOXES) {
            alerts.push(ALERTS.MAILBOX_SEARCH_LIMIT_EXCEEDED);
        }

        // Error alerts
        if ((durationSelected === "all day" || durationSelected === "custom") && customDurationError) {
            alerts.push(ALERTS.INVALID_MEETING_DURATION);
        }
        if (!dateRangeValue[0]) { // start date
            alerts.push(ALERTS.INVALID_START_DATE);
        }
        if (!dateRangeValue[1]) { // end date
            alerts.push(ALERTS.INVALID_END_DATE);
        }
        if (dateRangeValue[0] && dateRangeValue[1]) {
            const searchStartTime = new Date(dateRangeValue[0] + "T" + startTime);
            const now = new Date();

            // There must be at least 24h between the current time and the search time for livestream requests
            const invalidSearch = differenceInDays(searchStartTime, now) < 1;
            setStartDateError(invalidSearch);

            if (invalidSearch) {
                alerts.push(ALERTS.INVALID_LIVESTREAM_SEARCH_RANGE);
            }
        }
        if (!daysOfWeek.length) {
            alerts.push(ALERTS.NO_DAYS_OF_WEEK_SELECTED);
        }
        if (timeLabel === "invalid") {
            alerts.push(ALERTS.INVALID_TIME_WINDOW);
        }
        if (timezoneValue === ERRORS.INVALID_TIMEZONE) {
            alerts.push(ALERTS.INVALID_TIMEZONE);
        }

        if (!alerts.find((alert) => alert.type === "error" || alert.type === "warning") && removeEssentialPriority) {
            alerts.push(ALERTS.NO_SUGGESTIONS_FOUND);
        }

        // Show a warning alert if the meeting includes a room and the duration is longer than a day
        setDurationExceedsRoomLimit((rooms.length !== 0 || locations.length !== 0) && durationInMin > TIME_CONSTANT.ONE_DAY_IN_MIN); // using custom flag so alert can be styled

        return alerts;
    };

    // Get the inputted duration for the meeting in minutes
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getDurationInMinutes = () => {
        if (durationSelected === "all day" || durationSelected === "custom") {
            if (customDurationSelected === "min") {
                return parseInt(customDurationValue) || 0;
            } else if (customDurationSelected === "hour") {
                return parseInt(customDurationValue * 60) || 0;
            } else if (customDurationSelected === "day") {
                return parseInt(customDurationValue * 60 * 24) || 0;
            }
        } else {
            return durationSelected;
        }
    };

    // API Function: Get a list of participants for a meeting
    const getWishlistParticipantList = (removeEssentialPriority) => {
        let wishlistParticipantList = [];

        attendees.forEach((attendee) => {
            let currentAttendee = {
                exchangeMailbox: attendee.email
            };

            if (attendee.priority !== "Organizer" && attendee.priority !== "Required") {
                if (attendee.priority !== "Essential" || !removeEssentialPriority) {
                    currentAttendee["priorityLabel"] = attendee.priority.toLowerCase();
                }
            }

            wishlistParticipantList.push(currentAttendee);
        });

        // Add all livestream stations as optional participants to get station availability
        stations.forEach((station) => {
            let currentStation = {
                exchangeMailbox: station,
                priorityLabel: ATTENDEE_PRIORITY.OPTIONAL.toLowerCase(),
            };

            wishlistParticipantList.push(currentStation);
        });

        rooms.forEach((room) => {
            wishlistParticipantList.push({
                exchangeMailbox: room.email
            });
        });

        return wishlistParticipantList;
    };

    // API Function: Call RAS API to get list of meeting suggestions from a suggestion request
    // * disabling eslint useCallback warning since function is being used outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSuggestions = (removeEssentialPriority = false) => {
        setRefreshSuggestions(false);

        const alerts = getAlerts(removeEssentialPriority);
        let errors = alerts.filter((alert) => alert.type === "error");

        if (errors.length) {
            setAlerts(errors);
        } else {
            setAlerts(alerts);

            // Getting simple filters time of day
            let startTimeDate = new Date(dateRangeValue[0] + "T" + startTime);
            let endTimeDate = new Date(dateRangeValue[1] + "T" + endTime);

            let startHour = startTimeDate.getHours();
            let startMinute = startTimeDate.getMinutes();
            let endHour = endTimeDate.getHours();
            let endMinute = endTimeDate.getMinutes();

            let duration = getDurationInMinutes();
            if (durationSelected === "all day" || (durationSelected === "custom" && duration >= 60 * 24)) {
                startHour = 0;
                startMinute = 0;
                endHour = 0;
                endMinute = 0;
            }

            let startTimeString = "00:00:00";
            let endTimeString = "23:59:59";

            let startDate = new Date(dateRangeValue[0] + "T" + startTimeString);
            let endDate = new Date(dateRangeValue[1] + "T" + endTimeString);

            // If the start date is today, then the start time is set to the nearest 15 min to avoid suggestions in the past
            let currentDate = new Date();
            if (currentDate.toLocaleDateString() === startTimeDate.toLocaleDateString()) {
                startDate = roundTimeToNearestQuarterHour(currentDate);
            }

            let startEpoch = startDate.getTime() / 1000;
            let endEpoch = endDate.getTime() / 1000;

            // If the suggestion search range is longer than two months, set the end date to two months after the start
            let dateDifference = (endEpoch - startEpoch) / 60 / 60 / 24;
            if (dateDifference >= SEARCH_LIMIT.DAYS) {
                endDate = new Date(dateRangeValue[0] + "T" + endTimeString);
                endDate.setDate(endDate.getDate() + 61);
                endEpoch = endDate.getTime() / 1000;
            }

            let suggestionRequest = {
                "duration": duration + LIVESTREAM_PREPARATION_MINUTES, // add preparation time for a livestream event
                "simpleFilters": {
                    "dayOfWeek": {
                        "monday": daysOfWeek.includes("Mon"),
                        "tuesday": daysOfWeek.includes("Tue"),
                        "wednesday": daysOfWeek.includes("Wed"),
                        "thursday": daysOfWeek.includes("Thu"),
                        "friday": daysOfWeek.includes("Fri"),
                        // "saturday": daysOfWeek.includes("Sat"),
                        "saturday": false, // livestream engineers will never be available on Saturday
                        "sunday": daysOfWeek.includes("Sun"),
                    },
                    "timeOfDay": [
                        {
                            "startHour": startHour,
                            "startMinute": startMinute,
                            "endHour": endHour,
                            "endMinute": endMinute
                        }
                    ],
                    "timeZone": timezoneValue
                },
                "requestedOnBehalfOf": meeting.organizer,
                "searchDateRange": {
                    "startTime": startEpoch,
                    "endTime": endEpoch,
                },
                "wishlistRoomList": [{
                    "wishlistParticipantList": getWishlistParticipantList(removeEssentialPriority)
                }],
                "groupedBy": "timeslot",
                "timezone": {
                    "id": timezoneValue
                },
            };

            let wishlistRoomAttributes = locations;
            if (findRooms && wishlistRoomAttributes.length) {
                wishlistRoomAttributes.forEach((roomAttributes) => {
                    const wishlistRoomAttributes = {
                        ...roomAttributes,
                        "resourceList": roomAttributes.resourceList && roomAttributes.resourceList.length !== 0 ? roomAttributes.resourceList : undefined,
                    };
                    if (!suggestionRequest["wishlistRoomList"][0].wishlistRoomAttributes) {
                        suggestionRequest["wishlistRoomList"][0]["wishlistRoomAttributes"] = wishlistRoomAttributes;
                    } else {
                        suggestionRequest["wishlistRoomList"].push({
                            wishlistParticipantList: [],
                            wishlistRoomAttributes: wishlistRoomAttributes,
                        });
                    }
                });
            }

            if (durationSelected === 25 || durationSelected === 55) {
                // move meeting suggestions forward 5 minutes
                suggestionRequest["addOffsetMinutes"] = 5;
            }

            setIsInitialLoad(true);
            setIsLocationsChanged(!isFirstSearch && (prevRooms !== rooms || prevLocations !== locations));
            setIsAttendeesChanged(!isFirstSearch && JSON.stringify(prevAttendees) !== JSON.stringify(attendees));
            setPrevAttendees(attendees);
            setPrevRooms(rooms);
            setPrevLocations(locations);
            setPrevRequiredStationCount(eventRequirements["requiredStationCount"]);
            setPrevDuration(getDurationInMinutes());
            setMeetingSuggestionsDetail({});
            setSortOption("Availability");
            setCurrentSuggestion();
            setCurrentSuggestionIndex(0);
            setCurrentRoomListIndex(0);
            setSelectedRooms({});
            setSelectedRoom(0);
            setSuggestionsTimezoneId(timezoneValue);
            setChangedTimeWindow(undefined); // resetting the position of the grid window
            onSelectStations([]);

            if (flowType === FLOW_TYPE.CREATE) {
                setMeetingSuggestionSelected(0);
            } else {
                setMeetingSuggestionSelected();
                setIsExistingMeetingSelected(true);

                if (meetingDetails.time) {
                    let resources = [];

                    // Parse specific room selections
                    rooms.forEach((room) => {
                        resources.push({
                            email: room.email,
                            name: room.name,
                        });
                    });

                    let suggestion = {
                        "startTime": meetingDetails.time.startTime,
                        "endTime": meetingDetails.time.endTime
                    };

                    onSelectSuggestion(suggestion, resources);
                }
            }

            onSearchSuggestion(suggestionRequest, isAllDayEvent);
            onGetMeetingSuggestions(suggestionRequest);

            if (flowType === FLOW_TYPE.UPDATE) {
                if (suggestionViewType === SUGGESTION_VIEW_TYPE.GRID) {
                    setIsExistingMeetingSelected(false);
                    setMeetingSuggestionSelected(0);
                }
            }

            setIsFirstSearch(false);
        }

        setNoEssentialSuggestionCalled(removeEssentialPriority);
    };

    const getSelectedSuggestionNumber = () => {
        if (!meetingSuggestions || !meetingSuggestions.length) {
            return 0;
        }

        return (meetingSuggestionSelected || 0) + 1;
    };

    const onSelectNewSuggestion = (suggestionIndexDelta) => {
        setMeetingSuggestionSelected((meetingSuggestionSelected + suggestionIndexDelta) || 0);
        onChangeSelectSuggestion(meetingSuggestions[(meetingSuggestionSelected + suggestionIndexDelta) || 0]);
        setChangedTimeWindow(undefined);
    };

    const onChangeTimezoneValue = (timezone) => {
        setTimezoneValue(timezone);
        onUpdateCurrentMeeting({startTimezone: {id: timezone}, endTimezone: {id: timezone}});
        setRefreshSuggestions(true);
    };

    // Disable selection of dates in the past, up to 72 hours after the current time, and Saturdays to allow livestream engineers time for requests
    const isDisabledDate = useCallback((date) => (
        isBefore(parseISO(date), addDays(new Date(), +3), []) ||
        isSaturday(parseISO(date), [])
    ), []);

    // When the meeting time changes, check if it spans multiple dates
    useEffect(() => {
        const startDate = new Date(meeting.time.startTime * 1000);
        const endDate = new Date(meeting.time.endTime * 1000);

        const duration = getDurationInMinutes();

        if (duration <= TIME_CONSTANT.ONE_DAY_IN_MIN) {
            // false if the meeting ends at midnight
            const endsAtMidnight = endDate.toTimeString().split(" ")[0] === "00:00:00";
            setSpansMultipleDates(startDate.getDate() !== endDate.getDate() && !endsAtMidnight);
        } else {
            setSpansMultipleDates(true);
        }
    }, [meeting.time, setSpansMultipleDates, getDurationInMinutes]);

    // Get suggestions upon loading the step when editing a meeting or when the selected attendees or locations have changed
    useEffect(() => {
        if (identity && identity.username && currentStep === STEP.EVENT_SCHEDULE && meeting.organizer && !loadExistingMeeting && startTime && endTime && timezoneValue && stations) {
            const currentMeetingAttendeeCount = ((meetingDetails && meetingDetails.requiredAttendees) || [])
                                                .concat((meetingDetails && meetingDetails.optionalAttendees) || [])
                                                .filter((attendee) => !(attendee.email.endsWith("@chime.aws")))
                                                .length;
            const currentMeetingResourceCount = ((meetingDetails && meetingDetails.resources) || []).length;

            if ((isFirstSearch && (flowType === FLOW_TYPE.CREATE || (flowType === FLOW_TYPE.UPDATE && attendees.length >= currentMeetingAttendeeCount && rooms.length >= currentMeetingResourceCount))) ||
                (!isFirstSearch && (JSON.stringify(prevAttendees) !== JSON.stringify(attendees) || prevRooms !== rooms || prevLocations !== locations || prevRequiredStationCount !== eventRequirements["requiredStationCount"]))) {
                getSuggestions();
            }
        }
    }, [identity, currentStep, meeting, loadExistingMeeting, isFirstSearch, flowType, getSuggestions, prevAttendees, attendees, prevRooms, rooms, prevLocations, locations, startTime, endTime, timezoneValue, stations, meetingDetails, prevRequiredStationCount, eventRequirements]);

    useEffect(() => {
        if (!isFirstSearch && !isSearchingMeetingSuggestions && meetingSuggestions && meetingSuggestions.length === 0 && !noEssentialSuggestionCalled) {
            getSuggestions(true);
        }
    }, [isFirstSearch, isSearchingMeetingSuggestions, meetingSuggestions, noEssentialSuggestionCalled, getSuggestions]);

    // When suggestion inputs update, call getSuggestion
    useEffect(() => {
        if (refreshSuggestions) {
            getSuggestions();
        }
        // eslint-disable-next-line
    }, [refreshSuggestions]);

    return (
        <Column height="100%" spacing="none">
            <Column spacing={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "medium" : "small"} spacingInset="medium" backgroundColor="white" alignmentHorizontal="center">
                <Row width="100%" maxWidth={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "" : "360px"} wrap="down" spacing="small" alignmentHorizontal="center" alignmentVertical="stretch">
                    <Row spacing="small" wrap="down" alignmentHorizontal="center" alignmentVertical="stretch">
                        <LabeledDurationSelect
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            durationSelected={durationSelected}
                            setDurationSelected={setDurationSelected}
                            customDurationValue={customDurationValue}
                            setCustomDurationValue={setCustomDurationValue}
                            customDurationSelected={customDurationSelected}
                            setCustomDurationSelected={setCustomDurationSelected}
                            customDurationLabel={customDurationLabel}
                            setCustomDurationLabel={setCustomDurationLabel}
                            customDurationError={customDurationError}
                            setCustomDurationError={setCustomDurationError}
                            isAllDayEvent={isAllDayEvent}
                            setIsAllDayEvent={setIsAllDayEvent}
                            setRefreshSuggestions={setRefreshSuggestions}
                        />
                        <LabeledDateRangePicker
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            daysOfWeek={daysOfWeek}
                            setDaysOfWeek={setDaysOfWeek}
                            daysOfWeekLabel={daysOfWeekLabel}
                            setDaysOfWeekLabel={setDaysOfWeekLabel}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}
                            dateRangeLabel={dateRangeLabel}
                            setDateRangeLabel={setDateRangeLabel}
                            presets={presets}
                            startTime={startTime}
                            endTime={endTime}
                            startDateError={startDateError}
                            isDisabledDate={isDisabledDate}
                            setRefreshSuggestions={setRefreshSuggestions}
                            disabledDaysOfWeek={["Sat"]} // livestream engineers are never available on Saturday
                            dateFormat={dateFormat}
                        />
                    </Row>
                    <Row spacing="small" wrap="down" alignmentHorizontal="center" alignmentVertical="stretch">
                        <LabeledTimeSelect
                            screenSizeBreakpoint={screenSizeBreakpoint}
                            isAllDayEvent={isAllDayEvent}
                            startTime={startTime}
                            setStartTime={setStartTime}
                            endTime={endTime}
                            setEndTime={setEndTime}
                            timeLabel={timeLabel}
                            setTimeLabel={setTimeLabel}
                            timeFormat={timeFormat}
                            setRefreshSuggestions={setRefreshSuggestions}
                        />
                        <Column spacing="none" width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "150px" : "330px"}>
                            <Text type="b100">Timezone</Text>
                            {timezones.length ?
                                <TimezonePicker
                                    screenSizeBreakpoint={screenSizeBreakpoint}
                                    width="150px"
                                    mobileWidth="330px"
                                    size="small"
                                    timezones={timezones}
                                    value={timezoneValue}
                                    onChange={onChangeTimezoneValue} />
                                :
                                <Loader size="small" />
                            }
                        </Column>
                        <Column spacing="none">
                            <Text type="b100">&nbsp;</Text>
                            <Button type="primary" size="small" onClick={getSuggestions} disabled={!startTime || !endTime || !timezoneValue || !stations} minWidth="185px">
                                <Row spacingInset="none small">
                                    {(!startTime || !endTime || !timezoneValue || !stations) &&
                                        <Loader size="small" />
                                    }
                                    <Text type="b300" color="inverted">
                                        {isFirstSearch ? "Get suggestions" : "Refresh suggestions"}
                                    </Text>
                                </Row>
                            </Button>
                        </Column>
                    </Row>
                </Row>
            </Column>
            <Divider size="small" />
            <Column spacing={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "medium" : "small"} spacingInset="small medium" backgroundColor="white" alignmentHorizontal="center">
                <Row width="100%" maxWidth="1275px" wrap="down" spacing={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "small" : "medium"} alignmentHorizontal="justify">
                    {suggestionViewType === SUGGESTION_VIEW_TYPE.GRID ?
                        isExistingMeetingSelected ?
                            <Row>
                                <Link type="secondary" onClick={() => {onSelectNewSuggestion(0)}} disabled={!meetingSuggestions.length}>
                                    Suggestions{isFirstSearch ? "" : ` (${meetingSuggestions.length})`}
                                </Link>
                                <Text type="h100">Existing meeting</Text>
                            </Row>
                            :
                            <Row>
                                <Button type="icon" size="small" onClick={() => {onSelectNewSuggestion(-1)}} label={"Previous suggestion"} disabled={!meetingSuggestions.length || meetingSuggestionSelected === 0 || meetingSuggestionSelected === undefined || isSearchingMeetingSuggestions}>
                                    <Icon tokens={chevronLeftSmallTokens} />
                                </Button>
                                <Text type="h100">{isFirstSearch ? "Suggestions" : `Suggestion ${getSelectedSuggestionNumber()} of ${meetingSuggestions.length}`}</Text>
                                <Button type="icon" size="small" onClick={() => {onSelectNewSuggestion(1)}} label={"Next suggestion"} disabled={!meetingSuggestions.length || meetingSuggestionSelected + 1 === meetingSuggestions.length || meetingSuggestionSelected === undefined || isSearchingMeetingSuggestions}>
                                    <Icon tokens={chevronRightSmallTokens} />
                                </Button>
                                {flowType === FLOW_TYPE.UPDATE &&
                                    <Link type="secondary" onClick={onSelectExistingMeeting}>
                                        Existing meeting
                                    </Link>
                                }
                            </Row>
                        :
                        <Row spacing={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "large" : "medium"} wrap="down">
                            <Text type="h100">Suggestions{isFirstSearch ? "" : ` (${meetingSuggestions.length})`}</Text>
                            <Row spacing="small">
                                <Text id={SORT_BY_LABEL_ID} type="h100">Sort by</Text>
                                <Select
                                    size="small"
                                    width="130px"
                                    value={sortOption}
                                    onChange={toggleSortOption}
                                    aria-labelledby={SORT_BY_LABEL_ID}
                                >
                                    <SelectOption value="Availability" label="Availability" />
                                    <SelectOption value="Earliest time" label="Earliest time" />
                                </Select>
                            </Row>
                        </Row>
                    }
                    {/* TODO: Implement grid mode for livestreams */}
                </Row>
            </Column>
            <Divider size="small" />
            <Row widths={["fill", "fit", "fill"]} alignmentVertical="top" spacing="none">
                {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND && (suggestionViewType === SUGGESTION_VIEW_TYPE.LIST || !meetingSuggestions.length) &&
                    <div style={{
                        height: "100%",
                        // minHeight = 100vh - mastheadSize - (header (50px) + navigation header and footer (140px) + input fields (210px))
                        minHeight: `calc(100vh - ${mastheadSize} - 400px)`,
                        backgroundImage: `url(${PatternBackground})`,
                        backgroundRepeat: "repeat-y",
                        backgroundPosition: "left -250px center",
                        backgroundAttachment: "fixed",
                        opacity: BACKGROUND_OPACITY
                    }}/>
                }
                <div style={{overflow: "auto"}}>
                    <Column width="100%" maxWidth={(suggestionViewType === SUGGESTION_VIEW_TYPE.LIST || !meetingSuggestions.length) ? "1200px" : ""} alignmentHorizontal="center" spacingInset="medium none">
                        {alerts.length !== 0 &&
                            <AlertsContainer alerts={alerts} width={props.screenSizeBreakpoint > SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? SUGGESTION_WIDTH : undefined} />
                        }
                        {durationExceedsRoomLimit &&
                            <Alert type={ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT.type} size="medium">
                                <Column spacing="small">
                                    <Text id={ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT.id}>
                                        {ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT.value}
                                    </Text>
                                    <Text id={ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT_SUGGESTION.id}>
                                        {ALERTS.DURATION_EXCEEDS_ROOM_BOOKING_LIMIT_SUGGESTION.value}
                                    </Text>
                                </Column>
                            </Alert>
                        }
                        <Column width="100%" alignmentHorizontal="center">
                            {isFirstSearch ?
                                <Column maxWidth="350px" alignmentHorizontal="center" spacingInset="medium">
                                    <Text type="b300" alignment="center">Select 'Get suggestions' after adjusting when you would like to meet.</Text>
                                </Column>
                                :
                                <React.Fragment>
                                    {isSearchingMeetingSuggestions ?
                                        <Column alignmentHorizontal="center">
                                            {(isAttendeesChanged || isLocationsChanged) &&
                                                <Text type="h100">Selected {isAttendeesChanged && isLocationsChanged ? "attendees and locations" : isAttendeesChanged ? "attendees" : "locations"} have changed.</Text>
                                            }
                                            <Text type="h100">Searching for meeting suggestions...</Text>
                                            <Loader type="circular" size="large" />
                                        </Column>
                                        :
                                        <Column width="100%" alignmentHorizontal="center" spacingInset={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "none medium" : "none"}>
                                            {suggestionViewType === SUGGESTION_VIEW_TYPE.GRID && meetingSuggestions.length > 0 &&
                                                <Column width="100%" alignmentHorizontal="center" spacing="none">
                                                    {/* TODO: Remove alert once grid mode supports meetings across multiple days */}
                                                    {spansMultipleDates &&
                                                        <Column spacingInset="none none medium none">
                                                            <Alert type="warning" size="medium">
                                                                Grid view support for meetings that start and end on different days is limited. &nbsp;
                                                                <Link onClick={() => onSetSuggestionViewType(SUGGESTION_VIEW_TYPE.LIST)}>
                                                                    Click here to switch to list view.
                                                                </Link>
                                                            </Alert>
                                                        </Column>
                                                    }
                                                    <CalendarTimeGrid
                                                        flowType={flowType}
                                                        attendees={attendees}
                                                        prevRooms={prevRooms}
                                                        prevLocations={prevLocations}
                                                        meeting={meeting}
                                                        meetingDetails={meetingDetails}
                                                        meetingSuggestions={meetingSuggestions}
                                                        meetingSuggestionSelected={meetingSuggestionSelected}
                                                        isExistingMeetingSelected={isExistingMeetingSelected}
                                                        changedTimeWindow={changedTimeWindow}
                                                        setChangedTimeWindow={setChangedTimeWindow}
                                                        spansMultipleDates={spansMultipleDates}
                                                        onSelectSuggestion={onSelectSuggestion}
                                                        durationSelected={durationSelected}
                                                        setDurationSelected={setDurationSelected}
                                                        customDurationValue={customDurationValue}
                                                        setCustomDurationValue={setCustomDurationValue}
                                                        customDurationSelected={customDurationSelected}
                                                        setCustomDurationSelected={setCustomDurationSelected}
                                                        setCustomDurationLabel={setCustomDurationLabel}
                                                        prevDuration={prevDuration}
                                                        setPrevDuration={setPrevDuration}
                                                        selectedRooms={selectedRooms}
                                                        setSelectedRooms={setSelectedRooms}
                                                        isInitialLoad={isInitialLoad}
                                                        setIsInitialLoad={setIsInitialLoad}
                                                        timezones={timezones}
                                                        primaryBrowserTimezoneId={primaryBrowserTimezoneId}
                                                        suggestionsTimezoneId={suggestionsTimezoneId}
                                                        onChangeSelectSuggestion={onChangeSelectSuggestion}
                                                        openRoomSwapModal={openRoomSwapModal}
                                                        currentSuggestionIndex={currentSuggestionIndex}
                                                        onChangeStep={props.onChangeStep}
                                                        prevGetScheduleQuery={props.prevGetScheduleQuery}
                                                        setPrevGetScheduleQuery={props.setPrevGetScheduleQuery}
                                                        setRefreshSuggestions={setRefreshSuggestions}
                                                    />
                                                    {meetingSuggestions.length > 0 &&
                                                        <Column width="100%" alignmentHorizontal="center" spacingInset="small"
                                                                backgroundColor="white">
                                                            <StatusLegend
                                                                orientation="row"
                                                                statuses={["busy", "tentative", "outOfOffice", "noData"]}
                                                                showStripes={true}
                                                            />
                                                        </Column>
                                                    }
                                                </Column>
                                            }
                                            {suggestionViewType === SUGGESTION_VIEW_TYPE.LIST && meetingSuggestions.length > 0 &&
                                                <MeetingSuggestions
                                                    width={screenSizeBreakpoint > SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND ? SUGGESTION_WIDTH : undefined}
                                                    maxWidth={SUGGESTION_WIDTH}
                                                    flowType={flowType}
                                                    identity={identity}
                                                    screenSizeBreakpoint={screenSizeBreakpoint}
                                                    meeting={meeting}
                                                    attendees={attendees}
                                                    eventRequirements={eventRequirements}
                                                    timezones={timezones}
                                                    suggestionsTimezoneId={suggestionsTimezoneId}
                                                    currentYear={currentYear}
                                                    prevRooms={prevRooms}
                                                    prevLocations={prevLocations}
                                                    isInitialLoad={isInitialLoad}
                                                    setIsInitialLoad={setIsInitialLoad}
                                                    meetingSuggestions={meetingSuggestions}
                                                    meetingSuggestionSelected={meetingSuggestionSelected}
                                                    meetingSuggestionsDetail={meetingSuggestionsDetail}
                                                    setMeetingSuggestionsDetail={setMeetingSuggestionsDetail}
                                                    selectedRooms={selectedRooms}
                                                    setSelectedRooms={setSelectedRooms}
                                                    onChangeSelectSuggestion={onChangeSelectSuggestion}
                                                    openRoomSwapModal={openRoomSwapModal}
                                                    isExistingMeetingSelected={isExistingMeetingSelected}
                                                    timeFormat={timeFormat}
                                                />
                                            }
                                            {meetingSuggestions.length < 10 &&
                                                <SuggestionsTips
                                                    isSimpleMeeting={false}
                                                    alignmentHorizontal={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "left" : "center"}
                                                    maxWidth="876px"
                                                    spacing={screenSizeBreakpoint > 786 ? "large xlarge" : "medium"}
                                                    spacingInset={screenSizeBreakpoint > 786 ? "large xlarge" : "medium"}
                                                />
                                            }
                                            <React.Fragment>
                                                <Modal
                                                    title="Change room"
                                                    open={roomSwapOpen}
                                                    onClose={closeRoomSwapModal}
                                                    scrollContainer="modal"
                                                    closeLabel="Close"
                                                    describedById="room-swap-modal"
                                                >
                                                    {meetingSuggestions.length !== 0 &&
                                                        <Column width="fit" id="room-swap-modal">
                                                            <Text>
                                                                Select your preferred room that's available during your suggestion's time slot.
                                                            </Text>
                                                            <Text>
                                                                <b>Selected Time:</b> {convertEpoch(meetingSuggestions[currentSuggestionIndex].startTime, "time", timezone, timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR)} - {convertEpoch(meetingSuggestions[currentSuggestionIndex].endTime, "time", timezone, timeFormat === SETTINGS_TIME_FORMAT.TWELVE_HOUR)}, {convertEpoch(meetingSuggestions[currentSuggestionIndex].endTime, "longWeekdayDateYear", timezone)} {timezoneIdToDisplayName(timezones, timezone)}
                                                            </Text>
                                                            <Divider size="small" />
                                                            {meetingSuggestions[currentSuggestionIndex].suggestionRoomList[currentRoomListIndex].map((roomInfo, roomIndex) => (
                                                                <Column key={roomInfo.room.name}>
                                                                    <Row alignmentHorizontal="justify">
                                                                        <RadioButton size="large" checked={selectedRoom === roomIndex} value={roomIndex} onChange={setSelectedRoom}>
                                                                            {shortRoomName(roomInfo.room.name)}
                                                                        </RadioButton>
                                                                        <Row width="200px" wrap="down" alignmentHorizontal="right" spacing="small">
                                                                            <Row width="80px" alignmentHorizontal="right">
                                                                                <Text alignment="right">{roomInfo.room.capacity === 1 ? "1 seat" : roomInfo.room.capacity + " seats"}</Text>
                                                                            </Row>
                                                                            <Row width="110px" alignmentHorizontal="right" spacing="xsmall">
                                                                                {roomInfo.room.resourceList.includes(LOCATION_FEATURE.CAMERA) && renderLocationFeatureIcon(LOCATION_FEATURE.CAMERA, 22)}
                                                                                {roomInfo.room.resourceList.includes(LOCATION_FEATURE.DISPLAY) && renderLocationFeatureIcon(LOCATION_FEATURE.DISPLAY, 22)}
                                                                                {roomInfo.room.restricted && renderLocationFeatureIcon(LOCATION_FEATURE.RESTRICTED, 22)}
                                                                                {roomInfo.room.managed && renderLocationFeatureIcon(LOCATION_FEATURE.HAND_MANAGED, 22)}
                                                                                {(!roomInfo.room.resourceList.includes(LOCATION_FEATURE.CAMERA) &&
                                                                                    !roomInfo.room.resourceList.includes(LOCATION_FEATURE.DISPLAY) &&
                                                                                    !roomInfo.room.restricted &&
                                                                                    !roomInfo.room.managed) &&
                                                                                        <Text>None</Text>
                                                                                }
                                                                            </Row>
                                                                        </Row>
                                                                    </Row>
                                                                    <Divider size="small" />
                                                                </Column>
                                                            ))}
                                                        </Column>
                                                    }
                                                    <ModalFooter>
                                                        <Row alignmentHorizontal="right" widths="fit">
                                                            <Button type="primary" size="medium" onClick={closeRoomSwapModal}>
                                                                Done
                                                            </Button>
                                                        </Row>
                                                    </ModalFooter>
                                                </Modal>
                                            </React.Fragment>
                                        </Column>
                                    }
                                </React.Fragment>
                            }
                        </Column>
                    </Column>
                </div>
                {screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_SIDE_BACKGROUND && (suggestionViewType === SUGGESTION_VIEW_TYPE.LIST || !meetingSuggestions.length) &&
                    <div style={{
                        height: "100%",
                        // minHeight = 100vh - mastheadSize - (header (50px) + navigation header/footer (140px) + input fields (210px))
                        minHeight: `calc(100vh - ${mastheadSize} - 400px)`,
                        backgroundImage: `url(${PatternBackground})`,
                        backgroundRepeat: "repeat-y",
                        backgroundPosition: "right -250px center",
                        backgroundAttachment: "fixed",
                        opacity: BACKGROUND_OPACITY}}
                    />
                }
            </Row>
        </Column>
    );
};

export default StepEventSchedule;