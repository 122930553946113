import * as Actions from "./action-types";

export const setCalendarViewType = (viewType) => {
    return {type: Actions.SET_CALENDAR_VIEW_TYPE, viewType};
};

export const setCalendarDate = (date) => {
    return {type: Actions.SET_CALENDAR_DATE, date};
};

export const findMeetings = (requestedOnBehalfOf, startTime, endTime, maxResults) => {
    return {type: Actions.FIND_MEETINGS, requestedOnBehalfOf, startTime, endTime, maxResults};
};

export const setMeetingList = (meetingList) => {
    return {type: Actions.SET_MEETING_LIST, meetingList};
};

export const setCalendarStatusFilter = (calendarStatusFilter) => {
    return {type: Actions.SET_CALENDAR_STATUS_FILTER, calendarStatusFilter};
};

export const setCalendarResponseFilter = (calendarResponseFilter) => {
    return {type: Actions.SET_CALENDAR_RESPONSE_FILTER, calendarResponseFilter};
};

export const setAvailabilityRawData = (availabilityRawData) => {
    return {type: Actions.SET_AVAILABILITY_RAW_DATA, availabilityRawData};
};

export const setAvailabilityBlocks = (availabilityBlocks) => {
    return {type: Actions.SET_AVAILABILITY_BLOCKS, availabilityBlocks};
};
