import React, { useState, useEffect } from "react";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import Input from "@amzn/meridian/input";
import Select, { SelectOption } from "../meridian-custom-components/src/components/select";
import Button from "@amzn/meridian/button";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";
import Modal, { ModalFooter } from "../../shared/meridian-custom-components/src/components/modal";
import DatePicker from "@amzn/meridian/date-picker";
import Divider from "@amzn/meridian/divider";
import Alert from "@amzn/meridian/alert";

import infoKnockoutTokens from "@amzn/meridian-tokens/base/icon/info-knockout";

import DayOfMonthSearchBar from "./day-of-month-search-bar";
import MeetingsModalHeader from "./meetings-modal-header";

import { ALERTS, SCREEN_SIZE } from "../shared-constants";
import { convertEpoch, getMeetingDaysLabel, getOrdinalDay, getRecurrenceEndInfo } from "../../meeting-scheduler/meeting-scheduler-utils.js";
import { addAccessibilityPropertiesToLink } from "../accessibility-utils";
import { getLocalesFromSettingsDateFormat } from "../settings/settings-utils";

const LabeledRepeatSelect = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const meeting = props.meeting;
    const dateRangeValue = props.dateRangeValue;
    const dateFormat = props.dateFormat;

    // Meeting days of week
    const [daysOfWeek, setDaysOfWeek] = [props.daysOfWeek, props.setDaysOfWeek];
    const setDaysOfWeekLabel = props.setDaysOfWeekLabel;

    const setRepeatDays = props.setRepeatDays;
    const [repeatSelected, setRepeatSelected] = [props.repeatSelected, props.setRepeatSelected];
    const [customRepeatLabel, setCustomRepeatLabel] = [props.customRepeatLabel, props.setCustomRepeatLabel];
    const [customRepeatError, setCustomRepeatError] = [props.customRepeatError, props.setCustomRepeatError];

    const [customRepeatInterval, setCustomRepeatInterval] = [props.customRepeatInterval, props.setCustomRepeatInterval];
    const [customRepeatPeriod, setCustomRepeatPeriod] = [props.customRepeatPeriod, props.setCustomRepeatPeriod];
    const [customRepeatDays, setCustomRepeatDays] = [props.customRepeatDays, props.setCustomRepeatDays];
    const [customRepeatMonthSelected, setCustomRepeatMonthSelected] = [props.customRepeatMonthSelected, props.setCustomRepeatMonthSelected];
    const [customRepeatMonthDay, setCustomRepeatMonthDay] = [props.customRepeatMonthDay, props.setCustomRepeatMonthDay];
    const [customRepeatMonthDayOfWeek, setCustomRepeatMonthDayOfWeek] = [props.customRepeatMonthDayOfWeek, props.setCustomRepeatMonthDayOfWeek];
    const [customRepeatEndSelected, setCustomRepeatEndSelected] = [props.customRepeatEndSelected, props.setCustomRepeatEndSelected];
    const [customRepeatEndDate, setCustomRepeatEndDate] = [props.customRepeatEndDate, props.setCustomRepeatEndDate];
    const [customRepeatOccurrences, setCustomRepeatOccurrences] = [props.customRepeatOccurrences, props.setCustomRepeatOccurrences];

    const [recurrenceEndInfo, setRecurrenceEndInfo] = useState("");

    const REPEATS_LABEL_ID = "repeats-label-id";
    const MODAL_REPEAT_DURATION_UNIT_LABEL_ID = "modal-repeat-duration-unit-label-id";
    const MODAL_ON_LABEL_ID = "modal-on-label-id";
    const MODAL_REPEATS_LABEL_ID = "modal-repeats-label-id";
    const MODAL_END_LABEL_ID = "modal-end-label-id";
    const MODAL_END_DATE_LABEL_ID = "modal-end-date-label-id";

    const displayRecurrenceToast = props.displayRecurrenceToast;
    const repeatToastId = props.repeatToastId;

    const repeatOpen = props.repeatOpen;
    const onRepeatClose = props.onRepeatClose;
    const openRepeatModal = props.openRepeatModal;

    const prevRepeatInfo = props.prevRepeatInfo;
    const setIsRepeatChanged = props.setIsRepeatChanged;

    const [invalidEndDate, setInvalidEndDate] = useState(false);

    const locationAdded = props.locationAdded;
    const [showLocationWarning, setShowLocationWarning] = useState(locationAdded && customRepeatEndSelected === "none");
    const yearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setHours(0, 0, 0, 0); // midnight a year from today

    const onChangeRepeatSelected = (value, recurrenceRemoved = false) => {
        setRepeatSelected(value);

        if (value === "none") {
            updateDaysOfWeek(["Mon", "Tue", "Wed", "Thu", "Fri"], value);

            if ((!recurrenceRemoved && repeatSelected !== "none") ||
                (recurrenceRemoved && prevRepeatInfo.repeatSelected !== "none")) {
                setIsRepeatChanged(true);
            }
        } else {
            setCustomRepeatPeriod(value);
            openRepeatModal(repeatSelected);
        }

        if (value !== repeatSelected) {
            setCustomRepeatLabel();
        }
    };

    const onChangeCustomRepeatInterval = (value) => {
        if (value) {
            setCustomRepeatInterval(Math.min(value, 999));
        } else {
            setCustomRepeatInterval();
        }
    };

    const onChangeCustomRepeatDays = (value) => {
        let days = value;

        if (customRepeatDays.includes("Any") && customRepeatDays.length === 1 && value.length > 1) {
            days.splice(days.indexOf("Any"), 1);
            setCustomRepeatDays(days);
        } else if (value.includes("Any")) {
            setCustomRepeatDays(["Any"]);
        } else {
            setCustomRepeatDays(value);
        }
    };

    const onChangeCustomRepeatEndDate = (value) => {
        setCustomRepeatEndDate(value);
        setInvalidEndDate(Date.parse(value) < Date.parse(dateRangeValue[0]));
    };

    const onChangeCustomRepeatOccurrences = (value) => {
        if (value) {
            setCustomRepeatOccurrences(Math.min(value, 999));
        } else {
            setCustomRepeatOccurrences();
        }
    };

    const updateDaysOfWeek = (days = daysOfWeek, repeat = repeatSelected) => {
        if (days.includes("Any")) {
            const allDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri"];
            setRepeatDays(allDays); // meeting can repeat on any day of the week
            setDaysOfWeek(weekdays); // by default only use weekdays
            getMeetingDaysLabel(weekdays, repeat, setDaysOfWeekLabel);
        } else {
            setRepeatDays(days);
            setDaysOfWeek(days);
            getMeetingDaysLabel(days, repeat, setDaysOfWeekLabel);
        }
    };

    const repeatInfoEqual = () => {
        return repeatSelected === prevRepeatInfo.repeatSelected
            && customRepeatPeriod === prevRepeatInfo.customRepeatPeriod
            && customRepeatInterval === prevRepeatInfo.customRepeatInterval
            && JSON.stringify(customRepeatDays.sort()) === JSON.stringify(prevRepeatInfo.customRepeatDays.sort())
            && customRepeatMonthSelected === prevRepeatInfo.customRepeatMonthSelected
            && customRepeatMonthDay === prevRepeatInfo.customRepeatMonthDay
            && customRepeatMonthDayOfWeek === prevRepeatInfo.customRepeatMonthDayOfWeek
            && customRepeatEndSelected === prevRepeatInfo.customRepeatEndSelected
            && customRepeatEndDate === prevRepeatInfo.customRepeatEndDate
            && customRepeatOccurrences === prevRepeatInfo.customRepeatOccurrences
            && customRepeatLabel === prevRepeatInfo.customRepeatLabel;
    };

    // Remove recurrence pattern from meeting
    const removeRecurrence = () => {
        closeRepeatModal(true);
        onChangeRepeatSelected("none", true);
    };

    // Revert recurrence info back to previous state
    const cancelRepeatModal = () => {
        setRepeatSelected(prevRepeatInfo.repeatSelected);
        setCustomRepeatInterval(prevRepeatInfo.customRepeatInterval);
        setCustomRepeatPeriod(prevRepeatInfo.customRepeatPeriod);
        setCustomRepeatDays(prevRepeatInfo.customRepeatDays);
        setCustomRepeatMonthSelected(prevRepeatInfo.customRepeatMonthSelected);
        setCustomRepeatMonthDay(prevRepeatInfo.customRepeatMonthDay);
        setCustomRepeatMonthDayOfWeek(prevRepeatInfo.customRepeatMonthDayOfWeek);
        setCustomRepeatEndSelected(prevRepeatInfo.customRepeatEndSelected);
        setCustomRepeatEndDate(prevRepeatInfo.customRepeatEndDate);
        setCustomRepeatOccurrences(prevRepeatInfo.customRepeatOccurrences);
        setCustomRepeatLabel(prevRepeatInfo.customRepeatLabel);

        if (repeatToastId === undefined) {
            displayRecurrenceToast();
        }

        onRepeatClose();
    };

    const closeRepeatModal = (recurrenceRemoved = false) => {
        // If there are any empty fields in the repeat modal, there is an error
        if (!customRepeatInterval ||
            (customRepeatPeriod === "week" && !customRepeatDays.length) ||
            (customRepeatEndSelected === "by" && (!customRepeatEndDate || invalidEndDate)) ||
            (customRepeatEndSelected === "after" && !customRepeatOccurrences)) {
            setCustomRepeatError(true);
        } else {
            setCustomRepeatError(false);
        }

        if (customRepeatPeriod === "week") {
            setRepeatSelected("week");
            updateDaysOfWeek(customRepeatDays);
        } else if (customRepeatPeriod === "day") {
            setRepeatSelected("day");
            updateDaysOfWeek(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
        } else if (customRepeatPeriod === "month") {
            setRepeatSelected("month");
            if (customRepeatMonthSelected === "day") {
                updateDaysOfWeek(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
            } else if (customRepeatMonthDayOfWeek === "day") {
                updateDaysOfWeek(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
            } else if (customRepeatMonthDayOfWeek === "weekday") {
                updateDaysOfWeek(["Mon", "Tue", "Wed", "Thu", "Fri"]);
            } else if (customRepeatMonthDayOfWeek === "weekend") {
                updateDaysOfWeek(["Sun", "Sat"]);
            } else {
                updateDaysOfWeek([customRepeatMonthDayOfWeek]);
            }
        }

        getCustomRepeatLabel();

        if (!recurrenceRemoved && !repeatInfoEqual()) {
            setIsRepeatChanged(true);
        } else if (repeatToastId === undefined) {
            displayRecurrenceToast();
        }

        onRepeatClose();
    };

    // Update the custom repeat label based on the values from the modal
    const getCustomRepeatLabel = () => {
        if (repeatSelected !== "none") {
            let label = "*Every " + customRepeatPeriod;
            if (customRepeatInterval > 1) {
                label = "*Every " + customRepeatInterval + " " + customRepeatPeriod + "s";
            }

            if (customRepeatPeriod === "day") {
                setCustomRepeatLabel(label);
            } else if (customRepeatPeriod === "week") {
                if (customRepeatDays.includes("Any")) {
                    setCustomRepeatLabel(label + " on any day");
                } else if (customRepeatDays.length) {
                    let allDaysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                    let customRepeatLabel = [];

                    allDaysOfWeek.forEach((day) => {
                        if (customRepeatDays.includes(day)) {
                            customRepeatLabel.push(day);
                        };
                    });

                    setCustomRepeatLabel(label + " on " + customRepeatLabel.join(", "));
                } else {
                    setCustomRepeatLabel(label);
                }
            } else if (customRepeatPeriod === "month") {
                if (customRepeatMonthSelected === "day") {
                    setCustomRepeatLabel(label + " on the " + getOrdinalDay(customRepeatMonthDay));
                } else {
                    setCustomRepeatLabel(label + " on the " + customRepeatMonthSelected + " " + customRepeatMonthDayOfWeek);
                }
            } else {
                setCustomRepeatLabel("");
            }
        }
    };

    useEffect(() => {
        if (!customRepeatLabel) {
            getCustomRepeatLabel();
        }
        addAccessibilityPropertiesToLink("amw-repeat-selection-id", [
            ALERTS.NO_RECURRENCE_INTERVAL_INPUTTED.id,
            ALERTS.NO_RECURRENCE_MEETING_COUNT_INPUTTED.id,
            ALERTS.NO_RECUR_DAYS_OF_WEEK_SELECTED.id
        ]);
    });

    // Get recurrence info to display inside of the modal
    useEffect(() => {
        const startTime = meeting.time && meeting.time.startTime;

        // Calculate recurrence end date/occurrences based on the current parameters
        if (repeatSelected !== "none" && startTime) {
            const recurrence = {
                type: customRepeatPeriod,
                endByDate: customRepeatEndSelected === "by" ? new Date(customRepeatEndDate + "T00:00:00").getTime() / 1000 : undefined,
                endAfterNOccurrences: customRepeatEndSelected === "after" ? customRepeatOccurrences : undefined,
                interval: customRepeatInterval,
                daysOfWeek: {
                    sunday: customRepeatDays.includes("Sun"),
                    monday: customRepeatDays.includes("Mon"),
                    tuesday: customRepeatDays.includes("Tue"),
                    wednesday: customRepeatDays.includes("Wed"),
                    thursday: customRepeatDays.includes("Thu"),
                    friday: customRepeatDays.includes("Fri"),
                    saturday: customRepeatDays.includes("Sat"),
                },
                monthlyType: customRepeatMonthSelected === "day" ? "absolute" : "relative",
                weekOfMonth: customRepeatMonthSelected,
                dayOfMonth: customRepeatMonthDay,
                dayOfWeek: customRepeatMonthDayOfWeek,
            };

            // If "Any" is selected, recurrence will repeat on the selected suggestion's day of week
            if (customRepeatPeriod === "week" && customRepeatDays.includes("Any")) {
                const dayOfWeek = new Date(startTime * 1000).getDay();
                recurrence.daysOfWeek = {
                    sunday: dayOfWeek === 0,
                    monday: dayOfWeek === 1,
                    tuesday: dayOfWeek === 2,
                    wednesday: dayOfWeek === 3,
                    thursday: dayOfWeek === 4,
                    friday: dayOfWeek === 5,
                    saturday: dayOfWeek === 6,
                };
            }

            const recurrenceEndInfo = getRecurrenceEndInfo(recurrence, startTime);

            setRecurrenceEndInfo(recurrenceEndInfo);

            // If a location has been added, check if the meeting ends within a year
            const exceedsYear = recurrenceEndInfo && recurrenceEndInfo.lastDate > yearFromNow;
            setShowLocationWarning(locationAdded && (customRepeatEndSelected === "none" || exceedsYear));
        }
    }, [meeting.time, repeatSelected, customRepeatPeriod, customRepeatInterval, customRepeatDays, customRepeatMonthSelected, customRepeatMonthDay, customRepeatMonthDayOfWeek, customRepeatEndSelected, customRepeatEndDate, customRepeatOccurrences, repeatOpen, locationAdded, yearFromNow]);

    const repeatModalId = "repeatModal";

    return (
        <Column>
            <Column spacing="none" width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "150px" : "330px"}>
                <Text id={REPEATS_LABEL_ID} type="b100">Repeats</Text>
                <Select
                    width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "150px" : "330px"}
                    size="small"
                    value={repeatSelected}
                    onChange={onChangeRepeatSelected}
                    disabled={props.disabled}
                    error={repeatSelected !== "none" && customRepeatError}
                    placeholder="Select repeat pattern"
                    aria-labelledby={REPEATS_LABEL_ID}
                >
                    <SelectOption value="none" label="None" />
                    <SelectOption value="day" label="Daily*" />
                    <SelectOption value="week" label="Weekly*" />
                    <SelectOption value="month" label="Monthly*" />
                </Select>
                {repeatSelected !== "none" &&
                    <Text type="b100">
                        {/*<div id="amw-repeat-selection-id">
                            <Link type="secondary" onClick={openRepeatModal}>{customRepeatLabel}</Link>
                        </div>*/}
                        {customRepeatLabel}
                    </Text>
                }
            </Column>
            <React.Fragment>
                <Modal
                    open={repeatOpen}
                    bodySpacingInset="none"
                    scrollContainer="viewport"
                    describedById={repeatModalId}
                    width="550px"
                >
                    <MeetingsModalHeader
                        modalId={repeatModalId}
                        modalTitle="Set recurring meetings"
                        showCustomCloseButton={true}
                        onCloseModal={cancelRepeatModal}
                    />
                    <Column spacingInset="medium medium none medium">
                        <Text type="b200">
                            This will create recurring meetings until the end date, for a specific number of instances, or forever.
                            The suggestions provided are only for the first occurrence.
                        </Text>
                        <Divider size="small" />
                    </Column>
                    <Column id={repeatModalId} spacingInset="medium">
                        <Row alignmentVertical="bottom">
                            <Column spacing="none">
                                <Text type="b100">Repeats</Text>
                                <div style={{display: "none"}}>
                                    <Text id={MODAL_REPEAT_DURATION_UNIT_LABEL_ID}>Custom duration unit</Text>
                                </div>
                                <Select
                                    width="125px"
                                    size="small"
                                    value={customRepeatPeriod}
                                    onChange={setCustomRepeatPeriod}
                                    placeholder="Select repeat period"
                                    aria-labelledby={MODAL_REPEAT_DURATION_UNIT_LABEL_ID}
                                >
                                    <SelectOption value="day" label="Daily" />
                                    <SelectOption value="week" label="Weekly" />
                                    <SelectOption value="month" label="Monthly" />
                                </Select>
                            </Column>
                            <Column spacing="none">
                                <Text type="b100">Every</Text>
                                <Row spacing="small">
                                    <Input type="number" width="55px" pattern={/^[0-9]*$/} textAlign="right" size="small" value={customRepeatInterval} onChange={onChangeCustomRepeatInterval} error={!customRepeatInterval} />
                                    <Text type="b200">
                                        {customRepeatPeriod + "(s)"}
                                    </Text>
                                </Row>
                            </Column>
                        </Row>
                        {customRepeatPeriod === "week" &&
                            <Row wrap="down">
                                <Column spacing="none">
                                    <Text type="b100">On</Text>
                                    <ButtonGroup type="outline" value={customRepeatDays} onChange={onChangeCustomRepeatDays}>
                                        <ButtonOption value="Sun">Sun</ButtonOption>
                                        <ButtonOption value="Mon">Mon</ButtonOption>
                                        <ButtonOption value="Tue">Tue</ButtonOption>
                                        <ButtonOption value="Wed">Wed</ButtonOption>
                                        <ButtonOption value="Thu">Thu</ButtonOption>
                                        <ButtonOption value="Fri">Fri</ButtonOption>
                                        <ButtonOption value="Sat">Sat</ButtonOption>
                                        <ButtonOption value="Any">Any</ButtonOption>
                                    </ButtonGroup>
                                    {!customRepeatDays.length &&
                                        <Text type="b100" color="error">*Select at least one day</Text>
                                    }
                                </Column>
                            </Row>
                        }
                        {customRepeatPeriod === "month" &&
                            <Row alignmentVertical="bottom">
                                <Column spacing="none">
                                    <Text id={MODAL_ON_LABEL_ID} type="b100">On</Text>
                                    <Select
                                        width="120px"
                                        size="small"
                                        value={customRepeatMonthSelected}
                                        onChange={setCustomRepeatMonthSelected}
                                        placeholder="Select a month option"
                                        aria-labelledby={MODAL_ON_LABEL_ID}
                                    >
                                        <SelectOption value="day" label="Day" />
                                        <SelectOption disabled={true} value="" label="">{() => ( <Divider size="small" /> )}</SelectOption>
                                        <SelectOption value="first" label="First" />
                                        <SelectOption value="second" label="Second" />
                                        <SelectOption value="third" label="Third" />
                                        <SelectOption value="fourth" label="Fourth" />
                                        <SelectOption value="last" label="Last" />
                                    </Select>
                                </Column>
                                {customRepeatMonthSelected === "day" &&
                                    <Column>
                                        <DayOfMonthSearchBar width="175px" size="small" value={customRepeatMonthDay} onChange={setCustomRepeatMonthDay} />
                                    </Column>
                                }
                                {customRepeatMonthSelected !== "day" &&
                                    <Column>
                                        <div style={{display: "none"}}>
                                            <Text id={MODAL_REPEATS_LABEL_ID}>Repeats</Text>
                                        </div>
                                        <Select
                                            width="175px"
                                            size="small"
                                            value={customRepeatMonthDayOfWeek}
                                            onChange={setCustomRepeatMonthDayOfWeek}
                                            placeholder="Select a day of month"
                                            aria-labelledby={MODAL_REPEATS_LABEL_ID}
                                        >
                                            <SelectOption value="Sun" label="Sunday" />
                                            <SelectOption value="Mon" label="Monday" />
                                            <SelectOption value="Tue" label="Tuesday" />
                                            <SelectOption value="Wed" label="Wednesday" />
                                            <SelectOption value="Thu" label="Thursday" />
                                            <SelectOption value="Fri" label="Friday" />
                                            <SelectOption value="Sat" label="Saturday" />
                                            <SelectOption disabled={true} value="" label="">{() => ( <Divider size="small" /> )}</SelectOption>
                                            <SelectOption value="day" label="Day" />
                                            <SelectOption value="weekday" label="Weekday" />
                                            <SelectOption value="weekend" label="Weekend day" />
                                        </Select>
                                    </Column>
                                }
                            </Row>
                        }
                        {customRepeatPeriod === "month" && customRepeatMonthSelected === "day" && customRepeatMonthDay > 28 &&
                            <Row spacing="small">
                                <Icon tokens={infoKnockoutTokens} />
                                <Text type="b200">
                                    For months with fewer than {customRepeatMonthDay} days, the occurrence will fall on the last day of the month.
                                </Text>
                            </Row>
                        }
                        <Row alignmentVertical="bottom">
                            <Column spacing="none">
                                <Text type="b100" id={MODAL_END_LABEL_ID}>End</Text>
                                <Select
                                    width="90px"
                                    size="small"
                                    value={customRepeatEndSelected}
                                    onChange={setCustomRepeatEndSelected}
                                    placeholder="Select when repeat ends"
                                    aria-labelledby={MODAL_END_LABEL_ID}
                                >
                                    <SelectOption value="by" label="By" />
                                    <SelectOption value="after" label="After" />
                                    <SelectOption value="none" label="None" />
                                </Select>
                            </Column>
                            {customRepeatEndSelected === "by" &&
                                <Column spacing="none">
                                    <Text type="b100" id={MODAL_END_DATE_LABEL_ID}>End date (MM/DD/YYYY)</Text>
                                    <DatePicker
                                        size="small"
                                        width="160px"
                                        value={customRepeatEndDate}
                                        onChange={onChangeCustomRepeatEndDate}
                                        locale={getLocalesFromSettingsDateFormat(dateFormat)}
                                        error={!customRepeatEndDate || invalidEndDate}
                                        placeholder="Select recurrence end date"
                                        aria-describedby={MODAL_END_DATE_LABEL_ID}
                                    />
                                </Column>
                            }
                            {customRepeatEndSelected === "after" &&
                                <Row spacing="small">
                                    <Input width="55px" size="small" type="number" pattern={/^[0-9]*$/} value={customRepeatOccurrences} onChange={onChangeCustomRepeatOccurrences} error={customRepeatEndSelected === "after" && !customRepeatOccurrences} />
                                    <Text type="b200">occurrence(s)</Text>
                                </Row>
                            }
                        </Row>
                        {customRepeatEndSelected === "by" && invalidEndDate &&
                            <Alert size="small" type="error">End date is before search range's start date</Alert>
                        }
                        {customRepeatEndSelected === "none" &&
                            <Row spacing="small">
                                <Icon tokens={infoKnockoutTokens} />
                                <Text type="b200">
                                    This meeting will repeat forever
                                </Text>
                            </Row>
                        }
                        {customRepeatEndSelected === "by" && recurrenceEndInfo && recurrenceEndInfo.occurrences > 0 &&
                            <Row spacing="small">
                                <Icon tokens={infoKnockoutTokens} />
                                <Text type="b200">
                                    This meeting will end after {recurrenceEndInfo.occurrences} occurrence{recurrenceEndInfo.occurrences > 1 ? "s" : ""}
                                </Text>
                            </Row>
                        }
                        {customRepeatEndSelected === "after" && customRepeatOccurrences && recurrenceEndInfo &&
                            <Row spacing="small">
                                <Icon tokens={infoKnockoutTokens} />
                                <Text type="b200">
                                    This meeting will end on {recurrenceEndInfo.formattedLastDate}
                                </Text>
                            </Row>
                        }
                        {showLocationWarning &&
                            <Alert type="warning" size="small">
                                Conference rooms at Amazon can only be booked up to 365 days in advance (series must end by {convertEpoch(yearFromNow / 1000, "date")}).
                                Your room reservation will be declined.
                            </Alert>
                        }
                    </Column>
                    <ModalFooter>
                        <Row alignmentHorizontal="justify" widths="fit" wrap="down">
                            <Button type="tertiary" size="medium" onClick={removeRecurrence}>
                                Remove recurrence
                            </Button>
                            <Row>
                                <Button type="secondary" size="medium" onClick={cancelRepeatModal}>
                                    Cancel
                                </Button>
                                <Button type="primary" size="medium" onClick={closeRepeatModal}>
                                    Done
                                </Button>
                            </Row>
                        </Row>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        </Column>
    );
};

export default LabeledRepeatSelect;
