import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"
import inputTokens from "@amzn/meridian-tokens/component/input"
import Theme, { Style } from "../theme"
import Select from "../select/index"
import { getTokenHelper } from "../../_utils/token"
import { memoizeByKeys } from "../../_utils/functional"
import isElementOf from "../../_utils/is-element-of"
import visuallyHiddenStyles from "../../_styles/visually-hidden"
import fieldsetResetStyles from "../../_styles/fieldset-reset"

const organizeInputs = inputs => [
  inputs.length > 1 ? inputs[0] : undefined,
  inputs.length > 1 ? inputs.slice(1, inputs.length - 1) : inputs,
  inputs.length > 1 ? inputs[inputs.length - 1] : undefined,
]

const isSelect = isElementOf(Select)

const legendStyles = css({
  ...visuallyHiddenStyles,
})

const getCustomInputTokens = memoizeByKeys(
  ({ inputBorderRadius, inputBorderWidth, inputBackgroundColor }) => ({
    inputBorderRadius,
    inputBorderWidth,
    inputBackgroundColorDefault: inputBackgroundColor,
  }),
  ["inputBorderRadius", "inputBorderWidth", "inputBackgroundColor"]
)

/* eslint-disable react/prop-types */
const CustomInput = props => {
  return <Theme tokens={getCustomInputTokens(props)}>{props.children}</Theme>
}
/* eslint-enable react/prop-types */

const rowStyles = memoizeByKeys(
  ({ direction, width, minWidth, maxWidth }) =>
    css(fieldsetResetStyles, {
      flexDirection: direction === "row" ? "row" : "column",
      width,
      minWidth,
      maxWidth,
      display: "flex",
      "& > *": {
        boxSizing: "border-box",
        margin: 0,
      },
      "& > *:not([aria-haspopup])": {
        // Expand inputs horizontally to fill the entire group. Don't apply this
        // to selects, which will squish to fit their labels by default.
        flex: direction === "row" ? "auto" : undefined,
        // Set a min width of 0 on inputs (not selects as identified by their
        // aria-haspopup attribute) to counteract the default flexbox behavior that
        // won't let a flex child shrink smaller than its contents. Doing this
        // allows inputs with long labels to shrink and truncate their labels.
        // Details on this flexbox behavior here:
        // https://stackoverflow.com/a/36247448/2747759
        minWidth: 0,
      },
      // If the only item in the group is a select then expand it to fill the
      // width of the group.
      "& > [aria-haspopup]:only-child": {
        flex: direction === "row" ? "auto" : undefined,
      },
    }),
  ["direction", "width", "minWidth", "maxWidth"]
)

const InputGroup = ({
  children,
  prefix,
  suffix,
  disabled,
  onChange,
  type,
  size,
  value,
  direction,
  width,
  minWidth,
  maxWidth,
  label,
}) => {
  const inputs = React.Children.toArray(children).map((child, index) => {
    return React.cloneElement(child, {
      ...child.props,
      type: type || child.props.type,
      value: (value && value[index]) || child.props.value,
      onChange: onChange
        ? v => {
            const nextValue = [...value]
            nextValue[index] = v
            onChange(nextValue)
          }
        : child.props.onChange,
      prefix: !index && direction !== "column" && prefix ? prefix : undefined,
      suffix:
        index + 1 === children.length && direction !== "column" && suffix
          ? suffix
          : null,
      disabled: disabled || child.props.disabled,
      size: size || child.props.size,
    })
  })
  const [firstInput, middleInputs, lastInput] = organizeInputs(inputs)
  return (
    <Style tokens={inputTokens} map={getTokenHelper("input")}>
      {t => {
        const {
          inputBorderRadius,
          inputBorderWidth,
          inputBackgroundColorDefault,
          inputEndcapBackgroundColor,
        } = t.tokens
        return (
          <fieldset
            className={rowStyles({ direction, width, minWidth, maxWidth })}
          >
            <legend className={legendStyles}>{label}</legend>
            {firstInput && (
              <CustomInput
                inputBorderRadius={
                  direction === "row"
                    ? `${inputBorderRadius}px 0 0 ${inputBorderRadius}px`
                    : `${inputBorderRadius}px ${inputBorderRadius}px 0 0`
                }
                inputBorderWidth={
                  direction === "row"
                    ? `${inputBorderWidth}px 0 ${inputBorderWidth}px ${inputBorderWidth}px`
                    : `${inputBorderWidth}px ${inputBorderWidth}px 0 ${inputBorderWidth}px `
                }
                inputBackgroundColor={
                  isSelect(firstInput) && direction === "row"
                    ? inputEndcapBackgroundColor
                    : inputBackgroundColorDefault
                }
              >
                {firstInput}
              </CustomInput>
            )}
            {middleInputs.length > 0 &&
              middleInputs.map((middleInput, index) => (
                <CustomInput
                  inputBorderRadius={
                    inputs.length === 1 ? inputBorderRadius : 0
                  }
                  inputBorderWidth={
                    inputs.length === 1
                      ? inputBorderWidth
                      : direction === "row"
                      ? `${inputBorderWidth}px 0 ${inputBorderWidth}px ${inputBorderWidth}px`
                      : `${inputBorderWidth}px ${inputBorderWidth}px 0 ${inputBorderWidth}px`
                  }
                  inputBackgroundColor={
                    isSelect(middleInput) &&
                    inputs.length > 1 &&
                    direction === "row"
                      ? inputEndcapBackgroundColor
                      : inputBackgroundColorDefault
                  }
                  key={index}
                >
                  {middleInput}
                </CustomInput>
              ))}
            {lastInput && (
              <CustomInput
                inputBorderRadius={
                  direction === "row"
                    ? `0 ${inputBorderRadius}px ${inputBorderRadius}px 0`
                    : `0 0 ${inputBorderRadius}px ${inputBorderRadius}px`
                }
                inputBorderWidth={inputBorderWidth}
                inputBackgroundColor={
                  isSelect(lastInput) && direction === "row"
                    ? inputEndcapBackgroundColor
                    : inputBackgroundColorDefault
                }
              >
                {lastInput}
              </CustomInput>
            )}
          </fieldset>
        )
      }}
    </Style>
  )
}

InputGroup.propTypes = {
  /**
   * A set of Input components is expected here. Accepts both Inputs and Selects.
   */
  children: PropTypes.node.isRequired,
  /**
   * Determines whether the input groups display as a row or column.
   */
  direction: PropTypes.oneOf(["row", "column"]),
  /**
   * This disables interaction with all the components in the control group
   * and applies special visual styles to indicate that they are not
   * interactive.
   */
  disabled: PropTypes.bool,
  /**
   * An accessible label for the component. This will not be visible in the UI, but
   * will be read by screen readers.
   *
   * See docs for the [aria-label attribute](https://www.w3.org/TR/wai-aria/#aria-label)
   * for details on appropriate usage.
   *
   * @since 5.x*
   */
  label: PropTypes.string,
  /**
   * Set the maximum width of the input group using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   *
   * @since 4.x
   */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the minimum width of the input group using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   *
   * @since 4.x
   */
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * A prefix that will add a STATIC prefix endcap to the first input in the
   * group. Do not use this prop if you wish to create a selectable prefix. To
   * create a selectable prefix, pass in a `<Select/>` component with valid
   * `<SelectOptions>` as the first child of the `<InputGroup>`.
   * NOTE: this only works in InputGroups with direction set to row.
   */
  prefix: PropTypes.string,
  /**
   * Sets the size of all inputs in the group using a preset.
   *
   * This overrides any individual `size` props set on the inputs in the group.
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  /**
   * A suffix that will add a STATIC suffix endcap to the first input in the
   * group. Do not use this prop if you wish to create a selectable suffix. To
   * create a selectable suffix, pass in a `<Select/>` component with valid
   * `<SelectOptions>` as the last child of the `<InputGroup>`.
   * NOTE: this only works in InputGroups with direction set to row.
   */
  suffix: PropTypes.string,
  /**
   * Will set all inputs to the passed type and override types set on
   * individual child inputs
   */
  type: PropTypes.oneOf([
    "text",
    "number",
    "search",
    "tel",
    "url",
    "email",
    "password",
  ]),
  /**
   * An array of the current values of all the inputs in the group.
   *
   * Note that this prop is not required, but if you do not use it you must
   * use the `value` props on the individual inputs within the group.
   */
  value: PropTypes.array,
  /**
   * Set the width of the input group using a number (which will be treated
   * as pixels) or a string containing any valid CSS dimension.
   *
   * @since 4.x
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * A function that will be called when the user attempts to change the value
   * of one of the inputs in the group. An array of all the new values of the
   * inputs in the group will be passed as the first argument to this function.
   *
   * Meridian uses [controlled components](https://reactjs.org/docs/forms.html#controlled-components),
   * so you must track the values provided to this function somewhere in state
   * and pass them back via the `value` prop in order to make this component
   * interactive.
   *
   * Note that this prop is not required, but if you do not use it you must
   * use the `onChange` props on the individual inputs within the group.
   */
  onChange: PropTypes.func,
}

InputGroup.defaultProps = {
  direction: "row",
}

export default InputGroup
