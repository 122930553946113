import React, { useEffect } from "react";

import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import closeTokens from "@amzn/meridian-tokens/base/icon/close-small";

import HeadingPattern from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";

import { onSpaceOrEnterKeyDown } from "../shared-utils";

const MeetingsModalHeader = (props) => {
    const modalId = props.modalId;
    const modalTitle = props.modalTitle;
    const showCustomCloseButton = props.showCustomCloseButton; // if true, render custom "X" button
    const onCloseModal = props.onCloseModal; // function to close the modal

    const onKeyDown = (event) => {
        onSpaceOrEnterKeyDown(event, onCloseModal);
    };

    // Overwrite Meridian modal styling to match meetings header background
    useEffect(() => {
        let modal = document.getElementById(modalId);

        if (modal && !showCustomCloseButton) {
            modal.parentElement.style.paddingRight = "0px";

            let closeButton = modal.parentElement.parentElement.querySelector('[aria-label="Close"]');
            if (closeButton) {
                closeButton.style.color = "white";
            }
        }
    });

    return (
        <div style={{
            height: "56px",
            padding: "0",
            backgroundImage: `url(${HeadingPattern})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "rgb(26, 69, 78)",
            backgroundPosition: "-50px -90px",
            width: "100%"
        }}>
            {showCustomCloseButton &&
                <span
                    aria-label="Close"
                    role="button"
                    id="close"
                    tabIndex="0"
                    style={{
                        float: "right",
                        display: "inline-block",
                        padding: "10px 10px",
                        cursor: "pointer",
                        color: "#FFFFFF"
                    }}
                    onKeyDown={onKeyDown}
                    onClick={onCloseModal}
                >
                    <Icon tokens={closeTokens} />
                </span>
            }
            <Column spacingInset="medium">
                <Heading type="h300" level={2} color="inverted">
                    {modalTitle}
                </Heading>
            </Column>
        </div>
    );
};

export default MeetingsModalHeader;