/**
 * TODO: This limited locale information is included because of the complexity
 * of internationalizing date-related components. In general we should not
 * include locale-specific data in this component library until we have a better
 * idea of what a holistic internationalization API would look like. See:
 * https://sim.amazon.com/issues/b746b914-6eb7-482a-b3aa-5281090ddf16
 *
 * NOTE: This file uses the commonjs module format as it's also imported by
 * scripts/build-typescript-definitions.
 *
 * NOTE: Regarding lowercase "yyyy" and "dd" vs "MM" read this:
 * https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md#popular-mistakes
 *
 * NOTE: For `weekStart`, 0 is Sunday, 1 is Monday, etc.
 *
 * NOTE: The `compactDayOfWeekFormat` is primarily only needed for the DatePicker
 * mini calendar. Accepted formats are `long`, `short`, and `narrow`.
 * In English those correspond to Monday, Mon, and M.
 * Use `narrow` only when needed, otherwise use `short`. Some locales use the
 * same word for long and short, which can break the DatePicker mini calendar
 * Eg: The Burmese/Myanmar locale (my-MM) has dates that are too long using the
 * short format. Monday in Burmese is "တနင်္လာနေ့" for both long and short format.
 * The narrow format of "တနေ့" fits better in the DatePicker's calendar.
 *
 */

/**
 * All supported date locales.
 */
const locales = {
  "ar-AE": {
    format: "dd/MM/yyyy",
    weekStart: 0,
    compactDayOfWeekFormat: "short",
  },
  "de-DE": {
    format: "dd.MM.yyyy",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "en-CA": {
    format: "yyyy-MM-dd",
    weekStart: 0,
    compactDayOfWeekFormat: "short",
  },
  "en-GB": {
    format: "dd/MM/yyyy",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "en-IN": {
    format: "dd-MM-yyyy",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "en-US": {
    format: "MM/dd/yyyy",
    weekStart: 0,
    compactDayOfWeekFormat: "short",
  },
  "es-ES": {
    format: "dd/MM/yyyy",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "es-MX": {
    format: "dd/MM/yyyy",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "fr-CA": {
    format: "yyyy-MM-dd",
    weekStart: 0,
    compactDayOfWeekFormat: "short",
  },
  "fr-FR": {
    format: "dd/MM/yyyy",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "it-IT": {
    format: "dd/MM/yyyy",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "ja-JP": {
    format: "yyyy年MM月dd日",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "my-MM": {
    format: "dd-MM-yyyy",
    weekStart: 0,
    compactDayOfWeekFormat: "narrow",
  },
  "pl-PL": {
    format: "dd.MM.yyyy",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "so-SO": {
    format: "dd/MM/yyyy",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
  "zh-CN": {
    format: "yyyy年MM月dd日",
    weekStart: 1,
    compactDayOfWeekFormat: "short",
  },
}

/**
 * A fallback locale for when an unsupported locale is requested. Note that the
 * locale should still be *valid*, otherwise the Intl APIs we use will fail.
 *
 * The data here was chosen arbitrarily. For the format, it does seem fairly
 * universal that if the four digit year is shown first then it's always
 * year-then-month-then-day. This seems less ambiguous than falling back to,
 * say, en-US which uses the terrible day-then-month-then-year format. For
 * weekStart... 0 comes before 1? 🤷‍♂️
 */
const fallbackLocale = {
  format: "yyyy-MM-dd",
  weekStart: 0,
  compactDayOfWeekFormat: "short",
}

/**
 * Fetch the requested locale, falling back to en-US if the locale isn't
 * supported or isn't valid.
 */
const getDateLocale = locale => locales[locale] || fallbackLocale

// This module is written using commonjs so we can access it in our build
// scripts (specifically for generating TypeScript definitions). This __esModule
// flag tells webpack et al to treat this like an ES module even though it's
// written in commonjs.
module.exports.__esModule = { value: true }
module.exports.default = locales
module.exports.getDateLocale = getDateLocale
