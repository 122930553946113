import { call, put, takeEvery } from "redux-saga/effects";
import * as Actions from "../actions/action-types";
import { findMeetings } from "../../api/apig/ras";
import { isErrorResponse } from "../../api/apig/apig-utils";
import { getErrorToast } from "../../shared/toasts/toast-utils";
import { showToast } from "../../shared/toasts/actions";

import format from "date-fns/format";

export function* watchFindMeetings() {
    yield takeEvery(Actions.FIND_MEETINGS, findMeetingsApi);
}

export function* findMeetingsApi(action) {
    const response = yield call(
        findMeetings,
        action.requestedOnBehalfOf,
        action.startTime,
        action.endTime,
        action.maxResults
    );
    const parsedResponse = JSON.parse(response);
    if (isErrorResponse(parsedResponse)) {
        const user = action.requestedOnBehalfOf.split("@")[0];
        const startDate = format(new Date(action.startTime), "yyyy-MM-dd");
        const endDate = format(new Date(action.startTime), "yyyy-MM-dd");
        const errorMsg = startDate === endDate ?
            `An error occurred when retrieving meetings on ${startDate} for ${user}'s calendar.` :
            `An error occurred when retrieving meetings from ${startDate} to ${endDate} for ${user}'s calendar.`;
        const toast = getErrorToast(errorMsg);
        yield put(showToast(toast));
        return;
    }
    const meetingList = parsedResponse.meetings;
    yield put({
        type: Actions.SET_MEETING_LIST,
        meetingList
    });
}

const sagas = [
    watchFindMeetings()
];

export default sagas;
