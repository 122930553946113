import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import * as Actions from "../actions/action-types";
import { addFavorite, updateFavorites } from "../actions";
import { saveUserFavorites } from "../../../api/apig/ams";
import { favoritesEqual } from "../favorites-utils";
import { getFavorites } from "../../../../sagas/selector";
import { isErrorResponse } from "../../../api/apig/apig-utils";
import { getErrorToast } from "../../toasts/toast-utils";
import { showToast } from "../../toasts/actions";
import { FAVORITE_TYPE, UPDATE_USER_FAVORITES_DEBOUNCE_DELAY } from "../favorites-constants";
import { getGroup, getPerson } from "../../../api/apig/ras";

export function* updateUserFavoritesApi(action) {
    // Debounce, wait SAVE_USER_FAVORITES_DEBOUNCE_DELAY ms before sending a request
    yield delay(UPDATE_USER_FAVORITES_DEBOUNCE_DELAY);

    const response = yield call(saveUserFavorites, action.alias, action.favorites);
    if (isErrorResponse(JSON.parse(response))) {
        const toast = getErrorToast(`An error occurred when updating favorites.`);
        yield put(showToast(toast));
    }
}

export function* watchUpdateUserFavorites() {
    yield takeLatest(Actions.UPDATE_FAVORITES, updateUserFavoritesApi);
}

export function* addFavoriteApi(action) {
    const favorites = yield select(getFavorites) || [];
    if (!favorites.find((favorite) => favoritesEqual(favorite, action.favorite))) {
        favorites.push(action.favorite);
    }
    yield put(updateFavorites(JSON.stringify(favorites), action.alias));
}

export function* watchAddFavorite() {
    yield takeEvery(Actions.ADD_FAVORITE, addFavoriteApi);
}

export function* addFavoriteByEmailApi(action) {
    console.log("Calling addFavoriteByEmailApi in sagas for: " + action.email);
    const [alias, emailTail] = action.email.split("@");
    if (emailTail?.length > 0 && emailTail.includes("amazon")) {
        let response;

        response = yield call(getPerson, alias);
        const person = JSON.parse(response);
        if (!isErrorResponse(person)) {
            yield put(addFavorite({
                type: FAVORITE_TYPE.USER,
                value: person.username,
                name: person.name,
            }, action.alias));
            return;
        }

        response = yield call(getGroup, alias);
        const group = JSON.parse(response);
        if (!isErrorResponse(group) && group.name !== undefined) {
            yield put(addFavorite({
                type: FAVORITE_TYPE.GROUP,
                value: group.name,
                name: group.name + "@",
            }, action.alias));
            return;
        }
    }

    yield put(addFavorite({
        type: FAVORITE_TYPE.EXTERNAL_EMAIL,
        value: action.email,
        name: action.email,
    }, action.alias));
}

export function* watchAddFavoriteByEmail() {
    yield takeEvery(Actions.ADD_FAVORITE_BY_EMAIL, addFavoriteByEmailApi);
}

export function* removeFavoriteApi(action) {
    const favorites = (yield select(getFavorites) || []).filter((favorite) => !favoritesEqual(favorite, action.favorite));
    yield put(updateFavorites(JSON.stringify(favorites), action.alias));
}

export function* watchRemoveFavorite() {
    yield takeEvery(Actions.REMOVE_FAVORITE, removeFavoriteApi);
}

export function* removeFavoriteByAliasApi(action) {
    const favorites = (yield select(getFavorites) || []).filter((favorite) => favorite.value !== action.favoriteAlias);
    yield put(updateFavorites(JSON.stringify(favorites), action.alias));
}

export function* watchRemoveFavoriteByAlias() {
    yield takeEvery(Actions.REMOVE_FAVORITE_BY_ALIAS, removeFavoriteByAliasApi);
}

const sagas = [
    watchAddFavorite(),
    watchRemoveFavorite(),
    watchUpdateUserFavorites(),
    watchRemoveFavoriteByAlias(),
    watchAddFavoriteByEmail(),
];

export default sagas;