import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import {
    getSuggestions as getMeetingSuggestionsSelector,
    getBuildings,
    getCachedAvailabilitySuggestions,
    getChimeUniqueIds,
    getChimeUser,
    getIdentity,
    getSettingsBuilding,
    getSettingsChimeEmail,
    getSettingsDateFormat,
    getSettingsEnableAutoCallRoomBooking,
    getSettingsFloor,
    getSettingsPreferencesLoaded,
    getSettingsPrimaryTimezone,
    getSettingsTimeFormat,
    getSettingsUsePersonalChimePin,
    getTimezonesList,
    getMeetingDetails,
    getSettingsMinimumSeats,
    getSettingsCamera,
    getSettingsDisplay,
    getSettingsNoRestricted,
    getSettingsNoManaged,
    getSettingsAvailableOnly,
    getRefreshCount,
    getMeetingListRoomBooking,
    getCreatingMeetingState
} from "../../../sagas/selector";

import {
    createMeeting,
    getCachedResourceAvailability,
    getSuggestions
} from "../../shared/actions";
import * as ChimeActions from "../../shared/chime/actions";
import { SET_MEETING_DETAILS, SET_MEETINGS_ROOM_BOOKING } from "../../shared/actions/action-types";

import { loadFloors } from "../../shared/locations/actions";
import {
    incrementCount,
    getMeetingsRoomBooking
} from "../../shared/actions";
import {
    postRoomBookingMetric
} from "../../shared/metrics/actions";
import {
    showToast,
} from "../../shared/toasts/actions";
import { switchWorkflow } from "../../shared/workflow/actions/index";

import {
    buildCreateMeetingRequest,
    buildIdFromTime,
    createReservationTimes,
    filterResults,
    formDetailsLoaded,
    isFloorExistInTheBuilding
} from "../utils";
import { generateNewMeetingId } from "../../shared/meeting-utils";
import { getFormattedDateTime } from "../../shared/time-utils";
import { getUserDefaultLocation } from "../../shared/locations/locations-utils";
import { getUrlParameters } from "../../shared/permalink/permalink-utils";
import { getWarningToastWithComponent } from "../../shared/toasts/toast-utils";

import Heading from "@amzn/meridian/heading";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Loader from "@amzn/meridian/loader";
import Responsive from "@amzn/meridian/responsive";

import RoomFinderFormDetails from "./room-finder-form-details";
import RoomFinderResults from "./room-finder-results";
import RoomFinderModal from "./room-finder-modal";
import MeetingDetails from "../../shared/containers/meeting-details";
import PermalinkButton from "../../shared/permalink/components/permalink-button";

import { zonedTimeToUtc } from "date-fns-tz";
import { parse, isValid } from "date-fns";
import HeaderBackground from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";
import Toast from "../../shared/toasts/containers/toast-container";
import AlertsContainer from "../../shared/containers/alerts-container";
import updateBridgeInfoInBody from "../../shared/manage-bridge-info";
import {
    LOCATION_FEATURE,
    ERRORS as LOCATION_ERRORS,
} from "../../shared/locations/locations-constants";
import { ERRORS as TIMEZONE_ERRORS } from "../../shared/timezones/timezones-constants";
import { ALERTS, ALL, ONE_MINUTE } from "../../shared/shared-constants";
import { DURATION } from "../../meeting-quick/meeting-quick-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";
import { TOAST_COMPONENT, TIMEOUT } from "../../shared/toasts/toast-constants";

const RoomFinderContainer = () => {
    const dispatch = useDispatch();
    const identity = useSelector(getIdentity);
    const buildingList = useSelector(getBuildings);
    const timezones = useSelector(getTimezonesList);
    const { email, site, name, username } = useSelector(getIdentity);
    const roomFinderResults = useSelector(getCachedAvailabilitySuggestions);
    const chimeUniqueIds = useSelector(getChimeUniqueIds);
    const chimeUser = useSelector(getChimeUser);
    const primaryTimezone = useSelector(getSettingsPrimaryTimezone);
    const enableAutoCallRoomBooking = useSelector(getSettingsEnableAutoCallRoomBooking);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const dateFormat = useSelector(getSettingsDateFormat);
    const building = useSelector(getSettingsBuilding);
    const floor = useSelector(getSettingsFloor);
    const minimumSeats = useSelector(getSettingsMinimumSeats);
    const camera = useSelector(getSettingsCamera);
    const display = useSelector(getSettingsDisplay);
    const noRestricted = useSelector(getSettingsNoRestricted);
    const noManaged = useSelector(getSettingsNoManaged);
    const availableOnly = useSelector(getSettingsAvailableOnly);
    const usePersonalChimePin = useSelector(getSettingsUsePersonalChimePin);
    const chimeEmail = useSelector(getSettingsChimeEmail);
    const userPreferencesLoaded = useSelector(getSettingsPreferencesLoaded);
    const meetingSuggestions = useSelector(getMeetingSuggestionsSelector);
    const meetingList = useSelector(getMeetingListRoomBooking);
    const refreshCount = useSelector(getRefreshCount);
    const isCreatingMeeting = useSelector(getCreatingMeetingState);
    const queryParams = getUrlParameters(WORKFLOWS.ROOM_BOOKING.HREF);
    const [timezoneValue, setTimezoneValue] = useState(undefined); // useEffect will set initial timezoneValue
    const currentEpoch = Date.now();
    const initialStartTime = getFormattedDateTime(currentEpoch, timezoneValue, "yyyy-MM-dd");
    const initialStartTimeStamp = getFormattedDateTime(currentEpoch, timezoneValue, "HH") + ":00:00";
    const [startTime, setStartTime] = useState(initialStartTime);
    const [startTimeToShow, setStartTimeToShow] = useState(initialStartTime);
    const [startTimeStamp, setStartTimeStamp] = useState(initialStartTimeStamp);
    const [startTimeStampToShow, setStartTimeStampToShow] = useState(initialStartTimeStamp);
    const [selectedBuilding, setSelectedBuilding] = useState(undefined); // useEffect will set initial selectedBuilding
    const [selectedFloor, setSelectedFloor] = useState(undefined); // useEffect will set initial selectedFloor
    const [selectedRoom, setSelectedRoom] = useState("");
    const [selectedStartTime, setSelectedStartTime] = useState("");
    const [selectedEndTime, setSelectedEndTime] = useState("");
    const [selectedDuration, setSelectedDuration] = useState(DURATION.THIRTY_MINUTES.toString());
    const [minimalSeats, setMinimalSeats] = useState(undefined);
    const [cameraChecked, setCameraChecked] = useState(undefined);
    const [displayChecked, setDisplayChecked] = useState(undefined);
    const [noRestrictChecked, setNoRestrictChecked] = useState(undefined);
    const [noManagedChecked, setNoManagedChecked] = useState(undefined);
    const [availableOnlyChecked, setAvailableOnlyChecked] = useState(undefined);
    const [startTimeFilter, setStartTimeFilter] = useState(undefined);
    const [endTimeFilter, setEndTimeFilter] = useState(undefined);
    const [searchNearby, setSearchNearby] = useState(false);
    const [nearbyResults, setNearbyResults] = useState(undefined);

    const [selectedEvent, setSelectedEvent] = useState();
    const [selectedTileId, setSelectedTileId] = useState("");
    const [open, setOpen] = useState(false);
    const [defaultRoomListLoaded, setDefaultRoomListLoaded] = useState(false);
    const [defaultFloorListLoaded, setDefaultFloorListLoaded] = useState(false);
    const onClickButton = useCallback(() => setOpen(true), []);

    const [alerts, setAlerts] = useState([]);
    const [generatedChimeBridge, setGeneratedChimeBridge] = useState();
    const [personalChimeBridge, setPersonalChimeBridge] = useState();
    const [selectedMeeting, setSelectedMeeting] = useState(false);
    const [selectedMeetingID, setSelectedMeetingID] = useState("");
    const [queryParameters, setQueryParameters] = useState({});
    const [isQueryParamsCheck, setIsQueryParamsCheck] = useState(true);
    const [displayInvalidPreferenceToast, setDisplayInvalidPreferenceToast] = useState(true); // display toast for invalid user preference, only show once on page load

    // Room finder modal loader
    const [isLastStepLoading, setIsLastStepLoading] = useState(false); // disable Reserve button

    const RoomFinderResultsRef = useRef();
    const lastTimeRangeQueried = useRef("");

    useEffect(() => {
        document.title = "Room booking - Amazon Meetings";
    }, []);

    const onShowToast = (toast) => dispatch(showToast(toast));

    const onLoadFloors = (buildingCode) => dispatch(loadFloors(buildingCode));

    const onClickReserveButton = (reservationStart, startTime, endTime, roomName, roomEmail, chimePin, autoCall) => {
        setOpen(false);
        const includeChimePin = chimePin && chimePin !== "none";
        let request = buildCreateMeetingRequest(createReservationTimes(reservationStart, startTime, timezoneValue), createReservationTimes(reservationStart, endTime, timezoneValue), roomName, roomEmail, timezoneValue, email, name, includeChimePin, autoCall);
        request.meeting.uniqueID = generateNewMeetingId(username);
        setSelectedStartTime("");
        setSelectedEndTime("");
        if (includeChimePin) {
            request.meeting.body.value = updateBridgeInfoInBody(request.meeting.body.value, {bridgeID: chimePin});
            // we only add generated chime pin to the require attendee list
            if (chimePin !== personalChimeBridge) {
                request.meeting.requiredAttendees.push({email: `pin+${chimePin}@chime.aws`});
            }
        }
        dispatch(incrementCount());
        dispatch(createMeeting(request, undefined, "Room booking", !!queryParams.selectedBuilding));
    };

    const onClose = useCallback(() => {
        setOpen(false);
        setSelectedRoom(undefined);
        setSelectedEvent(undefined);
        setSelectedStartTime("");
        setSelectedEndTime("");

        // Set focus to the tile the reservation modal opened from
        const selectedTile = document.getElementById(selectedTileId);
        selectedTile && selectedTile.focus();
        setSelectedTileId("");
    }, [selectedTileId]);

    const onCloseModal = useCallback(() => {
        setOpen(false);
        setSelectedRoom(undefined);
        setSelectedEvent(undefined);
        setSelectedMeeting(false);
        setSelectedMeetingID("");
        setSelectedStartTime("");
        setSelectedEndTime("");

        // Set focus to the tile the reservation modal opened from
        const selectedTile = document.getElementById(selectedTileId);
        selectedTile && selectedTile.focus();
        setSelectedTileId("");
    }, [selectedTileId]);

    const getBuildingInfo = useCallback((defaultBuilding) => {
        return buildingList.find((building) => building.value === defaultBuilding) || {};
    }, [buildingList]);

    const createCachedAvailabilityRequest = useCallback((buildingCode, floor, startTime, startTimeStamp, timezoneValue, roomList = []) => {
        if (alerts.length || startTime === undefined || startTimeStamp === undefined || timezoneValue === undefined) {
            return undefined;
        }
        let startTimeInMillis = (zonedTimeToUtc(`${startTime} 00:00:00`, timezoneValue)).getTime();
        const endTimeInMillis = (zonedTimeToUtc(`${startTime} 23:59:59`, timezoneValue)).getTime();
        startTimeInMillis += refreshCount * 1000;

        let rooms;

        if (roomList.length) {
            // If specific rooms have been passed in (e.g. suggested from nearby buildings), use them for the cached request
            rooms = roomList;
        } else {
            // Use the selected building and floor to determine rooms to query
            if (buildingCode === undefined || floor === undefined) {
                return undefined;
            }

            let buildingInfo = getBuildingInfo(buildingCode);
            if (floor === ALL) {
                rooms = buildingInfo.roomList;
                rooms.sort(function(room1, room2) {
                    return room1.floor - room2.floor;
                });
            } else {
                rooms = buildingInfo.roomList && buildingInfo.roomList.filter((room) => room.floor === floor);
            }
        }

        rooms = rooms.slice(0, 100);

        return {
            rooms: rooms,
            listOfMailboxes: rooms.map((room) => room.email),
            startTime: startTimeInMillis,
            endTime: endTimeInMillis
        }
    }, [alerts, getBuildingInfo, refreshCount]);

    const onRefresh = (building = selectedBuilding, floor = selectedFloor, startDay = startTime, startHour = startTimeStamp, timezone = timezoneValue) => {
        let suggestionRequest = createCachedAvailabilityRequest(building, floor, startDay, startHour, timezone);
        if (suggestionRequest === undefined) {
            return;
        }
        dispatch(getCachedResourceAvailability(suggestionRequest));
        setSelectedRoom(undefined);
        setSelectedEvent(undefined);
        setStartTimeToShow(startDay);
        setStartTimeStampToShow(startHour);
    };

    const onSelectRoomAndTime = (roomName, event) => {
        setSelectedTileId(buildIdFromTime(event.time, roomName));
        setSelectedRoom(roomName);
        setSelectedEvent(event);
        let selectedMeeting;
        if (event.location !== undefined) {
            selectedMeeting = meetingList?.find((meeting) => meeting.location === event.location && meeting.time.startTime === event.time.startTime && meeting.time.endTime === event.time.endTime);
        } else if (event.subject.replace(/\s+/g, '') === name.replace(/\s+/g, '')) {
            selectedMeeting = meetingList?.find((meeting) => meeting.time.startTime === event.time.startTime && meeting.time.endTime === event.time.endTime);
        }

        if (selectedMeeting !== undefined) {
            setSelectedMeeting(true);
            setSelectedMeetingID(selectedMeeting.entryID);
        }
        onClickButton();
    };

    const getNearbySuggestions = () => {
        let startTime = startTimeFilter;
        let endTime = endTimeFilter;
        let suggestionRequest = {
            "searchDateRange": {
                "startTime": startTime,
                "endTime":endTime
            },
            "idealTime": startTime,
            "requestedOnBehalfOf": email,
            "wishlistRoomList": [{
                "wishlistRoomAttributes": {
                        "minCapacity": minimalSeats,
                        "buildingCode": selectedBuilding,
                        "restrictedAllowed": !noRestrictChecked,
                        "hiddenAllowed": false,
                        "thisBuildingOnly": false
                },
                "wishlistParticipantList": [{
                    "exchangeMailbox": email
                }]
            }]
        }
        if (displayChecked) {
            suggestionRequest["wishlistRoomList"][0]["wishlistRoomAttributes"]["resourceList"] = [LOCATION_FEATURE.DISPLAY];
        }
        if (cameraChecked) {
            suggestionRequest["wishlistRoomList"][0]["wishlistRoomAttributes"]["resourceList"] = [LOCATION_FEATURE.CAMERA, LOCATION_FEATURE.DISPLAY];
        }
        if (selectedFloor !== ALL) {
            suggestionRequest["wishlistRoomList"][0]["wishlistRoomAttributes"]["floor"] = selectedFloor;
        }
        dispatch(getSuggestions(suggestionRequest));
    };

    // Load selectedBuilding and selectedFloor for building and floor picker. Will also load initial results once all inputs are valid
    useEffect(() => {
        // We will want to use email here to check if the user's identity data loaded
        // Instead of site since the user's site might be empty and undefined
        if (email !== undefined && buildingList.length > 0) {
            let { userDefaultBuilding, userDefaultFloor } = getUserDefaultLocation(site, buildingList);

            // Settings Building & Floor value should be ready when the page after loading
            // Set userDefaultBuilding and userDefaultFloor according to Permalink parameters if it is opened with permalink
            // Since we are loading the preferences at the startup.js
            // Will need the undefined to check since floor can possibily be 0
            if (queryParams.selectedBuilding !== undefined && queryParams.selectedFloor !== undefined && buildingList.some((building) => building.value.toUpperCase() === queryParams.selectedBuilding.toUpperCase())) {
                userDefaultBuilding = queryParams.selectedBuilding.toUpperCase();
                if (queryParams.selectedFloor.toUpperCase() === ALL) {
                    userDefaultFloor = ALL;
                } else {
                    userDefaultFloor = parseInt(queryParams.selectedFloor) || queryParams.selectedFloor;
                }
            } else if (building !== undefined && floor !== undefined) {
                if (getBuildingInfo(building).value) {
                    // if user's preferred building is valid, update building and floor
                    userDefaultBuilding = building;
                    userDefaultFloor = floor;
                } else if (displayInvalidPreferenceToast) {
                    // if the user's building is invalid, display toast and leave building as default instead
                    const invalidBuildingToast = getWarningToastWithComponent();

                    invalidBuildingToast.toastActionProps.componentName = TOAST_COMPONENT.FUNCTION;
                    invalidBuildingToast.toastActionProps.text = `${building} may no longer be in use. Set your favorite building and floor in`
                    invalidBuildingToast.toastActionProps.functionText = "Preferences.";
                    invalidBuildingToast.toastActionProps.function = () => {
                        // redirect to preferences page
                        dispatch(push(`${WORKFLOWS.SETTINGS.HREF.substring(1)}?tab=ROOM_BOOKING`)); // substring to remove leading #, add query parameter for room booking tab
                        dispatch(switchWorkflow(WORKFLOWS.SETTINGS));
                    };
                    invalidBuildingToast.toastActionProps.close = true;
                    invalidBuildingToast.toastTimeout = TIMEOUT.NEVER;

                    onShowToast(invalidBuildingToast);
                    setDisplayInvalidPreferenceToast(false);
                }
            }

            let buildingInfo = getBuildingInfo(userDefaultBuilding);

            // When open with permalink, need to call onLoadFloors to load the roomList
            if (isQueryParamsCheck && buildingInfo.value && (buildingInfo.value === queryParams?.selectedBuilding?.toUpperCase())) {
                onLoadFloors(queryParams.selectedBuilding.toUpperCase());
                setIsQueryParamsCheck(false);
            // Load floorlist for default building
            } else if (defaultFloorListLoaded === false && buildingInfo.value === userDefaultBuilding) {
                onLoadFloors(userDefaultBuilding);
                setDefaultFloorListLoaded(true);
            }

            // Will need to check if user's default floor has the conference room
            if (buildingInfo.floorList?.length > 0 && (!isFloorExistInTheBuilding(buildingInfo, userDefaultFloor) && userDefaultFloor !== ALL)) {
                // if the user's floor is invalid, display toast and use first available floor
                if (displayInvalidPreferenceToast && userDefaultFloor !== LOCATION_ERRORS.INVALID_FLOOR) {
                    const invalidFloorToast = getWarningToastWithComponent();

                    invalidFloorToast.toastActionProps.componentName = TOAST_COMPONENT.FUNCTION;
                    invalidFloorToast.toastActionProps.text = `Floor ${userDefaultFloor} may no longer be in use. Set your favorite floor in`
                    invalidFloorToast.toastActionProps.functionText = "Preferences.";
                    invalidFloorToast.toastActionProps.function = () => {
                        // redirect to preferences page
                        dispatch(push(`${WORKFLOWS.SETTINGS.HREF.substring(1)}?tab=ROOM_BOOKING`)); // substring to remove leading #, add query parameter for room booking tab
                        dispatch(switchWorkflow(WORKFLOWS.SETTINGS));
                    };
                    invalidFloorToast.toastActionProps.close = true;
                    invalidFloorToast.toastTimeout = TIMEOUT.NEVER;

                    onShowToast(invalidFloorToast);
                    setDisplayInvalidPreferenceToast(false);
                }

                userDefaultFloor = buildingInfo.floorList[0];
            } else if (buildingInfo.floorList?.length === 0) {
                userDefaultFloor = LOCATION_ERRORS.BUILDING_WITHOUT_ROOMS;
            } else if (buildingInfo.floorList && buildingInfo.floorList === LOCATION_ERRORS.INVALID_FLOOR_LIST) {
                userDefaultFloor = LOCATION_ERRORS.INVALID_FLOOR_LIST;
            }

            // Will set the value when the selected building not initialized
            // Or the building selected as the userDefaultbuilding and the selected floor not existed on the selected building floor list
            if (selectedBuilding === undefined || (selectedBuilding === userDefaultBuilding && (!isFloorExistInTheBuilding(buildingInfo, selectedFloor) && selectedFloor !== ALL))) {
                setSelectedBuilding(userDefaultBuilding);
                setSelectedFloor(userDefaultFloor);
            }

            if (buildingInfo.roomList && userPreferencesLoaded && defaultRoomListLoaded === false) {
                let suggestionRequest = createCachedAvailabilityRequest(userDefaultBuilding, userDefaultFloor, startTime, startTimeStamp, timezoneValue);
                if (suggestionRequest) {
                    dispatch(getCachedResourceAvailability(suggestionRequest));
                    setDefaultRoomListLoaded(true);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, building, floor, buildingList, userPreferencesLoaded, dispatch, createCachedAvailabilityRequest, defaultRoomListLoaded, getBuildingInfo, timezoneValue, startTime, startTimeStamp, queryParams.selectedBuilding, queryParams.selectedFloor, isQueryParamsCheck]);

    // Load primaryBrowserTimezoneId for timezone picker and meeting request only for initial timezoneValue
    // Updates start time and calendar to show current time according to initial primary browser timezone
    useEffect(() => {
        if (!timezoneValue) {
            setTimezoneValue(primaryTimezone);

            const newCurrentEpoch = Date.now();
            const newStartTime = getFormattedDateTime(newCurrentEpoch, primaryTimezone, "yyyy-MM-dd");
            const newStartTimeStamp = getFormattedDateTime(newCurrentEpoch, primaryTimezone, "HH") + ":00:00";
            setStartTime(newStartTime);
            setStartTimeToShow(newStartTime);
            setStartTimeStamp(newStartTimeStamp);
            setStartTimeStampToShow(newStartTimeStamp);
        }
    }, [timezoneValue, primaryTimezone]);

    // Load minimumSeats, camera, display, noRestricted, noManaged from settings
    useEffect(() => {
        if (minimalSeats === undefined) {
            setMinimalSeats(minimumSeats !== undefined ? minimumSeats : 1);
        }
        if (cameraChecked  === undefined) {
            setCameraChecked(camera !== undefined ? camera : false);
        }
        if (displayChecked  === undefined) {
            setDisplayChecked(display !== undefined ? display : false);
        }
        if (noRestrictChecked  === undefined) {
            setNoRestrictChecked(noRestricted !== undefined ? noRestricted : false);
        }
        if (noManagedChecked  === undefined) {
            setNoManagedChecked(noManaged !== undefined ? noManaged : false);
        }
        if (availableOnlyChecked  === undefined) {
            setAvailableOnlyChecked(availableOnly !== undefined ? availableOnly : false);
        }
    }, [minimalSeats, cameraChecked, displayChecked, noRestrictChecked, noManagedChecked, minimumSeats, camera, display, noRestricted, noManaged, availableOnlyChecked, availableOnly])

    // Set start time and end time to filter booked rooms
    useEffect(() => {
        let startTimeFilter = (zonedTimeToUtc(`${startTime} ${startTimeStamp}`, timezoneValue)).getTime();
        let endTimeFilter = startTimeFilter + Number(selectedDuration) * ONE_MINUTE;
        setStartTimeFilter(startTimeFilter / 1000);
        setEndTimeFilter(endTimeFilter / 1000);
    }, [startTimeFilter, endTimeFilter, startTime, startTimeStamp, timezoneValue, selectedDuration])

    // Get meeting suggestions if click on search nearby floors and buildings
    useEffect(() => {
        if (searchNearby && meetingSuggestions?.length > 0 && meetingSuggestions[0].suggestionRoomList?.length > 0) {
            let rooms = meetingSuggestions[0].suggestionRoomList[0];
            let roomList = [];
            // Get rid of room ranking, only get the room information
            for (let i = 0; i < rooms.length; i++) {
                roomList.push(rooms[i].room);
            }
            roomList = roomList.filter((room) => {
                return (room.capacity >= minimalSeats)
                    && (!cameraChecked || room.resourceList.includes(LOCATION_FEATURE.CAMERA))
                    && (!displayChecked || room.resourceList.includes(LOCATION_FEATURE.DISPLAY))
                    && (!noRestrictChecked || !room.restricted)
                    && (!noManagedChecked || !room.managed);
            });

            if (!nearbyResults.length) {
                // create a cachedResourceAvailability request to retrieve availability for suggested rooms
                let cachedAvailabilityRequest = createCachedAvailabilityRequest(undefined, undefined, startTime, startTimeStamp, timezoneValue, filterResults(roomList));
                dispatch(getCachedResourceAvailability(cachedAvailabilityRequest));
            }

            setNearbyResults(filterResults(roomList));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchNearby, meetingSuggestions, cameraChecked, displayChecked, minimalSeats, noManagedChecked, noRestrictChecked])

    // Updates alerts state if there are invalid input fields
    useEffect(() => {
        let alerts = [];
        // Error alerts
        if (selectedBuilding === LOCATION_ERRORS.INVALID_BUILDING) {
            alerts.push(ALERTS.INVALID_BUILDING);
        }
        if (selectedFloor === LOCATION_ERRORS.INVALID_FLOOR) {
            alerts.push(ALERTS.INVALID_FLOOR);
        }
        if (selectedFloor === LOCATION_ERRORS.BUILDING_WITHOUT_ROOMS) {
            alerts.push(ALERTS.BUILDING_WITHOUT_ROOMS);
        }
        if (timezoneValue === TIMEZONE_ERRORS.INVALID_TIMEZONE) {
            alerts.push(ALERTS.INVALID_TIMEZONE);
        }
        setAlerts(alerts);
    }, [selectedBuilding, selectedFloor, setAlerts, timezoneValue]);

    // Generates a new chime pin if there isn't one
    useEffect(() => {
        if (identity && identity.username && identity.email && chimeUniqueIds && !chimeUniqueIds.AllocatedPins) {
            dispatch(ChimeActions.createChimePin({entities: [{email: chimeEmail ?? identity.email}]}));
        }

        // Update generated bridge info
        if (chimeUniqueIds && chimeUniqueIds.AllocatedPins) {
            setGeneratedChimeBridge(chimeUniqueIds.AllocatedPins && chimeUniqueIds.AllocatedPins[0].Pin);
        }
    }, [identity, chimeEmail, chimeUniqueIds, dispatch]);

    // Get personal chime pin if chimeUser is empty.
    useEffect(() => {
        if (identity && identity.username && identity.email && Object.keys(chimeUser).length === 0) {
            dispatch(ChimeActions.getUserByEmail({userEmail: chimeEmail ?? identity.email, username: identity.username}));
        }

        // Update personal bridge info
        if (chimeUser && chimeUser.PersonalPIN) {
            setPersonalChimeBridge(chimeUser.PersonalPIN);
        }
    }, [identity, chimeEmail, chimeUser, dispatch]);

    const header = useRef();

    // Begin new workflow content with focus on the <h1> heading
    useEffect(() => {
        if (header.current) {
            header.current.focus();
        }
    }, []);

    useEffect(() => {
        if (email !== undefined) {
            let startTimeInMillis = (zonedTimeToUtc(`${startTime} 00:00:00`, timezoneValue)).getTime();
            let endTimeInMillis = (zonedTimeToUtc(`${startTime} 23:59:59`, timezoneValue)).getTime();
            if (lastTimeRangeQueried.current !== `${email}-${startTimeInMillis}-${endTimeInMillis}` || meetingList === undefined) {
                dispatch(getMeetingsRoomBooking(email, startTimeInMillis, endTimeInMillis, -1));
                lastTimeRangeQueried.current = `${email}-${startTimeInMillis}-${endTimeInMillis}`;
            }
        }
    }, [startTime, email, dispatch, timezoneValue, meetingList]);

    // Permalink query parameters load for Room Booking Page
    useEffect(() => {
        if (identity && identity.username) {
            if (queryParams.startTime !== undefined && isValid(parse(queryParams.startTime, "yyyy-MM-dd", new Date()))) {
                dispatch(postRoomBookingMetric("permalink", "access"));
                setStartTime(queryParams.startTime);
                setStartTimeToShow(queryParams.startTime);
            }

            if (queryParams.startTimeStamp !== undefined && isValid(parse(queryParams.startTimeStamp, "HH:mm:ss", new Date()))) {
                const hour = queryParams.startTimeStamp.slice(0,2);
                setStartTimeStamp(hour + ":00:00");
                setStartTimeStampToShow(hour + ":00:00");
            }

            if (queryParams.timezoneValue !== undefined) {
                const timezone = timezones.find((timezone) => timezone.id === decodeURIComponent(queryParams.timezoneValue));
                if (timezone !== undefined) {
                    setTimezoneValue(queryParams.timezoneValue);
                }
            }

            const durationValues = ["15", "30", "45", "60", "90", "120"];
            if (queryParams.selectedDuration !== undefined && durationValues.includes(queryParams.selectedDuration)) {
                setSelectedDuration(queryParams.selectedDuration);
            }

            if (queryParams.minimalSeats !== undefined && !isNaN(parseInt(queryParams.minimalSeats))) {
                setMinimalSeats(Math.min(parseInt(queryParams.minimalSeats), 999));
            }

            const trueAndFalse = ["true", "false"];
            if (queryParams.displayChecked !== undefined && trueAndFalse.includes(queryParams.displayChecked.toLowerCase())) {
                setDisplayChecked(JSON.parse(queryParams.displayChecked));
            }
            if (queryParams.cameraChecked !== undefined && trueAndFalse.includes(queryParams.displayChecked.toLowerCase())) {
                if (JSON.parse(queryParams.cameraChecked) === true) {
                    setCameraChecked(JSON.parse(queryParams.cameraChecked));
                    setDisplayChecked(JSON.parse(queryParams.cameraChecked));
                } else {
                    setCameraChecked(JSON.parse(queryParams.cameraChecked));
                }
            }
            if (queryParams.noRestrictChecked !== undefined && trueAndFalse.includes(queryParams.noRestrictChecked.toLowerCase())) {
                setNoRestrictChecked(JSON.parse(queryParams.noRestrictChecked));
            }
            if (queryParams.noManagedChecked !== undefined && trueAndFalse.includes(queryParams.noManagedChecked.toLowerCase())) {
                setNoManagedChecked(JSON.parse(queryParams.noManagedChecked));
            }
            if (queryParams.availableOnlyChecked !== undefined && trueAndFalse.includes(queryParams.availableOnlyChecked.toLowerCase())) {
                setAvailableOnlyChecked(JSON.parse(queryParams.availableOnlyChecked));
            }
        }
    },[identity, queryParams.startTime, queryParams.startTimeStamp, queryParams.timezoneValue, queryParams.selectedDuration, queryParams.minimalSeats, queryParams.cameraChecked, queryParams.displayChecked, queryParams.noRestrictChecked, queryParams.noManagedChecked, queryParams.availableOnlyChecked, timezones, dispatch])


    // After calling getsuggestion, whenever change a filter, go back to search with room filters, not search nearby
    useEffect(() => {
        setSearchNearby(false);
        setNearbyResults([]);
    }, [selectedBuilding, selectedFloor, startTime, startTimeStamp, startTimeStampToShow, timezoneValue, selectedDuration, minimalSeats, cameraChecked, displayChecked, noRestrictChecked, noManagedChecked])

    return (
        formDetailsLoaded(site, buildingList, timezones) ?
            <Responsive query="min-width" props={{
                formContainerWidth: {
                    default: 360,
                    "600px": 600,
                    "810px": 810,
                    "900px": 900,
                    "1020px": 1020,
                    "1110px": 1110,
                    "1200px": "100%",
                },
                screenSizeBreakpoint: {
                    default: 360,
                    "1200px": 1200,
                    "900px": 900,
                    "600px": 600
                }
            }}>
            {(responsiveProps) => (
                <Column width= "100%" alignmentHorizontal={"center"} spacingInset={"none"} spacing={"none"}>
                    <div style={{
                        "backgroundImage": `url(${HeaderBackground})`,
                        "backgroundRepeat": "no-repeat",
                        "backgroundColor": "rgba(26, 69, 78)",
                        "backgroundPosition": "150px -90px",
                        "height": "50px",
                        "width": "100%",
                    }}>
                        <Row height={"100%"} alignmentVertical={"center"} spacingInset={"medium"} widths={["fill", "fit"]}>
                            <Heading color="inverted" level={1} type={"h300"} ref={header} tabIndex="-1">Room booking</Heading>
                            <PermalinkButton
                                path={WORKFLOWS.ROOM_BOOKING.HREF}
                                queryParameters={queryParameters}
                                page={"room booking"}
                            />
                        </Row>
                    </div>
                    <RoomFinderFormDetails
                        nearbyResults={nearbyResults}
                        building={building}
                        floor={floor}
                        buildingList={buildingList}
                        timezones={timezones}
                        timeFormat={timeFormat}
                        dateFormat={dateFormat}
                        site={site}
                        onRefresh={onRefresh}
                        selectedBuilding={selectedBuilding}
                        setSelectedBuilding={setSelectedBuilding}
                        selectedFloor={selectedFloor}
                        setSelectedFloor={setSelectedFloor}
                        startTime={startTime}
                        setStartTime={setStartTime}
                        startTimeStamp={startTimeStamp}
                        setStartTimeStamp={setStartTimeStamp}
                        setStartTimeStampToShow={setStartTimeStampToShow}
                        timezoneValue={timezoneValue}
                        setTimezoneValue={setTimezoneValue}
                        minimalSeats={minimalSeats}
                        setMinimalSeats={setMinimalSeats}
                        cameraChecked={cameraChecked}
                        setCameraChecked={setCameraChecked}
                        displayChecked={displayChecked}
                        setDisplayChecked={setDisplayChecked}
                        noRestrictChecked={noRestrictChecked}
                        setNoRestrictChecked={setNoRestrictChecked}
                        noManagedChecked={noManagedChecked}
                        setNoManagedChecked={setNoManagedChecked}
                        availableOnlyChecked={availableOnlyChecked}
                        setAvailableOnlyChecked={setAvailableOnlyChecked}
                        selectedDuration={selectedDuration}
                        setSelectedDuration={setSelectedDuration}
                        setSearchNearby={setSearchNearby}
                        setNearbyResults={setNearbyResults}
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                    />
                    {alerts.length !== 0 ?
                        <AlertsContainer alerts={alerts} spacingInset={"medium none"} width={responsiveProps.formContainerWidth} />
                        :
                        <div ref={RoomFinderResultsRef}>
                            {roomFinderResults.length === 0 ?
                                <Column alignmentHorizontal={"center"} spacingInset="medium small">
                                    <Heading level={2} type="h200" alignment={"center"}>
                                        Loading room availabilities...
                                    </Heading>
                                    <Loader type={"circular"} size={"large"} />
                                </Column>
                                :
                                <RoomFinderResults
                                    roomResults={filterResults(roomFinderResults)}
                                    getNearbySuggestions={getNearbySuggestions}
                                    nearbyResults={nearbyResults}
                                    selectedFloor={selectedFloor}
                                    startTime={startTimeToShow}
                                    setStartTime={setStartTimeToShow}
                                    startTimeStamp={startTimeStampToShow}
                                    setStartTimeStamp={setStartTimeStampToShow}
                                    setStartTimeInput={setStartTime}
                                    setStartTimeStampInput={setStartTimeStamp}
                                    timezoneValue={timezoneValue}
                                    timezones={timezones}
                                    minimalSeats={minimalSeats}
                                    cameraChecked={cameraChecked}
                                    displayChecked={displayChecked}
                                    noRestrictChecked={noRestrictChecked}
                                    noManagedChecked={noManagedChecked}
                                    startTimeFilter={startTimeFilter}
                                    endTimeFilter={endTimeFilter}
                                    availableOnlyChecked={availableOnlyChecked}
                                    setAvailableOnlyChecked={setAvailableOnlyChecked}
                                    searchNearby={searchNearby}
                                    setSearchNearby={setSearchNearby}
                                    onSelectRoomAndTime={onSelectRoomAndTime}
                                    onRefresh={onRefresh}
                                    meetingList={meetingList}
                                />
                            }
                        </div>
                    }
                    {selectedEvent ?
                        selectedMeeting ?
                            <MeetingDetails
                                open={selectedMeetingID !== ""}
                                entryID={selectedMeetingID}
                                onClose={onCloseModal}
                                saveAction={SET_MEETING_DETAILS}
                                selector={getMeetingDetails}
                                userEmail={email}
                                meetingListAction={SET_MEETINGS_ROOM_BOOKING}
                                meetingList={meetingList}
                                timeFormat={timeFormat}
                                page={"room booking"}
                                event={selectedEvent}
                                username={username}
                            />
                            :
                            <RoomFinderModal
                                identity={identity}
                                open={open}
                                setOpen={setOpen}
                                onClose={onClose}
                                onClickReserveButton={onClickReserveButton}
                                selectedRoom={selectedRoom}
                                selectedEvent={selectedEvent}
                                startTimeStamp={startTimeStampToShow}
                                startTime={startTimeToShow}
                                timezones={timezones}
                                timezoneValue={timezoneValue}
                                timeFormat={timeFormat}
                                selectedStartTime={selectedStartTime}
                                onSetSelectedStartTime={setSelectedStartTime}
                                selectedEndTime={selectedEndTime}
                                onSetSelectedEndTime={setSelectedEndTime}
                                screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                                usePersonalChimePin={usePersonalChimePin}
                                generatedChimeBridge={generatedChimeBridge}
                                personalChimeBridge={personalChimeBridge}
                                enableAutoCall={enableAutoCallRoomBooking}
                                durationSelected={selectedDuration}
                                meetingList={meetingList}
                                date={startTime}
                                timezone={timezoneValue}
                                email={email}
                                saveAction={SET_MEETING_DETAILS}
                                selector={getMeetingDetails}
                                isLastStepLoading={isLastStepLoading}
                                setIsLastStepLoading={setIsLastStepLoading}
                                isCreatingMeeting={isCreatingMeeting}
                            />
                        : null
                    }
                    <Toast />
                </Column>
            )}
            </Responsive>
            :
            <div style={{
                "backgroundImage": `url(${HeaderBackground})`,
                "backgroundRepeat": "no-repeat",
                "backgroundColor": "rgba(26, 69, 78)",
                "backgroundPosition": "150px -90px",
                "height": "50px",
                "width": "100%",
            }}>
                <Column height={"100%"} alignmentVertical={"center"} spacingInset={"medium"}>
                    <Heading color="inverted" level={1} type={"h300"} ref={header} tabIndex="-1">Room booking</Heading>
                </Column>
                <Column width={"100%"} height={"275px"} alignmentHorizontal={"center"} alignmentVertical={"center"}>
                    <Heading level={2} type="h200" alignment={"center"}>
                        Loading info...
                    </Heading>
                    <Loader type={"circular"} size={"large"} />
                </Column>
            </div>
    );
};

export default RoomFinderContainer;