import React from "react";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Tag from "@amzn/meridian/tag";
import Text from "@amzn/meridian/text";
import Tile from "@amzn/meridian/tile";
import Tooltip from "@amzn/meridian/tooltip";

import menuMeatball from "@amzn/meridian-tokens/base/icon/menu-meatball";
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil";

import { getPollStatusAccent, getCreationLabel } from "../poll-utils";
import { SCREEN_SIZE } from "../../shared/shared-constants";

const PollDashboardTile = (props) => {
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const organizerPreferredDate = props.organizerPreferredDate;
    const poll = props.poll;

    const showResultsPanel = props.showResultsPanel;
    const onOpenResults = props.onOpenResults;
    const onCloseResults = props.onCloseResults;
    const onRedirectUpdate = props.onRedirectUpdate;

    // Poll results and edit button
    const renderTileButtons = () => {
        return showResultsPanel ? (
            <Row alignmentHorizontal="center">
                <Tooltip
                    position="bottom"
                    title={<div>Close <b>poll results</b></div>}
                >
                    <Button
                        className="dashboard-tile-button"
                        type="icon"
                        size="small"
                        label="Close poll results"
                        onClick={onCloseResults}
                    >
                        <Icon tokens={menuMeatball} />
                    </Button>
                </Tooltip>
            </Row>
        ) : (
            <Row alignmentHorizontal="center">
                <Tooltip
                    position="bottom"
                    title={<div>Open <b>poll results</b></div>}
                >
                    <Button
                        className="dashboard-tile-button"
                        type="tertiary"
                        circular="true"
                        size="small"
                        label="Open poll results"
                        onClick={() => onOpenResults(poll.pollID)}
                    >
                        <Icon tokens={menuMeatball} />
                    </Button>
                </Tooltip>
                <Tooltip
                    position="bottom"
                    title={<div>Edit <b>meeting poll</b></div>}
                >
                    <Button
                        className="dashboard-tile-button"
                        type="icon"
                        size="small"
                        label="Edit meeting poll"
                        onClick={() => onRedirectUpdate(poll.pollID)}
                    >
                        <Icon tokens={pencilTokens} />
                    </Button>
                </Tooltip>
            </Row>
        );
    };

    // Number of votes submitted
    const renderVoteInfo = () => {
        return (
            <Column spacing="none" width="90px">
                <Row spacing="200" alignmentVertical="bottom">
                    <Text type="h500">
                        {`${(poll.attendees.filter((attendee) => attendee.response === true)).length}`}
                    </Text>
                    <div style={{paddingBottom: "4px"}}>
                        <Text type="b200" color="secondary">
                            {`/ ${poll.attendees.length}`}
                        </Text>
                    </div>
                </Row>
                <Text type="b200" color="secondary">Voted</Text>
            </Column>
        );
    };

    return (
        <li class="dashboard-tile">
            <Tile accent={getPollStatusAccent(poll.status)} spacingInset="400" onClick={showResultsPanel ? () => onOpenResults(poll.pollID) : undefined}>
                {screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.MD ?
                    <Row alignmentHorizontal="justify">
                        <Column spacing="none" width="80px">
                            <Text type="b200" color="secondary">Created</Text>
                            <Text type="b200">{`${getCreationLabel(poll.createTime, organizerPreferredDate)}`}</Text>
                        </Column>
                        <Column width={showResultsPanel && screenSizeBreakpoint < SCREEN_SIZE.BREAKPOINT.XL ? "160px" : "200px"}>
                            <Text type="h100" className="line-clamp">
                                {poll.subject}
                            </Text>
                        </Column>
                        {screenSizeBreakpoint >= SCREEN_SIZE.BREAKPOINT.XL && !showResultsPanel ?
                            <Row wrap="down" alignmentHorizontal="justify">
                                <Column alignmentHorizontal="left" width="140px">
                                    <Tag type={`${getPollStatusAccent(poll.status)}`}>
                                        {poll.status}
                                    </Tag>
                                </Column>
                                <Column spacing="none" width="80px">
                                    <Text type="b200" color="secondary">Due</Text>
                                    <Text type="b200">{`${getCreationLabel(poll.expiration, organizerPreferredDate)}`}</Text>
                                </Column>
                            </Row>
                            :
                            <Column>
                                <Column alignmentHorizontal="left" width="120px">
                                    <Tag type={`${getPollStatusAccent(poll.status)}`}>
                                        {poll.status}
                                    </Tag>
                                </Column>
                                <Column spacing="none" width="80px">
                                    <Text type="b200" color="secondary">Due</Text>
                                    <Text type="b200">{`${getCreationLabel(poll.expiration, organizerPreferredDate)}`}</Text>
                                </Column>
                            </Column>
                        }
                        {renderVoteInfo()}
                        {renderTileButtons()}
                    </Row>
                    :
                    <Column spacing="400">
                        <Row spacing="500">
                            <Column spacing="none" width="80px">
                                <Text type="b200" color="secondary">Created</Text>
                                <Text type="b200">{`${getCreationLabel(poll.createTime, organizerPreferredDate)}`}</Text>
                            </Column>
                            <Column spacing="none" width="80px">
                                <Text type="b200" color="secondary">Due</Text>
                                <Text type="b200">{`${getCreationLabel(poll.expiration, organizerPreferredDate)}`}</Text>
                            </Column>
                        </Row>
                        <Text type="h100" className="line-clamp">
                            {poll.subject}
                        </Text>
                        <Column width="fit-content">
                            <Tag type={`${getPollStatusAccent(poll.status)}`}>
                                {poll.status}
                            </Tag>
                        </Column>
                        <Divider />
                        <Row alignmentHorizontal="justify">
                            {renderVoteInfo()}
                            {renderTileButtons()}
                        </Row>
                    </Column>
                }
            </Tile>
        </li>
    );
};

export default PollDashboardTile;