import { call, take, takeEvery, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import _ from "lodash";

import * as Actions from "../actions/action-types";
import * as MeetingActions from "../../meeting-scheduler/actions/action-types";
import * as LivestreamActions from "../../meeting-livestream/actions/action-types";
import * as PeopleActions from "../../people/actions/action-types";
import * as FeedbackActions from "../../shared/feedback/actions/action-types";
import { createMeeting, deleteMeeting, getMeeting, turnMeetingRemindersOff } from "../../api/apig/ras";
import { generateNewMeetingId } from "../../shared/meeting-utils";
import { FIND_MEETINGS } from "../../shared/actions/action-types";
import { showToast } from "../../shared/toasts/actions";
import { getSuccessToastWithComponent, getErrorToast, getInformationalToast } from "../../shared/toasts/toast-utils";
import { isErrorResponse } from "../../api/apig/apig-utils";
import { pathToWorkflow } from "../../shared/workflow/workflow-utils";
import { switchWorkflow } from "../../shared/workflow/actions/index";
import { getFeedbackEligibility, setFeedbackAlertMessage } from "../../shared/feedback/actions";
import { getAlias, getIsEligible } from "../../../sagas/selector";

import { TOAST_COMPONENT, TOAST_TYPE } from "../../shared/toasts/toast-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";

import { setCreatingMeetingState } from "../actions";

export function* createMeetingApi(action) {
    yield put(setCreatingMeetingState(true));
    let selfMeeting = _.cloneDeep(action.meeting);
    selfMeeting.uniqueID = generateNewMeetingId(action.username);
    const selfMeetingRequest = {meeting: selfMeeting, sendInvitations: false};

    let response;
    let toast;
    // If there are no required attendees, only create selfMeeting.
    if (_.isEmpty(selfMeeting.requiredAttendees)) {
        response = yield call(createMeeting, selfMeetingRequest);
        const selfMeetingResponse = JSON.parse(response);
        if (isErrorResponse(selfMeetingResponse)) {
            yield put(setCreatingMeetingState(false));
            toast = getErrorToast("An error has occurred when creating Out Of The Office, please try again");
        } else {
            toast = getSuccessToastWithComponent();
            toast.toastActionProps.subject = selfMeeting.subject;
            toast.toastActionProps.entryId = selfMeetingResponse.entryID;
            toast.toastActionProps.userEmail = selfMeeting.organizer;
            toast.toastActionProps.componentName = TOAST_COMPONENT.OOTO_CREATED;

            yield put({type: Actions.CLEAR_DRAFT});
            yield put({type: PeopleActions.CLEAR_DRAFT});
            yield put(push("/"));
            yield put(switchWorkflow(WORKFLOWS.HOME));
        }
    } else {
        let awarenessMeeting = _.cloneDeep(selfMeeting);

        // Only manually set isAllDayEvent & time to awarenessMeeting when it's partial OOTO in case the time setup not correctly.
        if (!awarenessMeeting.isAllDayEvent) {
            awarenessMeeting.isAllDayEvent = true;
            const time = awarenessMeeting.time;
            const startTimeAsDate = time.startTime && (new Date(time.startTime * 1000));
            const endTimeAsDate = time.endTime && new Date(time.endTime * 1000);
            startTimeAsDate.setHours(0, 0, 0);
            endTimeAsDate.setHours(24, 0, 0);
            awarenessMeeting.time = {
                startTime: Math.floor(startTimeAsDate.getTime() / 1000),
                endTime: Math.floor(endTimeAsDate.getTime() / 1000)
            }
        }

        awarenessMeeting.uniqueID = generateNewMeetingId(action.username);
        awarenessMeeting.status = "free";
        selfMeeting.requiredAttendees = [];

        // Link the selfMeeting and the awarenessMeeting by adding each other's uniqueID in their extended properties
        awarenessMeeting.extendedProperties.ooto = {
            __type: "com.amazon.resourceadapter#StringValue",
            value: JSON.stringify({
                ootoMeetingType: "awarenessMeeting",
                selfMeetingUniqueId: selfMeeting.uniqueID
            })
        };

        selfMeeting.extendedProperties.ooto = {
            __type: "com.amazon.resourceadapter#StringValue",
            value: JSON.stringify({
                ootoMeetingType: "selfMeeting",
                awarenessMeetingUniqueId: awarenessMeeting.uniqueID
            })
        };

        const awarenessMeetingRequest = {meeting: awarenessMeeting, sendInvitations: true};

        const turnMeetingRemindersOffRequest = {
            requestedOnBehalfOf: awarenessMeeting.organizer,
            uniqueID: awarenessMeeting.uniqueID,
            attendees: _.map(awarenessMeeting.requiredAttendees, (attendee) => {
                return {
                    username: attendee.email?.split("@")[0], // obtain the attendee's alias through their email
                    email: attendee.email,
                    type: attendee.memberType
                };
            })
        };

        response = yield call(createMeeting, selfMeetingRequest);
        const selfMeetingResponse = JSON.parse(response);
        if (isErrorResponse(selfMeetingResponse)) {
            yield put(setCreatingMeetingState(false));
            toast = getErrorToast("An error has occurred when creating Out Of The Office, please try again");
        } else {
            response = yield call(createMeeting, awarenessMeetingRequest);
            const awarenessMeetingResponse = JSON.parse(response);
            if (isErrorResponse(awarenessMeetingResponse)) {
                yield put(setCreatingMeetingState(false));
                const deleteSelfMeetingRequest = {
                    requestedOnBehalfOf: selfMeeting.organizer,
                    uniqueOrEntryID: selfMeeting.uniqueID,
                    sendCancellations: false,
                    targetMailbox: selfMeeting.organizer
                };
                // Currently failing silently if the deleteMeeting call fails
                yield call(deleteMeeting, deleteSelfMeetingRequest);
                toast = getErrorToast("An error has occurred when creating Out Of The Office, please try again");
            } else {
                toast = getSuccessToastWithComponent();
                toast.toastActionProps.subject = awarenessMeeting.subject;
                toast.toastActionProps.entryId = awarenessMeetingResponse.entryID;
                toast.toastActionProps.userEmail = awarenessMeeting.organizer;
                toast.toastActionProps.componentName = TOAST_COMPONENT.OOTO_CREATED;

                // TODO: Need to fix states so we only clear the flow that the user was working on
                yield put({type: Actions.CLEAR_DRAFT});
                yield put({type: MeetingActions.CLEAR_DRAFT});
                yield put({type: LivestreamActions.CLEAR_DRAFT});
                yield put({type: PeopleActions.CLEAR_DRAFT});
                yield put(push("/"));
                yield put(switchWorkflow(WORKFLOWS.HOME));

                // Currently failing silently if the turnMeetingRemindersOff call fails
                yield call(turnMeetingRemindersOff, turnMeetingRemindersOffRequest);
            }
        }
    }
    // refresh agenda
    yield put({
        type: FIND_MEETINGS,
        requestedOnBehalfOf: selfMeeting.organizer,
        startTime: Date.now(),
        endTime: (new Date()).setHours(23, 55, 0),
        maxResults: -1
    });

    // Check if user is eligible for a feedback modal instead of a success toast.
    let isEligible;
    if (toast.toastType === TOAST_TYPE.SUCCESS) {
        const alias = yield select(getAlias);
        yield put(getFeedbackEligibility(alias));
        yield take([FeedbackActions.SET_IS_ELIGIBLE]);
        isEligible = yield select(getIsEligible);
        yield put(setFeedbackAlertMessage("Out of the Office Created"));
    }
    if (!isEligible) {
        yield put(showToast(toast));
    }
    yield put(setCreatingMeetingState(false));
}

export function* watchCreateMeeting() {
    yield takeEvery(Actions.CREATE_MEETING, createMeetingApi);
}

export function* getAwarenessMeetingAndSave(action) {
    console.log("getAwarenessMeetingAndSaveToState called successfully");
    console.log(`Getting meeting ${action.uniqueOrEntryID} on ${action.requestedOnBehalfOf}`);

    const response = yield call(getMeeting,
        action.requestedOnBehalfOf,
        action.uniqueOrEntryID,
        action.master,
        "ooto",
    );
    let parsedResponse = JSON.parse(response); 

    if (isErrorResponse(parsedResponse)) {
        const toast = getErrorToast("An error occurred when retrieving awareness meeting.");
        // If the user fails to retrieve a valid meeting when editing, redirect to the homepage
        if (pathToWorkflow() === WORKFLOWS.OOTO) {
            yield put(push("/"));
            yield put(switchWorkflow(pathToWorkflow()));
        }
        yield put(showToast(toast));
        return;
    }
    let copyOfParsedResponse = {
        ...parsedResponse
    };
    yield put({type: Actions.SET_AWARENESS_MEETING, awarenessMeeting: copyOfParsedResponse});
    const toast = getInformationalToast("Recipients from the copied meeting have been added on the attendee list.");
    yield put(showToast(toast));
}

export function* watchGetAwarenessAndSave() {
    yield takeEvery(Actions.GET_AWARENESS_MEETING_AND_SAVE, getAwarenessMeetingAndSave);
}

export function* updateMeeting(action) {
    yield put({type: Actions.UPDATE_MEETING, meeting: action.meeting});
}

export function* watchUpdateMeeting() {
    yield takeEvery(Actions.UPDATE_MEETING_ASYNC, updateMeeting);
}

const sagas = [
    watchUpdateMeeting(),
    watchCreateMeeting(),
    watchGetAwarenessAndSave(),
];

export default sagas;
