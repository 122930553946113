import React, { useState } from "react";

import LocationModal from "./location-modal";
import { locationsEqual, locationAndFavoriteEqual, renderFeatures, shortRoomName } from "../locations-utils";
import { createFavorite, renderSetFavoriteIcon, renderFavoriteTypeIcon } from "../../favorites/favorites-utils";
import { getMeetingResponseIcon } from "../../meeting-status-utils";
import {
    LOCATION_FEATURE,
    LOCATION_FLOOR_ANY,
    LOCATION_MODAL_TYPE,
    LOCATION_SORT_BY,
    LOCATION_TYPE,
    LOCATION_PROXIMITY,
} from "../locations-constants";
import { formatExchangeResponse, /*renderPriorityIcon*/ } from "../../../people/people-utils";
// import { ATTENDEE_PRIORITY } from "../../../people/people-constants";
import { FLOW_TYPE } from "../../../meeting-scheduler/meeting-scheduler-constants";
import { SCREEN_SIZE } from "../../shared-constants";
import { FAVORITE_TYPE } from "../../favorites/favorites-constants";

import Button from "@amzn/meridian/button";
// import Checkbox from "@amzn/meridian/checkbox";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";

// import minusIconTokens from "@amzn/meridian-tokens/base/icon/minus";
import trashTokens from "@amzn/meridian-tokens/base/icon/trash";
// import { ReactComponent as AutoSuggestIcon } from "../../../../assets/icons/locations/autosuggest.svg";

const LocationList = (props) => {
    const identity = props.identity;
    const flowType = props.flowType || FLOW_TYPE.CREATE;
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const buildings = props.buildings;
    const rooms = props.rooms;
    const locations = props.locations;
    const favorites = props.favorites;

    const [updateLocationModal, setUpdateLocationModal] = useState("");
    const [sortColumn, setSortColumn] = useState(LOCATION_SORT_BY.NAME);
    const [sortDirection, setSortDirection] = useState("ascending");
    // const [selectedLocations, setSelectedLocations] = useState([]);
    const locationCount = locations.length + rooms.length;
    // const checkedLocationsCount = selectedLocations.length;
    // const allLocationsSelected = locationCount === checkedLocationsCount;
    // const locationHeaderCheckboxIcon = !allLocationsSelected ? minusIconTokens : undefined;

    // // Location multi selection
    // const setAllLocations = (value) => {
    //     let tempSelectedLocations = [];
    //     if (value) {
    //         roomsAndLocations.forEach((location) => tempSelectedLocations.push(location.name));
    //     }
    //     setSelectedLocations(tempSelectedLocations);
    // };

    // const onChangeLocationHeader = () => {
    //     const allLocationValues = checkedLocationsCount === 0;
    //     setAllLocations(allLocationValues);
    // };

    // const onChangeLocationRow = (changedLocation) => {
    //     let tempSelectedLocations = [...selectedLocations];
    //     if (tempSelectedLocations.includes(changedLocation)) {
    //         tempSelectedLocations.splice(tempSelectedLocations.indexOf(changedLocation), 1);
    //     } else {
    //         tempSelectedLocations.push(changedLocation);
    //     }
    //     setSelectedLocations(tempSelectedLocations);
    // };

    const isSpecificRoom = (location) => {
        return location.type === LOCATION_TYPE.ROOM;
    };

    const combineRoomsAndLocations = () => {
        let formattedRooms = rooms.map((room) => {
            return {
                ...room,
                type: LOCATION_TYPE.ROOM
            };
        });

        let formattedLocations = locations.map((location) => {
            let building = buildings.find((building) => building.value === location.buildingCode);
            let buildingLabel = (building && building.label) || location.buildingCode || "Unknown building";
            let floor = location.floor !== undefined && location.floor !== LOCATION_FLOOR_ANY ? `floor ${location.floor}` :  "any floor";
            let proximity = location.thisBuildingOnly ? "" : ` ${LOCATION_PROXIMITY.INCLUDE_NEARBY}`;
            let seats = `${location.minCapacity}+ seats`;
            let resources = (location.resourceList.includes(LOCATION_FEATURE.CAMERA) ? ", camera" : "") +
                            (location.resourceList.includes(LOCATION_FEATURE.DISPLAY) ? ", display" : "");
            let restricted = location.restrictedAllowed ? ", restricted allowed" : "";
            let managed = location.hiddenAllowed ? ", hand managed allowed" : "";
            let label = `${buildingLabel}${proximity}, ${floor}, ${seats}${resources}${restricted}${managed}`;

            return {
                ...location,
                type: location.floor !== undefined ? LOCATION_TYPE.FLOOR : LOCATION_TYPE.BUILDING,
                buildingName: buildingLabel,
                name: `${buildingLabel}${proximity}, ${floor}, ${seats}`,
                label: `${label}`,
                restricted: location.restrictedAllowed,
                managed: location.hiddenAllowed
            };
        });

        return formattedRooms.concat(formattedLocations);
    };

    const roomsAndLocations = combineRoomsAndLocations();
    // Sorting
    const sortRoomsAndLocations = (sortColumn, sortDirection) => {
        let sortResult = sortDirection === "ascending" ? -1 : 1;
        let attributeToCompare;
        if (sortColumn === LOCATION_SORT_BY.TYPE) {
            attributeToCompare = "type";
        } else if (sortColumn === LOCATION_SORT_BY.STATUS) {
            attributeToCompare = "status";
        } else {
            // sort by locations
            attributeToCompare = "name";
        }

        return roomsAndLocations.sort((location1, location2) => {
            if (location1[attributeToCompare] < location2[attributeToCompare]) {
                return sortResult;
            }
            if (location1[attributeToCompare] > location2[attributeToCompare]) {
                return -sortResult;
            }
            return 0;
        });
    };

    const onSort = (sortState) => {
        if (sortState.sortColumn !== sortColumn) {
            setSortColumn(sortState.sortColumn);
        }

        if (sortState.sortDirection !== sortDirection) {
            setSortDirection(sortState.sortDirection);
        }
    };

    // Remove
    const onRemoveLocation = (location) => {
        if (isSpecificRoom(location)) {
            props.onRemoveRoom(location);
        } else {
            props.onRemoveLocation(location);
        }
    };

    // Favorites
    const favorited = (location) => {
        if (favorites === undefined || favorites.length === 0) {
            return false;
        }

        if (isSpecificRoom(location)) {
            return favorites.some((favorite) => location.email === favorite.value);
        } else {
            return favorites.some((favorite) => locationAndFavoriteEqual(location, favorite));
        }
    };

    const onFavoriteClick = (location) => {
        if (favorites === undefined) {
            return;
        }

        const favoriteType = isSpecificRoom(location) ? FAVORITE_TYPE.ROOM : FAVORITE_TYPE.LOCATION;
        const favorite = createFavorite(favoriteType, location);

        if (favorited(location)) {
            props.onRemoveFavorite(favorite, identity.username);
        } else {
            props.onAddFavorite(favorite, identity.username);
        }
    };

    return (
        <Table
            headerRows={1}
            showDividers={false}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            spacing="small"
        >
            <TableRow>
                {/*<TableCell width="44px">
                    <Checkbox
                        checked={checkedLocationsCount > 0}
                        onChange={onChangeLocationHeader}
                        iconTokens={locationHeaderCheckboxIcon}
                    />
                </TableCell>*/}
                <TableCell sortColumn={LOCATION_SORT_BY.NAME}>
                    Rooms ({locationCount})
                </TableCell>
                <TableCell width={screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? (flowType === FLOW_TYPE.UPDATE ? "15%" : "30%") : "10%"} />
                {flowType === FLOW_TYPE.UPDATE && screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                    <TableCell width="140px" sortColumn={LOCATION_SORT_BY.STATUS}>Status</TableCell>
                }
                <TableCell width="120px" alignmentHorizontal={screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "left" : "right"}>
                    Features
                </TableCell>
                {/*{screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW &&*/}
                    {/*<TableCell sortColumn={LOCATION_SORT_BY.TYPE} width="120px">*/}
                        {/*Location type*/}
                    {/*</TableCell>*/}
                {/*}*/}
                {screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                    <TableCell width="50px" />
                }
            </TableRow>
            {sortRoomsAndLocations(sortColumn, sortDirection).map((location) => (
                <TableRow
                    highlight={true}
                    key={location.label}
                >
                    {/*<TableCell>
                        <Checkbox
                            checked={selectedLocations.includes(location.name)}
                            onChange={() => onChangeLocationRow(location.name)}
                        />
                    </TableCell>*/}
                    <TableCell>
                        <Link
                            onClick={() => setUpdateLocationModal(location)}
                            type="secondary"
                        >
                            <Row spacing="large" alignmentVertical="center">
                                {(location.type === LOCATION_TYPE.FLOOR || location.type === LOCATION_TYPE.BUILDING) && renderFavoriteTypeIcon(FAVORITE_TYPE.LOCATION)}
                                {location.type === LOCATION_TYPE.ROOM && renderFavoriteTypeIcon(FAVORITE_TYPE.ROOM)}
                                {shortRoomName(location.name)}
                            </Row>
                        </Link>
                        <LocationModal
                            modalType={LOCATION_MODAL_TYPE.UPDATE}
                            modalOpen={locationsEqual(updateLocationModal, location)}
                            identity={identity}
                            buildings={buildings}
                            rooms={rooms}
                            locations={locations}
                            previousLocation={location}
                            autoSuggest={!isSpecificRoom(location)}
                            onCloseModal={() => setUpdateLocationModal("")}
                            onLoadFloors={props.onLoadFloors}
                            onAddRoom={props.onAddRoom}
                            onRemoveRoom={props.onRemoveRoom}
                            onAddLocation={props.onAddLocation}
                            onRemoveLocation={props.onRemoveLocation}
                        />
                    </TableCell>
                    <TableCell>
                        <Row>
                            {renderSetFavoriteIcon(favorited(location), onFavoriteClick, location)}
                        </Row>
                    </TableCell>
                    {flowType === FLOW_TYPE.UPDATE && screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                        <TableCell>
                            <Row spacing="xsmall" alignmentVertical="top">
                                {getMeetingResponseIcon(location.status)}
                                <Text>
                                    {formatExchangeResponse(location.status)}
                                </Text>
                            </Row>
                        </TableCell>
                    }
                    <TableCell>
                        {renderFeatures(
                            location,
                            isSpecificRoom(location) ? "None" : "Any",
                            "b300",
                            screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW ? "left" : "right")
                        }
                    </TableCell>
                    {/*{screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW &&*/}
                        {/*<TableCell>*/}
                            {/*<Row spacing="xsmall">*/}
                                {/*{isSpecificRoom(location) ?*/}
                                    {/*renderPriorityIcon(ATTENDEE_PRIORITY.REQUIRED)*/}
                                    {/*:*/}
                                    {/*<AutoSuggestIcon height="16px" width="16px" />*/}
                                {/*}*/}
                                {/*<Text>{location.type}</Text>*/}
                            {/*</Row>*/}
                        {/*</TableCell>*/}
                    {/*}*/}
                    {screenSizeBreakpoint > SCREEN_SIZE.MOBILE_VIEW &&
                        <TableCell alignmentHorizontal="right">
                            <Row height="16px">
                                <Button
                                    onClick={() => onRemoveLocation(location)}
                                    type="link"
                                    label={`Remove location ${shortRoomName(location.label)}`}
                                    size="small"
                                >
                                    <Icon tokens={trashTokens} />
                                </Button>
                            </Row>
                        </TableCell>
                    }
                </TableRow>
            ))}
        </Table>
    );
};

export default LocationList;
