import noop from "lodash/noop"
import { memoizeByKeys } from "../../_utils/functional"

/**
 * Attempt to convert a media query breakpoint to a number. If we received a
 * breakpoint like "5/10" (e.g. an aspect ratio) then run the math (e.g. "5/10"
 * => 0.5), otherwise just parse as a float (e.g. "5rem" => 5).
 */
const breakpointToNumber = str =>
  /^\s?[0-9.]+\s?\/\s?[0-9.]+\s?$/.test(str)
    ? parseFloat(str.split("/")[0]) / parseFloat(str.split("/")[1])
    : parseFloat(str)

const matchesFallback = ({ condition, breakpoint, fallback }) =>
  fallback === undefined
    ? false
    : condition === "min"
    ? breakpointToNumber(breakpoint) <= breakpointToNumber(fallback)
    : condition === "max"
    ? breakpointToNumber(breakpoint) >= breakpointToNumber(fallback)
    : breakpoint === fallback
/**
 * A wrapper around `window.matchMedia`. If `window.matchMedia` is available
 * then a media query will be constructed and passed to `window.matchMedia` to
 * create a `MediaQueryList` instance. If we're in an env where
 * `window.matchMedia` is not available (e.g. NodeJS) then this will return a
 * mock MediaQueryList that matches against the provided fallback.
 */
const matchMedia =
  typeof window !== "undefined" && window.matchMedia
    ? memoizeByKeys(
        ({ condition, breakpoint, feature, fallback }) => {
          // Build up a standard media query (e.g. `(min-width: 1000px)`) if
          // we have access to window.matchMedia
          const matchMedia = window.matchMedia(
            `(${condition ? `${condition}-` : ""}${feature}: ${breakpoint})`
          )
          // Tack on the a property indicating whether the fallback matches.
          // This is helpful for situations where you're on the client, but want
          // to know what the match state would have been on the server (i.e.
          // when re-hydrating server-rendered markup on the client with React).
          matchMedia.matchesFallback = matchesFallback({
            condition,
            breakpoint,
            fallback,
          })
          return matchMedia
        },
        ["feature", "condition", "breakpoint", "fallback"]
      )
    : args => {
        const matches = matchesFallback(args)
        return {
          // If we don't have access to `window.matchMedia` then "matches" will be
          // set statically based on the provided "fallback".
          matches,
          // Mock out these functions for parity with the `MediaQueryList`
          // instance returned if `window.matchMedia` is available
          matchesFallback: matches,
          addListener: noop,
          removeListener: noop,
        }
      }

export default matchMedia
