import React, { useEffect, useState } from "react";
import _ from "lodash";

import LocationSearch from "./location-search-bar";
import {
    DEFAULT_FEATURE_ICON_SIZE,
    LOCATION_FEATURE,
    LOCATION_FLOOR_ANY,
    LOCATION_MAX_CAPACITY,
    LOCATION_MODAL_TYPE,
    LOCATION_TYPE,
    LOCATION_DEFAULTS,
} from "../locations-constants";
import { KEYCODE } from "../../shared-constants";
import {
    floorListToFloorOptions,
    getUserDefaultLocation,
    renderFeatures,
    renderLocationFeatureIcon,
    roomListToRoomOptions
} from "../locations-utils";

import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Modal, { ModalFooter } from "../../meridian-custom-components/src/components/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Toggle from "@amzn/meridian/toggle";
import {COMPONENT_LABELS} from "../../accessibility-constants";

const LocationModal = (props) => {
    const modalType = props.modalType || LOCATION_MODAL_TYPE.ADD;
    const modalOpen = props.modalOpen;
    const identity = props.identity;
    const buildingOptions = props.buildings;
    const rooms = props.rooms;
    const previousLocation = props.previousLocation;
    const userSettingsBuilding = props.userSettingsBuilding;
    const userSettingsFloor = props.userSettingsFloor;
    const userSettingsMinimumSeats = props.userSettingsMinimumSeats;
    const userSettingsCamera = props.userSettingsCamera;
    const userSettingsDisplay = props.userSettingsDisplay;
    const userSettingsNoRestricted = props.userSettingsNoRestricted;
    const userSettingsNoManaged = props.userSettingsNoManaged;
    const onLoadFloors = props.onLoadFloors;
    const onCloseModal = props.onCloseModal;

    const [autoSuggest, setAutoSuggest] = useState(props.autoSuggest !== undefined ? props.autoSuggest : true);
    const [selectedBuilding, setSelectedBuilding] = useState(previousLocation && previousLocation.buildingCode);
    const [nearbyBuildings, setNearbyBuildings] = useState(previousLocation ?
        !previousLocation.thisBuildingOnly : !LOCATION_DEFAULTS.THIS_BUILDING_ONLY);
    const [selectedFloor, setSelectedFloor] = useState((previousLocation && previousLocation.floor) || LOCATION_FLOOR_ANY);
    const [selectedRoom, setSelectedRoom] = useState(previousLocation && previousLocation.email);
    const [minCapacity, setMinCapacity] = useState(previousLocation ?
        previousLocation.minCapacity : userSettingsMinimumSeats);
    const [cameraChecked, setCameraChecked] = useState((previousLocation && previousLocation.resourceList) ?
            previousLocation.resourceList.includes(LOCATION_FEATURE.CAMERA)
            : userSettingsCamera);
    const [displayChecked, setDisplayChecked] = useState((previousLocation && previousLocation.resourceList) ?
            previousLocation.resourceList.includes(LOCATION_FEATURE.DISPLAY)
            : userSettingsDisplay);
    const [noRestrictedChecked, setNoRestrictedChecked] = useState(previousLocation ?
        !previousLocation.restricted : userSettingsNoRestricted);
    const [noHandManagedChecked, setNoHandManagedChecked] = useState(previousLocation ?
        !previousLocation.managed : userSettingsNoManaged);
    const [prevSelectedBuilding, setPrevSelectedBuilding] = useState("");

    useEffect(() => {
        if (identity && identity.username && selectedBuilding === undefined) {
            let { userDefaultBuilding, userDefaultFloor } = getUserDefaultLocation(identity.site, buildingOptions);
            if (userSettingsBuilding !== undefined && userSettingsFloor !== undefined) {
                userDefaultBuilding = userSettingsBuilding;
                userDefaultFloor = userSettingsFloor;
            }
            setSelectedBuilding(userDefaultBuilding);
            setSelectedFloor(userDefaultFloor);
        }
    }, [identity, selectedBuilding, buildingOptions, userSettingsBuilding, userSettingsFloor, userSettingsCamera]);

    useEffect(() => {
        if (!modalOpen || prevSelectedBuilding === selectedBuilding) {
            return;
        }
        let building = selectedBuilding && buildingOptions && buildingOptions.find((building) => building.value === selectedBuilding);
        if (building && building.value && !building.floorList) {
            setPrevSelectedBuilding(selectedBuilding);
            onLoadFloors(selectedBuilding);
        }
    }, [modalOpen, selectedBuilding, prevSelectedBuilding, buildingOptions, onLoadFloors]);

    const getModalLabel = () => {
        switch (modalType) {
            case LOCATION_MODAL_TYPE.UPDATE:
                return "Update room";
            case LOCATION_MODAL_TYPE.ADD:
            default:
                return "Add room";
        }
    };

    const getBuilding = (buildingCode) => {
        return (buildingOptions && buildingOptions.find((building) => building.value === buildingCode)) || {};
    };

    const getSelectedRoom = () => {
        // if no room is selected (floor or building change), default to first option
        if (!selectedRoom) {
            return getFilteredRoomOptions(roomOptions)[0];
        }

        return roomOptions.find((room) => room.email === selectedRoom);
    };

    const getFilteredRoomOptions = (roomOptions) => {
        const filteredRooms = roomOptions.filter((roomOption) => {
            return (selectedFloor === LOCATION_FLOOR_ANY || selectedFloor === roomOption.floor)
                // remove room options that have already been added
                && (!rooms.find((room) => room.email === roomOption.email)
                    // unless it is the previous room choice that is being updated
                    || (previousLocation && previousLocation.email === roomOption.email));
        });

        return _.orderBy(filteredRooms, "name");
    };

    const floorOptions = floorListToFloorOptions(getBuilding(selectedBuilding).floorList);
    const roomOptions = roomListToRoomOptions(getBuilding(selectedBuilding).roomList);

    const onBuildingSelect = (buildingCode) => {
        setSelectedBuilding(buildingCode);
        onFloorSelect(LOCATION_FLOOR_ANY);
    };

    const onFloorSelect = (floor) => {
        setSelectedRoom(undefined);
        setSelectedFloor(floor);
    };

    const removePreviousLocation = () => {
        if (previousLocation.type === LOCATION_TYPE.ROOM) {
            props.onRemoveRoom(previousLocation);
        } else {
            props.onRemoveLocation(previousLocation);
        }
    };

    const onLocationSelect = (closeModal) => {
        if (modalType === LOCATION_MODAL_TYPE.UPDATE) {
            removePreviousLocation();
        }

        if (autoSuggest) {
            let resourceList = [];
            if (cameraChecked) {
                resourceList.push(LOCATION_FEATURE.CAMERA);
            }
            if (displayChecked) {
                resourceList.push(LOCATION_FEATURE.DISPLAY);
            }
            
            const location = {
                minCapacity: typeof minCapacity === "string" ? parseInt(minCapacity || 1) : minCapacity,
                buildingCode: selectedBuilding,
                floor: selectedFloor !== LOCATION_FLOOR_ANY ? selectedFloor : undefined,
                resourceList: resourceList,
                restrictedAllowed: !noRestrictedChecked,
                hiddenAllowed: !noHandManagedChecked,
                thisBuildingOnly: !nearbyBuildings,
            };

            if (props.onAddFavorite !== undefined) {
                props.onAddFavorite(location);
            } else {
                props.onAddLocation(location);
            }
        } else {
            if (props.onAddFavorite !== undefined) {
                props.onAddFavorite(getSelectedRoom());
            } else {
                props.onAddRoom(getSelectedRoom());
            }
            setSelectedRoom(undefined);
        }

        if (closeModal) {
            onCloseModal();
        }
    };

    const onLocationRemove = () => {
        removePreviousLocation();
        onCloseModal();
    };

    const onCameraChecked = (cameraChecked) => {
        setCameraChecked(cameraChecked);
        setDisplayChecked(cameraChecked);
    };

    const onKeyDown = (keyDownEvent) => {
        let capacity = minCapacity === undefined || minCapacity === "" ? 0 :
            typeof minCapacity === "string" ? parseInt(minCapacity) : minCapacity;
        let keyCode = keyDownEvent.keyCode || keyDownEvent.which;
        if (keyCode === KEYCODE.UP) {
            // Up key pressed
            setMinCapacity(Math.min(capacity + 1, LOCATION_MAX_CAPACITY));
        } else if (keyCode === KEYCODE.DOWN) {
            // Down key pressed
            setMinCapacity(capacity > 0 ? capacity - 1 : 0);
        }
    };

    return (
        <Modal
            title={getModalLabel()}
            open={modalOpen}
            onClose={() => onCloseModal()}
            scrollContainer="viewport"
            closeLabel="Close"
            describedById="modal-description"
            width="550px"
        >
            <Column spacing="small">
                <Row spacingInset="xxsmall">
                    <Toggle checked={autoSuggest} onChange={setAutoSuggest}>Auto-suggest</Toggle>
                </Row>
                <Column spacing="none">
                    <Row widths={["grid-8", "grid-4"]} spacingInset="xxsmall">
                        <Text type="b200" alignment="left">Building</Text>
                        <Text type="b200" alignment="left">Floor</Text>
                    </Row>
                    <Row widths={["grid-8", "grid-4"]} spacingInset="xxsmall">
                        <LocationSearch
                            isSearchable={true}
                            isMultiSelect={false}
                            locationOptions={buildingOptions}
                            location={selectedBuilding}
                            onLocationSelect={onBuildingSelect}
                            width="100%"
                            spacing="small"
                            placeholder="Select meeting building"
                            ariaLabel="Building"
                        />
                        <LocationSearch
                            isSearchable={true}
                            isMultiSelect={false}
                            locationOptions={floorOptions}
                            location={selectedFloor}
                            onLocationSelect={onFloorSelect}
                            width="100%"
                            spacing="small"
                            placeholder="Select meeting floor"
                            ariaLabel="Floor"
                        />
                    </Row>
                </Column>
                <Column spacing="none">
                    <Row spacingInset="xxsmall">
                        <Text type="b200" alignment="left">{autoSuggest ? "Minimum seats" : "Room"}</Text>
                    </Row>
                    <Row widths={["grid-12"]} spacingInset="xxsmall">
                        {autoSuggest ?
                            <Row widths={["grid-3", "grid-9"]}>
                                <Input
                                    aria-label={COMPONENT_LABELS.MINIMUM_SEATS}
                                    value={minCapacity}
                                    onChange={(value) => setMinCapacity(Math.min(value, 999))}
                                    type="number"
                                    size="small"
                                    onKeyDown={onKeyDown}
                                    pattern={/^[0-9]*$/}
                                />
                                <Checkbox checked={nearbyBuildings} onChange={setNearbyBuildings}>
                                    <Text type="b200" alignment="left">Include nearby buildings</Text>
                                </Checkbox>
                            </Row>
                            :
                            <LocationSearch
                                isSearchable={true}
                                isMultiSelect={false}
                                locationOptions={getFilteredRoomOptions(roomOptions)}
                                location={selectedRoom || (getSelectedRoom() && getSelectedRoom().email)}
                                onLocationSelect={setSelectedRoom}
                                width="100%"
                                spacing="small"
                                placeholder="Select a room"
                                ariaLabel="Room"
                            />
                        }
                    </Row>
                </Column>
                {autoSuggest ?
                    <Row spacingInset="xxsmall" widths={["fill", "fill"]} alignmentVertical="top" wrap="down">
                        <Column spacing="none" minWidth="225px">
                            <fieldset style={{border: 0, margin: 0, padding: 0}}>
                                <legend>
                                    <Text type="b200" alignment="left">Features</Text>
                                </legend>
                                <Box type="outline" spacingInset="small">
                                    <Row widths={["fill", "fit"]} spacingInset="xsmall">
                                        <Checkbox checked={cameraChecked} onChange={onCameraChecked}>
                                            <Text type="b200" alignment="left">Camera</Text>
                                        </Checkbox>
                                        {renderLocationFeatureIcon(LOCATION_FEATURE.CAMERA, DEFAULT_FEATURE_ICON_SIZE, false)}
                                    </Row>
                                    <Row widths={["fill", "fit"]} spacingInset="xsmall">
                                        <Checkbox checked={displayChecked} onChange={setDisplayChecked} disabled={cameraChecked}>
                                            <Text type="b200" alignment="left">Display</Text>
                                        </Checkbox>
                                        {renderLocationFeatureIcon(LOCATION_FEATURE.DISPLAY, DEFAULT_FEATURE_ICON_SIZE, false)}
                                    </Row>
                                </Box>
                            </fieldset>
                        </Column>
                        <Column spacing="none" minWidth="225px">
                            <fieldset style={{border: 0, margin: 0, padding: 0}}>
                                <legend>
                                    <Text type="b200" alignment="left">Permissions</Text>
                                </legend>
                                <Box type="outline" spacingInset="small">
                                    <Row widths={["fill", "fit"]} spacingInset="xsmall">
                                        <Checkbox checked={noRestrictedChecked} onChange={setNoRestrictedChecked}>
                                            <Text type="b200" alignment="left">No restricted</Text>
                                        </Checkbox>
                                        {renderLocationFeatureIcon(LOCATION_FEATURE.RESTRICTED, DEFAULT_FEATURE_ICON_SIZE, false)}
                                    </Row>
                                    <Row widths={["fill", "fit"]} spacingInset="xsmall">
                                        <Checkbox checked={noHandManagedChecked} onChange={setNoHandManagedChecked}>
                                            <Text type="b200" alignment="left">No hand-managed</Text>
                                        </Checkbox>
                                        {renderLocationFeatureIcon(LOCATION_FEATURE.HAND_MANAGED, DEFAULT_FEATURE_ICON_SIZE, false)}
                                    </Row>
                                </Box>
                            </fieldset>
                        </Column>
                    </Row>
                    :
                    <Row widths={["fit", "fill"]} spacingInset="small" alignmentVertical="top">
                        {getSelectedRoom() && [
                            <Column spacing="xxsmall" key="seats">
                                <Text type="h100" alignment="left">Seats</Text>
                                <Text type="b200" alignment="left">{getSelectedRoom().capacity}</Text>
                            </Column>,
                            <Column spacing="xxsmall" key="features">
                                <Text type="h100" alignment="left">Features</Text>
                                {renderFeatures(getSelectedRoom())}
                            </Column>
                        ]}
                    </Row>
                }
            </Column>
            <ModalFooter>
                <Row widths={["fit", "fill"]} wrap="down">
                    <Button type="secondary" onClick={() => onCloseModal()}>
                        Cancel
                    </Button>
                    <Row widths={["fill", "fit"]} wrap="down" alignmentHorizontal="right">
                        <Column alignmentHorizontal="right">
                            {modalType === LOCATION_MODAL_TYPE.UPDATE &&
                                <Button
                                    onClick={modalType === LOCATION_MODAL_TYPE.ADD ?
                                        () => onLocationSelect(false) : onLocationRemove}
                                    type="tertiary"
                                >
                                    Remove room
                                </Button>
                            }
                        </Column>
                        <Button
                            onClick={() => onLocationSelect(true)}
                            type="primary"
                        >
                            {getModalLabel()}
                        </Button>
                    </Row>
                </Row>
            </ModalFooter>
        </Modal>
    );
};

export default LocationModal;
