import React from "react";

import { renderEmailLink } from "../people-utils";
import { renderFavoriteTypeIcon, renderSetFavoriteIcon } from "../../shared/favorites/favorites-utils";
import { FAVORITE_TYPE } from "../../shared/favorites/favorites-constants";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

const ExternalEmailAttendeeCard = (props) => {
    const attendee = props.attendee;
    const email = attendee?.email;

    return (
        <Column spacing="xxsmall">
            <Row spacing="xsmall">
                {renderFavoriteTypeIcon(FAVORITE_TYPE.EXTERNAL_EMAIL)}
                {renderEmailLink(email, "b300", undefined, "bold")}
                {props.onFavoriteClick && renderSetFavoriteIcon(props.isFavorited(attendee), props.onFavoriteClick, attendee)}
            </Row>
            <Text type="b100" alignment="left">
                External Address
            </Text>
        </Column>
    );
};

export default ExternalEmailAttendeeCard;