import * as Actions from "./action-types";

export const updateCurrentMeeting = (meeting) => {
    return {type: Actions.UPDATE_MEETING_ASYNC, meeting};
};

export const saveDraft = (draft) => {
    return {type: Actions.SAVE_DRAFT, meeting: draft.meeting};
};

export const clearDraft = () => {
    return {type: Actions.CLEAR_DRAFT};
};

export const addAttendee = (attendee) => {
    return {type: Actions.ADD_ATTENDEE, attendee};
};

export const removeAttendee = (alias) => {
    return {type: Actions.REMOVE_ATTENDEE, alias};
};

export const searchSuggestion = (query, isAllDayEvent) => {
    return {type: Actions.SEARCH_SUGGESTION, query, isAllDayEvent};
};

export const selectSuggestion = (suggestion, resources) => {
    return {type: Actions.SELECT_SUGGESTION, suggestion, resources};
};

export const setSuggestionViewType = (suggestionViewType) => {
    return {type: Actions.SET_SUGGESTION_VIEW_TYPE, suggestionViewType};
};

export const selectStations = (selectedStations) => {
    return {type: Actions.SELECT_STATIONS, selectedStations};
};

export const changeStep = (step) => {
    return {type: Actions.CHANGE_STEP, step};
};

export const setMaster = (master) => {
    return {type: Actions.SET_MASTER, master};
};

export const setCreatingMeetingState = (isCreatingMeeting) => {
    return {type: Actions.SET_CREATING_MEETING_STATE, isCreatingMeeting};
};

export const updateRecurrence = (recurrence) => {
    return {type: Actions.UPDATE_RECURRENCE, recurrence};
};

export const setLoadMeeting = (loadMeeting) => {
    return {type: Actions.SET_LOAD_MEETING, loadMeeting};
};

// livestream actiontypes
export const clearPermissionPeopleSuggestions = () => {
    return {type: Actions.CLEAR_PERMISSION_PEOPLE_SUGGESTIONS};
};

export const clearPermissionGroupSuggestions = () => {
    return {type: Actions.CLEAR_PERMISSION_GROUP_SUGGESTIONS};
};

export const getPermissionPeopleSuggestions = (query) => {
    return {type: Actions.GET_PERMISSION_PEOPLE_SUGGESTIONS, query};
};

export const getPermissionGroupSuggestions = (query) => {
    return {type: Actions.GET_PERMISSION_GROUP_SUGGESTIONS, payload: {query: query}};
};

export const savePermissionPeopleSuggestions = (people) => {
    return {type: Actions.SAVE_PERMISSION_PEOPLE_SUGGESTIONS, people};
};

export const savePermissionGroupSuggestions = (groups) => {
    return {type: Actions.SAVE_PERMISSION_GROUP_SUGGESTIONS, payload: {groups: groups}};
};

export const createEventIntakeDetails = (prepMeetingRequest, viewerMeetingRequest, ticketPermalink, redirectTo, eventIntake) => {
    return {type: Actions.CREATE_EVENT_INTAKE_DETAILS, prepMeetingRequest, viewerMeetingRequest, ticketPermalink, redirectTo, eventIntake};
};

export const saveCreateEventIntakeDetails = (eventIntakeResponse) => {
    return {type: Actions.SAVE_CREATE_EVENT_INTAKE_DETAILS, payload: {eventIntakeResponse: eventIntakeResponse}};
};

export const getLatestEventDetails = (loadEventDetails) => {
    return {type: Actions.GET_LATEST_EVENT_DETAILS, payload: {seriesUrl: loadEventDetails.seriesUrl, organizer: loadEventDetails.organizer}};
}

export const saveLatestEventDetails = (event) => {
    return {type: Actions.SAVE_LATEST_EVENT_DETAILS, payload: {latestEvent: event}};
}

export const clearLatestEventDetails = () => {
    return {type: Actions.CLEAR_LATEST_EVENT_DETAILS};
}

export const setLatestEventErrorDetails = (errorDetails) => {
    return {type: Actions.SET_LATEST_EVENT_ERROR_DETAILS, payload: {errorDetails: errorDetails}};
};

export const getLivestreamEventSeriesUrlAvailability = (url) => {
    return {type: Actions.GET_LIVESTREAM_EVENT_SERIES_URL_AVAILABILITY, payload: {url: url}};
};

export const setLivestreamEventSeriesUrlAvailability = (url_availability) => {
    return {type: Actions.SET_LIVESTREAM_EVENT_SERIES_URL_AVAILABILITY, payload: {url_availability: url_availability}};
};

export const clearLivestreamEventSeriesUrlAvailability = () => {
    return {type: Actions.CLEAR_LIVESTREAM_EVENT_SERIES_URL_AVAILABILITY};
};

export const setLivestreamIntakeLoaded = (isLoaded) => {
    return {type: Actions.SET_LIVESTREAM_INTAKE_LOADED, isLoaded};
};

// user actionTypes
export const getCurrentUserData = () => {
    return {type: Actions.GET_CURRENT_USER_DATA, payload: {}};
};