import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import format from "date-fns/format";
import parse from "date-fns/parse";

import { getMeetingDetails } from "../../../sagas/selector";
import { SET_MEETING_DETAILS, SET_MEETINGS_LIST } from "../../shared/actions/action-types";
import { checkInRoom } from "../check-in/actions";
import { showToast } from "../toasts/actions";

import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Icon from "@amzn/meridian/icon";
import Tooltip from "@amzn/meridian/tooltip";
import Tile from "@amzn/meridian/tile";
import Responsive from "@amzn/meridian/responsive";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";

import lockTokens from "@amzn/meridian-tokens/base/icon/lock";
import syncTokens from "@amzn/meridian-tokens/base/icon/sync";
import alertKnockoutTokens from "@amzn/meridian-tokens/base/icon/alert-knockout";
import checkKnockoutTokens from "@amzn/meridian-tokens/base/icon/check-knockout";
import closeKnockoutTokens from "@amzn/meridian-tokens/base/icon/close-knockout";

import MeetingDetails from "../../shared/containers/meeting-details";
import ReleaseOrCheckInMeetingModal from "./release-check-in-meeting-modal";
import LoadingMeetings from "../../landing/components/loading-meeting";
import NoMeetingsOnCalendar from "../../calendar/components/no-meetings-on-calendar";

import PatternBackground from "../../../assets/backgrounds/pattern_small_horizontal.svg";
import thankYouEmojiSvg from "../../../assets/icons/shared/thank_you_emoji.svg";

import "../../shared/accessibility-styles.css";

import { getMeetingResponseIcon, getMeetingStatusColor, getResponseLabel } from "../../shared/meeting-status-utils";
import { generateComponentId } from "../shared-utils";
import { TIME_CONSTANT, ROOM_RESPONSE, SCREEN_SIZE } from "../shared-constants";
import { TOAST_TYPE, TOAST_ACTION_TYPE, TOAST_COMPONENT, TIMEOUT } from "../../shared/toasts/toast-constants";
import * as CheckInConstant from "../check-in/check-in-constants";
import { TIME_FORMAT } from "../settings/settings-constants";
import { WORKFLOWS } from "../../shared/workflow/workflow-constants";

const formatAgendaDate = date => format(date, "LLLL d, eeee");
const MeetingAgenda = (props) => {
    const dispatch = useDispatch();
    const acceptedOnlyFilter = props.acceptedOnly || false;
    const viewAllMeetingsFilter = typeof props.viewAllMeetings == "boolean" ? props.viewAllMeetings : true;
    const meetingResourcesDictionary = props.meetingResourcesDictionary || new Map();
    const roomCheckInStatusDictionary = props.roomCheckInStatusDictionary || new Map();

    const timeFormat = props.timeFormat;
    const [checkInRefocusInfo, setCheckInRefocusInfo] = [props.checkInRefocusInfo, props.setCheckInRefocusInfo];

    let [meetingIdOfSelectedTile, setMeetingIdOfSelectedTile] = useState("");
    const [checkInMeetingModalOpen, setCheckInMeetingModalOpen] = useState("");
    const [autoReleasedMeetings, setAutoReleasedMeetings] = useState(new Map());

    useEffect(() => {
        if (autoReleasedMeetings.size > 0) {
            for (let meeting of autoReleasedMeetings.values()) {
                const meetingDisplayName = meeting[0];
                const roomNames = meeting.slice(1).join(",");

                let refocusInfo = checkInRefocusInfo;
                // If no element was specified for refocus, set focus to previous active element after closing toast
                if (checkInRefocusInfo && Object.keys(checkInRefocusInfo).length === 0) {
                    refocusInfo = {element: document.activeElement};
                }

                dispatch(showToast({
                    id: String(Number(new Date())),
                    toastType: TOAST_TYPE.WARNING,
                    toastActionType: TOAST_ACTION_TYPE.DISPLAY_COMPONENT,
                    toastActionProps: {
                        text: `Your reservation of ${roomNames} for ${meetingDisplayName} has been released due to no check-in.`,
                        linkText: "Learn more about Meeting Check-in",
                        href: "https://w.amazon.com/bin/view/Meetex/SkyLantern/CheckIn/",
                        componentName: TOAST_COMPONENT.LINK,
                        ariaLabel: "Meeting room released",
                        refocusInfo: refocusInfo,
                    },
                    toastTimeout: TIMEOUT.NEVER
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoReleasedMeetings, dispatch]);

    const onCheckInRoomButtonClick = (meetingEntryId) => {
        setCheckInMeetingModalOpen(meetingEntryId);
    };

    const onCheckInRoomClick = (meeting) => {
        const roomsToCheckIn = [{
            meetingStartTime: meeting.time.startTime,
            roomEmail: meeting.resources[0].email,
        }];

        // Refocus to the check in button's associated tile after closing the toast
        let tileId = `tile-${document.activeElement?.id?.substring("check-in-".length)}`;
        let refocusInfo = {
            ...checkInRefocusInfo,
            elementId: tileId,
        };

        setCheckInRefocusInfo(refocusInfo);

        dispatch(checkInRoom("amw", roomsToCheckIn, [meeting.resources[0].displayName], refocusInfo));
    };

    const onOpenPopover = (meeting) => {
        setMeetingIdOfSelectedTile(meeting.entryID);
    };

    const onClosePopover = () => {
        setMeetingIdOfSelectedTile("");
    };

    const getReadableTime = (time, timezone = undefined, locale = "en-US") => {
        try {
            let date = new Date(new Date(time * 1000).toLocaleString(locale, {
                timeZone: timezone,
            }));
            let hours = date.getHours();
            let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            let twelveHourIndicator = "";

            if (timeFormat === TIME_FORMAT.TWELVE_HOUR) {
                twelveHourIndicator = hours <= 11 ? "AM" : "PM";
                hours = hours % 12 === 0 ? 12 : hours % 12;
            }

            if (timeFormat === TIME_FORMAT.TWENTY_FOUR_HOUR && hours < 10) {
                hours = "0" + hours;
            }

            return `${hours}:${minutes}${twelveHourIndicator}`;
        } catch (e) {
            return time;
        }
    };

    const meetingsToDisplay = () => {
        if (props.isCalendarView) {
            return props.meetingList;
        } else {
            let meetingsToDisplay = props.meetingList;
            if (acceptedOnlyFilter) {
                meetingsToDisplay = meetingsToDisplay.filter((meeting) => {
                    return meeting.response !== "tentative" && meeting.response !== "notResponded";
                });
            }
            return viewAllMeetingsFilter ? meetingsToDisplay : meetingsToDisplay.slice(0, 6);
        }
    };

    const meetingList = meetingsToDisplay();

    const renderMeetingList = (meetingList, screenSizeBreakpoint) => {
        const isReducedView = screenSizeBreakpoint < SCREEN_SIZE.BREAKPOINT.MD || props.workflow === WORKFLOWS.RESPOND_POLL.NAME;
        const autoReleasedMeetingMap = new Map();

        const meetingListViews = meetingList.map((meeting, index) => {
            let locationText = meeting.location || "No location specified for this meeting";
            const meetingSubject = meeting.subject || "Untitled meeting";
            const isCanceled = meetingSubject.startsWith("Canceled");

            if (meetingResourcesDictionary && meetingResourcesDictionary.has(meeting.entryID)) {
                const { resources, startTime, endTime, timezone } = meetingResourcesDictionary.get(meeting.entryID);
                meeting = {
                    ...meeting,
                    startTime,
                    endTime,
                    timezone,
                    resources,
                };
            }
            const showLocations = meeting.resources && meeting.resources.length > 0;
            const multipleLocations = showLocations && meeting.resources.length > 1;

            // The checkIn button will show when there is a meeting found that is eligible for release
            const hasEligibleMeetingForRelease = meeting.resources && roomCheckInStatusDictionary;

            let isCheckInButtonDisable = false;

            if (hasEligibleMeetingForRelease) {
                // The checkIn button will be disabled when at least one room checked in or if the room does not require check in
                if (meeting.resources.some((res) =>
                    roomCheckInStatusDictionary?.get(`${res.email}&${meeting.time.startTime}`) === CheckInConstant.CHECKED_IN ||
                    roomCheckInStatusDictionary?.get(`${res.email}&${meeting.time.startTime}`) === CheckInConstant.NOT_REQUIRED ||
                    roomCheckInStatusDictionary?.get(`${res.email}&${meeting.time.startTime}`) === CheckInConstant.CHECK_IN_ERROR.MEETING_NOT_FOUND)) {
                    isCheckInButtonDisable = true;
                }

                // The checkIn button will be disabled when all the rooms are not booked successfully
                if (meeting.resources.every((res) => res.response === ROOM_RESPONSE.DECLINE)) {
                    isCheckInButtonDisable = true;
                }
            } else {
                // No eligible room found should disable the checkIn button.
                isCheckInButtonDisable = true;
            }

            // Only when there are only one room and the location text was not modified and there is a meeting found that is eligible for release, will hide it from the tile
            // Since we will show it under the divider again for all the rooms
            const singleLocationText = showLocations && !multipleLocations && hasEligibleMeetingForRelease &&
                meeting.resources[0].displayName === meeting.location ?
                    "" : locationText;
            // Show default multiple locations when the value was not modified by the user and there is a meeting found that is eligible for release
            // Since we will show it under the divider again for all the rooms
            const multipleLocationText = multipleLocations && hasEligibleMeetingForRelease &&
                meeting.resources.map(res => res.displayName).join(", ") === meeting.location ?
                    "" : locationText;

            const renderMeetingTime = () => {
                return (
                    <Text type={isReducedView ? "h100" : "h200"} alignment="left">
                        {meeting.isAllDayEvent ? "All day event" : `${getReadableTime(meeting.time.startTime, props.timezone)} - ${getReadableTime(meeting.time.endTime, props.timezone)}`}
                    </Text>
                );
            };

            const renderMeetingResponse = () => {
                return (
                    <Text alignment="right">
                        {getMeetingResponseIcon(meeting.response, isCanceled, getResponseLabel(meeting.response, isCanceled))}
                    </Text>
                );
            };

            // Subject, private/recurring statuses
            const renderMeetingSubject = () => {
                return (
                    <Row spacing="small" alignmentVertical="top">
                        <Text type={isReducedView ? "h100" : "h200"} alignment="left" className="responsive-text">
                            {meetingSubject}
                        </Text>
                        {meeting.isPrivate &&
                            <Tooltip position="right" title="Private">
                                <Icon tokens={lockTokens}>Private</Icon>
                            </Tooltip>
                        }
                        {meeting.isRecurring &&
                            <Tooltip position="right" title="Recurring">
                                <Icon tokens={syncTokens}>Recurring</Icon>
                            </Tooltip>
                        }
                    </Row>
                );
            };

            const renderCheckInStatus = (status) => {
                switch (status) {
                    case CheckInConstant.NOT_REQUIRED:
                    case CheckInConstant.CHECK_IN_ERROR.MEETING_NOT_FOUND:
                        return (<span><Icon tokens={checkKnockoutTokens} /> No need to check in</span>);
                    case CheckInConstant.CHECKED_IN:
                        return (<span><Icon tokens={checkKnockoutTokens} /> Checked in</span>);
                    case CheckInConstant.RELEASED:
                        return (<span><Icon tokens={closeKnockoutTokens} /> Room released or declined</span>);
                    case CheckInConstant.AUTO_RELEASED:
                        return (<span><Icon tokens={alertKnockoutTokens} /> Room automatically released</span>);
                    default:
                        return null;
                }
            };

            const renderMeetingLocation = () => {
                return hasEligibleMeetingForRelease ? (
                    <Column spacing="none">
                        {meeting.resources.map((resource) => {
                            const roomCheckInStatus = roomCheckInStatusDictionary?.has(`${resource.email}&${meeting.time.startTime}`) && roomCheckInStatusDictionary.get(`${resource.email}&${meeting.time.startTime}`);
                            const isReleased = resource.response === ROOM_RESPONSE.DECLINE; // MCI API doesn't have the ability to check the release status so we are relying on RAS decline response to check the release status.
                            const isAutoReleased = roomCheckInStatus && isReleased && Date.now() - meeting.time.startTime * 1000 >= TIME_CONSTANT.TEN_MINUTES_IN_MS;

                            if (isAutoReleased) {
                                if (autoReleasedMeetingMap.has(meeting.entryID)) {
                                    autoReleasedMeetingMap.get(meeting.entryID).push(resource.displayName);
                                } else {
                                    autoReleasedMeetingMap.set(meeting.entryID, [meeting.subject, resource.displayName]);
                                }
                            }

                            let status;

                            if (roomCheckInStatus === CheckInConstant.NOT_REQUIRED ||
                                roomCheckInStatus === CheckInConstant.CHECKED_IN ||
                                roomCheckInStatus === CheckInConstant.CHECK_IN_ERROR.MEETING_NOT_FOUND) {
                                status = roomCheckInStatus;
                            } else if (isReleased) {
                                status = CheckInConstant.RELEASED;
                            } else if (isAutoReleased) {
                                status = CheckInConstant.AUTO_RELEASED;
                            }

                            return (
                                <Row spacing="small" wrap="down">
                                    <Text>{resource.displayName}</Text>
                                    {renderCheckInStatus(status)}
                                </Row>
                            );
                        })}
                    </Column>
                ) : (
                    <Text type="b200" alignment="left" className="responsive-text">
                        {multipleLocations ? multipleLocationText : singleLocationText}
                    </Text>
                );
            };

            const renderCheckInButton = () => {
                return (
                    <Button
                        id={`check-in-${meetingSubject.split().join("-")}-${meeting.time.startTime}-${index}`}
                        type="tertiary"
                        onClick={multipleLocations ? () => onCheckInRoomButtonClick(meeting.entryID) : () => onCheckInRoomClick(meeting)}
                        minWidth="180px"
                    >
                        Check in
                    </Button>
                );
            };

            return (
            <li style={{"padding": "4px"}} key={`${meetingSubject.split().join("-")}-${meeting.time.startTime}-${index}`}>
                <Column spacing="none" spacingInset={props.page === "homepage" ? "none small" : "none"} width="100%">
                    <div id={generateComponentId('tile', 'agenda')}>
                        <Tile
                            className="agenda-tile"
                            id={`tile-${meetingSubject.split().join("-")}-${meeting.time.startTime}-${index}`}
                            accent={getMeetingStatusColor(meeting.status, isCanceled)}
                            onClick={() => onOpenPopover(meeting)}
                            aria-describedby={`meeting-agenda-meeting-status-${index}`}
                        >
                            {isReducedView ?
                                <Column spacing="small">
                                    <Row alignmentHorizontal="justify">
                                        {renderMeetingTime()}
                                        {renderMeetingResponse()}
                                    </Row>
                                    {renderMeetingSubject()}
                                    {renderMeetingLocation()}
                                </Column>
                                :
                                <Row alignmentVertical="top" alignmentHorizontal="justify" spacingInset="small">
                                    <Row alignmentVertical="top">
                                        <Row minWidth="200px">
                                            {renderMeetingTime()}
                                        </Row>
                                        <Column spacing="small">
                                            {renderMeetingSubject()}
                                            {renderMeetingLocation()}
                                        </Column>
                                    </Row>
                                    <Row alignmentVertical="top" alignmentHorizontal="end" minWidth="125px">
                                        {renderMeetingResponse()}
                                    </Row>
                                </Row>
                            }
                        </Tile>
                    </div>
                    {/* Only show check-in related stuff to the meetings when there is a meeting found that is eligible for release*/}
                    {hasEligibleMeetingForRelease && !isCheckInButtonDisable &&
                        <Column spacingInset="400 0 300 0">
                            {isReducedView ?
                                <Column alignmentHorizontal="center">
                                    {renderCheckInButton()}
                                </Column>
                                :
                                <Row>
                                    <Row minWidth="224px">&nbsp;</Row>
                                    {renderCheckInButton()}
                                </Row>
                            }
                        </Column>
                    }
                </Column>
                {multipleLocations &&
                    <ReleaseOrCheckInMeetingModal isCheckIn
                        open={checkInMeetingModalOpen === meeting.entryID}
                        setOpen={setCheckInMeetingModalOpen}
                        isSingleRoom={!multipleLocations}
                        userEmail={props.userEmail}
                        meeting={meeting}
                        timezones={props.timezones}
                        primaryTimezone={props.primaryTimezone}
                        timeFormat={timeFormat}
                        roomCheckInStatusDictionary={roomCheckInStatusDictionary}
                        checkInRefocusInfo={checkInRefocusInfo}
                    />
                }
            </li>
        );});
        if (autoReleasedMeetings.size === 0 && autoReleasedMeetingMap.size > 0) {
            setAutoReleasedMeetings(autoReleasedMeetingMap);
        }
        return meetingListViews;
    };

    // The meetings received in this method come in a perDate Map as is generated in "calendar.js" for the Calendar view
    const renderMeetingsPerDay = (meetingListsPerDay, screenSizeBreakpoint) => {
        const dates = Object.keys(meetingListsPerDay).sort();
        if (dates.length === 0) {
            return (
                !props.meetingAgendaLoaded ?
                    <LoadingMeetings
                        loadingMessage="Loading your meetings..."
                        loaderSize="large"
                    />
                    :
                    <NoMeetingsOnCalendar emptyDueToFilters={props.emptyDueToFilters} />
            );
        }

        return dates.map((date) => {
            const meetingsForDate = meetingListsPerDay[date];
            return (
                <Column spacingInset="small" spacing="none" key={`agenda-for-${date}`}>
                    <Heading level={2} type="h200">{formatAgendaDate(parse(date, "yyyy-MM-dd", new Date()))}</Heading>
                    {renderMeetingList(meetingsForDate, screenSizeBreakpoint)}
                </Column>
            );
        });
    };
    return (
        <Responsive
            query="min-width"
            props={{
                screenSizeBreakpoint: {...SCREEN_SIZE.RESPONSIVE_BREAKPOINTS}
            }}
        >
            {(responsiveProps) => (
                props.meetingAgendaLoaded && props.meetingList.length === 0 && !props.emptyDueToFilters ?
                    <Column spacingInset="medium none">
                        <Box type="outline">
                            <Row alignmentHorizontal={responsiveProps.screenSizeBreakpoint >= 700 ? "left" : "center"} wrap={responsiveProps.screenSizeBreakpoint >= 700 ? "none" : "down"} spacing="none">
                                <div style={{
                                    backgroundImage: `url(${PatternBackground})`,
                                    backgroundRepeat: "repeat-y",
                                    backgroundPosition: "top",
                                    width: responsiveProps.screenSizeBreakpoint >= 700 ? "220px" : "100%",
                                    height: "190px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <img src={thankYouEmojiSvg} style={{"height": "105px", "width": "97px"}} alt="" />
                                </div>
                                <Column width="360px" spacingInset="medium large" spacing="xsmall">
                                    <Heading type="h200" level={3}>
                                        Make the most of it...
                                    </Heading>
                                    <Text type="b200">
                                        It looks like you have no upcoming meetings.
                                    </Text>
                                </Column>
                            </Row>
                        </Box>
                    </Column>
                    :
                    <div style={props.page === "calendar" ? {overflowY: "scroll"} : {}}>
                        <ol style={{
                            "listStyleType": "none",
                            "padding": "0px",
                            "width": "100%"
                        }}>
                            {props.isCalendarView ?
                                renderMeetingsPerDay(meetingList, responsiveProps.screenSizeBreakpoint)
                                :
                                renderMeetingList(meetingList, responsiveProps.screenSizeBreakpoint)
                            }
                        </ol>
                        <MeetingDetails
                            open={meetingIdOfSelectedTile !== ""}
                            entryID={meetingIdOfSelectedTile}
                            onClose={onClosePopover}
                            saveAction={SET_MEETING_DETAILS}
                            selector={getMeetingDetails}
                            userEmail={props.userEmail}
                            meetingListAction={SET_MEETINGS_LIST}
                            meetingList={meetingList}
                            timeFormat={timeFormat}
                            page={props.page}
                            username={props.username}
                        />
                    </div>
            )}
        </Responsive>
    );
};

export default MeetingAgenda;