import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import format from "date-fns/format";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import isBefore from "date-fns/isBefore";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Responsive from "@amzn/meridian/responsive";
import Text from "@amzn/meridian/text";
import Loader from "@amzn/meridian/loader";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Checkbox from "@amzn/meridian/checkbox";
import Modal, { ModalFooter } from "../../shared/meridian-custom-components/src/components/modal";

import {
    getIdentity,
    getMeetingListCalendar,
    getMeetingListCalendarLoaded,
    getAvailabilityRawData,
    getAvailabilityBlockData,
    getTimezonesList,
    getWorkHours,
    getSettingsPrimaryTimezone,
    getSettingsTimeFormat,
    getSettingsDateFormat,
    getSettingsWorkingHours
} from "../../../sagas/selector";
import { findMeetings, setAvailabilityRawData, setAvailabilityBlocks } from "../../calendar/actions";
import { postAvailabilitySharingCopyToClipboardMetric } from "../../shared/metrics/actions";
import LabeledDateRangePicker from "../../shared/components/labeled-date-range-picker";
import LabeledTimeSelect from "../../shared/components/labeled-time-select";
// import TimezonePicker from "../../shared/components/timezone-search-bar";
import Calendar from "../../calendar/components/calendar";
import SimpleDurationSelect from "../../shared/components/simple-duration-select";
import MeetingsToaster from "../../shared/toasts/containers/toast-container";
import MeetingsFeedbackContainer from "../../shared/feedback/containers/meetings-feedback-container";

import HeaderBackground from "../../../assets/backgrounds/pattern_small_horizontal_repeating.svg";

import { formatWorkHoursTime } from "../../shared/exchange-preferences/exchange-preferences-utils";
import { renderDurationLabel } from "../../shared/time-utils";
import { pad } from "../../meeting-roomfinder/utils";
import { parseTimeString24hTo12h } from "../../shared/shared-utils";
import { getMeetingDaysLabel, getNextDayOfWeek } from "../../meeting-scheduler/meeting-scheduler-utils";
import { MAPS_CALENDAR_STATE } from "../availability-sharing-constants";
import { CALENDAR_MODE } from "../../calendar/calendar-constants";
import { MODAL_CONSTANT, /*SCREEN_SIZE,*/ TIME_CONSTANT, TIME_FORMAT } from "../../shared/shared-constants";
import { DURATION } from "../../meeting-quick/meeting-quick-constants";
import { getTimeMinutes } from "../../shared/settings/settings-utils";

const MeetingAvailabilitySharing = () => {

    const identity = useSelector(getIdentity);
    const userEmail = identity.email;
    const dispatch = useDispatch();

    // Time selectors
    const exchangeWorkHours = useSelector(getWorkHours);
    const settingsWorkHours = useSelector(getSettingsWorkingHours);
    const timezones = useSelector(getTimezonesList);
    const primaryBrowserTimezone = useSelector(getSettingsPrimaryTimezone);
    const timeFormat = useSelector(getSettingsTimeFormat);
    const dateFormat = useSelector(getSettingsDateFormat);

    const [startTime, setStartTime] = useState(undefined); // Initialized in useEffect
    const [endTime, setEndTime] = useState(undefined); // Initialized in useEffect
    const [timeLabel, setTimeLabel] = useState(""); // Initialized in useEffect

    const [daysOfWeek, setDaysOfWeek] = useState(["Mon", "Tue", "Wed", "Thu", "Fri"]);
    const [daysOfWeekLabel, setDaysOfWeekLabel] = useState("Only on Mon - Fri");

    const [timezoneValue, setTimezoneValue] = useState(primaryBrowserTimezone);

    // Presets for the date range picker
    const formatIso = (date) => format(date, "yyyy-MM-dd");
    const formatAvailabilityDate = (date) => format(date, "LLLL d, EEEE");
    const today = formatIso(new Date());
    const tomorrow = formatIso(addDays(new Date(), +1));
    const nextWeekSunday = formatIso(getNextDayOfWeek(new Date(), 0));
    const nextWeekSaturday = formatIso(getNextDayOfWeek(addDays(new Date(), +7), 6));
    const weekFromNow = formatIso(addDays(new Date(), +7));
    const twoWeeksFromNow = formatIso(addDays(new Date(), +14));
    const monthFromNow = formatIso(addMonths(new Date(), +1));

    const presets = [
        { label: "Today", value: [today, today] },
        { label: "Tomorrow", value: [tomorrow, tomorrow] },
        { label: "Next week", value: [nextWeekSunday, nextWeekSaturday] },
        { label: "Within a week", value: [today, weekFromNow] },
        { label: "Within two weeks", value: [today, twoWeeksFromNow] },
        { label: "Within a month", value: [today, monthFromNow] },
    ];

    const [dateRangeValue, setDateRangeValue] = useState([today, weekFromNow]);
    const [dateRangeLabel, setDateRangeLabel] = useState("8 days");

    // Calendar
    const calendarViewType = "week"; // Always default to whole week view, days of the week are filtered separately
    const [calendarDate, setCalendarDate] = useState(dateRangeValue[0] || dateRangeValue[1]);
    const calendarStatusFilter = ["busy", "tentative", "outOfOffice"];
    const calendarResponseFilter = ["accept", "tentative", "notResponded"];
    const meetingListLoaded = useSelector(getMeetingListCalendarLoaded);
    const meetingList = useSelector(getMeetingListCalendar);
    let meetingListLoadedInternal = meetingListLoaded; // Variable to check if we've already requested for a new meeting list but the state has not been updated
    const formatIsoTime = (date) => format(date, "HH:mm");

    const lastTimeRangeQueried = useRef("");

    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [showRefreshAlert, setShowRefreshAlert] = useState(false);

    const [minDuration, setMinDuration] = useState(DURATION.THIRTY_MINUTES.toString());
    const [tentativeAsBusy, setTentativeAsBusy] = useState(true);
    const [recalculateAvailability, setRecalculateAvailability] = useState(false);

    // Organize meetings from findMeetings request by date to process them in the calendar view
    const getMeetingsByDate = useCallback((meetingList) => {
        if (!meetingListLoaded) {
            return {};
        }
        let meetingsByDate = {};
        meetingList.forEach((meeting) => {
            let meetingStartDate = formatIso(new Date(meeting.time.startTime * 1000));
            if (!meetingsByDate[meetingStartDate]) {
                meetingsByDate[meetingStartDate] = [];
            }
            meetingsByDate[meetingStartDate].push(meeting);
        });
        return meetingsByDate;
    }, [meetingListLoaded]);

    // Map with the raw data of the calendar state. Main key is the date, with a property for every hour, where every hour has the state for a 5 minute interval.
    // Example: {"2021-11-16":{"0":{"0":2,"5":2,"10":2,"15":2,"20":2,"25":2,"30":2,"35":2,"40":2,"45":2,"50":2,"55":2}, "1": {...} ...}
    // States are stored as an integer with 4 possible options described in the constant MAPS_CALENDAR_STATE
    const availabilityRawData = useSelector(getAvailabilityRawData);

    // Map with the raw data transformed into a block format. Only the relevant times for the range to share are selected.
    // Example: {"2021-11-16":{"10":[{"startTime":"10:00","endTime":"10:30","duration":0.5,"state":3}, ...}
    let availabilityBlocks = useSelector(getAvailabilityBlockData);

    // TODO: Date range to be handled in a future commit, currently only the same date is being sent for both start and end dates
    // Update the availability map for the block of time defined by startTime - endTime, and sets it's status to the one provided.
    // tmpAvailabilityMap - The map of time slots and their respective status' that this function will update
    // status - The status we want to update our map to have during the time range. This status tracks if a time slot is empty or has a meeting AND if it is shared or not shared.
    // Values: EMPTY_NOT_SHARED: 1, EMPTY_SHARED: 2, MEETING_NOT_SHARED: 3, MEETING_SHARED: 4
    // preventOverride - When true, skip the timeslot if it already has value. When false or omitted, the function behaves normally. This flag is mainly used during initialization.
    // updateSharedStateOnly - When false or omitted, the function behaves normally. When true, only update the shared state of a time slot's status to match the passed in status.
    // Ex: If the passed in status is EMPTY_NOT_SHARED, we will update all time slots in the given range to EMPTY_NOT_SHARED or MEETING_NOT_SHARED, keeping the original empty vs meeting state for each respective time slot.
    const updateAvailability = useCallback((tmpAvailabilityMap, status, startDate, endDate, startTime, endTime, preventOverride, updateSharedStateOnly) => {
        if (tmpAvailabilityMap === undefined || startTime >= endTime) {
            return tmpAvailabilityMap;
        }

        let parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
        parsedStartDate.setHours(0, 0, 0, 0);
        let parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());
        parsedEndDate.setHours(23, 0, 0, 0);

        const [startHour, startMinute] = startTime.split(":").map((time) => parseInt(time));
        const [endHour, endMinute] = endTime.split(":").map((time) => parseInt(time));

        for (let currentDate = parsedStartDate; isBefore(currentDate, parsedEndDate); currentDate = addDays(currentDate, +1)) {
            const currentDateString = formatIso(currentDate);
            if (preventOverride && tmpAvailabilityMap[currentDateString]) { // If it doesn't allow override and the map is already filled with previous ooto status then ignore it
                continue;
            }
            for (let hour = startHour; hour <= endHour; hour++) {
                let minuteToStart = 0, minuteToEnd = 60;
                if (hour === startHour) {
                    minuteToStart = startMinute;
                }
                if (hour === endHour) {
                    minuteToEnd = endMinute;
                }
                if (!tmpAvailabilityMap[currentDateString]) {
                    tmpAvailabilityMap[currentDateString] = {};
                }
                if (!tmpAvailabilityMap[currentDateString][hour]) {
                    tmpAvailabilityMap[currentDateString][hour] = {};
                }
                for (let minute = minuteToStart; minute < minuteToEnd; minute += 5) {
                    if (updateSharedStateOnly) {
                        if (tmpAvailabilityMap[currentDateString][hour][minute] === MAPS_CALENDAR_STATE.EMPTY_NOT_SHARED || tmpAvailabilityMap[currentDateString][hour][minute] === MAPS_CALENDAR_STATE.MEETING_NOT_SHARED) {
                            tmpAvailabilityMap[currentDateString][hour][minute] = tmpAvailabilityMap[currentDateString][hour][minute] + 1;
                        }
                    } else {
                        tmpAvailabilityMap[currentDateString][hour][minute] = status;
                    }
                }
            }
        }

        return tmpAvailabilityMap;
    }, []);

    const getNextBlockOfSameState = useCallback((date, startTime, maxEndTime = "24:00") => {
        const [startHour, startMinute] = startTime.split(":").map((time) => parseInt(time));
        const initialState = availabilityRawData[date] && availabilityRawData[date][startHour] && availabilityRawData[date][startHour][startMinute];
        let duration = 0;

        for (let hour = startHour; hour <= 24; hour++) {
            let minuteToStart = 0, minuteToEnd = 60;
            if (hour === startHour) {
                minuteToStart = startMinute;
            }

            for (let minute = minuteToStart; minute < minuteToEnd; minute += 5) {
                let currentTime = `${pad(hour, 2)}:${pad(minute, 2)}`;
                // Once the state is different from what we started with we return the full "block"
                if (availabilityRawData[date][hour][minute] !== initialState || currentTime === maxEndTime) {
                    return {
                        startTime,
                        endTime: currentTime,
                        duration: duration / 60,
                        state: initialState
                    };
                }
                duration += 5;
            }
        }

        // If we went through the rest of the day we close the block with time of the end of day
        return {
            startTime,
            endTime: "23:59",
            duration: duration / 60,
            state: initialState
        };
    }, [availabilityRawData]);

    const updateAvailabilityDateRange = useCallback((startDateRange, endDateRange, meetingListByDate, recalculateAvailability) => {
        let tmpAvailabilityMap = {};
        let parsedStartDateRange = parse(startDateRange, "yyyy-MM-dd", new Date());
        parsedStartDateRange.setHours(0, 0, 0, 0);
        let parsedEndDateRange = parse(endDateRange, "yyyy-MM-dd", new Date());
        parsedEndDateRange.setHours(23, 0, 0, 0);

        if (availabilityRawData.availabilityRangeStart !== startDateRange || availabilityRawData.availabilityRangeEnd !== endDateRange || recalculateAvailability) {
            for (let currentDate = parsedStartDateRange; isBefore(currentDate, parsedEndDateRange); currentDate = addDays(currentDate, +1)) {
                const currentDateString = formatIso(currentDate);
                let endDateString = currentDateString;
                // If date exists in current range, copy the current state
                if (availabilityRawData[currentDateString] && !recalculateAvailability) {
                    tmpAvailabilityMap[currentDateString] = availabilityRawData[currentDateString];
                }
                // If the date is new in the time range init a new day as free and update it to busy per each meeting in that day
                else {
                    // TODO: This would reset the ooto values to empty shared for the following days :C
                    // Need to find a way around this to not share a calendar if an ooto event spans over multiple days
                    tmpAvailabilityMap = updateAvailability(tmpAvailabilityMap, MAPS_CALENDAR_STATE.EMPTY_SHARED, currentDateString, currentDateString, "00:00", "23:59", true);
                    // eslint-disable-next-line no-loop-func
                    meetingListByDate[currentDateString] && meetingListByDate[currentDateString].forEach((meeting) => {
                        let meetingStatus = MAPS_CALENDAR_STATE.MEETING_NOT_SHARED;
                        // If meeting is marked as free in the calendar there's no need to update the availability since the default is free
                        if (meeting.status === "free") {
                            return;
                        }
                        if (meeting.status === "tentative" && !tentativeAsBusy) {
                            meetingStatus = MAPS_CALENDAR_STATE.MEETING_SHARED;
                        }
                        let meetingHourStart, meetingHourEnd;
                        // Need to handle all day events better, consider the case where they last more than a single day.
                        if (meeting.status === "outOfOffice" && meeting.isAllDayEvent) {
                            meetingStatus = MAPS_CALENDAR_STATE.EMPTY_NOT_SHARED;
                            meetingHourStart = "00:00";
                            meetingHourEnd = "23:59";
                            const endDate = new Date((meeting.time.endTime-1)*1000); // Convert epoch time stamp to js date.
                            endDateString = formatIso(endDate);
                        } else {
                            meetingHourStart = formatIsoTime(new Date (meeting.time.startTime * 1000));
                            meetingHourEnd = formatIsoTime(new Date (meeting.time.endTime * 1000));
                        }

                        tmpAvailabilityMap = updateAvailability(
                            tmpAvailabilityMap,
                            meetingStatus,
                            currentDateString,
                            endDateString,
                            meetingHourStart,
                            meetingHourEnd
                        );
                    });
                }
            }

            tmpAvailabilityMap.availabilityRangeStart = startDateRange;
            tmpAvailabilityMap.availabilityRangeEnd = endDateRange;
            setRecalculateAvailability(false);
            dispatch(setAvailabilityRawData(tmpAvailabilityMap));
        }
    }, [availabilityRawData, dispatch, tentativeAsBusy, updateAvailability]);

    const onUpdateAvailability = (status, startDate, endDate, updateStartTime, updateEndTime, updateSharedStateOnly) => {
        let tmpAvailabilityMap = updateAvailability(availabilityRawData, status, startDate, endDate, updateStartTime, updateEndTime, false, updateSharedStateOnly);
        dispatch(setAvailabilityRawData(tmpAvailabilityMap));
    };

    const getAvailabilityBlocks = useCallback((startTime = "00:00", endTime = "24:00", filterByStatus = null, minimumDuration = "0") => {
        let startDateRange = availabilityRawData.availabilityRangeStart;
        let endDateRange = availabilityRawData.availabilityRangeEnd;

        let parsedStartDateRange = parse(startDateRange, "yyyy-MM-dd", new Date());
        parsedStartDateRange.setHours(0, 0, 0, 0);
        let parsedEndDateRange = parse(endDateRange, "yyyy-MM-dd", new Date());
        parsedEndDateRange.setHours(23, 0, 0, 0);
        let tmpAvailabilityBlocks = {};

        for (let currentDate = parsedStartDateRange; isBefore(currentDate, parsedEndDateRange); currentDate = addDays(currentDate, +1)) {
            const currentDateString = formatIso(currentDate);
            tmpAvailabilityBlocks[currentDateString] = {};

            for (let currentTime = startTime; currentTime < endTime; ) {
                let currentAvailabilityBlock = getNextBlockOfSameState(currentDateString, currentTime, endTime);

                // If a shared available block is smaller than the minimum desired duration, mark it as NOT shared
                // We do this first so it can be ignored in the next step that checks for state match
                if (currentAvailabilityBlock.duration < (parseInt(minimumDuration) / 60) &&
                    (currentAvailabilityBlock.state === MAPS_CALENDAR_STATE.EMPTY_SHARED
                        // We could also consider shared meetings here, but it currently behaves weirdly when there are two 30 minute blocks
                        // next to each other and the minimum duration is bigger. Problem related to not keeping track when a meeting starts/ends in the raw data
                        // || currentAvailabilityBlock.state === MAPS_CALENDAR_STATE.MEETING_SHARED
                    )) {
                    currentAvailabilityBlock.state = MAPS_CALENDAR_STATE.EMPTY_NOT_SHARED;
                }

                // If we want to get only the blocks that match the state of a given list
                if (filterByStatus !== null && filterByStatus.indexOf(currentAvailabilityBlock.state) === -1) {
                    currentTime = currentAvailabilityBlock.endTime;
                    continue;
                }

                let blockStartHour = parseInt(currentAvailabilityBlock.startTime.split(":")[0]);
                if (!tmpAvailabilityBlocks[currentDateString][blockStartHour]) {
                    tmpAvailabilityBlocks[currentDateString][blockStartHour] = [];
                }
                if (currentAvailabilityBlock.endTime > endTime) {
                    currentAvailabilityBlock.endTime = endTime;
                }

                tmpAvailabilityBlocks[currentDateString][blockStartHour].push(currentAvailabilityBlock);
                currentTime = currentAvailabilityBlock.endTime;
            }
        }

        tmpAvailabilityBlocks.availabilityRangeStart = startDateRange;
        tmpAvailabilityBlocks.availabilityRangeEnd = endDateRange;
        return tmpAvailabilityBlocks;
    }, [availabilityRawData, getNextBlockOfSameState]);

    // Calendar events
    const onSetDateRangeValue = (dateRangeValue) => {
        setDateRangeValue(dateRangeValue);
        setCalendarDate(dateRangeValue[0] || dateRangeValue[1]);
    };

    // Merge any number contiguous blocks that match any of the states indicated into a single block with the combined duration
    const mergeAvailabilityBlocksOfSameState = (selectedAvailabilityBlocks, parsedStartDateRange, parsedEndDateRange, statesToMerge) => {
        let mergedAvailabilityBlocks = {};
        for (let currentDate = parsedStartDateRange; isBefore(currentDate, parsedEndDateRange); currentDate = addDays(currentDate, +1)) {
            if (daysOfWeek.indexOf(DAY_OF_WEEK[currentDate.getDay()]) === -1) {
                continue;
            }
            let formattedCurrentDate = formatIso(currentDate);
            mergedAvailabilityBlocks[formattedCurrentDate] = {};

            if (selectedAvailabilityBlocks[formattedCurrentDate]) {
                let previousBlock = {};
                for (let hour = 0; hour < 24; hour++) {
                    if (selectedAvailabilityBlocks[formattedCurrentDate][hour]) {
                        for (let i = 0; i < selectedAvailabilityBlocks[formattedCurrentDate][hour].length; i++) {
                            // If we find a new block with startTime that matches the previous' block endTime and also has any of the
                            // indicated state options, we combine them into a single block with combined duration
                            if (previousBlock.endTime === selectedAvailabilityBlocks[formattedCurrentDate][hour][i].startTime &&
                                statesToMerge.indexOf(selectedAvailabilityBlocks[formattedCurrentDate][hour][i].state) !== -1) {
                                previousBlock = {
                                    startTime: previousBlock.startTime,
                                    endTime: selectedAvailabilityBlocks[formattedCurrentDate][hour][i].endTime,
                                    duration: previousBlock.duration + selectedAvailabilityBlocks[formattedCurrentDate][hour][i].duration,
                                    state: previousBlock.state,
                                    hour: previousBlock.hour
                                }
                            } else {
                                // If the blocks are not contiguous or the state doesn't match we add the previous block to the final shared availability map
                                // we save the previous block in the map and start looking for the next match
                                if (previousBlock.startTime) {
                                    if (!mergedAvailabilityBlocks[formattedCurrentDate][previousBlock.hour]) {
                                        mergedAvailabilityBlocks[formattedCurrentDate][previousBlock.hour] = [];
                                    }
                                    mergedAvailabilityBlocks[formattedCurrentDate][previousBlock.hour].push(previousBlock);
                                }
                                previousBlock = {hour, ...selectedAvailabilityBlocks[formattedCurrentDate][hour][i]};
                            }
                        }
                    }
                }
                // For the last event of the day (if any) we add it to the shared availability blocks map
                if (previousBlock.startTime) {
                    if (!mergedAvailabilityBlocks[formattedCurrentDate][previousBlock.hour]) {
                        mergedAvailabilityBlocks[formattedCurrentDate][previousBlock.hour] = [];
                    }
                    mergedAvailabilityBlocks[formattedCurrentDate][previousBlock.hour].push(previousBlock);
                }
            }
        }

        return mergedAvailabilityBlocks;
    };

    const onGetAvailabilityToShare = () => {
        let startDateRange = availabilityRawData.availabilityRangeStart;
        let endDateRange = availabilityRawData.availabilityRangeEnd;

        let parsedStartDateRange = parse(startDateRange, "yyyy-MM-dd", new Date());
        parsedStartDateRange.setHours(0, 0, 0, 0);
        let parsedEndDateRange = parse(endDateRange, "yyyy-MM-dd", new Date());
        parsedEndDateRange.setHours(23, 0, 0, 0);

        // Get only the blocks that indicate times that are being shared.
        let sharedStates = [MAPS_CALENDAR_STATE.EMPTY_SHARED, MAPS_CALENDAR_STATE.MEETING_SHARED];
        let availableBlocks = mergeAvailabilityBlocksOfSameState(
            getAvailabilityBlocks(startTime.substring(0, 5), endTime.substring(0, 5), sharedStates, minDuration),
            new Date(parsedStartDateRange.getTime()),
            new Date(parsedEndDateRange.getTime()),
            sharedStates
        );

        let userFirstName = identity.name.split(" ")[0];
        let matchedTimezone = timezones?.find((timezone) => {
            return timezone.id === timezoneValue || timezone?.alternativeIDs.some((altId) => altId === timezoneValue);
        });
        let timezoneLabel = (matchedTimezone?.standardShortNameDateFns ? matchedTimezone.standardShortNameDateFns : matchedTimezone?.standardLongNameDateFns) || timezoneValue;
        let availabilityMessageData = {
            title: `${userFirstName}'s availability (${timezoneLabel}):`,
            availabilityList: []
        };

        for (let currentDate = parsedStartDateRange; isBefore(currentDate, parsedEndDateRange); currentDate = addDays(currentDate, +1)) {
            if (daysOfWeek.indexOf(DAY_OF_WEEK[currentDate.getDay()]) === -1) {
                continue;
            }
            let formattedCurrentDate = formatIso(currentDate);

            if (availableBlocks[formattedCurrentDate]) {
                let dayAvailabilities = {label: formatAvailabilityDate(currentDate), availableTimes: []};
                for (let hour = 0; hour < 24; hour++) {
                    if (availableBlocks[formattedCurrentDate][hour]) {
                        for (let i = 0; i < availableBlocks[formattedCurrentDate][hour].length; i++) {
                            dayAvailabilities.availableTimes.push(`${parseTimeString24hTo12h(availableBlocks[formattedCurrentDate][hour][i].startTime)} - ${parseTimeString24hTo12h(availableBlocks[formattedCurrentDate][hour][i].endTime)}`);
                        }
                    }
                }
                availabilityMessageData.availabilityList.push(dayAvailabilities);
            }
        }

        return availabilityMessageData;
    };

    // Share modal
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [availabilityMessage, setAvailabilityMessage] = useState({});

    const onOpenShareModal = () => {
        setShareModalOpen(true);
        setAvailabilityMessage(onGetAvailabilityToShare());
    };

    const onCloseShareModal = () => {
        setShareModalOpen(false);
        setAvailabilityMessage("");
    };

    const copyAvailabilityToClipboard = () => {
        let availabilityText = availabilityMessage.title + "\n\n";
        availabilityMessage.availabilityList.forEach((dayData) => {
            if (!dayData.availableTimes?.length) {
                return;
            }
            availabilityText += dayData.label + "\n";
            dayData.availableTimes.forEach((availabilityMessage) => {
                availabilityText += `\t${availabilityMessage}\n`;
            })
            availabilityText += "\n";
        });

        navigator.clipboard.writeText(availabilityText);
        let startDateRange = parse(dateRangeValue[0], "yyyy-MM-dd", new Date());
        let endDateRange = parse(dateRangeValue[1], "yyyy-MM-dd", new Date());
        let timeRangeDurationInDays = differenceInCalendarDays(endDateRange, startDateRange) + 1;
        dispatch(postAvailabilitySharingCopyToClipboardMetric(userEmail, timeRangeDurationInDays));
        onCloseShareModal();
    };

    useEffect(() => {
        if (!timezoneValue) {
            setTimezoneValue(primaryBrowserTimezone);
        }
    }, [timezoneValue, primaryBrowserTimezone]);

    // Load in work hours to start and end time filters
    useEffect(() => {
        let startTime, endTime;

        if (exchangeWorkHours.startTime && exchangeWorkHours.endTime) {
            startTime = exchangeWorkHours.startTime;
            endTime = exchangeWorkHours.endTime;
        }

        if (settingsWorkHours.startTime && settingsWorkHours.endTime) {
            startTime = getTimeMinutes(settingsWorkHours.startTime);
            endTime = getTimeMinutes(settingsWorkHours.endTime);
        }

        if (startTime !== undefined && endTime !== undefined) {
            setStartTime(formatWorkHoursTime(startTime, TIME_FORMAT.HH_MM_SS));
            setEndTime(formatWorkHoursTime(endTime, TIME_FORMAT.HH_MM_SS));
            setTimeLabel(renderDurationLabel(startTime, endTime, TIME_CONSTANT.MIN_NAME));
        }

        if (settingsWorkHours.days) {
            setDaysOfWeek(settingsWorkHours.days);
            getMeetingDaysLabel(settingsWorkHours.days, "none", setDaysOfWeekLabel);
        }
    }, [exchangeWorkHours, settingsWorkHours]);

    // Call findMeetings to populate the calendar
    useEffect(() => {
        if (!dateRangeValue.length || dateRangeValue[0] === undefined || dateRangeValue[1] === undefined) {
            return;
        }
        let startDateRange = parse(dateRangeValue[0], "yyyy-MM-dd", new Date());
        let endDateRange = parse(dateRangeValue[1], "yyyy-MM-dd", new Date());
        addDays(endDateRange, +1);

        let startOfWeekTime = startOfWeek(startDateRange).getTime();
        let endOfWeekTime = endOfWeek(endDateRange).getTime();
        if (lastTimeRangeQueried.current !== `${userEmail}-${startOfWeekTime}-${endOfWeekTime}` || triggerRefresh) {
            dispatch(findMeetings(userEmail, startOfWeekTime, endOfWeekTime, -1));
            lastTimeRangeQueried.current = `${userEmail}-${startOfWeekTime}-${endOfWeekTime}`;
            setTriggerRefresh(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            meetingListLoadedInternal = false;
        }
    }, [dateRangeValue, userEmail, triggerRefresh, dispatch]);

    // Update Availability map based on date range
    useEffect(() => {
        if (meetingListLoaded && meetingListLoadedInternal) {
            updateAvailabilityDateRange(dateRangeValue[0], dateRangeValue[1], getMeetingsByDate(meetingList), recalculateAvailability);
        }
    }, [dateRangeValue, meetingList, meetingListLoaded, updateAvailabilityDateRange, getMeetingsByDate, recalculateAvailability, meetingListLoadedInternal]);

    useEffect(() => {
        if (startTime && endTime && meetingListLoaded) {
            dispatch(setAvailabilityBlocks(getAvailabilityBlocks(startTime.substring(0, 5), endTime.substring(0, 5), null, minDuration)));
        }
    }, [availabilityRawData, startTime, endTime, minDuration, meetingListLoaded, dispatch, getAvailabilityBlocks]);

    const onSetTentativeAsBusy = (newTentativeAsBusy) => {
        setTentativeAsBusy(newTentativeAsBusy);
        setRecalculateAvailability(true);
    };

    const shortDayToFull = (days) => {
        return days.map((day) => {
            switch (day) {
                case "Sun": return "sunday";
                case "Mon": return "monday";
                case "Tue": return "tuesday";
                case "Wed": return "wednesday";
                case "Thu": return "thursday";
                case "Fri": return "friday";
                case "Sat": return "saturday";
                default: return "";
            }
        });
    };

    const DAY_OF_WEEK = [
        "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
    ];

    const header = useRef();

    // Begin new workflow content with focus on the <h1> heading
    useEffect(() => {
        document.title = "Availability sharing (Beta) - Amazon Meetings";
        if (header.current) {
            header.current.focus();
        }
    }, []);

    return (
        <Responsive query="min-width" props={{
            screenSizeBreakpoint: {
                default: 360,
                "1200px": 1200,
                "900px": 900,
                "600px": 600
            }
        }}>
        {(responsiveProps) => (
            <Column width="100%" height="100%" spacingInset="none" spacing="none">
                <div style={{
                    "backgroundImage": `url(${HeaderBackground})`,
                    "backgroundRepeat": "no-repeat",
                    "backgroundColor": "rgba(26, 69, 78)",
                    "backgroundPosition": "150px -90px",
                    "height": "50px",
                    "width": "100%",
                }}>
                    <Row height="100%" alignmentVertical="center" spacingInset="medium">
                        <Heading color="inverted" level={1} type="h300" ref={header} tabIndex="-1">Availability sharing (Beta)</Heading>
                    </Row>
                </div>

                <div
                    style={{
                        "overflow": "hidden",
                        "backgroundColor": "white",
                        "width": "100%",
                        "paddingLeft": "0px",
                        "paddingRight": "0px",
                        "borderBottom": "1px solid #bbc0c1", // Meridian gray-300
                    }}
                >
                    <Row width="100%" height="48px" alignmentHorizontal="right" alignmentVertical="center" spacingInset="small">
                        <Column spacing="none">
                            <Button type="primary" size="small" onClick={onOpenShareModal} disabled={!startTime || !endTime || !timezoneValue} minWidth="185px">
                                <Row spacingInset="none small">
                                    {(!startTime || !endTime || !timezoneValue) &&
                                        <Loader size="small" />
                                    }
                                    <Text type="b300" color="inverted">
                                        Share my availability
                                    </Text>
                                </Row>
                            </Button>
                        </Column>
                        <Modal
                            open={shareModalOpen}
                            scrollContainer="modal"
                            closeLabel="Close share availability modal"
                            onClose={onCloseShareModal}
                            width={MODAL_CONSTANT.MODAL_WIDTH}
                        >
                            <Column spacing="none">
                                <Heading level={2}>{availabilityMessage.title || ""}</Heading>
                                {availabilityMessage?.availabilityList?.map((dayAvailabilities) =>
                                    <>
                                        {dayAvailabilities?.availableTimes.length ?
                                            <Column spacing="none" spacingInset="small">
                                                <Text type="b400">{dayAvailabilities.label}:</Text>
                                                {dayAvailabilities.availableTimes.map((availabilityString) =>
                                                    <Text type="b300">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;{availabilityString}
                                                    </Text>
                                                )}
                                            </Column>
                                            :
                                            null
                                        }
                                    </>
                                )}
                            </Column>
                            <ModalFooter>
                                <Row alignmentHorizontal="right">
                                    <Button onClick={copyAvailabilityToClipboard}>Copy to clipboard</Button>
                                </Row>
                            </ModalFooter>
                        </Modal>
                    </Row>

                    <Row width="100%" height="100%" widths={["fit", "fit", "fit", "fit"]} spacingInset="medium" wrap="down" spacing="small" alignmentVertical="top" alignmentHorizontal="center">
                        <SimpleDurationSelect
                            duration={minDuration}
                            setDuration={setMinDuration}
                            label="Minimum duration"
                            labelId="min-duration-select-label-id"
                            textSize="b100"
                        />
                        <LabeledDateRangePicker
                            screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                            daysOfWeek={daysOfWeek}
                            setDaysOfWeek={setDaysOfWeek}
                            daysOfWeekLabel={daysOfWeekLabel}
                            setDaysOfWeekLabel={setDaysOfWeekLabel}
                            repeatDays={[]}
                            repeatSelected={"none"}
                            setIsRepeatChanged={() => {}}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={onSetDateRangeValue}
                            dateRangeLabel={dateRangeLabel}
                            setDateRangeLabel={setDateRangeLabel}
                            presets={presets}
                            startTime={startTime}
                            endTime={endTime}
                            dateFormat={dateFormat}
                        />
                        <Row spacing="small" wrap="down" alignmentHorizontal="center" alignmentVertical="stretch">
                            <LabeledTimeSelect
                                screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                                isAllDayEvent={false}
                                startTime={startTime}
                                setStartTime={setStartTime}
                                endTime={endTime}
                                setEndTime={setEndTime}
                                timeLabel={timeLabel}
                                setTimeLabel={setTimeLabel}
                                timeFormat={timeFormat}
                            />
                            {/* Temporarily commenting out the timezone selection until we have time to implement the changes in the calendar
                                <Column spacing="none" width={responsiveProps.screenSizeBreakpoint > SCREEN_SIZE.PARTIAL_MOBILE_VIEW ? "150px" : "330px"}>
                                    <Text type="b100">Timezone</Text>
                                    {timezones.length ?
                                        <TimezonePicker
                                            screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                                            width="150px"
                                            mobileWidth="330px"
                                            size="small"
                                            timezones={timezones}
                                            value={timezoneValue}
                                            onChange={setTimezoneValue} />
                                        :
                                        <Loader size="small" />
                                    }
                                </Column>*/
                            }
                        </Row>
                        <Row spacing="medium" wrap="down" alignmentVertical="top" height="48px">
                            <Column spacing="xsmall" spacingInset="none">
                                <div style={{height: "16px"}} />
                                <Checkbox checked={tentativeAsBusy} onChange={onSetTentativeAsBusy}>
                                    Treat tentative as busy
                                </Checkbox>
                            </Column>
                        </Row>
                    </Row>
                </div>
                <div style={{paddingTop: "8px"}}>
                    <Calendar
                        meetingListLoaded={meetingListLoaded}
                        meetings={meetingList}
                        date={calendarDate}
                        calendarStatusFilter={calendarStatusFilter}
                        calendarResponseFilter={calendarResponseFilter}
                        onSetCalendarDate={setCalendarDate}
                        viewType={calendarViewType}
                        daysOfWeek={shortDayToFull(daysOfWeek)}
                        userEmail={userEmail}
                        screenSizeBreakpoint={responsiveProps.screenSizeBreakpoint}
                        setTriggerRefresh={setTriggerRefresh}
                        showRefreshAlert={showRefreshAlert}
                        setShowRefreshAlert={setShowRefreshAlert}
                        selectedStartTime={startTime}
                        selectedEndTime={endTime}
                        trimEvents={true}
                        calendarMode={CALENDAR_MODE.AVAILABILITY_SHARING}
                        boundByDateRange={dateRangeValue}
                        availabilityBlocks={availabilityBlocks}
                        onUpdateAvailability={onUpdateAvailability}
                        availabilityMinDuration={minDuration}
                        timeFormat={timeFormat}
                        primaryTimezone={primaryBrowserTimezone}
                    />
                </div>
                <MeetingsToaster/>
                <MeetingsFeedbackContainer />
            </Column>
        )}
        </Responsive>
    );
};

export default MeetingAvailabilitySharing;
