import * as Actions from "./action-types";

export const updateCurrentMeeting = (meeting) => {
    return {type: Actions.UPDATE_MEETING_ASYNC, meeting};
};

export const saveDraft = (draft) => {
    return {type: Actions.SAVE_DRAFT, meeting: draft.meeting};
};

export const clearDraft = () => {
    return {type: Actions.CLEAR_DRAFT};
};

export const addAttendee = (attendee) => {
    return {type: Actions.ADD_ATTENDEE, attendee};
};

export const removeAttendee = (alias) => {
    return {type: Actions.REMOVE_ATTENDEE, alias};
};

export const searchSuggestion = (query, isAllDayEvent) => {
    return {type: Actions.SEARCH_SUGGESTION, query, isAllDayEvent};
};

export const selectSuggestion = (suggestion, resources) => {
    return {type: Actions.SELECT_SUGGESTION, suggestion, resources};
};

export const setSuggestionViewType = (suggestionViewType) => {
    return {type: Actions.SET_SUGGESTION_VIEW_TYPE, suggestionViewType};
};

export const changeStep = (step) => {
    return {type: Actions.CHANGE_STEP, step};
};

export const setMaster = (master) => {
    return {type: Actions.SET_MASTER, master};
};

export const setCreatingMeetingState = (isCreatingMeeting) => {
    return {type: Actions.SET_CREATING_MEETING_STATE, isCreatingMeeting};
};

export const updateRecurrence = (recurrence) => {
    return {type: Actions.UPDATE_RECURRENCE, recurrence};
};

export const setLoadMeeting = (loadMeeting) => {
    return {type: Actions.SET_LOAD_MEETING, loadMeeting};
};