import React from "react"
import {useSelector} from "react-redux";

import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark";

import {SCREEN_SIZE, MODAL_SIZE} from "../meeting-quick-constants";
import Favorites from "../../shared/favorites/components/favorites";
import {getAttendees, getFavorites, getIdentity} from "../../../sagas/selector";
import starFilledSvg from "../../../assets/icons/favorites/star_filled_light.svg";

const MeetingQuickFavoritesTab = (props) => {

    const identity = useSelector(getIdentity);
    const attendees = useSelector(getAttendees);
    const favorites = useSelector(getFavorites);

    return (
        <div>
            {(props.screenSizeBreakpoint >= SCREEN_SIZE.QUICK_MEETING_FULL_VIEW) &&
                <Theme tokens={blueDarkTokens}>
                    <Column spacing="none" width="fit" spacingInset="medium none medium medium">
                        <Row widths={["20px", "fill"]} spacing="small">
                            <img src={starFilledSvg} alt="favorited icon" width="22px" height="22px"/>
                            <Heading level={2} type="h300">
                                Favorites
                            </Heading>
                        </Row>
                        <Row widths={["20px", "fill"]} spacing="small">
                            <Row/>
                            <Text type="b200" alignment="left">
                                Add your favorites to your meetings with a single click
                            </Text>
                        </Row>
                    </Column>
                </Theme>
            }
            <Column
                spacingInset="none none medium medium"
                height={props.screenSizeBreakpoint < SCREEN_SIZE.QUICK_MEETING_MOBILE_VIEW ? MODAL_SIZE.MOBILE_FAVORITES_HEIGHT : MODAL_SIZE.FAVORITES_HEIGHT}
            >
                <Box
                    spacing="medium"
                    spacingInset="small"
                    height="100%"
                    overflowY="auto"
                    backgroundColor="primary"
                    type="fill"
                >
                    <Favorites
                        width="fill"
                        hideHeaderAndActions={true}
                        hideLocations={true}
                        identity={identity}
                        attendees={attendees}
                        favorites={favorites}
                        favoritesRef={props.favoritesRef}
                        onAddAttendee={props.onAddAttendee}
                        onGetRASDataForPerson={props.onGetRASDataForPerson}
                    />
                </Box>
            </Column>
        </div>
    );
}

export default MeetingQuickFavoritesTab;