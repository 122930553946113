import React from "react"
import PropTypes from "prop-types"
import matchMedia from "./match-media"

/**
 * This looks through an object of nested objects and returns a flat array
 * containing all the unique keys found in the nested objects.
 */
const getUniqueNestedObjectKeys = props =>
  Object.keys(props)
    // Get the keys of each nested object
    .map(prop => Object.keys(props[prop]))
    // Flatten the keys into one array
    .reduce((result, keys) => result.concat(keys), [])
    // Filter out duplicate keys
    .filter((value, index, keys) => keys.indexOf(value) === index)

/**
 * Sort an array according to it's numeric values
 */
const sortNumeric = (arr, direction) => {
  arr.sort(
    (a, b) =>
      (parseFloat(a) - parseFloat(b)) * (direction === "descending" ? -1 : 1)
  )
  return arr
}

/* eslint-disable react/prop-types */
/**
 * This component allows you to respond to the state of media features such as
 * viewport width, viewport aspect ratio, and device orientation. This is
 * similar to "media queries for JavaScript" (and it's even powered by media
 * queries behind the scenes), but it's a lot more powerful. Instead of only
 * allowing you to toggle something on or off based on a matching media query,
 * it allows you to set set any JavaScript variable based on the various states
 * of a media feature. You can use this functionality to do things that are not
 * possible with plain CSS media queries like:
 *   - render entirely different component trees based on viewport size
 *   - pass different props to a component based on viewport width
 *   - change the location of elements in the DOM based on device orientation
 *
 * NOTE: The props passed to this component cannot be changed after the
 * component is first instantiated.
 *
 * ```
 * // Renders "red" below the 1000px breakpoint, "blue" at or above the 1000px
 * // breakpoint but below the 1500px breakpoint, and "green" at or above the
 * // 1500px breakpoint. Renders 12px font below the 1500px breakpoint and 24px
 * // at or above the 1500px breakpoint.
 * <Responsive
 *   query="min-width"
 *   props={{
 *     color: { default: "red", "1000px": "blue", "1500px": "green" },
 *     fontSize: { default: 12, "1500px": 24 },
 *   }}
 * >
 *   {({ color, fontSize }) => (
 *     <div style={{ fontSize }}>My fav color is {color}</div>
 *.  )}
 * </Responsive>
 * ```
 */
class ResponsiveClass extends React.Component {
  constructor(props) {
    super(props)

    // Extract the media condition from the "query" prop
    const condition = /^min-/.test(props.query)
      ? "min"
      : /^max-/.test(props.query)
      ? "max"
      : null

    // Extract the media feature from the "query" prop
    const feature = condition
      ? props.query.replace(new RegExp(`^${condition}-`), "")
      : props.query

    // Extract a list of unique breakpoints from the keys of the "props" prop.
    // Don't include the "default" since that's not something we can actually
    // query for (we'll fallback to that manually if no breakpoint matches)
    const breakpoints = getUniqueNestedObjectKeys(props.props).filter(
      key => key !== "default"
    )

    // Sort the breakpoints according to the media query condition so that later
    // on we can just take the last matching breakpoint and know that that's the
    // min/max matching breakpoint
    const sortedBreakpoints = condition
      ? sortNumeric(
          breakpoints,
          condition === "min" ? "ascending" : "descending"
        )
      : breakpoints

    // This is an object of `MediaQueryList` instances (or mock instances - see
    // `matchMedia`) keyed by the breakpoints that they're matching against (e.g.
    // `{ "0px": mediaQueryA, "1000px": mediaQueryB }`).
    this.mediaQueries = sortedBreakpoints.map(breakpoint => {
      const mediaQuery = matchMedia({
        feature,
        condition,
        fallback: props.fallback,
        breakpoint,
      })
      const listener = () => this.setState({ [breakpoint]: mediaQuery.matches })
      return {
        breakpoint,
        mediaQuery,
        startListening: () => mediaQuery.addListener(listener),
        stopListening: () => mediaQuery.removeListener(listener),
      }
    })

    // Save off the list of prop names for each access later
    this.propNames = Object.keys(props.props)

    // State is an object keyed by breakpoint that includes the match state of
    // the media query for that breakpoint (e.g. if the query is "min-width",
    // we're listening at the breakpoints "0px" and "1000px", adn the viewport
    // width is "500px" then the state object would be:
    // { "0px": true, "1000px": false }
    this.state = this.mediaQueries.reduce(
      (result, { breakpoint, mediaQuery }) => {
        // If a fallback prop is present, match against that for the intial
        // state. This ensures that that the same value is used during server
        // rendering and during the initial rehydrating render on the client.
        result[breakpoint] = this.props.fallback
          ? mediaQuery.matchesFallback
          : mediaQuery.matches
        return result
      },
      {}
    )
  }

  componentDidMount() {
    this.mediaQueries.forEach(({ mediaQuery, breakpoint, startListening }) => {
      // Start listening for future state changes
      startListening()
      // If we matched against the fallback prop on the initial render, but the
      // actual match is different, then update the state now and trigger a
      // re-render
      if (
        this.props.fallback &&
        mediaQuery.matches !== mediaQuery.matchesFallback
      ) {
        // Note: React batches state updates in lifecycle methods so we'll only
        // get one re-render no matter how many breakpoints we have to update.
        this.setState({ [breakpoint]: mediaQuery.matches })
      }
    })
  }

  componentWillUnmount() {
    this.mediaQueries.forEach(({ stopListening }) => stopListening())
  }

  render() {
    // Choose the best value from "props" based on the state of our media
    // queries. If no match is found then return the "default" value (or
    // undefined if there is no default).
    const props = this.propNames.reduce((result, propName) => {
      const prop = this.props.props[propName]
      const value = this.mediaQueries.reduce(
        (result, { breakpoint }) =>
          this.state[breakpoint] && prop[breakpoint] !== undefined
            ? prop[breakpoint]
            : result,
        prop.default
      )
      result[propName] = value
      return result
    }, {})
    return this.props.children(props)
  }
}
/* eslint-enable react/prop-types */

// Keep refs from being passed. We're doing this for 4.x - the
// most recent breaking change - so that we can change the
// _actual_ component to functional later without waiting
// for the next breaking change
const Responsive = props => <ResponsiveClass {...props} />

Responsive.displayName = "Responsive"

Responsive.propTypes = {
  /**
   * A function that returns React elements. This will receive an object of
   * props based on the current state of the queried media feature and the
   * values passed to "props".
   */
  children: PropTypes.func.isRequired,
  /**
   * This is a nested object that allows you to define the prop values that
   * should be passed to the children function given any particular state of
   * the queried media feature. The special state "default" can be used to
   * indicate a value that should be returned if none of the other states are
   * matched. See the top-level documentation for this component for an
   * example.
   */
  props: PropTypes.objectOf(PropTypes.object).isRequired,
  /**
   * The media feature (e.g. width) and conditional (e.g. min) to watch for
   * changes and respond to.
   */
  query: PropTypes.oneOf([
    "min-width",
    "max-width",
    "min-height",
    "max-height",
    "min-aspect-ratio",
    "max-aspect-ratio",
    "orientation",
  ]).isRequired,
  /**
   * This is used as the fallback state of the queried media feature if the
   * component is rendered in an environment that doesn't support media
   * queries (e.g. NodeJS).
   *
   * Note that this does not have to match the states
   * found in the "props" object exactly to trigger a match. For example, if
   * "query" is set to "min-width", "fallback" is set to "1024px", and "props"
   * is set to `{ myValue: { default: "small", "800px": "medium", "1280px": "large" }}`,
   * then "myValue" would resolve to "medium" because "min-width: 800px"
   * passes when the width falls back to "1024px".
   *
   * IMPORTANT: This is required if you're server-rendering or pre-rendering this
   * component! Otherwise you may end up with a mismatch between your server
   * rendered markup and the markup that React tries to rehydrate with, and it
   * will literally shred the DOM.
   */
  fallback: PropTypes.string,
}

export default Responsive
