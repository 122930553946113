import React from "react";
import { useSelector } from "react-redux";

import { getSelectedPollDetails} from "../../../sagas/selector";

import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import clockTokens from "@amzn/meridian-tokens/base/icon/clock";
import syncTokens from "@amzn/meridian-tokens/base/icon/sync";
import Divider from "@amzn/meridian/divider";
import Tag from "@amzn/meridian/tag";

import { ReactComponent as RoomIcon } from "../../../assets/icons/locations/room-black.svg";

import "../../meeting-polls/meeting-polls.css";
import { compareAttendee } from "../../meeting-polls/poll-utils";
import { timezoneIdToDisplayName } from "../../shared/timezones/timezones-utils";
import { convertEpoch } from "../meeting-scheduler-utils";
import { parseRecurrence } from "../../shared/meeting-utils";
import { TIME_FORMAT } from "../../shared/settings/settings-constants";
import { SCREEN_SIZE } from "../../shared/shared-constants";

const MeetingOfPollReviewCard = (props) => {
    const pollDetails = useSelector(getSelectedPollDetails);

    const currentMeeting = useSelector(props.currentMeetingSelector);
    const screenSizeBreakpoint = props.screenSizeBreakpoint;
    const sortedAttendeeList = pollDetails.attendees ? pollDetails.attendees.sort(compareAttendee) : [];
    const availability = currentMeeting.time;
    const respondedAttendeeList = (sortedAttendeeList && sortedAttendeeList.filter((attendee) => attendee.response === true)) || [];
    const notRespondedAttendeeList = (sortedAttendeeList && sortedAttendeeList.filter((attendee) => attendee.response === false)) || [];
    const availableAttendeeList = (respondedAttendeeList && availability && respondedAttendeeList.filter(
        (attendee) => attendee.availability && attendee.availability.find(
            (attendeeAvailability) => attendeeAvailability.startTime <= availability.startTime && attendeeAvailability.endTime >= availability.endTime) !== undefined
    )) || [];
    const unavailableAttendeeList = (respondedAttendeeList && respondedAttendeeList.filter((attendee) => availableAttendeeList && !availableAttendeeList.includes(attendee))) || [];

    // Timezones
    const timezones = props.timezones;
    const suggestionsTimezoneId = props.suggestionsTimezoneId;
    const suggestionsTimezoneName = timezoneIdToDisplayName(timezones, suggestionsTimezoneId);
    const timeFormat = props.timeFormat;

    // Datetime format and date format
    const startTime = currentMeeting && currentMeeting.time && currentMeeting.time.startTime;
    const endTime = currentMeeting && currentMeeting.time && currentMeeting.time.endTime;
    const formatDateTime = (time, format) => {
        return convertEpoch(time, format, suggestionsTimezoneId, timeFormat === TIME_FORMAT.TWELVE_HOUR);
    };
    const displayedStartTime = formatDateTime(startTime, "time");
    const displayedEndTime = formatDateTime(endTime, "time");
    const displayedStartDate = formatDateTime(startTime, "shortWeekdayDate");
    const displayedEndDate = formatDateTime(endTime, "shortWeekdayDate");

    return(
        <Column width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? null : "100%"} spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "" : "none medium none none"}>
                <Column
                    spacingInset={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "medium medium medium none" : "medium"}
                    width="fill"
                    height="fill"
                    maxHeight="450px"
                    alignmentHorizontal="center"
                >
                    <Box
                        type="outline"
                        width={screenSizeBreakpoint >= SCREEN_SIZE.VIEW_WITHOUT_FAVORITES_SIDEBAR ? "400px" : "100%"}
                        height="fill"
                    >
                        <Row
                            spacing="medium"
                            spacingInset="300 medium"
                            width="100%"
                            alignmentHorizontal="left"
                            backgroundColor="#F9FAFA"
                        >
                            <Text type="h100">Selected meeting slot</Text>
                        </Row>
                        <section aria-label={`Time: ${formatDateTime(startTime, "longWeekdayDateYear")},
                                                    ${displayedStartTime} to
                                                    ${displayedEndTime}
                                                    ${suggestionsTimezoneName}
                                                    ${currentMeeting.recurrence ? parseRecurrence(currentMeeting.recurrence) : ""}`}>
                            <Column spacing="small" spacingInset="medium">
                                <Row alignmentHorizontal="justify">
                                    {displayedStartDate === displayedEndDate ?
                                        <Row alignmentVertical="top" spacing="small">
                                            <Icon tokens={clockTokens} />
                                            <Column spacing="small">
                                                <Row spacingInset="none xxsmall" spacing="xsmall">
                                                    <Text type="b200">
                                                        <b>{displayedStartTime}&nbsp;-&nbsp;{displayedEndTime}</b>
                                                    </Text>
                                                    <Text type="b100" color="secondary">
                                                        &nbsp;{suggestionsTimezoneName}
                                                    </Text>
                                                </Row>
                                                <Row spacingInset="none xxsmall">
                                                    <Text type="b200">
                                                        {displayedEndDate}
                                                    </Text>
                                                </Row>
                                            </Column>
                                        </Row>
                                        : // for multiple days time format
                                        <Row alignmentVertical="top" spacing="small">
                                            <Icon tokens={clockTokens} />
                                            <Column spacing="small">
                                                <Row spacingInset="xxsmall" spacing="xsmall">
                                                    <Text type="b200">
                                                        <b>{displayedStartTime}</b>
                                                    </Text>
                                                    <Text type="b100" color="secondary">
                                                        &nbsp;{suggestionsTimezoneName}
                                                    </Text>
                                                </Row>
                                                <Row spacingInset="none xxsmall">
                                                    <Text type="b200">
                                                        {displayedStartDate}
                                                    </Text>
                                                </Row>
                                            </Column>
                                            <Column spacing="small">
                                                <Row spacingInset="xxsmall" spacing="xsmall">
                                                    <Text type="b200">
                                                        <b>to&nbsp;&nbsp;{displayedEndTime}</b>
                                                    </Text>
                                                    <Text type="b100" color="secondary">
                                                        &nbsp;{suggestionsTimezoneName}
                                                    </Text>
                                                </Row>
                                                <Row spacingInset="none none none 450">
                                                    <Text type="b200">
                                                        {displayedEndDate}
                                                    </Text>
                                                </Row>
                                            </Column>
                                        </Row>
                                    }
                                </Row>
                                {currentMeeting.recurrence &&
                                <Row spacing="small">
                                    <Icon tokens={syncTokens} />
                                    <Text type="b200">{parseRecurrence(currentMeeting.recurrence)}</Text>
                                </Row>
                                }
                            </Column>
                        </section>
                        <Row spacingInset="none medium">
                            <Divider size="small"/>
                        </Row>
                        <section aria-label={""}>
                            <Row spacingInset="none 450 none large" height="fill">
                                <Column spacingInset="none none none xxsmall" width="stretch">
                                    <Column spacing="none" spacingInset="none none none 400" height="fill"
                                            maxHeight="75px" overflowY="auto">
                                        <div className="tag-text">
                                            Available ({availableAttendeeList.length}/{sortedAttendeeList.length})
                                        </div>
                                        {availableAttendeeList && availableAttendeeList.map((attendee) =>
                                            <Column spacing="xsmall" spacingInset="none">
                                                <Text type="b100">
                                                    {attendee.displayName}
                                                </Text>
                                            </Column>
                                        )}
                                        <div className="tag-text">
                                            Unavailable ({unavailableAttendeeList.length}/{sortedAttendeeList.length})
                                        </div>
                                        {unavailableAttendeeList && unavailableAttendeeList.map((attendee) =>
                                            <Column spacing="xsmall" spacingInset="none">
                                                <Text type="b100">
                                                    {attendee.displayName}
                                                </Text>
                                            </Column>
                                        )}
                                        <div className="tag-text">
                                            Not Responded ({notRespondedAttendeeList.length}/{sortedAttendeeList.length})
                                        </div>
                                        {notRespondedAttendeeList && notRespondedAttendeeList.map((attendee) =>
                                            <Column spacing="xsmall" spacingInset="none">
                                                <Text type="b100">
                                                    {attendee.displayName}
                                                </Text>
                                            </Column>
                                        )}
                                    </Column>
                                </Column>
                            </Row>
                            {/* TODO: Will support room in the future*/}
                            <Column spacing="xsmall" spacingInset="medium">
                                <Row alignmentHorizontal="justify">
                                    <Row spacing="small" alignmentVertical="top">
                                        <RoomIcon alt="room"/>
                                        <Column width="fill" alignmentHorizontal="left" spacingInset="none none none xxsmall">
                                            <Tag type="success">Virtual only</Tag>
                                        </Column>
                                    </Row>
                                </Row>
                            </Column>
                        </section>
                    </Box>
                </Column>
        </Column>
    );
};

export default MeetingOfPollReviewCard;